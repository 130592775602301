import React from "react";
import { CorrectionOfFragment } from "./fragments.graphql";
import { Label, Subtitle } from "@metronome-industries/design-system";
import { renderDate } from "lib/time";
import { dayjs } from "lib/dayjs";

export const CorrectionOf: React.FC<{
  correction_of: CorrectionOfFragment;
  customer: { id: string; name: string };
}> = ({ correction_of, customer }) => {
  return (
    <div className="pt-12 px-12">
      <div className="flex items-center gap-4">
        <Subtitle level={1}>
          Invoice correction for{" "}
          {`${customer.name} ${renderDate(
            dayjs
              .utc(
                correction_of.correction_record.original_invoice.__typename ===
                  "ContractScheduledInvoice" ||
                  correction_of.correction_record.original_invoice
                    .__typename === "ContractPostpaidTrueupInvoice"
                  ? correction_of.correction_record.original_invoice.issued_at
                  : correction_of.correction_record.original_invoice
                      .exclusive_end_date
              )
              .subtract(1, "s")
              .toDate(),
            {
              isUtc: true,
              excludeUtcLabel: true,
            }
          )}`}
        </Subtitle>
      </div>
      <div>
        <Label className="text-xs">
          {correction_of.correction_record.reason} /{" "}
          {correction_of.correction_record.memo}
        </Label>
      </div>
    </div>
  );
};
