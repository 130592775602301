import { dayjs, Dayjs } from "lib/dayjs";
import { renderDate, renderDateRange, renderDateTimeInUTC } from "lib/time";

export { dayjs, type Dayjs };

type DateInput = string | Date | Dayjs;

export function toDayjs(date: DateInput): Dayjs {
  return dayjs.utc(date);
}

export function toDate(date: DateInput): Date {
  return toDayjs(date).toDate();
}

export function distanceFrom(now: Dayjs, to: Dayjs) {
  to = toDayjs(to);

  const diff = to.diff(now, "milliseconds");
  return {
    isInPast: diff < 0,
    inFuture: diff > 0,
    distDays: Math.abs(to.diff(now, "days")),
  };
}

export function printDate(date: Dayjs) {
  return renderDate(toDate(date), { isUtc: true });
}

export function printDateTime(date: Dayjs) {
  return renderDateTimeInUTC(toDate(date), false);
}

/**
 * Print a date range, if there is not end date then it's printed as an open-ended range,
 * indicating if the start date is in the past or the future.
 */
export function printDateRange(
  now: Dayjs,
  from: Dayjs,
  to: Dayjs | undefined | null
) {
  if (!to) {
    if (now.isSameOrAfter(from)) {
      return `Started ${printDate(from)}`;
    }

    return `Starting ${printDate(from)}`;
  }

  return renderDateRange(from.toDate(), to.toDate(), { isUtc: true }, false);
}

/**
 * Determine if a date is within a given range. Uses milliseconds
 * accuracy and the start of the range is inclusive while the end
 * is exclusive.
 *
 * `endingBefore` is optional, if not provided then the range is
 * considered to be open-ended.
 */
export function isInRange(
  date: Dayjs,
  startingAt: Dayjs,
  endingBefore?: Dayjs
) {
  if (!endingBefore) {
    return toDayjs(date).isSameOrAfter(startingAt, "ms");
  } else {
    return toDayjs(date).isBetween(startingAt, endingBefore, "ms", "[)");
  }
}
