import React from "react";
import { Badge, HelpCircleTooltip } from "@metronome-industries/design-system";
import styles from "./index.module.less";
import { dayjs } from "lib/dayjs";
import classNames from "classnames";

import { StatusPillsFieldsFragment } from "types/generated-graphql/src/components/StatusPills/fragments.graphql";

export const StatusPills: React.FC<{
  invoice: StatusPillsFieldsFragment;
  compact?: boolean;
  className?: string;
  light: boolean;
}> = ({ invoice, compact, className, light }) => {
  const contents: React.ReactNode[] = [];
  const type = light ? "light" : "dark";

  if (invoice.status === "VOID") {
    return (
      <div className={classNames(styles.StatusPills, className)}>
        <Badge key="status" type={type} theme="error">
          VOID
        </Badge>
      </div>
    );
  }

  if (invoice.__typename === "CreditPurchaseInvoice") {
    if (dayjs(invoice.issued_at).isBefore(new Date())) {
      contents.push(
        <Badge key="status" type={type} theme="success">
          ISSUED
        </Badge>
      );
    } else {
      contents.push(
        <Badge key="status" type={type} theme="grey">
          SCHEDULED
        </Badge>
      );
      if (!compact) {
        contents.push(
          <HelpCircleTooltip
            key="help"
            content="This invoice will be issued on the scheduled date."
          />
        );
      }
    }
    return (
      <div className={classNames(styles.StatusPills, className)}>
        {contents}
      </div>
    );
  }

  if (invoice.status === "DRAFT") {
    contents.push(
      <Badge key="status" type={type} theme="grey">
        DRAFT
      </Badge>
    );
  } else if (invoice.status === "FINALIZED") {
    contents.push(
      <Badge key="status" type={type} theme="success">
        FINALIZED
      </Badge>
    );
  } else if (invoice.status === "PENDING_FINALIZATION") {
    contents.push(
      <Badge key="status" type={type} theme="primary">
        PENDING FINALIZATION
      </Badge>
    );
  } else if (invoice.status === "FINALIZED_IN_OLD_SYSTEM") {
    // This status is "internal", the resolvers should never return it. That said, to satisfy the type checker, we need to handle it.
    throw new Error(
      "Recieved finalized invoice from old system, which should not be exposed to callers ever"
    );
  } else {
    // If this is failing to compile, you probably need to add a new case to the switch statement above
    invoice.status satisfies never;
  }
  if (invoice.__typename === "ArrearsInvoice" && invoice.is_trial) {
    contents.push(
      <Badge
        className={classNames(styles.trialBadge, {
          [styles.light]: light,
        })}
        theme="success"
        type={type}
        key="trial"
      >
        {compact ? "TRIAL" : "TRIAL PERIOD"}
      </Badge>
    );
  }

  return (
    <div className={classNames(styles.StatusPills, className)}>{contents}</div>
  );
};
