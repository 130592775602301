import React from "react";

import { dayjs } from "lib/dayjs";
import { Subtitle, Caption } from "@metronome-industries/design-system";
import { renderDateTimeInUTC } from "lib/time";

import { Invoice as InvoiceType } from "../types";

import styles from "./index.module.less";
import classNames from "classnames";

type TimelineEntry = {
  title: string;
  date: Date;
  invoiceId: string;
};

export const InvoiceTimeline: React.FC<{
  invoice: InvoiceType;
}> = ({ invoice }) => {
  let entries: TimelineEntry[] = [];
  if (
    invoice.__typename === "ArrearsInvoice" ||
    invoice.__typename === "ContractUsageInvoice" ||
    invoice.__typename === "ParentInvoice" ||
    invoice.__typename === "AdhocContractUsageInvoice" ||
    invoice.__typename === "AdHocPlanInvoice"
  ) {
    entries = [
      {
        title: "Start",
        date: new Date(invoice.inclusive_start_date),
        invoiceId: invoice.id,
      },
      {
        title: "End",
        date: new Date(invoice.exclusive_end_date),
        invoiceId: invoice.id,
      },
      {
        title: "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else if (invoice.__typename === "CreditPurchaseInvoice") {
    entries = [
      {
        title: dayjs(invoice.issued_at).isSameOrAfter(new Date())
          ? "Scheduled"
          : "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else if (
    invoice.__typename === "AdvanceInvoice" ||
    invoice.__typename === "CorrectionInvoice" ||
    invoice.__typename === "SeatPurchaseInvoice" ||
    invoice.__typename === "ContractScheduledInvoice" ||
    invoice.__typename === "ContractRefundInvoice" ||
    invoice.__typename === "ContractPostpaidTrueupInvoice" ||
    invoice.__typename === "ContractProServiceInvoice"
  ) {
    entries = [
      {
        title: "Issued",
        date: new Date(invoice.issued_at),
        invoiceId: invoice.id,
      },
    ];
  } else {
    // If this is failing to compile, you probably need to add a new case to the switch statement above
    invoice satisfies never;
  }

  return (
    <div className={styles.InvoiceTimeline}>
      {entries.map((entry, i) => {
        return (
          <div
            key={`${entry.invoiceId}-${
              entry.title
            }-${entry.date.toISOString()}`}
            className={classNames(styles.entry, {
              [styles.active]:
                dayjs().isAfter(entry.date) &&
                (!entries[i + 1] || dayjs().isBefore(entries[i + 1].date)),
            })}
          >
            <Caption className={styles.caption}>{entry.title}</Caption>
            <Subtitle level={4}>
              {renderDateTimeInUTC(entry.date, false)}
            </Subtitle>
          </div>
        );
      })}
    </div>
  );
};
