import React from "react";
import styles from "./index.module.css";

import { Subtitle } from "@metronome-industries/design-system";

import {
  isSameMonth,
  isSameYear,
  isSameDay,
  startOfDay,
  addDays,
} from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import pluralize from "pluralize";

import dayjs from "dayjs";
import dayJSUtc from "dayjs/plugin/utc";
import { BillingFrequencyEnum_Enum } from "types/generated-graphql/__types__";
dayjs.extend(dayJSUtc);

const UTC_TIMEZONE = "Etc/UTC";

// Expects the date to already been passed through utcToZonedTime
const formatInUTC: typeof format = (zonedDate, formatString, options) => {
  return format(zonedDate, formatString, {
    ...options,
    timeZone: UTC_TIMEZONE,
  });
};

function richDateTimeElement(
  dateString: string,
  timeString: string,
  prefix?: string,
  suffix?: string
): JSX.Element {
  return (
    <Subtitle level={4}>
      {`${prefix ?? ""} ${dateString}`.trim()}
      &nbsp;
      <span className={styles.richTextTime}>
        {timeString}
        {suffix ? ` ${suffix}` : ``}
      </span>
    </Subtitle>
  );
}

type DateOptions = {
  isUtc: boolean;
  excludeYear?: boolean;
  excludeUtcLabel?: boolean;
};

export function renderDate(d: Date, options: DateOptions): string {
  const formatFn = options.isUtc ? formatInUTC : format;
  const formatString = options.excludeYear ? "MMM d" : "MMM d, yyyy";
  const formattedDate = formatFn(
    options.isUtc ? utcToZonedTime(d, UTC_TIMEZONE) : d,
    formatString
  );
  if (options.isUtc && !options.excludeUtcLabel) {
    return formattedDate + " (UTC)";
  }
  return formattedDate;
}

// Renders a date-time in the user's local time.
export function renderDateTime(
  d: Date,
  isRichText: true,
  prefix?: string
): JSX.Element;
export function renderDateTime(
  d: Date,
  isRichText: false,
  prefix?: string
): string;
export function renderDateTime(d: Date, isRichText: boolean, prefix?: string) {
  const dateString = format(d, "MMM d, yyyy");
  const timeString = format(d, "h:mm a");

  if (!isRichText) {
    return `${prefix ?? ""} ${dateString} ${timeString}`.trim();
  }

  return richDateTimeElement(dateString, timeString, prefix);
}

export function renderDateRange(
  startDate: Date,
  endDate: Date,
  options: DateOptions,
  isRichText: true
): JSX.Element;
export function renderDateRange(
  startDate: Date,
  endDate: Date,
  options: DateOptions,
  isRichText: false
): string;
export function renderDateRange(
  startDate: Date,
  endDate: Date,
  options: DateOptions,
  isRichText: boolean
) {
  const { isUtc: utc, excludeUtcLabel } = options;
  const formatFn = utc ? formatInUTC : format;
  if (utc) {
    startDate = utcToZonedTime(startDate, UTC_TIMEZONE);
    endDate = utcToZonedTime(endDate, UTC_TIMEZONE);
  }
  let dateString: string = "";
  let timeString: string = "";
  if (isSameDay(startDate, endDate)) {
    dateString = formatFn(
      startDate,
      options.excludeYear ? "MMM dd" : "MMM dd, yyyy"
    );
    if (formatFn(startDate, "a") === formatFn(endDate, "a")) {
      timeString =
        formatFn(startDate, "h:mm") + " - " + formatFn(endDate, "h:mm a");
    } else {
      timeString =
        formatFn(startDate, "h:mm a") + " - " + formatFn(endDate, "h:mm a");
    }
  } else if (
    isSameMonth(startDate, endDate) &&
    isSameYear(startDate, endDate)
  ) {
    dateString =
      formatFn(startDate, "MMM dd") +
      " - " +
      formatFn(endDate, options.excludeYear ? "dd" : "dd, yyyy");
  } else if (isSameYear(startDate, endDate)) {
    dateString =
      formatFn(startDate, "MMM dd") +
      " - " +
      formatFn(endDate, options.excludeYear ? "MMM dd" : "MMM dd, yyyy");
  } else {
    dateString =
      formatFn(startDate, "MMM dd, yyyy") +
      " - " +
      formatFn(endDate, "MMM dd, yyyy");
  }

  if (!isRichText) {
    return `${dateString} ${timeString}${excludeUtcLabel ? "" : " (UTC)"}`;
  }

  return richDateTimeElement(dateString, timeString);
}

export const getUtcStartOfDay = (date: Date): Date => {
  return zonedTimeToUtc(startOfDay(date), "Etc/UTC");
};

export const getUtcEndDate = (date: Date): Date => {
  return getUtcStartOfDay(addDays(date, 1));
};

export const dayBefore = (date: Date): Date =>
  dayjs.utc(date).subtract(1, "day").endOf("day").toDate();

const assertExhaustive = (x: never): never => x;

// Returns a textual description of how often an event recurs. E.g. "Recurs every month"
export const getRecurrenceText = (
  interval: number,
  frequency: BillingFrequencyEnum_Enum
) => {
  let months = interval;
  switch (frequency) {
    case BillingFrequencyEnum_Enum.Monthly:
      months = interval;
      break;
    case BillingFrequencyEnum_Enum.SemiMonthly:
      months = interval / 2;
      break;
    case BillingFrequencyEnum_Enum.Quarterly:
      months = interval * 3;
      break;
    case BillingFrequencyEnum_Enum.SemiAnnual:
      months = interval * 6;
      break;
    case BillingFrequencyEnum_Enum.Annual:
      months = interval * 12;
      break;
    default:
      assertExhaustive(frequency);
  }
  return `Recurs every ${pluralize("month", months, months !== 1)}`;
};

export function renderDateTimeInUTC(d: Date, isRichText: true): JSX.Element;
export function renderDateTimeInUTC(d: Date, isRichText: false): string;
export function renderDateTimeInUTC(d: Date, isRichText: boolean) {
  const dateString = formatInUTC(
    utcToZonedTime(d, UTC_TIMEZONE),
    "MMM d, yyyy"
  );
  const timeString = formatInUTC(utcToZonedTime(d, UTC_TIMEZONE), "h:mm a");

  if (!isRichText) {
    return `${dateString} ${timeString} (UTC)`.trim();
  }

  return richDateTimeElement(dateString, timeString, undefined, "(UTC)");
}
