import React, { useEffect, useState } from "react";
import { Headline } from "@metronome-industries/design-system";
import * as Sentry from "@sentry/react";

import { InvoiceSkeleton, Invoice } from "components/Invoice";

import { useRecentInvoices } from "./useRecentInvoices";
import { InvoiceList } from "./InvoicesList";
import { EmptyState } from "components/EmptyState";
import { ErrorEmptyState } from "components/EmptyState/ErrorEmptyState";
import { InvoiceStatusEnum } from "types/generated-graphql/__types__";

const Invoices: React.FC = () => {
  const [activeInvoiceId, setActiveInvoiceId] = useState<string>();

  let statuses: InvoiceStatusEnum[] | null = null;
  if (window.location.search.includes("hide_voided_invoices=")) {
    const urlParams = new URLSearchParams(window.location.search);
    if ((urlParams.get("hide_voided_invoices") ?? "false") === "true") {
      statuses = [
        InvoiceStatusEnum.Draft,
        InvoiceStatusEnum.Finalized,
        InvoiceStatusEnum.FinalizedInOldSystem,
        InvoiceStatusEnum.PendingFinalization,
      ];
    }
  }
  const invoicesReq = useRecentInvoices({
    variables: {
      limit: 100,
      statuses,
    },
    onCompleted(data) {
      if (data.Customer[0].recentInvoices.invoices.length > 0) {
        setActiveInvoiceId(data.Customer[0].recentInvoices.invoices[0].id);
      }
    },
  });

  useEffect(() => {
    if (invoicesReq.state !== "success") {
      return;
    }

    Sentry.setUser({
      id: invoicesReq.customerId,
    });

    setTimeout(() => {
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.style.display = "none";
      }
    }, 10);
  }, [invoicesReq]);

  if (invoicesReq.state === "not found") {
    return <EmptyState title="Invoice not found" subtitle="" />;
  }

  if (invoicesReq.state === "error") {
    return (
      <ErrorEmptyState
        title="We were unable to load invoices."
        error={invoicesReq.error}
      />
    );
  }

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <div className="grow flex flex-row h-full w-full overflow-hidden">
        <div className="w-1/3 max-w-lg grow-0 shrink-0 h-full overflow-auto pr-12 border-r border-r-gray-light">
          <Headline level={5} className="text-sm leading-1 font-medium my-12">
            Invoice History
          </Headline>
          <InvoiceList
            loading={invoicesReq.state === "loading"}
            activeInvoiceId={activeInvoiceId}
            setActiveInvoiceId={setActiveInvoiceId}
            invoices={
              invoicesReq.state === "success" ? invoicesReq.invoices : []
            }
          />
        </div>
        <div className="grow shrink h-full overflow-auto flex flex-col">
          {invoicesReq.state === "loading" ||
          (!activeInvoiceId && invoicesReq.invoices.length) ? (
            <InvoiceSkeleton />
          ) : !activeInvoiceId ? (
            <EmptyState
              title="No invoices found for this customer"
              subtitle=""
              icon="receipt"
            />
          ) : (
            <Invoice invoiceID={activeInvoiceId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
