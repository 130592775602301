import React from "react";
import classNames from "classnames";

import { ParagraphSkeleton } from "components/Skeleton";

export interface RoundRectListItem {
  id: string;
  containerClassName?: string;
  content: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

interface RoundRectProps extends React.PropsWithChildren {
  loading?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}
const RoundRect: React.FC<RoundRectProps> = (props) => (
  <li
    className={classNames(
      "border rounded-medium border-gray-light p-8",
      {
        "cursor-pointer bg-background hover:shadow-md hover:border-primary-200 hover:bg-primary-50":
          !props.loading,
      },
      props.className
    )}
    onClick={props.onClick}
  >
    {props.loading ? <ParagraphSkeleton numLines={3} /> : props.children}
  </li>
);

interface RoundRectListProps extends React.PropsWithChildren {
  loading: boolean;
  items: RoundRectListItem[];
  emptyState?: React.ReactNode;
}

export function RoundRectList(props: RoundRectListProps) {
  return (
    <ul className="space-y-12">
      {props.loading ? (
        <>
          <RoundRect loading />
          <RoundRect loading />
          <RoundRect loading />
          <RoundRect loading />
        </>
      ) : !props.items?.length ? (
        props.emptyState
      ) : (
        props.items.map((item) => (
          <RoundRect
            key={item.id}
            className={classNames(
              item.active && "border-primary-200 bg-primary-50",
              item.containerClassName
            )}
            onClick={item.onClick}
          >
            {item.content}
          </RoundRect>
        ))
      )}
    </ul>
  );
}
