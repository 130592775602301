import { toDayjs, Dayjs } from "../date";

import {
  ProductListItemFragment,
  UsageProductListItemFragment,
} from "./fragments.graphql";

export type ProductListItemType =
  | "USAGE"
  | "SUBSCRIPTION"
  | "FIXED"
  | "COMPOSITE"
  | "PRO_SERVICE";

function toType(
  typename: NonNullable<ProductListItemFragment["__typename"]>
): ProductListItemType {
  switch (typename) {
    case "FixedProductListItem":
      return "FIXED";
    case "UsageProductListItem":
      return "USAGE";
    case "SubscriptionProductListItem":
      return "SUBSCRIPTION";
    case "CompositeProductListItem":
      return "COMPOSITE";
    case "ProServiceProductListItem":
      return "PRO_SERVICE";
  }
}

interface TimedState {
  createdAt: Dayjs;
  effectiveAt: Dayjs;
  value: string;
}

export class ProductListItemFragmentReader {
  public readonly id: string;
  public readonly type: ProductListItemType;
  public readonly billable_metric?: UsageProductListItemFragment["initial"]["billable_metric"];
  public readonly netsuite_internal_item_id: string | null;
  public readonly netsuite_overage_item_id?: string | null;
  public readonly composite_products?: ProductListItemFragmentReader[];
  public readonly allNames: string[];

  public readonly name: {
    initial: Omit<TimedState, "effectiveAt">;
    updates: TimedState[];
  };

  constructor(fragment: ProductListItemFragment) {
    if (!fragment.__typename) {
      throw new Error("__typename is missing in ProductListItem fragment");
    }

    this.id = fragment.id;
    this.type = toType(fragment.__typename);

    this.name = {
      initial: {
        createdAt: toDayjs(fragment.initial.created_at),
        value: fragment.initial.name,
      },
      updates: fragment.updates
        .flatMap((update): TimedState | never[] =>
          update.name == null
            ? []
            : {
                createdAt: toDayjs(update.created_at),
                effectiveAt: toDayjs(update.effective_at),
                value: update.name,
              }
        )
        .sort((a, b) => a.effectiveAt.diff(b.effectiveAt)),
    };

    this.allNames = Array.from(
      new Set([
        fragment.initial.name,
        ...fragment.updates.flatMap((u) => u.name ?? []),
      ])
    );

    this.billable_metric =
      fragment.__typename === "UsageProductListItem"
        ? fragment.initial.billable_metric
        : undefined;
    this.netsuite_internal_item_id = fragment.initial.netsuite_internal_item_id;
    this.netsuite_overage_item_id =
      "netsuite_overage_item_id" in fragment.initial
        ? fragment.initial.netsuite_overage_item_id
        : undefined;
    this.composite_products =
      fragment.__typename === "CompositeProductListItem"
        ? fragment.initial.composite_products?.map(
            (product) => new ProductListItemFragmentReader(product)
          )
        : undefined;
  }

  printType(): string {
    switch (this.type) {
      case "FIXED":
        return "Fixed-recurring";
      case "USAGE":
        return "Usage-based";
      case "SUBSCRIPTION":
        return "Subscription";
      case "COMPOSITE":
        return "Composite";
      case "PRO_SERVICE":
        return "Professional services";
    }
  }

  getName(now: Dayjs) {
    let name = this.name.initial.value;

    for (
      let i = 0;
      i < this.name.updates.length &&
      this.name.updates[i].effectiveAt.isSameOrBefore(now);
      i++
    ) {
      name = this.name.updates[i].value;
    }

    return name;
  }

  getUpcomingNameChanges(now: Dayjs) {
    const updates: TimedState[] = [];

    for (const update of this.name.updates) {
      if (update.effectiveAt.isAfter(now)) {
        updates.push(update);
      }
    }

    return updates;
  }
}
