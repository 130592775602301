import React from "react";
import classNames from "classnames";
import { Icon } from "@metronome-industries/design-system";
import { DocumentNode } from "graphql";

import { reportToSentry } from "lib/errors/sentry";

import { getUserFacingErrorMessage } from "./errorHandling";

interface Props {
  /**
   * The top-level error message, suggest something like:
   * "We ran into an issue loading this page"
   */
  title: string;
  error: unknown;
  compact?: boolean;

  buttonText?: string;
  buttonAuthDoc?: DocumentNode;
  buttonRoutePath?: string;
}

export const ErrorEmptyState: React.FC<Props> = (props) => {
  const [sentryEventId, setSentryEventId] = React.useState<string>();

  React.useEffect(() => {
    if (props.error) {
      setSentryEventId(reportToSentry(props.error));
    } else {
      setSentryEventId(undefined);
    }
  }, [props.error]);

  const errorMessage = getUserFacingErrorMessage(props.error, "");

  const icon = (
    <div
      className={classNames(
        "flex items-center justify-center text-center bg-error-50 text-error-500",
        {
          "rounded-[40px] h-[80px] w-[80px] mb-12": !props.compact,
          "rounded-[20px] h-[40px] w-[40px]": props.compact,
        }
      )}
    >
      <Icon
        icon={Math.random() > 0.3 ? "sadOutline" : "bugOutline"}
        className={classNames({
          "text-[30px]": !props.compact,
          "text-[20px]": props.compact,
        })}
      />
    </div>
  );

  const title = (
    <p
      className={classNames("font-medium", {
        "text-lg": props.compact,
        "text-xl": !props.compact,
      })}
    >
      {props.title}
    </p>
  );

  const text = (
    <>
      <p className="text-sm max-w-xl">
        Don't worry! All of your data is safe, just try refreshing the page. If
        this problem persists, please contact us for support.
      </p>
      {errorMessage ? (
        <p className="text-xxs text-gray-medium font-mono">
          Error: {errorMessage}
        </p>
      ) : null}
      {sentryEventId ? (
        <p className="text-xxs text-gray-medium font-mono">
          Report ID: {sentryEventId}
        </p>
      ) : null}
    </>
  );

  return props.compact ? (
    <div className="mx-auto p-12 flex flex-row gap-12">
      <div className="grow-0">{icon}</div>
      <div className="grow flex flex-col gap-4">
        {title}
        {text}
      </div>
    </div>
  ) : (
    <div className="mx-auto p-24 text-center flex flex-col items-center gap-12 justify-center grow">
      {icon}
      {title}
      {text}
    </div>
  );
};
