/* a6e9b1130ba8ae7a1625384486ae78629a29aaf0
 * This file is automatically generated by graphql-let. */

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CreditAmount: string;
  FlatKeyValueMap: { [key: string]: string | number | boolean };
  JSON: unknown;
  JSONObject: object;
  RecordOfNullableStringArrays: Record<string, string[] | null>;
  RecordOfNullableStrings: Record<string, string | null>;
  RecordOfStrings: Record<string, string>;
  Scalar__ContractRateScheduleSegment: import("types/generated-graphql/__types__").ScalarContractRateScheduleSegment;
  Scalar__RateScheduleSegment: import("types/generated-graphql/__types__").ScalarRateScheduleSegment;
  json: object;
  jsonb: object;
  numeric: string;
  timestamptz: string;
  uuid: string;
};

export type ApiTokenResponse = {
  __typename?: 'APITokenResponse';
  id: Scalars['uuid'];
  token_string: Scalars['String'];
};

export type AwsProServiceRoyalty = {
  __typename?: 'AWSProServiceRoyalty';
  applicable_product_ids: Array<Scalars['uuid']>;
  aws_account_number: Maybe<Scalars['String']>;
  aws_offer_id: Maybe<Scalars['String']>;
  aws_payer_reference_id: Maybe<Scalars['String']>;
  contract: Contract;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Scalars['numeric'];
  netsuite_reseller_id: Scalars['String'];
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Scalars['timestamptz'];
};

export type AwsProServiceRoyaltyUpdate = {
  __typename?: 'AWSProServiceRoyaltyUpdate';
  applicable_product_ids: Maybe<Array<Scalars['uuid']>>;
  applicable_product_tags: Maybe<Array<Scalars['String']>>;
  aws_account_number: Maybe<Scalars['String']>;
  aws_offer_id: Maybe<Scalars['String']>;
  aws_payer_reference_id: Maybe<Scalars['String']>;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Maybe<Scalars['numeric']>;
  netsuite_reseller_id: Maybe<Scalars['String']>;
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type AwsRoyalty = {
  __typename?: 'AWSRoyalty';
  applicable_product_ids: Array<Scalars['uuid']>;
  applicable_product_tags: Array<Scalars['String']>;
  aws_account_number: Maybe<Scalars['String']>;
  aws_offer_id: Maybe<Scalars['String']>;
  aws_payer_reference_id: Maybe<Scalars['String']>;
  contract: Contract;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Scalars['numeric'];
  netsuite_reseller_id: Scalars['String'];
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Scalars['timestamptz'];
};

export type AwsRoyaltyUpdate = {
  __typename?: 'AWSRoyaltyUpdate';
  applicable_product_ids: Maybe<Array<Scalars['uuid']>>;
  applicable_product_tags: Maybe<Array<Scalars['String']>>;
  aws_account_number: Maybe<Scalars['String']>;
  aws_offer_id: Maybe<Scalars['String']>;
  aws_payer_reference_id: Maybe<Scalars['String']>;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Maybe<Scalars['numeric']>;
  netsuite_reseller_id: Maybe<Scalars['String']>;
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "AccountTypeEnum" */
export type AccountTypeEnum = {
  __typename?: 'AccountTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "AccountTypeEnum" */
export type AccountTypeEnum_Aggregate = {
  __typename?: 'AccountTypeEnum_aggregate';
  aggregate: Maybe<AccountTypeEnum_Aggregate_Fields>;
  nodes: Array<AccountTypeEnum>;
};

/** aggregate fields of "AccountTypeEnum" */
export type AccountTypeEnum_Aggregate_Fields = {
  __typename?: 'AccountTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<AccountTypeEnum_Max_Fields>;
  min: Maybe<AccountTypeEnum_Min_Fields>;
};


/** aggregate fields of "AccountTypeEnum" */
export type AccountTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AccountTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AccountTypeEnum". All fields are combined with a logical 'AND'. */
export type AccountTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<AccountTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<AccountTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<AccountTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AccountTypeEnum" */
export enum AccountTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountTypeEnumPkey = 'AccountTypeEnum_pkey'
}

export enum AccountTypeEnum_Enum {
  Active = 'ACTIVE',
  Demo = 'DEMO',
  LoadTest = 'LOAD_TEST',
  None = 'NONE',
  Prospect = 'PROSPECT',
  Sandbox = 'SANDBOX'
}

/** Boolean expression to compare columns of type "AccountTypeEnum_enum". All fields are combined with logical 'AND'. */
export type AccountTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AccountTypeEnum_Enum>;
  _in?: InputMaybe<Array<AccountTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountTypeEnum_Enum>;
  _nin?: InputMaybe<Array<AccountTypeEnum_Enum>>;
};

/** input type for inserting data into table "AccountTypeEnum" */
export type AccountTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountTypeEnum_Max_Fields = {
  __typename?: 'AccountTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountTypeEnum_Min_Fields = {
  __typename?: 'AccountTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AccountTypeEnum" */
export type AccountTypeEnum_Mutation_Response = {
  __typename?: 'AccountTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountTypeEnum>;
};

/** on_conflict condition type for table "AccountTypeEnum" */
export type AccountTypeEnum_On_Conflict = {
  constraint: AccountTypeEnum_Constraint;
  update_columns?: Array<AccountTypeEnum_Update_Column>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "AccountTypeEnum". */
export type AccountTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AccountTypeEnum */
export type AccountTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "AccountTypeEnum" */
export enum AccountTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "AccountTypeEnum" */
export type AccountTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AccountTypeEnum" */
export type AccountTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AccountTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccountTypeEnum" */
export enum AccountTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type AccountTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: AccountTypeEnum_Bool_Exp;
};

/** Base table for users and tokens */
export type Actor = {
  __typename?: 'Actor';
  client_id: Maybe<Scalars['uuid']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name: Scalars['String'];
  role_id: Maybe<Scalars['uuid']>;
};

export type ActorOrUser = Actor | User;

/** aggregated selection of "Actor" */
export type Actor_Aggregate = {
  __typename?: 'Actor_aggregate';
  aggregate: Maybe<Actor_Aggregate_Fields>;
  nodes: Array<Actor>;
};

/** aggregate fields of "Actor" */
export type Actor_Aggregate_Fields = {
  __typename?: 'Actor_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Actor_Max_Fields>;
  min: Maybe<Actor_Min_Fields>;
};


/** aggregate fields of "Actor" */
export type Actor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Actor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Actor". All fields are combined with a logical 'AND'. */
export type Actor_Bool_Exp = {
  _and?: InputMaybe<Array<Actor_Bool_Exp>>;
  _not?: InputMaybe<Actor_Bool_Exp>;
  _or?: InputMaybe<Array<Actor_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Actor" */
export enum Actor_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActorPkey = 'Actor_pkey'
}

/** input type for inserting data into table "Actor" */
export type Actor_Insert_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Actor_Max_Fields = {
  __typename?: 'Actor_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Actor_Min_Fields = {
  __typename?: 'Actor_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "Actor" */
export type Actor_Mutation_Response = {
  __typename?: 'Actor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Actor>;
};

/** input type for inserting object relation for remote table "Actor" */
export type Actor_Obj_Rel_Insert_Input = {
  data: Actor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Actor_On_Conflict>;
};

/** on_conflict condition type for table "Actor" */
export type Actor_On_Conflict = {
  constraint: Actor_Constraint;
  update_columns?: Array<Actor_Update_Column>;
  where?: InputMaybe<Actor_Bool_Exp>;
};

/** Ordering options when selecting data from "Actor". */
export type Actor_Order_By = {
  client_id?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Actor */
export type Actor_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Actor" */
export enum Actor_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "Actor" */
export type Actor_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "Actor" */
export type Actor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Actor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Actor_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "Actor" */
export enum Actor_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id'
}

export type Actor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Actor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Actor_Bool_Exp;
};

/**
 * credit_grant_id must match an existing creditgrant
 * total should be negative
 */
export type AdHocCreditLineItemInput = {
  credit_grant_id: Scalars['uuid'];
  subtotals_by_time?: InputMaybe<Array<SubtotalInput>>;
  total: Scalars['numeric'];
};

/**
 * AdHoc invoices allow for an invoice to be created off a plan without needing a customerplan to associate the customer.
 * Primary usecase is historical data import.
 * Currently only supports plans.
 */
export type AdHocPlanInvoice = Mri_Invoice & {
  __typename?: 'AdHocPlanInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  credit_type: CreditType;
  customer: Customer;
  exclusive_end_date: Scalars['timestamptz'];
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  inclusive_start_date: Scalars['timestamptz'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  plan: Plan;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


/**
 * AdHoc invoices allow for an invoice to be created off a plan without needing a customerplan to associate the customer.
 * Primary usecase is historical data import.
 * Currently only supports plans.
 */
export type AdHocPlanInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

/**
 * Creates an adhoc invoice.
 *   credit_type_id defaults to USD (cents)
 *   service_period_index defaults to 0
 *   service_period_start_type defaults to FIRST_OF_MONTH
 *   billing_frequency defaults to MONTHLY
 *   breakdown_granularity defaults to DAY
 */
export type AdHocPlanInvoiceInput = {
  billing_frequency?: InputMaybe<Mri_BillingFrequencyEnum>;
  breakdown_granularity?: InputMaybe<EventWindowSize>;
  credit_line_items?: InputMaybe<Array<AdHocCreditLineItemInput>>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_id: Scalars['uuid'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  plan_id: Scalars['uuid'];
  service_period_index?: InputMaybe<Scalars['Int']>;
  service_period_start_type?: InputMaybe<Mri_ServicePeriodStartOnEnum>;
  usage_line_items: Array<AdHocUsageItemInput>;
};

/**
 * product_id must match a product id on the plan (from AdHocPlanInvoiceInput.plan_id)
 * charge_id must match a productpricingfactor id on that product
 */
export type AdHocUsageItemInput = {
  charge_id: Scalars['uuid'];
  product_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  subtotals_by_time?: InputMaybe<Array<SubtotalInput>>;
};

export type AddMarketplaceToCustomerMutationResponse = {
  __typename?: 'AddMarketplaceToCustomerMutationResponse';
  billingProviderCustomer: BillingProviderCustomer;
  customerConfigs: Array<CustomerConfig>;
};

export type AdhocContractUsageInvoice = Mri_Invoice & {
  __typename?: 'AdhocContractUsageInvoice';
  billable_status: BillableStatus;
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  credit_type: CreditType;
  customer: Customer;
  exclusive_end_date: Scalars['timestamptz'];
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  inclusive_start_date: Scalars['timestamptz'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type AdhocContractUsageInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type AdhocContractUsageInvoiceInput = {
  billable_status?: InputMaybe<BillableStatus>;
  breakdown_granularity?: InputMaybe<EventWindowSize>;
  contract_id: Scalars['uuid'];
  credit_type_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  issue_date: Scalars['timestamptz'];
  managed_fields?: InputMaybe<Array<ManagedFieldInput>>;
  usage_line_items: Array<AdhocContractUsageLineItemInput>;
};

export type AdhocContractUsageLineItemInput = {
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  presentation_group_values?: InputMaybe<Array<PresentationGroupValueInput>>;
  pricing_group_values?: InputMaybe<Array<PricingGroupValueInput>>;
  product_id: Scalars['uuid'];
  quantity?: InputMaybe<Scalars['numeric']>;
  subtotals_by_time?: InputMaybe<Array<SubtotalInput>>;
};

export type AdvanceChargeLineItem = Mri_LineItem & {
  __typename?: 'AdvanceChargeLineItem';
  applicable_end_date: Maybe<Scalars['timestamptz']>;
  applicable_start_date: Scalars['timestamptz'];
  credit_type: CreditType;
  display_name: Scalars['String'];
  line_item_chunks: Array<LineItemChunk>;
  one_time_charge: Maybe<OneTimeProductCharge>;
  pricing_factor: ProductPricingFactor;
  quantity: Scalars['numeric'];
  sub_line_items: Maybe<Array<ChargeSubLineItem>>;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Maybe<Scalars['numeric']>;
};

export type AdvanceInvoice = Mri_Invoice & {
  __typename?: 'AdvanceInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  credit_type: CreditType;
  customer: Customer;
  customer_plan: Maybe<CustomerPlan>;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  plan: Plan;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type AdvanceInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

/** columns and relationships of "Alert" */
export type Alert = {
  __typename?: 'Alert';
  /** An object relationship */
  BillableMetric: Maybe<BillableMetric>;
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  CreditType: CreditType;
  /** An object relationship */
  Customer: Maybe<Customer>;
  /** An array relationship */
  CustomerAlerts: Array<CustomerAlert>;
  /** An aggregate relationship */
  CustomerAlerts_aggregate: CustomerAlert_Aggregate;
  /** An object relationship */
  Plan: Maybe<Plan>;
  alert_type: AlertTypeEnum_Enum;
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  credit_type_id: Scalars['uuid'];
  customer_alerts: Array<CustomerAlertComposite>;
  customer_count: Scalars['Int'];
  customer_id: Maybe<Scalars['uuid']>;
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  environment_type: EnvironmentTypeEnum_Enum;
  evaluate_on_create: Maybe<Scalars['Boolean']>;
  filters: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  plan_id: Maybe<Scalars['uuid']>;
  threshold: Scalars['numeric'];
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  webhooks_enabled: Scalars['Boolean'];
};


/** columns and relationships of "Alert" */
export type AlertCustomerAlertsArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


/** columns and relationships of "Alert" */
export type AlertCustomerAlerts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


/** columns and relationships of "Alert" */
export type AlertCustomer_AlertsArgs = {
  include_internal_alerts?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<CustomerAlertsSortingInput>;
};


/** columns and relationships of "Alert" */
export type AlertCustomer_CountArgs = {
  search?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Alert" */
export type AlertFiltersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "AlertManagedField" */
export type AlertManagedField = {
  __typename?: 'AlertManagedField';
  /** An object relationship */
  Alert: Alert;
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  alert_id: Scalars['uuid'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "AlertManagedField" */
export type AlertManagedField_Aggregate = {
  __typename?: 'AlertManagedField_aggregate';
  aggregate: Maybe<AlertManagedField_Aggregate_Fields>;
  nodes: Array<AlertManagedField>;
};

export type AlertManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<AlertManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<AlertManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<AlertManagedField_Aggregate_Bool_Exp_Count>;
};

export type AlertManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AlertManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AlertManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AlertManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type AlertManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AlertManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "AlertManagedField" */
export type AlertManagedField_Aggregate_Fields = {
  __typename?: 'AlertManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<AlertManagedField_Max_Fields>;
  min: Maybe<AlertManagedField_Min_Fields>;
};


/** aggregate fields of "AlertManagedField" */
export type AlertManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AlertManagedField" */
export type AlertManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AlertManagedField_Max_Order_By>;
  min?: InputMaybe<AlertManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "AlertManagedField" */
export type AlertManagedField_Arr_Rel_Insert_Input = {
  data: Array<AlertManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AlertManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "AlertManagedField". All fields are combined with a logical 'AND'. */
export type AlertManagedField_Bool_Exp = {
  Alert?: InputMaybe<Alert_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<AlertManagedField_Bool_Exp>>;
  _not?: InputMaybe<AlertManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<AlertManagedField_Bool_Exp>>;
  alert_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AlertManagedField" */
export enum AlertManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "alert_id" */
  AlertManagedFieldAlertIdKeyIdKey = 'AlertManagedField_alert_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  AlertManagedFieldKeyIdValueEnforceUniquenessClientIdKey = 'AlertManagedField_key_id_value_enforce_uniqueness_client_id_key',
  /** unique or primary key constraint on columns "id" */
  AlertManagedFieldPkey = 'AlertManagedField_pkey'
}

/** input type for inserting data into table "AlertManagedField" */
export type AlertManagedField_Insert_Input = {
  Alert?: InputMaybe<Alert_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AlertManagedField_Max_Fields = {
  __typename?: 'AlertManagedField_max_fields';
  alert_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "AlertManagedField" */
export type AlertManagedField_Max_Order_By = {
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AlertManagedField_Min_Fields = {
  __typename?: 'AlertManagedField_min_fields';
  alert_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "AlertManagedField" */
export type AlertManagedField_Min_Order_By = {
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "AlertManagedField" */
export type AlertManagedField_Mutation_Response = {
  __typename?: 'AlertManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AlertManagedField>;
};

/** on_conflict condition type for table "AlertManagedField" */
export type AlertManagedField_On_Conflict = {
  constraint: AlertManagedField_Constraint;
  update_columns?: Array<AlertManagedField_Update_Column>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "AlertManagedField". */
export type AlertManagedField_Order_By = {
  Alert?: InputMaybe<Alert_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AlertManagedField */
export type AlertManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "AlertManagedField" */
export enum AlertManagedField_Select_Column {
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "AlertManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "AlertManagedField" */
export enum AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "AlertManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "AlertManagedField" */
export enum AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "AlertManagedField" */
export type AlertManagedField_Set_Input = {
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AlertManagedField" */
export type AlertManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertManagedField_Stream_Cursor_Value_Input = {
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AlertManagedField" */
export enum AlertManagedField_Update_Column {
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type AlertManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AlertManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: AlertManagedField_Bool_Exp;
};

export enum AlertStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED'
}

/** columns and relationships of "AlertTypeEnum" */
export type AlertTypeEnum = {
  __typename?: 'AlertTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "AlertTypeEnum" */
export type AlertTypeEnum_Aggregate = {
  __typename?: 'AlertTypeEnum_aggregate';
  aggregate: Maybe<AlertTypeEnum_Aggregate_Fields>;
  nodes: Array<AlertTypeEnum>;
};

/** aggregate fields of "AlertTypeEnum" */
export type AlertTypeEnum_Aggregate_Fields = {
  __typename?: 'AlertTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<AlertTypeEnum_Max_Fields>;
  min: Maybe<AlertTypeEnum_Min_Fields>;
};


/** aggregate fields of "AlertTypeEnum" */
export type AlertTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AlertTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AlertTypeEnum". All fields are combined with a logical 'AND'. */
export type AlertTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<AlertTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<AlertTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<AlertTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AlertTypeEnum" */
export enum AlertTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  AlertTypeEnumPkey = 'AlertTypeEnum_pkey'
}

export enum AlertTypeEnum_Enum {
  InternalLowCreditBalanceReached = 'internal_low_credit_balance_reached',
  InvoiceTotalReached = 'invoice_total_reached',
  LowCreditBalanceReached = 'low_credit_balance_reached',
  LowRemainingCommitBalanceReached = 'low_remaining_commit_balance_reached',
  LowRemainingCommitPercentageReached = 'low_remaining_commit_percentage_reached',
  LowRemainingContractCreditAndCommitBalanceReached = 'low_remaining_contract_credit_and_commit_balance_reached',
  LowRemainingContractCreditBalanceReached = 'low_remaining_contract_credit_balance_reached',
  LowRemainingContractCreditPercentageReached = 'low_remaining_contract_credit_percentage_reached',
  LowRemainingCreditPercentageReached = 'low_remaining_credit_percentage_reached',
  LowRemainingDaysForCommitSegmentReached = 'low_remaining_days_for_commit_segment_reached',
  LowRemainingDaysForContractCreditSegmentReached = 'low_remaining_days_for_contract_credit_segment_reached',
  LowRemainingDaysInPlanReached = 'low_remaining_days_in_plan_reached',
  LowRemainingDaysOfCreditReached = 'low_remaining_days_of_credit_reached',
  MonthlyInvoiceTotalSpendThresholdReached = 'monthly_invoice_total_spend_threshold_reached',
  SpendThresholdReached = 'spend_threshold_reached',
  UsageThresholdReached = 'usage_threshold_reached'
}

/** Boolean expression to compare columns of type "AlertTypeEnum_enum". All fields are combined with logical 'AND'. */
export type AlertTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AlertTypeEnum_Enum>;
  _in?: InputMaybe<Array<AlertTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AlertTypeEnum_Enum>;
  _nin?: InputMaybe<Array<AlertTypeEnum_Enum>>;
};

/** input type for inserting data into table "AlertTypeEnum" */
export type AlertTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AlertTypeEnum_Max_Fields = {
  __typename?: 'AlertTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AlertTypeEnum_Min_Fields = {
  __typename?: 'AlertTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AlertTypeEnum" */
export type AlertTypeEnum_Mutation_Response = {
  __typename?: 'AlertTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AlertTypeEnum>;
};

/** on_conflict condition type for table "AlertTypeEnum" */
export type AlertTypeEnum_On_Conflict = {
  constraint: AlertTypeEnum_Constraint;
  update_columns?: Array<AlertTypeEnum_Update_Column>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "AlertTypeEnum". */
export type AlertTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AlertTypeEnum */
export type AlertTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "AlertTypeEnum" */
export enum AlertTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "AlertTypeEnum" */
export type AlertTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "AlertTypeEnum" */
export type AlertTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AlertTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AlertTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AlertTypeEnum" */
export enum AlertTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type AlertTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AlertTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: AlertTypeEnum_Bool_Exp;
};

/** aggregated selection of "Alert" */
export type Alert_Aggregate = {
  __typename?: 'Alert_aggregate';
  aggregate: Maybe<Alert_Aggregate_Fields>;
  nodes: Array<Alert>;
};

export type Alert_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Alert_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Alert_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Alert_Aggregate_Bool_Exp_Count>;
};

export type Alert_Aggregate_Bool_Exp_Bool_And = {
  arguments: Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Alert_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Alert_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Alert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Alert" */
export type Alert_Aggregate_Fields = {
  __typename?: 'Alert_aggregate_fields';
  avg: Maybe<Alert_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Alert_Max_Fields>;
  min: Maybe<Alert_Min_Fields>;
  stddev: Maybe<Alert_Stddev_Fields>;
  stddev_pop: Maybe<Alert_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Alert_Stddev_Samp_Fields>;
  sum: Maybe<Alert_Sum_Fields>;
  var_pop: Maybe<Alert_Var_Pop_Fields>;
  var_samp: Maybe<Alert_Var_Samp_Fields>;
  variance: Maybe<Alert_Variance_Fields>;
};


/** aggregate fields of "Alert" */
export type Alert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Alert" */
export type Alert_Aggregate_Order_By = {
  avg?: InputMaybe<Alert_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Alert_Max_Order_By>;
  min?: InputMaybe<Alert_Min_Order_By>;
  stddev?: InputMaybe<Alert_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Alert_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Alert_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Alert_Sum_Order_By>;
  var_pop?: InputMaybe<Alert_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Alert_Var_Samp_Order_By>;
  variance?: InputMaybe<Alert_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Alert_Append_Input = {
  filters?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Alert" */
export type Alert_Arr_Rel_Insert_Input = {
  data: Array<Alert_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};

/** aggregate avg on columns */
export type Alert_Avg_Fields = {
  __typename?: 'Alert_avg_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Alert" */
export type Alert_Avg_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Alert". All fields are combined with a logical 'AND'. */
export type Alert_Bool_Exp = {
  BillableMetric?: InputMaybe<BillableMetric_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CreditType?: InputMaybe<CreditType_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  CustomerAlerts?: InputMaybe<CustomerAlert_Bool_Exp>;
  CustomerAlerts_aggregate?: InputMaybe<CustomerAlert_Aggregate_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  _and?: InputMaybe<Array<Alert_Bool_Exp>>;
  _not?: InputMaybe<Alert_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Bool_Exp>>;
  alert_type?: InputMaybe<AlertTypeEnum_Enum_Comparison_Exp>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  archived_by?: InputMaybe<String_Comparison_Exp>;
  billable_metric_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  disabled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled_by?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  evaluate_on_create?: InputMaybe<Boolean_Comparison_Exp>;
  filters?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  threshold?: InputMaybe<Numeric_Comparison_Exp>;
  uniqueness_key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  webhooks_enabled?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "Alert" */
export enum Alert_Constraint {
  /** unique or primary key constraint on columns "id" */
  AlertPkey = 'Alert_pkey',
  /** unique or primary key constraint on columns "environment_type", "uniqueness_key", "client_id" */
  AlertClientIdUniquenessKeyUnique = 'alert_client_id_uniqueness_key_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Alert_Delete_At_Path_Input = {
  filters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Alert_Delete_Elem_Input = {
  filters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Alert_Delete_Key_Input = {
  filters?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Alert" */
export type Alert_Inc_Input = {
  threshold?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Alert" */
export type Alert_Insert_Input = {
  BillableMetric?: InputMaybe<BillableMetric_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  CustomerAlerts?: InputMaybe<CustomerAlert_Arr_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  alert_type?: InputMaybe<AlertTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  evaluate_on_create?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  threshold?: InputMaybe<Scalars['numeric']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhooks_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Alert_Max_Fields = {
  __typename?: 'Alert_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  customer_id: Maybe<Scalars['uuid']>;
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  threshold: Maybe<Scalars['numeric']>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Alert" */
export type Alert_Max_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  archived_by?: InputMaybe<Order_By>;
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  disabled_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  threshold?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Alert_Min_Fields = {
  __typename?: 'Alert_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  customer_id: Maybe<Scalars['uuid']>;
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  threshold: Maybe<Scalars['numeric']>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Alert" */
export type Alert_Min_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  archived_by?: InputMaybe<Order_By>;
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  disabled_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  threshold?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Alert" */
export type Alert_Mutation_Response = {
  __typename?: 'Alert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert>;
};

/** input type for inserting object relation for remote table "Alert" */
export type Alert_Obj_Rel_Insert_Input = {
  data: Alert_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};

/** on_conflict condition type for table "Alert" */
export type Alert_On_Conflict = {
  constraint: Alert_Constraint;
  update_columns?: Array<Alert_Update_Column>;
  where?: InputMaybe<Alert_Bool_Exp>;
};

/** Ordering options when selecting data from "Alert". */
export type Alert_Order_By = {
  BillableMetric?: InputMaybe<BillableMetric_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  CreditType?: InputMaybe<CreditType_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  CustomerAlerts_aggregate?: InputMaybe<CustomerAlert_Aggregate_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  alert_type?: InputMaybe<Order_By>;
  archived_at?: InputMaybe<Order_By>;
  archived_by?: InputMaybe<Order_By>;
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  disabled_by?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  evaluate_on_create?: InputMaybe<Order_By>;
  filters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  threshold?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  webhooks_enabled?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Alert */
export type Alert_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Alert_Prepend_Input = {
  filters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Alert" */
export enum Alert_Select_Column {
  /** column name */
  AlertType = 'alert_type',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ArchivedBy = 'archived_by',
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledBy = 'disabled_by',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  EvaluateOnCreate = 'evaluate_on_create',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Threshold = 'threshold',
  /** column name */
  UniquenessKey = 'uniqueness_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WebhooksEnabled = 'webhooks_enabled'
}

/** select "Alert_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Alert" */
export enum Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EvaluateOnCreate = 'evaluate_on_create',
  /** column name */
  WebhooksEnabled = 'webhooks_enabled'
}

/** select "Alert_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Alert" */
export enum Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EvaluateOnCreate = 'evaluate_on_create',
  /** column name */
  WebhooksEnabled = 'webhooks_enabled'
}

/** input type for updating data in table "Alert" */
export type Alert_Set_Input = {
  alert_type?: InputMaybe<AlertTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  evaluate_on_create?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  threshold?: InputMaybe<Scalars['numeric']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhooks_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Alert_Stddev_Fields = {
  __typename?: 'Alert_stddev_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Alert" */
export type Alert_Stddev_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Alert_Stddev_Pop_Fields = {
  __typename?: 'Alert_stddev_pop_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Alert" */
export type Alert_Stddev_Pop_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Alert_Stddev_Samp_Fields = {
  __typename?: 'Alert_stddev_samp_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Alert" */
export type Alert_Stddev_Samp_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Alert" */
export type Alert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Stream_Cursor_Value_Input = {
  alert_type?: InputMaybe<AlertTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  evaluate_on_create?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  threshold?: InputMaybe<Scalars['numeric']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhooks_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Alert_Sum_Fields = {
  __typename?: 'Alert_sum_fields';
  threshold: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Alert" */
export type Alert_Sum_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** update columns of table "Alert" */
export enum Alert_Update_Column {
  /** column name */
  AlertType = 'alert_type',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ArchivedBy = 'archived_by',
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledBy = 'disabled_by',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  EvaluateOnCreate = 'evaluate_on_create',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Threshold = 'threshold',
  /** column name */
  UniquenessKey = 'uniqueness_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WebhooksEnabled = 'webhooks_enabled'
}

export type Alert_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Alert_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Alert_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Alert_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Alert_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Alert_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Alert_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Alert_Var_Pop_Fields = {
  __typename?: 'Alert_var_pop_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Alert" */
export type Alert_Var_Pop_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Alert_Var_Samp_Fields = {
  __typename?: 'Alert_var_samp_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Alert" */
export type Alert_Var_Samp_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Alert_Variance_Fields = {
  __typename?: 'Alert_variance_fields';
  threshold: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Alert" */
export type Alert_Variance_Order_By = {
  threshold?: InputMaybe<Order_By>;
};

export enum AmountDistributionType {
  Divided = 'DIVIDED',
  DividedRounded = 'DIVIDED_ROUNDED',
  Each = 'EACH'
}

export type ArchiveContractMutationResponse = {
  __typename?: 'ArchiveContractMutationResponse';
  archived_at: Maybe<Scalars['timestamptz']>;
  contract_id: Maybe<Scalars['uuid']>;
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ArchiveProductListItemMutationResponse = {
  __typename?: 'ArchiveProductListItemMutationResponse';
  archived_at: Maybe<Scalars['timestamptz']>;
  error: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['uuid']>;
  success: Scalars['Boolean'];
};

export type ArchiveScheduledWebhookNotificationsCustomerFilter = {
  customer_id: Scalars['uuid'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  notification_type: Scalars['String'];
  starting_on?: InputMaybe<Scalars['timestamptz']>;
};

export enum ArchivedFilter {
  Archived = 'ARCHIVED',
  Both = 'BOTH',
  NotArchived = 'NOT_ARCHIVED'
}

export type ArrearsInvoice = Mri_Invoice & {
  __typename?: 'ArrearsInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  corrections: Array<CorrectionInvoice>;
  credit_type: CreditType;
  customer: Customer;
  customer_plan: Maybe<CustomerPlan>;
  exclusive_end_date: Scalars['timestamptz'];
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  inclusive_start_date: Scalars['timestamptz'];
  is_trial: Scalars['Boolean'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  plan: Plan;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ArrearsInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type AuditEntry = {
  as: Scalars['String'];
  key: Scalars['String'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action: Scalars['String'];
  actor: ActorOrUser;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  parameters: Scalars['JSONObject'];
  request_id: Scalars['uuid'];
  request_timestamp: Scalars['timestamptz'];
  resource_id: Scalars['String'];
  resource_type: Scalars['String'];
  status: AuditLogStatusEnum;
  timestamp: Scalars['timestamptz'];
};

export type AuditLogResponse = {
  __typename?: 'AuditLogResponse';
  data: Array<AuditLog>;
  next_cursor: Maybe<Scalars['String']>;
};

export enum AuditLogStatusEnum {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type AuthCheckResponse = {
  __typename?: 'AuthCheckResponse';
  allowed: Scalars['Boolean'];
};

export enum AuthCheckType {
  Mutation = 'MUTATION',
  Query = 'QUERY'
}

export type BaseCommit = {
  applicable_contracts: Maybe<Array<Contract>>;
  applicable_products: Maybe<Array<ProductListItem>>;
  applicable_tags: Maybe<Array<Scalars['String']>>;
  contract: Maybe<Contract>;
  customer: Customer;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  managed_fields: Array<CommitOrCreditManagedField>;
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  priority: Scalars['numeric'];
  product: ProductListItem;
  rollover_fraction: Maybe<Scalars['numeric']>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
};

export type BaseRateScheduleSegment = {
  ending_before: Maybe<Scalars['timestamptz']>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type BaseUsageFilter = {
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  group_key: Scalars['String'];
  group_values: Array<Scalars['String']>;
  id: Scalars['String'];
};

/** columns and relationships of "BillableMetric" */
export type BillableMetric = {
  __typename?: 'BillableMetric';
  /** An array relationship */
  Alerts: Array<Alert>;
  /** An aggregate relationship */
  Alerts_aggregate: Alert_Aggregate;
  /** An object relationship */
  Creator: Maybe<Actor>;
  active_plan_count: Scalars['Int'];
  active_product_count: Scalars['Int'];
  aggregate: BillingMetricAggregateEnum_Enum;
  aggregate_keys: Maybe<Scalars['jsonb']>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  deleted_at: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  fancy_plan: Maybe<Scalars['json']>;
  filter: Scalars['jsonb'];
  group_keys: Maybe<Scalars['jsonb']>;
  grouped_usage: GroupedUsage;
  id: Scalars['uuid'];
  is_draft: Scalars['Boolean'];
  managed_fields: Array<BillableMetricManagedField>;
  name: Scalars['String'];
  paged_usage: PagedUsage;
  products: Array<Product>;
  sql: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  usage: Array<UsageWindow>;
  usage_breakdown: UsageBreakdownResponse;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricAlertsArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricAlerts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricAggregate_KeysArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricFancy_PlanArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricFilterArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricGroup_KeysArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricGrouped_UsageArgs = {
  customer_id: Scalars['uuid'];
  ending_before: Scalars['timestamptz'];
  groupings?: InputMaybe<Array<UsageGroupByInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  next_page?: InputMaybe<Scalars['String']>;
  starting_on: Scalars['timestamptz'];
  window_size: WindowSize;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricPaged_UsageArgs = {
  customer_id: Scalars['uuid'];
  ending_before: Scalars['timestamptz'];
  group_key?: InputMaybe<Scalars['String']>;
  group_values?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  next_page?: InputMaybe<Scalars['String']>;
  starting_on: Scalars['timestamptz'];
  window_size: WindowSize;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricProductsArgs = {
  types: Array<ProductTypeEnum>;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricUsageArgs = {
  customer_id: Scalars['uuid'];
  ending_before: Scalars['timestamptz'];
  group_by?: InputMaybe<UsageGroupByInput>;
  starting_after: Scalars['timestamptz'];
  window_size: WindowSize;
};


/** columns and relationships of "BillableMetric" */
export type BillableMetricUsage_BreakdownArgs = {
  customer_id: Scalars['uuid'];
  ending_before: Scalars['timestamptz'];
  groups?: InputMaybe<Scalars['RecordOfNullableStringArrays']>;
  limit?: InputMaybe<Scalars['Int']>;
  next_page?: InputMaybe<Scalars['String']>;
  starting_on: Scalars['timestamptz'];
  window_size: EventWindowSize;
};

export enum BillableMetricGroupKeyFilterEnum {
  Empty = 'EMPTY',
  NonEmpty = 'NON_EMPTY'
}

export type BillableMetricInput = {
  aggregate: BillingMetricAggregateEnum_Enum;
  aggregate_key?: InputMaybe<Scalars['String']>;
  composite_group_keys?: InputMaybe<Array<Array<Scalars['String']>>>;
  custom_fields?: InputMaybe<Scalars['JSONObject']>;
  dangerously_override_id?: InputMaybe<Scalars['uuid']>;
  filter: Scalars['JSONObject'];
  group_keys?: InputMaybe<Array<Scalars['String']>>;
  is_draft: Scalars['Boolean'];
  name: Scalars['String'];
};

/** columns and relationships of "BillableMetricManagedField" */
export type BillableMetricManagedField = {
  __typename?: 'BillableMetricManagedField';
  /** An object relationship */
  BillableMetric: BillableMetric;
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  billable_metric_id: Scalars['uuid'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "BillableMetricManagedField" */
export type BillableMetricManagedField_Aggregate = {
  __typename?: 'BillableMetricManagedField_aggregate';
  aggregate: Maybe<BillableMetricManagedField_Aggregate_Fields>;
  nodes: Array<BillableMetricManagedField>;
};

/** aggregate fields of "BillableMetricManagedField" */
export type BillableMetricManagedField_Aggregate_Fields = {
  __typename?: 'BillableMetricManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillableMetricManagedField_Max_Fields>;
  min: Maybe<BillableMetricManagedField_Min_Fields>;
};


/** aggregate fields of "BillableMetricManagedField" */
export type BillableMetricManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillableMetricManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "BillableMetricManagedField". All fields are combined with a logical 'AND'. */
export type BillableMetricManagedField_Bool_Exp = {
  BillableMetric?: InputMaybe<BillableMetric_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<BillableMetricManagedField_Bool_Exp>>;
  _not?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<BillableMetricManagedField_Bool_Exp>>;
  billable_metric_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillableMetricManagedField" */
export enum BillableMetricManagedField_Constraint {
  /** unique or primary key constraint on columns "billable_metric_id", "key_id" */
  BillableMetricManagedFieldBillableMetricIdKeyIdKey = 'BillableMetricManagedField_billable_metric_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  BillableMetricManagedFieldKeyIdValueEnforceUniquenessKey = 'BillableMetricManagedField_key_id_value_enforce_uniqueness__key',
  /** unique or primary key constraint on columns "id" */
  BillableMetricManagedFieldPkey = 'BillableMetricManagedField_pkey'
}

/** input type for inserting data into table "BillableMetricManagedField" */
export type BillableMetricManagedField_Insert_Input = {
  BillableMetric?: InputMaybe<BillableMetric_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BillableMetricManagedField_Max_Fields = {
  __typename?: 'BillableMetricManagedField_max_fields';
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BillableMetricManagedField_Min_Fields = {
  __typename?: 'BillableMetricManagedField_min_fields';
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BillableMetricManagedField" */
export type BillableMetricManagedField_Mutation_Response = {
  __typename?: 'BillableMetricManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillableMetricManagedField>;
};

/** on_conflict condition type for table "BillableMetricManagedField" */
export type BillableMetricManagedField_On_Conflict = {
  constraint: BillableMetricManagedField_Constraint;
  update_columns?: Array<BillableMetricManagedField_Update_Column>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "BillableMetricManagedField". */
export type BillableMetricManagedField_Order_By = {
  BillableMetric?: InputMaybe<BillableMetric_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillableMetricManagedField */
export type BillableMetricManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "BillableMetricManagedField" */
export enum BillableMetricManagedField_Select_Column {
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "BillableMetricManagedField" */
export type BillableMetricManagedField_Set_Input = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "BillableMetricManagedField" */
export type BillableMetricManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillableMetricManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillableMetricManagedField_Stream_Cursor_Value_Input = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "BillableMetricManagedField" */
export enum BillableMetricManagedField_Update_Column {
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type BillableMetricManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillableMetricManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillableMetricManagedField_Bool_Exp;
};

export type BillableMetricSortingInput = {
  ascending: Scalars['Boolean'];
  order_by: BillableMetricSortingOrderByEnum;
};

export enum BillableMetricSortingOrderByEnum {
  Aggregate = 'AGGREGATE',
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  Id = 'ID',
  Name = 'NAME'
}

export enum BillableMetricTypeEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT'
}

/** aggregated selection of "BillableMetric" */
export type BillableMetric_Aggregate = {
  __typename?: 'BillableMetric_aggregate';
  aggregate: Maybe<BillableMetric_Aggregate_Fields>;
  nodes: Array<BillableMetric>;
};

export type BillableMetric_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<BillableMetric_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<BillableMetric_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<BillableMetric_Aggregate_Bool_Exp_Count>;
};

export type BillableMetric_Aggregate_Bool_Exp_Bool_And = {
  arguments: BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillableMetric_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BillableMetric_Aggregate_Bool_Exp_Bool_Or = {
  arguments: BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillableMetric_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type BillableMetric_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BillableMetric_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillableMetric_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BillableMetric" */
export type BillableMetric_Aggregate_Fields = {
  __typename?: 'BillableMetric_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillableMetric_Max_Fields>;
  min: Maybe<BillableMetric_Min_Fields>;
};


/** aggregate fields of "BillableMetric" */
export type BillableMetric_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillableMetric_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BillableMetric" */
export type BillableMetric_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BillableMetric_Max_Order_By>;
  min?: InputMaybe<BillableMetric_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BillableMetric_Append_Input = {
  aggregate_keys?: InputMaybe<Scalars['jsonb']>;
  filter?: InputMaybe<Scalars['jsonb']>;
  group_keys?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "BillableMetric" */
export type BillableMetric_Arr_Rel_Insert_Input = {
  data: Array<BillableMetric_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BillableMetric_On_Conflict>;
};

/** Boolean expression to filter rows from the table "BillableMetric". All fields are combined with a logical 'AND'. */
export type BillableMetric_Bool_Exp = {
  Alerts?: InputMaybe<Alert_Bool_Exp>;
  Alerts_aggregate?: InputMaybe<Alert_Aggregate_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<BillableMetric_Bool_Exp>>;
  _not?: InputMaybe<BillableMetric_Bool_Exp>;
  _or?: InputMaybe<Array<BillableMetric_Bool_Exp>>;
  aggregate?: InputMaybe<BillingMetricAggregateEnum_Enum_Comparison_Exp>;
  aggregate_keys?: InputMaybe<Jsonb_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  fancy_plan?: InputMaybe<Json_Comparison_Exp>;
  filter?: InputMaybe<Jsonb_Comparison_Exp>;
  group_keys?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_draft?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sql?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillableMetric" */
export enum BillableMetric_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillableMetricPkey = 'BillableMetric_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BillableMetric_Delete_At_Path_Input = {
  aggregate_keys?: InputMaybe<Array<Scalars['String']>>;
  filter?: InputMaybe<Array<Scalars['String']>>;
  group_keys?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type BillableMetric_Delete_Elem_Input = {
  aggregate_keys?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Scalars['Int']>;
  group_keys?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BillableMetric_Delete_Key_Input = {
  aggregate_keys?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  group_keys?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "BillableMetric" */
export type BillableMetric_Insert_Input = {
  Alerts?: InputMaybe<Alert_Arr_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  aggregate?: InputMaybe<BillingMetricAggregateEnum_Enum>;
  aggregate_keys?: InputMaybe<Scalars['jsonb']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  fancy_plan?: InputMaybe<Scalars['json']>;
  filter?: InputMaybe<Scalars['jsonb']>;
  group_keys?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sql?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BillableMetric_Max_Fields = {
  __typename?: 'BillableMetric_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  deleted_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  sql: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "BillableMetric" */
export type BillableMetric_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sql?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BillableMetric_Min_Fields = {
  __typename?: 'BillableMetric_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  deleted_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  sql: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "BillableMetric" */
export type BillableMetric_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sql?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BillableMetric" */
export type BillableMetric_Mutation_Response = {
  __typename?: 'BillableMetric_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillableMetric>;
};

/** input type for inserting object relation for remote table "BillableMetric" */
export type BillableMetric_Obj_Rel_Insert_Input = {
  data: BillableMetric_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<BillableMetric_On_Conflict>;
};

/** on_conflict condition type for table "BillableMetric" */
export type BillableMetric_On_Conflict = {
  constraint: BillableMetric_Constraint;
  update_columns?: Array<BillableMetric_Update_Column>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};

/** Ordering options when selecting data from "BillableMetric". */
export type BillableMetric_Order_By = {
  Alerts_aggregate?: InputMaybe<Alert_Aggregate_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  aggregate?: InputMaybe<Order_By>;
  aggregate_keys?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  fancy_plan?: InputMaybe<Order_By>;
  filter?: InputMaybe<Order_By>;
  group_keys?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_draft?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sql?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillableMetric */
export type BillableMetric_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BillableMetric_Prepend_Input = {
  aggregate_keys?: InputMaybe<Scalars['jsonb']>;
  filter?: InputMaybe<Scalars['jsonb']>;
  group_keys?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "BillableMetric" */
export enum BillableMetric_Select_Column {
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  AggregateKeys = 'aggregate_keys',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  FancyPlan = 'fancy_plan',
  /** column name */
  Filter = 'filter',
  /** column name */
  GroupKeys = 'group_keys',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  Name = 'name',
  /** column name */
  Sql = 'sql',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "BillableMetric_aggregate_bool_exp_bool_and_arguments_columns" columns of table "BillableMetric" */
export enum BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDraft = 'is_draft'
}

/** select "BillableMetric_aggregate_bool_exp_bool_or_arguments_columns" columns of table "BillableMetric" */
export enum BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDraft = 'is_draft'
}

/** input type for updating data in table "BillableMetric" */
export type BillableMetric_Set_Input = {
  aggregate?: InputMaybe<BillingMetricAggregateEnum_Enum>;
  aggregate_keys?: InputMaybe<Scalars['jsonb']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  fancy_plan?: InputMaybe<Scalars['json']>;
  filter?: InputMaybe<Scalars['jsonb']>;
  group_keys?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sql?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "BillableMetric" */
export type BillableMetric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillableMetric_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillableMetric_Stream_Cursor_Value_Input = {
  aggregate?: InputMaybe<BillingMetricAggregateEnum_Enum>;
  aggregate_keys?: InputMaybe<Scalars['jsonb']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  fancy_plan?: InputMaybe<Scalars['json']>;
  filter?: InputMaybe<Scalars['jsonb']>;
  group_keys?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sql?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "BillableMetric" */
export enum BillableMetric_Update_Column {
  /** column name */
  Aggregate = 'aggregate',
  /** column name */
  AggregateKeys = 'aggregate_keys',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  FancyPlan = 'fancy_plan',
  /** column name */
  Filter = 'filter',
  /** column name */
  GroupKeys = 'group_keys',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  Name = 'name',
  /** column name */
  Sql = 'sql',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BillableMetric_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BillableMetric_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<BillableMetric_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<BillableMetric_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<BillableMetric_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BillableMetric_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillableMetric_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillableMetric_Bool_Exp;
};

export enum BillableStatus {
  Billable = 'BILLABLE',
  Unbillable = 'UNBILLABLE'
}

export type BillableStatusFilter = {
  exclude?: InputMaybe<Array<BillableStatus>>;
  include?: InputMaybe<Array<BillableStatus>>;
};

export type BillableStatusWithEffectiveDate = {
  __typename?: 'BillableStatusWithEffectiveDate';
  billable_status: BillableStatus;
  effective_at: Maybe<Scalars['timestamptz']>;
};

export type BilledInvoiceTotals = {
  __typename?: 'BilledInvoiceTotals';
  num_invoices: Scalars['numeric'];
  total_usd: Scalars['numeric'];
};

/** columns and relationships of "BillingFrequencyEnum" */
export type BillingFrequencyEnum = {
  __typename?: 'BillingFrequencyEnum';
  value: Scalars['String'];
};

/** aggregated selection of "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Aggregate = {
  __typename?: 'BillingFrequencyEnum_aggregate';
  aggregate: Maybe<BillingFrequencyEnum_Aggregate_Fields>;
  nodes: Array<BillingFrequencyEnum>;
};

/** aggregate fields of "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Aggregate_Fields = {
  __typename?: 'BillingFrequencyEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingFrequencyEnum_Max_Fields>;
  min: Maybe<BillingFrequencyEnum_Min_Fields>;
};


/** aggregate fields of "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingFrequencyEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "BillingFrequencyEnum". All fields are combined with a logical 'AND'. */
export type BillingFrequencyEnum_Bool_Exp = {
  _and?: InputMaybe<Array<BillingFrequencyEnum_Bool_Exp>>;
  _not?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
  _or?: InputMaybe<Array<BillingFrequencyEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingFrequencyEnum" */
export enum BillingFrequencyEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingFrequencyEnumPkey = 'BillingFrequencyEnum_pkey'
}

export enum BillingFrequencyEnum_Enum {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL',
  SemiMonthly = 'SEMI_MONTHLY'
}

/** Boolean expression to compare columns of type "BillingFrequencyEnum_enum". All fields are combined with logical 'AND'. */
export type BillingFrequencyEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<BillingFrequencyEnum_Enum>;
  _in?: InputMaybe<Array<BillingFrequencyEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BillingFrequencyEnum_Enum>;
  _nin?: InputMaybe<Array<BillingFrequencyEnum_Enum>>;
};

/** input type for inserting data into table "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BillingFrequencyEnum_Max_Fields = {
  __typename?: 'BillingFrequencyEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BillingFrequencyEnum_Min_Fields = {
  __typename?: 'BillingFrequencyEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Mutation_Response = {
  __typename?: 'BillingFrequencyEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingFrequencyEnum>;
};

/** on_conflict condition type for table "BillingFrequencyEnum" */
export type BillingFrequencyEnum_On_Conflict = {
  constraint: BillingFrequencyEnum_Constraint;
  update_columns?: Array<BillingFrequencyEnum_Update_Column>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingFrequencyEnum". */
export type BillingFrequencyEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingFrequencyEnum */
export type BillingFrequencyEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "BillingFrequencyEnum" */
export enum BillingFrequencyEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "BillingFrequencyEnum" */
export type BillingFrequencyEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingFrequencyEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingFrequencyEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "BillingFrequencyEnum" */
export enum BillingFrequencyEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type BillingFrequencyEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingFrequencyEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingFrequencyEnum_Bool_Exp;
};

/** Valid values for Billable Metric aggregates */
export type BillingMetricAggregateEnum = {
  __typename?: 'BillingMetricAggregateEnum';
  value: Scalars['String'];
};

/** aggregated selection of "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Aggregate = {
  __typename?: 'BillingMetricAggregateEnum_aggregate';
  aggregate: Maybe<BillingMetricAggregateEnum_Aggregate_Fields>;
  nodes: Array<BillingMetricAggregateEnum>;
};

/** aggregate fields of "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Aggregate_Fields = {
  __typename?: 'BillingMetricAggregateEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingMetricAggregateEnum_Max_Fields>;
  min: Maybe<BillingMetricAggregateEnum_Min_Fields>;
};


/** aggregate fields of "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingMetricAggregateEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "BillingMetricAggregateEnum". All fields are combined with a logical 'AND'. */
export type BillingMetricAggregateEnum_Bool_Exp = {
  _and?: InputMaybe<Array<BillingMetricAggregateEnum_Bool_Exp>>;
  _not?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
  _or?: InputMaybe<Array<BillingMetricAggregateEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingMetricAggregateEnum" */
export enum BillingMetricAggregateEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingMetricAggregateEnumPkey = 'BillingMetricAggregateEnum_pkey'
}

export enum BillingMetricAggregateEnum_Enum {
  Count = 'count',
  Latest = 'latest',
  Max = 'max',
  Sum = 'sum',
  Unique = 'unique'
}

/** Boolean expression to compare columns of type "BillingMetricAggregateEnum_enum". All fields are combined with logical 'AND'. */
export type BillingMetricAggregateEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<BillingMetricAggregateEnum_Enum>;
  _in?: InputMaybe<Array<BillingMetricAggregateEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BillingMetricAggregateEnum_Enum>;
  _nin?: InputMaybe<Array<BillingMetricAggregateEnum_Enum>>;
};

/** input type for inserting data into table "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BillingMetricAggregateEnum_Max_Fields = {
  __typename?: 'BillingMetricAggregateEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BillingMetricAggregateEnum_Min_Fields = {
  __typename?: 'BillingMetricAggregateEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Mutation_Response = {
  __typename?: 'BillingMetricAggregateEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingMetricAggregateEnum>;
};

/** on_conflict condition type for table "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_On_Conflict = {
  constraint: BillingMetricAggregateEnum_Constraint;
  update_columns?: Array<BillingMetricAggregateEnum_Update_Column>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingMetricAggregateEnum". */
export type BillingMetricAggregateEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingMetricAggregateEnum */
export type BillingMetricAggregateEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "BillingMetricAggregateEnum" */
export enum BillingMetricAggregateEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "BillingMetricAggregateEnum" */
export type BillingMetricAggregateEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingMetricAggregateEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingMetricAggregateEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "BillingMetricAggregateEnum" */
export enum BillingMetricAggregateEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type BillingMetricAggregateEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingMetricAggregateEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingMetricAggregateEnum_Bool_Exp;
};

export type BillingProviderConfigurationInput = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_configuration_id?: InputMaybe<Scalars['uuid']>;
  delivery_method?: InputMaybe<BillingProviderDeliveryMethodEnum_Enum>;
};

/** columns and relationships of "BillingProviderCustomer" */
export type BillingProviderCustomer = {
  __typename?: 'BillingProviderCustomer';
  /** An object relationship */
  BillingProviderEnum: BillingProviderEnum;
  /** An object relationship */
  Customer: Customer;
  billing_provider: BillingProviderEnum_Enum;
  billing_provider_customer_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

export type BillingProviderCustomerInput = {
  billing_provider: BillingProviderEnum_Enum;
  billing_provider_customer_id: Scalars['String'];
};

/** aggregated selection of "BillingProviderCustomer" */
export type BillingProviderCustomer_Aggregate = {
  __typename?: 'BillingProviderCustomer_aggregate';
  aggregate: Maybe<BillingProviderCustomer_Aggregate_Fields>;
  nodes: Array<BillingProviderCustomer>;
};

export type BillingProviderCustomer_Aggregate_Bool_Exp = {
  count?: InputMaybe<BillingProviderCustomer_Aggregate_Bool_Exp_Count>;
};

export type BillingProviderCustomer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BillingProviderCustomer" */
export type BillingProviderCustomer_Aggregate_Fields = {
  __typename?: 'BillingProviderCustomer_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingProviderCustomer_Max_Fields>;
  min: Maybe<BillingProviderCustomer_Min_Fields>;
};


/** aggregate fields of "BillingProviderCustomer" */
export type BillingProviderCustomer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BillingProviderCustomer" */
export type BillingProviderCustomer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BillingProviderCustomer_Max_Order_By>;
  min?: InputMaybe<BillingProviderCustomer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "BillingProviderCustomer" */
export type BillingProviderCustomer_Arr_Rel_Insert_Input = {
  data: Array<BillingProviderCustomer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BillingProviderCustomer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "BillingProviderCustomer". All fields are combined with a logical 'AND'. */
export type BillingProviderCustomer_Bool_Exp = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  _and?: InputMaybe<Array<BillingProviderCustomer_Bool_Exp>>;
  _not?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
  _or?: InputMaybe<Array<BillingProviderCustomer_Bool_Exp>>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  billing_provider_customer_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingProviderCustomer" */
export enum BillingProviderCustomer_Constraint {
  /** unique or primary key constraint on columns "customer_id" */
  BillingProviderCustomerCustomerIdKey = 'BillingProviderCustomer_customer_id_key',
  /** unique or primary key constraint on columns "customer_id", "billing_provider" */
  BillingProviderCustomerPkey = 'BillingProviderCustomer_pkey'
}

/** input type for inserting data into table "BillingProviderCustomer" */
export type BillingProviderCustomer_Insert_Input = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_customer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BillingProviderCustomer_Max_Fields = {
  __typename?: 'BillingProviderCustomer_max_fields';
  billing_provider_customer_id: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "BillingProviderCustomer" */
export type BillingProviderCustomer_Max_Order_By = {
  billing_provider_customer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BillingProviderCustomer_Min_Fields = {
  __typename?: 'BillingProviderCustomer_min_fields';
  billing_provider_customer_id: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "BillingProviderCustomer" */
export type BillingProviderCustomer_Min_Order_By = {
  billing_provider_customer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BillingProviderCustomer" */
export type BillingProviderCustomer_Mutation_Response = {
  __typename?: 'BillingProviderCustomer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingProviderCustomer>;
};

/** on_conflict condition type for table "BillingProviderCustomer" */
export type BillingProviderCustomer_On_Conflict = {
  constraint: BillingProviderCustomer_Constraint;
  update_columns?: Array<BillingProviderCustomer_Update_Column>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingProviderCustomer". */
export type BillingProviderCustomer_Order_By = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  billing_provider?: InputMaybe<Order_By>;
  billing_provider_customer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingProviderCustomer */
export type BillingProviderCustomer_Pk_Columns_Input = {
  billing_provider: BillingProviderEnum_Enum;
  customer_id: Scalars['uuid'];
};

/** select columns of table "BillingProviderCustomer" */
export enum BillingProviderCustomer_Select_Column {
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  BillingProviderCustomerId = 'billing_provider_customer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "BillingProviderCustomer" */
export type BillingProviderCustomer_Set_Input = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_customer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "BillingProviderCustomer" */
export type BillingProviderCustomer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingProviderCustomer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingProviderCustomer_Stream_Cursor_Value_Input = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_customer_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "BillingProviderCustomer" */
export enum BillingProviderCustomer_Update_Column {
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  BillingProviderCustomerId = 'billing_provider_customer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BillingProviderCustomer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingProviderCustomer_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingProviderCustomer_Bool_Exp;
};

export type BillingProviderDeliveryMethod = {
  __typename?: 'BillingProviderDeliveryMethod';
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  delivery_method: BillingProviderDeliveryMethod_Enum;
  delivery_method_configuration: Scalars['FlatKeyValueMap'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
};

export enum BillingProviderDeliveryMethodEnum_Enum {
  AwsSns = 'AWS_SNS',
  AwsSqs = 'AWS_SQS',
  DirectToBillingProvider = 'DIRECT_TO_BILLING_PROVIDER'
}

export type BillingProviderDeliveryMethodWithoutConfiguration = {
  __typename?: 'BillingProviderDeliveryMethodWithoutConfiguration';
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  delivery_method: BillingProviderDeliveryMethod_Enum;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
};

export enum BillingProviderDeliveryMethod_Enum {
  AwsSns = 'AWS_SNS',
  AwsSqs = 'AWS_SQS',
  DirectToBillingProvider = 'DIRECT_TO_BILLING_PROVIDER'
}

/** columns and relationships of "BillingProviderEnum" */
export type BillingProviderEnum = {
  __typename?: 'BillingProviderEnum';
  value: Scalars['String'];
};

/** aggregated selection of "BillingProviderEnum" */
export type BillingProviderEnum_Aggregate = {
  __typename?: 'BillingProviderEnum_aggregate';
  aggregate: Maybe<BillingProviderEnum_Aggregate_Fields>;
  nodes: Array<BillingProviderEnum>;
};

/** aggregate fields of "BillingProviderEnum" */
export type BillingProviderEnum_Aggregate_Fields = {
  __typename?: 'BillingProviderEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingProviderEnum_Max_Fields>;
  min: Maybe<BillingProviderEnum_Min_Fields>;
};


/** aggregate fields of "BillingProviderEnum" */
export type BillingProviderEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingProviderEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "BillingProviderEnum". All fields are combined with a logical 'AND'. */
export type BillingProviderEnum_Bool_Exp = {
  _and?: InputMaybe<Array<BillingProviderEnum_Bool_Exp>>;
  _not?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  _or?: InputMaybe<Array<BillingProviderEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingProviderEnum" */
export enum BillingProviderEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingProviderEnumPkey = 'BillingProviderEnum_pkey'
}

export enum BillingProviderEnum_Enum {
  AwsMarketplace = 'AWS_MARKETPLACE',
  Azure = 'AZURE',
  Custom = 'CUSTOM',
  Gcp = 'GCP',
  Manual = 'MANUAL',
  Netsuite = 'NETSUITE',
  QuickbooksOnline = 'QUICKBOOKS_ONLINE',
  Stripe = 'STRIPE',
  Tackle = 'TACKLE',
  Workday = 'WORKDAY'
}

/** Boolean expression to compare columns of type "BillingProviderEnum_enum". All fields are combined with logical 'AND'. */
export type BillingProviderEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<BillingProviderEnum_Enum>;
  _in?: InputMaybe<Array<BillingProviderEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BillingProviderEnum_Enum>;
  _nin?: InputMaybe<Array<BillingProviderEnum_Enum>>;
};

/** input type for inserting data into table "BillingProviderEnum" */
export type BillingProviderEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BillingProviderEnum_Max_Fields = {
  __typename?: 'BillingProviderEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BillingProviderEnum_Min_Fields = {
  __typename?: 'BillingProviderEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BillingProviderEnum" */
export type BillingProviderEnum_Mutation_Response = {
  __typename?: 'BillingProviderEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingProviderEnum>;
};

/** input type for inserting object relation for remote table "BillingProviderEnum" */
export type BillingProviderEnum_Obj_Rel_Insert_Input = {
  data: BillingProviderEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<BillingProviderEnum_On_Conflict>;
};

/** on_conflict condition type for table "BillingProviderEnum" */
export type BillingProviderEnum_On_Conflict = {
  constraint: BillingProviderEnum_Constraint;
  update_columns?: Array<BillingProviderEnum_Update_Column>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingProviderEnum". */
export type BillingProviderEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingProviderEnum */
export type BillingProviderEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "BillingProviderEnum" */
export enum BillingProviderEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "BillingProviderEnum" */
export type BillingProviderEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "BillingProviderEnum" */
export type BillingProviderEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingProviderEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingProviderEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "BillingProviderEnum" */
export enum BillingProviderEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type BillingProviderEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingProviderEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingProviderEnum_Bool_Exp;
};

export type BillingProviderFilter = {
  exclude?: InputMaybe<Array<BillingProviderEnum_Enum>>;
  include?: InputMaybe<Array<BillingProviderEnum_Enum>>;
};

/** columns and relationships of "BillingProviderInvoice" */
export type BillingProviderInvoice = {
  __typename?: 'BillingProviderInvoice';
  /** An object relationship */
  BillingProviderEnum: BillingProviderEnum;
  billing_provider: BillingProviderEnum_Enum;
  billing_provider_error: Maybe<Scalars['String']>;
  billing_provider_invoice_id: Maybe<Scalars['String']>;
  billing_provider_sales_order_id: Maybe<Scalars['String']>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  customer: Maybe<Customer>;
  due_date: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  external_status: InvoiceExternalStatusEnum_Enum;
  finalized_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  issued_at: Maybe<Scalars['timestamptz']>;
  tax_applied_status: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  workato_job_id: Maybe<Scalars['String']>;
};

export type BillingProviderInvoiceExternalStatusFilter = {
  exclude?: InputMaybe<Array<InvoiceExternalStatusEnum_Enum>>;
  include?: InputMaybe<Array<InvoiceExternalStatusEnum_Enum>>;
};

export type BillingProviderInvoiceUpsertResult = {
  __typename?: 'BillingProviderInvoiceUpsertResult';
  billing_provider: BillingProviderEnum_Enum;
  billing_provider_error: Maybe<Scalars['String']>;
  billing_provider_invoice_id: Maybe<Scalars['String']>;
  billing_provider_sales_order_id: Maybe<Scalars['String']>;
  external_status: InvoiceExternalStatusEnum_Enum;
  invoice_id: Scalars['uuid'];
  tax_applied_status: Maybe<TaxAppliedStatusEnum_Enum>;
};

/** aggregated selection of "BillingProviderInvoice" */
export type BillingProviderInvoice_Aggregate = {
  __typename?: 'BillingProviderInvoice_aggregate';
  aggregate: Maybe<BillingProviderInvoice_Aggregate_Fields>;
  nodes: Array<BillingProviderInvoice>;
};

/** aggregate fields of "BillingProviderInvoice" */
export type BillingProviderInvoice_Aggregate_Fields = {
  __typename?: 'BillingProviderInvoice_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingProviderInvoice_Max_Fields>;
  min: Maybe<BillingProviderInvoice_Min_Fields>;
};


/** aggregate fields of "BillingProviderInvoice" */
export type BillingProviderInvoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingProviderInvoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "BillingProviderInvoice". All fields are combined with a logical 'AND'. */
export type BillingProviderInvoice_Bool_Exp = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  _and?: InputMaybe<Array<BillingProviderInvoice_Bool_Exp>>;
  _not?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
  _or?: InputMaybe<Array<BillingProviderInvoice_Bool_Exp>>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  billing_provider_error?: InputMaybe<String_Comparison_Exp>;
  billing_provider_invoice_id?: InputMaybe<String_Comparison_Exp>;
  billing_provider_sales_order_id?: InputMaybe<String_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  external_status?: InputMaybe<InvoiceExternalStatusEnum_Enum_Comparison_Exp>;
  finalized_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  issued_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tax_applied_status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workato_job_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingProviderInvoice" */
export enum BillingProviderInvoice_Constraint {
  /** unique or primary key constraint on columns "invoice_id" */
  BillingProviderInvoiceInvoiceIdKey = 'BillingProviderInvoice_invoice_id_key',
  /** unique or primary key constraint on columns "id" */
  BillingProviderInvoicePkey = 'BillingProviderInvoice_pkey'
}

/** input type for inserting data into table "BillingProviderInvoice" */
export type BillingProviderInvoice_Insert_Input = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_error?: InputMaybe<Scalars['String']>;
  billing_provider_invoice_id?: InputMaybe<Scalars['String']>;
  billing_provider_sales_order_id?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  external_status?: InputMaybe<InvoiceExternalStatusEnum_Enum>;
  finalized_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  tax_applied_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workato_job_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BillingProviderInvoice_Max_Fields = {
  __typename?: 'BillingProviderInvoice_max_fields';
  billing_provider_error: Maybe<Scalars['String']>;
  billing_provider_invoice_id: Maybe<Scalars['String']>;
  billing_provider_sales_order_id: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  finalized_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  issued_at: Maybe<Scalars['timestamptz']>;
  tax_applied_status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  workato_job_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type BillingProviderInvoice_Min_Fields = {
  __typename?: 'BillingProviderInvoice_min_fields';
  billing_provider_error: Maybe<Scalars['String']>;
  billing_provider_invoice_id: Maybe<Scalars['String']>;
  billing_provider_sales_order_id: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  finalized_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  issued_at: Maybe<Scalars['timestamptz']>;
  tax_applied_status: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  workato_job_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "BillingProviderInvoice" */
export type BillingProviderInvoice_Mutation_Response = {
  __typename?: 'BillingProviderInvoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingProviderInvoice>;
};

/** on_conflict condition type for table "BillingProviderInvoice" */
export type BillingProviderInvoice_On_Conflict = {
  constraint: BillingProviderInvoice_Constraint;
  update_columns?: Array<BillingProviderInvoice_Update_Column>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingProviderInvoice". */
export type BillingProviderInvoice_Order_By = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  billing_provider?: InputMaybe<Order_By>;
  billing_provider_error?: InputMaybe<Order_By>;
  billing_provider_invoice_id?: InputMaybe<Order_By>;
  billing_provider_sales_order_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  external_status?: InputMaybe<Order_By>;
  finalized_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  tax_applied_status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workato_job_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingProviderInvoice */
export type BillingProviderInvoice_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "BillingProviderInvoice" */
export enum BillingProviderInvoice_Select_Column {
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  BillingProviderError = 'billing_provider_error',
  /** column name */
  BillingProviderInvoiceId = 'billing_provider_invoice_id',
  /** column name */
  BillingProviderSalesOrderId = 'billing_provider_sales_order_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  ExternalStatus = 'external_status',
  /** column name */
  FinalizedAt = 'finalized_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  TaxAppliedStatus = 'tax_applied_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkatoJobId = 'workato_job_id'
}

/** input type for updating data in table "BillingProviderInvoice" */
export type BillingProviderInvoice_Set_Input = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_error?: InputMaybe<Scalars['String']>;
  billing_provider_invoice_id?: InputMaybe<Scalars['String']>;
  billing_provider_sales_order_id?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  external_status?: InputMaybe<InvoiceExternalStatusEnum_Enum>;
  finalized_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  tax_applied_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workato_job_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "BillingProviderInvoice" */
export type BillingProviderInvoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingProviderInvoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingProviderInvoice_Stream_Cursor_Value_Input = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_error?: InputMaybe<Scalars['String']>;
  billing_provider_invoice_id?: InputMaybe<Scalars['String']>;
  billing_provider_sales_order_id?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  external_status?: InputMaybe<InvoiceExternalStatusEnum_Enum>;
  finalized_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  issued_at?: InputMaybe<Scalars['timestamptz']>;
  tax_applied_status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workato_job_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "BillingProviderInvoice" */
export enum BillingProviderInvoice_Update_Column {
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  BillingProviderError = 'billing_provider_error',
  /** column name */
  BillingProviderInvoiceId = 'billing_provider_invoice_id',
  /** column name */
  BillingProviderSalesOrderId = 'billing_provider_sales_order_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  ExternalStatus = 'external_status',
  /** column name */
  FinalizedAt = 'finalized_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IssuedAt = 'issued_at',
  /** column name */
  TaxAppliedStatus = 'tax_applied_status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkatoJobId = 'workato_job_id'
}

export type BillingProviderInvoice_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingProviderInvoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingProviderInvoice_Bool_Exp;
};

/** columns and relationships of "BillingProviderToken" */
export type BillingProviderToken = {
  __typename?: 'BillingProviderToken';
  /** An object relationship */
  BillingProviderEnum: BillingProviderEnum;
  /** An object relationship */
  Client: Client;
  account_id: Scalars['String'];
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  encrypted_token_string: Maybe<Scalars['String']>;
  environment_type: EnvironmentTypeEnum_Enum;
  token_string: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "BillingProviderToken" */
export type BillingProviderToken_Aggregate = {
  __typename?: 'BillingProviderToken_aggregate';
  aggregate: Maybe<BillingProviderToken_Aggregate_Fields>;
  nodes: Array<BillingProviderToken>;
};

export type BillingProviderToken_Aggregate_Bool_Exp = {
  count?: InputMaybe<BillingProviderToken_Aggregate_Bool_Exp_Count>;
};

export type BillingProviderToken_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<BillingProviderToken_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "BillingProviderToken" */
export type BillingProviderToken_Aggregate_Fields = {
  __typename?: 'BillingProviderToken_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<BillingProviderToken_Max_Fields>;
  min: Maybe<BillingProviderToken_Min_Fields>;
};


/** aggregate fields of "BillingProviderToken" */
export type BillingProviderToken_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "BillingProviderToken" */
export type BillingProviderToken_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<BillingProviderToken_Max_Order_By>;
  min?: InputMaybe<BillingProviderToken_Min_Order_By>;
};

/** input type for inserting array relation for remote table "BillingProviderToken" */
export type BillingProviderToken_Arr_Rel_Insert_Input = {
  data: Array<BillingProviderToken_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<BillingProviderToken_On_Conflict>;
};

/** Boolean expression to filter rows from the table "BillingProviderToken". All fields are combined with a logical 'AND'. */
export type BillingProviderToken_Bool_Exp = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  _and?: InputMaybe<Array<BillingProviderToken_Bool_Exp>>;
  _not?: InputMaybe<BillingProviderToken_Bool_Exp>;
  _or?: InputMaybe<Array<BillingProviderToken_Bool_Exp>>;
  account_id?: InputMaybe<String_Comparison_Exp>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  encrypted_token_string?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  token_string?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "BillingProviderToken" */
export enum BillingProviderToken_Constraint {
  /** unique or primary key constraint on columns "environment_type", "billing_provider", "client_id" */
  BillingProviderTokenPkey = 'BillingProviderToken_pkey'
}

/** input type for inserting data into table "BillingProviderToken" */
export type BillingProviderToken_Insert_Input = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['String']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  token_string?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BillingProviderToken_Max_Fields = {
  __typename?: 'BillingProviderToken_max_fields';
  account_id: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  encrypted_token_string: Maybe<Scalars['String']>;
  token_string: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "BillingProviderToken" */
export type BillingProviderToken_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  encrypted_token_string?: InputMaybe<Order_By>;
  token_string?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type BillingProviderToken_Min_Fields = {
  __typename?: 'BillingProviderToken_min_fields';
  account_id: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  encrypted_token_string: Maybe<Scalars['String']>;
  token_string: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "BillingProviderToken" */
export type BillingProviderToken_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  encrypted_token_string?: InputMaybe<Order_By>;
  token_string?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "BillingProviderToken" */
export type BillingProviderToken_Mutation_Response = {
  __typename?: 'BillingProviderToken_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BillingProviderToken>;
};

/** on_conflict condition type for table "BillingProviderToken" */
export type BillingProviderToken_On_Conflict = {
  constraint: BillingProviderToken_Constraint;
  update_columns?: Array<BillingProviderToken_Update_Column>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};

/** Ordering options when selecting data from "BillingProviderToken". */
export type BillingProviderToken_Order_By = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  account_id?: InputMaybe<Order_By>;
  billing_provider?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  encrypted_token_string?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  token_string?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: BillingProviderToken */
export type BillingProviderToken_Pk_Columns_Input = {
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};

/** select columns of table "BillingProviderToken" */
export enum BillingProviderToken_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedTokenString = 'encrypted_token_string',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  TokenString = 'token_string',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "BillingProviderToken" */
export type BillingProviderToken_Set_Input = {
  account_id?: InputMaybe<Scalars['String']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  token_string?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "BillingProviderToken" */
export type BillingProviderToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: BillingProviderToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type BillingProviderToken_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['String']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  token_string?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "BillingProviderToken" */
export enum BillingProviderToken_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EncryptedTokenString = 'encrypted_token_string',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  TokenString = 'token_string',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type BillingProviderToken_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BillingProviderToken_Set_Input>;
  /** filter the rows which have to be updated */
  where: BillingProviderToken_Bool_Exp;
};

export type BlocksInput = {
  rounding_behavior?: InputMaybe<RoundingBehaviorEnum_Enum>;
  size: Scalars['numeric'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Used to return a cursor to determine if breakdowns data for this invoice has changed */
export type BreakdownCursor = {
  __typename?: 'BreakdownCursor';
  customer_aggregate: Maybe<CustomerAggregate>;
  ending_before: Scalars['timestamptz'];
  event_count: Scalars['Int'];
  ingest_aliases: Array<Scalars['String']>;
  latest_billable_metric_updated_at: Scalars['timestamptz'];
  starting_at: Scalars['timestamptz'];
  version: Scalars['Int'];
};

export type BreakdownItem = {
  __typename?: 'BreakdownItem';
  description: Scalars['String'];
  quantity: Scalars['numeric'];
  subtotal: Scalars['CreditAmount'];
  tiers: Array<BreakdownTier>;
  unit_price: Scalars['CreditAmount'];
};

export type BreakdownTier = {
  __typename?: 'BreakdownTier';
  description: Scalars['String'];
  metric_minimum: Scalars['numeric'];
  quantity: Scalars['numeric'];
  subtotal: Scalars['CreditAmount'];
  unit_price: Scalars['CreditAmount'];
};

export type CensusDestinationConnectLink = {
  __typename?: 'CensusDestinationConnectLink';
  destination_id: Maybe<Scalars['String']>;
  destination_type: CensusDestinationTypeEnum;
  expiration: Scalars['timestamptz'];
  expired: Scalars['Boolean'];
  id: Scalars['String'];
  redirect_uri: Maybe<Scalars['String']>;
  revoked: Scalars['Boolean'];
  uri: Scalars['String'];
};

export enum CensusDestinationTypeEnum {
  Salesforce = 'SALESFORCE'
}

export type CensusObjectSyncReport = {
  __typename?: 'CensusObjectSyncReport';
  object_name: Scalars['String'];
  sync_runs: Array<CensusSyncRun>;
};

export type CensusRecordSyncError = {
  __typename?: 'CensusRecordSyncError';
  error_message: Scalars['String'];
  record_attributes: Maybe<Scalars['FlatKeyValueMap']>;
};

export type CensusSyncRun = {
  __typename?: 'CensusSyncRun';
  completed_at: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  records_failed: Maybe<Scalars['Int']>;
  records_processed: Maybe<Scalars['Int']>;
  records_updated: Maybe<Scalars['Int']>;
  sampled_failed_records: Maybe<Array<CensusRecordSyncError>>;
  scheduled_execution_time: Maybe<Scalars['timestamptz']>;
  status: CensusSyncRunStatusEnum;
};

export enum CensusSyncRunStatusEnum {
  Completed = 'completed',
  Failed = 'failed',
  Queued = 'queued',
  Skipped = 'skipped',
  Working = 'working'
}

export type CensusWorkspace = {
  __typename?: 'CensusWorkspace';
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  destination_type: CensusDestinationTypeEnum;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['String'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

export type ChargeLineItem = Mri_LineItem & {
  __typename?: 'ChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  line_item_chunks: Array<LineItemChunk>;
  one_time_charge: Maybe<OneTimeProductCharge>;
  pricing_factor: ProductPricingFactor;
  quantity: Scalars['numeric'];
  sub_line_items: Maybe<Array<ChargeSubLineItem>>;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  tier_period: Maybe<TierPeriod>;
  total: Scalars['numeric'];
  unit_price: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "ChargeManagedField" */
export type ChargeManagedField = {
  __typename?: 'ChargeManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  ProductPricingFactor: ProductPricingFactor;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  product_pricing_factor_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ChargeManagedField" */
export type ChargeManagedField_Aggregate = {
  __typename?: 'ChargeManagedField_aggregate';
  aggregate: Maybe<ChargeManagedField_Aggregate_Fields>;
  nodes: Array<ChargeManagedField>;
};

export type ChargeManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ChargeManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ChargeManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ChargeManagedField_Aggregate_Bool_Exp_Count>;
};

export type ChargeManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChargeManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ChargeManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChargeManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ChargeManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChargeManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ChargeManagedField" */
export type ChargeManagedField_Aggregate_Fields = {
  __typename?: 'ChargeManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ChargeManagedField_Max_Fields>;
  min: Maybe<ChargeManagedField_Min_Fields>;
};


/** aggregate fields of "ChargeManagedField" */
export type ChargeManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ChargeManagedField" */
export type ChargeManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ChargeManagedField_Max_Order_By>;
  min?: InputMaybe<ChargeManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ChargeManagedField" */
export type ChargeManagedField_Arr_Rel_Insert_Input = {
  data: Array<ChargeManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChargeManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ChargeManagedField". All fields are combined with a logical 'AND'. */
export type ChargeManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ChargeManagedField_Bool_Exp>>;
  _not?: InputMaybe<ChargeManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ChargeManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ChargeManagedField" */
export enum ChargeManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ChargeManagedFieldKeyIdValueEnforceUniquenessClientIKey = 'ChargeManagedField_key_id_value_enforce_uniqueness_client_i_key',
  /** unique or primary key constraint on columns "id" */
  ChargeManagedFieldPkey = 'ChargeManagedField_pkey',
  /** unique or primary key constraint on columns "key_id", "product_pricing_factor_id" */
  ChargeManagedFieldProductPricingFactorIdKeyIdKey = 'ChargeManagedField_product_pricing_factor_id_key_id_key'
}

/** input type for inserting data into table "ChargeManagedField" */
export type ChargeManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChargeManagedField_Max_Fields = {
  __typename?: 'ChargeManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ChargeManagedField" */
export type ChargeManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ChargeManagedField_Min_Fields = {
  __typename?: 'ChargeManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ChargeManagedField" */
export type ChargeManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ChargeManagedField" */
export type ChargeManagedField_Mutation_Response = {
  __typename?: 'ChargeManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChargeManagedField>;
};

/** on_conflict condition type for table "ChargeManagedField" */
export type ChargeManagedField_On_Conflict = {
  constraint: ChargeManagedField_Constraint;
  update_columns?: Array<ChargeManagedField_Update_Column>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ChargeManagedField". */
export type ChargeManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ChargeManagedField */
export type ChargeManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ChargeManagedField" */
export enum ChargeManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ChargeManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ChargeManagedField" */
export enum ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ChargeManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ChargeManagedField" */
export enum ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ChargeManagedField" */
export type ChargeManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ChargeManagedField" */
export type ChargeManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ChargeManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ChargeManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ChargeManagedField" */
export enum ChargeManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ChargeManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChargeManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ChargeManagedField_Bool_Exp;
};

export type ChargeSubLineItem = GroupedChargeLineItem | TierChargeLineItem;

/** columns and relationships of "ChargeTypeEnum" */
export type ChargeTypeEnum = {
  __typename?: 'ChargeTypeEnum';
  /** An array relationship */
  ProductPricingFactors: Array<ProductPricingFactor>;
  /** An aggregate relationship */
  ProductPricingFactors_aggregate: ProductPricingFactor_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ChargeTypeEnum" */
export type ChargeTypeEnumProductPricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


/** columns and relationships of "ChargeTypeEnum" */
export type ChargeTypeEnumProductPricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};

/** aggregated selection of "ChargeTypeEnum" */
export type ChargeTypeEnum_Aggregate = {
  __typename?: 'ChargeTypeEnum_aggregate';
  aggregate: Maybe<ChargeTypeEnum_Aggregate_Fields>;
  nodes: Array<ChargeTypeEnum>;
};

/** aggregate fields of "ChargeTypeEnum" */
export type ChargeTypeEnum_Aggregate_Fields = {
  __typename?: 'ChargeTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ChargeTypeEnum_Max_Fields>;
  min: Maybe<ChargeTypeEnum_Min_Fields>;
};


/** aggregate fields of "ChargeTypeEnum" */
export type ChargeTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ChargeTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ChargeTypeEnum". All fields are combined with a logical 'AND'. */
export type ChargeTypeEnum_Bool_Exp = {
  ProductPricingFactors?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  ProductPricingFactors_aggregate?: InputMaybe<ProductPricingFactor_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<ChargeTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ChargeTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ChargeTypeEnum" */
export enum ChargeTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ChargeTypeEnumPkey = 'ChargeTypeEnum_pkey'
}

export enum ChargeTypeEnum_Enum {
  Composite = 'COMPOSITE',
  Flat = 'FLAT',
  Seat = 'SEAT',
  Usage = 'USAGE'
}

/** Boolean expression to compare columns of type "ChargeTypeEnum_enum". All fields are combined with logical 'AND'. */
export type ChargeTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ChargeTypeEnum_Enum>;
  _in?: InputMaybe<Array<ChargeTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ChargeTypeEnum_Enum>;
  _nin?: InputMaybe<Array<ChargeTypeEnum_Enum>>;
};

/** input type for inserting data into table "ChargeTypeEnum" */
export type ChargeTypeEnum_Insert_Input = {
  ProductPricingFactors?: InputMaybe<ProductPricingFactor_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChargeTypeEnum_Max_Fields = {
  __typename?: 'ChargeTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChargeTypeEnum_Min_Fields = {
  __typename?: 'ChargeTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ChargeTypeEnum" */
export type ChargeTypeEnum_Mutation_Response = {
  __typename?: 'ChargeTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChargeTypeEnum>;
};

/** input type for inserting object relation for remote table "ChargeTypeEnum" */
export type ChargeTypeEnum_Obj_Rel_Insert_Input = {
  data: ChargeTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ChargeTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "ChargeTypeEnum" */
export type ChargeTypeEnum_On_Conflict = {
  constraint: ChargeTypeEnum_Constraint;
  update_columns?: Array<ChargeTypeEnum_Update_Column>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ChargeTypeEnum". */
export type ChargeTypeEnum_Order_By = {
  ProductPricingFactors_aggregate?: InputMaybe<ProductPricingFactor_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ChargeTypeEnum */
export type ChargeTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ChargeTypeEnum" */
export enum ChargeTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ChargeTypeEnum" */
export type ChargeTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ChargeTypeEnum" */
export type ChargeTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ChargeTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ChargeTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ChargeTypeEnum" */
export enum ChargeTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ChargeTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChargeTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ChargeTypeEnum_Bool_Exp;
};

/** columns and relationships of "Client" */
export type Client = {
  __typename?: 'Client';
  /** An array relationship */
  BillableMetrics: Array<BillableMetric>;
  /** An aggregate relationship */
  BillableMetrics_aggregate: BillableMetric_Aggregate;
  /** An array relationship */
  BillingProviderTokens: Array<BillingProviderToken>;
  /** An aggregate relationship */
  BillingProviderTokens_aggregate: BillingProviderToken_Aggregate;
  /** An array relationship */
  ClientConfigs: Array<ClientConfig>;
  /** An aggregate relationship */
  ClientConfigs_aggregate: ClientConfig_Aggregate;
  /** An array relationship */
  Customers: Array<Customer>;
  /** An aggregate relationship */
  Customers_aggregate: Customer_Aggregate;
  /** An array relationship */
  Users: Array<User>;
  /** An aggregate relationship */
  Users_aggregate: User_Aggregate;
  account_type: AccountTypeEnum_Enum;
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  customer_count: CustomerCount;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "Client" */
export type ClientBillableMetricsArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientBillableMetrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientBillingProviderTokensArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientBillingProviderTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientClientConfigsArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientClientConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "Client" */
export type ClientCustomer_CountArgs = {
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  has_contract?: InputMaybe<Scalars['Boolean']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "ClientConfig" */
export type ClientConfig = {
  __typename?: 'ClientConfig';
  /** An object relationship */
  Client: Client;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key: ClientConfigKeyEnum_Enum;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** columns and relationships of "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum = {
  __typename?: 'ClientConfigKeyEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Aggregate = {
  __typename?: 'ClientConfigKeyEnum_aggregate';
  aggregate: Maybe<ClientConfigKeyEnum_Aggregate_Fields>;
  nodes: Array<ClientConfigKeyEnum>;
};

/** aggregate fields of "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Aggregate_Fields = {
  __typename?: 'ClientConfigKeyEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ClientConfigKeyEnum_Max_Fields>;
  min: Maybe<ClientConfigKeyEnum_Min_Fields>;
};


/** aggregate fields of "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientConfigKeyEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ClientConfigKeyEnum". All fields are combined with a logical 'AND'. */
export type ClientConfigKeyEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ClientConfigKeyEnum_Bool_Exp>>;
  _not?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ClientConfigKeyEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ClientConfigKeyEnum" */
export enum ClientConfigKeyEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientConfigKeyEnumPkey = 'ClientConfigKeyEnum_pkey'
}

export enum ClientConfigKeyEnum_Enum {
  AutomaticSeatPurchaseInvoices = 'automatic_seat_purchase_invoices',
  BooksClosedDate = 'books_closed_date',
  CollapseCreditLineItemsByNameInBpi = 'collapse_credit_line_items_by_name_in_bpi',
  CustomerBillingProviderConfigurationEnabled = 'customer_billing_provider_configuration_enabled',
  DeltaStreamEnabled = 'delta_stream_enabled',
  ExportInvoiceSubLineItems = 'export_invoice_sub_line_items',
  GracePeriodHours = 'grace_period_hours',
  GroupKeyExternalDisplayNames = 'group_key_external_display_names',
  GroupValueExternalDisplayNames = 'group_value_external_display_names',
  HideZeroUnitPriceLineItems = 'hide_zero_unit_price_line_items',
  HoldFinalizationForEndDateAfter = 'hold_finalization_for_end_date_after',
  IncludeZeroQuantitySubLineItems = 'include_zero_quantity_sub_line_items',
  InvoiceDaysUntilDue = 'invoice_days_until_due',
  LeaveInvoicesInDraft = 'leave_invoices_in_draft',
  MaxActiveBillableMetricCount = 'max_active_billable_metric_count',
  MaxBillableMetricGroupKeyCount = 'max_billable_metric_group_key_count',
  SkipZeroDollarInvoices = 'skip_zero_dollar_invoices',
  StripeAddTaxToLineItem = 'stripe_add_tax_to_line_item',
  StripeAppendTierIndexOnMetadata = 'stripe_append_tier_index_on_metadata',
  StripeCollapseContractUsageLineItems = 'stripe_collapse_contract_usage_line_items',
  StripeCollapseZeroQuantityLines = 'stripe_collapse_zero_quantity_lines',
  StripeHidePricingGroupKeys = 'stripe_hide_pricing_group_keys',
  StripeIncludeCloudUsageServicePeriodOnInvoice = 'stripe_include_cloud_usage_service_period_on_invoice',
  StripeIncludeServicePeriodDatesOnInvoice = 'stripe_include_service_period_dates_on_invoice',
  StripeInvoiceQuantityAlwaysString = 'stripe_invoice_quantity_always_string',
  StripeModifyCreditLineItemToMatchMetronome = 'stripe_modify_credit_line_item_to_match_metronome',
  StripeSkipInvoicesWithAllZeroQuantityLineItems = 'stripe_skip_invoices_with_all_zero_quantity_line_items',
  StripeTaxProvider = 'stripe_tax_provider',
  StripeUseCollapsedLineItemName = 'stripe_use_collapsed_line_item_name',
  StripeUseExtendedLabelsInLineItemName = 'stripe_use_extended_labels_in_line_item_name',
  StripeUseReplicateLineItemFormatting = 'stripe_use_replicate_line_item_formatting',
  StripeUseSupportChargeDatesForQuantity = 'stripe_use_support_charge_dates_for_quantity'
}

/** Boolean expression to compare columns of type "ClientConfigKeyEnum_enum". All fields are combined with logical 'AND'. */
export type ClientConfigKeyEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ClientConfigKeyEnum_Enum>;
  _in?: InputMaybe<Array<ClientConfigKeyEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ClientConfigKeyEnum_Enum>;
  _nin?: InputMaybe<Array<ClientConfigKeyEnum_Enum>>;
};

/** input type for inserting data into table "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClientConfigKeyEnum_Max_Fields = {
  __typename?: 'ClientConfigKeyEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ClientConfigKeyEnum_Min_Fields = {
  __typename?: 'ClientConfigKeyEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Mutation_Response = {
  __typename?: 'ClientConfigKeyEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientConfigKeyEnum>;
};

/** on_conflict condition type for table "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_On_Conflict = {
  constraint: ClientConfigKeyEnum_Constraint;
  update_columns?: Array<ClientConfigKeyEnum_Update_Column>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ClientConfigKeyEnum". */
export type ClientConfigKeyEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ClientConfigKeyEnum */
export type ClientConfigKeyEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ClientConfigKeyEnum" */
export enum ClientConfigKeyEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ClientConfigKeyEnum" */
export type ClientConfigKeyEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientConfigKeyEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientConfigKeyEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ClientConfigKeyEnum" */
export enum ClientConfigKeyEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ClientConfigKeyEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientConfigKeyEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientConfigKeyEnum_Bool_Exp;
};

/** aggregated selection of "ClientConfig" */
export type ClientConfig_Aggregate = {
  __typename?: 'ClientConfig_aggregate';
  aggregate: Maybe<ClientConfig_Aggregate_Fields>;
  nodes: Array<ClientConfig>;
};

export type ClientConfig_Aggregate_Bool_Exp = {
  count?: InputMaybe<ClientConfig_Aggregate_Bool_Exp_Count>;
};

export type ClientConfig_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ClientConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ClientConfig_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ClientConfig" */
export type ClientConfig_Aggregate_Fields = {
  __typename?: 'ClientConfig_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ClientConfig_Max_Fields>;
  min: Maybe<ClientConfig_Min_Fields>;
};


/** aggregate fields of "ClientConfig" */
export type ClientConfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ClientConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ClientConfig" */
export type ClientConfig_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ClientConfig_Max_Order_By>;
  min?: InputMaybe<ClientConfig_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ClientConfig" */
export type ClientConfig_Arr_Rel_Insert_Input = {
  data: Array<ClientConfig_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClientConfig_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ClientConfig". All fields are combined with a logical 'AND'. */
export type ClientConfig_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  _and?: InputMaybe<Array<ClientConfig_Bool_Exp>>;
  _not?: InputMaybe<ClientConfig_Bool_Exp>;
  _or?: InputMaybe<Array<ClientConfig_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<ClientConfigKeyEnum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ClientConfig" */
export enum ClientConfig_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientConfigPkey = 'ClientConfig_pkey',
  /** unique or primary key constraint on columns "key", "environment_type", "client_id" */
  ClientConfigUniqueness = 'ClientConfig_uniqueness'
}

/** input type for inserting data into table "ClientConfig" */
export type ClientConfig_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<ClientConfigKeyEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClientConfig_Max_Fields = {
  __typename?: 'ClientConfig_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ClientConfig" */
export type ClientConfig_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ClientConfig_Min_Fields = {
  __typename?: 'ClientConfig_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ClientConfig" */
export type ClientConfig_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ClientConfig" */
export type ClientConfig_Mutation_Response = {
  __typename?: 'ClientConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ClientConfig>;
};

/** on_conflict condition type for table "ClientConfig" */
export type ClientConfig_On_Conflict = {
  constraint: ClientConfig_Constraint;
  update_columns?: Array<ClientConfig_Update_Column>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};

/** Ordering options when selecting data from "ClientConfig". */
export type ClientConfig_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ClientConfig */
export type ClientConfig_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ClientConfig" */
export enum ClientConfig_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ClientConfig" */
export type ClientConfig_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<ClientConfigKeyEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ClientConfig" */
export type ClientConfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ClientConfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ClientConfig_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<ClientConfigKeyEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ClientConfig" */
export enum ClientConfig_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type ClientConfig_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClientConfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: ClientConfig_Bool_Exp;
};

export type ClientDashboardMetrics = {
  __typename?: 'ClientDashboardMetrics';
  invoice_revenues: Array<RevenueMetric>;
  revenue_by_month: Array<RevenueBreakdown>;
  top_10_spenders: Array<TopCustomerSpenders>;
  total_customer: CustomersMetric;
};

export type ClientOverview = {
  __typename?: 'ClientOverview';
  ending_before: Scalars['timestamptz'];
  starting_at: Scalars['timestamptz'];
  top_spenders: ClientOverview_TopSpenders;
  total_active_customers: ClientOverview_Chart;
  total_revenue: ClientOverview_Chart;
};

export type ClientOverview_Chart = {
  __typename?: 'ClientOverview_Chart';
  credit_type: Maybe<CreditType>;
  data: Array<ClientOverview_ChartDatum>;
  snapshot_date: Scalars['timestamptz'];
};

export type ClientOverview_ChartDatum = {
  __typename?: 'ClientOverview_ChartDatum';
  date: Scalars['timestamptz'];
  value: Scalars['numeric'];
};

export type ClientOverview_Spend = {
  __typename?: 'ClientOverview_Spend';
  amount: Scalars['numeric'];
  month: Scalars['timestamptz'];
};

export type ClientOverview_TopSpender = {
  __typename?: 'ClientOverview_TopSpender';
  customer: Customer;
  delta_percentage: Scalars['numeric'];
  total_revenue: Scalars['numeric'];
};

export type ClientOverview_TopSpenders = {
  __typename?: 'ClientOverview_TopSpenders';
  credit_type: CreditType;
  rows: Array<ClientOverview_TopSpender>;
  snapshot_date: Scalars['timestamptz'];
};

export type ClientUsingContracts = {
  __typename?: 'ClientUsingContracts';
  client_id: Scalars['uuid'];
  environment_types: Array<EnvironmentTypeEnum_Enum>;
};

export type ClientUsingPrepaidCredits = {
  __typename?: 'ClientUsingPrepaidCredits';
  client_id: Scalars['uuid'];
  environment_types: Array<EnvironmentTypeEnum_Enum>;
};

/** aggregated selection of "Client" */
export type Client_Aggregate = {
  __typename?: 'Client_aggregate';
  aggregate: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

/** aggregate fields of "Client" */
export type Client_Aggregate_Fields = {
  __typename?: 'Client_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Client_Max_Fields>;
  min: Maybe<Client_Min_Fields>;
};


/** aggregate fields of "Client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  BillableMetrics?: InputMaybe<BillableMetric_Bool_Exp>;
  BillableMetrics_aggregate?: InputMaybe<BillableMetric_Aggregate_Bool_Exp>;
  BillingProviderTokens?: InputMaybe<BillingProviderToken_Bool_Exp>;
  BillingProviderTokens_aggregate?: InputMaybe<BillingProviderToken_Aggregate_Bool_Exp>;
  ClientConfigs?: InputMaybe<ClientConfig_Bool_Exp>;
  ClientConfigs_aggregate?: InputMaybe<ClientConfig_Aggregate_Bool_Exp>;
  Customers?: InputMaybe<Customer_Bool_Exp>;
  Customers_aggregate?: InputMaybe<Customer_Aggregate_Bool_Exp>;
  Users?: InputMaybe<User_Bool_Exp>;
  Users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Client_Bool_Exp>>;
  _not?: InputMaybe<Client_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Bool_Exp>>;
  account_type?: InputMaybe<AccountTypeEnum_Enum_Comparison_Exp>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Client" */
export enum Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPkey = 'Client_pkey'
}

/** input type for inserting data into table "Client" */
export type Client_Insert_Input = {
  BillableMetrics?: InputMaybe<BillableMetric_Arr_Rel_Insert_Input>;
  BillingProviderTokens?: InputMaybe<BillingProviderToken_Arr_Rel_Insert_Input>;
  ClientConfigs?: InputMaybe<ClientConfig_Arr_Rel_Insert_Input>;
  Customers?: InputMaybe<Customer_Arr_Rel_Insert_Input>;
  Users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  account_type?: InputMaybe<AccountTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: 'Client_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: 'Client_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Client" */
export type Client_Mutation_Response = {
  __typename?: 'Client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "Client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>;
};

/** on_conflict condition type for table "Client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns?: Array<Client_Update_Column>;
  where?: InputMaybe<Client_Bool_Exp>;
};

/** Ordering options when selecting data from "Client". */
export type Client_Order_By = {
  BillableMetrics_aggregate?: InputMaybe<BillableMetric_Aggregate_Order_By>;
  BillingProviderTokens_aggregate?: InputMaybe<BillingProviderToken_Aggregate_Order_By>;
  ClientConfigs_aggregate?: InputMaybe<ClientConfig_Aggregate_Order_By>;
  Customers_aggregate?: InputMaybe<Customer_Aggregate_Order_By>;
  Users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  account_type?: InputMaybe<Order_By>;
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Client */
export type Client_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Client" */
export enum Client_Select_Column {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "Client" */
export type Client_Set_Input = {
  account_type?: InputMaybe<AccountTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  account_type?: InputMaybe<AccountTypeEnum_Enum>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Client" */
export enum Client_Update_Column {
  /** column name */
  AccountType = 'account_type',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp;
};

export type ClientsUsingContracts = {
  __typename?: 'ClientsUsingContracts';
  next_page: Maybe<Scalars['String']>;
  page: Array<ClientUsingContracts>;
};

export type ClientsUsingPrepaidCredits = {
  __typename?: 'ClientsUsingPrepaidCredits';
  next_page: Maybe<Scalars['String']>;
  page: Array<ClientUsingPrepaidCredits>;
};

/** columns and relationships of "CollectionScheduleEnum" */
export type CollectionScheduleEnum = {
  __typename?: 'CollectionScheduleEnum';
  value: Scalars['String'];
};

/** aggregated selection of "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Aggregate = {
  __typename?: 'CollectionScheduleEnum_aggregate';
  aggregate: Maybe<CollectionScheduleEnum_Aggregate_Fields>;
  nodes: Array<CollectionScheduleEnum>;
};

/** aggregate fields of "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Aggregate_Fields = {
  __typename?: 'CollectionScheduleEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CollectionScheduleEnum_Max_Fields>;
  min: Maybe<CollectionScheduleEnum_Min_Fields>;
};


/** aggregate fields of "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CollectionScheduleEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CollectionScheduleEnum". All fields are combined with a logical 'AND'. */
export type CollectionScheduleEnum_Bool_Exp = {
  _and?: InputMaybe<Array<CollectionScheduleEnum_Bool_Exp>>;
  _not?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
  _or?: InputMaybe<Array<CollectionScheduleEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CollectionScheduleEnum" */
export enum CollectionScheduleEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionScheduleEnumPkey = 'CollectionScheduleEnum_pkey'
}

export enum CollectionScheduleEnum_Enum {
  Advance = 'ADVANCE',
  Arrears = 'ARREARS',
  OneTimeAdvance = 'ONE_TIME_ADVANCE'
}

/** Boolean expression to compare columns of type "CollectionScheduleEnum_enum". All fields are combined with logical 'AND'. */
export type CollectionScheduleEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CollectionScheduleEnum_Enum>;
  _in?: InputMaybe<Array<CollectionScheduleEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CollectionScheduleEnum_Enum>;
  _nin?: InputMaybe<Array<CollectionScheduleEnum_Enum>>;
};

/** input type for inserting data into table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CollectionScheduleEnum_Max_Fields = {
  __typename?: 'CollectionScheduleEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CollectionScheduleEnum_Min_Fields = {
  __typename?: 'CollectionScheduleEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Mutation_Response = {
  __typename?: 'CollectionScheduleEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CollectionScheduleEnum>;
};

/** input type for inserting object relation for remote table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Obj_Rel_Insert_Input = {
  data: CollectionScheduleEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CollectionScheduleEnum_On_Conflict>;
};

/** on_conflict condition type for table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_On_Conflict = {
  constraint: CollectionScheduleEnum_Constraint;
  update_columns?: Array<CollectionScheduleEnum_Update_Column>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "CollectionScheduleEnum". */
export type CollectionScheduleEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CollectionScheduleEnum */
export type CollectionScheduleEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CollectionScheduleEnum" */
export enum CollectionScheduleEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CollectionScheduleEnum" */
export type CollectionScheduleEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CollectionScheduleEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CollectionScheduleEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CollectionScheduleEnum" */
export enum CollectionScheduleEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type CollectionScheduleEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CollectionScheduleEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: CollectionScheduleEnum_Bool_Exp;
};

export type Commit = {
  __typename?: 'Commit';
  access_schedule: Maybe<CommitAccessSchedule>;
  amount: Maybe<Scalars['numeric']>;
  applicable_contracts: Maybe<Array<Contract>>;
  applicable_products: Maybe<Array<ProductListItem>>;
  applicable_tags: Maybe<Array<Scalars['String']>>;
  contract: Maybe<Contract>;
  customer: Customer;
  description: Maybe<Scalars['String']>;
  external_type: Maybe<ExternalCommitType>;
  id: Scalars['uuid'];
  invoice_contract: Maybe<Contract>;
  invoice_schedule: Maybe<CommitInvoiceSchedule>;
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  priority: Scalars['numeric'];
  product: ProductListItem;
  rollover_fraction: Maybe<Scalars['numeric']>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
  type: CommitType;
  voided_at: Maybe<Scalars['timestamptz']>;
};

export type CommitAccessSchedule = {
  __typename?: 'CommitAccessSchedule';
  credit_type: CreditType;
  recurring_schedule: Maybe<RecurringSchedule>;
  schedule_items: Array<CommitSegmentScheduleItem>;
};

export type CommitAccessScheduleInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  schedule_items: Array<CommitSegmentScheduleItemInput>;
};

export type CommitInput = {
  access_schedule?: InputMaybe<CommitAccessScheduleInput>;
  amount?: InputMaybe<Scalars['numeric']>;
  applicable_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  applicable_tags?: InputMaybe<Array<Scalars['String']>>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  description?: InputMaybe<Scalars['String']>;
  external_type?: InputMaybe<ExternalCommitType>;
  invoice_schedule?: InputMaybe<CommitInvoiceScheduleInput>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_id: Scalars['uuid'];
  rollover_fraction?: InputMaybe<Scalars['numeric']>;
  type: CommitType;
};

export type CommitInvoiceSchedule = {
  __typename?: 'CommitInvoiceSchedule';
  credit_type: CreditType;
  recurring_schedule: Maybe<RecurringSchedule>;
  schedule_items: Array<CommitInvoiceScheduleItem>;
};

export type CommitInvoiceScheduleInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  recurring_schedule?: InputMaybe<RecurringScheduleInput>;
  schedule_items?: InputMaybe<Array<CommitInvoiceScheduleItemInput>>;
};

export type CommitInvoiceScheduleItem = {
  __typename?: 'CommitInvoiceScheduleItem';
  amount: Scalars['CreditAmount'];
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  quantity: Scalars['CreditAmount'];
  unit_price: Scalars['CreditAmount'];
};

export type CommitInvoiceScheduleItemInput = {
  amount?: InputMaybe<Scalars['CreditAmount']>;
  date: Scalars['timestamptz'];
  quantity?: InputMaybe<Scalars['CreditAmount']>;
  unit_price?: InputMaybe<Scalars['CreditAmount']>;
};

export type CommitInvoiceScheduleItemUpdateInput = {
  amount?: InputMaybe<Scalars['CreditAmount']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  quantity?: InputMaybe<Scalars['CreditAmount']>;
  unit_price?: InputMaybe<Scalars['CreditAmount']>;
};

/** columns and relationships of "CommitManagedField" */
export type CommitManagedField = {
  __typename?: 'CommitManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  commit_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CommitManagedField" */
export type CommitManagedField_Aggregate = {
  __typename?: 'CommitManagedField_aggregate';
  aggregate: Maybe<CommitManagedField_Aggregate_Fields>;
  nodes: Array<CommitManagedField>;
};

export type CommitManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CommitManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CommitManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CommitManagedField_Aggregate_Bool_Exp_Count>;
};

export type CommitManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CommitManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CommitManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CommitManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CommitManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CommitManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CommitManagedField" */
export type CommitManagedField_Aggregate_Fields = {
  __typename?: 'CommitManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CommitManagedField_Max_Fields>;
  min: Maybe<CommitManagedField_Min_Fields>;
};


/** aggregate fields of "CommitManagedField" */
export type CommitManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CommitManagedField" */
export type CommitManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CommitManagedField_Max_Order_By>;
  min?: InputMaybe<CommitManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CommitManagedField" */
export type CommitManagedField_Arr_Rel_Insert_Input = {
  data: Array<CommitManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CommitManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CommitManagedField". All fields are combined with a logical 'AND'. */
export type CommitManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CommitManagedField_Bool_Exp>>;
  _not?: InputMaybe<CommitManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<CommitManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  commit_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CommitManagedField" */
export enum CommitManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "commit_id" */
  CommitManagedFieldCommitIdKeyIdKey = 'CommitManagedField_commit_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  CommitManagedFieldKeyIdValueEnforceUniquenessClientIKey = 'CommitManagedField_key_id_value_enforce_uniqueness_client_i_key',
  /** unique or primary key constraint on columns "id" */
  CommitManagedFieldPkey = 'CommitManagedField_pkey'
}

/** input type for inserting data into table "CommitManagedField" */
export type CommitManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  commit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CommitManagedField_Max_Fields = {
  __typename?: 'CommitManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  commit_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CommitManagedField" */
export type CommitManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  commit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CommitManagedField_Min_Fields = {
  __typename?: 'CommitManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  commit_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CommitManagedField" */
export type CommitManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  commit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CommitManagedField" */
export type CommitManagedField_Mutation_Response = {
  __typename?: 'CommitManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CommitManagedField>;
};

/** on_conflict condition type for table "CommitManagedField" */
export type CommitManagedField_On_Conflict = {
  constraint: CommitManagedField_Constraint;
  update_columns?: Array<CommitManagedField_Update_Column>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "CommitManagedField". */
export type CommitManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  commit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CommitManagedField */
export type CommitManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CommitManagedField" */
export enum CommitManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommitId = 'commit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "CommitManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CommitManagedField" */
export enum CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "CommitManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CommitManagedField" */
export enum CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "CommitManagedField" */
export type CommitManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  commit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CommitManagedField" */
export type CommitManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CommitManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CommitManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  commit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CommitManagedField" */
export enum CommitManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommitId = 'commit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type CommitManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommitManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CommitManagedField_Bool_Exp;
};

export type CommitOrCreditManagedField = {
  __typename?: 'CommitOrCreditManagedField';
  ManagedFieldKey: ManagedFieldKey;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  value: Scalars['String'];
};

export type CommitScheduleAccessUpdateInput = {
  add?: InputMaybe<Array<CommitSegmentScheduleItemInput>>;
  remove?: InputMaybe<Array<CommitSegmentScheduleItemUpdateInput>>;
  update?: InputMaybe<Array<CommitSegmentScheduleItemUpdateInput>>;
};

export type CommitScheduleInvoiceUpdateInput = {
  add?: InputMaybe<Array<CommitInvoiceScheduleItemInput>>;
  remove?: InputMaybe<Array<CommitInvoiceScheduleItemUpdateInput>>;
  update?: InputMaybe<Array<CommitInvoiceScheduleItemUpdateInput>>;
};

export type CommitScheduleUpdateInput = {
  access_schedule?: InputMaybe<CommitScheduleAccessUpdateInput>;
  invoice_schedule?: InputMaybe<CommitScheduleInvoiceUpdateInput>;
};

export type CommitSegmentScheduleItem = {
  __typename?: 'CommitSegmentScheduleItem';
  amount: Scalars['numeric'];
  commit_with_segment: CommitWithSegment;
  date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  remaining_balance: Scalars['numeric'];
};


export type CommitSegmentScheduleItemRemaining_BalanceArgs = {
  customer_id?: InputMaybe<Scalars['uuid']>;
};

export type CommitSegmentScheduleItemInput = {
  amount: Scalars['CreditAmount'];
  date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
};

export type CommitSegmentScheduleItemUpdateInput = {
  amount?: InputMaybe<Scalars['CreditAmount']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
};

export enum CommitType {
  Postpaid = 'POSTPAID',
  Prepaid = 'PREPAID'
}

export type CommitUnion = PostpaidCommit | PrepaidCommit;

export type CommitWithSegment = {
  __typename?: 'CommitWithSegment';
  commit: Commit;
  commit_id: Scalars['uuid'];
  commit_union: CommitUnion;
  segment: CommitSegmentScheduleItem;
};

/** columns and relationships of "CompositeCharge" */
export type CompositeCharge = {
  __typename?: 'CompositeCharge';
  /** An array relationship */
  CompositeChargePricingFactors: Array<CompositeChargePricingFactor>;
  /** An aggregate relationship */
  CompositeChargePricingFactors_aggregate: CompositeChargePricingFactor_Aggregate;
  /** An array relationship */
  CompositeChargeTiers: Array<CompositeChargeTier>;
  /** An aggregate relationship */
  CompositeChargeTiers_aggregate: CompositeChargeTier_Aggregate;
  /** An object relationship */
  PricedProductPricingFactor: PricedProductPricingFactor;
  id: Scalars['uuid'];
  priced_product_pricing_factor_id: Scalars['uuid'];
  quantity: Scalars['Int'];
  type: CompositeChargeTypeEnum_Enum;
};


/** columns and relationships of "CompositeCharge" */
export type CompositeChargeCompositeChargePricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


/** columns and relationships of "CompositeCharge" */
export type CompositeChargeCompositeChargePricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


/** columns and relationships of "CompositeCharge" */
export type CompositeChargeCompositeChargeTiersArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


/** columns and relationships of "CompositeCharge" */
export type CompositeChargeCompositeChargeTiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};

/** columns and relationships of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment = {
  __typename?: 'CompositeChargeAdjustment';
  /** An array relationship */
  CompositeChargeTierAdjustments: Array<CompositeChargeTierAdjustment>;
  /** An aggregate relationship */
  CompositeChargeTierAdjustments_aggregate: CompositeChargeTierAdjustment_Aggregate;
  /** An object relationship */
  PricedProductPricingFactorAdjustment: PricedProductPricingFactorAdjustment;
  adjustment_type_enum: PriceAdjustmentTypeEnum_Enum;
  id: Scalars['uuid'];
  priced_product_pricing_factor_adjustment_id: Scalars['uuid'];
  quantity: Scalars['Int'];
};


/** columns and relationships of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustmentCompositeChargeTierAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


/** columns and relationships of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustmentCompositeChargeTierAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};

/** aggregated selection of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Aggregate = {
  __typename?: 'CompositeChargeAdjustment_aggregate';
  aggregate: Maybe<CompositeChargeAdjustment_Aggregate_Fields>;
  nodes: Array<CompositeChargeAdjustment>;
};

export type CompositeChargeAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompositeChargeAdjustment_Aggregate_Bool_Exp_Count>;
};

export type CompositeChargeAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Aggregate_Fields = {
  __typename?: 'CompositeChargeAdjustment_aggregate_fields';
  avg: Maybe<CompositeChargeAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CompositeChargeAdjustment_Max_Fields>;
  min: Maybe<CompositeChargeAdjustment_Min_Fields>;
  stddev: Maybe<CompositeChargeAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<CompositeChargeAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CompositeChargeAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<CompositeChargeAdjustment_Sum_Fields>;
  var_pop: Maybe<CompositeChargeAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<CompositeChargeAdjustment_Var_Samp_Fields>;
  variance: Maybe<CompositeChargeAdjustment_Variance_Fields>;
};


/** aggregate fields of "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<CompositeChargeAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompositeChargeAdjustment_Max_Order_By>;
  min?: InputMaybe<CompositeChargeAdjustment_Min_Order_By>;
  stddev?: InputMaybe<CompositeChargeAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompositeChargeAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompositeChargeAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompositeChargeAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<CompositeChargeAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompositeChargeAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<CompositeChargeAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Arr_Rel_Insert_Input = {
  data: Array<CompositeChargeAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeChargeAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type CompositeChargeAdjustment_Avg_Fields = {
  __typename?: 'CompositeChargeAdjustment_avg_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompositeChargeAdjustment". All fields are combined with a logical 'AND'. */
export type CompositeChargeAdjustment_Bool_Exp = {
  CompositeChargeTierAdjustments?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
  CompositeChargeTierAdjustments_aggregate?: InputMaybe<CompositeChargeTierAdjustment_Aggregate_Bool_Exp>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  _and?: InputMaybe<Array<CompositeChargeAdjustment_Bool_Exp>>;
  _not?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeChargeAdjustment_Bool_Exp>>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompositeChargeAdjustment" */
export enum CompositeChargeAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompositeChargeAdjustmentPkey = 'CompositeChargeAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Inc_Input = {
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Insert_Input = {
  CompositeChargeTierAdjustments?: InputMaybe<CompositeChargeTierAdjustment_Arr_Rel_Insert_Input>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CompositeChargeAdjustment_Max_Fields = {
  __typename?: 'CompositeChargeAdjustment_max_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompositeChargeAdjustment_Min_Fields = {
  __typename?: 'CompositeChargeAdjustment_min_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Mutation_Response = {
  __typename?: 'CompositeChargeAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeChargeAdjustment>;
};

/** input type for inserting object relation for remote table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Obj_Rel_Insert_Input = {
  data: CompositeChargeAdjustment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeChargeAdjustment_On_Conflict>;
};

/** on_conflict condition type for table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_On_Conflict = {
  constraint: CompositeChargeAdjustment_Constraint;
  update_columns?: Array<CompositeChargeAdjustment_Update_Column>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "CompositeChargeAdjustment". */
export type CompositeChargeAdjustment_Order_By = {
  CompositeChargeTierAdjustments_aggregate?: InputMaybe<CompositeChargeTierAdjustment_Aggregate_Order_By>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Order_By>;
  adjustment_type_enum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompositeChargeAdjustment */
export type CompositeChargeAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompositeChargeAdjustment" */
export enum CompositeChargeAdjustment_Select_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity'
}

/** input type for updating data in table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Set_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CompositeChargeAdjustment_Stddev_Fields = {
  __typename?: 'CompositeChargeAdjustment_stddev_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompositeChargeAdjustment_Stddev_Pop_Fields = {
  __typename?: 'CompositeChargeAdjustment_stddev_pop_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompositeChargeAdjustment_Stddev_Samp_Fields = {
  __typename?: 'CompositeChargeAdjustment_stddev_samp_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeChargeAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeChargeAdjustment_Stream_Cursor_Value_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CompositeChargeAdjustment_Sum_Fields = {
  __typename?: 'CompositeChargeAdjustment_sum_fields';
  quantity: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "CompositeChargeAdjustment" */
export enum CompositeChargeAdjustment_Update_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity'
}

export type CompositeChargeAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompositeChargeAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeChargeAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeChargeAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompositeChargeAdjustment_Var_Pop_Fields = {
  __typename?: 'CompositeChargeAdjustment_var_pop_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompositeChargeAdjustment_Var_Samp_Fields = {
  __typename?: 'CompositeChargeAdjustment_var_samp_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompositeChargeAdjustment_Variance_Fields = {
  __typename?: 'CompositeChargeAdjustment_variance_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompositeChargeAdjustment" */
export type CompositeChargeAdjustment_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

export type CompositeChargeLineItem = Mri_LineItem & {
  __typename?: 'CompositeChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  line_item_chunks: Array<LineItemChunk>;
  pricing_factor: ProductPricingFactor;
  quantity: Scalars['numeric'];
  sub_line_items: Maybe<Array<TierSubLineItem>>;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  target_product_pricing_factors: Array<ProductPricingFactor>;
  total: Scalars['numeric'];
  type: CompositeChargeTypeEnum_Enum;
};

/** columns and relationships of "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor = {
  __typename?: 'CompositeChargePricingFactor';
  /** An object relationship */
  CompositeCharge: CompositeCharge;
  /** An object relationship */
  ProductPricingFactor: ProductPricingFactor;
  composite_charge_id: Scalars['uuid'];
  product_pricing_factor_id: Scalars['uuid'];
};

/** aggregated selection of "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Aggregate = {
  __typename?: 'CompositeChargePricingFactor_aggregate';
  aggregate: Maybe<CompositeChargePricingFactor_Aggregate_Fields>;
  nodes: Array<CompositeChargePricingFactor>;
};

export type CompositeChargePricingFactor_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompositeChargePricingFactor_Aggregate_Bool_Exp_Count>;
};

export type CompositeChargePricingFactor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Aggregate_Fields = {
  __typename?: 'CompositeChargePricingFactor_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CompositeChargePricingFactor_Max_Fields>;
  min: Maybe<CompositeChargePricingFactor_Min_Fields>;
};


/** aggregate fields of "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompositeChargePricingFactor_Max_Order_By>;
  min?: InputMaybe<CompositeChargePricingFactor_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Arr_Rel_Insert_Input = {
  data: Array<CompositeChargePricingFactor_Insert_Input>;
};

/** Boolean expression to filter rows from the table "CompositeChargePricingFactor". All fields are combined with a logical 'AND'. */
export type CompositeChargePricingFactor_Bool_Exp = {
  CompositeCharge?: InputMaybe<CompositeCharge_Bool_Exp>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<CompositeChargePricingFactor_Bool_Exp>>;
  _not?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeChargePricingFactor_Bool_Exp>>;
  composite_charge_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Insert_Input = {
  CompositeCharge?: InputMaybe<CompositeCharge_Obj_Rel_Insert_Input>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Obj_Rel_Insert_Input>;
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CompositeChargePricingFactor_Max_Fields = {
  __typename?: 'CompositeChargePricingFactor_max_fields';
  composite_charge_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Max_Order_By = {
  composite_charge_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompositeChargePricingFactor_Min_Fields = {
  __typename?: 'CompositeChargePricingFactor_min_fields';
  composite_charge_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Min_Order_By = {
  composite_charge_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Mutation_Response = {
  __typename?: 'CompositeChargePricingFactor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeChargePricingFactor>;
};

/** Ordering options when selecting data from "CompositeChargePricingFactor". */
export type CompositeChargePricingFactor_Order_By = {
  CompositeCharge?: InputMaybe<CompositeCharge_Order_By>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Order_By>;
  composite_charge_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
};

/** select columns of table "CompositeChargePricingFactor" */
export enum CompositeChargePricingFactor_Select_Column {
  /** column name */
  CompositeChargeId = 'composite_charge_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id'
}

/** input type for updating data in table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Set_Input = {
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CompositeChargePricingFactor" */
export type CompositeChargePricingFactor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeChargePricingFactor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeChargePricingFactor_Stream_Cursor_Value_Input = {
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
};

export type CompositeChargePricingFactor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeChargePricingFactor_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeChargePricingFactor_Bool_Exp;
};

/** columns and relationships of "CompositeChargeTier" */
export type CompositeChargeTier = {
  __typename?: 'CompositeChargeTier';
  /** An object relationship */
  CompositeCharge: CompositeCharge;
  composite_charge_id: Scalars['uuid'];
  composite_minimum: Scalars['numeric'];
  id: Scalars['uuid'];
  value: Scalars['numeric'];
};

/** columns and relationships of "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment = {
  __typename?: 'CompositeChargeTierAdjustment';
  /** An object relationship */
  CompositeChargeAdjustment: CompositeChargeAdjustment;
  composite_charge_adjustment_id: Scalars['uuid'];
  composite_minimum: Scalars['numeric'];
  id: Scalars['uuid'];
  value: Scalars['numeric'];
};

/** aggregated selection of "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Aggregate = {
  __typename?: 'CompositeChargeTierAdjustment_aggregate';
  aggregate: Maybe<CompositeChargeTierAdjustment_Aggregate_Fields>;
  nodes: Array<CompositeChargeTierAdjustment>;
};

export type CompositeChargeTierAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompositeChargeTierAdjustment_Aggregate_Bool_Exp_Count>;
};

export type CompositeChargeTierAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Aggregate_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_aggregate_fields';
  avg: Maybe<CompositeChargeTierAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CompositeChargeTierAdjustment_Max_Fields>;
  min: Maybe<CompositeChargeTierAdjustment_Min_Fields>;
  stddev: Maybe<CompositeChargeTierAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<CompositeChargeTierAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CompositeChargeTierAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<CompositeChargeTierAdjustment_Sum_Fields>;
  var_pop: Maybe<CompositeChargeTierAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<CompositeChargeTierAdjustment_Var_Samp_Fields>;
  variance: Maybe<CompositeChargeTierAdjustment_Variance_Fields>;
};


/** aggregate fields of "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<CompositeChargeTierAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompositeChargeTierAdjustment_Max_Order_By>;
  min?: InputMaybe<CompositeChargeTierAdjustment_Min_Order_By>;
  stddev?: InputMaybe<CompositeChargeTierAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompositeChargeTierAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompositeChargeTierAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompositeChargeTierAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<CompositeChargeTierAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompositeChargeTierAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<CompositeChargeTierAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Arr_Rel_Insert_Input = {
  data: Array<CompositeChargeTierAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeChargeTierAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type CompositeChargeTierAdjustment_Avg_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_avg_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Avg_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompositeChargeTierAdjustment". All fields are combined with a logical 'AND'. */
export type CompositeChargeTierAdjustment_Bool_Exp = {
  CompositeChargeAdjustment?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
  _and?: InputMaybe<Array<CompositeChargeTierAdjustment_Bool_Exp>>;
  _not?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeChargeTierAdjustment_Bool_Exp>>;
  composite_charge_adjustment_id?: InputMaybe<Uuid_Comparison_Exp>;
  composite_minimum?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompositeChargeTierAdjustment" */
export enum CompositeChargeTierAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompositeChargeTierAdjustmentPkey = 'CompositeChargeTierAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Inc_Input = {
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Insert_Input = {
  CompositeChargeAdjustment?: InputMaybe<CompositeChargeAdjustment_Obj_Rel_Insert_Input>;
  composite_charge_adjustment_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CompositeChargeTierAdjustment_Max_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_max_fields';
  composite_charge_adjustment_id: Maybe<Scalars['uuid']>;
  composite_minimum: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Max_Order_By = {
  composite_charge_adjustment_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompositeChargeTierAdjustment_Min_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_min_fields';
  composite_charge_adjustment_id: Maybe<Scalars['uuid']>;
  composite_minimum: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Min_Order_By = {
  composite_charge_adjustment_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Mutation_Response = {
  __typename?: 'CompositeChargeTierAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeChargeTierAdjustment>;
};

/** on_conflict condition type for table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_On_Conflict = {
  constraint: CompositeChargeTierAdjustment_Constraint;
  update_columns?: Array<CompositeChargeTierAdjustment_Update_Column>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "CompositeChargeTierAdjustment". */
export type CompositeChargeTierAdjustment_Order_By = {
  CompositeChargeAdjustment?: InputMaybe<CompositeChargeAdjustment_Order_By>;
  composite_charge_adjustment_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompositeChargeTierAdjustment */
export type CompositeChargeTierAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompositeChargeTierAdjustment" */
export enum CompositeChargeTierAdjustment_Select_Column {
  /** column name */
  CompositeChargeAdjustmentId = 'composite_charge_adjustment_id',
  /** column name */
  CompositeMinimum = 'composite_minimum',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Set_Input = {
  composite_charge_adjustment_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CompositeChargeTierAdjustment_Stddev_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_stddev_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Stddev_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompositeChargeTierAdjustment_Stddev_Pop_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_stddev_pop_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Stddev_Pop_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompositeChargeTierAdjustment_Stddev_Samp_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_stddev_samp_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Stddev_Samp_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeChargeTierAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeChargeTierAdjustment_Stream_Cursor_Value_Input = {
  composite_charge_adjustment_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type CompositeChargeTierAdjustment_Sum_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_sum_fields';
  composite_minimum: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Sum_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "CompositeChargeTierAdjustment" */
export enum CompositeChargeTierAdjustment_Update_Column {
  /** column name */
  CompositeChargeAdjustmentId = 'composite_charge_adjustment_id',
  /** column name */
  CompositeMinimum = 'composite_minimum',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type CompositeChargeTierAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompositeChargeTierAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeChargeTierAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeChargeTierAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompositeChargeTierAdjustment_Var_Pop_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_var_pop_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Var_Pop_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompositeChargeTierAdjustment_Var_Samp_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_var_samp_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Var_Samp_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompositeChargeTierAdjustment_Variance_Fields = {
  __typename?: 'CompositeChargeTierAdjustment_variance_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompositeChargeTierAdjustment" */
export type CompositeChargeTierAdjustment_Variance_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregated selection of "CompositeChargeTier" */
export type CompositeChargeTier_Aggregate = {
  __typename?: 'CompositeChargeTier_aggregate';
  aggregate: Maybe<CompositeChargeTier_Aggregate_Fields>;
  nodes: Array<CompositeChargeTier>;
};

export type CompositeChargeTier_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompositeChargeTier_Aggregate_Bool_Exp_Count>;
};

export type CompositeChargeTier_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompositeChargeTier_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompositeChargeTier" */
export type CompositeChargeTier_Aggregate_Fields = {
  __typename?: 'CompositeChargeTier_aggregate_fields';
  avg: Maybe<CompositeChargeTier_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CompositeChargeTier_Max_Fields>;
  min: Maybe<CompositeChargeTier_Min_Fields>;
  stddev: Maybe<CompositeChargeTier_Stddev_Fields>;
  stddev_pop: Maybe<CompositeChargeTier_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CompositeChargeTier_Stddev_Samp_Fields>;
  sum: Maybe<CompositeChargeTier_Sum_Fields>;
  var_pop: Maybe<CompositeChargeTier_Var_Pop_Fields>;
  var_samp: Maybe<CompositeChargeTier_Var_Samp_Fields>;
  variance: Maybe<CompositeChargeTier_Variance_Fields>;
};


/** aggregate fields of "CompositeChargeTier" */
export type CompositeChargeTier_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositeChargeTier" */
export type CompositeChargeTier_Aggregate_Order_By = {
  avg?: InputMaybe<CompositeChargeTier_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompositeChargeTier_Max_Order_By>;
  min?: InputMaybe<CompositeChargeTier_Min_Order_By>;
  stddev?: InputMaybe<CompositeChargeTier_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompositeChargeTier_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompositeChargeTier_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompositeChargeTier_Sum_Order_By>;
  var_pop?: InputMaybe<CompositeChargeTier_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompositeChargeTier_Var_Samp_Order_By>;
  variance?: InputMaybe<CompositeChargeTier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompositeChargeTier" */
export type CompositeChargeTier_Arr_Rel_Insert_Input = {
  data: Array<CompositeChargeTier_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeChargeTier_On_Conflict>;
};

/** aggregate avg on columns */
export type CompositeChargeTier_Avg_Fields = {
  __typename?: 'CompositeChargeTier_avg_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Avg_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompositeChargeTier". All fields are combined with a logical 'AND'. */
export type CompositeChargeTier_Bool_Exp = {
  CompositeCharge?: InputMaybe<CompositeCharge_Bool_Exp>;
  _and?: InputMaybe<Array<CompositeChargeTier_Bool_Exp>>;
  _not?: InputMaybe<CompositeChargeTier_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeChargeTier_Bool_Exp>>;
  composite_charge_id?: InputMaybe<Uuid_Comparison_Exp>;
  composite_minimum?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompositeChargeTier" */
export enum CompositeChargeTier_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompositeChargeTierPkey = 'CompositeChargeTier_pkey'
}

/** input type for incrementing numeric columns in table "CompositeChargeTier" */
export type CompositeChargeTier_Inc_Input = {
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CompositeChargeTier" */
export type CompositeChargeTier_Insert_Input = {
  CompositeCharge?: InputMaybe<CompositeCharge_Obj_Rel_Insert_Input>;
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CompositeChargeTier_Max_Fields = {
  __typename?: 'CompositeChargeTier_max_fields';
  composite_charge_id: Maybe<Scalars['uuid']>;
  composite_minimum: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Max_Order_By = {
  composite_charge_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompositeChargeTier_Min_Fields = {
  __typename?: 'CompositeChargeTier_min_fields';
  composite_charge_id: Maybe<Scalars['uuid']>;
  composite_minimum: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Min_Order_By = {
  composite_charge_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompositeChargeTier" */
export type CompositeChargeTier_Mutation_Response = {
  __typename?: 'CompositeChargeTier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeChargeTier>;
};

/** on_conflict condition type for table "CompositeChargeTier" */
export type CompositeChargeTier_On_Conflict = {
  constraint: CompositeChargeTier_Constraint;
  update_columns?: Array<CompositeChargeTier_Update_Column>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};

/** Ordering options when selecting data from "CompositeChargeTier". */
export type CompositeChargeTier_Order_By = {
  CompositeCharge?: InputMaybe<CompositeCharge_Order_By>;
  composite_charge_id?: InputMaybe<Order_By>;
  composite_minimum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompositeChargeTier */
export type CompositeChargeTier_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompositeChargeTier" */
export enum CompositeChargeTier_Select_Column {
  /** column name */
  CompositeChargeId = 'composite_charge_id',
  /** column name */
  CompositeMinimum = 'composite_minimum',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CompositeChargeTier" */
export type CompositeChargeTier_Set_Input = {
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CompositeChargeTier_Stddev_Fields = {
  __typename?: 'CompositeChargeTier_stddev_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Stddev_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompositeChargeTier_Stddev_Pop_Fields = {
  __typename?: 'CompositeChargeTier_stddev_pop_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Stddev_Pop_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompositeChargeTier_Stddev_Samp_Fields = {
  __typename?: 'CompositeChargeTier_stddev_samp_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Stddev_Samp_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompositeChargeTier" */
export type CompositeChargeTier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeChargeTier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeChargeTier_Stream_Cursor_Value_Input = {
  composite_charge_id?: InputMaybe<Scalars['uuid']>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type CompositeChargeTier_Sum_Fields = {
  __typename?: 'CompositeChargeTier_sum_fields';
  composite_minimum: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Sum_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "CompositeChargeTier" */
export enum CompositeChargeTier_Update_Column {
  /** column name */
  CompositeChargeId = 'composite_charge_id',
  /** column name */
  CompositeMinimum = 'composite_minimum',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type CompositeChargeTier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompositeChargeTier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeChargeTier_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeChargeTier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompositeChargeTier_Var_Pop_Fields = {
  __typename?: 'CompositeChargeTier_var_pop_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Var_Pop_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompositeChargeTier_Var_Samp_Fields = {
  __typename?: 'CompositeChargeTier_var_samp_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Var_Samp_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompositeChargeTier_Variance_Fields = {
  __typename?: 'CompositeChargeTier_variance_fields';
  composite_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompositeChargeTier" */
export type CompositeChargeTier_Variance_Order_By = {
  composite_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum = {
  __typename?: 'CompositeChargeTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Aggregate = {
  __typename?: 'CompositeChargeTypeEnum_aggregate';
  aggregate: Maybe<CompositeChargeTypeEnum_Aggregate_Fields>;
  nodes: Array<CompositeChargeTypeEnum>;
};

/** aggregate fields of "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Aggregate_Fields = {
  __typename?: 'CompositeChargeTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CompositeChargeTypeEnum_Max_Fields>;
  min: Maybe<CompositeChargeTypeEnum_Min_Fields>;
};


/** aggregate fields of "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeChargeTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CompositeChargeTypeEnum". All fields are combined with a logical 'AND'. */
export type CompositeChargeTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<CompositeChargeTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeChargeTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompositeChargeTypeEnum" */
export enum CompositeChargeTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  CompositeChargeTypeEnumPkey = 'CompositeChargeTypeEnum_pkey'
}

export enum CompositeChargeTypeEnum_Enum {
  Minimum = 'MINIMUM',
  Percentage = 'PERCENTAGE'
}

/** Boolean expression to compare columns of type "CompositeChargeTypeEnum_enum". All fields are combined with logical 'AND'. */
export type CompositeChargeTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CompositeChargeTypeEnum_Enum>;
  _in?: InputMaybe<Array<CompositeChargeTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CompositeChargeTypeEnum_Enum>;
  _nin?: InputMaybe<Array<CompositeChargeTypeEnum_Enum>>;
};

/** input type for inserting data into table "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CompositeChargeTypeEnum_Max_Fields = {
  __typename?: 'CompositeChargeTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CompositeChargeTypeEnum_Min_Fields = {
  __typename?: 'CompositeChargeTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Mutation_Response = {
  __typename?: 'CompositeChargeTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeChargeTypeEnum>;
};

/** on_conflict condition type for table "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_On_Conflict = {
  constraint: CompositeChargeTypeEnum_Constraint;
  update_columns?: Array<CompositeChargeTypeEnum_Update_Column>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "CompositeChargeTypeEnum". */
export type CompositeChargeTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompositeChargeTypeEnum */
export type CompositeChargeTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CompositeChargeTypeEnum" */
export enum CompositeChargeTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CompositeChargeTypeEnum" */
export type CompositeChargeTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeChargeTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeChargeTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CompositeChargeTypeEnum" */
export enum CompositeChargeTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type CompositeChargeTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeChargeTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeChargeTypeEnum_Bool_Exp;
};

/** aggregated selection of "CompositeCharge" */
export type CompositeCharge_Aggregate = {
  __typename?: 'CompositeCharge_aggregate';
  aggregate: Maybe<CompositeCharge_Aggregate_Fields>;
  nodes: Array<CompositeCharge>;
};

export type CompositeCharge_Aggregate_Bool_Exp = {
  count?: InputMaybe<CompositeCharge_Aggregate_Bool_Exp_Count>;
};

export type CompositeCharge_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompositeCharge_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CompositeCharge" */
export type CompositeCharge_Aggregate_Fields = {
  __typename?: 'CompositeCharge_aggregate_fields';
  avg: Maybe<CompositeCharge_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CompositeCharge_Max_Fields>;
  min: Maybe<CompositeCharge_Min_Fields>;
  stddev: Maybe<CompositeCharge_Stddev_Fields>;
  stddev_pop: Maybe<CompositeCharge_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CompositeCharge_Stddev_Samp_Fields>;
  sum: Maybe<CompositeCharge_Sum_Fields>;
  var_pop: Maybe<CompositeCharge_Var_Pop_Fields>;
  var_samp: Maybe<CompositeCharge_Var_Samp_Fields>;
  variance: Maybe<CompositeCharge_Variance_Fields>;
};


/** aggregate fields of "CompositeCharge" */
export type CompositeCharge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CompositeCharge" */
export type CompositeCharge_Aggregate_Order_By = {
  avg?: InputMaybe<CompositeCharge_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CompositeCharge_Max_Order_By>;
  min?: InputMaybe<CompositeCharge_Min_Order_By>;
  stddev?: InputMaybe<CompositeCharge_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CompositeCharge_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CompositeCharge_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CompositeCharge_Sum_Order_By>;
  var_pop?: InputMaybe<CompositeCharge_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CompositeCharge_Var_Samp_Order_By>;
  variance?: InputMaybe<CompositeCharge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompositeCharge" */
export type CompositeCharge_Arr_Rel_Insert_Input = {
  data: Array<CompositeCharge_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeCharge_On_Conflict>;
};

/** aggregate avg on columns */
export type CompositeCharge_Avg_Fields = {
  __typename?: 'CompositeCharge_avg_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CompositeCharge" */
export type CompositeCharge_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CompositeCharge". All fields are combined with a logical 'AND'. */
export type CompositeCharge_Bool_Exp = {
  CompositeChargePricingFactors?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
  CompositeChargePricingFactors_aggregate?: InputMaybe<CompositeChargePricingFactor_Aggregate_Bool_Exp>;
  CompositeChargeTiers?: InputMaybe<CompositeChargeTier_Bool_Exp>;
  CompositeChargeTiers_aggregate?: InputMaybe<CompositeChargeTier_Aggregate_Bool_Exp>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<CompositeCharge_Bool_Exp>>;
  _not?: InputMaybe<CompositeCharge_Bool_Exp>;
  _or?: InputMaybe<Array<CompositeCharge_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priced_product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<CompositeChargeTypeEnum_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompositeCharge" */
export enum CompositeCharge_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompositeChargePkey = 'CompositeCharge_pkey'
}

/** input type for incrementing numeric columns in table "CompositeCharge" */
export type CompositeCharge_Inc_Input = {
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CompositeCharge" */
export type CompositeCharge_Insert_Input = {
  CompositeChargePricingFactors?: InputMaybe<CompositeChargePricingFactor_Arr_Rel_Insert_Input>;
  CompositeChargeTiers?: InputMaybe<CompositeChargeTier_Arr_Rel_Insert_Input>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CompositeChargeTypeEnum_Enum>;
};

/** aggregate max on columns */
export type CompositeCharge_Max_Fields = {
  __typename?: 'CompositeCharge_max_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "CompositeCharge" */
export type CompositeCharge_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CompositeCharge_Min_Fields = {
  __typename?: 'CompositeCharge_min_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "CompositeCharge" */
export type CompositeCharge_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CompositeCharge" */
export type CompositeCharge_Mutation_Response = {
  __typename?: 'CompositeCharge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompositeCharge>;
};

/** input type for inserting object relation for remote table "CompositeCharge" */
export type CompositeCharge_Obj_Rel_Insert_Input = {
  data: CompositeCharge_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CompositeCharge_On_Conflict>;
};

/** on_conflict condition type for table "CompositeCharge" */
export type CompositeCharge_On_Conflict = {
  constraint: CompositeCharge_Constraint;
  update_columns?: Array<CompositeCharge_Update_Column>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};

/** Ordering options when selecting data from "CompositeCharge". */
export type CompositeCharge_Order_By = {
  CompositeChargePricingFactors_aggregate?: InputMaybe<CompositeChargePricingFactor_Aggregate_Order_By>;
  CompositeChargeTiers_aggregate?: InputMaybe<CompositeChargeTier_Aggregate_Order_By>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CompositeCharge */
export type CompositeCharge_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompositeCharge" */
export enum CompositeCharge_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "CompositeCharge" */
export type CompositeCharge_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CompositeChargeTypeEnum_Enum>;
};

/** aggregate stddev on columns */
export type CompositeCharge_Stddev_Fields = {
  __typename?: 'CompositeCharge_stddev_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CompositeCharge" */
export type CompositeCharge_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CompositeCharge_Stddev_Pop_Fields = {
  __typename?: 'CompositeCharge_stddev_pop_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CompositeCharge" */
export type CompositeCharge_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CompositeCharge_Stddev_Samp_Fields = {
  __typename?: 'CompositeCharge_stddev_samp_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CompositeCharge" */
export type CompositeCharge_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CompositeCharge" */
export type CompositeCharge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CompositeCharge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CompositeCharge_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<CompositeChargeTypeEnum_Enum>;
};

/** aggregate sum on columns */
export type CompositeCharge_Sum_Fields = {
  __typename?: 'CompositeCharge_sum_fields';
  quantity: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CompositeCharge" */
export type CompositeCharge_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "CompositeCharge" */
export enum CompositeCharge_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Type = 'type'
}

export type CompositeCharge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompositeCharge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompositeCharge_Set_Input>;
  /** filter the rows which have to be updated */
  where: CompositeCharge_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CompositeCharge_Var_Pop_Fields = {
  __typename?: 'CompositeCharge_var_pop_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CompositeCharge" */
export type CompositeCharge_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CompositeCharge_Var_Samp_Fields = {
  __typename?: 'CompositeCharge_var_samp_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CompositeCharge" */
export type CompositeCharge_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CompositeCharge_Variance_Fields = {
  __typename?: 'CompositeCharge_variance_fields';
  quantity: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CompositeCharge" */
export type CompositeCharge_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
};

export type CompositeProductListItem = {
  __typename?: 'CompositeProductListItem';
  archived_at: Maybe<Scalars['timestamptz']>;
  current: CompositeProductListItemState;
  id: Scalars['uuid'];
  initial: CompositeProductListItemState;
  managed_fields: Array<ContractProductManagedField>;
  updates: Array<ProductListItemUpdate>;
};

export type CompositeProductListItemState = {
  __typename?: 'CompositeProductListItemState';
  Creator: Actor;
  composite_products: Maybe<Array<NonCompositeProductListItem>>;
  composite_tags: Maybe<Array<Scalars['String']>>;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  exclude_free_usage: Scalars['Boolean'];
  is_refundable: Scalars['Boolean'];
  name: Scalars['String'];
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  netsuite_overage_item_id: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  Creator: Actor;
  amendments: Array<ContractAmendment>;
  archived_at: Maybe<Scalars['timestamptz']>;
  commits: Array<Commit>;
  commits_union: Array<CommitUnion>;
  created_at: Scalars['timestamptz'];
  customer: Customer;
  customer_billing_provider_configuration: Maybe<CustomerBillingProviderConfiguration>;
  discounts: Array<Discount>;
  ending_before: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  managed_fields: Array<ContractManagedField>;
  multiplier_override_prioritization: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  named_schedule: Array<NamedScheduleSegment>;
  named_schedules: Array<NamedSchedule>;
  net_payment_terms_days: Maybe<Scalars['Int']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  overrides: Array<RateCardEntryOverride>;
  pro_services: Array<ProService>;
  rate_card: Maybe<RateCard>;
  rate_schedule: ContractRateSchedule;
  reseller_royalties: Array<ResellerRoyalty>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
  scheduled_charges: Array<ScheduledCharge>;
  starting_at: Scalars['timestamptz'];
  total_contract_value: Maybe<Scalars['numeric']>;
  transitions: Array<Transition>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  usage_filter: Maybe<UsageFilter>;
  usage_invoice_schedule: ContractUsageInvoiceSchedule;
};


export type ContractNamed_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};


export type ContractRate_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  selectors?: InputMaybe<Array<RateScheduleSelector>>;
};

/**
 * Represents a professional service (PS&T) reseller royalty charge.
 *
 * These show up on AWS ProService invoices (which are created via API requests),
 * and on scheduled invoices when the ProServices are paid upfront.
 */
export type ContractAwsProServiceRoyaltyLineItem = Mri_LineItem & {
  __typename?: 'ContractAWSProServiceRoyaltyLineItem';
  breakdown: Maybe<Array<ContractAwsProServiceRoyaltyLineItem>>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Maybe<Scalars['timestamptz']>;
  inclusive_start_date: Maybe<Scalars['timestamptz']>;
  quantity: Scalars['numeric'];
  reseller_royalty: AwsProServiceRoyalty;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/**
 * Represents a reseller royalty charge.
 *
 * These only show up on AWS royalty invoices (a specific type of ContractUsageInvoice),
 * which also include the corresponding usage or fixed charges.
 * The usage or fixed charges with royalties do not appear on other normal usage invoices.
 */
export type ContractAwsRoyaltyLineItem = Mri_LineItem & {
  __typename?: 'ContractAWSRoyaltyLineItem';
  breakdown: Maybe<Array<ContractAwsRoyaltyLineItem>>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Maybe<Scalars['timestamptz']>;
  inclusive_start_date: Maybe<Scalars['timestamptz']>;
  quantity: Scalars['numeric'];
  reseller_royalty: AwsRoyalty;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractAmendment = {
  __typename?: 'ContractAmendment';
  Creator: Actor;
  commits: Array<Commit>;
  commits_union: Array<CommitUnion>;
  contract: Contract;
  created_at: Scalars['timestamptz'];
  discounts: Array<Discount>;
  effective_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  multiplier_override_prioritization: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  overrides: Array<RateCardEntryOverride>;
  pro_services: Array<ProService>;
  reseller_royalties: Array<ResellerRoyaltyOrUpdate>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
  scheduled_charges: Array<ScheduledCharge>;
  total_contract_value: Maybe<Scalars['numeric']>;
};

/**
 * Represents drawdown from a prepaid or postpaid commit.
 *
 * These each correspond to a ContractUsageLineItem, modulo merging.
 */
export type ContractAppliedCommitLineItem = Mri_LineItem & {
  __typename?: 'ContractAppliedCommitLineItem';
  breakdown: Maybe<Array<ContractAppliedCommitLineItem>>;
  commit_with_segment: CommitWithSegment;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  presentation_group_values: Maybe<Array<PresentationGroupValue>>;
  presentation_groups: Maybe<Scalars['RecordOfNullableStrings']>;
  pricing_group_values: Maybe<Array<PricingGroupValue>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product: ContractUsageLineItemProductListItem;
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  total: Scalars['numeric'];
};

/** Represents a scheduled charge (not usage-based) on an invoice. */
export type ContractChargeLineItem = Mri_LineItem & {
  __typename?: 'ContractChargeLineItem';
  charge: ScheduledCharge;
  credit_type: CreditType;
  display_name: Scalars['String'];
  product: FixedOrProServiceProductListItem;
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/** Represents purchasing a prepaid commit. */
export type ContractCommitLineItem = Mri_LineItem & {
  __typename?: 'ContractCommitLineItem';
  commit: Commit;
  commit_union: CommitUnion;
  credit_type: CreditType;
  display_name: Scalars['String'];
  product: FixedProductListItem;
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractCommitMappingInput = {
  contract_id: Scalars['uuid'];
  credit_grant_id: Scalars['uuid'];
  product_id: Scalars['uuid'];
};

export type ContractConsistency = {
  __typename?: 'ContractConsistency';
  billing_provider_projection_inconsistencies: Array<ContractConsistency_OutdatedBillingProviderProjection>;
  rate_schedule_inconsistencies: Array<ContractConsistency_OutdatedRate>;
};


export type ContractConsistencyBilling_Provider_Projection_InconsistenciesArgs = {
  limit: Scalars['Int'];
};


export type ContractConsistencyRate_Schedule_InconsistenciesArgs = {
  limit: Scalars['Int'];
};

export type ContractConsistency_OutdatedBillingProviderProjection = {
  __typename?: 'ContractConsistency_OutdatedBillingProviderProjection';
  customer_id: Scalars['uuid'];
  latest_event_version: Scalars['Int'];
  latest_projection_version: Maybe<Scalars['Int']>;
};

export type ContractConsistency_OutdatedRate = {
  __typename?: 'ContractConsistency_OutdatedRate';
  earliest_rate_added_version: Maybe<Scalars['Int']>;
  earliest_schedule_segment_version: Maybe<Scalars['Int']>;
  latest_rate_added_version: Maybe<Scalars['Int']>;
  latest_schedule_segment_version: Maybe<Scalars['Int']>;
  pricing_group_values: Maybe<Array<RateCardEntryPricingGroupValueEntry>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product_id: Scalars['uuid'];
  rate_card_id: Scalars['uuid'];
};

export type ContractConversionLineItem = Mri_LineItem & {
  __typename?: 'ContractConversionLineItem';
  breakdown: Maybe<Array<ContractConversionLineItem>>;
  commit_with_segment: Maybe<CommitWithSegment>;
  credit_type: CreditType;
  custom_credit_type: CreditType;
  /**
   * Represents a credit type "conversion" line item, usually used when charging
   * overages on a custom pricing unit.
   */
  display_name: Scalars['String'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  pricing_group_values: Maybe<Array<PricingGroupValue>>;
  product: ContractUsageLineItemProductListItem;
  quantity: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  tier_metadata: Maybe<TierMetadata>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/** columns and relationships of "ContractCreditManagedField" */
export type ContractCreditManagedField = {
  __typename?: 'ContractCreditManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  contract_credit_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ContractCreditManagedField" */
export type ContractCreditManagedField_Aggregate = {
  __typename?: 'ContractCreditManagedField_aggregate';
  aggregate: Maybe<ContractCreditManagedField_Aggregate_Fields>;
  nodes: Array<ContractCreditManagedField>;
};

export type ContractCreditManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ContractCreditManagedField_Aggregate_Bool_Exp_Count>;
};

export type ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractCreditManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ContractCreditManagedField" */
export type ContractCreditManagedField_Aggregate_Fields = {
  __typename?: 'ContractCreditManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ContractCreditManagedField_Max_Fields>;
  min: Maybe<ContractCreditManagedField_Min_Fields>;
};


/** aggregate fields of "ContractCreditManagedField" */
export type ContractCreditManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ContractCreditManagedField" */
export type ContractCreditManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractCreditManagedField_Max_Order_By>;
  min?: InputMaybe<ContractCreditManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ContractCreditManagedField" */
export type ContractCreditManagedField_Arr_Rel_Insert_Input = {
  data: Array<ContractCreditManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractCreditManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ContractCreditManagedField". All fields are combined with a logical 'AND'. */
export type ContractCreditManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ContractCreditManagedField_Bool_Exp>>;
  _not?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ContractCreditManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_credit_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractCreditManagedField" */
export enum ContractCreditManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "contract_credit_id" */
  ContractCreditManagedFieldContractCreditIdKeyIdKey = 'ContractCreditManagedField_contract_credit_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ContractCreditManagedFieldKeyIdValueEnforceUniquenessKey = 'ContractCreditManagedField_key_id_value_enforce_uniqueness__key',
  /** unique or primary key constraint on columns "id" */
  ContractCreditManagedFieldPkey = 'ContractCreditManagedField_pkey'
}

/** input type for inserting data into table "ContractCreditManagedField" */
export type ContractCreditManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_credit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractCreditManagedField_Max_Fields = {
  __typename?: 'ContractCreditManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_credit_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ContractCreditManagedField" */
export type ContractCreditManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_credit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractCreditManagedField_Min_Fields = {
  __typename?: 'ContractCreditManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_credit_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ContractCreditManagedField" */
export type ContractCreditManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_credit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ContractCreditManagedField" */
export type ContractCreditManagedField_Mutation_Response = {
  __typename?: 'ContractCreditManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractCreditManagedField>;
};

/** on_conflict condition type for table "ContractCreditManagedField" */
export type ContractCreditManagedField_On_Conflict = {
  constraint: ContractCreditManagedField_Constraint;
  update_columns?: Array<ContractCreditManagedField_Update_Column>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractCreditManagedField". */
export type ContractCreditManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  contract_credit_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractCreditManagedField */
export type ContractCreditManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ContractCreditManagedField" */
export enum ContractCreditManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractCreditId = 'contract_credit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ContractCreditManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ContractCreditManagedField" */
export enum ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ContractCreditManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ContractCreditManagedField" */
export enum ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ContractCreditManagedField" */
export type ContractCreditManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_credit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ContractCreditManagedField" */
export type ContractCreditManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractCreditManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractCreditManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_credit_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ContractCreditManagedField" */
export enum ContractCreditManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractCreditId = 'contract_credit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ContractCreditManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractCreditManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractCreditManagedField_Bool_Exp;
};

/** Represents a discount applied to an invoice. These can only be applied to fixed product charges. */
export type ContractDiscountLineItem = Mri_LineItem & {
  __typename?: 'ContractDiscountLineItem';
  credit_type: CreditType;
  discount: Discount;
  display_name: Scalars['String'];
  product: FixedProductListItem;
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/** See comment on ContractAWSProServiceRoyaltyLineItem. */
export type ContractGcpProServiceRoyaltyLineItem = Mri_LineItem & {
  __typename?: 'ContractGCPProServiceRoyaltyLineItem';
  breakdown: Maybe<Array<ContractGcpProServiceRoyaltyLineItem>>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Maybe<Scalars['timestamptz']>;
  inclusive_start_date: Maybe<Scalars['timestamptz']>;
  quantity: Scalars['numeric'];
  reseller_royalty: GcpProServiceRoyalty;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/** See comment on ContractAWSRoyaltyLineItem. */
export type ContractGcpRoyaltyLineItem = Mri_LineItem & {
  __typename?: 'ContractGCPRoyaltyLineItem';
  breakdown: Maybe<Array<ContractGcpRoyaltyLineItem>>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Maybe<Scalars['timestamptz']>;
  inclusive_start_date: Maybe<Scalars['timestamptz']>;
  quantity: Scalars['numeric'];
  reseller_royalty: GcpRoyalty;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

/** columns and relationships of "ContractManagedField" */
export type ContractManagedField = {
  __typename?: 'ContractManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  contract_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ContractManagedField" */
export type ContractManagedField_Aggregate = {
  __typename?: 'ContractManagedField_aggregate';
  aggregate: Maybe<ContractManagedField_Aggregate_Fields>;
  nodes: Array<ContractManagedField>;
};

export type ContractManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ContractManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ContractManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ContractManagedField_Aggregate_Bool_Exp_Count>;
};

export type ContractManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ContractManagedField" */
export type ContractManagedField_Aggregate_Fields = {
  __typename?: 'ContractManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ContractManagedField_Max_Fields>;
  min: Maybe<ContractManagedField_Min_Fields>;
};


/** aggregate fields of "ContractManagedField" */
export type ContractManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ContractManagedField" */
export type ContractManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractManagedField_Max_Order_By>;
  min?: InputMaybe<ContractManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ContractManagedField" */
export type ContractManagedField_Arr_Rel_Insert_Input = {
  data: Array<ContractManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ContractManagedField". All fields are combined with a logical 'AND'. */
export type ContractManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ContractManagedField_Bool_Exp>>;
  _not?: InputMaybe<ContractManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ContractManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractManagedField" */
export enum ContractManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "contract_id" */
  ContractManagedFieldContractIdKeyIdKey = 'ContractManagedField_contract_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ContractManagedFieldKeyIdValueEnforceUniquenessClientKey = 'ContractManagedField_key_id_value_enforce_uniqueness_client_key',
  /** unique or primary key constraint on columns "id" */
  ContractManagedFieldPkey = 'ContractManagedField_pkey'
}

/** input type for inserting data into table "ContractManagedField" */
export type ContractManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractManagedField_Max_Fields = {
  __typename?: 'ContractManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ContractManagedField" */
export type ContractManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractManagedField_Min_Fields = {
  __typename?: 'ContractManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ContractManagedField" */
export type ContractManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ContractManagedField" */
export type ContractManagedField_Mutation_Response = {
  __typename?: 'ContractManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractManagedField>;
};

/** on_conflict condition type for table "ContractManagedField" */
export type ContractManagedField_On_Conflict = {
  constraint: ContractManagedField_Constraint;
  update_columns?: Array<ContractManagedField_Update_Column>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractManagedField". */
export type ContractManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  contract_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractManagedField */
export type ContractManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ContractManagedField" */
export enum ContractManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ContractManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ContractManagedField" */
export enum ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ContractManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ContractManagedField" */
export enum ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ContractManagedField" */
export type ContractManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ContractManagedField" */
export type ContractManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ContractManagedField" */
export enum ContractManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractId = 'contract_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ContractManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractManagedField_Bool_Exp;
};

export type ContractPage = {
  __typename?: 'ContractPage';
  contracts: Array<Contract>;
  cursor: Maybe<Scalars['String']>;
};

export type ContractPostpaidTrueupInvoice = Mri_Invoice & {
  __typename?: 'ContractPostpaidTrueupInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  corrections: Array<CorrectionInvoice>;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  regenerated_from: Maybe<ContractPostpaidTrueupInvoice>;
  regenerated_to: Maybe<ContractPostpaidTrueupInvoice>;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ContractPostpaidTrueupInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

/**
 * Trueup invoices are issued for postpaid commits and include any difference between the
 * actually-charged usage and the commit obligation.
 */
export type ContractPostpaidTrueupLineItem = Mri_LineItem & {
  __typename?: 'ContractPostpaidTrueupLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  postpaid_commit: PostpaidCommit;
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractPricing = {
  __typename?: 'ContractPricing';
  consistency: ContractConsistency;
  pricing_group_keys_in_use: Scalars['Boolean'];
  product: ProductListItem;
  products: Array<ProductListItem>;
  rate_card: RateCardMetadata;
  rate_cards: Array<RateCardMetadata>;
};


export type ContractPricingProductArgs = {
  id: Scalars['uuid'];
};


export type ContractPricingProductsArgs = {
  archived?: InputMaybe<ProductListItemArchivedFilter>;
  ids?: InputMaybe<Array<Scalars['uuid']>>;
  types?: InputMaybe<Array<ProductListItemTypeFilter>>;
};


export type ContractPricingRate_CardArgs = {
  id: Scalars['uuid'];
};


export type ContractPricingRate_CardsArgs = {
  archived?: InputMaybe<RateCardArchivedFilter>;
};

export type ContractProServiceInvoice = Mri_Invoice & {
  __typename?: 'ContractProServiceInvoice';
  amendment: Maybe<ContractAmendment>;
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  netsuite_invoice_header_end: Maybe<Scalars['timestamptz']>;
  netsuite_invoice_header_start: Maybe<Scalars['timestamptz']>;
  on_hold: Scalars['Boolean'];
  regenerated_from: Maybe<ContractProServiceInvoice>;
  regenerated_to: Maybe<ContractProServiceInvoice>;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ContractProServiceInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type ContractProServiceInvoicesInput = {
  amendment_id?: InputMaybe<Scalars['uuid']>;
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<ContractProServiceLineItemInput>;
  netsuite_invoice_header_end?: InputMaybe<Scalars['timestamptz']>;
  netsuite_invoice_header_start?: InputMaybe<Scalars['timestamptz']>;
};

export type ContractProServiceLineItem = Mri_LineItem & {
  __typename?: 'ContractProServiceLineItem';
  credit_type: CreditType;
  /**
   * Represents a "milestone" or "time & material" charge for a professional service
   * term on a contract
   */
  display_name: Scalars['String'];
  metadata: Maybe<Scalars['String']>;
  netsuite_invoice_billing_end: Maybe<Scalars['timestamptz']>;
  netsuite_invoice_billing_start: Maybe<Scalars['timestamptz']>;
  pro_service: ProService;
  product: ProServiceProductListItem;
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractProServiceLineItemInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  metadata?: InputMaybe<Scalars['String']>;
  netsuite_invoice_billing_end?: InputMaybe<Scalars['timestamptz']>;
  netsuite_invoice_billing_start?: InputMaybe<Scalars['timestamptz']>;
  pro_service_term_id: Scalars['uuid'];
  quantity?: InputMaybe<Scalars['numeric']>;
  unit_price?: InputMaybe<Scalars['numeric']>;
};

/** columns and relationships of "ContractProductManagedField" */
export type ContractProductManagedField = {
  __typename?: 'ContractProductManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  contract_product_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ContractProductManagedField" */
export type ContractProductManagedField_Aggregate = {
  __typename?: 'ContractProductManagedField_aggregate';
  aggregate: Maybe<ContractProductManagedField_Aggregate_Fields>;
  nodes: Array<ContractProductManagedField>;
};

export type ContractProductManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ContractProductManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ContractProductManagedField_Aggregate_Bool_Exp_Count>;
};

export type ContractProductManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractProductManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractProductManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ContractProductManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ContractProductManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ContractProductManagedField" */
export type ContractProductManagedField_Aggregate_Fields = {
  __typename?: 'ContractProductManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ContractProductManagedField_Max_Fields>;
  min: Maybe<ContractProductManagedField_Min_Fields>;
};


/** aggregate fields of "ContractProductManagedField" */
export type ContractProductManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ContractProductManagedField" */
export type ContractProductManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ContractProductManagedField_Max_Order_By>;
  min?: InputMaybe<ContractProductManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ContractProductManagedField" */
export type ContractProductManagedField_Arr_Rel_Insert_Input = {
  data: Array<ContractProductManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContractProductManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ContractProductManagedField". All fields are combined with a logical 'AND'. */
export type ContractProductManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ContractProductManagedField_Bool_Exp>>;
  _not?: InputMaybe<ContractProductManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ContractProductManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  contract_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContractProductManagedField" */
export enum ContractProductManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "contract_product_id" */
  ContractProductManagedFieldContractProductIdKeyIdKey = 'ContractProductManagedField_contract_product_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ContractProductManagedFieldKeyIdValueEnforceUniquenessKey = 'ContractProductManagedField_key_id_value_enforce_uniqueness_key',
  /** unique or primary key constraint on columns "id" */
  ContractProductManagedFieldPkey = 'ContractProductManagedField_pkey'
}

/** input type for inserting data into table "ContractProductManagedField" */
export type ContractProductManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_product_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContractProductManagedField_Max_Fields = {
  __typename?: 'ContractProductManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_product_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ContractProductManagedField" */
export type ContractProductManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ContractProductManagedField_Min_Fields = {
  __typename?: 'ContractProductManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  contract_product_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ContractProductManagedField" */
export type ContractProductManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  contract_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ContractProductManagedField" */
export type ContractProductManagedField_Mutation_Response = {
  __typename?: 'ContractProductManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContractProductManagedField>;
};

/** on_conflict condition type for table "ContractProductManagedField" */
export type ContractProductManagedField_On_Conflict = {
  constraint: ContractProductManagedField_Constraint;
  update_columns?: Array<ContractProductManagedField_Update_Column>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ContractProductManagedField". */
export type ContractProductManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  contract_product_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContractProductManagedField */
export type ContractProductManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ContractProductManagedField" */
export enum ContractProductManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractProductId = 'contract_product_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ContractProductManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ContractProductManagedField" */
export enum ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ContractProductManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ContractProductManagedField" */
export enum ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ContractProductManagedField" */
export type ContractProductManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_product_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ContractProductManagedField" */
export type ContractProductManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ContractProductManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractProductManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  contract_product_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ContractProductManagedField" */
export enum ContractProductManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  ContractProductId = 'contract_product_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ContractProductManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractProductManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ContractProductManagedField_Bool_Exp;
};

export type ContractRateSchedule = {
  __typename?: 'ContractRateSchedule';
  credit_types_on_segments: Array<CreditType>;
  next_page: Maybe<Scalars['String']>;
  products_on_segments: Array<ProductListItem>;
  scalar_segments: Array<Scalars['Scalar__ContractRateScheduleSegment']>;
  segments: Array<ContractRateScheduleSegment>;
};

export type ContractRateScheduleSegment = {
  __typename?: 'ContractRateScheduleSegment';
  ending_before: Maybe<Scalars['timestamptz']>;
  list_rate: Rate;
  override_rate: Maybe<Rate>;
  pricing_group_values: Maybe<Array<RateCardEntryPricingGroupValueEntry>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product: ProductListItem;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type ContractRefundInvoice = Mri_Invoice & {
  __typename?: 'ContractRefundInvoice';
  amendment: Maybe<ContractAmendment>;
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  correction_record: Maybe<ContractRefundInvoiceCorrectionRecord>;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  regenerated_from: Maybe<ContractRefundInvoice>;
  regenerated_to: Maybe<ContractRefundInvoice>;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ContractRefundInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type ContractRefundInvoiceCorrectionRecord = {
  __typename?: 'ContractRefundInvoiceCorrectionRecord';
  memo: Scalars['String'];
  original_invoice: ContractRefundInvoiceCorrectionRecordOriginalInvoice;
  reason: Scalars['String'];
};

export type ContractRefundInvoiceCorrectionRecordOriginalInvoice = ContractPostpaidTrueupInvoice | ContractScheduledInvoice;

/** Deprecated. */
export type ContractRolloverCommitLineItem = Mri_LineItem & {
  __typename?: 'ContractRolloverCommitLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  product: ContractUsageLineItemProductListItem;
  rollover_commit: Commit;
  rollover_commit_id: Scalars['String'];
  rollover_commit_union: CommitUnion;
  total: Scalars['numeric'];
};

export type ContractScheduledInvoice = Mri_Invoice & {
  __typename?: 'ContractScheduledInvoice';
  amendment: Maybe<ContractAmendment>;
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  corrections: Array<CorrectionInvoice>;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  regenerated_from: Maybe<ContractScheduledInvoice>;
  regenerated_to: Maybe<ContractScheduledInvoice>;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ContractScheduledInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type ContractScheduledLineItem = ContractAwsProServiceRoyaltyLineItem | ContractAwsRoyaltyLineItem | ContractChargeLineItem | ContractCommitLineItem | ContractDiscountLineItem | ContractGcpProServiceRoyaltyLineItem | ContractGcpRoyaltyLineItem;

export type ContractStats = {
  __typename?: 'ContractStats';
  customers_with_contracts_count: Scalars['Int'];
};

/**
 * Represents any subscription-based charge.
 * May also be connected to a ContractAppliedCommitLineItem, which will exist elsewhere on the invoice.
 * If the subscription is applied to multiple commits or segments within a single commit, then there will be multiple ContractSubscriptionLineItems
 * and multiple ContractAppliedCommitLineItems.
 */
export type ContractSubscriptionLineItem = Mri_LineItem & {
  __typename?: 'ContractSubscriptionLineItem';
  breakdown: Maybe<Array<ContractSubscriptionLineItem>>;
  commit_with_segment: Maybe<CommitWithSegment>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  is_prorated: Scalars['Boolean'];
  product: SubscriptionProductListItem;
  quantity: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractUsageInvoice = Mri_Invoice & {
  __typename?: 'ContractUsageInvoice';
  billable_status: BillableStatus;
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Contract;
  corrections: Array<CorrectionInvoice>;
  credit_type: CreditType;
  customer: Customer;
  exclusive_end_date: Scalars['timestamptz'];
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  inclusive_start_date: Scalars['timestamptz'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  regenerated_from: Maybe<ContractUsageInvoice>;
  regenerated_to: Maybe<ContractUsageInvoice>;
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ContractUsageInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export enum ContractUsageInvoiceBillingCycleAnchorEnum {
  ContractStart = 'CONTRACT_START',
  CustomDate = 'CUSTOM_DATE',
  FirstOfMonth = 'FIRST_OF_MONTH'
}

export type ContractUsageInvoiceSchedule = {
  __typename?: 'ContractUsageInvoiceSchedule';
  billing_cycle_anchor_date: Scalars['timestamptz'];
  frequency: ContractUsageInvoiceScheduleFrequencyEnum;
};

export enum ContractUsageInvoiceScheduleFrequencyEnum {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

/**
 * Represents any usage-based charge.
 *
 * May also be connected to a ContractAppliedCommitLineItem, which will exist elsewhere on the invoice.
 * If the usage is applied to multiple commits or segments within a single commit, then there will be multiple ContractUsageLineItems
 * and multiple ContractAppliedCommitLineItems.
 */
export type ContractUsageLineItem = Mri_LineItem & {
  __typename?: 'ContractUsageLineItem';
  breakdown: Maybe<Array<ContractUsageLineItem>>;
  commit_with_segment: Maybe<CommitWithSegment>;
  credit_type: CreditType;
  display_name: Scalars['String'];
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  presentation_group_values: Maybe<Array<PresentationGroupValue>>;
  presentation_groups: Maybe<Scalars['RecordOfNullableStrings']>;
  pricing_group_values: Maybe<Array<PricingGroupValue>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product: ContractUsageLineItemProductListItem;
  quantity: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  tier_metadata: Maybe<TierMetadata>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type ContractUsageLineItemProductListItem = CompositeProductListItem | SubscriptionProductListItem | UsageProductListItem;

export enum ConversionOperation {
  Divide = 'Divide',
  Multiply = 'Multiply'
}

export type CorrectableInvoice = ArrearsInvoice | ContractPostpaidTrueupInvoice | ContractScheduledInvoice | ContractUsageInvoice;

export type CorrectionInvoice = Mri_Invoice & {
  __typename?: 'CorrectionInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  contract: Maybe<Contract>;
  correction_record: InvoiceCorrectionRecord;
  created_at: Scalars['timestamptz'];
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type CorrectionInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type Cost = {
  __typename?: 'Cost';
  breakdown: Array<BreakdownItem>;
  credit_type: CreditType;
  group_key: Maybe<Scalars['String']>;
  group_value: Maybe<Scalars['String']>;
  memo: Scalars['String'];
  total: Scalars['CreditAmount'];
};

export type CostWindow = {
  __typename?: 'CostWindow';
  costs: Array<Cost>;
  end: Scalars['timestamptz'];
  start: Scalars['timestamptz'];
};

export type CreateBillingProviderSettingsInput = {
  stripe?: InputMaybe<CreateStripeSettingsInput>;
};

export type CreateContractUsageFilterInput = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  new_filter: NewUsageFilterInput;
};

export type CreateContractUsageInvoiceScheduleInput = {
  billing_cycle_anchor?: InputMaybe<ContractUsageInvoiceBillingCycleAnchorEnum>;
  billing_cycle_anchor_date?: InputMaybe<Scalars['timestamptz']>;
  frequency?: InputMaybe<ContractUsageInvoiceScheduleFrequencyEnum>;
  invoice_generation_starting_at?: InputMaybe<Scalars['timestamptz']>;
};

export type CreateStripeSettingsInput = {
  anrok_api_key?: InputMaybe<Scalars['String']>;
  stripe_api_key: Scalars['String'];
};

/** columns and relationships of "CreditAdjustment" */
export type CreditAdjustment = {
  __typename?: 'CreditAdjustment';
  /** An object relationship */
  CreditGrant: CreditGrant;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  credit_grant_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "CreditAdjustment" */
export type CreditAdjustment_Aggregate = {
  __typename?: 'CreditAdjustment_aggregate';
  aggregate: Maybe<CreditAdjustment_Aggregate_Fields>;
  nodes: Array<CreditAdjustment>;
};

/** aggregate fields of "CreditAdjustment" */
export type CreditAdjustment_Aggregate_Fields = {
  __typename?: 'CreditAdjustment_aggregate_fields';
  avg: Maybe<CreditAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditAdjustment_Max_Fields>;
  min: Maybe<CreditAdjustment_Min_Fields>;
  stddev: Maybe<CreditAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<CreditAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<CreditAdjustment_Sum_Fields>;
  var_pop: Maybe<CreditAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<CreditAdjustment_Var_Samp_Fields>;
  variance: Maybe<CreditAdjustment_Variance_Fields>;
};


/** aggregate fields of "CreditAdjustment" */
export type CreditAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CreditAdjustment_Avg_Fields = {
  __typename?: 'CreditAdjustment_avg_fields';
  amount: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CreditAdjustment". All fields are combined with a logical 'AND'. */
export type CreditAdjustment_Bool_Exp = {
  CreditGrant?: InputMaybe<CreditGrant_Bool_Exp>;
  _and?: InputMaybe<Array<CreditAdjustment_Bool_Exp>>;
  _not?: InputMaybe<CreditAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<CreditAdjustment_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_grant_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditAdjustment" */
export enum CreditAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditAdjustmentPkey = 'CreditAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "CreditAdjustment" */
export type CreditAdjustment_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditAdjustment" */
export type CreditAdjustment_Insert_Input = {
  CreditGrant?: InputMaybe<CreditGrant_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CreditAdjustment_Max_Fields = {
  __typename?: 'CreditAdjustment_max_fields';
  amount: Maybe<Scalars['numeric']>;
  created_at: Maybe<Scalars['timestamptz']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CreditAdjustment_Min_Fields = {
  __typename?: 'CreditAdjustment_min_fields';
  amount: Maybe<Scalars['numeric']>;
  created_at: Maybe<Scalars['timestamptz']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "CreditAdjustment" */
export type CreditAdjustment_Mutation_Response = {
  __typename?: 'CreditAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditAdjustment>;
};

/** on_conflict condition type for table "CreditAdjustment" */
export type CreditAdjustment_On_Conflict = {
  constraint: CreditAdjustment_Constraint;
  update_columns?: Array<CreditAdjustment_Update_Column>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditAdjustment". */
export type CreditAdjustment_Order_By = {
  CreditGrant?: InputMaybe<CreditGrant_Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditAdjustment */
export type CreditAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditAdjustment" */
export enum CreditAdjustment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "CreditAdjustment" */
export type CreditAdjustment_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CreditAdjustment_Stddev_Fields = {
  __typename?: 'CreditAdjustment_stddev_fields';
  amount: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CreditAdjustment_Stddev_Pop_Fields = {
  __typename?: 'CreditAdjustment_stddev_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CreditAdjustment_Stddev_Samp_Fields = {
  __typename?: 'CreditAdjustment_stddev_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CreditAdjustment" */
export type CreditAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditAdjustment_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CreditAdjustment_Sum_Fields = {
  __typename?: 'CreditAdjustment_sum_fields';
  amount: Maybe<Scalars['numeric']>;
};

/** update columns of table "CreditAdjustment" */
export enum CreditAdjustment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  Id = 'id'
}

export type CreditAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditAdjustment_Var_Pop_Fields = {
  __typename?: 'CreditAdjustment_var_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CreditAdjustment_Var_Samp_Fields = {
  __typename?: 'CreditAdjustment_var_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CreditAdjustment_Variance_Fields = {
  __typename?: 'CreditAdjustment_variance_fields';
  amount: Maybe<Scalars['Float']>;
};

export type CreditAmountWithType = {
  __typename?: 'CreditAmountWithType';
  amount: Scalars['CreditAmount'];
  credit_type: CreditType;
};

/** columns and relationships of "CreditGrant" */
export type CreditGrant = {
  __typename?: 'CreditGrant';
  /** An object relationship */
  AmountGrantedCreditType: CreditType;
  /** An object relationship */
  AmountPaidCreditType: CreditType;
  /** An object relationship */
  BillingProviderEnum: Maybe<BillingProviderEnum>;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An array relationship */
  CreditGrantDeductions: Array<CreditGrantDeduction>;
  /** An aggregate relationship */
  CreditGrantDeductions_aggregate: CreditGrantDeduction_Aggregate;
  /** An array relationship */
  CreditGrantManagedFields: Array<CreditGrantManagedField>;
  /** An aggregate relationship */
  CreditGrantManagedFields_aggregate: CreditGrantManagedField_Aggregate;
  /** An object relationship */
  Customer: Customer;
  /** An object relationship */
  RecurringCreditGrant: Maybe<RecurringCreditGrant>;
  /** An object relationship */
  Updater: Maybe<Actor>;
  /** An object relationship */
  Voider: Maybe<Actor>;
  amount_granted: Scalars['numeric'];
  amount_granted_credit_type_id: Scalars['uuid'];
  amount_paid: Scalars['numeric'];
  amount_paid_credit_type_id: Scalars['uuid'];
  billing_provider: Maybe<BillingProviderEnum_Enum>;
  client_id: Scalars['uuid'];
  cost_basis: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  credit_grant_type: Maybe<Scalars['String']>;
  customer_id: Scalars['uuid'];
  effective_at: Scalars['timestamptz'];
  environment_type: EnvironmentTypeEnum_Enum;
  expires_before: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice: Maybe<CreditPurchaseInvoice>;
  invoice_id: Maybe<Scalars['uuid']>;
  managed_fields: Array<CreditGrantManagedField>;
  /**
   * If you add any more sub-resolvers to this type, please make sure it
   * requires the Customer { id } field, so that auth works correctly
   */
  mri_credit_ledger: Mri_CreditLedger;
  name: Scalars['String'];
  priority: Scalars['numeric'];
  product_ids: Maybe<Array<Scalars['String']>>;
  products: Maybe<Array<Product>>;
  reason: Maybe<Scalars['String']>;
  recurring_credit_grant_id: Maybe<Scalars['uuid']>;
  rolled_over_from_id: Maybe<Scalars['uuid']>;
  rolled_over_to_id: Maybe<Scalars['uuid']>;
  rollover_settings: Maybe<Scalars['jsonb']>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by: Maybe<Scalars['String']>;
  voided_at: Maybe<Scalars['timestamptz']>;
  voided_by: Maybe<Scalars['String']>;
};


/** columns and relationships of "CreditGrant" */
export type CreditGrantCreditGrantDeductionsArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


/** columns and relationships of "CreditGrant" */
export type CreditGrantCreditGrantDeductions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


/** columns and relationships of "CreditGrant" */
export type CreditGrantCreditGrantManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


/** columns and relationships of "CreditGrant" */
export type CreditGrantCreditGrantManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


/** columns and relationships of "CreditGrant" */
export type CreditGrantRollover_SettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "CreditGrantDeduction" */
export type CreditGrantDeduction = {
  __typename?: 'CreditGrantDeduction';
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  CreditGrant: CreditGrant;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  credit_grant_id: Scalars['uuid'];
  effective_before: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice_adjustment_id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  memo: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CreditGrantDeduction" */
export type CreditGrantDeduction_Aggregate = {
  __typename?: 'CreditGrantDeduction_aggregate';
  aggregate: Maybe<CreditGrantDeduction_Aggregate_Fields>;
  nodes: Array<CreditGrantDeduction>;
};

export type CreditGrantDeduction_Aggregate_Bool_Exp = {
  count?: InputMaybe<CreditGrantDeduction_Aggregate_Bool_Exp_Count>;
};

export type CreditGrantDeduction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CreditGrantDeduction" */
export type CreditGrantDeduction_Aggregate_Fields = {
  __typename?: 'CreditGrantDeduction_aggregate_fields';
  avg: Maybe<CreditGrantDeduction_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditGrantDeduction_Max_Fields>;
  min: Maybe<CreditGrantDeduction_Min_Fields>;
  stddev: Maybe<CreditGrantDeduction_Stddev_Fields>;
  stddev_pop: Maybe<CreditGrantDeduction_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditGrantDeduction_Stddev_Samp_Fields>;
  sum: Maybe<CreditGrantDeduction_Sum_Fields>;
  var_pop: Maybe<CreditGrantDeduction_Var_Pop_Fields>;
  var_samp: Maybe<CreditGrantDeduction_Var_Samp_Fields>;
  variance: Maybe<CreditGrantDeduction_Variance_Fields>;
};


/** aggregate fields of "CreditGrantDeduction" */
export type CreditGrantDeduction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Aggregate_Order_By = {
  avg?: InputMaybe<CreditGrantDeduction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CreditGrantDeduction_Max_Order_By>;
  min?: InputMaybe<CreditGrantDeduction_Min_Order_By>;
  stddev?: InputMaybe<CreditGrantDeduction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CreditGrantDeduction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CreditGrantDeduction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CreditGrantDeduction_Sum_Order_By>;
  var_pop?: InputMaybe<CreditGrantDeduction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CreditGrantDeduction_Var_Samp_Order_By>;
  variance?: InputMaybe<CreditGrantDeduction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CreditGrantDeduction" */
export type CreditGrantDeduction_Arr_Rel_Insert_Input = {
  data: Array<CreditGrantDeduction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditGrantDeduction_On_Conflict>;
};

/** aggregate avg on columns */
export type CreditGrantDeduction_Avg_Fields = {
  __typename?: 'CreditGrantDeduction_avg_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CreditGrantDeduction". All fields are combined with a logical 'AND'. */
export type CreditGrantDeduction_Bool_Exp = {
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CreditGrant?: InputMaybe<CreditGrant_Bool_Exp>;
  _and?: InputMaybe<Array<CreditGrantDeduction_Bool_Exp>>;
  _not?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
  _or?: InputMaybe<Array<CreditGrantDeduction_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  credit_grant_id?: InputMaybe<Uuid_Comparison_Exp>;
  effective_before?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_adjustment_id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  memo?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditGrantDeduction" */
export enum CreditGrantDeduction_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditGrantDeductionPkey = 'CreditGrantDeduction_pkey'
}

/** input type for incrementing numeric columns in table "CreditGrantDeduction" */
export type CreditGrantDeduction_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditGrantDeduction" */
export type CreditGrantDeduction_Insert_Input = {
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CreditGrant?: InputMaybe<CreditGrant_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  effective_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_adjustment_id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CreditGrantDeduction_Max_Fields = {
  __typename?: 'CreditGrantDeduction_max_fields';
  amount: Maybe<Scalars['numeric']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  effective_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_adjustment_id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  memo: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  effective_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_adjustment_id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CreditGrantDeduction_Min_Fields = {
  __typename?: 'CreditGrantDeduction_min_fields';
  amount: Maybe<Scalars['numeric']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  effective_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_adjustment_id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  memo: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  effective_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_adjustment_id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CreditGrantDeduction" */
export type CreditGrantDeduction_Mutation_Response = {
  __typename?: 'CreditGrantDeduction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditGrantDeduction>;
};

/** on_conflict condition type for table "CreditGrantDeduction" */
export type CreditGrantDeduction_On_Conflict = {
  constraint: CreditGrantDeduction_Constraint;
  update_columns?: Array<CreditGrantDeduction_Update_Column>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditGrantDeduction". */
export type CreditGrantDeduction_Order_By = {
  Creator?: InputMaybe<Actor_Order_By>;
  CreditGrant?: InputMaybe<CreditGrant_Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  effective_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_adjustment_id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditGrantDeduction */
export type CreditGrantDeduction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditGrantDeduction" */
export enum CreditGrantDeduction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  EffectiveBefore = 'effective_before',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAdjustmentId = 'invoice_adjustment_id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Memo = 'memo',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CreditGrantDeduction" */
export type CreditGrantDeduction_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  effective_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_adjustment_id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CreditGrantDeduction_Stddev_Fields = {
  __typename?: 'CreditGrantDeduction_stddev_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CreditGrantDeduction_Stddev_Pop_Fields = {
  __typename?: 'CreditGrantDeduction_stddev_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CreditGrantDeduction_Stddev_Samp_Fields = {
  __typename?: 'CreditGrantDeduction_stddev_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CreditGrantDeduction" */
export type CreditGrantDeduction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditGrantDeduction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditGrantDeduction_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  effective_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_adjustment_id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CreditGrantDeduction_Sum_Fields = {
  __typename?: 'CreditGrantDeduction_sum_fields';
  amount: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "CreditGrantDeduction" */
export enum CreditGrantDeduction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  EffectiveBefore = 'effective_before',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAdjustmentId = 'invoice_adjustment_id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Memo = 'memo',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CreditGrantDeduction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditGrantDeduction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditGrantDeduction_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditGrantDeduction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditGrantDeduction_Var_Pop_Fields = {
  __typename?: 'CreditGrantDeduction_var_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CreditGrantDeduction_Var_Samp_Fields = {
  __typename?: 'CreditGrantDeduction_var_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CreditGrantDeduction_Variance_Fields = {
  __typename?: 'CreditGrantDeduction_variance_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CreditGrantDeduction" */
export type CreditGrantDeduction_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "CreditGrantHistory" */
export type CreditGrantHistory = {
  __typename?: 'CreditGrantHistory';
  /** An object relationship */
  BillingProviderEnum: Maybe<BillingProviderEnum>;
  /** An object relationship */
  CreditGrantUpdater: Maybe<Actor>;
  created_at: Scalars['timestamptz'];
  credit_grant_billing_provider: Maybe<BillingProviderEnum_Enum>;
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Int']>;
  credit_grant_expires_before: Scalars['timestamptz'];
  credit_grant_id: Scalars['uuid'];
  credit_grant_name: Scalars['String'];
  credit_grant_reason: Maybe<Scalars['String']>;
  credit_grant_rollover_settings: Maybe<Scalars['jsonb']>;
  credit_grant_type: Maybe<Scalars['String']>;
  credit_grant_updated_at: Scalars['timestamptz'];
  credit_grant_updated_by: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
};


/** columns and relationships of "CreditGrantHistory" */
export type CreditGrantHistoryCredit_Grant_Rollover_SettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "CreditGrantHistory" */
export type CreditGrantHistory_Aggregate = {
  __typename?: 'CreditGrantHistory_aggregate';
  aggregate: Maybe<CreditGrantHistory_Aggregate_Fields>;
  nodes: Array<CreditGrantHistory>;
};

/** aggregate fields of "CreditGrantHistory" */
export type CreditGrantHistory_Aggregate_Fields = {
  __typename?: 'CreditGrantHistory_aggregate_fields';
  avg: Maybe<CreditGrantHistory_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditGrantHistory_Max_Fields>;
  min: Maybe<CreditGrantHistory_Min_Fields>;
  stddev: Maybe<CreditGrantHistory_Stddev_Fields>;
  stddev_pop: Maybe<CreditGrantHistory_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditGrantHistory_Stddev_Samp_Fields>;
  sum: Maybe<CreditGrantHistory_Sum_Fields>;
  var_pop: Maybe<CreditGrantHistory_Var_Pop_Fields>;
  var_samp: Maybe<CreditGrantHistory_Var_Samp_Fields>;
  variance: Maybe<CreditGrantHistory_Variance_Fields>;
};


/** aggregate fields of "CreditGrantHistory" */
export type CreditGrantHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditGrantHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CreditGrantHistory_Append_Input = {
  credit_grant_rollover_settings?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type CreditGrantHistory_Avg_Fields = {
  __typename?: 'CreditGrantHistory_avg_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CreditGrantHistory". All fields are combined with a logical 'AND'. */
export type CreditGrantHistory_Bool_Exp = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  CreditGrantUpdater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CreditGrantHistory_Bool_Exp>>;
  _not?: InputMaybe<CreditGrantHistory_Bool_Exp>;
  _or?: InputMaybe<Array<CreditGrantHistory_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_grant_billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Numeric_Comparison_Exp>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Int_Comparison_Exp>;
  credit_grant_expires_before?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_grant_id?: InputMaybe<Uuid_Comparison_Exp>;
  credit_grant_name?: InputMaybe<String_Comparison_Exp>;
  credit_grant_reason?: InputMaybe<String_Comparison_Exp>;
  credit_grant_rollover_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  credit_grant_type?: InputMaybe<String_Comparison_Exp>;
  credit_grant_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_grant_updated_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditGrantHistory" */
export enum CreditGrantHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditGrantHistoryPkey = 'CreditGrantHistory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CreditGrantHistory_Delete_At_Path_Input = {
  credit_grant_rollover_settings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CreditGrantHistory_Delete_Elem_Input = {
  credit_grant_rollover_settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CreditGrantHistory_Delete_Key_Input = {
  credit_grant_rollover_settings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "CreditGrantHistory" */
export type CreditGrantHistory_Inc_Input = {
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CreditGrantHistory" */
export type CreditGrantHistory_Insert_Input = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  CreditGrantUpdater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Scalars['Int']>;
  credit_grant_expires_before?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_name?: InputMaybe<Scalars['String']>;
  credit_grant_reason?: InputMaybe<Scalars['String']>;
  credit_grant_rollover_settings?: InputMaybe<Scalars['jsonb']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  credit_grant_updated_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_updated_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CreditGrantHistory_Max_Fields = {
  __typename?: 'CreditGrantHistory_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Int']>;
  credit_grant_expires_before: Maybe<Scalars['timestamptz']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  credit_grant_name: Maybe<Scalars['String']>;
  credit_grant_reason: Maybe<Scalars['String']>;
  credit_grant_type: Maybe<Scalars['String']>;
  credit_grant_updated_at: Maybe<Scalars['timestamptz']>;
  credit_grant_updated_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CreditGrantHistory_Min_Fields = {
  __typename?: 'CreditGrantHistory_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Int']>;
  credit_grant_expires_before: Maybe<Scalars['timestamptz']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  credit_grant_name: Maybe<Scalars['String']>;
  credit_grant_reason: Maybe<Scalars['String']>;
  credit_grant_type: Maybe<Scalars['String']>;
  credit_grant_updated_at: Maybe<Scalars['timestamptz']>;
  credit_grant_updated_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "CreditGrantHistory" */
export type CreditGrantHistory_Mutation_Response = {
  __typename?: 'CreditGrantHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditGrantHistory>;
};

/** on_conflict condition type for table "CreditGrantHistory" */
export type CreditGrantHistory_On_Conflict = {
  constraint: CreditGrantHistory_Constraint;
  update_columns?: Array<CreditGrantHistory_Update_Column>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditGrantHistory". */
export type CreditGrantHistory_Order_By = {
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  CreditGrantUpdater?: InputMaybe<Actor_Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_grant_billing_provider?: InputMaybe<Order_By>;
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Order_By>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Order_By>;
  credit_grant_expires_before?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  credit_grant_name?: InputMaybe<Order_By>;
  credit_grant_reason?: InputMaybe<Order_By>;
  credit_grant_rollover_settings?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  credit_grant_updated_at?: InputMaybe<Order_By>;
  credit_grant_updated_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditGrantHistory */
export type CreditGrantHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CreditGrantHistory_Prepend_Input = {
  credit_grant_rollover_settings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CreditGrantHistory" */
export enum CreditGrantHistory_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditGrantBillingProvider = 'credit_grant_billing_provider',
  /** column name */
  CreditGrantDatabricksSbfRolloverFactor = 'credit_grant_databricks_sbf_rollover_factor',
  /** column name */
  CreditGrantDatabricksSbfRolloverMonths = 'credit_grant_databricks_sbf_rollover_months',
  /** column name */
  CreditGrantExpiresBefore = 'credit_grant_expires_before',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  CreditGrantName = 'credit_grant_name',
  /** column name */
  CreditGrantReason = 'credit_grant_reason',
  /** column name */
  CreditGrantRolloverSettings = 'credit_grant_rollover_settings',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  CreditGrantUpdatedAt = 'credit_grant_updated_at',
  /** column name */
  CreditGrantUpdatedBy = 'credit_grant_updated_by',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "CreditGrantHistory" */
export type CreditGrantHistory_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Scalars['Int']>;
  credit_grant_expires_before?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_name?: InputMaybe<Scalars['String']>;
  credit_grant_reason?: InputMaybe<Scalars['String']>;
  credit_grant_rollover_settings?: InputMaybe<Scalars['jsonb']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  credit_grant_updated_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_updated_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CreditGrantHistory_Stddev_Fields = {
  __typename?: 'CreditGrantHistory_stddev_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CreditGrantHistory_Stddev_Pop_Fields = {
  __typename?: 'CreditGrantHistory_stddev_pop_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CreditGrantHistory_Stddev_Samp_Fields = {
  __typename?: 'CreditGrantHistory_stddev_samp_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CreditGrantHistory" */
export type CreditGrantHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditGrantHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditGrantHistory_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  credit_grant_databricks_sbf_rollover_factor?: InputMaybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months?: InputMaybe<Scalars['Int']>;
  credit_grant_expires_before?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_name?: InputMaybe<Scalars['String']>;
  credit_grant_reason?: InputMaybe<Scalars['String']>;
  credit_grant_rollover_settings?: InputMaybe<Scalars['jsonb']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  credit_grant_updated_at?: InputMaybe<Scalars['timestamptz']>;
  credit_grant_updated_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CreditGrantHistory_Sum_Fields = {
  __typename?: 'CreditGrantHistory_sum_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['numeric']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Int']>;
};

/** update columns of table "CreditGrantHistory" */
export enum CreditGrantHistory_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditGrantBillingProvider = 'credit_grant_billing_provider',
  /** column name */
  CreditGrantDatabricksSbfRolloverFactor = 'credit_grant_databricks_sbf_rollover_factor',
  /** column name */
  CreditGrantDatabricksSbfRolloverMonths = 'credit_grant_databricks_sbf_rollover_months',
  /** column name */
  CreditGrantExpiresBefore = 'credit_grant_expires_before',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  CreditGrantName = 'credit_grant_name',
  /** column name */
  CreditGrantReason = 'credit_grant_reason',
  /** column name */
  CreditGrantRolloverSettings = 'credit_grant_rollover_settings',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  CreditGrantUpdatedAt = 'credit_grant_updated_at',
  /** column name */
  CreditGrantUpdatedBy = 'credit_grant_updated_by',
  /** column name */
  Id = 'id'
}

export type CreditGrantHistory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CreditGrantHistory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CreditGrantHistory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CreditGrantHistory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CreditGrantHistory_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditGrantHistory_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CreditGrantHistory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditGrantHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditGrantHistory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditGrantHistory_Var_Pop_Fields = {
  __typename?: 'CreditGrantHistory_var_pop_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CreditGrantHistory_Var_Samp_Fields = {
  __typename?: 'CreditGrantHistory_var_samp_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CreditGrantHistory_Variance_Fields = {
  __typename?: 'CreditGrantHistory_variance_fields';
  credit_grant_databricks_sbf_rollover_factor: Maybe<Scalars['Float']>;
  credit_grant_databricks_sbf_rollover_months: Maybe<Scalars['Float']>;
};

export type CreditGrantInput = {
  amount_granted: Scalars['CreditAmount'];
  amount_granted_credit_type_id: Scalars['uuid'];
  amount_paid: Scalars['CreditAmount'];
  amount_paid_credit_type_id: Scalars['uuid'];
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  custom_fields?: InputMaybe<Scalars['JSONObject']>;
  customer_id: Scalars['uuid'];
  effective_at: Scalars['timestamptz'];
  expires_before: Scalars['timestamptz'];
  invoice_date?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  priority: Scalars['numeric'];
  product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  reason?: InputMaybe<Scalars['String']>;
  rollover_settings?: InputMaybe<RolloverSettingsInput>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "CreditGrantManagedField" */
export type CreditGrantManagedField = {
  __typename?: 'CreditGrantManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  CreditGrant: CreditGrant;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  credit_grant_id: Scalars['uuid'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CreditGrantManagedField" */
export type CreditGrantManagedField_Aggregate = {
  __typename?: 'CreditGrantManagedField_aggregate';
  aggregate: Maybe<CreditGrantManagedField_Aggregate_Fields>;
  nodes: Array<CreditGrantManagedField>;
};

export type CreditGrantManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CreditGrantManagedField_Aggregate_Bool_Exp_Count>;
};

export type CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CreditGrantManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CreditGrantManagedField" */
export type CreditGrantManagedField_Aggregate_Fields = {
  __typename?: 'CreditGrantManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CreditGrantManagedField_Max_Fields>;
  min: Maybe<CreditGrantManagedField_Min_Fields>;
};


/** aggregate fields of "CreditGrantManagedField" */
export type CreditGrantManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CreditGrantManagedField" */
export type CreditGrantManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CreditGrantManagedField_Max_Order_By>;
  min?: InputMaybe<CreditGrantManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CreditGrantManagedField" */
export type CreditGrantManagedField_Arr_Rel_Insert_Input = {
  data: Array<CreditGrantManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditGrantManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CreditGrantManagedField". All fields are combined with a logical 'AND'. */
export type CreditGrantManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CreditGrant?: InputMaybe<CreditGrant_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CreditGrantManagedField_Bool_Exp>>;
  _not?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<CreditGrantManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  credit_grant_id?: InputMaybe<Uuid_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditGrantManagedField" */
export enum CreditGrantManagedField_Constraint {
  /** unique or primary key constraint on columns "credit_grant_id", "key_id" */
  CreditGrantManagedFieldCreditGrantIdKeyIdKey = 'CreditGrantManagedField_credit_grant_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  CreditGrantManagedFieldKeyIdValueEnforceUniquenessCliKey = 'CreditGrantManagedField_key_id_value_enforce_uniqueness_cli_key',
  /** unique or primary key constraint on columns "id" */
  CreditGrantManagedFieldPkey = 'CreditGrantManagedField_pkey'
}

/** input type for inserting data into table "CreditGrantManagedField" */
export type CreditGrantManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CreditGrant?: InputMaybe<CreditGrant_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CreditGrantManagedField_Max_Fields = {
  __typename?: 'CreditGrantManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CreditGrantManagedField" */
export type CreditGrantManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CreditGrantManagedField_Min_Fields = {
  __typename?: 'CreditGrantManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CreditGrantManagedField" */
export type CreditGrantManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CreditGrantManagedField" */
export type CreditGrantManagedField_Mutation_Response = {
  __typename?: 'CreditGrantManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditGrantManagedField>;
};

/** on_conflict condition type for table "CreditGrantManagedField" */
export type CreditGrantManagedField_On_Conflict = {
  constraint: CreditGrantManagedField_Constraint;
  update_columns?: Array<CreditGrantManagedField_Update_Column>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditGrantManagedField". */
export type CreditGrantManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  CreditGrant?: InputMaybe<CreditGrant_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_id?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditGrantManagedField */
export type CreditGrantManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditGrantManagedField" */
export enum CreditGrantManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "CreditGrantManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CreditGrantManagedField" */
export enum CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "CreditGrantManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CreditGrantManagedField" */
export enum CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "CreditGrantManagedField" */
export type CreditGrantManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CreditGrantManagedField" */
export type CreditGrantManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditGrantManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditGrantManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CreditGrantManagedField" */
export enum CreditGrantManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantId = 'credit_grant_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type CreditGrantManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditGrantManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditGrantManagedField_Bool_Exp;
};

export type CreditGrantPage = {
  __typename?: 'CreditGrantPage';
  credit_grants: Array<Mri_CreditGrant>;
  cursor: Maybe<Scalars['String']>;
};

export type CreditGrantUnion = CreditGrant | Mri_CreditGrant | PrepaidCreditGrant;

/** aggregated selection of "CreditGrant" */
export type CreditGrant_Aggregate = {
  __typename?: 'CreditGrant_aggregate';
  aggregate: Maybe<CreditGrant_Aggregate_Fields>;
  nodes: Array<CreditGrant>;
};

export type CreditGrant_Aggregate_Bool_Exp = {
  count?: InputMaybe<CreditGrant_Aggregate_Bool_Exp_Count>;
};

export type CreditGrant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CreditGrant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditGrant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CreditGrant" */
export type CreditGrant_Aggregate_Fields = {
  __typename?: 'CreditGrant_aggregate_fields';
  avg: Maybe<CreditGrant_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditGrant_Max_Fields>;
  min: Maybe<CreditGrant_Min_Fields>;
  stddev: Maybe<CreditGrant_Stddev_Fields>;
  stddev_pop: Maybe<CreditGrant_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditGrant_Stddev_Samp_Fields>;
  sum: Maybe<CreditGrant_Sum_Fields>;
  var_pop: Maybe<CreditGrant_Var_Pop_Fields>;
  var_samp: Maybe<CreditGrant_Var_Samp_Fields>;
  variance: Maybe<CreditGrant_Variance_Fields>;
};


/** aggregate fields of "CreditGrant" */
export type CreditGrant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditGrant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CreditGrant" */
export type CreditGrant_Aggregate_Order_By = {
  avg?: InputMaybe<CreditGrant_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CreditGrant_Max_Order_By>;
  min?: InputMaybe<CreditGrant_Min_Order_By>;
  stddev?: InputMaybe<CreditGrant_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CreditGrant_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CreditGrant_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CreditGrant_Sum_Order_By>;
  var_pop?: InputMaybe<CreditGrant_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CreditGrant_Var_Samp_Order_By>;
  variance?: InputMaybe<CreditGrant_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CreditGrant_Append_Input = {
  rollover_settings?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "CreditGrant" */
export type CreditGrant_Arr_Rel_Insert_Input = {
  data: Array<CreditGrant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditGrant_On_Conflict>;
};

/** aggregate avg on columns */
export type CreditGrant_Avg_Fields = {
  __typename?: 'CreditGrant_avg_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CreditGrant" */
export type CreditGrant_Avg_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CreditGrant". All fields are combined with a logical 'AND'. */
export type CreditGrant_Bool_Exp = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Bool_Exp>;
  AmountPaidCreditType?: InputMaybe<CreditType_Bool_Exp>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CreditGrantDeductions?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
  CreditGrantDeductions_aggregate?: InputMaybe<CreditGrantDeduction_Aggregate_Bool_Exp>;
  CreditGrantManagedFields?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  CreditGrantManagedFields_aggregate?: InputMaybe<CreditGrantManagedField_Aggregate_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  RecurringCreditGrant?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  Voider?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CreditGrant_Bool_Exp>>;
  _not?: InputMaybe<CreditGrant_Bool_Exp>;
  _or?: InputMaybe<Array<CreditGrant_Bool_Exp>>;
  amount_granted?: InputMaybe<Numeric_Comparison_Exp>;
  amount_granted_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  amount_paid?: InputMaybe<Numeric_Comparison_Exp>;
  amount_paid_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  credit_grant_type?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  effective_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  expires_before?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Numeric_Comparison_Exp>;
  product_ids?: InputMaybe<String_Array_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  recurring_credit_grant_id?: InputMaybe<Uuid_Comparison_Exp>;
  rolled_over_from_id?: InputMaybe<Uuid_Comparison_Exp>;
  rolled_over_to_id?: InputMaybe<Uuid_Comparison_Exp>;
  rollover_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  uniqueness_key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  voided_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  voided_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditGrant" */
export enum CreditGrant_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditGrantPkey = 'CreditGrant_pkey',
  /** unique or primary key constraint on columns "environment_type", "uniqueness_key", "client_id" */
  CreditGrantClientIdUniquenessKeyUnique = 'credit_grant_client_id_uniqueness_key_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CreditGrant_Delete_At_Path_Input = {
  rollover_settings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CreditGrant_Delete_Elem_Input = {
  rollover_settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CreditGrant_Delete_Key_Input = {
  rollover_settings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "CreditGrant" */
export type CreditGrant_Inc_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  priority?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditGrant" */
export type CreditGrant_Insert_Input = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  AmountPaidCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CreditGrantDeductions?: InputMaybe<CreditGrantDeduction_Arr_Rel_Insert_Input>;
  CreditGrantManagedFields?: InputMaybe<CreditGrantManagedField_Arr_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  RecurringCreditGrant?: InputMaybe<RecurringCreditGrant_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  Voider?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  expires_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurring_credit_grant_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_from_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_to_id?: InputMaybe<Scalars['uuid']>;
  rollover_settings?: InputMaybe<Scalars['jsonb']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  voided_at?: InputMaybe<Scalars['timestamptz']>;
  voided_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CreditGrant_Max_Fields = {
  __typename?: 'CreditGrant_max_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_granted_credit_type_id: Maybe<Scalars['uuid']>;
  amount_paid: Maybe<Scalars['numeric']>;
  amount_paid_credit_type_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_type: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['uuid']>;
  effective_at: Maybe<Scalars['timestamptz']>;
  expires_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  priority: Maybe<Scalars['numeric']>;
  product_ids: Maybe<Array<Scalars['String']>>;
  reason: Maybe<Scalars['String']>;
  recurring_credit_grant_id: Maybe<Scalars['uuid']>;
  rolled_over_from_id: Maybe<Scalars['uuid']>;
  rolled_over_to_id: Maybe<Scalars['uuid']>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  voided_at: Maybe<Scalars['timestamptz']>;
  voided_by: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CreditGrant" */
export type CreditGrant_Max_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  effective_at?: InputMaybe<Order_By>;
  expires_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurring_credit_grant_id?: InputMaybe<Order_By>;
  rolled_over_from_id?: InputMaybe<Order_By>;
  rolled_over_to_id?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  voided_at?: InputMaybe<Order_By>;
  voided_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CreditGrant_Min_Fields = {
  __typename?: 'CreditGrant_min_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_granted_credit_type_id: Maybe<Scalars['uuid']>;
  amount_paid: Maybe<Scalars['numeric']>;
  amount_paid_credit_type_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  credit_grant_type: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['uuid']>;
  effective_at: Maybe<Scalars['timestamptz']>;
  expires_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  priority: Maybe<Scalars['numeric']>;
  product_ids: Maybe<Array<Scalars['String']>>;
  reason: Maybe<Scalars['String']>;
  recurring_credit_grant_id: Maybe<Scalars['uuid']>;
  rolled_over_from_id: Maybe<Scalars['uuid']>;
  rolled_over_to_id: Maybe<Scalars['uuid']>;
  uniqueness_key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  voided_at: Maybe<Scalars['timestamptz']>;
  voided_by: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CreditGrant" */
export type CreditGrant_Min_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  effective_at?: InputMaybe<Order_By>;
  expires_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurring_credit_grant_id?: InputMaybe<Order_By>;
  rolled_over_from_id?: InputMaybe<Order_By>;
  rolled_over_to_id?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  voided_at?: InputMaybe<Order_By>;
  voided_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CreditGrant" */
export type CreditGrant_Mutation_Response = {
  __typename?: 'CreditGrant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditGrant>;
};

/** input type for inserting object relation for remote table "CreditGrant" */
export type CreditGrant_Obj_Rel_Insert_Input = {
  data: CreditGrant_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditGrant_On_Conflict>;
};

/** on_conflict condition type for table "CreditGrant" */
export type CreditGrant_On_Conflict = {
  constraint: CreditGrant_Constraint;
  update_columns?: Array<CreditGrant_Update_Column>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditGrant". */
export type CreditGrant_Order_By = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Order_By>;
  AmountPaidCreditType?: InputMaybe<CreditType_Order_By>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  CreditGrantDeductions_aggregate?: InputMaybe<CreditGrantDeduction_Aggregate_Order_By>;
  CreditGrantManagedFields_aggregate?: InputMaybe<CreditGrantManagedField_Aggregate_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  RecurringCreditGrant?: InputMaybe<RecurringCreditGrant_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  Voider?: InputMaybe<Actor_Order_By>;
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  billing_provider?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  effective_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  expires_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurring_credit_grant_id?: InputMaybe<Order_By>;
  rolled_over_from_id?: InputMaybe<Order_By>;
  rolled_over_to_id?: InputMaybe<Order_By>;
  rollover_settings?: InputMaybe<Order_By>;
  uniqueness_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  voided_at?: InputMaybe<Order_By>;
  voided_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditGrant */
export type CreditGrant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CreditGrant_Prepend_Input = {
  rollover_settings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CreditGrant" */
export enum CreditGrant_Select_Column {
  /** column name */
  AmountGranted = 'amount_granted',
  /** column name */
  AmountGrantedCreditTypeId = 'amount_granted_credit_type_id',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountPaidCreditTypeId = 'amount_paid_credit_type_id',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EffectiveAt = 'effective_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  ExpiresBefore = 'expires_before',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductIds = 'product_ids',
  /** column name */
  Reason = 'reason',
  /** column name */
  RecurringCreditGrantId = 'recurring_credit_grant_id',
  /** column name */
  RolledOverFromId = 'rolled_over_from_id',
  /** column name */
  RolledOverToId = 'rolled_over_to_id',
  /** column name */
  RolloverSettings = 'rollover_settings',
  /** column name */
  UniquenessKey = 'uniqueness_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VoidedAt = 'voided_at',
  /** column name */
  VoidedBy = 'voided_by'
}

/** input type for updating data in table "CreditGrant" */
export type CreditGrant_Set_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  expires_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurring_credit_grant_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_from_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_to_id?: InputMaybe<Scalars['uuid']>;
  rollover_settings?: InputMaybe<Scalars['jsonb']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  voided_at?: InputMaybe<Scalars['timestamptz']>;
  voided_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CreditGrant_Stddev_Fields = {
  __typename?: 'CreditGrant_stddev_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CreditGrant" */
export type CreditGrant_Stddev_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CreditGrant_Stddev_Pop_Fields = {
  __typename?: 'CreditGrant_stddev_pop_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CreditGrant" */
export type CreditGrant_Stddev_Pop_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CreditGrant_Stddev_Samp_Fields = {
  __typename?: 'CreditGrant_stddev_samp_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CreditGrant" */
export type CreditGrant_Stddev_Samp_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CreditGrant" */
export type CreditGrant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditGrant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditGrant_Stream_Cursor_Value_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  expires_before?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurring_credit_grant_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_from_id?: InputMaybe<Scalars['uuid']>;
  rolled_over_to_id?: InputMaybe<Scalars['uuid']>;
  rollover_settings?: InputMaybe<Scalars['jsonb']>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  voided_at?: InputMaybe<Scalars['timestamptz']>;
  voided_by?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CreditGrant_Sum_Fields = {
  __typename?: 'CreditGrant_sum_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_paid: Maybe<Scalars['numeric']>;
  priority: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CreditGrant" */
export type CreditGrant_Sum_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** update columns of table "CreditGrant" */
export enum CreditGrant_Update_Column {
  /** column name */
  AmountGranted = 'amount_granted',
  /** column name */
  AmountGrantedCreditTypeId = 'amount_granted_credit_type_id',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountPaidCreditTypeId = 'amount_paid_credit_type_id',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EffectiveAt = 'effective_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  ExpiresBefore = 'expires_before',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductIds = 'product_ids',
  /** column name */
  Reason = 'reason',
  /** column name */
  RecurringCreditGrantId = 'recurring_credit_grant_id',
  /** column name */
  RolledOverFromId = 'rolled_over_from_id',
  /** column name */
  RolledOverToId = 'rolled_over_to_id',
  /** column name */
  RolloverSettings = 'rollover_settings',
  /** column name */
  UniquenessKey = 'uniqueness_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  VoidedAt = 'voided_at',
  /** column name */
  VoidedBy = 'voided_by'
}

export type CreditGrant_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CreditGrant_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CreditGrant_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CreditGrant_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CreditGrant_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditGrant_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CreditGrant_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditGrant_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditGrant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditGrant_Var_Pop_Fields = {
  __typename?: 'CreditGrant_var_pop_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CreditGrant" */
export type CreditGrant_Var_Pop_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CreditGrant_Var_Samp_Fields = {
  __typename?: 'CreditGrant_var_samp_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CreditGrant" */
export type CreditGrant_Var_Samp_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CreditGrant_Variance_Fields = {
  __typename?: 'CreditGrant_variance_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CreditGrant" */
export type CreditGrant_Variance_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
};

export type CreditLineItem = Mri_LineItem & {
  __typename?: 'CreditLineItem';
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  display_name: Scalars['String'];
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  time_range: Maybe<TimeRange>;
  total: Scalars['numeric'];
};

export type CreditPurchaseChargeLineItem = Mri_LineItem & {
  __typename?: 'CreditPurchaseChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  quantity: Scalars['numeric'];
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type CreditPurchaseInvoice = Mri_Invoice & {
  __typename?: 'CreditPurchaseInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type CreditPurchaseInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type CreditPurchaseLineItem = Mri_LineItem & {
  __typename?: 'CreditPurchaseLineItem';
  credit_grant: CreditGrantUnion;
  credit_type: CreditType;
  display_name: Scalars['String'];
  sub_line_items: Array<CreditPurchaseChargeLineItem>;
  total: Scalars['numeric'];
};

/** columns and relationships of "CreditType" */
export type CreditType = {
  __typename?: 'CreditType';
  archived_at: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  environment_type: Maybe<EnvironmentTypeEnum_Enum>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "CreditTypeConversion" */
export type CreditTypeConversion = {
  __typename?: 'CreditTypeConversion';
  /** An object relationship */
  CustomCreditType: CreditType;
  /** An object relationship */
  FiatCreditType: CreditType;
  /** An object relationship */
  Plan: Plan;
  created_at: Scalars['timestamptz'];
  custom_credit_type_id: Scalars['uuid'];
  fiat_currency_credit_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
  to_fiat_conversion_factor: Scalars['numeric'];
};

/** columns and relationships of "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment = {
  __typename?: 'CreditTypeConversionAdjustment';
  /** An object relationship */
  CustomCreditType: CreditType;
  /** An object relationship */
  CustomerPlan: CustomerPlan;
  /** An object relationship */
  FiatCreditType: CreditType;
  created_at: Scalars['timestamptz'];
  custom_credit_type_id: Scalars['uuid'];
  customer_plan_id: Scalars['uuid'];
  fiat_currency_credit_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  start_period: Scalars['numeric'];
  to_fiat_conversion_factor: Scalars['numeric'];
};

/** aggregated selection of "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Aggregate = {
  __typename?: 'CreditTypeConversionAdjustment_aggregate';
  aggregate: Maybe<CreditTypeConversionAdjustment_Aggregate_Fields>;
  nodes: Array<CreditTypeConversionAdjustment>;
};

export type CreditTypeConversionAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<CreditTypeConversionAdjustment_Aggregate_Bool_Exp_Count>;
};

export type CreditTypeConversionAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Aggregate_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_aggregate_fields';
  avg: Maybe<CreditTypeConversionAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditTypeConversionAdjustment_Max_Fields>;
  min: Maybe<CreditTypeConversionAdjustment_Min_Fields>;
  stddev: Maybe<CreditTypeConversionAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<CreditTypeConversionAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditTypeConversionAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<CreditTypeConversionAdjustment_Sum_Fields>;
  var_pop: Maybe<CreditTypeConversionAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<CreditTypeConversionAdjustment_Var_Samp_Fields>;
  variance: Maybe<CreditTypeConversionAdjustment_Variance_Fields>;
};


/** aggregate fields of "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<CreditTypeConversionAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CreditTypeConversionAdjustment_Max_Order_By>;
  min?: InputMaybe<CreditTypeConversionAdjustment_Min_Order_By>;
  stddev?: InputMaybe<CreditTypeConversionAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CreditTypeConversionAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CreditTypeConversionAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CreditTypeConversionAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<CreditTypeConversionAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CreditTypeConversionAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<CreditTypeConversionAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Arr_Rel_Insert_Input = {
  data: Array<CreditTypeConversionAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditTypeConversionAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type CreditTypeConversionAdjustment_Avg_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_avg_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Avg_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CreditTypeConversionAdjustment". All fields are combined with a logical 'AND'. */
export type CreditTypeConversionAdjustment_Bool_Exp = {
  CustomCreditType?: InputMaybe<CreditType_Bool_Exp>;
  CustomerPlan?: InputMaybe<CustomerPlan_Bool_Exp>;
  FiatCreditType?: InputMaybe<CreditType_Bool_Exp>;
  _and?: InputMaybe<Array<CreditTypeConversionAdjustment_Bool_Exp>>;
  _not?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<CreditTypeConversionAdjustment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  customer_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  fiat_currency_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
  to_fiat_conversion_factor?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditTypeConversionAdjustment" */
export enum CreditTypeConversionAdjustment_Constraint {
  /** unique or primary key constraint on columns "custom_credit_type_id", "start_period", "customer_plan_id" */
  CreditTypeConversionAdjustmentCustomerPlanCustomCreditType = 'CreditTypeConversionAdjustment_customer_plan_custom_credit_type',
  /** unique or primary key constraint on columns "id" */
  CreditTypeConversionAdjustmentPkey = 'CreditTypeConversionAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Inc_Input = {
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Insert_Input = {
  CustomCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  CustomerPlan?: InputMaybe<CustomerPlan_Obj_Rel_Insert_Input>;
  FiatCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CreditTypeConversionAdjustment_Max_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_credit_type_id: Maybe<Scalars['uuid']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  fiat_currency_credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CreditTypeConversionAdjustment_Min_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_credit_type_id: Maybe<Scalars['uuid']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  fiat_currency_credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Mutation_Response = {
  __typename?: 'CreditTypeConversionAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditTypeConversionAdjustment>;
};

/** on_conflict condition type for table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_On_Conflict = {
  constraint: CreditTypeConversionAdjustment_Constraint;
  update_columns?: Array<CreditTypeConversionAdjustment_Update_Column>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditTypeConversionAdjustment". */
export type CreditTypeConversionAdjustment_Order_By = {
  CustomCreditType?: InputMaybe<CreditType_Order_By>;
  CustomerPlan?: InputMaybe<CustomerPlan_Order_By>;
  FiatCreditType?: InputMaybe<CreditType_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditTypeConversionAdjustment */
export type CreditTypeConversionAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditTypeConversionAdjustment" */
export enum CreditTypeConversionAdjustment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomCreditTypeId = 'custom_credit_type_id',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  FiatCurrencyCreditTypeId = 'fiat_currency_credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  ToFiatConversionFactor = 'to_fiat_conversion_factor'
}

/** input type for updating data in table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CreditTypeConversionAdjustment_Stddev_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_stddev_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Stddev_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CreditTypeConversionAdjustment_Stddev_Pop_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_stddev_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Stddev_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CreditTypeConversionAdjustment_Stddev_Samp_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_stddev_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Stddev_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditTypeConversionAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditTypeConversionAdjustment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type CreditTypeConversionAdjustment_Sum_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_sum_fields';
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Sum_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** update columns of table "CreditTypeConversionAdjustment" */
export enum CreditTypeConversionAdjustment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomCreditTypeId = 'custom_credit_type_id',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  FiatCurrencyCreditTypeId = 'fiat_currency_credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  ToFiatConversionFactor = 'to_fiat_conversion_factor'
}

export type CreditTypeConversionAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditTypeConversionAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditTypeConversionAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditTypeConversionAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditTypeConversionAdjustment_Var_Pop_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_var_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Var_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CreditTypeConversionAdjustment_Var_Samp_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_var_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Var_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CreditTypeConversionAdjustment_Variance_Fields = {
  __typename?: 'CreditTypeConversionAdjustment_variance_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CreditTypeConversionAdjustment" */
export type CreditTypeConversionAdjustment_Variance_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

export type CreditTypeConversionInput = {
  custom_credit_type_id: Scalars['uuid'];
  fiat_currency_credit_type_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
  to_fiat_conversion_factor: Scalars['numeric'];
};

/** aggregated selection of "CreditTypeConversion" */
export type CreditTypeConversion_Aggregate = {
  __typename?: 'CreditTypeConversion_aggregate';
  aggregate: Maybe<CreditTypeConversion_Aggregate_Fields>;
  nodes: Array<CreditTypeConversion>;
};

export type CreditTypeConversion_Aggregate_Bool_Exp = {
  count?: InputMaybe<CreditTypeConversion_Aggregate_Bool_Exp_Count>;
};

export type CreditTypeConversion_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CreditTypeConversion_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CreditTypeConversion" */
export type CreditTypeConversion_Aggregate_Fields = {
  __typename?: 'CreditTypeConversion_aggregate_fields';
  avg: Maybe<CreditTypeConversion_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CreditTypeConversion_Max_Fields>;
  min: Maybe<CreditTypeConversion_Min_Fields>;
  stddev: Maybe<CreditTypeConversion_Stddev_Fields>;
  stddev_pop: Maybe<CreditTypeConversion_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CreditTypeConversion_Stddev_Samp_Fields>;
  sum: Maybe<CreditTypeConversion_Sum_Fields>;
  var_pop: Maybe<CreditTypeConversion_Var_Pop_Fields>;
  var_samp: Maybe<CreditTypeConversion_Var_Samp_Fields>;
  variance: Maybe<CreditTypeConversion_Variance_Fields>;
};


/** aggregate fields of "CreditTypeConversion" */
export type CreditTypeConversion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CreditTypeConversion" */
export type CreditTypeConversion_Aggregate_Order_By = {
  avg?: InputMaybe<CreditTypeConversion_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CreditTypeConversion_Max_Order_By>;
  min?: InputMaybe<CreditTypeConversion_Min_Order_By>;
  stddev?: InputMaybe<CreditTypeConversion_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CreditTypeConversion_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CreditTypeConversion_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CreditTypeConversion_Sum_Order_By>;
  var_pop?: InputMaybe<CreditTypeConversion_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CreditTypeConversion_Var_Samp_Order_By>;
  variance?: InputMaybe<CreditTypeConversion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CreditTypeConversion" */
export type CreditTypeConversion_Arr_Rel_Insert_Input = {
  data: Array<CreditTypeConversion_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditTypeConversion_On_Conflict>;
};

/** aggregate avg on columns */
export type CreditTypeConversion_Avg_Fields = {
  __typename?: 'CreditTypeConversion_avg_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Avg_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CreditTypeConversion". All fields are combined with a logical 'AND'. */
export type CreditTypeConversion_Bool_Exp = {
  CustomCreditType?: InputMaybe<CreditType_Bool_Exp>;
  FiatCreditType?: InputMaybe<CreditType_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  _and?: InputMaybe<Array<CreditTypeConversion_Bool_Exp>>;
  _not?: InputMaybe<CreditTypeConversion_Bool_Exp>;
  _or?: InputMaybe<Array<CreditTypeConversion_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  fiat_currency_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
  to_fiat_conversion_factor?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditTypeConversion" */
export enum CreditTypeConversion_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditTypeConversionPkey = 'CreditTypeConversion_pkey',
  /** unique or primary key constraint on columns "custom_credit_type_id", "start_period", "plan_id" */
  CreditTypeConversionPlanCustomCreditTypeStartKey = 'CreditTypeConversion_plan_custom_credit_type_start_key'
}

/** input type for incrementing numeric columns in table "CreditTypeConversion" */
export type CreditTypeConversion_Inc_Input = {
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditTypeConversion" */
export type CreditTypeConversion_Insert_Input = {
  CustomCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  FiatCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CreditTypeConversion_Max_Fields = {
  __typename?: 'CreditTypeConversion_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_credit_type_id: Maybe<Scalars['uuid']>;
  fiat_currency_credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CreditTypeConversion_Min_Fields = {
  __typename?: 'CreditTypeConversion_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_credit_type_id: Maybe<Scalars['uuid']>;
  fiat_currency_credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CreditTypeConversion" */
export type CreditTypeConversion_Mutation_Response = {
  __typename?: 'CreditTypeConversion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditTypeConversion>;
};

/** on_conflict condition type for table "CreditTypeConversion" */
export type CreditTypeConversion_On_Conflict = {
  constraint: CreditTypeConversion_Constraint;
  update_columns?: Array<CreditTypeConversion_Update_Column>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditTypeConversion". */
export type CreditTypeConversion_Order_By = {
  CustomCreditType?: InputMaybe<CreditType_Order_By>;
  FiatCreditType?: InputMaybe<CreditType_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_credit_type_id?: InputMaybe<Order_By>;
  fiat_currency_credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditTypeConversion */
export type CreditTypeConversion_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditTypeConversion" */
export enum CreditTypeConversion_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomCreditTypeId = 'custom_credit_type_id',
  /** column name */
  FiatCurrencyCreditTypeId = 'fiat_currency_credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  ToFiatConversionFactor = 'to_fiat_conversion_factor'
}

/** input type for updating data in table "CreditTypeConversion" */
export type CreditTypeConversion_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CreditTypeConversion_Stddev_Fields = {
  __typename?: 'CreditTypeConversion_stddev_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Stddev_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CreditTypeConversion_Stddev_Pop_Fields = {
  __typename?: 'CreditTypeConversion_stddev_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Stddev_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CreditTypeConversion_Stddev_Samp_Fields = {
  __typename?: 'CreditTypeConversion_stddev_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Stddev_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CreditTypeConversion" */
export type CreditTypeConversion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditTypeConversion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditTypeConversion_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_credit_type_id?: InputMaybe<Scalars['uuid']>;
  fiat_currency_credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  to_fiat_conversion_factor?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type CreditTypeConversion_Sum_Fields = {
  __typename?: 'CreditTypeConversion_sum_fields';
  start_period: Maybe<Scalars['numeric']>;
  to_fiat_conversion_factor: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Sum_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** update columns of table "CreditTypeConversion" */
export enum CreditTypeConversion_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomCreditTypeId = 'custom_credit_type_id',
  /** column name */
  FiatCurrencyCreditTypeId = 'fiat_currency_credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  ToFiatConversionFactor = 'to_fiat_conversion_factor'
}

export type CreditTypeConversion_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CreditTypeConversion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditTypeConversion_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditTypeConversion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CreditTypeConversion_Var_Pop_Fields = {
  __typename?: 'CreditTypeConversion_var_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Var_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CreditTypeConversion_Var_Samp_Fields = {
  __typename?: 'CreditTypeConversion_var_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Var_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CreditTypeConversion_Variance_Fields = {
  __typename?: 'CreditTypeConversion_variance_fields';
  start_period: Maybe<Scalars['Float']>;
  to_fiat_conversion_factor: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CreditTypeConversion" */
export type CreditTypeConversion_Variance_Order_By = {
  start_period?: InputMaybe<Order_By>;
  to_fiat_conversion_factor?: InputMaybe<Order_By>;
};

/** aggregated selection of "CreditType" */
export type CreditType_Aggregate = {
  __typename?: 'CreditType_aggregate';
  aggregate: Maybe<CreditType_Aggregate_Fields>;
  nodes: Array<CreditType>;
};

/** aggregate fields of "CreditType" */
export type CreditType_Aggregate_Fields = {
  __typename?: 'CreditType_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CreditType_Max_Fields>;
  min: Maybe<CreditType_Min_Fields>;
};


/** aggregate fields of "CreditType" */
export type CreditType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CreditType". All fields are combined with a logical 'AND'. */
export type CreditType_Bool_Exp = {
  _and?: InputMaybe<Array<CreditType_Bool_Exp>>;
  _not?: InputMaybe<CreditType_Bool_Exp>;
  _or?: InputMaybe<Array<CreditType_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditType" */
export enum CreditType_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditTypePkey = 'CreditType_pkey'
}

/** input type for inserting data into table "CreditType" */
export type CreditType_Insert_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CreditType_Max_Fields = {
  __typename?: 'CreditType_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CreditType_Min_Fields = {
  __typename?: 'CreditType_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "CreditType" */
export type CreditType_Mutation_Response = {
  __typename?: 'CreditType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditType>;
};

/** input type for inserting object relation for remote table "CreditType" */
export type CreditType_Obj_Rel_Insert_Input = {
  data: CreditType_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditType_On_Conflict>;
};

/** on_conflict condition type for table "CreditType" */
export type CreditType_On_Conflict = {
  constraint: CreditType_Constraint;
  update_columns?: Array<CreditType_Update_Column>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditType". */
export type CreditType_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditType */
export type CreditType_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditType" */
export enum CreditType_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CreditType" */
export type CreditType_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "CreditType" */
export type CreditType_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CreditType_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CreditType_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "CreditType" */
export enum CreditType_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CreditType_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CreditType_Set_Input>;
  /** filter the rows which have to be updated */
  where: CreditType_Bool_Exp;
};

export type CurrentPlanUsage = {
  __typename?: 'CurrentPlanUsage';
  billable_metric: BillableMetric;
  group_key: Maybe<Scalars['String']>;
  usage: Array<UsageWindow>;
};

/** columns and relationships of "CustomPricing" */
export type CustomPricing = {
  __typename?: 'CustomPricing';
  /** An object relationship */
  CustomerPlan: CustomerPlan;
  /** An array relationship */
  CustomerPlanAdjustments: Array<CustomerPlanAdjustment>;
  /** An aggregate relationship */
  CustomerPlanAdjustments_aggregate: CustomerPlanAdjustment_Aggregate;
  /** An array relationship */
  PricedProductAdjustments: Array<PricedProductAdjustment>;
  /** An aggregate relationship */
  PricedProductAdjustments_aggregate: PricedProductAdjustment_Aggregate;
  /** An array relationship */
  PricedProductPricingFactorAdjustments: Array<PricedProductPricingFactorAdjustment>;
  /** An aggregate relationship */
  PricedProductPricingFactorAdjustments_aggregate: PricedProductPricingFactorAdjustment_Aggregate;
  created_at: Scalars['timestamptz'];
  customer_plan_id: Scalars['uuid'];
  id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingCustomerPlanAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingCustomerPlanAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingPricedProductAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingPricedProductAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingPricedProductPricingFactorAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomPricing" */
export type CustomPricingPricedProductPricingFactorAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};

export type CustomPricingInput = {
  priced_product_pricing_factor_adjustments: Array<PricedProductPricingFactorAdjustmentInput>;
};

/** aggregated selection of "CustomPricing" */
export type CustomPricing_Aggregate = {
  __typename?: 'CustomPricing_aggregate';
  aggregate: Maybe<CustomPricing_Aggregate_Fields>;
  nodes: Array<CustomPricing>;
};

export type CustomPricing_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomPricing_Aggregate_Bool_Exp_Count>;
};

export type CustomPricing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomPricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomPricing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomPricing" */
export type CustomPricing_Aggregate_Fields = {
  __typename?: 'CustomPricing_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomPricing_Max_Fields>;
  min: Maybe<CustomPricing_Min_Fields>;
};


/** aggregate fields of "CustomPricing" */
export type CustomPricing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomPricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomPricing" */
export type CustomPricing_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomPricing_Max_Order_By>;
  min?: InputMaybe<CustomPricing_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CustomPricing" */
export type CustomPricing_Arr_Rel_Insert_Input = {
  data: Array<CustomPricing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomPricing_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomPricing". All fields are combined with a logical 'AND'. */
export type CustomPricing_Bool_Exp = {
  CustomerPlan?: InputMaybe<CustomerPlan_Bool_Exp>;
  CustomerPlanAdjustments?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
  CustomerPlanAdjustments_aggregate?: InputMaybe<CustomerPlanAdjustment_Aggregate_Bool_Exp>;
  PricedProductAdjustments?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
  PricedProductAdjustments_aggregate?: InputMaybe<PricedProductAdjustment_Aggregate_Bool_Exp>;
  PricedProductPricingFactorAdjustments?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  PricedProductPricingFactorAdjustments_aggregate?: InputMaybe<PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CustomPricing_Bool_Exp>>;
  _not?: InputMaybe<CustomPricing_Bool_Exp>;
  _or?: InputMaybe<Array<CustomPricing_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomPricing" */
export enum CustomPricing_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomPricingPkey = 'CustomPricing_pkey'
}

/** input type for inserting data into table "CustomPricing" */
export type CustomPricing_Insert_Input = {
  CustomerPlan?: InputMaybe<CustomerPlan_Obj_Rel_Insert_Input>;
  CustomerPlanAdjustments?: InputMaybe<CustomerPlanAdjustment_Arr_Rel_Insert_Input>;
  PricedProductAdjustments?: InputMaybe<PricedProductAdjustment_Arr_Rel_Insert_Input>;
  PricedProductPricingFactorAdjustments?: InputMaybe<PricedProductPricingFactorAdjustment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CustomPricing_Max_Fields = {
  __typename?: 'CustomPricing_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "CustomPricing" */
export type CustomPricing_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomPricing_Min_Fields = {
  __typename?: 'CustomPricing_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "CustomPricing" */
export type CustomPricing_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomPricing" */
export type CustomPricing_Mutation_Response = {
  __typename?: 'CustomPricing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomPricing>;
};

/** input type for inserting object relation for remote table "CustomPricing" */
export type CustomPricing_Obj_Rel_Insert_Input = {
  data: CustomPricing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomPricing_On_Conflict>;
};

/** on_conflict condition type for table "CustomPricing" */
export type CustomPricing_On_Conflict = {
  constraint: CustomPricing_Constraint;
  update_columns?: Array<CustomPricing_Update_Column>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomPricing". */
export type CustomPricing_Order_By = {
  CustomerPlan?: InputMaybe<CustomerPlan_Order_By>;
  CustomerPlanAdjustments_aggregate?: InputMaybe<CustomerPlanAdjustment_Aggregate_Order_By>;
  PricedProductAdjustments_aggregate?: InputMaybe<PricedProductAdjustment_Aggregate_Order_By>;
  PricedProductPricingFactorAdjustments_aggregate?: InputMaybe<PricedProductPricingFactorAdjustment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomPricing */
export type CustomPricing_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomPricing" */
export enum CustomPricing_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id'
}

/** input type for updating data in table "CustomPricing" */
export type CustomPricing_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "CustomPricing" */
export type CustomPricing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomPricing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomPricing_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "CustomPricing" */
export enum CustomPricing_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id'
}

export type CustomPricing_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomPricing_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomPricing_Bool_Exp;
};

export type CustomRate = {
  __typename?: 'CustomRate';
  credit_type: CreditType;
  value: Scalars['JSONObject'];
};

export type CustomRateInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  value: Scalars['JSONObject'];
};

/** columns and relationships of "Customer" */
export type Customer = {
  __typename?: 'Customer';
  /** An array relationship */
  BillingProviderCustomers: Array<BillingProviderCustomer>;
  /** An aggregate relationship */
  BillingProviderCustomers_aggregate: BillingProviderCustomer_Aggregate;
  /** An object relationship */
  Client: Client;
  /** An array relationship */
  CreditGrants: Array<CreditGrant>;
  /** An aggregate relationship */
  CreditGrants_aggregate: CreditGrant_Aggregate;
  /** An array relationship */
  CustomerAlerts: Array<CustomerAlert>;
  /** An aggregate relationship */
  CustomerAlerts_aggregate: CustomerAlert_Aggregate;
  /** An array relationship */
  CustomerConfigs: Array<CustomerConfig>;
  /** An aggregate relationship */
  CustomerConfigs_aggregate: CustomerConfig_Aggregate;
  /** An array relationship */
  CustomerIngestAliases: Array<CustomerIngestAlias>;
  /** An aggregate relationship */
  CustomerIngestAliases_aggregate: CustomerIngestAlias_Aggregate;
  /** An array relationship */
  CustomerManagedFields: Array<CustomerManagedField>;
  /** An aggregate relationship */
  CustomerManagedFields_aggregate: CustomerManagedField_Aggregate;
  /** An array relationship */
  CustomerPlans: Array<CustomerPlan>;
  /** An aggregate relationship */
  CustomerPlans_aggregate: CustomerPlan_Aggregate;
  alerts: Array<CustomerAlertComposite>;
  archived_at: Maybe<Scalars['timestamptz']>;
  billable_status: BillableStatusWithEffectiveDate;
  client_id: Scalars['uuid'];
  commits: Array<CommitUnion>;
  contract: Maybe<Contract>;
  contract_status: Array<CustomerContractStatus>;
  contracts: Array<Contract>;
  created_at: Scalars['timestamptz'];
  credit_grants: Array<Mri_CreditGrant>;
  current_plan_usage: Maybe<Array<CurrentPlanUsage>>;
  current_seat_counts: Array<SeatCount>;
  /**
   * Returns the date of the last finalized invoice for this customer. This only includes invoices that could
   * consume credits, so it only includes ArrearsInvoices and AdvanceInvoices right now
   */
  end_date_of_last_finalized_invoice: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  has_contract: Scalars['Boolean'];
  id: Scalars['uuid'];
  /**
   * Returns invoices where the invoice [start_date, lock_date) intersects with
   * this query's [ending_after, starting_before) aka [start_date, end_date) (as half-open intervals).
   * If ending_after isn't supplied, it's inferred as the customer's earliest plan start date.
   * NOTE: we're renaming (start_date, end_date) to (ending_after, starting_before). Only one pair of these should be given.
   * If status and/or credit_type_id are given, filters down to just invoices with matching status / credit_type.
   */
  invoices: Array<Invoice>;
  managed_fields: Array<CustomerManagedField>;
  /**
   * Returns invoices where the issue date is within this query's
   * [inclusive_start_date, exclusive_end_date) interval,
   * or where the invoice service period intersects with that query interval.
   * If the query interval is not specified, then will just paginate through
   * all active or finalized invoices for the customer.
   */
  mri_invoices: InvoicePage;
  mri_ledgers: Array<Mri_CreditLedger>;
  name: Scalars['String'];
  named_schedule: Array<NamedScheduleSegment>;
  named_schedules: Array<NamedSchedule>;
  plan_status: Array<CustomerPlanStatus>;
  recharge_settings: Array<RechargeSettings>;
  /**
   * Returns service periods where the invoice [start_date, lock_date) intersects with
   * this query's [ending_after, starting_before) aka [start_date, end_date) (as half-open intervals).
   * If finalized_only is true, only includes periods where the lock date is before or
   * equal to the end_date in the query. Otherwise, includes service periods not only for
   * the customer's plans, but also service periods for any time they're not on a plan.
   * NOTE: we're renaming (start_date, end_date) to (ending_after, starting_before). Only one pair of these should be given.
   */
  service_periods: Array<ServicePeriod>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Customer" */
export type CustomerBillingProviderCustomersArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerBillingProviderCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCreditGrantsArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCreditGrants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerAlertsArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerAlerts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerConfigsArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerConfigs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerIngestAliasesArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerIngestAliases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerPlansArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerCustomerPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerAlertsArgs = {
  alert_ids?: InputMaybe<Array<Scalars['uuid']>>;
  include_internal_alerts?: InputMaybe<Scalars['Boolean']>;
  types: Array<AlertStatusEnum>;
};


/** columns and relationships of "Customer" */
export type CustomerBillable_StatusArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Customer" */
export type CustomerContractArgs = {
  id: Scalars['uuid'];
};


/** columns and relationships of "Customer" */
export type CustomerCurrent_Plan_UsageArgs = {
  end_date: Scalars['timestamptz'];
  fallback_to_all_metrics?: InputMaybe<Scalars['Boolean']>;
  start_date: Scalars['timestamptz'];
};


/** columns and relationships of "Customer" */
export type CustomerInvoicesArgs = {
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  ending_after?: InputMaybe<Scalars['timestamptz']>;
  schedule_type?: InputMaybe<Array<InputMaybe<InvoiceScheduleTypeEnum>>>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  starting_before?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<InvoiceStatus>;
};


/** columns and relationships of "Customer" */
export type CustomerMri_InvoicesArgs = {
  billable_status?: InputMaybe<BillableStatusFilter>;
  contract_amendments?: InputMaybe<UuidFilter>;
  contracts?: InputMaybe<UuidFilter>;
  credit_type?: InputMaybe<Scalars['uuid']>;
  cursor?: InputMaybe<Scalars['String']>;
  exclude_on_hold_invoices?: InputMaybe<Scalars['Boolean']>;
  exclusive_end_date?: InputMaybe<Scalars['timestamptz']>;
  generation_overrides?: InputMaybe<InvoiceGenerationOverrides>;
  ids?: InputMaybe<UuidFilter>;
  inclusive_start_date?: InputMaybe<Scalars['timestamptz']>;
  invoices_overlapping_arrears_invoices_covering_this_date?: InputMaybe<Scalars['timestamptz']>;
  limit?: InputMaybe<Scalars['Int']>;
  plans?: InputMaybe<UuidFilter>;
  sort?: InputMaybe<InvoiceSortOrder>;
  statuses?: InputMaybe<Array<InvoiceStatusEnum>>;
  types?: InputMaybe<Array<Scalars['String']>>;
};


/** columns and relationships of "Customer" */
export type CustomerMri_LedgersArgs = {
  credit_type_ids?: InputMaybe<Array<Scalars['uuid']>>;
};


/** columns and relationships of "Customer" */
export type CustomerNamed_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};


/** columns and relationships of "Customer" */
export type CustomerService_PeriodsArgs = {
  contracted_only?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  ending_after?: InputMaybe<Scalars['timestamptz']>;
  finalized_only?: InputMaybe<Scalars['Boolean']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  starting_before?: InputMaybe<Scalars['timestamptz']>;
};

export type CustomerAggregate = {
  __typename?: 'CustomerAggregate';
  rate_updates_hash: Scalars['String'];
  version: Scalars['Int'];
};

/** columns and relationships of "CustomerAlert" */
export type CustomerAlert = {
  __typename?: 'CustomerAlert';
  /** An object relationship */
  Alert: Alert;
  /** An object relationship */
  Customer: Customer;
  /** An array relationship */
  CustomerAlertHistories: Array<CustomerAlertHistory>;
  /** An aggregate relationship */
  CustomerAlertHistories_aggregate: CustomerAlertHistory_Aggregate;
  additional_data: Maybe<Scalars['jsonb']>;
  alert_id: Scalars['uuid'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  current_status: CustomerAlertStatusEnum_Enum;
  customer_id: Scalars['uuid'];
  environment_type: Scalars['String'];
  granular_idempotency_key: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_evaluation_time: Maybe<Scalars['timestamptz']>;
  triggered_by: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "CustomerAlert" */
export type CustomerAlertCustomerAlertHistoriesArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


/** columns and relationships of "CustomerAlert" */
export type CustomerAlertCustomerAlertHistories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


/** columns and relationships of "CustomerAlert" */
export type CustomerAlertAdditional_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type CustomerAlertComposite = {
  __typename?: 'CustomerAlertComposite';
  alert: Alert;
  created_at: Maybe<Scalars['timestamptz']>;
  customer: Customer;
  customer_status: Maybe<CustomerAlertCompositeStatusEnum>;
  id: Maybe<Scalars['uuid']>;
  last_evaluation_time: Maybe<Scalars['timestamptz']>;
  triggered_by: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

export enum CustomerAlertCompositeStatusEnum {
  Evaluating = 'EVALUATING',
  InAlarm = 'IN_ALARM',
  Ok = 'OK'
}

/** columns and relationships of "CustomerAlertHistory" */
export type CustomerAlertHistory = {
  __typename?: 'CustomerAlertHistory';
  /** An object relationship */
  CustomerAlert: CustomerAlert;
  additional_data: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  customer_alert_id: Scalars['uuid'];
  id: Scalars['uuid'];
  status: CustomerAlertStatusEnum_Enum;
};


/** columns and relationships of "CustomerAlertHistory" */
export type CustomerAlertHistoryAdditional_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "CustomerAlertHistory" */
export type CustomerAlertHistory_Aggregate = {
  __typename?: 'CustomerAlertHistory_aggregate';
  aggregate: Maybe<CustomerAlertHistory_Aggregate_Fields>;
  nodes: Array<CustomerAlertHistory>;
};

export type CustomerAlertHistory_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerAlertHistory_Aggregate_Bool_Exp_Count>;
};

export type CustomerAlertHistory_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerAlertHistory" */
export type CustomerAlertHistory_Aggregate_Fields = {
  __typename?: 'CustomerAlertHistory_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerAlertHistory_Max_Fields>;
  min: Maybe<CustomerAlertHistory_Min_Fields>;
};


/** aggregate fields of "CustomerAlertHistory" */
export type CustomerAlertHistory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerAlertHistory" */
export type CustomerAlertHistory_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerAlertHistory_Max_Order_By>;
  min?: InputMaybe<CustomerAlertHistory_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomerAlertHistory_Append_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "CustomerAlertHistory" */
export type CustomerAlertHistory_Arr_Rel_Insert_Input = {
  data: Array<CustomerAlertHistory_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerAlertHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerAlertHistory". All fields are combined with a logical 'AND'. */
export type CustomerAlertHistory_Bool_Exp = {
  CustomerAlert?: InputMaybe<CustomerAlert_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerAlertHistory_Bool_Exp>>;
  _not?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerAlertHistory_Bool_Exp>>;
  additional_data?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_alert_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<CustomerAlertStatusEnum_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerAlertHistory" */
export enum CustomerAlertHistory_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerAlertHistoryPkey = 'CustomerAlertHistory_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomerAlertHistory_Delete_At_Path_Input = {
  additional_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomerAlertHistory_Delete_Elem_Input = {
  additional_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomerAlertHistory_Delete_Key_Input = {
  additional_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "CustomerAlertHistory" */
export type CustomerAlertHistory_Insert_Input = {
  CustomerAlert?: InputMaybe<CustomerAlert_Obj_Rel_Insert_Input>;
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_alert_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
};

/** aggregate max on columns */
export type CustomerAlertHistory_Max_Fields = {
  __typename?: 'CustomerAlertHistory_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_alert_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "CustomerAlertHistory" */
export type CustomerAlertHistory_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_alert_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerAlertHistory_Min_Fields = {
  __typename?: 'CustomerAlertHistory_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_alert_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "CustomerAlertHistory" */
export type CustomerAlertHistory_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_alert_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerAlertHistory" */
export type CustomerAlertHistory_Mutation_Response = {
  __typename?: 'CustomerAlertHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerAlertHistory>;
};

/** on_conflict condition type for table "CustomerAlertHistory" */
export type CustomerAlertHistory_On_Conflict = {
  constraint: CustomerAlertHistory_Constraint;
  update_columns?: Array<CustomerAlertHistory_Update_Column>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerAlertHistory". */
export type CustomerAlertHistory_Order_By = {
  CustomerAlert?: InputMaybe<CustomerAlert_Order_By>;
  additional_data?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_alert_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerAlertHistory */
export type CustomerAlertHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomerAlertHistory_Prepend_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CustomerAlertHistory" */
export enum CustomerAlertHistory_Select_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerAlertId = 'customer_alert_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "CustomerAlertHistory" */
export type CustomerAlertHistory_Set_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_alert_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
};

/** Streaming cursor of the table "CustomerAlertHistory" */
export type CustomerAlertHistory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerAlertHistory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerAlertHistory_Stream_Cursor_Value_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_alert_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
};

/** update columns of table "CustomerAlertHistory" */
export enum CustomerAlertHistory_Update_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerAlertId = 'customer_alert_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

export type CustomerAlertHistory_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomerAlertHistory_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomerAlertHistory_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomerAlertHistory_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomerAlertHistory_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomerAlertHistory_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerAlertHistory_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerAlertHistory_Bool_Exp;
};

/** columns and relationships of "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum = {
  __typename?: 'CustomerAlertStatusEnum';
  value: Scalars['String'];
};

/** aggregated selection of "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Aggregate = {
  __typename?: 'CustomerAlertStatusEnum_aggregate';
  aggregate: Maybe<CustomerAlertStatusEnum_Aggregate_Fields>;
  nodes: Array<CustomerAlertStatusEnum>;
};

/** aggregate fields of "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Aggregate_Fields = {
  __typename?: 'CustomerAlertStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerAlertStatusEnum_Max_Fields>;
  min: Maybe<CustomerAlertStatusEnum_Min_Fields>;
};


/** aggregate fields of "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerAlertStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CustomerAlertStatusEnum". All fields are combined with a logical 'AND'. */
export type CustomerAlertStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<CustomerAlertStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerAlertStatusEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerAlertStatusEnum" */
export enum CustomerAlertStatusEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerAlertStatusEnumPkey = 'CustomerAlertStatusEnum_pkey'
}

export enum CustomerAlertStatusEnum_Enum {
  InAlarm = 'in_alarm',
  Ok = 'ok'
}

/** Boolean expression to compare columns of type "CustomerAlertStatusEnum_enum". All fields are combined with logical 'AND'. */
export type CustomerAlertStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CustomerAlertStatusEnum_Enum>;
  _in?: InputMaybe<Array<CustomerAlertStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CustomerAlertStatusEnum_Enum>;
  _nin?: InputMaybe<Array<CustomerAlertStatusEnum_Enum>>;
};

/** input type for inserting data into table "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomerAlertStatusEnum_Max_Fields = {
  __typename?: 'CustomerAlertStatusEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CustomerAlertStatusEnum_Min_Fields = {
  __typename?: 'CustomerAlertStatusEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Mutation_Response = {
  __typename?: 'CustomerAlertStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerAlertStatusEnum>;
};

/** on_conflict condition type for table "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_On_Conflict = {
  constraint: CustomerAlertStatusEnum_Constraint;
  update_columns?: Array<CustomerAlertStatusEnum_Update_Column>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerAlertStatusEnum". */
export type CustomerAlertStatusEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerAlertStatusEnum */
export type CustomerAlertStatusEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CustomerAlertStatusEnum" */
export enum CustomerAlertStatusEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CustomerAlertStatusEnum" */
export type CustomerAlertStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerAlertStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerAlertStatusEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CustomerAlertStatusEnum" */
export enum CustomerAlertStatusEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type CustomerAlertStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerAlertStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerAlertStatusEnum_Bool_Exp;
};

/** aggregated selection of "CustomerAlert" */
export type CustomerAlert_Aggregate = {
  __typename?: 'CustomerAlert_aggregate';
  aggregate: Maybe<CustomerAlert_Aggregate_Fields>;
  nodes: Array<CustomerAlert>;
};

export type CustomerAlert_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerAlert_Aggregate_Bool_Exp_Count>;
};

export type CustomerAlert_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerAlert_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerAlert" */
export type CustomerAlert_Aggregate_Fields = {
  __typename?: 'CustomerAlert_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerAlert_Max_Fields>;
  min: Maybe<CustomerAlert_Min_Fields>;
};


/** aggregate fields of "CustomerAlert" */
export type CustomerAlert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerAlert" */
export type CustomerAlert_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerAlert_Max_Order_By>;
  min?: InputMaybe<CustomerAlert_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomerAlert_Append_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "CustomerAlert" */
export type CustomerAlert_Arr_Rel_Insert_Input = {
  data: Array<CustomerAlert_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerAlert_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerAlert". All fields are combined with a logical 'AND'. */
export type CustomerAlert_Bool_Exp = {
  Alert?: InputMaybe<Alert_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  CustomerAlertHistories?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
  CustomerAlertHistories_aggregate?: InputMaybe<CustomerAlertHistory_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerAlert_Bool_Exp>>;
  _not?: InputMaybe<CustomerAlert_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerAlert_Bool_Exp>>;
  additional_data?: InputMaybe<Jsonb_Comparison_Exp>;
  alert_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_status?: InputMaybe<CustomerAlertStatusEnum_Enum_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  environment_type?: InputMaybe<String_Comparison_Exp>;
  granular_idempotency_key?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_evaluation_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  triggered_by?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerAlert" */
export enum CustomerAlert_Constraint {
  /** unique or primary key constraint on columns "customer_id", "alert_id", "granular_idempotency_key" */
  CustomerAlertCustomerIdAlertIdIdemKey = 'CustomerAlert_customer_id_alert_id_idem_key',
  /** unique or primary key constraint on columns "id" */
  CustomerAlertPkey = 'CustomerAlert_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomerAlert_Delete_At_Path_Input = {
  additional_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomerAlert_Delete_Elem_Input = {
  additional_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomerAlert_Delete_Key_Input = {
  additional_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "CustomerAlert" */
export type CustomerAlert_Insert_Input = {
  Alert?: InputMaybe<Alert_Obj_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  CustomerAlertHistories?: InputMaybe<CustomerAlertHistory_Arr_Rel_Insert_Input>;
  additional_data?: InputMaybe<Scalars['jsonb']>;
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<Scalars['String']>;
  granular_idempotency_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_evaluation_time?: InputMaybe<Scalars['timestamptz']>;
  triggered_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomerAlert_Max_Fields = {
  __typename?: 'CustomerAlert_max_fields';
  alert_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  environment_type: Maybe<Scalars['String']>;
  granular_idempotency_key: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  last_evaluation_time: Maybe<Scalars['timestamptz']>;
  triggered_by: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "CustomerAlert" */
export type CustomerAlert_Max_Order_By = {
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  granular_idempotency_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_evaluation_time?: InputMaybe<Order_By>;
  triggered_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerAlert_Min_Fields = {
  __typename?: 'CustomerAlert_min_fields';
  alert_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  environment_type: Maybe<Scalars['String']>;
  granular_idempotency_key: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  last_evaluation_time: Maybe<Scalars['timestamptz']>;
  triggered_by: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "CustomerAlert" */
export type CustomerAlert_Min_Order_By = {
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  granular_idempotency_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_evaluation_time?: InputMaybe<Order_By>;
  triggered_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerAlert" */
export type CustomerAlert_Mutation_Response = {
  __typename?: 'CustomerAlert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerAlert>;
};

/** input type for inserting object relation for remote table "CustomerAlert" */
export type CustomerAlert_Obj_Rel_Insert_Input = {
  data: CustomerAlert_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerAlert_On_Conflict>;
};

/** on_conflict condition type for table "CustomerAlert" */
export type CustomerAlert_On_Conflict = {
  constraint: CustomerAlert_Constraint;
  update_columns?: Array<CustomerAlert_Update_Column>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerAlert". */
export type CustomerAlert_Order_By = {
  Alert?: InputMaybe<Alert_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  CustomerAlertHistories_aggregate?: InputMaybe<CustomerAlertHistory_Aggregate_Order_By>;
  additional_data?: InputMaybe<Order_By>;
  alert_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_status?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  granular_idempotency_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_evaluation_time?: InputMaybe<Order_By>;
  triggered_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerAlert */
export type CustomerAlert_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomerAlert_Prepend_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "CustomerAlert" */
export enum CustomerAlert_Select_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStatus = 'current_status',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  GranularIdempotencyKey = 'granular_idempotency_key',
  /** column name */
  Id = 'id',
  /** column name */
  LastEvaluationTime = 'last_evaluation_time',
  /** column name */
  TriggeredBy = 'triggered_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CustomerAlert" */
export type CustomerAlert_Set_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<Scalars['String']>;
  granular_idempotency_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_evaluation_time?: InputMaybe<Scalars['timestamptz']>;
  triggered_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "CustomerAlert" */
export type CustomerAlert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerAlert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerAlert_Stream_Cursor_Value_Input = {
  additional_data?: InputMaybe<Scalars['jsonb']>;
  alert_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_status?: InputMaybe<CustomerAlertStatusEnum_Enum>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<Scalars['String']>;
  granular_idempotency_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_evaluation_time?: InputMaybe<Scalars['timestamptz']>;
  triggered_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "CustomerAlert" */
export enum CustomerAlert_Update_Column {
  /** column name */
  AdditionalData = 'additional_data',
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStatus = 'current_status',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  GranularIdempotencyKey = 'granular_idempotency_key',
  /** column name */
  Id = 'id',
  /** column name */
  LastEvaluationTime = 'last_evaluation_time',
  /** column name */
  TriggeredBy = 'triggered_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CustomerAlert_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomerAlert_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomerAlert_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomerAlert_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomerAlert_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomerAlert_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerAlert_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerAlert_Bool_Exp;
};

export type CustomerAlertsSortingInput = {
  ascending: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by: CustomerAlertsSortingOrderByEnum;
  search?: InputMaybe<Scalars['String']>;
};

export enum CustomerAlertsSortingOrderByEnum {
  AlertStatus = 'ALERT_STATUS',
  CustomerName = 'CUSTOMER_NAME'
}

export type CustomerBillingProviderConfiguration = {
  __typename?: 'CustomerBillingProviderConfiguration';
  billing_provider: BillingProviderEnum_Enum;
  configuration: Scalars['FlatKeyValueMap'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  delivery_method: BillingProviderDeliveryMethod_Enum;
  delivery_method_configuration: Scalars['FlatKeyValueMap'];
  delivery_method_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

export type CustomerBillingProviderConfigurationNotification = {
  __typename?: 'CustomerBillingProviderConfigurationNotification';
  contract_id: Scalars['uuid'];
  customer_billing_provider_configuration: CustomerBillingProviderConfiguration;
  notify_at: Scalars['timestamptz'];
};

export type CustomerBillingProviderConfigurationNotificationInput = {
  config_first_assigned_to_contract_at: Scalars['timestamptz'];
  customer_billing_provider_configuration_id: Scalars['uuid'];
  notification_sent_at: Scalars['timestamptz'];
};

export type CustomerCommitInput = {
  access_schedule: CommitAccessScheduleInput;
  applicable_contract_ids?: InputMaybe<Array<Scalars['uuid']>>;
  applicable_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  applicable_tags?: InputMaybe<Array<Scalars['String']>>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  description?: InputMaybe<Scalars['String']>;
  external_type?: InputMaybe<ExternalCommitType>;
  invoice_contract_id?: InputMaybe<Scalars['uuid']>;
  invoice_schedule?: InputMaybe<CommitInvoiceScheduleInput>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  priority: Scalars['numeric'];
  product_id: Scalars['uuid'];
  salesforce_opportunity_id?: InputMaybe<Scalars['String']>;
  type: CommitType;
};

/** columns and relationships of "CustomerConfig" */
export type CustomerConfig = {
  __typename?: 'CustomerConfig';
  /** An object relationship */
  Customer: Customer;
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

export type CustomerConfigInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CustomerConfig" */
export type CustomerConfig_Aggregate = {
  __typename?: 'CustomerConfig_aggregate';
  aggregate: Maybe<CustomerConfig_Aggregate_Fields>;
  nodes: Array<CustomerConfig>;
};

export type CustomerConfig_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerConfig_Aggregate_Bool_Exp_Count>;
};

export type CustomerConfig_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerConfig_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerConfig" */
export type CustomerConfig_Aggregate_Fields = {
  __typename?: 'CustomerConfig_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerConfig_Max_Fields>;
  min: Maybe<CustomerConfig_Min_Fields>;
};


/** aggregate fields of "CustomerConfig" */
export type CustomerConfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerConfig" */
export type CustomerConfig_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerConfig_Max_Order_By>;
  min?: InputMaybe<CustomerConfig_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerConfig" */
export type CustomerConfig_Arr_Rel_Insert_Input = {
  data: Array<CustomerConfig_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerConfig_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerConfig". All fields are combined with a logical 'AND'. */
export type CustomerConfig_Bool_Exp = {
  Customer?: InputMaybe<Customer_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerConfig_Bool_Exp>>;
  _not?: InputMaybe<CustomerConfig_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerConfig_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerConfig" */
export enum CustomerConfig_Constraint {
  /** unique or primary key constraint on columns "key", "customer_id" */
  CustomerConfigClientIdCustomerIdKeyKey = 'CustomerConfig_client_id_customer_id_key_key',
  /** unique or primary key constraint on columns "id" */
  CustomerConfigPkey = 'CustomerConfig_pkey'
}

/** input type for inserting data into table "CustomerConfig" */
export type CustomerConfig_Insert_Input = {
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomerConfig_Max_Fields = {
  __typename?: 'CustomerConfig_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CustomerConfig" */
export type CustomerConfig_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerConfig_Min_Fields = {
  __typename?: 'CustomerConfig_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CustomerConfig" */
export type CustomerConfig_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerConfig" */
export type CustomerConfig_Mutation_Response = {
  __typename?: 'CustomerConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerConfig>;
};

/** on_conflict condition type for table "CustomerConfig" */
export type CustomerConfig_On_Conflict = {
  constraint: CustomerConfig_Constraint;
  update_columns?: Array<CustomerConfig_Update_Column>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerConfig". */
export type CustomerConfig_Order_By = {
  Customer?: InputMaybe<Customer_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerConfig */
export type CustomerConfig_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerConfig" */
export enum CustomerConfig_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CustomerConfig" */
export type CustomerConfig_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CustomerConfig" */
export type CustomerConfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerConfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerConfig_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CustomerConfig" */
export enum CustomerConfig_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type CustomerConfig_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerConfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerConfig_Bool_Exp;
};

export type CustomerContractStatus = {
  __typename?: 'CustomerContractStatus';
  archived_at: Maybe<Scalars['timestamptz']>;
  ending_before: Maybe<Scalars['timestamptz']>;
  starting_at: Scalars['timestamptz'];
  transition_type: Maybe<Scalars['String']>;
};

export type CustomerCount = {
  __typename?: 'CustomerCount';
  count: Scalars['Int'];
  estimated: Scalars['Boolean'];
};

/** columns and relationships of "CustomerIngestAlias" */
export type CustomerIngestAlias = {
  __typename?: 'CustomerIngestAlias';
  /** An object relationship */
  Customer: Customer;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
  ingest_alias: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CustomerIngestAlias" */
export type CustomerIngestAlias_Aggregate = {
  __typename?: 'CustomerIngestAlias_aggregate';
  aggregate: Maybe<CustomerIngestAlias_Aggregate_Fields>;
  nodes: Array<CustomerIngestAlias>;
};

export type CustomerIngestAlias_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerIngestAlias_Aggregate_Bool_Exp_Count>;
};

export type CustomerIngestAlias_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerIngestAlias" */
export type CustomerIngestAlias_Aggregate_Fields = {
  __typename?: 'CustomerIngestAlias_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerIngestAlias_Max_Fields>;
  min: Maybe<CustomerIngestAlias_Min_Fields>;
};


/** aggregate fields of "CustomerIngestAlias" */
export type CustomerIngestAlias_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerIngestAlias" */
export type CustomerIngestAlias_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerIngestAlias_Max_Order_By>;
  min?: InputMaybe<CustomerIngestAlias_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerIngestAlias" */
export type CustomerIngestAlias_Arr_Rel_Insert_Input = {
  data: Array<CustomerIngestAlias_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerIngestAlias_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerIngestAlias". All fields are combined with a logical 'AND'. */
export type CustomerIngestAlias_Bool_Exp = {
  Customer?: InputMaybe<Customer_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerIngestAlias_Bool_Exp>>;
  _not?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerIngestAlias_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  ingest_alias?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerIngestAlias" */
export enum CustomerIngestAlias_Constraint {
  /** unique or primary key constraint on columns "ingest_alias", "environment_type", "client_id" */
  CustomerIngestAliasClientUniqueness = 'CustomerIngestAlias_client_uniqueness',
  /** unique or primary key constraint on columns "ingest_alias", "customer_id" */
  CustomerIngestAliasCustomerIdIngestAliasKey = 'CustomerIngestAlias_customer_id_ingest_alias_key',
  /** unique or primary key constraint on columns "ingest_alias", "customer_id", "client_id" */
  CustomerIngestAliasPkey = 'CustomerIngestAlias_pkey'
}

/** input type for inserting data into table "CustomerIngestAlias" */
export type CustomerIngestAlias_Insert_Input = {
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_alias?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomerIngestAlias_Max_Fields = {
  __typename?: 'CustomerIngestAlias_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  ingest_alias: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "CustomerIngestAlias" */
export type CustomerIngestAlias_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  ingest_alias?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerIngestAlias_Min_Fields = {
  __typename?: 'CustomerIngestAlias_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  ingest_alias: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "CustomerIngestAlias" */
export type CustomerIngestAlias_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  ingest_alias?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerIngestAlias" */
export type CustomerIngestAlias_Mutation_Response = {
  __typename?: 'CustomerIngestAlias_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerIngestAlias>;
};

/** on_conflict condition type for table "CustomerIngestAlias" */
export type CustomerIngestAlias_On_Conflict = {
  constraint: CustomerIngestAlias_Constraint;
  update_columns?: Array<CustomerIngestAlias_Update_Column>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerIngestAlias". */
export type CustomerIngestAlias_Order_By = {
  Customer?: InputMaybe<Customer_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  ingest_alias?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerIngestAlias */
export type CustomerIngestAlias_Pk_Columns_Input = {
  client_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ingest_alias: Scalars['String'];
};

/** select columns of table "CustomerIngestAlias" */
export enum CustomerIngestAlias_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  IngestAlias = 'ingest_alias',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CustomerIngestAlias" */
export type CustomerIngestAlias_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_alias?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "CustomerIngestAlias" */
export type CustomerIngestAlias_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerIngestAlias_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerIngestAlias_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_alias?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "CustomerIngestAlias" */
export enum CustomerIngestAlias_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  IngestAlias = 'ingest_alias',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CustomerIngestAlias_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerIngestAlias_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerIngestAlias_Bool_Exp;
};

export type CustomerList = {
  __typename?: 'CustomerList';
  customers: Array<Customer>;
  next_cursor: Maybe<Scalars['String']>;
  prev_cursor: Maybe<Scalars['String']>;
};

/** columns and relationships of "CustomerManagedField" */
export type CustomerManagedField = {
  __typename?: 'CustomerManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  Customer: Customer;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  customer_id: Scalars['uuid'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CustomerManagedField" */
export type CustomerManagedField_Aggregate = {
  __typename?: 'CustomerManagedField_aggregate';
  aggregate: Maybe<CustomerManagedField_Aggregate_Fields>;
  nodes: Array<CustomerManagedField>;
};

export type CustomerManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CustomerManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CustomerManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CustomerManagedField_Aggregate_Bool_Exp_Count>;
};

export type CustomerManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomerManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomerManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerManagedField" */
export type CustomerManagedField_Aggregate_Fields = {
  __typename?: 'CustomerManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerManagedField_Max_Fields>;
  min: Maybe<CustomerManagedField_Min_Fields>;
};


/** aggregate fields of "CustomerManagedField" */
export type CustomerManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerManagedField" */
export type CustomerManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerManagedField_Max_Order_By>;
  min?: InputMaybe<CustomerManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerManagedField" */
export type CustomerManagedField_Arr_Rel_Insert_Input = {
  data: Array<CustomerManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerManagedField". All fields are combined with a logical 'AND'. */
export type CustomerManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerManagedField_Bool_Exp>>;
  _not?: InputMaybe<CustomerManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerManagedField" */
export enum CustomerManagedField_Constraint {
  /** unique or primary key constraint on columns "customer_id", "key_id" */
  CustomerManagedFieldCustomerIdKeyIdKey = 'CustomerManagedField_customer_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  CustomerManagedFieldKeyIdValueEnforceUniquenessClientKey = 'CustomerManagedField_key_id_value_enforce_uniqueness_client_key',
  /** unique or primary key constraint on columns "id" */
  CustomerManagedFieldPkey = 'CustomerManagedField_pkey'
}

/** input type for inserting data into table "CustomerManagedField" */
export type CustomerManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomerManagedField_Max_Fields = {
  __typename?: 'CustomerManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CustomerManagedField" */
export type CustomerManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerManagedField_Min_Fields = {
  __typename?: 'CustomerManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CustomerManagedField" */
export type CustomerManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerManagedField" */
export type CustomerManagedField_Mutation_Response = {
  __typename?: 'CustomerManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerManagedField>;
};

/** on_conflict condition type for table "CustomerManagedField" */
export type CustomerManagedField_On_Conflict = {
  constraint: CustomerManagedField_Constraint;
  update_columns?: Array<CustomerManagedField_Update_Column>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerManagedField". */
export type CustomerManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerManagedField */
export type CustomerManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerManagedField" */
export enum CustomerManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "CustomerManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CustomerManagedField" */
export enum CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "CustomerManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CustomerManagedField" */
export enum CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "CustomerManagedField" */
export type CustomerManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CustomerManagedField" */
export type CustomerManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CustomerManagedField" */
export enum CustomerManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type CustomerManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerManagedField_Bool_Exp;
};

/** columns and relationships of "CustomerPlan" */
export type CustomerPlan = {
  __typename?: 'CustomerPlan';
  /** An array relationship */
  CreditTypeConversionAdjustments: Array<CreditTypeConversionAdjustment>;
  /** An aggregate relationship */
  CreditTypeConversionAdjustments_aggregate: CreditTypeConversionAdjustment_Aggregate;
  /** An array relationship */
  CustomPricings: Array<CustomPricing>;
  /** An aggregate relationship */
  CustomPricings_aggregate: CustomPricing_Aggregate;
  /** An object relationship */
  Customer: Customer;
  /** An array relationship */
  CustomerPlanManagedFields: Array<CustomerPlanManagedField>;
  /** An aggregate relationship */
  CustomerPlanManagedFields_aggregate: CustomerPlanManagedField_Aggregate;
  /** An object relationship */
  EnvironmentTypeEnum: EnvironmentTypeEnum;
  /** An object relationship */
  Plan: Plan;
  /** An object relationship */
  TrialSpec: Maybe<TrialSpec>;
  already_invoiced: Scalars['Boolean'];
  cancellation_date: Maybe<Scalars['timestamptz']>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  deleted_at: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  managed_fields: Array<CustomerPlanManagedField>;
  mri_remaining_trial_spending_caps: Array<CreditAmountWithType>;
  net_payment_terms_days: Maybe<Scalars['Int']>;
  plan_id: Scalars['uuid'];
  start_date: Scalars['timestamptz'];
  trial_spec: Maybe<TrialSpec>;
  trial_spec_id: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCreditTypeConversionAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCreditTypeConversionAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCustomPricingsArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCustomPricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCustomerPlanManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


/** columns and relationships of "CustomerPlan" */
export type CustomerPlanCustomerPlanManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};

/** columns and relationships of "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment = {
  __typename?: 'CustomerPlanAdjustment';
  /** An object relationship */
  CustomPricing: CustomPricing;
  created_at: Scalars['timestamptz'];
  custom_pricing_id: Scalars['uuid'];
  id: Scalars['uuid'];
  percent_adjustment: Scalars['numeric'];
  start_period: Scalars['numeric'];
};

/** aggregated selection of "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Aggregate = {
  __typename?: 'CustomerPlanAdjustment_aggregate';
  aggregate: Maybe<CustomerPlanAdjustment_Aggregate_Fields>;
  nodes: Array<CustomerPlanAdjustment>;
};

export type CustomerPlanAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerPlanAdjustment_Aggregate_Bool_Exp_Count>;
};

export type CustomerPlanAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Aggregate_Fields = {
  __typename?: 'CustomerPlanAdjustment_aggregate_fields';
  avg: Maybe<CustomerPlanAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CustomerPlanAdjustment_Max_Fields>;
  min: Maybe<CustomerPlanAdjustment_Min_Fields>;
  stddev: Maybe<CustomerPlanAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<CustomerPlanAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CustomerPlanAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<CustomerPlanAdjustment_Sum_Fields>;
  var_pop: Maybe<CustomerPlanAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<CustomerPlanAdjustment_Var_Samp_Fields>;
  variance: Maybe<CustomerPlanAdjustment_Variance_Fields>;
};


/** aggregate fields of "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<CustomerPlanAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerPlanAdjustment_Max_Order_By>;
  min?: InputMaybe<CustomerPlanAdjustment_Min_Order_By>;
  stddev?: InputMaybe<CustomerPlanAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CustomerPlanAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CustomerPlanAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CustomerPlanAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<CustomerPlanAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CustomerPlanAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<CustomerPlanAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Arr_Rel_Insert_Input = {
  data: Array<CustomerPlanAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerPlanAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type CustomerPlanAdjustment_Avg_Fields = {
  __typename?: 'CustomerPlanAdjustment_avg_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Avg_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CustomerPlanAdjustment". All fields are combined with a logical 'AND'. */
export type CustomerPlanAdjustment_Bool_Exp = {
  CustomPricing?: InputMaybe<CustomPricing_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerPlanAdjustment_Bool_Exp>>;
  _not?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerPlanAdjustment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_pricing_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  percent_adjustment?: InputMaybe<Numeric_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerPlanAdjustment" */
export enum CustomerPlanAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPlanAdjustmentPkey = 'CustomerPlanAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Inc_Input = {
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Insert_Input = {
  CustomPricing?: InputMaybe<CustomPricing_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CustomerPlanAdjustment_Max_Fields = {
  __typename?: 'CustomerPlanAdjustment_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  percent_adjustment: Maybe<Scalars['numeric']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerPlanAdjustment_Min_Fields = {
  __typename?: 'CustomerPlanAdjustment_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  percent_adjustment: Maybe<Scalars['numeric']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Mutation_Response = {
  __typename?: 'CustomerPlanAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerPlanAdjustment>;
};

/** on_conflict condition type for table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_On_Conflict = {
  constraint: CustomerPlanAdjustment_Constraint;
  update_columns?: Array<CustomerPlanAdjustment_Update_Column>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerPlanAdjustment". */
export type CustomerPlanAdjustment_Order_By = {
  CustomPricing?: InputMaybe<CustomPricing_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerPlanAdjustment */
export type CustomerPlanAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerPlanAdjustment" */
export enum CustomerPlanAdjustment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PercentAdjustment = 'percent_adjustment',
  /** column name */
  StartPeriod = 'start_period'
}

/** input type for updating data in table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CustomerPlanAdjustment_Stddev_Fields = {
  __typename?: 'CustomerPlanAdjustment_stddev_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Stddev_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CustomerPlanAdjustment_Stddev_Pop_Fields = {
  __typename?: 'CustomerPlanAdjustment_stddev_pop_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Stddev_Pop_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CustomerPlanAdjustment_Stddev_Samp_Fields = {
  __typename?: 'CustomerPlanAdjustment_stddev_samp_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Stddev_Samp_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerPlanAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerPlanAdjustment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type CustomerPlanAdjustment_Sum_Fields = {
  __typename?: 'CustomerPlanAdjustment_sum_fields';
  percent_adjustment: Maybe<Scalars['numeric']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Sum_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** update columns of table "CustomerPlanAdjustment" */
export enum CustomerPlanAdjustment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PercentAdjustment = 'percent_adjustment',
  /** column name */
  StartPeriod = 'start_period'
}

export type CustomerPlanAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomerPlanAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerPlanAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerPlanAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomerPlanAdjustment_Var_Pop_Fields = {
  __typename?: 'CustomerPlanAdjustment_var_pop_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Var_Pop_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CustomerPlanAdjustment_Var_Samp_Fields = {
  __typename?: 'CustomerPlanAdjustment_var_samp_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Var_Samp_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CustomerPlanAdjustment_Variance_Fields = {
  __typename?: 'CustomerPlanAdjustment_variance_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CustomerPlanAdjustment" */
export type CustomerPlanAdjustment_Variance_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** columns and relationships of "CustomerPlanManagedField" */
export type CustomerPlanManagedField = {
  __typename?: 'CustomerPlanManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  CustomerPlan: CustomerPlan;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  customer_plan_id: Scalars['uuid'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Aggregate = {
  __typename?: 'CustomerPlanManagedField_aggregate';
  aggregate: Maybe<CustomerPlanManagedField_Aggregate_Fields>;
  nodes: Array<CustomerPlanManagedField>;
};

export type CustomerPlanManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<CustomerPlanManagedField_Aggregate_Bool_Exp_Count>;
};

export type CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type CustomerPlanManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Aggregate_Fields = {
  __typename?: 'CustomerPlanManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerPlanManagedField_Max_Fields>;
  min: Maybe<CustomerPlanManagedField_Min_Fields>;
};


/** aggregate fields of "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerPlanManagedField_Max_Order_By>;
  min?: InputMaybe<CustomerPlanManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Arr_Rel_Insert_Input = {
  data: Array<CustomerPlanManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerPlanManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CustomerPlanManagedField". All fields are combined with a logical 'AND'. */
export type CustomerPlanManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CustomerPlan?: InputMaybe<CustomerPlan_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerPlanManagedField_Bool_Exp>>;
  _not?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerPlanManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  customer_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerPlanManagedField" */
export enum CustomerPlanManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "customer_plan_id" */
  CustomerPlanManagedFieldCustomerPlanIdKeyIdKey = 'CustomerPlanManagedField_customer_plan_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  CustomerPlanManagedFieldKeyIdValueEnforceUniquenessClKey = 'CustomerPlanManagedField_key_id_value_enforce_uniqueness_cl_key',
  /** unique or primary key constraint on columns "id" */
  CustomerPlanManagedFieldPkey = 'CustomerPlanManagedField_pkey'
}

/** input type for inserting data into table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CustomerPlan?: InputMaybe<CustomerPlan_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomerPlanManagedField_Max_Fields = {
  __typename?: 'CustomerPlanManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerPlanManagedField_Min_Fields = {
  __typename?: 'CustomerPlanManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Mutation_Response = {
  __typename?: 'CustomerPlanManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerPlanManagedField>;
};

/** on_conflict condition type for table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_On_Conflict = {
  constraint: CustomerPlanManagedField_Constraint;
  update_columns?: Array<CustomerPlanManagedField_Update_Column>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerPlanManagedField". */
export type CustomerPlanManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  CustomerPlan?: InputMaybe<CustomerPlan_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerPlanManagedField */
export type CustomerPlanManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerPlanManagedField" */
export enum CustomerPlanManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "CustomerPlanManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "CustomerPlanManagedField" */
export enum CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "CustomerPlanManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "CustomerPlanManagedField" */
export enum CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "CustomerPlanManagedField" */
export type CustomerPlanManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerPlanManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerPlanManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CustomerPlanManagedField" */
export enum CustomerPlanManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type CustomerPlanManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerPlanManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerPlanManagedField_Bool_Exp;
};

/** columns and relationships of "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge = {
  __typename?: 'CustomerPlanOneTimeCharge';
  /** An object relationship */
  CustomerPlan: CustomerPlan;
  /** An object relationship */
  ProductPricingFactor: ProductPricingFactor;
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  credit_type_id: Scalars['uuid'];
  customer_plan_id: Scalars['uuid'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  price: Scalars['numeric'];
  product_pricing_factor_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  service_period_start_date: Scalars['timestamptz'];
  sub_line_item_id: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Aggregate = {
  __typename?: 'CustomerPlanOneTimeCharge_aggregate';
  aggregate: Maybe<CustomerPlanOneTimeCharge_Aggregate_Fields>;
  nodes: Array<CustomerPlanOneTimeCharge>;
};

/** aggregate fields of "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Aggregate_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_aggregate_fields';
  avg: Maybe<CustomerPlanOneTimeCharge_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CustomerPlanOneTimeCharge_Max_Fields>;
  min: Maybe<CustomerPlanOneTimeCharge_Min_Fields>;
  stddev: Maybe<CustomerPlanOneTimeCharge_Stddev_Fields>;
  stddev_pop: Maybe<CustomerPlanOneTimeCharge_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CustomerPlanOneTimeCharge_Stddev_Samp_Fields>;
  sum: Maybe<CustomerPlanOneTimeCharge_Sum_Fields>;
  var_pop: Maybe<CustomerPlanOneTimeCharge_Var_Pop_Fields>;
  var_samp: Maybe<CustomerPlanOneTimeCharge_Var_Samp_Fields>;
  variance: Maybe<CustomerPlanOneTimeCharge_Variance_Fields>;
};


/** aggregate fields of "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerPlanOneTimeCharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CustomerPlanOneTimeCharge_Avg_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_avg_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CustomerPlanOneTimeCharge". All fields are combined with a logical 'AND'. */
export type CustomerPlanOneTimeCharge_Bool_Exp = {
  CustomerPlan?: InputMaybe<CustomerPlan_Bool_Exp>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerPlanOneTimeCharge_Bool_Exp>>;
  _not?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerPlanOneTimeCharge_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  customer_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  service_period_start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  sub_line_item_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerPlanOneTimeCharge" */
export enum CustomerPlanOneTimeCharge_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPlanOneTimeChargePkey = 'CustomerPlanOneTimeCharge_pkey'
}

/** input type for incrementing numeric columns in table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Insert_Input = {
  CustomerPlan?: InputMaybe<CustomerPlan_Obj_Rel_Insert_Input>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Obj_Rel_Insert_Input>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  service_period_start_date?: InputMaybe<Scalars['timestamptz']>;
  sub_line_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CustomerPlanOneTimeCharge_Max_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  price: Maybe<Scalars['numeric']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  service_period_start_date: Maybe<Scalars['timestamptz']>;
  sub_line_item_id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CustomerPlanOneTimeCharge_Min_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  created_at: Maybe<Scalars['timestamptz']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  customer_plan_id: Maybe<Scalars['uuid']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  price: Maybe<Scalars['numeric']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  service_period_start_date: Maybe<Scalars['timestamptz']>;
  sub_line_item_id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Mutation_Response = {
  __typename?: 'CustomerPlanOneTimeCharge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerPlanOneTimeCharge>;
};

/** on_conflict condition type for table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_On_Conflict = {
  constraint: CustomerPlanOneTimeCharge_Constraint;
  update_columns?: Array<CustomerPlanOneTimeCharge_Update_Column>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerPlanOneTimeCharge". */
export type CustomerPlanOneTimeCharge_Order_By = {
  CustomerPlan?: InputMaybe<CustomerPlan_Order_By>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Order_By>;
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  customer_plan_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_period_start_date?: InputMaybe<Order_By>;
  sub_line_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerPlanOneTimeCharge */
export type CustomerPlanOneTimeCharge_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerPlanOneTimeCharge" */
export enum CustomerPlanOneTimeCharge_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServicePeriodStartDate = 'service_period_start_date',
  /** column name */
  SubLineItemId = 'sub_line_item_id'
}

/** input type for updating data in table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  service_period_start_date?: InputMaybe<Scalars['timestamptz']>;
  sub_line_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CustomerPlanOneTimeCharge_Stddev_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_stddev_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CustomerPlanOneTimeCharge_Stddev_Pop_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_stddev_pop_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CustomerPlanOneTimeCharge_Stddev_Samp_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_stddev_samp_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "CustomerPlanOneTimeCharge" */
export type CustomerPlanOneTimeCharge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerPlanOneTimeCharge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerPlanOneTimeCharge_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  customer_plan_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  service_period_start_date?: InputMaybe<Scalars['timestamptz']>;
  sub_line_item_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CustomerPlanOneTimeCharge_Sum_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_sum_fields';
  price: Maybe<Scalars['numeric']>;
  quantity: Maybe<Scalars['numeric']>;
};

/** update columns of table "CustomerPlanOneTimeCharge" */
export enum CustomerPlanOneTimeCharge_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  CustomerPlanId = 'customer_plan_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServicePeriodStartDate = 'service_period_start_date',
  /** column name */
  SubLineItemId = 'sub_line_item_id'
}

export type CustomerPlanOneTimeCharge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomerPlanOneTimeCharge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerPlanOneTimeCharge_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerPlanOneTimeCharge_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomerPlanOneTimeCharge_Var_Pop_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_var_pop_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CustomerPlanOneTimeCharge_Var_Samp_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_var_samp_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CustomerPlanOneTimeCharge_Variance_Fields = {
  __typename?: 'CustomerPlanOneTimeCharge_variance_fields';
  price: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

export type CustomerPlanStatus = {
  __typename?: 'CustomerPlanStatus';
  ending_before: Maybe<Scalars['timestamptz']>;
  starting_at: Scalars['timestamptz'];
};

export enum CustomerPlanStatusEnum {
  Active = 'ACTIVE',
  ActiveAndFuture = 'ACTIVE_AND_FUTURE',
  ActiveAndLapsed = 'ACTIVE_AND_LAPSED',
  Any = 'ANY',
  Future = 'FUTURE',
  Lapsed = 'LAPSED'
}

/** aggregated selection of "CustomerPlan" */
export type CustomerPlan_Aggregate = {
  __typename?: 'CustomerPlan_aggregate';
  aggregate: Maybe<CustomerPlan_Aggregate_Fields>;
  nodes: Array<CustomerPlan>;
};

export type CustomerPlan_Aggregate_Bool_Exp = {
  count?: InputMaybe<CustomerPlan_Aggregate_Bool_Exp_Count>;
};

export type CustomerPlan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CustomerPlan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "CustomerPlan" */
export type CustomerPlan_Aggregate_Fields = {
  __typename?: 'CustomerPlan_aggregate_fields';
  avg: Maybe<CustomerPlan_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<CustomerPlan_Max_Fields>;
  min: Maybe<CustomerPlan_Min_Fields>;
  stddev: Maybe<CustomerPlan_Stddev_Fields>;
  stddev_pop: Maybe<CustomerPlan_Stddev_Pop_Fields>;
  stddev_samp: Maybe<CustomerPlan_Stddev_Samp_Fields>;
  sum: Maybe<CustomerPlan_Sum_Fields>;
  var_pop: Maybe<CustomerPlan_Var_Pop_Fields>;
  var_samp: Maybe<CustomerPlan_Var_Samp_Fields>;
  variance: Maybe<CustomerPlan_Variance_Fields>;
};


/** aggregate fields of "CustomerPlan" */
export type CustomerPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CustomerPlan" */
export type CustomerPlan_Aggregate_Order_By = {
  avg?: InputMaybe<CustomerPlan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<CustomerPlan_Max_Order_By>;
  min?: InputMaybe<CustomerPlan_Min_Order_By>;
  stddev?: InputMaybe<CustomerPlan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<CustomerPlan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<CustomerPlan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<CustomerPlan_Sum_Order_By>;
  var_pop?: InputMaybe<CustomerPlan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<CustomerPlan_Var_Samp_Order_By>;
  variance?: InputMaybe<CustomerPlan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CustomerPlan" */
export type CustomerPlan_Arr_Rel_Insert_Input = {
  data: Array<CustomerPlan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerPlan_On_Conflict>;
};

/** aggregate avg on columns */
export type CustomerPlan_Avg_Fields = {
  __typename?: 'CustomerPlan_avg_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CustomerPlan" */
export type CustomerPlan_Avg_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "CustomerPlan". All fields are combined with a logical 'AND'. */
export type CustomerPlan_Bool_Exp = {
  CreditTypeConversionAdjustments?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
  CreditTypeConversionAdjustments_aggregate?: InputMaybe<CreditTypeConversionAdjustment_Aggregate_Bool_Exp>;
  CustomPricings?: InputMaybe<CustomPricing_Bool_Exp>;
  CustomPricings_aggregate?: InputMaybe<CustomPricing_Aggregate_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  CustomerPlanManagedFields?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  CustomerPlanManagedFields_aggregate?: InputMaybe<CustomerPlanManagedField_Aggregate_Bool_Exp>;
  EnvironmentTypeEnum?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  TrialSpec?: InputMaybe<TrialSpec_Bool_Exp>;
  _and?: InputMaybe<Array<CustomerPlan_Bool_Exp>>;
  _not?: InputMaybe<CustomerPlan_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerPlan_Bool_Exp>>;
  cancellation_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  net_payment_terms_days?: InputMaybe<Int_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  trial_spec_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerPlan" */
export enum CustomerPlan_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPlanPkey = 'CustomerPlan_pkey'
}

/** input type for incrementing numeric columns in table "CustomerPlan" */
export type CustomerPlan_Inc_Input = {
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CustomerPlan" */
export type CustomerPlan_Insert_Input = {
  CreditTypeConversionAdjustments?: InputMaybe<CreditTypeConversionAdjustment_Arr_Rel_Insert_Input>;
  CustomPricings?: InputMaybe<CustomPricing_Arr_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  CustomerPlanManagedFields?: InputMaybe<CustomerPlanManagedField_Arr_Rel_Insert_Input>;
  EnvironmentTypeEnum?: InputMaybe<EnvironmentTypeEnum_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  TrialSpec?: InputMaybe<TrialSpec_Obj_Rel_Insert_Input>;
  cancellation_date?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CustomerPlan_Max_Fields = {
  __typename?: 'CustomerPlan_max_fields';
  cancellation_date: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  deleted_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  net_payment_terms_days: Maybe<Scalars['Int']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_date: Maybe<Scalars['timestamptz']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "CustomerPlan" */
export type CustomerPlan_Max_Order_By = {
  cancellation_date?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_payment_terms_days?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type CustomerPlan_Min_Fields = {
  __typename?: 'CustomerPlan_min_fields';
  cancellation_date: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  deleted_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  net_payment_terms_days: Maybe<Scalars['Int']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_date: Maybe<Scalars['timestamptz']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "CustomerPlan" */
export type CustomerPlan_Min_Order_By = {
  cancellation_date?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_payment_terms_days?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "CustomerPlan" */
export type CustomerPlan_Mutation_Response = {
  __typename?: 'CustomerPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerPlan>;
};

/** input type for inserting object relation for remote table "CustomerPlan" */
export type CustomerPlan_Obj_Rel_Insert_Input = {
  data: CustomerPlan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerPlan_On_Conflict>;
};

/** on_conflict condition type for table "CustomerPlan" */
export type CustomerPlan_On_Conflict = {
  constraint: CustomerPlan_Constraint;
  update_columns?: Array<CustomerPlan_Update_Column>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerPlan". */
export type CustomerPlan_Order_By = {
  CreditTypeConversionAdjustments_aggregate?: InputMaybe<CreditTypeConversionAdjustment_Aggregate_Order_By>;
  CustomPricings_aggregate?: InputMaybe<CustomPricing_Aggregate_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  CustomerPlanManagedFields_aggregate?: InputMaybe<CustomerPlanManagedField_Aggregate_Order_By>;
  EnvironmentTypeEnum?: InputMaybe<EnvironmentTypeEnum_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  TrialSpec?: InputMaybe<TrialSpec_Order_By>;
  cancellation_date?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  net_payment_terms_days?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerPlan */
export type CustomerPlan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerPlan" */
export enum CustomerPlan_Select_Column {
  /** column name */
  CancellationDate = 'cancellation_date',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetPaymentTermsDays = 'net_payment_terms_days',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TrialSpecId = 'trial_spec_id'
}

/** input type for updating data in table "CustomerPlan" */
export type CustomerPlan_Set_Input = {
  cancellation_date?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CustomerPlan_Stddev_Fields = {
  __typename?: 'CustomerPlan_stddev_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CustomerPlan" */
export type CustomerPlan_Stddev_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CustomerPlan_Stddev_Pop_Fields = {
  __typename?: 'CustomerPlan_stddev_pop_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CustomerPlan" */
export type CustomerPlan_Stddev_Pop_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CustomerPlan_Stddev_Samp_Fields = {
  __typename?: 'CustomerPlan_stddev_samp_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CustomerPlan" */
export type CustomerPlan_Stddev_Samp_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "CustomerPlan" */
export type CustomerPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerPlan_Stream_Cursor_Value_Input = {
  cancellation_date?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type CustomerPlan_Sum_Fields = {
  __typename?: 'CustomerPlan_sum_fields';
  net_payment_terms_days: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CustomerPlan" */
export type CustomerPlan_Sum_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** update columns of table "CustomerPlan" */
export enum CustomerPlan_Update_Column {
  /** column name */
  CancellationDate = 'cancellation_date',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  NetPaymentTermsDays = 'net_payment_terms_days',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TrialSpecId = 'trial_spec_id'
}

export type CustomerPlan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CustomerPlan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerPlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type CustomerPlan_Var_Pop_Fields = {
  __typename?: 'CustomerPlan_var_pop_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CustomerPlan" */
export type CustomerPlan_Var_Pop_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CustomerPlan_Var_Samp_Fields = {
  __typename?: 'CustomerPlan_var_samp_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CustomerPlan" */
export type CustomerPlan_Var_Samp_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type CustomerPlan_Variance_Fields = {
  __typename?: 'CustomerPlan_variance_fields';
  net_payment_terms_days: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CustomerPlan" */
export type CustomerPlan_Variance_Order_By = {
  net_payment_terms_days?: InputMaybe<Order_By>;
};

/** columns and relationships of "CustomerRelationship" */
export type CustomerRelationship = {
  __typename?: 'CustomerRelationship';
  /** An object relationship */
  child_customer: Customer;
  child_customer_id: Scalars['uuid'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  ending_before: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  /** An object relationship */
  parent_customer: Customer;
  parent_customer_id: Scalars['uuid'];
  starting_on: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "CustomerRelationship" */
export type CustomerRelationship_Aggregate = {
  __typename?: 'CustomerRelationship_aggregate';
  aggregate: Maybe<CustomerRelationship_Aggregate_Fields>;
  nodes: Array<CustomerRelationship>;
};

/** aggregate fields of "CustomerRelationship" */
export type CustomerRelationship_Aggregate_Fields = {
  __typename?: 'CustomerRelationship_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<CustomerRelationship_Max_Fields>;
  min: Maybe<CustomerRelationship_Min_Fields>;
};


/** aggregate fields of "CustomerRelationship" */
export type CustomerRelationship_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerRelationship_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CustomerRelationship". All fields are combined with a logical 'AND'. */
export type CustomerRelationship_Bool_Exp = {
  _and?: InputMaybe<Array<CustomerRelationship_Bool_Exp>>;
  _not?: InputMaybe<CustomerRelationship_Bool_Exp>;
  _or?: InputMaybe<Array<CustomerRelationship_Bool_Exp>>;
  child_customer?: InputMaybe<Customer_Bool_Exp>;
  child_customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ending_before?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_customer?: InputMaybe<Customer_Bool_Exp>;
  parent_customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  starting_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "CustomerRelationship" */
export enum CustomerRelationship_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerRelationshipPkey = 'CustomerRelationship_pkey'
}

/** input type for inserting data into table "CustomerRelationship" */
export type CustomerRelationship_Insert_Input = {
  child_customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  child_customer_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  parent_customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  parent_customer_id?: InputMaybe<Scalars['uuid']>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CustomerRelationship_Max_Fields = {
  __typename?: 'CustomerRelationship_max_fields';
  child_customer_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  ending_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  parent_customer_id: Maybe<Scalars['uuid']>;
  starting_on: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type CustomerRelationship_Min_Fields = {
  __typename?: 'CustomerRelationship_min_fields';
  child_customer_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  ending_before: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  parent_customer_id: Maybe<Scalars['uuid']>;
  starting_on: Maybe<Scalars['timestamptz']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "CustomerRelationship" */
export type CustomerRelationship_Mutation_Response = {
  __typename?: 'CustomerRelationship_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerRelationship>;
};

/** on_conflict condition type for table "CustomerRelationship" */
export type CustomerRelationship_On_Conflict = {
  constraint: CustomerRelationship_Constraint;
  update_columns?: Array<CustomerRelationship_Update_Column>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};

/** Ordering options when selecting data from "CustomerRelationship". */
export type CustomerRelationship_Order_By = {
  child_customer?: InputMaybe<Customer_Order_By>;
  child_customer_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ending_before?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_customer?: InputMaybe<Customer_Order_By>;
  parent_customer_id?: InputMaybe<Order_By>;
  starting_on?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CustomerRelationship */
export type CustomerRelationship_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CustomerRelationship" */
export enum CustomerRelationship_Select_Column {
  /** column name */
  ChildCustomerId = 'child_customer_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndingBefore = 'ending_before',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  ParentCustomerId = 'parent_customer_id',
  /** column name */
  StartingOn = 'starting_on',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "CustomerRelationship" */
export type CustomerRelationship_Set_Input = {
  child_customer_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  parent_customer_id?: InputMaybe<Scalars['uuid']>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "CustomerRelationship" */
export type CustomerRelationship_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: CustomerRelationship_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerRelationship_Stream_Cursor_Value_Input = {
  child_customer_id?: InputMaybe<Scalars['uuid']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  parent_customer_id?: InputMaybe<Scalars['uuid']>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "CustomerRelationship" */
export enum CustomerRelationship_Update_Column {
  /** column name */
  ChildCustomerId = 'child_customer_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndingBefore = 'ending_before',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  ParentCustomerId = 'parent_customer_id',
  /** column name */
  StartingOn = 'starting_on',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type CustomerRelationship_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerRelationship_Set_Input>;
  /** filter the rows which have to be updated */
  where: CustomerRelationship_Bool_Exp;
};

export type CustomerUsingContracts = {
  __typename?: 'CustomerUsingContracts';
  customer: Customer;
};

/** aggregated selection of "Customer" */
export type Customer_Aggregate = {
  __typename?: 'Customer_aggregate';
  aggregate: Maybe<Customer_Aggregate_Fields>;
  nodes: Array<Customer>;
};

export type Customer_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Customer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Customer_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Customer_Aggregate_Bool_Exp_Count>;
};

export type Customer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Customer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Customer" */
export type Customer_Aggregate_Fields = {
  __typename?: 'Customer_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Customer_Max_Fields>;
  min: Maybe<Customer_Min_Fields>;
};


/** aggregate fields of "Customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Customer" */
export type Customer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Max_Order_By>;
  min?: InputMaybe<Customer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Customer" */
export type Customer_Arr_Rel_Insert_Input = {
  data: Array<Customer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  BillingProviderCustomers?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
  BillingProviderCustomers_aggregate?: InputMaybe<BillingProviderCustomer_Aggregate_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  CreditGrants?: InputMaybe<CreditGrant_Bool_Exp>;
  CreditGrants_aggregate?: InputMaybe<CreditGrant_Aggregate_Bool_Exp>;
  CustomerAlerts?: InputMaybe<CustomerAlert_Bool_Exp>;
  CustomerAlerts_aggregate?: InputMaybe<CustomerAlert_Aggregate_Bool_Exp>;
  CustomerConfigs?: InputMaybe<CustomerConfig_Bool_Exp>;
  CustomerConfigs_aggregate?: InputMaybe<CustomerConfig_Aggregate_Bool_Exp>;
  CustomerIngestAliases?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
  CustomerIngestAliases_aggregate?: InputMaybe<CustomerIngestAlias_Aggregate_Bool_Exp>;
  CustomerManagedFields?: InputMaybe<CustomerManagedField_Bool_Exp>;
  CustomerManagedFields_aggregate?: InputMaybe<CustomerManagedField_Aggregate_Bool_Exp>;
  CustomerPlans?: InputMaybe<CustomerPlan_Bool_Exp>;
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Bool_Exp>>;
  _not?: InputMaybe<Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  has_contract?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'Customer_pkey'
}

/** input type for inserting data into table "Customer" */
export type Customer_Insert_Input = {
  BillingProviderCustomers?: InputMaybe<BillingProviderCustomer_Arr_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  CreditGrants?: InputMaybe<CreditGrant_Arr_Rel_Insert_Input>;
  CustomerAlerts?: InputMaybe<CustomerAlert_Arr_Rel_Insert_Input>;
  CustomerConfigs?: InputMaybe<CustomerConfig_Arr_Rel_Insert_Input>;
  CustomerIngestAliases?: InputMaybe<CustomerIngestAlias_Arr_Rel_Insert_Input>;
  CustomerManagedFields?: InputMaybe<CustomerManagedField_Arr_Rel_Insert_Input>;
  CustomerPlans?: InputMaybe<CustomerPlan_Arr_Rel_Insert_Input>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  has_contract?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Customer_Max_Fields = {
  __typename?: 'Customer_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Customer" */
export type Customer_Max_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Min_Fields = {
  __typename?: 'Customer_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Customer" */
export type Customer_Min_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Customer" */
export type Customer_Mutation_Response = {
  __typename?: 'Customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer>;
};

/** input type for inserting object relation for remote table "Customer" */
export type Customer_Obj_Rel_Insert_Input = {
  data: Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};

/** on_conflict condition type for table "Customer" */
export type Customer_On_Conflict = {
  constraint: Customer_Constraint;
  update_columns?: Array<Customer_Update_Column>;
  where?: InputMaybe<Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "Customer". */
export type Customer_Order_By = {
  BillingProviderCustomers_aggregate?: InputMaybe<BillingProviderCustomer_Aggregate_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  CreditGrants_aggregate?: InputMaybe<CreditGrant_Aggregate_Order_By>;
  CustomerAlerts_aggregate?: InputMaybe<CustomerAlert_Aggregate_Order_By>;
  CustomerConfigs_aggregate?: InputMaybe<CustomerConfig_Aggregate_Order_By>;
  CustomerIngestAliases_aggregate?: InputMaybe<CustomerIngestAlias_Aggregate_Order_By>;
  CustomerManagedFields_aggregate?: InputMaybe<CustomerManagedField_Aggregate_Order_By>;
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Order_By>;
  archived_at?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  has_contract?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Customer */
export type Customer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Customer" */
export enum Customer_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  HasContract = 'has_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Customer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Customer" */
export enum Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasContract = 'has_contract'
}

/** select "Customer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Customer" */
export enum Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasContract = 'has_contract'
}

/** input type for updating data in table "Customer" */
export type Customer_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  has_contract?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Customer" */
export type Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  has_contract?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Customer" */
export enum Customer_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  HasContract = 'has_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Customer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Bool_Exp;
};

export type CustomersMetric = {
  __typename?: 'CustomersMetric';
  total_customers: Scalars['numeric'];
  total_customers_on_plan: Scalars['numeric'];
};

export type CustomersUsingContracts = {
  __typename?: 'CustomersUsingContracts';
  next_page: Maybe<Scalars['String']>;
  page: Array<CustomerUsingContracts>;
};

export type CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange = {
  __typename?: 'CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange';
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
};

export type CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse = {
  __typename?: 'CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse';
  customers: Array<CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange>;
};

export type Discount = {
  __typename?: 'Discount';
  contract: Contract;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  product: ProductListItem;
  schedule: DiscountSchedule;
};

export type DiscountInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  product_id: Scalars['uuid'];
  schedule: DiscountScheduleInput;
};

export type DiscountSchedule = {
  __typename?: 'DiscountSchedule';
  credit_type: CreditType;
  recurring_schedule: Maybe<RecurringSchedule>;
  schedule_items: Array<DiscountScheduleItem>;
};

export type DiscountScheduleInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  recurring_schedule?: InputMaybe<RecurringScheduleInput>;
  schedule_items?: InputMaybe<Array<DiscountScheduleItemInput>>;
};

export type DiscountScheduleItem = {
  __typename?: 'DiscountScheduleItem';
  amount: Scalars['CreditAmount'];
  date: Scalars['timestamptz'];
  end_date: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  quantity: Scalars['CreditAmount'];
  unit_price: Scalars['CreditAmount'];
};

export type DiscountScheduleItemInput = {
  amount?: InputMaybe<Scalars['CreditAmount']>;
  date: Scalars['timestamptz'];
  end_date?: InputMaybe<Scalars['timestamptz']>;
  quantity?: InputMaybe<Scalars['CreditAmount']>;
  unit_price?: InputMaybe<Scalars['CreditAmount']>;
};

/** columns and relationships of "DraftPlan" */
export type DraftPlan = {
  __typename?: 'DraftPlan';
  /** An object relationship */
  Creator: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  data: Scalars['jsonb'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  revision: Scalars['Int'];
};


/** columns and relationships of "DraftPlan" */
export type DraftPlanDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "DraftPlan" */
export type DraftPlan_Aggregate = {
  __typename?: 'DraftPlan_aggregate';
  aggregate: Maybe<DraftPlan_Aggregate_Fields>;
  nodes: Array<DraftPlan>;
};

/** aggregate fields of "DraftPlan" */
export type DraftPlan_Aggregate_Fields = {
  __typename?: 'DraftPlan_aggregate_fields';
  avg: Maybe<DraftPlan_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<DraftPlan_Max_Fields>;
  min: Maybe<DraftPlan_Min_Fields>;
  stddev: Maybe<DraftPlan_Stddev_Fields>;
  stddev_pop: Maybe<DraftPlan_Stddev_Pop_Fields>;
  stddev_samp: Maybe<DraftPlan_Stddev_Samp_Fields>;
  sum: Maybe<DraftPlan_Sum_Fields>;
  var_pop: Maybe<DraftPlan_Var_Pop_Fields>;
  var_samp: Maybe<DraftPlan_Var_Samp_Fields>;
  variance: Maybe<DraftPlan_Variance_Fields>;
};


/** aggregate fields of "DraftPlan" */
export type DraftPlan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DraftPlan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DraftPlan_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type DraftPlan_Avg_Fields = {
  __typename?: 'DraftPlan_avg_fields';
  revision: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "DraftPlan". All fields are combined with a logical 'AND'. */
export type DraftPlan_Bool_Exp = {
  Creator?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<DraftPlan_Bool_Exp>>;
  _not?: InputMaybe<DraftPlan_Bool_Exp>;
  _or?: InputMaybe<Array<DraftPlan_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  revision?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "DraftPlan" */
export enum DraftPlan_Constraint {
  /** unique or primary key constraint on columns "id" */
  DraftPlanPkey = 'DraftPlan_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DraftPlan_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DraftPlan_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DraftPlan_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "DraftPlan" */
export type DraftPlan_Inc_Input = {
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "DraftPlan" */
export type DraftPlan_Insert_Input = {
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['jsonb']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type DraftPlan_Max_Fields = {
  __typename?: 'DraftPlan_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  revision: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type DraftPlan_Min_Fields = {
  __typename?: 'DraftPlan_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  revision: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "DraftPlan" */
export type DraftPlan_Mutation_Response = {
  __typename?: 'DraftPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DraftPlan>;
};

/** on_conflict condition type for table "DraftPlan" */
export type DraftPlan_On_Conflict = {
  constraint: DraftPlan_Constraint;
  update_columns?: Array<DraftPlan_Update_Column>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};

/** Ordering options when selecting data from "DraftPlan". */
export type DraftPlan_Order_By = {
  Creator?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
};

/** primary key columns input for table: DraftPlan */
export type DraftPlan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DraftPlan_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "DraftPlan" */
export enum DraftPlan_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Revision = 'revision'
}

/** input type for updating data in table "DraftPlan" */
export type DraftPlan_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['jsonb']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type DraftPlan_Stddev_Fields = {
  __typename?: 'DraftPlan_stddev_fields';
  revision: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DraftPlan_Stddev_Pop_Fields = {
  __typename?: 'DraftPlan_stddev_pop_fields';
  revision: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DraftPlan_Stddev_Samp_Fields = {
  __typename?: 'DraftPlan_stddev_samp_fields';
  revision: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "DraftPlan" */
export type DraftPlan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DraftPlan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type DraftPlan_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['jsonb']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  revision?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type DraftPlan_Sum_Fields = {
  __typename?: 'DraftPlan_sum_fields';
  revision: Maybe<Scalars['Int']>;
};

/** update columns of table "DraftPlan" */
export enum DraftPlan_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Revision = 'revision'
}

export type DraftPlan_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DraftPlan_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DraftPlan_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DraftPlan_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DraftPlan_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DraftPlan_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DraftPlan_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DraftPlan_Set_Input>;
  /** filter the rows which have to be updated */
  where: DraftPlan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type DraftPlan_Var_Pop_Fields = {
  __typename?: 'DraftPlan_var_pop_fields';
  revision: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DraftPlan_Var_Samp_Fields = {
  __typename?: 'DraftPlan_var_samp_fields';
  revision: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DraftPlan_Variance_Fields = {
  __typename?: 'DraftPlan_variance_fields';
  revision: Maybe<Scalars['Float']>;
};

export type EditPlanResponse = {
  __typename?: 'EditPlanResponse';
  new_revision: Plan;
  old_revision: Plan;
};

export type EffectiveDates = {
  __typename?: 'EffectiveDates';
  exclusive_end_date: Maybe<Scalars['timestamptz']>;
  inclusive_start_date: Scalars['timestamptz'];
};

export type Environment = {
  __typename?: 'Environment';
  enabled: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  type: Maybe<EnvironmentTypeEnum_Enum>;
};

/** columns and relationships of "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum = {
  __typename?: 'EnvironmentTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Aggregate = {
  __typename?: 'EnvironmentTypeEnum_aggregate';
  aggregate: Maybe<EnvironmentTypeEnum_Aggregate_Fields>;
  nodes: Array<EnvironmentTypeEnum>;
};

/** aggregate fields of "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Aggregate_Fields = {
  __typename?: 'EnvironmentTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<EnvironmentTypeEnum_Max_Fields>;
  min: Maybe<EnvironmentTypeEnum_Min_Fields>;
};


/** aggregate fields of "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EnvironmentTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "EnvironmentTypeEnum". All fields are combined with a logical 'AND'. */
export type EnvironmentTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<EnvironmentTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<EnvironmentTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "EnvironmentTypeEnum" */
export enum EnvironmentTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnvironmentTypeEnumPkey = 'EnvironmentTypeEnum_pkey'
}

export enum EnvironmentTypeEnum_Enum {
  Production = 'PRODUCTION',
  Qa = 'QA',
  Sandbox = 'SANDBOX',
  Staging = 'STAGING',
  Uat = 'UAT'
}

/** Boolean expression to compare columns of type "EnvironmentTypeEnum_enum". All fields are combined with logical 'AND'. */
export type EnvironmentTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EnvironmentTypeEnum_Enum>;
  _in?: InputMaybe<Array<EnvironmentTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EnvironmentTypeEnum_Enum>;
  _nin?: InputMaybe<Array<EnvironmentTypeEnum_Enum>>;
};

/** input type for inserting data into table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EnvironmentTypeEnum_Max_Fields = {
  __typename?: 'EnvironmentTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EnvironmentTypeEnum_Min_Fields = {
  __typename?: 'EnvironmentTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Mutation_Response = {
  __typename?: 'EnvironmentTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EnvironmentTypeEnum>;
};

/** input type for inserting object relation for remote table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Obj_Rel_Insert_Input = {
  data: EnvironmentTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EnvironmentTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_On_Conflict = {
  constraint: EnvironmentTypeEnum_Constraint;
  update_columns?: Array<EnvironmentTypeEnum_Update_Column>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "EnvironmentTypeEnum". */
export type EnvironmentTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: EnvironmentTypeEnum */
export type EnvironmentTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "EnvironmentTypeEnum" */
export enum EnvironmentTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "EnvironmentTypeEnum" */
export type EnvironmentTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EnvironmentTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EnvironmentTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "EnvironmentTypeEnum" */
export enum EnvironmentTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type EnvironmentTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnvironmentTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: EnvironmentTypeEnum_Bool_Exp;
};

export type Event = {
  __typename?: 'Event';
  billable_metrics: Array<BillableMetric>;
  customer: Maybe<Customer>;
  customer_id: Scalars['String'];
  environment_type: EnvironmentTypeEnum_Enum;
  event_type: Scalars['String'];
  id: Scalars['uuid'];
  is_duplicate: Scalars['Boolean'];
  plan: Maybe<Plan>;
  processedAt: Maybe<Scalars['timestamptz']>;
  properties: Scalars['JSONObject'];
  seat_metrics: Array<SeatMetric>;
  timestamp: Scalars['timestamptz'];
  transaction_id: Scalars['String'];
};


export type EventBillable_MetricsArgs = {
  types: Array<BillableMetricTypeEnum>;
};


export type EventSeat_MetricsArgs = {
  types: Array<BillableMetricTypeEnum>;
};

export type EventCountWindow = {
  __typename?: 'EventCountWindow';
  count: Scalars['numeric'];
  ending_before: Scalars['timestamptz'];
  starting_on: Scalars['timestamptz'];
};

export enum EventWindowSize {
  Day = 'Day',
  Hour = 'Hour',
  None = 'None'
}

export enum ExternalCommitType {
  Commit = 'COMMIT',
  Credit = 'CREDIT'
}

export type FailedMarketplacesMeteringRecord = {
  __typename?: 'FailedMarketplacesMeteringRecord';
  created_at: Scalars['timestamptz'];
  marketplaces_metered_usage_id: Scalars['String'];
  meter_timestamp: Scalars['timestamptz'];
};

export type FailedMarketplacesMeteringRecordList = {
  __typename?: 'FailedMarketplacesMeteringRecordList';
  failed_marketplaces_metering_records: Array<FailedMarketplacesMeteringRecord>;
  next_cursor: Maybe<Scalars['String']>;
};

export type FancyMetricInput = {
  name: Scalars['String'];
  sql: Scalars['String'];
};

export type FancyMetricValidationResponse = {
  __typename?: 'FancyMetricValidationResponse';
  _debug_plan: Maybe<Scalars['JSONObject']>;
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type FixedOrProServiceProductListItem = FixedProductListItem | ProServiceProductListItem;

export type FixedProductListItem = {
  __typename?: 'FixedProductListItem';
  archived_at: Maybe<Scalars['timestamptz']>;
  current: FixedProductListItemState;
  id: Scalars['uuid'];
  initial: FixedProductListItemState;
  managed_fields: Array<ContractProductManagedField>;
  updates: Array<ProductListItemUpdate>;
};

export type FixedProductListItemState = {
  __typename?: 'FixedProductListItemState';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  is_refundable: Scalars['Boolean'];
  name: Scalars['String'];
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

/** columns and relationships of "FlatFee" */
export type FlatFee = {
  __typename?: 'FlatFee';
  /** An object relationship */
  CollectionScheduleEnum: CollectionScheduleEnum;
  /** An object relationship */
  PricedProductPricingFactor: PricedProductPricingFactor;
  collection_interval: Scalars['Int'];
  collection_schedule: CollectionScheduleEnum_Enum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_prorated: Maybe<Scalars['Boolean']>;
  metric_minimum: Scalars['numeric'];
  priced_product_pricing_factor_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  value: Scalars['numeric'];
};

/** columns and relationships of "FlatFeeAdjustment" */
export type FlatFeeAdjustment = {
  __typename?: 'FlatFeeAdjustment';
  /** An object relationship */
  PriceAdjustmentTypeEnum: PriceAdjustmentTypeEnum;
  /** An object relationship */
  PricedProductPricingFactorAdjustment: PricedProductPricingFactorAdjustment;
  adjustment_type_enum: PriceAdjustmentTypeEnum_Enum;
  id: Scalars['uuid'];
  priced_product_pricing_factor_adjustment_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  value: Scalars['numeric'];
};

/** aggregated selection of "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Aggregate = {
  __typename?: 'FlatFeeAdjustment_aggregate';
  aggregate: Maybe<FlatFeeAdjustment_Aggregate_Fields>;
  nodes: Array<FlatFeeAdjustment>;
};

export type FlatFeeAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<FlatFeeAdjustment_Aggregate_Bool_Exp_Count>;
};

export type FlatFeeAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Aggregate_Fields = {
  __typename?: 'FlatFeeAdjustment_aggregate_fields';
  avg: Maybe<FlatFeeAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<FlatFeeAdjustment_Max_Fields>;
  min: Maybe<FlatFeeAdjustment_Min_Fields>;
  stddev: Maybe<FlatFeeAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<FlatFeeAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<FlatFeeAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<FlatFeeAdjustment_Sum_Fields>;
  var_pop: Maybe<FlatFeeAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<FlatFeeAdjustment_Var_Samp_Fields>;
  variance: Maybe<FlatFeeAdjustment_Variance_Fields>;
};


/** aggregate fields of "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<FlatFeeAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FlatFeeAdjustment_Max_Order_By>;
  min?: InputMaybe<FlatFeeAdjustment_Min_Order_By>;
  stddev?: InputMaybe<FlatFeeAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FlatFeeAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FlatFeeAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FlatFeeAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<FlatFeeAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FlatFeeAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<FlatFeeAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Arr_Rel_Insert_Input = {
  data: Array<FlatFeeAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FlatFeeAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type FlatFeeAdjustment_Avg_Fields = {
  __typename?: 'FlatFeeAdjustment_avg_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Avg_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FlatFeeAdjustment". All fields are combined with a logical 'AND'. */
export type FlatFeeAdjustment_Bool_Exp = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  _and?: InputMaybe<Array<FlatFeeAdjustment_Bool_Exp>>;
  _not?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<FlatFeeAdjustment_Bool_Exp>>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "FlatFeeAdjustment" */
export enum FlatFeeAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlatFeeAdjustmentPkey = 'FlatFeeAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Inc_Input = {
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Insert_Input = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Obj_Rel_Insert_Input>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type FlatFeeAdjustment_Max_Fields = {
  __typename?: 'FlatFeeAdjustment_max_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FlatFeeAdjustment_Min_Fields = {
  __typename?: 'FlatFeeAdjustment_min_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Mutation_Response = {
  __typename?: 'FlatFeeAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FlatFeeAdjustment>;
};

/** on_conflict condition type for table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_On_Conflict = {
  constraint: FlatFeeAdjustment_Constraint;
  update_columns?: Array<FlatFeeAdjustment_Update_Column>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "FlatFeeAdjustment". */
export type FlatFeeAdjustment_Order_By = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Order_By>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Order_By>;
  adjustment_type_enum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FlatFeeAdjustment */
export type FlatFeeAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "FlatFeeAdjustment" */
export enum FlatFeeAdjustment_Select_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Set_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type FlatFeeAdjustment_Stddev_Fields = {
  __typename?: 'FlatFeeAdjustment_stddev_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Stddev_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FlatFeeAdjustment_Stddev_Pop_Fields = {
  __typename?: 'FlatFeeAdjustment_stddev_pop_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Stddev_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FlatFeeAdjustment_Stddev_Samp_Fields = {
  __typename?: 'FlatFeeAdjustment_stddev_samp_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Stddev_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FlatFeeAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FlatFeeAdjustment_Stream_Cursor_Value_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type FlatFeeAdjustment_Sum_Fields = {
  __typename?: 'FlatFeeAdjustment_sum_fields';
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Sum_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "FlatFeeAdjustment" */
export enum FlatFeeAdjustment_Update_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

export type FlatFeeAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FlatFeeAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FlatFeeAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: FlatFeeAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FlatFeeAdjustment_Var_Pop_Fields = {
  __typename?: 'FlatFeeAdjustment_var_pop_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Var_Pop_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FlatFeeAdjustment_Var_Samp_Fields = {
  __typename?: 'FlatFeeAdjustment_var_samp_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Var_Samp_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FlatFeeAdjustment_Variance_Fields = {
  __typename?: 'FlatFeeAdjustment_variance_fields';
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FlatFeeAdjustment" */
export type FlatFeeAdjustment_Variance_Order_By = {
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregated selection of "FlatFee" */
export type FlatFee_Aggregate = {
  __typename?: 'FlatFee_aggregate';
  aggregate: Maybe<FlatFee_Aggregate_Fields>;
  nodes: Array<FlatFee>;
};

export type FlatFee_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<FlatFee_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<FlatFee_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<FlatFee_Aggregate_Bool_Exp_Count>;
};

export type FlatFee_Aggregate_Bool_Exp_Bool_And = {
  arguments: FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlatFee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type FlatFee_Aggregate_Bool_Exp_Bool_Or = {
  arguments: FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlatFee_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type FlatFee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FlatFee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<FlatFee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "FlatFee" */
export type FlatFee_Aggregate_Fields = {
  __typename?: 'FlatFee_aggregate_fields';
  avg: Maybe<FlatFee_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<FlatFee_Max_Fields>;
  min: Maybe<FlatFee_Min_Fields>;
  stddev: Maybe<FlatFee_Stddev_Fields>;
  stddev_pop: Maybe<FlatFee_Stddev_Pop_Fields>;
  stddev_samp: Maybe<FlatFee_Stddev_Samp_Fields>;
  sum: Maybe<FlatFee_Sum_Fields>;
  var_pop: Maybe<FlatFee_Var_Pop_Fields>;
  var_samp: Maybe<FlatFee_Var_Samp_Fields>;
  variance: Maybe<FlatFee_Variance_Fields>;
};


/** aggregate fields of "FlatFee" */
export type FlatFee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FlatFee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FlatFee" */
export type FlatFee_Aggregate_Order_By = {
  avg?: InputMaybe<FlatFee_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<FlatFee_Max_Order_By>;
  min?: InputMaybe<FlatFee_Min_Order_By>;
  stddev?: InputMaybe<FlatFee_Stddev_Order_By>;
  stddev_pop?: InputMaybe<FlatFee_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<FlatFee_Stddev_Samp_Order_By>;
  sum?: InputMaybe<FlatFee_Sum_Order_By>;
  var_pop?: InputMaybe<FlatFee_Var_Pop_Order_By>;
  var_samp?: InputMaybe<FlatFee_Var_Samp_Order_By>;
  variance?: InputMaybe<FlatFee_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FlatFee" */
export type FlatFee_Arr_Rel_Insert_Input = {
  data: Array<FlatFee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<FlatFee_On_Conflict>;
};

/** aggregate avg on columns */
export type FlatFee_Avg_Fields = {
  __typename?: 'FlatFee_avg_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FlatFee" */
export type FlatFee_Avg_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FlatFee". All fields are combined with a logical 'AND'. */
export type FlatFee_Bool_Exp = {
  CollectionScheduleEnum?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<FlatFee_Bool_Exp>>;
  _not?: InputMaybe<FlatFee_Bool_Exp>;
  _or?: InputMaybe<Array<FlatFee_Bool_Exp>>;
  collection_interval?: InputMaybe<Int_Comparison_Exp>;
  collection_schedule?: InputMaybe<CollectionScheduleEnum_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_prorated?: InputMaybe<Boolean_Comparison_Exp>;
  metric_minimum?: InputMaybe<Numeric_Comparison_Exp>;
  priced_product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "FlatFee" */
export enum FlatFee_Constraint {
  /** unique or primary key constraint on columns "id" */
  FlatFeePkey = 'FlatFee_pkey',
  /** unique or primary key constraint on columns "metric_minimum", "priced_product_pricing_factor_id" */
  FlatFeePricedProductPricingFactorIdMetricMinimumKey = 'FlatFee_priced_product_pricing_factor_id_metric_minimum_key'
}

/** input type for incrementing numeric columns in table "FlatFee" */
export type FlatFee_Inc_Input = {
  collection_interval?: InputMaybe<Scalars['Int']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "FlatFee" */
export type FlatFee_Insert_Input = {
  CollectionScheduleEnum?: InputMaybe<CollectionScheduleEnum_Obj_Rel_Insert_Input>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Obj_Rel_Insert_Input>;
  collection_interval?: InputMaybe<Scalars['Int']>;
  collection_schedule?: InputMaybe<CollectionScheduleEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_prorated?: InputMaybe<Scalars['Boolean']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type FlatFee_Max_Fields = {
  __typename?: 'FlatFee_max_fields';
  collection_interval: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "FlatFee" */
export type FlatFee_Max_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type FlatFee_Min_Fields = {
  __typename?: 'FlatFee_min_fields';
  collection_interval: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "FlatFee" */
export type FlatFee_Min_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "FlatFee" */
export type FlatFee_Mutation_Response = {
  __typename?: 'FlatFee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FlatFee>;
};

/** input type for inserting object relation for remote table "FlatFee" */
export type FlatFee_Obj_Rel_Insert_Input = {
  data: FlatFee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<FlatFee_On_Conflict>;
};

/** on_conflict condition type for table "FlatFee" */
export type FlatFee_On_Conflict = {
  constraint: FlatFee_Constraint;
  update_columns?: Array<FlatFee_Update_Column>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};

/** Ordering options when selecting data from "FlatFee". */
export type FlatFee_Order_By = {
  CollectionScheduleEnum?: InputMaybe<CollectionScheduleEnum_Order_By>;
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Order_By>;
  collection_interval?: InputMaybe<Order_By>;
  collection_schedule?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_prorated?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: FlatFee */
export type FlatFee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "FlatFee" */
export enum FlatFee_Select_Column {
  /** column name */
  CollectionInterval = 'collection_interval',
  /** column name */
  CollectionSchedule = 'collection_schedule',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProrated = 'is_prorated',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

/** select "FlatFee_aggregate_bool_exp_bool_and_arguments_columns" columns of table "FlatFee" */
export enum FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsProrated = 'is_prorated'
}

/** select "FlatFee_aggregate_bool_exp_bool_or_arguments_columns" columns of table "FlatFee" */
export enum FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsProrated = 'is_prorated'
}

/** input type for updating data in table "FlatFee" */
export type FlatFee_Set_Input = {
  collection_interval?: InputMaybe<Scalars['Int']>;
  collection_schedule?: InputMaybe<CollectionScheduleEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_prorated?: InputMaybe<Scalars['Boolean']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type FlatFee_Stddev_Fields = {
  __typename?: 'FlatFee_stddev_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FlatFee" */
export type FlatFee_Stddev_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FlatFee_Stddev_Pop_Fields = {
  __typename?: 'FlatFee_stddev_pop_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FlatFee" */
export type FlatFee_Stddev_Pop_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FlatFee_Stddev_Samp_Fields = {
  __typename?: 'FlatFee_stddev_samp_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FlatFee" */
export type FlatFee_Stddev_Samp_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "FlatFee" */
export type FlatFee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FlatFee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type FlatFee_Stream_Cursor_Value_Input = {
  collection_interval?: InputMaybe<Scalars['Int']>;
  collection_schedule?: InputMaybe<CollectionScheduleEnum_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_prorated?: InputMaybe<Scalars['Boolean']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type FlatFee_Sum_Fields = {
  __typename?: 'FlatFee_sum_fields';
  collection_interval: Maybe<Scalars['Int']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "FlatFee" */
export type FlatFee_Sum_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "FlatFee" */
export enum FlatFee_Update_Column {
  /** column name */
  CollectionInterval = 'collection_interval',
  /** column name */
  CollectionSchedule = 'collection_schedule',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsProrated = 'is_prorated',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

export type FlatFee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FlatFee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FlatFee_Set_Input>;
  /** filter the rows which have to be updated */
  where: FlatFee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type FlatFee_Var_Pop_Fields = {
  __typename?: 'FlatFee_var_pop_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FlatFee" */
export type FlatFee_Var_Pop_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FlatFee_Var_Samp_Fields = {
  __typename?: 'FlatFee_var_samp_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FlatFee" */
export type FlatFee_Var_Samp_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type FlatFee_Variance_Fields = {
  __typename?: 'FlatFee_variance_fields';
  collection_interval: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FlatFee" */
export type FlatFee_Variance_Order_By = {
  collection_interval?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type FlatRate = {
  __typename?: 'FlatRate';
  credit_type: CreditType;
  unit_price: Scalars['numeric'];
};

export type FlatRateInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  unit_price: Scalars['numeric'];
};

export enum FutureInvoiceBehavior {
  AsIs = 'AS_IS',
  Remove = 'REMOVE'
}

export type FutureInvoiceBehaviorInput = {
  trueup?: InputMaybe<FutureInvoiceBehavior>;
};

export type GcpProServiceRoyalty = {
  __typename?: 'GCPProServiceRoyalty';
  applicable_product_ids: Array<Scalars['uuid']>;
  contract: Contract;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Scalars['numeric'];
  gcp_account_id: Maybe<Scalars['String']>;
  gcp_offer_id: Maybe<Scalars['String']>;
  netsuite_reseller_id: Scalars['String'];
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Scalars['timestamptz'];
};

export type GcpProServiceRoyaltyUpdate = {
  __typename?: 'GCPProServiceRoyaltyUpdate';
  applicable_product_ids: Maybe<Array<Scalars['uuid']>>;
  applicable_product_tags: Maybe<Array<Scalars['String']>>;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Maybe<Scalars['numeric']>;
  gcp_account_id: Maybe<Scalars['String']>;
  gcp_offer_id: Maybe<Scalars['String']>;
  netsuite_reseller_id: Maybe<Scalars['String']>;
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type GcpRoyalty = {
  __typename?: 'GCPRoyalty';
  applicable_product_ids: Array<Scalars['uuid']>;
  applicable_product_tags: Array<Scalars['String']>;
  contract: Contract;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Scalars['numeric'];
  gcp_account_id: Maybe<Scalars['String']>;
  gcp_offer_id: Maybe<Scalars['String']>;
  netsuite_reseller_id: Scalars['String'];
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Scalars['timestamptz'];
};

export type GcpRoyaltyUpdate = {
  __typename?: 'GCPRoyaltyUpdate';
  applicable_product_ids: Maybe<Array<Scalars['uuid']>>;
  applicable_product_tags: Maybe<Array<Scalars['String']>>;
  ending_before: Maybe<Scalars['timestamptz']>;
  fraction: Maybe<Scalars['numeric']>;
  gcp_account_id: Maybe<Scalars['String']>;
  gcp_offer_id: Maybe<Scalars['String']>;
  netsuite_reseller_id: Maybe<Scalars['String']>;
  reseller_contract_value: Maybe<Scalars['numeric']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type GenerateAzureAuthTokenMutationResponse = {
  __typename?: 'GenerateAzureAuthTokenMutationResponse';
  auth_token: Scalars['String'];
  expires_on: Scalars['timestamptz'];
};

export type GroupedChargeLineItem = Mri_LineItem & {
  __typename?: 'GroupedChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  group_key: Scalars['String'];
  group_value: Maybe<Scalars['String']>;
  line_item_chunks: Array<LineItemChunk>;
  quantity: Scalars['numeric'];
  sub_line_items: Maybe<Array<TierSubLineItem>>;
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Maybe<Scalars['numeric']>;
};

export type GroupedProductChargeLineItem = Mri_LineItem & {
  __typename?: 'GroupedProductChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  group_key: Scalars['String'];
  group_value: Maybe<Scalars['String']>;
  line_item_chunks: Array<LineItemChunk>;
  product: Product;
  sub_line_items: Array<ProductChargeSubLineItem>;
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  total: Scalars['numeric'];
};

export type GroupedUsage = {
  __typename?: 'GroupedUsage';
  data: Array<GroupedUsageWindow>;
  next_page: Maybe<Scalars['String']>;
};

export type GroupedUsageTuple = {
  __typename?: 'GroupedUsageTuple';
  group_key: Scalars['String'];
  group_value: Scalars['String'];
};

export type GroupedUsageWindow = {
  __typename?: 'GroupedUsageWindow';
  ending_before: Scalars['timestamptz'];
  groupings: Array<GroupedUsageTuple>;
  starting_on: Scalars['timestamptz'];
  value: Maybe<Scalars['String']>;
};

export type InitialUsageFilter = BaseUsageFilter & {
  __typename?: 'InitialUsageFilter';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  group_key: Scalars['String'];
  group_values: Array<Scalars['String']>;
  id: Scalars['String'];
};

export type InsertBillingProviderDeliveryMethodResult = {
  __typename?: 'InsertBillingProviderDeliveryMethodResult';
  billing_provider: BillingProviderEnum_Enum;
  delivery_method: BillingProviderDeliveryMethod_Enum;
  delivery_method_configuration: Scalars['FlatKeyValueMap'];
  id: Scalars['uuid'];
};

export type InsertCustomerBillingProviderConfigurationInput = {
  billing_provider: BillingProviderEnum_Enum;
  configuration: Scalars['FlatKeyValueMap'];
  customer_id: Scalars['uuid'];
  delivery_method?: InputMaybe<BillingProviderDeliveryMethod_Enum>;
  delivery_method_id?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['numeric']>;
};

export type InsertCustomerBillingProviderConfigurationNewCustomerInput = {
  billing_provider: BillingProviderEnum_Enum;
  configuration: Scalars['FlatKeyValueMap'];
  delivery_method?: InputMaybe<BillingProviderDeliveryMethod_Enum>;
  delivery_method_id?: InputMaybe<Scalars['uuid']>;
  version?: InputMaybe<Scalars['numeric']>;
};

export type InsertCustomerBillingProviderConfigurationResult = {
  __typename?: 'InsertCustomerBillingProviderConfigurationResult';
  billing_provider: BillingProviderEnum_Enum;
  configuration: Scalars['FlatKeyValueMap'];
  customer_id: Scalars['uuid'];
  delivery_method_id: Scalars['uuid'];
  id: Scalars['uuid'];
  version: Scalars['numeric'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  credit_type: CreditType;
  customer: Customer;
  customer_plan: Maybe<CustomerPlan>;
  id: Scalars['uuid'];
  invoice_adjustments: Array<InvoiceAdjustment>;
  line_items: Array<LineItem>;
  one_time_charges: Maybe<Array<OneTimeProductCharge>>;
  plan: Maybe<Plan>;
  schedule_type: InvoiceScheduleTypeEnum;
  service_period: ServicePeriod;
  status: InvoiceStatus;
  subtotal: Scalars['CreditAmount'];
  total: Scalars['CreditAmount'];
};

export type InvoiceAdjustment = {
  __typename?: 'InvoiceAdjustment';
  credit_type: CreditType;
  id: Maybe<Scalars['uuid']>;
  mri_rollout_adjustment_draft_only_credit_grant_id_do_not_use: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  total: Scalars['CreditAmount'];
};

export type InvoiceCorrectionContractLineItemPointer = {
  charge_id?: InputMaybe<Scalars['uuid']>;
  commit_id?: InputMaybe<Scalars['uuid']>;
  discount_id?: InputMaybe<Scalars['uuid']>;
  exclusive_end_date?: InputMaybe<Scalars['timestamptz']>;
  inclusive_start_date?: InputMaybe<Scalars['timestamptz']>;
  usage_product_id?: InputMaybe<Scalars['uuid']>;
};

export type InvoiceCorrectionInput = {
  correction_items: Array<InvoiceCorrectionItem>;
  invoice_id: Scalars['uuid'];
  issue_date?: InputMaybe<Scalars['timestamptz']>;
  memo: Scalars['String'];
  reason: Scalars['String'];
  sequence_numbers: Array<SequenceNumberInput>;
};

export type InvoiceCorrectionItem = {
  contract_line_item_pointer?: InputMaybe<InvoiceCorrectionContractLineItemPointer>;
  line_item_pointer?: InputMaybe<InvoiceCorrectionLineItemPointer>;
  type: InvoiceCorrectionItemTypeEnum;
  value: Scalars['numeric'];
};

export enum InvoiceCorrectionItemTypeEnum {
  QuantityChange = 'QUANTITY_CHANGE',
  TotalChange = 'TOTAL_CHANGE'
}

export type InvoiceCorrectionLineItemGroupPointer = {
  group_key: Scalars['String'];
  group_value: Scalars['String'];
};

export type InvoiceCorrectionLineItemPointer = {
  group?: InputMaybe<InvoiceCorrectionLineItemGroupPointer>;
  product_id: Scalars['uuid'];
  product_pricing_factor_id: Scalars['uuid'];
};

export type InvoiceCorrectionRecord = {
  __typename?: 'InvoiceCorrectionRecord';
  creator: Actor;
  id: Scalars['uuid'];
  memo: Scalars['String'];
  original_invoice: CorrectableInvoice;
  reason: Scalars['String'];
  sequence_number: Scalars['Int'];
};

export type InvoiceCountsByFinalizationStatusResponse = {
  __typename?: 'InvoiceCountsByFinalizationStatusResponse';
  in_progress: Scalars['numeric'];
  pending: Scalars['numeric'];
};

/** columns and relationships of "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum = {
  __typename?: 'InvoiceExternalStatusEnum';
  value: Scalars['String'];
};

/** aggregated selection of "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Aggregate = {
  __typename?: 'InvoiceExternalStatusEnum_aggregate';
  aggregate: Maybe<InvoiceExternalStatusEnum_Aggregate_Fields>;
  nodes: Array<InvoiceExternalStatusEnum>;
};

/** aggregate fields of "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Aggregate_Fields = {
  __typename?: 'InvoiceExternalStatusEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<InvoiceExternalStatusEnum_Max_Fields>;
  min: Maybe<InvoiceExternalStatusEnum_Min_Fields>;
};


/** aggregate fields of "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceExternalStatusEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "InvoiceExternalStatusEnum". All fields are combined with a logical 'AND'. */
export type InvoiceExternalStatusEnum_Bool_Exp = {
  _and?: InputMaybe<Array<InvoiceExternalStatusEnum_Bool_Exp>>;
  _not?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceExternalStatusEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "InvoiceExternalStatusEnum" */
export enum InvoiceExternalStatusEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  InvoiceExternalStatusEnumPkey = 'InvoiceExternalStatusEnum_pkey'
}

export enum InvoiceExternalStatusEnum_Enum {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
  InvalidRequestError = 'INVALID_REQUEST_ERROR',
  Paid = 'PAID',
  PaymentFailed = 'PAYMENT_FAILED',
  Queued = 'QUEUED',
  Sent = 'SENT',
  Skipped = 'SKIPPED',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

/** Boolean expression to compare columns of type "InvoiceExternalStatusEnum_enum". All fields are combined with logical 'AND'. */
export type InvoiceExternalStatusEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<InvoiceExternalStatusEnum_Enum>;
  _in?: InputMaybe<Array<InvoiceExternalStatusEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<InvoiceExternalStatusEnum_Enum>;
  _nin?: InputMaybe<Array<InvoiceExternalStatusEnum_Enum>>;
};

/** input type for inserting data into table "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InvoiceExternalStatusEnum_Max_Fields = {
  __typename?: 'InvoiceExternalStatusEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type InvoiceExternalStatusEnum_Min_Fields = {
  __typename?: 'InvoiceExternalStatusEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Mutation_Response = {
  __typename?: 'InvoiceExternalStatusEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceExternalStatusEnum>;
};

/** on_conflict condition type for table "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_On_Conflict = {
  constraint: InvoiceExternalStatusEnum_Constraint;
  update_columns?: Array<InvoiceExternalStatusEnum_Update_Column>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "InvoiceExternalStatusEnum". */
export type InvoiceExternalStatusEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: InvoiceExternalStatusEnum */
export type InvoiceExternalStatusEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "InvoiceExternalStatusEnum" */
export enum InvoiceExternalStatusEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "InvoiceExternalStatusEnum" */
export type InvoiceExternalStatusEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceExternalStatusEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceExternalStatusEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "InvoiceExternalStatusEnum" */
export enum InvoiceExternalStatusEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type InvoiceExternalStatusEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceExternalStatusEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceExternalStatusEnum_Bool_Exp;
};

export enum InvoiceExternalTypeEnum {
  Correction = 'CORRECTION',
  CreditPurchase = 'CREDIT_PURCHASE',
  Parent = 'PARENT',
  PlanArrears = 'PLAN_ARREARS',
  Scheduled = 'SCHEDULED',
  SeatPurchase = 'SEAT_PURCHASE',
  Usage = 'USAGE'
}

export type InvoiceFinalizationInput = {
  books_closed_date: Scalars['timestamptz'];
  finalization_date: Scalars['timestamptz'];
  invoice_type: InvoiceExternalTypeEnum;
  request_timestamp: Scalars['timestamptz'];
};

export type InvoiceFinalizationMetadata = {
  __typename?: 'InvoiceFinalizationMetadata';
  Creator: Actor;
  books_closed_date: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  finalization_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice_cohort: InvoiceExternalTypeEnum;
  invoices_finalized: Scalars['Int'];
  status: InvoiceFinalizationMetadataStatus;
};

export enum InvoiceFinalizationMetadataStatus {
  Finalized = 'FINALIZED',
  Pending = 'PENDING'
}

export type InvoiceFinalizationProgressCountResponse = {
  __typename?: 'InvoiceFinalizationProgressCountResponse';
  count: Scalars['Int'];
};

export type InvoiceGenerationOverrides = {
  draft_invoice_calculation_cutoff_timestamp?: InputMaybe<Scalars['timestamptz']>;
  force_druid?: InputMaybe<Scalars['Boolean']>;
  force_invoice_recalculation?: InputMaybe<Scalars['Boolean']>;
  override_presentation_key?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoiceIdentifier = {
  __typename?: 'InvoiceIdentifier';
  credit_type_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  invoice: Mri_Invoice;
};

/** columns and relationships of "InvoiceManagedField" */
export type InvoiceManagedField = {
  __typename?: 'InvoiceManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "InvoiceManagedField" */
export type InvoiceManagedField_Aggregate = {
  __typename?: 'InvoiceManagedField_aggregate';
  aggregate: Maybe<InvoiceManagedField_Aggregate_Fields>;
  nodes: Array<InvoiceManagedField>;
};

export type InvoiceManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<InvoiceManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<InvoiceManagedField_Aggregate_Bool_Exp_Count>;
};

export type InvoiceManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InvoiceManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InvoiceManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "InvoiceManagedField" */
export type InvoiceManagedField_Aggregate_Fields = {
  __typename?: 'InvoiceManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<InvoiceManagedField_Max_Fields>;
  min: Maybe<InvoiceManagedField_Min_Fields>;
};


/** aggregate fields of "InvoiceManagedField" */
export type InvoiceManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "InvoiceManagedField" */
export type InvoiceManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InvoiceManagedField_Max_Order_By>;
  min?: InputMaybe<InvoiceManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "InvoiceManagedField" */
export type InvoiceManagedField_Arr_Rel_Insert_Input = {
  data: Array<InvoiceManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InvoiceManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "InvoiceManagedField". All fields are combined with a logical 'AND'. */
export type InvoiceManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<InvoiceManagedField_Bool_Exp>>;
  _not?: InputMaybe<InvoiceManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<InvoiceManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "InvoiceManagedField" */
export enum InvoiceManagedField_Constraint {
  /** unique or primary key constraint on columns "key_id", "invoice_id" */
  InvoiceManagedFieldInvoiceIdKeyIdKey = 'InvoiceManagedField_invoice_id_key_id_key',
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  InvoiceManagedFieldKeyIdValueEnforceUniquenessClientKey = 'InvoiceManagedField_key_id_value_enforce_uniqueness_client__key',
  /** unique or primary key constraint on columns "id" */
  InvoiceManagedFieldPkey = 'InvoiceManagedField_pkey'
}

/** input type for inserting data into table "InvoiceManagedField" */
export type InvoiceManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InvoiceManagedField_Max_Fields = {
  __typename?: 'InvoiceManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "InvoiceManagedField" */
export type InvoiceManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InvoiceManagedField_Min_Fields = {
  __typename?: 'InvoiceManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  invoice_id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "InvoiceManagedField" */
export type InvoiceManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "InvoiceManagedField" */
export type InvoiceManagedField_Mutation_Response = {
  __typename?: 'InvoiceManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InvoiceManagedField>;
};

/** on_conflict condition type for table "InvoiceManagedField" */
export type InvoiceManagedField_On_Conflict = {
  constraint: InvoiceManagedField_Constraint;
  update_columns?: Array<InvoiceManagedField_Update_Column>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "InvoiceManagedField". */
export type InvoiceManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: InvoiceManagedField */
export type InvoiceManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "InvoiceManagedField" */
export enum InvoiceManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "InvoiceManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "InvoiceManagedField" */
export enum InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "InvoiceManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "InvoiceManagedField" */
export enum InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "InvoiceManagedField" */
export type InvoiceManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "InvoiceManagedField" */
export type InvoiceManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InvoiceManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InvoiceManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "InvoiceManagedField" */
export enum InvoiceManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type InvoiceManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InvoiceManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: InvoiceManagedField_Bool_Exp;
};

export type InvoicePage = {
  __typename?: 'InvoicePage';
  cursor: Maybe<Scalars['String']>;
  invoices: Array<Mri_Invoice>;
};

export enum InvoiceScheduleTypeEnum {
  Advance = 'ADVANCE',
  Arrears = 'ARREARS',
  CreditPurchase = 'CREDIT_PURCHASE',
  Trial = 'TRIAL'
}

export type InvoiceSortOrder = {
  date?: InputMaybe<SortDirection>;
};

export enum InvoiceStatus {
  ConsistencyCheckDraft = 'CONSISTENCY_CHECK_DRAFT',
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
  Void = 'VOID'
}

export enum InvoiceStatusEnum {
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
  FinalizedInOldSystem = 'FINALIZED_IN_OLD_SYSTEM',
  PendingFinalization = 'PENDING_FINALIZATION',
  Void = 'VOID'
}

export type LaunchSoxReportGeneratorMutationResponse = {
  __typename?: 'LaunchSoxReportGeneratorMutationResponse';
  message_id: Scalars['String'];
};

export type LegacyContractLineItem = Mri_LineItem & {
  __typename?: 'LegacyContractLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  total: Scalars['numeric'];
};

export type LegacyLineItem = Mri_LineItem & {
  __typename?: 'LegacyLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  total: Scalars['numeric'];
};

export type LineItem = {
  __typename?: 'LineItem';
  credit_type: CreditType;
  group_key: Maybe<Scalars['String']>;
  group_value: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  product: Maybe<Product>;
  quantity: Scalars['numeric'];
  sub_line_items: Array<SubLineItem>;
  total: Scalars['CreditAmount'];
};

export type LineItemChunk = {
  __typename?: 'LineItemChunk';
  quantity: Maybe<Scalars['numeric']>;
  time_range: TimeRange;
  total: Scalars['numeric'];
};

export type ListDeliveryMethodsResponse = {
  __typename?: 'ListDeliveryMethodsResponse';
  delivery_methods: Array<BillingProviderDeliveryMethod>;
  next_cursor: Maybe<Scalars['String']>;
};

export type ListDeliveryMethodsWithoutConfigurationForAllClientsResponse = {
  __typename?: 'ListDeliveryMethodsWithoutConfigurationForAllClientsResponse';
  delivery_methods: Array<BillingProviderDeliveryMethodWithoutConfiguration>;
  next_cursor: Maybe<Scalars['String']>;
};

export enum Mri_BillingFrequencyEnum {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL',
  SemiMonthly = 'SEMI_MONTHLY'
}

export enum Mri_CollectionScheduleEnum {
  Advance = 'ADVANCE',
  Arrears = 'ARREARS',
  OneTimeAdvance = 'ONE_TIME_ADVANCE'
}

export type Mri_CreditBalance = {
  __typename?: 'MRI_CreditBalance';
  effective_at: Scalars['timestamptz'];
  excluding_pending: Scalars['numeric'];
  including_pending: Scalars['numeric'];
};

export type Mri_CreditDeductionCreditLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_CreditDeductionCreditLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_deduction: CreditGrantDeduction;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  invoice: Mri_Invoice;
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_CreditExpirationCreditLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_CreditExpirationCreditLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_CreditGrant = {
  __typename?: 'MRI_CreditGrant';
  AmountGrantedCreditType: CreditType;
  AmountPaidCreditType: CreditType;
  Creator: Actor;
  Customer: Customer;
  Voider: Maybe<Actor>;
  amount_granted: Scalars['numeric'];
  amount_paid: Scalars['numeric'];
  billing_provider: Maybe<BillingProviderEnum_Enum>;
  cost_basis: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  credit_grant_type: Maybe<Scalars['String']>;
  effective_at: Scalars['timestamptz'];
  environment_type: EnvironmentTypeEnum_Enum;
  expires_before: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice: Maybe<CreditPurchaseInvoice>;
  invoice_id: Maybe<Scalars['uuid']>;
  managed_fields: Array<CreditGrantManagedField>;
  mri_credit_ledger: Mri_CreditLedger;
  name: Scalars['String'];
  priority: Scalars['numeric'];
  products: Maybe<Array<Product>>;
  reason: Maybe<Scalars['String']>;
  rolled_over_from: Maybe<Mri_CreditGrant>;
  rolled_over_to: Maybe<Mri_CreditGrant>;
  rollover_settings: Maybe<RolloverSettings>;
  uniqueness_key: Maybe<Scalars['String']>;
  voided_at: Maybe<Scalars['timestamptz']>;
};

export type Mri_CreditGrantCreditLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_CreditGrantCreditLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_CreditLedger = {
  __typename?: 'MRI_CreditLedger';
  credit_type: CreditType;
  ending_balance: Mri_CreditBalance;
  ledger_entries: Array<Mri_CreditLedgerEntry>;
  starting_balance: Mri_CreditBalance;
};


export type Mri_CreditLedgerEnding_BalanceArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
};


export type Mri_CreditLedgerLedger_EntriesArgs = {
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  starting_after?: InputMaybe<Scalars['timestamptz']>;
};


export type Mri_CreditLedgerStarting_BalanceArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Mri_CreditLedgerEntry = {
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_GroupedUsage = {
  __typename?: 'MRI_GroupedUsage';
  cursor: Maybe<Scalars['String']>;
  data: Array<Mri_GroupedUsageWindow>;
};

export type Mri_GroupedUsageTuple = {
  __typename?: 'MRI_GroupedUsageTuple';
  group_key: Scalars['String'];
  group_value: Scalars['String'];
};

export type Mri_GroupedUsageWindow = {
  __typename?: 'MRI_GroupedUsageWindow';
  exclusive_end_date: Scalars['timestamptz'];
  groupings: Array<Mri_GroupedUsageTuple>;
  inclusive_start_date: Scalars['timestamptz'];
  value: Scalars['String'];
};

export type Mri_Invoice = {
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type Mri_InvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type Mri_LineItem = {
  credit_type: CreditType;
  display_name: Scalars['String'];
  total: Scalars['numeric'];
};

export type Mri_PendingChargeCreditLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_PendingChargeCreditLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  draft_invoice: Mri_Invoice;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_PendingCreditExpirationCreditLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_PendingCreditExpirationCreditLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_PendingRolloverCreditGrantLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_PendingRolloverCreditGrantLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_PendingRolloverDeductionLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_PendingRolloverDeductionLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_RolloverCreditGrantLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_RolloverCreditGrantLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export type Mri_RolloverDeductionLedgerEntry = Mri_CreditLedgerEntry & {
  __typename?: 'MRI_RolloverDeductionLedgerEntry';
  amount: Scalars['numeric'];
  creator: Actor;
  credit_grant: Mri_CreditGrant;
  credit_type: CreditType;
  effective_at: Scalars['timestamptz'];
  memo: Scalars['String'];
  running_balance: Scalars['numeric'];
};

export enum Mri_ServicePeriodStartOnEnum {
  FirstOfMonth = 'FIRST_OF_MONTH',
  StartOfPlan = 'START_OF_PLAN'
}

export type Mri_UsageGroupByInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

/** columns and relationships of "ManagedEntityEnum" */
export type ManagedEntityEnum = {
  __typename?: 'ManagedEntityEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ManagedEntityEnum" */
export type ManagedEntityEnum_Aggregate = {
  __typename?: 'ManagedEntityEnum_aggregate';
  aggregate: Maybe<ManagedEntityEnum_Aggregate_Fields>;
  nodes: Array<ManagedEntityEnum>;
};

/** aggregate fields of "ManagedEntityEnum" */
export type ManagedEntityEnum_Aggregate_Fields = {
  __typename?: 'ManagedEntityEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ManagedEntityEnum_Max_Fields>;
  min: Maybe<ManagedEntityEnum_Min_Fields>;
};


/** aggregate fields of "ManagedEntityEnum" */
export type ManagedEntityEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ManagedEntityEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ManagedEntityEnum". All fields are combined with a logical 'AND'. */
export type ManagedEntityEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ManagedEntityEnum_Bool_Exp>>;
  _not?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ManagedEntityEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ManagedEntityEnum" */
export enum ManagedEntityEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ManagedEntityEnumPkey = 'ManagedEntityEnum_pkey'
}

export enum ManagedEntityEnum_Enum {
  Alert = 'Alert',
  BillableMetric = 'BillableMetric',
  Charge = 'Charge',
  Commit = 'Commit',
  Contract = 'Contract',
  ContractCredit = 'ContractCredit',
  ContractProduct = 'ContractProduct',
  CreditGrant = 'CreditGrant',
  Customer = 'Customer',
  CustomerPlan = 'CustomerPlan',
  Invoice = 'Invoice',
  Plan = 'Plan',
  ProService = 'ProService',
  Product = 'Product',
  RateCard = 'RateCard',
  ScheduledCharge = 'ScheduledCharge'
}

/** Boolean expression to compare columns of type "ManagedEntityEnum_enum". All fields are combined with logical 'AND'. */
export type ManagedEntityEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ManagedEntityEnum_Enum>;
  _in?: InputMaybe<Array<ManagedEntityEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ManagedEntityEnum_Enum>;
  _nin?: InputMaybe<Array<ManagedEntityEnum_Enum>>;
};

/** input type for inserting data into table "ManagedEntityEnum" */
export type ManagedEntityEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ManagedEntityEnum_Max_Fields = {
  __typename?: 'ManagedEntityEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ManagedEntityEnum_Min_Fields = {
  __typename?: 'ManagedEntityEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ManagedEntityEnum" */
export type ManagedEntityEnum_Mutation_Response = {
  __typename?: 'ManagedEntityEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagedEntityEnum>;
};

/** on_conflict condition type for table "ManagedEntityEnum" */
export type ManagedEntityEnum_On_Conflict = {
  constraint: ManagedEntityEnum_Constraint;
  update_columns?: Array<ManagedEntityEnum_Update_Column>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ManagedEntityEnum". */
export type ManagedEntityEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ManagedEntityEnum */
export type ManagedEntityEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ManagedEntityEnum" */
export enum ManagedEntityEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ManagedEntityEnum" */
export type ManagedEntityEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ManagedEntityEnum" */
export type ManagedEntityEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManagedEntityEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagedEntityEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ManagedEntityEnum" */
export enum ManagedEntityEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ManagedEntityEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManagedEntityEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ManagedEntityEnum_Bool_Exp;
};

export type ManagedFieldInput = {
  key_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKey = {
  __typename?: 'ManagedFieldKey';
  /** An array relationship */
  AlertManagedFields: Array<AlertManagedField>;
  /** An aggregate relationship */
  AlertManagedFields_aggregate: AlertManagedField_Aggregate;
  /** An array relationship */
  ChargeManagedFields: Array<ChargeManagedField>;
  /** An aggregate relationship */
  ChargeManagedFields_aggregate: ChargeManagedField_Aggregate;
  /** An object relationship */
  Client: Client;
  /** An array relationship */
  CommitManagedFields: Array<CommitManagedField>;
  /** An aggregate relationship */
  CommitManagedFields_aggregate: CommitManagedField_Aggregate;
  /** An array relationship */
  ContractCreditManagedFields: Array<ContractCreditManagedField>;
  /** An aggregate relationship */
  ContractCreditManagedFields_aggregate: ContractCreditManagedField_Aggregate;
  /** An array relationship */
  ContractManagedFields: Array<ContractManagedField>;
  /** An aggregate relationship */
  ContractManagedFields_aggregate: ContractManagedField_Aggregate;
  /** An array relationship */
  ContractProductManagedFields: Array<ContractProductManagedField>;
  /** An aggregate relationship */
  ContractProductManagedFields_aggregate: ContractProductManagedField_Aggregate;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An array relationship */
  CreditGrantManagedFields: Array<CreditGrantManagedField>;
  /** An aggregate relationship */
  CreditGrantManagedFields_aggregate: CreditGrantManagedField_Aggregate;
  /** An array relationship */
  CustomerManagedFields: Array<CustomerManagedField>;
  /** An aggregate relationship */
  CustomerManagedFields_aggregate: CustomerManagedField_Aggregate;
  /** An array relationship */
  CustomerPlanManagedFields: Array<CustomerPlanManagedField>;
  /** An aggregate relationship */
  CustomerPlanManagedFields_aggregate: CustomerPlanManagedField_Aggregate;
  /** An object relationship */
  Disabler: Maybe<Actor>;
  /** An array relationship */
  InvoiceManagedFields: Array<InvoiceManagedField>;
  /** An aggregate relationship */
  InvoiceManagedFields_aggregate: InvoiceManagedField_Aggregate;
  /** An array relationship */
  PlanManagedFields: Array<PlanManagedField>;
  /** An aggregate relationship */
  PlanManagedFields_aggregate: PlanManagedField_Aggregate;
  /** An array relationship */
  ProServiceManagedFields: Array<ProServiceManagedField>;
  /** An aggregate relationship */
  ProServiceManagedFields_aggregate: ProServiceManagedField_Aggregate;
  /** An array relationship */
  ProductManagedFields: Array<ProductManagedField>;
  /** An aggregate relationship */
  ProductManagedFields_aggregate: ProductManagedField_Aggregate;
  /** An array relationship */
  RateCardManagedFields: Array<RateCardManagedField>;
  /** An aggregate relationship */
  RateCardManagedFields_aggregate: RateCardManagedField_Aggregate;
  /** An array relationship */
  ScheduledChargeManagedFields: Array<ScheduledChargeManagedField>;
  /** An aggregate relationship */
  ScheduledChargeManagedFields_aggregate: ScheduledChargeManagedField_Aggregate;
  /** An object relationship */
  Updater: Maybe<Actor>;
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  enforce_uniqueness: Scalars['Boolean'];
  entity: ManagedEntityEnum_Enum;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  is_active: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyAlertManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyAlertManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyChargeManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyChargeManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCommitManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCommitManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractCreditManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractCreditManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractProductManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyContractProductManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCreditGrantManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCreditGrantManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCustomerManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCustomerManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCustomerPlanManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyCustomerPlanManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyInvoiceManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyInvoiceManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyPlanManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyPlanManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyProServiceManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyProServiceManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyProductManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyProductManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyRateCardManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyRateCardManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyScheduledChargeManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


/** columns and relationships of "ManagedFieldKey" */
export type ManagedFieldKeyScheduledChargeManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};

/** aggregated selection of "ManagedFieldKey" */
export type ManagedFieldKey_Aggregate = {
  __typename?: 'ManagedFieldKey_aggregate';
  aggregate: Maybe<ManagedFieldKey_Aggregate_Fields>;
  nodes: Array<ManagedFieldKey>;
};

/** aggregate fields of "ManagedFieldKey" */
export type ManagedFieldKey_Aggregate_Fields = {
  __typename?: 'ManagedFieldKey_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ManagedFieldKey_Max_Fields>;
  min: Maybe<ManagedFieldKey_Min_Fields>;
};


/** aggregate fields of "ManagedFieldKey" */
export type ManagedFieldKey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ManagedFieldKey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ManagedFieldKey". All fields are combined with a logical 'AND'. */
export type ManagedFieldKey_Bool_Exp = {
  AlertManagedFields?: InputMaybe<AlertManagedField_Bool_Exp>;
  AlertManagedFields_aggregate?: InputMaybe<AlertManagedField_Aggregate_Bool_Exp>;
  ChargeManagedFields?: InputMaybe<ChargeManagedField_Bool_Exp>;
  ChargeManagedFields_aggregate?: InputMaybe<ChargeManagedField_Aggregate_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  CommitManagedFields?: InputMaybe<CommitManagedField_Bool_Exp>;
  CommitManagedFields_aggregate?: InputMaybe<CommitManagedField_Aggregate_Bool_Exp>;
  ContractCreditManagedFields?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
  ContractCreditManagedFields_aggregate?: InputMaybe<ContractCreditManagedField_Aggregate_Bool_Exp>;
  ContractManagedFields?: InputMaybe<ContractManagedField_Bool_Exp>;
  ContractManagedFields_aggregate?: InputMaybe<ContractManagedField_Aggregate_Bool_Exp>;
  ContractProductManagedFields?: InputMaybe<ContractProductManagedField_Bool_Exp>;
  ContractProductManagedFields_aggregate?: InputMaybe<ContractProductManagedField_Aggregate_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  CreditGrantManagedFields?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
  CreditGrantManagedFields_aggregate?: InputMaybe<CreditGrantManagedField_Aggregate_Bool_Exp>;
  CustomerManagedFields?: InputMaybe<CustomerManagedField_Bool_Exp>;
  CustomerManagedFields_aggregate?: InputMaybe<CustomerManagedField_Aggregate_Bool_Exp>;
  CustomerPlanManagedFields?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
  CustomerPlanManagedFields_aggregate?: InputMaybe<CustomerPlanManagedField_Aggregate_Bool_Exp>;
  Disabler?: InputMaybe<Actor_Bool_Exp>;
  InvoiceManagedFields?: InputMaybe<InvoiceManagedField_Bool_Exp>;
  InvoiceManagedFields_aggregate?: InputMaybe<InvoiceManagedField_Aggregate_Bool_Exp>;
  PlanManagedFields?: InputMaybe<PlanManagedField_Bool_Exp>;
  PlanManagedFields_aggregate?: InputMaybe<PlanManagedField_Aggregate_Bool_Exp>;
  ProServiceManagedFields?: InputMaybe<ProServiceManagedField_Bool_Exp>;
  ProServiceManagedFields_aggregate?: InputMaybe<ProServiceManagedField_Aggregate_Bool_Exp>;
  ProductManagedFields?: InputMaybe<ProductManagedField_Bool_Exp>;
  ProductManagedFields_aggregate?: InputMaybe<ProductManagedField_Aggregate_Bool_Exp>;
  RateCardManagedFields?: InputMaybe<RateCardManagedField_Bool_Exp>;
  RateCardManagedFields_aggregate?: InputMaybe<RateCardManagedField_Aggregate_Bool_Exp>;
  ScheduledChargeManagedFields?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
  ScheduledChargeManagedFields_aggregate?: InputMaybe<ScheduledChargeManagedField_Aggregate_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ManagedFieldKey_Bool_Exp>>;
  _not?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  _or?: InputMaybe<Array<ManagedFieldKey_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  archived_by?: InputMaybe<String_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  disabled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  entity?: InputMaybe<ManagedEntityEnum_Enum_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ManagedFieldKey" */
export enum ManagedFieldKey_Constraint {
  /** unique or primary key constraint on columns "key", "environment_type", "entity", "is_active", "client_id" */
  ManagedFieldKeyEntityKeyIsActiveClientIdEnvironmentKey = 'ManagedFieldKey_entity_key_is_active_client_id_environment__key',
  /** unique or primary key constraint on columns "id" */
  ManagedFieldKeyPkey = 'ManagedFieldKey_pkey'
}

/** input type for inserting data into table "ManagedFieldKey" */
export type ManagedFieldKey_Insert_Input = {
  AlertManagedFields?: InputMaybe<AlertManagedField_Arr_Rel_Insert_Input>;
  ChargeManagedFields?: InputMaybe<ChargeManagedField_Arr_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  CommitManagedFields?: InputMaybe<CommitManagedField_Arr_Rel_Insert_Input>;
  ContractCreditManagedFields?: InputMaybe<ContractCreditManagedField_Arr_Rel_Insert_Input>;
  ContractManagedFields?: InputMaybe<ContractManagedField_Arr_Rel_Insert_Input>;
  ContractProductManagedFields?: InputMaybe<ContractProductManagedField_Arr_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  CreditGrantManagedFields?: InputMaybe<CreditGrantManagedField_Arr_Rel_Insert_Input>;
  CustomerManagedFields?: InputMaybe<CustomerManagedField_Arr_Rel_Insert_Input>;
  CustomerPlanManagedFields?: InputMaybe<CustomerPlanManagedField_Arr_Rel_Insert_Input>;
  Disabler?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  InvoiceManagedFields?: InputMaybe<InvoiceManagedField_Arr_Rel_Insert_Input>;
  PlanManagedFields?: InputMaybe<PlanManagedField_Arr_Rel_Insert_Input>;
  ProServiceManagedFields?: InputMaybe<ProServiceManagedField_Arr_Rel_Insert_Input>;
  ProductManagedFields?: InputMaybe<ProductManagedField_Arr_Rel_Insert_Input>;
  RateCardManagedFields?: InputMaybe<RateCardManagedField_Arr_Rel_Insert_Input>;
  ScheduledChargeManagedFields?: InputMaybe<ScheduledChargeManagedField_Arr_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ManagedFieldKey_Max_Fields = {
  __typename?: 'ManagedFieldKey_max_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ManagedFieldKey_Min_Fields = {
  __typename?: 'ManagedFieldKey_min_fields';
  archived_at: Maybe<Scalars['timestamptz']>;
  archived_by: Maybe<Scalars['String']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  disabled_at: Maybe<Scalars['timestamptz']>;
  disabled_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ManagedFieldKey" */
export type ManagedFieldKey_Mutation_Response = {
  __typename?: 'ManagedFieldKey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagedFieldKey>;
};

/** input type for inserting object relation for remote table "ManagedFieldKey" */
export type ManagedFieldKey_Obj_Rel_Insert_Input = {
  data: ManagedFieldKey_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ManagedFieldKey_On_Conflict>;
};

/** on_conflict condition type for table "ManagedFieldKey" */
export type ManagedFieldKey_On_Conflict = {
  constraint: ManagedFieldKey_Constraint;
  update_columns?: Array<ManagedFieldKey_Update_Column>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};

/** Ordering options when selecting data from "ManagedFieldKey". */
export type ManagedFieldKey_Order_By = {
  AlertManagedFields_aggregate?: InputMaybe<AlertManagedField_Aggregate_Order_By>;
  ChargeManagedFields_aggregate?: InputMaybe<ChargeManagedField_Aggregate_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  CommitManagedFields_aggregate?: InputMaybe<CommitManagedField_Aggregate_Order_By>;
  ContractCreditManagedFields_aggregate?: InputMaybe<ContractCreditManagedField_Aggregate_Order_By>;
  ContractManagedFields_aggregate?: InputMaybe<ContractManagedField_Aggregate_Order_By>;
  ContractProductManagedFields_aggregate?: InputMaybe<ContractProductManagedField_Aggregate_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  CreditGrantManagedFields_aggregate?: InputMaybe<CreditGrantManagedField_Aggregate_Order_By>;
  CustomerManagedFields_aggregate?: InputMaybe<CustomerManagedField_Aggregate_Order_By>;
  CustomerPlanManagedFields_aggregate?: InputMaybe<CustomerPlanManagedField_Aggregate_Order_By>;
  Disabler?: InputMaybe<Actor_Order_By>;
  InvoiceManagedFields_aggregate?: InputMaybe<InvoiceManagedField_Aggregate_Order_By>;
  PlanManagedFields_aggregate?: InputMaybe<PlanManagedField_Aggregate_Order_By>;
  ProServiceManagedFields_aggregate?: InputMaybe<ProServiceManagedField_Aggregate_Order_By>;
  ProductManagedFields_aggregate?: InputMaybe<ProductManagedField_Aggregate_Order_By>;
  RateCardManagedFields_aggregate?: InputMaybe<RateCardManagedField_Aggregate_Order_By>;
  ScheduledChargeManagedFields_aggregate?: InputMaybe<ScheduledChargeManagedField_Aggregate_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  archived_at?: InputMaybe<Order_By>;
  archived_by?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  disabled_at?: InputMaybe<Order_By>;
  disabled_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  entity?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ManagedFieldKey */
export type ManagedFieldKey_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ManagedFieldKey" */
export enum ManagedFieldKey_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ArchivedBy = 'archived_by',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledBy = 'disabled_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  Entity = 'entity',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "ManagedFieldKey" */
export type ManagedFieldKey_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ManagedFieldKey" */
export type ManagedFieldKey_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManagedFieldKey_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagedFieldKey_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']>;
  archived_by?: InputMaybe<Scalars['String']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  disabled_at?: InputMaybe<Scalars['timestamptz']>;
  disabled_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ManagedFieldKey" */
export enum ManagedFieldKey_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  ArchivedBy = 'archived_by',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledBy = 'disabled_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  Entity = 'entity',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

export type ManagedFieldKey_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManagedFieldKey_Set_Input>;
  /** filter the rows which have to be updated */
  where: ManagedFieldKey_Bool_Exp;
};

/** columns and relationships of "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping = {
  __typename?: 'ManagedFieldVendorMapping';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  entity: ManagedEntityEnum_Enum;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  vendor: ManagedVendorEnum_Enum;
  vendor_entity: Scalars['String'];
  vendor_field: Scalars['String'];
};

/** aggregated selection of "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Aggregate = {
  __typename?: 'ManagedFieldVendorMapping_aggregate';
  aggregate: Maybe<ManagedFieldVendorMapping_Aggregate_Fields>;
  nodes: Array<ManagedFieldVendorMapping>;
};

/** aggregate fields of "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Aggregate_Fields = {
  __typename?: 'ManagedFieldVendorMapping_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ManagedFieldVendorMapping_Max_Fields>;
  min: Maybe<ManagedFieldVendorMapping_Min_Fields>;
};


/** aggregate fields of "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ManagedFieldVendorMapping_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ManagedFieldVendorMapping". All fields are combined with a logical 'AND'. */
export type ManagedFieldVendorMapping_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  _and?: InputMaybe<Array<ManagedFieldVendorMapping_Bool_Exp>>;
  _not?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
  _or?: InputMaybe<Array<ManagedFieldVendorMapping_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  entity?: InputMaybe<ManagedEntityEnum_Enum_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<ManagedVendorEnum_Enum_Comparison_Exp>;
  vendor_entity?: InputMaybe<String_Comparison_Exp>;
  vendor_field?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ManagedFieldVendorMapping" */
export enum ManagedFieldVendorMapping_Constraint {
  /** unique or primary key constraint on columns "id" */
  ManagedFieldVendorMappingPkey = 'ManagedFieldVendorMapping_pkey',
  /** unique or primary key constraint on columns "vendor_entity", "vendor", "environment_type", "vendor_field", "client_id" */
  ManagedFieldVendorMappingVendorVendorEntityVendorFieldKey = 'ManagedFieldVendorMapping_vendor_vendor_entity_vendor_field_key'
}

/** input type for inserting data into table "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<ManagedVendorEnum_Enum>;
  vendor_entity?: InputMaybe<Scalars['String']>;
  vendor_field?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ManagedFieldVendorMapping_Max_Fields = {
  __typename?: 'ManagedFieldVendorMapping_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  vendor_entity: Maybe<Scalars['String']>;
  vendor_field: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ManagedFieldVendorMapping_Min_Fields = {
  __typename?: 'ManagedFieldVendorMapping_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  vendor_entity: Maybe<Scalars['String']>;
  vendor_field: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Mutation_Response = {
  __typename?: 'ManagedFieldVendorMapping_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagedFieldVendorMapping>;
};

/** on_conflict condition type for table "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_On_Conflict = {
  constraint: ManagedFieldVendorMapping_Constraint;
  update_columns?: Array<ManagedFieldVendorMapping_Update_Column>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};

/** Ordering options when selecting data from "ManagedFieldVendorMapping". */
export type ManagedFieldVendorMapping_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  entity?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Order_By>;
  vendor_entity?: InputMaybe<Order_By>;
  vendor_field?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ManagedFieldVendorMapping */
export type ManagedFieldVendorMapping_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ManagedFieldVendorMapping" */
export enum ManagedFieldVendorMapping_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Entity = 'entity',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Vendor = 'vendor',
  /** column name */
  VendorEntity = 'vendor_entity',
  /** column name */
  VendorField = 'vendor_field'
}

/** input type for updating data in table "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<ManagedVendorEnum_Enum>;
  vendor_entity?: InputMaybe<Scalars['String']>;
  vendor_field?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ManagedFieldVendorMapping" */
export type ManagedFieldVendorMapping_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManagedFieldVendorMapping_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagedFieldVendorMapping_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<ManagedEntityEnum_Enum>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<ManagedVendorEnum_Enum>;
  vendor_entity?: InputMaybe<Scalars['String']>;
  vendor_field?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ManagedFieldVendorMapping" */
export enum ManagedFieldVendorMapping_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Entity = 'entity',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Vendor = 'vendor',
  /** column name */
  VendorEntity = 'vendor_entity',
  /** column name */
  VendorField = 'vendor_field'
}

export type ManagedFieldVendorMapping_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManagedFieldVendorMapping_Set_Input>;
  /** filter the rows which have to be updated */
  where: ManagedFieldVendorMapping_Bool_Exp;
};

/** columns and relationships of "ManagedVendorEnum" */
export type ManagedVendorEnum = {
  __typename?: 'ManagedVendorEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ManagedVendorEnum" */
export type ManagedVendorEnum_Aggregate = {
  __typename?: 'ManagedVendorEnum_aggregate';
  aggregate: Maybe<ManagedVendorEnum_Aggregate_Fields>;
  nodes: Array<ManagedVendorEnum>;
};

/** aggregate fields of "ManagedVendorEnum" */
export type ManagedVendorEnum_Aggregate_Fields = {
  __typename?: 'ManagedVendorEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ManagedVendorEnum_Max_Fields>;
  min: Maybe<ManagedVendorEnum_Min_Fields>;
};


/** aggregate fields of "ManagedVendorEnum" */
export type ManagedVendorEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ManagedVendorEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ManagedVendorEnum". All fields are combined with a logical 'AND'. */
export type ManagedVendorEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ManagedVendorEnum_Bool_Exp>>;
  _not?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ManagedVendorEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ManagedVendorEnum" */
export enum ManagedVendorEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ManagedVendorEnumPkey = 'ManagedVendorEnum_pkey'
}

export enum ManagedVendorEnum_Enum {
  Chargebee = 'Chargebee',
  NetSuite = 'NetSuite',
  Stripe = 'Stripe'
}

/** Boolean expression to compare columns of type "ManagedVendorEnum_enum". All fields are combined with logical 'AND'. */
export type ManagedVendorEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ManagedVendorEnum_Enum>;
  _in?: InputMaybe<Array<ManagedVendorEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ManagedVendorEnum_Enum>;
  _nin?: InputMaybe<Array<ManagedVendorEnum_Enum>>;
};

/** input type for inserting data into table "ManagedVendorEnum" */
export type ManagedVendorEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ManagedVendorEnum_Max_Fields = {
  __typename?: 'ManagedVendorEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ManagedVendorEnum_Min_Fields = {
  __typename?: 'ManagedVendorEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ManagedVendorEnum" */
export type ManagedVendorEnum_Mutation_Response = {
  __typename?: 'ManagedVendorEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagedVendorEnum>;
};

/** on_conflict condition type for table "ManagedVendorEnum" */
export type ManagedVendorEnum_On_Conflict = {
  constraint: ManagedVendorEnum_Constraint;
  update_columns?: Array<ManagedVendorEnum_Update_Column>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ManagedVendorEnum". */
export type ManagedVendorEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ManagedVendorEnum */
export type ManagedVendorEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ManagedVendorEnum" */
export enum ManagedVendorEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ManagedVendorEnum" */
export type ManagedVendorEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ManagedVendorEnum" */
export type ManagedVendorEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ManagedVendorEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagedVendorEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ManagedVendorEnum" */
export enum ManagedVendorEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ManagedVendorEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ManagedVendorEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ManagedVendorEnum_Bool_Exp;
};

export type MaxAmountRolloverAmountInput = {
  value: Scalars['numeric'];
};

export type MaxPercentageRolloverAmountInput = {
  value: Scalars['numeric'];
};

export type MetricValidationResponse = {
  __typename?: 'MetricValidationResponse';
  success: Scalars['Boolean'];
  validation_errors: Maybe<Array<Scalars['String']>>;
};

/** columns and relationships of "Minimum" */
export type Minimum = {
  __typename?: 'Minimum';
  /** An object relationship */
  CreditType: CreditType;
  /** An object relationship */
  Plan: Plan;
  created_at: Scalars['timestamptz'];
  credit_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  plan_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
  value: Scalars['numeric'];
};

export type MinimumInput = {
  credit_type_id: Scalars['uuid'];
  name: Scalars['String'];
  start_period: Scalars['numeric'];
  value: Scalars['numeric'];
};

export type MinimumLineItem = Mri_LineItem & {
  __typename?: 'MinimumLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  minimum: Scalars['numeric'];
  total: Scalars['numeric'];
};

/** aggregated selection of "Minimum" */
export type Minimum_Aggregate = {
  __typename?: 'Minimum_aggregate';
  aggregate: Maybe<Minimum_Aggregate_Fields>;
  nodes: Array<Minimum>;
};

export type Minimum_Aggregate_Bool_Exp = {
  count?: InputMaybe<Minimum_Aggregate_Bool_Exp_Count>;
};

export type Minimum_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Minimum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Minimum_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Minimum" */
export type Minimum_Aggregate_Fields = {
  __typename?: 'Minimum_aggregate_fields';
  avg: Maybe<Minimum_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Minimum_Max_Fields>;
  min: Maybe<Minimum_Min_Fields>;
  stddev: Maybe<Minimum_Stddev_Fields>;
  stddev_pop: Maybe<Minimum_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Minimum_Stddev_Samp_Fields>;
  sum: Maybe<Minimum_Sum_Fields>;
  var_pop: Maybe<Minimum_Var_Pop_Fields>;
  var_samp: Maybe<Minimum_Var_Samp_Fields>;
  variance: Maybe<Minimum_Variance_Fields>;
};


/** aggregate fields of "Minimum" */
export type Minimum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Minimum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Minimum" */
export type Minimum_Aggregate_Order_By = {
  avg?: InputMaybe<Minimum_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Minimum_Max_Order_By>;
  min?: InputMaybe<Minimum_Min_Order_By>;
  stddev?: InputMaybe<Minimum_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Minimum_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Minimum_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Minimum_Sum_Order_By>;
  var_pop?: InputMaybe<Minimum_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Minimum_Var_Samp_Order_By>;
  variance?: InputMaybe<Minimum_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Minimum" */
export type Minimum_Arr_Rel_Insert_Input = {
  data: Array<Minimum_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Minimum_On_Conflict>;
};

/** aggregate avg on columns */
export type Minimum_Avg_Fields = {
  __typename?: 'Minimum_avg_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Minimum" */
export type Minimum_Avg_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Minimum". All fields are combined with a logical 'AND'. */
export type Minimum_Bool_Exp = {
  CreditType?: InputMaybe<CreditType_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  _and?: InputMaybe<Array<Minimum_Bool_Exp>>;
  _not?: InputMaybe<Minimum_Bool_Exp>;
  _or?: InputMaybe<Array<Minimum_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Minimum" */
export enum Minimum_Constraint {
  /** unique or primary key constraint on columns "id" */
  MinimumPkey = 'Minimum_pkey',
  /** unique or primary key constraint on columns "credit_type_id", "start_period", "plan_id" */
  MinimumPlanCreditTypeStartKey = 'Minimum_plan_credit_type_start_key'
}

/** input type for incrementing numeric columns in table "Minimum" */
export type Minimum_Inc_Input = {
  start_period?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Minimum" */
export type Minimum_Insert_Input = {
  CreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Minimum_Max_Fields = {
  __typename?: 'Minimum_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "Minimum" */
export type Minimum_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Minimum_Min_Fields = {
  __typename?: 'Minimum_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "Minimum" */
export type Minimum_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Minimum" */
export type Minimum_Mutation_Response = {
  __typename?: 'Minimum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Minimum>;
};

/** on_conflict condition type for table "Minimum" */
export type Minimum_On_Conflict = {
  constraint: Minimum_Constraint;
  update_columns?: Array<Minimum_Update_Column>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};

/** Ordering options when selecting data from "Minimum". */
export type Minimum_Order_By = {
  CreditType?: InputMaybe<CreditType_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Minimum */
export type Minimum_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Minimum" */
export enum Minimum_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Minimum" */
export type Minimum_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Minimum_Stddev_Fields = {
  __typename?: 'Minimum_stddev_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Minimum" */
export type Minimum_Stddev_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Minimum_Stddev_Pop_Fields = {
  __typename?: 'Minimum_stddev_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Minimum" */
export type Minimum_Stddev_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Minimum_Stddev_Samp_Fields = {
  __typename?: 'Minimum_stddev_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Minimum" */
export type Minimum_Stddev_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Minimum" */
export type Minimum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Minimum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Minimum_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Minimum_Sum_Fields = {
  __typename?: 'Minimum_sum_fields';
  start_period: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Minimum" */
export type Minimum_Sum_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "Minimum" */
export enum Minimum_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  Value = 'value'
}

export type Minimum_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Minimum_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Minimum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Minimum_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Minimum_Var_Pop_Fields = {
  __typename?: 'Minimum_var_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Minimum" */
export type Minimum_Var_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Minimum_Var_Samp_Fields = {
  __typename?: 'Minimum_var_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Minimum" */
export type Minimum_Var_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Minimum_Variance_Fields = {
  __typename?: 'Minimum_variance_fields';
  start_period: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Minimum" */
export type Minimum_Variance_Order_By = {
  start_period?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

export type MoveRateCardProductsInput = {
  product_moves: Array<RateCardProductPositionInput>;
  rate_card_id: Scalars['uuid'];
};

export type MultiplierOverride = {
  __typename?: 'MultiplierOverride';
  multiplier: Scalars['numeric'];
  priority: Maybe<Scalars['numeric']>;
};

/** mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  add_aws_marketplace_to_customer: AddMarketplaceToCustomerMutationResponse;
  add_azure_marketplace_to_customer: AddMarketplaceToCustomerMutationResponse;
  add_manual_commit_ledger_entry: MutationResponse;
  add_plan_to_customer: CustomerPlan;
  add_rate: RateCardEntry;
  amend_contract: Contract;
  archive_alert: Alert;
  archive_billable_metric: BillableMetric;
  archive_contract: ArchiveContractMutationResponse;
  archive_customer: Customer;
  archive_plan: Plan;
  archive_product: Product;
  archive_product_list_item: ArchiveProductListItemMutationResponse;
  archive_scheduled_webhook_notifications: Array<ScheduledWebhookNotification>;
  archive_seat_metric: SeatMetric;
  archive_user: User;
  backfill_billable_metric: MutationResponse;
  cancel_end_customer_plan: MutationResponse;
  change_commit_end_date: CommitUnion;
  change_password: MutationResponse;
  create_adhoc_contract_usage_invoices: Array<AdhocContractUsageInvoice>;
  create_adhoc_plan_invoices: Array<AdHocPlanInvoice>;
  create_api_token: ApiTokenResponse;
  create_billable_metric: BillableMetric;
  create_billable_metrics: Array<BillableMetric>;
  create_billing_provider_settings: MutationResponse;
  create_census_workspace: CensusWorkspace;
  create_composite_product_list_item: CompositeProductListItem;
  create_contract: Contract;
  create_credit_grant: CreditGrant;
  create_customer: Customer;
  create_customer_commit: CommitUnion;
  create_fancy_metric: BillableMetric;
  create_fixed_product_list_item: FixedProductListItem;
  create_invoice_correction: CorrectionInvoice;
  create_one_time_charge: OneTimeProductCharge;
  create_plan: Plan;
  create_prepaid_credit_grant: PepaidCreditGrantResponse;
  create_pro_service_invoices: Array<ContractProServiceInvoice>;
  create_pro_service_product_list_item: ProServiceProductListItem;
  create_rate_card: RateCard;
  create_recharge_settings: RechargeSettings;
  create_recharge_settings_and_recharge: RechargeSettingsUpdateAndChargeResponse;
  create_recurring_credit_grants: Array<CreditGrant>;
  create_scheduled_webhook_notification: ScheduledWebhookNotification;
  create_seat_metric: SeatMetric;
  create_seat_purchase_invoice: SeatPurchaseInvoice;
  create_subscription_product_list_item: SubscriptionProductListItem;
  create_usage_product_list_item: UsageProductListItem;
  create_user: User;
  create_webhook: Webhook;
  /** delete data from the table: "AccountTypeEnum" */
  delete_AccountTypeEnum: Maybe<AccountTypeEnum_Mutation_Response>;
  /** delete single row from the table: "AccountTypeEnum" */
  delete_AccountTypeEnum_by_pk: Maybe<AccountTypeEnum>;
  /** delete data from the table: "Actor" */
  delete_Actor: Maybe<Actor_Mutation_Response>;
  /** delete single row from the table: "Actor" */
  delete_Actor_by_pk: Maybe<Actor>;
  /** delete data from the table: "Alert" */
  delete_Alert: Maybe<Alert_Mutation_Response>;
  /** delete data from the table: "AlertManagedField" */
  delete_AlertManagedField: Maybe<AlertManagedField_Mutation_Response>;
  /** delete single row from the table: "AlertManagedField" */
  delete_AlertManagedField_by_pk: Maybe<AlertManagedField>;
  /** delete data from the table: "AlertTypeEnum" */
  delete_AlertTypeEnum: Maybe<AlertTypeEnum_Mutation_Response>;
  /** delete single row from the table: "AlertTypeEnum" */
  delete_AlertTypeEnum_by_pk: Maybe<AlertTypeEnum>;
  /** delete single row from the table: "Alert" */
  delete_Alert_by_pk: Maybe<Alert>;
  /** delete data from the table: "BillableMetric" */
  delete_BillableMetric: Maybe<BillableMetric_Mutation_Response>;
  /** delete data from the table: "BillableMetricManagedField" */
  delete_BillableMetricManagedField: Maybe<BillableMetricManagedField_Mutation_Response>;
  /** delete single row from the table: "BillableMetricManagedField" */
  delete_BillableMetricManagedField_by_pk: Maybe<BillableMetricManagedField>;
  /** delete single row from the table: "BillableMetric" */
  delete_BillableMetric_by_pk: Maybe<BillableMetric>;
  /** delete data from the table: "BillingFrequencyEnum" */
  delete_BillingFrequencyEnum: Maybe<BillingFrequencyEnum_Mutation_Response>;
  /** delete single row from the table: "BillingFrequencyEnum" */
  delete_BillingFrequencyEnum_by_pk: Maybe<BillingFrequencyEnum>;
  /** delete data from the table: "BillingMetricAggregateEnum" */
  delete_BillingMetricAggregateEnum: Maybe<BillingMetricAggregateEnum_Mutation_Response>;
  /** delete single row from the table: "BillingMetricAggregateEnum" */
  delete_BillingMetricAggregateEnum_by_pk: Maybe<BillingMetricAggregateEnum>;
  /** delete data from the table: "BillingProviderCustomer" */
  delete_BillingProviderCustomer: Maybe<BillingProviderCustomer_Mutation_Response>;
  /** delete single row from the table: "BillingProviderCustomer" */
  delete_BillingProviderCustomer_by_pk: Maybe<BillingProviderCustomer>;
  /** delete data from the table: "BillingProviderEnum" */
  delete_BillingProviderEnum: Maybe<BillingProviderEnum_Mutation_Response>;
  /** delete single row from the table: "BillingProviderEnum" */
  delete_BillingProviderEnum_by_pk: Maybe<BillingProviderEnum>;
  /** delete data from the table: "BillingProviderInvoice" */
  delete_BillingProviderInvoice: Maybe<BillingProviderInvoice_Mutation_Response>;
  /** delete single row from the table: "BillingProviderInvoice" */
  delete_BillingProviderInvoice_by_pk: Maybe<BillingProviderInvoice>;
  /** delete data from the table: "BillingProviderToken" */
  delete_BillingProviderToken: Maybe<BillingProviderToken_Mutation_Response>;
  /** delete single row from the table: "BillingProviderToken" */
  delete_BillingProviderToken_by_pk: Maybe<BillingProviderToken>;
  /** delete data from the table: "ChargeManagedField" */
  delete_ChargeManagedField: Maybe<ChargeManagedField_Mutation_Response>;
  /** delete single row from the table: "ChargeManagedField" */
  delete_ChargeManagedField_by_pk: Maybe<ChargeManagedField>;
  /** delete data from the table: "ChargeTypeEnum" */
  delete_ChargeTypeEnum: Maybe<ChargeTypeEnum_Mutation_Response>;
  /** delete single row from the table: "ChargeTypeEnum" */
  delete_ChargeTypeEnum_by_pk: Maybe<ChargeTypeEnum>;
  /** delete data from the table: "Client" */
  delete_Client: Maybe<Client_Mutation_Response>;
  /** delete data from the table: "ClientConfig" */
  delete_ClientConfig: Maybe<ClientConfig_Mutation_Response>;
  /** delete data from the table: "ClientConfigKeyEnum" */
  delete_ClientConfigKeyEnum: Maybe<ClientConfigKeyEnum_Mutation_Response>;
  /** delete single row from the table: "ClientConfigKeyEnum" */
  delete_ClientConfigKeyEnum_by_pk: Maybe<ClientConfigKeyEnum>;
  /** delete single row from the table: "ClientConfig" */
  delete_ClientConfig_by_pk: Maybe<ClientConfig>;
  /** delete single row from the table: "Client" */
  delete_Client_by_pk: Maybe<Client>;
  /** delete data from the table: "CollectionScheduleEnum" */
  delete_CollectionScheduleEnum: Maybe<CollectionScheduleEnum_Mutation_Response>;
  /** delete single row from the table: "CollectionScheduleEnum" */
  delete_CollectionScheduleEnum_by_pk: Maybe<CollectionScheduleEnum>;
  /** delete data from the table: "CommitManagedField" */
  delete_CommitManagedField: Maybe<CommitManagedField_Mutation_Response>;
  /** delete single row from the table: "CommitManagedField" */
  delete_CommitManagedField_by_pk: Maybe<CommitManagedField>;
  /** delete data from the table: "CompositeCharge" */
  delete_CompositeCharge: Maybe<CompositeCharge_Mutation_Response>;
  /** delete data from the table: "CompositeChargeAdjustment" */
  delete_CompositeChargeAdjustment: Maybe<CompositeChargeAdjustment_Mutation_Response>;
  /** delete single row from the table: "CompositeChargeAdjustment" */
  delete_CompositeChargeAdjustment_by_pk: Maybe<CompositeChargeAdjustment>;
  /** delete data from the table: "CompositeChargePricingFactor" */
  delete_CompositeChargePricingFactor: Maybe<CompositeChargePricingFactor_Mutation_Response>;
  /** delete data from the table: "CompositeChargeTier" */
  delete_CompositeChargeTier: Maybe<CompositeChargeTier_Mutation_Response>;
  /** delete data from the table: "CompositeChargeTierAdjustment" */
  delete_CompositeChargeTierAdjustment: Maybe<CompositeChargeTierAdjustment_Mutation_Response>;
  /** delete single row from the table: "CompositeChargeTierAdjustment" */
  delete_CompositeChargeTierAdjustment_by_pk: Maybe<CompositeChargeTierAdjustment>;
  /** delete single row from the table: "CompositeChargeTier" */
  delete_CompositeChargeTier_by_pk: Maybe<CompositeChargeTier>;
  /** delete data from the table: "CompositeChargeTypeEnum" */
  delete_CompositeChargeTypeEnum: Maybe<CompositeChargeTypeEnum_Mutation_Response>;
  /** delete single row from the table: "CompositeChargeTypeEnum" */
  delete_CompositeChargeTypeEnum_by_pk: Maybe<CompositeChargeTypeEnum>;
  /** delete single row from the table: "CompositeCharge" */
  delete_CompositeCharge_by_pk: Maybe<CompositeCharge>;
  /** delete data from the table: "ContractCreditManagedField" */
  delete_ContractCreditManagedField: Maybe<ContractCreditManagedField_Mutation_Response>;
  /** delete single row from the table: "ContractCreditManagedField" */
  delete_ContractCreditManagedField_by_pk: Maybe<ContractCreditManagedField>;
  /** delete data from the table: "ContractManagedField" */
  delete_ContractManagedField: Maybe<ContractManagedField_Mutation_Response>;
  /** delete single row from the table: "ContractManagedField" */
  delete_ContractManagedField_by_pk: Maybe<ContractManagedField>;
  /** delete data from the table: "ContractProductManagedField" */
  delete_ContractProductManagedField: Maybe<ContractProductManagedField_Mutation_Response>;
  /** delete single row from the table: "ContractProductManagedField" */
  delete_ContractProductManagedField_by_pk: Maybe<ContractProductManagedField>;
  /** delete data from the table: "CreditAdjustment" */
  delete_CreditAdjustment: Maybe<CreditAdjustment_Mutation_Response>;
  /** delete single row from the table: "CreditAdjustment" */
  delete_CreditAdjustment_by_pk: Maybe<CreditAdjustment>;
  /** delete data from the table: "CreditGrant" */
  delete_CreditGrant: Maybe<CreditGrant_Mutation_Response>;
  /** delete data from the table: "CreditGrantDeduction" */
  delete_CreditGrantDeduction: Maybe<CreditGrantDeduction_Mutation_Response>;
  /** delete single row from the table: "CreditGrantDeduction" */
  delete_CreditGrantDeduction_by_pk: Maybe<CreditGrantDeduction>;
  /** delete data from the table: "CreditGrantHistory" */
  delete_CreditGrantHistory: Maybe<CreditGrantHistory_Mutation_Response>;
  /** delete single row from the table: "CreditGrantHistory" */
  delete_CreditGrantHistory_by_pk: Maybe<CreditGrantHistory>;
  /** delete data from the table: "CreditGrantManagedField" */
  delete_CreditGrantManagedField: Maybe<CreditGrantManagedField_Mutation_Response>;
  /** delete single row from the table: "CreditGrantManagedField" */
  delete_CreditGrantManagedField_by_pk: Maybe<CreditGrantManagedField>;
  /** delete single row from the table: "CreditGrant" */
  delete_CreditGrant_by_pk: Maybe<CreditGrant>;
  /** delete data from the table: "CreditType" */
  delete_CreditType: Maybe<CreditType_Mutation_Response>;
  /** delete data from the table: "CreditTypeConversion" */
  delete_CreditTypeConversion: Maybe<CreditTypeConversion_Mutation_Response>;
  /** delete data from the table: "CreditTypeConversionAdjustment" */
  delete_CreditTypeConversionAdjustment: Maybe<CreditTypeConversionAdjustment_Mutation_Response>;
  /** delete single row from the table: "CreditTypeConversionAdjustment" */
  delete_CreditTypeConversionAdjustment_by_pk: Maybe<CreditTypeConversionAdjustment>;
  /** delete single row from the table: "CreditTypeConversion" */
  delete_CreditTypeConversion_by_pk: Maybe<CreditTypeConversion>;
  /** delete single row from the table: "CreditType" */
  delete_CreditType_by_pk: Maybe<CreditType>;
  /** delete data from the table: "CustomPricing" */
  delete_CustomPricing: Maybe<CustomPricing_Mutation_Response>;
  /** delete single row from the table: "CustomPricing" */
  delete_CustomPricing_by_pk: Maybe<CustomPricing>;
  /** delete data from the table: "Customer" */
  delete_Customer: Maybe<Customer_Mutation_Response>;
  /** delete data from the table: "CustomerAlert" */
  delete_CustomerAlert: Maybe<CustomerAlert_Mutation_Response>;
  /** delete data from the table: "CustomerAlertHistory" */
  delete_CustomerAlertHistory: Maybe<CustomerAlertHistory_Mutation_Response>;
  /** delete single row from the table: "CustomerAlertHistory" */
  delete_CustomerAlertHistory_by_pk: Maybe<CustomerAlertHistory>;
  /** delete data from the table: "CustomerAlertStatusEnum" */
  delete_CustomerAlertStatusEnum: Maybe<CustomerAlertStatusEnum_Mutation_Response>;
  /** delete single row from the table: "CustomerAlertStatusEnum" */
  delete_CustomerAlertStatusEnum_by_pk: Maybe<CustomerAlertStatusEnum>;
  /** delete single row from the table: "CustomerAlert" */
  delete_CustomerAlert_by_pk: Maybe<CustomerAlert>;
  /** delete data from the table: "CustomerConfig" */
  delete_CustomerConfig: Maybe<CustomerConfig_Mutation_Response>;
  /** delete single row from the table: "CustomerConfig" */
  delete_CustomerConfig_by_pk: Maybe<CustomerConfig>;
  /** delete data from the table: "CustomerIngestAlias" */
  delete_CustomerIngestAlias: Maybe<CustomerIngestAlias_Mutation_Response>;
  /** delete single row from the table: "CustomerIngestAlias" */
  delete_CustomerIngestAlias_by_pk: Maybe<CustomerIngestAlias>;
  /** delete data from the table: "CustomerManagedField" */
  delete_CustomerManagedField: Maybe<CustomerManagedField_Mutation_Response>;
  /** delete single row from the table: "CustomerManagedField" */
  delete_CustomerManagedField_by_pk: Maybe<CustomerManagedField>;
  /** delete data from the table: "CustomerPlan" */
  delete_CustomerPlan: Maybe<CustomerPlan_Mutation_Response>;
  /** delete data from the table: "CustomerPlanAdjustment" */
  delete_CustomerPlanAdjustment: Maybe<CustomerPlanAdjustment_Mutation_Response>;
  /** delete single row from the table: "CustomerPlanAdjustment" */
  delete_CustomerPlanAdjustment_by_pk: Maybe<CustomerPlanAdjustment>;
  /** delete data from the table: "CustomerPlanManagedField" */
  delete_CustomerPlanManagedField: Maybe<CustomerPlanManagedField_Mutation_Response>;
  /** delete single row from the table: "CustomerPlanManagedField" */
  delete_CustomerPlanManagedField_by_pk: Maybe<CustomerPlanManagedField>;
  /** delete data from the table: "CustomerPlanOneTimeCharge" */
  delete_CustomerPlanOneTimeCharge: Maybe<CustomerPlanOneTimeCharge_Mutation_Response>;
  /** delete single row from the table: "CustomerPlanOneTimeCharge" */
  delete_CustomerPlanOneTimeCharge_by_pk: Maybe<CustomerPlanOneTimeCharge>;
  /** delete single row from the table: "CustomerPlan" */
  delete_CustomerPlan_by_pk: Maybe<CustomerPlan>;
  /** delete data from the table: "CustomerRelationship" */
  delete_CustomerRelationship: Maybe<CustomerRelationship_Mutation_Response>;
  /** delete single row from the table: "CustomerRelationship" */
  delete_CustomerRelationship_by_pk: Maybe<CustomerRelationship>;
  /** delete single row from the table: "Customer" */
  delete_Customer_by_pk: Maybe<Customer>;
  /** delete data from the table: "DraftPlan" */
  delete_DraftPlan: Maybe<DraftPlan_Mutation_Response>;
  /** delete single row from the table: "DraftPlan" */
  delete_DraftPlan_by_pk: Maybe<DraftPlan>;
  /** delete data from the table: "EnvironmentTypeEnum" */
  delete_EnvironmentTypeEnum: Maybe<EnvironmentTypeEnum_Mutation_Response>;
  /** delete single row from the table: "EnvironmentTypeEnum" */
  delete_EnvironmentTypeEnum_by_pk: Maybe<EnvironmentTypeEnum>;
  /** delete data from the table: "FlatFee" */
  delete_FlatFee: Maybe<FlatFee_Mutation_Response>;
  /** delete data from the table: "FlatFeeAdjustment" */
  delete_FlatFeeAdjustment: Maybe<FlatFeeAdjustment_Mutation_Response>;
  /** delete single row from the table: "FlatFeeAdjustment" */
  delete_FlatFeeAdjustment_by_pk: Maybe<FlatFeeAdjustment>;
  /** delete single row from the table: "FlatFee" */
  delete_FlatFee_by_pk: Maybe<FlatFee>;
  /** delete data from the table: "InvoiceExternalStatusEnum" */
  delete_InvoiceExternalStatusEnum: Maybe<InvoiceExternalStatusEnum_Mutation_Response>;
  /** delete single row from the table: "InvoiceExternalStatusEnum" */
  delete_InvoiceExternalStatusEnum_by_pk: Maybe<InvoiceExternalStatusEnum>;
  /** delete data from the table: "InvoiceManagedField" */
  delete_InvoiceManagedField: Maybe<InvoiceManagedField_Mutation_Response>;
  /** delete single row from the table: "InvoiceManagedField" */
  delete_InvoiceManagedField_by_pk: Maybe<InvoiceManagedField>;
  /** delete data from the table: "ManagedEntityEnum" */
  delete_ManagedEntityEnum: Maybe<ManagedEntityEnum_Mutation_Response>;
  /** delete single row from the table: "ManagedEntityEnum" */
  delete_ManagedEntityEnum_by_pk: Maybe<ManagedEntityEnum>;
  /** delete data from the table: "ManagedFieldKey" */
  delete_ManagedFieldKey: Maybe<ManagedFieldKey_Mutation_Response>;
  /** delete single row from the table: "ManagedFieldKey" */
  delete_ManagedFieldKey_by_pk: Maybe<ManagedFieldKey>;
  /** delete data from the table: "ManagedFieldVendorMapping" */
  delete_ManagedFieldVendorMapping: Maybe<ManagedFieldVendorMapping_Mutation_Response>;
  /** delete single row from the table: "ManagedFieldVendorMapping" */
  delete_ManagedFieldVendorMapping_by_pk: Maybe<ManagedFieldVendorMapping>;
  /** delete data from the table: "ManagedVendorEnum" */
  delete_ManagedVendorEnum: Maybe<ManagedVendorEnum_Mutation_Response>;
  /** delete single row from the table: "ManagedVendorEnum" */
  delete_ManagedVendorEnum_by_pk: Maybe<ManagedVendorEnum>;
  /** delete data from the table: "Minimum" */
  delete_Minimum: Maybe<Minimum_Mutation_Response>;
  /** delete single row from the table: "Minimum" */
  delete_Minimum_by_pk: Maybe<Minimum>;
  /** delete data from the table: "Plan" */
  delete_Plan: Maybe<Plan_Mutation_Response>;
  /** delete data from the table: "PlanManagedField" */
  delete_PlanManagedField: Maybe<PlanManagedField_Mutation_Response>;
  /** delete single row from the table: "PlanManagedField" */
  delete_PlanManagedField_by_pk: Maybe<PlanManagedField>;
  /** delete single row from the table: "Plan" */
  delete_Plan_by_pk: Maybe<Plan>;
  /** delete data from the table: "Price" */
  delete_Price: Maybe<Price_Mutation_Response>;
  /** delete data from the table: "PriceAdjustment" */
  delete_PriceAdjustment: Maybe<PriceAdjustment_Mutation_Response>;
  /** delete data from the table: "PriceAdjustmentTypeEnum" */
  delete_PriceAdjustmentTypeEnum: Maybe<PriceAdjustmentTypeEnum_Mutation_Response>;
  /** delete single row from the table: "PriceAdjustmentTypeEnum" */
  delete_PriceAdjustmentTypeEnum_by_pk: Maybe<PriceAdjustmentTypeEnum>;
  /** delete single row from the table: "PriceAdjustment" */
  delete_PriceAdjustment_by_pk: Maybe<PriceAdjustment>;
  /** delete single row from the table: "Price" */
  delete_Price_by_pk: Maybe<Price>;
  /** delete data from the table: "PricedProduct" */
  delete_PricedProduct: Maybe<PricedProduct_Mutation_Response>;
  /** delete data from the table: "PricedProductAdjustment" */
  delete_PricedProductAdjustment: Maybe<PricedProductAdjustment_Mutation_Response>;
  /** delete single row from the table: "PricedProductAdjustment" */
  delete_PricedProductAdjustment_by_pk: Maybe<PricedProductAdjustment>;
  /** delete data from the table: "PricedProductPricingFactor" */
  delete_PricedProductPricingFactor: Maybe<PricedProductPricingFactor_Mutation_Response>;
  /** delete data from the table: "PricedProductPricingFactorAdjustment" */
  delete_PricedProductPricingFactorAdjustment: Maybe<PricedProductPricingFactorAdjustment_Mutation_Response>;
  /** delete single row from the table: "PricedProductPricingFactorAdjustment" */
  delete_PricedProductPricingFactorAdjustment_by_pk: Maybe<PricedProductPricingFactorAdjustment>;
  /** delete single row from the table: "PricedProductPricingFactor" */
  delete_PricedProductPricingFactor_by_pk: Maybe<PricedProductPricingFactor>;
  /** delete single row from the table: "PricedProduct" */
  delete_PricedProduct_by_pk: Maybe<PricedProduct>;
  /** delete data from the table: "ProServiceManagedField" */
  delete_ProServiceManagedField: Maybe<ProServiceManagedField_Mutation_Response>;
  /** delete single row from the table: "ProServiceManagedField" */
  delete_ProServiceManagedField_by_pk: Maybe<ProServiceManagedField>;
  /** delete data from the table: "Product" */
  delete_Product: Maybe<Product_Mutation_Response>;
  /** delete data from the table: "ProductManagedField" */
  delete_ProductManagedField: Maybe<ProductManagedField_Mutation_Response>;
  /** delete single row from the table: "ProductManagedField" */
  delete_ProductManagedField_by_pk: Maybe<ProductManagedField>;
  /** delete data from the table: "ProductPricingFactor" */
  delete_ProductPricingFactor: Maybe<ProductPricingFactor_Mutation_Response>;
  /** delete single row from the table: "ProductPricingFactor" */
  delete_ProductPricingFactor_by_pk: Maybe<ProductPricingFactor>;
  /** delete single row from the table: "Product" */
  delete_Product_by_pk: Maybe<Product>;
  /** delete data from the table: "RateCardManagedField" */
  delete_RateCardManagedField: Maybe<RateCardManagedField_Mutation_Response>;
  /** delete single row from the table: "RateCardManagedField" */
  delete_RateCardManagedField_by_pk: Maybe<RateCardManagedField>;
  /** delete data from the table: "RecurringCreditGrant" */
  delete_RecurringCreditGrant: Maybe<RecurringCreditGrant_Mutation_Response>;
  /** delete single row from the table: "RecurringCreditGrant" */
  delete_RecurringCreditGrant_by_pk: Maybe<RecurringCreditGrant>;
  /** delete data from the table: "Role" */
  delete_Role: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "Role" */
  delete_Role_by_pk: Maybe<Role>;
  /** delete data from the table: "RoundingBehaviorEnum" */
  delete_RoundingBehaviorEnum: Maybe<RoundingBehaviorEnum_Mutation_Response>;
  /** delete single row from the table: "RoundingBehaviorEnum" */
  delete_RoundingBehaviorEnum_by_pk: Maybe<RoundingBehaviorEnum>;
  /** delete data from the table: "ScheduledChargeManagedField" */
  delete_ScheduledChargeManagedField: Maybe<ScheduledChargeManagedField_Mutation_Response>;
  /** delete single row from the table: "ScheduledChargeManagedField" */
  delete_ScheduledChargeManagedField_by_pk: Maybe<ScheduledChargeManagedField>;
  /** delete data from the table: "ServicePeriodStartOnEnum" */
  delete_ServicePeriodStartOnEnum: Maybe<ServicePeriodStartOnEnum_Mutation_Response>;
  /** delete single row from the table: "ServicePeriodStartOnEnum" */
  delete_ServicePeriodStartOnEnum_by_pk: Maybe<ServicePeriodStartOnEnum>;
  /** delete data from the table: "StripePrepaidConfig_temporary" */
  delete_StripePrepaidConfig_temporary: Maybe<StripePrepaidConfig_Temporary_Mutation_Response>;
  /** delete single row from the table: "StripePrepaidConfig_temporary" */
  delete_StripePrepaidConfig_temporary_by_pk: Maybe<StripePrepaidConfig_Temporary>;
  /** delete data from the table: "TieringModeEnum" */
  delete_TieringModeEnum: Maybe<TieringModeEnum_Mutation_Response>;
  /** delete single row from the table: "TieringModeEnum" */
  delete_TieringModeEnum_by_pk: Maybe<TieringModeEnum>;
  /** delete data from the table: "Tokens" */
  delete_Tokens: Maybe<Tokens_Mutation_Response>;
  /** delete single row from the table: "Tokens" */
  delete_Tokens_by_pk: Maybe<Tokens>;
  /** delete data from the table: "TrialSpec" */
  delete_TrialSpec: Maybe<TrialSpec_Mutation_Response>;
  /** delete data from the table: "TrialSpecSpendingCap" */
  delete_TrialSpecSpendingCap: Maybe<TrialSpecSpendingCap_Mutation_Response>;
  /** delete single row from the table: "TrialSpecSpendingCap" */
  delete_TrialSpecSpendingCap_by_pk: Maybe<TrialSpecSpendingCap>;
  /** delete single row from the table: "TrialSpec" */
  delete_TrialSpec_by_pk: Maybe<TrialSpec>;
  /** delete data from the table: "UserAuthTypeEnum" */
  delete_UserAuthTypeEnum: Maybe<UserAuthTypeEnum_Mutation_Response>;
  /** delete single row from the table: "UserAuthTypeEnum" */
  delete_UserAuthTypeEnum_by_pk: Maybe<UserAuthTypeEnum>;
  /** delete data from the table: "User" */
  delete_User_doNotUse: Maybe<User_Mutation_Response>;
  /** delete data from the table: "Webhook" */
  delete_Webhook: Maybe<Webhook_Mutation_Response>;
  /** delete data from the table: "webhook_notifications" */
  delete_WebhookNotification: Maybe<WebhookNotification_Mutation_Response>;
  /** delete single row from the table: "webhook_notifications" */
  delete_WebhookNotification_by_pk: Maybe<WebhookNotification>;
  /** delete single row from the table: "Webhook" */
  delete_Webhook_by_pk: Maybe<Webhook>;
  delete_billing_provider_settings: MutationResponse;
  /** delete single row from the table: "User" */
  delete_by_pk_User_doNotUse: Maybe<User>;
  delete_one_time_charge: Scalars['Boolean'];
  delete_recharge_settings: MutationResponse;
  delete_user: MutationResponse;
  demo_setup_customer: Scalars['String'];
  disable_managed_field_key: ManagedFieldKey;
  edit_contract: Contract;
  /**
   * Edits a plan "in-place", spinning out the old version of the plan as an archived plan.
   * The _new_ version of the plan retains the plan's id, while the archived previous version
   * gets a new id.
   *
   * If `dangerous_cutover_date` is not supplied, customers on the plan will not have their CustomerPlan
   * entries changed, but the plan that they point to will have changed. Thus they will begin
   * seeing the new pricing immediately in draft invoices, and future invoices will be finalized
   * using the new pricing retroactive to the beginning of the billing period. Their billing
   * periods will not change assuming the plan's billing interval doesn't change (which we disallow
   * in the editing UI), and where they are with respect to ramps on the plan will remain unchanged
   * since their plan start date is not changing.
   *
   * Note: users can specify starting_on to ensure that only service periods starting after the specified date will use the
   * new version of the plan. Spec here: https://www.notion.so/teammetronome/Eng-Spec-Scheduled-Plan-Edits-8d948975bc504d7cae7c9b0d522a5c98?pvs=4
   *
   * If `dangerous_cutover_date` is supplied, we will split the CustomerPlan for each customer for whom the
   * plan is active on the cutover date. They will have the old version of their plan terminated on the
   * cutover_date and the new version of the plan started on the cutover_date, using whatever previous
   * cancellation_date they had for the plan in the new version of the plan.
   * WARNING: This will cause them them have a new plan start date on the new version of the plan, so
   * they'll reset ramps if the plan has those and will prematurely end their trial if they had one set
   * up. If the cutover_date isn't aligned with a billing period boundary, this will also split the
   * customer's billing period where the plan changes.
   */
  edit_plan: EditPlanResponse;
  edit_product: Product;
  end_customer_plan: MutationResponse;
  generate_azure_auth_token: GenerateAzureAuthTokenMutationResponse;
  generate_census_destination_connect_link: CensusDestinationConnectLink;
  generate_prequel_auth_token: PrequelAuthTokenResponse;
  generate_workato_sso_url: WorkatoSsoUrlResponse;
  /** insert data into the table: "AccountTypeEnum" */
  insert_AccountTypeEnum: Maybe<AccountTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "AccountTypeEnum" */
  insert_AccountTypeEnum_one: Maybe<AccountTypeEnum>;
  /** insert data into the table: "Actor" */
  insert_Actor: Maybe<Actor_Mutation_Response>;
  /** insert a single row into the table: "Actor" */
  insert_Actor_one: Maybe<Actor>;
  /** insert data into the table: "Alert" */
  insert_Alert: Maybe<Alert_Mutation_Response>;
  /** insert data into the table: "AlertManagedField" */
  insert_AlertManagedField: Maybe<AlertManagedField_Mutation_Response>;
  /** insert a single row into the table: "AlertManagedField" */
  insert_AlertManagedField_one: Maybe<AlertManagedField>;
  /** insert data into the table: "AlertTypeEnum" */
  insert_AlertTypeEnum: Maybe<AlertTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "AlertTypeEnum" */
  insert_AlertTypeEnum_one: Maybe<AlertTypeEnum>;
  /** insert a single row into the table: "Alert" */
  insert_Alert_one: Maybe<Alert>;
  /** insert data into the table: "BillableMetric" */
  insert_BillableMetric: Maybe<BillableMetric_Mutation_Response>;
  /** insert data into the table: "BillableMetricManagedField" */
  insert_BillableMetricManagedField: Maybe<BillableMetricManagedField_Mutation_Response>;
  /** insert a single row into the table: "BillableMetricManagedField" */
  insert_BillableMetricManagedField_one: Maybe<BillableMetricManagedField>;
  /** insert a single row into the table: "BillableMetric" */
  insert_BillableMetric_one: Maybe<BillableMetric>;
  /** insert data into the table: "BillingFrequencyEnum" */
  insert_BillingFrequencyEnum: Maybe<BillingFrequencyEnum_Mutation_Response>;
  /** insert a single row into the table: "BillingFrequencyEnum" */
  insert_BillingFrequencyEnum_one: Maybe<BillingFrequencyEnum>;
  /** insert data into the table: "BillingMetricAggregateEnum" */
  insert_BillingMetricAggregateEnum: Maybe<BillingMetricAggregateEnum_Mutation_Response>;
  /** insert a single row into the table: "BillingMetricAggregateEnum" */
  insert_BillingMetricAggregateEnum_one: Maybe<BillingMetricAggregateEnum>;
  /** insert data into the table: "BillingProviderCustomer" */
  insert_BillingProviderCustomer: Maybe<BillingProviderCustomer_Mutation_Response>;
  /** insert a single row into the table: "BillingProviderCustomer" */
  insert_BillingProviderCustomer_one: Maybe<BillingProviderCustomer>;
  /** insert data into the table: "BillingProviderEnum" */
  insert_BillingProviderEnum: Maybe<BillingProviderEnum_Mutation_Response>;
  /** insert a single row into the table: "BillingProviderEnum" */
  insert_BillingProviderEnum_one: Maybe<BillingProviderEnum>;
  /** insert data into the table: "BillingProviderInvoice" */
  insert_BillingProviderInvoice: Maybe<BillingProviderInvoice_Mutation_Response>;
  /** insert a single row into the table: "BillingProviderInvoice" */
  insert_BillingProviderInvoice_one: Maybe<BillingProviderInvoice>;
  /** insert data into the table: "BillingProviderToken" */
  insert_BillingProviderToken: Maybe<BillingProviderToken_Mutation_Response>;
  /** insert a single row into the table: "BillingProviderToken" */
  insert_BillingProviderToken_one: Maybe<BillingProviderToken>;
  /** insert data into the table: "ChargeManagedField" */
  insert_ChargeManagedField: Maybe<ChargeManagedField_Mutation_Response>;
  /** insert a single row into the table: "ChargeManagedField" */
  insert_ChargeManagedField_one: Maybe<ChargeManagedField>;
  /** insert data into the table: "ChargeTypeEnum" */
  insert_ChargeTypeEnum: Maybe<ChargeTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "ChargeTypeEnum" */
  insert_ChargeTypeEnum_one: Maybe<ChargeTypeEnum>;
  /** insert data into the table: "Client" */
  insert_Client: Maybe<Client_Mutation_Response>;
  /** insert data into the table: "ClientConfig" */
  insert_ClientConfig: Maybe<ClientConfig_Mutation_Response>;
  /** insert data into the table: "ClientConfigKeyEnum" */
  insert_ClientConfigKeyEnum: Maybe<ClientConfigKeyEnum_Mutation_Response>;
  /** insert a single row into the table: "ClientConfigKeyEnum" */
  insert_ClientConfigKeyEnum_one: Maybe<ClientConfigKeyEnum>;
  /** insert a single row into the table: "ClientConfig" */
  insert_ClientConfig_one: Maybe<ClientConfig>;
  /** insert a single row into the table: "Client" */
  insert_Client_one: Maybe<Client>;
  /** insert data into the table: "CollectionScheduleEnum" */
  insert_CollectionScheduleEnum: Maybe<CollectionScheduleEnum_Mutation_Response>;
  /** insert a single row into the table: "CollectionScheduleEnum" */
  insert_CollectionScheduleEnum_one: Maybe<CollectionScheduleEnum>;
  /** insert data into the table: "CommitManagedField" */
  insert_CommitManagedField: Maybe<CommitManagedField_Mutation_Response>;
  /** insert a single row into the table: "CommitManagedField" */
  insert_CommitManagedField_one: Maybe<CommitManagedField>;
  /** insert data into the table: "CompositeCharge" */
  insert_CompositeCharge: Maybe<CompositeCharge_Mutation_Response>;
  /** insert data into the table: "CompositeChargeAdjustment" */
  insert_CompositeChargeAdjustment: Maybe<CompositeChargeAdjustment_Mutation_Response>;
  /** insert a single row into the table: "CompositeChargeAdjustment" */
  insert_CompositeChargeAdjustment_one: Maybe<CompositeChargeAdjustment>;
  /** insert data into the table: "CompositeChargePricingFactor" */
  insert_CompositeChargePricingFactor: Maybe<CompositeChargePricingFactor_Mutation_Response>;
  /** insert a single row into the table: "CompositeChargePricingFactor" */
  insert_CompositeChargePricingFactor_one: Maybe<CompositeChargePricingFactor>;
  /** insert data into the table: "CompositeChargeTier" */
  insert_CompositeChargeTier: Maybe<CompositeChargeTier_Mutation_Response>;
  /** insert data into the table: "CompositeChargeTierAdjustment" */
  insert_CompositeChargeTierAdjustment: Maybe<CompositeChargeTierAdjustment_Mutation_Response>;
  /** insert a single row into the table: "CompositeChargeTierAdjustment" */
  insert_CompositeChargeTierAdjustment_one: Maybe<CompositeChargeTierAdjustment>;
  /** insert a single row into the table: "CompositeChargeTier" */
  insert_CompositeChargeTier_one: Maybe<CompositeChargeTier>;
  /** insert data into the table: "CompositeChargeTypeEnum" */
  insert_CompositeChargeTypeEnum: Maybe<CompositeChargeTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "CompositeChargeTypeEnum" */
  insert_CompositeChargeTypeEnum_one: Maybe<CompositeChargeTypeEnum>;
  /** insert a single row into the table: "CompositeCharge" */
  insert_CompositeCharge_one: Maybe<CompositeCharge>;
  /** insert data into the table: "ContractCreditManagedField" */
  insert_ContractCreditManagedField: Maybe<ContractCreditManagedField_Mutation_Response>;
  /** insert a single row into the table: "ContractCreditManagedField" */
  insert_ContractCreditManagedField_one: Maybe<ContractCreditManagedField>;
  /** insert data into the table: "ContractManagedField" */
  insert_ContractManagedField: Maybe<ContractManagedField_Mutation_Response>;
  /** insert a single row into the table: "ContractManagedField" */
  insert_ContractManagedField_one: Maybe<ContractManagedField>;
  /** insert data into the table: "ContractProductManagedField" */
  insert_ContractProductManagedField: Maybe<ContractProductManagedField_Mutation_Response>;
  /** insert a single row into the table: "ContractProductManagedField" */
  insert_ContractProductManagedField_one: Maybe<ContractProductManagedField>;
  /** insert data into the table: "CreditAdjustment" */
  insert_CreditAdjustment: Maybe<CreditAdjustment_Mutation_Response>;
  /** insert a single row into the table: "CreditAdjustment" */
  insert_CreditAdjustment_one: Maybe<CreditAdjustment>;
  /** insert data into the table: "CreditGrant" */
  insert_CreditGrant: Maybe<CreditGrant_Mutation_Response>;
  /** insert data into the table: "CreditGrantDeduction" */
  insert_CreditGrantDeduction: Maybe<CreditGrantDeduction_Mutation_Response>;
  /** insert a single row into the table: "CreditGrantDeduction" */
  insert_CreditGrantDeduction_one: Maybe<CreditGrantDeduction>;
  /** insert data into the table: "CreditGrantHistory" */
  insert_CreditGrantHistory: Maybe<CreditGrantHistory_Mutation_Response>;
  /** insert a single row into the table: "CreditGrantHistory" */
  insert_CreditGrantHistory_one: Maybe<CreditGrantHistory>;
  /** insert data into the table: "CreditGrantManagedField" */
  insert_CreditGrantManagedField: Maybe<CreditGrantManagedField_Mutation_Response>;
  /** insert a single row into the table: "CreditGrantManagedField" */
  insert_CreditGrantManagedField_one: Maybe<CreditGrantManagedField>;
  /** insert a single row into the table: "CreditGrant" */
  insert_CreditGrant_one: Maybe<CreditGrant>;
  /** insert data into the table: "CreditType" */
  insert_CreditType: Maybe<CreditType_Mutation_Response>;
  /** insert data into the table: "CreditTypeConversion" */
  insert_CreditTypeConversion: Maybe<CreditTypeConversion_Mutation_Response>;
  /** insert data into the table: "CreditTypeConversionAdjustment" */
  insert_CreditTypeConversionAdjustment: Maybe<CreditTypeConversionAdjustment_Mutation_Response>;
  /** insert a single row into the table: "CreditTypeConversionAdjustment" */
  insert_CreditTypeConversionAdjustment_one: Maybe<CreditTypeConversionAdjustment>;
  /** insert a single row into the table: "CreditTypeConversion" */
  insert_CreditTypeConversion_one: Maybe<CreditTypeConversion>;
  /** insert a single row into the table: "CreditType" */
  insert_CreditType_one: Maybe<CreditType>;
  /** insert data into the table: "CustomPricing" */
  insert_CustomPricing: Maybe<CustomPricing_Mutation_Response>;
  /** insert a single row into the table: "CustomPricing" */
  insert_CustomPricing_one: Maybe<CustomPricing>;
  /** insert data into the table: "Customer" */
  insert_Customer: Maybe<Customer_Mutation_Response>;
  /** insert data into the table: "CustomerAlert" */
  insert_CustomerAlert: Maybe<CustomerAlert_Mutation_Response>;
  /** insert data into the table: "CustomerAlertHistory" */
  insert_CustomerAlertHistory: Maybe<CustomerAlertHistory_Mutation_Response>;
  /** insert a single row into the table: "CustomerAlertHistory" */
  insert_CustomerAlertHistory_one: Maybe<CustomerAlertHistory>;
  /** insert data into the table: "CustomerAlertStatusEnum" */
  insert_CustomerAlertStatusEnum: Maybe<CustomerAlertStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "CustomerAlertStatusEnum" */
  insert_CustomerAlertStatusEnum_one: Maybe<CustomerAlertStatusEnum>;
  /** insert a single row into the table: "CustomerAlert" */
  insert_CustomerAlert_one: Maybe<CustomerAlert>;
  /** insert data into the table: "CustomerConfig" */
  insert_CustomerConfig: Maybe<CustomerConfig_Mutation_Response>;
  /** insert a single row into the table: "CustomerConfig" */
  insert_CustomerConfig_one: Maybe<CustomerConfig>;
  /** insert data into the table: "CustomerIngestAlias" */
  insert_CustomerIngestAlias: Maybe<CustomerIngestAlias_Mutation_Response>;
  /** insert a single row into the table: "CustomerIngestAlias" */
  insert_CustomerIngestAlias_one: Maybe<CustomerIngestAlias>;
  /** insert data into the table: "CustomerManagedField" */
  insert_CustomerManagedField: Maybe<CustomerManagedField_Mutation_Response>;
  /** insert a single row into the table: "CustomerManagedField" */
  insert_CustomerManagedField_one: Maybe<CustomerManagedField>;
  /** insert data into the table: "CustomerPlan" */
  insert_CustomerPlan: Maybe<CustomerPlan_Mutation_Response>;
  /** insert data into the table: "CustomerPlanAdjustment" */
  insert_CustomerPlanAdjustment: Maybe<CustomerPlanAdjustment_Mutation_Response>;
  /** insert a single row into the table: "CustomerPlanAdjustment" */
  insert_CustomerPlanAdjustment_one: Maybe<CustomerPlanAdjustment>;
  /** insert data into the table: "CustomerPlanManagedField" */
  insert_CustomerPlanManagedField: Maybe<CustomerPlanManagedField_Mutation_Response>;
  /** insert a single row into the table: "CustomerPlanManagedField" */
  insert_CustomerPlanManagedField_one: Maybe<CustomerPlanManagedField>;
  /** insert data into the table: "CustomerPlanOneTimeCharge" */
  insert_CustomerPlanOneTimeCharge: Maybe<CustomerPlanOneTimeCharge_Mutation_Response>;
  /** insert a single row into the table: "CustomerPlanOneTimeCharge" */
  insert_CustomerPlanOneTimeCharge_one: Maybe<CustomerPlanOneTimeCharge>;
  /** insert a single row into the table: "CustomerPlan" */
  insert_CustomerPlan_one: Maybe<CustomerPlan>;
  /** insert data into the table: "CustomerRelationship" */
  insert_CustomerRelationship: Maybe<CustomerRelationship_Mutation_Response>;
  /** insert a single row into the table: "CustomerRelationship" */
  insert_CustomerRelationship_one: Maybe<CustomerRelationship>;
  /** insert a single row into the table: "Customer" */
  insert_Customer_one: Maybe<Customer>;
  /** insert data into the table: "DraftPlan" */
  insert_DraftPlan: Maybe<DraftPlan_Mutation_Response>;
  /** insert a single row into the table: "DraftPlan" */
  insert_DraftPlan_one: Maybe<DraftPlan>;
  /** insert data into the table: "EnvironmentTypeEnum" */
  insert_EnvironmentTypeEnum: Maybe<EnvironmentTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "EnvironmentTypeEnum" */
  insert_EnvironmentTypeEnum_one: Maybe<EnvironmentTypeEnum>;
  /** insert data into the table: "FlatFee" */
  insert_FlatFee: Maybe<FlatFee_Mutation_Response>;
  /** insert data into the table: "FlatFeeAdjustment" */
  insert_FlatFeeAdjustment: Maybe<FlatFeeAdjustment_Mutation_Response>;
  /** insert a single row into the table: "FlatFeeAdjustment" */
  insert_FlatFeeAdjustment_one: Maybe<FlatFeeAdjustment>;
  /** insert a single row into the table: "FlatFee" */
  insert_FlatFee_one: Maybe<FlatFee>;
  /** insert data into the table: "InvoiceExternalStatusEnum" */
  insert_InvoiceExternalStatusEnum: Maybe<InvoiceExternalStatusEnum_Mutation_Response>;
  /** insert a single row into the table: "InvoiceExternalStatusEnum" */
  insert_InvoiceExternalStatusEnum_one: Maybe<InvoiceExternalStatusEnum>;
  /** insert data into the table: "InvoiceManagedField" */
  insert_InvoiceManagedField: Maybe<InvoiceManagedField_Mutation_Response>;
  /** insert a single row into the table: "InvoiceManagedField" */
  insert_InvoiceManagedField_one: Maybe<InvoiceManagedField>;
  /** insert data into the table: "ManagedEntityEnum" */
  insert_ManagedEntityEnum: Maybe<ManagedEntityEnum_Mutation_Response>;
  /** insert a single row into the table: "ManagedEntityEnum" */
  insert_ManagedEntityEnum_one: Maybe<ManagedEntityEnum>;
  /** insert data into the table: "ManagedFieldKey" */
  insert_ManagedFieldKey: Maybe<ManagedFieldKey_Mutation_Response>;
  /** insert a single row into the table: "ManagedFieldKey" */
  insert_ManagedFieldKey_one: Maybe<ManagedFieldKey>;
  /** insert data into the table: "ManagedFieldVendorMapping" */
  insert_ManagedFieldVendorMapping: Maybe<ManagedFieldVendorMapping_Mutation_Response>;
  /** insert a single row into the table: "ManagedFieldVendorMapping" */
  insert_ManagedFieldVendorMapping_one: Maybe<ManagedFieldVendorMapping>;
  /** insert data into the table: "ManagedVendorEnum" */
  insert_ManagedVendorEnum: Maybe<ManagedVendorEnum_Mutation_Response>;
  /** insert a single row into the table: "ManagedVendorEnum" */
  insert_ManagedVendorEnum_one: Maybe<ManagedVendorEnum>;
  /** insert data into the table: "Minimum" */
  insert_Minimum: Maybe<Minimum_Mutation_Response>;
  /** insert a single row into the table: "Minimum" */
  insert_Minimum_one: Maybe<Minimum>;
  /** insert data into the table: "Plan" */
  insert_Plan: Maybe<Plan_Mutation_Response>;
  /** insert data into the table: "PlanManagedField" */
  insert_PlanManagedField: Maybe<PlanManagedField_Mutation_Response>;
  /** insert a single row into the table: "PlanManagedField" */
  insert_PlanManagedField_one: Maybe<PlanManagedField>;
  /** insert a single row into the table: "Plan" */
  insert_Plan_one: Maybe<Plan>;
  /** insert data into the table: "Price" */
  insert_Price: Maybe<Price_Mutation_Response>;
  /** insert data into the table: "PriceAdjustment" */
  insert_PriceAdjustment: Maybe<PriceAdjustment_Mutation_Response>;
  /** insert data into the table: "PriceAdjustmentTypeEnum" */
  insert_PriceAdjustmentTypeEnum: Maybe<PriceAdjustmentTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "PriceAdjustmentTypeEnum" */
  insert_PriceAdjustmentTypeEnum_one: Maybe<PriceAdjustmentTypeEnum>;
  /** insert a single row into the table: "PriceAdjustment" */
  insert_PriceAdjustment_one: Maybe<PriceAdjustment>;
  /** insert a single row into the table: "Price" */
  insert_Price_one: Maybe<Price>;
  /** insert data into the table: "PricedProduct" */
  insert_PricedProduct: Maybe<PricedProduct_Mutation_Response>;
  /** insert data into the table: "PricedProductAdjustment" */
  insert_PricedProductAdjustment: Maybe<PricedProductAdjustment_Mutation_Response>;
  /** insert a single row into the table: "PricedProductAdjustment" */
  insert_PricedProductAdjustment_one: Maybe<PricedProductAdjustment>;
  /** insert data into the table: "PricedProductPricingFactor" */
  insert_PricedProductPricingFactor: Maybe<PricedProductPricingFactor_Mutation_Response>;
  /** insert data into the table: "PricedProductPricingFactorAdjustment" */
  insert_PricedProductPricingFactorAdjustment: Maybe<PricedProductPricingFactorAdjustment_Mutation_Response>;
  /** insert a single row into the table: "PricedProductPricingFactorAdjustment" */
  insert_PricedProductPricingFactorAdjustment_one: Maybe<PricedProductPricingFactorAdjustment>;
  /** insert a single row into the table: "PricedProductPricingFactor" */
  insert_PricedProductPricingFactor_one: Maybe<PricedProductPricingFactor>;
  /** insert a single row into the table: "PricedProduct" */
  insert_PricedProduct_one: Maybe<PricedProduct>;
  /** insert data into the table: "ProServiceManagedField" */
  insert_ProServiceManagedField: Maybe<ProServiceManagedField_Mutation_Response>;
  /** insert a single row into the table: "ProServiceManagedField" */
  insert_ProServiceManagedField_one: Maybe<ProServiceManagedField>;
  /** insert data into the table: "Product" */
  insert_Product: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "ProductManagedField" */
  insert_ProductManagedField: Maybe<ProductManagedField_Mutation_Response>;
  /** insert a single row into the table: "ProductManagedField" */
  insert_ProductManagedField_one: Maybe<ProductManagedField>;
  /** insert data into the table: "ProductPricingFactor" */
  insert_ProductPricingFactor: Maybe<ProductPricingFactor_Mutation_Response>;
  /** insert a single row into the table: "ProductPricingFactor" */
  insert_ProductPricingFactor_one: Maybe<ProductPricingFactor>;
  /** insert a single row into the table: "Product" */
  insert_Product_one: Maybe<Product>;
  /** insert data into the table: "RateCardManagedField" */
  insert_RateCardManagedField: Maybe<RateCardManagedField_Mutation_Response>;
  /** insert a single row into the table: "RateCardManagedField" */
  insert_RateCardManagedField_one: Maybe<RateCardManagedField>;
  /** insert data into the table: "RecurringCreditGrant" */
  insert_RecurringCreditGrant: Maybe<RecurringCreditGrant_Mutation_Response>;
  /** insert a single row into the table: "RecurringCreditGrant" */
  insert_RecurringCreditGrant_one: Maybe<RecurringCreditGrant>;
  /** insert data into the table: "Role" */
  insert_Role: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "Role" */
  insert_Role_one: Maybe<Role>;
  /** insert data into the table: "RoundingBehaviorEnum" */
  insert_RoundingBehaviorEnum: Maybe<RoundingBehaviorEnum_Mutation_Response>;
  /** insert a single row into the table: "RoundingBehaviorEnum" */
  insert_RoundingBehaviorEnum_one: Maybe<RoundingBehaviorEnum>;
  /** insert data into the table: "ScheduledChargeManagedField" */
  insert_ScheduledChargeManagedField: Maybe<ScheduledChargeManagedField_Mutation_Response>;
  /** insert a single row into the table: "ScheduledChargeManagedField" */
  insert_ScheduledChargeManagedField_one: Maybe<ScheduledChargeManagedField>;
  /** insert data into the table: "ServicePeriodStartOnEnum" */
  insert_ServicePeriodStartOnEnum: Maybe<ServicePeriodStartOnEnum_Mutation_Response>;
  /** insert a single row into the table: "ServicePeriodStartOnEnum" */
  insert_ServicePeriodStartOnEnum_one: Maybe<ServicePeriodStartOnEnum>;
  /** insert data into the table: "StripePrepaidConfig_temporary" */
  insert_StripePrepaidConfig_temporary: Maybe<StripePrepaidConfig_Temporary_Mutation_Response>;
  /** insert a single row into the table: "StripePrepaidConfig_temporary" */
  insert_StripePrepaidConfig_temporary_one: Maybe<StripePrepaidConfig_Temporary>;
  /** insert data into the table: "TieringModeEnum" */
  insert_TieringModeEnum: Maybe<TieringModeEnum_Mutation_Response>;
  /** insert a single row into the table: "TieringModeEnum" */
  insert_TieringModeEnum_one: Maybe<TieringModeEnum>;
  /** insert data into the table: "Tokens" */
  insert_Tokens: Maybe<Tokens_Mutation_Response>;
  /** insert a single row into the table: "Tokens" */
  insert_Tokens_one: Maybe<Tokens>;
  /** insert data into the table: "TrialSpec" */
  insert_TrialSpec: Maybe<TrialSpec_Mutation_Response>;
  /** insert data into the table: "TrialSpecSpendingCap" */
  insert_TrialSpecSpendingCap: Maybe<TrialSpecSpendingCap_Mutation_Response>;
  /** insert a single row into the table: "TrialSpecSpendingCap" */
  insert_TrialSpecSpendingCap_one: Maybe<TrialSpecSpendingCap>;
  /** insert a single row into the table: "TrialSpec" */
  insert_TrialSpec_one: Maybe<TrialSpec>;
  /** insert data into the table: "UserAuthTypeEnum" */
  insert_UserAuthTypeEnum: Maybe<UserAuthTypeEnum_Mutation_Response>;
  /** insert a single row into the table: "UserAuthTypeEnum" */
  insert_UserAuthTypeEnum_one: Maybe<UserAuthTypeEnum>;
  /** insert data into the table: "User" */
  insert_User_doNotUse: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "User" */
  insert_User_one_doNotUse: Maybe<User>;
  /** insert data into the table: "Webhook" */
  insert_Webhook: Maybe<Webhook_Mutation_Response>;
  /** insert data into the table: "webhook_notifications" */
  insert_WebhookNotification: Maybe<WebhookNotification_Mutation_Response>;
  /** insert a single row into the table: "webhook_notifications" */
  insert_WebhookNotification_one: Maybe<WebhookNotification>;
  /** insert a single row into the table: "Webhook" */
  insert_Webhook_one: Maybe<Webhook>;
  insert_billing_provider_delivery_method: InsertBillingProviderDeliveryMethodResult;
  insert_customer_billing_provider_configuration: InsertCustomerBillingProviderConfigurationResult;
  insert_customer_billing_provider_configuration_notification: Scalars['Boolean'];
  insert_customer_billing_provider_configurations: Array<InsertCustomerBillingProviderConfigurationResult>;
  insert_failed_marketplaces_metering_record: FailedMarketplacesMeteringRecord;
  launch_sox_report_generator: LaunchSoxReportGeneratorMutationResponse;
  migrate_plan_credits_to_contract_commits: MutationResponse;
  move_rate_card_products: RateCard;
  mri_end_customer_plan: Maybe<CustomerPlan>;
  mri_finalize_invoices_for_customer: Customer;
  /**
   * This voids both the old and new versions of the given invoice.
   * By default it invokes the heartbeat to regenerate any necessary draft invoice
   * and finalize it, but that can be suppressed with `skip_invoking_heartbeat`.
   */
  mri_void_invoice: Mri_Invoice;
  prepaid_credit_grant_recharge: PrepaidCreditGrantRechargeResponse;
  provision_salesforce_census_workspace: MutationResponse;
  rebuild_contract_billing_provider_projections: MutationResponse;
  rebuild_rate_schedules: MutationResponse;
  regenerate_invoice: Mri_Invoice;
  release_invoices_for_finalization: InvoiceFinalizationMetadata;
  release_prepaid_credit_grant: CreditGrant;
  remove_customer: MutationResponse;
  repair_credit_grant_recharge: MutationResponse;
  repair_prepaid_credit_grant: RepairPrepaidCreditGrantResponse;
  save_vendor_entity_mappings: SaveVendorEntityMappingsResponse;
  send_fake_synthetic_usage_event: UsageEventMutationResponse;
  send_invoice_to_stripe: Scalars['Boolean'];
  set_customer_ingest_aliases: Customer;
  set_customer_plan_trial: CustomerPlan;
  store_azure_marketplace_credentials: MutationResponse;
  store_client_webhook_secret: MutationResponse;
  store_stripe_credentials: MutationResponse;
  store_stripe_restricted_key: MutationResponse;
  test_webhooks: MutationResponse;
  /** update data of the table: "AccountTypeEnum" */
  update_AccountTypeEnum: Maybe<AccountTypeEnum_Mutation_Response>;
  /** update single row of the table: "AccountTypeEnum" */
  update_AccountTypeEnum_by_pk: Maybe<AccountTypeEnum>;
  /** update multiples rows of table: "AccountTypeEnum" */
  update_AccountTypeEnum_many: Maybe<Array<Maybe<AccountTypeEnum_Mutation_Response>>>;
  /** update data of the table: "Actor" */
  update_Actor: Maybe<Actor_Mutation_Response>;
  /** update single row of the table: "Actor" */
  update_Actor_by_pk: Maybe<Actor>;
  /** update multiples rows of table: "Actor" */
  update_Actor_many: Maybe<Array<Maybe<Actor_Mutation_Response>>>;
  /** update data of the table: "Alert" */
  update_Alert: Maybe<Alert_Mutation_Response>;
  /** update data of the table: "AlertManagedField" */
  update_AlertManagedField: Maybe<AlertManagedField_Mutation_Response>;
  /** update single row of the table: "AlertManagedField" */
  update_AlertManagedField_by_pk: Maybe<AlertManagedField>;
  /** update multiples rows of table: "AlertManagedField" */
  update_AlertManagedField_many: Maybe<Array<Maybe<AlertManagedField_Mutation_Response>>>;
  /** update data of the table: "AlertTypeEnum" */
  update_AlertTypeEnum: Maybe<AlertTypeEnum_Mutation_Response>;
  /** update single row of the table: "AlertTypeEnum" */
  update_AlertTypeEnum_by_pk: Maybe<AlertTypeEnum>;
  /** update multiples rows of table: "AlertTypeEnum" */
  update_AlertTypeEnum_many: Maybe<Array<Maybe<AlertTypeEnum_Mutation_Response>>>;
  /** update single row of the table: "Alert" */
  update_Alert_by_pk: Maybe<Alert>;
  /** update multiples rows of table: "Alert" */
  update_Alert_many: Maybe<Array<Maybe<Alert_Mutation_Response>>>;
  /** update data of the table: "BillableMetric" */
  update_BillableMetric: Maybe<BillableMetric_Mutation_Response>;
  /** update data of the table: "BillableMetricManagedField" */
  update_BillableMetricManagedField: Maybe<BillableMetricManagedField_Mutation_Response>;
  /** update single row of the table: "BillableMetricManagedField" */
  update_BillableMetricManagedField_by_pk: Maybe<BillableMetricManagedField>;
  /** update multiples rows of table: "BillableMetricManagedField" */
  update_BillableMetricManagedField_many: Maybe<Array<Maybe<BillableMetricManagedField_Mutation_Response>>>;
  /** update single row of the table: "BillableMetric" */
  update_BillableMetric_by_pk: Maybe<BillableMetric>;
  /** update multiples rows of table: "BillableMetric" */
  update_BillableMetric_many: Maybe<Array<Maybe<BillableMetric_Mutation_Response>>>;
  /** update data of the table: "BillingFrequencyEnum" */
  update_BillingFrequencyEnum: Maybe<BillingFrequencyEnum_Mutation_Response>;
  /** update single row of the table: "BillingFrequencyEnum" */
  update_BillingFrequencyEnum_by_pk: Maybe<BillingFrequencyEnum>;
  /** update multiples rows of table: "BillingFrequencyEnum" */
  update_BillingFrequencyEnum_many: Maybe<Array<Maybe<BillingFrequencyEnum_Mutation_Response>>>;
  /** update data of the table: "BillingMetricAggregateEnum" */
  update_BillingMetricAggregateEnum: Maybe<BillingMetricAggregateEnum_Mutation_Response>;
  /** update single row of the table: "BillingMetricAggregateEnum" */
  update_BillingMetricAggregateEnum_by_pk: Maybe<BillingMetricAggregateEnum>;
  /** update multiples rows of table: "BillingMetricAggregateEnum" */
  update_BillingMetricAggregateEnum_many: Maybe<Array<Maybe<BillingMetricAggregateEnum_Mutation_Response>>>;
  /** update data of the table: "BillingProviderCustomer" */
  update_BillingProviderCustomer: Maybe<BillingProviderCustomer_Mutation_Response>;
  /** update single row of the table: "BillingProviderCustomer" */
  update_BillingProviderCustomer_by_pk: Maybe<BillingProviderCustomer>;
  /** update multiples rows of table: "BillingProviderCustomer" */
  update_BillingProviderCustomer_many: Maybe<Array<Maybe<BillingProviderCustomer_Mutation_Response>>>;
  /** update data of the table: "BillingProviderEnum" */
  update_BillingProviderEnum: Maybe<BillingProviderEnum_Mutation_Response>;
  /** update single row of the table: "BillingProviderEnum" */
  update_BillingProviderEnum_by_pk: Maybe<BillingProviderEnum>;
  /** update multiples rows of table: "BillingProviderEnum" */
  update_BillingProviderEnum_many: Maybe<Array<Maybe<BillingProviderEnum_Mutation_Response>>>;
  /** update data of the table: "BillingProviderInvoice" */
  update_BillingProviderInvoice: Maybe<BillingProviderInvoice_Mutation_Response>;
  /** update single row of the table: "BillingProviderInvoice" */
  update_BillingProviderInvoice_by_pk: Maybe<BillingProviderInvoice>;
  /** update multiples rows of table: "BillingProviderInvoice" */
  update_BillingProviderInvoice_many: Maybe<Array<Maybe<BillingProviderInvoice_Mutation_Response>>>;
  /** update data of the table: "BillingProviderToken" */
  update_BillingProviderToken: Maybe<BillingProviderToken_Mutation_Response>;
  /** update single row of the table: "BillingProviderToken" */
  update_BillingProviderToken_by_pk: Maybe<BillingProviderToken>;
  /** update multiples rows of table: "BillingProviderToken" */
  update_BillingProviderToken_many: Maybe<Array<Maybe<BillingProviderToken_Mutation_Response>>>;
  /** update data of the table: "ChargeManagedField" */
  update_ChargeManagedField: Maybe<ChargeManagedField_Mutation_Response>;
  /** update single row of the table: "ChargeManagedField" */
  update_ChargeManagedField_by_pk: Maybe<ChargeManagedField>;
  /** update multiples rows of table: "ChargeManagedField" */
  update_ChargeManagedField_many: Maybe<Array<Maybe<ChargeManagedField_Mutation_Response>>>;
  /** update data of the table: "ChargeTypeEnum" */
  update_ChargeTypeEnum: Maybe<ChargeTypeEnum_Mutation_Response>;
  /** update single row of the table: "ChargeTypeEnum" */
  update_ChargeTypeEnum_by_pk: Maybe<ChargeTypeEnum>;
  /** update multiples rows of table: "ChargeTypeEnum" */
  update_ChargeTypeEnum_many: Maybe<Array<Maybe<ChargeTypeEnum_Mutation_Response>>>;
  /** update data of the table: "Client" */
  update_Client: Maybe<Client_Mutation_Response>;
  /** update data of the table: "ClientConfig" */
  update_ClientConfig: Maybe<ClientConfig_Mutation_Response>;
  /** update data of the table: "ClientConfigKeyEnum" */
  update_ClientConfigKeyEnum: Maybe<ClientConfigKeyEnum_Mutation_Response>;
  /** update single row of the table: "ClientConfigKeyEnum" */
  update_ClientConfigKeyEnum_by_pk: Maybe<ClientConfigKeyEnum>;
  /** update multiples rows of table: "ClientConfigKeyEnum" */
  update_ClientConfigKeyEnum_many: Maybe<Array<Maybe<ClientConfigKeyEnum_Mutation_Response>>>;
  /** update single row of the table: "ClientConfig" */
  update_ClientConfig_by_pk: Maybe<ClientConfig>;
  /** update multiples rows of table: "ClientConfig" */
  update_ClientConfig_many: Maybe<Array<Maybe<ClientConfig_Mutation_Response>>>;
  /** update single row of the table: "Client" */
  update_Client_by_pk: Maybe<Client>;
  /** update multiples rows of table: "Client" */
  update_Client_many: Maybe<Array<Maybe<Client_Mutation_Response>>>;
  /** update data of the table: "CollectionScheduleEnum" */
  update_CollectionScheduleEnum: Maybe<CollectionScheduleEnum_Mutation_Response>;
  /** update single row of the table: "CollectionScheduleEnum" */
  update_CollectionScheduleEnum_by_pk: Maybe<CollectionScheduleEnum>;
  /** update multiples rows of table: "CollectionScheduleEnum" */
  update_CollectionScheduleEnum_many: Maybe<Array<Maybe<CollectionScheduleEnum_Mutation_Response>>>;
  /** update data of the table: "CommitManagedField" */
  update_CommitManagedField: Maybe<CommitManagedField_Mutation_Response>;
  /** update single row of the table: "CommitManagedField" */
  update_CommitManagedField_by_pk: Maybe<CommitManagedField>;
  /** update multiples rows of table: "CommitManagedField" */
  update_CommitManagedField_many: Maybe<Array<Maybe<CommitManagedField_Mutation_Response>>>;
  /** update data of the table: "CompositeCharge" */
  update_CompositeCharge: Maybe<CompositeCharge_Mutation_Response>;
  /** update data of the table: "CompositeChargeAdjustment" */
  update_CompositeChargeAdjustment: Maybe<CompositeChargeAdjustment_Mutation_Response>;
  /** update single row of the table: "CompositeChargeAdjustment" */
  update_CompositeChargeAdjustment_by_pk: Maybe<CompositeChargeAdjustment>;
  /** update multiples rows of table: "CompositeChargeAdjustment" */
  update_CompositeChargeAdjustment_many: Maybe<Array<Maybe<CompositeChargeAdjustment_Mutation_Response>>>;
  /** update data of the table: "CompositeChargePricingFactor" */
  update_CompositeChargePricingFactor: Maybe<CompositeChargePricingFactor_Mutation_Response>;
  /** update multiples rows of table: "CompositeChargePricingFactor" */
  update_CompositeChargePricingFactor_many: Maybe<Array<Maybe<CompositeChargePricingFactor_Mutation_Response>>>;
  /** update data of the table: "CompositeChargeTier" */
  update_CompositeChargeTier: Maybe<CompositeChargeTier_Mutation_Response>;
  /** update data of the table: "CompositeChargeTierAdjustment" */
  update_CompositeChargeTierAdjustment: Maybe<CompositeChargeTierAdjustment_Mutation_Response>;
  /** update single row of the table: "CompositeChargeTierAdjustment" */
  update_CompositeChargeTierAdjustment_by_pk: Maybe<CompositeChargeTierAdjustment>;
  /** update multiples rows of table: "CompositeChargeTierAdjustment" */
  update_CompositeChargeTierAdjustment_many: Maybe<Array<Maybe<CompositeChargeTierAdjustment_Mutation_Response>>>;
  /** update single row of the table: "CompositeChargeTier" */
  update_CompositeChargeTier_by_pk: Maybe<CompositeChargeTier>;
  /** update multiples rows of table: "CompositeChargeTier" */
  update_CompositeChargeTier_many: Maybe<Array<Maybe<CompositeChargeTier_Mutation_Response>>>;
  /** update data of the table: "CompositeChargeTypeEnum" */
  update_CompositeChargeTypeEnum: Maybe<CompositeChargeTypeEnum_Mutation_Response>;
  /** update single row of the table: "CompositeChargeTypeEnum" */
  update_CompositeChargeTypeEnum_by_pk: Maybe<CompositeChargeTypeEnum>;
  /** update multiples rows of table: "CompositeChargeTypeEnum" */
  update_CompositeChargeTypeEnum_many: Maybe<Array<Maybe<CompositeChargeTypeEnum_Mutation_Response>>>;
  /** update single row of the table: "CompositeCharge" */
  update_CompositeCharge_by_pk: Maybe<CompositeCharge>;
  /** update multiples rows of table: "CompositeCharge" */
  update_CompositeCharge_many: Maybe<Array<Maybe<CompositeCharge_Mutation_Response>>>;
  /** update data of the table: "ContractCreditManagedField" */
  update_ContractCreditManagedField: Maybe<ContractCreditManagedField_Mutation_Response>;
  /** update single row of the table: "ContractCreditManagedField" */
  update_ContractCreditManagedField_by_pk: Maybe<ContractCreditManagedField>;
  /** update multiples rows of table: "ContractCreditManagedField" */
  update_ContractCreditManagedField_many: Maybe<Array<Maybe<ContractCreditManagedField_Mutation_Response>>>;
  /** update data of the table: "ContractManagedField" */
  update_ContractManagedField: Maybe<ContractManagedField_Mutation_Response>;
  /** update single row of the table: "ContractManagedField" */
  update_ContractManagedField_by_pk: Maybe<ContractManagedField>;
  /** update multiples rows of table: "ContractManagedField" */
  update_ContractManagedField_many: Maybe<Array<Maybe<ContractManagedField_Mutation_Response>>>;
  /** update data of the table: "ContractProductManagedField" */
  update_ContractProductManagedField: Maybe<ContractProductManagedField_Mutation_Response>;
  /** update single row of the table: "ContractProductManagedField" */
  update_ContractProductManagedField_by_pk: Maybe<ContractProductManagedField>;
  /** update multiples rows of table: "ContractProductManagedField" */
  update_ContractProductManagedField_many: Maybe<Array<Maybe<ContractProductManagedField_Mutation_Response>>>;
  /** update data of the table: "CreditAdjustment" */
  update_CreditAdjustment: Maybe<CreditAdjustment_Mutation_Response>;
  /** update single row of the table: "CreditAdjustment" */
  update_CreditAdjustment_by_pk: Maybe<CreditAdjustment>;
  /** update multiples rows of table: "CreditAdjustment" */
  update_CreditAdjustment_many: Maybe<Array<Maybe<CreditAdjustment_Mutation_Response>>>;
  /** update data of the table: "CreditGrant" */
  update_CreditGrant: Maybe<CreditGrant_Mutation_Response>;
  /** update data of the table: "CreditGrantDeduction" */
  update_CreditGrantDeduction: Maybe<CreditGrantDeduction_Mutation_Response>;
  /** update single row of the table: "CreditGrantDeduction" */
  update_CreditGrantDeduction_by_pk: Maybe<CreditGrantDeduction>;
  /** update multiples rows of table: "CreditGrantDeduction" */
  update_CreditGrantDeduction_many: Maybe<Array<Maybe<CreditGrantDeduction_Mutation_Response>>>;
  /** update data of the table: "CreditGrantHistory" */
  update_CreditGrantHistory: Maybe<CreditGrantHistory_Mutation_Response>;
  /** update single row of the table: "CreditGrantHistory" */
  update_CreditGrantHistory_by_pk: Maybe<CreditGrantHistory>;
  /** update multiples rows of table: "CreditGrantHistory" */
  update_CreditGrantHistory_many: Maybe<Array<Maybe<CreditGrantHistory_Mutation_Response>>>;
  /** update data of the table: "CreditGrantManagedField" */
  update_CreditGrantManagedField: Maybe<CreditGrantManagedField_Mutation_Response>;
  /** update single row of the table: "CreditGrantManagedField" */
  update_CreditGrantManagedField_by_pk: Maybe<CreditGrantManagedField>;
  /** update multiples rows of table: "CreditGrantManagedField" */
  update_CreditGrantManagedField_many: Maybe<Array<Maybe<CreditGrantManagedField_Mutation_Response>>>;
  /** update single row of the table: "CreditGrant" */
  update_CreditGrant_by_pk: Maybe<CreditGrant>;
  /** update multiples rows of table: "CreditGrant" */
  update_CreditGrant_many: Maybe<Array<Maybe<CreditGrant_Mutation_Response>>>;
  /** update data of the table: "CreditType" */
  update_CreditType: Maybe<CreditType_Mutation_Response>;
  /** update data of the table: "CreditTypeConversion" */
  update_CreditTypeConversion: Maybe<CreditTypeConversion_Mutation_Response>;
  /** update data of the table: "CreditTypeConversionAdjustment" */
  update_CreditTypeConversionAdjustment: Maybe<CreditTypeConversionAdjustment_Mutation_Response>;
  /** update single row of the table: "CreditTypeConversionAdjustment" */
  update_CreditTypeConversionAdjustment_by_pk: Maybe<CreditTypeConversionAdjustment>;
  /** update multiples rows of table: "CreditTypeConversionAdjustment" */
  update_CreditTypeConversionAdjustment_many: Maybe<Array<Maybe<CreditTypeConversionAdjustment_Mutation_Response>>>;
  /** update single row of the table: "CreditTypeConversion" */
  update_CreditTypeConversion_by_pk: Maybe<CreditTypeConversion>;
  /** update multiples rows of table: "CreditTypeConversion" */
  update_CreditTypeConversion_many: Maybe<Array<Maybe<CreditTypeConversion_Mutation_Response>>>;
  /** update single row of the table: "CreditType" */
  update_CreditType_by_pk: Maybe<CreditType>;
  /** update multiples rows of table: "CreditType" */
  update_CreditType_many: Maybe<Array<Maybe<CreditType_Mutation_Response>>>;
  /** update data of the table: "CustomPricing" */
  update_CustomPricing: Maybe<CustomPricing_Mutation_Response>;
  /** update single row of the table: "CustomPricing" */
  update_CustomPricing_by_pk: Maybe<CustomPricing>;
  /** update multiples rows of table: "CustomPricing" */
  update_CustomPricing_many: Maybe<Array<Maybe<CustomPricing_Mutation_Response>>>;
  /** update data of the table: "Customer" */
  update_Customer: Maybe<Customer_Mutation_Response>;
  /** update data of the table: "CustomerAlert" */
  update_CustomerAlert: Maybe<CustomerAlert_Mutation_Response>;
  /** update data of the table: "CustomerAlertHistory" */
  update_CustomerAlertHistory: Maybe<CustomerAlertHistory_Mutation_Response>;
  /** update single row of the table: "CustomerAlertHistory" */
  update_CustomerAlertHistory_by_pk: Maybe<CustomerAlertHistory>;
  /** update multiples rows of table: "CustomerAlertHistory" */
  update_CustomerAlertHistory_many: Maybe<Array<Maybe<CustomerAlertHistory_Mutation_Response>>>;
  /** update data of the table: "CustomerAlertStatusEnum" */
  update_CustomerAlertStatusEnum: Maybe<CustomerAlertStatusEnum_Mutation_Response>;
  /** update single row of the table: "CustomerAlertStatusEnum" */
  update_CustomerAlertStatusEnum_by_pk: Maybe<CustomerAlertStatusEnum>;
  /** update multiples rows of table: "CustomerAlertStatusEnum" */
  update_CustomerAlertStatusEnum_many: Maybe<Array<Maybe<CustomerAlertStatusEnum_Mutation_Response>>>;
  /** update single row of the table: "CustomerAlert" */
  update_CustomerAlert_by_pk: Maybe<CustomerAlert>;
  /** update multiples rows of table: "CustomerAlert" */
  update_CustomerAlert_many: Maybe<Array<Maybe<CustomerAlert_Mutation_Response>>>;
  /** update data of the table: "CustomerConfig" */
  update_CustomerConfig: Maybe<CustomerConfig_Mutation_Response>;
  /** update single row of the table: "CustomerConfig" */
  update_CustomerConfig_by_pk: Maybe<CustomerConfig>;
  /** update multiples rows of table: "CustomerConfig" */
  update_CustomerConfig_many: Maybe<Array<Maybe<CustomerConfig_Mutation_Response>>>;
  /** update data of the table: "CustomerIngestAlias" */
  update_CustomerIngestAlias: Maybe<CustomerIngestAlias_Mutation_Response>;
  /** update single row of the table: "CustomerIngestAlias" */
  update_CustomerIngestAlias_by_pk: Maybe<CustomerIngestAlias>;
  /** update multiples rows of table: "CustomerIngestAlias" */
  update_CustomerIngestAlias_many: Maybe<Array<Maybe<CustomerIngestAlias_Mutation_Response>>>;
  /** update data of the table: "CustomerManagedField" */
  update_CustomerManagedField: Maybe<CustomerManagedField_Mutation_Response>;
  /** update single row of the table: "CustomerManagedField" */
  update_CustomerManagedField_by_pk: Maybe<CustomerManagedField>;
  /** update multiples rows of table: "CustomerManagedField" */
  update_CustomerManagedField_many: Maybe<Array<Maybe<CustomerManagedField_Mutation_Response>>>;
  /** update data of the table: "CustomerPlan" */
  update_CustomerPlan: Maybe<CustomerPlan_Mutation_Response>;
  /** update data of the table: "CustomerPlanAdjustment" */
  update_CustomerPlanAdjustment: Maybe<CustomerPlanAdjustment_Mutation_Response>;
  /** update single row of the table: "CustomerPlanAdjustment" */
  update_CustomerPlanAdjustment_by_pk: Maybe<CustomerPlanAdjustment>;
  /** update multiples rows of table: "CustomerPlanAdjustment" */
  update_CustomerPlanAdjustment_many: Maybe<Array<Maybe<CustomerPlanAdjustment_Mutation_Response>>>;
  /** update data of the table: "CustomerPlanManagedField" */
  update_CustomerPlanManagedField: Maybe<CustomerPlanManagedField_Mutation_Response>;
  /** update single row of the table: "CustomerPlanManagedField" */
  update_CustomerPlanManagedField_by_pk: Maybe<CustomerPlanManagedField>;
  /** update multiples rows of table: "CustomerPlanManagedField" */
  update_CustomerPlanManagedField_many: Maybe<Array<Maybe<CustomerPlanManagedField_Mutation_Response>>>;
  /** update data of the table: "CustomerPlanOneTimeCharge" */
  update_CustomerPlanOneTimeCharge: Maybe<CustomerPlanOneTimeCharge_Mutation_Response>;
  /** update single row of the table: "CustomerPlanOneTimeCharge" */
  update_CustomerPlanOneTimeCharge_by_pk: Maybe<CustomerPlanOneTimeCharge>;
  /** update multiples rows of table: "CustomerPlanOneTimeCharge" */
  update_CustomerPlanOneTimeCharge_many: Maybe<Array<Maybe<CustomerPlanOneTimeCharge_Mutation_Response>>>;
  /** update single row of the table: "CustomerPlan" */
  update_CustomerPlan_by_pk: Maybe<CustomerPlan>;
  /** update multiples rows of table: "CustomerPlan" */
  update_CustomerPlan_many: Maybe<Array<Maybe<CustomerPlan_Mutation_Response>>>;
  /** update data of the table: "CustomerRelationship" */
  update_CustomerRelationship: Maybe<CustomerRelationship_Mutation_Response>;
  /** update single row of the table: "CustomerRelationship" */
  update_CustomerRelationship_by_pk: Maybe<CustomerRelationship>;
  /** update multiples rows of table: "CustomerRelationship" */
  update_CustomerRelationship_many: Maybe<Array<Maybe<CustomerRelationship_Mutation_Response>>>;
  /** update single row of the table: "Customer" */
  update_Customer_by_pk: Maybe<Customer>;
  /** update multiples rows of table: "Customer" */
  update_Customer_many: Maybe<Array<Maybe<Customer_Mutation_Response>>>;
  /** update data of the table: "DraftPlan" */
  update_DraftPlan: Maybe<DraftPlan_Mutation_Response>;
  /** update single row of the table: "DraftPlan" */
  update_DraftPlan_by_pk: Maybe<DraftPlan>;
  /** update multiples rows of table: "DraftPlan" */
  update_DraftPlan_many: Maybe<Array<Maybe<DraftPlan_Mutation_Response>>>;
  /** update data of the table: "EnvironmentTypeEnum" */
  update_EnvironmentTypeEnum: Maybe<EnvironmentTypeEnum_Mutation_Response>;
  /** update single row of the table: "EnvironmentTypeEnum" */
  update_EnvironmentTypeEnum_by_pk: Maybe<EnvironmentTypeEnum>;
  /** update multiples rows of table: "EnvironmentTypeEnum" */
  update_EnvironmentTypeEnum_many: Maybe<Array<Maybe<EnvironmentTypeEnum_Mutation_Response>>>;
  /** update data of the table: "FlatFee" */
  update_FlatFee: Maybe<FlatFee_Mutation_Response>;
  /** update data of the table: "FlatFeeAdjustment" */
  update_FlatFeeAdjustment: Maybe<FlatFeeAdjustment_Mutation_Response>;
  /** update single row of the table: "FlatFeeAdjustment" */
  update_FlatFeeAdjustment_by_pk: Maybe<FlatFeeAdjustment>;
  /** update multiples rows of table: "FlatFeeAdjustment" */
  update_FlatFeeAdjustment_many: Maybe<Array<Maybe<FlatFeeAdjustment_Mutation_Response>>>;
  /** update single row of the table: "FlatFee" */
  update_FlatFee_by_pk: Maybe<FlatFee>;
  /** update multiples rows of table: "FlatFee" */
  update_FlatFee_many: Maybe<Array<Maybe<FlatFee_Mutation_Response>>>;
  /** update data of the table: "InvoiceExternalStatusEnum" */
  update_InvoiceExternalStatusEnum: Maybe<InvoiceExternalStatusEnum_Mutation_Response>;
  /** update single row of the table: "InvoiceExternalStatusEnum" */
  update_InvoiceExternalStatusEnum_by_pk: Maybe<InvoiceExternalStatusEnum>;
  /** update multiples rows of table: "InvoiceExternalStatusEnum" */
  update_InvoiceExternalStatusEnum_many: Maybe<Array<Maybe<InvoiceExternalStatusEnum_Mutation_Response>>>;
  /** update data of the table: "InvoiceManagedField" */
  update_InvoiceManagedField: Maybe<InvoiceManagedField_Mutation_Response>;
  /** update single row of the table: "InvoiceManagedField" */
  update_InvoiceManagedField_by_pk: Maybe<InvoiceManagedField>;
  /** update multiples rows of table: "InvoiceManagedField" */
  update_InvoiceManagedField_many: Maybe<Array<Maybe<InvoiceManagedField_Mutation_Response>>>;
  /** update data of the table: "ManagedEntityEnum" */
  update_ManagedEntityEnum: Maybe<ManagedEntityEnum_Mutation_Response>;
  /** update single row of the table: "ManagedEntityEnum" */
  update_ManagedEntityEnum_by_pk: Maybe<ManagedEntityEnum>;
  /** update multiples rows of table: "ManagedEntityEnum" */
  update_ManagedEntityEnum_many: Maybe<Array<Maybe<ManagedEntityEnum_Mutation_Response>>>;
  /** update data of the table: "ManagedFieldKey" */
  update_ManagedFieldKey: Maybe<ManagedFieldKey_Mutation_Response>;
  /** update single row of the table: "ManagedFieldKey" */
  update_ManagedFieldKey_by_pk: Maybe<ManagedFieldKey>;
  /** update multiples rows of table: "ManagedFieldKey" */
  update_ManagedFieldKey_many: Maybe<Array<Maybe<ManagedFieldKey_Mutation_Response>>>;
  /** update data of the table: "ManagedFieldVendorMapping" */
  update_ManagedFieldVendorMapping: Maybe<ManagedFieldVendorMapping_Mutation_Response>;
  /** update single row of the table: "ManagedFieldVendorMapping" */
  update_ManagedFieldVendorMapping_by_pk: Maybe<ManagedFieldVendorMapping>;
  /** update multiples rows of table: "ManagedFieldVendorMapping" */
  update_ManagedFieldVendorMapping_many: Maybe<Array<Maybe<ManagedFieldVendorMapping_Mutation_Response>>>;
  /** update data of the table: "ManagedVendorEnum" */
  update_ManagedVendorEnum: Maybe<ManagedVendorEnum_Mutation_Response>;
  /** update single row of the table: "ManagedVendorEnum" */
  update_ManagedVendorEnum_by_pk: Maybe<ManagedVendorEnum>;
  /** update multiples rows of table: "ManagedVendorEnum" */
  update_ManagedVendorEnum_many: Maybe<Array<Maybe<ManagedVendorEnum_Mutation_Response>>>;
  /** update data of the table: "Minimum" */
  update_Minimum: Maybe<Minimum_Mutation_Response>;
  /** update single row of the table: "Minimum" */
  update_Minimum_by_pk: Maybe<Minimum>;
  /** update multiples rows of table: "Minimum" */
  update_Minimum_many: Maybe<Array<Maybe<Minimum_Mutation_Response>>>;
  /** update data of the table: "Plan" */
  update_Plan: Maybe<Plan_Mutation_Response>;
  /** update data of the table: "PlanManagedField" */
  update_PlanManagedField: Maybe<PlanManagedField_Mutation_Response>;
  /** update single row of the table: "PlanManagedField" */
  update_PlanManagedField_by_pk: Maybe<PlanManagedField>;
  /** update multiples rows of table: "PlanManagedField" */
  update_PlanManagedField_many: Maybe<Array<Maybe<PlanManagedField_Mutation_Response>>>;
  /** update single row of the table: "Plan" */
  update_Plan_by_pk: Maybe<Plan>;
  /** update multiples rows of table: "Plan" */
  update_Plan_many: Maybe<Array<Maybe<Plan_Mutation_Response>>>;
  /** update data of the table: "Price" */
  update_Price: Maybe<Price_Mutation_Response>;
  /** update data of the table: "PriceAdjustment" */
  update_PriceAdjustment: Maybe<PriceAdjustment_Mutation_Response>;
  /** update data of the table: "PriceAdjustmentTypeEnum" */
  update_PriceAdjustmentTypeEnum: Maybe<PriceAdjustmentTypeEnum_Mutation_Response>;
  /** update single row of the table: "PriceAdjustmentTypeEnum" */
  update_PriceAdjustmentTypeEnum_by_pk: Maybe<PriceAdjustmentTypeEnum>;
  /** update multiples rows of table: "PriceAdjustmentTypeEnum" */
  update_PriceAdjustmentTypeEnum_many: Maybe<Array<Maybe<PriceAdjustmentTypeEnum_Mutation_Response>>>;
  /** update single row of the table: "PriceAdjustment" */
  update_PriceAdjustment_by_pk: Maybe<PriceAdjustment>;
  /** update multiples rows of table: "PriceAdjustment" */
  update_PriceAdjustment_many: Maybe<Array<Maybe<PriceAdjustment_Mutation_Response>>>;
  /** update single row of the table: "Price" */
  update_Price_by_pk: Maybe<Price>;
  /** update multiples rows of table: "Price" */
  update_Price_many: Maybe<Array<Maybe<Price_Mutation_Response>>>;
  /** update data of the table: "PricedProduct" */
  update_PricedProduct: Maybe<PricedProduct_Mutation_Response>;
  /** update data of the table: "PricedProductAdjustment" */
  update_PricedProductAdjustment: Maybe<PricedProductAdjustment_Mutation_Response>;
  /** update single row of the table: "PricedProductAdjustment" */
  update_PricedProductAdjustment_by_pk: Maybe<PricedProductAdjustment>;
  /** update multiples rows of table: "PricedProductAdjustment" */
  update_PricedProductAdjustment_many: Maybe<Array<Maybe<PricedProductAdjustment_Mutation_Response>>>;
  /** update data of the table: "PricedProductPricingFactor" */
  update_PricedProductPricingFactor: Maybe<PricedProductPricingFactor_Mutation_Response>;
  /** update data of the table: "PricedProductPricingFactorAdjustment" */
  update_PricedProductPricingFactorAdjustment: Maybe<PricedProductPricingFactorAdjustment_Mutation_Response>;
  /** update single row of the table: "PricedProductPricingFactorAdjustment" */
  update_PricedProductPricingFactorAdjustment_by_pk: Maybe<PricedProductPricingFactorAdjustment>;
  /** update multiples rows of table: "PricedProductPricingFactorAdjustment" */
  update_PricedProductPricingFactorAdjustment_many: Maybe<Array<Maybe<PricedProductPricingFactorAdjustment_Mutation_Response>>>;
  /** update single row of the table: "PricedProductPricingFactor" */
  update_PricedProductPricingFactor_by_pk: Maybe<PricedProductPricingFactor>;
  /** update multiples rows of table: "PricedProductPricingFactor" */
  update_PricedProductPricingFactor_many: Maybe<Array<Maybe<PricedProductPricingFactor_Mutation_Response>>>;
  /** update single row of the table: "PricedProduct" */
  update_PricedProduct_by_pk: Maybe<PricedProduct>;
  /** update multiples rows of table: "PricedProduct" */
  update_PricedProduct_many: Maybe<Array<Maybe<PricedProduct_Mutation_Response>>>;
  /** update data of the table: "ProServiceManagedField" */
  update_ProServiceManagedField: Maybe<ProServiceManagedField_Mutation_Response>;
  /** update single row of the table: "ProServiceManagedField" */
  update_ProServiceManagedField_by_pk: Maybe<ProServiceManagedField>;
  /** update multiples rows of table: "ProServiceManagedField" */
  update_ProServiceManagedField_many: Maybe<Array<Maybe<ProServiceManagedField_Mutation_Response>>>;
  /** update data of the table: "Product" */
  update_Product: Maybe<Product_Mutation_Response>;
  /** update data of the table: "ProductManagedField" */
  update_ProductManagedField: Maybe<ProductManagedField_Mutation_Response>;
  /** update single row of the table: "ProductManagedField" */
  update_ProductManagedField_by_pk: Maybe<ProductManagedField>;
  /** update multiples rows of table: "ProductManagedField" */
  update_ProductManagedField_many: Maybe<Array<Maybe<ProductManagedField_Mutation_Response>>>;
  /** update data of the table: "ProductPricingFactor" */
  update_ProductPricingFactor: Maybe<ProductPricingFactor_Mutation_Response>;
  /** update single row of the table: "ProductPricingFactor" */
  update_ProductPricingFactor_by_pk: Maybe<ProductPricingFactor>;
  /** update multiples rows of table: "ProductPricingFactor" */
  update_ProductPricingFactor_many: Maybe<Array<Maybe<ProductPricingFactor_Mutation_Response>>>;
  /** update single row of the table: "Product" */
  update_Product_by_pk: Maybe<Product>;
  /** update multiples rows of table: "Product" */
  update_Product_many: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "RateCardManagedField" */
  update_RateCardManagedField: Maybe<RateCardManagedField_Mutation_Response>;
  /** update single row of the table: "RateCardManagedField" */
  update_RateCardManagedField_by_pk: Maybe<RateCardManagedField>;
  /** update multiples rows of table: "RateCardManagedField" */
  update_RateCardManagedField_many: Maybe<Array<Maybe<RateCardManagedField_Mutation_Response>>>;
  /** update data of the table: "RecurringCreditGrant" */
  update_RecurringCreditGrant: Maybe<RecurringCreditGrant_Mutation_Response>;
  /** update single row of the table: "RecurringCreditGrant" */
  update_RecurringCreditGrant_by_pk: Maybe<RecurringCreditGrant>;
  /** update multiples rows of table: "RecurringCreditGrant" */
  update_RecurringCreditGrant_many: Maybe<Array<Maybe<RecurringCreditGrant_Mutation_Response>>>;
  /** update data of the table: "Role" */
  update_Role: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "Role" */
  update_Role_by_pk: Maybe<Role>;
  /** update multiples rows of table: "Role" */
  update_Role_many: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "RoundingBehaviorEnum" */
  update_RoundingBehaviorEnum: Maybe<RoundingBehaviorEnum_Mutation_Response>;
  /** update single row of the table: "RoundingBehaviorEnum" */
  update_RoundingBehaviorEnum_by_pk: Maybe<RoundingBehaviorEnum>;
  /** update multiples rows of table: "RoundingBehaviorEnum" */
  update_RoundingBehaviorEnum_many: Maybe<Array<Maybe<RoundingBehaviorEnum_Mutation_Response>>>;
  /** update data of the table: "ScheduledChargeManagedField" */
  update_ScheduledChargeManagedField: Maybe<ScheduledChargeManagedField_Mutation_Response>;
  /** update single row of the table: "ScheduledChargeManagedField" */
  update_ScheduledChargeManagedField_by_pk: Maybe<ScheduledChargeManagedField>;
  /** update multiples rows of table: "ScheduledChargeManagedField" */
  update_ScheduledChargeManagedField_many: Maybe<Array<Maybe<ScheduledChargeManagedField_Mutation_Response>>>;
  /** update data of the table: "ServicePeriodStartOnEnum" */
  update_ServicePeriodStartOnEnum: Maybe<ServicePeriodStartOnEnum_Mutation_Response>;
  /** update single row of the table: "ServicePeriodStartOnEnum" */
  update_ServicePeriodStartOnEnum_by_pk: Maybe<ServicePeriodStartOnEnum>;
  /** update multiples rows of table: "ServicePeriodStartOnEnum" */
  update_ServicePeriodStartOnEnum_many: Maybe<Array<Maybe<ServicePeriodStartOnEnum_Mutation_Response>>>;
  /** update data of the table: "StripePrepaidConfig_temporary" */
  update_StripePrepaidConfig_temporary: Maybe<StripePrepaidConfig_Temporary_Mutation_Response>;
  /** update single row of the table: "StripePrepaidConfig_temporary" */
  update_StripePrepaidConfig_temporary_by_pk: Maybe<StripePrepaidConfig_Temporary>;
  /** update multiples rows of table: "StripePrepaidConfig_temporary" */
  update_StripePrepaidConfig_temporary_many: Maybe<Array<Maybe<StripePrepaidConfig_Temporary_Mutation_Response>>>;
  /** update data of the table: "TieringModeEnum" */
  update_TieringModeEnum: Maybe<TieringModeEnum_Mutation_Response>;
  /** update single row of the table: "TieringModeEnum" */
  update_TieringModeEnum_by_pk: Maybe<TieringModeEnum>;
  /** update multiples rows of table: "TieringModeEnum" */
  update_TieringModeEnum_many: Maybe<Array<Maybe<TieringModeEnum_Mutation_Response>>>;
  /** update data of the table: "Tokens" */
  update_Tokens: Maybe<Tokens_Mutation_Response>;
  /** update single row of the table: "Tokens" */
  update_Tokens_by_pk: Maybe<Tokens>;
  /** update multiples rows of table: "Tokens" */
  update_Tokens_many: Maybe<Array<Maybe<Tokens_Mutation_Response>>>;
  /** update data of the table: "TrialSpec" */
  update_TrialSpec: Maybe<TrialSpec_Mutation_Response>;
  /** update data of the table: "TrialSpecSpendingCap" */
  update_TrialSpecSpendingCap: Maybe<TrialSpecSpendingCap_Mutation_Response>;
  /** update single row of the table: "TrialSpecSpendingCap" */
  update_TrialSpecSpendingCap_by_pk: Maybe<TrialSpecSpendingCap>;
  /** update multiples rows of table: "TrialSpecSpendingCap" */
  update_TrialSpecSpendingCap_many: Maybe<Array<Maybe<TrialSpecSpendingCap_Mutation_Response>>>;
  /** update single row of the table: "TrialSpec" */
  update_TrialSpec_by_pk: Maybe<TrialSpec>;
  /** update multiples rows of table: "TrialSpec" */
  update_TrialSpec_many: Maybe<Array<Maybe<TrialSpec_Mutation_Response>>>;
  /** update data of the table: "UserAuthTypeEnum" */
  update_UserAuthTypeEnum: Maybe<UserAuthTypeEnum_Mutation_Response>;
  /** update single row of the table: "UserAuthTypeEnum" */
  update_UserAuthTypeEnum_by_pk: Maybe<UserAuthTypeEnum>;
  /** update multiples rows of table: "UserAuthTypeEnum" */
  update_UserAuthTypeEnum_many: Maybe<Array<Maybe<UserAuthTypeEnum_Mutation_Response>>>;
  /** update data of the table: "User" */
  update_User_doNotUse: Maybe<User_Mutation_Response>;
  /** update multiples rows of table: "User" */
  update_User_many: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "Webhook" */
  update_Webhook: Maybe<Webhook_Mutation_Response>;
  /** update data of the table: "webhook_notifications" */
  update_WebhookNotification: Maybe<WebhookNotification_Mutation_Response>;
  /** update single row of the table: "webhook_notifications" */
  update_WebhookNotification_by_pk: Maybe<WebhookNotification>;
  /** update multiples rows of table: "webhook_notifications" */
  update_WebhookNotification_many: Maybe<Array<Maybe<WebhookNotification_Mutation_Response>>>;
  /** update single row of the table: "Webhook" */
  update_Webhook_by_pk: Maybe<Webhook>;
  /** update multiples rows of table: "Webhook" */
  update_Webhook_many: Maybe<Array<Maybe<Webhook_Mutation_Response>>>;
  update_billing_provider_settings: MutationResponse;
  /** update single row of the table: "User" */
  update_by_pk_User_doNotUse: Maybe<User>;
  update_client_billing_provider_configurations: MutationResponse;
  update_commit_schedule: UpdateCommitScheduleMutationResponse;
  update_contract_end_date: Contract;
  update_contract_invoice_date: Mri_Invoice;
  update_contract_named_schedule: MutationResponse;
  update_credit_grant: CreditGrant;
  update_customer_billable_status: Customer;
  update_customer_named_schedule: MutationResponse;
  update_email: User;
  update_invoice_finalization_metadata_status: InvoiceFinalizationMetadata;
  update_name: User;
  update_product_list_item: ProductListItem;
  update_rate_card: RateCard;
  update_rate_card_named_schedule: MutationResponse;
  update_recharge_settings: RechargeSettings;
  update_recharge_settings_and_recharge: RechargeSettingsUpdateAndChargeResponse;
  update_usage_filter: MutationResponse;
  upsert_billing_provider_invoice: Array<Maybe<BillingProviderInvoiceUpsertResult>>;
  void_credit_grant: CreditGrant;
  void_customer_commit: CommitUnion;
  void_prepaid_credit_grant: CreditGrant;
};


/** mutation root */
export type MutationAdd_Aws_Marketplace_To_CustomerArgs = {
  aws_customer_id: Scalars['String'];
  aws_product_code: Scalars['String'];
  aws_region: Scalars['String'];
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationAdd_Azure_Marketplace_To_CustomerArgs = {
  azure_subscription_id: Scalars['String'];
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationAdd_Manual_Commit_Ledger_EntryArgs = {
  amount: Scalars['numeric'];
  commit_id: Scalars['uuid'];
  contract_id?: InputMaybe<Scalars['uuid']>;
  customer_id: Scalars['uuid'];
  reason: Scalars['String'];
  segment_id: Scalars['uuid'];
  timestamp?: InputMaybe<Scalars['timestamptz']>;
};


/** mutation root */
export type MutationAdd_Plan_To_CustomerArgs = {
  cancellation_date?: InputMaybe<Scalars['timestamptz']>;
  credit_type_conversion_adjustments?: InputMaybe<Array<CreditTypeConversionInput>>;
  custom_pricing?: InputMaybe<CustomPricingInput>;
  customer_id: Scalars['uuid'];
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  plan_id: Scalars['uuid'];
  start_date: Scalars['timestamptz'];
  trial_spec?: InputMaybe<TrialSpecInput>;
};


/** mutation root */
export type MutationAdd_RateArgs = {
  effective_at: Scalars['timestamptz'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  entitled: Scalars['Boolean'];
  pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  pricing_groups?: InputMaybe<Scalars['RecordOfStrings']>;
  product_list_item_id: Scalars['uuid'];
  rate: RateInput;
  rate_card_id: Scalars['uuid'];
};


/** mutation root */
export type MutationAmend_ContractArgs = {
  commits?: InputMaybe<Array<CommitInput>>;
  contract_id: Scalars['uuid'];
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  customer_id: Scalars['uuid'];
  discounts?: InputMaybe<Array<DiscountInput>>;
  effective_at: Scalars['timestamptz'];
  multiplier_override_prioritization?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<RateCardEntryOverrideInput>>;
  pro_services?: InputMaybe<Array<ProServiceInput>>;
  reseller_royalties?: InputMaybe<Array<ResellerRoyaltyOrUpdateInput>>;
  salesforce_opportunity_id?: InputMaybe<Scalars['String']>;
  scheduled_charges?: InputMaybe<Array<ScheduledChargeInput>>;
  total_contract_value?: InputMaybe<Scalars['numeric']>;
};


/** mutation root */
export type MutationArchive_AlertArgs = {
  alert_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_Billable_MetricArgs = {
  billable_metric_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_ContractArgs = {
  archived_at: Scalars['timestamptz'];
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  void_invoices?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationArchive_CustomerArgs = {
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_PlanArgs = {
  plan_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_ProductArgs = {
  product_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_Product_List_ItemArgs = {
  archived_at: Scalars['timestamptz'];
  product_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_Scheduled_Webhook_NotificationsArgs = {
  customer_filter?: InputMaybe<ArchiveScheduledWebhookNotificationsCustomerFilter>;
  notification_ids?: InputMaybe<Array<Scalars['uuid']>>;
};


/** mutation root */
export type MutationArchive_Seat_MetricArgs = {
  seat_metric_id: Scalars['uuid'];
};


/** mutation root */
export type MutationArchive_UserArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationBackfill_Billable_MetricArgs = {
  billable_metric_id: Scalars['uuid'];
};


/** mutation root */
export type MutationCancel_End_Customer_PlanArgs = {
  customer_id: Scalars['uuid'];
  customer_plan_id: Scalars['uuid'];
};


/** mutation root */
export type MutationChange_Commit_End_DateArgs = {
  amendment_id?: InputMaybe<Scalars['uuid']>;
  commit_id: Scalars['uuid'];
  contract_id?: InputMaybe<Scalars['uuid']>;
  customer_id: Scalars['uuid'];
  end_access_at?: InputMaybe<Scalars['timestamptz']>;
  end_invoices_at?: InputMaybe<Scalars['timestamptz']>;
};


/** mutation root */
export type MutationChange_PasswordArgs = {
  current_password: Scalars['String'];
  new_password: Scalars['String'];
};


/** mutation root */
export type MutationCreate_Adhoc_Contract_Usage_InvoicesArgs = {
  inputs: Array<AdhocContractUsageInvoiceInput>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationCreate_Adhoc_Plan_InvoicesArgs = {
  inputs: Array<AdHocPlanInvoiceInput>;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationCreate_Api_TokenArgs = {
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  name: Scalars['String'];
};


/** mutation root */
export type MutationCreate_Billable_MetricArgs = {
  input: BillableMetricInput;
};


/** mutation root */
export type MutationCreate_Billable_MetricsArgs = {
  inputs: Array<BillableMetricInput>;
};


/** mutation root */
export type MutationCreate_Billing_Provider_SettingsArgs = {
  billing_provider: BillingProviderEnum_Enum;
  settings: CreateBillingProviderSettingsInput;
};


/** mutation root */
export type MutationCreate_Census_WorkspaceArgs = {
  destination_type: CensusDestinationTypeEnum;
};


/** mutation root */
export type MutationCreate_Composite_Product_List_ItemArgs = {
  composite_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  composite_tags?: InputMaybe<Array<Scalars['String']>>;
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  exclude_free_usage?: InputMaybe<Scalars['Boolean']>;
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  netsuite_overage_item_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationCreate_ContractArgs = {
  billing_provider_configuration?: InputMaybe<BillingProviderConfigurationInput>;
  commits?: InputMaybe<Array<CommitInput>>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  customer_id: Scalars['uuid'];
  discounts?: InputMaybe<Array<DiscountInput>>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  multiplier_override_prioritization?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<RateCardEntryOverrideInput>>;
  pro_services?: InputMaybe<Array<ProServiceInput>>;
  rate_card_alias?: InputMaybe<Scalars['String']>;
  rate_card_id?: InputMaybe<Scalars['uuid']>;
  reseller_royalties?: InputMaybe<Array<ResellerRoyaltyInput>>;
  salesforce_opportunity_id?: InputMaybe<Scalars['String']>;
  scheduled_charges?: InputMaybe<Array<ScheduledChargeInput>>;
  starting_at: Scalars['timestamptz'];
  total_contract_value?: InputMaybe<Scalars['numeric']>;
  transition?: InputMaybe<TransitionInput>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  usage_filter?: InputMaybe<CreateContractUsageFilterInput>;
  usage_invoice_schedule?: InputMaybe<CreateContractUsageInvoiceScheduleInput>;
};


/** mutation root */
export type MutationCreate_Credit_GrantArgs = {
  credit_grant: CreditGrantInput;
};


/** mutation root */
export type MutationCreate_CustomerArgs = {
  billing_provider_customer?: InputMaybe<BillingProviderCustomerInput>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  customer_billing_provider_configurations?: InputMaybe<Array<InsertCustomerBillingProviderConfigurationNewCustomerInput>>;
  customer_configs?: InputMaybe<Array<CustomerConfigInput>>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_aliases: Array<Scalars['String']>;
  name: Scalars['String'];
};


/** mutation root */
export type MutationCreate_Customer_CommitArgs = {
  customer_commit: CustomerCommitInput;
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationCreate_Fancy_MetricArgs = {
  input: FancyMetricInput;
};


/** mutation root */
export type MutationCreate_Fixed_Product_List_ItemArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationCreate_Invoice_CorrectionArgs = {
  invoice_correction: InvoiceCorrectionInput;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationCreate_One_Time_ChargeArgs = {
  customer_plan_id: Scalars['uuid'];
  description: Scalars['String'];
  price: Scalars['numeric'];
  product_pricing_factor_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  service_period_start_date: Scalars['timestamptz'];
};


/** mutation root */
export type MutationCreate_PlanArgs = {
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  plan: PlanInput;
};


/** mutation root */
export type MutationCreate_Prepaid_Credit_GrantArgs = {
  credit_grant: CreditGrantInput;
  prepaid_input: PrepaidCreditGrantInput;
};


/** mutation root */
export type MutationCreate_Pro_Service_InvoicesArgs = {
  pro_service_invoice_data: ContractProServiceInvoicesInput;
};


/** mutation root */
export type MutationCreate_Pro_Service_Product_List_ItemArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationCreate_Rate_CardArgs = {
  aliases?: InputMaybe<Array<RateCardAliasInput>>;
  creditTypeConversions?: InputMaybe<Array<RateCardCreditTypeConversionInput>>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  description?: InputMaybe<Scalars['String']>;
  fiatCreditTypeId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rates?: InputMaybe<Array<RateCardEntryInput>>;
};


/** mutation root */
export type MutationCreate_Recharge_SettingsArgs = {
  recharge_settings: RechargeSettingsInput;
};


/** mutation root */
export type MutationCreate_Recharge_Settings_And_RechargeArgs = {
  current_credit_balance: Scalars['numeric'];
  recharge_settings: RechargeSettingsInput;
};


/** mutation root */
export type MutationCreate_Recurring_Credit_GrantsArgs = {
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationCreate_Scheduled_Webhook_NotificationArgs = {
  notification: Scalars['JSON'];
  notification_type: Scalars['String'];
  timestamp: Scalars['timestamptz'];
};


/** mutation root */
export type MutationCreate_Seat_MetricArgs = {
  input: SeatMetricInput;
};


/** mutation root */
export type MutationCreate_Seat_Purchase_InvoiceArgs = {
  customer_id: Scalars['uuid'];
  invoice_date: Scalars['timestamptz'];
  seat_charges: Array<SeatChargeInput>;
};


/** mutation root */
export type MutationCreate_Subscription_Product_List_ItemArgs = {
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  netsuite_overage_item_id?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationCreate_Usage_Product_List_ItemArgs = {
  billable_metric_id: Scalars['uuid'];
  effective_at?: InputMaybe<Scalars['timestamptz']>;
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  netsuite_overage_item_id?: InputMaybe<Scalars['String']>;
  presentation_group_key?: InputMaybe<Array<Scalars['String']>>;
  pricing_group_key?: InputMaybe<Array<Scalars['String']>>;
  quantity_conversion?: InputMaybe<QuantityConversionInput>;
  quantity_rounding?: InputMaybe<QuantityRoundingInput>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationCreate_UserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


/** mutation root */
export type MutationCreate_WebhookArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};


/** mutation root */
export type MutationDelete_AccountTypeEnumArgs = {
  where: AccountTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_AccountTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_ActorArgs = {
  where: Actor_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Actor_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationDelete_AlertArgs = {
  where: Alert_Bool_Exp;
};


/** mutation root */
export type MutationDelete_AlertManagedFieldArgs = {
  where: AlertManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_AlertManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_AlertTypeEnumArgs = {
  where: AlertTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_AlertTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Alert_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_BillableMetricArgs = {
  where: BillableMetric_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillableMetricManagedFieldArgs = {
  where: BillableMetricManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillableMetricManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_BillableMetric_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_BillingFrequencyEnumArgs = {
  where: BillingFrequencyEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingFrequencyEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_BillingMetricAggregateEnumArgs = {
  where: BillingMetricAggregateEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingMetricAggregateEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_BillingProviderCustomerArgs = {
  where: BillingProviderCustomer_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingProviderCustomer_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_BillingProviderEnumArgs = {
  where: BillingProviderEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingProviderEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_BillingProviderInvoiceArgs = {
  where: BillingProviderInvoice_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingProviderInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_BillingProviderTokenArgs = {
  where: BillingProviderToken_Bool_Exp;
};


/** mutation root */
export type MutationDelete_BillingProviderToken_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


/** mutation root */
export type MutationDelete_ChargeManagedFieldArgs = {
  where: ChargeManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ChargeTypeEnumArgs = {
  where: ChargeTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_ClientArgs = {
  where: Client_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ClientConfigArgs = {
  where: ClientConfig_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ClientConfigKeyEnumArgs = {
  where: ClientConfigKeyEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ClientConfigKeyEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_ClientConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Client_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CollectionScheduleEnumArgs = {
  where: CollectionScheduleEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CollectionScheduleEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_CommitManagedFieldArgs = {
  where: CommitManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CommitManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CompositeChargeArgs = {
  where: CompositeCharge_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeAdjustmentArgs = {
  where: CompositeChargeAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CompositeChargePricingFactorArgs = {
  where: CompositeChargePricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeTierArgs = {
  where: CompositeChargeTier_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeTierAdjustmentArgs = {
  where: CompositeChargeTierAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeTierAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CompositeChargeTier_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CompositeChargeTypeEnumArgs = {
  where: CompositeChargeTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CompositeChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_CompositeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ContractCreditManagedFieldArgs = {
  where: ContractCreditManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ContractCreditManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ContractManagedFieldArgs = {
  where: ContractManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ContractManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ContractProductManagedFieldArgs = {
  where: ContractProductManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ContractProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditAdjustmentArgs = {
  where: CreditAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditGrantArgs = {
  where: CreditGrant_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditGrantDeductionArgs = {
  where: CreditGrantDeduction_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditGrantDeduction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditGrantHistoryArgs = {
  where: CreditGrantHistory_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditGrantHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditGrantManagedFieldArgs = {
  where: CreditGrantManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditGrantManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditTypeArgs = {
  where: CreditType_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditTypeConversionArgs = {
  where: CreditTypeConversion_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditTypeConversionAdjustmentArgs = {
  where: CreditTypeConversionAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CreditTypeConversionAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditTypeConversion_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CreditType_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomPricingArgs = {
  where: CustomPricing_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomPricing_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerArgs = {
  where: Customer_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerAlertArgs = {
  where: CustomerAlert_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerAlertHistoryArgs = {
  where: CustomerAlertHistory_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerAlertHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerAlertStatusEnumArgs = {
  where: CustomerAlertStatusEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerAlertStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_CustomerAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerConfigArgs = {
  where: CustomerConfig_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerIngestAliasArgs = {
  where: CustomerIngestAlias_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerIngestAlias_By_PkArgs = {
  client_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ingest_alias: Scalars['String'];
};


/** mutation root */
export type MutationDelete_CustomerManagedFieldArgs = {
  where: CustomerManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerPlanArgs = {
  where: CustomerPlan_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerPlanAdjustmentArgs = {
  where: CustomerPlanAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerPlanAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerPlanManagedFieldArgs = {
  where: CustomerPlanManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerPlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerPlanOneTimeChargeArgs = {
  where: CustomerPlanOneTimeCharge_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerPlanOneTimeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_CustomerRelationshipArgs = {
  where: CustomerRelationship_Bool_Exp;
};


/** mutation root */
export type MutationDelete_CustomerRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_DraftPlanArgs = {
  where: DraftPlan_Bool_Exp;
};


/** mutation root */
export type MutationDelete_DraftPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_EnvironmentTypeEnumArgs = {
  where: EnvironmentTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_EnvironmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_FlatFeeArgs = {
  where: FlatFee_Bool_Exp;
};


/** mutation root */
export type MutationDelete_FlatFeeAdjustmentArgs = {
  where: FlatFeeAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_FlatFeeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_FlatFee_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_InvoiceExternalStatusEnumArgs = {
  where: InvoiceExternalStatusEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_InvoiceExternalStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_InvoiceManagedFieldArgs = {
  where: InvoiceManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_InvoiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ManagedEntityEnumArgs = {
  where: ManagedEntityEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ManagedEntityEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_ManagedFieldKeyArgs = {
  where: ManagedFieldKey_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ManagedFieldKey_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ManagedFieldVendorMappingArgs = {
  where: ManagedFieldVendorMapping_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ManagedFieldVendorMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ManagedVendorEnumArgs = {
  where: ManagedVendorEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ManagedVendorEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_MinimumArgs = {
  where: Minimum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Minimum_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PlanArgs = {
  where: Plan_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PlanManagedFieldArgs = {
  where: PlanManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Plan_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PriceArgs = {
  where: Price_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PriceAdjustmentArgs = {
  where: PriceAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PriceAdjustmentTypeEnumArgs = {
  where: PriceAdjustmentTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PriceAdjustmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_PriceAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Price_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PricedProductArgs = {
  where: PricedProduct_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PricedProductAdjustmentArgs = {
  where: PricedProductAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PricedProductAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PricedProductPricingFactorArgs = {
  where: PricedProductPricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PricedProductPricingFactorAdjustmentArgs = {
  where: PricedProductPricingFactorAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationDelete_PricedProductPricingFactorAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PricedProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_PricedProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ProServiceManagedFieldArgs = {
  where: ProServiceManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ProServiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ProductManagedFieldArgs = {
  where: ProductManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ProductPricingFactorArgs = {
  where: ProductPricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Product_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_RateCardManagedFieldArgs = {
  where: RateCardManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_RateCardManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_RecurringCreditGrantArgs = {
  where: RecurringCreditGrant_Bool_Exp;
};


/** mutation root */
export type MutationDelete_RecurringCreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_RoundingBehaviorEnumArgs = {
  where: RoundingBehaviorEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_RoundingBehaviorEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_ScheduledChargeManagedFieldArgs = {
  where: ScheduledChargeManagedField_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ScheduledChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_ServicePeriodStartOnEnumArgs = {
  where: ServicePeriodStartOnEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_ServicePeriodStartOnEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_StripePrepaidConfig_TemporaryArgs = {
  where: StripePrepaidConfig_Temporary_Bool_Exp;
};


/** mutation root */
export type MutationDelete_StripePrepaidConfig_Temporary_By_PkArgs = {
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


/** mutation root */
export type MutationDelete_TieringModeEnumArgs = {
  where: TieringModeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_TieringModeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_TokensArgs = {
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type MutationDelete_Tokens_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationDelete_TrialSpecArgs = {
  where: TrialSpec_Bool_Exp;
};


/** mutation root */
export type MutationDelete_TrialSpecSpendingCapArgs = {
  where: TrialSpecSpendingCap_Bool_Exp;
};


/** mutation root */
export type MutationDelete_TrialSpecSpendingCap_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_TrialSpec_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_UserAuthTypeEnumArgs = {
  where: UserAuthTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationDelete_UserAuthTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationDelete_User_DoNotUseArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type MutationDelete_WebhookArgs = {
  where: Webhook_Bool_Exp;
};


/** mutation root */
export type MutationDelete_WebhookNotificationArgs = {
  where: WebhookNotification_Bool_Exp;
};


/** mutation root */
export type MutationDelete_WebhookNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Webhook_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationDelete_Billing_Provider_SettingsArgs = {
  billing_provider: BillingProviderEnum_Enum;
};


/** mutation root */
export type MutationDelete_By_Pk_User_DoNotUseArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationDelete_One_Time_ChargeArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_Recharge_SettingsArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationDelete_UserArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type MutationDemo_Setup_CustomerArgs = {
  customer_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
  redirect_url_cancel: Scalars['String'];
  redirect_url_success: Scalars['String'];
};


/** mutation root */
export type MutationDisable_Managed_Field_KeyArgs = {
  key_id: Scalars['uuid'];
};


/** mutation root */
export type MutationEdit_ContractArgs = {
  add_commits?: InputMaybe<Array<CommitInput>>;
  add_discounts?: InputMaybe<Array<DiscountInput>>;
  add_overrides?: InputMaybe<Array<RateCardEntryOverrideInput>>;
  add_pro_services?: InputMaybe<Array<ProServiceInput>>;
  add_reseller_royalties?: InputMaybe<Array<ResellerRoyaltyOrUpdateInput>>;
  add_scheduled_charges?: InputMaybe<Array<ScheduledChargeInput>>;
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  remove_commits?: InputMaybe<Array<Scalars['uuid']>>;
  remove_discounts?: InputMaybe<Array<Scalars['uuid']>>;
  remove_overrides?: InputMaybe<Array<Scalars['uuid']>>;
  remove_scheduled_charges?: InputMaybe<Array<Scalars['uuid']>>;
  update_commit_access_schedule?: InputMaybe<Array<UpdateCommitAccessScheduleInput>>;
  update_commit_invoice_schedule?: InputMaybe<Array<UpdateCommitInvoiceScheduleInput>>;
  update_commit_netsuite_sales_order_id?: InputMaybe<Array<UpdateCommitNetsuiteSalesOrderIdInput>>;
  update_commit_rollover_fraction?: InputMaybe<Array<UpdateCommitRolloverFractionInput>>;
  update_contract_end_date?: InputMaybe<Scalars['timestamptz']>;
  update_contract_name?: InputMaybe<Scalars['String']>;
  update_contract_net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  update_contract_netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  update_contract_salesforce_opportunity_id?: InputMaybe<Scalars['String']>;
  update_contract_start_date?: InputMaybe<Scalars['timestamptz']>;
  update_scheduled_charge_invoice_schedule?: InputMaybe<Array<UpdateScheduledChargeScheduleInput>>;
  update_scheduled_charge_netsuite_sales_order_id?: InputMaybe<Array<UpdateScheduledChargeNetsuiteSalesOrderIdInput>>;
  update_total_contract_value?: InputMaybe<Scalars['numeric']>;
};


/** mutation root */
export type MutationEdit_PlanArgs = {
  dangerous_cutover_date?: InputMaybe<Scalars['timestamptz']>;
  new_plan: PlanInput;
  plan_id: Scalars['uuid'];
  starting_on?: InputMaybe<Scalars['timestamptz']>;
};


/** mutation root */
export type MutationEdit_ProductArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  pricing_factors: Array<PricingFactorInput>;
  product_id: Scalars['uuid'];
};


/** mutation root */
export type MutationEnd_Customer_PlanArgs = {
  cancellation_date: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  customer_plan_id: Scalars['uuid'];
};


/** mutation root */
export type MutationGenerate_Census_Destination_Connect_LinkArgs = {
  destination_type: CensusDestinationTypeEnum;
};


/** mutation root */
export type MutationGenerate_Prequel_Auth_TokenArgs = {
  application_origin: Scalars['String'];
  bucket_name?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  vendor?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationInsert_AccountTypeEnumArgs = {
  objects: Array<AccountTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<AccountTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AccountTypeEnum_OneArgs = {
  object: AccountTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<AccountTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ActorArgs = {
  objects: Array<Actor_Insert_Input>;
  on_conflict?: InputMaybe<Actor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Actor_OneArgs = {
  object: Actor_Insert_Input;
  on_conflict?: InputMaybe<Actor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AlertArgs = {
  objects: Array<Alert_Insert_Input>;
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AlertManagedFieldArgs = {
  objects: Array<AlertManagedField_Insert_Input>;
  on_conflict?: InputMaybe<AlertManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AlertManagedField_OneArgs = {
  object: AlertManagedField_Insert_Input;
  on_conflict?: InputMaybe<AlertManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AlertTypeEnumArgs = {
  objects: Array<AlertTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<AlertTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_AlertTypeEnum_OneArgs = {
  object: AlertTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<AlertTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Alert_OneArgs = {
  object: Alert_Insert_Input;
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillableMetricArgs = {
  objects: Array<BillableMetric_Insert_Input>;
  on_conflict?: InputMaybe<BillableMetric_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillableMetricManagedFieldArgs = {
  objects: Array<BillableMetricManagedField_Insert_Input>;
  on_conflict?: InputMaybe<BillableMetricManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillableMetricManagedField_OneArgs = {
  object: BillableMetricManagedField_Insert_Input;
  on_conflict?: InputMaybe<BillableMetricManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillableMetric_OneArgs = {
  object: BillableMetric_Insert_Input;
  on_conflict?: InputMaybe<BillableMetric_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingFrequencyEnumArgs = {
  objects: Array<BillingFrequencyEnum_Insert_Input>;
  on_conflict?: InputMaybe<BillingFrequencyEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingFrequencyEnum_OneArgs = {
  object: BillingFrequencyEnum_Insert_Input;
  on_conflict?: InputMaybe<BillingFrequencyEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingMetricAggregateEnumArgs = {
  objects: Array<BillingMetricAggregateEnum_Insert_Input>;
  on_conflict?: InputMaybe<BillingMetricAggregateEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingMetricAggregateEnum_OneArgs = {
  object: BillingMetricAggregateEnum_Insert_Input;
  on_conflict?: InputMaybe<BillingMetricAggregateEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderCustomerArgs = {
  objects: Array<BillingProviderCustomer_Insert_Input>;
  on_conflict?: InputMaybe<BillingProviderCustomer_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderCustomer_OneArgs = {
  object: BillingProviderCustomer_Insert_Input;
  on_conflict?: InputMaybe<BillingProviderCustomer_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderEnumArgs = {
  objects: Array<BillingProviderEnum_Insert_Input>;
  on_conflict?: InputMaybe<BillingProviderEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderEnum_OneArgs = {
  object: BillingProviderEnum_Insert_Input;
  on_conflict?: InputMaybe<BillingProviderEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderInvoiceArgs = {
  objects: Array<BillingProviderInvoice_Insert_Input>;
  on_conflict?: InputMaybe<BillingProviderInvoice_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderInvoice_OneArgs = {
  object: BillingProviderInvoice_Insert_Input;
  on_conflict?: InputMaybe<BillingProviderInvoice_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderTokenArgs = {
  objects: Array<BillingProviderToken_Insert_Input>;
  on_conflict?: InputMaybe<BillingProviderToken_On_Conflict>;
};


/** mutation root */
export type MutationInsert_BillingProviderToken_OneArgs = {
  object: BillingProviderToken_Insert_Input;
  on_conflict?: InputMaybe<BillingProviderToken_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ChargeManagedFieldArgs = {
  objects: Array<ChargeManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ChargeManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ChargeManagedField_OneArgs = {
  object: ChargeManagedField_Insert_Input;
  on_conflict?: InputMaybe<ChargeManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ChargeTypeEnumArgs = {
  objects: Array<ChargeTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<ChargeTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ChargeTypeEnum_OneArgs = {
  object: ChargeTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<ChargeTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ClientConfigArgs = {
  objects: Array<ClientConfig_Insert_Input>;
  on_conflict?: InputMaybe<ClientConfig_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ClientConfigKeyEnumArgs = {
  objects: Array<ClientConfigKeyEnum_Insert_Input>;
  on_conflict?: InputMaybe<ClientConfigKeyEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ClientConfigKeyEnum_OneArgs = {
  object: ClientConfigKeyEnum_Insert_Input;
  on_conflict?: InputMaybe<ClientConfigKeyEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ClientConfig_OneArgs = {
  object: ClientConfig_Insert_Input;
  on_conflict?: InputMaybe<ClientConfig_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: InputMaybe<Client_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CollectionScheduleEnumArgs = {
  objects: Array<CollectionScheduleEnum_Insert_Input>;
  on_conflict?: InputMaybe<CollectionScheduleEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CollectionScheduleEnum_OneArgs = {
  object: CollectionScheduleEnum_Insert_Input;
  on_conflict?: InputMaybe<CollectionScheduleEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CommitManagedFieldArgs = {
  objects: Array<CommitManagedField_Insert_Input>;
  on_conflict?: InputMaybe<CommitManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CommitManagedField_OneArgs = {
  object: CommitManagedField_Insert_Input;
  on_conflict?: InputMaybe<CommitManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeArgs = {
  objects: Array<CompositeCharge_Insert_Input>;
  on_conflict?: InputMaybe<CompositeCharge_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeAdjustmentArgs = {
  objects: Array<CompositeChargeAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<CompositeChargeAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeAdjustment_OneArgs = {
  object: CompositeChargeAdjustment_Insert_Input;
  on_conflict?: InputMaybe<CompositeChargeAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargePricingFactorArgs = {
  objects: Array<CompositeChargePricingFactor_Insert_Input>;
};


/** mutation root */
export type MutationInsert_CompositeChargePricingFactor_OneArgs = {
  object: CompositeChargePricingFactor_Insert_Input;
};


/** mutation root */
export type MutationInsert_CompositeChargeTierArgs = {
  objects: Array<CompositeChargeTier_Insert_Input>;
  on_conflict?: InputMaybe<CompositeChargeTier_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeTierAdjustmentArgs = {
  objects: Array<CompositeChargeTierAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<CompositeChargeTierAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeTierAdjustment_OneArgs = {
  object: CompositeChargeTierAdjustment_Insert_Input;
  on_conflict?: InputMaybe<CompositeChargeTierAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeTier_OneArgs = {
  object: CompositeChargeTier_Insert_Input;
  on_conflict?: InputMaybe<CompositeChargeTier_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeTypeEnumArgs = {
  objects: Array<CompositeChargeTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<CompositeChargeTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeChargeTypeEnum_OneArgs = {
  object: CompositeChargeTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<CompositeChargeTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CompositeCharge_OneArgs = {
  object: CompositeCharge_Insert_Input;
  on_conflict?: InputMaybe<CompositeCharge_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractCreditManagedFieldArgs = {
  objects: Array<ContractCreditManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ContractCreditManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractCreditManagedField_OneArgs = {
  object: ContractCreditManagedField_Insert_Input;
  on_conflict?: InputMaybe<ContractCreditManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractManagedFieldArgs = {
  objects: Array<ContractManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ContractManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractManagedField_OneArgs = {
  object: ContractManagedField_Insert_Input;
  on_conflict?: InputMaybe<ContractManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractProductManagedFieldArgs = {
  objects: Array<ContractProductManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ContractProductManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ContractProductManagedField_OneArgs = {
  object: ContractProductManagedField_Insert_Input;
  on_conflict?: InputMaybe<ContractProductManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditAdjustmentArgs = {
  objects: Array<CreditAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<CreditAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditAdjustment_OneArgs = {
  object: CreditAdjustment_Insert_Input;
  on_conflict?: InputMaybe<CreditAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantArgs = {
  objects: Array<CreditGrant_Insert_Input>;
  on_conflict?: InputMaybe<CreditGrant_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantDeductionArgs = {
  objects: Array<CreditGrantDeduction_Insert_Input>;
  on_conflict?: InputMaybe<CreditGrantDeduction_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantDeduction_OneArgs = {
  object: CreditGrantDeduction_Insert_Input;
  on_conflict?: InputMaybe<CreditGrantDeduction_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantHistoryArgs = {
  objects: Array<CreditGrantHistory_Insert_Input>;
  on_conflict?: InputMaybe<CreditGrantHistory_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantHistory_OneArgs = {
  object: CreditGrantHistory_Insert_Input;
  on_conflict?: InputMaybe<CreditGrantHistory_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantManagedFieldArgs = {
  objects: Array<CreditGrantManagedField_Insert_Input>;
  on_conflict?: InputMaybe<CreditGrantManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrantManagedField_OneArgs = {
  object: CreditGrantManagedField_Insert_Input;
  on_conflict?: InputMaybe<CreditGrantManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditGrant_OneArgs = {
  object: CreditGrant_Insert_Input;
  on_conflict?: InputMaybe<CreditGrant_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditTypeArgs = {
  objects: Array<CreditType_Insert_Input>;
  on_conflict?: InputMaybe<CreditType_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditTypeConversionArgs = {
  objects: Array<CreditTypeConversion_Insert_Input>;
  on_conflict?: InputMaybe<CreditTypeConversion_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditTypeConversionAdjustmentArgs = {
  objects: Array<CreditTypeConversionAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<CreditTypeConversionAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditTypeConversionAdjustment_OneArgs = {
  object: CreditTypeConversionAdjustment_Insert_Input;
  on_conflict?: InputMaybe<CreditTypeConversionAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditTypeConversion_OneArgs = {
  object: CreditTypeConversion_Insert_Input;
  on_conflict?: InputMaybe<CreditTypeConversion_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CreditType_OneArgs = {
  object: CreditType_Insert_Input;
  on_conflict?: InputMaybe<CreditType_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomPricingArgs = {
  objects: Array<CustomPricing_Insert_Input>;
  on_conflict?: InputMaybe<CustomPricing_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomPricing_OneArgs = {
  object: CustomPricing_Insert_Input;
  on_conflict?: InputMaybe<CustomPricing_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerArgs = {
  objects: Array<Customer_Insert_Input>;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlertArgs = {
  objects: Array<CustomerAlert_Insert_Input>;
  on_conflict?: InputMaybe<CustomerAlert_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlertHistoryArgs = {
  objects: Array<CustomerAlertHistory_Insert_Input>;
  on_conflict?: InputMaybe<CustomerAlertHistory_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlertHistory_OneArgs = {
  object: CustomerAlertHistory_Insert_Input;
  on_conflict?: InputMaybe<CustomerAlertHistory_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlertStatusEnumArgs = {
  objects: Array<CustomerAlertStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<CustomerAlertStatusEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlertStatusEnum_OneArgs = {
  object: CustomerAlertStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<CustomerAlertStatusEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerAlert_OneArgs = {
  object: CustomerAlert_Insert_Input;
  on_conflict?: InputMaybe<CustomerAlert_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerConfigArgs = {
  objects: Array<CustomerConfig_Insert_Input>;
  on_conflict?: InputMaybe<CustomerConfig_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerConfig_OneArgs = {
  object: CustomerConfig_Insert_Input;
  on_conflict?: InputMaybe<CustomerConfig_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerIngestAliasArgs = {
  objects: Array<CustomerIngestAlias_Insert_Input>;
  on_conflict?: InputMaybe<CustomerIngestAlias_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerIngestAlias_OneArgs = {
  object: CustomerIngestAlias_Insert_Input;
  on_conflict?: InputMaybe<CustomerIngestAlias_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerManagedFieldArgs = {
  objects: Array<CustomerManagedField_Insert_Input>;
  on_conflict?: InputMaybe<CustomerManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerManagedField_OneArgs = {
  object: CustomerManagedField_Insert_Input;
  on_conflict?: InputMaybe<CustomerManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanArgs = {
  objects: Array<CustomerPlan_Insert_Input>;
  on_conflict?: InputMaybe<CustomerPlan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanAdjustmentArgs = {
  objects: Array<CustomerPlanAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<CustomerPlanAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanAdjustment_OneArgs = {
  object: CustomerPlanAdjustment_Insert_Input;
  on_conflict?: InputMaybe<CustomerPlanAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanManagedFieldArgs = {
  objects: Array<CustomerPlanManagedField_Insert_Input>;
  on_conflict?: InputMaybe<CustomerPlanManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanManagedField_OneArgs = {
  object: CustomerPlanManagedField_Insert_Input;
  on_conflict?: InputMaybe<CustomerPlanManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanOneTimeChargeArgs = {
  objects: Array<CustomerPlanOneTimeCharge_Insert_Input>;
  on_conflict?: InputMaybe<CustomerPlanOneTimeCharge_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlanOneTimeCharge_OneArgs = {
  object: CustomerPlanOneTimeCharge_Insert_Input;
  on_conflict?: InputMaybe<CustomerPlanOneTimeCharge_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerPlan_OneArgs = {
  object: CustomerPlan_Insert_Input;
  on_conflict?: InputMaybe<CustomerPlan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerRelationshipArgs = {
  objects: Array<CustomerRelationship_Insert_Input>;
  on_conflict?: InputMaybe<CustomerRelationship_On_Conflict>;
};


/** mutation root */
export type MutationInsert_CustomerRelationship_OneArgs = {
  object: CustomerRelationship_Insert_Input;
  on_conflict?: InputMaybe<CustomerRelationship_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Customer_OneArgs = {
  object: Customer_Insert_Input;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type MutationInsert_DraftPlanArgs = {
  objects: Array<DraftPlan_Insert_Input>;
  on_conflict?: InputMaybe<DraftPlan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_DraftPlan_OneArgs = {
  object: DraftPlan_Insert_Input;
  on_conflict?: InputMaybe<DraftPlan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_EnvironmentTypeEnumArgs = {
  objects: Array<EnvironmentTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<EnvironmentTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_EnvironmentTypeEnum_OneArgs = {
  object: EnvironmentTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<EnvironmentTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_FlatFeeArgs = {
  objects: Array<FlatFee_Insert_Input>;
  on_conflict?: InputMaybe<FlatFee_On_Conflict>;
};


/** mutation root */
export type MutationInsert_FlatFeeAdjustmentArgs = {
  objects: Array<FlatFeeAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<FlatFeeAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_FlatFeeAdjustment_OneArgs = {
  object: FlatFeeAdjustment_Insert_Input;
  on_conflict?: InputMaybe<FlatFeeAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_FlatFee_OneArgs = {
  object: FlatFee_Insert_Input;
  on_conflict?: InputMaybe<FlatFee_On_Conflict>;
};


/** mutation root */
export type MutationInsert_InvoiceExternalStatusEnumArgs = {
  objects: Array<InvoiceExternalStatusEnum_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceExternalStatusEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_InvoiceExternalStatusEnum_OneArgs = {
  object: InvoiceExternalStatusEnum_Insert_Input;
  on_conflict?: InputMaybe<InvoiceExternalStatusEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_InvoiceManagedFieldArgs = {
  objects: Array<InvoiceManagedField_Insert_Input>;
  on_conflict?: InputMaybe<InvoiceManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_InvoiceManagedField_OneArgs = {
  object: InvoiceManagedField_Insert_Input;
  on_conflict?: InputMaybe<InvoiceManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedEntityEnumArgs = {
  objects: Array<ManagedEntityEnum_Insert_Input>;
  on_conflict?: InputMaybe<ManagedEntityEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedEntityEnum_OneArgs = {
  object: ManagedEntityEnum_Insert_Input;
  on_conflict?: InputMaybe<ManagedEntityEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedFieldKeyArgs = {
  objects: Array<ManagedFieldKey_Insert_Input>;
  on_conflict?: InputMaybe<ManagedFieldKey_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedFieldKey_OneArgs = {
  object: ManagedFieldKey_Insert_Input;
  on_conflict?: InputMaybe<ManagedFieldKey_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedFieldVendorMappingArgs = {
  objects: Array<ManagedFieldVendorMapping_Insert_Input>;
  on_conflict?: InputMaybe<ManagedFieldVendorMapping_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedFieldVendorMapping_OneArgs = {
  object: ManagedFieldVendorMapping_Insert_Input;
  on_conflict?: InputMaybe<ManagedFieldVendorMapping_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedVendorEnumArgs = {
  objects: Array<ManagedVendorEnum_Insert_Input>;
  on_conflict?: InputMaybe<ManagedVendorEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ManagedVendorEnum_OneArgs = {
  object: ManagedVendorEnum_Insert_Input;
  on_conflict?: InputMaybe<ManagedVendorEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_MinimumArgs = {
  objects: Array<Minimum_Insert_Input>;
  on_conflict?: InputMaybe<Minimum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Minimum_OneArgs = {
  object: Minimum_Insert_Input;
  on_conflict?: InputMaybe<Minimum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PlanArgs = {
  objects: Array<Plan_Insert_Input>;
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PlanManagedFieldArgs = {
  objects: Array<PlanManagedField_Insert_Input>;
  on_conflict?: InputMaybe<PlanManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PlanManagedField_OneArgs = {
  object: PlanManagedField_Insert_Input;
  on_conflict?: InputMaybe<PlanManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Plan_OneArgs = {
  object: Plan_Insert_Input;
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PriceArgs = {
  objects: Array<Price_Insert_Input>;
  on_conflict?: InputMaybe<Price_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PriceAdjustmentArgs = {
  objects: Array<PriceAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<PriceAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PriceAdjustmentTypeEnumArgs = {
  objects: Array<PriceAdjustmentTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<PriceAdjustmentTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PriceAdjustmentTypeEnum_OneArgs = {
  object: PriceAdjustmentTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<PriceAdjustmentTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PriceAdjustment_OneArgs = {
  object: PriceAdjustment_Insert_Input;
  on_conflict?: InputMaybe<PriceAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Price_OneArgs = {
  object: Price_Insert_Input;
  on_conflict?: InputMaybe<Price_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductArgs = {
  objects: Array<PricedProduct_Insert_Input>;
  on_conflict?: InputMaybe<PricedProduct_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductAdjustmentArgs = {
  objects: Array<PricedProductAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<PricedProductAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductAdjustment_OneArgs = {
  object: PricedProductAdjustment_Insert_Input;
  on_conflict?: InputMaybe<PricedProductAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductPricingFactorArgs = {
  objects: Array<PricedProductPricingFactor_Insert_Input>;
  on_conflict?: InputMaybe<PricedProductPricingFactor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductPricingFactorAdjustmentArgs = {
  objects: Array<PricedProductPricingFactorAdjustment_Insert_Input>;
  on_conflict?: InputMaybe<PricedProductPricingFactorAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductPricingFactorAdjustment_OneArgs = {
  object: PricedProductPricingFactorAdjustment_Insert_Input;
  on_conflict?: InputMaybe<PricedProductPricingFactorAdjustment_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProductPricingFactor_OneArgs = {
  object: PricedProductPricingFactor_Insert_Input;
  on_conflict?: InputMaybe<PricedProductPricingFactor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_PricedProduct_OneArgs = {
  object: PricedProduct_Insert_Input;
  on_conflict?: InputMaybe<PricedProduct_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProServiceManagedFieldArgs = {
  objects: Array<ProServiceManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ProServiceManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProServiceManagedField_OneArgs = {
  object: ProServiceManagedField_Insert_Input;
  on_conflict?: InputMaybe<ProServiceManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProductManagedFieldArgs = {
  objects: Array<ProductManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ProductManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProductManagedField_OneArgs = {
  object: ProductManagedField_Insert_Input;
  on_conflict?: InputMaybe<ProductManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProductPricingFactorArgs = {
  objects: Array<ProductPricingFactor_Insert_Input>;
  on_conflict?: InputMaybe<ProductPricingFactor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ProductPricingFactor_OneArgs = {
  object: ProductPricingFactor_Insert_Input;
  on_conflict?: InputMaybe<ProductPricingFactor_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RateCardManagedFieldArgs = {
  objects: Array<RateCardManagedField_Insert_Input>;
  on_conflict?: InputMaybe<RateCardManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RateCardManagedField_OneArgs = {
  object: RateCardManagedField_Insert_Input;
  on_conflict?: InputMaybe<RateCardManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RecurringCreditGrantArgs = {
  objects: Array<RecurringCreditGrant_Insert_Input>;
  on_conflict?: InputMaybe<RecurringCreditGrant_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RecurringCreditGrant_OneArgs = {
  object: RecurringCreditGrant_Insert_Input;
  on_conflict?: InputMaybe<RecurringCreditGrant_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: InputMaybe<Role_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RoundingBehaviorEnumArgs = {
  objects: Array<RoundingBehaviorEnum_Insert_Input>;
  on_conflict?: InputMaybe<RoundingBehaviorEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_RoundingBehaviorEnum_OneArgs = {
  object: RoundingBehaviorEnum_Insert_Input;
  on_conflict?: InputMaybe<RoundingBehaviorEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ScheduledChargeManagedFieldArgs = {
  objects: Array<ScheduledChargeManagedField_Insert_Input>;
  on_conflict?: InputMaybe<ScheduledChargeManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ScheduledChargeManagedField_OneArgs = {
  object: ScheduledChargeManagedField_Insert_Input;
  on_conflict?: InputMaybe<ScheduledChargeManagedField_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ServicePeriodStartOnEnumArgs = {
  objects: Array<ServicePeriodStartOnEnum_Insert_Input>;
  on_conflict?: InputMaybe<ServicePeriodStartOnEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_ServicePeriodStartOnEnum_OneArgs = {
  object: ServicePeriodStartOnEnum_Insert_Input;
  on_conflict?: InputMaybe<ServicePeriodStartOnEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_StripePrepaidConfig_TemporaryArgs = {
  objects: Array<StripePrepaidConfig_Temporary_Insert_Input>;
  on_conflict?: InputMaybe<StripePrepaidConfig_Temporary_On_Conflict>;
};


/** mutation root */
export type MutationInsert_StripePrepaidConfig_Temporary_OneArgs = {
  object: StripePrepaidConfig_Temporary_Insert_Input;
  on_conflict?: InputMaybe<StripePrepaidConfig_Temporary_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TieringModeEnumArgs = {
  objects: Array<TieringModeEnum_Insert_Input>;
  on_conflict?: InputMaybe<TieringModeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TieringModeEnum_OneArgs = {
  object: TieringModeEnum_Insert_Input;
  on_conflict?: InputMaybe<TieringModeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TokensArgs = {
  objects: Array<Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Tokens_OneArgs = {
  object: Tokens_Insert_Input;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TrialSpecArgs = {
  objects: Array<TrialSpec_Insert_Input>;
  on_conflict?: InputMaybe<TrialSpec_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TrialSpecSpendingCapArgs = {
  objects: Array<TrialSpecSpendingCap_Insert_Input>;
  on_conflict?: InputMaybe<TrialSpecSpendingCap_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TrialSpecSpendingCap_OneArgs = {
  object: TrialSpecSpendingCap_Insert_Input;
  on_conflict?: InputMaybe<TrialSpecSpendingCap_On_Conflict>;
};


/** mutation root */
export type MutationInsert_TrialSpec_OneArgs = {
  object: TrialSpec_Insert_Input;
  on_conflict?: InputMaybe<TrialSpec_On_Conflict>;
};


/** mutation root */
export type MutationInsert_UserAuthTypeEnumArgs = {
  objects: Array<UserAuthTypeEnum_Insert_Input>;
  on_conflict?: InputMaybe<UserAuthTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_UserAuthTypeEnum_OneArgs = {
  object: UserAuthTypeEnum_Insert_Input;
  on_conflict?: InputMaybe<UserAuthTypeEnum_On_Conflict>;
};


/** mutation root */
export type MutationInsert_User_DoNotUseArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type MutationInsert_User_One_DoNotUseArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type MutationInsert_WebhookArgs = {
  objects: Array<Webhook_Insert_Input>;
  on_conflict?: InputMaybe<Webhook_On_Conflict>;
};


/** mutation root */
export type MutationInsert_WebhookNotificationArgs = {
  objects: Array<WebhookNotification_Insert_Input>;
  on_conflict?: InputMaybe<WebhookNotification_On_Conflict>;
};


/** mutation root */
export type MutationInsert_WebhookNotification_OneArgs = {
  object: WebhookNotification_Insert_Input;
  on_conflict?: InputMaybe<WebhookNotification_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Webhook_OneArgs = {
  object: Webhook_Insert_Input;
  on_conflict?: InputMaybe<Webhook_On_Conflict>;
};


/** mutation root */
export type MutationInsert_Billing_Provider_Delivery_MethodArgs = {
  billing_provider: BillingProviderEnum_Enum;
  delivery_method: BillingProviderDeliveryMethod_Enum;
  delivery_method_configuration: Scalars['FlatKeyValueMap'];
};


/** mutation root */
export type MutationInsert_Customer_Billing_Provider_ConfigurationArgs = {
  customer_billing_provider_configuration_input: InsertCustomerBillingProviderConfigurationInput;
};


/** mutation root */
export type MutationInsert_Customer_Billing_Provider_Configuration_NotificationArgs = {
  customer_billing_provider_configuration_notification: CustomerBillingProviderConfigurationNotificationInput;
};


/** mutation root */
export type MutationInsert_Customer_Billing_Provider_ConfigurationsArgs = {
  customer_billing_provider_configuration_inputs: Array<InsertCustomerBillingProviderConfigurationInput>;
};


/** mutation root */
export type MutationInsert_Failed_Marketplaces_Metering_RecordArgs = {
  created_timestamp?: InputMaybe<Scalars['timestamptz']>;
  customer_id: Scalars['uuid'];
  marketplaces_metered_usage_id: Scalars['String'];
  meter_timestamp: Scalars['timestamptz'];
};


/** mutation root */
export type MutationLaunch_Sox_Report_GeneratorArgs = {
  end_date: Scalars['timestamptz'];
  report_email: Scalars['String'];
  start_date: Scalars['timestamptz'];
};


/** mutation root */
export type MutationMigrate_Plan_Credits_To_Contract_CommitsArgs = {
  contract_credit_priority_override?: InputMaybe<Scalars['numeric']>;
  customer_id: Scalars['uuid'];
  dry_run?: InputMaybe<Scalars['Boolean']>;
  expire_rolled_over_credits_after?: InputMaybe<Scalars['timestamptz']>;
  free_credits_product_id: Scalars['uuid'];
  grant_to_contract_commit_mapping?: InputMaybe<Array<ContractCommitMappingInput>>;
  prepaid_credits_product_id: Scalars['uuid'];
};


/** mutation root */
export type MutationMove_Rate_Card_ProductsArgs = {
  input: MoveRateCardProductsInput;
};


/** mutation root */
export type MutationMri_End_Customer_PlanArgs = {
  cancellation_date: Scalars['timestamptz'];
  customer_id: Scalars['uuid'];
  customer_plan_id: Scalars['uuid'];
  void_stripe_invoices: Scalars['Boolean'];
};


/** mutation root */
export type MutationMri_Finalize_Invoices_For_CustomerArgs = {
  customer_id: Scalars['uuid'];
  invoices_to_finalize_in_order: Array<Scalars['uuid']>;
};


/** mutation root */
export type MutationMri_Void_InvoiceArgs = {
  id: Scalars['uuid'];
  skip_invoking_heartbeat?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationPrepaid_Credit_Grant_RechargeArgs = {
  current_credit_balance: Scalars['numeric'];
  customer_id: Scalars['uuid'];
  recharge_id: Scalars['uuid'];
};


/** mutation root */
export type MutationProvision_Salesforce_Census_WorkspaceArgs = {
  only_linked_accounts: Scalars['Boolean'];
};


/** mutation root */
export type MutationRebuild_Contract_Billing_Provider_ProjectionsArgs = {
  customer_ids: Array<Scalars['uuid']>;
};


/** mutation root */
export type MutationRebuild_Rate_SchedulesArgs = {
  rate_card_id: Scalars['uuid'];
  selectors: Array<RebuildRateSegmentsSelector>;
};


/** mutation root */
export type MutationRegenerate_InvoiceArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRelease_Invoices_For_FinalizationArgs = {
  inputs: InvoiceFinalizationInput;
  preview?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationRelease_Prepaid_Credit_GrantArgs = {
  credit_grant_id: Scalars['uuid'];
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationRemove_CustomerArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRepair_Credit_Grant_RechargeArgs = {
  customer_id: Scalars['uuid'];
  recharge_id: Scalars['uuid'];
};


/** mutation root */
export type MutationRepair_Prepaid_Credit_GrantArgs = {
  credit_grant_id: Scalars['uuid'];
  try_to_void?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationSave_Vendor_Entity_MappingsArgs = {
  objects: Array<SaveVendorEntityMappingInput>;
};


/** mutation root */
export type MutationSend_Fake_Synthetic_Usage_EventArgs = {
  billable_metric_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  json_payload: Scalars['String'];
};


/** mutation root */
export type MutationSend_Invoice_To_StripeArgs = {
  invoice_id: Scalars['uuid'];
};


/** mutation root */
export type MutationSet_Customer_Ingest_AliasesArgs = {
  customer_id: Scalars['uuid'];
  ingest_aliases: Array<Scalars['String']>;
};


/** mutation root */
export type MutationSet_Customer_Plan_TrialArgs = {
  customer_plan_id: Scalars['uuid'];
  trial_spec: TrialSpecInput;
};


/** mutation root */
export type MutationStore_Azure_Marketplace_CredentialsArgs = {
  azure_client_id: Scalars['String'];
  azure_tenant_id: Scalars['String'];
  raw_azure_client_secret: Scalars['String'];
};


/** mutation root */
export type MutationStore_Client_Webhook_SecretArgs = {
  webhook_secret: Scalars['String'];
};


/** mutation root */
export type MutationStore_Stripe_CredentialsArgs = {
  code: Scalars['String'];
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
};


/** mutation root */
export type MutationStore_Stripe_Restricted_KeyArgs = {
  restricted_key: Scalars['String'];
};


/** mutation root */
export type MutationTest_WebhooksArgs = {
  message: Scalars['String'];
};


/** mutation root */
export type MutationUpdate_AccountTypeEnumArgs = {
  _set?: InputMaybe<AccountTypeEnum_Set_Input>;
  where: AccountTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_AccountTypeEnum_By_PkArgs = {
  _set?: InputMaybe<AccountTypeEnum_Set_Input>;
  pk_columns: AccountTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_AccountTypeEnum_ManyArgs = {
  updates: Array<AccountTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_ActorArgs = {
  _set?: InputMaybe<Actor_Set_Input>;
  where: Actor_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Actor_By_PkArgs = {
  _set?: InputMaybe<Actor_Set_Input>;
  pk_columns: Actor_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Actor_ManyArgs = {
  updates: Array<Actor_Updates>;
};


/** mutation root */
export type MutationUpdate_AlertArgs = {
  _append?: InputMaybe<Alert_Append_Input>;
  _delete_at_path?: InputMaybe<Alert_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Alert_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Alert_Delete_Key_Input>;
  _inc?: InputMaybe<Alert_Inc_Input>;
  _prepend?: InputMaybe<Alert_Prepend_Input>;
  _set?: InputMaybe<Alert_Set_Input>;
  where: Alert_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_AlertManagedFieldArgs = {
  _set?: InputMaybe<AlertManagedField_Set_Input>;
  where: AlertManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_AlertManagedField_By_PkArgs = {
  _set?: InputMaybe<AlertManagedField_Set_Input>;
  pk_columns: AlertManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_AlertManagedField_ManyArgs = {
  updates: Array<AlertManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_AlertTypeEnumArgs = {
  _set?: InputMaybe<AlertTypeEnum_Set_Input>;
  where: AlertTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_AlertTypeEnum_By_PkArgs = {
  _set?: InputMaybe<AlertTypeEnum_Set_Input>;
  pk_columns: AlertTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_AlertTypeEnum_ManyArgs = {
  updates: Array<AlertTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_Alert_By_PkArgs = {
  _append?: InputMaybe<Alert_Append_Input>;
  _delete_at_path?: InputMaybe<Alert_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Alert_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Alert_Delete_Key_Input>;
  _inc?: InputMaybe<Alert_Inc_Input>;
  _prepend?: InputMaybe<Alert_Prepend_Input>;
  _set?: InputMaybe<Alert_Set_Input>;
  pk_columns: Alert_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Alert_ManyArgs = {
  updates: Array<Alert_Updates>;
};


/** mutation root */
export type MutationUpdate_BillableMetricArgs = {
  _append?: InputMaybe<BillableMetric_Append_Input>;
  _delete_at_path?: InputMaybe<BillableMetric_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BillableMetric_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BillableMetric_Delete_Key_Input>;
  _prepend?: InputMaybe<BillableMetric_Prepend_Input>;
  _set?: InputMaybe<BillableMetric_Set_Input>;
  where: BillableMetric_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillableMetricManagedFieldArgs = {
  _set?: InputMaybe<BillableMetricManagedField_Set_Input>;
  where: BillableMetricManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillableMetricManagedField_By_PkArgs = {
  _set?: InputMaybe<BillableMetricManagedField_Set_Input>;
  pk_columns: BillableMetricManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillableMetricManagedField_ManyArgs = {
  updates: Array<BillableMetricManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_BillableMetric_By_PkArgs = {
  _append?: InputMaybe<BillableMetric_Append_Input>;
  _delete_at_path?: InputMaybe<BillableMetric_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<BillableMetric_Delete_Elem_Input>;
  _delete_key?: InputMaybe<BillableMetric_Delete_Key_Input>;
  _prepend?: InputMaybe<BillableMetric_Prepend_Input>;
  _set?: InputMaybe<BillableMetric_Set_Input>;
  pk_columns: BillableMetric_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillableMetric_ManyArgs = {
  updates: Array<BillableMetric_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingFrequencyEnumArgs = {
  _set?: InputMaybe<BillingFrequencyEnum_Set_Input>;
  where: BillingFrequencyEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingFrequencyEnum_By_PkArgs = {
  _set?: InputMaybe<BillingFrequencyEnum_Set_Input>;
  pk_columns: BillingFrequencyEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingFrequencyEnum_ManyArgs = {
  updates: Array<BillingFrequencyEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingMetricAggregateEnumArgs = {
  _set?: InputMaybe<BillingMetricAggregateEnum_Set_Input>;
  where: BillingMetricAggregateEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingMetricAggregateEnum_By_PkArgs = {
  _set?: InputMaybe<BillingMetricAggregateEnum_Set_Input>;
  pk_columns: BillingMetricAggregateEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingMetricAggregateEnum_ManyArgs = {
  updates: Array<BillingMetricAggregateEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingProviderCustomerArgs = {
  _set?: InputMaybe<BillingProviderCustomer_Set_Input>;
  where: BillingProviderCustomer_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingProviderCustomer_By_PkArgs = {
  _set?: InputMaybe<BillingProviderCustomer_Set_Input>;
  pk_columns: BillingProviderCustomer_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingProviderCustomer_ManyArgs = {
  updates: Array<BillingProviderCustomer_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingProviderEnumArgs = {
  _set?: InputMaybe<BillingProviderEnum_Set_Input>;
  where: BillingProviderEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingProviderEnum_By_PkArgs = {
  _set?: InputMaybe<BillingProviderEnum_Set_Input>;
  pk_columns: BillingProviderEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingProviderEnum_ManyArgs = {
  updates: Array<BillingProviderEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingProviderInvoiceArgs = {
  _set?: InputMaybe<BillingProviderInvoice_Set_Input>;
  where: BillingProviderInvoice_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingProviderInvoice_By_PkArgs = {
  _set?: InputMaybe<BillingProviderInvoice_Set_Input>;
  pk_columns: BillingProviderInvoice_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingProviderInvoice_ManyArgs = {
  updates: Array<BillingProviderInvoice_Updates>;
};


/** mutation root */
export type MutationUpdate_BillingProviderTokenArgs = {
  _set?: InputMaybe<BillingProviderToken_Set_Input>;
  where: BillingProviderToken_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_BillingProviderToken_By_PkArgs = {
  _set?: InputMaybe<BillingProviderToken_Set_Input>;
  pk_columns: BillingProviderToken_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_BillingProviderToken_ManyArgs = {
  updates: Array<BillingProviderToken_Updates>;
};


/** mutation root */
export type MutationUpdate_ChargeManagedFieldArgs = {
  _set?: InputMaybe<ChargeManagedField_Set_Input>;
  where: ChargeManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ChargeManagedField_By_PkArgs = {
  _set?: InputMaybe<ChargeManagedField_Set_Input>;
  pk_columns: ChargeManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ChargeManagedField_ManyArgs = {
  updates: Array<ChargeManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ChargeTypeEnumArgs = {
  _set?: InputMaybe<ChargeTypeEnum_Set_Input>;
  where: ChargeTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ChargeTypeEnum_By_PkArgs = {
  _set?: InputMaybe<ChargeTypeEnum_Set_Input>;
  pk_columns: ChargeTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ChargeTypeEnum_ManyArgs = {
  updates: Array<ChargeTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_ClientArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ClientConfigArgs = {
  _set?: InputMaybe<ClientConfig_Set_Input>;
  where: ClientConfig_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ClientConfigKeyEnumArgs = {
  _set?: InputMaybe<ClientConfigKeyEnum_Set_Input>;
  where: ClientConfigKeyEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ClientConfigKeyEnum_By_PkArgs = {
  _set?: InputMaybe<ClientConfigKeyEnum_Set_Input>;
  pk_columns: ClientConfigKeyEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ClientConfigKeyEnum_ManyArgs = {
  updates: Array<ClientConfigKeyEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_ClientConfig_By_PkArgs = {
  _set?: InputMaybe<ClientConfig_Set_Input>;
  pk_columns: ClientConfig_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ClientConfig_ManyArgs = {
  updates: Array<ClientConfig_Updates>;
};


/** mutation root */
export type MutationUpdate_Client_By_PkArgs = {
  _set?: InputMaybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>;
};


/** mutation root */
export type MutationUpdate_CollectionScheduleEnumArgs = {
  _set?: InputMaybe<CollectionScheduleEnum_Set_Input>;
  where: CollectionScheduleEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CollectionScheduleEnum_By_PkArgs = {
  _set?: InputMaybe<CollectionScheduleEnum_Set_Input>;
  pk_columns: CollectionScheduleEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CollectionScheduleEnum_ManyArgs = {
  updates: Array<CollectionScheduleEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_CommitManagedFieldArgs = {
  _set?: InputMaybe<CommitManagedField_Set_Input>;
  where: CommitManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CommitManagedField_By_PkArgs = {
  _set?: InputMaybe<CommitManagedField_Set_Input>;
  pk_columns: CommitManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CommitManagedField_ManyArgs = {
  updates: Array<CommitManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeChargeArgs = {
  _inc?: InputMaybe<CompositeCharge_Inc_Input>;
  _set?: InputMaybe<CompositeCharge_Set_Input>;
  where: CompositeCharge_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargeAdjustmentArgs = {
  _inc?: InputMaybe<CompositeChargeAdjustment_Inc_Input>;
  _set?: InputMaybe<CompositeChargeAdjustment_Set_Input>;
  where: CompositeChargeAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargeAdjustment_By_PkArgs = {
  _inc?: InputMaybe<CompositeChargeAdjustment_Inc_Input>;
  _set?: InputMaybe<CompositeChargeAdjustment_Set_Input>;
  pk_columns: CompositeChargeAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CompositeChargeAdjustment_ManyArgs = {
  updates: Array<CompositeChargeAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeChargePricingFactorArgs = {
  _set?: InputMaybe<CompositeChargePricingFactor_Set_Input>;
  where: CompositeChargePricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargePricingFactor_ManyArgs = {
  updates: Array<CompositeChargePricingFactor_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTierArgs = {
  _inc?: InputMaybe<CompositeChargeTier_Inc_Input>;
  _set?: InputMaybe<CompositeChargeTier_Set_Input>;
  where: CompositeChargeTier_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTierAdjustmentArgs = {
  _inc?: InputMaybe<CompositeChargeTierAdjustment_Inc_Input>;
  _set?: InputMaybe<CompositeChargeTierAdjustment_Set_Input>;
  where: CompositeChargeTierAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTierAdjustment_By_PkArgs = {
  _inc?: InputMaybe<CompositeChargeTierAdjustment_Inc_Input>;
  _set?: InputMaybe<CompositeChargeTierAdjustment_Set_Input>;
  pk_columns: CompositeChargeTierAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTierAdjustment_ManyArgs = {
  updates: Array<CompositeChargeTierAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTier_By_PkArgs = {
  _inc?: InputMaybe<CompositeChargeTier_Inc_Input>;
  _set?: InputMaybe<CompositeChargeTier_Set_Input>;
  pk_columns: CompositeChargeTier_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTier_ManyArgs = {
  updates: Array<CompositeChargeTier_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTypeEnumArgs = {
  _set?: InputMaybe<CompositeChargeTypeEnum_Set_Input>;
  where: CompositeChargeTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTypeEnum_By_PkArgs = {
  _set?: InputMaybe<CompositeChargeTypeEnum_Set_Input>;
  pk_columns: CompositeChargeTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CompositeChargeTypeEnum_ManyArgs = {
  updates: Array<CompositeChargeTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_CompositeCharge_By_PkArgs = {
  _inc?: InputMaybe<CompositeCharge_Inc_Input>;
  _set?: InputMaybe<CompositeCharge_Set_Input>;
  pk_columns: CompositeCharge_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CompositeCharge_ManyArgs = {
  updates: Array<CompositeCharge_Updates>;
};


/** mutation root */
export type MutationUpdate_ContractCreditManagedFieldArgs = {
  _set?: InputMaybe<ContractCreditManagedField_Set_Input>;
  where: ContractCreditManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ContractCreditManagedField_By_PkArgs = {
  _set?: InputMaybe<ContractCreditManagedField_Set_Input>;
  pk_columns: ContractCreditManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ContractCreditManagedField_ManyArgs = {
  updates: Array<ContractCreditManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ContractManagedFieldArgs = {
  _set?: InputMaybe<ContractManagedField_Set_Input>;
  where: ContractManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ContractManagedField_By_PkArgs = {
  _set?: InputMaybe<ContractManagedField_Set_Input>;
  pk_columns: ContractManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ContractManagedField_ManyArgs = {
  updates: Array<ContractManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ContractProductManagedFieldArgs = {
  _set?: InputMaybe<ContractProductManagedField_Set_Input>;
  where: ContractProductManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ContractProductManagedField_By_PkArgs = {
  _set?: InputMaybe<ContractProductManagedField_Set_Input>;
  pk_columns: ContractProductManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ContractProductManagedField_ManyArgs = {
  updates: Array<ContractProductManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditAdjustmentArgs = {
  _inc?: InputMaybe<CreditAdjustment_Inc_Input>;
  _set?: InputMaybe<CreditAdjustment_Set_Input>;
  where: CreditAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditAdjustment_By_PkArgs = {
  _inc?: InputMaybe<CreditAdjustment_Inc_Input>;
  _set?: InputMaybe<CreditAdjustment_Set_Input>;
  pk_columns: CreditAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditAdjustment_ManyArgs = {
  updates: Array<CreditAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditGrantArgs = {
  _append?: InputMaybe<CreditGrant_Append_Input>;
  _delete_at_path?: InputMaybe<CreditGrant_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CreditGrant_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CreditGrant_Delete_Key_Input>;
  _inc?: InputMaybe<CreditGrant_Inc_Input>;
  _prepend?: InputMaybe<CreditGrant_Prepend_Input>;
  _set?: InputMaybe<CreditGrant_Set_Input>;
  where: CreditGrant_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditGrantDeductionArgs = {
  _inc?: InputMaybe<CreditGrantDeduction_Inc_Input>;
  _set?: InputMaybe<CreditGrantDeduction_Set_Input>;
  where: CreditGrantDeduction_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditGrantDeduction_By_PkArgs = {
  _inc?: InputMaybe<CreditGrantDeduction_Inc_Input>;
  _set?: InputMaybe<CreditGrantDeduction_Set_Input>;
  pk_columns: CreditGrantDeduction_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditGrantDeduction_ManyArgs = {
  updates: Array<CreditGrantDeduction_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditGrantHistoryArgs = {
  _append?: InputMaybe<CreditGrantHistory_Append_Input>;
  _delete_at_path?: InputMaybe<CreditGrantHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CreditGrantHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CreditGrantHistory_Delete_Key_Input>;
  _inc?: InputMaybe<CreditGrantHistory_Inc_Input>;
  _prepend?: InputMaybe<CreditGrantHistory_Prepend_Input>;
  _set?: InputMaybe<CreditGrantHistory_Set_Input>;
  where: CreditGrantHistory_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditGrantHistory_By_PkArgs = {
  _append?: InputMaybe<CreditGrantHistory_Append_Input>;
  _delete_at_path?: InputMaybe<CreditGrantHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CreditGrantHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CreditGrantHistory_Delete_Key_Input>;
  _inc?: InputMaybe<CreditGrantHistory_Inc_Input>;
  _prepend?: InputMaybe<CreditGrantHistory_Prepend_Input>;
  _set?: InputMaybe<CreditGrantHistory_Set_Input>;
  pk_columns: CreditGrantHistory_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditGrantHistory_ManyArgs = {
  updates: Array<CreditGrantHistory_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditGrantManagedFieldArgs = {
  _set?: InputMaybe<CreditGrantManagedField_Set_Input>;
  where: CreditGrantManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditGrantManagedField_By_PkArgs = {
  _set?: InputMaybe<CreditGrantManagedField_Set_Input>;
  pk_columns: CreditGrantManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditGrantManagedField_ManyArgs = {
  updates: Array<CreditGrantManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditGrant_By_PkArgs = {
  _append?: InputMaybe<CreditGrant_Append_Input>;
  _delete_at_path?: InputMaybe<CreditGrant_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CreditGrant_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CreditGrant_Delete_Key_Input>;
  _inc?: InputMaybe<CreditGrant_Inc_Input>;
  _prepend?: InputMaybe<CreditGrant_Prepend_Input>;
  _set?: InputMaybe<CreditGrant_Set_Input>;
  pk_columns: CreditGrant_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditGrant_ManyArgs = {
  updates: Array<CreditGrant_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditTypeArgs = {
  _set?: InputMaybe<CreditType_Set_Input>;
  where: CreditType_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversionArgs = {
  _inc?: InputMaybe<CreditTypeConversion_Inc_Input>;
  _set?: InputMaybe<CreditTypeConversion_Set_Input>;
  where: CreditTypeConversion_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversionAdjustmentArgs = {
  _inc?: InputMaybe<CreditTypeConversionAdjustment_Inc_Input>;
  _set?: InputMaybe<CreditTypeConversionAdjustment_Set_Input>;
  where: CreditTypeConversionAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversionAdjustment_By_PkArgs = {
  _inc?: InputMaybe<CreditTypeConversionAdjustment_Inc_Input>;
  _set?: InputMaybe<CreditTypeConversionAdjustment_Set_Input>;
  pk_columns: CreditTypeConversionAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversionAdjustment_ManyArgs = {
  updates: Array<CreditTypeConversionAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversion_By_PkArgs = {
  _inc?: InputMaybe<CreditTypeConversion_Inc_Input>;
  _set?: InputMaybe<CreditTypeConversion_Set_Input>;
  pk_columns: CreditTypeConversion_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditTypeConversion_ManyArgs = {
  updates: Array<CreditTypeConversion_Updates>;
};


/** mutation root */
export type MutationUpdate_CreditType_By_PkArgs = {
  _set?: InputMaybe<CreditType_Set_Input>;
  pk_columns: CreditType_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CreditType_ManyArgs = {
  updates: Array<CreditType_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomPricingArgs = {
  _set?: InputMaybe<CustomPricing_Set_Input>;
  where: CustomPricing_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomPricing_By_PkArgs = {
  _set?: InputMaybe<CustomPricing_Set_Input>;
  pk_columns: CustomPricing_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomPricing_ManyArgs = {
  updates: Array<CustomPricing_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerArgs = {
  _set?: InputMaybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerAlertArgs = {
  _append?: InputMaybe<CustomerAlert_Append_Input>;
  _delete_at_path?: InputMaybe<CustomerAlert_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomerAlert_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomerAlert_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomerAlert_Prepend_Input>;
  _set?: InputMaybe<CustomerAlert_Set_Input>;
  where: CustomerAlert_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerAlertHistoryArgs = {
  _append?: InputMaybe<CustomerAlertHistory_Append_Input>;
  _delete_at_path?: InputMaybe<CustomerAlertHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomerAlertHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomerAlertHistory_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomerAlertHistory_Prepend_Input>;
  _set?: InputMaybe<CustomerAlertHistory_Set_Input>;
  where: CustomerAlertHistory_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerAlertHistory_By_PkArgs = {
  _append?: InputMaybe<CustomerAlertHistory_Append_Input>;
  _delete_at_path?: InputMaybe<CustomerAlertHistory_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomerAlertHistory_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomerAlertHistory_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomerAlertHistory_Prepend_Input>;
  _set?: InputMaybe<CustomerAlertHistory_Set_Input>;
  pk_columns: CustomerAlertHistory_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerAlertHistory_ManyArgs = {
  updates: Array<CustomerAlertHistory_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerAlertStatusEnumArgs = {
  _set?: InputMaybe<CustomerAlertStatusEnum_Set_Input>;
  where: CustomerAlertStatusEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerAlertStatusEnum_By_PkArgs = {
  _set?: InputMaybe<CustomerAlertStatusEnum_Set_Input>;
  pk_columns: CustomerAlertStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerAlertStatusEnum_ManyArgs = {
  updates: Array<CustomerAlertStatusEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerAlert_By_PkArgs = {
  _append?: InputMaybe<CustomerAlert_Append_Input>;
  _delete_at_path?: InputMaybe<CustomerAlert_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<CustomerAlert_Delete_Elem_Input>;
  _delete_key?: InputMaybe<CustomerAlert_Delete_Key_Input>;
  _prepend?: InputMaybe<CustomerAlert_Prepend_Input>;
  _set?: InputMaybe<CustomerAlert_Set_Input>;
  pk_columns: CustomerAlert_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerAlert_ManyArgs = {
  updates: Array<CustomerAlert_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerConfigArgs = {
  _set?: InputMaybe<CustomerConfig_Set_Input>;
  where: CustomerConfig_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerConfig_By_PkArgs = {
  _set?: InputMaybe<CustomerConfig_Set_Input>;
  pk_columns: CustomerConfig_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerConfig_ManyArgs = {
  updates: Array<CustomerConfig_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerIngestAliasArgs = {
  _set?: InputMaybe<CustomerIngestAlias_Set_Input>;
  where: CustomerIngestAlias_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerIngestAlias_By_PkArgs = {
  _set?: InputMaybe<CustomerIngestAlias_Set_Input>;
  pk_columns: CustomerIngestAlias_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerIngestAlias_ManyArgs = {
  updates: Array<CustomerIngestAlias_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerManagedFieldArgs = {
  _set?: InputMaybe<CustomerManagedField_Set_Input>;
  where: CustomerManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerManagedField_By_PkArgs = {
  _set?: InputMaybe<CustomerManagedField_Set_Input>;
  pk_columns: CustomerManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerManagedField_ManyArgs = {
  updates: Array<CustomerManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerPlanArgs = {
  _inc?: InputMaybe<CustomerPlan_Inc_Input>;
  _set?: InputMaybe<CustomerPlan_Set_Input>;
  where: CustomerPlan_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerPlanAdjustmentArgs = {
  _inc?: InputMaybe<CustomerPlanAdjustment_Inc_Input>;
  _set?: InputMaybe<CustomerPlanAdjustment_Set_Input>;
  where: CustomerPlanAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerPlanAdjustment_By_PkArgs = {
  _inc?: InputMaybe<CustomerPlanAdjustment_Inc_Input>;
  _set?: InputMaybe<CustomerPlanAdjustment_Set_Input>;
  pk_columns: CustomerPlanAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerPlanAdjustment_ManyArgs = {
  updates: Array<CustomerPlanAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerPlanManagedFieldArgs = {
  _set?: InputMaybe<CustomerPlanManagedField_Set_Input>;
  where: CustomerPlanManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerPlanManagedField_By_PkArgs = {
  _set?: InputMaybe<CustomerPlanManagedField_Set_Input>;
  pk_columns: CustomerPlanManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerPlanManagedField_ManyArgs = {
  updates: Array<CustomerPlanManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerPlanOneTimeChargeArgs = {
  _inc?: InputMaybe<CustomerPlanOneTimeCharge_Inc_Input>;
  _set?: InputMaybe<CustomerPlanOneTimeCharge_Set_Input>;
  where: CustomerPlanOneTimeCharge_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerPlanOneTimeCharge_By_PkArgs = {
  _inc?: InputMaybe<CustomerPlanOneTimeCharge_Inc_Input>;
  _set?: InputMaybe<CustomerPlanOneTimeCharge_Set_Input>;
  pk_columns: CustomerPlanOneTimeCharge_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerPlanOneTimeCharge_ManyArgs = {
  updates: Array<CustomerPlanOneTimeCharge_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerPlan_By_PkArgs = {
  _inc?: InputMaybe<CustomerPlan_Inc_Input>;
  _set?: InputMaybe<CustomerPlan_Set_Input>;
  pk_columns: CustomerPlan_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerPlan_ManyArgs = {
  updates: Array<CustomerPlan_Updates>;
};


/** mutation root */
export type MutationUpdate_CustomerRelationshipArgs = {
  _set?: InputMaybe<CustomerRelationship_Set_Input>;
  where: CustomerRelationship_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_CustomerRelationship_By_PkArgs = {
  _set?: InputMaybe<CustomerRelationship_Set_Input>;
  pk_columns: CustomerRelationship_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_CustomerRelationship_ManyArgs = {
  updates: Array<CustomerRelationship_Updates>;
};


/** mutation root */
export type MutationUpdate_Customer_By_PkArgs = {
  _set?: InputMaybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Customer_ManyArgs = {
  updates: Array<Customer_Updates>;
};


/** mutation root */
export type MutationUpdate_DraftPlanArgs = {
  _append?: InputMaybe<DraftPlan_Append_Input>;
  _delete_at_path?: InputMaybe<DraftPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<DraftPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<DraftPlan_Delete_Key_Input>;
  _inc?: InputMaybe<DraftPlan_Inc_Input>;
  _prepend?: InputMaybe<DraftPlan_Prepend_Input>;
  _set?: InputMaybe<DraftPlan_Set_Input>;
  where: DraftPlan_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_DraftPlan_By_PkArgs = {
  _append?: InputMaybe<DraftPlan_Append_Input>;
  _delete_at_path?: InputMaybe<DraftPlan_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<DraftPlan_Delete_Elem_Input>;
  _delete_key?: InputMaybe<DraftPlan_Delete_Key_Input>;
  _inc?: InputMaybe<DraftPlan_Inc_Input>;
  _prepend?: InputMaybe<DraftPlan_Prepend_Input>;
  _set?: InputMaybe<DraftPlan_Set_Input>;
  pk_columns: DraftPlan_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_DraftPlan_ManyArgs = {
  updates: Array<DraftPlan_Updates>;
};


/** mutation root */
export type MutationUpdate_EnvironmentTypeEnumArgs = {
  _set?: InputMaybe<EnvironmentTypeEnum_Set_Input>;
  where: EnvironmentTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_EnvironmentTypeEnum_By_PkArgs = {
  _set?: InputMaybe<EnvironmentTypeEnum_Set_Input>;
  pk_columns: EnvironmentTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_EnvironmentTypeEnum_ManyArgs = {
  updates: Array<EnvironmentTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_FlatFeeArgs = {
  _inc?: InputMaybe<FlatFee_Inc_Input>;
  _set?: InputMaybe<FlatFee_Set_Input>;
  where: FlatFee_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_FlatFeeAdjustmentArgs = {
  _inc?: InputMaybe<FlatFeeAdjustment_Inc_Input>;
  _set?: InputMaybe<FlatFeeAdjustment_Set_Input>;
  where: FlatFeeAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_FlatFeeAdjustment_By_PkArgs = {
  _inc?: InputMaybe<FlatFeeAdjustment_Inc_Input>;
  _set?: InputMaybe<FlatFeeAdjustment_Set_Input>;
  pk_columns: FlatFeeAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_FlatFeeAdjustment_ManyArgs = {
  updates: Array<FlatFeeAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_FlatFee_By_PkArgs = {
  _inc?: InputMaybe<FlatFee_Inc_Input>;
  _set?: InputMaybe<FlatFee_Set_Input>;
  pk_columns: FlatFee_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_FlatFee_ManyArgs = {
  updates: Array<FlatFee_Updates>;
};


/** mutation root */
export type MutationUpdate_InvoiceExternalStatusEnumArgs = {
  _set?: InputMaybe<InvoiceExternalStatusEnum_Set_Input>;
  where: InvoiceExternalStatusEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_InvoiceExternalStatusEnum_By_PkArgs = {
  _set?: InputMaybe<InvoiceExternalStatusEnum_Set_Input>;
  pk_columns: InvoiceExternalStatusEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_InvoiceExternalStatusEnum_ManyArgs = {
  updates: Array<InvoiceExternalStatusEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_InvoiceManagedFieldArgs = {
  _set?: InputMaybe<InvoiceManagedField_Set_Input>;
  where: InvoiceManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_InvoiceManagedField_By_PkArgs = {
  _set?: InputMaybe<InvoiceManagedField_Set_Input>;
  pk_columns: InvoiceManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_InvoiceManagedField_ManyArgs = {
  updates: Array<InvoiceManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ManagedEntityEnumArgs = {
  _set?: InputMaybe<ManagedEntityEnum_Set_Input>;
  where: ManagedEntityEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ManagedEntityEnum_By_PkArgs = {
  _set?: InputMaybe<ManagedEntityEnum_Set_Input>;
  pk_columns: ManagedEntityEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ManagedEntityEnum_ManyArgs = {
  updates: Array<ManagedEntityEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_ManagedFieldKeyArgs = {
  _set?: InputMaybe<ManagedFieldKey_Set_Input>;
  where: ManagedFieldKey_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ManagedFieldKey_By_PkArgs = {
  _set?: InputMaybe<ManagedFieldKey_Set_Input>;
  pk_columns: ManagedFieldKey_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ManagedFieldKey_ManyArgs = {
  updates: Array<ManagedFieldKey_Updates>;
};


/** mutation root */
export type MutationUpdate_ManagedFieldVendorMappingArgs = {
  _set?: InputMaybe<ManagedFieldVendorMapping_Set_Input>;
  where: ManagedFieldVendorMapping_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ManagedFieldVendorMapping_By_PkArgs = {
  _set?: InputMaybe<ManagedFieldVendorMapping_Set_Input>;
  pk_columns: ManagedFieldVendorMapping_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ManagedFieldVendorMapping_ManyArgs = {
  updates: Array<ManagedFieldVendorMapping_Updates>;
};


/** mutation root */
export type MutationUpdate_ManagedVendorEnumArgs = {
  _set?: InputMaybe<ManagedVendorEnum_Set_Input>;
  where: ManagedVendorEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ManagedVendorEnum_By_PkArgs = {
  _set?: InputMaybe<ManagedVendorEnum_Set_Input>;
  pk_columns: ManagedVendorEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ManagedVendorEnum_ManyArgs = {
  updates: Array<ManagedVendorEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_MinimumArgs = {
  _inc?: InputMaybe<Minimum_Inc_Input>;
  _set?: InputMaybe<Minimum_Set_Input>;
  where: Minimum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Minimum_By_PkArgs = {
  _inc?: InputMaybe<Minimum_Inc_Input>;
  _set?: InputMaybe<Minimum_Set_Input>;
  pk_columns: Minimum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Minimum_ManyArgs = {
  updates: Array<Minimum_Updates>;
};


/** mutation root */
export type MutationUpdate_PlanArgs = {
  _inc?: InputMaybe<Plan_Inc_Input>;
  _set?: InputMaybe<Plan_Set_Input>;
  where: Plan_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PlanManagedFieldArgs = {
  _set?: InputMaybe<PlanManagedField_Set_Input>;
  where: PlanManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PlanManagedField_By_PkArgs = {
  _set?: InputMaybe<PlanManagedField_Set_Input>;
  pk_columns: PlanManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PlanManagedField_ManyArgs = {
  updates: Array<PlanManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_Plan_By_PkArgs = {
  _inc?: InputMaybe<Plan_Inc_Input>;
  _set?: InputMaybe<Plan_Set_Input>;
  pk_columns: Plan_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Plan_ManyArgs = {
  updates: Array<Plan_Updates>;
};


/** mutation root */
export type MutationUpdate_PriceArgs = {
  _inc?: InputMaybe<Price_Inc_Input>;
  _set?: InputMaybe<Price_Set_Input>;
  where: Price_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PriceAdjustmentArgs = {
  _inc?: InputMaybe<PriceAdjustment_Inc_Input>;
  _set?: InputMaybe<PriceAdjustment_Set_Input>;
  where: PriceAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PriceAdjustmentTypeEnumArgs = {
  _set?: InputMaybe<PriceAdjustmentTypeEnum_Set_Input>;
  where: PriceAdjustmentTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PriceAdjustmentTypeEnum_By_PkArgs = {
  _set?: InputMaybe<PriceAdjustmentTypeEnum_Set_Input>;
  pk_columns: PriceAdjustmentTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PriceAdjustmentTypeEnum_ManyArgs = {
  updates: Array<PriceAdjustmentTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_PriceAdjustment_By_PkArgs = {
  _inc?: InputMaybe<PriceAdjustment_Inc_Input>;
  _set?: InputMaybe<PriceAdjustment_Set_Input>;
  pk_columns: PriceAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PriceAdjustment_ManyArgs = {
  updates: Array<PriceAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_Price_By_PkArgs = {
  _inc?: InputMaybe<Price_Inc_Input>;
  _set?: InputMaybe<Price_Set_Input>;
  pk_columns: Price_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Price_ManyArgs = {
  updates: Array<Price_Updates>;
};


/** mutation root */
export type MutationUpdate_PricedProductArgs = {
  _inc?: InputMaybe<PricedProduct_Inc_Input>;
  _set?: InputMaybe<PricedProduct_Set_Input>;
  where: PricedProduct_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PricedProductAdjustmentArgs = {
  _inc?: InputMaybe<PricedProductAdjustment_Inc_Input>;
  _set?: InputMaybe<PricedProductAdjustment_Set_Input>;
  where: PricedProductAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PricedProductAdjustment_By_PkArgs = {
  _inc?: InputMaybe<PricedProductAdjustment_Inc_Input>;
  _set?: InputMaybe<PricedProductAdjustment_Set_Input>;
  pk_columns: PricedProductAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PricedProductAdjustment_ManyArgs = {
  updates: Array<PricedProductAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactorArgs = {
  _inc?: InputMaybe<PricedProductPricingFactor_Inc_Input>;
  _set?: InputMaybe<PricedProductPricingFactor_Set_Input>;
  where: PricedProductPricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactorAdjustmentArgs = {
  _inc?: InputMaybe<PricedProductPricingFactorAdjustment_Inc_Input>;
  _set?: InputMaybe<PricedProductPricingFactorAdjustment_Set_Input>;
  where: PricedProductPricingFactorAdjustment_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactorAdjustment_By_PkArgs = {
  _inc?: InputMaybe<PricedProductPricingFactorAdjustment_Inc_Input>;
  _set?: InputMaybe<PricedProductPricingFactorAdjustment_Set_Input>;
  pk_columns: PricedProductPricingFactorAdjustment_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactorAdjustment_ManyArgs = {
  updates: Array<PricedProductPricingFactorAdjustment_Updates>;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactor_By_PkArgs = {
  _inc?: InputMaybe<PricedProductPricingFactor_Inc_Input>;
  _set?: InputMaybe<PricedProductPricingFactor_Set_Input>;
  pk_columns: PricedProductPricingFactor_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PricedProductPricingFactor_ManyArgs = {
  updates: Array<PricedProductPricingFactor_Updates>;
};


/** mutation root */
export type MutationUpdate_PricedProduct_By_PkArgs = {
  _inc?: InputMaybe<PricedProduct_Inc_Input>;
  _set?: InputMaybe<PricedProduct_Set_Input>;
  pk_columns: PricedProduct_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_PricedProduct_ManyArgs = {
  updates: Array<PricedProduct_Updates>;
};


/** mutation root */
export type MutationUpdate_ProServiceManagedFieldArgs = {
  _set?: InputMaybe<ProServiceManagedField_Set_Input>;
  where: ProServiceManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ProServiceManagedField_By_PkArgs = {
  _set?: InputMaybe<ProServiceManagedField_Set_Input>;
  pk_columns: ProServiceManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ProServiceManagedField_ManyArgs = {
  updates: Array<ProServiceManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ProductArgs = {
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ProductManagedFieldArgs = {
  _set?: InputMaybe<ProductManagedField_Set_Input>;
  where: ProductManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ProductManagedField_By_PkArgs = {
  _set?: InputMaybe<ProductManagedField_Set_Input>;
  pk_columns: ProductManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ProductManagedField_ManyArgs = {
  updates: Array<ProductManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ProductPricingFactorArgs = {
  _inc?: InputMaybe<ProductPricingFactor_Inc_Input>;
  _set?: InputMaybe<ProductPricingFactor_Set_Input>;
  where: ProductPricingFactor_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ProductPricingFactor_By_PkArgs = {
  _inc?: InputMaybe<ProductPricingFactor_Inc_Input>;
  _set?: InputMaybe<ProductPricingFactor_Set_Input>;
  pk_columns: ProductPricingFactor_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ProductPricingFactor_ManyArgs = {
  updates: Array<ProductPricingFactor_Updates>;
};


/** mutation root */
export type MutationUpdate_Product_By_PkArgs = {
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type MutationUpdate_RateCardManagedFieldArgs = {
  _set?: InputMaybe<RateCardManagedField_Set_Input>;
  where: RateCardManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_RateCardManagedField_By_PkArgs = {
  _set?: InputMaybe<RateCardManagedField_Set_Input>;
  pk_columns: RateCardManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_RateCardManagedField_ManyArgs = {
  updates: Array<RateCardManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_RecurringCreditGrantArgs = {
  _inc?: InputMaybe<RecurringCreditGrant_Inc_Input>;
  _set?: InputMaybe<RecurringCreditGrant_Set_Input>;
  where: RecurringCreditGrant_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_RecurringCreditGrant_By_PkArgs = {
  _inc?: InputMaybe<RecurringCreditGrant_Inc_Input>;
  _set?: InputMaybe<RecurringCreditGrant_Set_Input>;
  pk_columns: RecurringCreditGrant_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_RecurringCreditGrant_ManyArgs = {
  updates: Array<RecurringCreditGrant_Updates>;
};


/** mutation root */
export type MutationUpdate_RoleArgs = {
  _append?: InputMaybe<Role_Append_Input>;
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  _prepend?: InputMaybe<Role_Prepend_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Role_By_PkArgs = {
  _append?: InputMaybe<Role_Append_Input>;
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  _prepend?: InputMaybe<Role_Prepend_Input>;
  _set?: InputMaybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type MutationUpdate_RoundingBehaviorEnumArgs = {
  _set?: InputMaybe<RoundingBehaviorEnum_Set_Input>;
  where: RoundingBehaviorEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_RoundingBehaviorEnum_By_PkArgs = {
  _set?: InputMaybe<RoundingBehaviorEnum_Set_Input>;
  pk_columns: RoundingBehaviorEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_RoundingBehaviorEnum_ManyArgs = {
  updates: Array<RoundingBehaviorEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_ScheduledChargeManagedFieldArgs = {
  _set?: InputMaybe<ScheduledChargeManagedField_Set_Input>;
  where: ScheduledChargeManagedField_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ScheduledChargeManagedField_By_PkArgs = {
  _set?: InputMaybe<ScheduledChargeManagedField_Set_Input>;
  pk_columns: ScheduledChargeManagedField_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ScheduledChargeManagedField_ManyArgs = {
  updates: Array<ScheduledChargeManagedField_Updates>;
};


/** mutation root */
export type MutationUpdate_ServicePeriodStartOnEnumArgs = {
  _set?: InputMaybe<ServicePeriodStartOnEnum_Set_Input>;
  where: ServicePeriodStartOnEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_ServicePeriodStartOnEnum_By_PkArgs = {
  _set?: InputMaybe<ServicePeriodStartOnEnum_Set_Input>;
  pk_columns: ServicePeriodStartOnEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_ServicePeriodStartOnEnum_ManyArgs = {
  updates: Array<ServicePeriodStartOnEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_StripePrepaidConfig_TemporaryArgs = {
  _set?: InputMaybe<StripePrepaidConfig_Temporary_Set_Input>;
  where: StripePrepaidConfig_Temporary_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_StripePrepaidConfig_Temporary_By_PkArgs = {
  _set?: InputMaybe<StripePrepaidConfig_Temporary_Set_Input>;
  pk_columns: StripePrepaidConfig_Temporary_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_StripePrepaidConfig_Temporary_ManyArgs = {
  updates: Array<StripePrepaidConfig_Temporary_Updates>;
};


/** mutation root */
export type MutationUpdate_TieringModeEnumArgs = {
  _set?: InputMaybe<TieringModeEnum_Set_Input>;
  where: TieringModeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_TieringModeEnum_By_PkArgs = {
  _set?: InputMaybe<TieringModeEnum_Set_Input>;
  pk_columns: TieringModeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_TieringModeEnum_ManyArgs = {
  updates: Array<TieringModeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_TokensArgs = {
  _set?: InputMaybe<Tokens_Set_Input>;
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_Tokens_By_PkArgs = {
  _set?: InputMaybe<Tokens_Set_Input>;
  pk_columns: Tokens_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Tokens_ManyArgs = {
  updates: Array<Tokens_Updates>;
};


/** mutation root */
export type MutationUpdate_TrialSpecArgs = {
  _inc?: InputMaybe<TrialSpec_Inc_Input>;
  _set?: InputMaybe<TrialSpec_Set_Input>;
  where: TrialSpec_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_TrialSpecSpendingCapArgs = {
  _inc?: InputMaybe<TrialSpecSpendingCap_Inc_Input>;
  _set?: InputMaybe<TrialSpecSpendingCap_Set_Input>;
  where: TrialSpecSpendingCap_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_TrialSpecSpendingCap_By_PkArgs = {
  _inc?: InputMaybe<TrialSpecSpendingCap_Inc_Input>;
  _set?: InputMaybe<TrialSpecSpendingCap_Set_Input>;
  pk_columns: TrialSpecSpendingCap_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_TrialSpecSpendingCap_ManyArgs = {
  updates: Array<TrialSpecSpendingCap_Updates>;
};


/** mutation root */
export type MutationUpdate_TrialSpec_By_PkArgs = {
  _inc?: InputMaybe<TrialSpec_Inc_Input>;
  _set?: InputMaybe<TrialSpec_Set_Input>;
  pk_columns: TrialSpec_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_TrialSpec_ManyArgs = {
  updates: Array<TrialSpec_Updates>;
};


/** mutation root */
export type MutationUpdate_UserAuthTypeEnumArgs = {
  _set?: InputMaybe<UserAuthTypeEnum_Set_Input>;
  where: UserAuthTypeEnum_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_UserAuthTypeEnum_By_PkArgs = {
  _set?: InputMaybe<UserAuthTypeEnum_Set_Input>;
  pk_columns: UserAuthTypeEnum_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_UserAuthTypeEnum_ManyArgs = {
  updates: Array<UserAuthTypeEnum_Updates>;
};


/** mutation root */
export type MutationUpdate_User_DoNotUseArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type MutationUpdate_WebhookArgs = {
  _set?: InputMaybe<Webhook_Set_Input>;
  where: Webhook_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_WebhookNotificationArgs = {
  _append?: InputMaybe<WebhookNotification_Append_Input>;
  _delete_at_path?: InputMaybe<WebhookNotification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WebhookNotification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WebhookNotification_Delete_Key_Input>;
  _inc?: InputMaybe<WebhookNotification_Inc_Input>;
  _prepend?: InputMaybe<WebhookNotification_Prepend_Input>;
  _set?: InputMaybe<WebhookNotification_Set_Input>;
  where: WebhookNotification_Bool_Exp;
};


/** mutation root */
export type MutationUpdate_WebhookNotification_By_PkArgs = {
  _append?: InputMaybe<WebhookNotification_Append_Input>;
  _delete_at_path?: InputMaybe<WebhookNotification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<WebhookNotification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<WebhookNotification_Delete_Key_Input>;
  _inc?: InputMaybe<WebhookNotification_Inc_Input>;
  _prepend?: InputMaybe<WebhookNotification_Prepend_Input>;
  _set?: InputMaybe<WebhookNotification_Set_Input>;
  pk_columns: WebhookNotification_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_WebhookNotification_ManyArgs = {
  updates: Array<WebhookNotification_Updates>;
};


/** mutation root */
export type MutationUpdate_Webhook_By_PkArgs = {
  _set?: InputMaybe<Webhook_Set_Input>;
  pk_columns: Webhook_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Webhook_ManyArgs = {
  updates: Array<Webhook_Updates>;
};


/** mutation root */
export type MutationUpdate_Billing_Provider_SettingsArgs = {
  billing_provider: BillingProviderEnum_Enum;
  settings: UpdateBillingProviderSettingsInput;
};


/** mutation root */
export type MutationUpdate_By_Pk_User_DoNotUseArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type MutationUpdate_Client_Billing_Provider_ConfigurationsArgs = {
  billing_provider: BillingProviderEnum_Enum;
  configuration: Scalars['FlatKeyValueMap'];
  delivery_method: BillingProviderDeliveryMethod_Enum;
};


/** mutation root */
export type MutationUpdate_Commit_ScheduleArgs = {
  amendment_id?: InputMaybe<Scalars['uuid']>;
  commit_id: Scalars['uuid'];
  contract_id?: InputMaybe<Scalars['uuid']>;
  customer_id: Scalars['uuid'];
  preview?: InputMaybe<Scalars['Boolean']>;
  update: CommitScheduleUpdateInput;
};


/** mutation root */
export type MutationUpdate_Contract_End_DateArgs = {
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
};


/** mutation root */
export type MutationUpdate_Contract_Invoice_DateArgs = {
  invoice_id: Scalars['uuid'];
  new_invoice_date: Scalars['timestamptz'];
};


/** mutation root */
export type MutationUpdate_Contract_Named_ScheduleArgs = {
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  schedule_name: Scalars['String'];
  starting_at: Scalars['timestamptz'];
  value: Scalars['JSON'];
};


/** mutation root */
export type MutationUpdate_Credit_GrantArgs = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  expires_before: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  rollover_settings?: InputMaybe<RolloverSettingsInput>;
};


/** mutation root */
export type MutationUpdate_Customer_Billable_StatusArgs = {
  billable_status: BillableStatus;
  customer_id: Scalars['uuid'];
  effective_at: Scalars['timestamptz'];
};


/** mutation root */
export type MutationUpdate_Customer_Named_ScheduleArgs = {
  customer_id: Scalars['uuid'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  schedule_name: Scalars['String'];
  starting_at: Scalars['timestamptz'];
  value: Scalars['JSON'];
};


/** mutation root */
export type MutationUpdate_EmailArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type MutationUpdate_Invoice_Finalization_Metadata_StatusArgs = {
  id: Scalars['uuid'];
  status: InvoiceFinalizationMetadataStatus;
};


/** mutation root */
export type MutationUpdate_NameArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type MutationUpdate_Product_List_ItemArgs = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  composite_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  composite_tags?: InputMaybe<Array<Scalars['String']>>;
  effective_at: Scalars['timestamptz'];
  exclude_free_usage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  is_refundable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_internal_item_id?: InputMaybe<Scalars['String']>;
  netsuite_overage_item_id?: InputMaybe<Scalars['String']>;
  presentation_group_key?: InputMaybe<Array<Scalars['String']>>;
  pricing_group_key?: InputMaybe<Array<Scalars['String']>>;
  quantity_conversion?: InputMaybe<QuantityConversionInput>;
  quantity_rounding?: InputMaybe<QuantityRoundingInput>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


/** mutation root */
export type MutationUpdate_Rate_CardArgs = {
  additionalRates?: InputMaybe<Array<RateCardEntryInput>>;
  aliases?: InputMaybe<Array<RateCardAliasInput>>;
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationUpdate_Rate_Card_Named_ScheduleArgs = {
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  rate_card_id: Scalars['uuid'];
  schedule_name: Scalars['String'];
  starting_at: Scalars['timestamptz'];
  value: Scalars['JSON'];
};


/** mutation root */
export type MutationUpdate_Recharge_SettingsArgs = {
  id: Scalars['uuid'];
  recharge_settings: RechargeSettingsInput;
};


/** mutation root */
export type MutationUpdate_Recharge_Settings_And_RechargeArgs = {
  current_credit_balance: Scalars['numeric'];
  id: Scalars['uuid'];
  recharge_settings: RechargeSettingsInput;
};


/** mutation root */
export type MutationUpdate_Usage_FilterArgs = {
  contract_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  effective_at: Scalars['timestamptz'];
  new_filter: NewUsageFilterInput;
};


/** mutation root */
export type MutationUpsert_Billing_Provider_InvoiceArgs = {
  input: Array<InputMaybe<UpsertBillingProviderInvoiceInput>>;
};


/** mutation root */
export type MutationVoid_Credit_GrantArgs = {
  credit_grant_id: Scalars['uuid'];
  release_uniqueness_key?: InputMaybe<Scalars['Boolean']>;
  void_credit_purchase_invoice?: InputMaybe<Scalars['Boolean']>;
};


/** mutation root */
export type MutationVoid_Customer_CommitArgs = {
  commit_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
};


/** mutation root */
export type MutationVoid_Prepaid_Credit_GrantArgs = {
  credit_grant_id: Scalars['uuid'];
  invoice_id?: InputMaybe<Scalars['uuid']>;
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type NamedSchedule = {
  __typename?: 'NamedSchedule';
  name: Scalars['String'];
  segments: Array<NamedScheduleSegment>;
};

export type NamedScheduleData = {
  __typename?: 'NamedScheduleData';
  value: Scalars['JSON'];
};

export type NamedScheduleSegment = {
  __typename?: 'NamedScheduleSegment';
  data: NamedScheduleData;
  ending_before: Maybe<Scalars['timestamptz']>;
  starting_at: Scalars['timestamptz'];
};

export type NewUsageFilterInput = {
  group_key: Scalars['String'];
  group_values: Array<Scalars['String']>;
};

export type NonCompositeProductListItem = FixedProductListItem | ProServiceProductListItem | SubscriptionProductListItem | UsageProductListItem;

export type NullableTimeRangeInput = {
  exclusive_end_date?: InputMaybe<Scalars['timestamptz']>;
  inclusive_start_date?: InputMaybe<Scalars['timestamptz']>;
  is_null?: InputMaybe<Scalars['Boolean']>;
};

export type OneTimeProductCharge = {
  __typename?: 'OneTimeProductCharge';
  created_at: Scalars['timestamptz'];
  credit_type: CreditType;
  customer_plan: CustomerPlan;
  description: Scalars['String'];
  id: Scalars['uuid'];
  price: Scalars['numeric'];
  product: Product;
  product_pricing_factor: ProductPricingFactor;
  quantity: Scalars['numeric'];
  service_period_start_date: Scalars['timestamptz'];
  total: Scalars['numeric'];
};

export type OverageLineItem = Mri_LineItem & {
  __typename?: 'OverageLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  overage_credit_amount: Scalars['numeric'];
  overage_credit_type: CreditType;
  overage_rate: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  total: Scalars['numeric'];
};

export type OverrideRate = MultiplierOverride | OverwriteOverride | TieredOverride;

export type OverrideRateInput = {
  multiplier?: InputMaybe<Scalars['numeric']>;
  new_rate?: InputMaybe<RateInput>;
  priority?: InputMaybe<Scalars['numeric']>;
  tiers?: InputMaybe<Array<TieredOverrideInput>>;
};

export type OverrideSpecifier = {
  __typename?: 'OverrideSpecifier';
  presentation_group_values: Maybe<Array<PresentationGroupValue>>;
  pricing_group_values: Maybe<Array<PricingGroupValue>>;
  product_id: Maybe<Scalars['String']>;
  product_tags: Maybe<Array<Scalars['String']>>;
};

export type OverrideSpecifierInput = {
  presentation_group_values?: InputMaybe<Array<PresentationGroupValueInput>>;
  pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  product_id?: InputMaybe<Scalars['String']>;
  product_tags?: InputMaybe<Array<Scalars['String']>>;
};

export type OverwriteOverride = {
  __typename?: 'OverwriteOverride';
  new_rate: Rate;
};

export type PagedSeatMetricsResult = {
  __typename?: 'PagedSeatMetricsResult';
  cursor: Maybe<Scalars['String']>;
  metrics: Array<SeatMetric>;
};

export type PagedUsage = {
  __typename?: 'PagedUsage';
  data: Array<PagedUsageWindow>;
  next_page: Maybe<Scalars['String']>;
};

export type PagedUsageWindow = {
  __typename?: 'PagedUsageWindow';
  ending_before: Scalars['timestamptz'];
  group_key: Maybe<Scalars['String']>;
  group_value: Maybe<Scalars['String']>;
  starting_on: Scalars['timestamptz'];
  value: Maybe<Scalars['String']>;
};

export type ParentInvoice = Mri_Invoice & {
  __typename?: 'ParentInvoice';
  credit_type: CreditType;
  customer: Customer;
  exclusive_end_date: Scalars['timestamptz'];
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  inclusive_start_date: Scalars['timestamptz'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type ParentInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type PendingPrepaidGrant = {
  __typename?: 'PendingPrepaidGrant';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

export type PendingPrepaidGrantsResponse = {
  __typename?: 'PendingPrepaidGrantsResponse';
  cursor: Maybe<Scalars['String']>;
  pending_prepaid_grants: Array<PendingPrepaidGrant>;
};

export type PendingRechargesResponse = {
  __typename?: 'PendingRechargesResponse';
  cursor: Maybe<Scalars['String']>;
  pending_recharges: Array<RechargeSettings>;
};

export type PepaidCreditGrantResponse = {
  __typename?: 'PepaidCreditGrantResponse';
  credit_grant: Maybe<CreditGrant>;
  status: PrepaidCreditGrantStatusEnum;
  stripe_response: Maybe<StripeCreditGrantResponse>;
};

export type PercentageRate = {
  __typename?: 'PercentageRate';
  fraction: Scalars['numeric'];
  use_list_prices: Scalars['Boolean'];
};

export type PercentageRateInput = {
  fraction: Scalars['numeric'];
  use_list_prices: Scalars['Boolean'];
};

/** columns and relationships of "Plan" */
export type Plan = {
  __typename?: 'Plan';
  /** An object relationship */
  Actor: Maybe<Actor>;
  /** An array relationship */
  Alerts: Array<Alert>;
  /** An aggregate relationship */
  Alerts_aggregate: Alert_Aggregate;
  /** An object relationship */
  BillingProviderEnum: Maybe<BillingProviderEnum>;
  /** An object relationship */
  Client: Client;
  /** An array relationship */
  CreditTypeConversions: Array<CreditTypeConversion>;
  /** An aggregate relationship */
  CreditTypeConversions_aggregate: CreditTypeConversion_Aggregate;
  /** An array relationship */
  CustomerPlans: Array<CustomerPlan>;
  /** An aggregate relationship */
  CustomerPlans_aggregate: CustomerPlan_Aggregate;
  /** An array relationship */
  Minimums: Array<Minimum>;
  /** An aggregate relationship */
  Minimums_aggregate: Minimum_Aggregate;
  /** An array relationship */
  PlanManagedFields: Array<PlanManagedField>;
  /** An aggregate relationship */
  PlanManagedFields_aggregate: PlanManagedField_Aggregate;
  /** An array relationship */
  PricedProducts: Array<PricedProduct>;
  /** An aggregate relationship */
  PricedProducts_aggregate: PricedProduct_Aggregate;
  /** An array relationship */
  RecurringCreditGrants: Array<RecurringCreditGrant>;
  /** An aggregate relationship */
  RecurringCreditGrants_aggregate: RecurringCreditGrant_Aggregate;
  /** An object relationship */
  TrialSpec: Maybe<TrialSpec>;
  billing_frequency: BillingFrequencyEnum_Enum;
  billing_provider: Maybe<BillingProviderEnum_Enum>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  customer_count: Scalars['Int'];
  customer_plans: Array<CustomerPlan>;
  default_length_months: Maybe<Scalars['Int']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  managed_fields: Array<PlanManagedField>;
  must_support_aws_marketplace: Scalars['Boolean'];
  name: Scalars['String'];
  newest_revision_id: Maybe<Scalars['uuid']>;
  seat_billing_frequency: Maybe<BillingFrequencyEnum_Enum>;
  service_period_start_type: ServicePeriodStartOnEnum_Enum;
  starting_on: Maybe<Scalars['timestamptz']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Plan" */
export type PlanAlertsArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanAlerts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanCreditTypeConversionsArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanCreditTypeConversions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanCustomerPlansArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanCustomerPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanMinimumsArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanMinimums_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanPlanManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanPlanManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanPricedProductsArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanPricedProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanRecurringCreditGrantsArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanRecurringCreditGrants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


/** columns and relationships of "Plan" */
export type PlanCustomer_CountArgs = {
  status: CustomerPlanStatusEnum;
};


/** columns and relationships of "Plan" */
export type PlanCustomer_PlansArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  limit?: InputMaybe<Scalars['Int']>;
  status: CustomerPlanStatusEnum;
};

export type PlanInput = {
  billingFrequency: BillingFrequencyEnum_Enum;
  billingProvider?: InputMaybe<BillingProviderEnum_Enum>;
  creditTypeConversions: Array<CreditTypeConversionInput>;
  defaultLength?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
  minimums: Array<MinimumInput>;
  name: Scalars['String'];
  pricedProducts: Array<PricedProductInput>;
  recurringCreditGrant?: InputMaybe<RecurringCreditGrantInput>;
  seatBillingFrequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  servicePeriodStartType: ServicePeriodStartOnEnum_Enum;
  trialSpec?: InputMaybe<TrialSpecInput>;
};

/** columns and relationships of "PlanManagedField" */
export type PlanManagedField = {
  __typename?: 'PlanManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Plan: Plan;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  plan_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "PlanManagedField" */
export type PlanManagedField_Aggregate = {
  __typename?: 'PlanManagedField_aggregate';
  aggregate: Maybe<PlanManagedField_Aggregate_Fields>;
  nodes: Array<PlanManagedField>;
};

export type PlanManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PlanManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PlanManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PlanManagedField_Aggregate_Bool_Exp_Count>;
};

export type PlanManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PlanManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PlanManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PlanManagedField" */
export type PlanManagedField_Aggregate_Fields = {
  __typename?: 'PlanManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<PlanManagedField_Max_Fields>;
  min: Maybe<PlanManagedField_Min_Fields>;
};


/** aggregate fields of "PlanManagedField" */
export type PlanManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PlanManagedField" */
export type PlanManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PlanManagedField_Max_Order_By>;
  min?: InputMaybe<PlanManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "PlanManagedField" */
export type PlanManagedField_Arr_Rel_Insert_Input = {
  data: Array<PlanManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "PlanManagedField". All fields are combined with a logical 'AND'. */
export type PlanManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<PlanManagedField_Bool_Exp>>;
  _not?: InputMaybe<PlanManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<PlanManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PlanManagedField" */
export enum PlanManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  PlanManagedFieldKeyIdValueEnforceUniquenessClientIdKey = 'PlanManagedField_key_id_value_enforce_uniqueness_client_id__key',
  /** unique or primary key constraint on columns "id" */
  PlanManagedFieldPkey = 'PlanManagedField_pkey',
  /** unique or primary key constraint on columns "key_id", "plan_id" */
  PlanManagedFieldPlanIdKeyIdKey = 'PlanManagedField_plan_id_key_id_key'
}

/** input type for inserting data into table "PlanManagedField" */
export type PlanManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PlanManagedField_Max_Fields = {
  __typename?: 'PlanManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "PlanManagedField" */
export type PlanManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PlanManagedField_Min_Fields = {
  __typename?: 'PlanManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  plan_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "PlanManagedField" */
export type PlanManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PlanManagedField" */
export type PlanManagedField_Mutation_Response = {
  __typename?: 'PlanManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanManagedField>;
};

/** on_conflict condition type for table "PlanManagedField" */
export type PlanManagedField_On_Conflict = {
  constraint: PlanManagedField_Constraint;
  update_columns?: Array<PlanManagedField_Update_Column>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "PlanManagedField". */
export type PlanManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PlanManagedField */
export type PlanManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PlanManagedField" */
export enum PlanManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "PlanManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "PlanManagedField" */
export enum PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "PlanManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "PlanManagedField" */
export enum PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "PlanManagedField" */
export type PlanManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PlanManagedField" */
export type PlanManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PlanManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PlanManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "PlanManagedField" */
export enum PlanManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type PlanManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: PlanManagedField_Bool_Exp;
};

export enum PlanTypeEnum {
  ActiveOnly = 'ACTIVE_ONLY',
  All = 'ALL',
  ArchivedOnly = 'ARCHIVED_ONLY'
}

/** aggregated selection of "Plan" */
export type Plan_Aggregate = {
  __typename?: 'Plan_aggregate';
  aggregate: Maybe<Plan_Aggregate_Fields>;
  nodes: Array<Plan>;
};

export type Plan_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plan_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plan_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plan_Aggregate_Bool_Exp_Count>;
};

export type Plan_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Plan_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Plan" */
export type Plan_Aggregate_Fields = {
  __typename?: 'Plan_aggregate_fields';
  avg: Maybe<Plan_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Plan_Max_Fields>;
  min: Maybe<Plan_Min_Fields>;
  stddev: Maybe<Plan_Stddev_Fields>;
  stddev_pop: Maybe<Plan_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Plan_Stddev_Samp_Fields>;
  sum: Maybe<Plan_Sum_Fields>;
  var_pop: Maybe<Plan_Var_Pop_Fields>;
  var_samp: Maybe<Plan_Var_Samp_Fields>;
  variance: Maybe<Plan_Variance_Fields>;
};


/** aggregate fields of "Plan" */
export type Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Plan" */
export type Plan_Aggregate_Order_By = {
  avg?: InputMaybe<Plan_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plan_Max_Order_By>;
  min?: InputMaybe<Plan_Min_Order_By>;
  stddev?: InputMaybe<Plan_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plan_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plan_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plan_Sum_Order_By>;
  var_pop?: InputMaybe<Plan_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plan_Var_Samp_Order_By>;
  variance?: InputMaybe<Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Plan" */
export type Plan_Arr_Rel_Insert_Input = {
  data: Array<Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Plan_Avg_Fields = {
  __typename?: 'Plan_avg_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Plan" */
export type Plan_Avg_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Plan". All fields are combined with a logical 'AND'. */
export type Plan_Bool_Exp = {
  Actor?: InputMaybe<Actor_Bool_Exp>;
  Alerts?: InputMaybe<Alert_Bool_Exp>;
  Alerts_aggregate?: InputMaybe<Alert_Aggregate_Bool_Exp>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  CreditTypeConversions?: InputMaybe<CreditTypeConversion_Bool_Exp>;
  CreditTypeConversions_aggregate?: InputMaybe<CreditTypeConversion_Aggregate_Bool_Exp>;
  CustomerPlans?: InputMaybe<CustomerPlan_Bool_Exp>;
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Bool_Exp>;
  Minimums?: InputMaybe<Minimum_Bool_Exp>;
  Minimums_aggregate?: InputMaybe<Minimum_Aggregate_Bool_Exp>;
  PlanManagedFields?: InputMaybe<PlanManagedField_Bool_Exp>;
  PlanManagedFields_aggregate?: InputMaybe<PlanManagedField_Aggregate_Bool_Exp>;
  PricedProducts?: InputMaybe<PricedProduct_Bool_Exp>;
  PricedProducts_aggregate?: InputMaybe<PricedProduct_Aggregate_Bool_Exp>;
  RecurringCreditGrants?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  RecurringCreditGrants_aggregate?: InputMaybe<RecurringCreditGrant_Aggregate_Bool_Exp>;
  TrialSpec?: InputMaybe<TrialSpec_Bool_Exp>;
  _and?: InputMaybe<Array<Plan_Bool_Exp>>;
  _not?: InputMaybe<Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Plan_Bool_Exp>>;
  billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum_Comparison_Exp>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  default_length_months?: InputMaybe<Int_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  must_support_aws_marketplace?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  newest_revision_id?: InputMaybe<Uuid_Comparison_Exp>;
  seat_billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum_Comparison_Exp>;
  service_period_start_type?: InputMaybe<ServicePeriodStartOnEnum_Enum_Comparison_Exp>;
  starting_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  trial_spec_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Plan" */
export enum Plan_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanPkey = 'Plan_pkey'
}

/** input type for incrementing numeric columns in table "Plan" */
export type Plan_Inc_Input = {
  default_length_months?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Plan" */
export type Plan_Insert_Input = {
  Actor?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  Alerts?: InputMaybe<Alert_Arr_Rel_Insert_Input>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  CreditTypeConversions?: InputMaybe<CreditTypeConversion_Arr_Rel_Insert_Input>;
  CustomerPlans?: InputMaybe<CustomerPlan_Arr_Rel_Insert_Input>;
  Minimums?: InputMaybe<Minimum_Arr_Rel_Insert_Input>;
  PlanManagedFields?: InputMaybe<PlanManagedField_Arr_Rel_Insert_Input>;
  PricedProducts?: InputMaybe<PricedProduct_Arr_Rel_Insert_Input>;
  RecurringCreditGrants?: InputMaybe<RecurringCreditGrant_Arr_Rel_Insert_Input>;
  TrialSpec?: InputMaybe<TrialSpec_Obj_Rel_Insert_Input>;
  billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  default_length_months?: InputMaybe<Scalars['Int']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  must_support_aws_marketplace?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  newest_revision_id?: InputMaybe<Scalars['uuid']>;
  seat_billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  service_period_start_type?: InputMaybe<ServicePeriodStartOnEnum_Enum>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Plan_Max_Fields = {
  __typename?: 'Plan_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  default_length_months: Maybe<Scalars['Int']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  newest_revision_id: Maybe<Scalars['uuid']>;
  starting_on: Maybe<Scalars['timestamptz']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Plan" */
export type Plan_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_length_months?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newest_revision_id?: InputMaybe<Order_By>;
  starting_on?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Min_Fields = {
  __typename?: 'Plan_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  default_length_months: Maybe<Scalars['Int']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  newest_revision_id: Maybe<Scalars['uuid']>;
  starting_on: Maybe<Scalars['timestamptz']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Plan" */
export type Plan_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_length_months?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newest_revision_id?: InputMaybe<Order_By>;
  starting_on?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Plan" */
export type Plan_Mutation_Response = {
  __typename?: 'Plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan>;
};

/** input type for inserting object relation for remote table "Plan" */
export type Plan_Obj_Rel_Insert_Input = {
  data: Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_On_Conflict>;
};

/** on_conflict condition type for table "Plan" */
export type Plan_On_Conflict = {
  constraint: Plan_Constraint;
  update_columns?: Array<Plan_Update_Column>;
  where?: InputMaybe<Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "Plan". */
export type Plan_Order_By = {
  Actor?: InputMaybe<Actor_Order_By>;
  Alerts_aggregate?: InputMaybe<Alert_Aggregate_Order_By>;
  BillingProviderEnum?: InputMaybe<BillingProviderEnum_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  CreditTypeConversions_aggregate?: InputMaybe<CreditTypeConversion_Aggregate_Order_By>;
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Order_By>;
  Minimums_aggregate?: InputMaybe<Minimum_Aggregate_Order_By>;
  PlanManagedFields_aggregate?: InputMaybe<PlanManagedField_Aggregate_Order_By>;
  PricedProducts_aggregate?: InputMaybe<PricedProduct_Aggregate_Order_By>;
  RecurringCreditGrants_aggregate?: InputMaybe<RecurringCreditGrant_Aggregate_Order_By>;
  TrialSpec?: InputMaybe<TrialSpec_Order_By>;
  billing_frequency?: InputMaybe<Order_By>;
  billing_provider?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_length_months?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  must_support_aws_marketplace?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  newest_revision_id?: InputMaybe<Order_By>;
  seat_billing_frequency?: InputMaybe<Order_By>;
  service_period_start_type?: InputMaybe<Order_By>;
  starting_on?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Plan */
export type Plan_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Plan" */
export enum Plan_Select_Column {
  /** column name */
  BillingFrequency = 'billing_frequency',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultLengthMonths = 'default_length_months',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  MustSupportAwsMarketplace = 'must_support_aws_marketplace',
  /** column name */
  Name = 'name',
  /** column name */
  NewestRevisionId = 'newest_revision_id',
  /** column name */
  SeatBillingFrequency = 'seat_billing_frequency',
  /** column name */
  ServicePeriodStartType = 'service_period_start_type',
  /** column name */
  StartingOn = 'starting_on',
  /** column name */
  TrialSpecId = 'trial_spec_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Plan_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Plan" */
export enum Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  MustSupportAwsMarketplace = 'must_support_aws_marketplace'
}

/** select "Plan_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Plan" */
export enum Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  MustSupportAwsMarketplace = 'must_support_aws_marketplace'
}

/** input type for updating data in table "Plan" */
export type Plan_Set_Input = {
  billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  default_length_months?: InputMaybe<Scalars['Int']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  must_support_aws_marketplace?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  newest_revision_id?: InputMaybe<Scalars['uuid']>;
  seat_billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  service_period_start_type?: InputMaybe<ServicePeriodStartOnEnum_Enum>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Plan_Stddev_Fields = {
  __typename?: 'Plan_stddev_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Plan" */
export type Plan_Stddev_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plan_Stddev_Pop_Fields = {
  __typename?: 'Plan_stddev_pop_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Plan" */
export type Plan_Stddev_Pop_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plan_Stddev_Samp_Fields = {
  __typename?: 'Plan_stddev_samp_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Plan" */
export type Plan_Stddev_Samp_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Plan" */
export type Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Stream_Cursor_Value_Input = {
  billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  default_length_months?: InputMaybe<Scalars['Int']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  must_support_aws_marketplace?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  newest_revision_id?: InputMaybe<Scalars['uuid']>;
  seat_billing_frequency?: InputMaybe<BillingFrequencyEnum_Enum>;
  service_period_start_type?: InputMaybe<ServicePeriodStartOnEnum_Enum>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Plan_Sum_Fields = {
  __typename?: 'Plan_sum_fields';
  default_length_months: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Plan" */
export type Plan_Sum_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** update columns of table "Plan" */
export enum Plan_Update_Column {
  /** column name */
  BillingFrequency = 'billing_frequency',
  /** column name */
  BillingProvider = 'billing_provider',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultLengthMonths = 'default_length_months',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  MustSupportAwsMarketplace = 'must_support_aws_marketplace',
  /** column name */
  Name = 'name',
  /** column name */
  NewestRevisionId = 'newest_revision_id',
  /** column name */
  SeatBillingFrequency = 'seat_billing_frequency',
  /** column name */
  ServicePeriodStartType = 'service_period_start_type',
  /** column name */
  StartingOn = 'starting_on',
  /** column name */
  TrialSpecId = 'trial_spec_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plan_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plan_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plan_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plan_Var_Pop_Fields = {
  __typename?: 'Plan_var_pop_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Plan" */
export type Plan_Var_Pop_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plan_Var_Samp_Fields = {
  __typename?: 'Plan_var_samp_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Plan" */
export type Plan_Var_Samp_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plan_Variance_Fields = {
  __typename?: 'Plan_variance_fields';
  default_length_months: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Plan" */
export type Plan_Variance_Order_By = {
  default_length_months?: InputMaybe<Order_By>;
};

export type PostpaidCommit = BaseCommit & {
  __typename?: 'PostpaidCommit';
  access_schedule: CommitAccessSchedule;
  amount: Scalars['numeric'];
  applicable_contracts: Maybe<Array<Contract>>;
  applicable_products: Maybe<Array<ProductListItem>>;
  applicable_tags: Maybe<Array<Scalars['String']>>;
  contract: Maybe<Contract>;
  customer: Customer;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  invoice_contract: Maybe<Contract>;
  invoice_schedule: CommitInvoiceSchedule;
  ledger: Array<PostpaidCommitLedgerEntry>;
  managed_fields: Array<CommitOrCreditManagedField>;
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  priority: Scalars['numeric'];
  product: ProductListItem;
  remaining_balance: Scalars['numeric'];
  rolled_over_from: Maybe<PostpaidCommit>;
  rollover_fraction: Maybe<Scalars['numeric']>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
};

export type PostpaidCommitAutomatedInvoiceDeductionLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitAutomatedInvoiceDeductionLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitBaseLedgerEntry = {
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitCanceledLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitCanceledLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitCreditedLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitCreditedLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitExpirationLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitExpirationLedgerEntry';
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitInitialBalanceLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitInitialBalanceLedgerEntry';
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitLedgerEntry = PostpaidCommitAutomatedInvoiceDeductionLedgerEntry | PostpaidCommitCanceledLedgerEntry | PostpaidCommitCreditedLedgerEntry | PostpaidCommitExpirationLedgerEntry | PostpaidCommitInitialBalanceLedgerEntry | PostpaidCommitManualLedgerEntry | PostpaidCommitRolloverLedgerEntry | PostpaidCommitTrueupLedgerEntry;

export type PostpaidCommitManualLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitManualLedgerEntry';
  amount: Scalars['numeric'];
  reason: Scalars['String'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitRolloverLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitRolloverLedgerEntry';
  amount: Scalars['numeric'];
  new_commit: PostpaidCommit;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PostpaidCommitTrueupLedgerEntry = PostpaidCommitBaseLedgerEntry & {
  __typename?: 'PostpaidCommitTrueupLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommit = BaseCommit & {
  __typename?: 'PrepaidCommit';
  access_schedule: CommitAccessSchedule;
  applicable_contracts: Maybe<Array<Contract>>;
  applicable_products: Maybe<Array<ProductListItem>>;
  applicable_tags: Maybe<Array<Scalars['String']>>;
  contract: Maybe<Contract>;
  customer: Customer;
  description: Maybe<Scalars['String']>;
  external_type: ExternalCommitType;
  id: Scalars['uuid'];
  invoice_contract: Maybe<Contract>;
  invoice_schedule: Maybe<CommitInvoiceSchedule>;
  ledger: Array<PrepaidCommitLedgerEntry>;
  managed_fields: Array<CommitOrCreditManagedField>;
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  priority: Scalars['numeric'];
  product: ProductListItem;
  rolled_over_from: Maybe<PrepaidCommit>;
  rollover_fraction: Maybe<Scalars['numeric']>;
  salesforce_opportunity_id: Maybe<Scalars['String']>;
  voided_at: Maybe<Scalars['timestamptz']>;
};

export type PrepaidCommitAutomatedInvoiceDeductionLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitAutomatedInvoiceDeductionLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitBaseLedgerEntry = {
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitCanceledLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitCanceledLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitCreditedLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitCreditedLedgerEntry';
  amount: Scalars['numeric'];
  invoice: Mri_Invoice;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitExpirationLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitExpirationLedgerEntry';
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitLedgerEntry = PrepaidCommitAutomatedInvoiceDeductionLedgerEntry | PrepaidCommitCanceledLedgerEntry | PrepaidCommitCreditedLedgerEntry | PrepaidCommitExpirationLedgerEntry | PrepaidCommitManualLedgerEntry | PrepaidCommitRolloverLedgerEntry | PrepaidCommitSegmentStartLedgerEntry;

export type PrepaidCommitManualLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitManualLedgerEntry';
  amount: Scalars['numeric'];
  reason: Scalars['String'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitRolloverLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitRolloverLedgerEntry';
  amount: Scalars['numeric'];
  new_commit: PrepaidCommit;
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCommitSegmentStartLedgerEntry = PrepaidCommitBaseLedgerEntry & {
  __typename?: 'PrepaidCommitSegmentStartLedgerEntry';
  amount: Scalars['numeric'];
  segment: CommitSegmentScheduleItem;
  timestamp: Scalars['timestamptz'];
};

export type PrepaidCreditGrant = {
  __typename?: 'PrepaidCreditGrant';
  AmountGrantedCreditType: CreditType;
  AmountPaidCreditType: CreditType;
  amount_granted: Scalars['numeric'];
  amount_paid: Scalars['numeric'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  reason: Scalars['String'];
};

export type PrepaidCreditGrantInput = {
  stripe_payment_data?: InputMaybe<StripePaymentData>;
};

export type PrepaidCreditGrantRechargeResponse = {
  __typename?: 'PrepaidCreditGrantRechargeResponse';
  credit_grant: Maybe<CreditGrant>;
  stripe_error_code: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum PrepaidCreditGrantStatusEnum {
  ActionRequired = 'ACTION_REQUIRED',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export type PrequelAuthTokenResponse = {
  __typename?: 'PrequelAuthTokenResponse';
  token_string: Scalars['String'];
};

export type PresentationGroupValue = {
  __typename?: 'PresentationGroupValue';
  name: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export type PresentationGroupValueInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "Price" */
export type Price = {
  __typename?: 'Price';
  /** An object relationship */
  PricedProductPricingFactor: PricedProductPricingFactor;
  block_rounding_behavior: Maybe<RoundingBehaviorEnum_Enum>;
  block_size: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  metric_minimum: Scalars['numeric'];
  priced_product_pricing_factor_id: Scalars['uuid'];
  value: Scalars['numeric'];
};

/** columns and relationships of "PriceAdjustment" */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment';
  /** An object relationship */
  PriceAdjustmentTypeEnum: PriceAdjustmentTypeEnum;
  /** An object relationship */
  PricedProductPricingFactorAdjustment: PricedProductPricingFactorAdjustment;
  adjustment_type_enum: PriceAdjustmentTypeEnum_Enum;
  id: Scalars['uuid'];
  metric_minimum: Scalars['numeric'];
  priced_product_pricing_factor_adjustment_id: Scalars['uuid'];
  quantity: Maybe<Scalars['numeric']>;
  value: Scalars['numeric'];
};

/**
 * Price data for usage-based charge, a flat fee, or a composite charge.
 * Since we can't use unions in input types, we have a looser type definition here.
 * The real input should be
 * for UsageBased prices:                       (value, metric_minimum)
 * for FlatFee prices:                          (value)
 * for FlatFee quantities:                      (value=0, quantity)
 * for CompositeCharge quantities:              (quantity, adjustment_type_enum: "QUANTITY")
 * for CompositeCharge prices (and quantities): (quantity, value, composite_minimum, adjustment_type_enum: "RATE_OVERRIDE")
 */
export type PriceAdjustmentInput = {
  adjustment_type_enum: PriceAdjustmentTypeEnum_Enum;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** columns and relationships of "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum = {
  __typename?: 'PriceAdjustmentTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Aggregate = {
  __typename?: 'PriceAdjustmentTypeEnum_aggregate';
  aggregate: Maybe<PriceAdjustmentTypeEnum_Aggregate_Fields>;
  nodes: Array<PriceAdjustmentTypeEnum>;
};

/** aggregate fields of "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Aggregate_Fields = {
  __typename?: 'PriceAdjustmentTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<PriceAdjustmentTypeEnum_Max_Fields>;
  min: Maybe<PriceAdjustmentTypeEnum_Min_Fields>;
};


/** aggregate fields of "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PriceAdjustmentTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "PriceAdjustmentTypeEnum". All fields are combined with a logical 'AND'. */
export type PriceAdjustmentTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<PriceAdjustmentTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<PriceAdjustmentTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PriceAdjustmentTypeEnum" */
export enum PriceAdjustmentTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  PriceAdjustmentTypeEnumPkey = 'PriceAdjustmentTypeEnum_pkey'
}

export enum PriceAdjustmentTypeEnum_Enum {
  FixedAmount = 'FIXED_AMOUNT',
  Percentage = 'PERCENTAGE',
  Quantity = 'QUANTITY',
  RateOverride = 'RATE_OVERRIDE'
}

/** Boolean expression to compare columns of type "PriceAdjustmentTypeEnum_enum". All fields are combined with logical 'AND'. */
export type PriceAdjustmentTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  _in?: InputMaybe<Array<PriceAdjustmentTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  _nin?: InputMaybe<Array<PriceAdjustmentTypeEnum_Enum>>;
};

/** input type for inserting data into table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PriceAdjustmentTypeEnum_Max_Fields = {
  __typename?: 'PriceAdjustmentTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PriceAdjustmentTypeEnum_Min_Fields = {
  __typename?: 'PriceAdjustmentTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Mutation_Response = {
  __typename?: 'PriceAdjustmentTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PriceAdjustmentTypeEnum>;
};

/** input type for inserting object relation for remote table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Obj_Rel_Insert_Input = {
  data: PriceAdjustmentTypeEnum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PriceAdjustmentTypeEnum_On_Conflict>;
};

/** on_conflict condition type for table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_On_Conflict = {
  constraint: PriceAdjustmentTypeEnum_Constraint;
  update_columns?: Array<PriceAdjustmentTypeEnum_Update_Column>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "PriceAdjustmentTypeEnum". */
export type PriceAdjustmentTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PriceAdjustmentTypeEnum */
export type PriceAdjustmentTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "PriceAdjustmentTypeEnum" */
export enum PriceAdjustmentTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "PriceAdjustmentTypeEnum" */
export type PriceAdjustmentTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PriceAdjustmentTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PriceAdjustmentTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "PriceAdjustmentTypeEnum" */
export enum PriceAdjustmentTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type PriceAdjustmentTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PriceAdjustmentTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: PriceAdjustmentTypeEnum_Bool_Exp;
};

/** aggregated selection of "PriceAdjustment" */
export type PriceAdjustment_Aggregate = {
  __typename?: 'PriceAdjustment_aggregate';
  aggregate: Maybe<PriceAdjustment_Aggregate_Fields>;
  nodes: Array<PriceAdjustment>;
};

export type PriceAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<PriceAdjustment_Aggregate_Bool_Exp_Count>;
};

export type PriceAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PriceAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PriceAdjustment" */
export type PriceAdjustment_Aggregate_Fields = {
  __typename?: 'PriceAdjustment_aggregate_fields';
  avg: Maybe<PriceAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<PriceAdjustment_Max_Fields>;
  min: Maybe<PriceAdjustment_Min_Fields>;
  stddev: Maybe<PriceAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<PriceAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<PriceAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<PriceAdjustment_Sum_Fields>;
  var_pop: Maybe<PriceAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<PriceAdjustment_Var_Samp_Fields>;
  variance: Maybe<PriceAdjustment_Variance_Fields>;
};


/** aggregate fields of "PriceAdjustment" */
export type PriceAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PriceAdjustment" */
export type PriceAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<PriceAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PriceAdjustment_Max_Order_By>;
  min?: InputMaybe<PriceAdjustment_Min_Order_By>;
  stddev?: InputMaybe<PriceAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PriceAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PriceAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PriceAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<PriceAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PriceAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<PriceAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PriceAdjustment" */
export type PriceAdjustment_Arr_Rel_Insert_Input = {
  data: Array<PriceAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PriceAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type PriceAdjustment_Avg_Fields = {
  __typename?: 'PriceAdjustment_avg_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Avg_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PriceAdjustment". All fields are combined with a logical 'AND'. */
export type PriceAdjustment_Bool_Exp = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  _and?: InputMaybe<Array<PriceAdjustment_Bool_Exp>>;
  _not?: InputMaybe<PriceAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<PriceAdjustment_Bool_Exp>>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metric_minimum?: InputMaybe<Numeric_Comparison_Exp>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "PriceAdjustment" */
export enum PriceAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceAdjustmentPkey = 'PriceAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "PriceAdjustment" */
export type PriceAdjustment_Inc_Input = {
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "PriceAdjustment" */
export type PriceAdjustment_Insert_Input = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Obj_Rel_Insert_Input>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input>;
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type PriceAdjustment_Max_Fields = {
  __typename?: 'PriceAdjustment_max_fields';
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PriceAdjustment_Min_Fields = {
  __typename?: 'PriceAdjustment_min_fields';
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_adjustment_id: Maybe<Scalars['uuid']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PriceAdjustment" */
export type PriceAdjustment_Mutation_Response = {
  __typename?: 'PriceAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PriceAdjustment>;
};

/** on_conflict condition type for table "PriceAdjustment" */
export type PriceAdjustment_On_Conflict = {
  constraint: PriceAdjustment_Constraint;
  update_columns?: Array<PriceAdjustment_Update_Column>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "PriceAdjustment". */
export type PriceAdjustment_Order_By = {
  PriceAdjustmentTypeEnum?: InputMaybe<PriceAdjustmentTypeEnum_Order_By>;
  PricedProductPricingFactorAdjustment?: InputMaybe<PricedProductPricingFactorAdjustment_Order_By>;
  adjustment_type_enum?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PriceAdjustment */
export type PriceAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PriceAdjustment" */
export enum PriceAdjustment_Select_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "PriceAdjustment" */
export type PriceAdjustment_Set_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type PriceAdjustment_Stddev_Fields = {
  __typename?: 'PriceAdjustment_stddev_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Stddev_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PriceAdjustment_Stddev_Pop_Fields = {
  __typename?: 'PriceAdjustment_stddev_pop_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Stddev_Pop_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PriceAdjustment_Stddev_Samp_Fields = {
  __typename?: 'PriceAdjustment_stddev_samp_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Stddev_Samp_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PriceAdjustment" */
export type PriceAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PriceAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PriceAdjustment_Stream_Cursor_Value_Input = {
  adjustment_type_enum?: InputMaybe<PriceAdjustmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_adjustment_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type PriceAdjustment_Sum_Fields = {
  __typename?: 'PriceAdjustment_sum_fields';
  metric_minimum: Maybe<Scalars['numeric']>;
  quantity: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Sum_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "PriceAdjustment" */
export enum PriceAdjustment_Update_Column {
  /** column name */
  AdjustmentTypeEnum = 'adjustment_type_enum',
  /** column name */
  Id = 'id',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorAdjustmentId = 'priced_product_pricing_factor_adjustment_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Value = 'value'
}

export type PriceAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PriceAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PriceAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: PriceAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PriceAdjustment_Var_Pop_Fields = {
  __typename?: 'PriceAdjustment_var_pop_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Var_Pop_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PriceAdjustment_Var_Samp_Fields = {
  __typename?: 'PriceAdjustment_var_samp_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Var_Samp_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PriceAdjustment_Variance_Fields = {
  __typename?: 'PriceAdjustment_variance_fields';
  metric_minimum: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PriceAdjustment" */
export type PriceAdjustment_Variance_Order_By = {
  metric_minimum?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/**
 * Price data for usage-based charge, a flat fee, or a composite charge.
 * Since we can't use unions in input types, we have a looser type definition here.
 * The real input should be
 * for UsageBased prices:      (value, metric_minimum, blocks | null)
 * for FlatFee prices:         (value, metric_minimum, quantity, collection_schedule, collection_interval, is_prorated)
 * for CompositeCharge prices: (value, composite_minimum, composite_charge_type, quantity, pricing_factor_ids)
 */
export type PriceInput = {
  blocks?: InputMaybe<BlocksInput>;
  collection_interval?: InputMaybe<Scalars['numeric']>;
  collection_schedule?: InputMaybe<CollectionScheduleEnum_Enum>;
  composite_charge_type?: InputMaybe<CompositeChargeTypeEnum_Enum>;
  composite_minimum?: InputMaybe<Scalars['numeric']>;
  is_prorated?: InputMaybe<Scalars['Boolean']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  product_pricing_factor_ids?: InputMaybe<Array<Scalars['uuid']>>;
  quantity?: InputMaybe<Scalars['numeric']>;
  value: Scalars['numeric'];
};

/** aggregated selection of "Price" */
export type Price_Aggregate = {
  __typename?: 'Price_aggregate';
  aggregate: Maybe<Price_Aggregate_Fields>;
  nodes: Array<Price>;
};

export type Price_Aggregate_Bool_Exp = {
  count?: InputMaybe<Price_Aggregate_Bool_Exp_Count>;
};

export type Price_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Price_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Price_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Price" */
export type Price_Aggregate_Fields = {
  __typename?: 'Price_aggregate_fields';
  avg: Maybe<Price_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Price_Max_Fields>;
  min: Maybe<Price_Min_Fields>;
  stddev: Maybe<Price_Stddev_Fields>;
  stddev_pop: Maybe<Price_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Price_Stddev_Samp_Fields>;
  sum: Maybe<Price_Sum_Fields>;
  var_pop: Maybe<Price_Var_Pop_Fields>;
  var_samp: Maybe<Price_Var_Samp_Fields>;
  variance: Maybe<Price_Variance_Fields>;
};


/** aggregate fields of "Price" */
export type Price_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Price_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Price" */
export type Price_Aggregate_Order_By = {
  avg?: InputMaybe<Price_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Max_Order_By>;
  min?: InputMaybe<Price_Min_Order_By>;
  stddev?: InputMaybe<Price_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Price_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Price_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Price_Sum_Order_By>;
  var_pop?: InputMaybe<Price_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Price_Var_Samp_Order_By>;
  variance?: InputMaybe<Price_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Price" */
export type Price_Arr_Rel_Insert_Input = {
  data: Array<Price_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_On_Conflict>;
};

/** aggregate avg on columns */
export type Price_Avg_Fields = {
  __typename?: 'Price_avg_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Price" */
export type Price_Avg_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Price". All fields are combined with a logical 'AND'. */
export type Price_Bool_Exp = {
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<Price_Bool_Exp>>;
  _not?: InputMaybe<Price_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Bool_Exp>>;
  block_rounding_behavior?: InputMaybe<RoundingBehaviorEnum_Enum_Comparison_Exp>;
  block_size?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metric_minimum?: InputMaybe<Numeric_Comparison_Exp>;
  priced_product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Price" */
export enum Price_Constraint {
  /** unique or primary key constraint on columns "metric_minimum", "priced_product_pricing_factor_id" */
  PricePricedProductPricingFactorIdMetricMinimumKey = 'Price_priced_product_pricing_factor_id_metric_minimum_key',
  /** unique or primary key constraint on columns "id" */
  PricePkey = 'price_pkey'
}

/** input type for incrementing numeric columns in table "Price" */
export type Price_Inc_Input = {
  block_size?: InputMaybe<Scalars['numeric']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Price" */
export type Price_Insert_Input = {
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Obj_Rel_Insert_Input>;
  block_rounding_behavior?: InputMaybe<RoundingBehaviorEnum_Enum>;
  block_size?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Price_Max_Fields = {
  __typename?: 'Price_max_fields';
  block_size: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "Price" */
export type Price_Max_Order_By = {
  block_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Price_Min_Fields = {
  __typename?: 'Price_min_fields';
  block_size: Maybe<Scalars['numeric']>;
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  priced_product_pricing_factor_id: Maybe<Scalars['uuid']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "Price" */
export type Price_Min_Order_By = {
  block_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Price" */
export type Price_Mutation_Response = {
  __typename?: 'Price_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Price>;
};

/** on_conflict condition type for table "Price" */
export type Price_On_Conflict = {
  constraint: Price_Constraint;
  update_columns?: Array<Price_Update_Column>;
  where?: InputMaybe<Price_Bool_Exp>;
};

/** Ordering options when selecting data from "Price". */
export type Price_Order_By = {
  PricedProductPricingFactor?: InputMaybe<PricedProductPricingFactor_Order_By>;
  block_rounding_behavior?: InputMaybe<Order_By>;
  block_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  priced_product_pricing_factor_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Price */
export type Price_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Price" */
export enum Price_Select_Column {
  /** column name */
  BlockRoundingBehavior = 'block_rounding_behavior',
  /** column name */
  BlockSize = 'block_size',
  /** column name */
  Id = 'id',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Price" */
export type Price_Set_Input = {
  block_rounding_behavior?: InputMaybe<RoundingBehaviorEnum_Enum>;
  block_size?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Price_Stddev_Fields = {
  __typename?: 'Price_stddev_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Price" */
export type Price_Stddev_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Price_Stddev_Pop_Fields = {
  __typename?: 'Price_stddev_pop_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Price" */
export type Price_Stddev_Pop_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Price_Stddev_Samp_Fields = {
  __typename?: 'Price_stddev_samp_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Price" */
export type Price_Stddev_Samp_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Price" */
export type Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Stream_Cursor_Value_Input = {
  block_rounding_behavior?: InputMaybe<RoundingBehaviorEnum_Enum>;
  block_size?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  metric_minimum?: InputMaybe<Scalars['numeric']>;
  priced_product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Price_Sum_Fields = {
  __typename?: 'Price_sum_fields';
  block_size: Maybe<Scalars['numeric']>;
  metric_minimum: Maybe<Scalars['numeric']>;
  value: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Price" */
export type Price_Sum_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "Price" */
export enum Price_Update_Column {
  /** column name */
  BlockRoundingBehavior = 'block_rounding_behavior',
  /** column name */
  BlockSize = 'block_size',
  /** column name */
  Id = 'id',
  /** column name */
  MetricMinimum = 'metric_minimum',
  /** column name */
  PricedProductPricingFactorId = 'priced_product_pricing_factor_id',
  /** column name */
  Value = 'value'
}

export type Price_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Price_Var_Pop_Fields = {
  __typename?: 'Price_var_pop_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Price" */
export type Price_Var_Pop_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Price_Var_Samp_Fields = {
  __typename?: 'Price_var_samp_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Price" */
export type Price_Var_Samp_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Price_Variance_Fields = {
  __typename?: 'Price_variance_fields';
  block_size: Maybe<Scalars['Float']>;
  metric_minimum: Maybe<Scalars['Float']>;
  value: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Price" */
export type Price_Variance_Order_By = {
  block_size?: InputMaybe<Order_By>;
  metric_minimum?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "PricedProduct" */
export type PricedProduct = {
  __typename?: 'PricedProduct';
  /** An object relationship */
  CreditType: CreditType;
  /** An object relationship */
  Plan: Plan;
  /** An array relationship */
  PricedProductPricingFactors: Array<PricedProductPricingFactor>;
  /** An aggregate relationship */
  PricedProductPricingFactors_aggregate: PricedProductPricingFactor_Aggregate;
  /** An object relationship */
  Product: Product;
  credit_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  ordering: Maybe<Scalars['Int']>;
  plan_id: Scalars['uuid'];
  product_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "PricedProduct" */
export type PricedProductPricedProductPricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


/** columns and relationships of "PricedProduct" */
export type PricedProductPricedProductPricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};

/** columns and relationships of "PricedProductAdjustment" */
export type PricedProductAdjustment = {
  __typename?: 'PricedProductAdjustment';
  /** An object relationship */
  CustomPricing: CustomPricing;
  /** An object relationship */
  PricedProduct: PricedProduct;
  created_at: Scalars['timestamptz'];
  custom_pricing_id: Scalars['uuid'];
  id: Scalars['uuid'];
  percent_adjustment: Scalars['numeric'];
  priced_product_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
};

/** aggregated selection of "PricedProductAdjustment" */
export type PricedProductAdjustment_Aggregate = {
  __typename?: 'PricedProductAdjustment_aggregate';
  aggregate: Maybe<PricedProductAdjustment_Aggregate_Fields>;
  nodes: Array<PricedProductAdjustment>;
};

export type PricedProductAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<PricedProductAdjustment_Aggregate_Bool_Exp_Count>;
};

export type PricedProductAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PricedProductAdjustment" */
export type PricedProductAdjustment_Aggregate_Fields = {
  __typename?: 'PricedProductAdjustment_aggregate_fields';
  avg: Maybe<PricedProductAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<PricedProductAdjustment_Max_Fields>;
  min: Maybe<PricedProductAdjustment_Min_Fields>;
  stddev: Maybe<PricedProductAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<PricedProductAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<PricedProductAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<PricedProductAdjustment_Sum_Fields>;
  var_pop: Maybe<PricedProductAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<PricedProductAdjustment_Var_Samp_Fields>;
  variance: Maybe<PricedProductAdjustment_Variance_Fields>;
};


/** aggregate fields of "PricedProductAdjustment" */
export type PricedProductAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<PricedProductAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PricedProductAdjustment_Max_Order_By>;
  min?: InputMaybe<PricedProductAdjustment_Min_Order_By>;
  stddev?: InputMaybe<PricedProductAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PricedProductAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PricedProductAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PricedProductAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<PricedProductAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PricedProductAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<PricedProductAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PricedProductAdjustment" */
export type PricedProductAdjustment_Arr_Rel_Insert_Input = {
  data: Array<PricedProductAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProductAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type PricedProductAdjustment_Avg_Fields = {
  __typename?: 'PricedProductAdjustment_avg_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Avg_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PricedProductAdjustment". All fields are combined with a logical 'AND'. */
export type PricedProductAdjustment_Bool_Exp = {
  CustomPricing?: InputMaybe<CustomPricing_Bool_Exp>;
  PricedProduct?: InputMaybe<PricedProduct_Bool_Exp>;
  _and?: InputMaybe<Array<PricedProductAdjustment_Bool_Exp>>;
  _not?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<PricedProductAdjustment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_pricing_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  percent_adjustment?: InputMaybe<Numeric_Comparison_Exp>;
  priced_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "PricedProductAdjustment" */
export enum PricedProductAdjustment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PricedProductAdjustmentPkey = 'PricedProductAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "PricedProductAdjustment" */
export type PricedProductAdjustment_Inc_Input = {
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "PricedProductAdjustment" */
export type PricedProductAdjustment_Insert_Input = {
  CustomPricing?: InputMaybe<CustomPricing_Obj_Rel_Insert_Input>;
  PricedProduct?: InputMaybe<PricedProduct_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type PricedProductAdjustment_Max_Fields = {
  __typename?: 'PricedProductAdjustment_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  percent_adjustment: Maybe<Scalars['numeric']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PricedProductAdjustment_Min_Fields = {
  __typename?: 'PricedProductAdjustment_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  percent_adjustment: Maybe<Scalars['numeric']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PricedProductAdjustment" */
export type PricedProductAdjustment_Mutation_Response = {
  __typename?: 'PricedProductAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricedProductAdjustment>;
};

/** on_conflict condition type for table "PricedProductAdjustment" */
export type PricedProductAdjustment_On_Conflict = {
  constraint: PricedProductAdjustment_Constraint;
  update_columns?: Array<PricedProductAdjustment_Update_Column>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "PricedProductAdjustment". */
export type PricedProductAdjustment_Order_By = {
  CustomPricing?: InputMaybe<CustomPricing_Order_By>;
  PricedProduct?: InputMaybe<PricedProduct_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percent_adjustment?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PricedProductAdjustment */
export type PricedProductAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PricedProductAdjustment" */
export enum PricedProductAdjustment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PercentAdjustment = 'percent_adjustment',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  StartPeriod = 'start_period'
}

/** input type for updating data in table "PricedProductAdjustment" */
export type PricedProductAdjustment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type PricedProductAdjustment_Stddev_Fields = {
  __typename?: 'PricedProductAdjustment_stddev_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Stddev_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PricedProductAdjustment_Stddev_Pop_Fields = {
  __typename?: 'PricedProductAdjustment_stddev_pop_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Stddev_Pop_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PricedProductAdjustment_Stddev_Samp_Fields = {
  __typename?: 'PricedProductAdjustment_stddev_samp_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Stddev_Samp_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PricedProductAdjustment" */
export type PricedProductAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PricedProductAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PricedProductAdjustment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  percent_adjustment?: InputMaybe<Scalars['numeric']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type PricedProductAdjustment_Sum_Fields = {
  __typename?: 'PricedProductAdjustment_sum_fields';
  percent_adjustment: Maybe<Scalars['numeric']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Sum_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** update columns of table "PricedProductAdjustment" */
export enum PricedProductAdjustment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PercentAdjustment = 'percent_adjustment',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  StartPeriod = 'start_period'
}

export type PricedProductAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PricedProductAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PricedProductAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: PricedProductAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PricedProductAdjustment_Var_Pop_Fields = {
  __typename?: 'PricedProductAdjustment_var_pop_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Var_Pop_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PricedProductAdjustment_Var_Samp_Fields = {
  __typename?: 'PricedProductAdjustment_var_samp_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Var_Samp_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PricedProductAdjustment_Variance_Fields = {
  __typename?: 'PricedProductAdjustment_variance_fields';
  percent_adjustment: Maybe<Scalars['Float']>;
  start_period: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PricedProductAdjustment" */
export type PricedProductAdjustment_Variance_Order_By = {
  percent_adjustment?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

export type PricedProductInput = {
  credit_type_id: Scalars['uuid'];
  ordering?: InputMaybe<Scalars['Int']>;
  pricing_factors: Array<PricedProductPricingFactorInput>;
  product_id: Scalars['uuid'];
};

/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactor = {
  __typename?: 'PricedProductPricingFactor';
  /** An array relationship */
  CompositeCharges: Array<CompositeCharge>;
  /** An aggregate relationship */
  CompositeCharges_aggregate: CompositeCharge_Aggregate;
  /** An object relationship */
  FlatFee: Maybe<FlatFee>;
  /** An array relationship */
  FlatFees: Array<FlatFee>;
  /** An aggregate relationship */
  FlatFees_aggregate: FlatFee_Aggregate;
  /** An object relationship */
  PricedProduct: PricedProduct;
  /** An array relationship */
  Prices: Array<Price>;
  /** An aggregate relationship */
  Prices_aggregate: Price_Aggregate;
  /** An object relationship */
  ProductPricingFactor: ProductPricingFactor;
  SeatPrices: Array<SeatPrice>;
  id: Scalars['uuid'];
  priced_product_id: Scalars['uuid'];
  product_pricing_factor_id: Scalars['uuid'];
  skip_ramp: Scalars['Boolean'];
  start_period: Scalars['numeric'];
  tier_reset_frequency: Scalars['Int'];
  tiering_mode: TieringModeEnum_Enum;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorCompositeChargesArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorCompositeCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorFlatFeesArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorFlatFees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorPricesArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactor" */
export type PricedProductPricingFactorPrices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};

/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment = {
  __typename?: 'PricedProductPricingFactorAdjustment';
  /** An array relationship */
  CompositeChargeAdjustments: Array<CompositeChargeAdjustment>;
  /** An aggregate relationship */
  CompositeChargeAdjustments_aggregate: CompositeChargeAdjustment_Aggregate;
  /** An object relationship */
  CustomPricing: CustomPricing;
  /** An array relationship */
  FlatFeeAdjustments: Array<FlatFeeAdjustment>;
  /** An aggregate relationship */
  FlatFeeAdjustments_aggregate: FlatFeeAdjustment_Aggregate;
  /** An array relationship */
  PriceAdjustments: Array<PriceAdjustment>;
  /** An aggregate relationship */
  PriceAdjustments_aggregate: PriceAdjustment_Aggregate;
  /** An object relationship */
  PricedProduct: PricedProduct;
  /** An object relationship */
  ProductPricingFactor: ProductPricingFactor;
  created_at: Scalars['timestamptz'];
  custom_pricing_id: Scalars['uuid'];
  id: Scalars['uuid'];
  priced_product_id: Scalars['uuid'];
  product_pricing_factor_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentCompositeChargeAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentCompositeChargeAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentFlatFeeAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentFlatFeeAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentPriceAdjustmentsArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


/** columns and relationships of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustmentPriceAdjustments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};

export type PricedProductPricingFactorAdjustmentInput = {
  charge_type_enum?: InputMaybe<ChargeTypeEnum_Enum>;
  price_adjustments: Array<PriceAdjustmentInput>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id: Scalars['uuid'];
  start_period: Scalars['numeric'];
};

/** aggregated selection of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Aggregate = {
  __typename?: 'PricedProductPricingFactorAdjustment_aggregate';
  aggregate: Maybe<PricedProductPricingFactorAdjustment_Aggregate_Fields>;
  nodes: Array<PricedProductPricingFactorAdjustment>;
};

export type PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp_Count>;
};

export type PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Aggregate_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_aggregate_fields';
  avg: Maybe<PricedProductPricingFactorAdjustment_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<PricedProductPricingFactorAdjustment_Max_Fields>;
  min: Maybe<PricedProductPricingFactorAdjustment_Min_Fields>;
  stddev: Maybe<PricedProductPricingFactorAdjustment_Stddev_Fields>;
  stddev_pop: Maybe<PricedProductPricingFactorAdjustment_Stddev_Pop_Fields>;
  stddev_samp: Maybe<PricedProductPricingFactorAdjustment_Stddev_Samp_Fields>;
  sum: Maybe<PricedProductPricingFactorAdjustment_Sum_Fields>;
  var_pop: Maybe<PricedProductPricingFactorAdjustment_Var_Pop_Fields>;
  var_samp: Maybe<PricedProductPricingFactorAdjustment_Var_Samp_Fields>;
  variance: Maybe<PricedProductPricingFactorAdjustment_Variance_Fields>;
};


/** aggregate fields of "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Aggregate_Order_By = {
  avg?: InputMaybe<PricedProductPricingFactorAdjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PricedProductPricingFactorAdjustment_Max_Order_By>;
  min?: InputMaybe<PricedProductPricingFactorAdjustment_Min_Order_By>;
  stddev?: InputMaybe<PricedProductPricingFactorAdjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PricedProductPricingFactorAdjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PricedProductPricingFactorAdjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PricedProductPricingFactorAdjustment_Sum_Order_By>;
  var_pop?: InputMaybe<PricedProductPricingFactorAdjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PricedProductPricingFactorAdjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<PricedProductPricingFactorAdjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Arr_Rel_Insert_Input = {
  data: Array<PricedProductPricingFactorAdjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProductPricingFactorAdjustment_On_Conflict>;
};

/** aggregate avg on columns */
export type PricedProductPricingFactorAdjustment_Avg_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_avg_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Avg_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PricedProductPricingFactorAdjustment". All fields are combined with a logical 'AND'. */
export type PricedProductPricingFactorAdjustment_Bool_Exp = {
  CompositeChargeAdjustments?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
  CompositeChargeAdjustments_aggregate?: InputMaybe<CompositeChargeAdjustment_Aggregate_Bool_Exp>;
  CustomPricing?: InputMaybe<CustomPricing_Bool_Exp>;
  FlatFeeAdjustments?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
  FlatFeeAdjustments_aggregate?: InputMaybe<FlatFeeAdjustment_Aggregate_Bool_Exp>;
  PriceAdjustments?: InputMaybe<PriceAdjustment_Bool_Exp>;
  PriceAdjustments_aggregate?: InputMaybe<PriceAdjustment_Aggregate_Bool_Exp>;
  PricedProduct?: InputMaybe<PricedProduct_Bool_Exp>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Bool_Exp>>;
  _not?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
  _or?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_pricing_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priced_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "PricedProductPricingFactorAdjustment" */
export enum PricedProductPricingFactorAdjustment_Constraint {
  /** unique or primary key constraint on columns "custom_pricing_id", "start_period", "priced_product_id", "product_pricing_factor_id" */
  PppfaUniqueIdsWithinStartPeriod = 'PPPFA_unique_ids_within_start_period',
  /** unique or primary key constraint on columns "id" */
  PricedProductPricingFactorAdjustmentPkey = 'PricedProductPricingFactorAdjustment_pkey'
}

/** input type for incrementing numeric columns in table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Inc_Input = {
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Insert_Input = {
  CompositeChargeAdjustments?: InputMaybe<CompositeChargeAdjustment_Arr_Rel_Insert_Input>;
  CustomPricing?: InputMaybe<CustomPricing_Obj_Rel_Insert_Input>;
  FlatFeeAdjustments?: InputMaybe<FlatFeeAdjustment_Arr_Rel_Insert_Input>;
  PriceAdjustments?: InputMaybe<PriceAdjustment_Arr_Rel_Insert_Input>;
  PricedProduct?: InputMaybe<PricedProduct_Obj_Rel_Insert_Input>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type PricedProductPricingFactorAdjustment_Max_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PricedProductPricingFactorAdjustment_Min_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  custom_pricing_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Mutation_Response = {
  __typename?: 'PricedProductPricingFactorAdjustment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricedProductPricingFactorAdjustment>;
};

/** input type for inserting object relation for remote table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input = {
  data: PricedProductPricingFactorAdjustment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProductPricingFactorAdjustment_On_Conflict>;
};

/** on_conflict condition type for table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_On_Conflict = {
  constraint: PricedProductPricingFactorAdjustment_Constraint;
  update_columns?: Array<PricedProductPricingFactorAdjustment_Update_Column>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "PricedProductPricingFactorAdjustment". */
export type PricedProductPricingFactorAdjustment_Order_By = {
  CompositeChargeAdjustments_aggregate?: InputMaybe<CompositeChargeAdjustment_Aggregate_Order_By>;
  CustomPricing?: InputMaybe<CustomPricing_Order_By>;
  FlatFeeAdjustments_aggregate?: InputMaybe<FlatFeeAdjustment_Aggregate_Order_By>;
  PriceAdjustments_aggregate?: InputMaybe<PriceAdjustment_Aggregate_Order_By>;
  PricedProduct?: InputMaybe<PricedProduct_Order_By>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_pricing_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PricedProductPricingFactorAdjustment */
export type PricedProductPricingFactorAdjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PricedProductPricingFactorAdjustment" */
export enum PricedProductPricingFactorAdjustment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  StartPeriod = 'start_period'
}

/** input type for updating data in table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type PricedProductPricingFactorAdjustment_Stddev_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_stddev_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Stddev_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PricedProductPricingFactorAdjustment_Stddev_Pop_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_stddev_pop_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Stddev_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PricedProductPricingFactorAdjustment_Stddev_Samp_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_stddev_samp_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Stddev_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PricedProductPricingFactorAdjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PricedProductPricingFactorAdjustment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_pricing_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  start_period?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type PricedProductPricingFactorAdjustment_Sum_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_sum_fields';
  start_period: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Sum_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** update columns of table "PricedProductPricingFactorAdjustment" */
export enum PricedProductPricingFactorAdjustment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomPricingId = 'custom_pricing_id',
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  StartPeriod = 'start_period'
}

export type PricedProductPricingFactorAdjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PricedProductPricingFactorAdjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PricedProductPricingFactorAdjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: PricedProductPricingFactorAdjustment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PricedProductPricingFactorAdjustment_Var_Pop_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_var_pop_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Var_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PricedProductPricingFactorAdjustment_Var_Samp_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_var_samp_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Var_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PricedProductPricingFactorAdjustment_Variance_Fields = {
  __typename?: 'PricedProductPricingFactorAdjustment_variance_fields';
  start_period: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PricedProductPricingFactorAdjustment" */
export type PricedProductPricingFactorAdjustment_Variance_Order_By = {
  start_period?: InputMaybe<Order_By>;
};

export type PricedProductPricingFactorInput = {
  charge_type_enum: ChargeTypeEnum_Enum;
  prices: Array<PriceInput>;
  product_pricing_factor_id: Scalars['uuid'];
  /**
   * Whether this pricing factor should be ignored for the ramp where
   * it would apply (as defined by its start_period)
   */
  skip_ramp?: InputMaybe<Scalars['Boolean']>;
  start_period: Scalars['numeric'];
  tier_reset_frequency?: InputMaybe<Scalars['Int']>;
  tiering_mode?: InputMaybe<TieringModeEnum_Enum>;
};

/** aggregated selection of "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Aggregate = {
  __typename?: 'PricedProductPricingFactor_aggregate';
  aggregate: Maybe<PricedProductPricingFactor_Aggregate_Fields>;
  nodes: Array<PricedProductPricingFactor>;
};

export type PricedProductPricingFactor_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<PricedProductPricingFactor_Aggregate_Bool_Exp_Count>;
};

export type PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And = {
  arguments: PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or = {
  arguments: PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type PricedProductPricingFactor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Aggregate_Fields = {
  __typename?: 'PricedProductPricingFactor_aggregate_fields';
  avg: Maybe<PricedProductPricingFactor_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<PricedProductPricingFactor_Max_Fields>;
  min: Maybe<PricedProductPricingFactor_Min_Fields>;
  stddev: Maybe<PricedProductPricingFactor_Stddev_Fields>;
  stddev_pop: Maybe<PricedProductPricingFactor_Stddev_Pop_Fields>;
  stddev_samp: Maybe<PricedProductPricingFactor_Stddev_Samp_Fields>;
  sum: Maybe<PricedProductPricingFactor_Sum_Fields>;
  var_pop: Maybe<PricedProductPricingFactor_Var_Pop_Fields>;
  var_samp: Maybe<PricedProductPricingFactor_Var_Samp_Fields>;
  variance: Maybe<PricedProductPricingFactor_Variance_Fields>;
};


/** aggregate fields of "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Aggregate_Order_By = {
  avg?: InputMaybe<PricedProductPricingFactor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PricedProductPricingFactor_Max_Order_By>;
  min?: InputMaybe<PricedProductPricingFactor_Min_Order_By>;
  stddev?: InputMaybe<PricedProductPricingFactor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PricedProductPricingFactor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PricedProductPricingFactor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PricedProductPricingFactor_Sum_Order_By>;
  var_pop?: InputMaybe<PricedProductPricingFactor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PricedProductPricingFactor_Var_Samp_Order_By>;
  variance?: InputMaybe<PricedProductPricingFactor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Arr_Rel_Insert_Input = {
  data: Array<PricedProductPricingFactor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProductPricingFactor_On_Conflict>;
};

/** aggregate avg on columns */
export type PricedProductPricingFactor_Avg_Fields = {
  __typename?: 'PricedProductPricingFactor_avg_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Avg_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PricedProductPricingFactor". All fields are combined with a logical 'AND'. */
export type PricedProductPricingFactor_Bool_Exp = {
  CompositeCharges?: InputMaybe<CompositeCharge_Bool_Exp>;
  CompositeCharges_aggregate?: InputMaybe<CompositeCharge_Aggregate_Bool_Exp>;
  FlatFee?: InputMaybe<FlatFee_Bool_Exp>;
  FlatFees?: InputMaybe<FlatFee_Bool_Exp>;
  FlatFees_aggregate?: InputMaybe<FlatFee_Aggregate_Bool_Exp>;
  PricedProduct?: InputMaybe<PricedProduct_Bool_Exp>;
  Prices?: InputMaybe<Price_Bool_Exp>;
  Prices_aggregate?: InputMaybe<Price_Aggregate_Bool_Exp>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  _and?: InputMaybe<Array<PricedProductPricingFactor_Bool_Exp>>;
  _not?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  _or?: InputMaybe<Array<PricedProductPricingFactor_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priced_product_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_pricing_factor_id?: InputMaybe<Uuid_Comparison_Exp>;
  skip_ramp?: InputMaybe<Boolean_Comparison_Exp>;
  start_period?: InputMaybe<Numeric_Comparison_Exp>;
  tier_reset_frequency?: InputMaybe<Int_Comparison_Exp>;
  tiering_mode?: InputMaybe<TieringModeEnum_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "PricedProductPricingFactor" */
export enum PricedProductPricingFactor_Constraint {
  /** unique or primary key constraint on columns "id" */
  PricedProductPricingFactorPkey = 'PricedProductPricingFactor_pkey',
  /** unique or primary key constraint on columns "start_period", "priced_product_id", "product_pricing_factor_id" */
  PricedProductPricingFactorPricedProductIdProductPricingFa = 'PricedProductPricingFactor_priced_product_id_product_pricing_fa'
}

/** input type for incrementing numeric columns in table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Inc_Input = {
  start_period?: InputMaybe<Scalars['numeric']>;
  tier_reset_frequency?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Insert_Input = {
  CompositeCharges?: InputMaybe<CompositeCharge_Arr_Rel_Insert_Input>;
  FlatFee?: InputMaybe<FlatFee_Obj_Rel_Insert_Input>;
  FlatFees?: InputMaybe<FlatFee_Arr_Rel_Insert_Input>;
  PricedProduct?: InputMaybe<PricedProduct_Obj_Rel_Insert_Input>;
  Prices?: InputMaybe<Price_Arr_Rel_Insert_Input>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  skip_ramp?: InputMaybe<Scalars['Boolean']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  tier_reset_frequency?: InputMaybe<Scalars['Int']>;
  tiering_mode?: InputMaybe<TieringModeEnum_Enum>;
};

/** aggregate max on columns */
export type PricedProductPricingFactor_Max_Fields = {
  __typename?: 'PricedProductPricingFactor_max_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  tier_reset_frequency: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PricedProductPricingFactor_Min_Fields = {
  __typename?: 'PricedProductPricingFactor_min_fields';
  id: Maybe<Scalars['uuid']>;
  priced_product_id: Maybe<Scalars['uuid']>;
  product_pricing_factor_id: Maybe<Scalars['uuid']>;
  start_period: Maybe<Scalars['numeric']>;
  tier_reset_frequency: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Mutation_Response = {
  __typename?: 'PricedProductPricingFactor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricedProductPricingFactor>;
};

/** input type for inserting object relation for remote table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Obj_Rel_Insert_Input = {
  data: PricedProductPricingFactor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProductPricingFactor_On_Conflict>;
};

/** on_conflict condition type for table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_On_Conflict = {
  constraint: PricedProductPricingFactor_Constraint;
  update_columns?: Array<PricedProductPricingFactor_Update_Column>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};

/** Ordering options when selecting data from "PricedProductPricingFactor". */
export type PricedProductPricingFactor_Order_By = {
  CompositeCharges_aggregate?: InputMaybe<CompositeCharge_Aggregate_Order_By>;
  FlatFee?: InputMaybe<FlatFee_Order_By>;
  FlatFees_aggregate?: InputMaybe<FlatFee_Aggregate_Order_By>;
  PricedProduct?: InputMaybe<PricedProduct_Order_By>;
  Prices_aggregate?: InputMaybe<Price_Aggregate_Order_By>;
  ProductPricingFactor?: InputMaybe<ProductPricingFactor_Order_By>;
  id?: InputMaybe<Order_By>;
  priced_product_id?: InputMaybe<Order_By>;
  product_pricing_factor_id?: InputMaybe<Order_By>;
  skip_ramp?: InputMaybe<Order_By>;
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
  tiering_mode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PricedProductPricingFactor */
export type PricedProductPricingFactor_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PricedProductPricingFactor" */
export enum PricedProductPricingFactor_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  SkipRamp = 'skip_ramp',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  TierResetFrequency = 'tier_reset_frequency',
  /** column name */
  TieringMode = 'tiering_mode'
}

/** select "PricedProductPricingFactor_aggregate_bool_exp_bool_and_arguments_columns" columns of table "PricedProductPricingFactor" */
export enum PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SkipRamp = 'skip_ramp'
}

/** select "PricedProductPricingFactor_aggregate_bool_exp_bool_or_arguments_columns" columns of table "PricedProductPricingFactor" */
export enum PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SkipRamp = 'skip_ramp'
}

/** input type for updating data in table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  skip_ramp?: InputMaybe<Scalars['Boolean']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  tier_reset_frequency?: InputMaybe<Scalars['Int']>;
  tiering_mode?: InputMaybe<TieringModeEnum_Enum>;
};

/** aggregate stddev on columns */
export type PricedProductPricingFactor_Stddev_Fields = {
  __typename?: 'PricedProductPricingFactor_stddev_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Stddev_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PricedProductPricingFactor_Stddev_Pop_Fields = {
  __typename?: 'PricedProductPricingFactor_stddev_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Stddev_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PricedProductPricingFactor_Stddev_Samp_Fields = {
  __typename?: 'PricedProductPricingFactor_stddev_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Stddev_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PricedProductPricingFactor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PricedProductPricingFactor_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  priced_product_id?: InputMaybe<Scalars['uuid']>;
  product_pricing_factor_id?: InputMaybe<Scalars['uuid']>;
  skip_ramp?: InputMaybe<Scalars['Boolean']>;
  start_period?: InputMaybe<Scalars['numeric']>;
  tier_reset_frequency?: InputMaybe<Scalars['Int']>;
  tiering_mode?: InputMaybe<TieringModeEnum_Enum>;
};

/** aggregate sum on columns */
export type PricedProductPricingFactor_Sum_Fields = {
  __typename?: 'PricedProductPricingFactor_sum_fields';
  start_period: Maybe<Scalars['numeric']>;
  tier_reset_frequency: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Sum_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** update columns of table "PricedProductPricingFactor" */
export enum PricedProductPricingFactor_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PricedProductId = 'priced_product_id',
  /** column name */
  ProductPricingFactorId = 'product_pricing_factor_id',
  /** column name */
  SkipRamp = 'skip_ramp',
  /** column name */
  StartPeriod = 'start_period',
  /** column name */
  TierResetFrequency = 'tier_reset_frequency',
  /** column name */
  TieringMode = 'tiering_mode'
}

export type PricedProductPricingFactor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PricedProductPricingFactor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PricedProductPricingFactor_Set_Input>;
  /** filter the rows which have to be updated */
  where: PricedProductPricingFactor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PricedProductPricingFactor_Var_Pop_Fields = {
  __typename?: 'PricedProductPricingFactor_var_pop_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Var_Pop_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PricedProductPricingFactor_Var_Samp_Fields = {
  __typename?: 'PricedProductPricingFactor_var_samp_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Var_Samp_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PricedProductPricingFactor_Variance_Fields = {
  __typename?: 'PricedProductPricingFactor_variance_fields';
  start_period: Maybe<Scalars['Float']>;
  tier_reset_frequency: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PricedProductPricingFactor" */
export type PricedProductPricingFactor_Variance_Order_By = {
  start_period?: InputMaybe<Order_By>;
  tier_reset_frequency?: InputMaybe<Order_By>;
};

/** aggregated selection of "PricedProduct" */
export type PricedProduct_Aggregate = {
  __typename?: 'PricedProduct_aggregate';
  aggregate: Maybe<PricedProduct_Aggregate_Fields>;
  nodes: Array<PricedProduct>;
};

export type PricedProduct_Aggregate_Bool_Exp = {
  count?: InputMaybe<PricedProduct_Aggregate_Bool_Exp_Count>;
};

export type PricedProduct_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PricedProduct_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PricedProduct_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "PricedProduct" */
export type PricedProduct_Aggregate_Fields = {
  __typename?: 'PricedProduct_aggregate_fields';
  avg: Maybe<PricedProduct_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<PricedProduct_Max_Fields>;
  min: Maybe<PricedProduct_Min_Fields>;
  stddev: Maybe<PricedProduct_Stddev_Fields>;
  stddev_pop: Maybe<PricedProduct_Stddev_Pop_Fields>;
  stddev_samp: Maybe<PricedProduct_Stddev_Samp_Fields>;
  sum: Maybe<PricedProduct_Sum_Fields>;
  var_pop: Maybe<PricedProduct_Var_Pop_Fields>;
  var_samp: Maybe<PricedProduct_Var_Samp_Fields>;
  variance: Maybe<PricedProduct_Variance_Fields>;
};


/** aggregate fields of "PricedProduct" */
export type PricedProduct_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PricedProduct_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PricedProduct" */
export type PricedProduct_Aggregate_Order_By = {
  avg?: InputMaybe<PricedProduct_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PricedProduct_Max_Order_By>;
  min?: InputMaybe<PricedProduct_Min_Order_By>;
  stddev?: InputMaybe<PricedProduct_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PricedProduct_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PricedProduct_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PricedProduct_Sum_Order_By>;
  var_pop?: InputMaybe<PricedProduct_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PricedProduct_Var_Samp_Order_By>;
  variance?: InputMaybe<PricedProduct_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PricedProduct" */
export type PricedProduct_Arr_Rel_Insert_Input = {
  data: Array<PricedProduct_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProduct_On_Conflict>;
};

/** aggregate avg on columns */
export type PricedProduct_Avg_Fields = {
  __typename?: 'PricedProduct_avg_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PricedProduct" */
export type PricedProduct_Avg_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PricedProduct". All fields are combined with a logical 'AND'. */
export type PricedProduct_Bool_Exp = {
  CreditType?: InputMaybe<CreditType_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  PricedProductPricingFactors?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  PricedProductPricingFactors_aggregate?: InputMaybe<PricedProductPricingFactor_Aggregate_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  _and?: InputMaybe<Array<PricedProduct_Bool_Exp>>;
  _not?: InputMaybe<PricedProduct_Bool_Exp>;
  _or?: InputMaybe<Array<PricedProduct_Bool_Exp>>;
  credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ordering?: InputMaybe<Int_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "PricedProduct" */
export enum PricedProduct_Constraint {
  /** unique or primary key constraint on columns "id" */
  PricedProductPkey = 'PricedProduct_pkey'
}

/** input type for incrementing numeric columns in table "PricedProduct" */
export type PricedProduct_Inc_Input = {
  ordering?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "PricedProduct" */
export type PricedProduct_Insert_Input = {
  CreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  PricedProductPricingFactors?: InputMaybe<PricedProductPricingFactor_Arr_Rel_Insert_Input>;
  Product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ordering?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PricedProduct_Max_Fields = {
  __typename?: 'PricedProduct_max_fields';
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  ordering: Maybe<Scalars['Int']>;
  plan_id: Maybe<Scalars['uuid']>;
  product_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "PricedProduct" */
export type PricedProduct_Max_Order_By = {
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PricedProduct_Min_Fields = {
  __typename?: 'PricedProduct_min_fields';
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  ordering: Maybe<Scalars['Int']>;
  plan_id: Maybe<Scalars['uuid']>;
  product_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "PricedProduct" */
export type PricedProduct_Min_Order_By = {
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PricedProduct" */
export type PricedProduct_Mutation_Response = {
  __typename?: 'PricedProduct_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricedProduct>;
};

/** input type for inserting object relation for remote table "PricedProduct" */
export type PricedProduct_Obj_Rel_Insert_Input = {
  data: PricedProduct_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PricedProduct_On_Conflict>;
};

/** on_conflict condition type for table "PricedProduct" */
export type PricedProduct_On_Conflict = {
  constraint: PricedProduct_Constraint;
  update_columns?: Array<PricedProduct_Update_Column>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};

/** Ordering options when selecting data from "PricedProduct". */
export type PricedProduct_Order_By = {
  CreditType?: InputMaybe<CreditType_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  PricedProductPricingFactors_aggregate?: InputMaybe<PricedProductPricingFactor_Aggregate_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PricedProduct */
export type PricedProduct_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PricedProduct" */
export enum PricedProduct_Select_Column {
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Ordering = 'ordering',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "PricedProduct" */
export type PricedProduct_Set_Input = {
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ordering?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type PricedProduct_Stddev_Fields = {
  __typename?: 'PricedProduct_stddev_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PricedProduct" */
export type PricedProduct_Stddev_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PricedProduct_Stddev_Pop_Fields = {
  __typename?: 'PricedProduct_stddev_pop_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PricedProduct" */
export type PricedProduct_Stddev_Pop_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PricedProduct_Stddev_Samp_Fields = {
  __typename?: 'PricedProduct_stddev_samp_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PricedProduct" */
export type PricedProduct_Stddev_Samp_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "PricedProduct" */
export type PricedProduct_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PricedProduct_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PricedProduct_Stream_Cursor_Value_Input = {
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  ordering?: InputMaybe<Scalars['Int']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type PricedProduct_Sum_Fields = {
  __typename?: 'PricedProduct_sum_fields';
  ordering: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PricedProduct" */
export type PricedProduct_Sum_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** update columns of table "PricedProduct" */
export enum PricedProduct_Update_Column {
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Ordering = 'ordering',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type PricedProduct_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PricedProduct_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PricedProduct_Set_Input>;
  /** filter the rows which have to be updated */
  where: PricedProduct_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PricedProduct_Var_Pop_Fields = {
  __typename?: 'PricedProduct_var_pop_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PricedProduct" */
export type PricedProduct_Var_Pop_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PricedProduct_Var_Samp_Fields = {
  __typename?: 'PricedProduct_var_samp_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PricedProduct" */
export type PricedProduct_Var_Samp_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PricedProduct_Variance_Fields = {
  __typename?: 'PricedProduct_variance_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PricedProduct" */
export type PricedProduct_Variance_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

export type PricingFactorInput = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  charge_type_enum: ChargeTypeEnum_Enum;
  name: Scalars['String'];
  ordering: Scalars['Int'];
  pricing_factor_id?: InputMaybe<Scalars['uuid']>;
};

export type PricingGroupValue = {
  __typename?: 'PricingGroupValue';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PricingGroupValueInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PricingSchemeIdentifier = {
  __typename?: 'PricingSchemeIdentifier';
  billable_metric_id: Scalars['uuid'];
};

export type ProService = {
  __typename?: 'ProService';
  contract: Contract;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  managed_fields: Array<ProServiceManagedField>;
  maxAmount: Scalars['CreditAmount'];
  max_amount: Maybe<Scalars['CreditAmount']>;
  netsuite_overage_item_id: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  product: Maybe<ProServiceProductListItem>;
  product_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  unit_price: Scalars['CreditAmount'];
};

export type ProServiceInput = {
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  description?: InputMaybe<Scalars['String']>;
  max_amount: Scalars['CreditAmount'];
  netsuite_overage_item_id?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  product_id: Scalars['uuid'];
  quantity: Scalars['numeric'];
  unit_price: Scalars['CreditAmount'];
};

/** columns and relationships of "ProServiceManagedField" */
export type ProServiceManagedField = {
  __typename?: 'ProServiceManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  pro_service_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ProServiceManagedField" */
export type ProServiceManagedField_Aggregate = {
  __typename?: 'ProServiceManagedField_aggregate';
  aggregate: Maybe<ProServiceManagedField_Aggregate_Fields>;
  nodes: Array<ProServiceManagedField>;
};

export type ProServiceManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ProServiceManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ProServiceManagedField_Aggregate_Bool_Exp_Count>;
};

export type ProServiceManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProServiceManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProServiceManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProServiceManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProServiceManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProServiceManagedField" */
export type ProServiceManagedField_Aggregate_Fields = {
  __typename?: 'ProServiceManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ProServiceManagedField_Max_Fields>;
  min: Maybe<ProServiceManagedField_Min_Fields>;
};


/** aggregate fields of "ProServiceManagedField" */
export type ProServiceManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProServiceManagedField" */
export type ProServiceManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProServiceManagedField_Max_Order_By>;
  min?: InputMaybe<ProServiceManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ProServiceManagedField" */
export type ProServiceManagedField_Arr_Rel_Insert_Input = {
  data: Array<ProServiceManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProServiceManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ProServiceManagedField". All fields are combined with a logical 'AND'. */
export type ProServiceManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ProServiceManagedField_Bool_Exp>>;
  _not?: InputMaybe<ProServiceManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ProServiceManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  pro_service_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProServiceManagedField" */
export enum ProServiceManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ProServiceManagedFieldKeyIdValueEnforceUniquenessClieKey = 'ProServiceManagedField_key_id_value_enforce_uniqueness_clie_key',
  /** unique or primary key constraint on columns "id" */
  ProServiceManagedFieldPkey = 'ProServiceManagedField_pkey',
  /** unique or primary key constraint on columns "key_id", "pro_service_id" */
  ProServiceManagedFieldProServiceIdKeyIdKey = 'ProServiceManagedField_pro_service_id_key_id_key'
}

/** input type for inserting data into table "ProServiceManagedField" */
export type ProServiceManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  pro_service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProServiceManagedField_Max_Fields = {
  __typename?: 'ProServiceManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  pro_service_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ProServiceManagedField" */
export type ProServiceManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  pro_service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProServiceManagedField_Min_Fields = {
  __typename?: 'ProServiceManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  pro_service_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ProServiceManagedField" */
export type ProServiceManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  pro_service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProServiceManagedField" */
export type ProServiceManagedField_Mutation_Response = {
  __typename?: 'ProServiceManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProServiceManagedField>;
};

/** on_conflict condition type for table "ProServiceManagedField" */
export type ProServiceManagedField_On_Conflict = {
  constraint: ProServiceManagedField_Constraint;
  update_columns?: Array<ProServiceManagedField_Update_Column>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ProServiceManagedField". */
export type ProServiceManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  pro_service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProServiceManagedField */
export type ProServiceManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ProServiceManagedField" */
export enum ProServiceManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProServiceId = 'pro_service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ProServiceManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ProServiceManagedField" */
export enum ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ProServiceManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ProServiceManagedField" */
export enum ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ProServiceManagedField" */
export type ProServiceManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  pro_service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ProServiceManagedField" */
export type ProServiceManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProServiceManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProServiceManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  pro_service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ProServiceManagedField" */
export enum ProServiceManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProServiceId = 'pro_service_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ProServiceManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProServiceManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProServiceManagedField_Bool_Exp;
};

export type ProServiceProductListItem = {
  __typename?: 'ProServiceProductListItem';
  archived_at: Maybe<Scalars['timestamptz']>;
  current: ProServiceProductListItemState;
  id: Scalars['uuid'];
  initial: ProServiceProductListItemState;
  managed_fields: Array<ContractProductManagedField>;
  updates: Array<ProductListItemUpdate>;
};

export type ProServiceProductListItemState = {
  __typename?: 'ProServiceProductListItemState';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  is_refundable: Scalars['Boolean'];
  name: Scalars['String'];
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

/** columns and relationships of "Product" */
export type Product = {
  __typename?: 'Product';
  /** An object relationship */
  Actor: Maybe<Actor>;
  /** An object relationship */
  Client: Client;
  /** An array relationship */
  PricedProducts: Array<PricedProduct>;
  /** An aggregate relationship */
  PricedProducts_aggregate: PricedProduct_Aggregate;
  /** An array relationship */
  ProductManagedFields: Array<ProductManagedField>;
  /** An aggregate relationship */
  ProductManagedFields_aggregate: ProductManagedField_Aggregate;
  /** An array relationship */
  ProductPricingFactors: Array<ProductPricingFactor>;
  /** An aggregate relationship */
  ProductPricingFactors_aggregate: ProductPricingFactor_Aggregate;
  active_customers_count: Scalars['Int'];
  active_customers_plan_count: Scalars['Int'];
  active_plan_count: Scalars['Int'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  environment_type: EnvironmentTypeEnum_Enum;
  group_key: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  managed_fields: Array<ProductManagedField>;
  name: Scalars['String'];
  plan_count: Scalars['Int'];
  plans: Array<Plan>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Product" */
export type ProductPricedProductsArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductPricedProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductProductManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductProductManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductProductPricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductProductPricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


/** columns and relationships of "Product" */
export type ProductPlansArgs = {
  type: PlanTypeEnum;
};

export type ProductChargeLineItem = Mri_LineItem & {
  __typename?: 'ProductChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  line_item_chunks: Array<LineItemChunk>;
  product: Product;
  sub_line_items: Array<ProductChargeSubLineItem>;
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  total: Scalars['numeric'];
};

export type ProductChargeSubLineItem = AdvanceChargeLineItem | ChargeLineItem | CompositeChargeLineItem | SeatChargeLineItem;

export type ProductListItem = CompositeProductListItem | FixedProductListItem | ProServiceProductListItem | SubscriptionProductListItem | UsageProductListItem;

export enum ProductListItemArchivedFilter {
  All = 'ALL',
  Archived = 'ARCHIVED',
  NotArchived = 'NOT_ARCHIVED'
}

export enum ProductListItemTypeFilter {
  Composite = 'COMPOSITE',
  Fixed = 'FIXED',
  ProService = 'PRO_SERVICE',
  Subscription = 'SUBSCRIPTION',
  Usage = 'USAGE'
}

export type ProductListItemUpdate = {
  __typename?: 'ProductListItemUpdate';
  Creator: Actor;
  billable_metric: Maybe<BillableMetric>;
  composite_products: Maybe<Array<NonCompositeProductListItem>>;
  composite_tags: Maybe<Array<Scalars['String']>>;
  created_at: Scalars['timestamptz'];
  effective_at: Scalars['timestamptz'];
  exclude_free_usage: Maybe<Scalars['Boolean']>;
  is_refundable: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  netsuite_overage_item_id: Maybe<Scalars['String']>;
  presentation_group_key: Maybe<Array<Scalars['String']>>;
  pricing_group_key: Maybe<Array<Scalars['String']>>;
  quantity_conversion: Maybe<QuantityConversion>;
  quantity_conversion_update: Maybe<QuantityConversionUpdate>;
  quantity_rounding: Maybe<QuantityRounding>;
  quantity_rounding_update: Maybe<QuantityRoundingUpdate>;
  tags: Maybe<Array<Scalars['String']>>;
};

/** columns and relationships of "ProductManagedField" */
export type ProductManagedField = {
  __typename?: 'ProductManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Product: Product;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  product_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ProductManagedField" */
export type ProductManagedField_Aggregate = {
  __typename?: 'ProductManagedField_aggregate';
  aggregate: Maybe<ProductManagedField_Aggregate_Fields>;
  nodes: Array<ProductManagedField>;
};

export type ProductManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ProductManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ProductManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ProductManagedField_Aggregate_Bool_Exp_Count>;
};

export type ProductManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ProductManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProductManagedField" */
export type ProductManagedField_Aggregate_Fields = {
  __typename?: 'ProductManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ProductManagedField_Max_Fields>;
  min: Maybe<ProductManagedField_Min_Fields>;
};


/** aggregate fields of "ProductManagedField" */
export type ProductManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProductManagedField" */
export type ProductManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProductManagedField_Max_Order_By>;
  min?: InputMaybe<ProductManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ProductManagedField" */
export type ProductManagedField_Arr_Rel_Insert_Input = {
  data: Array<ProductManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ProductManagedField". All fields are combined with a logical 'AND'. */
export type ProductManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ProductManagedField_Bool_Exp>>;
  _not?: InputMaybe<ProductManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ProductManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProductManagedField" */
export enum ProductManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ProductManagedFieldKeyIdValueEnforceUniquenessClientKey = 'ProductManagedField_key_id_value_enforce_uniqueness_client__key',
  /** unique or primary key constraint on columns "id" */
  ProductManagedFieldPkey = 'ProductManagedField_pkey',
  /** unique or primary key constraint on columns "product_id", "key_id" */
  ProductManagedFieldProductIdKeyIdKey = 'ProductManagedField_product_id_key_id_key'
}

/** input type for inserting data into table "ProductManagedField" */
export type ProductManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProductManagedField_Max_Fields = {
  __typename?: 'ProductManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  product_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ProductManagedField" */
export type ProductManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProductManagedField_Min_Fields = {
  __typename?: 'ProductManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  product_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ProductManagedField" */
export type ProductManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProductManagedField" */
export type ProductManagedField_Mutation_Response = {
  __typename?: 'ProductManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductManagedField>;
};

/** on_conflict condition type for table "ProductManagedField" */
export type ProductManagedField_On_Conflict = {
  constraint: ProductManagedField_Constraint;
  update_columns?: Array<ProductManagedField_Update_Column>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ProductManagedField". */
export type ProductManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProductManagedField */
export type ProductManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ProductManagedField" */
export enum ProductManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ProductManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ProductManagedField" */
export enum ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ProductManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ProductManagedField" */
export enum ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ProductManagedField" */
export type ProductManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ProductManagedField" */
export type ProductManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ProductManagedField" */
export enum ProductManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ProductManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductManagedField_Bool_Exp;
};

/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactor = {
  __typename?: 'ProductPricingFactor';
  /** An object relationship */
  BillableMetric: Maybe<BillableMetric>;
  /** An array relationship */
  ChargeManagedFields: Array<ChargeManagedField>;
  /** An aggregate relationship */
  ChargeManagedFields_aggregate: ChargeManagedField_Aggregate;
  /** An object relationship */
  ChargeTypeEnum: ChargeTypeEnum;
  /** An array relationship */
  CompositeChargePricingFactors: Array<CompositeChargePricingFactor>;
  /** An aggregate relationship */
  CompositeChargePricingFactors_aggregate: CompositeChargePricingFactor_Aggregate;
  /** An array relationship */
  PricedProductPricingFactors: Array<PricedProductPricingFactor>;
  /** An aggregate relationship */
  PricedProductPricingFactors_aggregate: PricedProductPricingFactor_Aggregate;
  /** An object relationship */
  Product: Product;
  billable_metric_id: Maybe<Scalars['uuid']>;
  charge_type_enum: ChargeTypeEnum_Enum;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  environment_type: Scalars['String'];
  id: Scalars['uuid'];
  managed_fields: Array<ChargeManagedField>;
  name: Scalars['String'];
  netsuite_item_id: Maybe<Scalars['String']>;
  ordering: Maybe<Scalars['Int']>;
  product_id: Scalars['uuid'];
  seat_metric: Maybe<SeatMetric>;
  seat_metric_id: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorChargeManagedFieldsArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorChargeManagedFields_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorCompositeChargePricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorCompositeChargePricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorPricedProductPricingFactorsArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


/** columns and relationships of "ProductPricingFactor" */
export type ProductPricingFactorPricedProductPricingFactors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};

/** aggregated selection of "ProductPricingFactor" */
export type ProductPricingFactor_Aggregate = {
  __typename?: 'ProductPricingFactor_aggregate';
  aggregate: Maybe<ProductPricingFactor_Aggregate_Fields>;
  nodes: Array<ProductPricingFactor>;
};

export type ProductPricingFactor_Aggregate_Bool_Exp = {
  count?: InputMaybe<ProductPricingFactor_Aggregate_Bool_Exp_Count>;
};

export type ProductPricingFactor_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ProductPricingFactor" */
export type ProductPricingFactor_Aggregate_Fields = {
  __typename?: 'ProductPricingFactor_aggregate_fields';
  avg: Maybe<ProductPricingFactor_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<ProductPricingFactor_Max_Fields>;
  min: Maybe<ProductPricingFactor_Min_Fields>;
  stddev: Maybe<ProductPricingFactor_Stddev_Fields>;
  stddev_pop: Maybe<ProductPricingFactor_Stddev_Pop_Fields>;
  stddev_samp: Maybe<ProductPricingFactor_Stddev_Samp_Fields>;
  sum: Maybe<ProductPricingFactor_Sum_Fields>;
  var_pop: Maybe<ProductPricingFactor_Var_Pop_Fields>;
  var_samp: Maybe<ProductPricingFactor_Var_Samp_Fields>;
  variance: Maybe<ProductPricingFactor_Variance_Fields>;
};


/** aggregate fields of "ProductPricingFactor" */
export type ProductPricingFactor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ProductPricingFactor" */
export type ProductPricingFactor_Aggregate_Order_By = {
  avg?: InputMaybe<ProductPricingFactor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProductPricingFactor_Max_Order_By>;
  min?: InputMaybe<ProductPricingFactor_Min_Order_By>;
  stddev?: InputMaybe<ProductPricingFactor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProductPricingFactor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProductPricingFactor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProductPricingFactor_Sum_Order_By>;
  var_pop?: InputMaybe<ProductPricingFactor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProductPricingFactor_Var_Samp_Order_By>;
  variance?: InputMaybe<ProductPricingFactor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ProductPricingFactor" */
export type ProductPricingFactor_Arr_Rel_Insert_Input = {
  data: Array<ProductPricingFactor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductPricingFactor_On_Conflict>;
};

/** aggregate avg on columns */
export type ProductPricingFactor_Avg_Fields = {
  __typename?: 'ProductPricingFactor_avg_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Avg_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ProductPricingFactor". All fields are combined with a logical 'AND'. */
export type ProductPricingFactor_Bool_Exp = {
  BillableMetric?: InputMaybe<BillableMetric_Bool_Exp>;
  ChargeManagedFields?: InputMaybe<ChargeManagedField_Bool_Exp>;
  ChargeManagedFields_aggregate?: InputMaybe<ChargeManagedField_Aggregate_Bool_Exp>;
  ChargeTypeEnum?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
  CompositeChargePricingFactors?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
  CompositeChargePricingFactors_aggregate?: InputMaybe<CompositeChargePricingFactor_Aggregate_Bool_Exp>;
  PricedProductPricingFactors?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
  PricedProductPricingFactors_aggregate?: InputMaybe<PricedProductPricingFactor_Aggregate_Bool_Exp>;
  Product?: InputMaybe<Product_Bool_Exp>;
  _and?: InputMaybe<Array<ProductPricingFactor_Bool_Exp>>;
  _not?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  _or?: InputMaybe<Array<ProductPricingFactor_Bool_Exp>>;
  billable_metric_id?: InputMaybe<Uuid_Comparison_Exp>;
  charge_type_enum?: InputMaybe<ChargeTypeEnum_Enum_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  netsuite_item_id?: InputMaybe<String_Comparison_Exp>;
  ordering?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<Uuid_Comparison_Exp>;
  seat_metric_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ProductPricingFactor" */
export enum ProductPricingFactor_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPricingFactorPkey = 'ProductPricingFactor_pkey'
}

/** input type for incrementing numeric columns in table "ProductPricingFactor" */
export type ProductPricingFactor_Inc_Input = {
  ordering?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ProductPricingFactor" */
export type ProductPricingFactor_Insert_Input = {
  BillableMetric?: InputMaybe<BillableMetric_Obj_Rel_Insert_Input>;
  ChargeManagedFields?: InputMaybe<ChargeManagedField_Arr_Rel_Insert_Input>;
  ChargeTypeEnum?: InputMaybe<ChargeTypeEnum_Obj_Rel_Insert_Input>;
  CompositeChargePricingFactors?: InputMaybe<CompositeChargePricingFactor_Arr_Rel_Insert_Input>;
  PricedProductPricingFactors?: InputMaybe<PricedProductPricingFactor_Arr_Rel_Insert_Input>;
  Product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  charge_type_enum?: InputMaybe<ChargeTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_item_id?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  seat_metric_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProductPricingFactor_Max_Fields = {
  __typename?: 'ProductPricingFactor_max_fields';
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  environment_type: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  netsuite_item_id: Maybe<Scalars['String']>;
  ordering: Maybe<Scalars['Int']>;
  product_id: Maybe<Scalars['uuid']>;
  seat_metric_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Max_Order_By = {
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  netsuite_item_id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  seat_metric_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProductPricingFactor_Min_Fields = {
  __typename?: 'ProductPricingFactor_min_fields';
  billable_metric_id: Maybe<Scalars['uuid']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  environment_type: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  netsuite_item_id: Maybe<Scalars['String']>;
  ordering: Maybe<Scalars['Int']>;
  product_id: Maybe<Scalars['uuid']>;
  seat_metric_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Min_Order_By = {
  billable_metric_id?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  netsuite_item_id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  seat_metric_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ProductPricingFactor" */
export type ProductPricingFactor_Mutation_Response = {
  __typename?: 'ProductPricingFactor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductPricingFactor>;
};

/** input type for inserting object relation for remote table "ProductPricingFactor" */
export type ProductPricingFactor_Obj_Rel_Insert_Input = {
  data: ProductPricingFactor_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductPricingFactor_On_Conflict>;
};

/** on_conflict condition type for table "ProductPricingFactor" */
export type ProductPricingFactor_On_Conflict = {
  constraint: ProductPricingFactor_Constraint;
  update_columns?: Array<ProductPricingFactor_Update_Column>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};

/** Ordering options when selecting data from "ProductPricingFactor". */
export type ProductPricingFactor_Order_By = {
  BillableMetric?: InputMaybe<BillableMetric_Order_By>;
  ChargeManagedFields_aggregate?: InputMaybe<ChargeManagedField_Aggregate_Order_By>;
  ChargeTypeEnum?: InputMaybe<ChargeTypeEnum_Order_By>;
  CompositeChargePricingFactors_aggregate?: InputMaybe<CompositeChargePricingFactor_Aggregate_Order_By>;
  PricedProductPricingFactors_aggregate?: InputMaybe<PricedProductPricingFactor_Aggregate_Order_By>;
  Product?: InputMaybe<Product_Order_By>;
  billable_metric_id?: InputMaybe<Order_By>;
  charge_type_enum?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  netsuite_item_id?: InputMaybe<Order_By>;
  ordering?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  seat_metric_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ProductPricingFactor */
export type ProductPricingFactor_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ProductPricingFactor" */
export enum ProductPricingFactor_Select_Column {
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ChargeTypeEnum = 'charge_type_enum',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NetsuiteItemId = 'netsuite_item_id',
  /** column name */
  Ordering = 'ordering',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SeatMetricId = 'seat_metric_id'
}

/** input type for updating data in table "ProductPricingFactor" */
export type ProductPricingFactor_Set_Input = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  charge_type_enum?: InputMaybe<ChargeTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_item_id?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  seat_metric_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ProductPricingFactor_Stddev_Fields = {
  __typename?: 'ProductPricingFactor_stddev_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Stddev_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductPricingFactor_Stddev_Pop_Fields = {
  __typename?: 'ProductPricingFactor_stddev_pop_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Stddev_Pop_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductPricingFactor_Stddev_Samp_Fields = {
  __typename?: 'ProductPricingFactor_stddev_samp_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Stddev_Samp_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ProductPricingFactor" */
export type ProductPricingFactor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductPricingFactor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductPricingFactor_Stream_Cursor_Value_Input = {
  billable_metric_id?: InputMaybe<Scalars['uuid']>;
  charge_type_enum?: InputMaybe<ChargeTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_item_id?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  seat_metric_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ProductPricingFactor_Sum_Fields = {
  __typename?: 'ProductPricingFactor_sum_fields';
  ordering: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Sum_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** update columns of table "ProductPricingFactor" */
export enum ProductPricingFactor_Update_Column {
  /** column name */
  BillableMetricId = 'billable_metric_id',
  /** column name */
  ChargeTypeEnum = 'charge_type_enum',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NetsuiteItemId = 'netsuite_item_id',
  /** column name */
  Ordering = 'ordering',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SeatMetricId = 'seat_metric_id'
}

export type ProductPricingFactor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductPricingFactor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductPricingFactor_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductPricingFactor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductPricingFactor_Var_Pop_Fields = {
  __typename?: 'ProductPricingFactor_var_pop_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Var_Pop_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductPricingFactor_Var_Samp_Fields = {
  __typename?: 'ProductPricingFactor_var_samp_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Var_Samp_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductPricingFactor_Variance_Fields = {
  __typename?: 'ProductPricingFactor_variance_fields';
  ordering: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ProductPricingFactor" */
export type ProductPricingFactor_Variance_Order_By = {
  ordering?: InputMaybe<Order_By>;
};

export enum ProductTypeEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

/** aggregated selection of "Product" */
export type Product_Aggregate = {
  __typename?: 'Product_aggregate';
  aggregate: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "Product" */
export type Product_Aggregate_Fields = {
  __typename?: 'Product_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Product_Max_Fields>;
  min: Maybe<Product_Min_Fields>;
};


/** aggregate fields of "Product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  Actor?: InputMaybe<Actor_Bool_Exp>;
  Client?: InputMaybe<Client_Bool_Exp>;
  PricedProducts?: InputMaybe<PricedProduct_Bool_Exp>;
  PricedProducts_aggregate?: InputMaybe<PricedProduct_Aggregate_Bool_Exp>;
  ProductManagedFields?: InputMaybe<ProductManagedField_Bool_Exp>;
  ProductManagedFields_aggregate?: InputMaybe<ProductManagedField_Aggregate_Bool_Exp>;
  ProductPricingFactors?: InputMaybe<ProductPricingFactor_Bool_Exp>;
  ProductPricingFactors_aggregate?: InputMaybe<ProductPricingFactor_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  group_key?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'Product_pkey'
}

/** input type for inserting data into table "Product" */
export type Product_Insert_Input = {
  Actor?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  PricedProducts?: InputMaybe<PricedProduct_Arr_Rel_Insert_Input>;
  ProductManagedFields?: InputMaybe<ProductManagedField_Arr_Rel_Insert_Input>;
  ProductPricingFactors?: InputMaybe<ProductPricingFactor_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  group_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'Product_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  group_key: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'Product_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  group_key: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Product" */
export type Product_Mutation_Response = {
  __typename?: 'Product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "Product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "Product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "Product". */
export type Product_Order_By = {
  Actor?: InputMaybe<Actor_Order_By>;
  Client?: InputMaybe<Client_Order_By>;
  PricedProducts_aggregate?: InputMaybe<PricedProduct_Aggregate_Order_By>;
  ProductManagedFields_aggregate?: InputMaybe<ProductManagedField_Aggregate_Order_By>;
  ProductPricingFactors_aggregate?: InputMaybe<ProductPricingFactor_Aggregate_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  group_key?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Product */
export type Product_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Product" */
export enum Product_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  GroupKey = 'group_key',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Product" */
export type Product_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  group_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  group_key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Product" */
export enum Product_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  GroupKey = 'group_key',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

export type ProductsAndRateCards = {
  __typename?: 'ProductsAndRateCards';
  product: ProductListItem;
  products: Array<ProductListItem>;
  rate_card: RateCard;
  rate_cards: Array<RateCard>;
  version: Scalars['Int'];
};


export type ProductsAndRateCardsProductArgs = {
  id: Scalars['uuid'];
};


export type ProductsAndRateCardsProductsArgs = {
  archived?: InputMaybe<ProductListItemArchivedFilter>;
  ids?: InputMaybe<Array<Scalars['uuid']>>;
  types?: InputMaybe<Array<ProductListItemTypeFilter>>;
};


export type ProductsAndRateCardsRate_CardArgs = {
  id: Scalars['uuid'];
};


export type ProductsAndRateCardsRate_CardsArgs = {
  archived?: InputMaybe<RateCardArchivedFilter>;
};

export type ProductsSortingInput = {
  ascending: Scalars['Boolean'];
  order_by: ProductsSortingOrderByEnum;
};

export enum ProductsSortingOrderByEnum {
  CreatedBy = 'CREATED_BY',
  Id = 'ID',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

export type QuantityConversion = {
  __typename?: 'QuantityConversion';
  conversion_factor: Scalars['numeric'];
  name: Maybe<Scalars['String']>;
  operation: ConversionOperation;
};

export type QuantityConversionInput = {
  conversion_factor: Scalars['numeric'];
  name?: InputMaybe<Scalars['String']>;
  operation: ConversionOperation;
};

export type QuantityConversionUpdate = UpdateOrUndefined & {
  __typename?: 'QuantityConversionUpdate';
  is_undefined: Scalars['Boolean'];
  value: Maybe<QuantityConversion>;
};

export type QuantityRounding = {
  __typename?: 'QuantityRounding';
  decimal_places: Scalars['Int'];
  rounding_method: RoundingMethod;
};

export type QuantityRoundingInput = {
  decimal_places: Scalars['Int'];
  rounding_method: RoundingMethod;
};

export type QuantityRoundingUpdate = UpdateOrUndefined & {
  __typename?: 'QuantityRoundingUpdate';
  is_undefined: Scalars['Boolean'];
  value: Maybe<QuantityRounding>;
};

export type Query = {
  __typename?: 'Query';
  /** fetch data from the table: "AccountTypeEnum" */
  AccountTypeEnum: Array<AccountTypeEnum>;
  /** fetch aggregated fields from the table: "AccountTypeEnum" */
  AccountTypeEnum_aggregate: AccountTypeEnum_Aggregate;
  /** fetch data from the table: "AccountTypeEnum" using primary key columns */
  AccountTypeEnum_by_pk: Maybe<AccountTypeEnum>;
  /** fetch data from the table: "Actor" */
  Actor: Array<Actor>;
  /** fetch aggregated fields from the table: "Actor" */
  Actor_aggregate: Actor_Aggregate;
  /** fetch data from the table: "Actor" using primary key columns */
  Actor_by_pk: Maybe<Actor>;
  /** fetch data from the table: "Alert" */
  Alert: Array<Alert>;
  /** fetch data from the table: "AlertManagedField" */
  AlertManagedField: Array<AlertManagedField>;
  /** fetch aggregated fields from the table: "AlertManagedField" */
  AlertManagedField_aggregate: AlertManagedField_Aggregate;
  /** fetch data from the table: "AlertManagedField" using primary key columns */
  AlertManagedField_by_pk: Maybe<AlertManagedField>;
  /** fetch data from the table: "AlertTypeEnum" */
  AlertTypeEnum: Array<AlertTypeEnum>;
  /** fetch aggregated fields from the table: "AlertTypeEnum" */
  AlertTypeEnum_aggregate: AlertTypeEnum_Aggregate;
  /** fetch data from the table: "AlertTypeEnum" using primary key columns */
  AlertTypeEnum_by_pk: Maybe<AlertTypeEnum>;
  /** fetch aggregated fields from the table: "Alert" */
  Alert_aggregate: Alert_Aggregate;
  /** fetch data from the table: "Alert" using primary key columns */
  Alert_by_pk: Maybe<Alert>;
  /** fetch data from the table: "BillableMetric" */
  BillableMetric: Array<BillableMetric>;
  /** fetch data from the table: "BillableMetricManagedField" */
  BillableMetricManagedField: Array<BillableMetricManagedField>;
  /** fetch aggregated fields from the table: "BillableMetricManagedField" */
  BillableMetricManagedField_aggregate: BillableMetricManagedField_Aggregate;
  /** fetch data from the table: "BillableMetricManagedField" using primary key columns */
  BillableMetricManagedField_by_pk: Maybe<BillableMetricManagedField>;
  /** fetch aggregated fields from the table: "BillableMetric" */
  BillableMetric_aggregate: BillableMetric_Aggregate;
  /** fetch data from the table: "BillableMetric" using primary key columns */
  BillableMetric_by_pk: Maybe<BillableMetric>;
  /** fetch data from the table: "BillingFrequencyEnum" */
  BillingFrequencyEnum: Array<BillingFrequencyEnum>;
  /** fetch aggregated fields from the table: "BillingFrequencyEnum" */
  BillingFrequencyEnum_aggregate: BillingFrequencyEnum_Aggregate;
  /** fetch data from the table: "BillingFrequencyEnum" using primary key columns */
  BillingFrequencyEnum_by_pk: Maybe<BillingFrequencyEnum>;
  /** fetch data from the table: "BillingMetricAggregateEnum" */
  BillingMetricAggregateEnum: Array<BillingMetricAggregateEnum>;
  /** fetch aggregated fields from the table: "BillingMetricAggregateEnum" */
  BillingMetricAggregateEnum_aggregate: BillingMetricAggregateEnum_Aggregate;
  /** fetch data from the table: "BillingMetricAggregateEnum" using primary key columns */
  BillingMetricAggregateEnum_by_pk: Maybe<BillingMetricAggregateEnum>;
  /** fetch data from the table: "BillingProviderCustomer" */
  BillingProviderCustomer: Array<BillingProviderCustomer>;
  /** fetch aggregated fields from the table: "BillingProviderCustomer" */
  BillingProviderCustomer_aggregate: BillingProviderCustomer_Aggregate;
  /** fetch data from the table: "BillingProviderCustomer" using primary key columns */
  BillingProviderCustomer_by_pk: Maybe<BillingProviderCustomer>;
  /** fetch data from the table: "BillingProviderEnum" */
  BillingProviderEnum: Array<BillingProviderEnum>;
  /** fetch aggregated fields from the table: "BillingProviderEnum" */
  BillingProviderEnum_aggregate: BillingProviderEnum_Aggregate;
  /** fetch data from the table: "BillingProviderEnum" using primary key columns */
  BillingProviderEnum_by_pk: Maybe<BillingProviderEnum>;
  /** fetch data from the table: "BillingProviderInvoice" */
  BillingProviderInvoice: Array<BillingProviderInvoice>;
  /** fetch aggregated fields from the table: "BillingProviderInvoice" */
  BillingProviderInvoice_aggregate: BillingProviderInvoice_Aggregate;
  /** fetch data from the table: "BillingProviderInvoice" using primary key columns */
  BillingProviderInvoice_by_pk: Maybe<BillingProviderInvoice>;
  /** fetch data from the table: "BillingProviderToken" */
  BillingProviderToken: Array<BillingProviderToken>;
  /** fetch aggregated fields from the table: "BillingProviderToken" */
  BillingProviderToken_aggregate: BillingProviderToken_Aggregate;
  /** fetch data from the table: "BillingProviderToken" using primary key columns */
  BillingProviderToken_by_pk: Maybe<BillingProviderToken>;
  /** fetch data from the table: "ChargeManagedField" */
  ChargeManagedField: Array<ChargeManagedField>;
  /** fetch aggregated fields from the table: "ChargeManagedField" */
  ChargeManagedField_aggregate: ChargeManagedField_Aggregate;
  /** fetch data from the table: "ChargeManagedField" using primary key columns */
  ChargeManagedField_by_pk: Maybe<ChargeManagedField>;
  /** fetch data from the table: "ChargeTypeEnum" */
  ChargeTypeEnum: Array<ChargeTypeEnum>;
  /** fetch aggregated fields from the table: "ChargeTypeEnum" */
  ChargeTypeEnum_aggregate: ChargeTypeEnum_Aggregate;
  /** fetch data from the table: "ChargeTypeEnum" using primary key columns */
  ChargeTypeEnum_by_pk: Maybe<ChargeTypeEnum>;
  /** fetch data from the table: "Client" */
  Client: Array<Client>;
  /** fetch data from the table: "ClientConfig" */
  ClientConfig: Array<ClientConfig>;
  /** fetch data from the table: "ClientConfigKeyEnum" */
  ClientConfigKeyEnum: Array<ClientConfigKeyEnum>;
  /** fetch aggregated fields from the table: "ClientConfigKeyEnum" */
  ClientConfigKeyEnum_aggregate: ClientConfigKeyEnum_Aggregate;
  /** fetch data from the table: "ClientConfigKeyEnum" using primary key columns */
  ClientConfigKeyEnum_by_pk: Maybe<ClientConfigKeyEnum>;
  /** fetch aggregated fields from the table: "ClientConfig" */
  ClientConfig_aggregate: ClientConfig_Aggregate;
  /** fetch data from the table: "ClientConfig" using primary key columns */
  ClientConfig_by_pk: Maybe<ClientConfig>;
  /** fetch aggregated fields from the table: "Client" */
  Client_aggregate: Client_Aggregate;
  /** fetch data from the table: "Client" using primary key columns */
  Client_by_pk: Maybe<Client>;
  /** fetch data from the table: "CollectionScheduleEnum" */
  CollectionScheduleEnum: Array<CollectionScheduleEnum>;
  /** fetch aggregated fields from the table: "CollectionScheduleEnum" */
  CollectionScheduleEnum_aggregate: CollectionScheduleEnum_Aggregate;
  /** fetch data from the table: "CollectionScheduleEnum" using primary key columns */
  CollectionScheduleEnum_by_pk: Maybe<CollectionScheduleEnum>;
  /** fetch data from the table: "CommitManagedField" */
  CommitManagedField: Array<CommitManagedField>;
  /** fetch aggregated fields from the table: "CommitManagedField" */
  CommitManagedField_aggregate: CommitManagedField_Aggregate;
  /** fetch data from the table: "CommitManagedField" using primary key columns */
  CommitManagedField_by_pk: Maybe<CommitManagedField>;
  /** fetch data from the table: "CompositeCharge" */
  CompositeCharge: Array<CompositeCharge>;
  /** fetch data from the table: "CompositeChargeAdjustment" */
  CompositeChargeAdjustment: Array<CompositeChargeAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeAdjustment" */
  CompositeChargeAdjustment_aggregate: CompositeChargeAdjustment_Aggregate;
  /** fetch data from the table: "CompositeChargeAdjustment" using primary key columns */
  CompositeChargeAdjustment_by_pk: Maybe<CompositeChargeAdjustment>;
  /** fetch data from the table: "CompositeChargePricingFactor" */
  CompositeChargePricingFactor: Array<CompositeChargePricingFactor>;
  /** fetch aggregated fields from the table: "CompositeChargePricingFactor" */
  CompositeChargePricingFactor_aggregate: CompositeChargePricingFactor_Aggregate;
  /** fetch data from the table: "CompositeChargeTier" */
  CompositeChargeTier: Array<CompositeChargeTier>;
  /** fetch data from the table: "CompositeChargeTierAdjustment" */
  CompositeChargeTierAdjustment: Array<CompositeChargeTierAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeTierAdjustment" */
  CompositeChargeTierAdjustment_aggregate: CompositeChargeTierAdjustment_Aggregate;
  /** fetch data from the table: "CompositeChargeTierAdjustment" using primary key columns */
  CompositeChargeTierAdjustment_by_pk: Maybe<CompositeChargeTierAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeTier" */
  CompositeChargeTier_aggregate: CompositeChargeTier_Aggregate;
  /** fetch data from the table: "CompositeChargeTier" using primary key columns */
  CompositeChargeTier_by_pk: Maybe<CompositeChargeTier>;
  /** fetch data from the table: "CompositeChargeTypeEnum" */
  CompositeChargeTypeEnum: Array<CompositeChargeTypeEnum>;
  /** fetch aggregated fields from the table: "CompositeChargeTypeEnum" */
  CompositeChargeTypeEnum_aggregate: CompositeChargeTypeEnum_Aggregate;
  /** fetch data from the table: "CompositeChargeTypeEnum" using primary key columns */
  CompositeChargeTypeEnum_by_pk: Maybe<CompositeChargeTypeEnum>;
  /** fetch aggregated fields from the table: "CompositeCharge" */
  CompositeCharge_aggregate: CompositeCharge_Aggregate;
  /** fetch data from the table: "CompositeCharge" using primary key columns */
  CompositeCharge_by_pk: Maybe<CompositeCharge>;
  /** fetch data from the table: "ContractCreditManagedField" */
  ContractCreditManagedField: Array<ContractCreditManagedField>;
  /** fetch aggregated fields from the table: "ContractCreditManagedField" */
  ContractCreditManagedField_aggregate: ContractCreditManagedField_Aggregate;
  /** fetch data from the table: "ContractCreditManagedField" using primary key columns */
  ContractCreditManagedField_by_pk: Maybe<ContractCreditManagedField>;
  /** fetch data from the table: "ContractManagedField" */
  ContractManagedField: Array<ContractManagedField>;
  /** fetch aggregated fields from the table: "ContractManagedField" */
  ContractManagedField_aggregate: ContractManagedField_Aggregate;
  /** fetch data from the table: "ContractManagedField" using primary key columns */
  ContractManagedField_by_pk: Maybe<ContractManagedField>;
  /** fetch data from the table: "ContractProductManagedField" */
  ContractProductManagedField: Array<ContractProductManagedField>;
  /** fetch aggregated fields from the table: "ContractProductManagedField" */
  ContractProductManagedField_aggregate: ContractProductManagedField_Aggregate;
  /** fetch data from the table: "ContractProductManagedField" using primary key columns */
  ContractProductManagedField_by_pk: Maybe<ContractProductManagedField>;
  /** fetch data from the table: "CreditAdjustment" */
  CreditAdjustment: Array<CreditAdjustment>;
  /** fetch aggregated fields from the table: "CreditAdjustment" */
  CreditAdjustment_aggregate: CreditAdjustment_Aggregate;
  /** fetch data from the table: "CreditAdjustment" using primary key columns */
  CreditAdjustment_by_pk: Maybe<CreditAdjustment>;
  /** fetch data from the table: "CreditGrant" */
  CreditGrant: Array<CreditGrant>;
  /** fetch data from the table: "CreditGrantDeduction" */
  CreditGrantDeduction: Array<CreditGrantDeduction>;
  /** fetch aggregated fields from the table: "CreditGrantDeduction" */
  CreditGrantDeduction_aggregate: CreditGrantDeduction_Aggregate;
  /** fetch data from the table: "CreditGrantDeduction" using primary key columns */
  CreditGrantDeduction_by_pk: Maybe<CreditGrantDeduction>;
  /** fetch data from the table: "CreditGrantHistory" */
  CreditGrantHistory: Array<CreditGrantHistory>;
  /** fetch aggregated fields from the table: "CreditGrantHistory" */
  CreditGrantHistory_aggregate: CreditGrantHistory_Aggregate;
  /** fetch data from the table: "CreditGrantHistory" using primary key columns */
  CreditGrantHistory_by_pk: Maybe<CreditGrantHistory>;
  /** fetch data from the table: "CreditGrantManagedField" */
  CreditGrantManagedField: Array<CreditGrantManagedField>;
  /** fetch aggregated fields from the table: "CreditGrantManagedField" */
  CreditGrantManagedField_aggregate: CreditGrantManagedField_Aggregate;
  /** fetch data from the table: "CreditGrantManagedField" using primary key columns */
  CreditGrantManagedField_by_pk: Maybe<CreditGrantManagedField>;
  /** fetch aggregated fields from the table: "CreditGrant" */
  CreditGrant_aggregate: CreditGrant_Aggregate;
  /** fetch data from the table: "CreditGrant" using primary key columns */
  CreditGrant_by_pk: Maybe<CreditGrant>;
  /** fetch data from the table: "CreditType" */
  CreditType: Array<CreditType>;
  /** fetch data from the table: "CreditTypeConversion" */
  CreditTypeConversion: Array<CreditTypeConversion>;
  /** fetch data from the table: "CreditTypeConversionAdjustment" */
  CreditTypeConversionAdjustment: Array<CreditTypeConversionAdjustment>;
  /** fetch aggregated fields from the table: "CreditTypeConversionAdjustment" */
  CreditTypeConversionAdjustment_aggregate: CreditTypeConversionAdjustment_Aggregate;
  /** fetch data from the table: "CreditTypeConversionAdjustment" using primary key columns */
  CreditTypeConversionAdjustment_by_pk: Maybe<CreditTypeConversionAdjustment>;
  /** fetch aggregated fields from the table: "CreditTypeConversion" */
  CreditTypeConversion_aggregate: CreditTypeConversion_Aggregate;
  /** fetch data from the table: "CreditTypeConversion" using primary key columns */
  CreditTypeConversion_by_pk: Maybe<CreditTypeConversion>;
  /** fetch aggregated fields from the table: "CreditType" */
  CreditType_aggregate: CreditType_Aggregate;
  /** fetch data from the table: "CreditType" using primary key columns */
  CreditType_by_pk: Maybe<CreditType>;
  /** fetch data from the table: "CustomPricing" */
  CustomPricing: Array<CustomPricing>;
  /** fetch aggregated fields from the table: "CustomPricing" */
  CustomPricing_aggregate: CustomPricing_Aggregate;
  /** fetch data from the table: "CustomPricing" using primary key columns */
  CustomPricing_by_pk: Maybe<CustomPricing>;
  /** fetch data from the table: "Customer" */
  Customer: Array<Customer>;
  /** fetch data from the table: "CustomerAlert" */
  CustomerAlert: Array<CustomerAlert>;
  /** fetch data from the table: "CustomerAlertHistory" */
  CustomerAlertHistory: Array<CustomerAlertHistory>;
  /** fetch aggregated fields from the table: "CustomerAlertHistory" */
  CustomerAlertHistory_aggregate: CustomerAlertHistory_Aggregate;
  /** fetch data from the table: "CustomerAlertHistory" using primary key columns */
  CustomerAlertHistory_by_pk: Maybe<CustomerAlertHistory>;
  /** fetch data from the table: "CustomerAlertStatusEnum" */
  CustomerAlertStatusEnum: Array<CustomerAlertStatusEnum>;
  /** fetch aggregated fields from the table: "CustomerAlertStatusEnum" */
  CustomerAlertStatusEnum_aggregate: CustomerAlertStatusEnum_Aggregate;
  /** fetch data from the table: "CustomerAlertStatusEnum" using primary key columns */
  CustomerAlertStatusEnum_by_pk: Maybe<CustomerAlertStatusEnum>;
  /** fetch aggregated fields from the table: "CustomerAlert" */
  CustomerAlert_aggregate: CustomerAlert_Aggregate;
  /** fetch data from the table: "CustomerAlert" using primary key columns */
  CustomerAlert_by_pk: Maybe<CustomerAlert>;
  /** fetch data from the table: "CustomerConfig" */
  CustomerConfig: Array<CustomerConfig>;
  /** fetch aggregated fields from the table: "CustomerConfig" */
  CustomerConfig_aggregate: CustomerConfig_Aggregate;
  /** fetch data from the table: "CustomerConfig" using primary key columns */
  CustomerConfig_by_pk: Maybe<CustomerConfig>;
  /** fetch data from the table: "CustomerIngestAlias" */
  CustomerIngestAlias: Array<CustomerIngestAlias>;
  /** fetch aggregated fields from the table: "CustomerIngestAlias" */
  CustomerIngestAlias_aggregate: CustomerIngestAlias_Aggregate;
  /** fetch data from the table: "CustomerIngestAlias" using primary key columns */
  CustomerIngestAlias_by_pk: Maybe<CustomerIngestAlias>;
  /** fetch data from the table: "CustomerManagedField" */
  CustomerManagedField: Array<CustomerManagedField>;
  /** fetch aggregated fields from the table: "CustomerManagedField" */
  CustomerManagedField_aggregate: CustomerManagedField_Aggregate;
  /** fetch data from the table: "CustomerManagedField" using primary key columns */
  CustomerManagedField_by_pk: Maybe<CustomerManagedField>;
  /** fetch data from the table: "CustomerPlan" */
  CustomerPlan: Array<CustomerPlan>;
  /** fetch data from the table: "CustomerPlanAdjustment" */
  CustomerPlanAdjustment: Array<CustomerPlanAdjustment>;
  /** fetch aggregated fields from the table: "CustomerPlanAdjustment" */
  CustomerPlanAdjustment_aggregate: CustomerPlanAdjustment_Aggregate;
  /** fetch data from the table: "CustomerPlanAdjustment" using primary key columns */
  CustomerPlanAdjustment_by_pk: Maybe<CustomerPlanAdjustment>;
  /** fetch data from the table: "CustomerPlanManagedField" */
  CustomerPlanManagedField: Array<CustomerPlanManagedField>;
  /** fetch aggregated fields from the table: "CustomerPlanManagedField" */
  CustomerPlanManagedField_aggregate: CustomerPlanManagedField_Aggregate;
  /** fetch data from the table: "CustomerPlanManagedField" using primary key columns */
  CustomerPlanManagedField_by_pk: Maybe<CustomerPlanManagedField>;
  /** fetch data from the table: "CustomerPlanOneTimeCharge" */
  CustomerPlanOneTimeCharge: Array<CustomerPlanOneTimeCharge>;
  /** fetch aggregated fields from the table: "CustomerPlanOneTimeCharge" */
  CustomerPlanOneTimeCharge_aggregate: CustomerPlanOneTimeCharge_Aggregate;
  /** fetch data from the table: "CustomerPlanOneTimeCharge" using primary key columns */
  CustomerPlanOneTimeCharge_by_pk: Maybe<CustomerPlanOneTimeCharge>;
  /** fetch aggregated fields from the table: "CustomerPlan" */
  CustomerPlan_aggregate: CustomerPlan_Aggregate;
  /** fetch data from the table: "CustomerPlan" using primary key columns */
  CustomerPlan_by_pk: Maybe<CustomerPlan>;
  /** fetch data from the table: "CustomerRelationship" */
  CustomerRelationship: Array<CustomerRelationship>;
  /** fetch aggregated fields from the table: "CustomerRelationship" */
  CustomerRelationship_aggregate: CustomerRelationship_Aggregate;
  /** fetch data from the table: "CustomerRelationship" using primary key columns */
  CustomerRelationship_by_pk: Maybe<CustomerRelationship>;
  /** fetch aggregated fields from the table: "Customer" */
  Customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "Customer" using primary key columns */
  Customer_by_pk: Maybe<Customer>;
  /** fetch data from the table: "DraftPlan" */
  DraftPlan: Array<DraftPlan>;
  /** fetch aggregated fields from the table: "DraftPlan" */
  DraftPlan_aggregate: DraftPlan_Aggregate;
  /** fetch data from the table: "DraftPlan" using primary key columns */
  DraftPlan_by_pk: Maybe<DraftPlan>;
  /** fetch data from the table: "EnvironmentTypeEnum" */
  EnvironmentTypeEnum: Array<EnvironmentTypeEnum>;
  /** fetch aggregated fields from the table: "EnvironmentTypeEnum" */
  EnvironmentTypeEnum_aggregate: EnvironmentTypeEnum_Aggregate;
  /** fetch data from the table: "EnvironmentTypeEnum" using primary key columns */
  EnvironmentTypeEnum_by_pk: Maybe<EnvironmentTypeEnum>;
  /** fetch data from the table: "FlatFee" */
  FlatFee: Array<FlatFee>;
  /** fetch data from the table: "FlatFeeAdjustment" */
  FlatFeeAdjustment: Array<FlatFeeAdjustment>;
  /** fetch aggregated fields from the table: "FlatFeeAdjustment" */
  FlatFeeAdjustment_aggregate: FlatFeeAdjustment_Aggregate;
  /** fetch data from the table: "FlatFeeAdjustment" using primary key columns */
  FlatFeeAdjustment_by_pk: Maybe<FlatFeeAdjustment>;
  /** fetch aggregated fields from the table: "FlatFee" */
  FlatFee_aggregate: FlatFee_Aggregate;
  /** fetch data from the table: "FlatFee" using primary key columns */
  FlatFee_by_pk: Maybe<FlatFee>;
  /** fetch data from the table: "InvoiceExternalStatusEnum" */
  InvoiceExternalStatusEnum: Array<InvoiceExternalStatusEnum>;
  /** fetch aggregated fields from the table: "InvoiceExternalStatusEnum" */
  InvoiceExternalStatusEnum_aggregate: InvoiceExternalStatusEnum_Aggregate;
  /** fetch data from the table: "InvoiceExternalStatusEnum" using primary key columns */
  InvoiceExternalStatusEnum_by_pk: Maybe<InvoiceExternalStatusEnum>;
  /** fetch data from the table: "InvoiceManagedField" */
  InvoiceManagedField: Array<InvoiceManagedField>;
  /** fetch aggregated fields from the table: "InvoiceManagedField" */
  InvoiceManagedField_aggregate: InvoiceManagedField_Aggregate;
  /** fetch data from the table: "InvoiceManagedField" using primary key columns */
  InvoiceManagedField_by_pk: Maybe<InvoiceManagedField>;
  /** fetch data from the table: "ManagedEntityEnum" */
  ManagedEntityEnum: Array<ManagedEntityEnum>;
  /** fetch aggregated fields from the table: "ManagedEntityEnum" */
  ManagedEntityEnum_aggregate: ManagedEntityEnum_Aggregate;
  /** fetch data from the table: "ManagedEntityEnum" using primary key columns */
  ManagedEntityEnum_by_pk: Maybe<ManagedEntityEnum>;
  /** fetch data from the table: "ManagedFieldKey" */
  ManagedFieldKey: Array<ManagedFieldKey>;
  /** fetch aggregated fields from the table: "ManagedFieldKey" */
  ManagedFieldKey_aggregate: ManagedFieldKey_Aggregate;
  /** fetch data from the table: "ManagedFieldKey" using primary key columns */
  ManagedFieldKey_by_pk: Maybe<ManagedFieldKey>;
  /** fetch data from the table: "ManagedFieldVendorMapping" */
  ManagedFieldVendorMapping: Array<ManagedFieldVendorMapping>;
  /** fetch aggregated fields from the table: "ManagedFieldVendorMapping" */
  ManagedFieldVendorMapping_aggregate: ManagedFieldVendorMapping_Aggregate;
  /** fetch data from the table: "ManagedFieldVendorMapping" using primary key columns */
  ManagedFieldVendorMapping_by_pk: Maybe<ManagedFieldVendorMapping>;
  /** fetch data from the table: "ManagedVendorEnum" */
  ManagedVendorEnum: Array<ManagedVendorEnum>;
  /** fetch aggregated fields from the table: "ManagedVendorEnum" */
  ManagedVendorEnum_aggregate: ManagedVendorEnum_Aggregate;
  /** fetch data from the table: "ManagedVendorEnum" using primary key columns */
  ManagedVendorEnum_by_pk: Maybe<ManagedVendorEnum>;
  /** fetch data from the table: "Minimum" */
  Minimum: Array<Minimum>;
  /** fetch aggregated fields from the table: "Minimum" */
  Minimum_aggregate: Minimum_Aggregate;
  /** fetch data from the table: "Minimum" using primary key columns */
  Minimum_by_pk: Maybe<Minimum>;
  /** fetch data from the table: "Plan" */
  Plan: Array<Plan>;
  /** fetch data from the table: "PlanManagedField" */
  PlanManagedField: Array<PlanManagedField>;
  /** fetch aggregated fields from the table: "PlanManagedField" */
  PlanManagedField_aggregate: PlanManagedField_Aggregate;
  /** fetch data from the table: "PlanManagedField" using primary key columns */
  PlanManagedField_by_pk: Maybe<PlanManagedField>;
  /** fetch aggregated fields from the table: "Plan" */
  Plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "Plan" using primary key columns */
  Plan_by_pk: Maybe<Plan>;
  /** fetch data from the table: "Price" */
  Price: Array<Price>;
  /** fetch data from the table: "PriceAdjustment" */
  PriceAdjustment: Array<PriceAdjustment>;
  /** fetch data from the table: "PriceAdjustmentTypeEnum" */
  PriceAdjustmentTypeEnum: Array<PriceAdjustmentTypeEnum>;
  /** fetch aggregated fields from the table: "PriceAdjustmentTypeEnum" */
  PriceAdjustmentTypeEnum_aggregate: PriceAdjustmentTypeEnum_Aggregate;
  /** fetch data from the table: "PriceAdjustmentTypeEnum" using primary key columns */
  PriceAdjustmentTypeEnum_by_pk: Maybe<PriceAdjustmentTypeEnum>;
  /** fetch aggregated fields from the table: "PriceAdjustment" */
  PriceAdjustment_aggregate: PriceAdjustment_Aggregate;
  /** fetch data from the table: "PriceAdjustment" using primary key columns */
  PriceAdjustment_by_pk: Maybe<PriceAdjustment>;
  /** fetch aggregated fields from the table: "Price" */
  Price_aggregate: Price_Aggregate;
  /** fetch data from the table: "Price" using primary key columns */
  Price_by_pk: Maybe<Price>;
  /** fetch data from the table: "PricedProduct" */
  PricedProduct: Array<PricedProduct>;
  /** fetch data from the table: "PricedProductAdjustment" */
  PricedProductAdjustment: Array<PricedProductAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductAdjustment" */
  PricedProductAdjustment_aggregate: PricedProductAdjustment_Aggregate;
  /** fetch data from the table: "PricedProductAdjustment" using primary key columns */
  PricedProductAdjustment_by_pk: Maybe<PricedProductAdjustment>;
  /** fetch data from the table: "PricedProductPricingFactor" */
  PricedProductPricingFactor: Array<PricedProductPricingFactor>;
  /** fetch data from the table: "PricedProductPricingFactorAdjustment" */
  PricedProductPricingFactorAdjustment: Array<PricedProductPricingFactorAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductPricingFactorAdjustment" */
  PricedProductPricingFactorAdjustment_aggregate: PricedProductPricingFactorAdjustment_Aggregate;
  /** fetch data from the table: "PricedProductPricingFactorAdjustment" using primary key columns */
  PricedProductPricingFactorAdjustment_by_pk: Maybe<PricedProductPricingFactorAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductPricingFactor" */
  PricedProductPricingFactor_aggregate: PricedProductPricingFactor_Aggregate;
  /** fetch data from the table: "PricedProductPricingFactor" using primary key columns */
  PricedProductPricingFactor_by_pk: Maybe<PricedProductPricingFactor>;
  /** fetch aggregated fields from the table: "PricedProduct" */
  PricedProduct_aggregate: PricedProduct_Aggregate;
  /** fetch data from the table: "PricedProduct" using primary key columns */
  PricedProduct_by_pk: Maybe<PricedProduct>;
  /** fetch data from the table: "ProServiceManagedField" */
  ProServiceManagedField: Array<ProServiceManagedField>;
  /** fetch aggregated fields from the table: "ProServiceManagedField" */
  ProServiceManagedField_aggregate: ProServiceManagedField_Aggregate;
  /** fetch data from the table: "ProServiceManagedField" using primary key columns */
  ProServiceManagedField_by_pk: Maybe<ProServiceManagedField>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductManagedField" */
  ProductManagedField: Array<ProductManagedField>;
  /** fetch aggregated fields from the table: "ProductManagedField" */
  ProductManagedField_aggregate: ProductManagedField_Aggregate;
  /** fetch data from the table: "ProductManagedField" using primary key columns */
  ProductManagedField_by_pk: Maybe<ProductManagedField>;
  /** fetch data from the table: "ProductPricingFactor" */
  ProductPricingFactor: Array<ProductPricingFactor>;
  /** fetch aggregated fields from the table: "ProductPricingFactor" */
  ProductPricingFactor_aggregate: ProductPricingFactor_Aggregate;
  /** fetch data from the table: "ProductPricingFactor" using primary key columns */
  ProductPricingFactor_by_pk: Maybe<ProductPricingFactor>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: Product_Aggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk: Maybe<Product>;
  /** fetch data from the table: "RateCardManagedField" */
  RateCardManagedField: Array<RateCardManagedField>;
  /** fetch aggregated fields from the table: "RateCardManagedField" */
  RateCardManagedField_aggregate: RateCardManagedField_Aggregate;
  /** fetch data from the table: "RateCardManagedField" using primary key columns */
  RateCardManagedField_by_pk: Maybe<RateCardManagedField>;
  /** fetch data from the table: "RecurringCreditGrant" */
  RecurringCreditGrant: Array<RecurringCreditGrant>;
  /** fetch aggregated fields from the table: "RecurringCreditGrant" */
  RecurringCreditGrant_aggregate: RecurringCreditGrant_Aggregate;
  /** fetch data from the table: "RecurringCreditGrant" using primary key columns */
  RecurringCreditGrant_by_pk: Maybe<RecurringCreditGrant>;
  /** fetch data from the table: "Role" */
  Role: Array<Role>;
  /** fetch aggregated fields from the table: "Role" */
  Role_aggregate: Role_Aggregate;
  /** fetch data from the table: "Role" using primary key columns */
  Role_by_pk: Maybe<Role>;
  /** fetch data from the table: "RoundingBehaviorEnum" */
  RoundingBehaviorEnum: Array<RoundingBehaviorEnum>;
  /** fetch aggregated fields from the table: "RoundingBehaviorEnum" */
  RoundingBehaviorEnum_aggregate: RoundingBehaviorEnum_Aggregate;
  /** fetch data from the table: "RoundingBehaviorEnum" using primary key columns */
  RoundingBehaviorEnum_by_pk: Maybe<RoundingBehaviorEnum>;
  /** fetch data from the table: "ScheduledChargeManagedField" */
  ScheduledChargeManagedField: Array<ScheduledChargeManagedField>;
  /** fetch aggregated fields from the table: "ScheduledChargeManagedField" */
  ScheduledChargeManagedField_aggregate: ScheduledChargeManagedField_Aggregate;
  /** fetch data from the table: "ScheduledChargeManagedField" using primary key columns */
  ScheduledChargeManagedField_by_pk: Maybe<ScheduledChargeManagedField>;
  /** fetch data from the table: "ServicePeriodStartOnEnum" */
  ServicePeriodStartOnEnum: Array<ServicePeriodStartOnEnum>;
  /** fetch aggregated fields from the table: "ServicePeriodStartOnEnum" */
  ServicePeriodStartOnEnum_aggregate: ServicePeriodStartOnEnum_Aggregate;
  /** fetch data from the table: "ServicePeriodStartOnEnum" using primary key columns */
  ServicePeriodStartOnEnum_by_pk: Maybe<ServicePeriodStartOnEnum>;
  /** fetch data from the table: "StripePrepaidConfig_temporary" */
  StripePrepaidConfig_temporary: Array<StripePrepaidConfig_Temporary>;
  /** fetch aggregated fields from the table: "StripePrepaidConfig_temporary" */
  StripePrepaidConfig_temporary_aggregate: StripePrepaidConfig_Temporary_Aggregate;
  /** fetch data from the table: "StripePrepaidConfig_temporary" using primary key columns */
  StripePrepaidConfig_temporary_by_pk: Maybe<StripePrepaidConfig_Temporary>;
  /** fetch data from the table: "TieringModeEnum" */
  TieringModeEnum: Array<TieringModeEnum>;
  /** fetch aggregated fields from the table: "TieringModeEnum" */
  TieringModeEnum_aggregate: TieringModeEnum_Aggregate;
  /** fetch data from the table: "TieringModeEnum" using primary key columns */
  TieringModeEnum_by_pk: Maybe<TieringModeEnum>;
  /** An array relationship */
  Tokens: Array<Tokens>;
  /** An aggregate relationship */
  Tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "Tokens" using primary key columns */
  Tokens_by_pk: Maybe<Tokens>;
  /** fetch data from the table: "TrialSpec" */
  TrialSpec: Array<TrialSpec>;
  /** fetch data from the table: "TrialSpecSpendingCap" */
  TrialSpecSpendingCap: Array<TrialSpecSpendingCap>;
  /** fetch aggregated fields from the table: "TrialSpecSpendingCap" */
  TrialSpecSpendingCap_aggregate: TrialSpecSpendingCap_Aggregate;
  /** fetch data from the table: "TrialSpecSpendingCap" using primary key columns */
  TrialSpecSpendingCap_by_pk: Maybe<TrialSpecSpendingCap>;
  /** fetch aggregated fields from the table: "TrialSpec" */
  TrialSpec_aggregate: TrialSpec_Aggregate;
  /** fetch data from the table: "TrialSpec" using primary key columns */
  TrialSpec_by_pk: Maybe<TrialSpec>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserAuthTypeEnum" */
  UserAuthTypeEnum: Array<UserAuthTypeEnum>;
  /** fetch aggregated fields from the table: "UserAuthTypeEnum" */
  UserAuthTypeEnum_aggregate: UserAuthTypeEnum_Aggregate;
  /** fetch data from the table: "UserAuthTypeEnum" using primary key columns */
  UserAuthTypeEnum_by_pk: Maybe<UserAuthTypeEnum>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk: Maybe<User>;
  /** fetch data from the table: "Webhook" */
  Webhook: Array<Webhook>;
  /** fetch data from the table: "webhook_notifications" */
  WebhookNotification: Array<WebhookNotification>;
  /** fetch aggregated fields from the table: "webhook_notifications" */
  WebhookNotification_aggregate: WebhookNotification_Aggregate;
  /** fetch data from the table: "webhook_notifications" using primary key columns */
  WebhookNotification_by_pk: Maybe<WebhookNotification>;
  /** fetch aggregated fields from the table: "Webhook" */
  Webhook_aggregate: Webhook_Aggregate;
  /** fetch data from the table: "Webhook" using primary key columns */
  Webhook_by_pk: Maybe<Webhook>;
  add_rate_event_effective_dates: EffectiveDates;
  alerts: Array<Alert>;
  all_users: Array<User>;
  audit_log: Maybe<AuditLog>;
  audit_logs: AuditLogResponse;
  auth_check: AuthCheckResponse;
  auth_check_mri: AuthCheckResponse;
  billable_metrics: Array<BillableMetric>;
  breakdown_cursor: BreakdownCursor;
  client_dashboard: ClientDashboardMetrics;
  client_overview: ClientOverview;
  clients_using_contracts: ClientsUsingContracts;
  clients_using_prepaid_credits: ClientsUsingPrepaidCredits;
  contract_consistency: ContractConsistency;
  contract_pricing: ContractPricing;
  contract_stats: ContractStats;
  credit_grants: CreditGrantPage;
  customer_billing_provider_configurations: Array<CustomerBillingProviderConfiguration>;
  customers_using_contract_count: Scalars['Int'];
  customers_using_contracts: CustomersUsingContracts;
  customers_with_credit_grants_taking_effect_or_expiring_within_time_range: CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse;
  customers_with_recurring_credit_grants: Array<Customer>;
  environments: Array<Environment>;
  event_count: Array<EventCountWindow>;
  events: Array<Event>;
  get_active_customer_contracts_for_billing_provider: ContractPage;
  get_billing_provider_config_notifications: Array<Maybe<CustomerBillingProviderConfigurationNotification>>;
  get_census_object_sync_runs: Array<CensusObjectSyncReport>;
  get_census_workspace: CensusWorkspace;
  invoice_counts_by_finalization_status: InvoiceCountsByFinalizationStatusResponse;
  invoice_finalization_metadata: Array<Maybe<InvoiceFinalizationMetadata>>;
  invoice_finalization_progress_count: InvoiceFinalizationProgressCountResponse;
  /** Returns a list of all invoices for a given client, filtered by the given parameters. */
  invoices: InvoicePage;
  is_customer_billing_provider_configuration_enabled: Scalars['Boolean'];
  is_delta_stream_enabled: Scalars['Boolean'];
  list_customers: Maybe<CustomerList>;
  list_delivery_methods: ListDeliveryMethodsResponse;
  list_delivery_methods_without_configuration_for_all_clients: ListDeliveryMethodsWithoutConfigurationForAllClientsResponse;
  list_failed_marketplace_metering_records: FailedMarketplacesMeteringRecordList;
  /** Returns the calling User, and ensures it's provisioned in the DB. */
  me: User;
  /**
   * Pass force_invoice_recalculation to force the invoice to be recalculated
   * even if it has already been finalized.
   * This is useful for testing the invoice recalculation logic and re-computing invoices
   * that need to be updated due to a reflow
   */
  mri_invoice: Maybe<Mri_Invoice>;
  pending_prepaid_credit_grants: PendingPrepaidGrantsResponse;
  pending_recharges: PendingRechargesResponse;
  /** Get plans corresponding to the given PlanTypeEnum. */
  plans: Array<Plan>;
  products: Array<Product>;
  products_and_rate_cards: ProductsAndRateCards;
  scheduled_webhook_notifications: Maybe<ScheduledWebhookNotificationList>;
  searchCustomers: Array<Customer>;
  seat_metric: SeatMetric;
  seat_metrics: PagedSeatMetricsResult;
  simulate_fancy_metric_usage: SimulatedFancyMetricUsageResult;
  stripeCustomer: Maybe<StripeCustomer>;
  unpaid_invoices: Array<UnpaidInvoiceIdentifier>;
  validate_billable_metric: MetricValidationResponse;
  validate_contract_input: MutationResponse;
  validate_fancy_metric: FancyMetricValidationResponse;
};


export type QueryAccountTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AccountTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountTypeEnum_Order_By>>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};


export type QueryAccountTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccountTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountTypeEnum_Order_By>>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};


export type QueryAccountTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryActorArgs = {
  distinct_on?: InputMaybe<Array<Actor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Actor_Order_By>>;
  where?: InputMaybe<Actor_Bool_Exp>;
};


export type QueryActor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Actor_Order_By>>;
  where?: InputMaybe<Actor_Bool_Exp>;
};


export type QueryActor_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryAlertArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type QueryAlertManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


export type QueryAlertManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


export type QueryAlertManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryAlertTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AlertTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypeEnum_Order_By>>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};


export type QueryAlertTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypeEnum_Order_By>>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};


export type QueryAlertTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type QueryAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryBillableMetricArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


export type QueryBillableMetricManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<BillableMetricManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetricManagedField_Order_By>>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};


export type QueryBillableMetricManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillableMetricManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetricManagedField_Order_By>>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};


export type QueryBillableMetricManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryBillableMetric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


export type QueryBillableMetric_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryBillingFrequencyEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingFrequencyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingFrequencyEnum_Order_By>>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};


export type QueryBillingFrequencyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingFrequencyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingFrequencyEnum_Order_By>>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};


export type QueryBillingFrequencyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryBillingMetricAggregateEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingMetricAggregateEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingMetricAggregateEnum_Order_By>>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};


export type QueryBillingMetricAggregateEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingMetricAggregateEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingMetricAggregateEnum_Order_By>>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};


export type QueryBillingMetricAggregateEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryBillingProviderCustomerArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


export type QueryBillingProviderCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


export type QueryBillingProviderCustomer_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  customer_id: Scalars['uuid'];
};


export type QueryBillingProviderEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderEnum_Order_By>>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};


export type QueryBillingProviderEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderEnum_Order_By>>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};


export type QueryBillingProviderEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryBillingProviderInvoiceArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderInvoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderInvoice_Order_By>>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};


export type QueryBillingProviderInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderInvoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderInvoice_Order_By>>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};


export type QueryBillingProviderInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryBillingProviderTokenArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


export type QueryBillingProviderToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


export type QueryBillingProviderToken_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


export type QueryChargeManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


export type QueryChargeManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


export type QueryChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryChargeTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeTypeEnum_Order_By>>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};


export type QueryChargeTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeTypeEnum_Order_By>>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};


export type QueryChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type QueryClientConfigArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


export type QueryClientConfigKeyEnumArgs = {
  distinct_on?: InputMaybe<Array<ClientConfigKeyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfigKeyEnum_Order_By>>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};


export type QueryClientConfigKeyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientConfigKeyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfigKeyEnum_Order_By>>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};


export type QueryClientConfigKeyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryClientConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


export type QueryClientConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type QueryClient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCollectionScheduleEnumArgs = {
  distinct_on?: InputMaybe<Array<CollectionScheduleEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CollectionScheduleEnum_Order_By>>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};


export type QueryCollectionScheduleEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CollectionScheduleEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CollectionScheduleEnum_Order_By>>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};


export type QueryCollectionScheduleEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryCommitManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


export type QueryCommitManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


export type QueryCommitManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCompositeChargeArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


export type QueryCompositeChargeAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


export type QueryCompositeChargeAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


export type QueryCompositeChargeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCompositeChargePricingFactorArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


export type QueryCompositeChargePricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


export type QueryCompositeChargeTierArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


export type QueryCompositeChargeTierAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


export type QueryCompositeChargeTierAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


export type QueryCompositeChargeTierAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCompositeChargeTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


export type QueryCompositeChargeTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCompositeChargeTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTypeEnum_Order_By>>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};


export type QueryCompositeChargeTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTypeEnum_Order_By>>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};


export type QueryCompositeChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryCompositeCharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


export type QueryCompositeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryContractCreditManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


export type QueryContractCreditManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


export type QueryContractCreditManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryContractManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


export type QueryContractManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


export type QueryContractManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryContractProductManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


export type QueryContractProductManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


export type QueryContractProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CreditAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditAdjustment_Order_By>>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};


export type QueryCreditAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditAdjustment_Order_By>>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};


export type QueryCreditAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditGrantArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


export type QueryCreditGrantDeductionArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


export type QueryCreditGrantDeduction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


export type QueryCreditGrantDeduction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditGrantHistoryArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantHistory_Order_By>>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};


export type QueryCreditGrantHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantHistory_Order_By>>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};


export type QueryCreditGrantHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditGrantManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


export type QueryCreditGrantManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


export type QueryCreditGrantManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditGrant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


export type QueryCreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditTypeArgs = {
  distinct_on?: InputMaybe<Array<CreditType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditType_Order_By>>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};


export type QueryCreditTypeConversionArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


export type QueryCreditTypeConversionAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


export type QueryCreditTypeConversionAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


export type QueryCreditTypeConversionAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditTypeConversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


export type QueryCreditTypeConversion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCreditType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditType_Order_By>>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};


export type QueryCreditType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomPricingArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


export type QueryCustomPricing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


export type QueryCustomPricing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type QueryCustomerAlertArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


export type QueryCustomerAlertHistoryArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


export type QueryCustomerAlertHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


export type QueryCustomerAlertHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerAlertStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertStatusEnum_Order_By>>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};


export type QueryCustomerAlertStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertStatusEnum_Order_By>>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};


export type QueryCustomerAlertStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryCustomerAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


export type QueryCustomerAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerConfigArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


export type QueryCustomerConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


export type QueryCustomerConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerIngestAliasArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


export type QueryCustomerIngestAlias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


export type QueryCustomerIngestAlias_By_PkArgs = {
  client_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ingest_alias: Scalars['String'];
};


export type QueryCustomerManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


export type QueryCustomerManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


export type QueryCustomerManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerPlanArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


export type QueryCustomerPlanAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


export type QueryCustomerPlanAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


export type QueryCustomerPlanAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerPlanManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


export type QueryCustomerPlanManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


export type QueryCustomerPlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerPlanOneTimeChargeArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanOneTimeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanOneTimeCharge_Order_By>>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};


export type QueryCustomerPlanOneTimeCharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanOneTimeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanOneTimeCharge_Order_By>>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};


export type QueryCustomerPlanOneTimeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


export type QueryCustomerPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomerRelationshipArgs = {
  distinct_on?: InputMaybe<Array<CustomerRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerRelationship_Order_By>>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};


export type QueryCustomerRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerRelationship_Order_By>>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};


export type QueryCustomerRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type QueryCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryDraftPlanArgs = {
  distinct_on?: InputMaybe<Array<DraftPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DraftPlan_Order_By>>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};


export type QueryDraftPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DraftPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DraftPlan_Order_By>>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};


export type QueryDraftPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryEnvironmentTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnvironmentTypeEnum_Order_By>>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};


export type QueryEnvironmentTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnvironmentTypeEnum_Order_By>>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};


export type QueryEnvironmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryFlatFeeArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


export type QueryFlatFeeAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


export type QueryFlatFeeAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


export type QueryFlatFeeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryFlatFee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


export type QueryFlatFee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryInvoiceExternalStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<InvoiceExternalStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceExternalStatusEnum_Order_By>>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};


export type QueryInvoiceExternalStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceExternalStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceExternalStatusEnum_Order_By>>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};


export type QueryInvoiceExternalStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryInvoiceManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


export type QueryInvoiceManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


export type QueryInvoiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryManagedEntityEnumArgs = {
  distinct_on?: InputMaybe<Array<ManagedEntityEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedEntityEnum_Order_By>>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};


export type QueryManagedEntityEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedEntityEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedEntityEnum_Order_By>>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};


export type QueryManagedEntityEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryManagedFieldKeyArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldKey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldKey_Order_By>>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};


export type QueryManagedFieldKey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldKey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldKey_Order_By>>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};


export type QueryManagedFieldKey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryManagedFieldVendorMappingArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldVendorMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldVendorMapping_Order_By>>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};


export type QueryManagedFieldVendorMapping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldVendorMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldVendorMapping_Order_By>>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};


export type QueryManagedFieldVendorMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryManagedVendorEnumArgs = {
  distinct_on?: InputMaybe<Array<ManagedVendorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedVendorEnum_Order_By>>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};


export type QueryManagedVendorEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedVendorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedVendorEnum_Order_By>>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};


export type QueryManagedVendorEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryMinimumArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


export type QueryMinimum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


export type QueryMinimum_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPlanArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type QueryPlanManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


export type QueryPlanManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


export type QueryPlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type QueryPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPriceArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type QueryPriceAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


export type QueryPriceAdjustmentTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustmentTypeEnum_Order_By>>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};


export type QueryPriceAdjustmentTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustmentTypeEnum_Order_By>>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};


export type QueryPriceAdjustmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryPriceAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


export type QueryPriceAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPrice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type QueryPrice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPricedProductArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


export type QueryPricedProductAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


export type QueryPricedProductAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


export type QueryPricedProductAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPricedProductPricingFactorArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


export type QueryPricedProductPricingFactorAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


export type QueryPricedProductPricingFactorAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


export type QueryPricedProductPricingFactorAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPricedProductPricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


export type QueryPricedProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryPricedProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


export type QueryPricedProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryProServiceManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


export type QueryProServiceManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


export type QueryProServiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type QueryProductManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


export type QueryProductManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


export type QueryProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryProductPricingFactorArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


export type QueryProductPricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


export type QueryProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type QueryProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRateCardManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


export type QueryRateCardManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


export type QueryRateCardManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRecurringCreditGrantArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


export type QueryRecurringCreditGrant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


export type QueryRecurringCreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type QueryRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type QueryRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryRoundingBehaviorEnumArgs = {
  distinct_on?: InputMaybe<Array<RoundingBehaviorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundingBehaviorEnum_Order_By>>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};


export type QueryRoundingBehaviorEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RoundingBehaviorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundingBehaviorEnum_Order_By>>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};


export type QueryRoundingBehaviorEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryScheduledChargeManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


export type QueryScheduledChargeManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


export type QueryScheduledChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryServicePeriodStartOnEnumArgs = {
  distinct_on?: InputMaybe<Array<ServicePeriodStartOnEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServicePeriodStartOnEnum_Order_By>>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};


export type QueryServicePeriodStartOnEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServicePeriodStartOnEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServicePeriodStartOnEnum_Order_By>>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};


export type QueryServicePeriodStartOnEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryStripePrepaidConfig_TemporaryArgs = {
  distinct_on?: InputMaybe<Array<StripePrepaidConfig_Temporary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripePrepaidConfig_Temporary_Order_By>>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};


export type QueryStripePrepaidConfig_Temporary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StripePrepaidConfig_Temporary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripePrepaidConfig_Temporary_Order_By>>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};


export type QueryStripePrepaidConfig_Temporary_By_PkArgs = {
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


export type QueryTieringModeEnumArgs = {
  distinct_on?: InputMaybe<Array<TieringModeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TieringModeEnum_Order_By>>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};


export type QueryTieringModeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TieringModeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TieringModeEnum_Order_By>>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};


export type QueryTieringModeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type QueryTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type QueryTokens_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryTrialSpecArgs = {
  distinct_on?: InputMaybe<Array<TrialSpec_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpec_Order_By>>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};


export type QueryTrialSpecSpendingCapArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


export type QueryTrialSpecSpendingCap_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


export type QueryTrialSpecSpendingCap_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryTrialSpec_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TrialSpec_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpec_Order_By>>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};


export type QueryTrialSpec_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUserAuthTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<UserAuthTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAuthTypeEnum_Order_By>>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};


export type QueryUserAuthTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAuthTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAuthTypeEnum_Order_By>>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};


export type QueryUserAuthTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUser_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryWebhookArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Order_By>>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};


export type QueryWebhookNotificationArgs = {
  distinct_on?: InputMaybe<Array<WebhookNotification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WebhookNotification_Order_By>>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};


export type QueryWebhookNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WebhookNotification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WebhookNotification_Order_By>>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};


export type QueryWebhookNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryWebhook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Order_By>>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};


export type QueryWebhook_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryAdd_Rate_Event_Effective_DatesArgs = {
  aggregate_id: Scalars['String'];
  aggregate_version: Scalars['Int'];
};


export type QueryAlertsArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  include_internal_alerts?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  types: Array<AlertStatusEnum>;
};


export type QueryAll_UsersArgs = {
  sort?: InputMaybe<UserSortingInput>;
};


export type QueryAudit_LogArgs = {
  logID: Scalars['String'];
  requestTimestamp: Scalars['timestamptz'];
};


export type QueryAudit_LogsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  descending?: InputMaybe<Scalars['Boolean']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  limit?: InputMaybe<Scalars['Int']>;
  resource_id?: InputMaybe<Scalars['String']>;
  resource_type?: InputMaybe<Scalars['String']>;
  starting_after?: InputMaybe<Scalars['timestamptz']>;
};


export type QueryAuth_CheckArgs = {
  args?: InputMaybe<Scalars['JSONObject']>;
  resolver: Scalars['String'];
  type: AuthCheckType;
};


export type QueryAuth_Check_MriArgs = {
  args?: InputMaybe<Scalars['JSONObject']>;
  resolver: Scalars['String'];
  type: AuthCheckType;
};


export type QueryBillable_MetricsArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  group_key_filter?: InputMaybe<BillableMetricGroupKeyFilterEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<BillableMetricSortingInput>;
  types: Array<BillableMetricTypeEnum>;
};


export type QueryBreakdown_CursorArgs = {
  customer_id: Scalars['uuid'];
  ending_before: Scalars['timestamptz'];
  invoice_id: Scalars['uuid'];
  starting_at: Scalars['timestamptz'];
};


export type QueryClients_Using_ContractsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter_archived?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryClients_Using_Prepaid_CreditsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryCredit_GrantsArgs = {
  amount_granted_credit_types?: InputMaybe<UuidFilter>;
  cursor?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<UuidFilter>;
  effective_at?: InputMaybe<TimeRangeInput>;
  expires_before?: InputMaybe<TimeRangeInput>;
  ids?: InputMaybe<UuidFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  voided_at?: InputMaybe<NullableTimeRangeInput>;
};


export type QueryCustomer_Billing_Provider_ConfigurationsArgs = {
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  customer_id: Scalars['uuid'];
  delivery_method?: InputMaybe<BillingProviderDeliveryMethod_Enum>;
};


export type QueryCustomers_Using_ContractsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  idEndingBefore?: InputMaybe<Scalars['uuid']>;
  idStartingAt?: InputMaybe<Scalars['uuid']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomers_With_Credit_Grants_Taking_Effect_Or_Expiring_Within_Time_RangeArgs = {
  client_id: Scalars['uuid'];
  credit_grant_time_range: RequiredTimeRangeInput;
  customer_id_range?: InputMaybe<UuidRange>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  last_customer_id?: InputMaybe<Scalars['uuid']>;
  limit: Scalars['numeric'];
};


export type QueryCustomers_With_Recurring_Credit_GrantsArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  ids: UuidRange;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryEvent_CountArgs = {
  billable_metric_ids?: InputMaybe<Array<Scalars['uuid']>>;
  customer_ids?: InputMaybe<Array<Scalars['uuid']>>;
  duplicates?: InputMaybe<Scalars['Boolean']>;
  ending_before: Scalars['timestamptz'];
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_aliases?: InputMaybe<Array<Scalars['String']>>;
  starting_after: Scalars['timestamptz'];
};


export type QueryEventsArgs = {
  billable_metric_ids?: InputMaybe<Array<Scalars['uuid']>>;
  customer_ids?: InputMaybe<Array<Scalars['uuid']>>;
  duplicates?: InputMaybe<Scalars['Boolean']>;
  ending_before: Scalars['timestamptz'];
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  ingest_aliases?: InputMaybe<Array<Scalars['String']>>;
  limit: Scalars['Int'];
  starting_after: Scalars['timestamptz'];
  transaction_ids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGet_Active_Customer_Contracts_For_Billing_ProviderArgs = {
  active_at?: InputMaybe<Scalars['timestamptz']>;
  billing_providers: Array<BillingProviderEnum_Enum>;
  cursor?: InputMaybe<Scalars['String']>;
  customer_ids?: InputMaybe<Array<Scalars['uuid']>>;
  delivery_method?: InputMaybe<BillingProviderDeliveryMethodEnum_Enum>;
  grace_period_hours: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGet_Billing_Provider_Config_NotificationsArgs = {
  endDate: Scalars['timestamptz'];
  startDate: Scalars['timestamptz'];
};


export type QueryGet_Census_Object_Sync_RunsArgs = {
  destination_type: CensusDestinationTypeEnum;
  num_recent_syncs?: InputMaybe<Scalars['Int']>;
};


export type QueryGet_Census_WorkspaceArgs = {
  destination_type?: InputMaybe<CensusDestinationTypeEnum>;
  workspace_id?: InputMaybe<Scalars['String']>;
};


export type QueryInvoice_Counts_By_Finalization_StatusArgs = {
  finalization_hold_date: Scalars['timestamptz'];
};


export type QueryInvoice_Finalization_Progress_CountArgs = {
  metadata_id: Scalars['uuid'];
};


export type QueryInvoicesArgs = {
  billable_status?: InputMaybe<BillableStatusFilter>;
  billing_provider?: InputMaybe<BillingProviderFilter>;
  billing_provider_invoice_external_status?: InputMaybe<BillingProviderInvoiceExternalStatusFilter>;
  contract_amendments?: InputMaybe<UuidFilter>;
  contracts?: InputMaybe<UuidFilter>;
  cursor?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<UuidFilter>;
  date?: InputMaybe<TimeRangeInput>;
  exclude_archived_customers?: InputMaybe<Scalars['Boolean']>;
  exclude_on_hold_invoices?: InputMaybe<Scalars['Boolean']>;
  invoice_id?: InputMaybe<UuidRange>;
  limit?: InputMaybe<Scalars['Int']>;
  override_presentation_key?: InputMaybe<Array<Scalars['String']>>;
  plans?: InputMaybe<UuidFilter>;
  statuses: Array<InvoiceStatusEnum>;
  types?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<TimeRangeInput>;
};


export type QueryList_CustomersArgs = {
  archived: ArchivedFilter;
  cursor?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryList_Delivery_MethodsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  page_size?: InputMaybe<Scalars['Int']>;
};


export type QueryList_Delivery_Methods_Without_Configuration_For_All_ClientsArgs = {
  billing_provider: BillingProviderEnum_Enum;
  cursor?: InputMaybe<Scalars['String']>;
  delivery_method: BillingProviderDeliveryMethod_Enum;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryList_Failed_Marketplace_Metering_RecordsArgs = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  cursor?: InputMaybe<Scalars['String']>;
  customer_ids?: InputMaybe<Array<Scalars['uuid']>>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  page_size?: InputMaybe<Scalars['Int']>;
  starting_on?: InputMaybe<Scalars['timestamptz']>;
};


export type QueryMri_InvoiceArgs = {
  generation_overrides?: InputMaybe<InvoiceGenerationOverrides>;
  id: Scalars['uuid'];
};


export type QueryPending_Prepaid_Credit_GrantsArgs = {
  created_before?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  pending_processing?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPending_RechargesArgs = {
  created_before?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryPlansArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  limit?: InputMaybe<Scalars['Int']>;
  plan_type: PlanTypeEnum;
};


export type QueryProductsArgs = {
  charge_types?: InputMaybe<Array<InputMaybe<ChargeTypeEnum_Enum>>>;
  cursor?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ProductsSortingInput>;
  types: Array<ProductTypeEnum>;
};


export type QueryScheduled_Webhook_NotificationsArgs = {
  cursor?: InputMaybe<Scalars['uuid']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  limit?: InputMaybe<Scalars['Int']>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<ScheduledWebhookNotificationTypeEnum>;
};


export type QuerySearchCustomersArgs = {
  archived?: InputMaybe<ArchivedFilter>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  query: Scalars['String'];
};


export type QuerySeat_MetricArgs = {
  seat_metric_id: Scalars['uuid'];
};


export type QuerySeat_MetricsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['uuid']>>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SeatMetricSortingInput>;
  types: Array<BillableMetricTypeEnum>;
};


export type QuerySimulate_Fancy_Metric_UsageArgs = {
  input: SimulateFancyMetricUsageInput;
};


export type QueryStripeCustomerArgs = {
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id: Scalars['String'];
};


export type QueryUnpaid_InvoicesArgs = {
  billing_provider: Scalars['String'];
  credit_purchase_grace_period?: InputMaybe<Scalars['Boolean']>;
  earliest_invoice_end_date: Scalars['timestamptz'];
  earliest_zero_dollar_invoice_finalized_date?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  exclude_invoices_for_archived_customers?: InputMaybe<Scalars['Boolean']>;
  exclude_zero_dollar_invoices?: InputMaybe<Scalars['Boolean']>;
  last_invoice_id?: InputMaybe<Scalars['uuid']>;
  latest_invoice_end_date?: InputMaybe<Scalars['timestamptz']>;
  limit: Scalars['Int'];
  use_billing_provider_on_contract?: InputMaybe<Scalars['Boolean']>;
  use_billing_provider_on_credit_grant?: InputMaybe<Scalars['Boolean']>;
  use_billing_provider_on_plan?: InputMaybe<Scalars['Boolean']>;
};


export type QueryValidate_Billable_MetricArgs = {
  metric: BillableMetricInput;
};


export type QueryValidate_Contract_InputArgs = {
  billing_provider_configuration?: InputMaybe<BillingProviderConfigurationInput>;
  commits?: InputMaybe<Array<CommitInput>>;
  custom_fields?: InputMaybe<Array<ManagedFieldInput>>;
  discounts?: InputMaybe<Array<DiscountInput>>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  multiplier_override_prioritization?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  net_payment_terms_days?: InputMaybe<Scalars['Int']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  overrides?: InputMaybe<Array<RateCardEntryOverrideInput>>;
  pro_services?: InputMaybe<Array<ProServiceInput>>;
  rate_card_alias?: InputMaybe<Scalars['String']>;
  rate_card_id?: InputMaybe<Scalars['uuid']>;
  reseller_royalties?: InputMaybe<Array<ResellerRoyaltyInput>>;
  salesforce_opportunity_id?: InputMaybe<Scalars['String']>;
  scheduled_charges?: InputMaybe<Array<ScheduledChargeInput>>;
  starting_at: Scalars['timestamptz'];
  total_contract_value?: InputMaybe<Scalars['numeric']>;
  transition?: InputMaybe<TransitionInput>;
  uniqueness_key?: InputMaybe<Scalars['String']>;
  usage_filter?: InputMaybe<CreateContractUsageFilterInput>;
  usage_invoice_schedule?: InputMaybe<CreateContractUsageInvoiceScheduleInput>;
};


export type QueryValidate_Fancy_MetricArgs = {
  sql: Scalars['String'];
};

export type Rate = CustomRate | FlatRate | PercentageRate | SubscriptionRate | TieredRate;

export type RateCard = {
  __typename?: 'RateCard';
  Creator: Actor;
  Updater: Actor;
  aliases: Array<RateCardAliasScheduleSegment>;
  archived: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  credit_type_conversions: Maybe<Array<RateCardCreditTypeConversion>>;
  description: Maybe<Scalars['String']>;
  fiat_credit_type: CreditType;
  id: Scalars['uuid'];
  managed_fields: Array<RateCardManagedField>;
  name: Scalars['String'];
  named_schedule: Array<NamedScheduleSegment>;
  named_schedules: Array<NamedSchedule>;
  products: Array<ProductListItem>;
  rate_schedule: RateSchedule;
  rates_per_product: Array<RatesPerProduct>;
  updated_at: Scalars['timestamptz'];
};


export type RateCardNamed_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};


export type RateCardRate_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  entitled_filter?: InputMaybe<RateScheduleEntitledFilter>;
  limit: Scalars['numeric'];
  selectors?: InputMaybe<Array<RateScheduleSelector>>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  truncate?: InputMaybe<Scalars['Boolean']>;
  try_product_order_sort?: InputMaybe<Scalars['Boolean']>;
};

export type RateCardAliasData = {
  __typename?: 'RateCardAliasData';
  alias: Scalars['String'];
};

export type RateCardAliasInput = {
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  starting_at?: InputMaybe<Scalars['timestamptz']>;
};

export type RateCardAliasScheduleSegment = {
  __typename?: 'RateCardAliasScheduleSegment';
  data: RateCardAliasData;
  ending_before: Maybe<Scalars['timestamptz']>;
  starting_at: Maybe<Scalars['timestamptz']>;
};

export enum RateCardArchivedFilter {
  All = 'ALL',
  Archived = 'ARCHIVED',
  NotArchived = 'NOT_ARCHIVED'
}

export type RateCardCreditTypeConversion = {
  __typename?: 'RateCardCreditTypeConversion';
  custom_credit_type: CreditType;
  fiat_per_custom_credit: Scalars['numeric'];
};

export type RateCardCreditTypeConversionInput = {
  custom_credit_type_id: Scalars['String'];
  fiat_per_custom_credit: Scalars['numeric'];
};

export type RateCardEntry = {
  __typename?: 'RateCardEntry';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Scalars['timestamptz'];
  ending_before: Maybe<Scalars['timestamptz']>;
  entitled: Scalars['Boolean'];
  id: Scalars['uuid'];
  pricing_group_values: Maybe<Array<RateCardEntryPricingGroupValueEntry>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product: UsageProductListItem;
  product_list_item: RateCardProductListItem;
  rate: Rate;
  rate_card: RateCard;
};

export type RateCardEntryInput = {
  effective_at: Scalars['timestamptz'];
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  entitled: Scalars['Boolean'];
  pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  pricing_groups?: InputMaybe<Scalars['RecordOfStrings']>;
  product_list_item_id: Scalars['uuid'];
  rate: RateInput;
};

export type RateCardEntryOverride = {
  __typename?: 'RateCardEntryOverride';
  ending_before: Maybe<Scalars['timestamptz']>;
  entitled: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  override_specifiers: Array<OverrideSpecifier>;
  /** @deprecated No longer supported */
  product: Maybe<ProductListItem>;
  rate: Maybe<OverrideRate>;
  starting_at: Scalars['timestamptz'];
  /** @deprecated No longer supported */
  tags: Maybe<Array<Scalars['String']>>;
};

export type RateCardEntryOverrideInput = {
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  entitled?: InputMaybe<Scalars['Boolean']>;
  override_specifiers?: InputMaybe<Array<OverrideSpecifierInput>>;
  rate?: InputMaybe<OverrideRateInput>;
  starting_at: Scalars['timestamptz'];
};

export type RateCardEntryPricingGroupValueEntry = {
  __typename?: 'RateCardEntryPricingGroupValueEntry';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type RateCardEntryPricingGroupValueInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "RateCardManagedField" */
export type RateCardManagedField = {
  __typename?: 'RateCardManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  rate_card_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "RateCardManagedField" */
export type RateCardManagedField_Aggregate = {
  __typename?: 'RateCardManagedField_aggregate';
  aggregate: Maybe<RateCardManagedField_Aggregate_Fields>;
  nodes: Array<RateCardManagedField>;
};

export type RateCardManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<RateCardManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<RateCardManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<RateCardManagedField_Aggregate_Bool_Exp_Count>;
};

export type RateCardManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RateCardManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type RateCardManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RateCardManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type RateCardManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RateCardManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "RateCardManagedField" */
export type RateCardManagedField_Aggregate_Fields = {
  __typename?: 'RateCardManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<RateCardManagedField_Max_Fields>;
  min: Maybe<RateCardManagedField_Min_Fields>;
};


/** aggregate fields of "RateCardManagedField" */
export type RateCardManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RateCardManagedField" */
export type RateCardManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RateCardManagedField_Max_Order_By>;
  min?: InputMaybe<RateCardManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "RateCardManagedField" */
export type RateCardManagedField_Arr_Rel_Insert_Input = {
  data: Array<RateCardManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<RateCardManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "RateCardManagedField". All fields are combined with a logical 'AND'. */
export type RateCardManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<RateCardManagedField_Bool_Exp>>;
  _not?: InputMaybe<RateCardManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<RateCardManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  rate_card_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "RateCardManagedField" */
export enum RateCardManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  RateCardManagedFieldKeyIdValueEnforceUniquenessClientKey = 'RateCardManagedField_key_id_value_enforce_uniqueness_client_key',
  /** unique or primary key constraint on columns "id" */
  RateCardManagedFieldPkey = 'RateCardManagedField_pkey',
  /** unique or primary key constraint on columns "key_id", "rate_card_id" */
  RateCardManagedFieldRateCardIdKeyIdKey = 'RateCardManagedField_rate_card_id_key_id_key'
}

/** input type for inserting data into table "RateCardManagedField" */
export type RateCardManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  rate_card_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RateCardManagedField_Max_Fields = {
  __typename?: 'RateCardManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  rate_card_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "RateCardManagedField" */
export type RateCardManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  rate_card_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RateCardManagedField_Min_Fields = {
  __typename?: 'RateCardManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  rate_card_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "RateCardManagedField" */
export type RateCardManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  rate_card_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "RateCardManagedField" */
export type RateCardManagedField_Mutation_Response = {
  __typename?: 'RateCardManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RateCardManagedField>;
};

/** on_conflict condition type for table "RateCardManagedField" */
export type RateCardManagedField_On_Conflict = {
  constraint: RateCardManagedField_Constraint;
  update_columns?: Array<RateCardManagedField_Update_Column>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "RateCardManagedField". */
export type RateCardManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  rate_card_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RateCardManagedField */
export type RateCardManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "RateCardManagedField" */
export enum RateCardManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  RateCardId = 'rate_card_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "RateCardManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "RateCardManagedField" */
export enum RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "RateCardManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "RateCardManagedField" */
export enum RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "RateCardManagedField" */
export type RateCardManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  rate_card_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "RateCardManagedField" */
export type RateCardManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RateCardManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RateCardManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  rate_card_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "RateCardManagedField" */
export enum RateCardManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  RateCardId = 'rate_card_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type RateCardManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RateCardManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: RateCardManagedField_Bool_Exp;
};

export type RateCardMetadata = {
  __typename?: 'RateCardMetadata';
  Creator: Actor;
  Updater: Actor;
  aliases: Array<RateCardAliasScheduleSegment>;
  archived: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  credit_type_conversions: Maybe<Array<RateCardCreditTypeConversion>>;
  description: Maybe<Scalars['String']>;
  fiat_credit_type: CreditType;
  id: Scalars['uuid'];
  name: Scalars['String'];
  named_schedule: Array<NamedScheduleSegment>;
  named_schedules: Array<NamedSchedule>;
  product_count: Scalars['Int'];
  products: Array<ProductListItem>;
  rate_schedule: RateSchedule;
  updated_at: Scalars['timestamptz'];
};


export type RateCardMetadataNamed_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};


export type RateCardMetadataRate_ScheduleArgs = {
  at?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  entitled_filter?: InputMaybe<RateScheduleEntitledFilter>;
  limit: Scalars['numeric'];
  selectors?: InputMaybe<Array<RateScheduleSelector>>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  truncate?: InputMaybe<Scalars['Boolean']>;
  try_product_order_sort?: InputMaybe<Scalars['Boolean']>;
};

export type RateCardProductListItem = SubscriptionProductListItem | UsageProductListItem;

export type RateCardProductPositionInput = {
  position: Scalars['Int'];
  product_id: Scalars['uuid'];
};

export type RateInput = {
  custom_rate?: InputMaybe<CustomRateInput>;
  flat_rate?: InputMaybe<FlatRateInput>;
  percentage_rate?: InputMaybe<PercentageRateInput>;
  subscription_rate?: InputMaybe<SubscriptionRateInput>;
  tiered_rate?: InputMaybe<TieredRateInput>;
  type: Scalars['String'];
};

export type RateSchedule = {
  __typename?: 'RateSchedule';
  credit_types_on_segments: Array<CreditType>;
  next_page: Maybe<Scalars['String']>;
  products_on_segments: Array<ProductListItem>;
  scalar_segments: Array<Scalars['Scalar__RateScheduleSegment']>;
  segments: Array<RateScheduleSegment>;
  using_product_order: Scalars['Boolean'];
};

export enum RateScheduleEntitledFilter {
  All = 'ALL',
  Entitled = 'ENTITLED',
  NotEntitled = 'NOT_ENTITLED'
}

export type RateScheduleSegment = BaseRateScheduleSegment & {
  __typename?: 'RateScheduleSegment';
  ending_before: Maybe<Scalars['timestamptz']>;
  entitled: Scalars['Boolean'];
  id: Scalars['uuid'];
  pricing_group_values: Maybe<Array<RateCardEntryPricingGroupValueEntry>>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product: ProductListItem;
  rate: Rate;
  starting_at: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

export type RateScheduleSelector = {
  partial_pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  partial_pricing_groups?: InputMaybe<Scalars['RecordOfStrings']>;
  pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  pricing_groups?: InputMaybe<Scalars['RecordOfStrings']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  product_tags?: InputMaybe<Array<Scalars['String']>>;
};

export type RatesPerProduct = {
  __typename?: 'RatesPerProduct';
  product: ProductListItem;
  rates: Array<RateCardEntry>;
};

export type RebuildRateSegmentsSelector = {
  pricing_group_values?: InputMaybe<Array<RateCardEntryPricingGroupValueInput>>;
  pricing_groups?: InputMaybe<Scalars['RecordOfStrings']>;
  product_id: Scalars['uuid'];
};

export type RechargeSettings = {
  __typename?: 'RechargeSettings';
  auto_recharge_threshold: Scalars['numeric'];
  auto_recharge_to_amount: Scalars['numeric'];
  billing_provider: Maybe<BillingProviderEnum_Enum>;
  billing_provider_config: Maybe<RechargeSettingsBillingProviderConfig>;
  credit_grant_type: Maybe<Scalars['String']>;
  credit_type: CreditType;
  customer: Customer;
  enabled: Scalars['Boolean'];
  grant_duration: Scalars['numeric'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  priority: Scalars['numeric'];
  product_ids: Maybe<Array<Scalars['uuid']>>;
  reason: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  status_metadata: Maybe<Scalars['JSONObject']>;
};

export type RechargeSettingsBillingProviderConfig = {
  __typename?: 'RechargeSettingsBillingProviderConfig';
  stripe_config: Maybe<RechargeSettingsStripeConfig>;
};

export type RechargeSettingsBillingProviderConfigInput = {
  stripe_config?: InputMaybe<RechargeSettingsStripeConfigInput>;
};

export type RechargeSettingsInput = {
  auto_recharge_threshold: Scalars['numeric'];
  auto_recharge_to_amount: Scalars['numeric'];
  billing_provider?: InputMaybe<BillingProviderEnum_Enum>;
  billing_provider_config?: InputMaybe<RechargeSettingsBillingProviderConfigInput>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  credit_type_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  enabled: Scalars['Boolean'];
  grant_duration: Scalars['numeric'];
  name: Scalars['String'];
  priority: Scalars['numeric'];
  product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RechargeSettingsStripeConfig = {
  __typename?: 'RechargeSettingsStripeConfig';
  automatic_tax: Maybe<Scalars['Boolean']>;
  default_tax_rates: Maybe<Array<Scalars['String']>>;
  invoice_custom_fields: Maybe<Scalars['JSONObject']>;
  invoice_metadata: Maybe<Scalars['JSONObject']>;
  payment_method_id: Maybe<Scalars['String']>;
  product_id: Maybe<Scalars['String']>;
  tax_product_id: Maybe<Scalars['String']>;
};

export type RechargeSettingsStripeConfigInput = {
  automatic_tax?: InputMaybe<Scalars['Boolean']>;
  default_tax_rates?: InputMaybe<Array<Scalars['String']>>;
  invoice_custom_fields?: InputMaybe<Scalars['JSONObject']>;
  invoice_metadata?: InputMaybe<Scalars['JSONObject']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  tax_platform?: InputMaybe<TaxPlatform>;
  tax_product_id?: InputMaybe<Scalars['String']>;
};

export type RechargeSettingsUpdateAndChargeResponse = {
  __typename?: 'RechargeSettingsUpdateAndChargeResponse';
  recharge_response: Maybe<PrepaidCreditGrantRechargeResponse>;
  recharge_settings: RechargeSettings;
};

/** columns and relationships of "RecurringCreditGrant" */
export type RecurringCreditGrant = {
  __typename?: 'RecurringCreditGrant';
  /** An object relationship */
  AmountGrantedCreditType: CreditType;
  /** An object relationship */
  AmountPaidCreditType: CreditType;
  /** An object relationship */
  Plan: Plan;
  amount_granted: Scalars['numeric'];
  amount_granted_credit_type_id: Scalars['uuid'];
  amount_paid: Scalars['numeric'];
  amount_paid_credit_type_id: Scalars['uuid'];
  credit_grant_type: Maybe<Scalars['String']>;
  effective_duration: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  plan_id: Scalars['uuid'];
  priority: Scalars['numeric'];
  product_ids: Maybe<Array<Scalars['String']>>;
  reason: Maybe<Scalars['String']>;
  recurrence_duration: Maybe<Scalars['Int']>;
  recurrence_interval: Maybe<Scalars['Int']>;
  send_invoice: Scalars['Boolean'];
};

export type RecurringCreditGrantInput = {
  amount_granted: Scalars['numeric'];
  amount_granted_credit_type_id: Scalars['uuid'];
  amount_paid: Scalars['numeric'];
  amount_paid_credit_type_id: Scalars['uuid'];
  credit_grant_type?: InputMaybe<Scalars['String']>;
  effective_duration: Scalars['Int'];
  name: Scalars['String'];
  priority: Scalars['numeric'];
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurrence_duration?: InputMaybe<Scalars['Int']>;
  recurrence_interval?: InputMaybe<Scalars['Int']>;
  send_invoice: Scalars['Boolean'];
};

/** aggregated selection of "RecurringCreditGrant" */
export type RecurringCreditGrant_Aggregate = {
  __typename?: 'RecurringCreditGrant_aggregate';
  aggregate: Maybe<RecurringCreditGrant_Aggregate_Fields>;
  nodes: Array<RecurringCreditGrant>;
};

export type RecurringCreditGrant_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<RecurringCreditGrant_Aggregate_Bool_Exp_Count>;
};

export type RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And = {
  arguments: RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or = {
  arguments: RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type RecurringCreditGrant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "RecurringCreditGrant" */
export type RecurringCreditGrant_Aggregate_Fields = {
  __typename?: 'RecurringCreditGrant_aggregate_fields';
  avg: Maybe<RecurringCreditGrant_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<RecurringCreditGrant_Max_Fields>;
  min: Maybe<RecurringCreditGrant_Min_Fields>;
  stddev: Maybe<RecurringCreditGrant_Stddev_Fields>;
  stddev_pop: Maybe<RecurringCreditGrant_Stddev_Pop_Fields>;
  stddev_samp: Maybe<RecurringCreditGrant_Stddev_Samp_Fields>;
  sum: Maybe<RecurringCreditGrant_Sum_Fields>;
  var_pop: Maybe<RecurringCreditGrant_Var_Pop_Fields>;
  var_samp: Maybe<RecurringCreditGrant_Var_Samp_Fields>;
  variance: Maybe<RecurringCreditGrant_Variance_Fields>;
};


/** aggregate fields of "RecurringCreditGrant" */
export type RecurringCreditGrant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Aggregate_Order_By = {
  avg?: InputMaybe<RecurringCreditGrant_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RecurringCreditGrant_Max_Order_By>;
  min?: InputMaybe<RecurringCreditGrant_Min_Order_By>;
  stddev?: InputMaybe<RecurringCreditGrant_Stddev_Order_By>;
  stddev_pop?: InputMaybe<RecurringCreditGrant_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<RecurringCreditGrant_Stddev_Samp_Order_By>;
  sum?: InputMaybe<RecurringCreditGrant_Sum_Order_By>;
  var_pop?: InputMaybe<RecurringCreditGrant_Var_Pop_Order_By>;
  var_samp?: InputMaybe<RecurringCreditGrant_Var_Samp_Order_By>;
  variance?: InputMaybe<RecurringCreditGrant_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "RecurringCreditGrant" */
export type RecurringCreditGrant_Arr_Rel_Insert_Input = {
  data: Array<RecurringCreditGrant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<RecurringCreditGrant_On_Conflict>;
};

/** aggregate avg on columns */
export type RecurringCreditGrant_Avg_Fields = {
  __typename?: 'RecurringCreditGrant_avg_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Avg_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "RecurringCreditGrant". All fields are combined with a logical 'AND'. */
export type RecurringCreditGrant_Bool_Exp = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Bool_Exp>;
  AmountPaidCreditType?: InputMaybe<CreditType_Bool_Exp>;
  Plan?: InputMaybe<Plan_Bool_Exp>;
  _and?: InputMaybe<Array<RecurringCreditGrant_Bool_Exp>>;
  _not?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
  _or?: InputMaybe<Array<RecurringCreditGrant_Bool_Exp>>;
  amount_granted?: InputMaybe<Numeric_Comparison_Exp>;
  amount_granted_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  amount_paid?: InputMaybe<Numeric_Comparison_Exp>;
  amount_paid_credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  credit_grant_type?: InputMaybe<String_Comparison_Exp>;
  effective_duration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Numeric_Comparison_Exp>;
  product_ids?: InputMaybe<String_Array_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  recurrence_duration?: InputMaybe<Int_Comparison_Exp>;
  recurrence_interval?: InputMaybe<Int_Comparison_Exp>;
  send_invoice?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "RecurringCreditGrant" */
export enum RecurringCreditGrant_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecurringCreditGrantPkey = 'RecurringCreditGrant_pkey'
}

/** input type for incrementing numeric columns in table "RecurringCreditGrant" */
export type RecurringCreditGrant_Inc_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  effective_duration?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['numeric']>;
  recurrence_duration?: InputMaybe<Scalars['Int']>;
  recurrence_interval?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "RecurringCreditGrant" */
export type RecurringCreditGrant_Insert_Input = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  AmountPaidCreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  Plan?: InputMaybe<Plan_Obj_Rel_Insert_Input>;
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  effective_duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurrence_duration?: InputMaybe<Scalars['Int']>;
  recurrence_interval?: InputMaybe<Scalars['Int']>;
  send_invoice?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type RecurringCreditGrant_Max_Fields = {
  __typename?: 'RecurringCreditGrant_max_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_granted_credit_type_id: Maybe<Scalars['uuid']>;
  amount_paid: Maybe<Scalars['numeric']>;
  amount_paid_credit_type_id: Maybe<Scalars['uuid']>;
  credit_grant_type: Maybe<Scalars['String']>;
  effective_duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  priority: Maybe<Scalars['numeric']>;
  product_ids: Maybe<Array<Scalars['String']>>;
  reason: Maybe<Scalars['String']>;
  recurrence_duration: Maybe<Scalars['Int']>;
  recurrence_interval: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Max_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RecurringCreditGrant_Min_Fields = {
  __typename?: 'RecurringCreditGrant_min_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_granted_credit_type_id: Maybe<Scalars['uuid']>;
  amount_paid: Maybe<Scalars['numeric']>;
  amount_paid_credit_type_id: Maybe<Scalars['uuid']>;
  credit_grant_type: Maybe<Scalars['String']>;
  effective_duration: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  plan_id: Maybe<Scalars['uuid']>;
  priority: Maybe<Scalars['numeric']>;
  product_ids: Maybe<Array<Scalars['String']>>;
  reason: Maybe<Scalars['String']>;
  recurrence_duration: Maybe<Scalars['Int']>;
  recurrence_interval: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Min_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "RecurringCreditGrant" */
export type RecurringCreditGrant_Mutation_Response = {
  __typename?: 'RecurringCreditGrant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RecurringCreditGrant>;
};

/** input type for inserting object relation for remote table "RecurringCreditGrant" */
export type RecurringCreditGrant_Obj_Rel_Insert_Input = {
  data: RecurringCreditGrant_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RecurringCreditGrant_On_Conflict>;
};

/** on_conflict condition type for table "RecurringCreditGrant" */
export type RecurringCreditGrant_On_Conflict = {
  constraint: RecurringCreditGrant_Constraint;
  update_columns?: Array<RecurringCreditGrant_Update_Column>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};

/** Ordering options when selecting data from "RecurringCreditGrant". */
export type RecurringCreditGrant_Order_By = {
  AmountGrantedCreditType?: InputMaybe<CreditType_Order_By>;
  AmountPaidCreditType?: InputMaybe<CreditType_Order_By>;
  Plan?: InputMaybe<Plan_Order_By>;
  amount_granted?: InputMaybe<Order_By>;
  amount_granted_credit_type_id?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_paid_credit_type_id?: InputMaybe<Order_By>;
  credit_grant_type?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  product_ids?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
  send_invoice?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RecurringCreditGrant */
export type RecurringCreditGrant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "RecurringCreditGrant" */
export enum RecurringCreditGrant_Select_Column {
  /** column name */
  AmountGranted = 'amount_granted',
  /** column name */
  AmountGrantedCreditTypeId = 'amount_granted_credit_type_id',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountPaidCreditTypeId = 'amount_paid_credit_type_id',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  EffectiveDuration = 'effective_duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductIds = 'product_ids',
  /** column name */
  Reason = 'reason',
  /** column name */
  RecurrenceDuration = 'recurrence_duration',
  /** column name */
  RecurrenceInterval = 'recurrence_interval',
  /** column name */
  SendInvoice = 'send_invoice'
}

/** select "RecurringCreditGrant_aggregate_bool_exp_bool_and_arguments_columns" columns of table "RecurringCreditGrant" */
export enum RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SendInvoice = 'send_invoice'
}

/** select "RecurringCreditGrant_aggregate_bool_exp_bool_or_arguments_columns" columns of table "RecurringCreditGrant" */
export enum RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SendInvoice = 'send_invoice'
}

/** input type for updating data in table "RecurringCreditGrant" */
export type RecurringCreditGrant_Set_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  effective_duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurrence_duration?: InputMaybe<Scalars['Int']>;
  recurrence_interval?: InputMaybe<Scalars['Int']>;
  send_invoice?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type RecurringCreditGrant_Stddev_Fields = {
  __typename?: 'RecurringCreditGrant_stddev_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Stddev_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RecurringCreditGrant_Stddev_Pop_Fields = {
  __typename?: 'RecurringCreditGrant_stddev_pop_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Stddev_Pop_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RecurringCreditGrant_Stddev_Samp_Fields = {
  __typename?: 'RecurringCreditGrant_stddev_samp_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Stddev_Samp_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "RecurringCreditGrant" */
export type RecurringCreditGrant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RecurringCreditGrant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RecurringCreditGrant_Stream_Cursor_Value_Input = {
  amount_granted?: InputMaybe<Scalars['numeric']>;
  amount_granted_credit_type_id?: InputMaybe<Scalars['uuid']>;
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_paid_credit_type_id?: InputMaybe<Scalars['uuid']>;
  credit_grant_type?: InputMaybe<Scalars['String']>;
  effective_duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['numeric']>;
  product_ids?: InputMaybe<Array<Scalars['String']>>;
  reason?: InputMaybe<Scalars['String']>;
  recurrence_duration?: InputMaybe<Scalars['Int']>;
  recurrence_interval?: InputMaybe<Scalars['Int']>;
  send_invoice?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type RecurringCreditGrant_Sum_Fields = {
  __typename?: 'RecurringCreditGrant_sum_fields';
  amount_granted: Maybe<Scalars['numeric']>;
  amount_paid: Maybe<Scalars['numeric']>;
  effective_duration: Maybe<Scalars['Int']>;
  priority: Maybe<Scalars['numeric']>;
  recurrence_duration: Maybe<Scalars['Int']>;
  recurrence_interval: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Sum_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** update columns of table "RecurringCreditGrant" */
export enum RecurringCreditGrant_Update_Column {
  /** column name */
  AmountGranted = 'amount_granted',
  /** column name */
  AmountGrantedCreditTypeId = 'amount_granted_credit_type_id',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountPaidCreditTypeId = 'amount_paid_credit_type_id',
  /** column name */
  CreditGrantType = 'credit_grant_type',
  /** column name */
  EffectiveDuration = 'effective_duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  ProductIds = 'product_ids',
  /** column name */
  Reason = 'reason',
  /** column name */
  RecurrenceDuration = 'recurrence_duration',
  /** column name */
  RecurrenceInterval = 'recurrence_interval',
  /** column name */
  SendInvoice = 'send_invoice'
}

export type RecurringCreditGrant_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RecurringCreditGrant_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RecurringCreditGrant_Set_Input>;
  /** filter the rows which have to be updated */
  where: RecurringCreditGrant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RecurringCreditGrant_Var_Pop_Fields = {
  __typename?: 'RecurringCreditGrant_var_pop_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Var_Pop_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RecurringCreditGrant_Var_Samp_Fields = {
  __typename?: 'RecurringCreditGrant_var_samp_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Var_Samp_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type RecurringCreditGrant_Variance_Fields = {
  __typename?: 'RecurringCreditGrant_variance_fields';
  amount_granted: Maybe<Scalars['Float']>;
  amount_paid: Maybe<Scalars['Float']>;
  effective_duration: Maybe<Scalars['Float']>;
  priority: Maybe<Scalars['Float']>;
  recurrence_duration: Maybe<Scalars['Float']>;
  recurrence_interval: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "RecurringCreditGrant" */
export type RecurringCreditGrant_Variance_Order_By = {
  amount_granted?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  effective_duration?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  recurrence_duration?: InputMaybe<Order_By>;
  recurrence_interval?: InputMaybe<Order_By>;
};

export type RecurringSchedule = {
  __typename?: 'RecurringSchedule';
  amount: Scalars['CreditAmount'];
  amount_distribution: AmountDistributionType;
  end_date: Scalars['timestamptz'];
  frequency: RecurringScheduleFrequency;
  quantity: Maybe<Scalars['CreditAmount']>;
  start_date: Scalars['timestamptz'];
  unit_price: Maybe<Scalars['CreditAmount']>;
};

export enum RecurringScheduleFrequency {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL'
}

export type RecurringScheduleInput = {
  amount: Scalars['CreditAmount'];
  amount_distribution: AmountDistributionType;
  end_date: Scalars['timestamptz'];
  frequency: RecurringScheduleFrequency;
  quantity?: InputMaybe<Scalars['CreditAmount']>;
  start_date: Scalars['timestamptz'];
  unit_price?: InputMaybe<Scalars['CreditAmount']>;
};

export enum RepairPrepaidCreditGrantAction {
  Deleted = 'DELETED',
  Fixed = 'FIXED',
  None = 'NONE',
  Voided = 'VOIDED'
}

export type RepairPrepaidCreditGrantResponse = {
  __typename?: 'RepairPrepaidCreditGrantResponse';
  action_taken: RepairPrepaidCreditGrantAction;
  credit_grant: Maybe<CreditGrant>;
  result: Maybe<PrepaidCreditGrantStatusEnum>;
};

export type RequiredTimeRangeInput = {
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
};

export type ResellerRoyalty = AwsProServiceRoyalty | AwsRoyalty | GcpProServiceRoyalty | GcpRoyalty;

/**
 * Since we can't use unions in input types, we have a looser type definition here.
 * The real input should be
 *   for AWS:   optional (aws_account_number, aws_payer_reference_id, aws_offer_id)
 *   for GCP:   optional (gcp_account_id, gcp_offer_id)
 */
export type ResellerRoyaltyInput = {
  applicable_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  applicable_product_tags?: InputMaybe<Array<Scalars['String']>>;
  aws_account_number?: InputMaybe<Scalars['String']>;
  aws_offer_id?: InputMaybe<Scalars['String']>;
  aws_payer_reference_id?: InputMaybe<Scalars['String']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  fraction: Scalars['numeric'];
  gcp_account_id?: InputMaybe<Scalars['String']>;
  gcp_offer_id?: InputMaybe<Scalars['String']>;
  netsuite_reseller_id: Scalars['String'];
  reseller_contract_value?: InputMaybe<Scalars['numeric']>;
  starting_at: Scalars['timestamptz'];
  type: ResellerType;
};

export type ResellerRoyaltyOrUpdate = AwsProServiceRoyalty | AwsProServiceRoyaltyUpdate | AwsRoyalty | AwsRoyaltyUpdate | GcpProServiceRoyalty | GcpProServiceRoyaltyUpdate | GcpRoyalty | GcpRoyaltyUpdate;

/**
 * Since we can't use unions in input types, we have a looser type definition here.
 * The real input should be ResellerRoyalty | ResellerRoyaltyUpdate.
 */
export type ResellerRoyaltyOrUpdateInput = {
  applicable_product_ids?: InputMaybe<Array<Scalars['uuid']>>;
  applicable_product_tags?: InputMaybe<Array<Scalars['String']>>;
  aws_account_number?: InputMaybe<Scalars['String']>;
  aws_offer_id?: InputMaybe<Scalars['String']>;
  aws_payer_reference_id?: InputMaybe<Scalars['String']>;
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  fraction?: InputMaybe<Scalars['numeric']>;
  gcp_account_id?: InputMaybe<Scalars['String']>;
  gcp_offer_id?: InputMaybe<Scalars['String']>;
  netsuite_reseller_id?: InputMaybe<Scalars['String']>;
  reseller_contract_value?: InputMaybe<Scalars['numeric']>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  type: ResellerType;
};

export type ResellerRoyaltyUpdate = AwsProServiceRoyaltyUpdate | AwsRoyaltyUpdate | GcpProServiceRoyaltyUpdate | GcpRoyaltyUpdate;

export enum ResellerType {
  Aws = 'AWS',
  AwsProService = 'AWS_PRO_SERVICE',
  Gcp = 'GCP',
  GcpProService = 'GCP_PRO_SERVICE'
}

export type RevenueBreakdown = {
  __typename?: 'RevenueBreakdown';
  average_revenue: Scalars['numeric'];
  date: Scalars['String'];
  total_revenue: Scalars['numeric'];
};

export type RevenueMetric = {
  __typename?: 'RevenueMetric';
  customer_count: Scalars['numeric'];
  customer_with_spend_count: Scalars['numeric'];
  invoice_count: Scalars['numeric'];
  range: RevenueRange;
  total_usd: Scalars['numeric'];
};

export enum RevenueRange {
  All = 'ALL',
  NinetyDay = 'NINETY_DAY',
  SevenDay = 'SEVEN_DAY',
  ThirtyDay = 'THIRTY_DAY',
  Unknown = 'UNKNOWN',
  Ytd = 'YTD'
}

/** columns and relationships of "Role" */
export type Role = {
  __typename?: 'Role';
  /** An object relationship */
  Client: Maybe<Client>;
  /** An array relationship */
  Tokens: Array<Tokens>;
  /** An aggregate relationship */
  Tokens_aggregate: Tokens_Aggregate;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  key: Scalars['String'];
  name: Scalars['String'];
  permissions: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Role" */
export type RoleTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


/** columns and relationships of "Role" */
export type RoleTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


/** columns and relationships of "Role" */
export type RolePermissionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Role" */
export type Role_Aggregate = {
  __typename?: 'Role_aggregate';
  aggregate: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "Role" */
export type Role_Aggregate_Fields = {
  __typename?: 'Role_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Role_Max_Fields>;
  min: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "Role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Role_Append_Input = {
  permissions?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Tokens?: InputMaybe<Tokens_Bool_Exp>;
  Tokens_aggregate?: InputMaybe<Tokens_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Role_Bool_Exp>>;
  _not?: InputMaybe<Role_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  permissions?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "key", "client_id" */
  RoleClientIdKeyIdx = 'Role_client_id_key_idx',
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'Role_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Role_Delete_At_Path_Input = {
  permissions?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Role_Delete_Elem_Input = {
  permissions?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Role_Delete_Key_Input = {
  permissions?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "Role" */
export type Role_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Tokens?: InputMaybe<Tokens_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'Role_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'Role_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Role" */
export type Role_Mutation_Response = {
  __typename?: 'Role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "Role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "Role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: InputMaybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "Role". */
export type Role_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Tokens_aggregate?: InputMaybe<Tokens_Aggregate_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  permissions?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Role_Prepend_Input = {
  permissions?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Role" */
export enum Role_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Role" */
export type Role_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Role" */
export enum Role_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Role_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Role_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Role_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Role_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Role_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Role_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Bool_Exp;
};

export type RolloverAmount = RolloverMaxAmount | RolloverMaxPercentage;

export type RolloverAmountInput = {
  max_amount?: InputMaybe<MaxAmountRolloverAmountInput>;
  max_percentage?: InputMaybe<MaxPercentageRolloverAmountInput>;
};

export type RolloverMaxAmount = {
  __typename?: 'RolloverMaxAmount';
  value: Scalars['numeric'];
};

export type RolloverMaxPercentage = {
  __typename?: 'RolloverMaxPercentage';
  value: Scalars['numeric'];
};

export type RolloverSettings = {
  __typename?: 'RolloverSettings';
  expiry_date: Scalars['timestamptz'];
  priority: Scalars['numeric'];
  rollover_amount: RolloverAmount;
};

export type RolloverSettingsInput = {
  expiry_date: Scalars['timestamptz'];
  priority: Scalars['numeric'];
  rollover_amount: RolloverAmountInput;
};

export type RootLineItem = ContractAwsProServiceRoyaltyLineItem | ContractAwsRoyaltyLineItem | ContractAppliedCommitLineItem | ContractChargeLineItem | ContractCommitLineItem | ContractConversionLineItem | ContractDiscountLineItem | ContractGcpProServiceRoyaltyLineItem | ContractGcpRoyaltyLineItem | ContractPostpaidTrueupLineItem | ContractProServiceLineItem | ContractRolloverCommitLineItem | ContractSubscriptionLineItem | ContractUsageLineItem | CreditLineItem | CreditPurchaseLineItem | GroupedProductChargeLineItem | LegacyContractLineItem | LegacyLineItem | MinimumLineItem | OverageLineItem | ProductChargeLineItem | TrialDiscountLineItem;

/** columns and relationships of "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum = {
  __typename?: 'RoundingBehaviorEnum';
  value: Scalars['String'];
};

/** aggregated selection of "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Aggregate = {
  __typename?: 'RoundingBehaviorEnum_aggregate';
  aggregate: Maybe<RoundingBehaviorEnum_Aggregate_Fields>;
  nodes: Array<RoundingBehaviorEnum>;
};

/** aggregate fields of "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Aggregate_Fields = {
  __typename?: 'RoundingBehaviorEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<RoundingBehaviorEnum_Max_Fields>;
  min: Maybe<RoundingBehaviorEnum_Min_Fields>;
};


/** aggregate fields of "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RoundingBehaviorEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "RoundingBehaviorEnum". All fields are combined with a logical 'AND'. */
export type RoundingBehaviorEnum_Bool_Exp = {
  _and?: InputMaybe<Array<RoundingBehaviorEnum_Bool_Exp>>;
  _not?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
  _or?: InputMaybe<Array<RoundingBehaviorEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "RoundingBehaviorEnum" */
export enum RoundingBehaviorEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  RoundingBehaviorEnumPkey = 'RoundingBehaviorEnum_pkey'
}

export enum RoundingBehaviorEnum_Enum {
  Ceiling = 'CEILING',
  Floor = 'FLOOR'
}

/** Boolean expression to compare columns of type "RoundingBehaviorEnum_enum". All fields are combined with logical 'AND'. */
export type RoundingBehaviorEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<RoundingBehaviorEnum_Enum>;
  _in?: InputMaybe<Array<RoundingBehaviorEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RoundingBehaviorEnum_Enum>;
  _nin?: InputMaybe<Array<RoundingBehaviorEnum_Enum>>;
};

/** input type for inserting data into table "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RoundingBehaviorEnum_Max_Fields = {
  __typename?: 'RoundingBehaviorEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RoundingBehaviorEnum_Min_Fields = {
  __typename?: 'RoundingBehaviorEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Mutation_Response = {
  __typename?: 'RoundingBehaviorEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoundingBehaviorEnum>;
};

/** on_conflict condition type for table "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_On_Conflict = {
  constraint: RoundingBehaviorEnum_Constraint;
  update_columns?: Array<RoundingBehaviorEnum_Update_Column>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "RoundingBehaviorEnum". */
export type RoundingBehaviorEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: RoundingBehaviorEnum */
export type RoundingBehaviorEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "RoundingBehaviorEnum" */
export enum RoundingBehaviorEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "RoundingBehaviorEnum" */
export type RoundingBehaviorEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RoundingBehaviorEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RoundingBehaviorEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "RoundingBehaviorEnum" */
export enum RoundingBehaviorEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type RoundingBehaviorEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoundingBehaviorEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: RoundingBehaviorEnum_Bool_Exp;
};

export enum RoundingMethod {
  Ceiling = 'Ceiling',
  Floor = 'Floor',
  HalfUp = 'HalfUp'
}

export type SaveVendorEntityMappingInput = {
  entity: Scalars['String'];
  key_id: Scalars['uuid'];
  vendor: Scalars['String'];
  vendor_entity: Scalars['String'];
  vendor_field: Scalars['String'];
};

export type SaveVendorEntityMappingsResponse = {
  __typename?: 'SaveVendorEntityMappingsResponse';
  affected_rows: Scalars['Int'];
  returning: Array<ManagedFieldVendorMapping>;
};

export type ScalarContractRateScheduleSegment = BaseRateScheduleSegment & {
  __typename?: 'ScalarContractRateScheduleSegment';
  ending_before: Maybe<Scalars['timestamptz']>;
  list_rate: ScalarRate;
  override_rate: Maybe<ScalarRate>;
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product_id: Scalars['uuid'];
  starting_at: Maybe<Scalars['timestamptz']>;
};

export type ScalarFlatRate = {
  __typename?: 'ScalarFlatRate';
  credit_type_id: Scalars['uuid'];
  unit_price: Scalars['numeric'];
};

export type ScalarRate = CustomRate | PercentageRate | ScalarFlatRate | SubscriptionRate | TieredRate;

export type ScalarRateScheduleSegment = BaseRateScheduleSegment & {
  __typename?: 'ScalarRateScheduleSegment';
  ending_before: Maybe<Scalars['timestamptz']>;
  entitled: Scalars['Boolean'];
  id: Scalars['uuid'];
  pricing_groups: Maybe<Scalars['RecordOfStrings']>;
  product_id: Scalars['uuid'];
  rate: ScalarRate;
  starting_at: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

export type ScheduledCharge = {
  __typename?: 'ScheduledCharge';
  contract: Contract;
  description: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  managed_fields: Array<ScheduledChargeManagedField>;
  name: Maybe<Scalars['String']>;
  netsuite_sales_order_id: Maybe<Scalars['String']>;
  product: ProductListItem;
  schedule: ScheduledChargeSchedule;
};

export type ScheduledChargeInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  netsuite_sales_order_id?: InputMaybe<Scalars['String']>;
  product_id: Scalars['uuid'];
  schedule: ScheduledChargeScheduleInput;
};

/** columns and relationships of "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField = {
  __typename?: 'ScheduledChargeManagedField';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Creator: Maybe<Actor>;
  /** An object relationship */
  ManagedFieldKey: ManagedFieldKey;
  /** An object relationship */
  Updater: Maybe<Actor>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  enforce_uniqueness: Maybe<Scalars['Boolean']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  key_id: Scalars['uuid'];
  scheduled_charge_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  updated_by: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Aggregate = {
  __typename?: 'ScheduledChargeManagedField_aggregate';
  aggregate: Maybe<ScheduledChargeManagedField_Aggregate_Fields>;
  nodes: Array<ScheduledChargeManagedField>;
};

export type ScheduledChargeManagedField_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<ScheduledChargeManagedField_Aggregate_Bool_Exp_Count>;
};

export type ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And = {
  arguments: ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or = {
  arguments: ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type ScheduledChargeManagedField_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Aggregate_Fields = {
  __typename?: 'ScheduledChargeManagedField_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ScheduledChargeManagedField_Max_Fields>;
  min: Maybe<ScheduledChargeManagedField_Min_Fields>;
};


/** aggregate fields of "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ScheduledChargeManagedField_Max_Order_By>;
  min?: InputMaybe<ScheduledChargeManagedField_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Arr_Rel_Insert_Input = {
  data: Array<ScheduledChargeManagedField_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ScheduledChargeManagedField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ScheduledChargeManagedField". All fields are combined with a logical 'AND'. */
export type ScheduledChargeManagedField_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Creator?: InputMaybe<Actor_Bool_Exp>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Bool_Exp>;
  Updater?: InputMaybe<Actor_Bool_Exp>;
  _and?: InputMaybe<Array<ScheduledChargeManagedField_Bool_Exp>>;
  _not?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
  _or?: InputMaybe<Array<ScheduledChargeManagedField_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  enforce_uniqueness?: InputMaybe<Boolean_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key_id?: InputMaybe<Uuid_Comparison_Exp>;
  scheduled_charge_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ScheduledChargeManagedField" */
export enum ScheduledChargeManagedField_Constraint {
  /** unique or primary key constraint on columns "environment_type", "key_id", "value", "enforce_uniqueness", "client_id" */
  ScheduledChargeManagedFieldKeyIdValueEnforceUniquenessKey = 'ScheduledChargeManagedField_key_id_value_enforce_uniqueness_key',
  /** unique or primary key constraint on columns "id" */
  ScheduledChargeManagedFieldPkey = 'ScheduledChargeManagedField_pkey',
  /** unique or primary key constraint on columns "scheduled_charge_id", "key_id" */
  ScheduledChargeManagedFieldScheduledChargeIdKeyIdKey = 'ScheduledChargeManagedField_scheduled_charge_id_key_id_key'
}

/** input type for inserting data into table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Obj_Rel_Insert_Input>;
  Updater?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  scheduled_charge_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ScheduledChargeManagedField_Max_Fields = {
  __typename?: 'ScheduledChargeManagedField_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  scheduled_charge_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  scheduled_charge_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ScheduledChargeManagedField_Min_Fields = {
  __typename?: 'ScheduledChargeManagedField_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  key_id: Maybe<Scalars['uuid']>;
  scheduled_charge_id: Maybe<Scalars['uuid']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  scheduled_charge_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Mutation_Response = {
  __typename?: 'ScheduledChargeManagedField_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ScheduledChargeManagedField>;
};

/** on_conflict condition type for table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_On_Conflict = {
  constraint: ScheduledChargeManagedField_Constraint;
  update_columns?: Array<ScheduledChargeManagedField_Update_Column>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};

/** Ordering options when selecting data from "ScheduledChargeManagedField". */
export type ScheduledChargeManagedField_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Creator?: InputMaybe<Actor_Order_By>;
  ManagedFieldKey?: InputMaybe<ManagedFieldKey_Order_By>;
  Updater?: InputMaybe<Actor_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enforce_uniqueness?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key_id?: InputMaybe<Order_By>;
  scheduled_charge_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ScheduledChargeManagedField */
export type ScheduledChargeManagedField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ScheduledChargeManagedField" */
export enum ScheduledChargeManagedField_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ScheduledChargeId = 'scheduled_charge_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** select "ScheduledChargeManagedField_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ScheduledChargeManagedField" */
export enum ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** select "ScheduledChargeManagedField_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ScheduledChargeManagedField" */
export enum ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness'
}

/** input type for updating data in table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  scheduled_charge_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ScheduledChargeManagedField" */
export type ScheduledChargeManagedField_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ScheduledChargeManagedField_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ScheduledChargeManagedField_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  enforce_uniqueness?: InputMaybe<Scalars['Boolean']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  key_id?: InputMaybe<Scalars['uuid']>;
  scheduled_charge_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ScheduledChargeManagedField" */
export enum ScheduledChargeManagedField_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnforceUniqueness = 'enforce_uniqueness',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ScheduledChargeId = 'scheduled_charge_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

export type ScheduledChargeManagedField_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ScheduledChargeManagedField_Set_Input>;
  /** filter the rows which have to be updated */
  where: ScheduledChargeManagedField_Bool_Exp;
};

export type ScheduledChargeSchedule = {
  __typename?: 'ScheduledChargeSchedule';
  credit_type: CreditType;
  recurring_schedule: Maybe<RecurringSchedule>;
  schedule_items: Array<ScheduledChargeScheduleItem>;
};

export type ScheduledChargeScheduleInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  recurring_schedule?: InputMaybe<RecurringScheduleInput>;
  schedule_items?: InputMaybe<Array<ScheduledChargeScheduleItemInput>>;
};

export type ScheduledChargeScheduleItem = {
  __typename?: 'ScheduledChargeScheduleItem';
  amount: Scalars['CreditAmount'];
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  quantity: Scalars['CreditAmount'];
  unit_price: Scalars['CreditAmount'];
};

export type ScheduledChargeScheduleItemInput = {
  amount?: InputMaybe<Scalars['CreditAmount']>;
  date: Scalars['timestamptz'];
  quantity?: InputMaybe<Scalars['CreditAmount']>;
  unit_price?: InputMaybe<Scalars['CreditAmount']>;
};

export type ScheduledWebhookNotification = {
  __typename?: 'ScheduledWebhookNotification';
  archived_at: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  notification: Scalars['JSONObject'];
  notification_type: Scalars['String'];
  timestamp: Scalars['timestamptz'];
};

export type ScheduledWebhookNotificationList = {
  __typename?: 'ScheduledWebhookNotificationList';
  cursor: Maybe<Scalars['uuid']>;
  list: Array<ScheduledWebhookNotification>;
};

export enum ScheduledWebhookNotificationTypeEnum {
  ActiveOnly = 'ACTIVE_ONLY',
  All = 'ALL',
  ArchivedOnly = 'ARCHIVED_ONLY'
}

export type SeatChargeInput = {
  charge_id: Scalars['uuid'];
  current_seat_count?: InputMaybe<Scalars['numeric']>;
  quantity: Scalars['numeric'];
};

export type SeatChargeLineItem = Mri_LineItem & {
  __typename?: 'SeatChargeLineItem';
  applicable_end_date: Scalars['timestamptz'];
  applicable_start_date: Scalars['timestamptz'];
  credit_type: CreditType;
  current_seat_count: Maybe<Scalars['numeric']>;
  display_name: Scalars['String'];
  is_prorated: Scalars['Boolean'];
  line_item_chunks: Array<LineItemChunk>;
  pricing_factor: ProductPricingFactor;
  quantity: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  total: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type SeatCount = {
  __typename?: 'SeatCount';
  charge: ProductPricingFactor;
  seat_count: Scalars['numeric'];
};

export type SeatMetric = {
  __typename?: 'SeatMetric';
  Creator: Actor;
  active_plan_count: Scalars['Int'];
  active_product_count: Scalars['Int'];
  aggregate_key: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at: Maybe<Scalars['timestamptz']>;
  filter: Scalars['JSONObject'];
  id: Scalars['uuid'];
  is_draft: Scalars['Boolean'];
  name: Scalars['String'];
  products: Array<Product>;
  updated_at: Scalars['timestamptz'];
  usage: Mri_GroupedUsage;
};


export type SeatMetricUsageArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['uuid'];
  eventWindowSize: EventWindowSize;
  exclusive_end_date: Scalars['timestamptz'];
  groupings: Array<Mri_UsageGroupByInput>;
  inclusive_start_date: Scalars['timestamptz'];
  limit?: InputMaybe<Scalars['Int']>;
  usage_type: SeatMetricUsageEnum;
};

export type SeatMetricInput = {
  aggregate_key: Scalars['String'];
  filter: Scalars['JSONObject'];
  is_draft: Scalars['Boolean'];
  name: Scalars['String'];
};

export type SeatMetricSortingInput = {
  ascending: Scalars['Boolean'];
  order_by: SeatMetricSortingOrderByEnum;
};

export enum SeatMetricSortingOrderByEnum {
  CreatedAt = 'CREATED_AT',
  CreatedBy = 'CREATED_BY',
  Name = 'NAME'
}

export enum SeatMetricUsageEnum {
  Latest = 'LATEST',
  Max = 'MAX'
}

export type SeatPrice = {
  __typename?: 'SeatPrice';
  id: Scalars['uuid'];
  initial_quantity: Scalars['numeric'];
  is_prorated: Scalars['Boolean'];
  metric_minimum: Scalars['numeric'];
  value: Scalars['numeric'];
};

export type SeatPurchaseInvoice = Mri_Invoice & {
  __typename?: 'SeatPurchaseInvoice';
  billing_provider_invoice: Maybe<BillingProviderInvoice>;
  credit_type: CreditType;
  customer: Customer;
  external_type: InvoiceExternalTypeEnum;
  id: Scalars['uuid'];
  issued_at: Scalars['timestamptz'];
  line_items: Array<RootLineItem>;
  managed_fields: Array<InvoiceManagedField>;
  on_hold: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  total: Scalars['numeric'];
};


export type SeatPurchaseInvoiceLine_ItemsArgs = {
  subtotal_breakdown?: InputMaybe<SubtotalBreakdownInput>;
};

export type SequenceNumber = {
  __typename?: 'SequenceNumber';
  original_invoice: CorrectableInvoice;
  sequence_number: Scalars['Int'];
};

export type SequenceNumberInput = {
  original_invoice_id: Scalars['uuid'];
  sequence_number: Scalars['Int'];
};

export type ServicePeriod = {
  __typename?: 'ServicePeriod';
  accrued_costs: Array<CostWindow>;
  end_date: Scalars['timestamptz'];
  lock_date: Maybe<Scalars['timestamptz']>;
  start_date: Scalars['timestamptz'];
};


export type ServicePeriodAccrued_CostsArgs = {
  window_size?: InputMaybe<WindowSize>;
};

/** columns and relationships of "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum = {
  __typename?: 'ServicePeriodStartOnEnum';
  value: Scalars['String'];
};

/** aggregated selection of "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Aggregate = {
  __typename?: 'ServicePeriodStartOnEnum_aggregate';
  aggregate: Maybe<ServicePeriodStartOnEnum_Aggregate_Fields>;
  nodes: Array<ServicePeriodStartOnEnum>;
};

/** aggregate fields of "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Aggregate_Fields = {
  __typename?: 'ServicePeriodStartOnEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<ServicePeriodStartOnEnum_Max_Fields>;
  min: Maybe<ServicePeriodStartOnEnum_Min_Fields>;
};


/** aggregate fields of "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ServicePeriodStartOnEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ServicePeriodStartOnEnum". All fields are combined with a logical 'AND'. */
export type ServicePeriodStartOnEnum_Bool_Exp = {
  _and?: InputMaybe<Array<ServicePeriodStartOnEnum_Bool_Exp>>;
  _not?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
  _or?: InputMaybe<Array<ServicePeriodStartOnEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ServicePeriodStartOnEnum" */
export enum ServicePeriodStartOnEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  ServicePeriodStartOnEnumPkey = 'ServicePeriodStartOnEnum_pkey'
}

export enum ServicePeriodStartOnEnum_Enum {
  FirstOfMonth = 'FIRST_OF_MONTH',
  StartOfPlan = 'START_OF_PLAN'
}

/** Boolean expression to compare columns of type "ServicePeriodStartOnEnum_enum". All fields are combined with logical 'AND'. */
export type ServicePeriodStartOnEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<ServicePeriodStartOnEnum_Enum>;
  _in?: InputMaybe<Array<ServicePeriodStartOnEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ServicePeriodStartOnEnum_Enum>;
  _nin?: InputMaybe<Array<ServicePeriodStartOnEnum_Enum>>;
};

/** input type for inserting data into table "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ServicePeriodStartOnEnum_Max_Fields = {
  __typename?: 'ServicePeriodStartOnEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ServicePeriodStartOnEnum_Min_Fields = {
  __typename?: 'ServicePeriodStartOnEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Mutation_Response = {
  __typename?: 'ServicePeriodStartOnEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServicePeriodStartOnEnum>;
};

/** on_conflict condition type for table "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_On_Conflict = {
  constraint: ServicePeriodStartOnEnum_Constraint;
  update_columns?: Array<ServicePeriodStartOnEnum_Update_Column>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "ServicePeriodStartOnEnum". */
export type ServicePeriodStartOnEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ServicePeriodStartOnEnum */
export type ServicePeriodStartOnEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ServicePeriodStartOnEnum" */
export enum ServicePeriodStartOnEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ServicePeriodStartOnEnum" */
export type ServicePeriodStartOnEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ServicePeriodStartOnEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ServicePeriodStartOnEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ServicePeriodStartOnEnum" */
export enum ServicePeriodStartOnEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type ServicePeriodStartOnEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServicePeriodStartOnEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: ServicePeriodStartOnEnum_Bool_Exp;
};

export type SimulateFancyMetricUsageInput = {
  customer_id: Scalars['uuid'];
  sql: Scalars['String'];
  start_date: Scalars['timestamptz'];
};

export type SimulatedFancyMetricUsageData = {
  __typename?: 'SimulatedFancyMetricUsageData';
  groups: Maybe<Scalars['RecordOfNullableStrings']>;
  value: Scalars['numeric'];
};

export type SimulatedFancyMetricUsageResult = {
  __typename?: 'SimulatedFancyMetricUsageResult';
  usage: Array<SimulatedFancyMetricUsageData>;
  value_column_name: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type StripeCreditGrantResponse = {
  __typename?: 'StripeCreditGrantResponse';
  error_code: Maybe<Scalars['String']>;
  invoice_id: Maybe<Scalars['String']>;
  next_action_stripe_sdk: Maybe<Scalars['JSONObject']>;
  next_action_type: Maybe<StripeNextActionTypeEnum>;
  payment_intent_client_secret: Maybe<Scalars['String']>;
  redirect_url: Maybe<Scalars['String']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  name: Scalars['String'];
};

export enum StripeNextActionTypeEnum {
  RedirectToUrl = 'REDIRECT_TO_URL',
  UseStripeSdk = 'USE_STRIPE_SDK'
}

export type StripePaymentData = {
  automatic_tax: Scalars['Boolean'];
  customer_present: Scalars['Boolean'];
  default_tax_rates?: InputMaybe<Array<Scalars['String']>>;
  invoice_custom_fields?: InputMaybe<Scalars['JSONObject']>;
  invoice_metadata?: InputMaybe<Scalars['JSONObject']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  precalculated_tax?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['uuid']>;
  radar_session_id?: InputMaybe<Scalars['String']>;
  redirect_url?: InputMaybe<Scalars['String']>;
  skip_tax_on_tax_platform_error?: InputMaybe<Scalars['Boolean']>;
  tax_platform?: InputMaybe<TaxPlatform>;
  tax_product_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary = {
  __typename?: 'StripePrepaidConfig_temporary';
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  encrypted_anrok_api_token_string: Maybe<Scalars['String']>;
  encrypted_token_string: Scalars['String'];
  environment_type: EnvironmentTypeEnum_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by: Maybe<Scalars['String']>;
  webhook_secret: Maybe<Scalars['String']>;
};

/** aggregated selection of "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Aggregate = {
  __typename?: 'StripePrepaidConfig_temporary_aggregate';
  aggregate: Maybe<StripePrepaidConfig_Temporary_Aggregate_Fields>;
  nodes: Array<StripePrepaidConfig_Temporary>;
};

/** aggregate fields of "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Aggregate_Fields = {
  __typename?: 'StripePrepaidConfig_temporary_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<StripePrepaidConfig_Temporary_Max_Fields>;
  min: Maybe<StripePrepaidConfig_Temporary_Min_Fields>;
};


/** aggregate fields of "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StripePrepaidConfig_Temporary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "StripePrepaidConfig_temporary". All fields are combined with a logical 'AND'. */
export type StripePrepaidConfig_Temporary_Bool_Exp = {
  _and?: InputMaybe<Array<StripePrepaidConfig_Temporary_Bool_Exp>>;
  _not?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
  _or?: InputMaybe<Array<StripePrepaidConfig_Temporary_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  encrypted_anrok_api_token_string?: InputMaybe<String_Comparison_Exp>;
  encrypted_token_string?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  webhook_secret?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "StripePrepaidConfig_temporary" */
export enum StripePrepaidConfig_Temporary_Constraint {
  /** unique or primary key constraint on columns "environment_type", "client_id" */
  StripeprepaidconfigTemporaryPkey = 'stripeprepaidconfig_temporary_pkey'
}

/** input type for inserting data into table "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Insert_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  encrypted_anrok_api_token_string?: InputMaybe<Scalars['String']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhook_secret?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type StripePrepaidConfig_Temporary_Max_Fields = {
  __typename?: 'StripePrepaidConfig_temporary_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  encrypted_anrok_api_token_string: Maybe<Scalars['String']>;
  encrypted_token_string: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  webhook_secret: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type StripePrepaidConfig_Temporary_Min_Fields = {
  __typename?: 'StripePrepaidConfig_temporary_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  encrypted_anrok_api_token_string: Maybe<Scalars['String']>;
  encrypted_token_string: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  updated_by: Maybe<Scalars['String']>;
  webhook_secret: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Mutation_Response = {
  __typename?: 'StripePrepaidConfig_temporary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StripePrepaidConfig_Temporary>;
};

/** on_conflict condition type for table "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_On_Conflict = {
  constraint: StripePrepaidConfig_Temporary_Constraint;
  update_columns?: Array<StripePrepaidConfig_Temporary_Update_Column>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};

/** Ordering options when selecting data from "StripePrepaidConfig_temporary". */
export type StripePrepaidConfig_Temporary_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  encrypted_anrok_api_token_string?: InputMaybe<Order_By>;
  encrypted_token_string?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  webhook_secret?: InputMaybe<Order_By>;
};

/** primary key columns input for table: StripePrepaidConfig_temporary */
export type StripePrepaidConfig_Temporary_Pk_Columns_Input = {
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};

/** select columns of table "StripePrepaidConfig_temporary" */
export enum StripePrepaidConfig_Temporary_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EncryptedAnrokApiTokenString = 'encrypted_anrok_api_token_string',
  /** column name */
  EncryptedTokenString = 'encrypted_token_string',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WebhookSecret = 'webhook_secret'
}

/** input type for updating data in table "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  encrypted_anrok_api_token_string?: InputMaybe<Scalars['String']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhook_secret?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "StripePrepaidConfig_temporary" */
export type StripePrepaidConfig_Temporary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StripePrepaidConfig_Temporary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type StripePrepaidConfig_Temporary_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  encrypted_anrok_api_token_string?: InputMaybe<Scalars['String']>;
  encrypted_token_string?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
  webhook_secret?: InputMaybe<Scalars['String']>;
};

/** update columns of table "StripePrepaidConfig_temporary" */
export enum StripePrepaidConfig_Temporary_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EncryptedAnrokApiTokenString = 'encrypted_anrok_api_token_string',
  /** column name */
  EncryptedTokenString = 'encrypted_token_string',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  WebhookSecret = 'webhook_secret'
}

export type StripePrepaidConfig_Temporary_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StripePrepaidConfig_Temporary_Set_Input>;
  /** filter the rows which have to be updated */
  where: StripePrepaidConfig_Temporary_Bool_Exp;
};

export type SubLineItem = {
  __typename?: 'SubLineItem';
  id: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  one_time_charge: Maybe<OneTimeProductCharge>;
  pricing_scheme: Maybe<PricingSchemeIdentifier>;
  product_pricing_factor: Maybe<ProductPricingFactor>;
  quantity: Scalars['numeric'];
  subtotal: Scalars['CreditAmount'];
  tiers: Array<SubLineItemTier>;
};

export type SubLineItemTier = {
  __typename?: 'SubLineItemTier';
  description: Scalars['String'];
  id: Maybe<Scalars['uuid']>;
  metric_minimum: Scalars['numeric'];
  quantity: Scalars['numeric'];
  subtotal: Scalars['CreditAmount'];
  unit_price: Scalars['numeric'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** fetch data from the table: "AccountTypeEnum" */
  AccountTypeEnum: Array<AccountTypeEnum>;
  /** fetch aggregated fields from the table: "AccountTypeEnum" */
  AccountTypeEnum_aggregate: AccountTypeEnum_Aggregate;
  /** fetch data from the table: "AccountTypeEnum" using primary key columns */
  AccountTypeEnum_by_pk: Maybe<AccountTypeEnum>;
  /** fetch data from the table in a streaming manner: "AccountTypeEnum" */
  AccountTypeEnum_stream: Array<AccountTypeEnum>;
  /** fetch data from the table: "Actor" */
  Actor: Array<Actor>;
  /** fetch aggregated fields from the table: "Actor" */
  Actor_aggregate: Actor_Aggregate;
  /** fetch data from the table: "Actor" using primary key columns */
  Actor_by_pk: Maybe<Actor>;
  /** fetch data from the table in a streaming manner: "Actor" */
  Actor_stream: Array<Actor>;
  /** fetch data from the table: "Alert" */
  Alert: Array<Alert>;
  /** fetch data from the table: "AlertManagedField" */
  AlertManagedField: Array<AlertManagedField>;
  /** fetch aggregated fields from the table: "AlertManagedField" */
  AlertManagedField_aggregate: AlertManagedField_Aggregate;
  /** fetch data from the table: "AlertManagedField" using primary key columns */
  AlertManagedField_by_pk: Maybe<AlertManagedField>;
  /** fetch data from the table in a streaming manner: "AlertManagedField" */
  AlertManagedField_stream: Array<AlertManagedField>;
  /** fetch data from the table: "AlertTypeEnum" */
  AlertTypeEnum: Array<AlertTypeEnum>;
  /** fetch aggregated fields from the table: "AlertTypeEnum" */
  AlertTypeEnum_aggregate: AlertTypeEnum_Aggregate;
  /** fetch data from the table: "AlertTypeEnum" using primary key columns */
  AlertTypeEnum_by_pk: Maybe<AlertTypeEnum>;
  /** fetch data from the table in a streaming manner: "AlertTypeEnum" */
  AlertTypeEnum_stream: Array<AlertTypeEnum>;
  /** fetch aggregated fields from the table: "Alert" */
  Alert_aggregate: Alert_Aggregate;
  /** fetch data from the table: "Alert" using primary key columns */
  Alert_by_pk: Maybe<Alert>;
  /** fetch data from the table in a streaming manner: "Alert" */
  Alert_stream: Array<Alert>;
  /** fetch data from the table: "BillableMetric" */
  BillableMetric: Array<BillableMetric>;
  /** fetch data from the table: "BillableMetricManagedField" */
  BillableMetricManagedField: Array<BillableMetricManagedField>;
  /** fetch aggregated fields from the table: "BillableMetricManagedField" */
  BillableMetricManagedField_aggregate: BillableMetricManagedField_Aggregate;
  /** fetch data from the table: "BillableMetricManagedField" using primary key columns */
  BillableMetricManagedField_by_pk: Maybe<BillableMetricManagedField>;
  /** fetch data from the table in a streaming manner: "BillableMetricManagedField" */
  BillableMetricManagedField_stream: Array<BillableMetricManagedField>;
  /** fetch aggregated fields from the table: "BillableMetric" */
  BillableMetric_aggregate: BillableMetric_Aggregate;
  /** fetch data from the table: "BillableMetric" using primary key columns */
  BillableMetric_by_pk: Maybe<BillableMetric>;
  /** fetch data from the table in a streaming manner: "BillableMetric" */
  BillableMetric_stream: Array<BillableMetric>;
  /** fetch data from the table: "BillingFrequencyEnum" */
  BillingFrequencyEnum: Array<BillingFrequencyEnum>;
  /** fetch aggregated fields from the table: "BillingFrequencyEnum" */
  BillingFrequencyEnum_aggregate: BillingFrequencyEnum_Aggregate;
  /** fetch data from the table: "BillingFrequencyEnum" using primary key columns */
  BillingFrequencyEnum_by_pk: Maybe<BillingFrequencyEnum>;
  /** fetch data from the table in a streaming manner: "BillingFrequencyEnum" */
  BillingFrequencyEnum_stream: Array<BillingFrequencyEnum>;
  /** fetch data from the table: "BillingMetricAggregateEnum" */
  BillingMetricAggregateEnum: Array<BillingMetricAggregateEnum>;
  /** fetch aggregated fields from the table: "BillingMetricAggregateEnum" */
  BillingMetricAggregateEnum_aggregate: BillingMetricAggregateEnum_Aggregate;
  /** fetch data from the table: "BillingMetricAggregateEnum" using primary key columns */
  BillingMetricAggregateEnum_by_pk: Maybe<BillingMetricAggregateEnum>;
  /** fetch data from the table in a streaming manner: "BillingMetricAggregateEnum" */
  BillingMetricAggregateEnum_stream: Array<BillingMetricAggregateEnum>;
  /** fetch data from the table: "BillingProviderCustomer" */
  BillingProviderCustomer: Array<BillingProviderCustomer>;
  /** fetch aggregated fields from the table: "BillingProviderCustomer" */
  BillingProviderCustomer_aggregate: BillingProviderCustomer_Aggregate;
  /** fetch data from the table: "BillingProviderCustomer" using primary key columns */
  BillingProviderCustomer_by_pk: Maybe<BillingProviderCustomer>;
  /** fetch data from the table in a streaming manner: "BillingProviderCustomer" */
  BillingProviderCustomer_stream: Array<BillingProviderCustomer>;
  /** fetch data from the table: "BillingProviderEnum" */
  BillingProviderEnum: Array<BillingProviderEnum>;
  /** fetch aggregated fields from the table: "BillingProviderEnum" */
  BillingProviderEnum_aggregate: BillingProviderEnum_Aggregate;
  /** fetch data from the table: "BillingProviderEnum" using primary key columns */
  BillingProviderEnum_by_pk: Maybe<BillingProviderEnum>;
  /** fetch data from the table in a streaming manner: "BillingProviderEnum" */
  BillingProviderEnum_stream: Array<BillingProviderEnum>;
  /** fetch data from the table: "BillingProviderInvoice" */
  BillingProviderInvoice: Array<BillingProviderInvoice>;
  /** fetch aggregated fields from the table: "BillingProviderInvoice" */
  BillingProviderInvoice_aggregate: BillingProviderInvoice_Aggregate;
  /** fetch data from the table: "BillingProviderInvoice" using primary key columns */
  BillingProviderInvoice_by_pk: Maybe<BillingProviderInvoice>;
  /** fetch data from the table in a streaming manner: "BillingProviderInvoice" */
  BillingProviderInvoice_stream: Array<BillingProviderInvoice>;
  /** fetch data from the table: "BillingProviderToken" */
  BillingProviderToken: Array<BillingProviderToken>;
  /** fetch aggregated fields from the table: "BillingProviderToken" */
  BillingProviderToken_aggregate: BillingProviderToken_Aggregate;
  /** fetch data from the table: "BillingProviderToken" using primary key columns */
  BillingProviderToken_by_pk: Maybe<BillingProviderToken>;
  /** fetch data from the table in a streaming manner: "BillingProviderToken" */
  BillingProviderToken_stream: Array<BillingProviderToken>;
  /** fetch data from the table: "ChargeManagedField" */
  ChargeManagedField: Array<ChargeManagedField>;
  /** fetch aggregated fields from the table: "ChargeManagedField" */
  ChargeManagedField_aggregate: ChargeManagedField_Aggregate;
  /** fetch data from the table: "ChargeManagedField" using primary key columns */
  ChargeManagedField_by_pk: Maybe<ChargeManagedField>;
  /** fetch data from the table in a streaming manner: "ChargeManagedField" */
  ChargeManagedField_stream: Array<ChargeManagedField>;
  /** fetch data from the table: "ChargeTypeEnum" */
  ChargeTypeEnum: Array<ChargeTypeEnum>;
  /** fetch aggregated fields from the table: "ChargeTypeEnum" */
  ChargeTypeEnum_aggregate: ChargeTypeEnum_Aggregate;
  /** fetch data from the table: "ChargeTypeEnum" using primary key columns */
  ChargeTypeEnum_by_pk: Maybe<ChargeTypeEnum>;
  /** fetch data from the table in a streaming manner: "ChargeTypeEnum" */
  ChargeTypeEnum_stream: Array<ChargeTypeEnum>;
  /** fetch data from the table: "Client" */
  Client: Array<Client>;
  /** fetch data from the table: "ClientConfig" */
  ClientConfig: Array<ClientConfig>;
  /** fetch data from the table: "ClientConfigKeyEnum" */
  ClientConfigKeyEnum: Array<ClientConfigKeyEnum>;
  /** fetch aggregated fields from the table: "ClientConfigKeyEnum" */
  ClientConfigKeyEnum_aggregate: ClientConfigKeyEnum_Aggregate;
  /** fetch data from the table: "ClientConfigKeyEnum" using primary key columns */
  ClientConfigKeyEnum_by_pk: Maybe<ClientConfigKeyEnum>;
  /** fetch data from the table in a streaming manner: "ClientConfigKeyEnum" */
  ClientConfigKeyEnum_stream: Array<ClientConfigKeyEnum>;
  /** fetch aggregated fields from the table: "ClientConfig" */
  ClientConfig_aggregate: ClientConfig_Aggregate;
  /** fetch data from the table: "ClientConfig" using primary key columns */
  ClientConfig_by_pk: Maybe<ClientConfig>;
  /** fetch data from the table in a streaming manner: "ClientConfig" */
  ClientConfig_stream: Array<ClientConfig>;
  /** fetch aggregated fields from the table: "Client" */
  Client_aggregate: Client_Aggregate;
  /** fetch data from the table: "Client" using primary key columns */
  Client_by_pk: Maybe<Client>;
  /** fetch data from the table in a streaming manner: "Client" */
  Client_stream: Array<Client>;
  /** fetch data from the table: "CollectionScheduleEnum" */
  CollectionScheduleEnum: Array<CollectionScheduleEnum>;
  /** fetch aggregated fields from the table: "CollectionScheduleEnum" */
  CollectionScheduleEnum_aggregate: CollectionScheduleEnum_Aggregate;
  /** fetch data from the table: "CollectionScheduleEnum" using primary key columns */
  CollectionScheduleEnum_by_pk: Maybe<CollectionScheduleEnum>;
  /** fetch data from the table in a streaming manner: "CollectionScheduleEnum" */
  CollectionScheduleEnum_stream: Array<CollectionScheduleEnum>;
  /** fetch data from the table: "CommitManagedField" */
  CommitManagedField: Array<CommitManagedField>;
  /** fetch aggregated fields from the table: "CommitManagedField" */
  CommitManagedField_aggregate: CommitManagedField_Aggregate;
  /** fetch data from the table: "CommitManagedField" using primary key columns */
  CommitManagedField_by_pk: Maybe<CommitManagedField>;
  /** fetch data from the table in a streaming manner: "CommitManagedField" */
  CommitManagedField_stream: Array<CommitManagedField>;
  /** fetch data from the table: "CompositeCharge" */
  CompositeCharge: Array<CompositeCharge>;
  /** fetch data from the table: "CompositeChargeAdjustment" */
  CompositeChargeAdjustment: Array<CompositeChargeAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeAdjustment" */
  CompositeChargeAdjustment_aggregate: CompositeChargeAdjustment_Aggregate;
  /** fetch data from the table: "CompositeChargeAdjustment" using primary key columns */
  CompositeChargeAdjustment_by_pk: Maybe<CompositeChargeAdjustment>;
  /** fetch data from the table in a streaming manner: "CompositeChargeAdjustment" */
  CompositeChargeAdjustment_stream: Array<CompositeChargeAdjustment>;
  /** fetch data from the table: "CompositeChargePricingFactor" */
  CompositeChargePricingFactor: Array<CompositeChargePricingFactor>;
  /** fetch aggregated fields from the table: "CompositeChargePricingFactor" */
  CompositeChargePricingFactor_aggregate: CompositeChargePricingFactor_Aggregate;
  /** fetch data from the table in a streaming manner: "CompositeChargePricingFactor" */
  CompositeChargePricingFactor_stream: Array<CompositeChargePricingFactor>;
  /** fetch data from the table: "CompositeChargeTier" */
  CompositeChargeTier: Array<CompositeChargeTier>;
  /** fetch data from the table: "CompositeChargeTierAdjustment" */
  CompositeChargeTierAdjustment: Array<CompositeChargeTierAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeTierAdjustment" */
  CompositeChargeTierAdjustment_aggregate: CompositeChargeTierAdjustment_Aggregate;
  /** fetch data from the table: "CompositeChargeTierAdjustment" using primary key columns */
  CompositeChargeTierAdjustment_by_pk: Maybe<CompositeChargeTierAdjustment>;
  /** fetch data from the table in a streaming manner: "CompositeChargeTierAdjustment" */
  CompositeChargeTierAdjustment_stream: Array<CompositeChargeTierAdjustment>;
  /** fetch aggregated fields from the table: "CompositeChargeTier" */
  CompositeChargeTier_aggregate: CompositeChargeTier_Aggregate;
  /** fetch data from the table: "CompositeChargeTier" using primary key columns */
  CompositeChargeTier_by_pk: Maybe<CompositeChargeTier>;
  /** fetch data from the table in a streaming manner: "CompositeChargeTier" */
  CompositeChargeTier_stream: Array<CompositeChargeTier>;
  /** fetch data from the table: "CompositeChargeTypeEnum" */
  CompositeChargeTypeEnum: Array<CompositeChargeTypeEnum>;
  /** fetch aggregated fields from the table: "CompositeChargeTypeEnum" */
  CompositeChargeTypeEnum_aggregate: CompositeChargeTypeEnum_Aggregate;
  /** fetch data from the table: "CompositeChargeTypeEnum" using primary key columns */
  CompositeChargeTypeEnum_by_pk: Maybe<CompositeChargeTypeEnum>;
  /** fetch data from the table in a streaming manner: "CompositeChargeTypeEnum" */
  CompositeChargeTypeEnum_stream: Array<CompositeChargeTypeEnum>;
  /** fetch aggregated fields from the table: "CompositeCharge" */
  CompositeCharge_aggregate: CompositeCharge_Aggregate;
  /** fetch data from the table: "CompositeCharge" using primary key columns */
  CompositeCharge_by_pk: Maybe<CompositeCharge>;
  /** fetch data from the table in a streaming manner: "CompositeCharge" */
  CompositeCharge_stream: Array<CompositeCharge>;
  /** fetch data from the table: "ContractCreditManagedField" */
  ContractCreditManagedField: Array<ContractCreditManagedField>;
  /** fetch aggregated fields from the table: "ContractCreditManagedField" */
  ContractCreditManagedField_aggregate: ContractCreditManagedField_Aggregate;
  /** fetch data from the table: "ContractCreditManagedField" using primary key columns */
  ContractCreditManagedField_by_pk: Maybe<ContractCreditManagedField>;
  /** fetch data from the table in a streaming manner: "ContractCreditManagedField" */
  ContractCreditManagedField_stream: Array<ContractCreditManagedField>;
  /** fetch data from the table: "ContractManagedField" */
  ContractManagedField: Array<ContractManagedField>;
  /** fetch aggregated fields from the table: "ContractManagedField" */
  ContractManagedField_aggregate: ContractManagedField_Aggregate;
  /** fetch data from the table: "ContractManagedField" using primary key columns */
  ContractManagedField_by_pk: Maybe<ContractManagedField>;
  /** fetch data from the table in a streaming manner: "ContractManagedField" */
  ContractManagedField_stream: Array<ContractManagedField>;
  /** fetch data from the table: "ContractProductManagedField" */
  ContractProductManagedField: Array<ContractProductManagedField>;
  /** fetch aggregated fields from the table: "ContractProductManagedField" */
  ContractProductManagedField_aggregate: ContractProductManagedField_Aggregate;
  /** fetch data from the table: "ContractProductManagedField" using primary key columns */
  ContractProductManagedField_by_pk: Maybe<ContractProductManagedField>;
  /** fetch data from the table in a streaming manner: "ContractProductManagedField" */
  ContractProductManagedField_stream: Array<ContractProductManagedField>;
  /** fetch data from the table: "CreditAdjustment" */
  CreditAdjustment: Array<CreditAdjustment>;
  /** fetch aggregated fields from the table: "CreditAdjustment" */
  CreditAdjustment_aggregate: CreditAdjustment_Aggregate;
  /** fetch data from the table: "CreditAdjustment" using primary key columns */
  CreditAdjustment_by_pk: Maybe<CreditAdjustment>;
  /** fetch data from the table in a streaming manner: "CreditAdjustment" */
  CreditAdjustment_stream: Array<CreditAdjustment>;
  /** fetch data from the table: "CreditGrant" */
  CreditGrant: Array<CreditGrant>;
  /** fetch data from the table: "CreditGrantDeduction" */
  CreditGrantDeduction: Array<CreditGrantDeduction>;
  /** fetch aggregated fields from the table: "CreditGrantDeduction" */
  CreditGrantDeduction_aggregate: CreditGrantDeduction_Aggregate;
  /** fetch data from the table: "CreditGrantDeduction" using primary key columns */
  CreditGrantDeduction_by_pk: Maybe<CreditGrantDeduction>;
  /** fetch data from the table in a streaming manner: "CreditGrantDeduction" */
  CreditGrantDeduction_stream: Array<CreditGrantDeduction>;
  /** fetch data from the table: "CreditGrantHistory" */
  CreditGrantHistory: Array<CreditGrantHistory>;
  /** fetch aggregated fields from the table: "CreditGrantHistory" */
  CreditGrantHistory_aggregate: CreditGrantHistory_Aggregate;
  /** fetch data from the table: "CreditGrantHistory" using primary key columns */
  CreditGrantHistory_by_pk: Maybe<CreditGrantHistory>;
  /** fetch data from the table in a streaming manner: "CreditGrantHistory" */
  CreditGrantHistory_stream: Array<CreditGrantHistory>;
  /** fetch data from the table: "CreditGrantManagedField" */
  CreditGrantManagedField: Array<CreditGrantManagedField>;
  /** fetch aggregated fields from the table: "CreditGrantManagedField" */
  CreditGrantManagedField_aggregate: CreditGrantManagedField_Aggregate;
  /** fetch data from the table: "CreditGrantManagedField" using primary key columns */
  CreditGrantManagedField_by_pk: Maybe<CreditGrantManagedField>;
  /** fetch data from the table in a streaming manner: "CreditGrantManagedField" */
  CreditGrantManagedField_stream: Array<CreditGrantManagedField>;
  /** fetch aggregated fields from the table: "CreditGrant" */
  CreditGrant_aggregate: CreditGrant_Aggregate;
  /** fetch data from the table: "CreditGrant" using primary key columns */
  CreditGrant_by_pk: Maybe<CreditGrant>;
  /** fetch data from the table in a streaming manner: "CreditGrant" */
  CreditGrant_stream: Array<CreditGrant>;
  /** fetch data from the table: "CreditType" */
  CreditType: Array<CreditType>;
  /** fetch data from the table: "CreditTypeConversion" */
  CreditTypeConversion: Array<CreditTypeConversion>;
  /** fetch data from the table: "CreditTypeConversionAdjustment" */
  CreditTypeConversionAdjustment: Array<CreditTypeConversionAdjustment>;
  /** fetch aggregated fields from the table: "CreditTypeConversionAdjustment" */
  CreditTypeConversionAdjustment_aggregate: CreditTypeConversionAdjustment_Aggregate;
  /** fetch data from the table: "CreditTypeConversionAdjustment" using primary key columns */
  CreditTypeConversionAdjustment_by_pk: Maybe<CreditTypeConversionAdjustment>;
  /** fetch data from the table in a streaming manner: "CreditTypeConversionAdjustment" */
  CreditTypeConversionAdjustment_stream: Array<CreditTypeConversionAdjustment>;
  /** fetch aggregated fields from the table: "CreditTypeConversion" */
  CreditTypeConversion_aggregate: CreditTypeConversion_Aggregate;
  /** fetch data from the table: "CreditTypeConversion" using primary key columns */
  CreditTypeConversion_by_pk: Maybe<CreditTypeConversion>;
  /** fetch data from the table in a streaming manner: "CreditTypeConversion" */
  CreditTypeConversion_stream: Array<CreditTypeConversion>;
  /** fetch aggregated fields from the table: "CreditType" */
  CreditType_aggregate: CreditType_Aggregate;
  /** fetch data from the table: "CreditType" using primary key columns */
  CreditType_by_pk: Maybe<CreditType>;
  /** fetch data from the table in a streaming manner: "CreditType" */
  CreditType_stream: Array<CreditType>;
  /** fetch data from the table: "CustomPricing" */
  CustomPricing: Array<CustomPricing>;
  /** fetch aggregated fields from the table: "CustomPricing" */
  CustomPricing_aggregate: CustomPricing_Aggregate;
  /** fetch data from the table: "CustomPricing" using primary key columns */
  CustomPricing_by_pk: Maybe<CustomPricing>;
  /** fetch data from the table in a streaming manner: "CustomPricing" */
  CustomPricing_stream: Array<CustomPricing>;
  /** fetch data from the table: "Customer" */
  Customer: Array<Customer>;
  /** fetch data from the table: "CustomerAlert" */
  CustomerAlert: Array<CustomerAlert>;
  /** fetch data from the table: "CustomerAlertHistory" */
  CustomerAlertHistory: Array<CustomerAlertHistory>;
  /** fetch aggregated fields from the table: "CustomerAlertHistory" */
  CustomerAlertHistory_aggregate: CustomerAlertHistory_Aggregate;
  /** fetch data from the table: "CustomerAlertHistory" using primary key columns */
  CustomerAlertHistory_by_pk: Maybe<CustomerAlertHistory>;
  /** fetch data from the table in a streaming manner: "CustomerAlertHistory" */
  CustomerAlertHistory_stream: Array<CustomerAlertHistory>;
  /** fetch data from the table: "CustomerAlertStatusEnum" */
  CustomerAlertStatusEnum: Array<CustomerAlertStatusEnum>;
  /** fetch aggregated fields from the table: "CustomerAlertStatusEnum" */
  CustomerAlertStatusEnum_aggregate: CustomerAlertStatusEnum_Aggregate;
  /** fetch data from the table: "CustomerAlertStatusEnum" using primary key columns */
  CustomerAlertStatusEnum_by_pk: Maybe<CustomerAlertStatusEnum>;
  /** fetch data from the table in a streaming manner: "CustomerAlertStatusEnum" */
  CustomerAlertStatusEnum_stream: Array<CustomerAlertStatusEnum>;
  /** fetch aggregated fields from the table: "CustomerAlert" */
  CustomerAlert_aggregate: CustomerAlert_Aggregate;
  /** fetch data from the table: "CustomerAlert" using primary key columns */
  CustomerAlert_by_pk: Maybe<CustomerAlert>;
  /** fetch data from the table in a streaming manner: "CustomerAlert" */
  CustomerAlert_stream: Array<CustomerAlert>;
  /** fetch data from the table: "CustomerConfig" */
  CustomerConfig: Array<CustomerConfig>;
  /** fetch aggregated fields from the table: "CustomerConfig" */
  CustomerConfig_aggregate: CustomerConfig_Aggregate;
  /** fetch data from the table: "CustomerConfig" using primary key columns */
  CustomerConfig_by_pk: Maybe<CustomerConfig>;
  /** fetch data from the table in a streaming manner: "CustomerConfig" */
  CustomerConfig_stream: Array<CustomerConfig>;
  /** fetch data from the table: "CustomerIngestAlias" */
  CustomerIngestAlias: Array<CustomerIngestAlias>;
  /** fetch aggregated fields from the table: "CustomerIngestAlias" */
  CustomerIngestAlias_aggregate: CustomerIngestAlias_Aggregate;
  /** fetch data from the table: "CustomerIngestAlias" using primary key columns */
  CustomerIngestAlias_by_pk: Maybe<CustomerIngestAlias>;
  /** fetch data from the table in a streaming manner: "CustomerIngestAlias" */
  CustomerIngestAlias_stream: Array<CustomerIngestAlias>;
  /** fetch data from the table: "CustomerManagedField" */
  CustomerManagedField: Array<CustomerManagedField>;
  /** fetch aggregated fields from the table: "CustomerManagedField" */
  CustomerManagedField_aggregate: CustomerManagedField_Aggregate;
  /** fetch data from the table: "CustomerManagedField" using primary key columns */
  CustomerManagedField_by_pk: Maybe<CustomerManagedField>;
  /** fetch data from the table in a streaming manner: "CustomerManagedField" */
  CustomerManagedField_stream: Array<CustomerManagedField>;
  /** fetch data from the table: "CustomerPlan" */
  CustomerPlan: Array<CustomerPlan>;
  /** fetch data from the table: "CustomerPlanAdjustment" */
  CustomerPlanAdjustment: Array<CustomerPlanAdjustment>;
  /** fetch aggregated fields from the table: "CustomerPlanAdjustment" */
  CustomerPlanAdjustment_aggregate: CustomerPlanAdjustment_Aggregate;
  /** fetch data from the table: "CustomerPlanAdjustment" using primary key columns */
  CustomerPlanAdjustment_by_pk: Maybe<CustomerPlanAdjustment>;
  /** fetch data from the table in a streaming manner: "CustomerPlanAdjustment" */
  CustomerPlanAdjustment_stream: Array<CustomerPlanAdjustment>;
  /** fetch data from the table: "CustomerPlanManagedField" */
  CustomerPlanManagedField: Array<CustomerPlanManagedField>;
  /** fetch aggregated fields from the table: "CustomerPlanManagedField" */
  CustomerPlanManagedField_aggregate: CustomerPlanManagedField_Aggregate;
  /** fetch data from the table: "CustomerPlanManagedField" using primary key columns */
  CustomerPlanManagedField_by_pk: Maybe<CustomerPlanManagedField>;
  /** fetch data from the table in a streaming manner: "CustomerPlanManagedField" */
  CustomerPlanManagedField_stream: Array<CustomerPlanManagedField>;
  /** fetch data from the table: "CustomerPlanOneTimeCharge" */
  CustomerPlanOneTimeCharge: Array<CustomerPlanOneTimeCharge>;
  /** fetch aggregated fields from the table: "CustomerPlanOneTimeCharge" */
  CustomerPlanOneTimeCharge_aggregate: CustomerPlanOneTimeCharge_Aggregate;
  /** fetch data from the table: "CustomerPlanOneTimeCharge" using primary key columns */
  CustomerPlanOneTimeCharge_by_pk: Maybe<CustomerPlanOneTimeCharge>;
  /** fetch data from the table in a streaming manner: "CustomerPlanOneTimeCharge" */
  CustomerPlanOneTimeCharge_stream: Array<CustomerPlanOneTimeCharge>;
  /** fetch aggregated fields from the table: "CustomerPlan" */
  CustomerPlan_aggregate: CustomerPlan_Aggregate;
  /** fetch data from the table: "CustomerPlan" using primary key columns */
  CustomerPlan_by_pk: Maybe<CustomerPlan>;
  /** fetch data from the table in a streaming manner: "CustomerPlan" */
  CustomerPlan_stream: Array<CustomerPlan>;
  /** fetch data from the table: "CustomerRelationship" */
  CustomerRelationship: Array<CustomerRelationship>;
  /** fetch aggregated fields from the table: "CustomerRelationship" */
  CustomerRelationship_aggregate: CustomerRelationship_Aggregate;
  /** fetch data from the table: "CustomerRelationship" using primary key columns */
  CustomerRelationship_by_pk: Maybe<CustomerRelationship>;
  /** fetch data from the table in a streaming manner: "CustomerRelationship" */
  CustomerRelationship_stream: Array<CustomerRelationship>;
  /** fetch aggregated fields from the table: "Customer" */
  Customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "Customer" using primary key columns */
  Customer_by_pk: Maybe<Customer>;
  /** fetch data from the table in a streaming manner: "Customer" */
  Customer_stream: Array<Customer>;
  /** fetch data from the table: "DraftPlan" */
  DraftPlan: Array<DraftPlan>;
  /** fetch aggregated fields from the table: "DraftPlan" */
  DraftPlan_aggregate: DraftPlan_Aggregate;
  /** fetch data from the table: "DraftPlan" using primary key columns */
  DraftPlan_by_pk: Maybe<DraftPlan>;
  /** fetch data from the table in a streaming manner: "DraftPlan" */
  DraftPlan_stream: Array<DraftPlan>;
  /** fetch data from the table: "EnvironmentTypeEnum" */
  EnvironmentTypeEnum: Array<EnvironmentTypeEnum>;
  /** fetch aggregated fields from the table: "EnvironmentTypeEnum" */
  EnvironmentTypeEnum_aggregate: EnvironmentTypeEnum_Aggregate;
  /** fetch data from the table: "EnvironmentTypeEnum" using primary key columns */
  EnvironmentTypeEnum_by_pk: Maybe<EnvironmentTypeEnum>;
  /** fetch data from the table in a streaming manner: "EnvironmentTypeEnum" */
  EnvironmentTypeEnum_stream: Array<EnvironmentTypeEnum>;
  /** fetch data from the table: "FlatFee" */
  FlatFee: Array<FlatFee>;
  /** fetch data from the table: "FlatFeeAdjustment" */
  FlatFeeAdjustment: Array<FlatFeeAdjustment>;
  /** fetch aggregated fields from the table: "FlatFeeAdjustment" */
  FlatFeeAdjustment_aggregate: FlatFeeAdjustment_Aggregate;
  /** fetch data from the table: "FlatFeeAdjustment" using primary key columns */
  FlatFeeAdjustment_by_pk: Maybe<FlatFeeAdjustment>;
  /** fetch data from the table in a streaming manner: "FlatFeeAdjustment" */
  FlatFeeAdjustment_stream: Array<FlatFeeAdjustment>;
  /** fetch aggregated fields from the table: "FlatFee" */
  FlatFee_aggregate: FlatFee_Aggregate;
  /** fetch data from the table: "FlatFee" using primary key columns */
  FlatFee_by_pk: Maybe<FlatFee>;
  /** fetch data from the table in a streaming manner: "FlatFee" */
  FlatFee_stream: Array<FlatFee>;
  /** fetch data from the table: "InvoiceExternalStatusEnum" */
  InvoiceExternalStatusEnum: Array<InvoiceExternalStatusEnum>;
  /** fetch aggregated fields from the table: "InvoiceExternalStatusEnum" */
  InvoiceExternalStatusEnum_aggregate: InvoiceExternalStatusEnum_Aggregate;
  /** fetch data from the table: "InvoiceExternalStatusEnum" using primary key columns */
  InvoiceExternalStatusEnum_by_pk: Maybe<InvoiceExternalStatusEnum>;
  /** fetch data from the table in a streaming manner: "InvoiceExternalStatusEnum" */
  InvoiceExternalStatusEnum_stream: Array<InvoiceExternalStatusEnum>;
  /** fetch data from the table: "InvoiceManagedField" */
  InvoiceManagedField: Array<InvoiceManagedField>;
  /** fetch aggregated fields from the table: "InvoiceManagedField" */
  InvoiceManagedField_aggregate: InvoiceManagedField_Aggregate;
  /** fetch data from the table: "InvoiceManagedField" using primary key columns */
  InvoiceManagedField_by_pk: Maybe<InvoiceManagedField>;
  /** fetch data from the table in a streaming manner: "InvoiceManagedField" */
  InvoiceManagedField_stream: Array<InvoiceManagedField>;
  /** fetch data from the table: "ManagedEntityEnum" */
  ManagedEntityEnum: Array<ManagedEntityEnum>;
  /** fetch aggregated fields from the table: "ManagedEntityEnum" */
  ManagedEntityEnum_aggregate: ManagedEntityEnum_Aggregate;
  /** fetch data from the table: "ManagedEntityEnum" using primary key columns */
  ManagedEntityEnum_by_pk: Maybe<ManagedEntityEnum>;
  /** fetch data from the table in a streaming manner: "ManagedEntityEnum" */
  ManagedEntityEnum_stream: Array<ManagedEntityEnum>;
  /** fetch data from the table: "ManagedFieldKey" */
  ManagedFieldKey: Array<ManagedFieldKey>;
  /** fetch aggregated fields from the table: "ManagedFieldKey" */
  ManagedFieldKey_aggregate: ManagedFieldKey_Aggregate;
  /** fetch data from the table: "ManagedFieldKey" using primary key columns */
  ManagedFieldKey_by_pk: Maybe<ManagedFieldKey>;
  /** fetch data from the table in a streaming manner: "ManagedFieldKey" */
  ManagedFieldKey_stream: Array<ManagedFieldKey>;
  /** fetch data from the table: "ManagedFieldVendorMapping" */
  ManagedFieldVendorMapping: Array<ManagedFieldVendorMapping>;
  /** fetch aggregated fields from the table: "ManagedFieldVendorMapping" */
  ManagedFieldVendorMapping_aggregate: ManagedFieldVendorMapping_Aggregate;
  /** fetch data from the table: "ManagedFieldVendorMapping" using primary key columns */
  ManagedFieldVendorMapping_by_pk: Maybe<ManagedFieldVendorMapping>;
  /** fetch data from the table in a streaming manner: "ManagedFieldVendorMapping" */
  ManagedFieldVendorMapping_stream: Array<ManagedFieldVendorMapping>;
  /** fetch data from the table: "ManagedVendorEnum" */
  ManagedVendorEnum: Array<ManagedVendorEnum>;
  /** fetch aggregated fields from the table: "ManagedVendorEnum" */
  ManagedVendorEnum_aggregate: ManagedVendorEnum_Aggregate;
  /** fetch data from the table: "ManagedVendorEnum" using primary key columns */
  ManagedVendorEnum_by_pk: Maybe<ManagedVendorEnum>;
  /** fetch data from the table in a streaming manner: "ManagedVendorEnum" */
  ManagedVendorEnum_stream: Array<ManagedVendorEnum>;
  /** fetch data from the table: "Minimum" */
  Minimum: Array<Minimum>;
  /** fetch aggregated fields from the table: "Minimum" */
  Minimum_aggregate: Minimum_Aggregate;
  /** fetch data from the table: "Minimum" using primary key columns */
  Minimum_by_pk: Maybe<Minimum>;
  /** fetch data from the table in a streaming manner: "Minimum" */
  Minimum_stream: Array<Minimum>;
  /** fetch data from the table: "Plan" */
  Plan: Array<Plan>;
  /** fetch data from the table: "PlanManagedField" */
  PlanManagedField: Array<PlanManagedField>;
  /** fetch aggregated fields from the table: "PlanManagedField" */
  PlanManagedField_aggregate: PlanManagedField_Aggregate;
  /** fetch data from the table: "PlanManagedField" using primary key columns */
  PlanManagedField_by_pk: Maybe<PlanManagedField>;
  /** fetch data from the table in a streaming manner: "PlanManagedField" */
  PlanManagedField_stream: Array<PlanManagedField>;
  /** fetch aggregated fields from the table: "Plan" */
  Plan_aggregate: Plan_Aggregate;
  /** fetch data from the table: "Plan" using primary key columns */
  Plan_by_pk: Maybe<Plan>;
  /** fetch data from the table in a streaming manner: "Plan" */
  Plan_stream: Array<Plan>;
  /** fetch data from the table: "Price" */
  Price: Array<Price>;
  /** fetch data from the table: "PriceAdjustment" */
  PriceAdjustment: Array<PriceAdjustment>;
  /** fetch data from the table: "PriceAdjustmentTypeEnum" */
  PriceAdjustmentTypeEnum: Array<PriceAdjustmentTypeEnum>;
  /** fetch aggregated fields from the table: "PriceAdjustmentTypeEnum" */
  PriceAdjustmentTypeEnum_aggregate: PriceAdjustmentTypeEnum_Aggregate;
  /** fetch data from the table: "PriceAdjustmentTypeEnum" using primary key columns */
  PriceAdjustmentTypeEnum_by_pk: Maybe<PriceAdjustmentTypeEnum>;
  /** fetch data from the table in a streaming manner: "PriceAdjustmentTypeEnum" */
  PriceAdjustmentTypeEnum_stream: Array<PriceAdjustmentTypeEnum>;
  /** fetch aggregated fields from the table: "PriceAdjustment" */
  PriceAdjustment_aggregate: PriceAdjustment_Aggregate;
  /** fetch data from the table: "PriceAdjustment" using primary key columns */
  PriceAdjustment_by_pk: Maybe<PriceAdjustment>;
  /** fetch data from the table in a streaming manner: "PriceAdjustment" */
  PriceAdjustment_stream: Array<PriceAdjustment>;
  /** fetch aggregated fields from the table: "Price" */
  Price_aggregate: Price_Aggregate;
  /** fetch data from the table: "Price" using primary key columns */
  Price_by_pk: Maybe<Price>;
  /** fetch data from the table in a streaming manner: "Price" */
  Price_stream: Array<Price>;
  /** fetch data from the table: "PricedProduct" */
  PricedProduct: Array<PricedProduct>;
  /** fetch data from the table: "PricedProductAdjustment" */
  PricedProductAdjustment: Array<PricedProductAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductAdjustment" */
  PricedProductAdjustment_aggregate: PricedProductAdjustment_Aggregate;
  /** fetch data from the table: "PricedProductAdjustment" using primary key columns */
  PricedProductAdjustment_by_pk: Maybe<PricedProductAdjustment>;
  /** fetch data from the table in a streaming manner: "PricedProductAdjustment" */
  PricedProductAdjustment_stream: Array<PricedProductAdjustment>;
  /** fetch data from the table: "PricedProductPricingFactor" */
  PricedProductPricingFactor: Array<PricedProductPricingFactor>;
  /** fetch data from the table: "PricedProductPricingFactorAdjustment" */
  PricedProductPricingFactorAdjustment: Array<PricedProductPricingFactorAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductPricingFactorAdjustment" */
  PricedProductPricingFactorAdjustment_aggregate: PricedProductPricingFactorAdjustment_Aggregate;
  /** fetch data from the table: "PricedProductPricingFactorAdjustment" using primary key columns */
  PricedProductPricingFactorAdjustment_by_pk: Maybe<PricedProductPricingFactorAdjustment>;
  /** fetch data from the table in a streaming manner: "PricedProductPricingFactorAdjustment" */
  PricedProductPricingFactorAdjustment_stream: Array<PricedProductPricingFactorAdjustment>;
  /** fetch aggregated fields from the table: "PricedProductPricingFactor" */
  PricedProductPricingFactor_aggregate: PricedProductPricingFactor_Aggregate;
  /** fetch data from the table: "PricedProductPricingFactor" using primary key columns */
  PricedProductPricingFactor_by_pk: Maybe<PricedProductPricingFactor>;
  /** fetch data from the table in a streaming manner: "PricedProductPricingFactor" */
  PricedProductPricingFactor_stream: Array<PricedProductPricingFactor>;
  /** fetch aggregated fields from the table: "PricedProduct" */
  PricedProduct_aggregate: PricedProduct_Aggregate;
  /** fetch data from the table: "PricedProduct" using primary key columns */
  PricedProduct_by_pk: Maybe<PricedProduct>;
  /** fetch data from the table in a streaming manner: "PricedProduct" */
  PricedProduct_stream: Array<PricedProduct>;
  /** fetch data from the table: "ProServiceManagedField" */
  ProServiceManagedField: Array<ProServiceManagedField>;
  /** fetch aggregated fields from the table: "ProServiceManagedField" */
  ProServiceManagedField_aggregate: ProServiceManagedField_Aggregate;
  /** fetch data from the table: "ProServiceManagedField" using primary key columns */
  ProServiceManagedField_by_pk: Maybe<ProServiceManagedField>;
  /** fetch data from the table in a streaming manner: "ProServiceManagedField" */
  ProServiceManagedField_stream: Array<ProServiceManagedField>;
  /** fetch data from the table: "Product" */
  Product: Array<Product>;
  /** fetch data from the table: "ProductManagedField" */
  ProductManagedField: Array<ProductManagedField>;
  /** fetch aggregated fields from the table: "ProductManagedField" */
  ProductManagedField_aggregate: ProductManagedField_Aggregate;
  /** fetch data from the table: "ProductManagedField" using primary key columns */
  ProductManagedField_by_pk: Maybe<ProductManagedField>;
  /** fetch data from the table in a streaming manner: "ProductManagedField" */
  ProductManagedField_stream: Array<ProductManagedField>;
  /** fetch data from the table: "ProductPricingFactor" */
  ProductPricingFactor: Array<ProductPricingFactor>;
  /** fetch aggregated fields from the table: "ProductPricingFactor" */
  ProductPricingFactor_aggregate: ProductPricingFactor_Aggregate;
  /** fetch data from the table: "ProductPricingFactor" using primary key columns */
  ProductPricingFactor_by_pk: Maybe<ProductPricingFactor>;
  /** fetch data from the table in a streaming manner: "ProductPricingFactor" */
  ProductPricingFactor_stream: Array<ProductPricingFactor>;
  /** fetch aggregated fields from the table: "Product" */
  Product_aggregate: Product_Aggregate;
  /** fetch data from the table: "Product" using primary key columns */
  Product_by_pk: Maybe<Product>;
  /** fetch data from the table in a streaming manner: "Product" */
  Product_stream: Array<Product>;
  /** fetch data from the table: "RateCardManagedField" */
  RateCardManagedField: Array<RateCardManagedField>;
  /** fetch aggregated fields from the table: "RateCardManagedField" */
  RateCardManagedField_aggregate: RateCardManagedField_Aggregate;
  /** fetch data from the table: "RateCardManagedField" using primary key columns */
  RateCardManagedField_by_pk: Maybe<RateCardManagedField>;
  /** fetch data from the table in a streaming manner: "RateCardManagedField" */
  RateCardManagedField_stream: Array<RateCardManagedField>;
  /** fetch data from the table: "RecurringCreditGrant" */
  RecurringCreditGrant: Array<RecurringCreditGrant>;
  /** fetch aggregated fields from the table: "RecurringCreditGrant" */
  RecurringCreditGrant_aggregate: RecurringCreditGrant_Aggregate;
  /** fetch data from the table: "RecurringCreditGrant" using primary key columns */
  RecurringCreditGrant_by_pk: Maybe<RecurringCreditGrant>;
  /** fetch data from the table in a streaming manner: "RecurringCreditGrant" */
  RecurringCreditGrant_stream: Array<RecurringCreditGrant>;
  /** fetch data from the table: "Role" */
  Role: Array<Role>;
  /** fetch aggregated fields from the table: "Role" */
  Role_aggregate: Role_Aggregate;
  /** fetch data from the table: "Role" using primary key columns */
  Role_by_pk: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "Role" */
  Role_stream: Array<Role>;
  /** fetch data from the table: "RoundingBehaviorEnum" */
  RoundingBehaviorEnum: Array<RoundingBehaviorEnum>;
  /** fetch aggregated fields from the table: "RoundingBehaviorEnum" */
  RoundingBehaviorEnum_aggregate: RoundingBehaviorEnum_Aggregate;
  /** fetch data from the table: "RoundingBehaviorEnum" using primary key columns */
  RoundingBehaviorEnum_by_pk: Maybe<RoundingBehaviorEnum>;
  /** fetch data from the table in a streaming manner: "RoundingBehaviorEnum" */
  RoundingBehaviorEnum_stream: Array<RoundingBehaviorEnum>;
  /** fetch data from the table: "ScheduledChargeManagedField" */
  ScheduledChargeManagedField: Array<ScheduledChargeManagedField>;
  /** fetch aggregated fields from the table: "ScheduledChargeManagedField" */
  ScheduledChargeManagedField_aggregate: ScheduledChargeManagedField_Aggregate;
  /** fetch data from the table: "ScheduledChargeManagedField" using primary key columns */
  ScheduledChargeManagedField_by_pk: Maybe<ScheduledChargeManagedField>;
  /** fetch data from the table in a streaming manner: "ScheduledChargeManagedField" */
  ScheduledChargeManagedField_stream: Array<ScheduledChargeManagedField>;
  /** fetch data from the table: "ServicePeriodStartOnEnum" */
  ServicePeriodStartOnEnum: Array<ServicePeriodStartOnEnum>;
  /** fetch aggregated fields from the table: "ServicePeriodStartOnEnum" */
  ServicePeriodStartOnEnum_aggregate: ServicePeriodStartOnEnum_Aggregate;
  /** fetch data from the table: "ServicePeriodStartOnEnum" using primary key columns */
  ServicePeriodStartOnEnum_by_pk: Maybe<ServicePeriodStartOnEnum>;
  /** fetch data from the table in a streaming manner: "ServicePeriodStartOnEnum" */
  ServicePeriodStartOnEnum_stream: Array<ServicePeriodStartOnEnum>;
  /** fetch data from the table: "StripePrepaidConfig_temporary" */
  StripePrepaidConfig_temporary: Array<StripePrepaidConfig_Temporary>;
  /** fetch aggregated fields from the table: "StripePrepaidConfig_temporary" */
  StripePrepaidConfig_temporary_aggregate: StripePrepaidConfig_Temporary_Aggregate;
  /** fetch data from the table: "StripePrepaidConfig_temporary" using primary key columns */
  StripePrepaidConfig_temporary_by_pk: Maybe<StripePrepaidConfig_Temporary>;
  /** fetch data from the table in a streaming manner: "StripePrepaidConfig_temporary" */
  StripePrepaidConfig_temporary_stream: Array<StripePrepaidConfig_Temporary>;
  /** fetch data from the table: "TieringModeEnum" */
  TieringModeEnum: Array<TieringModeEnum>;
  /** fetch aggregated fields from the table: "TieringModeEnum" */
  TieringModeEnum_aggregate: TieringModeEnum_Aggregate;
  /** fetch data from the table: "TieringModeEnum" using primary key columns */
  TieringModeEnum_by_pk: Maybe<TieringModeEnum>;
  /** fetch data from the table in a streaming manner: "TieringModeEnum" */
  TieringModeEnum_stream: Array<TieringModeEnum>;
  /** An array relationship */
  Tokens: Array<Tokens>;
  /** An aggregate relationship */
  Tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "Tokens" using primary key columns */
  Tokens_by_pk: Maybe<Tokens>;
  /** fetch data from the table in a streaming manner: "Tokens" */
  Tokens_stream: Array<Tokens>;
  /** fetch data from the table: "TrialSpec" */
  TrialSpec: Array<TrialSpec>;
  /** fetch data from the table: "TrialSpecSpendingCap" */
  TrialSpecSpendingCap: Array<TrialSpecSpendingCap>;
  /** fetch aggregated fields from the table: "TrialSpecSpendingCap" */
  TrialSpecSpendingCap_aggregate: TrialSpecSpendingCap_Aggregate;
  /** fetch data from the table: "TrialSpecSpendingCap" using primary key columns */
  TrialSpecSpendingCap_by_pk: Maybe<TrialSpecSpendingCap>;
  /** fetch data from the table in a streaming manner: "TrialSpecSpendingCap" */
  TrialSpecSpendingCap_stream: Array<TrialSpecSpendingCap>;
  /** fetch aggregated fields from the table: "TrialSpec" */
  TrialSpec_aggregate: TrialSpec_Aggregate;
  /** fetch data from the table: "TrialSpec" using primary key columns */
  TrialSpec_by_pk: Maybe<TrialSpec>;
  /** fetch data from the table in a streaming manner: "TrialSpec" */
  TrialSpec_stream: Array<TrialSpec>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserAuthTypeEnum" */
  UserAuthTypeEnum: Array<UserAuthTypeEnum>;
  /** fetch aggregated fields from the table: "UserAuthTypeEnum" */
  UserAuthTypeEnum_aggregate: UserAuthTypeEnum_Aggregate;
  /** fetch data from the table: "UserAuthTypeEnum" using primary key columns */
  UserAuthTypeEnum_by_pk: Maybe<UserAuthTypeEnum>;
  /** fetch data from the table in a streaming manner: "UserAuthTypeEnum" */
  UserAuthTypeEnum_stream: Array<UserAuthTypeEnum>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk: Maybe<User>;
  /** fetch data from the table in a streaming manner: "User" */
  User_stream: Array<User>;
  /** fetch data from the table: "Webhook" */
  Webhook: Array<Webhook>;
  /** fetch data from the table: "webhook_notifications" */
  WebhookNotification: Array<WebhookNotification>;
  /** fetch aggregated fields from the table: "webhook_notifications" */
  WebhookNotification_aggregate: WebhookNotification_Aggregate;
  /** fetch data from the table: "webhook_notifications" using primary key columns */
  WebhookNotification_by_pk: Maybe<WebhookNotification>;
  /** fetch data from the table in a streaming manner: "webhook_notifications" */
  WebhookNotification_stream: Array<WebhookNotification>;
  /** fetch aggregated fields from the table: "Webhook" */
  Webhook_aggregate: Webhook_Aggregate;
  /** fetch data from the table: "Webhook" using primary key columns */
  Webhook_by_pk: Maybe<Webhook>;
  /** fetch data from the table in a streaming manner: "Webhook" */
  Webhook_stream: Array<Webhook>;
};


export type SubscriptionAccountTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AccountTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountTypeEnum_Order_By>>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};


export type SubscriptionAccountTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccountTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountTypeEnum_Order_By>>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};


export type SubscriptionAccountTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionAccountTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AccountTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<AccountTypeEnum_Bool_Exp>;
};


export type SubscriptionActorArgs = {
  distinct_on?: InputMaybe<Array<Actor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Actor_Order_By>>;
  where?: InputMaybe<Actor_Bool_Exp>;
};


export type SubscriptionActor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Actor_Order_By>>;
  where?: InputMaybe<Actor_Bool_Exp>;
};


export type SubscriptionActor_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionActor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Actor_Stream_Cursor_Input>>;
  where?: InputMaybe<Actor_Bool_Exp>;
};


export type SubscriptionAlertArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type SubscriptionAlertManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


export type SubscriptionAlertManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertManagedField_Order_By>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


export type SubscriptionAlertManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAlertManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertManagedField_Bool_Exp>;
};


export type SubscriptionAlertTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<AlertTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypeEnum_Order_By>>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};


export type SubscriptionAlertTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AlertTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AlertTypeEnum_Order_By>>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};


export type SubscriptionAlertTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionAlertTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AlertTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<AlertTypeEnum_Bool_Exp>;
};


export type SubscriptionAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type SubscriptionAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAlert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type SubscriptionBillableMetricArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


export type SubscriptionBillableMetricManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<BillableMetricManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetricManagedField_Order_By>>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};


export type SubscriptionBillableMetricManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillableMetricManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetricManagedField_Order_By>>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};


export type SubscriptionBillableMetricManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionBillableMetricManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillableMetricManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<BillableMetricManagedField_Bool_Exp>;
};


export type SubscriptionBillableMetric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillableMetric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillableMetric_Order_By>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


export type SubscriptionBillableMetric_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionBillableMetric_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillableMetric_Stream_Cursor_Input>>;
  where?: InputMaybe<BillableMetric_Bool_Exp>;
};


export type SubscriptionBillingFrequencyEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingFrequencyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingFrequencyEnum_Order_By>>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};


export type SubscriptionBillingFrequencyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingFrequencyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingFrequencyEnum_Order_By>>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};


export type SubscriptionBillingFrequencyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionBillingFrequencyEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingFrequencyEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingFrequencyEnum_Bool_Exp>;
};


export type SubscriptionBillingMetricAggregateEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingMetricAggregateEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingMetricAggregateEnum_Order_By>>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};


export type SubscriptionBillingMetricAggregateEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingMetricAggregateEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingMetricAggregateEnum_Order_By>>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};


export type SubscriptionBillingMetricAggregateEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionBillingMetricAggregateEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingMetricAggregateEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingMetricAggregateEnum_Bool_Exp>;
};


export type SubscriptionBillingProviderCustomerArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


export type SubscriptionBillingProviderCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderCustomer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderCustomer_Order_By>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


export type SubscriptionBillingProviderCustomer_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  customer_id: Scalars['uuid'];
};


export type SubscriptionBillingProviderCustomer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingProviderCustomer_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingProviderCustomer_Bool_Exp>;
};


export type SubscriptionBillingProviderEnumArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderEnum_Order_By>>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};


export type SubscriptionBillingProviderEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderEnum_Order_By>>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};


export type SubscriptionBillingProviderEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionBillingProviderEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingProviderEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingProviderEnum_Bool_Exp>;
};


export type SubscriptionBillingProviderInvoiceArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderInvoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderInvoice_Order_By>>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};


export type SubscriptionBillingProviderInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderInvoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderInvoice_Order_By>>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};


export type SubscriptionBillingProviderInvoice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionBillingProviderInvoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingProviderInvoice_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingProviderInvoice_Bool_Exp>;
};


export type SubscriptionBillingProviderTokenArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


export type SubscriptionBillingProviderToken_AggregateArgs = {
  distinct_on?: InputMaybe<Array<BillingProviderToken_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BillingProviderToken_Order_By>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


export type SubscriptionBillingProviderToken_By_PkArgs = {
  billing_provider: BillingProviderEnum_Enum;
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


export type SubscriptionBillingProviderToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BillingProviderToken_Stream_Cursor_Input>>;
  where?: InputMaybe<BillingProviderToken_Bool_Exp>;
};


export type SubscriptionChargeManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


export type SubscriptionChargeManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeManagedField_Order_By>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


export type SubscriptionChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionChargeManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChargeManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ChargeManagedField_Bool_Exp>;
};


export type SubscriptionChargeTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<ChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeTypeEnum_Order_By>>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionChargeTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChargeTypeEnum_Order_By>>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionChargeTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChargeTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type SubscriptionClientConfigArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


export type SubscriptionClientConfigKeyEnumArgs = {
  distinct_on?: InputMaybe<Array<ClientConfigKeyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfigKeyEnum_Order_By>>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};


export type SubscriptionClientConfigKeyEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientConfigKeyEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfigKeyEnum_Order_By>>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};


export type SubscriptionClientConfigKeyEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionClientConfigKeyEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientConfigKeyEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientConfigKeyEnum_Bool_Exp>;
};


export type SubscriptionClientConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ClientConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClientConfig_Order_By>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


export type SubscriptionClientConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionClientConfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClientConfig_Stream_Cursor_Input>>;
  where?: InputMaybe<ClientConfig_Bool_Exp>;
};


export type SubscriptionClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Client_Order_By>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type SubscriptionClient_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionClient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Bool_Exp>;
};


export type SubscriptionCollectionScheduleEnumArgs = {
  distinct_on?: InputMaybe<Array<CollectionScheduleEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CollectionScheduleEnum_Order_By>>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};


export type SubscriptionCollectionScheduleEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CollectionScheduleEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CollectionScheduleEnum_Order_By>>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};


export type SubscriptionCollectionScheduleEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionCollectionScheduleEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CollectionScheduleEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<CollectionScheduleEnum_Bool_Exp>;
};


export type SubscriptionCommitManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


export type SubscriptionCommitManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CommitManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CommitManagedField_Order_By>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


export type SubscriptionCommitManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCommitManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CommitManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<CommitManagedField_Bool_Exp>;
};


export type SubscriptionCompositeChargeArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


export type SubscriptionCompositeChargeAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargeAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCompositeChargeAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeChargeAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeChargeAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargePricingFactorArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


export type SubscriptionCompositeChargePricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargePricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargePricingFactor_Order_By>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


export type SubscriptionCompositeChargePricingFactor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeChargePricingFactor_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeChargePricingFactor_Bool_Exp>;
};


export type SubscriptionCompositeChargeTierArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


export type SubscriptionCompositeChargeTierAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargeTierAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTierAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTierAdjustment_Order_By>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargeTierAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCompositeChargeTierAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeChargeTierAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeChargeTierAdjustment_Bool_Exp>;
};


export type SubscriptionCompositeChargeTier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTier_Order_By>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


export type SubscriptionCompositeChargeTier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCompositeChargeTier_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeChargeTier_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeChargeTier_Bool_Exp>;
};


export type SubscriptionCompositeChargeTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTypeEnum_Order_By>>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionCompositeChargeTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeChargeTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeChargeTypeEnum_Order_By>>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionCompositeChargeTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionCompositeChargeTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeChargeTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeChargeTypeEnum_Bool_Exp>;
};


export type SubscriptionCompositeCharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CompositeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompositeCharge_Order_By>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


export type SubscriptionCompositeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCompositeCharge_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompositeCharge_Stream_Cursor_Input>>;
  where?: InputMaybe<CompositeCharge_Bool_Exp>;
};


export type SubscriptionContractCreditManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


export type SubscriptionContractCreditManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractCreditManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractCreditManagedField_Order_By>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


export type SubscriptionContractCreditManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionContractCreditManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractCreditManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractCreditManagedField_Bool_Exp>;
};


export type SubscriptionContractManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


export type SubscriptionContractManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractManagedField_Order_By>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


export type SubscriptionContractManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionContractManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractManagedField_Bool_Exp>;
};


export type SubscriptionContractProductManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


export type SubscriptionContractProductManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ContractProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContractProductManagedField_Order_By>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


export type SubscriptionContractProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionContractProductManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContractProductManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ContractProductManagedField_Bool_Exp>;
};


export type SubscriptionCreditAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CreditAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditAdjustment_Order_By>>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};


export type SubscriptionCreditAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditAdjustment_Order_By>>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};


export type SubscriptionCreditAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditAdjustment_Bool_Exp>;
};


export type SubscriptionCreditGrantArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


export type SubscriptionCreditGrantDeductionArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


export type SubscriptionCreditGrantDeduction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantDeduction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantDeduction_Order_By>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


export type SubscriptionCreditGrantDeduction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditGrantDeduction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditGrantDeduction_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditGrantDeduction_Bool_Exp>;
};


export type SubscriptionCreditGrantHistoryArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantHistory_Order_By>>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};


export type SubscriptionCreditGrantHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantHistory_Order_By>>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};


export type SubscriptionCreditGrantHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditGrantHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditGrantHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditGrantHistory_Bool_Exp>;
};


export type SubscriptionCreditGrantManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


export type SubscriptionCreditGrantManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrantManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrantManagedField_Order_By>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


export type SubscriptionCreditGrantManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditGrantManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditGrantManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditGrantManagedField_Bool_Exp>;
};


export type SubscriptionCreditGrant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditGrant_Order_By>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


export type SubscriptionCreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditGrant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditGrant_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditGrant_Bool_Exp>;
};


export type SubscriptionCreditTypeArgs = {
  distinct_on?: InputMaybe<Array<CreditType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditType_Order_By>>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};


export type SubscriptionCreditTypeConversionArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


export type SubscriptionCreditTypeConversionAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


export type SubscriptionCreditTypeConversionAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversionAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversionAdjustment_Order_By>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


export type SubscriptionCreditTypeConversionAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditTypeConversionAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditTypeConversionAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditTypeConversionAdjustment_Bool_Exp>;
};


export type SubscriptionCreditTypeConversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditTypeConversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditTypeConversion_Order_By>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


export type SubscriptionCreditTypeConversion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditTypeConversion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditTypeConversion_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditTypeConversion_Bool_Exp>;
};


export type SubscriptionCreditType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditType_Order_By>>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};


export type SubscriptionCreditType_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCreditType_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CreditType_Stream_Cursor_Input>>;
  where?: InputMaybe<CreditType_Bool_Exp>;
};


export type SubscriptionCustomPricingArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


export type SubscriptionCustomPricing_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomPricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomPricing_Order_By>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


export type SubscriptionCustomPricing_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomPricing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomPricing_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomPricing_Bool_Exp>;
};


export type SubscriptionCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type SubscriptionCustomerAlertArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


export type SubscriptionCustomerAlertHistoryArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


export type SubscriptionCustomerAlertHistory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertHistory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertHistory_Order_By>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


export type SubscriptionCustomerAlertHistory_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerAlertHistory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerAlertHistory_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerAlertHistory_Bool_Exp>;
};


export type SubscriptionCustomerAlertStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertStatusEnum_Order_By>>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};


export type SubscriptionCustomerAlertStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlertStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlertStatusEnum_Order_By>>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};


export type SubscriptionCustomerAlertStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionCustomerAlertStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerAlertStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerAlertStatusEnum_Bool_Exp>;
};


export type SubscriptionCustomerAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerAlert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerAlert_Order_By>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


export type SubscriptionCustomerAlert_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerAlert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerAlert_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerAlert_Bool_Exp>;
};


export type SubscriptionCustomerConfigArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


export type SubscriptionCustomerConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerConfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerConfig_Order_By>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


export type SubscriptionCustomerConfig_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerConfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerConfig_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerConfig_Bool_Exp>;
};


export type SubscriptionCustomerIngestAliasArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


export type SubscriptionCustomerIngestAlias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerIngestAlias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerIngestAlias_Order_By>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


export type SubscriptionCustomerIngestAlias_By_PkArgs = {
  client_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  ingest_alias: Scalars['String'];
};


export type SubscriptionCustomerIngestAlias_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerIngestAlias_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerIngestAlias_Bool_Exp>;
};


export type SubscriptionCustomerManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


export type SubscriptionCustomerManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerManagedField_Order_By>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


export type SubscriptionCustomerManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerManagedField_Bool_Exp>;
};


export type SubscriptionCustomerPlanArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


export type SubscriptionCustomerPlanAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


export type SubscriptionCustomerPlanAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanAdjustment_Order_By>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


export type SubscriptionCustomerPlanAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerPlanAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerPlanAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerPlanAdjustment_Bool_Exp>;
};


export type SubscriptionCustomerPlanManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


export type SubscriptionCustomerPlanManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanManagedField_Order_By>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


export type SubscriptionCustomerPlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerPlanManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerPlanManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerPlanManagedField_Bool_Exp>;
};


export type SubscriptionCustomerPlanOneTimeChargeArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanOneTimeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanOneTimeCharge_Order_By>>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};


export type SubscriptionCustomerPlanOneTimeCharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlanOneTimeCharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlanOneTimeCharge_Order_By>>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};


export type SubscriptionCustomerPlanOneTimeCharge_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerPlanOneTimeCharge_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerPlanOneTimeCharge_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerPlanOneTimeCharge_Bool_Exp>;
};


export type SubscriptionCustomerPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


export type SubscriptionCustomerPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


export type SubscriptionCustomerRelationshipArgs = {
  distinct_on?: InputMaybe<Array<CustomerRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerRelationship_Order_By>>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};


export type SubscriptionCustomerRelationship_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerRelationship_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerRelationship_Order_By>>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};


export type SubscriptionCustomerRelationship_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomerRelationship_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerRelationship_Stream_Cursor_Input>>;
  where?: InputMaybe<CustomerRelationship_Bool_Exp>;
};


export type SubscriptionCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type SubscriptionCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionCustomer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type SubscriptionDraftPlanArgs = {
  distinct_on?: InputMaybe<Array<DraftPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DraftPlan_Order_By>>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};


export type SubscriptionDraftPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DraftPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DraftPlan_Order_By>>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};


export type SubscriptionDraftPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionDraftPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DraftPlan_Stream_Cursor_Input>>;
  where?: InputMaybe<DraftPlan_Bool_Exp>;
};


export type SubscriptionEnvironmentTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnvironmentTypeEnum_Order_By>>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};


export type SubscriptionEnvironmentTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EnvironmentTypeEnum_Order_By>>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};


export type SubscriptionEnvironmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionEnvironmentTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EnvironmentTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<EnvironmentTypeEnum_Bool_Exp>;
};


export type SubscriptionFlatFeeArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


export type SubscriptionFlatFeeAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


export type SubscriptionFlatFeeAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFeeAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFeeAdjustment_Order_By>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


export type SubscriptionFlatFeeAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionFlatFeeAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlatFeeAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<FlatFeeAdjustment_Bool_Exp>;
};


export type SubscriptionFlatFee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FlatFee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FlatFee_Order_By>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


export type SubscriptionFlatFee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionFlatFee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FlatFee_Stream_Cursor_Input>>;
  where?: InputMaybe<FlatFee_Bool_Exp>;
};


export type SubscriptionInvoiceExternalStatusEnumArgs = {
  distinct_on?: InputMaybe<Array<InvoiceExternalStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceExternalStatusEnum_Order_By>>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};


export type SubscriptionInvoiceExternalStatusEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceExternalStatusEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceExternalStatusEnum_Order_By>>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};


export type SubscriptionInvoiceExternalStatusEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionInvoiceExternalStatusEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceExternalStatusEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceExternalStatusEnum_Bool_Exp>;
};


export type SubscriptionInvoiceManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


export type SubscriptionInvoiceManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InvoiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InvoiceManagedField_Order_By>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


export type SubscriptionInvoiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionInvoiceManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InvoiceManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<InvoiceManagedField_Bool_Exp>;
};


export type SubscriptionManagedEntityEnumArgs = {
  distinct_on?: InputMaybe<Array<ManagedEntityEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedEntityEnum_Order_By>>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};


export type SubscriptionManagedEntityEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedEntityEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedEntityEnum_Order_By>>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};


export type SubscriptionManagedEntityEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionManagedEntityEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManagedEntityEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ManagedEntityEnum_Bool_Exp>;
};


export type SubscriptionManagedFieldKeyArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldKey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldKey_Order_By>>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};


export type SubscriptionManagedFieldKey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldKey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldKey_Order_By>>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};


export type SubscriptionManagedFieldKey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionManagedFieldKey_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManagedFieldKey_Stream_Cursor_Input>>;
  where?: InputMaybe<ManagedFieldKey_Bool_Exp>;
};


export type SubscriptionManagedFieldVendorMappingArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldVendorMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldVendorMapping_Order_By>>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};


export type SubscriptionManagedFieldVendorMapping_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedFieldVendorMapping_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedFieldVendorMapping_Order_By>>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};


export type SubscriptionManagedFieldVendorMapping_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionManagedFieldVendorMapping_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManagedFieldVendorMapping_Stream_Cursor_Input>>;
  where?: InputMaybe<ManagedFieldVendorMapping_Bool_Exp>;
};


export type SubscriptionManagedVendorEnumArgs = {
  distinct_on?: InputMaybe<Array<ManagedVendorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedVendorEnum_Order_By>>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};


export type SubscriptionManagedVendorEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ManagedVendorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ManagedVendorEnum_Order_By>>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};


export type SubscriptionManagedVendorEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionManagedVendorEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ManagedVendorEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ManagedVendorEnum_Bool_Exp>;
};


export type SubscriptionMinimumArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


export type SubscriptionMinimum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Minimum_Order_By>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


export type SubscriptionMinimum_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionMinimum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Minimum_Stream_Cursor_Input>>;
  where?: InputMaybe<Minimum_Bool_Exp>;
};


export type SubscriptionPlanArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type SubscriptionPlanManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


export type SubscriptionPlanManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanManagedField_Order_By>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


export type SubscriptionPlanManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPlanManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<PlanManagedField_Bool_Exp>;
};


export type SubscriptionPlan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type SubscriptionPlan_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPlan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


export type SubscriptionPriceArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type SubscriptionPriceAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


export type SubscriptionPriceAdjustmentTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustmentTypeEnum_Order_By>>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};


export type SubscriptionPriceAdjustmentTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustmentTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustmentTypeEnum_Order_By>>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};


export type SubscriptionPriceAdjustmentTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionPriceAdjustmentTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PriceAdjustmentTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<PriceAdjustmentTypeEnum_Bool_Exp>;
};


export type SubscriptionPriceAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PriceAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PriceAdjustment_Order_By>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


export type SubscriptionPriceAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPriceAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PriceAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<PriceAdjustment_Bool_Exp>;
};


export type SubscriptionPrice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Price_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Price_Order_By>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type SubscriptionPrice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPrice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Price_Stream_Cursor_Input>>;
  where?: InputMaybe<Price_Bool_Exp>;
};


export type SubscriptionPricedProductArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


export type SubscriptionPricedProductAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPricedProductAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PricedProductAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<PricedProductAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactorArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactorAdjustmentArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactorAdjustment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactorAdjustment_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactorAdjustment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPricedProductPricingFactorAdjustment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PricedProductPricingFactorAdjustment_Stream_Cursor_Input>>;
  where?: InputMaybe<PricedProductPricingFactorAdjustment_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProductPricingFactor_Order_By>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


export type SubscriptionPricedProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPricedProductPricingFactor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PricedProductPricingFactor_Stream_Cursor_Input>>;
  where?: InputMaybe<PricedProductPricingFactor_Bool_Exp>;
};


export type SubscriptionPricedProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PricedProduct_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PricedProduct_Order_By>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


export type SubscriptionPricedProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionPricedProduct_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PricedProduct_Stream_Cursor_Input>>;
  where?: InputMaybe<PricedProduct_Bool_Exp>;
};


export type SubscriptionProServiceManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


export type SubscriptionProServiceManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProServiceManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProServiceManagedField_Order_By>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


export type SubscriptionProServiceManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionProServiceManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProServiceManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ProServiceManagedField_Bool_Exp>;
};


export type SubscriptionProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type SubscriptionProductManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


export type SubscriptionProductManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductManagedField_Order_By>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


export type SubscriptionProductManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionProductManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductManagedField_Bool_Exp>;
};


export type SubscriptionProductPricingFactorArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


export type SubscriptionProductPricingFactor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductPricingFactor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductPricingFactor_Order_By>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


export type SubscriptionProductPricingFactor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionProductPricingFactor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductPricingFactor_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductPricingFactor_Bool_Exp>;
};


export type SubscriptionProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type SubscriptionProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionProduct_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Product_Stream_Cursor_Input>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type SubscriptionRateCardManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


export type SubscriptionRateCardManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RateCardManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RateCardManagedField_Order_By>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


export type SubscriptionRateCardManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRateCardManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RateCardManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<RateCardManagedField_Bool_Exp>;
};


export type SubscriptionRecurringCreditGrantArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


export type SubscriptionRecurringCreditGrant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RecurringCreditGrant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RecurringCreditGrant_Order_By>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


export type SubscriptionRecurringCreditGrant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRecurringCreditGrant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RecurringCreditGrant_Stream_Cursor_Input>>;
  where?: InputMaybe<RecurringCreditGrant_Bool_Exp>;
};


export type SubscriptionRoleArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type SubscriptionRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Order_By>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type SubscriptionRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Bool_Exp>;
};


export type SubscriptionRoundingBehaviorEnumArgs = {
  distinct_on?: InputMaybe<Array<RoundingBehaviorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundingBehaviorEnum_Order_By>>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};


export type SubscriptionRoundingBehaviorEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RoundingBehaviorEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoundingBehaviorEnum_Order_By>>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};


export type SubscriptionRoundingBehaviorEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRoundingBehaviorEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RoundingBehaviorEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<RoundingBehaviorEnum_Bool_Exp>;
};


export type SubscriptionScheduledChargeManagedFieldArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


export type SubscriptionScheduledChargeManagedField_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ScheduledChargeManagedField_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScheduledChargeManagedField_Order_By>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


export type SubscriptionScheduledChargeManagedField_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionScheduledChargeManagedField_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScheduledChargeManagedField_Stream_Cursor_Input>>;
  where?: InputMaybe<ScheduledChargeManagedField_Bool_Exp>;
};


export type SubscriptionServicePeriodStartOnEnumArgs = {
  distinct_on?: InputMaybe<Array<ServicePeriodStartOnEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServicePeriodStartOnEnum_Order_By>>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};


export type SubscriptionServicePeriodStartOnEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ServicePeriodStartOnEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServicePeriodStartOnEnum_Order_By>>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};


export type SubscriptionServicePeriodStartOnEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionServicePeriodStartOnEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServicePeriodStartOnEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<ServicePeriodStartOnEnum_Bool_Exp>;
};


export type SubscriptionStripePrepaidConfig_TemporaryArgs = {
  distinct_on?: InputMaybe<Array<StripePrepaidConfig_Temporary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripePrepaidConfig_Temporary_Order_By>>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};


export type SubscriptionStripePrepaidConfig_Temporary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StripePrepaidConfig_Temporary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StripePrepaidConfig_Temporary_Order_By>>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};


export type SubscriptionStripePrepaidConfig_Temporary_By_PkArgs = {
  client_id: Scalars['uuid'];
  environment_type: EnvironmentTypeEnum_Enum;
};


export type SubscriptionStripePrepaidConfig_Temporary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StripePrepaidConfig_Temporary_Stream_Cursor_Input>>;
  where?: InputMaybe<StripePrepaidConfig_Temporary_Bool_Exp>;
};


export type SubscriptionTieringModeEnumArgs = {
  distinct_on?: InputMaybe<Array<TieringModeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TieringModeEnum_Order_By>>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};


export type SubscriptionTieringModeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TieringModeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TieringModeEnum_Order_By>>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};


export type SubscriptionTieringModeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionTieringModeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TieringModeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};


export type SubscriptionTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type SubscriptionTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type SubscriptionTokens_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionTokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type SubscriptionTrialSpecArgs = {
  distinct_on?: InputMaybe<Array<TrialSpec_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpec_Order_By>>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};


export type SubscriptionTrialSpecSpendingCapArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


export type SubscriptionTrialSpecSpendingCap_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


export type SubscriptionTrialSpecSpendingCap_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTrialSpecSpendingCap_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TrialSpecSpendingCap_Stream_Cursor_Input>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


export type SubscriptionTrialSpec_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TrialSpec_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpec_Order_By>>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};


export type SubscriptionTrialSpec_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionTrialSpec_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TrialSpec_Stream_Cursor_Input>>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};


export type SubscriptionUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUserAuthTypeEnumArgs = {
  distinct_on?: InputMaybe<Array<UserAuthTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAuthTypeEnum_Order_By>>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};


export type SubscriptionUserAuthTypeEnum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAuthTypeEnum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAuthTypeEnum_Order_By>>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};


export type SubscriptionUserAuthTypeEnum_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionUserAuthTypeEnum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserAuthTypeEnum_Stream_Cursor_Input>>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};


export type SubscriptionUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUser_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionWebhookArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Order_By>>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};


export type SubscriptionWebhookNotificationArgs = {
  distinct_on?: InputMaybe<Array<WebhookNotification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WebhookNotification_Order_By>>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};


export type SubscriptionWebhookNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WebhookNotification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WebhookNotification_Order_By>>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};


export type SubscriptionWebhookNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionWebhookNotification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WebhookNotification_Stream_Cursor_Input>>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};


export type SubscriptionWebhook_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webhook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webhook_Order_By>>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};


export type SubscriptionWebhook_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionWebhook_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Webhook_Stream_Cursor_Input>>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};

export type SubscriptionProductListItem = {
  __typename?: 'SubscriptionProductListItem';
  archived_at: Maybe<Scalars['timestamptz']>;
  current: SubscriptionProductListItemState;
  id: Scalars['uuid'];
  initial: SubscriptionProductListItemState;
  managed_fields: Array<ContractProductManagedField>;
  updates: Array<ProductListItemUpdate>;
};

export type SubscriptionProductListItemState = {
  __typename?: 'SubscriptionProductListItemState';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  is_refundable: Scalars['Boolean'];
  name: Scalars['String'];
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  netsuite_overage_item_id: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

export type SubscriptionRate = {
  __typename?: 'SubscriptionRate';
  credit_type: CreditType;
  is_prorated: Scalars['Boolean'];
  quantity: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type SubscriptionRateInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  is_prorated: Scalars['Boolean'];
  quantity: Scalars['numeric'];
  unit_price: Scalars['numeric'];
};

export type SubtotalAndQuantityByTime = {
  __typename?: 'SubtotalAndQuantityByTime';
  quantity: Scalars['numeric'];
  time_range: TimeRange;
  total: Scalars['numeric'];
};

/**
 * Used to request a breakdown of the invoice line items into segments of a specific granularity (e.g. daily/hourly).
 * If this argument is given, then the line items will have their `subtotals_by_time` fields populated with these
 * requested breakdowns.
 */
export type SubtotalBreakdownInput = {
  ending_before?: InputMaybe<Scalars['timestamptz']>;
  granularity: EventWindowSize;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
};

export type SubtotalByTime = {
  __typename?: 'SubtotalByTime';
  time_range: TimeRange;
  total: Scalars['numeric'];
};

export type SubtotalInput = {
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
  quantity: Scalars['numeric'];
};

export enum TaxAppliedStatusEnum_Enum {
  Applied = 'APPLIED',
  Pending = 'PENDING'
}

export enum TaxPlatform {
  Anrok = 'ANROK',
  Stripe = 'STRIPE'
}

export type Tier = {
  __typename?: 'Tier';
  size: Maybe<Scalars['numeric']>;
  unit_price: Scalars['numeric'];
};

export type TierChargeLineItem = Mri_LineItem & {
  __typename?: 'TierChargeLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  line_item_chunks: Array<LineItemChunk>;
  metric_minimum: Scalars['numeric'];
  quantity: Scalars['numeric'];
  subtotals_by_time: Maybe<Array<SubtotalAndQuantityByTime>>;
  tier_price: Scalars['numeric'];
  total: Scalars['numeric'];
};

export type TierInput = {
  size?: InputMaybe<Scalars['numeric']>;
  unit_price: Scalars['numeric'];
};

export type TierMetadata = {
  __typename?: 'TierMetadata';
  level: Scalars['Int'];
  size: Maybe<Scalars['numeric']>;
  starting_at: Scalars['numeric'];
};

export type TierPeriod = {
  __typename?: 'TierPeriod';
  end: Maybe<Scalars['timestamptz']>;
  start: Scalars['timestamptz'];
};

export type TierPrice = FlatFee | Price;

export type TierSubLineItem = TierChargeLineItem;

export type TieredOverride = {
  __typename?: 'TieredOverride';
  priority: Scalars['numeric'];
  tiers: Array<TieredOverrideTiers>;
};

export type TieredOverrideInput = {
  multiplier: Scalars['numeric'];
  size?: InputMaybe<Scalars['numeric']>;
};

export type TieredOverrideTiers = {
  __typename?: 'TieredOverrideTiers';
  multiplier: Scalars['numeric'];
  size: Maybe<Scalars['numeric']>;
};

export type TieredRate = {
  __typename?: 'TieredRate';
  credit_type: CreditType;
  tiers: Array<Tier>;
};

export type TieredRateInput = {
  credit_type_id?: InputMaybe<Scalars['String']>;
  tiers: Array<TierInput>;
};

/** columns and relationships of "TieringModeEnum" */
export type TieringModeEnum = {
  __typename?: 'TieringModeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "TieringModeEnum" */
export type TieringModeEnum_Aggregate = {
  __typename?: 'TieringModeEnum_aggregate';
  aggregate: Maybe<TieringModeEnum_Aggregate_Fields>;
  nodes: Array<TieringModeEnum>;
};

/** aggregate fields of "TieringModeEnum" */
export type TieringModeEnum_Aggregate_Fields = {
  __typename?: 'TieringModeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<TieringModeEnum_Max_Fields>;
  min: Maybe<TieringModeEnum_Min_Fields>;
};


/** aggregate fields of "TieringModeEnum" */
export type TieringModeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TieringModeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "TieringModeEnum". All fields are combined with a logical 'AND'. */
export type TieringModeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<TieringModeEnum_Bool_Exp>>;
  _not?: InputMaybe<TieringModeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<TieringModeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TieringModeEnum" */
export enum TieringModeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  TieringModeEnumPkey = 'TieringModeEnum_pkey'
}

export enum TieringModeEnum_Enum {
  Standard = 'STANDARD',
  Volume = 'VOLUME'
}

/** Boolean expression to compare columns of type "TieringModeEnum_enum". All fields are combined with logical 'AND'. */
export type TieringModeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<TieringModeEnum_Enum>;
  _in?: InputMaybe<Array<TieringModeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TieringModeEnum_Enum>;
  _nin?: InputMaybe<Array<TieringModeEnum_Enum>>;
};

/** input type for inserting data into table "TieringModeEnum" */
export type TieringModeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TieringModeEnum_Max_Fields = {
  __typename?: 'TieringModeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TieringModeEnum_Min_Fields = {
  __typename?: 'TieringModeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "TieringModeEnum" */
export type TieringModeEnum_Mutation_Response = {
  __typename?: 'TieringModeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TieringModeEnum>;
};

/** on_conflict condition type for table "TieringModeEnum" */
export type TieringModeEnum_On_Conflict = {
  constraint: TieringModeEnum_Constraint;
  update_columns?: Array<TieringModeEnum_Update_Column>;
  where?: InputMaybe<TieringModeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "TieringModeEnum". */
export type TieringModeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TieringModeEnum */
export type TieringModeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "TieringModeEnum" */
export enum TieringModeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "TieringModeEnum" */
export type TieringModeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "TieringModeEnum" */
export type TieringModeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TieringModeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TieringModeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "TieringModeEnum" */
export enum TieringModeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type TieringModeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TieringModeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: TieringModeEnum_Bool_Exp;
};

export type TimeRange = {
  __typename?: 'TimeRange';
  exclusive_end_date: Scalars['timestamptz'];
  inclusive_start_date: Scalars['timestamptz'];
};

export type TimeRangeInput = {
  exclusive_end_date?: InputMaybe<Scalars['timestamptz']>;
  inclusive_start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "Tokens" */
export type Tokens = {
  __typename?: 'Tokens';
  /** An object relationship */
  Client: Client;
  /** An object relationship */
  Role: Maybe<Role>;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deprecated_at: Maybe<Scalars['timestamptz']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['String'];
  name: Scalars['String'];
  role_id: Maybe<Scalars['uuid']>;
  token_display_suffix: Scalars['String'];
  token_hash: Scalars['String'];
};

/** aggregated selection of "Tokens" */
export type Tokens_Aggregate = {
  __typename?: 'Tokens_aggregate';
  aggregate: Maybe<Tokens_Aggregate_Fields>;
  nodes: Array<Tokens>;
};

export type Tokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tokens_Aggregate_Bool_Exp_Count>;
};

export type Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Tokens" */
export type Tokens_Aggregate_Fields = {
  __typename?: 'Tokens_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Tokens_Max_Fields>;
  min: Maybe<Tokens_Min_Fields>;
};


/** aggregate fields of "Tokens" */
export type Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Tokens" */
export type Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tokens_Max_Order_By>;
  min?: InputMaybe<Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Tokens" */
export type Tokens_Arr_Rel_Insert_Input = {
  data: Array<Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Tokens". All fields are combined with a logical 'AND'. */
export type Tokens_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  Role?: InputMaybe<Role_Bool_Exp>;
  _and?: InputMaybe<Array<Tokens_Bool_Exp>>;
  _not?: InputMaybe<Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Tokens_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  token_display_suffix?: InputMaybe<String_Comparison_Exp>;
  token_hash?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tokens" */
export enum Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  TokensIdKey = 'Tokens_id_key',
  /** unique or primary key constraint on columns "id" */
  TokensPkey = 'Tokens_pkey',
  /** unique or primary key constraint on columns "token_hash" */
  UniqueTokenHash = 'unique_token_hash'
}

/** input type for inserting data into table "Tokens" */
export type Tokens_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  Role?: InputMaybe<Role_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  token_display_suffix?: InputMaybe<Scalars['String']>;
  token_hash?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tokens_Max_Fields = {
  __typename?: 'Tokens_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
  token_display_suffix: Maybe<Scalars['String']>;
  token_hash: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Tokens" */
export type Tokens_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  token_display_suffix?: InputMaybe<Order_By>;
  token_hash?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tokens_Min_Fields = {
  __typename?: 'Tokens_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
  token_display_suffix: Maybe<Scalars['String']>;
  token_hash: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Tokens" */
export type Tokens_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  token_display_suffix?: InputMaybe<Order_By>;
  token_hash?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Tokens" */
export type Tokens_Mutation_Response = {
  __typename?: 'Tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tokens>;
};

/** on_conflict condition type for table "Tokens" */
export type Tokens_On_Conflict = {
  constraint: Tokens_Constraint;
  update_columns?: Array<Tokens_Update_Column>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "Tokens". */
export type Tokens_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  Role?: InputMaybe<Role_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  token_display_suffix?: InputMaybe<Order_By>;
  token_hash?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Tokens */
export type Tokens_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Tokens" */
export enum Tokens_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TokenDisplaySuffix = 'token_display_suffix',
  /** column name */
  TokenHash = 'token_hash'
}

/** input type for updating data in table "Tokens" */
export type Tokens_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  token_display_suffix?: InputMaybe<Scalars['String']>;
  token_hash?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Tokens" */
export type Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tokens_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  token_display_suffix?: InputMaybe<Scalars['String']>;
  token_hash?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Tokens" */
export enum Tokens_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  TokenDisplaySuffix = 'token_display_suffix',
  /** column name */
  TokenHash = 'token_hash'
}

export type Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tokens_Bool_Exp;
};

export type TopCustomerSpenders = {
  __typename?: 'TopCustomerSpenders';
  id: Scalars['uuid'];
  monthly_spend: Array<RevenueBreakdown>;
  name: Scalars['String'];
  total: Scalars['numeric'];
};

export type Transition = {
  __typename?: 'Transition';
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  from_contract: Contract;
  id: Scalars['uuid'];
  to_contract: Contract;
  type: TransitionTypeEnum;
};

export type TransitionInput = {
  from_contract_id: Scalars['uuid'];
  future_invoice_behavior?: InputMaybe<FutureInvoiceBehaviorInput>;
  type: TransitionTypeEnum;
};

export enum TransitionTypeEnum {
  Renewal = 'RENEWAL',
  Supersede = 'SUPERSEDE'
}

export type TrialDiscountLineItem = Mri_LineItem & {
  __typename?: 'TrialDiscountLineItem';
  credit_type: CreditType;
  display_name: Scalars['String'];
  subtotals_by_time: Maybe<Array<SubtotalByTime>>;
  total: Scalars['numeric'];
};

/** columns and relationships of "TrialSpec" */
export type TrialSpec = {
  __typename?: 'TrialSpec';
  /** An array relationship */
  CustomerPlans: Array<CustomerPlan>;
  /** An aggregate relationship */
  CustomerPlans_aggregate: CustomerPlan_Aggregate;
  /** An array relationship */
  Plans: Array<Plan>;
  /** An aggregate relationship */
  Plans_aggregate: Plan_Aggregate;
  /** An array relationship */
  TrialSpecSpendingCaps: Array<TrialSpecSpendingCap>;
  /** An aggregate relationship */
  TrialSpecSpendingCaps_aggregate: TrialSpecSpendingCap_Aggregate;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  length_in_days: Scalars['numeric'];
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecCustomerPlansArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecCustomerPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerPlan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerPlan_Order_By>>;
  where?: InputMaybe<CustomerPlan_Bool_Exp>;
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecPlansArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Order_By>>;
  where?: InputMaybe<Plan_Bool_Exp>;
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecTrialSpecSpendingCapsArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};


/** columns and relationships of "TrialSpec" */
export type TrialSpecTrialSpecSpendingCaps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TrialSpecSpendingCap_Order_By>>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};

export type TrialSpecInput = {
  length_in_days: Scalars['Int'];
  spending_caps: Array<TrialSpecSpendingCapInput>;
};

/** columns and relationships of "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap = {
  __typename?: 'TrialSpecSpendingCap';
  /** An object relationship */
  CreditType: CreditType;
  /** An object relationship */
  TrialSpec: TrialSpec;
  amount: Scalars['numeric'];
  credit_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  trial_spec_id: Scalars['uuid'];
};

export type TrialSpecSpendingCapInput = {
  amount: Scalars['numeric'];
  credit_type_id: Scalars['uuid'];
};

/** aggregated selection of "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Aggregate = {
  __typename?: 'TrialSpecSpendingCap_aggregate';
  aggregate: Maybe<TrialSpecSpendingCap_Aggregate_Fields>;
  nodes: Array<TrialSpecSpendingCap>;
};

export type TrialSpecSpendingCap_Aggregate_Bool_Exp = {
  count?: InputMaybe<TrialSpecSpendingCap_Aggregate_Bool_Exp_Count>;
};

export type TrialSpecSpendingCap_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Aggregate_Fields = {
  __typename?: 'TrialSpecSpendingCap_aggregate_fields';
  avg: Maybe<TrialSpecSpendingCap_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<TrialSpecSpendingCap_Max_Fields>;
  min: Maybe<TrialSpecSpendingCap_Min_Fields>;
  stddev: Maybe<TrialSpecSpendingCap_Stddev_Fields>;
  stddev_pop: Maybe<TrialSpecSpendingCap_Stddev_Pop_Fields>;
  stddev_samp: Maybe<TrialSpecSpendingCap_Stddev_Samp_Fields>;
  sum: Maybe<TrialSpecSpendingCap_Sum_Fields>;
  var_pop: Maybe<TrialSpecSpendingCap_Var_Pop_Fields>;
  var_samp: Maybe<TrialSpecSpendingCap_Var_Samp_Fields>;
  variance: Maybe<TrialSpecSpendingCap_Variance_Fields>;
};


/** aggregate fields of "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TrialSpecSpendingCap_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Aggregate_Order_By = {
  avg?: InputMaybe<TrialSpecSpendingCap_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TrialSpecSpendingCap_Max_Order_By>;
  min?: InputMaybe<TrialSpecSpendingCap_Min_Order_By>;
  stddev?: InputMaybe<TrialSpecSpendingCap_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TrialSpecSpendingCap_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TrialSpecSpendingCap_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TrialSpecSpendingCap_Sum_Order_By>;
  var_pop?: InputMaybe<TrialSpecSpendingCap_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TrialSpecSpendingCap_Var_Samp_Order_By>;
  variance?: InputMaybe<TrialSpecSpendingCap_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Arr_Rel_Insert_Input = {
  data: Array<TrialSpecSpendingCap_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TrialSpecSpendingCap_On_Conflict>;
};

/** aggregate avg on columns */
export type TrialSpecSpendingCap_Avg_Fields = {
  __typename?: 'TrialSpecSpendingCap_avg_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TrialSpecSpendingCap". All fields are combined with a logical 'AND'. */
export type TrialSpecSpendingCap_Bool_Exp = {
  CreditType?: InputMaybe<CreditType_Bool_Exp>;
  TrialSpec?: InputMaybe<TrialSpec_Bool_Exp>;
  _and?: InputMaybe<Array<TrialSpecSpendingCap_Bool_Exp>>;
  _not?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
  _or?: InputMaybe<Array<TrialSpecSpendingCap_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  credit_type_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  trial_spec_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "TrialSpecSpendingCap" */
export enum TrialSpecSpendingCap_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrialSpecSpendingCapPkey = 'TrialSpecSpendingCap_pkey',
  /** unique or primary key constraint on columns "credit_type_id", "trial_spec_id" */
  TrialSpecSpendingCapTrialSpecIdCreditTypeIdKey = 'TrialSpecSpendingCap_trial_spec_id_credit_type_id_key'
}

/** input type for incrementing numeric columns in table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Insert_Input = {
  CreditType?: InputMaybe<CreditType_Obj_Rel_Insert_Input>;
  TrialSpec?: InputMaybe<TrialSpec_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['numeric']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TrialSpecSpendingCap_Max_Fields = {
  __typename?: 'TrialSpecSpendingCap_max_fields';
  amount: Maybe<Scalars['numeric']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TrialSpecSpendingCap_Min_Fields = {
  __typename?: 'TrialSpecSpendingCap_min_fields';
  amount: Maybe<Scalars['numeric']>;
  credit_type_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  trial_spec_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Mutation_Response = {
  __typename?: 'TrialSpecSpendingCap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TrialSpecSpendingCap>;
};

/** on_conflict condition type for table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_On_Conflict = {
  constraint: TrialSpecSpendingCap_Constraint;
  update_columns?: Array<TrialSpecSpendingCap_Update_Column>;
  where?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
};

/** Ordering options when selecting data from "TrialSpecSpendingCap". */
export type TrialSpecSpendingCap_Order_By = {
  CreditType?: InputMaybe<CreditType_Order_By>;
  TrialSpec?: InputMaybe<TrialSpec_Order_By>;
  amount?: InputMaybe<Order_By>;
  credit_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  trial_spec_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TrialSpecSpendingCap */
export type TrialSpecSpendingCap_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TrialSpecSpendingCap" */
export enum TrialSpecSpendingCap_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrialSpecId = 'trial_spec_id'
}

/** input type for updating data in table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type TrialSpecSpendingCap_Stddev_Fields = {
  __typename?: 'TrialSpecSpendingCap_stddev_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TrialSpecSpendingCap_Stddev_Pop_Fields = {
  __typename?: 'TrialSpecSpendingCap_stddev_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TrialSpecSpendingCap_Stddev_Samp_Fields = {
  __typename?: 'TrialSpecSpendingCap_stddev_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TrialSpecSpendingCap_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TrialSpecSpendingCap_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  credit_type_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  trial_spec_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type TrialSpecSpendingCap_Sum_Fields = {
  __typename?: 'TrialSpecSpendingCap_sum_fields';
  amount: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "TrialSpecSpendingCap" */
export enum TrialSpecSpendingCap_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreditTypeId = 'credit_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  TrialSpecId = 'trial_spec_id'
}

export type TrialSpecSpendingCap_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TrialSpecSpendingCap_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TrialSpecSpendingCap_Set_Input>;
  /** filter the rows which have to be updated */
  where: TrialSpecSpendingCap_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TrialSpecSpendingCap_Var_Pop_Fields = {
  __typename?: 'TrialSpecSpendingCap_var_pop_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TrialSpecSpendingCap_Var_Samp_Fields = {
  __typename?: 'TrialSpecSpendingCap_var_samp_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TrialSpecSpendingCap_Variance_Fields = {
  __typename?: 'TrialSpecSpendingCap_variance_fields';
  amount: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TrialSpecSpendingCap" */
export type TrialSpecSpendingCap_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregated selection of "TrialSpec" */
export type TrialSpec_Aggregate = {
  __typename?: 'TrialSpec_aggregate';
  aggregate: Maybe<TrialSpec_Aggregate_Fields>;
  nodes: Array<TrialSpec>;
};

/** aggregate fields of "TrialSpec" */
export type TrialSpec_Aggregate_Fields = {
  __typename?: 'TrialSpec_aggregate_fields';
  avg: Maybe<TrialSpec_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<TrialSpec_Max_Fields>;
  min: Maybe<TrialSpec_Min_Fields>;
  stddev: Maybe<TrialSpec_Stddev_Fields>;
  stddev_pop: Maybe<TrialSpec_Stddev_Pop_Fields>;
  stddev_samp: Maybe<TrialSpec_Stddev_Samp_Fields>;
  sum: Maybe<TrialSpec_Sum_Fields>;
  var_pop: Maybe<TrialSpec_Var_Pop_Fields>;
  var_samp: Maybe<TrialSpec_Var_Samp_Fields>;
  variance: Maybe<TrialSpec_Variance_Fields>;
};


/** aggregate fields of "TrialSpec" */
export type TrialSpec_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TrialSpec_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TrialSpec_Avg_Fields = {
  __typename?: 'TrialSpec_avg_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TrialSpec". All fields are combined with a logical 'AND'. */
export type TrialSpec_Bool_Exp = {
  CustomerPlans?: InputMaybe<CustomerPlan_Bool_Exp>;
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Bool_Exp>;
  Plans?: InputMaybe<Plan_Bool_Exp>;
  Plans_aggregate?: InputMaybe<Plan_Aggregate_Bool_Exp>;
  TrialSpecSpendingCaps?: InputMaybe<TrialSpecSpendingCap_Bool_Exp>;
  TrialSpecSpendingCaps_aggregate?: InputMaybe<TrialSpecSpendingCap_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<TrialSpec_Bool_Exp>>;
  _not?: InputMaybe<TrialSpec_Bool_Exp>;
  _or?: InputMaybe<Array<TrialSpec_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  length_in_days?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "TrialSpec" */
export enum TrialSpec_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrialSpecPkey = 'TrialSpec_pkey'
}

/** input type for incrementing numeric columns in table "TrialSpec" */
export type TrialSpec_Inc_Input = {
  length_in_days?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "TrialSpec" */
export type TrialSpec_Insert_Input = {
  CustomerPlans?: InputMaybe<CustomerPlan_Arr_Rel_Insert_Input>;
  Plans?: InputMaybe<Plan_Arr_Rel_Insert_Input>;
  TrialSpecSpendingCaps?: InputMaybe<TrialSpecSpendingCap_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  length_in_days?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type TrialSpec_Max_Fields = {
  __typename?: 'TrialSpec_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  length_in_days: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type TrialSpec_Min_Fields = {
  __typename?: 'TrialSpec_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  length_in_days: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "TrialSpec" */
export type TrialSpec_Mutation_Response = {
  __typename?: 'TrialSpec_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TrialSpec>;
};

/** input type for inserting object relation for remote table "TrialSpec" */
export type TrialSpec_Obj_Rel_Insert_Input = {
  data: TrialSpec_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TrialSpec_On_Conflict>;
};

/** on_conflict condition type for table "TrialSpec" */
export type TrialSpec_On_Conflict = {
  constraint: TrialSpec_Constraint;
  update_columns?: Array<TrialSpec_Update_Column>;
  where?: InputMaybe<TrialSpec_Bool_Exp>;
};

/** Ordering options when selecting data from "TrialSpec". */
export type TrialSpec_Order_By = {
  CustomerPlans_aggregate?: InputMaybe<CustomerPlan_Aggregate_Order_By>;
  Plans_aggregate?: InputMaybe<Plan_Aggregate_Order_By>;
  TrialSpecSpendingCaps_aggregate?: InputMaybe<TrialSpecSpendingCap_Aggregate_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_days?: InputMaybe<Order_By>;
};

/** primary key columns input for table: TrialSpec */
export type TrialSpec_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TrialSpec" */
export enum TrialSpec_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  LengthInDays = 'length_in_days'
}

/** input type for updating data in table "TrialSpec" */
export type TrialSpec_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  length_in_days?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type TrialSpec_Stddev_Fields = {
  __typename?: 'TrialSpec_stddev_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TrialSpec_Stddev_Pop_Fields = {
  __typename?: 'TrialSpec_stddev_pop_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TrialSpec_Stddev_Samp_Fields = {
  __typename?: 'TrialSpec_stddev_samp_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "TrialSpec" */
export type TrialSpec_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TrialSpec_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TrialSpec_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  length_in_days?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type TrialSpec_Sum_Fields = {
  __typename?: 'TrialSpec_sum_fields';
  length_in_days: Maybe<Scalars['numeric']>;
};

/** update columns of table "TrialSpec" */
export enum TrialSpec_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  LengthInDays = 'length_in_days'
}

export type TrialSpec_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TrialSpec_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TrialSpec_Set_Input>;
  /** filter the rows which have to be updated */
  where: TrialSpec_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TrialSpec_Var_Pop_Fields = {
  __typename?: 'TrialSpec_var_pop_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TrialSpec_Var_Samp_Fields = {
  __typename?: 'TrialSpec_var_samp_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TrialSpec_Variance_Fields = {
  __typename?: 'TrialSpec_variance_fields';
  length_in_days: Maybe<Scalars['Float']>;
};

export type UnpaidInvoiceIdentifier = {
  __typename?: 'UnpaidInvoiceIdentifier';
  billing_provider_configuration: Maybe<Scalars['FlatKeyValueMap']>;
  billing_provider_customer_id: Maybe<Scalars['String']>;
  credit_type_id: Scalars['uuid'];
  customer_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

export type UpdateBillingProviderSettingsInput = {
  stripe?: InputMaybe<UpdateStripeSettingsInput>;
};

export type UpdateCommitAccessScheduleInput = {
  commit_id: Scalars['uuid'];
  input: CommitAccessScheduleInput;
};

export type UpdateCommitInvoiceScheduleInput = {
  commit_id: Scalars['uuid'];
  input: CommitInvoiceScheduleInput;
};

export type UpdateCommitNetsuiteSalesOrderIdInput = {
  commit_id: Scalars['uuid'];
  netsuite_sales_order_id: Scalars['String'];
};

export type UpdateCommitRolloverFractionInput = {
  commit_id: Scalars['uuid'];
  commit_rollover_fraction: Scalars['numeric'];
};

export type UpdateCommitScheduleImpactedInvoice = {
  __typename?: 'UpdateCommitScheduleImpactedInvoice';
  contract_id: Maybe<Scalars['uuid']>;
  invoice_id: Scalars['uuid'];
  update: Scalars['String'];
  update_index: Scalars['Int'];
};

export type UpdateCommitScheduleInvoiceItemPreview = {
  __typename?: 'UpdateCommitScheduleInvoiceItemPreview';
  amount: Scalars['CreditAmount'];
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invoice_id: Scalars['uuid'];
  quantity: Scalars['CreditAmount'];
  unit_price: Scalars['CreditAmount'];
};

export type UpdateCommitScheduleMutationResponse = {
  __typename?: 'UpdateCommitScheduleMutationResponse';
  access_schedule_items: Maybe<Array<UpdateCommitScheduleSegmentItemPreview>>;
  finalized_postpaid_trueup_invoices: Maybe<Array<UpdateCommitScheduleImpactedInvoice>>;
  finalized_scheduled_invoices: Maybe<Array<UpdateCommitScheduleImpactedInvoice>>;
  finalized_usage_invoices: Maybe<Array<UpdateCommitScheduleImpactedInvoice>>;
  invoice_schedule_items: Maybe<Array<UpdateCommitScheduleInvoiceItemPreview>>;
  success: Scalars['Boolean'];
};

export type UpdateCommitScheduleSegmentItemPreview = {
  __typename?: 'UpdateCommitScheduleSegmentItemPreview';
  amount: Scalars['numeric'];
  date: Scalars['timestamptz'];
  end_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

export type UpdateOrUndefined = {
  is_undefined: Scalars['Boolean'];
  value: Maybe<UpdateOrUndefinedValue>;
};

export type UpdateOrUndefinedValue = QuantityConversion | QuantityRounding;

export type UpdateScheduledChargeNetsuiteSalesOrderIdInput = {
  netsuite_sales_order_id: Scalars['String'];
  scheduled_charge_id: Scalars['uuid'];
};

export type UpdateScheduledChargeScheduleInput = {
  input: ScheduledChargeScheduleInput;
  scheduled_charge_id: Scalars['uuid'];
};

export type UpdateStripeSettingsInput = {
  anrok_api_key?: InputMaybe<Scalars['String']>;
  stripe_api_key?: InputMaybe<Scalars['String']>;
};

export type UpsertBillingProviderInvoiceInput = {
  billing_provider: BillingProviderEnum_Enum;
  billing_provider_error?: InputMaybe<Scalars['String']>;
  billing_provider_invoice_id?: InputMaybe<Scalars['String']>;
  billing_provider_sales_order_id?: InputMaybe<Scalars['String']>;
  external_status: InvoiceExternalStatusEnum_Enum;
  invoice_id: Scalars['String'];
  issued_at?: InputMaybe<Scalars['String']>;
  tax_applied_status?: InputMaybe<TaxAppliedStatusEnum_Enum>;
  workato_job_id?: InputMaybe<Scalars['String']>;
};

export type UsageBreakdownData = {
  __typename?: 'UsageBreakdownData';
  ending_before: Scalars['timestamptz'];
  groups: Maybe<Scalars['RecordOfStrings']>;
  starting_on: Scalars['timestamptz'];
  value: Maybe<Scalars['String']>;
};

export type UsageBreakdownResponse = {
  __typename?: 'UsageBreakdownResponse';
  data: Array<UsageBreakdownData>;
  next_page: Maybe<Scalars['String']>;
};

export type UsageEventMutationResponse = {
  __typename?: 'UsageEventMutationResponse';
  error: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  transaction_id: Maybe<Scalars['uuid']>;
};

export type UsageFilter = {
  __typename?: 'UsageFilter';
  current: Maybe<InitialUsageFilter>;
  initial: Maybe<InitialUsageFilter>;
  updates: Array<UsageFilterUpdate>;
};

export type UsageFilterUpdate = BaseUsageFilter & {
  __typename?: 'UsageFilterUpdate';
  Creator: Actor;
  created_at: Scalars['timestamptz'];
  effective_at: Scalars['timestamptz'];
  group_key: Scalars['String'];
  group_values: Array<Scalars['String']>;
  id: Scalars['String'];
};

export type UsageGroup = {
  __typename?: 'UsageGroup';
  group_value: Scalars['String'];
  metric_value: Maybe<Scalars['numeric']>;
};

export type UsageGroupByInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type UsageProductListItem = {
  __typename?: 'UsageProductListItem';
  archived_at: Maybe<Scalars['timestamptz']>;
  current: UsageProductListItemState;
  id: Scalars['uuid'];
  initial: UsageProductListItemState;
  managed_fields: Array<ContractProductManagedField>;
  updates: Array<ProductListItemUpdate>;
};

export type UsageProductListItemState = {
  __typename?: 'UsageProductListItemState';
  Creator: Actor;
  billable_metric: BillableMetric;
  created_at: Scalars['timestamptz'];
  effective_at: Maybe<Scalars['timestamptz']>;
  is_refundable: Scalars['Boolean'];
  name: Scalars['String'];
  netsuite_internal_item_id: Maybe<Scalars['String']>;
  netsuite_overage_item_id: Maybe<Scalars['String']>;
  presentation_group_key: Maybe<Array<Scalars['String']>>;
  pricing_group_key: Maybe<Array<Scalars['String']>>;
  quantity_conversion: Maybe<QuantityConversion>;
  quantity_rounding: Maybe<QuantityRounding>;
  tags: Array<Scalars['String']>;
};

export type UsageWindow = {
  __typename?: 'UsageWindow';
  end: Scalars['timestamptz'];
  groups: Maybe<Array<UsageGroup>>;
  metric_value: Maybe<Scalars['numeric']>;
  start: Scalars['timestamptz'];
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  auth_type: UserAuthTypeEnum_Enum;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deprecated_at: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  role: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "UserAuthTypeEnum" */
export type UserAuthTypeEnum = {
  __typename?: 'UserAuthTypeEnum';
  value: Scalars['String'];
};

/** aggregated selection of "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Aggregate = {
  __typename?: 'UserAuthTypeEnum_aggregate';
  aggregate: Maybe<UserAuthTypeEnum_Aggregate_Fields>;
  nodes: Array<UserAuthTypeEnum>;
};

/** aggregate fields of "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Aggregate_Fields = {
  __typename?: 'UserAuthTypeEnum_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<UserAuthTypeEnum_Max_Fields>;
  min: Maybe<UserAuthTypeEnum_Min_Fields>;
};


/** aggregate fields of "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserAuthTypeEnum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "UserAuthTypeEnum". All fields are combined with a logical 'AND'. */
export type UserAuthTypeEnum_Bool_Exp = {
  _and?: InputMaybe<Array<UserAuthTypeEnum_Bool_Exp>>;
  _not?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
  _or?: InputMaybe<Array<UserAuthTypeEnum_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserAuthTypeEnum" */
export enum UserAuthTypeEnum_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserAuthTypeEnumPkey = 'UserAuthTypeEnum_pkey'
}

export enum UserAuthTypeEnum_Enum {
  Password = 'PASSWORD',
  Saml = 'SAML'
}

/** Boolean expression to compare columns of type "UserAuthTypeEnum_enum". All fields are combined with logical 'AND'. */
export type UserAuthTypeEnum_Enum_Comparison_Exp = {
  _eq?: InputMaybe<UserAuthTypeEnum_Enum>;
  _in?: InputMaybe<Array<UserAuthTypeEnum_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<UserAuthTypeEnum_Enum>;
  _nin?: InputMaybe<Array<UserAuthTypeEnum_Enum>>;
};

/** input type for inserting data into table "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserAuthTypeEnum_Max_Fields = {
  __typename?: 'UserAuthTypeEnum_max_fields';
  value: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserAuthTypeEnum_Min_Fields = {
  __typename?: 'UserAuthTypeEnum_min_fields';
  value: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Mutation_Response = {
  __typename?: 'UserAuthTypeEnum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAuthTypeEnum>;
};

/** on_conflict condition type for table "UserAuthTypeEnum" */
export type UserAuthTypeEnum_On_Conflict = {
  constraint: UserAuthTypeEnum_Constraint;
  update_columns?: Array<UserAuthTypeEnum_Update_Column>;
  where?: InputMaybe<UserAuthTypeEnum_Bool_Exp>;
};

/** Ordering options when selecting data from "UserAuthTypeEnum". */
export type UserAuthTypeEnum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserAuthTypeEnum */
export type UserAuthTypeEnum_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "UserAuthTypeEnum" */
export enum UserAuthTypeEnum_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "UserAuthTypeEnum" */
export type UserAuthTypeEnum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: UserAuthTypeEnum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserAuthTypeEnum_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "UserAuthTypeEnum" */
export enum UserAuthTypeEnum_Update_Column {
  /** column name */
  Value = 'value'
}

export type UserAuthTypeEnum_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserAuthTypeEnum_Set_Input>;
  /** filter the rows which have to be updated */
  where: UserAuthTypeEnum_Bool_Exp;
};

export type UserSortingInput = {
  ascending: Scalars['Boolean'];
  order_by: UserSortingOrderByEnum;
};

export enum UserSortingOrderByEnum {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  Name = 'NAME',
  Role = 'ROLE'
}

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: 'User_aggregate';
  aggregate: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: 'User_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<User_Max_Fields>;
  min: Maybe<User_Min_Fields>;
};


/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "User" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "User" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  auth_type?: InputMaybe<UserAuthTypeEnum_Enum_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deprecated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'User_pkey'
}

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  auth_type?: InputMaybe<UserAuthTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'User_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "User" */
export type User_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'User_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  deprecated_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  role_id: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "User" */
export type User_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: 'User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** on_conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "User". */
export type User_Order_By = {
  auth_type?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deprecated_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: User */
export type User_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "User" */
export enum User_Select_Column {
  /** column name */
  AuthType = 'auth_type',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "User" */
export type User_Set_Input = {
  auth_type?: InputMaybe<UserAuthTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "User" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  auth_type?: InputMaybe<UserAuthTypeEnum_Enum>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deprecated_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "User" */
export enum User_Update_Column {
  /** column name */
  AuthType = 'auth_type',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeprecatedAt = 'deprecated_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'role_id'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

export type UuidFilter = {
  exclude?: InputMaybe<Array<Scalars['uuid']>>;
  include?: InputMaybe<Array<Scalars['uuid']>>;
};

export type UuidRange = {
  gte?: InputMaybe<Scalars['uuid']>;
  lt?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "Webhook" */
export type Webhook = {
  __typename?: 'Webhook';
  /** An object relationship */
  Client: Client;
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  /** An object relationship */
  creator: Maybe<Actor>;
  encrypted_secret: Maybe<Scalars['String']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  url: Scalars['String'];
};

/** This table handles a huge volume of writes-- please be very careful with any indices or other things that can incur additional write load */
export type WebhookNotification = {
  __typename?: 'WebhookNotification';
  attempts: Scalars['numeric'];
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  customer_id: Maybe<Scalars['uuid']>;
  environment_type: EnvironmentTypeEnum_Enum;
  id: Scalars['uuid'];
  notification_id: Scalars['uuid'];
  notification_type: Scalars['String'];
  properties: Scalars['jsonb'];
  success: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  webhook_id: Scalars['String'];
};


/** This table handles a huge volume of writes-- please be very careful with any indices or other things that can incur additional write load */
export type WebhookNotificationPropertiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "webhook_notifications" */
export type WebhookNotification_Aggregate = {
  __typename?: 'WebhookNotification_aggregate';
  aggregate: Maybe<WebhookNotification_Aggregate_Fields>;
  nodes: Array<WebhookNotification>;
};

/** aggregate fields of "webhook_notifications" */
export type WebhookNotification_Aggregate_Fields = {
  __typename?: 'WebhookNotification_aggregate_fields';
  avg: Maybe<WebhookNotification_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<WebhookNotification_Max_Fields>;
  min: Maybe<WebhookNotification_Min_Fields>;
  stddev: Maybe<WebhookNotification_Stddev_Fields>;
  stddev_pop: Maybe<WebhookNotification_Stddev_Pop_Fields>;
  stddev_samp: Maybe<WebhookNotification_Stddev_Samp_Fields>;
  sum: Maybe<WebhookNotification_Sum_Fields>;
  var_pop: Maybe<WebhookNotification_Var_Pop_Fields>;
  var_samp: Maybe<WebhookNotification_Var_Samp_Fields>;
  variance: Maybe<WebhookNotification_Variance_Fields>;
};


/** aggregate fields of "webhook_notifications" */
export type WebhookNotification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WebhookNotification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WebhookNotification_Append_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type WebhookNotification_Avg_Fields = {
  __typename?: 'WebhookNotification_avg_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "webhook_notifications". All fields are combined with a logical 'AND'. */
export type WebhookNotification_Bool_Exp = {
  _and?: InputMaybe<Array<WebhookNotification_Bool_Exp>>;
  _not?: InputMaybe<WebhookNotification_Bool_Exp>;
  _or?: InputMaybe<Array<WebhookNotification_Bool_Exp>>;
  attempts?: InputMaybe<Numeric_Comparison_Exp>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_type?: InputMaybe<String_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  success?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  webhook_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "webhook_notifications" */
export enum WebhookNotification_Constraint {
  /** unique or primary key constraint on columns "webhook_id", "notification_id" */
  WebhookNotificationsNotificationIdWebhookId = 'webhook_notifications_notification_id_webhook_id',
  /** unique or primary key constraint on columns "id" */
  WebhookNotificationsPkey = 'webhook_notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WebhookNotification_Delete_At_Path_Input = {
  properties?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WebhookNotification_Delete_Elem_Input = {
  properties?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WebhookNotification_Delete_Key_Input = {
  properties?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "webhook_notifications" */
export type WebhookNotification_Inc_Input = {
  attempts?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "webhook_notifications" */
export type WebhookNotification_Insert_Input = {
  attempts?: InputMaybe<Scalars['numeric']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  notification_type?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  success?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WebhookNotification_Max_Fields = {
  __typename?: 'WebhookNotification_max_fields';
  attempts: Maybe<Scalars['numeric']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  notification_id: Maybe<Scalars['uuid']>;
  notification_type: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  webhook_id: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WebhookNotification_Min_Fields = {
  __typename?: 'WebhookNotification_min_fields';
  attempts: Maybe<Scalars['numeric']>;
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  notification_id: Maybe<Scalars['uuid']>;
  notification_type: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  webhook_id: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "webhook_notifications" */
export type WebhookNotification_Mutation_Response = {
  __typename?: 'WebhookNotification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WebhookNotification>;
};

/** on_conflict condition type for table "webhook_notifications" */
export type WebhookNotification_On_Conflict = {
  constraint: WebhookNotification_Constraint;
  update_columns?: Array<WebhookNotification_Update_Column>;
  where?: InputMaybe<WebhookNotification_Bool_Exp>;
};

/** Ordering options when selecting data from "webhook_notifications". */
export type WebhookNotification_Order_By = {
  attempts?: InputMaybe<Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  notification_type?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  success?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhook_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: webhook_notifications */
export type WebhookNotification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WebhookNotification_Prepend_Input = {
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "webhook_notifications" */
export enum WebhookNotification_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Properties = 'properties',
  /** column name */
  Success = 'success',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookId = 'webhook_id'
}

/** input type for updating data in table "webhook_notifications" */
export type WebhookNotification_Set_Input = {
  attempts?: InputMaybe<Scalars['numeric']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  notification_type?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  success?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type WebhookNotification_Stddev_Fields = {
  __typename?: 'WebhookNotification_stddev_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WebhookNotification_Stddev_Pop_Fields = {
  __typename?: 'WebhookNotification_stddev_pop_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WebhookNotification_Stddev_Samp_Fields = {
  __typename?: 'WebhookNotification_stddev_samp_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "WebhookNotification" */
export type WebhookNotification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WebhookNotification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WebhookNotification_Stream_Cursor_Value_Input = {
  attempts?: InputMaybe<Scalars['numeric']>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  notification_type?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  success?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type WebhookNotification_Sum_Fields = {
  __typename?: 'WebhookNotification_sum_fields';
  attempts: Maybe<Scalars['numeric']>;
};

/** update columns of table "webhook_notifications" */
export enum WebhookNotification_Update_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  Properties = 'properties',
  /** column name */
  Success = 'success',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookId = 'webhook_id'
}

export type WebhookNotification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WebhookNotification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WebhookNotification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WebhookNotification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WebhookNotification_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WebhookNotification_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WebhookNotification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WebhookNotification_Set_Input>;
  /** filter the rows which have to be updated */
  where: WebhookNotification_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WebhookNotification_Var_Pop_Fields = {
  __typename?: 'WebhookNotification_var_pop_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WebhookNotification_Var_Samp_Fields = {
  __typename?: 'WebhookNotification_var_samp_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WebhookNotification_Variance_Fields = {
  __typename?: 'WebhookNotification_variance_fields';
  attempts: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Webhook" */
export type Webhook_Aggregate = {
  __typename?: 'Webhook_aggregate';
  aggregate: Maybe<Webhook_Aggregate_Fields>;
  nodes: Array<Webhook>;
};

/** aggregate fields of "Webhook" */
export type Webhook_Aggregate_Fields = {
  __typename?: 'Webhook_aggregate_fields';
  count: Scalars['Int'];
  max: Maybe<Webhook_Max_Fields>;
  min: Maybe<Webhook_Min_Fields>;
};


/** aggregate fields of "Webhook" */
export type Webhook_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webhook_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Webhook". All fields are combined with a logical 'AND'. */
export type Webhook_Bool_Exp = {
  Client?: InputMaybe<Client_Bool_Exp>;
  _and?: InputMaybe<Array<Webhook_Bool_Exp>>;
  _not?: InputMaybe<Webhook_Bool_Exp>;
  _or?: InputMaybe<Array<Webhook_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  creator?: InputMaybe<Actor_Bool_Exp>;
  encrypted_secret?: InputMaybe<String_Comparison_Exp>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Webhook" */
export enum Webhook_Constraint {
  /** unique or primary key constraint on columns "id" */
  WebhookPkey = 'Webhook_pkey'
}

/** input type for inserting data into table "Webhook" */
export type Webhook_Insert_Input = {
  Client?: InputMaybe<Client_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  creator?: InputMaybe<Actor_Obj_Rel_Insert_Input>;
  encrypted_secret?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Webhook_Max_Fields = {
  __typename?: 'Webhook_max_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  encrypted_secret: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Webhook_Min_Fields = {
  __typename?: 'Webhook_min_fields';
  client_id: Maybe<Scalars['uuid']>;
  created_at: Maybe<Scalars['timestamptz']>;
  created_by: Maybe<Scalars['String']>;
  encrypted_secret: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Webhook" */
export type Webhook_Mutation_Response = {
  __typename?: 'Webhook_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webhook>;
};

/** on_conflict condition type for table "Webhook" */
export type Webhook_On_Conflict = {
  constraint: Webhook_Constraint;
  update_columns?: Array<Webhook_Update_Column>;
  where?: InputMaybe<Webhook_Bool_Exp>;
};

/** Ordering options when selecting data from "Webhook". */
export type Webhook_Order_By = {
  Client?: InputMaybe<Client_Order_By>;
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  creator?: InputMaybe<Actor_Order_By>;
  encrypted_secret?: InputMaybe<Order_By>;
  environment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Webhook */
export type Webhook_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Webhook" */
export enum Webhook_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EncryptedSecret = 'encrypted_secret',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "Webhook" */
export type Webhook_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  encrypted_secret?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Webhook" */
export type Webhook_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Webhook_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Webhook_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  encrypted_secret?: InputMaybe<Scalars['String']>;
  environment_type?: InputMaybe<EnvironmentTypeEnum_Enum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Webhook" */
export enum Webhook_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EncryptedSecret = 'encrypted_secret',
  /** column name */
  EnvironmentType = 'environment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

export type Webhook_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Webhook_Set_Input>;
  /** filter the rows which have to be updated */
  where: Webhook_Bool_Exp;
};

export enum WindowSize {
  Day = 'Day',
  Hour = 'Hour',
  None = 'None'
}

export type WorkatoSsoUrlResponse = {
  __typename?: 'WorkatoSSOUrlResponse';
  sso_url: Scalars['String'];
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  APITokenResponse: ResolverTypeWrapper<ApiTokenResponse>;
  AWSProServiceRoyalty: ResolverTypeWrapper<AwsProServiceRoyalty>;
  AWSProServiceRoyaltyUpdate: ResolverTypeWrapper<AwsProServiceRoyaltyUpdate>;
  AWSRoyalty: ResolverTypeWrapper<AwsRoyalty>;
  AWSRoyaltyUpdate: ResolverTypeWrapper<AwsRoyaltyUpdate>;
  AccountTypeEnum: ResolverTypeWrapper<AccountTypeEnum>;
  AccountTypeEnum_aggregate: ResolverTypeWrapper<AccountTypeEnum_Aggregate>;
  AccountTypeEnum_aggregate_fields: ResolverTypeWrapper<AccountTypeEnum_Aggregate_Fields>;
  AccountTypeEnum_bool_exp: AccountTypeEnum_Bool_Exp;
  AccountTypeEnum_constraint: AccountTypeEnum_Constraint;
  AccountTypeEnum_enum: AccountTypeEnum_Enum;
  AccountTypeEnum_enum_comparison_exp: AccountTypeEnum_Enum_Comparison_Exp;
  AccountTypeEnum_insert_input: AccountTypeEnum_Insert_Input;
  AccountTypeEnum_max_fields: ResolverTypeWrapper<AccountTypeEnum_Max_Fields>;
  AccountTypeEnum_min_fields: ResolverTypeWrapper<AccountTypeEnum_Min_Fields>;
  AccountTypeEnum_mutation_response: ResolverTypeWrapper<AccountTypeEnum_Mutation_Response>;
  AccountTypeEnum_on_conflict: AccountTypeEnum_On_Conflict;
  AccountTypeEnum_order_by: AccountTypeEnum_Order_By;
  AccountTypeEnum_pk_columns_input: AccountTypeEnum_Pk_Columns_Input;
  AccountTypeEnum_select_column: AccountTypeEnum_Select_Column;
  AccountTypeEnum_set_input: AccountTypeEnum_Set_Input;
  AccountTypeEnum_stream_cursor_input: AccountTypeEnum_Stream_Cursor_Input;
  AccountTypeEnum_stream_cursor_value_input: AccountTypeEnum_Stream_Cursor_Value_Input;
  AccountTypeEnum_update_column: AccountTypeEnum_Update_Column;
  AccountTypeEnum_updates: AccountTypeEnum_Updates;
  Actor: ResolverTypeWrapper<Actor>;
  ActorOrUser: ResolversTypes['Actor'] | ResolversTypes['User'];
  Actor_aggregate: ResolverTypeWrapper<Actor_Aggregate>;
  Actor_aggregate_fields: ResolverTypeWrapper<Actor_Aggregate_Fields>;
  Actor_bool_exp: Actor_Bool_Exp;
  Actor_constraint: Actor_Constraint;
  Actor_insert_input: Actor_Insert_Input;
  Actor_max_fields: ResolverTypeWrapper<Actor_Max_Fields>;
  Actor_min_fields: ResolverTypeWrapper<Actor_Min_Fields>;
  Actor_mutation_response: ResolverTypeWrapper<Actor_Mutation_Response>;
  Actor_obj_rel_insert_input: Actor_Obj_Rel_Insert_Input;
  Actor_on_conflict: Actor_On_Conflict;
  Actor_order_by: Actor_Order_By;
  Actor_pk_columns_input: Actor_Pk_Columns_Input;
  Actor_select_column: Actor_Select_Column;
  Actor_set_input: Actor_Set_Input;
  Actor_stream_cursor_input: Actor_Stream_Cursor_Input;
  Actor_stream_cursor_value_input: Actor_Stream_Cursor_Value_Input;
  Actor_update_column: Actor_Update_Column;
  Actor_updates: Actor_Updates;
  AdHocCreditLineItemInput: AdHocCreditLineItemInput;
  AdHocPlanInvoice: ResolverTypeWrapper<Omit<AdHocPlanInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  AdHocPlanInvoiceInput: AdHocPlanInvoiceInput;
  AdHocUsageItemInput: AdHocUsageItemInput;
  AddMarketplaceToCustomerMutationResponse: ResolverTypeWrapper<AddMarketplaceToCustomerMutationResponse>;
  AdhocContractUsageInvoice: ResolverTypeWrapper<Omit<AdhocContractUsageInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  AdhocContractUsageInvoiceInput: AdhocContractUsageInvoiceInput;
  AdhocContractUsageLineItemInput: AdhocContractUsageLineItemInput;
  AdvanceChargeLineItem: ResolverTypeWrapper<Omit<AdvanceChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversTypes['ChargeSubLineItem']>> }>;
  AdvanceInvoice: ResolverTypeWrapper<Omit<AdvanceInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  Alert: ResolverTypeWrapper<Alert>;
  AlertManagedField: ResolverTypeWrapper<AlertManagedField>;
  AlertManagedField_aggregate: ResolverTypeWrapper<AlertManagedField_Aggregate>;
  AlertManagedField_aggregate_bool_exp: AlertManagedField_Aggregate_Bool_Exp;
  AlertManagedField_aggregate_bool_exp_bool_and: AlertManagedField_Aggregate_Bool_Exp_Bool_And;
  AlertManagedField_aggregate_bool_exp_bool_or: AlertManagedField_Aggregate_Bool_Exp_Bool_Or;
  AlertManagedField_aggregate_bool_exp_count: AlertManagedField_Aggregate_Bool_Exp_Count;
  AlertManagedField_aggregate_fields: ResolverTypeWrapper<AlertManagedField_Aggregate_Fields>;
  AlertManagedField_aggregate_order_by: AlertManagedField_Aggregate_Order_By;
  AlertManagedField_arr_rel_insert_input: AlertManagedField_Arr_Rel_Insert_Input;
  AlertManagedField_bool_exp: AlertManagedField_Bool_Exp;
  AlertManagedField_constraint: AlertManagedField_Constraint;
  AlertManagedField_insert_input: AlertManagedField_Insert_Input;
  AlertManagedField_max_fields: ResolverTypeWrapper<AlertManagedField_Max_Fields>;
  AlertManagedField_max_order_by: AlertManagedField_Max_Order_By;
  AlertManagedField_min_fields: ResolverTypeWrapper<AlertManagedField_Min_Fields>;
  AlertManagedField_min_order_by: AlertManagedField_Min_Order_By;
  AlertManagedField_mutation_response: ResolverTypeWrapper<AlertManagedField_Mutation_Response>;
  AlertManagedField_on_conflict: AlertManagedField_On_Conflict;
  AlertManagedField_order_by: AlertManagedField_Order_By;
  AlertManagedField_pk_columns_input: AlertManagedField_Pk_Columns_Input;
  AlertManagedField_select_column: AlertManagedField_Select_Column;
  AlertManagedField_select_column_AlertManagedField_aggregate_bool_exp_bool_and_arguments_columns: AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  AlertManagedField_select_column_AlertManagedField_aggregate_bool_exp_bool_or_arguments_columns: AlertManagedField_Select_Column_AlertManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  AlertManagedField_set_input: AlertManagedField_Set_Input;
  AlertManagedField_stream_cursor_input: AlertManagedField_Stream_Cursor_Input;
  AlertManagedField_stream_cursor_value_input: AlertManagedField_Stream_Cursor_Value_Input;
  AlertManagedField_update_column: AlertManagedField_Update_Column;
  AlertManagedField_updates: AlertManagedField_Updates;
  AlertStatusEnum: AlertStatusEnum;
  AlertTypeEnum: ResolverTypeWrapper<AlertTypeEnum>;
  AlertTypeEnum_aggregate: ResolverTypeWrapper<AlertTypeEnum_Aggregate>;
  AlertTypeEnum_aggregate_fields: ResolverTypeWrapper<AlertTypeEnum_Aggregate_Fields>;
  AlertTypeEnum_bool_exp: AlertTypeEnum_Bool_Exp;
  AlertTypeEnum_constraint: AlertTypeEnum_Constraint;
  AlertTypeEnum_enum: AlertTypeEnum_Enum;
  AlertTypeEnum_enum_comparison_exp: AlertTypeEnum_Enum_Comparison_Exp;
  AlertTypeEnum_insert_input: AlertTypeEnum_Insert_Input;
  AlertTypeEnum_max_fields: ResolverTypeWrapper<AlertTypeEnum_Max_Fields>;
  AlertTypeEnum_min_fields: ResolverTypeWrapper<AlertTypeEnum_Min_Fields>;
  AlertTypeEnum_mutation_response: ResolverTypeWrapper<AlertTypeEnum_Mutation_Response>;
  AlertTypeEnum_on_conflict: AlertTypeEnum_On_Conflict;
  AlertTypeEnum_order_by: AlertTypeEnum_Order_By;
  AlertTypeEnum_pk_columns_input: AlertTypeEnum_Pk_Columns_Input;
  AlertTypeEnum_select_column: AlertTypeEnum_Select_Column;
  AlertTypeEnum_set_input: AlertTypeEnum_Set_Input;
  AlertTypeEnum_stream_cursor_input: AlertTypeEnum_Stream_Cursor_Input;
  AlertTypeEnum_stream_cursor_value_input: AlertTypeEnum_Stream_Cursor_Value_Input;
  AlertTypeEnum_update_column: AlertTypeEnum_Update_Column;
  AlertTypeEnum_updates: AlertTypeEnum_Updates;
  Alert_aggregate: ResolverTypeWrapper<Alert_Aggregate>;
  Alert_aggregate_bool_exp: Alert_Aggregate_Bool_Exp;
  Alert_aggregate_bool_exp_bool_and: Alert_Aggregate_Bool_Exp_Bool_And;
  Alert_aggregate_bool_exp_bool_or: Alert_Aggregate_Bool_Exp_Bool_Or;
  Alert_aggregate_bool_exp_count: Alert_Aggregate_Bool_Exp_Count;
  Alert_aggregate_fields: ResolverTypeWrapper<Alert_Aggregate_Fields>;
  Alert_aggregate_order_by: Alert_Aggregate_Order_By;
  Alert_append_input: Alert_Append_Input;
  Alert_arr_rel_insert_input: Alert_Arr_Rel_Insert_Input;
  Alert_avg_fields: ResolverTypeWrapper<Alert_Avg_Fields>;
  Alert_avg_order_by: Alert_Avg_Order_By;
  Alert_bool_exp: Alert_Bool_Exp;
  Alert_constraint: Alert_Constraint;
  Alert_delete_at_path_input: Alert_Delete_At_Path_Input;
  Alert_delete_elem_input: Alert_Delete_Elem_Input;
  Alert_delete_key_input: Alert_Delete_Key_Input;
  Alert_inc_input: Alert_Inc_Input;
  Alert_insert_input: Alert_Insert_Input;
  Alert_max_fields: ResolverTypeWrapper<Alert_Max_Fields>;
  Alert_max_order_by: Alert_Max_Order_By;
  Alert_min_fields: ResolverTypeWrapper<Alert_Min_Fields>;
  Alert_min_order_by: Alert_Min_Order_By;
  Alert_mutation_response: ResolverTypeWrapper<Alert_Mutation_Response>;
  Alert_obj_rel_insert_input: Alert_Obj_Rel_Insert_Input;
  Alert_on_conflict: Alert_On_Conflict;
  Alert_order_by: Alert_Order_By;
  Alert_pk_columns_input: Alert_Pk_Columns_Input;
  Alert_prepend_input: Alert_Prepend_Input;
  Alert_select_column: Alert_Select_Column;
  Alert_select_column_Alert_aggregate_bool_exp_bool_and_arguments_columns: Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  Alert_select_column_Alert_aggregate_bool_exp_bool_or_arguments_columns: Alert_Select_Column_Alert_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  Alert_set_input: Alert_Set_Input;
  Alert_stddev_fields: ResolverTypeWrapper<Alert_Stddev_Fields>;
  Alert_stddev_order_by: Alert_Stddev_Order_By;
  Alert_stddev_pop_fields: ResolverTypeWrapper<Alert_Stddev_Pop_Fields>;
  Alert_stddev_pop_order_by: Alert_Stddev_Pop_Order_By;
  Alert_stddev_samp_fields: ResolverTypeWrapper<Alert_Stddev_Samp_Fields>;
  Alert_stddev_samp_order_by: Alert_Stddev_Samp_Order_By;
  Alert_stream_cursor_input: Alert_Stream_Cursor_Input;
  Alert_stream_cursor_value_input: Alert_Stream_Cursor_Value_Input;
  Alert_sum_fields: ResolverTypeWrapper<Alert_Sum_Fields>;
  Alert_sum_order_by: Alert_Sum_Order_By;
  Alert_update_column: Alert_Update_Column;
  Alert_updates: Alert_Updates;
  Alert_var_pop_fields: ResolverTypeWrapper<Alert_Var_Pop_Fields>;
  Alert_var_pop_order_by: Alert_Var_Pop_Order_By;
  Alert_var_samp_fields: ResolverTypeWrapper<Alert_Var_Samp_Fields>;
  Alert_var_samp_order_by: Alert_Var_Samp_Order_By;
  Alert_variance_fields: ResolverTypeWrapper<Alert_Variance_Fields>;
  Alert_variance_order_by: Alert_Variance_Order_By;
  AmountDistributionType: AmountDistributionType;
  ArchiveContractMutationResponse: ResolverTypeWrapper<ArchiveContractMutationResponse>;
  ArchiveProductListItemMutationResponse: ResolverTypeWrapper<ArchiveProductListItemMutationResponse>;
  ArchiveScheduledWebhookNotificationsCustomerFilter: ArchiveScheduledWebhookNotificationsCustomerFilter;
  ArchivedFilter: ArchivedFilter;
  ArrearsInvoice: ResolverTypeWrapper<Omit<ArrearsInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  AuditEntry: AuditEntry;
  AuditLog: ResolverTypeWrapper<Omit<AuditLog, 'actor'> & { actor: ResolversTypes['ActorOrUser'] }>;
  AuditLogResponse: ResolverTypeWrapper<AuditLogResponse>;
  AuditLogStatusEnum: AuditLogStatusEnum;
  AuthCheckResponse: ResolverTypeWrapper<AuthCheckResponse>;
  AuthCheckType: AuthCheckType;
  BaseCommit: ResolversTypes['PostpaidCommit'] | ResolversTypes['PrepaidCommit'];
  BaseRateScheduleSegment: ResolversTypes['RateScheduleSegment'] | ResolversTypes['ScalarContractRateScheduleSegment'] | ResolversTypes['ScalarRateScheduleSegment'];
  BaseUsageFilter: ResolversTypes['InitialUsageFilter'] | ResolversTypes['UsageFilterUpdate'];
  BillableMetric: ResolverTypeWrapper<BillableMetric>;
  BillableMetricGroupKeyFilterEnum: BillableMetricGroupKeyFilterEnum;
  BillableMetricInput: BillableMetricInput;
  BillableMetricManagedField: ResolverTypeWrapper<BillableMetricManagedField>;
  BillableMetricManagedField_aggregate: ResolverTypeWrapper<BillableMetricManagedField_Aggregate>;
  BillableMetricManagedField_aggregate_fields: ResolverTypeWrapper<BillableMetricManagedField_Aggregate_Fields>;
  BillableMetricManagedField_bool_exp: BillableMetricManagedField_Bool_Exp;
  BillableMetricManagedField_constraint: BillableMetricManagedField_Constraint;
  BillableMetricManagedField_insert_input: BillableMetricManagedField_Insert_Input;
  BillableMetricManagedField_max_fields: ResolverTypeWrapper<BillableMetricManagedField_Max_Fields>;
  BillableMetricManagedField_min_fields: ResolverTypeWrapper<BillableMetricManagedField_Min_Fields>;
  BillableMetricManagedField_mutation_response: ResolverTypeWrapper<BillableMetricManagedField_Mutation_Response>;
  BillableMetricManagedField_on_conflict: BillableMetricManagedField_On_Conflict;
  BillableMetricManagedField_order_by: BillableMetricManagedField_Order_By;
  BillableMetricManagedField_pk_columns_input: BillableMetricManagedField_Pk_Columns_Input;
  BillableMetricManagedField_select_column: BillableMetricManagedField_Select_Column;
  BillableMetricManagedField_set_input: BillableMetricManagedField_Set_Input;
  BillableMetricManagedField_stream_cursor_input: BillableMetricManagedField_Stream_Cursor_Input;
  BillableMetricManagedField_stream_cursor_value_input: BillableMetricManagedField_Stream_Cursor_Value_Input;
  BillableMetricManagedField_update_column: BillableMetricManagedField_Update_Column;
  BillableMetricManagedField_updates: BillableMetricManagedField_Updates;
  BillableMetricSortingInput: BillableMetricSortingInput;
  BillableMetricSortingOrderByEnum: BillableMetricSortingOrderByEnum;
  BillableMetricTypeEnum: BillableMetricTypeEnum;
  BillableMetric_aggregate: ResolverTypeWrapper<BillableMetric_Aggregate>;
  BillableMetric_aggregate_bool_exp: BillableMetric_Aggregate_Bool_Exp;
  BillableMetric_aggregate_bool_exp_bool_and: BillableMetric_Aggregate_Bool_Exp_Bool_And;
  BillableMetric_aggregate_bool_exp_bool_or: BillableMetric_Aggregate_Bool_Exp_Bool_Or;
  BillableMetric_aggregate_bool_exp_count: BillableMetric_Aggregate_Bool_Exp_Count;
  BillableMetric_aggregate_fields: ResolverTypeWrapper<BillableMetric_Aggregate_Fields>;
  BillableMetric_aggregate_order_by: BillableMetric_Aggregate_Order_By;
  BillableMetric_append_input: BillableMetric_Append_Input;
  BillableMetric_arr_rel_insert_input: BillableMetric_Arr_Rel_Insert_Input;
  BillableMetric_bool_exp: BillableMetric_Bool_Exp;
  BillableMetric_constraint: BillableMetric_Constraint;
  BillableMetric_delete_at_path_input: BillableMetric_Delete_At_Path_Input;
  BillableMetric_delete_elem_input: BillableMetric_Delete_Elem_Input;
  BillableMetric_delete_key_input: BillableMetric_Delete_Key_Input;
  BillableMetric_insert_input: BillableMetric_Insert_Input;
  BillableMetric_max_fields: ResolverTypeWrapper<BillableMetric_Max_Fields>;
  BillableMetric_max_order_by: BillableMetric_Max_Order_By;
  BillableMetric_min_fields: ResolverTypeWrapper<BillableMetric_Min_Fields>;
  BillableMetric_min_order_by: BillableMetric_Min_Order_By;
  BillableMetric_mutation_response: ResolverTypeWrapper<BillableMetric_Mutation_Response>;
  BillableMetric_obj_rel_insert_input: BillableMetric_Obj_Rel_Insert_Input;
  BillableMetric_on_conflict: BillableMetric_On_Conflict;
  BillableMetric_order_by: BillableMetric_Order_By;
  BillableMetric_pk_columns_input: BillableMetric_Pk_Columns_Input;
  BillableMetric_prepend_input: BillableMetric_Prepend_Input;
  BillableMetric_select_column: BillableMetric_Select_Column;
  BillableMetric_select_column_BillableMetric_aggregate_bool_exp_bool_and_arguments_columns: BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  BillableMetric_select_column_BillableMetric_aggregate_bool_exp_bool_or_arguments_columns: BillableMetric_Select_Column_BillableMetric_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  BillableMetric_set_input: BillableMetric_Set_Input;
  BillableMetric_stream_cursor_input: BillableMetric_Stream_Cursor_Input;
  BillableMetric_stream_cursor_value_input: BillableMetric_Stream_Cursor_Value_Input;
  BillableMetric_update_column: BillableMetric_Update_Column;
  BillableMetric_updates: BillableMetric_Updates;
  BillableStatus: BillableStatus;
  BillableStatusFilter: BillableStatusFilter;
  BillableStatusWithEffectiveDate: ResolverTypeWrapper<BillableStatusWithEffectiveDate>;
  BilledInvoiceTotals: ResolverTypeWrapper<BilledInvoiceTotals>;
  BillingFrequencyEnum: ResolverTypeWrapper<BillingFrequencyEnum>;
  BillingFrequencyEnum_aggregate: ResolverTypeWrapper<BillingFrequencyEnum_Aggregate>;
  BillingFrequencyEnum_aggregate_fields: ResolverTypeWrapper<BillingFrequencyEnum_Aggregate_Fields>;
  BillingFrequencyEnum_bool_exp: BillingFrequencyEnum_Bool_Exp;
  BillingFrequencyEnum_constraint: BillingFrequencyEnum_Constraint;
  BillingFrequencyEnum_enum: BillingFrequencyEnum_Enum;
  BillingFrequencyEnum_enum_comparison_exp: BillingFrequencyEnum_Enum_Comparison_Exp;
  BillingFrequencyEnum_insert_input: BillingFrequencyEnum_Insert_Input;
  BillingFrequencyEnum_max_fields: ResolverTypeWrapper<BillingFrequencyEnum_Max_Fields>;
  BillingFrequencyEnum_min_fields: ResolverTypeWrapper<BillingFrequencyEnum_Min_Fields>;
  BillingFrequencyEnum_mutation_response: ResolverTypeWrapper<BillingFrequencyEnum_Mutation_Response>;
  BillingFrequencyEnum_on_conflict: BillingFrequencyEnum_On_Conflict;
  BillingFrequencyEnum_order_by: BillingFrequencyEnum_Order_By;
  BillingFrequencyEnum_pk_columns_input: BillingFrequencyEnum_Pk_Columns_Input;
  BillingFrequencyEnum_select_column: BillingFrequencyEnum_Select_Column;
  BillingFrequencyEnum_set_input: BillingFrequencyEnum_Set_Input;
  BillingFrequencyEnum_stream_cursor_input: BillingFrequencyEnum_Stream_Cursor_Input;
  BillingFrequencyEnum_stream_cursor_value_input: BillingFrequencyEnum_Stream_Cursor_Value_Input;
  BillingFrequencyEnum_update_column: BillingFrequencyEnum_Update_Column;
  BillingFrequencyEnum_updates: BillingFrequencyEnum_Updates;
  BillingMetricAggregateEnum: ResolverTypeWrapper<BillingMetricAggregateEnum>;
  BillingMetricAggregateEnum_aggregate: ResolverTypeWrapper<BillingMetricAggregateEnum_Aggregate>;
  BillingMetricAggregateEnum_aggregate_fields: ResolverTypeWrapper<BillingMetricAggregateEnum_Aggregate_Fields>;
  BillingMetricAggregateEnum_bool_exp: BillingMetricAggregateEnum_Bool_Exp;
  BillingMetricAggregateEnum_constraint: BillingMetricAggregateEnum_Constraint;
  BillingMetricAggregateEnum_enum: BillingMetricAggregateEnum_Enum;
  BillingMetricAggregateEnum_enum_comparison_exp: BillingMetricAggregateEnum_Enum_Comparison_Exp;
  BillingMetricAggregateEnum_insert_input: BillingMetricAggregateEnum_Insert_Input;
  BillingMetricAggregateEnum_max_fields: ResolverTypeWrapper<BillingMetricAggregateEnum_Max_Fields>;
  BillingMetricAggregateEnum_min_fields: ResolverTypeWrapper<BillingMetricAggregateEnum_Min_Fields>;
  BillingMetricAggregateEnum_mutation_response: ResolverTypeWrapper<BillingMetricAggregateEnum_Mutation_Response>;
  BillingMetricAggregateEnum_on_conflict: BillingMetricAggregateEnum_On_Conflict;
  BillingMetricAggregateEnum_order_by: BillingMetricAggregateEnum_Order_By;
  BillingMetricAggregateEnum_pk_columns_input: BillingMetricAggregateEnum_Pk_Columns_Input;
  BillingMetricAggregateEnum_select_column: BillingMetricAggregateEnum_Select_Column;
  BillingMetricAggregateEnum_set_input: BillingMetricAggregateEnum_Set_Input;
  BillingMetricAggregateEnum_stream_cursor_input: BillingMetricAggregateEnum_Stream_Cursor_Input;
  BillingMetricAggregateEnum_stream_cursor_value_input: BillingMetricAggregateEnum_Stream_Cursor_Value_Input;
  BillingMetricAggregateEnum_update_column: BillingMetricAggregateEnum_Update_Column;
  BillingMetricAggregateEnum_updates: BillingMetricAggregateEnum_Updates;
  BillingProviderConfigurationInput: BillingProviderConfigurationInput;
  BillingProviderCustomer: ResolverTypeWrapper<BillingProviderCustomer>;
  BillingProviderCustomerInput: BillingProviderCustomerInput;
  BillingProviderCustomer_aggregate: ResolverTypeWrapper<BillingProviderCustomer_Aggregate>;
  BillingProviderCustomer_aggregate_bool_exp: BillingProviderCustomer_Aggregate_Bool_Exp;
  BillingProviderCustomer_aggregate_bool_exp_count: BillingProviderCustomer_Aggregate_Bool_Exp_Count;
  BillingProviderCustomer_aggregate_fields: ResolverTypeWrapper<BillingProviderCustomer_Aggregate_Fields>;
  BillingProviderCustomer_aggregate_order_by: BillingProviderCustomer_Aggregate_Order_By;
  BillingProviderCustomer_arr_rel_insert_input: BillingProviderCustomer_Arr_Rel_Insert_Input;
  BillingProviderCustomer_bool_exp: BillingProviderCustomer_Bool_Exp;
  BillingProviderCustomer_constraint: BillingProviderCustomer_Constraint;
  BillingProviderCustomer_insert_input: BillingProviderCustomer_Insert_Input;
  BillingProviderCustomer_max_fields: ResolverTypeWrapper<BillingProviderCustomer_Max_Fields>;
  BillingProviderCustomer_max_order_by: BillingProviderCustomer_Max_Order_By;
  BillingProviderCustomer_min_fields: ResolverTypeWrapper<BillingProviderCustomer_Min_Fields>;
  BillingProviderCustomer_min_order_by: BillingProviderCustomer_Min_Order_By;
  BillingProviderCustomer_mutation_response: ResolverTypeWrapper<BillingProviderCustomer_Mutation_Response>;
  BillingProviderCustomer_on_conflict: BillingProviderCustomer_On_Conflict;
  BillingProviderCustomer_order_by: BillingProviderCustomer_Order_By;
  BillingProviderCustomer_pk_columns_input: BillingProviderCustomer_Pk_Columns_Input;
  BillingProviderCustomer_select_column: BillingProviderCustomer_Select_Column;
  BillingProviderCustomer_set_input: BillingProviderCustomer_Set_Input;
  BillingProviderCustomer_stream_cursor_input: BillingProviderCustomer_Stream_Cursor_Input;
  BillingProviderCustomer_stream_cursor_value_input: BillingProviderCustomer_Stream_Cursor_Value_Input;
  BillingProviderCustomer_update_column: BillingProviderCustomer_Update_Column;
  BillingProviderCustomer_updates: BillingProviderCustomer_Updates;
  BillingProviderDeliveryMethod: ResolverTypeWrapper<BillingProviderDeliveryMethod>;
  BillingProviderDeliveryMethodEnum_enum: BillingProviderDeliveryMethodEnum_Enum;
  BillingProviderDeliveryMethodWithoutConfiguration: ResolverTypeWrapper<BillingProviderDeliveryMethodWithoutConfiguration>;
  BillingProviderDeliveryMethod_enum: BillingProviderDeliveryMethod_Enum;
  BillingProviderEnum: ResolverTypeWrapper<BillingProviderEnum>;
  BillingProviderEnum_aggregate: ResolverTypeWrapper<BillingProviderEnum_Aggregate>;
  BillingProviderEnum_aggregate_fields: ResolverTypeWrapper<BillingProviderEnum_Aggregate_Fields>;
  BillingProviderEnum_bool_exp: BillingProviderEnum_Bool_Exp;
  BillingProviderEnum_constraint: BillingProviderEnum_Constraint;
  BillingProviderEnum_enum: BillingProviderEnum_Enum;
  BillingProviderEnum_enum_comparison_exp: BillingProviderEnum_Enum_Comparison_Exp;
  BillingProviderEnum_insert_input: BillingProviderEnum_Insert_Input;
  BillingProviderEnum_max_fields: ResolverTypeWrapper<BillingProviderEnum_Max_Fields>;
  BillingProviderEnum_min_fields: ResolverTypeWrapper<BillingProviderEnum_Min_Fields>;
  BillingProviderEnum_mutation_response: ResolverTypeWrapper<BillingProviderEnum_Mutation_Response>;
  BillingProviderEnum_obj_rel_insert_input: BillingProviderEnum_Obj_Rel_Insert_Input;
  BillingProviderEnum_on_conflict: BillingProviderEnum_On_Conflict;
  BillingProviderEnum_order_by: BillingProviderEnum_Order_By;
  BillingProviderEnum_pk_columns_input: BillingProviderEnum_Pk_Columns_Input;
  BillingProviderEnum_select_column: BillingProviderEnum_Select_Column;
  BillingProviderEnum_set_input: BillingProviderEnum_Set_Input;
  BillingProviderEnum_stream_cursor_input: BillingProviderEnum_Stream_Cursor_Input;
  BillingProviderEnum_stream_cursor_value_input: BillingProviderEnum_Stream_Cursor_Value_Input;
  BillingProviderEnum_update_column: BillingProviderEnum_Update_Column;
  BillingProviderEnum_updates: BillingProviderEnum_Updates;
  BillingProviderFilter: BillingProviderFilter;
  BillingProviderInvoice: ResolverTypeWrapper<BillingProviderInvoice>;
  BillingProviderInvoiceExternalStatusFilter: BillingProviderInvoiceExternalStatusFilter;
  BillingProviderInvoiceUpsertResult: ResolverTypeWrapper<BillingProviderInvoiceUpsertResult>;
  BillingProviderInvoice_aggregate: ResolverTypeWrapper<BillingProviderInvoice_Aggregate>;
  BillingProviderInvoice_aggregate_fields: ResolverTypeWrapper<BillingProviderInvoice_Aggregate_Fields>;
  BillingProviderInvoice_bool_exp: BillingProviderInvoice_Bool_Exp;
  BillingProviderInvoice_constraint: BillingProviderInvoice_Constraint;
  BillingProviderInvoice_insert_input: BillingProviderInvoice_Insert_Input;
  BillingProviderInvoice_max_fields: ResolverTypeWrapper<BillingProviderInvoice_Max_Fields>;
  BillingProviderInvoice_min_fields: ResolverTypeWrapper<BillingProviderInvoice_Min_Fields>;
  BillingProviderInvoice_mutation_response: ResolverTypeWrapper<BillingProviderInvoice_Mutation_Response>;
  BillingProviderInvoice_on_conflict: BillingProviderInvoice_On_Conflict;
  BillingProviderInvoice_order_by: BillingProviderInvoice_Order_By;
  BillingProviderInvoice_pk_columns_input: BillingProviderInvoice_Pk_Columns_Input;
  BillingProviderInvoice_select_column: BillingProviderInvoice_Select_Column;
  BillingProviderInvoice_set_input: BillingProviderInvoice_Set_Input;
  BillingProviderInvoice_stream_cursor_input: BillingProviderInvoice_Stream_Cursor_Input;
  BillingProviderInvoice_stream_cursor_value_input: BillingProviderInvoice_Stream_Cursor_Value_Input;
  BillingProviderInvoice_update_column: BillingProviderInvoice_Update_Column;
  BillingProviderInvoice_updates: BillingProviderInvoice_Updates;
  BillingProviderToken: ResolverTypeWrapper<BillingProviderToken>;
  BillingProviderToken_aggregate: ResolverTypeWrapper<BillingProviderToken_Aggregate>;
  BillingProviderToken_aggregate_bool_exp: BillingProviderToken_Aggregate_Bool_Exp;
  BillingProviderToken_aggregate_bool_exp_count: BillingProviderToken_Aggregate_Bool_Exp_Count;
  BillingProviderToken_aggregate_fields: ResolverTypeWrapper<BillingProviderToken_Aggregate_Fields>;
  BillingProviderToken_aggregate_order_by: BillingProviderToken_Aggregate_Order_By;
  BillingProviderToken_arr_rel_insert_input: BillingProviderToken_Arr_Rel_Insert_Input;
  BillingProviderToken_bool_exp: BillingProviderToken_Bool_Exp;
  BillingProviderToken_constraint: BillingProviderToken_Constraint;
  BillingProviderToken_insert_input: BillingProviderToken_Insert_Input;
  BillingProviderToken_max_fields: ResolverTypeWrapper<BillingProviderToken_Max_Fields>;
  BillingProviderToken_max_order_by: BillingProviderToken_Max_Order_By;
  BillingProviderToken_min_fields: ResolverTypeWrapper<BillingProviderToken_Min_Fields>;
  BillingProviderToken_min_order_by: BillingProviderToken_Min_Order_By;
  BillingProviderToken_mutation_response: ResolverTypeWrapper<BillingProviderToken_Mutation_Response>;
  BillingProviderToken_on_conflict: BillingProviderToken_On_Conflict;
  BillingProviderToken_order_by: BillingProviderToken_Order_By;
  BillingProviderToken_pk_columns_input: BillingProviderToken_Pk_Columns_Input;
  BillingProviderToken_select_column: BillingProviderToken_Select_Column;
  BillingProviderToken_set_input: BillingProviderToken_Set_Input;
  BillingProviderToken_stream_cursor_input: BillingProviderToken_Stream_Cursor_Input;
  BillingProviderToken_stream_cursor_value_input: BillingProviderToken_Stream_Cursor_Value_Input;
  BillingProviderToken_update_column: BillingProviderToken_Update_Column;
  BillingProviderToken_updates: BillingProviderToken_Updates;
  BlocksInput: BlocksInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  BreakdownCursor: ResolverTypeWrapper<BreakdownCursor>;
  BreakdownItem: ResolverTypeWrapper<BreakdownItem>;
  BreakdownTier: ResolverTypeWrapper<BreakdownTier>;
  CensusDestinationConnectLink: ResolverTypeWrapper<CensusDestinationConnectLink>;
  CensusDestinationTypeEnum: CensusDestinationTypeEnum;
  CensusObjectSyncReport: ResolverTypeWrapper<CensusObjectSyncReport>;
  CensusRecordSyncError: ResolverTypeWrapper<CensusRecordSyncError>;
  CensusSyncRun: ResolverTypeWrapper<CensusSyncRun>;
  CensusSyncRunStatusEnum: CensusSyncRunStatusEnum;
  CensusWorkspace: ResolverTypeWrapper<CensusWorkspace>;
  ChargeLineItem: ResolverTypeWrapper<Omit<ChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversTypes['ChargeSubLineItem']>> }>;
  ChargeManagedField: ResolverTypeWrapper<ChargeManagedField>;
  ChargeManagedField_aggregate: ResolverTypeWrapper<ChargeManagedField_Aggregate>;
  ChargeManagedField_aggregate_bool_exp: ChargeManagedField_Aggregate_Bool_Exp;
  ChargeManagedField_aggregate_bool_exp_bool_and: ChargeManagedField_Aggregate_Bool_Exp_Bool_And;
  ChargeManagedField_aggregate_bool_exp_bool_or: ChargeManagedField_Aggregate_Bool_Exp_Bool_Or;
  ChargeManagedField_aggregate_bool_exp_count: ChargeManagedField_Aggregate_Bool_Exp_Count;
  ChargeManagedField_aggregate_fields: ResolverTypeWrapper<ChargeManagedField_Aggregate_Fields>;
  ChargeManagedField_aggregate_order_by: ChargeManagedField_Aggregate_Order_By;
  ChargeManagedField_arr_rel_insert_input: ChargeManagedField_Arr_Rel_Insert_Input;
  ChargeManagedField_bool_exp: ChargeManagedField_Bool_Exp;
  ChargeManagedField_constraint: ChargeManagedField_Constraint;
  ChargeManagedField_insert_input: ChargeManagedField_Insert_Input;
  ChargeManagedField_max_fields: ResolverTypeWrapper<ChargeManagedField_Max_Fields>;
  ChargeManagedField_max_order_by: ChargeManagedField_Max_Order_By;
  ChargeManagedField_min_fields: ResolverTypeWrapper<ChargeManagedField_Min_Fields>;
  ChargeManagedField_min_order_by: ChargeManagedField_Min_Order_By;
  ChargeManagedField_mutation_response: ResolverTypeWrapper<ChargeManagedField_Mutation_Response>;
  ChargeManagedField_on_conflict: ChargeManagedField_On_Conflict;
  ChargeManagedField_order_by: ChargeManagedField_Order_By;
  ChargeManagedField_pk_columns_input: ChargeManagedField_Pk_Columns_Input;
  ChargeManagedField_select_column: ChargeManagedField_Select_Column;
  ChargeManagedField_select_column_ChargeManagedField_aggregate_bool_exp_bool_and_arguments_columns: ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ChargeManagedField_select_column_ChargeManagedField_aggregate_bool_exp_bool_or_arguments_columns: ChargeManagedField_Select_Column_ChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ChargeManagedField_set_input: ChargeManagedField_Set_Input;
  ChargeManagedField_stream_cursor_input: ChargeManagedField_Stream_Cursor_Input;
  ChargeManagedField_stream_cursor_value_input: ChargeManagedField_Stream_Cursor_Value_Input;
  ChargeManagedField_update_column: ChargeManagedField_Update_Column;
  ChargeManagedField_updates: ChargeManagedField_Updates;
  ChargeSubLineItem: ResolversTypes['GroupedChargeLineItem'] | ResolversTypes['TierChargeLineItem'];
  ChargeTypeEnum: ResolverTypeWrapper<ChargeTypeEnum>;
  ChargeTypeEnum_aggregate: ResolverTypeWrapper<ChargeTypeEnum_Aggregate>;
  ChargeTypeEnum_aggregate_fields: ResolverTypeWrapper<ChargeTypeEnum_Aggregate_Fields>;
  ChargeTypeEnum_bool_exp: ChargeTypeEnum_Bool_Exp;
  ChargeTypeEnum_constraint: ChargeTypeEnum_Constraint;
  ChargeTypeEnum_enum: ChargeTypeEnum_Enum;
  ChargeTypeEnum_enum_comparison_exp: ChargeTypeEnum_Enum_Comparison_Exp;
  ChargeTypeEnum_insert_input: ChargeTypeEnum_Insert_Input;
  ChargeTypeEnum_max_fields: ResolverTypeWrapper<ChargeTypeEnum_Max_Fields>;
  ChargeTypeEnum_min_fields: ResolverTypeWrapper<ChargeTypeEnum_Min_Fields>;
  ChargeTypeEnum_mutation_response: ResolverTypeWrapper<ChargeTypeEnum_Mutation_Response>;
  ChargeTypeEnum_obj_rel_insert_input: ChargeTypeEnum_Obj_Rel_Insert_Input;
  ChargeTypeEnum_on_conflict: ChargeTypeEnum_On_Conflict;
  ChargeTypeEnum_order_by: ChargeTypeEnum_Order_By;
  ChargeTypeEnum_pk_columns_input: ChargeTypeEnum_Pk_Columns_Input;
  ChargeTypeEnum_select_column: ChargeTypeEnum_Select_Column;
  ChargeTypeEnum_set_input: ChargeTypeEnum_Set_Input;
  ChargeTypeEnum_stream_cursor_input: ChargeTypeEnum_Stream_Cursor_Input;
  ChargeTypeEnum_stream_cursor_value_input: ChargeTypeEnum_Stream_Cursor_Value_Input;
  ChargeTypeEnum_update_column: ChargeTypeEnum_Update_Column;
  ChargeTypeEnum_updates: ChargeTypeEnum_Updates;
  Client: ResolverTypeWrapper<Client>;
  ClientConfig: ResolverTypeWrapper<ClientConfig>;
  ClientConfigKeyEnum: ResolverTypeWrapper<ClientConfigKeyEnum>;
  ClientConfigKeyEnum_aggregate: ResolverTypeWrapper<ClientConfigKeyEnum_Aggregate>;
  ClientConfigKeyEnum_aggregate_fields: ResolverTypeWrapper<ClientConfigKeyEnum_Aggregate_Fields>;
  ClientConfigKeyEnum_bool_exp: ClientConfigKeyEnum_Bool_Exp;
  ClientConfigKeyEnum_constraint: ClientConfigKeyEnum_Constraint;
  ClientConfigKeyEnum_enum: ClientConfigKeyEnum_Enum;
  ClientConfigKeyEnum_enum_comparison_exp: ClientConfigKeyEnum_Enum_Comparison_Exp;
  ClientConfigKeyEnum_insert_input: ClientConfigKeyEnum_Insert_Input;
  ClientConfigKeyEnum_max_fields: ResolverTypeWrapper<ClientConfigKeyEnum_Max_Fields>;
  ClientConfigKeyEnum_min_fields: ResolverTypeWrapper<ClientConfigKeyEnum_Min_Fields>;
  ClientConfigKeyEnum_mutation_response: ResolverTypeWrapper<ClientConfigKeyEnum_Mutation_Response>;
  ClientConfigKeyEnum_on_conflict: ClientConfigKeyEnum_On_Conflict;
  ClientConfigKeyEnum_order_by: ClientConfigKeyEnum_Order_By;
  ClientConfigKeyEnum_pk_columns_input: ClientConfigKeyEnum_Pk_Columns_Input;
  ClientConfigKeyEnum_select_column: ClientConfigKeyEnum_Select_Column;
  ClientConfigKeyEnum_set_input: ClientConfigKeyEnum_Set_Input;
  ClientConfigKeyEnum_stream_cursor_input: ClientConfigKeyEnum_Stream_Cursor_Input;
  ClientConfigKeyEnum_stream_cursor_value_input: ClientConfigKeyEnum_Stream_Cursor_Value_Input;
  ClientConfigKeyEnum_update_column: ClientConfigKeyEnum_Update_Column;
  ClientConfigKeyEnum_updates: ClientConfigKeyEnum_Updates;
  ClientConfig_aggregate: ResolverTypeWrapper<ClientConfig_Aggregate>;
  ClientConfig_aggregate_bool_exp: ClientConfig_Aggregate_Bool_Exp;
  ClientConfig_aggregate_bool_exp_count: ClientConfig_Aggregate_Bool_Exp_Count;
  ClientConfig_aggregate_fields: ResolverTypeWrapper<ClientConfig_Aggregate_Fields>;
  ClientConfig_aggregate_order_by: ClientConfig_Aggregate_Order_By;
  ClientConfig_arr_rel_insert_input: ClientConfig_Arr_Rel_Insert_Input;
  ClientConfig_bool_exp: ClientConfig_Bool_Exp;
  ClientConfig_constraint: ClientConfig_Constraint;
  ClientConfig_insert_input: ClientConfig_Insert_Input;
  ClientConfig_max_fields: ResolverTypeWrapper<ClientConfig_Max_Fields>;
  ClientConfig_max_order_by: ClientConfig_Max_Order_By;
  ClientConfig_min_fields: ResolverTypeWrapper<ClientConfig_Min_Fields>;
  ClientConfig_min_order_by: ClientConfig_Min_Order_By;
  ClientConfig_mutation_response: ResolverTypeWrapper<ClientConfig_Mutation_Response>;
  ClientConfig_on_conflict: ClientConfig_On_Conflict;
  ClientConfig_order_by: ClientConfig_Order_By;
  ClientConfig_pk_columns_input: ClientConfig_Pk_Columns_Input;
  ClientConfig_select_column: ClientConfig_Select_Column;
  ClientConfig_set_input: ClientConfig_Set_Input;
  ClientConfig_stream_cursor_input: ClientConfig_Stream_Cursor_Input;
  ClientConfig_stream_cursor_value_input: ClientConfig_Stream_Cursor_Value_Input;
  ClientConfig_update_column: ClientConfig_Update_Column;
  ClientConfig_updates: ClientConfig_Updates;
  ClientDashboardMetrics: ResolverTypeWrapper<ClientDashboardMetrics>;
  ClientOverview: ResolverTypeWrapper<ClientOverview>;
  ClientOverview_Chart: ResolverTypeWrapper<ClientOverview_Chart>;
  ClientOverview_ChartDatum: ResolverTypeWrapper<ClientOverview_ChartDatum>;
  ClientOverview_Spend: ResolverTypeWrapper<ClientOverview_Spend>;
  ClientOverview_TopSpender: ResolverTypeWrapper<ClientOverview_TopSpender>;
  ClientOverview_TopSpenders: ResolverTypeWrapper<ClientOverview_TopSpenders>;
  ClientUsingContracts: ResolverTypeWrapper<ClientUsingContracts>;
  ClientUsingPrepaidCredits: ResolverTypeWrapper<ClientUsingPrepaidCredits>;
  Client_aggregate: ResolverTypeWrapper<Client_Aggregate>;
  Client_aggregate_fields: ResolverTypeWrapper<Client_Aggregate_Fields>;
  Client_bool_exp: Client_Bool_Exp;
  Client_constraint: Client_Constraint;
  Client_insert_input: Client_Insert_Input;
  Client_max_fields: ResolverTypeWrapper<Client_Max_Fields>;
  Client_min_fields: ResolverTypeWrapper<Client_Min_Fields>;
  Client_mutation_response: ResolverTypeWrapper<Client_Mutation_Response>;
  Client_obj_rel_insert_input: Client_Obj_Rel_Insert_Input;
  Client_on_conflict: Client_On_Conflict;
  Client_order_by: Client_Order_By;
  Client_pk_columns_input: Client_Pk_Columns_Input;
  Client_select_column: Client_Select_Column;
  Client_set_input: Client_Set_Input;
  Client_stream_cursor_input: Client_Stream_Cursor_Input;
  Client_stream_cursor_value_input: Client_Stream_Cursor_Value_Input;
  Client_update_column: Client_Update_Column;
  Client_updates: Client_Updates;
  ClientsUsingContracts: ResolverTypeWrapper<ClientsUsingContracts>;
  ClientsUsingPrepaidCredits: ResolverTypeWrapper<ClientsUsingPrepaidCredits>;
  CollectionScheduleEnum: ResolverTypeWrapper<CollectionScheduleEnum>;
  CollectionScheduleEnum_aggregate: ResolverTypeWrapper<CollectionScheduleEnum_Aggregate>;
  CollectionScheduleEnum_aggregate_fields: ResolverTypeWrapper<CollectionScheduleEnum_Aggregate_Fields>;
  CollectionScheduleEnum_bool_exp: CollectionScheduleEnum_Bool_Exp;
  CollectionScheduleEnum_constraint: CollectionScheduleEnum_Constraint;
  CollectionScheduleEnum_enum: CollectionScheduleEnum_Enum;
  CollectionScheduleEnum_enum_comparison_exp: CollectionScheduleEnum_Enum_Comparison_Exp;
  CollectionScheduleEnum_insert_input: CollectionScheduleEnum_Insert_Input;
  CollectionScheduleEnum_max_fields: ResolverTypeWrapper<CollectionScheduleEnum_Max_Fields>;
  CollectionScheduleEnum_min_fields: ResolverTypeWrapper<CollectionScheduleEnum_Min_Fields>;
  CollectionScheduleEnum_mutation_response: ResolverTypeWrapper<CollectionScheduleEnum_Mutation_Response>;
  CollectionScheduleEnum_obj_rel_insert_input: CollectionScheduleEnum_Obj_Rel_Insert_Input;
  CollectionScheduleEnum_on_conflict: CollectionScheduleEnum_On_Conflict;
  CollectionScheduleEnum_order_by: CollectionScheduleEnum_Order_By;
  CollectionScheduleEnum_pk_columns_input: CollectionScheduleEnum_Pk_Columns_Input;
  CollectionScheduleEnum_select_column: CollectionScheduleEnum_Select_Column;
  CollectionScheduleEnum_set_input: CollectionScheduleEnum_Set_Input;
  CollectionScheduleEnum_stream_cursor_input: CollectionScheduleEnum_Stream_Cursor_Input;
  CollectionScheduleEnum_stream_cursor_value_input: CollectionScheduleEnum_Stream_Cursor_Value_Input;
  CollectionScheduleEnum_update_column: CollectionScheduleEnum_Update_Column;
  CollectionScheduleEnum_updates: CollectionScheduleEnum_Updates;
  Commit: ResolverTypeWrapper<Omit<Commit, 'applicable_products' | 'product'> & { applicable_products: Maybe<Array<ResolversTypes['ProductListItem']>>, product: ResolversTypes['ProductListItem'] }>;
  CommitAccessSchedule: ResolverTypeWrapper<CommitAccessSchedule>;
  CommitAccessScheduleInput: CommitAccessScheduleInput;
  CommitInput: CommitInput;
  CommitInvoiceSchedule: ResolverTypeWrapper<CommitInvoiceSchedule>;
  CommitInvoiceScheduleInput: CommitInvoiceScheduleInput;
  CommitInvoiceScheduleItem: ResolverTypeWrapper<CommitInvoiceScheduleItem>;
  CommitInvoiceScheduleItemInput: CommitInvoiceScheduleItemInput;
  CommitInvoiceScheduleItemUpdateInput: CommitInvoiceScheduleItemUpdateInput;
  CommitManagedField: ResolverTypeWrapper<CommitManagedField>;
  CommitManagedField_aggregate: ResolverTypeWrapper<CommitManagedField_Aggregate>;
  CommitManagedField_aggregate_bool_exp: CommitManagedField_Aggregate_Bool_Exp;
  CommitManagedField_aggregate_bool_exp_bool_and: CommitManagedField_Aggregate_Bool_Exp_Bool_And;
  CommitManagedField_aggregate_bool_exp_bool_or: CommitManagedField_Aggregate_Bool_Exp_Bool_Or;
  CommitManagedField_aggregate_bool_exp_count: CommitManagedField_Aggregate_Bool_Exp_Count;
  CommitManagedField_aggregate_fields: ResolverTypeWrapper<CommitManagedField_Aggregate_Fields>;
  CommitManagedField_aggregate_order_by: CommitManagedField_Aggregate_Order_By;
  CommitManagedField_arr_rel_insert_input: CommitManagedField_Arr_Rel_Insert_Input;
  CommitManagedField_bool_exp: CommitManagedField_Bool_Exp;
  CommitManagedField_constraint: CommitManagedField_Constraint;
  CommitManagedField_insert_input: CommitManagedField_Insert_Input;
  CommitManagedField_max_fields: ResolverTypeWrapper<CommitManagedField_Max_Fields>;
  CommitManagedField_max_order_by: CommitManagedField_Max_Order_By;
  CommitManagedField_min_fields: ResolverTypeWrapper<CommitManagedField_Min_Fields>;
  CommitManagedField_min_order_by: CommitManagedField_Min_Order_By;
  CommitManagedField_mutation_response: ResolverTypeWrapper<CommitManagedField_Mutation_Response>;
  CommitManagedField_on_conflict: CommitManagedField_On_Conflict;
  CommitManagedField_order_by: CommitManagedField_Order_By;
  CommitManagedField_pk_columns_input: CommitManagedField_Pk_Columns_Input;
  CommitManagedField_select_column: CommitManagedField_Select_Column;
  CommitManagedField_select_column_CommitManagedField_aggregate_bool_exp_bool_and_arguments_columns: CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  CommitManagedField_select_column_CommitManagedField_aggregate_bool_exp_bool_or_arguments_columns: CommitManagedField_Select_Column_CommitManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  CommitManagedField_set_input: CommitManagedField_Set_Input;
  CommitManagedField_stream_cursor_input: CommitManagedField_Stream_Cursor_Input;
  CommitManagedField_stream_cursor_value_input: CommitManagedField_Stream_Cursor_Value_Input;
  CommitManagedField_update_column: CommitManagedField_Update_Column;
  CommitManagedField_updates: CommitManagedField_Updates;
  CommitOrCreditManagedField: ResolverTypeWrapper<CommitOrCreditManagedField>;
  CommitScheduleAccessUpdateInput: CommitScheduleAccessUpdateInput;
  CommitScheduleInvoiceUpdateInput: CommitScheduleInvoiceUpdateInput;
  CommitScheduleUpdateInput: CommitScheduleUpdateInput;
  CommitSegmentScheduleItem: ResolverTypeWrapper<CommitSegmentScheduleItem>;
  CommitSegmentScheduleItemInput: CommitSegmentScheduleItemInput;
  CommitSegmentScheduleItemUpdateInput: CommitSegmentScheduleItemUpdateInput;
  CommitType: CommitType;
  CommitUnion: ResolversTypes['PostpaidCommit'] | ResolversTypes['PrepaidCommit'];
  CommitWithSegment: ResolverTypeWrapper<Omit<CommitWithSegment, 'commit_union'> & { commit_union: ResolversTypes['CommitUnion'] }>;
  CompositeCharge: ResolverTypeWrapper<CompositeCharge>;
  CompositeChargeAdjustment: ResolverTypeWrapper<CompositeChargeAdjustment>;
  CompositeChargeAdjustment_aggregate: ResolverTypeWrapper<CompositeChargeAdjustment_Aggregate>;
  CompositeChargeAdjustment_aggregate_bool_exp: CompositeChargeAdjustment_Aggregate_Bool_Exp;
  CompositeChargeAdjustment_aggregate_bool_exp_count: CompositeChargeAdjustment_Aggregate_Bool_Exp_Count;
  CompositeChargeAdjustment_aggregate_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Aggregate_Fields>;
  CompositeChargeAdjustment_aggregate_order_by: CompositeChargeAdjustment_Aggregate_Order_By;
  CompositeChargeAdjustment_arr_rel_insert_input: CompositeChargeAdjustment_Arr_Rel_Insert_Input;
  CompositeChargeAdjustment_avg_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Avg_Fields>;
  CompositeChargeAdjustment_avg_order_by: CompositeChargeAdjustment_Avg_Order_By;
  CompositeChargeAdjustment_bool_exp: CompositeChargeAdjustment_Bool_Exp;
  CompositeChargeAdjustment_constraint: CompositeChargeAdjustment_Constraint;
  CompositeChargeAdjustment_inc_input: CompositeChargeAdjustment_Inc_Input;
  CompositeChargeAdjustment_insert_input: CompositeChargeAdjustment_Insert_Input;
  CompositeChargeAdjustment_max_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Max_Fields>;
  CompositeChargeAdjustment_max_order_by: CompositeChargeAdjustment_Max_Order_By;
  CompositeChargeAdjustment_min_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Min_Fields>;
  CompositeChargeAdjustment_min_order_by: CompositeChargeAdjustment_Min_Order_By;
  CompositeChargeAdjustment_mutation_response: ResolverTypeWrapper<CompositeChargeAdjustment_Mutation_Response>;
  CompositeChargeAdjustment_obj_rel_insert_input: CompositeChargeAdjustment_Obj_Rel_Insert_Input;
  CompositeChargeAdjustment_on_conflict: CompositeChargeAdjustment_On_Conflict;
  CompositeChargeAdjustment_order_by: CompositeChargeAdjustment_Order_By;
  CompositeChargeAdjustment_pk_columns_input: CompositeChargeAdjustment_Pk_Columns_Input;
  CompositeChargeAdjustment_select_column: CompositeChargeAdjustment_Select_Column;
  CompositeChargeAdjustment_set_input: CompositeChargeAdjustment_Set_Input;
  CompositeChargeAdjustment_stddev_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Stddev_Fields>;
  CompositeChargeAdjustment_stddev_order_by: CompositeChargeAdjustment_Stddev_Order_By;
  CompositeChargeAdjustment_stddev_pop_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Stddev_Pop_Fields>;
  CompositeChargeAdjustment_stddev_pop_order_by: CompositeChargeAdjustment_Stddev_Pop_Order_By;
  CompositeChargeAdjustment_stddev_samp_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Stddev_Samp_Fields>;
  CompositeChargeAdjustment_stddev_samp_order_by: CompositeChargeAdjustment_Stddev_Samp_Order_By;
  CompositeChargeAdjustment_stream_cursor_input: CompositeChargeAdjustment_Stream_Cursor_Input;
  CompositeChargeAdjustment_stream_cursor_value_input: CompositeChargeAdjustment_Stream_Cursor_Value_Input;
  CompositeChargeAdjustment_sum_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Sum_Fields>;
  CompositeChargeAdjustment_sum_order_by: CompositeChargeAdjustment_Sum_Order_By;
  CompositeChargeAdjustment_update_column: CompositeChargeAdjustment_Update_Column;
  CompositeChargeAdjustment_updates: CompositeChargeAdjustment_Updates;
  CompositeChargeAdjustment_var_pop_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Var_Pop_Fields>;
  CompositeChargeAdjustment_var_pop_order_by: CompositeChargeAdjustment_Var_Pop_Order_By;
  CompositeChargeAdjustment_var_samp_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Var_Samp_Fields>;
  CompositeChargeAdjustment_var_samp_order_by: CompositeChargeAdjustment_Var_Samp_Order_By;
  CompositeChargeAdjustment_variance_fields: ResolverTypeWrapper<CompositeChargeAdjustment_Variance_Fields>;
  CompositeChargeAdjustment_variance_order_by: CompositeChargeAdjustment_Variance_Order_By;
  CompositeChargeLineItem: ResolverTypeWrapper<Omit<CompositeChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversTypes['TierSubLineItem']>> }>;
  CompositeChargePricingFactor: ResolverTypeWrapper<CompositeChargePricingFactor>;
  CompositeChargePricingFactor_aggregate: ResolverTypeWrapper<CompositeChargePricingFactor_Aggregate>;
  CompositeChargePricingFactor_aggregate_bool_exp: CompositeChargePricingFactor_Aggregate_Bool_Exp;
  CompositeChargePricingFactor_aggregate_bool_exp_count: CompositeChargePricingFactor_Aggregate_Bool_Exp_Count;
  CompositeChargePricingFactor_aggregate_fields: ResolverTypeWrapper<CompositeChargePricingFactor_Aggregate_Fields>;
  CompositeChargePricingFactor_aggregate_order_by: CompositeChargePricingFactor_Aggregate_Order_By;
  CompositeChargePricingFactor_arr_rel_insert_input: CompositeChargePricingFactor_Arr_Rel_Insert_Input;
  CompositeChargePricingFactor_bool_exp: CompositeChargePricingFactor_Bool_Exp;
  CompositeChargePricingFactor_insert_input: CompositeChargePricingFactor_Insert_Input;
  CompositeChargePricingFactor_max_fields: ResolverTypeWrapper<CompositeChargePricingFactor_Max_Fields>;
  CompositeChargePricingFactor_max_order_by: CompositeChargePricingFactor_Max_Order_By;
  CompositeChargePricingFactor_min_fields: ResolverTypeWrapper<CompositeChargePricingFactor_Min_Fields>;
  CompositeChargePricingFactor_min_order_by: CompositeChargePricingFactor_Min_Order_By;
  CompositeChargePricingFactor_mutation_response: ResolverTypeWrapper<CompositeChargePricingFactor_Mutation_Response>;
  CompositeChargePricingFactor_order_by: CompositeChargePricingFactor_Order_By;
  CompositeChargePricingFactor_select_column: CompositeChargePricingFactor_Select_Column;
  CompositeChargePricingFactor_set_input: CompositeChargePricingFactor_Set_Input;
  CompositeChargePricingFactor_stream_cursor_input: CompositeChargePricingFactor_Stream_Cursor_Input;
  CompositeChargePricingFactor_stream_cursor_value_input: CompositeChargePricingFactor_Stream_Cursor_Value_Input;
  CompositeChargePricingFactor_updates: CompositeChargePricingFactor_Updates;
  CompositeChargeTier: ResolverTypeWrapper<CompositeChargeTier>;
  CompositeChargeTierAdjustment: ResolverTypeWrapper<CompositeChargeTierAdjustment>;
  CompositeChargeTierAdjustment_aggregate: ResolverTypeWrapper<CompositeChargeTierAdjustment_Aggregate>;
  CompositeChargeTierAdjustment_aggregate_bool_exp: CompositeChargeTierAdjustment_Aggregate_Bool_Exp;
  CompositeChargeTierAdjustment_aggregate_bool_exp_count: CompositeChargeTierAdjustment_Aggregate_Bool_Exp_Count;
  CompositeChargeTierAdjustment_aggregate_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Aggregate_Fields>;
  CompositeChargeTierAdjustment_aggregate_order_by: CompositeChargeTierAdjustment_Aggregate_Order_By;
  CompositeChargeTierAdjustment_arr_rel_insert_input: CompositeChargeTierAdjustment_Arr_Rel_Insert_Input;
  CompositeChargeTierAdjustment_avg_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Avg_Fields>;
  CompositeChargeTierAdjustment_avg_order_by: CompositeChargeTierAdjustment_Avg_Order_By;
  CompositeChargeTierAdjustment_bool_exp: CompositeChargeTierAdjustment_Bool_Exp;
  CompositeChargeTierAdjustment_constraint: CompositeChargeTierAdjustment_Constraint;
  CompositeChargeTierAdjustment_inc_input: CompositeChargeTierAdjustment_Inc_Input;
  CompositeChargeTierAdjustment_insert_input: CompositeChargeTierAdjustment_Insert_Input;
  CompositeChargeTierAdjustment_max_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Max_Fields>;
  CompositeChargeTierAdjustment_max_order_by: CompositeChargeTierAdjustment_Max_Order_By;
  CompositeChargeTierAdjustment_min_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Min_Fields>;
  CompositeChargeTierAdjustment_min_order_by: CompositeChargeTierAdjustment_Min_Order_By;
  CompositeChargeTierAdjustment_mutation_response: ResolverTypeWrapper<CompositeChargeTierAdjustment_Mutation_Response>;
  CompositeChargeTierAdjustment_on_conflict: CompositeChargeTierAdjustment_On_Conflict;
  CompositeChargeTierAdjustment_order_by: CompositeChargeTierAdjustment_Order_By;
  CompositeChargeTierAdjustment_pk_columns_input: CompositeChargeTierAdjustment_Pk_Columns_Input;
  CompositeChargeTierAdjustment_select_column: CompositeChargeTierAdjustment_Select_Column;
  CompositeChargeTierAdjustment_set_input: CompositeChargeTierAdjustment_Set_Input;
  CompositeChargeTierAdjustment_stddev_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Stddev_Fields>;
  CompositeChargeTierAdjustment_stddev_order_by: CompositeChargeTierAdjustment_Stddev_Order_By;
  CompositeChargeTierAdjustment_stddev_pop_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Stddev_Pop_Fields>;
  CompositeChargeTierAdjustment_stddev_pop_order_by: CompositeChargeTierAdjustment_Stddev_Pop_Order_By;
  CompositeChargeTierAdjustment_stddev_samp_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Stddev_Samp_Fields>;
  CompositeChargeTierAdjustment_stddev_samp_order_by: CompositeChargeTierAdjustment_Stddev_Samp_Order_By;
  CompositeChargeTierAdjustment_stream_cursor_input: CompositeChargeTierAdjustment_Stream_Cursor_Input;
  CompositeChargeTierAdjustment_stream_cursor_value_input: CompositeChargeTierAdjustment_Stream_Cursor_Value_Input;
  CompositeChargeTierAdjustment_sum_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Sum_Fields>;
  CompositeChargeTierAdjustment_sum_order_by: CompositeChargeTierAdjustment_Sum_Order_By;
  CompositeChargeTierAdjustment_update_column: CompositeChargeTierAdjustment_Update_Column;
  CompositeChargeTierAdjustment_updates: CompositeChargeTierAdjustment_Updates;
  CompositeChargeTierAdjustment_var_pop_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Var_Pop_Fields>;
  CompositeChargeTierAdjustment_var_pop_order_by: CompositeChargeTierAdjustment_Var_Pop_Order_By;
  CompositeChargeTierAdjustment_var_samp_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Var_Samp_Fields>;
  CompositeChargeTierAdjustment_var_samp_order_by: CompositeChargeTierAdjustment_Var_Samp_Order_By;
  CompositeChargeTierAdjustment_variance_fields: ResolverTypeWrapper<CompositeChargeTierAdjustment_Variance_Fields>;
  CompositeChargeTierAdjustment_variance_order_by: CompositeChargeTierAdjustment_Variance_Order_By;
  CompositeChargeTier_aggregate: ResolverTypeWrapper<CompositeChargeTier_Aggregate>;
  CompositeChargeTier_aggregate_bool_exp: CompositeChargeTier_Aggregate_Bool_Exp;
  CompositeChargeTier_aggregate_bool_exp_count: CompositeChargeTier_Aggregate_Bool_Exp_Count;
  CompositeChargeTier_aggregate_fields: ResolverTypeWrapper<CompositeChargeTier_Aggregate_Fields>;
  CompositeChargeTier_aggregate_order_by: CompositeChargeTier_Aggregate_Order_By;
  CompositeChargeTier_arr_rel_insert_input: CompositeChargeTier_Arr_Rel_Insert_Input;
  CompositeChargeTier_avg_fields: ResolverTypeWrapper<CompositeChargeTier_Avg_Fields>;
  CompositeChargeTier_avg_order_by: CompositeChargeTier_Avg_Order_By;
  CompositeChargeTier_bool_exp: CompositeChargeTier_Bool_Exp;
  CompositeChargeTier_constraint: CompositeChargeTier_Constraint;
  CompositeChargeTier_inc_input: CompositeChargeTier_Inc_Input;
  CompositeChargeTier_insert_input: CompositeChargeTier_Insert_Input;
  CompositeChargeTier_max_fields: ResolverTypeWrapper<CompositeChargeTier_Max_Fields>;
  CompositeChargeTier_max_order_by: CompositeChargeTier_Max_Order_By;
  CompositeChargeTier_min_fields: ResolverTypeWrapper<CompositeChargeTier_Min_Fields>;
  CompositeChargeTier_min_order_by: CompositeChargeTier_Min_Order_By;
  CompositeChargeTier_mutation_response: ResolverTypeWrapper<CompositeChargeTier_Mutation_Response>;
  CompositeChargeTier_on_conflict: CompositeChargeTier_On_Conflict;
  CompositeChargeTier_order_by: CompositeChargeTier_Order_By;
  CompositeChargeTier_pk_columns_input: CompositeChargeTier_Pk_Columns_Input;
  CompositeChargeTier_select_column: CompositeChargeTier_Select_Column;
  CompositeChargeTier_set_input: CompositeChargeTier_Set_Input;
  CompositeChargeTier_stddev_fields: ResolverTypeWrapper<CompositeChargeTier_Stddev_Fields>;
  CompositeChargeTier_stddev_order_by: CompositeChargeTier_Stddev_Order_By;
  CompositeChargeTier_stddev_pop_fields: ResolverTypeWrapper<CompositeChargeTier_Stddev_Pop_Fields>;
  CompositeChargeTier_stddev_pop_order_by: CompositeChargeTier_Stddev_Pop_Order_By;
  CompositeChargeTier_stddev_samp_fields: ResolverTypeWrapper<CompositeChargeTier_Stddev_Samp_Fields>;
  CompositeChargeTier_stddev_samp_order_by: CompositeChargeTier_Stddev_Samp_Order_By;
  CompositeChargeTier_stream_cursor_input: CompositeChargeTier_Stream_Cursor_Input;
  CompositeChargeTier_stream_cursor_value_input: CompositeChargeTier_Stream_Cursor_Value_Input;
  CompositeChargeTier_sum_fields: ResolverTypeWrapper<CompositeChargeTier_Sum_Fields>;
  CompositeChargeTier_sum_order_by: CompositeChargeTier_Sum_Order_By;
  CompositeChargeTier_update_column: CompositeChargeTier_Update_Column;
  CompositeChargeTier_updates: CompositeChargeTier_Updates;
  CompositeChargeTier_var_pop_fields: ResolverTypeWrapper<CompositeChargeTier_Var_Pop_Fields>;
  CompositeChargeTier_var_pop_order_by: CompositeChargeTier_Var_Pop_Order_By;
  CompositeChargeTier_var_samp_fields: ResolverTypeWrapper<CompositeChargeTier_Var_Samp_Fields>;
  CompositeChargeTier_var_samp_order_by: CompositeChargeTier_Var_Samp_Order_By;
  CompositeChargeTier_variance_fields: ResolverTypeWrapper<CompositeChargeTier_Variance_Fields>;
  CompositeChargeTier_variance_order_by: CompositeChargeTier_Variance_Order_By;
  CompositeChargeTypeEnum: ResolverTypeWrapper<CompositeChargeTypeEnum>;
  CompositeChargeTypeEnum_aggregate: ResolverTypeWrapper<CompositeChargeTypeEnum_Aggregate>;
  CompositeChargeTypeEnum_aggregate_fields: ResolverTypeWrapper<CompositeChargeTypeEnum_Aggregate_Fields>;
  CompositeChargeTypeEnum_bool_exp: CompositeChargeTypeEnum_Bool_Exp;
  CompositeChargeTypeEnum_constraint: CompositeChargeTypeEnum_Constraint;
  CompositeChargeTypeEnum_enum: CompositeChargeTypeEnum_Enum;
  CompositeChargeTypeEnum_enum_comparison_exp: CompositeChargeTypeEnum_Enum_Comparison_Exp;
  CompositeChargeTypeEnum_insert_input: CompositeChargeTypeEnum_Insert_Input;
  CompositeChargeTypeEnum_max_fields: ResolverTypeWrapper<CompositeChargeTypeEnum_Max_Fields>;
  CompositeChargeTypeEnum_min_fields: ResolverTypeWrapper<CompositeChargeTypeEnum_Min_Fields>;
  CompositeChargeTypeEnum_mutation_response: ResolverTypeWrapper<CompositeChargeTypeEnum_Mutation_Response>;
  CompositeChargeTypeEnum_on_conflict: CompositeChargeTypeEnum_On_Conflict;
  CompositeChargeTypeEnum_order_by: CompositeChargeTypeEnum_Order_By;
  CompositeChargeTypeEnum_pk_columns_input: CompositeChargeTypeEnum_Pk_Columns_Input;
  CompositeChargeTypeEnum_select_column: CompositeChargeTypeEnum_Select_Column;
  CompositeChargeTypeEnum_set_input: CompositeChargeTypeEnum_Set_Input;
  CompositeChargeTypeEnum_stream_cursor_input: CompositeChargeTypeEnum_Stream_Cursor_Input;
  CompositeChargeTypeEnum_stream_cursor_value_input: CompositeChargeTypeEnum_Stream_Cursor_Value_Input;
  CompositeChargeTypeEnum_update_column: CompositeChargeTypeEnum_Update_Column;
  CompositeChargeTypeEnum_updates: CompositeChargeTypeEnum_Updates;
  CompositeCharge_aggregate: ResolverTypeWrapper<CompositeCharge_Aggregate>;
  CompositeCharge_aggregate_bool_exp: CompositeCharge_Aggregate_Bool_Exp;
  CompositeCharge_aggregate_bool_exp_count: CompositeCharge_Aggregate_Bool_Exp_Count;
  CompositeCharge_aggregate_fields: ResolverTypeWrapper<CompositeCharge_Aggregate_Fields>;
  CompositeCharge_aggregate_order_by: CompositeCharge_Aggregate_Order_By;
  CompositeCharge_arr_rel_insert_input: CompositeCharge_Arr_Rel_Insert_Input;
  CompositeCharge_avg_fields: ResolverTypeWrapper<CompositeCharge_Avg_Fields>;
  CompositeCharge_avg_order_by: CompositeCharge_Avg_Order_By;
  CompositeCharge_bool_exp: CompositeCharge_Bool_Exp;
  CompositeCharge_constraint: CompositeCharge_Constraint;
  CompositeCharge_inc_input: CompositeCharge_Inc_Input;
  CompositeCharge_insert_input: CompositeCharge_Insert_Input;
  CompositeCharge_max_fields: ResolverTypeWrapper<CompositeCharge_Max_Fields>;
  CompositeCharge_max_order_by: CompositeCharge_Max_Order_By;
  CompositeCharge_min_fields: ResolverTypeWrapper<CompositeCharge_Min_Fields>;
  CompositeCharge_min_order_by: CompositeCharge_Min_Order_By;
  CompositeCharge_mutation_response: ResolverTypeWrapper<CompositeCharge_Mutation_Response>;
  CompositeCharge_obj_rel_insert_input: CompositeCharge_Obj_Rel_Insert_Input;
  CompositeCharge_on_conflict: CompositeCharge_On_Conflict;
  CompositeCharge_order_by: CompositeCharge_Order_By;
  CompositeCharge_pk_columns_input: CompositeCharge_Pk_Columns_Input;
  CompositeCharge_select_column: CompositeCharge_Select_Column;
  CompositeCharge_set_input: CompositeCharge_Set_Input;
  CompositeCharge_stddev_fields: ResolverTypeWrapper<CompositeCharge_Stddev_Fields>;
  CompositeCharge_stddev_order_by: CompositeCharge_Stddev_Order_By;
  CompositeCharge_stddev_pop_fields: ResolverTypeWrapper<CompositeCharge_Stddev_Pop_Fields>;
  CompositeCharge_stddev_pop_order_by: CompositeCharge_Stddev_Pop_Order_By;
  CompositeCharge_stddev_samp_fields: ResolverTypeWrapper<CompositeCharge_Stddev_Samp_Fields>;
  CompositeCharge_stddev_samp_order_by: CompositeCharge_Stddev_Samp_Order_By;
  CompositeCharge_stream_cursor_input: CompositeCharge_Stream_Cursor_Input;
  CompositeCharge_stream_cursor_value_input: CompositeCharge_Stream_Cursor_Value_Input;
  CompositeCharge_sum_fields: ResolverTypeWrapper<CompositeCharge_Sum_Fields>;
  CompositeCharge_sum_order_by: CompositeCharge_Sum_Order_By;
  CompositeCharge_update_column: CompositeCharge_Update_Column;
  CompositeCharge_updates: CompositeCharge_Updates;
  CompositeCharge_var_pop_fields: ResolverTypeWrapper<CompositeCharge_Var_Pop_Fields>;
  CompositeCharge_var_pop_order_by: CompositeCharge_Var_Pop_Order_By;
  CompositeCharge_var_samp_fields: ResolverTypeWrapper<CompositeCharge_Var_Samp_Fields>;
  CompositeCharge_var_samp_order_by: CompositeCharge_Var_Samp_Order_By;
  CompositeCharge_variance_fields: ResolverTypeWrapper<CompositeCharge_Variance_Fields>;
  CompositeCharge_variance_order_by: CompositeCharge_Variance_Order_By;
  CompositeProductListItem: ResolverTypeWrapper<CompositeProductListItem>;
  CompositeProductListItemState: ResolverTypeWrapper<Omit<CompositeProductListItemState, 'composite_products'> & { composite_products: Maybe<Array<ResolversTypes['NonCompositeProductListItem']>> }>;
  Contract: ResolverTypeWrapper<Omit<Contract, 'commits_union' | 'reseller_royalties'> & { commits_union: Array<ResolversTypes['CommitUnion']>, reseller_royalties: Array<ResolversTypes['ResellerRoyalty']> }>;
  ContractAWSProServiceRoyaltyLineItem: ResolverTypeWrapper<ContractAwsProServiceRoyaltyLineItem>;
  ContractAWSRoyaltyLineItem: ResolverTypeWrapper<ContractAwsRoyaltyLineItem>;
  ContractAmendment: ResolverTypeWrapper<Omit<ContractAmendment, 'commits_union' | 'reseller_royalties'> & { commits_union: Array<ResolversTypes['CommitUnion']>, reseller_royalties: Array<ResolversTypes['ResellerRoyaltyOrUpdate']> }>;
  ContractAppliedCommitLineItem: ResolverTypeWrapper<Omit<ContractAppliedCommitLineItem, 'product'> & { product: ResolversTypes['ContractUsageLineItemProductListItem'] }>;
  ContractChargeLineItem: ResolverTypeWrapper<Omit<ContractChargeLineItem, 'product'> & { product: ResolversTypes['FixedOrProServiceProductListItem'] }>;
  ContractCommitLineItem: ResolverTypeWrapper<Omit<ContractCommitLineItem, 'commit_union'> & { commit_union: ResolversTypes['CommitUnion'] }>;
  ContractCommitMappingInput: ContractCommitMappingInput;
  ContractConsistency: ResolverTypeWrapper<ContractConsistency>;
  ContractConsistency_OutdatedBillingProviderProjection: ResolverTypeWrapper<ContractConsistency_OutdatedBillingProviderProjection>;
  ContractConsistency_OutdatedRate: ResolverTypeWrapper<ContractConsistency_OutdatedRate>;
  ContractConversionLineItem: ResolverTypeWrapper<Omit<ContractConversionLineItem, 'product'> & { product: ResolversTypes['ContractUsageLineItemProductListItem'] }>;
  ContractCreditManagedField: ResolverTypeWrapper<ContractCreditManagedField>;
  ContractCreditManagedField_aggregate: ResolverTypeWrapper<ContractCreditManagedField_Aggregate>;
  ContractCreditManagedField_aggregate_bool_exp: ContractCreditManagedField_Aggregate_Bool_Exp;
  ContractCreditManagedField_aggregate_bool_exp_bool_and: ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractCreditManagedField_aggregate_bool_exp_bool_or: ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractCreditManagedField_aggregate_bool_exp_count: ContractCreditManagedField_Aggregate_Bool_Exp_Count;
  ContractCreditManagedField_aggregate_fields: ResolverTypeWrapper<ContractCreditManagedField_Aggregate_Fields>;
  ContractCreditManagedField_aggregate_order_by: ContractCreditManagedField_Aggregate_Order_By;
  ContractCreditManagedField_arr_rel_insert_input: ContractCreditManagedField_Arr_Rel_Insert_Input;
  ContractCreditManagedField_bool_exp: ContractCreditManagedField_Bool_Exp;
  ContractCreditManagedField_constraint: ContractCreditManagedField_Constraint;
  ContractCreditManagedField_insert_input: ContractCreditManagedField_Insert_Input;
  ContractCreditManagedField_max_fields: ResolverTypeWrapper<ContractCreditManagedField_Max_Fields>;
  ContractCreditManagedField_max_order_by: ContractCreditManagedField_Max_Order_By;
  ContractCreditManagedField_min_fields: ResolverTypeWrapper<ContractCreditManagedField_Min_Fields>;
  ContractCreditManagedField_min_order_by: ContractCreditManagedField_Min_Order_By;
  ContractCreditManagedField_mutation_response: ResolverTypeWrapper<ContractCreditManagedField_Mutation_Response>;
  ContractCreditManagedField_on_conflict: ContractCreditManagedField_On_Conflict;
  ContractCreditManagedField_order_by: ContractCreditManagedField_Order_By;
  ContractCreditManagedField_pk_columns_input: ContractCreditManagedField_Pk_Columns_Input;
  ContractCreditManagedField_select_column: ContractCreditManagedField_Select_Column;
  ContractCreditManagedField_select_column_ContractCreditManagedField_aggregate_bool_exp_bool_and_arguments_columns: ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ContractCreditManagedField_select_column_ContractCreditManagedField_aggregate_bool_exp_bool_or_arguments_columns: ContractCreditManagedField_Select_Column_ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ContractCreditManagedField_set_input: ContractCreditManagedField_Set_Input;
  ContractCreditManagedField_stream_cursor_input: ContractCreditManagedField_Stream_Cursor_Input;
  ContractCreditManagedField_stream_cursor_value_input: ContractCreditManagedField_Stream_Cursor_Value_Input;
  ContractCreditManagedField_update_column: ContractCreditManagedField_Update_Column;
  ContractCreditManagedField_updates: ContractCreditManagedField_Updates;
  ContractDiscountLineItem: ResolverTypeWrapper<ContractDiscountLineItem>;
  ContractGCPProServiceRoyaltyLineItem: ResolverTypeWrapper<ContractGcpProServiceRoyaltyLineItem>;
  ContractGCPRoyaltyLineItem: ResolverTypeWrapper<ContractGcpRoyaltyLineItem>;
  ContractManagedField: ResolverTypeWrapper<ContractManagedField>;
  ContractManagedField_aggregate: ResolverTypeWrapper<ContractManagedField_Aggregate>;
  ContractManagedField_aggregate_bool_exp: ContractManagedField_Aggregate_Bool_Exp;
  ContractManagedField_aggregate_bool_exp_bool_and: ContractManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractManagedField_aggregate_bool_exp_bool_or: ContractManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractManagedField_aggregate_bool_exp_count: ContractManagedField_Aggregate_Bool_Exp_Count;
  ContractManagedField_aggregate_fields: ResolverTypeWrapper<ContractManagedField_Aggregate_Fields>;
  ContractManagedField_aggregate_order_by: ContractManagedField_Aggregate_Order_By;
  ContractManagedField_arr_rel_insert_input: ContractManagedField_Arr_Rel_Insert_Input;
  ContractManagedField_bool_exp: ContractManagedField_Bool_Exp;
  ContractManagedField_constraint: ContractManagedField_Constraint;
  ContractManagedField_insert_input: ContractManagedField_Insert_Input;
  ContractManagedField_max_fields: ResolverTypeWrapper<ContractManagedField_Max_Fields>;
  ContractManagedField_max_order_by: ContractManagedField_Max_Order_By;
  ContractManagedField_min_fields: ResolverTypeWrapper<ContractManagedField_Min_Fields>;
  ContractManagedField_min_order_by: ContractManagedField_Min_Order_By;
  ContractManagedField_mutation_response: ResolverTypeWrapper<ContractManagedField_Mutation_Response>;
  ContractManagedField_on_conflict: ContractManagedField_On_Conflict;
  ContractManagedField_order_by: ContractManagedField_Order_By;
  ContractManagedField_pk_columns_input: ContractManagedField_Pk_Columns_Input;
  ContractManagedField_select_column: ContractManagedField_Select_Column;
  ContractManagedField_select_column_ContractManagedField_aggregate_bool_exp_bool_and_arguments_columns: ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ContractManagedField_select_column_ContractManagedField_aggregate_bool_exp_bool_or_arguments_columns: ContractManagedField_Select_Column_ContractManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ContractManagedField_set_input: ContractManagedField_Set_Input;
  ContractManagedField_stream_cursor_input: ContractManagedField_Stream_Cursor_Input;
  ContractManagedField_stream_cursor_value_input: ContractManagedField_Stream_Cursor_Value_Input;
  ContractManagedField_update_column: ContractManagedField_Update_Column;
  ContractManagedField_updates: ContractManagedField_Updates;
  ContractPage: ResolverTypeWrapper<ContractPage>;
  ContractPostpaidTrueupInvoice: ResolverTypeWrapper<Omit<ContractPostpaidTrueupInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  ContractPostpaidTrueupLineItem: ResolverTypeWrapper<ContractPostpaidTrueupLineItem>;
  ContractPricing: ResolverTypeWrapper<Omit<ContractPricing, 'product' | 'products'> & { product: ResolversTypes['ProductListItem'], products: Array<ResolversTypes['ProductListItem']> }>;
  ContractProServiceInvoice: ResolverTypeWrapper<Omit<ContractProServiceInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  ContractProServiceInvoicesInput: ContractProServiceInvoicesInput;
  ContractProServiceLineItem: ResolverTypeWrapper<ContractProServiceLineItem>;
  ContractProServiceLineItemInput: ContractProServiceLineItemInput;
  ContractProductManagedField: ResolverTypeWrapper<ContractProductManagedField>;
  ContractProductManagedField_aggregate: ResolverTypeWrapper<ContractProductManagedField_Aggregate>;
  ContractProductManagedField_aggregate_bool_exp: ContractProductManagedField_Aggregate_Bool_Exp;
  ContractProductManagedField_aggregate_bool_exp_bool_and: ContractProductManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractProductManagedField_aggregate_bool_exp_bool_or: ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractProductManagedField_aggregate_bool_exp_count: ContractProductManagedField_Aggregate_Bool_Exp_Count;
  ContractProductManagedField_aggregate_fields: ResolverTypeWrapper<ContractProductManagedField_Aggregate_Fields>;
  ContractProductManagedField_aggregate_order_by: ContractProductManagedField_Aggregate_Order_By;
  ContractProductManagedField_arr_rel_insert_input: ContractProductManagedField_Arr_Rel_Insert_Input;
  ContractProductManagedField_bool_exp: ContractProductManagedField_Bool_Exp;
  ContractProductManagedField_constraint: ContractProductManagedField_Constraint;
  ContractProductManagedField_insert_input: ContractProductManagedField_Insert_Input;
  ContractProductManagedField_max_fields: ResolverTypeWrapper<ContractProductManagedField_Max_Fields>;
  ContractProductManagedField_max_order_by: ContractProductManagedField_Max_Order_By;
  ContractProductManagedField_min_fields: ResolverTypeWrapper<ContractProductManagedField_Min_Fields>;
  ContractProductManagedField_min_order_by: ContractProductManagedField_Min_Order_By;
  ContractProductManagedField_mutation_response: ResolverTypeWrapper<ContractProductManagedField_Mutation_Response>;
  ContractProductManagedField_on_conflict: ContractProductManagedField_On_Conflict;
  ContractProductManagedField_order_by: ContractProductManagedField_Order_By;
  ContractProductManagedField_pk_columns_input: ContractProductManagedField_Pk_Columns_Input;
  ContractProductManagedField_select_column: ContractProductManagedField_Select_Column;
  ContractProductManagedField_select_column_ContractProductManagedField_aggregate_bool_exp_bool_and_arguments_columns: ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ContractProductManagedField_select_column_ContractProductManagedField_aggregate_bool_exp_bool_or_arguments_columns: ContractProductManagedField_Select_Column_ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ContractProductManagedField_set_input: ContractProductManagedField_Set_Input;
  ContractProductManagedField_stream_cursor_input: ContractProductManagedField_Stream_Cursor_Input;
  ContractProductManagedField_stream_cursor_value_input: ContractProductManagedField_Stream_Cursor_Value_Input;
  ContractProductManagedField_update_column: ContractProductManagedField_Update_Column;
  ContractProductManagedField_updates: ContractProductManagedField_Updates;
  ContractRateSchedule: ResolverTypeWrapper<Omit<ContractRateSchedule, 'products_on_segments'> & { products_on_segments: Array<ResolversTypes['ProductListItem']> }>;
  ContractRateScheduleSegment: ResolverTypeWrapper<Omit<ContractRateScheduleSegment, 'list_rate' | 'override_rate' | 'product'> & { list_rate: ResolversTypes['Rate'], override_rate: Maybe<ResolversTypes['Rate']>, product: ResolversTypes['ProductListItem'] }>;
  ContractRefundInvoice: ResolverTypeWrapper<Omit<ContractRefundInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  ContractRefundInvoiceCorrectionRecord: ResolverTypeWrapper<Omit<ContractRefundInvoiceCorrectionRecord, 'original_invoice'> & { original_invoice: ResolversTypes['ContractRefundInvoiceCorrectionRecordOriginalInvoice'] }>;
  ContractRefundInvoiceCorrectionRecordOriginalInvoice: ResolversTypes['ContractPostpaidTrueupInvoice'] | ResolversTypes['ContractScheduledInvoice'];
  ContractRolloverCommitLineItem: ResolverTypeWrapper<Omit<ContractRolloverCommitLineItem, 'product' | 'rollover_commit_union'> & { product: ResolversTypes['ContractUsageLineItemProductListItem'], rollover_commit_union: ResolversTypes['CommitUnion'] }>;
  ContractScheduledInvoice: ResolverTypeWrapper<Omit<ContractScheduledInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  ContractScheduledLineItem: ResolversTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversTypes['ContractAWSRoyaltyLineItem'] | ResolversTypes['ContractChargeLineItem'] | ResolversTypes['ContractCommitLineItem'] | ResolversTypes['ContractDiscountLineItem'] | ResolversTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversTypes['ContractGCPRoyaltyLineItem'];
  ContractStats: ResolverTypeWrapper<ContractStats>;
  ContractSubscriptionLineItem: ResolverTypeWrapper<ContractSubscriptionLineItem>;
  ContractUsageInvoice: ResolverTypeWrapper<Omit<ContractUsageInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  ContractUsageInvoiceBillingCycleAnchorEnum: ContractUsageInvoiceBillingCycleAnchorEnum;
  ContractUsageInvoiceSchedule: ResolverTypeWrapper<ContractUsageInvoiceSchedule>;
  ContractUsageInvoiceScheduleFrequencyEnum: ContractUsageInvoiceScheduleFrequencyEnum;
  ContractUsageLineItem: ResolverTypeWrapper<Omit<ContractUsageLineItem, 'product'> & { product: ResolversTypes['ContractUsageLineItemProductListItem'] }>;
  ContractUsageLineItemProductListItem: ResolversTypes['CompositeProductListItem'] | ResolversTypes['SubscriptionProductListItem'] | ResolversTypes['UsageProductListItem'];
  ConversionOperation: ConversionOperation;
  CorrectableInvoice: ResolversTypes['ArrearsInvoice'] | ResolversTypes['ContractPostpaidTrueupInvoice'] | ResolversTypes['ContractScheduledInvoice'] | ResolversTypes['ContractUsageInvoice'];
  CorrectionInvoice: ResolverTypeWrapper<Omit<CorrectionInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  Cost: ResolverTypeWrapper<Cost>;
  CostWindow: ResolverTypeWrapper<CostWindow>;
  CreateBillingProviderSettingsInput: CreateBillingProviderSettingsInput;
  CreateContractUsageFilterInput: CreateContractUsageFilterInput;
  CreateContractUsageInvoiceScheduleInput: CreateContractUsageInvoiceScheduleInput;
  CreateStripeSettingsInput: CreateStripeSettingsInput;
  CreditAdjustment: ResolverTypeWrapper<CreditAdjustment>;
  CreditAdjustment_aggregate: ResolverTypeWrapper<CreditAdjustment_Aggregate>;
  CreditAdjustment_aggregate_fields: ResolverTypeWrapper<CreditAdjustment_Aggregate_Fields>;
  CreditAdjustment_avg_fields: ResolverTypeWrapper<CreditAdjustment_Avg_Fields>;
  CreditAdjustment_bool_exp: CreditAdjustment_Bool_Exp;
  CreditAdjustment_constraint: CreditAdjustment_Constraint;
  CreditAdjustment_inc_input: CreditAdjustment_Inc_Input;
  CreditAdjustment_insert_input: CreditAdjustment_Insert_Input;
  CreditAdjustment_max_fields: ResolverTypeWrapper<CreditAdjustment_Max_Fields>;
  CreditAdjustment_min_fields: ResolverTypeWrapper<CreditAdjustment_Min_Fields>;
  CreditAdjustment_mutation_response: ResolverTypeWrapper<CreditAdjustment_Mutation_Response>;
  CreditAdjustment_on_conflict: CreditAdjustment_On_Conflict;
  CreditAdjustment_order_by: CreditAdjustment_Order_By;
  CreditAdjustment_pk_columns_input: CreditAdjustment_Pk_Columns_Input;
  CreditAdjustment_select_column: CreditAdjustment_Select_Column;
  CreditAdjustment_set_input: CreditAdjustment_Set_Input;
  CreditAdjustment_stddev_fields: ResolverTypeWrapper<CreditAdjustment_Stddev_Fields>;
  CreditAdjustment_stddev_pop_fields: ResolverTypeWrapper<CreditAdjustment_Stddev_Pop_Fields>;
  CreditAdjustment_stddev_samp_fields: ResolverTypeWrapper<CreditAdjustment_Stddev_Samp_Fields>;
  CreditAdjustment_stream_cursor_input: CreditAdjustment_Stream_Cursor_Input;
  CreditAdjustment_stream_cursor_value_input: CreditAdjustment_Stream_Cursor_Value_Input;
  CreditAdjustment_sum_fields: ResolverTypeWrapper<CreditAdjustment_Sum_Fields>;
  CreditAdjustment_update_column: CreditAdjustment_Update_Column;
  CreditAdjustment_updates: CreditAdjustment_Updates;
  CreditAdjustment_var_pop_fields: ResolverTypeWrapper<CreditAdjustment_Var_Pop_Fields>;
  CreditAdjustment_var_samp_fields: ResolverTypeWrapper<CreditAdjustment_Var_Samp_Fields>;
  CreditAdjustment_variance_fields: ResolverTypeWrapper<CreditAdjustment_Variance_Fields>;
  CreditAmount: ResolverTypeWrapper<Scalars['CreditAmount']>;
  CreditAmountWithType: ResolverTypeWrapper<CreditAmountWithType>;
  CreditGrant: ResolverTypeWrapper<CreditGrant>;
  CreditGrantDeduction: ResolverTypeWrapper<CreditGrantDeduction>;
  CreditGrantDeduction_aggregate: ResolverTypeWrapper<CreditGrantDeduction_Aggregate>;
  CreditGrantDeduction_aggregate_bool_exp: CreditGrantDeduction_Aggregate_Bool_Exp;
  CreditGrantDeduction_aggregate_bool_exp_count: CreditGrantDeduction_Aggregate_Bool_Exp_Count;
  CreditGrantDeduction_aggregate_fields: ResolverTypeWrapper<CreditGrantDeduction_Aggregate_Fields>;
  CreditGrantDeduction_aggregate_order_by: CreditGrantDeduction_Aggregate_Order_By;
  CreditGrantDeduction_arr_rel_insert_input: CreditGrantDeduction_Arr_Rel_Insert_Input;
  CreditGrantDeduction_avg_fields: ResolverTypeWrapper<CreditGrantDeduction_Avg_Fields>;
  CreditGrantDeduction_avg_order_by: CreditGrantDeduction_Avg_Order_By;
  CreditGrantDeduction_bool_exp: CreditGrantDeduction_Bool_Exp;
  CreditGrantDeduction_constraint: CreditGrantDeduction_Constraint;
  CreditGrantDeduction_inc_input: CreditGrantDeduction_Inc_Input;
  CreditGrantDeduction_insert_input: CreditGrantDeduction_Insert_Input;
  CreditGrantDeduction_max_fields: ResolverTypeWrapper<CreditGrantDeduction_Max_Fields>;
  CreditGrantDeduction_max_order_by: CreditGrantDeduction_Max_Order_By;
  CreditGrantDeduction_min_fields: ResolverTypeWrapper<CreditGrantDeduction_Min_Fields>;
  CreditGrantDeduction_min_order_by: CreditGrantDeduction_Min_Order_By;
  CreditGrantDeduction_mutation_response: ResolverTypeWrapper<CreditGrantDeduction_Mutation_Response>;
  CreditGrantDeduction_on_conflict: CreditGrantDeduction_On_Conflict;
  CreditGrantDeduction_order_by: CreditGrantDeduction_Order_By;
  CreditGrantDeduction_pk_columns_input: CreditGrantDeduction_Pk_Columns_Input;
  CreditGrantDeduction_select_column: CreditGrantDeduction_Select_Column;
  CreditGrantDeduction_set_input: CreditGrantDeduction_Set_Input;
  CreditGrantDeduction_stddev_fields: ResolverTypeWrapper<CreditGrantDeduction_Stddev_Fields>;
  CreditGrantDeduction_stddev_order_by: CreditGrantDeduction_Stddev_Order_By;
  CreditGrantDeduction_stddev_pop_fields: ResolverTypeWrapper<CreditGrantDeduction_Stddev_Pop_Fields>;
  CreditGrantDeduction_stddev_pop_order_by: CreditGrantDeduction_Stddev_Pop_Order_By;
  CreditGrantDeduction_stddev_samp_fields: ResolverTypeWrapper<CreditGrantDeduction_Stddev_Samp_Fields>;
  CreditGrantDeduction_stddev_samp_order_by: CreditGrantDeduction_Stddev_Samp_Order_By;
  CreditGrantDeduction_stream_cursor_input: CreditGrantDeduction_Stream_Cursor_Input;
  CreditGrantDeduction_stream_cursor_value_input: CreditGrantDeduction_Stream_Cursor_Value_Input;
  CreditGrantDeduction_sum_fields: ResolverTypeWrapper<CreditGrantDeduction_Sum_Fields>;
  CreditGrantDeduction_sum_order_by: CreditGrantDeduction_Sum_Order_By;
  CreditGrantDeduction_update_column: CreditGrantDeduction_Update_Column;
  CreditGrantDeduction_updates: CreditGrantDeduction_Updates;
  CreditGrantDeduction_var_pop_fields: ResolverTypeWrapper<CreditGrantDeduction_Var_Pop_Fields>;
  CreditGrantDeduction_var_pop_order_by: CreditGrantDeduction_Var_Pop_Order_By;
  CreditGrantDeduction_var_samp_fields: ResolverTypeWrapper<CreditGrantDeduction_Var_Samp_Fields>;
  CreditGrantDeduction_var_samp_order_by: CreditGrantDeduction_Var_Samp_Order_By;
  CreditGrantDeduction_variance_fields: ResolverTypeWrapper<CreditGrantDeduction_Variance_Fields>;
  CreditGrantDeduction_variance_order_by: CreditGrantDeduction_Variance_Order_By;
  CreditGrantHistory: ResolverTypeWrapper<CreditGrantHistory>;
  CreditGrantHistory_aggregate: ResolverTypeWrapper<CreditGrantHistory_Aggregate>;
  CreditGrantHistory_aggregate_fields: ResolverTypeWrapper<CreditGrantHistory_Aggregate_Fields>;
  CreditGrantHistory_append_input: CreditGrantHistory_Append_Input;
  CreditGrantHistory_avg_fields: ResolverTypeWrapper<CreditGrantHistory_Avg_Fields>;
  CreditGrantHistory_bool_exp: CreditGrantHistory_Bool_Exp;
  CreditGrantHistory_constraint: CreditGrantHistory_Constraint;
  CreditGrantHistory_delete_at_path_input: CreditGrantHistory_Delete_At_Path_Input;
  CreditGrantHistory_delete_elem_input: CreditGrantHistory_Delete_Elem_Input;
  CreditGrantHistory_delete_key_input: CreditGrantHistory_Delete_Key_Input;
  CreditGrantHistory_inc_input: CreditGrantHistory_Inc_Input;
  CreditGrantHistory_insert_input: CreditGrantHistory_Insert_Input;
  CreditGrantHistory_max_fields: ResolverTypeWrapper<CreditGrantHistory_Max_Fields>;
  CreditGrantHistory_min_fields: ResolverTypeWrapper<CreditGrantHistory_Min_Fields>;
  CreditGrantHistory_mutation_response: ResolverTypeWrapper<CreditGrantHistory_Mutation_Response>;
  CreditGrantHistory_on_conflict: CreditGrantHistory_On_Conflict;
  CreditGrantHistory_order_by: CreditGrantHistory_Order_By;
  CreditGrantHistory_pk_columns_input: CreditGrantHistory_Pk_Columns_Input;
  CreditGrantHistory_prepend_input: CreditGrantHistory_Prepend_Input;
  CreditGrantHistory_select_column: CreditGrantHistory_Select_Column;
  CreditGrantHistory_set_input: CreditGrantHistory_Set_Input;
  CreditGrantHistory_stddev_fields: ResolverTypeWrapper<CreditGrantHistory_Stddev_Fields>;
  CreditGrantHistory_stddev_pop_fields: ResolverTypeWrapper<CreditGrantHistory_Stddev_Pop_Fields>;
  CreditGrantHistory_stddev_samp_fields: ResolverTypeWrapper<CreditGrantHistory_Stddev_Samp_Fields>;
  CreditGrantHistory_stream_cursor_input: CreditGrantHistory_Stream_Cursor_Input;
  CreditGrantHistory_stream_cursor_value_input: CreditGrantHistory_Stream_Cursor_Value_Input;
  CreditGrantHistory_sum_fields: ResolverTypeWrapper<CreditGrantHistory_Sum_Fields>;
  CreditGrantHistory_update_column: CreditGrantHistory_Update_Column;
  CreditGrantHistory_updates: CreditGrantHistory_Updates;
  CreditGrantHistory_var_pop_fields: ResolverTypeWrapper<CreditGrantHistory_Var_Pop_Fields>;
  CreditGrantHistory_var_samp_fields: ResolverTypeWrapper<CreditGrantHistory_Var_Samp_Fields>;
  CreditGrantHistory_variance_fields: ResolverTypeWrapper<CreditGrantHistory_Variance_Fields>;
  CreditGrantInput: CreditGrantInput;
  CreditGrantManagedField: ResolverTypeWrapper<CreditGrantManagedField>;
  CreditGrantManagedField_aggregate: ResolverTypeWrapper<CreditGrantManagedField_Aggregate>;
  CreditGrantManagedField_aggregate_bool_exp: CreditGrantManagedField_Aggregate_Bool_Exp;
  CreditGrantManagedField_aggregate_bool_exp_bool_and: CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And;
  CreditGrantManagedField_aggregate_bool_exp_bool_or: CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or;
  CreditGrantManagedField_aggregate_bool_exp_count: CreditGrantManagedField_Aggregate_Bool_Exp_Count;
  CreditGrantManagedField_aggregate_fields: ResolverTypeWrapper<CreditGrantManagedField_Aggregate_Fields>;
  CreditGrantManagedField_aggregate_order_by: CreditGrantManagedField_Aggregate_Order_By;
  CreditGrantManagedField_arr_rel_insert_input: CreditGrantManagedField_Arr_Rel_Insert_Input;
  CreditGrantManagedField_bool_exp: CreditGrantManagedField_Bool_Exp;
  CreditGrantManagedField_constraint: CreditGrantManagedField_Constraint;
  CreditGrantManagedField_insert_input: CreditGrantManagedField_Insert_Input;
  CreditGrantManagedField_max_fields: ResolverTypeWrapper<CreditGrantManagedField_Max_Fields>;
  CreditGrantManagedField_max_order_by: CreditGrantManagedField_Max_Order_By;
  CreditGrantManagedField_min_fields: ResolverTypeWrapper<CreditGrantManagedField_Min_Fields>;
  CreditGrantManagedField_min_order_by: CreditGrantManagedField_Min_Order_By;
  CreditGrantManagedField_mutation_response: ResolverTypeWrapper<CreditGrantManagedField_Mutation_Response>;
  CreditGrantManagedField_on_conflict: CreditGrantManagedField_On_Conflict;
  CreditGrantManagedField_order_by: CreditGrantManagedField_Order_By;
  CreditGrantManagedField_pk_columns_input: CreditGrantManagedField_Pk_Columns_Input;
  CreditGrantManagedField_select_column: CreditGrantManagedField_Select_Column;
  CreditGrantManagedField_select_column_CreditGrantManagedField_aggregate_bool_exp_bool_and_arguments_columns: CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  CreditGrantManagedField_select_column_CreditGrantManagedField_aggregate_bool_exp_bool_or_arguments_columns: CreditGrantManagedField_Select_Column_CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  CreditGrantManagedField_set_input: CreditGrantManagedField_Set_Input;
  CreditGrantManagedField_stream_cursor_input: CreditGrantManagedField_Stream_Cursor_Input;
  CreditGrantManagedField_stream_cursor_value_input: CreditGrantManagedField_Stream_Cursor_Value_Input;
  CreditGrantManagedField_update_column: CreditGrantManagedField_Update_Column;
  CreditGrantManagedField_updates: CreditGrantManagedField_Updates;
  CreditGrantPage: ResolverTypeWrapper<CreditGrantPage>;
  CreditGrantUnion: ResolversTypes['CreditGrant'] | ResolversTypes['MRI_CreditGrant'] | ResolversTypes['PrepaidCreditGrant'];
  CreditGrant_aggregate: ResolverTypeWrapper<CreditGrant_Aggregate>;
  CreditGrant_aggregate_bool_exp: CreditGrant_Aggregate_Bool_Exp;
  CreditGrant_aggregate_bool_exp_count: CreditGrant_Aggregate_Bool_Exp_Count;
  CreditGrant_aggregate_fields: ResolverTypeWrapper<CreditGrant_Aggregate_Fields>;
  CreditGrant_aggregate_order_by: CreditGrant_Aggregate_Order_By;
  CreditGrant_append_input: CreditGrant_Append_Input;
  CreditGrant_arr_rel_insert_input: CreditGrant_Arr_Rel_Insert_Input;
  CreditGrant_avg_fields: ResolverTypeWrapper<CreditGrant_Avg_Fields>;
  CreditGrant_avg_order_by: CreditGrant_Avg_Order_By;
  CreditGrant_bool_exp: CreditGrant_Bool_Exp;
  CreditGrant_constraint: CreditGrant_Constraint;
  CreditGrant_delete_at_path_input: CreditGrant_Delete_At_Path_Input;
  CreditGrant_delete_elem_input: CreditGrant_Delete_Elem_Input;
  CreditGrant_delete_key_input: CreditGrant_Delete_Key_Input;
  CreditGrant_inc_input: CreditGrant_Inc_Input;
  CreditGrant_insert_input: CreditGrant_Insert_Input;
  CreditGrant_max_fields: ResolverTypeWrapper<CreditGrant_Max_Fields>;
  CreditGrant_max_order_by: CreditGrant_Max_Order_By;
  CreditGrant_min_fields: ResolverTypeWrapper<CreditGrant_Min_Fields>;
  CreditGrant_min_order_by: CreditGrant_Min_Order_By;
  CreditGrant_mutation_response: ResolverTypeWrapper<CreditGrant_Mutation_Response>;
  CreditGrant_obj_rel_insert_input: CreditGrant_Obj_Rel_Insert_Input;
  CreditGrant_on_conflict: CreditGrant_On_Conflict;
  CreditGrant_order_by: CreditGrant_Order_By;
  CreditGrant_pk_columns_input: CreditGrant_Pk_Columns_Input;
  CreditGrant_prepend_input: CreditGrant_Prepend_Input;
  CreditGrant_select_column: CreditGrant_Select_Column;
  CreditGrant_set_input: CreditGrant_Set_Input;
  CreditGrant_stddev_fields: ResolverTypeWrapper<CreditGrant_Stddev_Fields>;
  CreditGrant_stddev_order_by: CreditGrant_Stddev_Order_By;
  CreditGrant_stddev_pop_fields: ResolverTypeWrapper<CreditGrant_Stddev_Pop_Fields>;
  CreditGrant_stddev_pop_order_by: CreditGrant_Stddev_Pop_Order_By;
  CreditGrant_stddev_samp_fields: ResolverTypeWrapper<CreditGrant_Stddev_Samp_Fields>;
  CreditGrant_stddev_samp_order_by: CreditGrant_Stddev_Samp_Order_By;
  CreditGrant_stream_cursor_input: CreditGrant_Stream_Cursor_Input;
  CreditGrant_stream_cursor_value_input: CreditGrant_Stream_Cursor_Value_Input;
  CreditGrant_sum_fields: ResolverTypeWrapper<CreditGrant_Sum_Fields>;
  CreditGrant_sum_order_by: CreditGrant_Sum_Order_By;
  CreditGrant_update_column: CreditGrant_Update_Column;
  CreditGrant_updates: CreditGrant_Updates;
  CreditGrant_var_pop_fields: ResolverTypeWrapper<CreditGrant_Var_Pop_Fields>;
  CreditGrant_var_pop_order_by: CreditGrant_Var_Pop_Order_By;
  CreditGrant_var_samp_fields: ResolverTypeWrapper<CreditGrant_Var_Samp_Fields>;
  CreditGrant_var_samp_order_by: CreditGrant_Var_Samp_Order_By;
  CreditGrant_variance_fields: ResolverTypeWrapper<CreditGrant_Variance_Fields>;
  CreditGrant_variance_order_by: CreditGrant_Variance_Order_By;
  CreditLineItem: ResolverTypeWrapper<CreditLineItem>;
  CreditPurchaseChargeLineItem: ResolverTypeWrapper<CreditPurchaseChargeLineItem>;
  CreditPurchaseInvoice: ResolverTypeWrapper<Omit<CreditPurchaseInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  CreditPurchaseLineItem: ResolverTypeWrapper<Omit<CreditPurchaseLineItem, 'credit_grant'> & { credit_grant: ResolversTypes['CreditGrantUnion'] }>;
  CreditType: ResolverTypeWrapper<CreditType>;
  CreditTypeConversion: ResolverTypeWrapper<CreditTypeConversion>;
  CreditTypeConversionAdjustment: ResolverTypeWrapper<CreditTypeConversionAdjustment>;
  CreditTypeConversionAdjustment_aggregate: ResolverTypeWrapper<CreditTypeConversionAdjustment_Aggregate>;
  CreditTypeConversionAdjustment_aggregate_bool_exp: CreditTypeConversionAdjustment_Aggregate_Bool_Exp;
  CreditTypeConversionAdjustment_aggregate_bool_exp_count: CreditTypeConversionAdjustment_Aggregate_Bool_Exp_Count;
  CreditTypeConversionAdjustment_aggregate_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Aggregate_Fields>;
  CreditTypeConversionAdjustment_aggregate_order_by: CreditTypeConversionAdjustment_Aggregate_Order_By;
  CreditTypeConversionAdjustment_arr_rel_insert_input: CreditTypeConversionAdjustment_Arr_Rel_Insert_Input;
  CreditTypeConversionAdjustment_avg_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Avg_Fields>;
  CreditTypeConversionAdjustment_avg_order_by: CreditTypeConversionAdjustment_Avg_Order_By;
  CreditTypeConversionAdjustment_bool_exp: CreditTypeConversionAdjustment_Bool_Exp;
  CreditTypeConversionAdjustment_constraint: CreditTypeConversionAdjustment_Constraint;
  CreditTypeConversionAdjustment_inc_input: CreditTypeConversionAdjustment_Inc_Input;
  CreditTypeConversionAdjustment_insert_input: CreditTypeConversionAdjustment_Insert_Input;
  CreditTypeConversionAdjustment_max_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Max_Fields>;
  CreditTypeConversionAdjustment_max_order_by: CreditTypeConversionAdjustment_Max_Order_By;
  CreditTypeConversionAdjustment_min_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Min_Fields>;
  CreditTypeConversionAdjustment_min_order_by: CreditTypeConversionAdjustment_Min_Order_By;
  CreditTypeConversionAdjustment_mutation_response: ResolverTypeWrapper<CreditTypeConversionAdjustment_Mutation_Response>;
  CreditTypeConversionAdjustment_on_conflict: CreditTypeConversionAdjustment_On_Conflict;
  CreditTypeConversionAdjustment_order_by: CreditTypeConversionAdjustment_Order_By;
  CreditTypeConversionAdjustment_pk_columns_input: CreditTypeConversionAdjustment_Pk_Columns_Input;
  CreditTypeConversionAdjustment_select_column: CreditTypeConversionAdjustment_Select_Column;
  CreditTypeConversionAdjustment_set_input: CreditTypeConversionAdjustment_Set_Input;
  CreditTypeConversionAdjustment_stddev_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Stddev_Fields>;
  CreditTypeConversionAdjustment_stddev_order_by: CreditTypeConversionAdjustment_Stddev_Order_By;
  CreditTypeConversionAdjustment_stddev_pop_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Stddev_Pop_Fields>;
  CreditTypeConversionAdjustment_stddev_pop_order_by: CreditTypeConversionAdjustment_Stddev_Pop_Order_By;
  CreditTypeConversionAdjustment_stddev_samp_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Stddev_Samp_Fields>;
  CreditTypeConversionAdjustment_stddev_samp_order_by: CreditTypeConversionAdjustment_Stddev_Samp_Order_By;
  CreditTypeConversionAdjustment_stream_cursor_input: CreditTypeConversionAdjustment_Stream_Cursor_Input;
  CreditTypeConversionAdjustment_stream_cursor_value_input: CreditTypeConversionAdjustment_Stream_Cursor_Value_Input;
  CreditTypeConversionAdjustment_sum_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Sum_Fields>;
  CreditTypeConversionAdjustment_sum_order_by: CreditTypeConversionAdjustment_Sum_Order_By;
  CreditTypeConversionAdjustment_update_column: CreditTypeConversionAdjustment_Update_Column;
  CreditTypeConversionAdjustment_updates: CreditTypeConversionAdjustment_Updates;
  CreditTypeConversionAdjustment_var_pop_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Var_Pop_Fields>;
  CreditTypeConversionAdjustment_var_pop_order_by: CreditTypeConversionAdjustment_Var_Pop_Order_By;
  CreditTypeConversionAdjustment_var_samp_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Var_Samp_Fields>;
  CreditTypeConversionAdjustment_var_samp_order_by: CreditTypeConversionAdjustment_Var_Samp_Order_By;
  CreditTypeConversionAdjustment_variance_fields: ResolverTypeWrapper<CreditTypeConversionAdjustment_Variance_Fields>;
  CreditTypeConversionAdjustment_variance_order_by: CreditTypeConversionAdjustment_Variance_Order_By;
  CreditTypeConversionInput: CreditTypeConversionInput;
  CreditTypeConversion_aggregate: ResolverTypeWrapper<CreditTypeConversion_Aggregate>;
  CreditTypeConversion_aggregate_bool_exp: CreditTypeConversion_Aggregate_Bool_Exp;
  CreditTypeConversion_aggregate_bool_exp_count: CreditTypeConversion_Aggregate_Bool_Exp_Count;
  CreditTypeConversion_aggregate_fields: ResolverTypeWrapper<CreditTypeConversion_Aggregate_Fields>;
  CreditTypeConversion_aggregate_order_by: CreditTypeConversion_Aggregate_Order_By;
  CreditTypeConversion_arr_rel_insert_input: CreditTypeConversion_Arr_Rel_Insert_Input;
  CreditTypeConversion_avg_fields: ResolverTypeWrapper<CreditTypeConversion_Avg_Fields>;
  CreditTypeConversion_avg_order_by: CreditTypeConversion_Avg_Order_By;
  CreditTypeConversion_bool_exp: CreditTypeConversion_Bool_Exp;
  CreditTypeConversion_constraint: CreditTypeConversion_Constraint;
  CreditTypeConversion_inc_input: CreditTypeConversion_Inc_Input;
  CreditTypeConversion_insert_input: CreditTypeConversion_Insert_Input;
  CreditTypeConversion_max_fields: ResolverTypeWrapper<CreditTypeConversion_Max_Fields>;
  CreditTypeConversion_max_order_by: CreditTypeConversion_Max_Order_By;
  CreditTypeConversion_min_fields: ResolverTypeWrapper<CreditTypeConversion_Min_Fields>;
  CreditTypeConversion_min_order_by: CreditTypeConversion_Min_Order_By;
  CreditTypeConversion_mutation_response: ResolverTypeWrapper<CreditTypeConversion_Mutation_Response>;
  CreditTypeConversion_on_conflict: CreditTypeConversion_On_Conflict;
  CreditTypeConversion_order_by: CreditTypeConversion_Order_By;
  CreditTypeConversion_pk_columns_input: CreditTypeConversion_Pk_Columns_Input;
  CreditTypeConversion_select_column: CreditTypeConversion_Select_Column;
  CreditTypeConversion_set_input: CreditTypeConversion_Set_Input;
  CreditTypeConversion_stddev_fields: ResolverTypeWrapper<CreditTypeConversion_Stddev_Fields>;
  CreditTypeConversion_stddev_order_by: CreditTypeConversion_Stddev_Order_By;
  CreditTypeConversion_stddev_pop_fields: ResolverTypeWrapper<CreditTypeConversion_Stddev_Pop_Fields>;
  CreditTypeConversion_stddev_pop_order_by: CreditTypeConversion_Stddev_Pop_Order_By;
  CreditTypeConversion_stddev_samp_fields: ResolverTypeWrapper<CreditTypeConversion_Stddev_Samp_Fields>;
  CreditTypeConversion_stddev_samp_order_by: CreditTypeConversion_Stddev_Samp_Order_By;
  CreditTypeConversion_stream_cursor_input: CreditTypeConversion_Stream_Cursor_Input;
  CreditTypeConversion_stream_cursor_value_input: CreditTypeConversion_Stream_Cursor_Value_Input;
  CreditTypeConversion_sum_fields: ResolverTypeWrapper<CreditTypeConversion_Sum_Fields>;
  CreditTypeConversion_sum_order_by: CreditTypeConversion_Sum_Order_By;
  CreditTypeConversion_update_column: CreditTypeConversion_Update_Column;
  CreditTypeConversion_updates: CreditTypeConversion_Updates;
  CreditTypeConversion_var_pop_fields: ResolverTypeWrapper<CreditTypeConversion_Var_Pop_Fields>;
  CreditTypeConversion_var_pop_order_by: CreditTypeConversion_Var_Pop_Order_By;
  CreditTypeConversion_var_samp_fields: ResolverTypeWrapper<CreditTypeConversion_Var_Samp_Fields>;
  CreditTypeConversion_var_samp_order_by: CreditTypeConversion_Var_Samp_Order_By;
  CreditTypeConversion_variance_fields: ResolverTypeWrapper<CreditTypeConversion_Variance_Fields>;
  CreditTypeConversion_variance_order_by: CreditTypeConversion_Variance_Order_By;
  CreditType_aggregate: ResolverTypeWrapper<CreditType_Aggregate>;
  CreditType_aggregate_fields: ResolverTypeWrapper<CreditType_Aggregate_Fields>;
  CreditType_bool_exp: CreditType_Bool_Exp;
  CreditType_constraint: CreditType_Constraint;
  CreditType_insert_input: CreditType_Insert_Input;
  CreditType_max_fields: ResolverTypeWrapper<CreditType_Max_Fields>;
  CreditType_min_fields: ResolverTypeWrapper<CreditType_Min_Fields>;
  CreditType_mutation_response: ResolverTypeWrapper<CreditType_Mutation_Response>;
  CreditType_obj_rel_insert_input: CreditType_Obj_Rel_Insert_Input;
  CreditType_on_conflict: CreditType_On_Conflict;
  CreditType_order_by: CreditType_Order_By;
  CreditType_pk_columns_input: CreditType_Pk_Columns_Input;
  CreditType_select_column: CreditType_Select_Column;
  CreditType_set_input: CreditType_Set_Input;
  CreditType_stream_cursor_input: CreditType_Stream_Cursor_Input;
  CreditType_stream_cursor_value_input: CreditType_Stream_Cursor_Value_Input;
  CreditType_update_column: CreditType_Update_Column;
  CreditType_updates: CreditType_Updates;
  CurrentPlanUsage: ResolverTypeWrapper<CurrentPlanUsage>;
  CustomPricing: ResolverTypeWrapper<CustomPricing>;
  CustomPricingInput: CustomPricingInput;
  CustomPricing_aggregate: ResolverTypeWrapper<CustomPricing_Aggregate>;
  CustomPricing_aggregate_bool_exp: CustomPricing_Aggregate_Bool_Exp;
  CustomPricing_aggregate_bool_exp_count: CustomPricing_Aggregate_Bool_Exp_Count;
  CustomPricing_aggregate_fields: ResolverTypeWrapper<CustomPricing_Aggregate_Fields>;
  CustomPricing_aggregate_order_by: CustomPricing_Aggregate_Order_By;
  CustomPricing_arr_rel_insert_input: CustomPricing_Arr_Rel_Insert_Input;
  CustomPricing_bool_exp: CustomPricing_Bool_Exp;
  CustomPricing_constraint: CustomPricing_Constraint;
  CustomPricing_insert_input: CustomPricing_Insert_Input;
  CustomPricing_max_fields: ResolverTypeWrapper<CustomPricing_Max_Fields>;
  CustomPricing_max_order_by: CustomPricing_Max_Order_By;
  CustomPricing_min_fields: ResolverTypeWrapper<CustomPricing_Min_Fields>;
  CustomPricing_min_order_by: CustomPricing_Min_Order_By;
  CustomPricing_mutation_response: ResolverTypeWrapper<CustomPricing_Mutation_Response>;
  CustomPricing_obj_rel_insert_input: CustomPricing_Obj_Rel_Insert_Input;
  CustomPricing_on_conflict: CustomPricing_On_Conflict;
  CustomPricing_order_by: CustomPricing_Order_By;
  CustomPricing_pk_columns_input: CustomPricing_Pk_Columns_Input;
  CustomPricing_select_column: CustomPricing_Select_Column;
  CustomPricing_set_input: CustomPricing_Set_Input;
  CustomPricing_stream_cursor_input: CustomPricing_Stream_Cursor_Input;
  CustomPricing_stream_cursor_value_input: CustomPricing_Stream_Cursor_Value_Input;
  CustomPricing_update_column: CustomPricing_Update_Column;
  CustomPricing_updates: CustomPricing_Updates;
  CustomRate: ResolverTypeWrapper<CustomRate>;
  CustomRateInput: CustomRateInput;
  Customer: ResolverTypeWrapper<Omit<Customer, 'commits'> & { commits: Array<ResolversTypes['CommitUnion']> }>;
  CustomerAggregate: ResolverTypeWrapper<CustomerAggregate>;
  CustomerAlert: ResolverTypeWrapper<CustomerAlert>;
  CustomerAlertComposite: ResolverTypeWrapper<CustomerAlertComposite>;
  CustomerAlertCompositeStatusEnum: CustomerAlertCompositeStatusEnum;
  CustomerAlertHistory: ResolverTypeWrapper<CustomerAlertHistory>;
  CustomerAlertHistory_aggregate: ResolverTypeWrapper<CustomerAlertHistory_Aggregate>;
  CustomerAlertHistory_aggregate_bool_exp: CustomerAlertHistory_Aggregate_Bool_Exp;
  CustomerAlertHistory_aggregate_bool_exp_count: CustomerAlertHistory_Aggregate_Bool_Exp_Count;
  CustomerAlertHistory_aggregate_fields: ResolverTypeWrapper<CustomerAlertHistory_Aggregate_Fields>;
  CustomerAlertHistory_aggregate_order_by: CustomerAlertHistory_Aggregate_Order_By;
  CustomerAlertHistory_append_input: CustomerAlertHistory_Append_Input;
  CustomerAlertHistory_arr_rel_insert_input: CustomerAlertHistory_Arr_Rel_Insert_Input;
  CustomerAlertHistory_bool_exp: CustomerAlertHistory_Bool_Exp;
  CustomerAlertHistory_constraint: CustomerAlertHistory_Constraint;
  CustomerAlertHistory_delete_at_path_input: CustomerAlertHistory_Delete_At_Path_Input;
  CustomerAlertHistory_delete_elem_input: CustomerAlertHistory_Delete_Elem_Input;
  CustomerAlertHistory_delete_key_input: CustomerAlertHistory_Delete_Key_Input;
  CustomerAlertHistory_insert_input: CustomerAlertHistory_Insert_Input;
  CustomerAlertHistory_max_fields: ResolverTypeWrapper<CustomerAlertHistory_Max_Fields>;
  CustomerAlertHistory_max_order_by: CustomerAlertHistory_Max_Order_By;
  CustomerAlertHistory_min_fields: ResolverTypeWrapper<CustomerAlertHistory_Min_Fields>;
  CustomerAlertHistory_min_order_by: CustomerAlertHistory_Min_Order_By;
  CustomerAlertHistory_mutation_response: ResolverTypeWrapper<CustomerAlertHistory_Mutation_Response>;
  CustomerAlertHistory_on_conflict: CustomerAlertHistory_On_Conflict;
  CustomerAlertHistory_order_by: CustomerAlertHistory_Order_By;
  CustomerAlertHistory_pk_columns_input: CustomerAlertHistory_Pk_Columns_Input;
  CustomerAlertHistory_prepend_input: CustomerAlertHistory_Prepend_Input;
  CustomerAlertHistory_select_column: CustomerAlertHistory_Select_Column;
  CustomerAlertHistory_set_input: CustomerAlertHistory_Set_Input;
  CustomerAlertHistory_stream_cursor_input: CustomerAlertHistory_Stream_Cursor_Input;
  CustomerAlertHistory_stream_cursor_value_input: CustomerAlertHistory_Stream_Cursor_Value_Input;
  CustomerAlertHistory_update_column: CustomerAlertHistory_Update_Column;
  CustomerAlertHistory_updates: CustomerAlertHistory_Updates;
  CustomerAlertStatusEnum: ResolverTypeWrapper<CustomerAlertStatusEnum>;
  CustomerAlertStatusEnum_aggregate: ResolverTypeWrapper<CustomerAlertStatusEnum_Aggregate>;
  CustomerAlertStatusEnum_aggregate_fields: ResolverTypeWrapper<CustomerAlertStatusEnum_Aggregate_Fields>;
  CustomerAlertStatusEnum_bool_exp: CustomerAlertStatusEnum_Bool_Exp;
  CustomerAlertStatusEnum_constraint: CustomerAlertStatusEnum_Constraint;
  CustomerAlertStatusEnum_enum: CustomerAlertStatusEnum_Enum;
  CustomerAlertStatusEnum_enum_comparison_exp: CustomerAlertStatusEnum_Enum_Comparison_Exp;
  CustomerAlertStatusEnum_insert_input: CustomerAlertStatusEnum_Insert_Input;
  CustomerAlertStatusEnum_max_fields: ResolverTypeWrapper<CustomerAlertStatusEnum_Max_Fields>;
  CustomerAlertStatusEnum_min_fields: ResolverTypeWrapper<CustomerAlertStatusEnum_Min_Fields>;
  CustomerAlertStatusEnum_mutation_response: ResolverTypeWrapper<CustomerAlertStatusEnum_Mutation_Response>;
  CustomerAlertStatusEnum_on_conflict: CustomerAlertStatusEnum_On_Conflict;
  CustomerAlertStatusEnum_order_by: CustomerAlertStatusEnum_Order_By;
  CustomerAlertStatusEnum_pk_columns_input: CustomerAlertStatusEnum_Pk_Columns_Input;
  CustomerAlertStatusEnum_select_column: CustomerAlertStatusEnum_Select_Column;
  CustomerAlertStatusEnum_set_input: CustomerAlertStatusEnum_Set_Input;
  CustomerAlertStatusEnum_stream_cursor_input: CustomerAlertStatusEnum_Stream_Cursor_Input;
  CustomerAlertStatusEnum_stream_cursor_value_input: CustomerAlertStatusEnum_Stream_Cursor_Value_Input;
  CustomerAlertStatusEnum_update_column: CustomerAlertStatusEnum_Update_Column;
  CustomerAlertStatusEnum_updates: CustomerAlertStatusEnum_Updates;
  CustomerAlert_aggregate: ResolverTypeWrapper<CustomerAlert_Aggregate>;
  CustomerAlert_aggregate_bool_exp: CustomerAlert_Aggregate_Bool_Exp;
  CustomerAlert_aggregate_bool_exp_count: CustomerAlert_Aggregate_Bool_Exp_Count;
  CustomerAlert_aggregate_fields: ResolverTypeWrapper<CustomerAlert_Aggregate_Fields>;
  CustomerAlert_aggregate_order_by: CustomerAlert_Aggregate_Order_By;
  CustomerAlert_append_input: CustomerAlert_Append_Input;
  CustomerAlert_arr_rel_insert_input: CustomerAlert_Arr_Rel_Insert_Input;
  CustomerAlert_bool_exp: CustomerAlert_Bool_Exp;
  CustomerAlert_constraint: CustomerAlert_Constraint;
  CustomerAlert_delete_at_path_input: CustomerAlert_Delete_At_Path_Input;
  CustomerAlert_delete_elem_input: CustomerAlert_Delete_Elem_Input;
  CustomerAlert_delete_key_input: CustomerAlert_Delete_Key_Input;
  CustomerAlert_insert_input: CustomerAlert_Insert_Input;
  CustomerAlert_max_fields: ResolverTypeWrapper<CustomerAlert_Max_Fields>;
  CustomerAlert_max_order_by: CustomerAlert_Max_Order_By;
  CustomerAlert_min_fields: ResolverTypeWrapper<CustomerAlert_Min_Fields>;
  CustomerAlert_min_order_by: CustomerAlert_Min_Order_By;
  CustomerAlert_mutation_response: ResolverTypeWrapper<CustomerAlert_Mutation_Response>;
  CustomerAlert_obj_rel_insert_input: CustomerAlert_Obj_Rel_Insert_Input;
  CustomerAlert_on_conflict: CustomerAlert_On_Conflict;
  CustomerAlert_order_by: CustomerAlert_Order_By;
  CustomerAlert_pk_columns_input: CustomerAlert_Pk_Columns_Input;
  CustomerAlert_prepend_input: CustomerAlert_Prepend_Input;
  CustomerAlert_select_column: CustomerAlert_Select_Column;
  CustomerAlert_set_input: CustomerAlert_Set_Input;
  CustomerAlert_stream_cursor_input: CustomerAlert_Stream_Cursor_Input;
  CustomerAlert_stream_cursor_value_input: CustomerAlert_Stream_Cursor_Value_Input;
  CustomerAlert_update_column: CustomerAlert_Update_Column;
  CustomerAlert_updates: CustomerAlert_Updates;
  CustomerAlertsSortingInput: CustomerAlertsSortingInput;
  CustomerAlertsSortingOrderByEnum: CustomerAlertsSortingOrderByEnum;
  CustomerBillingProviderConfiguration: ResolverTypeWrapper<CustomerBillingProviderConfiguration>;
  CustomerBillingProviderConfigurationNotification: ResolverTypeWrapper<CustomerBillingProviderConfigurationNotification>;
  CustomerBillingProviderConfigurationNotificationInput: CustomerBillingProviderConfigurationNotificationInput;
  CustomerCommitInput: CustomerCommitInput;
  CustomerConfig: ResolverTypeWrapper<CustomerConfig>;
  CustomerConfigInput: CustomerConfigInput;
  CustomerConfig_aggregate: ResolverTypeWrapper<CustomerConfig_Aggregate>;
  CustomerConfig_aggregate_bool_exp: CustomerConfig_Aggregate_Bool_Exp;
  CustomerConfig_aggregate_bool_exp_count: CustomerConfig_Aggregate_Bool_Exp_Count;
  CustomerConfig_aggregate_fields: ResolverTypeWrapper<CustomerConfig_Aggregate_Fields>;
  CustomerConfig_aggregate_order_by: CustomerConfig_Aggregate_Order_By;
  CustomerConfig_arr_rel_insert_input: CustomerConfig_Arr_Rel_Insert_Input;
  CustomerConfig_bool_exp: CustomerConfig_Bool_Exp;
  CustomerConfig_constraint: CustomerConfig_Constraint;
  CustomerConfig_insert_input: CustomerConfig_Insert_Input;
  CustomerConfig_max_fields: ResolverTypeWrapper<CustomerConfig_Max_Fields>;
  CustomerConfig_max_order_by: CustomerConfig_Max_Order_By;
  CustomerConfig_min_fields: ResolverTypeWrapper<CustomerConfig_Min_Fields>;
  CustomerConfig_min_order_by: CustomerConfig_Min_Order_By;
  CustomerConfig_mutation_response: ResolverTypeWrapper<CustomerConfig_Mutation_Response>;
  CustomerConfig_on_conflict: CustomerConfig_On_Conflict;
  CustomerConfig_order_by: CustomerConfig_Order_By;
  CustomerConfig_pk_columns_input: CustomerConfig_Pk_Columns_Input;
  CustomerConfig_select_column: CustomerConfig_Select_Column;
  CustomerConfig_set_input: CustomerConfig_Set_Input;
  CustomerConfig_stream_cursor_input: CustomerConfig_Stream_Cursor_Input;
  CustomerConfig_stream_cursor_value_input: CustomerConfig_Stream_Cursor_Value_Input;
  CustomerConfig_update_column: CustomerConfig_Update_Column;
  CustomerConfig_updates: CustomerConfig_Updates;
  CustomerContractStatus: ResolverTypeWrapper<CustomerContractStatus>;
  CustomerCount: ResolverTypeWrapper<CustomerCount>;
  CustomerIngestAlias: ResolverTypeWrapper<CustomerIngestAlias>;
  CustomerIngestAlias_aggregate: ResolverTypeWrapper<CustomerIngestAlias_Aggregate>;
  CustomerIngestAlias_aggregate_bool_exp: CustomerIngestAlias_Aggregate_Bool_Exp;
  CustomerIngestAlias_aggregate_bool_exp_count: CustomerIngestAlias_Aggregate_Bool_Exp_Count;
  CustomerIngestAlias_aggregate_fields: ResolverTypeWrapper<CustomerIngestAlias_Aggregate_Fields>;
  CustomerIngestAlias_aggregate_order_by: CustomerIngestAlias_Aggregate_Order_By;
  CustomerIngestAlias_arr_rel_insert_input: CustomerIngestAlias_Arr_Rel_Insert_Input;
  CustomerIngestAlias_bool_exp: CustomerIngestAlias_Bool_Exp;
  CustomerIngestAlias_constraint: CustomerIngestAlias_Constraint;
  CustomerIngestAlias_insert_input: CustomerIngestAlias_Insert_Input;
  CustomerIngestAlias_max_fields: ResolverTypeWrapper<CustomerIngestAlias_Max_Fields>;
  CustomerIngestAlias_max_order_by: CustomerIngestAlias_Max_Order_By;
  CustomerIngestAlias_min_fields: ResolverTypeWrapper<CustomerIngestAlias_Min_Fields>;
  CustomerIngestAlias_min_order_by: CustomerIngestAlias_Min_Order_By;
  CustomerIngestAlias_mutation_response: ResolverTypeWrapper<CustomerIngestAlias_Mutation_Response>;
  CustomerIngestAlias_on_conflict: CustomerIngestAlias_On_Conflict;
  CustomerIngestAlias_order_by: CustomerIngestAlias_Order_By;
  CustomerIngestAlias_pk_columns_input: CustomerIngestAlias_Pk_Columns_Input;
  CustomerIngestAlias_select_column: CustomerIngestAlias_Select_Column;
  CustomerIngestAlias_set_input: CustomerIngestAlias_Set_Input;
  CustomerIngestAlias_stream_cursor_input: CustomerIngestAlias_Stream_Cursor_Input;
  CustomerIngestAlias_stream_cursor_value_input: CustomerIngestAlias_Stream_Cursor_Value_Input;
  CustomerIngestAlias_update_column: CustomerIngestAlias_Update_Column;
  CustomerIngestAlias_updates: CustomerIngestAlias_Updates;
  CustomerList: ResolverTypeWrapper<CustomerList>;
  CustomerManagedField: ResolverTypeWrapper<CustomerManagedField>;
  CustomerManagedField_aggregate: ResolverTypeWrapper<CustomerManagedField_Aggregate>;
  CustomerManagedField_aggregate_bool_exp: CustomerManagedField_Aggregate_Bool_Exp;
  CustomerManagedField_aggregate_bool_exp_bool_and: CustomerManagedField_Aggregate_Bool_Exp_Bool_And;
  CustomerManagedField_aggregate_bool_exp_bool_or: CustomerManagedField_Aggregate_Bool_Exp_Bool_Or;
  CustomerManagedField_aggregate_bool_exp_count: CustomerManagedField_Aggregate_Bool_Exp_Count;
  CustomerManagedField_aggregate_fields: ResolverTypeWrapper<CustomerManagedField_Aggregate_Fields>;
  CustomerManagedField_aggregate_order_by: CustomerManagedField_Aggregate_Order_By;
  CustomerManagedField_arr_rel_insert_input: CustomerManagedField_Arr_Rel_Insert_Input;
  CustomerManagedField_bool_exp: CustomerManagedField_Bool_Exp;
  CustomerManagedField_constraint: CustomerManagedField_Constraint;
  CustomerManagedField_insert_input: CustomerManagedField_Insert_Input;
  CustomerManagedField_max_fields: ResolverTypeWrapper<CustomerManagedField_Max_Fields>;
  CustomerManagedField_max_order_by: CustomerManagedField_Max_Order_By;
  CustomerManagedField_min_fields: ResolverTypeWrapper<CustomerManagedField_Min_Fields>;
  CustomerManagedField_min_order_by: CustomerManagedField_Min_Order_By;
  CustomerManagedField_mutation_response: ResolverTypeWrapper<CustomerManagedField_Mutation_Response>;
  CustomerManagedField_on_conflict: CustomerManagedField_On_Conflict;
  CustomerManagedField_order_by: CustomerManagedField_Order_By;
  CustomerManagedField_pk_columns_input: CustomerManagedField_Pk_Columns_Input;
  CustomerManagedField_select_column: CustomerManagedField_Select_Column;
  CustomerManagedField_select_column_CustomerManagedField_aggregate_bool_exp_bool_and_arguments_columns: CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  CustomerManagedField_select_column_CustomerManagedField_aggregate_bool_exp_bool_or_arguments_columns: CustomerManagedField_Select_Column_CustomerManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  CustomerManagedField_set_input: CustomerManagedField_Set_Input;
  CustomerManagedField_stream_cursor_input: CustomerManagedField_Stream_Cursor_Input;
  CustomerManagedField_stream_cursor_value_input: CustomerManagedField_Stream_Cursor_Value_Input;
  CustomerManagedField_update_column: CustomerManagedField_Update_Column;
  CustomerManagedField_updates: CustomerManagedField_Updates;
  CustomerPlan: ResolverTypeWrapper<CustomerPlan>;
  CustomerPlanAdjustment: ResolverTypeWrapper<CustomerPlanAdjustment>;
  CustomerPlanAdjustment_aggregate: ResolverTypeWrapper<CustomerPlanAdjustment_Aggregate>;
  CustomerPlanAdjustment_aggregate_bool_exp: CustomerPlanAdjustment_Aggregate_Bool_Exp;
  CustomerPlanAdjustment_aggregate_bool_exp_count: CustomerPlanAdjustment_Aggregate_Bool_Exp_Count;
  CustomerPlanAdjustment_aggregate_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Aggregate_Fields>;
  CustomerPlanAdjustment_aggregate_order_by: CustomerPlanAdjustment_Aggregate_Order_By;
  CustomerPlanAdjustment_arr_rel_insert_input: CustomerPlanAdjustment_Arr_Rel_Insert_Input;
  CustomerPlanAdjustment_avg_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Avg_Fields>;
  CustomerPlanAdjustment_avg_order_by: CustomerPlanAdjustment_Avg_Order_By;
  CustomerPlanAdjustment_bool_exp: CustomerPlanAdjustment_Bool_Exp;
  CustomerPlanAdjustment_constraint: CustomerPlanAdjustment_Constraint;
  CustomerPlanAdjustment_inc_input: CustomerPlanAdjustment_Inc_Input;
  CustomerPlanAdjustment_insert_input: CustomerPlanAdjustment_Insert_Input;
  CustomerPlanAdjustment_max_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Max_Fields>;
  CustomerPlanAdjustment_max_order_by: CustomerPlanAdjustment_Max_Order_By;
  CustomerPlanAdjustment_min_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Min_Fields>;
  CustomerPlanAdjustment_min_order_by: CustomerPlanAdjustment_Min_Order_By;
  CustomerPlanAdjustment_mutation_response: ResolverTypeWrapper<CustomerPlanAdjustment_Mutation_Response>;
  CustomerPlanAdjustment_on_conflict: CustomerPlanAdjustment_On_Conflict;
  CustomerPlanAdjustment_order_by: CustomerPlanAdjustment_Order_By;
  CustomerPlanAdjustment_pk_columns_input: CustomerPlanAdjustment_Pk_Columns_Input;
  CustomerPlanAdjustment_select_column: CustomerPlanAdjustment_Select_Column;
  CustomerPlanAdjustment_set_input: CustomerPlanAdjustment_Set_Input;
  CustomerPlanAdjustment_stddev_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Stddev_Fields>;
  CustomerPlanAdjustment_stddev_order_by: CustomerPlanAdjustment_Stddev_Order_By;
  CustomerPlanAdjustment_stddev_pop_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Stddev_Pop_Fields>;
  CustomerPlanAdjustment_stddev_pop_order_by: CustomerPlanAdjustment_Stddev_Pop_Order_By;
  CustomerPlanAdjustment_stddev_samp_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Stddev_Samp_Fields>;
  CustomerPlanAdjustment_stddev_samp_order_by: CustomerPlanAdjustment_Stddev_Samp_Order_By;
  CustomerPlanAdjustment_stream_cursor_input: CustomerPlanAdjustment_Stream_Cursor_Input;
  CustomerPlanAdjustment_stream_cursor_value_input: CustomerPlanAdjustment_Stream_Cursor_Value_Input;
  CustomerPlanAdjustment_sum_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Sum_Fields>;
  CustomerPlanAdjustment_sum_order_by: CustomerPlanAdjustment_Sum_Order_By;
  CustomerPlanAdjustment_update_column: CustomerPlanAdjustment_Update_Column;
  CustomerPlanAdjustment_updates: CustomerPlanAdjustment_Updates;
  CustomerPlanAdjustment_var_pop_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Var_Pop_Fields>;
  CustomerPlanAdjustment_var_pop_order_by: CustomerPlanAdjustment_Var_Pop_Order_By;
  CustomerPlanAdjustment_var_samp_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Var_Samp_Fields>;
  CustomerPlanAdjustment_var_samp_order_by: CustomerPlanAdjustment_Var_Samp_Order_By;
  CustomerPlanAdjustment_variance_fields: ResolverTypeWrapper<CustomerPlanAdjustment_Variance_Fields>;
  CustomerPlanAdjustment_variance_order_by: CustomerPlanAdjustment_Variance_Order_By;
  CustomerPlanManagedField: ResolverTypeWrapper<CustomerPlanManagedField>;
  CustomerPlanManagedField_aggregate: ResolverTypeWrapper<CustomerPlanManagedField_Aggregate>;
  CustomerPlanManagedField_aggregate_bool_exp: CustomerPlanManagedField_Aggregate_Bool_Exp;
  CustomerPlanManagedField_aggregate_bool_exp_bool_and: CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And;
  CustomerPlanManagedField_aggregate_bool_exp_bool_or: CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or;
  CustomerPlanManagedField_aggregate_bool_exp_count: CustomerPlanManagedField_Aggregate_Bool_Exp_Count;
  CustomerPlanManagedField_aggregate_fields: ResolverTypeWrapper<CustomerPlanManagedField_Aggregate_Fields>;
  CustomerPlanManagedField_aggregate_order_by: CustomerPlanManagedField_Aggregate_Order_By;
  CustomerPlanManagedField_arr_rel_insert_input: CustomerPlanManagedField_Arr_Rel_Insert_Input;
  CustomerPlanManagedField_bool_exp: CustomerPlanManagedField_Bool_Exp;
  CustomerPlanManagedField_constraint: CustomerPlanManagedField_Constraint;
  CustomerPlanManagedField_insert_input: CustomerPlanManagedField_Insert_Input;
  CustomerPlanManagedField_max_fields: ResolverTypeWrapper<CustomerPlanManagedField_Max_Fields>;
  CustomerPlanManagedField_max_order_by: CustomerPlanManagedField_Max_Order_By;
  CustomerPlanManagedField_min_fields: ResolverTypeWrapper<CustomerPlanManagedField_Min_Fields>;
  CustomerPlanManagedField_min_order_by: CustomerPlanManagedField_Min_Order_By;
  CustomerPlanManagedField_mutation_response: ResolverTypeWrapper<CustomerPlanManagedField_Mutation_Response>;
  CustomerPlanManagedField_on_conflict: CustomerPlanManagedField_On_Conflict;
  CustomerPlanManagedField_order_by: CustomerPlanManagedField_Order_By;
  CustomerPlanManagedField_pk_columns_input: CustomerPlanManagedField_Pk_Columns_Input;
  CustomerPlanManagedField_select_column: CustomerPlanManagedField_Select_Column;
  CustomerPlanManagedField_select_column_CustomerPlanManagedField_aggregate_bool_exp_bool_and_arguments_columns: CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  CustomerPlanManagedField_select_column_CustomerPlanManagedField_aggregate_bool_exp_bool_or_arguments_columns: CustomerPlanManagedField_Select_Column_CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  CustomerPlanManagedField_set_input: CustomerPlanManagedField_Set_Input;
  CustomerPlanManagedField_stream_cursor_input: CustomerPlanManagedField_Stream_Cursor_Input;
  CustomerPlanManagedField_stream_cursor_value_input: CustomerPlanManagedField_Stream_Cursor_Value_Input;
  CustomerPlanManagedField_update_column: CustomerPlanManagedField_Update_Column;
  CustomerPlanManagedField_updates: CustomerPlanManagedField_Updates;
  CustomerPlanOneTimeCharge: ResolverTypeWrapper<CustomerPlanOneTimeCharge>;
  CustomerPlanOneTimeCharge_aggregate: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Aggregate>;
  CustomerPlanOneTimeCharge_aggregate_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Aggregate_Fields>;
  CustomerPlanOneTimeCharge_avg_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Avg_Fields>;
  CustomerPlanOneTimeCharge_bool_exp: CustomerPlanOneTimeCharge_Bool_Exp;
  CustomerPlanOneTimeCharge_constraint: CustomerPlanOneTimeCharge_Constraint;
  CustomerPlanOneTimeCharge_inc_input: CustomerPlanOneTimeCharge_Inc_Input;
  CustomerPlanOneTimeCharge_insert_input: CustomerPlanOneTimeCharge_Insert_Input;
  CustomerPlanOneTimeCharge_max_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Max_Fields>;
  CustomerPlanOneTimeCharge_min_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Min_Fields>;
  CustomerPlanOneTimeCharge_mutation_response: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Mutation_Response>;
  CustomerPlanOneTimeCharge_on_conflict: CustomerPlanOneTimeCharge_On_Conflict;
  CustomerPlanOneTimeCharge_order_by: CustomerPlanOneTimeCharge_Order_By;
  CustomerPlanOneTimeCharge_pk_columns_input: CustomerPlanOneTimeCharge_Pk_Columns_Input;
  CustomerPlanOneTimeCharge_select_column: CustomerPlanOneTimeCharge_Select_Column;
  CustomerPlanOneTimeCharge_set_input: CustomerPlanOneTimeCharge_Set_Input;
  CustomerPlanOneTimeCharge_stddev_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Stddev_Fields>;
  CustomerPlanOneTimeCharge_stddev_pop_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Stddev_Pop_Fields>;
  CustomerPlanOneTimeCharge_stddev_samp_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Stddev_Samp_Fields>;
  CustomerPlanOneTimeCharge_stream_cursor_input: CustomerPlanOneTimeCharge_Stream_Cursor_Input;
  CustomerPlanOneTimeCharge_stream_cursor_value_input: CustomerPlanOneTimeCharge_Stream_Cursor_Value_Input;
  CustomerPlanOneTimeCharge_sum_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Sum_Fields>;
  CustomerPlanOneTimeCharge_update_column: CustomerPlanOneTimeCharge_Update_Column;
  CustomerPlanOneTimeCharge_updates: CustomerPlanOneTimeCharge_Updates;
  CustomerPlanOneTimeCharge_var_pop_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Var_Pop_Fields>;
  CustomerPlanOneTimeCharge_var_samp_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Var_Samp_Fields>;
  CustomerPlanOneTimeCharge_variance_fields: ResolverTypeWrapper<CustomerPlanOneTimeCharge_Variance_Fields>;
  CustomerPlanStatus: ResolverTypeWrapper<CustomerPlanStatus>;
  CustomerPlanStatusEnum: CustomerPlanStatusEnum;
  CustomerPlan_aggregate: ResolverTypeWrapper<CustomerPlan_Aggregate>;
  CustomerPlan_aggregate_bool_exp: CustomerPlan_Aggregate_Bool_Exp;
  CustomerPlan_aggregate_bool_exp_count: CustomerPlan_Aggregate_Bool_Exp_Count;
  CustomerPlan_aggregate_fields: ResolverTypeWrapper<CustomerPlan_Aggregate_Fields>;
  CustomerPlan_aggregate_order_by: CustomerPlan_Aggregate_Order_By;
  CustomerPlan_arr_rel_insert_input: CustomerPlan_Arr_Rel_Insert_Input;
  CustomerPlan_avg_fields: ResolverTypeWrapper<CustomerPlan_Avg_Fields>;
  CustomerPlan_avg_order_by: CustomerPlan_Avg_Order_By;
  CustomerPlan_bool_exp: CustomerPlan_Bool_Exp;
  CustomerPlan_constraint: CustomerPlan_Constraint;
  CustomerPlan_inc_input: CustomerPlan_Inc_Input;
  CustomerPlan_insert_input: CustomerPlan_Insert_Input;
  CustomerPlan_max_fields: ResolverTypeWrapper<CustomerPlan_Max_Fields>;
  CustomerPlan_max_order_by: CustomerPlan_Max_Order_By;
  CustomerPlan_min_fields: ResolverTypeWrapper<CustomerPlan_Min_Fields>;
  CustomerPlan_min_order_by: CustomerPlan_Min_Order_By;
  CustomerPlan_mutation_response: ResolverTypeWrapper<CustomerPlan_Mutation_Response>;
  CustomerPlan_obj_rel_insert_input: CustomerPlan_Obj_Rel_Insert_Input;
  CustomerPlan_on_conflict: CustomerPlan_On_Conflict;
  CustomerPlan_order_by: CustomerPlan_Order_By;
  CustomerPlan_pk_columns_input: CustomerPlan_Pk_Columns_Input;
  CustomerPlan_select_column: CustomerPlan_Select_Column;
  CustomerPlan_set_input: CustomerPlan_Set_Input;
  CustomerPlan_stddev_fields: ResolverTypeWrapper<CustomerPlan_Stddev_Fields>;
  CustomerPlan_stddev_order_by: CustomerPlan_Stddev_Order_By;
  CustomerPlan_stddev_pop_fields: ResolverTypeWrapper<CustomerPlan_Stddev_Pop_Fields>;
  CustomerPlan_stddev_pop_order_by: CustomerPlan_Stddev_Pop_Order_By;
  CustomerPlan_stddev_samp_fields: ResolverTypeWrapper<CustomerPlan_Stddev_Samp_Fields>;
  CustomerPlan_stddev_samp_order_by: CustomerPlan_Stddev_Samp_Order_By;
  CustomerPlan_stream_cursor_input: CustomerPlan_Stream_Cursor_Input;
  CustomerPlan_stream_cursor_value_input: CustomerPlan_Stream_Cursor_Value_Input;
  CustomerPlan_sum_fields: ResolverTypeWrapper<CustomerPlan_Sum_Fields>;
  CustomerPlan_sum_order_by: CustomerPlan_Sum_Order_By;
  CustomerPlan_update_column: CustomerPlan_Update_Column;
  CustomerPlan_updates: CustomerPlan_Updates;
  CustomerPlan_var_pop_fields: ResolverTypeWrapper<CustomerPlan_Var_Pop_Fields>;
  CustomerPlan_var_pop_order_by: CustomerPlan_Var_Pop_Order_By;
  CustomerPlan_var_samp_fields: ResolverTypeWrapper<CustomerPlan_Var_Samp_Fields>;
  CustomerPlan_var_samp_order_by: CustomerPlan_Var_Samp_Order_By;
  CustomerPlan_variance_fields: ResolverTypeWrapper<CustomerPlan_Variance_Fields>;
  CustomerPlan_variance_order_by: CustomerPlan_Variance_Order_By;
  CustomerRelationship: ResolverTypeWrapper<CustomerRelationship>;
  CustomerRelationship_aggregate: ResolverTypeWrapper<CustomerRelationship_Aggregate>;
  CustomerRelationship_aggregate_fields: ResolverTypeWrapper<CustomerRelationship_Aggregate_Fields>;
  CustomerRelationship_bool_exp: CustomerRelationship_Bool_Exp;
  CustomerRelationship_constraint: CustomerRelationship_Constraint;
  CustomerRelationship_insert_input: CustomerRelationship_Insert_Input;
  CustomerRelationship_max_fields: ResolverTypeWrapper<CustomerRelationship_Max_Fields>;
  CustomerRelationship_min_fields: ResolverTypeWrapper<CustomerRelationship_Min_Fields>;
  CustomerRelationship_mutation_response: ResolverTypeWrapper<CustomerRelationship_Mutation_Response>;
  CustomerRelationship_on_conflict: CustomerRelationship_On_Conflict;
  CustomerRelationship_order_by: CustomerRelationship_Order_By;
  CustomerRelationship_pk_columns_input: CustomerRelationship_Pk_Columns_Input;
  CustomerRelationship_select_column: CustomerRelationship_Select_Column;
  CustomerRelationship_set_input: CustomerRelationship_Set_Input;
  CustomerRelationship_stream_cursor_input: CustomerRelationship_Stream_Cursor_Input;
  CustomerRelationship_stream_cursor_value_input: CustomerRelationship_Stream_Cursor_Value_Input;
  CustomerRelationship_update_column: CustomerRelationship_Update_Column;
  CustomerRelationship_updates: CustomerRelationship_Updates;
  CustomerUsingContracts: ResolverTypeWrapper<CustomerUsingContracts>;
  Customer_aggregate: ResolverTypeWrapper<Customer_Aggregate>;
  Customer_aggregate_bool_exp: Customer_Aggregate_Bool_Exp;
  Customer_aggregate_bool_exp_bool_and: Customer_Aggregate_Bool_Exp_Bool_And;
  Customer_aggregate_bool_exp_bool_or: Customer_Aggregate_Bool_Exp_Bool_Or;
  Customer_aggregate_bool_exp_count: Customer_Aggregate_Bool_Exp_Count;
  Customer_aggregate_fields: ResolverTypeWrapper<Customer_Aggregate_Fields>;
  Customer_aggregate_order_by: Customer_Aggregate_Order_By;
  Customer_arr_rel_insert_input: Customer_Arr_Rel_Insert_Input;
  Customer_bool_exp: Customer_Bool_Exp;
  Customer_constraint: Customer_Constraint;
  Customer_insert_input: Customer_Insert_Input;
  Customer_max_fields: ResolverTypeWrapper<Customer_Max_Fields>;
  Customer_max_order_by: Customer_Max_Order_By;
  Customer_min_fields: ResolverTypeWrapper<Customer_Min_Fields>;
  Customer_min_order_by: Customer_Min_Order_By;
  Customer_mutation_response: ResolverTypeWrapper<Customer_Mutation_Response>;
  Customer_obj_rel_insert_input: Customer_Obj_Rel_Insert_Input;
  Customer_on_conflict: Customer_On_Conflict;
  Customer_order_by: Customer_Order_By;
  Customer_pk_columns_input: Customer_Pk_Columns_Input;
  Customer_select_column: Customer_Select_Column;
  Customer_select_column_Customer_aggregate_bool_exp_bool_and_arguments_columns: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  Customer_select_column_Customer_aggregate_bool_exp_bool_or_arguments_columns: Customer_Select_Column_Customer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  Customer_set_input: Customer_Set_Input;
  Customer_stream_cursor_input: Customer_Stream_Cursor_Input;
  Customer_stream_cursor_value_input: Customer_Stream_Cursor_Value_Input;
  Customer_update_column: Customer_Update_Column;
  Customer_updates: Customer_Updates;
  CustomersMetric: ResolverTypeWrapper<CustomersMetric>;
  CustomersUsingContracts: ResolverTypeWrapper<CustomersUsingContracts>;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange: ResolverTypeWrapper<CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange>;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse: ResolverTypeWrapper<CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse>;
  Discount: ResolverTypeWrapper<Omit<Discount, 'product'> & { product: ResolversTypes['ProductListItem'] }>;
  DiscountInput: DiscountInput;
  DiscountSchedule: ResolverTypeWrapper<DiscountSchedule>;
  DiscountScheduleInput: DiscountScheduleInput;
  DiscountScheduleItem: ResolverTypeWrapper<DiscountScheduleItem>;
  DiscountScheduleItemInput: DiscountScheduleItemInput;
  DraftPlan: ResolverTypeWrapper<DraftPlan>;
  DraftPlan_aggregate: ResolverTypeWrapper<DraftPlan_Aggregate>;
  DraftPlan_aggregate_fields: ResolverTypeWrapper<DraftPlan_Aggregate_Fields>;
  DraftPlan_append_input: DraftPlan_Append_Input;
  DraftPlan_avg_fields: ResolverTypeWrapper<DraftPlan_Avg_Fields>;
  DraftPlan_bool_exp: DraftPlan_Bool_Exp;
  DraftPlan_constraint: DraftPlan_Constraint;
  DraftPlan_delete_at_path_input: DraftPlan_Delete_At_Path_Input;
  DraftPlan_delete_elem_input: DraftPlan_Delete_Elem_Input;
  DraftPlan_delete_key_input: DraftPlan_Delete_Key_Input;
  DraftPlan_inc_input: DraftPlan_Inc_Input;
  DraftPlan_insert_input: DraftPlan_Insert_Input;
  DraftPlan_max_fields: ResolverTypeWrapper<DraftPlan_Max_Fields>;
  DraftPlan_min_fields: ResolverTypeWrapper<DraftPlan_Min_Fields>;
  DraftPlan_mutation_response: ResolverTypeWrapper<DraftPlan_Mutation_Response>;
  DraftPlan_on_conflict: DraftPlan_On_Conflict;
  DraftPlan_order_by: DraftPlan_Order_By;
  DraftPlan_pk_columns_input: DraftPlan_Pk_Columns_Input;
  DraftPlan_prepend_input: DraftPlan_Prepend_Input;
  DraftPlan_select_column: DraftPlan_Select_Column;
  DraftPlan_set_input: DraftPlan_Set_Input;
  DraftPlan_stddev_fields: ResolverTypeWrapper<DraftPlan_Stddev_Fields>;
  DraftPlan_stddev_pop_fields: ResolverTypeWrapper<DraftPlan_Stddev_Pop_Fields>;
  DraftPlan_stddev_samp_fields: ResolverTypeWrapper<DraftPlan_Stddev_Samp_Fields>;
  DraftPlan_stream_cursor_input: DraftPlan_Stream_Cursor_Input;
  DraftPlan_stream_cursor_value_input: DraftPlan_Stream_Cursor_Value_Input;
  DraftPlan_sum_fields: ResolverTypeWrapper<DraftPlan_Sum_Fields>;
  DraftPlan_update_column: DraftPlan_Update_Column;
  DraftPlan_updates: DraftPlan_Updates;
  DraftPlan_var_pop_fields: ResolverTypeWrapper<DraftPlan_Var_Pop_Fields>;
  DraftPlan_var_samp_fields: ResolverTypeWrapper<DraftPlan_Var_Samp_Fields>;
  DraftPlan_variance_fields: ResolverTypeWrapper<DraftPlan_Variance_Fields>;
  EditPlanResponse: ResolverTypeWrapper<EditPlanResponse>;
  EffectiveDates: ResolverTypeWrapper<EffectiveDates>;
  Environment: ResolverTypeWrapper<Environment>;
  EnvironmentTypeEnum: ResolverTypeWrapper<EnvironmentTypeEnum>;
  EnvironmentTypeEnum_aggregate: ResolverTypeWrapper<EnvironmentTypeEnum_Aggregate>;
  EnvironmentTypeEnum_aggregate_fields: ResolverTypeWrapper<EnvironmentTypeEnum_Aggregate_Fields>;
  EnvironmentTypeEnum_bool_exp: EnvironmentTypeEnum_Bool_Exp;
  EnvironmentTypeEnum_constraint: EnvironmentTypeEnum_Constraint;
  EnvironmentTypeEnum_enum: EnvironmentTypeEnum_Enum;
  EnvironmentTypeEnum_enum_comparison_exp: EnvironmentTypeEnum_Enum_Comparison_Exp;
  EnvironmentTypeEnum_insert_input: EnvironmentTypeEnum_Insert_Input;
  EnvironmentTypeEnum_max_fields: ResolverTypeWrapper<EnvironmentTypeEnum_Max_Fields>;
  EnvironmentTypeEnum_min_fields: ResolverTypeWrapper<EnvironmentTypeEnum_Min_Fields>;
  EnvironmentTypeEnum_mutation_response: ResolverTypeWrapper<EnvironmentTypeEnum_Mutation_Response>;
  EnvironmentTypeEnum_obj_rel_insert_input: EnvironmentTypeEnum_Obj_Rel_Insert_Input;
  EnvironmentTypeEnum_on_conflict: EnvironmentTypeEnum_On_Conflict;
  EnvironmentTypeEnum_order_by: EnvironmentTypeEnum_Order_By;
  EnvironmentTypeEnum_pk_columns_input: EnvironmentTypeEnum_Pk_Columns_Input;
  EnvironmentTypeEnum_select_column: EnvironmentTypeEnum_Select_Column;
  EnvironmentTypeEnum_set_input: EnvironmentTypeEnum_Set_Input;
  EnvironmentTypeEnum_stream_cursor_input: EnvironmentTypeEnum_Stream_Cursor_Input;
  EnvironmentTypeEnum_stream_cursor_value_input: EnvironmentTypeEnum_Stream_Cursor_Value_Input;
  EnvironmentTypeEnum_update_column: EnvironmentTypeEnum_Update_Column;
  EnvironmentTypeEnum_updates: EnvironmentTypeEnum_Updates;
  Event: ResolverTypeWrapper<Event>;
  EventCountWindow: ResolverTypeWrapper<EventCountWindow>;
  EventWindowSize: EventWindowSize;
  ExternalCommitType: ExternalCommitType;
  FailedMarketplacesMeteringRecord: ResolverTypeWrapper<FailedMarketplacesMeteringRecord>;
  FailedMarketplacesMeteringRecordList: ResolverTypeWrapper<FailedMarketplacesMeteringRecordList>;
  FancyMetricInput: FancyMetricInput;
  FancyMetricValidationResponse: ResolverTypeWrapper<FancyMetricValidationResponse>;
  FixedOrProServiceProductListItem: ResolversTypes['FixedProductListItem'] | ResolversTypes['ProServiceProductListItem'];
  FixedProductListItem: ResolverTypeWrapper<FixedProductListItem>;
  FixedProductListItemState: ResolverTypeWrapper<FixedProductListItemState>;
  FlatFee: ResolverTypeWrapper<FlatFee>;
  FlatFeeAdjustment: ResolverTypeWrapper<FlatFeeAdjustment>;
  FlatFeeAdjustment_aggregate: ResolverTypeWrapper<FlatFeeAdjustment_Aggregate>;
  FlatFeeAdjustment_aggregate_bool_exp: FlatFeeAdjustment_Aggregate_Bool_Exp;
  FlatFeeAdjustment_aggregate_bool_exp_count: FlatFeeAdjustment_Aggregate_Bool_Exp_Count;
  FlatFeeAdjustment_aggregate_fields: ResolverTypeWrapper<FlatFeeAdjustment_Aggregate_Fields>;
  FlatFeeAdjustment_aggregate_order_by: FlatFeeAdjustment_Aggregate_Order_By;
  FlatFeeAdjustment_arr_rel_insert_input: FlatFeeAdjustment_Arr_Rel_Insert_Input;
  FlatFeeAdjustment_avg_fields: ResolverTypeWrapper<FlatFeeAdjustment_Avg_Fields>;
  FlatFeeAdjustment_avg_order_by: FlatFeeAdjustment_Avg_Order_By;
  FlatFeeAdjustment_bool_exp: FlatFeeAdjustment_Bool_Exp;
  FlatFeeAdjustment_constraint: FlatFeeAdjustment_Constraint;
  FlatFeeAdjustment_inc_input: FlatFeeAdjustment_Inc_Input;
  FlatFeeAdjustment_insert_input: FlatFeeAdjustment_Insert_Input;
  FlatFeeAdjustment_max_fields: ResolverTypeWrapper<FlatFeeAdjustment_Max_Fields>;
  FlatFeeAdjustment_max_order_by: FlatFeeAdjustment_Max_Order_By;
  FlatFeeAdjustment_min_fields: ResolverTypeWrapper<FlatFeeAdjustment_Min_Fields>;
  FlatFeeAdjustment_min_order_by: FlatFeeAdjustment_Min_Order_By;
  FlatFeeAdjustment_mutation_response: ResolverTypeWrapper<FlatFeeAdjustment_Mutation_Response>;
  FlatFeeAdjustment_on_conflict: FlatFeeAdjustment_On_Conflict;
  FlatFeeAdjustment_order_by: FlatFeeAdjustment_Order_By;
  FlatFeeAdjustment_pk_columns_input: FlatFeeAdjustment_Pk_Columns_Input;
  FlatFeeAdjustment_select_column: FlatFeeAdjustment_Select_Column;
  FlatFeeAdjustment_set_input: FlatFeeAdjustment_Set_Input;
  FlatFeeAdjustment_stddev_fields: ResolverTypeWrapper<FlatFeeAdjustment_Stddev_Fields>;
  FlatFeeAdjustment_stddev_order_by: FlatFeeAdjustment_Stddev_Order_By;
  FlatFeeAdjustment_stddev_pop_fields: ResolverTypeWrapper<FlatFeeAdjustment_Stddev_Pop_Fields>;
  FlatFeeAdjustment_stddev_pop_order_by: FlatFeeAdjustment_Stddev_Pop_Order_By;
  FlatFeeAdjustment_stddev_samp_fields: ResolverTypeWrapper<FlatFeeAdjustment_Stddev_Samp_Fields>;
  FlatFeeAdjustment_stddev_samp_order_by: FlatFeeAdjustment_Stddev_Samp_Order_By;
  FlatFeeAdjustment_stream_cursor_input: FlatFeeAdjustment_Stream_Cursor_Input;
  FlatFeeAdjustment_stream_cursor_value_input: FlatFeeAdjustment_Stream_Cursor_Value_Input;
  FlatFeeAdjustment_sum_fields: ResolverTypeWrapper<FlatFeeAdjustment_Sum_Fields>;
  FlatFeeAdjustment_sum_order_by: FlatFeeAdjustment_Sum_Order_By;
  FlatFeeAdjustment_update_column: FlatFeeAdjustment_Update_Column;
  FlatFeeAdjustment_updates: FlatFeeAdjustment_Updates;
  FlatFeeAdjustment_var_pop_fields: ResolverTypeWrapper<FlatFeeAdjustment_Var_Pop_Fields>;
  FlatFeeAdjustment_var_pop_order_by: FlatFeeAdjustment_Var_Pop_Order_By;
  FlatFeeAdjustment_var_samp_fields: ResolverTypeWrapper<FlatFeeAdjustment_Var_Samp_Fields>;
  FlatFeeAdjustment_var_samp_order_by: FlatFeeAdjustment_Var_Samp_Order_By;
  FlatFeeAdjustment_variance_fields: ResolverTypeWrapper<FlatFeeAdjustment_Variance_Fields>;
  FlatFeeAdjustment_variance_order_by: FlatFeeAdjustment_Variance_Order_By;
  FlatFee_aggregate: ResolverTypeWrapper<FlatFee_Aggregate>;
  FlatFee_aggregate_bool_exp: FlatFee_Aggregate_Bool_Exp;
  FlatFee_aggregate_bool_exp_bool_and: FlatFee_Aggregate_Bool_Exp_Bool_And;
  FlatFee_aggregate_bool_exp_bool_or: FlatFee_Aggregate_Bool_Exp_Bool_Or;
  FlatFee_aggregate_bool_exp_count: FlatFee_Aggregate_Bool_Exp_Count;
  FlatFee_aggregate_fields: ResolverTypeWrapper<FlatFee_Aggregate_Fields>;
  FlatFee_aggregate_order_by: FlatFee_Aggregate_Order_By;
  FlatFee_arr_rel_insert_input: FlatFee_Arr_Rel_Insert_Input;
  FlatFee_avg_fields: ResolverTypeWrapper<FlatFee_Avg_Fields>;
  FlatFee_avg_order_by: FlatFee_Avg_Order_By;
  FlatFee_bool_exp: FlatFee_Bool_Exp;
  FlatFee_constraint: FlatFee_Constraint;
  FlatFee_inc_input: FlatFee_Inc_Input;
  FlatFee_insert_input: FlatFee_Insert_Input;
  FlatFee_max_fields: ResolverTypeWrapper<FlatFee_Max_Fields>;
  FlatFee_max_order_by: FlatFee_Max_Order_By;
  FlatFee_min_fields: ResolverTypeWrapper<FlatFee_Min_Fields>;
  FlatFee_min_order_by: FlatFee_Min_Order_By;
  FlatFee_mutation_response: ResolverTypeWrapper<FlatFee_Mutation_Response>;
  FlatFee_obj_rel_insert_input: FlatFee_Obj_Rel_Insert_Input;
  FlatFee_on_conflict: FlatFee_On_Conflict;
  FlatFee_order_by: FlatFee_Order_By;
  FlatFee_pk_columns_input: FlatFee_Pk_Columns_Input;
  FlatFee_select_column: FlatFee_Select_Column;
  FlatFee_select_column_FlatFee_aggregate_bool_exp_bool_and_arguments_columns: FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  FlatFee_select_column_FlatFee_aggregate_bool_exp_bool_or_arguments_columns: FlatFee_Select_Column_FlatFee_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  FlatFee_set_input: FlatFee_Set_Input;
  FlatFee_stddev_fields: ResolverTypeWrapper<FlatFee_Stddev_Fields>;
  FlatFee_stddev_order_by: FlatFee_Stddev_Order_By;
  FlatFee_stddev_pop_fields: ResolverTypeWrapper<FlatFee_Stddev_Pop_Fields>;
  FlatFee_stddev_pop_order_by: FlatFee_Stddev_Pop_Order_By;
  FlatFee_stddev_samp_fields: ResolverTypeWrapper<FlatFee_Stddev_Samp_Fields>;
  FlatFee_stddev_samp_order_by: FlatFee_Stddev_Samp_Order_By;
  FlatFee_stream_cursor_input: FlatFee_Stream_Cursor_Input;
  FlatFee_stream_cursor_value_input: FlatFee_Stream_Cursor_Value_Input;
  FlatFee_sum_fields: ResolverTypeWrapper<FlatFee_Sum_Fields>;
  FlatFee_sum_order_by: FlatFee_Sum_Order_By;
  FlatFee_update_column: FlatFee_Update_Column;
  FlatFee_updates: FlatFee_Updates;
  FlatFee_var_pop_fields: ResolverTypeWrapper<FlatFee_Var_Pop_Fields>;
  FlatFee_var_pop_order_by: FlatFee_Var_Pop_Order_By;
  FlatFee_var_samp_fields: ResolverTypeWrapper<FlatFee_Var_Samp_Fields>;
  FlatFee_var_samp_order_by: FlatFee_Var_Samp_Order_By;
  FlatFee_variance_fields: ResolverTypeWrapper<FlatFee_Variance_Fields>;
  FlatFee_variance_order_by: FlatFee_Variance_Order_By;
  FlatKeyValueMap: ResolverTypeWrapper<Scalars['FlatKeyValueMap']>;
  FlatRate: ResolverTypeWrapper<FlatRate>;
  FlatRateInput: FlatRateInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FutureInvoiceBehavior: FutureInvoiceBehavior;
  FutureInvoiceBehaviorInput: FutureInvoiceBehaviorInput;
  GCPProServiceRoyalty: ResolverTypeWrapper<GcpProServiceRoyalty>;
  GCPProServiceRoyaltyUpdate: ResolverTypeWrapper<GcpProServiceRoyaltyUpdate>;
  GCPRoyalty: ResolverTypeWrapper<GcpRoyalty>;
  GCPRoyaltyUpdate: ResolverTypeWrapper<GcpRoyaltyUpdate>;
  GenerateAzureAuthTokenMutationResponse: ResolverTypeWrapper<GenerateAzureAuthTokenMutationResponse>;
  GroupedChargeLineItem: ResolverTypeWrapper<Omit<GroupedChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversTypes['TierSubLineItem']>> }>;
  GroupedProductChargeLineItem: ResolverTypeWrapper<Omit<GroupedProductChargeLineItem, 'sub_line_items'> & { sub_line_items: Array<ResolversTypes['ProductChargeSubLineItem']> }>;
  GroupedUsage: ResolverTypeWrapper<GroupedUsage>;
  GroupedUsageTuple: ResolverTypeWrapper<GroupedUsageTuple>;
  GroupedUsageWindow: ResolverTypeWrapper<GroupedUsageWindow>;
  InitialUsageFilter: ResolverTypeWrapper<InitialUsageFilter>;
  InsertBillingProviderDeliveryMethodResult: ResolverTypeWrapper<InsertBillingProviderDeliveryMethodResult>;
  InsertCustomerBillingProviderConfigurationInput: InsertCustomerBillingProviderConfigurationInput;
  InsertCustomerBillingProviderConfigurationNewCustomerInput: InsertCustomerBillingProviderConfigurationNewCustomerInput;
  InsertCustomerBillingProviderConfigurationResult: ResolverTypeWrapper<InsertCustomerBillingProviderConfigurationResult>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int_comparison_exp: Int_Comparison_Exp;
  Invoice: ResolverTypeWrapper<Invoice>;
  InvoiceAdjustment: ResolverTypeWrapper<InvoiceAdjustment>;
  InvoiceCorrectionContractLineItemPointer: InvoiceCorrectionContractLineItemPointer;
  InvoiceCorrectionInput: InvoiceCorrectionInput;
  InvoiceCorrectionItem: InvoiceCorrectionItem;
  InvoiceCorrectionItemTypeEnum: InvoiceCorrectionItemTypeEnum;
  InvoiceCorrectionLineItemGroupPointer: InvoiceCorrectionLineItemGroupPointer;
  InvoiceCorrectionLineItemPointer: InvoiceCorrectionLineItemPointer;
  InvoiceCorrectionRecord: ResolverTypeWrapper<Omit<InvoiceCorrectionRecord, 'original_invoice'> & { original_invoice: ResolversTypes['CorrectableInvoice'] }>;
  InvoiceCountsByFinalizationStatusResponse: ResolverTypeWrapper<InvoiceCountsByFinalizationStatusResponse>;
  InvoiceExternalStatusEnum: ResolverTypeWrapper<InvoiceExternalStatusEnum>;
  InvoiceExternalStatusEnum_aggregate: ResolverTypeWrapper<InvoiceExternalStatusEnum_Aggregate>;
  InvoiceExternalStatusEnum_aggregate_fields: ResolverTypeWrapper<InvoiceExternalStatusEnum_Aggregate_Fields>;
  InvoiceExternalStatusEnum_bool_exp: InvoiceExternalStatusEnum_Bool_Exp;
  InvoiceExternalStatusEnum_constraint: InvoiceExternalStatusEnum_Constraint;
  InvoiceExternalStatusEnum_enum: InvoiceExternalStatusEnum_Enum;
  InvoiceExternalStatusEnum_enum_comparison_exp: InvoiceExternalStatusEnum_Enum_Comparison_Exp;
  InvoiceExternalStatusEnum_insert_input: InvoiceExternalStatusEnum_Insert_Input;
  InvoiceExternalStatusEnum_max_fields: ResolverTypeWrapper<InvoiceExternalStatusEnum_Max_Fields>;
  InvoiceExternalStatusEnum_min_fields: ResolverTypeWrapper<InvoiceExternalStatusEnum_Min_Fields>;
  InvoiceExternalStatusEnum_mutation_response: ResolverTypeWrapper<InvoiceExternalStatusEnum_Mutation_Response>;
  InvoiceExternalStatusEnum_on_conflict: InvoiceExternalStatusEnum_On_Conflict;
  InvoiceExternalStatusEnum_order_by: InvoiceExternalStatusEnum_Order_By;
  InvoiceExternalStatusEnum_pk_columns_input: InvoiceExternalStatusEnum_Pk_Columns_Input;
  InvoiceExternalStatusEnum_select_column: InvoiceExternalStatusEnum_Select_Column;
  InvoiceExternalStatusEnum_set_input: InvoiceExternalStatusEnum_Set_Input;
  InvoiceExternalStatusEnum_stream_cursor_input: InvoiceExternalStatusEnum_Stream_Cursor_Input;
  InvoiceExternalStatusEnum_stream_cursor_value_input: InvoiceExternalStatusEnum_Stream_Cursor_Value_Input;
  InvoiceExternalStatusEnum_update_column: InvoiceExternalStatusEnum_Update_Column;
  InvoiceExternalStatusEnum_updates: InvoiceExternalStatusEnum_Updates;
  InvoiceExternalTypeEnum: InvoiceExternalTypeEnum;
  InvoiceFinalizationInput: InvoiceFinalizationInput;
  InvoiceFinalizationMetadata: ResolverTypeWrapper<InvoiceFinalizationMetadata>;
  InvoiceFinalizationMetadataStatus: InvoiceFinalizationMetadataStatus;
  InvoiceFinalizationProgressCountResponse: ResolverTypeWrapper<InvoiceFinalizationProgressCountResponse>;
  InvoiceGenerationOverrides: InvoiceGenerationOverrides;
  InvoiceIdentifier: ResolverTypeWrapper<InvoiceIdentifier>;
  InvoiceManagedField: ResolverTypeWrapper<InvoiceManagedField>;
  InvoiceManagedField_aggregate: ResolverTypeWrapper<InvoiceManagedField_Aggregate>;
  InvoiceManagedField_aggregate_bool_exp: InvoiceManagedField_Aggregate_Bool_Exp;
  InvoiceManagedField_aggregate_bool_exp_bool_and: InvoiceManagedField_Aggregate_Bool_Exp_Bool_And;
  InvoiceManagedField_aggregate_bool_exp_bool_or: InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or;
  InvoiceManagedField_aggregate_bool_exp_count: InvoiceManagedField_Aggregate_Bool_Exp_Count;
  InvoiceManagedField_aggregate_fields: ResolverTypeWrapper<InvoiceManagedField_Aggregate_Fields>;
  InvoiceManagedField_aggregate_order_by: InvoiceManagedField_Aggregate_Order_By;
  InvoiceManagedField_arr_rel_insert_input: InvoiceManagedField_Arr_Rel_Insert_Input;
  InvoiceManagedField_bool_exp: InvoiceManagedField_Bool_Exp;
  InvoiceManagedField_constraint: InvoiceManagedField_Constraint;
  InvoiceManagedField_insert_input: InvoiceManagedField_Insert_Input;
  InvoiceManagedField_max_fields: ResolverTypeWrapper<InvoiceManagedField_Max_Fields>;
  InvoiceManagedField_max_order_by: InvoiceManagedField_Max_Order_By;
  InvoiceManagedField_min_fields: ResolverTypeWrapper<InvoiceManagedField_Min_Fields>;
  InvoiceManagedField_min_order_by: InvoiceManagedField_Min_Order_By;
  InvoiceManagedField_mutation_response: ResolverTypeWrapper<InvoiceManagedField_Mutation_Response>;
  InvoiceManagedField_on_conflict: InvoiceManagedField_On_Conflict;
  InvoiceManagedField_order_by: InvoiceManagedField_Order_By;
  InvoiceManagedField_pk_columns_input: InvoiceManagedField_Pk_Columns_Input;
  InvoiceManagedField_select_column: InvoiceManagedField_Select_Column;
  InvoiceManagedField_select_column_InvoiceManagedField_aggregate_bool_exp_bool_and_arguments_columns: InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  InvoiceManagedField_select_column_InvoiceManagedField_aggregate_bool_exp_bool_or_arguments_columns: InvoiceManagedField_Select_Column_InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  InvoiceManagedField_set_input: InvoiceManagedField_Set_Input;
  InvoiceManagedField_stream_cursor_input: InvoiceManagedField_Stream_Cursor_Input;
  InvoiceManagedField_stream_cursor_value_input: InvoiceManagedField_Stream_Cursor_Value_Input;
  InvoiceManagedField_update_column: InvoiceManagedField_Update_Column;
  InvoiceManagedField_updates: InvoiceManagedField_Updates;
  InvoicePage: ResolverTypeWrapper<InvoicePage>;
  InvoiceScheduleTypeEnum: InvoiceScheduleTypeEnum;
  InvoiceSortOrder: InvoiceSortOrder;
  InvoiceStatus: InvoiceStatus;
  InvoiceStatusEnum: InvoiceStatusEnum;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  LaunchSoxReportGeneratorMutationResponse: ResolverTypeWrapper<LaunchSoxReportGeneratorMutationResponse>;
  LegacyContractLineItem: ResolverTypeWrapper<LegacyContractLineItem>;
  LegacyLineItem: ResolverTypeWrapper<LegacyLineItem>;
  LineItem: ResolverTypeWrapper<LineItem>;
  LineItemChunk: ResolverTypeWrapper<LineItemChunk>;
  ListDeliveryMethodsResponse: ResolverTypeWrapper<ListDeliveryMethodsResponse>;
  ListDeliveryMethodsWithoutConfigurationForAllClientsResponse: ResolverTypeWrapper<ListDeliveryMethodsWithoutConfigurationForAllClientsResponse>;
  MRI_BillingFrequencyEnum: Mri_BillingFrequencyEnum;
  MRI_CollectionScheduleEnum: Mri_CollectionScheduleEnum;
  MRI_CreditBalance: ResolverTypeWrapper<Mri_CreditBalance>;
  MRI_CreditDeductionCreditLedgerEntry: ResolverTypeWrapper<Mri_CreditDeductionCreditLedgerEntry>;
  MRI_CreditExpirationCreditLedgerEntry: ResolverTypeWrapper<Mri_CreditExpirationCreditLedgerEntry>;
  MRI_CreditGrant: ResolverTypeWrapper<Mri_CreditGrant>;
  MRI_CreditGrantCreditLedgerEntry: ResolverTypeWrapper<Mri_CreditGrantCreditLedgerEntry>;
  MRI_CreditLedger: ResolverTypeWrapper<Mri_CreditLedger>;
  MRI_CreditLedgerEntry: ResolversTypes['MRI_CreditDeductionCreditLedgerEntry'] | ResolversTypes['MRI_CreditExpirationCreditLedgerEntry'] | ResolversTypes['MRI_CreditGrantCreditLedgerEntry'] | ResolversTypes['MRI_PendingChargeCreditLedgerEntry'] | ResolversTypes['MRI_PendingCreditExpirationCreditLedgerEntry'] | ResolversTypes['MRI_PendingRolloverCreditGrantLedgerEntry'] | ResolversTypes['MRI_PendingRolloverDeductionLedgerEntry'] | ResolversTypes['MRI_RolloverCreditGrantLedgerEntry'] | ResolversTypes['MRI_RolloverDeductionLedgerEntry'];
  MRI_GroupedUsage: ResolverTypeWrapper<Mri_GroupedUsage>;
  MRI_GroupedUsageTuple: ResolverTypeWrapper<Mri_GroupedUsageTuple>;
  MRI_GroupedUsageWindow: ResolverTypeWrapper<Mri_GroupedUsageWindow>;
  MRI_Invoice: ResolversTypes['AdHocPlanInvoice'] | ResolversTypes['AdhocContractUsageInvoice'] | ResolversTypes['AdvanceInvoice'] | ResolversTypes['ArrearsInvoice'] | ResolversTypes['ContractPostpaidTrueupInvoice'] | ResolversTypes['ContractProServiceInvoice'] | ResolversTypes['ContractRefundInvoice'] | ResolversTypes['ContractScheduledInvoice'] | ResolversTypes['ContractUsageInvoice'] | ResolversTypes['CorrectionInvoice'] | ResolversTypes['CreditPurchaseInvoice'] | ResolversTypes['ParentInvoice'] | ResolversTypes['SeatPurchaseInvoice'];
  MRI_LineItem: ResolversTypes['AdvanceChargeLineItem'] | ResolversTypes['ChargeLineItem'] | ResolversTypes['CompositeChargeLineItem'] | ResolversTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversTypes['ContractAWSRoyaltyLineItem'] | ResolversTypes['ContractAppliedCommitLineItem'] | ResolversTypes['ContractChargeLineItem'] | ResolversTypes['ContractCommitLineItem'] | ResolversTypes['ContractConversionLineItem'] | ResolversTypes['ContractDiscountLineItem'] | ResolversTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversTypes['ContractGCPRoyaltyLineItem'] | ResolversTypes['ContractPostpaidTrueupLineItem'] | ResolversTypes['ContractProServiceLineItem'] | ResolversTypes['ContractRolloverCommitLineItem'] | ResolversTypes['ContractSubscriptionLineItem'] | ResolversTypes['ContractUsageLineItem'] | ResolversTypes['CreditLineItem'] | ResolversTypes['CreditPurchaseChargeLineItem'] | ResolversTypes['CreditPurchaseLineItem'] | ResolversTypes['GroupedChargeLineItem'] | ResolversTypes['GroupedProductChargeLineItem'] | ResolversTypes['LegacyContractLineItem'] | ResolversTypes['LegacyLineItem'] | ResolversTypes['MinimumLineItem'] | ResolversTypes['OverageLineItem'] | ResolversTypes['ProductChargeLineItem'] | ResolversTypes['SeatChargeLineItem'] | ResolversTypes['TierChargeLineItem'] | ResolversTypes['TrialDiscountLineItem'];
  MRI_PendingChargeCreditLedgerEntry: ResolverTypeWrapper<Mri_PendingChargeCreditLedgerEntry>;
  MRI_PendingCreditExpirationCreditLedgerEntry: ResolverTypeWrapper<Mri_PendingCreditExpirationCreditLedgerEntry>;
  MRI_PendingRolloverCreditGrantLedgerEntry: ResolverTypeWrapper<Mri_PendingRolloverCreditGrantLedgerEntry>;
  MRI_PendingRolloverDeductionLedgerEntry: ResolverTypeWrapper<Mri_PendingRolloverDeductionLedgerEntry>;
  MRI_RolloverCreditGrantLedgerEntry: ResolverTypeWrapper<Mri_RolloverCreditGrantLedgerEntry>;
  MRI_RolloverDeductionLedgerEntry: ResolverTypeWrapper<Mri_RolloverDeductionLedgerEntry>;
  MRI_ServicePeriodStartOnEnum: Mri_ServicePeriodStartOnEnum;
  MRI_UsageGroupByInput: Mri_UsageGroupByInput;
  ManagedEntityEnum: ResolverTypeWrapper<ManagedEntityEnum>;
  ManagedEntityEnum_aggregate: ResolverTypeWrapper<ManagedEntityEnum_Aggregate>;
  ManagedEntityEnum_aggregate_fields: ResolverTypeWrapper<ManagedEntityEnum_Aggregate_Fields>;
  ManagedEntityEnum_bool_exp: ManagedEntityEnum_Bool_Exp;
  ManagedEntityEnum_constraint: ManagedEntityEnum_Constraint;
  ManagedEntityEnum_enum: ManagedEntityEnum_Enum;
  ManagedEntityEnum_enum_comparison_exp: ManagedEntityEnum_Enum_Comparison_Exp;
  ManagedEntityEnum_insert_input: ManagedEntityEnum_Insert_Input;
  ManagedEntityEnum_max_fields: ResolverTypeWrapper<ManagedEntityEnum_Max_Fields>;
  ManagedEntityEnum_min_fields: ResolverTypeWrapper<ManagedEntityEnum_Min_Fields>;
  ManagedEntityEnum_mutation_response: ResolverTypeWrapper<ManagedEntityEnum_Mutation_Response>;
  ManagedEntityEnum_on_conflict: ManagedEntityEnum_On_Conflict;
  ManagedEntityEnum_order_by: ManagedEntityEnum_Order_By;
  ManagedEntityEnum_pk_columns_input: ManagedEntityEnum_Pk_Columns_Input;
  ManagedEntityEnum_select_column: ManagedEntityEnum_Select_Column;
  ManagedEntityEnum_set_input: ManagedEntityEnum_Set_Input;
  ManagedEntityEnum_stream_cursor_input: ManagedEntityEnum_Stream_Cursor_Input;
  ManagedEntityEnum_stream_cursor_value_input: ManagedEntityEnum_Stream_Cursor_Value_Input;
  ManagedEntityEnum_update_column: ManagedEntityEnum_Update_Column;
  ManagedEntityEnum_updates: ManagedEntityEnum_Updates;
  ManagedFieldInput: ManagedFieldInput;
  ManagedFieldKey: ResolverTypeWrapper<ManagedFieldKey>;
  ManagedFieldKey_aggregate: ResolverTypeWrapper<ManagedFieldKey_Aggregate>;
  ManagedFieldKey_aggregate_fields: ResolverTypeWrapper<ManagedFieldKey_Aggregate_Fields>;
  ManagedFieldKey_bool_exp: ManagedFieldKey_Bool_Exp;
  ManagedFieldKey_constraint: ManagedFieldKey_Constraint;
  ManagedFieldKey_insert_input: ManagedFieldKey_Insert_Input;
  ManagedFieldKey_max_fields: ResolverTypeWrapper<ManagedFieldKey_Max_Fields>;
  ManagedFieldKey_min_fields: ResolverTypeWrapper<ManagedFieldKey_Min_Fields>;
  ManagedFieldKey_mutation_response: ResolverTypeWrapper<ManagedFieldKey_Mutation_Response>;
  ManagedFieldKey_obj_rel_insert_input: ManagedFieldKey_Obj_Rel_Insert_Input;
  ManagedFieldKey_on_conflict: ManagedFieldKey_On_Conflict;
  ManagedFieldKey_order_by: ManagedFieldKey_Order_By;
  ManagedFieldKey_pk_columns_input: ManagedFieldKey_Pk_Columns_Input;
  ManagedFieldKey_select_column: ManagedFieldKey_Select_Column;
  ManagedFieldKey_set_input: ManagedFieldKey_Set_Input;
  ManagedFieldKey_stream_cursor_input: ManagedFieldKey_Stream_Cursor_Input;
  ManagedFieldKey_stream_cursor_value_input: ManagedFieldKey_Stream_Cursor_Value_Input;
  ManagedFieldKey_update_column: ManagedFieldKey_Update_Column;
  ManagedFieldKey_updates: ManagedFieldKey_Updates;
  ManagedFieldVendorMapping: ResolverTypeWrapper<ManagedFieldVendorMapping>;
  ManagedFieldVendorMapping_aggregate: ResolverTypeWrapper<ManagedFieldVendorMapping_Aggregate>;
  ManagedFieldVendorMapping_aggregate_fields: ResolverTypeWrapper<ManagedFieldVendorMapping_Aggregate_Fields>;
  ManagedFieldVendorMapping_bool_exp: ManagedFieldVendorMapping_Bool_Exp;
  ManagedFieldVendorMapping_constraint: ManagedFieldVendorMapping_Constraint;
  ManagedFieldVendorMapping_insert_input: ManagedFieldVendorMapping_Insert_Input;
  ManagedFieldVendorMapping_max_fields: ResolverTypeWrapper<ManagedFieldVendorMapping_Max_Fields>;
  ManagedFieldVendorMapping_min_fields: ResolverTypeWrapper<ManagedFieldVendorMapping_Min_Fields>;
  ManagedFieldVendorMapping_mutation_response: ResolverTypeWrapper<ManagedFieldVendorMapping_Mutation_Response>;
  ManagedFieldVendorMapping_on_conflict: ManagedFieldVendorMapping_On_Conflict;
  ManagedFieldVendorMapping_order_by: ManagedFieldVendorMapping_Order_By;
  ManagedFieldVendorMapping_pk_columns_input: ManagedFieldVendorMapping_Pk_Columns_Input;
  ManagedFieldVendorMapping_select_column: ManagedFieldVendorMapping_Select_Column;
  ManagedFieldVendorMapping_set_input: ManagedFieldVendorMapping_Set_Input;
  ManagedFieldVendorMapping_stream_cursor_input: ManagedFieldVendorMapping_Stream_Cursor_Input;
  ManagedFieldVendorMapping_stream_cursor_value_input: ManagedFieldVendorMapping_Stream_Cursor_Value_Input;
  ManagedFieldVendorMapping_update_column: ManagedFieldVendorMapping_Update_Column;
  ManagedFieldVendorMapping_updates: ManagedFieldVendorMapping_Updates;
  ManagedVendorEnum: ResolverTypeWrapper<ManagedVendorEnum>;
  ManagedVendorEnum_aggregate: ResolverTypeWrapper<ManagedVendorEnum_Aggregate>;
  ManagedVendorEnum_aggregate_fields: ResolverTypeWrapper<ManagedVendorEnum_Aggregate_Fields>;
  ManagedVendorEnum_bool_exp: ManagedVendorEnum_Bool_Exp;
  ManagedVendorEnum_constraint: ManagedVendorEnum_Constraint;
  ManagedVendorEnum_enum: ManagedVendorEnum_Enum;
  ManagedVendorEnum_enum_comparison_exp: ManagedVendorEnum_Enum_Comparison_Exp;
  ManagedVendorEnum_insert_input: ManagedVendorEnum_Insert_Input;
  ManagedVendorEnum_max_fields: ResolverTypeWrapper<ManagedVendorEnum_Max_Fields>;
  ManagedVendorEnum_min_fields: ResolverTypeWrapper<ManagedVendorEnum_Min_Fields>;
  ManagedVendorEnum_mutation_response: ResolverTypeWrapper<ManagedVendorEnum_Mutation_Response>;
  ManagedVendorEnum_on_conflict: ManagedVendorEnum_On_Conflict;
  ManagedVendorEnum_order_by: ManagedVendorEnum_Order_By;
  ManagedVendorEnum_pk_columns_input: ManagedVendorEnum_Pk_Columns_Input;
  ManagedVendorEnum_select_column: ManagedVendorEnum_Select_Column;
  ManagedVendorEnum_set_input: ManagedVendorEnum_Set_Input;
  ManagedVendorEnum_stream_cursor_input: ManagedVendorEnum_Stream_Cursor_Input;
  ManagedVendorEnum_stream_cursor_value_input: ManagedVendorEnum_Stream_Cursor_Value_Input;
  ManagedVendorEnum_update_column: ManagedVendorEnum_Update_Column;
  ManagedVendorEnum_updates: ManagedVendorEnum_Updates;
  MaxAmountRolloverAmountInput: MaxAmountRolloverAmountInput;
  MaxPercentageRolloverAmountInput: MaxPercentageRolloverAmountInput;
  MetricValidationResponse: ResolverTypeWrapper<MetricValidationResponse>;
  Minimum: ResolverTypeWrapper<Minimum>;
  MinimumInput: MinimumInput;
  MinimumLineItem: ResolverTypeWrapper<MinimumLineItem>;
  Minimum_aggregate: ResolverTypeWrapper<Minimum_Aggregate>;
  Minimum_aggregate_bool_exp: Minimum_Aggregate_Bool_Exp;
  Minimum_aggregate_bool_exp_count: Minimum_Aggregate_Bool_Exp_Count;
  Minimum_aggregate_fields: ResolverTypeWrapper<Minimum_Aggregate_Fields>;
  Minimum_aggregate_order_by: Minimum_Aggregate_Order_By;
  Minimum_arr_rel_insert_input: Minimum_Arr_Rel_Insert_Input;
  Minimum_avg_fields: ResolverTypeWrapper<Minimum_Avg_Fields>;
  Minimum_avg_order_by: Minimum_Avg_Order_By;
  Minimum_bool_exp: Minimum_Bool_Exp;
  Minimum_constraint: Minimum_Constraint;
  Minimum_inc_input: Minimum_Inc_Input;
  Minimum_insert_input: Minimum_Insert_Input;
  Minimum_max_fields: ResolverTypeWrapper<Minimum_Max_Fields>;
  Minimum_max_order_by: Minimum_Max_Order_By;
  Minimum_min_fields: ResolverTypeWrapper<Minimum_Min_Fields>;
  Minimum_min_order_by: Minimum_Min_Order_By;
  Minimum_mutation_response: ResolverTypeWrapper<Minimum_Mutation_Response>;
  Minimum_on_conflict: Minimum_On_Conflict;
  Minimum_order_by: Minimum_Order_By;
  Minimum_pk_columns_input: Minimum_Pk_Columns_Input;
  Minimum_select_column: Minimum_Select_Column;
  Minimum_set_input: Minimum_Set_Input;
  Minimum_stddev_fields: ResolverTypeWrapper<Minimum_Stddev_Fields>;
  Minimum_stddev_order_by: Minimum_Stddev_Order_By;
  Minimum_stddev_pop_fields: ResolverTypeWrapper<Minimum_Stddev_Pop_Fields>;
  Minimum_stddev_pop_order_by: Minimum_Stddev_Pop_Order_By;
  Minimum_stddev_samp_fields: ResolverTypeWrapper<Minimum_Stddev_Samp_Fields>;
  Minimum_stddev_samp_order_by: Minimum_Stddev_Samp_Order_By;
  Minimum_stream_cursor_input: Minimum_Stream_Cursor_Input;
  Minimum_stream_cursor_value_input: Minimum_Stream_Cursor_Value_Input;
  Minimum_sum_fields: ResolverTypeWrapper<Minimum_Sum_Fields>;
  Minimum_sum_order_by: Minimum_Sum_Order_By;
  Minimum_update_column: Minimum_Update_Column;
  Minimum_updates: Minimum_Updates;
  Minimum_var_pop_fields: ResolverTypeWrapper<Minimum_Var_Pop_Fields>;
  Minimum_var_pop_order_by: Minimum_Var_Pop_Order_By;
  Minimum_var_samp_fields: ResolverTypeWrapper<Minimum_Var_Samp_Fields>;
  Minimum_var_samp_order_by: Minimum_Var_Samp_Order_By;
  Minimum_variance_fields: ResolverTypeWrapper<Minimum_Variance_Fields>;
  Minimum_variance_order_by: Minimum_Variance_Order_By;
  MoveRateCardProductsInput: MoveRateCardProductsInput;
  MultiplierOverride: ResolverTypeWrapper<MultiplierOverride>;
  Mutation: ResolverTypeWrapper<{}>;
  MutationResponse: ResolverTypeWrapper<MutationResponse>;
  NamedSchedule: ResolverTypeWrapper<NamedSchedule>;
  NamedScheduleData: ResolverTypeWrapper<NamedScheduleData>;
  NamedScheduleSegment: ResolverTypeWrapper<NamedScheduleSegment>;
  NewUsageFilterInput: NewUsageFilterInput;
  NonCompositeProductListItem: ResolversTypes['FixedProductListItem'] | ResolversTypes['ProServiceProductListItem'] | ResolversTypes['SubscriptionProductListItem'] | ResolversTypes['UsageProductListItem'];
  NullableTimeRangeInput: NullableTimeRangeInput;
  OneTimeProductCharge: ResolverTypeWrapper<OneTimeProductCharge>;
  OverageLineItem: ResolverTypeWrapper<OverageLineItem>;
  OverrideRate: ResolversTypes['MultiplierOverride'] | ResolversTypes['OverwriteOverride'] | ResolversTypes['TieredOverride'];
  OverrideRateInput: OverrideRateInput;
  OverrideSpecifier: ResolverTypeWrapper<OverrideSpecifier>;
  OverrideSpecifierInput: OverrideSpecifierInput;
  OverwriteOverride: ResolverTypeWrapper<Omit<OverwriteOverride, 'new_rate'> & { new_rate: ResolversTypes['Rate'] }>;
  PagedSeatMetricsResult: ResolverTypeWrapper<PagedSeatMetricsResult>;
  PagedUsage: ResolverTypeWrapper<PagedUsage>;
  PagedUsageWindow: ResolverTypeWrapper<PagedUsageWindow>;
  ParentInvoice: ResolverTypeWrapper<Omit<ParentInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  PendingPrepaidGrant: ResolverTypeWrapper<PendingPrepaidGrant>;
  PendingPrepaidGrantsResponse: ResolverTypeWrapper<PendingPrepaidGrantsResponse>;
  PendingRechargesResponse: ResolverTypeWrapper<PendingRechargesResponse>;
  PepaidCreditGrantResponse: ResolverTypeWrapper<PepaidCreditGrantResponse>;
  PercentageRate: ResolverTypeWrapper<PercentageRate>;
  PercentageRateInput: PercentageRateInput;
  Plan: ResolverTypeWrapper<Plan>;
  PlanInput: PlanInput;
  PlanManagedField: ResolverTypeWrapper<PlanManagedField>;
  PlanManagedField_aggregate: ResolverTypeWrapper<PlanManagedField_Aggregate>;
  PlanManagedField_aggregate_bool_exp: PlanManagedField_Aggregate_Bool_Exp;
  PlanManagedField_aggregate_bool_exp_bool_and: PlanManagedField_Aggregate_Bool_Exp_Bool_And;
  PlanManagedField_aggregate_bool_exp_bool_or: PlanManagedField_Aggregate_Bool_Exp_Bool_Or;
  PlanManagedField_aggregate_bool_exp_count: PlanManagedField_Aggregate_Bool_Exp_Count;
  PlanManagedField_aggregate_fields: ResolverTypeWrapper<PlanManagedField_Aggregate_Fields>;
  PlanManagedField_aggregate_order_by: PlanManagedField_Aggregate_Order_By;
  PlanManagedField_arr_rel_insert_input: PlanManagedField_Arr_Rel_Insert_Input;
  PlanManagedField_bool_exp: PlanManagedField_Bool_Exp;
  PlanManagedField_constraint: PlanManagedField_Constraint;
  PlanManagedField_insert_input: PlanManagedField_Insert_Input;
  PlanManagedField_max_fields: ResolverTypeWrapper<PlanManagedField_Max_Fields>;
  PlanManagedField_max_order_by: PlanManagedField_Max_Order_By;
  PlanManagedField_min_fields: ResolverTypeWrapper<PlanManagedField_Min_Fields>;
  PlanManagedField_min_order_by: PlanManagedField_Min_Order_By;
  PlanManagedField_mutation_response: ResolverTypeWrapper<PlanManagedField_Mutation_Response>;
  PlanManagedField_on_conflict: PlanManagedField_On_Conflict;
  PlanManagedField_order_by: PlanManagedField_Order_By;
  PlanManagedField_pk_columns_input: PlanManagedField_Pk_Columns_Input;
  PlanManagedField_select_column: PlanManagedField_Select_Column;
  PlanManagedField_select_column_PlanManagedField_aggregate_bool_exp_bool_and_arguments_columns: PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  PlanManagedField_select_column_PlanManagedField_aggregate_bool_exp_bool_or_arguments_columns: PlanManagedField_Select_Column_PlanManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  PlanManagedField_set_input: PlanManagedField_Set_Input;
  PlanManagedField_stream_cursor_input: PlanManagedField_Stream_Cursor_Input;
  PlanManagedField_stream_cursor_value_input: PlanManagedField_Stream_Cursor_Value_Input;
  PlanManagedField_update_column: PlanManagedField_Update_Column;
  PlanManagedField_updates: PlanManagedField_Updates;
  PlanTypeEnum: PlanTypeEnum;
  Plan_aggregate: ResolverTypeWrapper<Plan_Aggregate>;
  Plan_aggregate_bool_exp: Plan_Aggregate_Bool_Exp;
  Plan_aggregate_bool_exp_bool_and: Plan_Aggregate_Bool_Exp_Bool_And;
  Plan_aggregate_bool_exp_bool_or: Plan_Aggregate_Bool_Exp_Bool_Or;
  Plan_aggregate_bool_exp_count: Plan_Aggregate_Bool_Exp_Count;
  Plan_aggregate_fields: ResolverTypeWrapper<Plan_Aggregate_Fields>;
  Plan_aggregate_order_by: Plan_Aggregate_Order_By;
  Plan_arr_rel_insert_input: Plan_Arr_Rel_Insert_Input;
  Plan_avg_fields: ResolverTypeWrapper<Plan_Avg_Fields>;
  Plan_avg_order_by: Plan_Avg_Order_By;
  Plan_bool_exp: Plan_Bool_Exp;
  Plan_constraint: Plan_Constraint;
  Plan_inc_input: Plan_Inc_Input;
  Plan_insert_input: Plan_Insert_Input;
  Plan_max_fields: ResolverTypeWrapper<Plan_Max_Fields>;
  Plan_max_order_by: Plan_Max_Order_By;
  Plan_min_fields: ResolverTypeWrapper<Plan_Min_Fields>;
  Plan_min_order_by: Plan_Min_Order_By;
  Plan_mutation_response: ResolverTypeWrapper<Plan_Mutation_Response>;
  Plan_obj_rel_insert_input: Plan_Obj_Rel_Insert_Input;
  Plan_on_conflict: Plan_On_Conflict;
  Plan_order_by: Plan_Order_By;
  Plan_pk_columns_input: Plan_Pk_Columns_Input;
  Plan_select_column: Plan_Select_Column;
  Plan_select_column_Plan_aggregate_bool_exp_bool_and_arguments_columns: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  Plan_select_column_Plan_aggregate_bool_exp_bool_or_arguments_columns: Plan_Select_Column_Plan_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  Plan_set_input: Plan_Set_Input;
  Plan_stddev_fields: ResolverTypeWrapper<Plan_Stddev_Fields>;
  Plan_stddev_order_by: Plan_Stddev_Order_By;
  Plan_stddev_pop_fields: ResolverTypeWrapper<Plan_Stddev_Pop_Fields>;
  Plan_stddev_pop_order_by: Plan_Stddev_Pop_Order_By;
  Plan_stddev_samp_fields: ResolverTypeWrapper<Plan_Stddev_Samp_Fields>;
  Plan_stddev_samp_order_by: Plan_Stddev_Samp_Order_By;
  Plan_stream_cursor_input: Plan_Stream_Cursor_Input;
  Plan_stream_cursor_value_input: Plan_Stream_Cursor_Value_Input;
  Plan_sum_fields: ResolverTypeWrapper<Plan_Sum_Fields>;
  Plan_sum_order_by: Plan_Sum_Order_By;
  Plan_update_column: Plan_Update_Column;
  Plan_updates: Plan_Updates;
  Plan_var_pop_fields: ResolverTypeWrapper<Plan_Var_Pop_Fields>;
  Plan_var_pop_order_by: Plan_Var_Pop_Order_By;
  Plan_var_samp_fields: ResolverTypeWrapper<Plan_Var_Samp_Fields>;
  Plan_var_samp_order_by: Plan_Var_Samp_Order_By;
  Plan_variance_fields: ResolverTypeWrapper<Plan_Variance_Fields>;
  Plan_variance_order_by: Plan_Variance_Order_By;
  PostpaidCommit: ResolverTypeWrapper<Omit<PostpaidCommit, 'applicable_products' | 'ledger' | 'product'> & { applicable_products: Maybe<Array<ResolversTypes['ProductListItem']>>, ledger: Array<ResolversTypes['PostpaidCommitLedgerEntry']>, product: ResolversTypes['ProductListItem'] }>;
  PostpaidCommitAutomatedInvoiceDeductionLedgerEntry: ResolverTypeWrapper<PostpaidCommitAutomatedInvoiceDeductionLedgerEntry>;
  PostpaidCommitBaseLedgerEntry: ResolversTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversTypes['PostpaidCommitCanceledLedgerEntry'] | ResolversTypes['PostpaidCommitCreditedLedgerEntry'] | ResolversTypes['PostpaidCommitExpirationLedgerEntry'] | ResolversTypes['PostpaidCommitInitialBalanceLedgerEntry'] | ResolversTypes['PostpaidCommitManualLedgerEntry'] | ResolversTypes['PostpaidCommitRolloverLedgerEntry'] | ResolversTypes['PostpaidCommitTrueupLedgerEntry'];
  PostpaidCommitCanceledLedgerEntry: ResolverTypeWrapper<PostpaidCommitCanceledLedgerEntry>;
  PostpaidCommitCreditedLedgerEntry: ResolverTypeWrapper<PostpaidCommitCreditedLedgerEntry>;
  PostpaidCommitExpirationLedgerEntry: ResolverTypeWrapper<PostpaidCommitExpirationLedgerEntry>;
  PostpaidCommitInitialBalanceLedgerEntry: ResolverTypeWrapper<PostpaidCommitInitialBalanceLedgerEntry>;
  PostpaidCommitLedgerEntry: ResolversTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversTypes['PostpaidCommitCanceledLedgerEntry'] | ResolversTypes['PostpaidCommitCreditedLedgerEntry'] | ResolversTypes['PostpaidCommitExpirationLedgerEntry'] | ResolversTypes['PostpaidCommitInitialBalanceLedgerEntry'] | ResolversTypes['PostpaidCommitManualLedgerEntry'] | ResolversTypes['PostpaidCommitRolloverLedgerEntry'] | ResolversTypes['PostpaidCommitTrueupLedgerEntry'];
  PostpaidCommitManualLedgerEntry: ResolverTypeWrapper<PostpaidCommitManualLedgerEntry>;
  PostpaidCommitRolloverLedgerEntry: ResolverTypeWrapper<PostpaidCommitRolloverLedgerEntry>;
  PostpaidCommitTrueupLedgerEntry: ResolverTypeWrapper<PostpaidCommitTrueupLedgerEntry>;
  PrepaidCommit: ResolverTypeWrapper<Omit<PrepaidCommit, 'applicable_products' | 'ledger' | 'product'> & { applicable_products: Maybe<Array<ResolversTypes['ProductListItem']>>, ledger: Array<ResolversTypes['PrepaidCommitLedgerEntry']>, product: ResolversTypes['ProductListItem'] }>;
  PrepaidCommitAutomatedInvoiceDeductionLedgerEntry: ResolverTypeWrapper<PrepaidCommitAutomatedInvoiceDeductionLedgerEntry>;
  PrepaidCommitBaseLedgerEntry: ResolversTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversTypes['PrepaidCommitCanceledLedgerEntry'] | ResolversTypes['PrepaidCommitCreditedLedgerEntry'] | ResolversTypes['PrepaidCommitExpirationLedgerEntry'] | ResolversTypes['PrepaidCommitManualLedgerEntry'] | ResolversTypes['PrepaidCommitRolloverLedgerEntry'] | ResolversTypes['PrepaidCommitSegmentStartLedgerEntry'];
  PrepaidCommitCanceledLedgerEntry: ResolverTypeWrapper<PrepaidCommitCanceledLedgerEntry>;
  PrepaidCommitCreditedLedgerEntry: ResolverTypeWrapper<PrepaidCommitCreditedLedgerEntry>;
  PrepaidCommitExpirationLedgerEntry: ResolverTypeWrapper<PrepaidCommitExpirationLedgerEntry>;
  PrepaidCommitLedgerEntry: ResolversTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversTypes['PrepaidCommitCanceledLedgerEntry'] | ResolversTypes['PrepaidCommitCreditedLedgerEntry'] | ResolversTypes['PrepaidCommitExpirationLedgerEntry'] | ResolversTypes['PrepaidCommitManualLedgerEntry'] | ResolversTypes['PrepaidCommitRolloverLedgerEntry'] | ResolversTypes['PrepaidCommitSegmentStartLedgerEntry'];
  PrepaidCommitManualLedgerEntry: ResolverTypeWrapper<PrepaidCommitManualLedgerEntry>;
  PrepaidCommitRolloverLedgerEntry: ResolverTypeWrapper<PrepaidCommitRolloverLedgerEntry>;
  PrepaidCommitSegmentStartLedgerEntry: ResolverTypeWrapper<PrepaidCommitSegmentStartLedgerEntry>;
  PrepaidCreditGrant: ResolverTypeWrapper<PrepaidCreditGrant>;
  PrepaidCreditGrantInput: PrepaidCreditGrantInput;
  PrepaidCreditGrantRechargeResponse: ResolverTypeWrapper<PrepaidCreditGrantRechargeResponse>;
  PrepaidCreditGrantStatusEnum: PrepaidCreditGrantStatusEnum;
  PrequelAuthTokenResponse: ResolverTypeWrapper<PrequelAuthTokenResponse>;
  PresentationGroupValue: ResolverTypeWrapper<PresentationGroupValue>;
  PresentationGroupValueInput: PresentationGroupValueInput;
  Price: ResolverTypeWrapper<Price>;
  PriceAdjustment: ResolverTypeWrapper<PriceAdjustment>;
  PriceAdjustmentInput: PriceAdjustmentInput;
  PriceAdjustmentTypeEnum: ResolverTypeWrapper<PriceAdjustmentTypeEnum>;
  PriceAdjustmentTypeEnum_aggregate: ResolverTypeWrapper<PriceAdjustmentTypeEnum_Aggregate>;
  PriceAdjustmentTypeEnum_aggregate_fields: ResolverTypeWrapper<PriceAdjustmentTypeEnum_Aggregate_Fields>;
  PriceAdjustmentTypeEnum_bool_exp: PriceAdjustmentTypeEnum_Bool_Exp;
  PriceAdjustmentTypeEnum_constraint: PriceAdjustmentTypeEnum_Constraint;
  PriceAdjustmentTypeEnum_enum: PriceAdjustmentTypeEnum_Enum;
  PriceAdjustmentTypeEnum_enum_comparison_exp: PriceAdjustmentTypeEnum_Enum_Comparison_Exp;
  PriceAdjustmentTypeEnum_insert_input: PriceAdjustmentTypeEnum_Insert_Input;
  PriceAdjustmentTypeEnum_max_fields: ResolverTypeWrapper<PriceAdjustmentTypeEnum_Max_Fields>;
  PriceAdjustmentTypeEnum_min_fields: ResolverTypeWrapper<PriceAdjustmentTypeEnum_Min_Fields>;
  PriceAdjustmentTypeEnum_mutation_response: ResolverTypeWrapper<PriceAdjustmentTypeEnum_Mutation_Response>;
  PriceAdjustmentTypeEnum_obj_rel_insert_input: PriceAdjustmentTypeEnum_Obj_Rel_Insert_Input;
  PriceAdjustmentTypeEnum_on_conflict: PriceAdjustmentTypeEnum_On_Conflict;
  PriceAdjustmentTypeEnum_order_by: PriceAdjustmentTypeEnum_Order_By;
  PriceAdjustmentTypeEnum_pk_columns_input: PriceAdjustmentTypeEnum_Pk_Columns_Input;
  PriceAdjustmentTypeEnum_select_column: PriceAdjustmentTypeEnum_Select_Column;
  PriceAdjustmentTypeEnum_set_input: PriceAdjustmentTypeEnum_Set_Input;
  PriceAdjustmentTypeEnum_stream_cursor_input: PriceAdjustmentTypeEnum_Stream_Cursor_Input;
  PriceAdjustmentTypeEnum_stream_cursor_value_input: PriceAdjustmentTypeEnum_Stream_Cursor_Value_Input;
  PriceAdjustmentTypeEnum_update_column: PriceAdjustmentTypeEnum_Update_Column;
  PriceAdjustmentTypeEnum_updates: PriceAdjustmentTypeEnum_Updates;
  PriceAdjustment_aggregate: ResolverTypeWrapper<PriceAdjustment_Aggregate>;
  PriceAdjustment_aggregate_bool_exp: PriceAdjustment_Aggregate_Bool_Exp;
  PriceAdjustment_aggregate_bool_exp_count: PriceAdjustment_Aggregate_Bool_Exp_Count;
  PriceAdjustment_aggregate_fields: ResolverTypeWrapper<PriceAdjustment_Aggregate_Fields>;
  PriceAdjustment_aggregate_order_by: PriceAdjustment_Aggregate_Order_By;
  PriceAdjustment_arr_rel_insert_input: PriceAdjustment_Arr_Rel_Insert_Input;
  PriceAdjustment_avg_fields: ResolverTypeWrapper<PriceAdjustment_Avg_Fields>;
  PriceAdjustment_avg_order_by: PriceAdjustment_Avg_Order_By;
  PriceAdjustment_bool_exp: PriceAdjustment_Bool_Exp;
  PriceAdjustment_constraint: PriceAdjustment_Constraint;
  PriceAdjustment_inc_input: PriceAdjustment_Inc_Input;
  PriceAdjustment_insert_input: PriceAdjustment_Insert_Input;
  PriceAdjustment_max_fields: ResolverTypeWrapper<PriceAdjustment_Max_Fields>;
  PriceAdjustment_max_order_by: PriceAdjustment_Max_Order_By;
  PriceAdjustment_min_fields: ResolverTypeWrapper<PriceAdjustment_Min_Fields>;
  PriceAdjustment_min_order_by: PriceAdjustment_Min_Order_By;
  PriceAdjustment_mutation_response: ResolverTypeWrapper<PriceAdjustment_Mutation_Response>;
  PriceAdjustment_on_conflict: PriceAdjustment_On_Conflict;
  PriceAdjustment_order_by: PriceAdjustment_Order_By;
  PriceAdjustment_pk_columns_input: PriceAdjustment_Pk_Columns_Input;
  PriceAdjustment_select_column: PriceAdjustment_Select_Column;
  PriceAdjustment_set_input: PriceAdjustment_Set_Input;
  PriceAdjustment_stddev_fields: ResolverTypeWrapper<PriceAdjustment_Stddev_Fields>;
  PriceAdjustment_stddev_order_by: PriceAdjustment_Stddev_Order_By;
  PriceAdjustment_stddev_pop_fields: ResolverTypeWrapper<PriceAdjustment_Stddev_Pop_Fields>;
  PriceAdjustment_stddev_pop_order_by: PriceAdjustment_Stddev_Pop_Order_By;
  PriceAdjustment_stddev_samp_fields: ResolverTypeWrapper<PriceAdjustment_Stddev_Samp_Fields>;
  PriceAdjustment_stddev_samp_order_by: PriceAdjustment_Stddev_Samp_Order_By;
  PriceAdjustment_stream_cursor_input: PriceAdjustment_Stream_Cursor_Input;
  PriceAdjustment_stream_cursor_value_input: PriceAdjustment_Stream_Cursor_Value_Input;
  PriceAdjustment_sum_fields: ResolverTypeWrapper<PriceAdjustment_Sum_Fields>;
  PriceAdjustment_sum_order_by: PriceAdjustment_Sum_Order_By;
  PriceAdjustment_update_column: PriceAdjustment_Update_Column;
  PriceAdjustment_updates: PriceAdjustment_Updates;
  PriceAdjustment_var_pop_fields: ResolverTypeWrapper<PriceAdjustment_Var_Pop_Fields>;
  PriceAdjustment_var_pop_order_by: PriceAdjustment_Var_Pop_Order_By;
  PriceAdjustment_var_samp_fields: ResolverTypeWrapper<PriceAdjustment_Var_Samp_Fields>;
  PriceAdjustment_var_samp_order_by: PriceAdjustment_Var_Samp_Order_By;
  PriceAdjustment_variance_fields: ResolverTypeWrapper<PriceAdjustment_Variance_Fields>;
  PriceAdjustment_variance_order_by: PriceAdjustment_Variance_Order_By;
  PriceInput: PriceInput;
  Price_aggregate: ResolverTypeWrapper<Price_Aggregate>;
  Price_aggregate_bool_exp: Price_Aggregate_Bool_Exp;
  Price_aggregate_bool_exp_count: Price_Aggregate_Bool_Exp_Count;
  Price_aggregate_fields: ResolverTypeWrapper<Price_Aggregate_Fields>;
  Price_aggregate_order_by: Price_Aggregate_Order_By;
  Price_arr_rel_insert_input: Price_Arr_Rel_Insert_Input;
  Price_avg_fields: ResolverTypeWrapper<Price_Avg_Fields>;
  Price_avg_order_by: Price_Avg_Order_By;
  Price_bool_exp: Price_Bool_Exp;
  Price_constraint: Price_Constraint;
  Price_inc_input: Price_Inc_Input;
  Price_insert_input: Price_Insert_Input;
  Price_max_fields: ResolverTypeWrapper<Price_Max_Fields>;
  Price_max_order_by: Price_Max_Order_By;
  Price_min_fields: ResolverTypeWrapper<Price_Min_Fields>;
  Price_min_order_by: Price_Min_Order_By;
  Price_mutation_response: ResolverTypeWrapper<Price_Mutation_Response>;
  Price_on_conflict: Price_On_Conflict;
  Price_order_by: Price_Order_By;
  Price_pk_columns_input: Price_Pk_Columns_Input;
  Price_select_column: Price_Select_Column;
  Price_set_input: Price_Set_Input;
  Price_stddev_fields: ResolverTypeWrapper<Price_Stddev_Fields>;
  Price_stddev_order_by: Price_Stddev_Order_By;
  Price_stddev_pop_fields: ResolverTypeWrapper<Price_Stddev_Pop_Fields>;
  Price_stddev_pop_order_by: Price_Stddev_Pop_Order_By;
  Price_stddev_samp_fields: ResolverTypeWrapper<Price_Stddev_Samp_Fields>;
  Price_stddev_samp_order_by: Price_Stddev_Samp_Order_By;
  Price_stream_cursor_input: Price_Stream_Cursor_Input;
  Price_stream_cursor_value_input: Price_Stream_Cursor_Value_Input;
  Price_sum_fields: ResolverTypeWrapper<Price_Sum_Fields>;
  Price_sum_order_by: Price_Sum_Order_By;
  Price_update_column: Price_Update_Column;
  Price_updates: Price_Updates;
  Price_var_pop_fields: ResolverTypeWrapper<Price_Var_Pop_Fields>;
  Price_var_pop_order_by: Price_Var_Pop_Order_By;
  Price_var_samp_fields: ResolverTypeWrapper<Price_Var_Samp_Fields>;
  Price_var_samp_order_by: Price_Var_Samp_Order_By;
  Price_variance_fields: ResolverTypeWrapper<Price_Variance_Fields>;
  Price_variance_order_by: Price_Variance_Order_By;
  PricedProduct: ResolverTypeWrapper<PricedProduct>;
  PricedProductAdjustment: ResolverTypeWrapper<PricedProductAdjustment>;
  PricedProductAdjustment_aggregate: ResolverTypeWrapper<PricedProductAdjustment_Aggregate>;
  PricedProductAdjustment_aggregate_bool_exp: PricedProductAdjustment_Aggregate_Bool_Exp;
  PricedProductAdjustment_aggregate_bool_exp_count: PricedProductAdjustment_Aggregate_Bool_Exp_Count;
  PricedProductAdjustment_aggregate_fields: ResolverTypeWrapper<PricedProductAdjustment_Aggregate_Fields>;
  PricedProductAdjustment_aggregate_order_by: PricedProductAdjustment_Aggregate_Order_By;
  PricedProductAdjustment_arr_rel_insert_input: PricedProductAdjustment_Arr_Rel_Insert_Input;
  PricedProductAdjustment_avg_fields: ResolverTypeWrapper<PricedProductAdjustment_Avg_Fields>;
  PricedProductAdjustment_avg_order_by: PricedProductAdjustment_Avg_Order_By;
  PricedProductAdjustment_bool_exp: PricedProductAdjustment_Bool_Exp;
  PricedProductAdjustment_constraint: PricedProductAdjustment_Constraint;
  PricedProductAdjustment_inc_input: PricedProductAdjustment_Inc_Input;
  PricedProductAdjustment_insert_input: PricedProductAdjustment_Insert_Input;
  PricedProductAdjustment_max_fields: ResolverTypeWrapper<PricedProductAdjustment_Max_Fields>;
  PricedProductAdjustment_max_order_by: PricedProductAdjustment_Max_Order_By;
  PricedProductAdjustment_min_fields: ResolverTypeWrapper<PricedProductAdjustment_Min_Fields>;
  PricedProductAdjustment_min_order_by: PricedProductAdjustment_Min_Order_By;
  PricedProductAdjustment_mutation_response: ResolverTypeWrapper<PricedProductAdjustment_Mutation_Response>;
  PricedProductAdjustment_on_conflict: PricedProductAdjustment_On_Conflict;
  PricedProductAdjustment_order_by: PricedProductAdjustment_Order_By;
  PricedProductAdjustment_pk_columns_input: PricedProductAdjustment_Pk_Columns_Input;
  PricedProductAdjustment_select_column: PricedProductAdjustment_Select_Column;
  PricedProductAdjustment_set_input: PricedProductAdjustment_Set_Input;
  PricedProductAdjustment_stddev_fields: ResolverTypeWrapper<PricedProductAdjustment_Stddev_Fields>;
  PricedProductAdjustment_stddev_order_by: PricedProductAdjustment_Stddev_Order_By;
  PricedProductAdjustment_stddev_pop_fields: ResolverTypeWrapper<PricedProductAdjustment_Stddev_Pop_Fields>;
  PricedProductAdjustment_stddev_pop_order_by: PricedProductAdjustment_Stddev_Pop_Order_By;
  PricedProductAdjustment_stddev_samp_fields: ResolverTypeWrapper<PricedProductAdjustment_Stddev_Samp_Fields>;
  PricedProductAdjustment_stddev_samp_order_by: PricedProductAdjustment_Stddev_Samp_Order_By;
  PricedProductAdjustment_stream_cursor_input: PricedProductAdjustment_Stream_Cursor_Input;
  PricedProductAdjustment_stream_cursor_value_input: PricedProductAdjustment_Stream_Cursor_Value_Input;
  PricedProductAdjustment_sum_fields: ResolverTypeWrapper<PricedProductAdjustment_Sum_Fields>;
  PricedProductAdjustment_sum_order_by: PricedProductAdjustment_Sum_Order_By;
  PricedProductAdjustment_update_column: PricedProductAdjustment_Update_Column;
  PricedProductAdjustment_updates: PricedProductAdjustment_Updates;
  PricedProductAdjustment_var_pop_fields: ResolverTypeWrapper<PricedProductAdjustment_Var_Pop_Fields>;
  PricedProductAdjustment_var_pop_order_by: PricedProductAdjustment_Var_Pop_Order_By;
  PricedProductAdjustment_var_samp_fields: ResolverTypeWrapper<PricedProductAdjustment_Var_Samp_Fields>;
  PricedProductAdjustment_var_samp_order_by: PricedProductAdjustment_Var_Samp_Order_By;
  PricedProductAdjustment_variance_fields: ResolverTypeWrapper<PricedProductAdjustment_Variance_Fields>;
  PricedProductAdjustment_variance_order_by: PricedProductAdjustment_Variance_Order_By;
  PricedProductInput: PricedProductInput;
  PricedProductPricingFactor: ResolverTypeWrapper<PricedProductPricingFactor>;
  PricedProductPricingFactorAdjustment: ResolverTypeWrapper<PricedProductPricingFactorAdjustment>;
  PricedProductPricingFactorAdjustmentInput: PricedProductPricingFactorAdjustmentInput;
  PricedProductPricingFactorAdjustment_aggregate: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Aggregate>;
  PricedProductPricingFactorAdjustment_aggregate_bool_exp: PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp;
  PricedProductPricingFactorAdjustment_aggregate_bool_exp_count: PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp_Count;
  PricedProductPricingFactorAdjustment_aggregate_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Aggregate_Fields>;
  PricedProductPricingFactorAdjustment_aggregate_order_by: PricedProductPricingFactorAdjustment_Aggregate_Order_By;
  PricedProductPricingFactorAdjustment_arr_rel_insert_input: PricedProductPricingFactorAdjustment_Arr_Rel_Insert_Input;
  PricedProductPricingFactorAdjustment_avg_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Avg_Fields>;
  PricedProductPricingFactorAdjustment_avg_order_by: PricedProductPricingFactorAdjustment_Avg_Order_By;
  PricedProductPricingFactorAdjustment_bool_exp: PricedProductPricingFactorAdjustment_Bool_Exp;
  PricedProductPricingFactorAdjustment_constraint: PricedProductPricingFactorAdjustment_Constraint;
  PricedProductPricingFactorAdjustment_inc_input: PricedProductPricingFactorAdjustment_Inc_Input;
  PricedProductPricingFactorAdjustment_insert_input: PricedProductPricingFactorAdjustment_Insert_Input;
  PricedProductPricingFactorAdjustment_max_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Max_Fields>;
  PricedProductPricingFactorAdjustment_max_order_by: PricedProductPricingFactorAdjustment_Max_Order_By;
  PricedProductPricingFactorAdjustment_min_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Min_Fields>;
  PricedProductPricingFactorAdjustment_min_order_by: PricedProductPricingFactorAdjustment_Min_Order_By;
  PricedProductPricingFactorAdjustment_mutation_response: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Mutation_Response>;
  PricedProductPricingFactorAdjustment_obj_rel_insert_input: PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input;
  PricedProductPricingFactorAdjustment_on_conflict: PricedProductPricingFactorAdjustment_On_Conflict;
  PricedProductPricingFactorAdjustment_order_by: PricedProductPricingFactorAdjustment_Order_By;
  PricedProductPricingFactorAdjustment_pk_columns_input: PricedProductPricingFactorAdjustment_Pk_Columns_Input;
  PricedProductPricingFactorAdjustment_select_column: PricedProductPricingFactorAdjustment_Select_Column;
  PricedProductPricingFactorAdjustment_set_input: PricedProductPricingFactorAdjustment_Set_Input;
  PricedProductPricingFactorAdjustment_stddev_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Stddev_Fields>;
  PricedProductPricingFactorAdjustment_stddev_order_by: PricedProductPricingFactorAdjustment_Stddev_Order_By;
  PricedProductPricingFactorAdjustment_stddev_pop_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Stddev_Pop_Fields>;
  PricedProductPricingFactorAdjustment_stddev_pop_order_by: PricedProductPricingFactorAdjustment_Stddev_Pop_Order_By;
  PricedProductPricingFactorAdjustment_stddev_samp_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Stddev_Samp_Fields>;
  PricedProductPricingFactorAdjustment_stddev_samp_order_by: PricedProductPricingFactorAdjustment_Stddev_Samp_Order_By;
  PricedProductPricingFactorAdjustment_stream_cursor_input: PricedProductPricingFactorAdjustment_Stream_Cursor_Input;
  PricedProductPricingFactorAdjustment_stream_cursor_value_input: PricedProductPricingFactorAdjustment_Stream_Cursor_Value_Input;
  PricedProductPricingFactorAdjustment_sum_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Sum_Fields>;
  PricedProductPricingFactorAdjustment_sum_order_by: PricedProductPricingFactorAdjustment_Sum_Order_By;
  PricedProductPricingFactorAdjustment_update_column: PricedProductPricingFactorAdjustment_Update_Column;
  PricedProductPricingFactorAdjustment_updates: PricedProductPricingFactorAdjustment_Updates;
  PricedProductPricingFactorAdjustment_var_pop_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Var_Pop_Fields>;
  PricedProductPricingFactorAdjustment_var_pop_order_by: PricedProductPricingFactorAdjustment_Var_Pop_Order_By;
  PricedProductPricingFactorAdjustment_var_samp_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Var_Samp_Fields>;
  PricedProductPricingFactorAdjustment_var_samp_order_by: PricedProductPricingFactorAdjustment_Var_Samp_Order_By;
  PricedProductPricingFactorAdjustment_variance_fields: ResolverTypeWrapper<PricedProductPricingFactorAdjustment_Variance_Fields>;
  PricedProductPricingFactorAdjustment_variance_order_by: PricedProductPricingFactorAdjustment_Variance_Order_By;
  PricedProductPricingFactorInput: PricedProductPricingFactorInput;
  PricedProductPricingFactor_aggregate: ResolverTypeWrapper<PricedProductPricingFactor_Aggregate>;
  PricedProductPricingFactor_aggregate_bool_exp: PricedProductPricingFactor_Aggregate_Bool_Exp;
  PricedProductPricingFactor_aggregate_bool_exp_bool_and: PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And;
  PricedProductPricingFactor_aggregate_bool_exp_bool_or: PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or;
  PricedProductPricingFactor_aggregate_bool_exp_count: PricedProductPricingFactor_Aggregate_Bool_Exp_Count;
  PricedProductPricingFactor_aggregate_fields: ResolverTypeWrapper<PricedProductPricingFactor_Aggregate_Fields>;
  PricedProductPricingFactor_aggregate_order_by: PricedProductPricingFactor_Aggregate_Order_By;
  PricedProductPricingFactor_arr_rel_insert_input: PricedProductPricingFactor_Arr_Rel_Insert_Input;
  PricedProductPricingFactor_avg_fields: ResolverTypeWrapper<PricedProductPricingFactor_Avg_Fields>;
  PricedProductPricingFactor_avg_order_by: PricedProductPricingFactor_Avg_Order_By;
  PricedProductPricingFactor_bool_exp: PricedProductPricingFactor_Bool_Exp;
  PricedProductPricingFactor_constraint: PricedProductPricingFactor_Constraint;
  PricedProductPricingFactor_inc_input: PricedProductPricingFactor_Inc_Input;
  PricedProductPricingFactor_insert_input: PricedProductPricingFactor_Insert_Input;
  PricedProductPricingFactor_max_fields: ResolverTypeWrapper<PricedProductPricingFactor_Max_Fields>;
  PricedProductPricingFactor_max_order_by: PricedProductPricingFactor_Max_Order_By;
  PricedProductPricingFactor_min_fields: ResolverTypeWrapper<PricedProductPricingFactor_Min_Fields>;
  PricedProductPricingFactor_min_order_by: PricedProductPricingFactor_Min_Order_By;
  PricedProductPricingFactor_mutation_response: ResolverTypeWrapper<PricedProductPricingFactor_Mutation_Response>;
  PricedProductPricingFactor_obj_rel_insert_input: PricedProductPricingFactor_Obj_Rel_Insert_Input;
  PricedProductPricingFactor_on_conflict: PricedProductPricingFactor_On_Conflict;
  PricedProductPricingFactor_order_by: PricedProductPricingFactor_Order_By;
  PricedProductPricingFactor_pk_columns_input: PricedProductPricingFactor_Pk_Columns_Input;
  PricedProductPricingFactor_select_column: PricedProductPricingFactor_Select_Column;
  PricedProductPricingFactor_select_column_PricedProductPricingFactor_aggregate_bool_exp_bool_and_arguments_columns: PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  PricedProductPricingFactor_select_column_PricedProductPricingFactor_aggregate_bool_exp_bool_or_arguments_columns: PricedProductPricingFactor_Select_Column_PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  PricedProductPricingFactor_set_input: PricedProductPricingFactor_Set_Input;
  PricedProductPricingFactor_stddev_fields: ResolverTypeWrapper<PricedProductPricingFactor_Stddev_Fields>;
  PricedProductPricingFactor_stddev_order_by: PricedProductPricingFactor_Stddev_Order_By;
  PricedProductPricingFactor_stddev_pop_fields: ResolverTypeWrapper<PricedProductPricingFactor_Stddev_Pop_Fields>;
  PricedProductPricingFactor_stddev_pop_order_by: PricedProductPricingFactor_Stddev_Pop_Order_By;
  PricedProductPricingFactor_stddev_samp_fields: ResolverTypeWrapper<PricedProductPricingFactor_Stddev_Samp_Fields>;
  PricedProductPricingFactor_stddev_samp_order_by: PricedProductPricingFactor_Stddev_Samp_Order_By;
  PricedProductPricingFactor_stream_cursor_input: PricedProductPricingFactor_Stream_Cursor_Input;
  PricedProductPricingFactor_stream_cursor_value_input: PricedProductPricingFactor_Stream_Cursor_Value_Input;
  PricedProductPricingFactor_sum_fields: ResolverTypeWrapper<PricedProductPricingFactor_Sum_Fields>;
  PricedProductPricingFactor_sum_order_by: PricedProductPricingFactor_Sum_Order_By;
  PricedProductPricingFactor_update_column: PricedProductPricingFactor_Update_Column;
  PricedProductPricingFactor_updates: PricedProductPricingFactor_Updates;
  PricedProductPricingFactor_var_pop_fields: ResolverTypeWrapper<PricedProductPricingFactor_Var_Pop_Fields>;
  PricedProductPricingFactor_var_pop_order_by: PricedProductPricingFactor_Var_Pop_Order_By;
  PricedProductPricingFactor_var_samp_fields: ResolverTypeWrapper<PricedProductPricingFactor_Var_Samp_Fields>;
  PricedProductPricingFactor_var_samp_order_by: PricedProductPricingFactor_Var_Samp_Order_By;
  PricedProductPricingFactor_variance_fields: ResolverTypeWrapper<PricedProductPricingFactor_Variance_Fields>;
  PricedProductPricingFactor_variance_order_by: PricedProductPricingFactor_Variance_Order_By;
  PricedProduct_aggregate: ResolverTypeWrapper<PricedProduct_Aggregate>;
  PricedProduct_aggregate_bool_exp: PricedProduct_Aggregate_Bool_Exp;
  PricedProduct_aggregate_bool_exp_count: PricedProduct_Aggregate_Bool_Exp_Count;
  PricedProduct_aggregate_fields: ResolverTypeWrapper<PricedProduct_Aggregate_Fields>;
  PricedProduct_aggregate_order_by: PricedProduct_Aggregate_Order_By;
  PricedProduct_arr_rel_insert_input: PricedProduct_Arr_Rel_Insert_Input;
  PricedProduct_avg_fields: ResolverTypeWrapper<PricedProduct_Avg_Fields>;
  PricedProduct_avg_order_by: PricedProduct_Avg_Order_By;
  PricedProduct_bool_exp: PricedProduct_Bool_Exp;
  PricedProduct_constraint: PricedProduct_Constraint;
  PricedProduct_inc_input: PricedProduct_Inc_Input;
  PricedProduct_insert_input: PricedProduct_Insert_Input;
  PricedProduct_max_fields: ResolverTypeWrapper<PricedProduct_Max_Fields>;
  PricedProduct_max_order_by: PricedProduct_Max_Order_By;
  PricedProduct_min_fields: ResolverTypeWrapper<PricedProduct_Min_Fields>;
  PricedProduct_min_order_by: PricedProduct_Min_Order_By;
  PricedProduct_mutation_response: ResolverTypeWrapper<PricedProduct_Mutation_Response>;
  PricedProduct_obj_rel_insert_input: PricedProduct_Obj_Rel_Insert_Input;
  PricedProduct_on_conflict: PricedProduct_On_Conflict;
  PricedProduct_order_by: PricedProduct_Order_By;
  PricedProduct_pk_columns_input: PricedProduct_Pk_Columns_Input;
  PricedProduct_select_column: PricedProduct_Select_Column;
  PricedProduct_set_input: PricedProduct_Set_Input;
  PricedProduct_stddev_fields: ResolverTypeWrapper<PricedProduct_Stddev_Fields>;
  PricedProduct_stddev_order_by: PricedProduct_Stddev_Order_By;
  PricedProduct_stddev_pop_fields: ResolverTypeWrapper<PricedProduct_Stddev_Pop_Fields>;
  PricedProduct_stddev_pop_order_by: PricedProduct_Stddev_Pop_Order_By;
  PricedProduct_stddev_samp_fields: ResolverTypeWrapper<PricedProduct_Stddev_Samp_Fields>;
  PricedProduct_stddev_samp_order_by: PricedProduct_Stddev_Samp_Order_By;
  PricedProduct_stream_cursor_input: PricedProduct_Stream_Cursor_Input;
  PricedProduct_stream_cursor_value_input: PricedProduct_Stream_Cursor_Value_Input;
  PricedProduct_sum_fields: ResolverTypeWrapper<PricedProduct_Sum_Fields>;
  PricedProduct_sum_order_by: PricedProduct_Sum_Order_By;
  PricedProduct_update_column: PricedProduct_Update_Column;
  PricedProduct_updates: PricedProduct_Updates;
  PricedProduct_var_pop_fields: ResolverTypeWrapper<PricedProduct_Var_Pop_Fields>;
  PricedProduct_var_pop_order_by: PricedProduct_Var_Pop_Order_By;
  PricedProduct_var_samp_fields: ResolverTypeWrapper<PricedProduct_Var_Samp_Fields>;
  PricedProduct_var_samp_order_by: PricedProduct_Var_Samp_Order_By;
  PricedProduct_variance_fields: ResolverTypeWrapper<PricedProduct_Variance_Fields>;
  PricedProduct_variance_order_by: PricedProduct_Variance_Order_By;
  PricingFactorInput: PricingFactorInput;
  PricingGroupValue: ResolverTypeWrapper<PricingGroupValue>;
  PricingGroupValueInput: PricingGroupValueInput;
  PricingSchemeIdentifier: ResolverTypeWrapper<PricingSchemeIdentifier>;
  ProService: ResolverTypeWrapper<ProService>;
  ProServiceInput: ProServiceInput;
  ProServiceManagedField: ResolverTypeWrapper<ProServiceManagedField>;
  ProServiceManagedField_aggregate: ResolverTypeWrapper<ProServiceManagedField_Aggregate>;
  ProServiceManagedField_aggregate_bool_exp: ProServiceManagedField_Aggregate_Bool_Exp;
  ProServiceManagedField_aggregate_bool_exp_bool_and: ProServiceManagedField_Aggregate_Bool_Exp_Bool_And;
  ProServiceManagedField_aggregate_bool_exp_bool_or: ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or;
  ProServiceManagedField_aggregate_bool_exp_count: ProServiceManagedField_Aggregate_Bool_Exp_Count;
  ProServiceManagedField_aggregate_fields: ResolverTypeWrapper<ProServiceManagedField_Aggregate_Fields>;
  ProServiceManagedField_aggregate_order_by: ProServiceManagedField_Aggregate_Order_By;
  ProServiceManagedField_arr_rel_insert_input: ProServiceManagedField_Arr_Rel_Insert_Input;
  ProServiceManagedField_bool_exp: ProServiceManagedField_Bool_Exp;
  ProServiceManagedField_constraint: ProServiceManagedField_Constraint;
  ProServiceManagedField_insert_input: ProServiceManagedField_Insert_Input;
  ProServiceManagedField_max_fields: ResolverTypeWrapper<ProServiceManagedField_Max_Fields>;
  ProServiceManagedField_max_order_by: ProServiceManagedField_Max_Order_By;
  ProServiceManagedField_min_fields: ResolverTypeWrapper<ProServiceManagedField_Min_Fields>;
  ProServiceManagedField_min_order_by: ProServiceManagedField_Min_Order_By;
  ProServiceManagedField_mutation_response: ResolverTypeWrapper<ProServiceManagedField_Mutation_Response>;
  ProServiceManagedField_on_conflict: ProServiceManagedField_On_Conflict;
  ProServiceManagedField_order_by: ProServiceManagedField_Order_By;
  ProServiceManagedField_pk_columns_input: ProServiceManagedField_Pk_Columns_Input;
  ProServiceManagedField_select_column: ProServiceManagedField_Select_Column;
  ProServiceManagedField_select_column_ProServiceManagedField_aggregate_bool_exp_bool_and_arguments_columns: ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ProServiceManagedField_select_column_ProServiceManagedField_aggregate_bool_exp_bool_or_arguments_columns: ProServiceManagedField_Select_Column_ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ProServiceManagedField_set_input: ProServiceManagedField_Set_Input;
  ProServiceManagedField_stream_cursor_input: ProServiceManagedField_Stream_Cursor_Input;
  ProServiceManagedField_stream_cursor_value_input: ProServiceManagedField_Stream_Cursor_Value_Input;
  ProServiceManagedField_update_column: ProServiceManagedField_Update_Column;
  ProServiceManagedField_updates: ProServiceManagedField_Updates;
  ProServiceProductListItem: ResolverTypeWrapper<ProServiceProductListItem>;
  ProServiceProductListItemState: ResolverTypeWrapper<ProServiceProductListItemState>;
  Product: ResolverTypeWrapper<Product>;
  ProductChargeLineItem: ResolverTypeWrapper<Omit<ProductChargeLineItem, 'sub_line_items'> & { sub_line_items: Array<ResolversTypes['ProductChargeSubLineItem']> }>;
  ProductChargeSubLineItem: ResolversTypes['AdvanceChargeLineItem'] | ResolversTypes['ChargeLineItem'] | ResolversTypes['CompositeChargeLineItem'] | ResolversTypes['SeatChargeLineItem'];
  ProductListItem: ResolversTypes['CompositeProductListItem'] | ResolversTypes['FixedProductListItem'] | ResolversTypes['ProServiceProductListItem'] | ResolversTypes['SubscriptionProductListItem'] | ResolversTypes['UsageProductListItem'];
  ProductListItemArchivedFilter: ProductListItemArchivedFilter;
  ProductListItemTypeFilter: ProductListItemTypeFilter;
  ProductListItemUpdate: ResolverTypeWrapper<Omit<ProductListItemUpdate, 'composite_products'> & { composite_products: Maybe<Array<ResolversTypes['NonCompositeProductListItem']>> }>;
  ProductManagedField: ResolverTypeWrapper<ProductManagedField>;
  ProductManagedField_aggregate: ResolverTypeWrapper<ProductManagedField_Aggregate>;
  ProductManagedField_aggregate_bool_exp: ProductManagedField_Aggregate_Bool_Exp;
  ProductManagedField_aggregate_bool_exp_bool_and: ProductManagedField_Aggregate_Bool_Exp_Bool_And;
  ProductManagedField_aggregate_bool_exp_bool_or: ProductManagedField_Aggregate_Bool_Exp_Bool_Or;
  ProductManagedField_aggregate_bool_exp_count: ProductManagedField_Aggregate_Bool_Exp_Count;
  ProductManagedField_aggregate_fields: ResolverTypeWrapper<ProductManagedField_Aggregate_Fields>;
  ProductManagedField_aggregate_order_by: ProductManagedField_Aggregate_Order_By;
  ProductManagedField_arr_rel_insert_input: ProductManagedField_Arr_Rel_Insert_Input;
  ProductManagedField_bool_exp: ProductManagedField_Bool_Exp;
  ProductManagedField_constraint: ProductManagedField_Constraint;
  ProductManagedField_insert_input: ProductManagedField_Insert_Input;
  ProductManagedField_max_fields: ResolverTypeWrapper<ProductManagedField_Max_Fields>;
  ProductManagedField_max_order_by: ProductManagedField_Max_Order_By;
  ProductManagedField_min_fields: ResolverTypeWrapper<ProductManagedField_Min_Fields>;
  ProductManagedField_min_order_by: ProductManagedField_Min_Order_By;
  ProductManagedField_mutation_response: ResolverTypeWrapper<ProductManagedField_Mutation_Response>;
  ProductManagedField_on_conflict: ProductManagedField_On_Conflict;
  ProductManagedField_order_by: ProductManagedField_Order_By;
  ProductManagedField_pk_columns_input: ProductManagedField_Pk_Columns_Input;
  ProductManagedField_select_column: ProductManagedField_Select_Column;
  ProductManagedField_select_column_ProductManagedField_aggregate_bool_exp_bool_and_arguments_columns: ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ProductManagedField_select_column_ProductManagedField_aggregate_bool_exp_bool_or_arguments_columns: ProductManagedField_Select_Column_ProductManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ProductManagedField_set_input: ProductManagedField_Set_Input;
  ProductManagedField_stream_cursor_input: ProductManagedField_Stream_Cursor_Input;
  ProductManagedField_stream_cursor_value_input: ProductManagedField_Stream_Cursor_Value_Input;
  ProductManagedField_update_column: ProductManagedField_Update_Column;
  ProductManagedField_updates: ProductManagedField_Updates;
  ProductPricingFactor: ResolverTypeWrapper<ProductPricingFactor>;
  ProductPricingFactor_aggregate: ResolverTypeWrapper<ProductPricingFactor_Aggregate>;
  ProductPricingFactor_aggregate_bool_exp: ProductPricingFactor_Aggregate_Bool_Exp;
  ProductPricingFactor_aggregate_bool_exp_count: ProductPricingFactor_Aggregate_Bool_Exp_Count;
  ProductPricingFactor_aggregate_fields: ResolverTypeWrapper<ProductPricingFactor_Aggregate_Fields>;
  ProductPricingFactor_aggregate_order_by: ProductPricingFactor_Aggregate_Order_By;
  ProductPricingFactor_arr_rel_insert_input: ProductPricingFactor_Arr_Rel_Insert_Input;
  ProductPricingFactor_avg_fields: ResolverTypeWrapper<ProductPricingFactor_Avg_Fields>;
  ProductPricingFactor_avg_order_by: ProductPricingFactor_Avg_Order_By;
  ProductPricingFactor_bool_exp: ProductPricingFactor_Bool_Exp;
  ProductPricingFactor_constraint: ProductPricingFactor_Constraint;
  ProductPricingFactor_inc_input: ProductPricingFactor_Inc_Input;
  ProductPricingFactor_insert_input: ProductPricingFactor_Insert_Input;
  ProductPricingFactor_max_fields: ResolverTypeWrapper<ProductPricingFactor_Max_Fields>;
  ProductPricingFactor_max_order_by: ProductPricingFactor_Max_Order_By;
  ProductPricingFactor_min_fields: ResolverTypeWrapper<ProductPricingFactor_Min_Fields>;
  ProductPricingFactor_min_order_by: ProductPricingFactor_Min_Order_By;
  ProductPricingFactor_mutation_response: ResolverTypeWrapper<ProductPricingFactor_Mutation_Response>;
  ProductPricingFactor_obj_rel_insert_input: ProductPricingFactor_Obj_Rel_Insert_Input;
  ProductPricingFactor_on_conflict: ProductPricingFactor_On_Conflict;
  ProductPricingFactor_order_by: ProductPricingFactor_Order_By;
  ProductPricingFactor_pk_columns_input: ProductPricingFactor_Pk_Columns_Input;
  ProductPricingFactor_select_column: ProductPricingFactor_Select_Column;
  ProductPricingFactor_set_input: ProductPricingFactor_Set_Input;
  ProductPricingFactor_stddev_fields: ResolverTypeWrapper<ProductPricingFactor_Stddev_Fields>;
  ProductPricingFactor_stddev_order_by: ProductPricingFactor_Stddev_Order_By;
  ProductPricingFactor_stddev_pop_fields: ResolverTypeWrapper<ProductPricingFactor_Stddev_Pop_Fields>;
  ProductPricingFactor_stddev_pop_order_by: ProductPricingFactor_Stddev_Pop_Order_By;
  ProductPricingFactor_stddev_samp_fields: ResolverTypeWrapper<ProductPricingFactor_Stddev_Samp_Fields>;
  ProductPricingFactor_stddev_samp_order_by: ProductPricingFactor_Stddev_Samp_Order_By;
  ProductPricingFactor_stream_cursor_input: ProductPricingFactor_Stream_Cursor_Input;
  ProductPricingFactor_stream_cursor_value_input: ProductPricingFactor_Stream_Cursor_Value_Input;
  ProductPricingFactor_sum_fields: ResolverTypeWrapper<ProductPricingFactor_Sum_Fields>;
  ProductPricingFactor_sum_order_by: ProductPricingFactor_Sum_Order_By;
  ProductPricingFactor_update_column: ProductPricingFactor_Update_Column;
  ProductPricingFactor_updates: ProductPricingFactor_Updates;
  ProductPricingFactor_var_pop_fields: ResolverTypeWrapper<ProductPricingFactor_Var_Pop_Fields>;
  ProductPricingFactor_var_pop_order_by: ProductPricingFactor_Var_Pop_Order_By;
  ProductPricingFactor_var_samp_fields: ResolverTypeWrapper<ProductPricingFactor_Var_Samp_Fields>;
  ProductPricingFactor_var_samp_order_by: ProductPricingFactor_Var_Samp_Order_By;
  ProductPricingFactor_variance_fields: ResolverTypeWrapper<ProductPricingFactor_Variance_Fields>;
  ProductPricingFactor_variance_order_by: ProductPricingFactor_Variance_Order_By;
  ProductTypeEnum: ProductTypeEnum;
  Product_aggregate: ResolverTypeWrapper<Product_Aggregate>;
  Product_aggregate_fields: ResolverTypeWrapper<Product_Aggregate_Fields>;
  Product_bool_exp: Product_Bool_Exp;
  Product_constraint: Product_Constraint;
  Product_insert_input: Product_Insert_Input;
  Product_max_fields: ResolverTypeWrapper<Product_Max_Fields>;
  Product_min_fields: ResolverTypeWrapper<Product_Min_Fields>;
  Product_mutation_response: ResolverTypeWrapper<Product_Mutation_Response>;
  Product_obj_rel_insert_input: Product_Obj_Rel_Insert_Input;
  Product_on_conflict: Product_On_Conflict;
  Product_order_by: Product_Order_By;
  Product_pk_columns_input: Product_Pk_Columns_Input;
  Product_select_column: Product_Select_Column;
  Product_set_input: Product_Set_Input;
  Product_stream_cursor_input: Product_Stream_Cursor_Input;
  Product_stream_cursor_value_input: Product_Stream_Cursor_Value_Input;
  Product_update_column: Product_Update_Column;
  Product_updates: Product_Updates;
  ProductsAndRateCards: ResolverTypeWrapper<Omit<ProductsAndRateCards, 'product' | 'products'> & { product: ResolversTypes['ProductListItem'], products: Array<ResolversTypes['ProductListItem']> }>;
  ProductsSortingInput: ProductsSortingInput;
  ProductsSortingOrderByEnum: ProductsSortingOrderByEnum;
  QuantityConversion: ResolverTypeWrapper<QuantityConversion>;
  QuantityConversionInput: QuantityConversionInput;
  QuantityConversionUpdate: ResolverTypeWrapper<QuantityConversionUpdate>;
  QuantityRounding: ResolverTypeWrapper<QuantityRounding>;
  QuantityRoundingInput: QuantityRoundingInput;
  QuantityRoundingUpdate: ResolverTypeWrapper<QuantityRoundingUpdate>;
  Query: ResolverTypeWrapper<{}>;
  Rate: ResolversTypes['CustomRate'] | ResolversTypes['FlatRate'] | ResolversTypes['PercentageRate'] | ResolversTypes['SubscriptionRate'] | ResolversTypes['TieredRate'];
  RateCard: ResolverTypeWrapper<Omit<RateCard, 'products'> & { products: Array<ResolversTypes['ProductListItem']> }>;
  RateCardAliasData: ResolverTypeWrapper<RateCardAliasData>;
  RateCardAliasInput: RateCardAliasInput;
  RateCardAliasScheduleSegment: ResolverTypeWrapper<RateCardAliasScheduleSegment>;
  RateCardArchivedFilter: RateCardArchivedFilter;
  RateCardCreditTypeConversion: ResolverTypeWrapper<RateCardCreditTypeConversion>;
  RateCardCreditTypeConversionInput: RateCardCreditTypeConversionInput;
  RateCardEntry: ResolverTypeWrapper<Omit<RateCardEntry, 'product_list_item' | 'rate'> & { product_list_item: ResolversTypes['RateCardProductListItem'], rate: ResolversTypes['Rate'] }>;
  RateCardEntryInput: RateCardEntryInput;
  RateCardEntryOverride: ResolverTypeWrapper<Omit<RateCardEntryOverride, 'product' | 'rate'> & { product: Maybe<ResolversTypes['ProductListItem']>, rate: Maybe<ResolversTypes['OverrideRate']> }>;
  RateCardEntryOverrideInput: RateCardEntryOverrideInput;
  RateCardEntryPricingGroupValueEntry: ResolverTypeWrapper<RateCardEntryPricingGroupValueEntry>;
  RateCardEntryPricingGroupValueInput: RateCardEntryPricingGroupValueInput;
  RateCardManagedField: ResolverTypeWrapper<RateCardManagedField>;
  RateCardManagedField_aggregate: ResolverTypeWrapper<RateCardManagedField_Aggregate>;
  RateCardManagedField_aggregate_bool_exp: RateCardManagedField_Aggregate_Bool_Exp;
  RateCardManagedField_aggregate_bool_exp_bool_and: RateCardManagedField_Aggregate_Bool_Exp_Bool_And;
  RateCardManagedField_aggregate_bool_exp_bool_or: RateCardManagedField_Aggregate_Bool_Exp_Bool_Or;
  RateCardManagedField_aggregate_bool_exp_count: RateCardManagedField_Aggregate_Bool_Exp_Count;
  RateCardManagedField_aggregate_fields: ResolverTypeWrapper<RateCardManagedField_Aggregate_Fields>;
  RateCardManagedField_aggregate_order_by: RateCardManagedField_Aggregate_Order_By;
  RateCardManagedField_arr_rel_insert_input: RateCardManagedField_Arr_Rel_Insert_Input;
  RateCardManagedField_bool_exp: RateCardManagedField_Bool_Exp;
  RateCardManagedField_constraint: RateCardManagedField_Constraint;
  RateCardManagedField_insert_input: RateCardManagedField_Insert_Input;
  RateCardManagedField_max_fields: ResolverTypeWrapper<RateCardManagedField_Max_Fields>;
  RateCardManagedField_max_order_by: RateCardManagedField_Max_Order_By;
  RateCardManagedField_min_fields: ResolverTypeWrapper<RateCardManagedField_Min_Fields>;
  RateCardManagedField_min_order_by: RateCardManagedField_Min_Order_By;
  RateCardManagedField_mutation_response: ResolverTypeWrapper<RateCardManagedField_Mutation_Response>;
  RateCardManagedField_on_conflict: RateCardManagedField_On_Conflict;
  RateCardManagedField_order_by: RateCardManagedField_Order_By;
  RateCardManagedField_pk_columns_input: RateCardManagedField_Pk_Columns_Input;
  RateCardManagedField_select_column: RateCardManagedField_Select_Column;
  RateCardManagedField_select_column_RateCardManagedField_aggregate_bool_exp_bool_and_arguments_columns: RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  RateCardManagedField_select_column_RateCardManagedField_aggregate_bool_exp_bool_or_arguments_columns: RateCardManagedField_Select_Column_RateCardManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  RateCardManagedField_set_input: RateCardManagedField_Set_Input;
  RateCardManagedField_stream_cursor_input: RateCardManagedField_Stream_Cursor_Input;
  RateCardManagedField_stream_cursor_value_input: RateCardManagedField_Stream_Cursor_Value_Input;
  RateCardManagedField_update_column: RateCardManagedField_Update_Column;
  RateCardManagedField_updates: RateCardManagedField_Updates;
  RateCardMetadata: ResolverTypeWrapper<Omit<RateCardMetadata, 'products'> & { products: Array<ResolversTypes['ProductListItem']> }>;
  RateCardProductListItem: ResolversTypes['SubscriptionProductListItem'] | ResolversTypes['UsageProductListItem'];
  RateCardProductPositionInput: RateCardProductPositionInput;
  RateInput: RateInput;
  RateSchedule: ResolverTypeWrapper<Omit<RateSchedule, 'products_on_segments'> & { products_on_segments: Array<ResolversTypes['ProductListItem']> }>;
  RateScheduleEntitledFilter: RateScheduleEntitledFilter;
  RateScheduleSegment: ResolverTypeWrapper<Omit<RateScheduleSegment, 'product' | 'rate'> & { product: ResolversTypes['ProductListItem'], rate: ResolversTypes['Rate'] }>;
  RateScheduleSelector: RateScheduleSelector;
  RatesPerProduct: ResolverTypeWrapper<Omit<RatesPerProduct, 'product'> & { product: ResolversTypes['ProductListItem'] }>;
  RebuildRateSegmentsSelector: RebuildRateSegmentsSelector;
  RechargeSettings: ResolverTypeWrapper<RechargeSettings>;
  RechargeSettingsBillingProviderConfig: ResolverTypeWrapper<RechargeSettingsBillingProviderConfig>;
  RechargeSettingsBillingProviderConfigInput: RechargeSettingsBillingProviderConfigInput;
  RechargeSettingsInput: RechargeSettingsInput;
  RechargeSettingsStripeConfig: ResolverTypeWrapper<RechargeSettingsStripeConfig>;
  RechargeSettingsStripeConfigInput: RechargeSettingsStripeConfigInput;
  RechargeSettingsUpdateAndChargeResponse: ResolverTypeWrapper<RechargeSettingsUpdateAndChargeResponse>;
  RecordOfNullableStringArrays: ResolverTypeWrapper<Scalars['RecordOfNullableStringArrays']>;
  RecordOfNullableStrings: ResolverTypeWrapper<Scalars['RecordOfNullableStrings']>;
  RecordOfStrings: ResolverTypeWrapper<Scalars['RecordOfStrings']>;
  RecurringCreditGrant: ResolverTypeWrapper<RecurringCreditGrant>;
  RecurringCreditGrantInput: RecurringCreditGrantInput;
  RecurringCreditGrant_aggregate: ResolverTypeWrapper<RecurringCreditGrant_Aggregate>;
  RecurringCreditGrant_aggregate_bool_exp: RecurringCreditGrant_Aggregate_Bool_Exp;
  RecurringCreditGrant_aggregate_bool_exp_bool_and: RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And;
  RecurringCreditGrant_aggregate_bool_exp_bool_or: RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or;
  RecurringCreditGrant_aggregate_bool_exp_count: RecurringCreditGrant_Aggregate_Bool_Exp_Count;
  RecurringCreditGrant_aggregate_fields: ResolverTypeWrapper<RecurringCreditGrant_Aggregate_Fields>;
  RecurringCreditGrant_aggregate_order_by: RecurringCreditGrant_Aggregate_Order_By;
  RecurringCreditGrant_arr_rel_insert_input: RecurringCreditGrant_Arr_Rel_Insert_Input;
  RecurringCreditGrant_avg_fields: ResolverTypeWrapper<RecurringCreditGrant_Avg_Fields>;
  RecurringCreditGrant_avg_order_by: RecurringCreditGrant_Avg_Order_By;
  RecurringCreditGrant_bool_exp: RecurringCreditGrant_Bool_Exp;
  RecurringCreditGrant_constraint: RecurringCreditGrant_Constraint;
  RecurringCreditGrant_inc_input: RecurringCreditGrant_Inc_Input;
  RecurringCreditGrant_insert_input: RecurringCreditGrant_Insert_Input;
  RecurringCreditGrant_max_fields: ResolverTypeWrapper<RecurringCreditGrant_Max_Fields>;
  RecurringCreditGrant_max_order_by: RecurringCreditGrant_Max_Order_By;
  RecurringCreditGrant_min_fields: ResolverTypeWrapper<RecurringCreditGrant_Min_Fields>;
  RecurringCreditGrant_min_order_by: RecurringCreditGrant_Min_Order_By;
  RecurringCreditGrant_mutation_response: ResolverTypeWrapper<RecurringCreditGrant_Mutation_Response>;
  RecurringCreditGrant_obj_rel_insert_input: RecurringCreditGrant_Obj_Rel_Insert_Input;
  RecurringCreditGrant_on_conflict: RecurringCreditGrant_On_Conflict;
  RecurringCreditGrant_order_by: RecurringCreditGrant_Order_By;
  RecurringCreditGrant_pk_columns_input: RecurringCreditGrant_Pk_Columns_Input;
  RecurringCreditGrant_select_column: RecurringCreditGrant_Select_Column;
  RecurringCreditGrant_select_column_RecurringCreditGrant_aggregate_bool_exp_bool_and_arguments_columns: RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  RecurringCreditGrant_select_column_RecurringCreditGrant_aggregate_bool_exp_bool_or_arguments_columns: RecurringCreditGrant_Select_Column_RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  RecurringCreditGrant_set_input: RecurringCreditGrant_Set_Input;
  RecurringCreditGrant_stddev_fields: ResolverTypeWrapper<RecurringCreditGrant_Stddev_Fields>;
  RecurringCreditGrant_stddev_order_by: RecurringCreditGrant_Stddev_Order_By;
  RecurringCreditGrant_stddev_pop_fields: ResolverTypeWrapper<RecurringCreditGrant_Stddev_Pop_Fields>;
  RecurringCreditGrant_stddev_pop_order_by: RecurringCreditGrant_Stddev_Pop_Order_By;
  RecurringCreditGrant_stddev_samp_fields: ResolverTypeWrapper<RecurringCreditGrant_Stddev_Samp_Fields>;
  RecurringCreditGrant_stddev_samp_order_by: RecurringCreditGrant_Stddev_Samp_Order_By;
  RecurringCreditGrant_stream_cursor_input: RecurringCreditGrant_Stream_Cursor_Input;
  RecurringCreditGrant_stream_cursor_value_input: RecurringCreditGrant_Stream_Cursor_Value_Input;
  RecurringCreditGrant_sum_fields: ResolverTypeWrapper<RecurringCreditGrant_Sum_Fields>;
  RecurringCreditGrant_sum_order_by: RecurringCreditGrant_Sum_Order_By;
  RecurringCreditGrant_update_column: RecurringCreditGrant_Update_Column;
  RecurringCreditGrant_updates: RecurringCreditGrant_Updates;
  RecurringCreditGrant_var_pop_fields: ResolverTypeWrapper<RecurringCreditGrant_Var_Pop_Fields>;
  RecurringCreditGrant_var_pop_order_by: RecurringCreditGrant_Var_Pop_Order_By;
  RecurringCreditGrant_var_samp_fields: ResolverTypeWrapper<RecurringCreditGrant_Var_Samp_Fields>;
  RecurringCreditGrant_var_samp_order_by: RecurringCreditGrant_Var_Samp_Order_By;
  RecurringCreditGrant_variance_fields: ResolverTypeWrapper<RecurringCreditGrant_Variance_Fields>;
  RecurringCreditGrant_variance_order_by: RecurringCreditGrant_Variance_Order_By;
  RecurringSchedule: ResolverTypeWrapper<RecurringSchedule>;
  RecurringScheduleFrequency: RecurringScheduleFrequency;
  RecurringScheduleInput: RecurringScheduleInput;
  RepairPrepaidCreditGrantAction: RepairPrepaidCreditGrantAction;
  RepairPrepaidCreditGrantResponse: ResolverTypeWrapper<RepairPrepaidCreditGrantResponse>;
  RequiredTimeRangeInput: RequiredTimeRangeInput;
  ResellerRoyalty: ResolversTypes['AWSProServiceRoyalty'] | ResolversTypes['AWSRoyalty'] | ResolversTypes['GCPProServiceRoyalty'] | ResolversTypes['GCPRoyalty'];
  ResellerRoyaltyInput: ResellerRoyaltyInput;
  ResellerRoyaltyOrUpdate: ResolversTypes['AWSProServiceRoyalty'] | ResolversTypes['AWSProServiceRoyaltyUpdate'] | ResolversTypes['AWSRoyalty'] | ResolversTypes['AWSRoyaltyUpdate'] | ResolversTypes['GCPProServiceRoyalty'] | ResolversTypes['GCPProServiceRoyaltyUpdate'] | ResolversTypes['GCPRoyalty'] | ResolversTypes['GCPRoyaltyUpdate'];
  ResellerRoyaltyOrUpdateInput: ResellerRoyaltyOrUpdateInput;
  ResellerRoyaltyUpdate: ResolversTypes['AWSProServiceRoyaltyUpdate'] | ResolversTypes['AWSRoyaltyUpdate'] | ResolversTypes['GCPProServiceRoyaltyUpdate'] | ResolversTypes['GCPRoyaltyUpdate'];
  ResellerType: ResellerType;
  RevenueBreakdown: ResolverTypeWrapper<RevenueBreakdown>;
  RevenueMetric: ResolverTypeWrapper<RevenueMetric>;
  RevenueRange: RevenueRange;
  Role: ResolverTypeWrapper<Role>;
  Role_aggregate: ResolverTypeWrapper<Role_Aggregate>;
  Role_aggregate_fields: ResolverTypeWrapper<Role_Aggregate_Fields>;
  Role_append_input: Role_Append_Input;
  Role_bool_exp: Role_Bool_Exp;
  Role_constraint: Role_Constraint;
  Role_delete_at_path_input: Role_Delete_At_Path_Input;
  Role_delete_elem_input: Role_Delete_Elem_Input;
  Role_delete_key_input: Role_Delete_Key_Input;
  Role_insert_input: Role_Insert_Input;
  Role_max_fields: ResolverTypeWrapper<Role_Max_Fields>;
  Role_min_fields: ResolverTypeWrapper<Role_Min_Fields>;
  Role_mutation_response: ResolverTypeWrapper<Role_Mutation_Response>;
  Role_obj_rel_insert_input: Role_Obj_Rel_Insert_Input;
  Role_on_conflict: Role_On_Conflict;
  Role_order_by: Role_Order_By;
  Role_pk_columns_input: Role_Pk_Columns_Input;
  Role_prepend_input: Role_Prepend_Input;
  Role_select_column: Role_Select_Column;
  Role_set_input: Role_Set_Input;
  Role_stream_cursor_input: Role_Stream_Cursor_Input;
  Role_stream_cursor_value_input: Role_Stream_Cursor_Value_Input;
  Role_update_column: Role_Update_Column;
  Role_updates: Role_Updates;
  RolloverAmount: ResolversTypes['RolloverMaxAmount'] | ResolversTypes['RolloverMaxPercentage'];
  RolloverAmountInput: RolloverAmountInput;
  RolloverMaxAmount: ResolverTypeWrapper<RolloverMaxAmount>;
  RolloverMaxPercentage: ResolverTypeWrapper<RolloverMaxPercentage>;
  RolloverSettings: ResolverTypeWrapper<Omit<RolloverSettings, 'rollover_amount'> & { rollover_amount: ResolversTypes['RolloverAmount'] }>;
  RolloverSettingsInput: RolloverSettingsInput;
  RootLineItem: ResolversTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversTypes['ContractAWSRoyaltyLineItem'] | ResolversTypes['ContractAppliedCommitLineItem'] | ResolversTypes['ContractChargeLineItem'] | ResolversTypes['ContractCommitLineItem'] | ResolversTypes['ContractConversionLineItem'] | ResolversTypes['ContractDiscountLineItem'] | ResolversTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversTypes['ContractGCPRoyaltyLineItem'] | ResolversTypes['ContractPostpaidTrueupLineItem'] | ResolversTypes['ContractProServiceLineItem'] | ResolversTypes['ContractRolloverCommitLineItem'] | ResolversTypes['ContractSubscriptionLineItem'] | ResolversTypes['ContractUsageLineItem'] | ResolversTypes['CreditLineItem'] | ResolversTypes['CreditPurchaseLineItem'] | ResolversTypes['GroupedProductChargeLineItem'] | ResolversTypes['LegacyContractLineItem'] | ResolversTypes['LegacyLineItem'] | ResolversTypes['MinimumLineItem'] | ResolversTypes['OverageLineItem'] | ResolversTypes['ProductChargeLineItem'] | ResolversTypes['TrialDiscountLineItem'];
  RoundingBehaviorEnum: ResolverTypeWrapper<RoundingBehaviorEnum>;
  RoundingBehaviorEnum_aggregate: ResolverTypeWrapper<RoundingBehaviorEnum_Aggregate>;
  RoundingBehaviorEnum_aggregate_fields: ResolverTypeWrapper<RoundingBehaviorEnum_Aggregate_Fields>;
  RoundingBehaviorEnum_bool_exp: RoundingBehaviorEnum_Bool_Exp;
  RoundingBehaviorEnum_constraint: RoundingBehaviorEnum_Constraint;
  RoundingBehaviorEnum_enum: RoundingBehaviorEnum_Enum;
  RoundingBehaviorEnum_enum_comparison_exp: RoundingBehaviorEnum_Enum_Comparison_Exp;
  RoundingBehaviorEnum_insert_input: RoundingBehaviorEnum_Insert_Input;
  RoundingBehaviorEnum_max_fields: ResolverTypeWrapper<RoundingBehaviorEnum_Max_Fields>;
  RoundingBehaviorEnum_min_fields: ResolverTypeWrapper<RoundingBehaviorEnum_Min_Fields>;
  RoundingBehaviorEnum_mutation_response: ResolverTypeWrapper<RoundingBehaviorEnum_Mutation_Response>;
  RoundingBehaviorEnum_on_conflict: RoundingBehaviorEnum_On_Conflict;
  RoundingBehaviorEnum_order_by: RoundingBehaviorEnum_Order_By;
  RoundingBehaviorEnum_pk_columns_input: RoundingBehaviorEnum_Pk_Columns_Input;
  RoundingBehaviorEnum_select_column: RoundingBehaviorEnum_Select_Column;
  RoundingBehaviorEnum_set_input: RoundingBehaviorEnum_Set_Input;
  RoundingBehaviorEnum_stream_cursor_input: RoundingBehaviorEnum_Stream_Cursor_Input;
  RoundingBehaviorEnum_stream_cursor_value_input: RoundingBehaviorEnum_Stream_Cursor_Value_Input;
  RoundingBehaviorEnum_update_column: RoundingBehaviorEnum_Update_Column;
  RoundingBehaviorEnum_updates: RoundingBehaviorEnum_Updates;
  RoundingMethod: RoundingMethod;
  SaveVendorEntityMappingInput: SaveVendorEntityMappingInput;
  SaveVendorEntityMappingsResponse: ResolverTypeWrapper<SaveVendorEntityMappingsResponse>;
  ScalarContractRateScheduleSegment: ResolverTypeWrapper<Omit<ScalarContractRateScheduleSegment, 'list_rate' | 'override_rate'> & { list_rate: ResolversTypes['ScalarRate'], override_rate: Maybe<ResolversTypes['ScalarRate']> }>;
  ScalarFlatRate: ResolverTypeWrapper<ScalarFlatRate>;
  ScalarRate: ResolversTypes['CustomRate'] | ResolversTypes['PercentageRate'] | ResolversTypes['ScalarFlatRate'] | ResolversTypes['SubscriptionRate'] | ResolversTypes['TieredRate'];
  ScalarRateScheduleSegment: ResolverTypeWrapper<Omit<ScalarRateScheduleSegment, 'rate'> & { rate: ResolversTypes['ScalarRate'] }>;
  Scalar__ContractRateScheduleSegment: ResolverTypeWrapper<Scalars['Scalar__ContractRateScheduleSegment']>;
  Scalar__RateScheduleSegment: ResolverTypeWrapper<Scalars['Scalar__RateScheduleSegment']>;
  ScheduledCharge: ResolverTypeWrapper<Omit<ScheduledCharge, 'product'> & { product: ResolversTypes['ProductListItem'] }>;
  ScheduledChargeInput: ScheduledChargeInput;
  ScheduledChargeManagedField: ResolverTypeWrapper<ScheduledChargeManagedField>;
  ScheduledChargeManagedField_aggregate: ResolverTypeWrapper<ScheduledChargeManagedField_Aggregate>;
  ScheduledChargeManagedField_aggregate_bool_exp: ScheduledChargeManagedField_Aggregate_Bool_Exp;
  ScheduledChargeManagedField_aggregate_bool_exp_bool_and: ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And;
  ScheduledChargeManagedField_aggregate_bool_exp_bool_or: ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or;
  ScheduledChargeManagedField_aggregate_bool_exp_count: ScheduledChargeManagedField_Aggregate_Bool_Exp_Count;
  ScheduledChargeManagedField_aggregate_fields: ResolverTypeWrapper<ScheduledChargeManagedField_Aggregate_Fields>;
  ScheduledChargeManagedField_aggregate_order_by: ScheduledChargeManagedField_Aggregate_Order_By;
  ScheduledChargeManagedField_arr_rel_insert_input: ScheduledChargeManagedField_Arr_Rel_Insert_Input;
  ScheduledChargeManagedField_bool_exp: ScheduledChargeManagedField_Bool_Exp;
  ScheduledChargeManagedField_constraint: ScheduledChargeManagedField_Constraint;
  ScheduledChargeManagedField_insert_input: ScheduledChargeManagedField_Insert_Input;
  ScheduledChargeManagedField_max_fields: ResolverTypeWrapper<ScheduledChargeManagedField_Max_Fields>;
  ScheduledChargeManagedField_max_order_by: ScheduledChargeManagedField_Max_Order_By;
  ScheduledChargeManagedField_min_fields: ResolverTypeWrapper<ScheduledChargeManagedField_Min_Fields>;
  ScheduledChargeManagedField_min_order_by: ScheduledChargeManagedField_Min_Order_By;
  ScheduledChargeManagedField_mutation_response: ResolverTypeWrapper<ScheduledChargeManagedField_Mutation_Response>;
  ScheduledChargeManagedField_on_conflict: ScheduledChargeManagedField_On_Conflict;
  ScheduledChargeManagedField_order_by: ScheduledChargeManagedField_Order_By;
  ScheduledChargeManagedField_pk_columns_input: ScheduledChargeManagedField_Pk_Columns_Input;
  ScheduledChargeManagedField_select_column: ScheduledChargeManagedField_Select_Column;
  ScheduledChargeManagedField_select_column_ScheduledChargeManagedField_aggregate_bool_exp_bool_and_arguments_columns: ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  ScheduledChargeManagedField_select_column_ScheduledChargeManagedField_aggregate_bool_exp_bool_or_arguments_columns: ScheduledChargeManagedField_Select_Column_ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  ScheduledChargeManagedField_set_input: ScheduledChargeManagedField_Set_Input;
  ScheduledChargeManagedField_stream_cursor_input: ScheduledChargeManagedField_Stream_Cursor_Input;
  ScheduledChargeManagedField_stream_cursor_value_input: ScheduledChargeManagedField_Stream_Cursor_Value_Input;
  ScheduledChargeManagedField_update_column: ScheduledChargeManagedField_Update_Column;
  ScheduledChargeManagedField_updates: ScheduledChargeManagedField_Updates;
  ScheduledChargeSchedule: ResolverTypeWrapper<ScheduledChargeSchedule>;
  ScheduledChargeScheduleInput: ScheduledChargeScheduleInput;
  ScheduledChargeScheduleItem: ResolverTypeWrapper<ScheduledChargeScheduleItem>;
  ScheduledChargeScheduleItemInput: ScheduledChargeScheduleItemInput;
  ScheduledWebhookNotification: ResolverTypeWrapper<ScheduledWebhookNotification>;
  ScheduledWebhookNotificationList: ResolverTypeWrapper<ScheduledWebhookNotificationList>;
  ScheduledWebhookNotificationTypeEnum: ScheduledWebhookNotificationTypeEnum;
  SeatChargeInput: SeatChargeInput;
  SeatChargeLineItem: ResolverTypeWrapper<SeatChargeLineItem>;
  SeatCount: ResolverTypeWrapper<SeatCount>;
  SeatMetric: ResolverTypeWrapper<SeatMetric>;
  SeatMetricInput: SeatMetricInput;
  SeatMetricSortingInput: SeatMetricSortingInput;
  SeatMetricSortingOrderByEnum: SeatMetricSortingOrderByEnum;
  SeatMetricUsageEnum: SeatMetricUsageEnum;
  SeatPrice: ResolverTypeWrapper<SeatPrice>;
  SeatPurchaseInvoice: ResolverTypeWrapper<Omit<SeatPurchaseInvoice, 'line_items'> & { line_items: Array<ResolversTypes['RootLineItem']> }>;
  SequenceNumber: ResolverTypeWrapper<Omit<SequenceNumber, 'original_invoice'> & { original_invoice: ResolversTypes['CorrectableInvoice'] }>;
  SequenceNumberInput: SequenceNumberInput;
  ServicePeriod: ResolverTypeWrapper<ServicePeriod>;
  ServicePeriodStartOnEnum: ResolverTypeWrapper<ServicePeriodStartOnEnum>;
  ServicePeriodStartOnEnum_aggregate: ResolverTypeWrapper<ServicePeriodStartOnEnum_Aggregate>;
  ServicePeriodStartOnEnum_aggregate_fields: ResolverTypeWrapper<ServicePeriodStartOnEnum_Aggregate_Fields>;
  ServicePeriodStartOnEnum_bool_exp: ServicePeriodStartOnEnum_Bool_Exp;
  ServicePeriodStartOnEnum_constraint: ServicePeriodStartOnEnum_Constraint;
  ServicePeriodStartOnEnum_enum: ServicePeriodStartOnEnum_Enum;
  ServicePeriodStartOnEnum_enum_comparison_exp: ServicePeriodStartOnEnum_Enum_Comparison_Exp;
  ServicePeriodStartOnEnum_insert_input: ServicePeriodStartOnEnum_Insert_Input;
  ServicePeriodStartOnEnum_max_fields: ResolverTypeWrapper<ServicePeriodStartOnEnum_Max_Fields>;
  ServicePeriodStartOnEnum_min_fields: ResolverTypeWrapper<ServicePeriodStartOnEnum_Min_Fields>;
  ServicePeriodStartOnEnum_mutation_response: ResolverTypeWrapper<ServicePeriodStartOnEnum_Mutation_Response>;
  ServicePeriodStartOnEnum_on_conflict: ServicePeriodStartOnEnum_On_Conflict;
  ServicePeriodStartOnEnum_order_by: ServicePeriodStartOnEnum_Order_By;
  ServicePeriodStartOnEnum_pk_columns_input: ServicePeriodStartOnEnum_Pk_Columns_Input;
  ServicePeriodStartOnEnum_select_column: ServicePeriodStartOnEnum_Select_Column;
  ServicePeriodStartOnEnum_set_input: ServicePeriodStartOnEnum_Set_Input;
  ServicePeriodStartOnEnum_stream_cursor_input: ServicePeriodStartOnEnum_Stream_Cursor_Input;
  ServicePeriodStartOnEnum_stream_cursor_value_input: ServicePeriodStartOnEnum_Stream_Cursor_Value_Input;
  ServicePeriodStartOnEnum_update_column: ServicePeriodStartOnEnum_Update_Column;
  ServicePeriodStartOnEnum_updates: ServicePeriodStartOnEnum_Updates;
  SimulateFancyMetricUsageInput: SimulateFancyMetricUsageInput;
  SimulatedFancyMetricUsageData: ResolverTypeWrapper<SimulatedFancyMetricUsageData>;
  SimulatedFancyMetricUsageResult: ResolverTypeWrapper<SimulatedFancyMetricUsageResult>;
  SortDirection: SortDirection;
  String: ResolverTypeWrapper<Scalars['String']>;
  String_array_comparison_exp: String_Array_Comparison_Exp;
  String_comparison_exp: String_Comparison_Exp;
  StripeCreditGrantResponse: ResolverTypeWrapper<StripeCreditGrantResponse>;
  StripeCustomer: ResolverTypeWrapper<StripeCustomer>;
  StripeNextActionTypeEnum: StripeNextActionTypeEnum;
  StripePaymentData: StripePaymentData;
  StripePrepaidConfig_temporary: ResolverTypeWrapper<StripePrepaidConfig_Temporary>;
  StripePrepaidConfig_temporary_aggregate: ResolverTypeWrapper<StripePrepaidConfig_Temporary_Aggregate>;
  StripePrepaidConfig_temporary_aggregate_fields: ResolverTypeWrapper<StripePrepaidConfig_Temporary_Aggregate_Fields>;
  StripePrepaidConfig_temporary_bool_exp: StripePrepaidConfig_Temporary_Bool_Exp;
  StripePrepaidConfig_temporary_constraint: StripePrepaidConfig_Temporary_Constraint;
  StripePrepaidConfig_temporary_insert_input: StripePrepaidConfig_Temporary_Insert_Input;
  StripePrepaidConfig_temporary_max_fields: ResolverTypeWrapper<StripePrepaidConfig_Temporary_Max_Fields>;
  StripePrepaidConfig_temporary_min_fields: ResolverTypeWrapper<StripePrepaidConfig_Temporary_Min_Fields>;
  StripePrepaidConfig_temporary_mutation_response: ResolverTypeWrapper<StripePrepaidConfig_Temporary_Mutation_Response>;
  StripePrepaidConfig_temporary_on_conflict: StripePrepaidConfig_Temporary_On_Conflict;
  StripePrepaidConfig_temporary_order_by: StripePrepaidConfig_Temporary_Order_By;
  StripePrepaidConfig_temporary_pk_columns_input: StripePrepaidConfig_Temporary_Pk_Columns_Input;
  StripePrepaidConfig_temporary_select_column: StripePrepaidConfig_Temporary_Select_Column;
  StripePrepaidConfig_temporary_set_input: StripePrepaidConfig_Temporary_Set_Input;
  StripePrepaidConfig_temporary_stream_cursor_input: StripePrepaidConfig_Temporary_Stream_Cursor_Input;
  StripePrepaidConfig_temporary_stream_cursor_value_input: StripePrepaidConfig_Temporary_Stream_Cursor_Value_Input;
  StripePrepaidConfig_temporary_update_column: StripePrepaidConfig_Temporary_Update_Column;
  StripePrepaidConfig_temporary_updates: StripePrepaidConfig_Temporary_Updates;
  SubLineItem: ResolverTypeWrapper<SubLineItem>;
  SubLineItemTier: ResolverTypeWrapper<SubLineItemTier>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionProductListItem: ResolverTypeWrapper<SubscriptionProductListItem>;
  SubscriptionProductListItemState: ResolverTypeWrapper<SubscriptionProductListItemState>;
  SubscriptionRate: ResolverTypeWrapper<SubscriptionRate>;
  SubscriptionRateInput: SubscriptionRateInput;
  SubtotalAndQuantityByTime: ResolverTypeWrapper<SubtotalAndQuantityByTime>;
  SubtotalBreakdownInput: SubtotalBreakdownInput;
  SubtotalByTime: ResolverTypeWrapper<SubtotalByTime>;
  SubtotalInput: SubtotalInput;
  TaxAppliedStatusEnum_enum: TaxAppliedStatusEnum_Enum;
  TaxPlatform: TaxPlatform;
  Tier: ResolverTypeWrapper<Tier>;
  TierChargeLineItem: ResolverTypeWrapper<TierChargeLineItem>;
  TierInput: TierInput;
  TierMetadata: ResolverTypeWrapper<TierMetadata>;
  TierPeriod: ResolverTypeWrapper<TierPeriod>;
  TierPrice: ResolversTypes['FlatFee'] | ResolversTypes['Price'];
  TierSubLineItem: ResolversTypes['TierChargeLineItem'];
  TieredOverride: ResolverTypeWrapper<TieredOverride>;
  TieredOverrideInput: TieredOverrideInput;
  TieredOverrideTiers: ResolverTypeWrapper<TieredOverrideTiers>;
  TieredRate: ResolverTypeWrapper<TieredRate>;
  TieredRateInput: TieredRateInput;
  TieringModeEnum: ResolverTypeWrapper<TieringModeEnum>;
  TieringModeEnum_aggregate: ResolverTypeWrapper<TieringModeEnum_Aggregate>;
  TieringModeEnum_aggregate_fields: ResolverTypeWrapper<TieringModeEnum_Aggregate_Fields>;
  TieringModeEnum_bool_exp: TieringModeEnum_Bool_Exp;
  TieringModeEnum_constraint: TieringModeEnum_Constraint;
  TieringModeEnum_enum: TieringModeEnum_Enum;
  TieringModeEnum_enum_comparison_exp: TieringModeEnum_Enum_Comparison_Exp;
  TieringModeEnum_insert_input: TieringModeEnum_Insert_Input;
  TieringModeEnum_max_fields: ResolverTypeWrapper<TieringModeEnum_Max_Fields>;
  TieringModeEnum_min_fields: ResolverTypeWrapper<TieringModeEnum_Min_Fields>;
  TieringModeEnum_mutation_response: ResolverTypeWrapper<TieringModeEnum_Mutation_Response>;
  TieringModeEnum_on_conflict: TieringModeEnum_On_Conflict;
  TieringModeEnum_order_by: TieringModeEnum_Order_By;
  TieringModeEnum_pk_columns_input: TieringModeEnum_Pk_Columns_Input;
  TieringModeEnum_select_column: TieringModeEnum_Select_Column;
  TieringModeEnum_set_input: TieringModeEnum_Set_Input;
  TieringModeEnum_stream_cursor_input: TieringModeEnum_Stream_Cursor_Input;
  TieringModeEnum_stream_cursor_value_input: TieringModeEnum_Stream_Cursor_Value_Input;
  TieringModeEnum_update_column: TieringModeEnum_Update_Column;
  TieringModeEnum_updates: TieringModeEnum_Updates;
  TimeRange: ResolverTypeWrapper<TimeRange>;
  TimeRangeInput: TimeRangeInput;
  Tokens: ResolverTypeWrapper<Tokens>;
  Tokens_aggregate: ResolverTypeWrapper<Tokens_Aggregate>;
  Tokens_aggregate_bool_exp: Tokens_Aggregate_Bool_Exp;
  Tokens_aggregate_bool_exp_count: Tokens_Aggregate_Bool_Exp_Count;
  Tokens_aggregate_fields: ResolverTypeWrapper<Tokens_Aggregate_Fields>;
  Tokens_aggregate_order_by: Tokens_Aggregate_Order_By;
  Tokens_arr_rel_insert_input: Tokens_Arr_Rel_Insert_Input;
  Tokens_bool_exp: Tokens_Bool_Exp;
  Tokens_constraint: Tokens_Constraint;
  Tokens_insert_input: Tokens_Insert_Input;
  Tokens_max_fields: ResolverTypeWrapper<Tokens_Max_Fields>;
  Tokens_max_order_by: Tokens_Max_Order_By;
  Tokens_min_fields: ResolverTypeWrapper<Tokens_Min_Fields>;
  Tokens_min_order_by: Tokens_Min_Order_By;
  Tokens_mutation_response: ResolverTypeWrapper<Tokens_Mutation_Response>;
  Tokens_on_conflict: Tokens_On_Conflict;
  Tokens_order_by: Tokens_Order_By;
  Tokens_pk_columns_input: Tokens_Pk_Columns_Input;
  Tokens_select_column: Tokens_Select_Column;
  Tokens_set_input: Tokens_Set_Input;
  Tokens_stream_cursor_input: Tokens_Stream_Cursor_Input;
  Tokens_stream_cursor_value_input: Tokens_Stream_Cursor_Value_Input;
  Tokens_update_column: Tokens_Update_Column;
  Tokens_updates: Tokens_Updates;
  TopCustomerSpenders: ResolverTypeWrapper<TopCustomerSpenders>;
  Transition: ResolverTypeWrapper<Transition>;
  TransitionInput: TransitionInput;
  TransitionTypeEnum: TransitionTypeEnum;
  TrialDiscountLineItem: ResolverTypeWrapper<TrialDiscountLineItem>;
  TrialSpec: ResolverTypeWrapper<TrialSpec>;
  TrialSpecInput: TrialSpecInput;
  TrialSpecSpendingCap: ResolverTypeWrapper<TrialSpecSpendingCap>;
  TrialSpecSpendingCapInput: TrialSpecSpendingCapInput;
  TrialSpecSpendingCap_aggregate: ResolverTypeWrapper<TrialSpecSpendingCap_Aggregate>;
  TrialSpecSpendingCap_aggregate_bool_exp: TrialSpecSpendingCap_Aggregate_Bool_Exp;
  TrialSpecSpendingCap_aggregate_bool_exp_count: TrialSpecSpendingCap_Aggregate_Bool_Exp_Count;
  TrialSpecSpendingCap_aggregate_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Aggregate_Fields>;
  TrialSpecSpendingCap_aggregate_order_by: TrialSpecSpendingCap_Aggregate_Order_By;
  TrialSpecSpendingCap_arr_rel_insert_input: TrialSpecSpendingCap_Arr_Rel_Insert_Input;
  TrialSpecSpendingCap_avg_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Avg_Fields>;
  TrialSpecSpendingCap_avg_order_by: TrialSpecSpendingCap_Avg_Order_By;
  TrialSpecSpendingCap_bool_exp: TrialSpecSpendingCap_Bool_Exp;
  TrialSpecSpendingCap_constraint: TrialSpecSpendingCap_Constraint;
  TrialSpecSpendingCap_inc_input: TrialSpecSpendingCap_Inc_Input;
  TrialSpecSpendingCap_insert_input: TrialSpecSpendingCap_Insert_Input;
  TrialSpecSpendingCap_max_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Max_Fields>;
  TrialSpecSpendingCap_max_order_by: TrialSpecSpendingCap_Max_Order_By;
  TrialSpecSpendingCap_min_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Min_Fields>;
  TrialSpecSpendingCap_min_order_by: TrialSpecSpendingCap_Min_Order_By;
  TrialSpecSpendingCap_mutation_response: ResolverTypeWrapper<TrialSpecSpendingCap_Mutation_Response>;
  TrialSpecSpendingCap_on_conflict: TrialSpecSpendingCap_On_Conflict;
  TrialSpecSpendingCap_order_by: TrialSpecSpendingCap_Order_By;
  TrialSpecSpendingCap_pk_columns_input: TrialSpecSpendingCap_Pk_Columns_Input;
  TrialSpecSpendingCap_select_column: TrialSpecSpendingCap_Select_Column;
  TrialSpecSpendingCap_set_input: TrialSpecSpendingCap_Set_Input;
  TrialSpecSpendingCap_stddev_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Stddev_Fields>;
  TrialSpecSpendingCap_stddev_order_by: TrialSpecSpendingCap_Stddev_Order_By;
  TrialSpecSpendingCap_stddev_pop_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Stddev_Pop_Fields>;
  TrialSpecSpendingCap_stddev_pop_order_by: TrialSpecSpendingCap_Stddev_Pop_Order_By;
  TrialSpecSpendingCap_stddev_samp_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Stddev_Samp_Fields>;
  TrialSpecSpendingCap_stddev_samp_order_by: TrialSpecSpendingCap_Stddev_Samp_Order_By;
  TrialSpecSpendingCap_stream_cursor_input: TrialSpecSpendingCap_Stream_Cursor_Input;
  TrialSpecSpendingCap_stream_cursor_value_input: TrialSpecSpendingCap_Stream_Cursor_Value_Input;
  TrialSpecSpendingCap_sum_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Sum_Fields>;
  TrialSpecSpendingCap_sum_order_by: TrialSpecSpendingCap_Sum_Order_By;
  TrialSpecSpendingCap_update_column: TrialSpecSpendingCap_Update_Column;
  TrialSpecSpendingCap_updates: TrialSpecSpendingCap_Updates;
  TrialSpecSpendingCap_var_pop_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Var_Pop_Fields>;
  TrialSpecSpendingCap_var_pop_order_by: TrialSpecSpendingCap_Var_Pop_Order_By;
  TrialSpecSpendingCap_var_samp_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Var_Samp_Fields>;
  TrialSpecSpendingCap_var_samp_order_by: TrialSpecSpendingCap_Var_Samp_Order_By;
  TrialSpecSpendingCap_variance_fields: ResolverTypeWrapper<TrialSpecSpendingCap_Variance_Fields>;
  TrialSpecSpendingCap_variance_order_by: TrialSpecSpendingCap_Variance_Order_By;
  TrialSpec_aggregate: ResolverTypeWrapper<TrialSpec_Aggregate>;
  TrialSpec_aggregate_fields: ResolverTypeWrapper<TrialSpec_Aggregate_Fields>;
  TrialSpec_avg_fields: ResolverTypeWrapper<TrialSpec_Avg_Fields>;
  TrialSpec_bool_exp: TrialSpec_Bool_Exp;
  TrialSpec_constraint: TrialSpec_Constraint;
  TrialSpec_inc_input: TrialSpec_Inc_Input;
  TrialSpec_insert_input: TrialSpec_Insert_Input;
  TrialSpec_max_fields: ResolverTypeWrapper<TrialSpec_Max_Fields>;
  TrialSpec_min_fields: ResolverTypeWrapper<TrialSpec_Min_Fields>;
  TrialSpec_mutation_response: ResolverTypeWrapper<TrialSpec_Mutation_Response>;
  TrialSpec_obj_rel_insert_input: TrialSpec_Obj_Rel_Insert_Input;
  TrialSpec_on_conflict: TrialSpec_On_Conflict;
  TrialSpec_order_by: TrialSpec_Order_By;
  TrialSpec_pk_columns_input: TrialSpec_Pk_Columns_Input;
  TrialSpec_select_column: TrialSpec_Select_Column;
  TrialSpec_set_input: TrialSpec_Set_Input;
  TrialSpec_stddev_fields: ResolverTypeWrapper<TrialSpec_Stddev_Fields>;
  TrialSpec_stddev_pop_fields: ResolverTypeWrapper<TrialSpec_Stddev_Pop_Fields>;
  TrialSpec_stddev_samp_fields: ResolverTypeWrapper<TrialSpec_Stddev_Samp_Fields>;
  TrialSpec_stream_cursor_input: TrialSpec_Stream_Cursor_Input;
  TrialSpec_stream_cursor_value_input: TrialSpec_Stream_Cursor_Value_Input;
  TrialSpec_sum_fields: ResolverTypeWrapper<TrialSpec_Sum_Fields>;
  TrialSpec_update_column: TrialSpec_Update_Column;
  TrialSpec_updates: TrialSpec_Updates;
  TrialSpec_var_pop_fields: ResolverTypeWrapper<TrialSpec_Var_Pop_Fields>;
  TrialSpec_var_samp_fields: ResolverTypeWrapper<TrialSpec_Var_Samp_Fields>;
  TrialSpec_variance_fields: ResolverTypeWrapper<TrialSpec_Variance_Fields>;
  UnpaidInvoiceIdentifier: ResolverTypeWrapper<UnpaidInvoiceIdentifier>;
  UpdateBillingProviderSettingsInput: UpdateBillingProviderSettingsInput;
  UpdateCommitAccessScheduleInput: UpdateCommitAccessScheduleInput;
  UpdateCommitInvoiceScheduleInput: UpdateCommitInvoiceScheduleInput;
  UpdateCommitNetsuiteSalesOrderIdInput: UpdateCommitNetsuiteSalesOrderIdInput;
  UpdateCommitRolloverFractionInput: UpdateCommitRolloverFractionInput;
  UpdateCommitScheduleImpactedInvoice: ResolverTypeWrapper<UpdateCommitScheduleImpactedInvoice>;
  UpdateCommitScheduleInvoiceItemPreview: ResolverTypeWrapper<UpdateCommitScheduleInvoiceItemPreview>;
  UpdateCommitScheduleMutationResponse: ResolverTypeWrapper<UpdateCommitScheduleMutationResponse>;
  UpdateCommitScheduleSegmentItemPreview: ResolverTypeWrapper<UpdateCommitScheduleSegmentItemPreview>;
  UpdateOrUndefined: ResolversTypes['QuantityConversionUpdate'] | ResolversTypes['QuantityRoundingUpdate'];
  UpdateOrUndefinedValue: ResolversTypes['QuantityConversion'] | ResolversTypes['QuantityRounding'];
  UpdateScheduledChargeNetsuiteSalesOrderIdInput: UpdateScheduledChargeNetsuiteSalesOrderIdInput;
  UpdateScheduledChargeScheduleInput: UpdateScheduledChargeScheduleInput;
  UpdateStripeSettingsInput: UpdateStripeSettingsInput;
  UpsertBillingProviderInvoiceInput: UpsertBillingProviderInvoiceInput;
  UsageBreakdownData: ResolverTypeWrapper<UsageBreakdownData>;
  UsageBreakdownResponse: ResolverTypeWrapper<UsageBreakdownResponse>;
  UsageEventMutationResponse: ResolverTypeWrapper<UsageEventMutationResponse>;
  UsageFilter: ResolverTypeWrapper<UsageFilter>;
  UsageFilterUpdate: ResolverTypeWrapper<UsageFilterUpdate>;
  UsageGroup: ResolverTypeWrapper<UsageGroup>;
  UsageGroupByInput: UsageGroupByInput;
  UsageProductListItem: ResolverTypeWrapper<UsageProductListItem>;
  UsageProductListItemState: ResolverTypeWrapper<UsageProductListItemState>;
  UsageWindow: ResolverTypeWrapper<UsageWindow>;
  User: ResolverTypeWrapper<User>;
  UserAuthTypeEnum: ResolverTypeWrapper<UserAuthTypeEnum>;
  UserAuthTypeEnum_aggregate: ResolverTypeWrapper<UserAuthTypeEnum_Aggregate>;
  UserAuthTypeEnum_aggregate_fields: ResolverTypeWrapper<UserAuthTypeEnum_Aggregate_Fields>;
  UserAuthTypeEnum_bool_exp: UserAuthTypeEnum_Bool_Exp;
  UserAuthTypeEnum_constraint: UserAuthTypeEnum_Constraint;
  UserAuthTypeEnum_enum: UserAuthTypeEnum_Enum;
  UserAuthTypeEnum_enum_comparison_exp: UserAuthTypeEnum_Enum_Comparison_Exp;
  UserAuthTypeEnum_insert_input: UserAuthTypeEnum_Insert_Input;
  UserAuthTypeEnum_max_fields: ResolverTypeWrapper<UserAuthTypeEnum_Max_Fields>;
  UserAuthTypeEnum_min_fields: ResolverTypeWrapper<UserAuthTypeEnum_Min_Fields>;
  UserAuthTypeEnum_mutation_response: ResolverTypeWrapper<UserAuthTypeEnum_Mutation_Response>;
  UserAuthTypeEnum_on_conflict: UserAuthTypeEnum_On_Conflict;
  UserAuthTypeEnum_order_by: UserAuthTypeEnum_Order_By;
  UserAuthTypeEnum_pk_columns_input: UserAuthTypeEnum_Pk_Columns_Input;
  UserAuthTypeEnum_select_column: UserAuthTypeEnum_Select_Column;
  UserAuthTypeEnum_set_input: UserAuthTypeEnum_Set_Input;
  UserAuthTypeEnum_stream_cursor_input: UserAuthTypeEnum_Stream_Cursor_Input;
  UserAuthTypeEnum_stream_cursor_value_input: UserAuthTypeEnum_Stream_Cursor_Value_Input;
  UserAuthTypeEnum_update_column: UserAuthTypeEnum_Update_Column;
  UserAuthTypeEnum_updates: UserAuthTypeEnum_Updates;
  UserSortingInput: UserSortingInput;
  UserSortingOrderByEnum: UserSortingOrderByEnum;
  User_aggregate: ResolverTypeWrapper<User_Aggregate>;
  User_aggregate_bool_exp: User_Aggregate_Bool_Exp;
  User_aggregate_bool_exp_count: User_Aggregate_Bool_Exp_Count;
  User_aggregate_fields: ResolverTypeWrapper<User_Aggregate_Fields>;
  User_aggregate_order_by: User_Aggregate_Order_By;
  User_arr_rel_insert_input: User_Arr_Rel_Insert_Input;
  User_bool_exp: User_Bool_Exp;
  User_constraint: User_Constraint;
  User_insert_input: User_Insert_Input;
  User_max_fields: ResolverTypeWrapper<User_Max_Fields>;
  User_max_order_by: User_Max_Order_By;
  User_min_fields: ResolverTypeWrapper<User_Min_Fields>;
  User_min_order_by: User_Min_Order_By;
  User_mutation_response: ResolverTypeWrapper<User_Mutation_Response>;
  User_on_conflict: User_On_Conflict;
  User_order_by: User_Order_By;
  User_pk_columns_input: User_Pk_Columns_Input;
  User_select_column: User_Select_Column;
  User_set_input: User_Set_Input;
  User_stream_cursor_input: User_Stream_Cursor_Input;
  User_stream_cursor_value_input: User_Stream_Cursor_Value_Input;
  User_update_column: User_Update_Column;
  User_updates: User_Updates;
  UuidFilter: UuidFilter;
  UuidRange: UuidRange;
  Webhook: ResolverTypeWrapper<Webhook>;
  WebhookNotification: ResolverTypeWrapper<WebhookNotification>;
  WebhookNotification_aggregate: ResolverTypeWrapper<WebhookNotification_Aggregate>;
  WebhookNotification_aggregate_fields: ResolverTypeWrapper<WebhookNotification_Aggregate_Fields>;
  WebhookNotification_append_input: WebhookNotification_Append_Input;
  WebhookNotification_avg_fields: ResolverTypeWrapper<WebhookNotification_Avg_Fields>;
  WebhookNotification_bool_exp: WebhookNotification_Bool_Exp;
  WebhookNotification_constraint: WebhookNotification_Constraint;
  WebhookNotification_delete_at_path_input: WebhookNotification_Delete_At_Path_Input;
  WebhookNotification_delete_elem_input: WebhookNotification_Delete_Elem_Input;
  WebhookNotification_delete_key_input: WebhookNotification_Delete_Key_Input;
  WebhookNotification_inc_input: WebhookNotification_Inc_Input;
  WebhookNotification_insert_input: WebhookNotification_Insert_Input;
  WebhookNotification_max_fields: ResolverTypeWrapper<WebhookNotification_Max_Fields>;
  WebhookNotification_min_fields: ResolverTypeWrapper<WebhookNotification_Min_Fields>;
  WebhookNotification_mutation_response: ResolverTypeWrapper<WebhookNotification_Mutation_Response>;
  WebhookNotification_on_conflict: WebhookNotification_On_Conflict;
  WebhookNotification_order_by: WebhookNotification_Order_By;
  WebhookNotification_pk_columns_input: WebhookNotification_Pk_Columns_Input;
  WebhookNotification_prepend_input: WebhookNotification_Prepend_Input;
  WebhookNotification_select_column: WebhookNotification_Select_Column;
  WebhookNotification_set_input: WebhookNotification_Set_Input;
  WebhookNotification_stddev_fields: ResolverTypeWrapper<WebhookNotification_Stddev_Fields>;
  WebhookNotification_stddev_pop_fields: ResolverTypeWrapper<WebhookNotification_Stddev_Pop_Fields>;
  WebhookNotification_stddev_samp_fields: ResolverTypeWrapper<WebhookNotification_Stddev_Samp_Fields>;
  WebhookNotification_stream_cursor_input: WebhookNotification_Stream_Cursor_Input;
  WebhookNotification_stream_cursor_value_input: WebhookNotification_Stream_Cursor_Value_Input;
  WebhookNotification_sum_fields: ResolverTypeWrapper<WebhookNotification_Sum_Fields>;
  WebhookNotification_update_column: WebhookNotification_Update_Column;
  WebhookNotification_updates: WebhookNotification_Updates;
  WebhookNotification_var_pop_fields: ResolverTypeWrapper<WebhookNotification_Var_Pop_Fields>;
  WebhookNotification_var_samp_fields: ResolverTypeWrapper<WebhookNotification_Var_Samp_Fields>;
  WebhookNotification_variance_fields: ResolverTypeWrapper<WebhookNotification_Variance_Fields>;
  Webhook_aggregate: ResolverTypeWrapper<Webhook_Aggregate>;
  Webhook_aggregate_fields: ResolverTypeWrapper<Webhook_Aggregate_Fields>;
  Webhook_bool_exp: Webhook_Bool_Exp;
  Webhook_constraint: Webhook_Constraint;
  Webhook_insert_input: Webhook_Insert_Input;
  Webhook_max_fields: ResolverTypeWrapper<Webhook_Max_Fields>;
  Webhook_min_fields: ResolverTypeWrapper<Webhook_Min_Fields>;
  Webhook_mutation_response: ResolverTypeWrapper<Webhook_Mutation_Response>;
  Webhook_on_conflict: Webhook_On_Conflict;
  Webhook_order_by: Webhook_Order_By;
  Webhook_pk_columns_input: Webhook_Pk_Columns_Input;
  Webhook_select_column: Webhook_Select_Column;
  Webhook_set_input: Webhook_Set_Input;
  Webhook_stream_cursor_input: Webhook_Stream_Cursor_Input;
  Webhook_stream_cursor_value_input: Webhook_Stream_Cursor_Value_Input;
  Webhook_update_column: Webhook_Update_Column;
  Webhook_updates: Webhook_Updates;
  WindowSize: WindowSize;
  WorkatoSSOUrlResponse: ResolverTypeWrapper<WorkatoSsoUrlResponse>;
  cursor_ordering: Cursor_Ordering;
  json: ResolverTypeWrapper<Scalars['json']>;
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: Numeric_Comparison_Exp;
  order_by: Order_By;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: Uuid_Comparison_Exp;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  APITokenResponse: ApiTokenResponse;
  AWSProServiceRoyalty: AwsProServiceRoyalty;
  AWSProServiceRoyaltyUpdate: AwsProServiceRoyaltyUpdate;
  AWSRoyalty: AwsRoyalty;
  AWSRoyaltyUpdate: AwsRoyaltyUpdate;
  AccountTypeEnum: AccountTypeEnum;
  AccountTypeEnum_aggregate: AccountTypeEnum_Aggregate;
  AccountTypeEnum_aggregate_fields: AccountTypeEnum_Aggregate_Fields;
  AccountTypeEnum_bool_exp: AccountTypeEnum_Bool_Exp;
  AccountTypeEnum_enum_comparison_exp: AccountTypeEnum_Enum_Comparison_Exp;
  AccountTypeEnum_insert_input: AccountTypeEnum_Insert_Input;
  AccountTypeEnum_max_fields: AccountTypeEnum_Max_Fields;
  AccountTypeEnum_min_fields: AccountTypeEnum_Min_Fields;
  AccountTypeEnum_mutation_response: AccountTypeEnum_Mutation_Response;
  AccountTypeEnum_on_conflict: AccountTypeEnum_On_Conflict;
  AccountTypeEnum_order_by: AccountTypeEnum_Order_By;
  AccountTypeEnum_pk_columns_input: AccountTypeEnum_Pk_Columns_Input;
  AccountTypeEnum_set_input: AccountTypeEnum_Set_Input;
  AccountTypeEnum_stream_cursor_input: AccountTypeEnum_Stream_Cursor_Input;
  AccountTypeEnum_stream_cursor_value_input: AccountTypeEnum_Stream_Cursor_Value_Input;
  AccountTypeEnum_updates: AccountTypeEnum_Updates;
  Actor: Actor;
  ActorOrUser: ResolversParentTypes['Actor'] | ResolversParentTypes['User'];
  Actor_aggregate: Actor_Aggregate;
  Actor_aggregate_fields: Actor_Aggregate_Fields;
  Actor_bool_exp: Actor_Bool_Exp;
  Actor_insert_input: Actor_Insert_Input;
  Actor_max_fields: Actor_Max_Fields;
  Actor_min_fields: Actor_Min_Fields;
  Actor_mutation_response: Actor_Mutation_Response;
  Actor_obj_rel_insert_input: Actor_Obj_Rel_Insert_Input;
  Actor_on_conflict: Actor_On_Conflict;
  Actor_order_by: Actor_Order_By;
  Actor_pk_columns_input: Actor_Pk_Columns_Input;
  Actor_set_input: Actor_Set_Input;
  Actor_stream_cursor_input: Actor_Stream_Cursor_Input;
  Actor_stream_cursor_value_input: Actor_Stream_Cursor_Value_Input;
  Actor_updates: Actor_Updates;
  AdHocCreditLineItemInput: AdHocCreditLineItemInput;
  AdHocPlanInvoice: Omit<AdHocPlanInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  AdHocPlanInvoiceInput: AdHocPlanInvoiceInput;
  AdHocUsageItemInput: AdHocUsageItemInput;
  AddMarketplaceToCustomerMutationResponse: AddMarketplaceToCustomerMutationResponse;
  AdhocContractUsageInvoice: Omit<AdhocContractUsageInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  AdhocContractUsageInvoiceInput: AdhocContractUsageInvoiceInput;
  AdhocContractUsageLineItemInput: AdhocContractUsageLineItemInput;
  AdvanceChargeLineItem: Omit<AdvanceChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversParentTypes['ChargeSubLineItem']>> };
  AdvanceInvoice: Omit<AdvanceInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  Alert: Alert;
  AlertManagedField: AlertManagedField;
  AlertManagedField_aggregate: AlertManagedField_Aggregate;
  AlertManagedField_aggregate_bool_exp: AlertManagedField_Aggregate_Bool_Exp;
  AlertManagedField_aggregate_bool_exp_bool_and: AlertManagedField_Aggregate_Bool_Exp_Bool_And;
  AlertManagedField_aggregate_bool_exp_bool_or: AlertManagedField_Aggregate_Bool_Exp_Bool_Or;
  AlertManagedField_aggregate_bool_exp_count: AlertManagedField_Aggregate_Bool_Exp_Count;
  AlertManagedField_aggregate_fields: AlertManagedField_Aggregate_Fields;
  AlertManagedField_aggregate_order_by: AlertManagedField_Aggregate_Order_By;
  AlertManagedField_arr_rel_insert_input: AlertManagedField_Arr_Rel_Insert_Input;
  AlertManagedField_bool_exp: AlertManagedField_Bool_Exp;
  AlertManagedField_insert_input: AlertManagedField_Insert_Input;
  AlertManagedField_max_fields: AlertManagedField_Max_Fields;
  AlertManagedField_max_order_by: AlertManagedField_Max_Order_By;
  AlertManagedField_min_fields: AlertManagedField_Min_Fields;
  AlertManagedField_min_order_by: AlertManagedField_Min_Order_By;
  AlertManagedField_mutation_response: AlertManagedField_Mutation_Response;
  AlertManagedField_on_conflict: AlertManagedField_On_Conflict;
  AlertManagedField_order_by: AlertManagedField_Order_By;
  AlertManagedField_pk_columns_input: AlertManagedField_Pk_Columns_Input;
  AlertManagedField_set_input: AlertManagedField_Set_Input;
  AlertManagedField_stream_cursor_input: AlertManagedField_Stream_Cursor_Input;
  AlertManagedField_stream_cursor_value_input: AlertManagedField_Stream_Cursor_Value_Input;
  AlertManagedField_updates: AlertManagedField_Updates;
  AlertTypeEnum: AlertTypeEnum;
  AlertTypeEnum_aggregate: AlertTypeEnum_Aggregate;
  AlertTypeEnum_aggregate_fields: AlertTypeEnum_Aggregate_Fields;
  AlertTypeEnum_bool_exp: AlertTypeEnum_Bool_Exp;
  AlertTypeEnum_enum_comparison_exp: AlertTypeEnum_Enum_Comparison_Exp;
  AlertTypeEnum_insert_input: AlertTypeEnum_Insert_Input;
  AlertTypeEnum_max_fields: AlertTypeEnum_Max_Fields;
  AlertTypeEnum_min_fields: AlertTypeEnum_Min_Fields;
  AlertTypeEnum_mutation_response: AlertTypeEnum_Mutation_Response;
  AlertTypeEnum_on_conflict: AlertTypeEnum_On_Conflict;
  AlertTypeEnum_order_by: AlertTypeEnum_Order_By;
  AlertTypeEnum_pk_columns_input: AlertTypeEnum_Pk_Columns_Input;
  AlertTypeEnum_set_input: AlertTypeEnum_Set_Input;
  AlertTypeEnum_stream_cursor_input: AlertTypeEnum_Stream_Cursor_Input;
  AlertTypeEnum_stream_cursor_value_input: AlertTypeEnum_Stream_Cursor_Value_Input;
  AlertTypeEnum_updates: AlertTypeEnum_Updates;
  Alert_aggregate: Alert_Aggregate;
  Alert_aggregate_bool_exp: Alert_Aggregate_Bool_Exp;
  Alert_aggregate_bool_exp_bool_and: Alert_Aggregate_Bool_Exp_Bool_And;
  Alert_aggregate_bool_exp_bool_or: Alert_Aggregate_Bool_Exp_Bool_Or;
  Alert_aggregate_bool_exp_count: Alert_Aggregate_Bool_Exp_Count;
  Alert_aggregate_fields: Alert_Aggregate_Fields;
  Alert_aggregate_order_by: Alert_Aggregate_Order_By;
  Alert_append_input: Alert_Append_Input;
  Alert_arr_rel_insert_input: Alert_Arr_Rel_Insert_Input;
  Alert_avg_fields: Alert_Avg_Fields;
  Alert_avg_order_by: Alert_Avg_Order_By;
  Alert_bool_exp: Alert_Bool_Exp;
  Alert_delete_at_path_input: Alert_Delete_At_Path_Input;
  Alert_delete_elem_input: Alert_Delete_Elem_Input;
  Alert_delete_key_input: Alert_Delete_Key_Input;
  Alert_inc_input: Alert_Inc_Input;
  Alert_insert_input: Alert_Insert_Input;
  Alert_max_fields: Alert_Max_Fields;
  Alert_max_order_by: Alert_Max_Order_By;
  Alert_min_fields: Alert_Min_Fields;
  Alert_min_order_by: Alert_Min_Order_By;
  Alert_mutation_response: Alert_Mutation_Response;
  Alert_obj_rel_insert_input: Alert_Obj_Rel_Insert_Input;
  Alert_on_conflict: Alert_On_Conflict;
  Alert_order_by: Alert_Order_By;
  Alert_pk_columns_input: Alert_Pk_Columns_Input;
  Alert_prepend_input: Alert_Prepend_Input;
  Alert_set_input: Alert_Set_Input;
  Alert_stddev_fields: Alert_Stddev_Fields;
  Alert_stddev_order_by: Alert_Stddev_Order_By;
  Alert_stddev_pop_fields: Alert_Stddev_Pop_Fields;
  Alert_stddev_pop_order_by: Alert_Stddev_Pop_Order_By;
  Alert_stddev_samp_fields: Alert_Stddev_Samp_Fields;
  Alert_stddev_samp_order_by: Alert_Stddev_Samp_Order_By;
  Alert_stream_cursor_input: Alert_Stream_Cursor_Input;
  Alert_stream_cursor_value_input: Alert_Stream_Cursor_Value_Input;
  Alert_sum_fields: Alert_Sum_Fields;
  Alert_sum_order_by: Alert_Sum_Order_By;
  Alert_updates: Alert_Updates;
  Alert_var_pop_fields: Alert_Var_Pop_Fields;
  Alert_var_pop_order_by: Alert_Var_Pop_Order_By;
  Alert_var_samp_fields: Alert_Var_Samp_Fields;
  Alert_var_samp_order_by: Alert_Var_Samp_Order_By;
  Alert_variance_fields: Alert_Variance_Fields;
  Alert_variance_order_by: Alert_Variance_Order_By;
  ArchiveContractMutationResponse: ArchiveContractMutationResponse;
  ArchiveProductListItemMutationResponse: ArchiveProductListItemMutationResponse;
  ArchiveScheduledWebhookNotificationsCustomerFilter: ArchiveScheduledWebhookNotificationsCustomerFilter;
  ArrearsInvoice: Omit<ArrearsInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  AuditEntry: AuditEntry;
  AuditLog: Omit<AuditLog, 'actor'> & { actor: ResolversParentTypes['ActorOrUser'] };
  AuditLogResponse: AuditLogResponse;
  AuthCheckResponse: AuthCheckResponse;
  BaseCommit: ResolversParentTypes['PostpaidCommit'] | ResolversParentTypes['PrepaidCommit'];
  BaseRateScheduleSegment: ResolversParentTypes['RateScheduleSegment'] | ResolversParentTypes['ScalarContractRateScheduleSegment'] | ResolversParentTypes['ScalarRateScheduleSegment'];
  BaseUsageFilter: ResolversParentTypes['InitialUsageFilter'] | ResolversParentTypes['UsageFilterUpdate'];
  BillableMetric: BillableMetric;
  BillableMetricInput: BillableMetricInput;
  BillableMetricManagedField: BillableMetricManagedField;
  BillableMetricManagedField_aggregate: BillableMetricManagedField_Aggregate;
  BillableMetricManagedField_aggregate_fields: BillableMetricManagedField_Aggregate_Fields;
  BillableMetricManagedField_bool_exp: BillableMetricManagedField_Bool_Exp;
  BillableMetricManagedField_insert_input: BillableMetricManagedField_Insert_Input;
  BillableMetricManagedField_max_fields: BillableMetricManagedField_Max_Fields;
  BillableMetricManagedField_min_fields: BillableMetricManagedField_Min_Fields;
  BillableMetricManagedField_mutation_response: BillableMetricManagedField_Mutation_Response;
  BillableMetricManagedField_on_conflict: BillableMetricManagedField_On_Conflict;
  BillableMetricManagedField_order_by: BillableMetricManagedField_Order_By;
  BillableMetricManagedField_pk_columns_input: BillableMetricManagedField_Pk_Columns_Input;
  BillableMetricManagedField_set_input: BillableMetricManagedField_Set_Input;
  BillableMetricManagedField_stream_cursor_input: BillableMetricManagedField_Stream_Cursor_Input;
  BillableMetricManagedField_stream_cursor_value_input: BillableMetricManagedField_Stream_Cursor_Value_Input;
  BillableMetricManagedField_updates: BillableMetricManagedField_Updates;
  BillableMetricSortingInput: BillableMetricSortingInput;
  BillableMetric_aggregate: BillableMetric_Aggregate;
  BillableMetric_aggregate_bool_exp: BillableMetric_Aggregate_Bool_Exp;
  BillableMetric_aggregate_bool_exp_bool_and: BillableMetric_Aggregate_Bool_Exp_Bool_And;
  BillableMetric_aggregate_bool_exp_bool_or: BillableMetric_Aggregate_Bool_Exp_Bool_Or;
  BillableMetric_aggregate_bool_exp_count: BillableMetric_Aggregate_Bool_Exp_Count;
  BillableMetric_aggregate_fields: BillableMetric_Aggregate_Fields;
  BillableMetric_aggregate_order_by: BillableMetric_Aggregate_Order_By;
  BillableMetric_append_input: BillableMetric_Append_Input;
  BillableMetric_arr_rel_insert_input: BillableMetric_Arr_Rel_Insert_Input;
  BillableMetric_bool_exp: BillableMetric_Bool_Exp;
  BillableMetric_delete_at_path_input: BillableMetric_Delete_At_Path_Input;
  BillableMetric_delete_elem_input: BillableMetric_Delete_Elem_Input;
  BillableMetric_delete_key_input: BillableMetric_Delete_Key_Input;
  BillableMetric_insert_input: BillableMetric_Insert_Input;
  BillableMetric_max_fields: BillableMetric_Max_Fields;
  BillableMetric_max_order_by: BillableMetric_Max_Order_By;
  BillableMetric_min_fields: BillableMetric_Min_Fields;
  BillableMetric_min_order_by: BillableMetric_Min_Order_By;
  BillableMetric_mutation_response: BillableMetric_Mutation_Response;
  BillableMetric_obj_rel_insert_input: BillableMetric_Obj_Rel_Insert_Input;
  BillableMetric_on_conflict: BillableMetric_On_Conflict;
  BillableMetric_order_by: BillableMetric_Order_By;
  BillableMetric_pk_columns_input: BillableMetric_Pk_Columns_Input;
  BillableMetric_prepend_input: BillableMetric_Prepend_Input;
  BillableMetric_set_input: BillableMetric_Set_Input;
  BillableMetric_stream_cursor_input: BillableMetric_Stream_Cursor_Input;
  BillableMetric_stream_cursor_value_input: BillableMetric_Stream_Cursor_Value_Input;
  BillableMetric_updates: BillableMetric_Updates;
  BillableStatusFilter: BillableStatusFilter;
  BillableStatusWithEffectiveDate: BillableStatusWithEffectiveDate;
  BilledInvoiceTotals: BilledInvoiceTotals;
  BillingFrequencyEnum: BillingFrequencyEnum;
  BillingFrequencyEnum_aggregate: BillingFrequencyEnum_Aggregate;
  BillingFrequencyEnum_aggregate_fields: BillingFrequencyEnum_Aggregate_Fields;
  BillingFrequencyEnum_bool_exp: BillingFrequencyEnum_Bool_Exp;
  BillingFrequencyEnum_enum_comparison_exp: BillingFrequencyEnum_Enum_Comparison_Exp;
  BillingFrequencyEnum_insert_input: BillingFrequencyEnum_Insert_Input;
  BillingFrequencyEnum_max_fields: BillingFrequencyEnum_Max_Fields;
  BillingFrequencyEnum_min_fields: BillingFrequencyEnum_Min_Fields;
  BillingFrequencyEnum_mutation_response: BillingFrequencyEnum_Mutation_Response;
  BillingFrequencyEnum_on_conflict: BillingFrequencyEnum_On_Conflict;
  BillingFrequencyEnum_order_by: BillingFrequencyEnum_Order_By;
  BillingFrequencyEnum_pk_columns_input: BillingFrequencyEnum_Pk_Columns_Input;
  BillingFrequencyEnum_set_input: BillingFrequencyEnum_Set_Input;
  BillingFrequencyEnum_stream_cursor_input: BillingFrequencyEnum_Stream_Cursor_Input;
  BillingFrequencyEnum_stream_cursor_value_input: BillingFrequencyEnum_Stream_Cursor_Value_Input;
  BillingFrequencyEnum_updates: BillingFrequencyEnum_Updates;
  BillingMetricAggregateEnum: BillingMetricAggregateEnum;
  BillingMetricAggregateEnum_aggregate: BillingMetricAggregateEnum_Aggregate;
  BillingMetricAggregateEnum_aggregate_fields: BillingMetricAggregateEnum_Aggregate_Fields;
  BillingMetricAggregateEnum_bool_exp: BillingMetricAggregateEnum_Bool_Exp;
  BillingMetricAggregateEnum_enum_comparison_exp: BillingMetricAggregateEnum_Enum_Comparison_Exp;
  BillingMetricAggregateEnum_insert_input: BillingMetricAggregateEnum_Insert_Input;
  BillingMetricAggregateEnum_max_fields: BillingMetricAggregateEnum_Max_Fields;
  BillingMetricAggregateEnum_min_fields: BillingMetricAggregateEnum_Min_Fields;
  BillingMetricAggregateEnum_mutation_response: BillingMetricAggregateEnum_Mutation_Response;
  BillingMetricAggregateEnum_on_conflict: BillingMetricAggregateEnum_On_Conflict;
  BillingMetricAggregateEnum_order_by: BillingMetricAggregateEnum_Order_By;
  BillingMetricAggregateEnum_pk_columns_input: BillingMetricAggregateEnum_Pk_Columns_Input;
  BillingMetricAggregateEnum_set_input: BillingMetricAggregateEnum_Set_Input;
  BillingMetricAggregateEnum_stream_cursor_input: BillingMetricAggregateEnum_Stream_Cursor_Input;
  BillingMetricAggregateEnum_stream_cursor_value_input: BillingMetricAggregateEnum_Stream_Cursor_Value_Input;
  BillingMetricAggregateEnum_updates: BillingMetricAggregateEnum_Updates;
  BillingProviderConfigurationInput: BillingProviderConfigurationInput;
  BillingProviderCustomer: BillingProviderCustomer;
  BillingProviderCustomerInput: BillingProviderCustomerInput;
  BillingProviderCustomer_aggregate: BillingProviderCustomer_Aggregate;
  BillingProviderCustomer_aggregate_bool_exp: BillingProviderCustomer_Aggregate_Bool_Exp;
  BillingProviderCustomer_aggregate_bool_exp_count: BillingProviderCustomer_Aggregate_Bool_Exp_Count;
  BillingProviderCustomer_aggregate_fields: BillingProviderCustomer_Aggregate_Fields;
  BillingProviderCustomer_aggregate_order_by: BillingProviderCustomer_Aggregate_Order_By;
  BillingProviderCustomer_arr_rel_insert_input: BillingProviderCustomer_Arr_Rel_Insert_Input;
  BillingProviderCustomer_bool_exp: BillingProviderCustomer_Bool_Exp;
  BillingProviderCustomer_insert_input: BillingProviderCustomer_Insert_Input;
  BillingProviderCustomer_max_fields: BillingProviderCustomer_Max_Fields;
  BillingProviderCustomer_max_order_by: BillingProviderCustomer_Max_Order_By;
  BillingProviderCustomer_min_fields: BillingProviderCustomer_Min_Fields;
  BillingProviderCustomer_min_order_by: BillingProviderCustomer_Min_Order_By;
  BillingProviderCustomer_mutation_response: BillingProviderCustomer_Mutation_Response;
  BillingProviderCustomer_on_conflict: BillingProviderCustomer_On_Conflict;
  BillingProviderCustomer_order_by: BillingProviderCustomer_Order_By;
  BillingProviderCustomer_pk_columns_input: BillingProviderCustomer_Pk_Columns_Input;
  BillingProviderCustomer_set_input: BillingProviderCustomer_Set_Input;
  BillingProviderCustomer_stream_cursor_input: BillingProviderCustomer_Stream_Cursor_Input;
  BillingProviderCustomer_stream_cursor_value_input: BillingProviderCustomer_Stream_Cursor_Value_Input;
  BillingProviderCustomer_updates: BillingProviderCustomer_Updates;
  BillingProviderDeliveryMethod: BillingProviderDeliveryMethod;
  BillingProviderDeliveryMethodWithoutConfiguration: BillingProviderDeliveryMethodWithoutConfiguration;
  BillingProviderEnum: BillingProviderEnum;
  BillingProviderEnum_aggregate: BillingProviderEnum_Aggregate;
  BillingProviderEnum_aggregate_fields: BillingProviderEnum_Aggregate_Fields;
  BillingProviderEnum_bool_exp: BillingProviderEnum_Bool_Exp;
  BillingProviderEnum_enum_comparison_exp: BillingProviderEnum_Enum_Comparison_Exp;
  BillingProviderEnum_insert_input: BillingProviderEnum_Insert_Input;
  BillingProviderEnum_max_fields: BillingProviderEnum_Max_Fields;
  BillingProviderEnum_min_fields: BillingProviderEnum_Min_Fields;
  BillingProviderEnum_mutation_response: BillingProviderEnum_Mutation_Response;
  BillingProviderEnum_obj_rel_insert_input: BillingProviderEnum_Obj_Rel_Insert_Input;
  BillingProviderEnum_on_conflict: BillingProviderEnum_On_Conflict;
  BillingProviderEnum_order_by: BillingProviderEnum_Order_By;
  BillingProviderEnum_pk_columns_input: BillingProviderEnum_Pk_Columns_Input;
  BillingProviderEnum_set_input: BillingProviderEnum_Set_Input;
  BillingProviderEnum_stream_cursor_input: BillingProviderEnum_Stream_Cursor_Input;
  BillingProviderEnum_stream_cursor_value_input: BillingProviderEnum_Stream_Cursor_Value_Input;
  BillingProviderEnum_updates: BillingProviderEnum_Updates;
  BillingProviderFilter: BillingProviderFilter;
  BillingProviderInvoice: BillingProviderInvoice;
  BillingProviderInvoiceExternalStatusFilter: BillingProviderInvoiceExternalStatusFilter;
  BillingProviderInvoiceUpsertResult: BillingProviderInvoiceUpsertResult;
  BillingProviderInvoice_aggregate: BillingProviderInvoice_Aggregate;
  BillingProviderInvoice_aggregate_fields: BillingProviderInvoice_Aggregate_Fields;
  BillingProviderInvoice_bool_exp: BillingProviderInvoice_Bool_Exp;
  BillingProviderInvoice_insert_input: BillingProviderInvoice_Insert_Input;
  BillingProviderInvoice_max_fields: BillingProviderInvoice_Max_Fields;
  BillingProviderInvoice_min_fields: BillingProviderInvoice_Min_Fields;
  BillingProviderInvoice_mutation_response: BillingProviderInvoice_Mutation_Response;
  BillingProviderInvoice_on_conflict: BillingProviderInvoice_On_Conflict;
  BillingProviderInvoice_order_by: BillingProviderInvoice_Order_By;
  BillingProviderInvoice_pk_columns_input: BillingProviderInvoice_Pk_Columns_Input;
  BillingProviderInvoice_set_input: BillingProviderInvoice_Set_Input;
  BillingProviderInvoice_stream_cursor_input: BillingProviderInvoice_Stream_Cursor_Input;
  BillingProviderInvoice_stream_cursor_value_input: BillingProviderInvoice_Stream_Cursor_Value_Input;
  BillingProviderInvoice_updates: BillingProviderInvoice_Updates;
  BillingProviderToken: BillingProviderToken;
  BillingProviderToken_aggregate: BillingProviderToken_Aggregate;
  BillingProviderToken_aggregate_bool_exp: BillingProviderToken_Aggregate_Bool_Exp;
  BillingProviderToken_aggregate_bool_exp_count: BillingProviderToken_Aggregate_Bool_Exp_Count;
  BillingProviderToken_aggregate_fields: BillingProviderToken_Aggregate_Fields;
  BillingProviderToken_aggregate_order_by: BillingProviderToken_Aggregate_Order_By;
  BillingProviderToken_arr_rel_insert_input: BillingProviderToken_Arr_Rel_Insert_Input;
  BillingProviderToken_bool_exp: BillingProviderToken_Bool_Exp;
  BillingProviderToken_insert_input: BillingProviderToken_Insert_Input;
  BillingProviderToken_max_fields: BillingProviderToken_Max_Fields;
  BillingProviderToken_max_order_by: BillingProviderToken_Max_Order_By;
  BillingProviderToken_min_fields: BillingProviderToken_Min_Fields;
  BillingProviderToken_min_order_by: BillingProviderToken_Min_Order_By;
  BillingProviderToken_mutation_response: BillingProviderToken_Mutation_Response;
  BillingProviderToken_on_conflict: BillingProviderToken_On_Conflict;
  BillingProviderToken_order_by: BillingProviderToken_Order_By;
  BillingProviderToken_pk_columns_input: BillingProviderToken_Pk_Columns_Input;
  BillingProviderToken_set_input: BillingProviderToken_Set_Input;
  BillingProviderToken_stream_cursor_input: BillingProviderToken_Stream_Cursor_Input;
  BillingProviderToken_stream_cursor_value_input: BillingProviderToken_Stream_Cursor_Value_Input;
  BillingProviderToken_updates: BillingProviderToken_Updates;
  BlocksInput: BlocksInput;
  Boolean: Scalars['Boolean'];
  Boolean_comparison_exp: Boolean_Comparison_Exp;
  BreakdownCursor: BreakdownCursor;
  BreakdownItem: BreakdownItem;
  BreakdownTier: BreakdownTier;
  CensusDestinationConnectLink: CensusDestinationConnectLink;
  CensusObjectSyncReport: CensusObjectSyncReport;
  CensusRecordSyncError: CensusRecordSyncError;
  CensusSyncRun: CensusSyncRun;
  CensusWorkspace: CensusWorkspace;
  ChargeLineItem: Omit<ChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversParentTypes['ChargeSubLineItem']>> };
  ChargeManagedField: ChargeManagedField;
  ChargeManagedField_aggregate: ChargeManagedField_Aggregate;
  ChargeManagedField_aggregate_bool_exp: ChargeManagedField_Aggregate_Bool_Exp;
  ChargeManagedField_aggregate_bool_exp_bool_and: ChargeManagedField_Aggregate_Bool_Exp_Bool_And;
  ChargeManagedField_aggregate_bool_exp_bool_or: ChargeManagedField_Aggregate_Bool_Exp_Bool_Or;
  ChargeManagedField_aggregate_bool_exp_count: ChargeManagedField_Aggregate_Bool_Exp_Count;
  ChargeManagedField_aggregate_fields: ChargeManagedField_Aggregate_Fields;
  ChargeManagedField_aggregate_order_by: ChargeManagedField_Aggregate_Order_By;
  ChargeManagedField_arr_rel_insert_input: ChargeManagedField_Arr_Rel_Insert_Input;
  ChargeManagedField_bool_exp: ChargeManagedField_Bool_Exp;
  ChargeManagedField_insert_input: ChargeManagedField_Insert_Input;
  ChargeManagedField_max_fields: ChargeManagedField_Max_Fields;
  ChargeManagedField_max_order_by: ChargeManagedField_Max_Order_By;
  ChargeManagedField_min_fields: ChargeManagedField_Min_Fields;
  ChargeManagedField_min_order_by: ChargeManagedField_Min_Order_By;
  ChargeManagedField_mutation_response: ChargeManagedField_Mutation_Response;
  ChargeManagedField_on_conflict: ChargeManagedField_On_Conflict;
  ChargeManagedField_order_by: ChargeManagedField_Order_By;
  ChargeManagedField_pk_columns_input: ChargeManagedField_Pk_Columns_Input;
  ChargeManagedField_set_input: ChargeManagedField_Set_Input;
  ChargeManagedField_stream_cursor_input: ChargeManagedField_Stream_Cursor_Input;
  ChargeManagedField_stream_cursor_value_input: ChargeManagedField_Stream_Cursor_Value_Input;
  ChargeManagedField_updates: ChargeManagedField_Updates;
  ChargeSubLineItem: ResolversParentTypes['GroupedChargeLineItem'] | ResolversParentTypes['TierChargeLineItem'];
  ChargeTypeEnum: ChargeTypeEnum;
  ChargeTypeEnum_aggregate: ChargeTypeEnum_Aggregate;
  ChargeTypeEnum_aggregate_fields: ChargeTypeEnum_Aggregate_Fields;
  ChargeTypeEnum_bool_exp: ChargeTypeEnum_Bool_Exp;
  ChargeTypeEnum_enum_comparison_exp: ChargeTypeEnum_Enum_Comparison_Exp;
  ChargeTypeEnum_insert_input: ChargeTypeEnum_Insert_Input;
  ChargeTypeEnum_max_fields: ChargeTypeEnum_Max_Fields;
  ChargeTypeEnum_min_fields: ChargeTypeEnum_Min_Fields;
  ChargeTypeEnum_mutation_response: ChargeTypeEnum_Mutation_Response;
  ChargeTypeEnum_obj_rel_insert_input: ChargeTypeEnum_Obj_Rel_Insert_Input;
  ChargeTypeEnum_on_conflict: ChargeTypeEnum_On_Conflict;
  ChargeTypeEnum_order_by: ChargeTypeEnum_Order_By;
  ChargeTypeEnum_pk_columns_input: ChargeTypeEnum_Pk_Columns_Input;
  ChargeTypeEnum_set_input: ChargeTypeEnum_Set_Input;
  ChargeTypeEnum_stream_cursor_input: ChargeTypeEnum_Stream_Cursor_Input;
  ChargeTypeEnum_stream_cursor_value_input: ChargeTypeEnum_Stream_Cursor_Value_Input;
  ChargeTypeEnum_updates: ChargeTypeEnum_Updates;
  Client: Client;
  ClientConfig: ClientConfig;
  ClientConfigKeyEnum: ClientConfigKeyEnum;
  ClientConfigKeyEnum_aggregate: ClientConfigKeyEnum_Aggregate;
  ClientConfigKeyEnum_aggregate_fields: ClientConfigKeyEnum_Aggregate_Fields;
  ClientConfigKeyEnum_bool_exp: ClientConfigKeyEnum_Bool_Exp;
  ClientConfigKeyEnum_enum_comparison_exp: ClientConfigKeyEnum_Enum_Comparison_Exp;
  ClientConfigKeyEnum_insert_input: ClientConfigKeyEnum_Insert_Input;
  ClientConfigKeyEnum_max_fields: ClientConfigKeyEnum_Max_Fields;
  ClientConfigKeyEnum_min_fields: ClientConfigKeyEnum_Min_Fields;
  ClientConfigKeyEnum_mutation_response: ClientConfigKeyEnum_Mutation_Response;
  ClientConfigKeyEnum_on_conflict: ClientConfigKeyEnum_On_Conflict;
  ClientConfigKeyEnum_order_by: ClientConfigKeyEnum_Order_By;
  ClientConfigKeyEnum_pk_columns_input: ClientConfigKeyEnum_Pk_Columns_Input;
  ClientConfigKeyEnum_set_input: ClientConfigKeyEnum_Set_Input;
  ClientConfigKeyEnum_stream_cursor_input: ClientConfigKeyEnum_Stream_Cursor_Input;
  ClientConfigKeyEnum_stream_cursor_value_input: ClientConfigKeyEnum_Stream_Cursor_Value_Input;
  ClientConfigKeyEnum_updates: ClientConfigKeyEnum_Updates;
  ClientConfig_aggregate: ClientConfig_Aggregate;
  ClientConfig_aggregate_bool_exp: ClientConfig_Aggregate_Bool_Exp;
  ClientConfig_aggregate_bool_exp_count: ClientConfig_Aggregate_Bool_Exp_Count;
  ClientConfig_aggregate_fields: ClientConfig_Aggregate_Fields;
  ClientConfig_aggregate_order_by: ClientConfig_Aggregate_Order_By;
  ClientConfig_arr_rel_insert_input: ClientConfig_Arr_Rel_Insert_Input;
  ClientConfig_bool_exp: ClientConfig_Bool_Exp;
  ClientConfig_insert_input: ClientConfig_Insert_Input;
  ClientConfig_max_fields: ClientConfig_Max_Fields;
  ClientConfig_max_order_by: ClientConfig_Max_Order_By;
  ClientConfig_min_fields: ClientConfig_Min_Fields;
  ClientConfig_min_order_by: ClientConfig_Min_Order_By;
  ClientConfig_mutation_response: ClientConfig_Mutation_Response;
  ClientConfig_on_conflict: ClientConfig_On_Conflict;
  ClientConfig_order_by: ClientConfig_Order_By;
  ClientConfig_pk_columns_input: ClientConfig_Pk_Columns_Input;
  ClientConfig_set_input: ClientConfig_Set_Input;
  ClientConfig_stream_cursor_input: ClientConfig_Stream_Cursor_Input;
  ClientConfig_stream_cursor_value_input: ClientConfig_Stream_Cursor_Value_Input;
  ClientConfig_updates: ClientConfig_Updates;
  ClientDashboardMetrics: ClientDashboardMetrics;
  ClientOverview: ClientOverview;
  ClientOverview_Chart: ClientOverview_Chart;
  ClientOverview_ChartDatum: ClientOverview_ChartDatum;
  ClientOverview_Spend: ClientOverview_Spend;
  ClientOverview_TopSpender: ClientOverview_TopSpender;
  ClientOverview_TopSpenders: ClientOverview_TopSpenders;
  ClientUsingContracts: ClientUsingContracts;
  ClientUsingPrepaidCredits: ClientUsingPrepaidCredits;
  Client_aggregate: Client_Aggregate;
  Client_aggregate_fields: Client_Aggregate_Fields;
  Client_bool_exp: Client_Bool_Exp;
  Client_insert_input: Client_Insert_Input;
  Client_max_fields: Client_Max_Fields;
  Client_min_fields: Client_Min_Fields;
  Client_mutation_response: Client_Mutation_Response;
  Client_obj_rel_insert_input: Client_Obj_Rel_Insert_Input;
  Client_on_conflict: Client_On_Conflict;
  Client_order_by: Client_Order_By;
  Client_pk_columns_input: Client_Pk_Columns_Input;
  Client_set_input: Client_Set_Input;
  Client_stream_cursor_input: Client_Stream_Cursor_Input;
  Client_stream_cursor_value_input: Client_Stream_Cursor_Value_Input;
  Client_updates: Client_Updates;
  ClientsUsingContracts: ClientsUsingContracts;
  ClientsUsingPrepaidCredits: ClientsUsingPrepaidCredits;
  CollectionScheduleEnum: CollectionScheduleEnum;
  CollectionScheduleEnum_aggregate: CollectionScheduleEnum_Aggregate;
  CollectionScheduleEnum_aggregate_fields: CollectionScheduleEnum_Aggregate_Fields;
  CollectionScheduleEnum_bool_exp: CollectionScheduleEnum_Bool_Exp;
  CollectionScheduleEnum_enum_comparison_exp: CollectionScheduleEnum_Enum_Comparison_Exp;
  CollectionScheduleEnum_insert_input: CollectionScheduleEnum_Insert_Input;
  CollectionScheduleEnum_max_fields: CollectionScheduleEnum_Max_Fields;
  CollectionScheduleEnum_min_fields: CollectionScheduleEnum_Min_Fields;
  CollectionScheduleEnum_mutation_response: CollectionScheduleEnum_Mutation_Response;
  CollectionScheduleEnum_obj_rel_insert_input: CollectionScheduleEnum_Obj_Rel_Insert_Input;
  CollectionScheduleEnum_on_conflict: CollectionScheduleEnum_On_Conflict;
  CollectionScheduleEnum_order_by: CollectionScheduleEnum_Order_By;
  CollectionScheduleEnum_pk_columns_input: CollectionScheduleEnum_Pk_Columns_Input;
  CollectionScheduleEnum_set_input: CollectionScheduleEnum_Set_Input;
  CollectionScheduleEnum_stream_cursor_input: CollectionScheduleEnum_Stream_Cursor_Input;
  CollectionScheduleEnum_stream_cursor_value_input: CollectionScheduleEnum_Stream_Cursor_Value_Input;
  CollectionScheduleEnum_updates: CollectionScheduleEnum_Updates;
  Commit: Omit<Commit, 'applicable_products' | 'product'> & { applicable_products: Maybe<Array<ResolversParentTypes['ProductListItem']>>, product: ResolversParentTypes['ProductListItem'] };
  CommitAccessSchedule: CommitAccessSchedule;
  CommitAccessScheduleInput: CommitAccessScheduleInput;
  CommitInput: CommitInput;
  CommitInvoiceSchedule: CommitInvoiceSchedule;
  CommitInvoiceScheduleInput: CommitInvoiceScheduleInput;
  CommitInvoiceScheduleItem: CommitInvoiceScheduleItem;
  CommitInvoiceScheduleItemInput: CommitInvoiceScheduleItemInput;
  CommitInvoiceScheduleItemUpdateInput: CommitInvoiceScheduleItemUpdateInput;
  CommitManagedField: CommitManagedField;
  CommitManagedField_aggregate: CommitManagedField_Aggregate;
  CommitManagedField_aggregate_bool_exp: CommitManagedField_Aggregate_Bool_Exp;
  CommitManagedField_aggregate_bool_exp_bool_and: CommitManagedField_Aggregate_Bool_Exp_Bool_And;
  CommitManagedField_aggregate_bool_exp_bool_or: CommitManagedField_Aggregate_Bool_Exp_Bool_Or;
  CommitManagedField_aggregate_bool_exp_count: CommitManagedField_Aggregate_Bool_Exp_Count;
  CommitManagedField_aggregate_fields: CommitManagedField_Aggregate_Fields;
  CommitManagedField_aggregate_order_by: CommitManagedField_Aggregate_Order_By;
  CommitManagedField_arr_rel_insert_input: CommitManagedField_Arr_Rel_Insert_Input;
  CommitManagedField_bool_exp: CommitManagedField_Bool_Exp;
  CommitManagedField_insert_input: CommitManagedField_Insert_Input;
  CommitManagedField_max_fields: CommitManagedField_Max_Fields;
  CommitManagedField_max_order_by: CommitManagedField_Max_Order_By;
  CommitManagedField_min_fields: CommitManagedField_Min_Fields;
  CommitManagedField_min_order_by: CommitManagedField_Min_Order_By;
  CommitManagedField_mutation_response: CommitManagedField_Mutation_Response;
  CommitManagedField_on_conflict: CommitManagedField_On_Conflict;
  CommitManagedField_order_by: CommitManagedField_Order_By;
  CommitManagedField_pk_columns_input: CommitManagedField_Pk_Columns_Input;
  CommitManagedField_set_input: CommitManagedField_Set_Input;
  CommitManagedField_stream_cursor_input: CommitManagedField_Stream_Cursor_Input;
  CommitManagedField_stream_cursor_value_input: CommitManagedField_Stream_Cursor_Value_Input;
  CommitManagedField_updates: CommitManagedField_Updates;
  CommitOrCreditManagedField: CommitOrCreditManagedField;
  CommitScheduleAccessUpdateInput: CommitScheduleAccessUpdateInput;
  CommitScheduleInvoiceUpdateInput: CommitScheduleInvoiceUpdateInput;
  CommitScheduleUpdateInput: CommitScheduleUpdateInput;
  CommitSegmentScheduleItem: CommitSegmentScheduleItem;
  CommitSegmentScheduleItemInput: CommitSegmentScheduleItemInput;
  CommitSegmentScheduleItemUpdateInput: CommitSegmentScheduleItemUpdateInput;
  CommitUnion: ResolversParentTypes['PostpaidCommit'] | ResolversParentTypes['PrepaidCommit'];
  CommitWithSegment: Omit<CommitWithSegment, 'commit_union'> & { commit_union: ResolversParentTypes['CommitUnion'] };
  CompositeCharge: CompositeCharge;
  CompositeChargeAdjustment: CompositeChargeAdjustment;
  CompositeChargeAdjustment_aggregate: CompositeChargeAdjustment_Aggregate;
  CompositeChargeAdjustment_aggregate_bool_exp: CompositeChargeAdjustment_Aggregate_Bool_Exp;
  CompositeChargeAdjustment_aggregate_bool_exp_count: CompositeChargeAdjustment_Aggregate_Bool_Exp_Count;
  CompositeChargeAdjustment_aggregate_fields: CompositeChargeAdjustment_Aggregate_Fields;
  CompositeChargeAdjustment_aggregate_order_by: CompositeChargeAdjustment_Aggregate_Order_By;
  CompositeChargeAdjustment_arr_rel_insert_input: CompositeChargeAdjustment_Arr_Rel_Insert_Input;
  CompositeChargeAdjustment_avg_fields: CompositeChargeAdjustment_Avg_Fields;
  CompositeChargeAdjustment_avg_order_by: CompositeChargeAdjustment_Avg_Order_By;
  CompositeChargeAdjustment_bool_exp: CompositeChargeAdjustment_Bool_Exp;
  CompositeChargeAdjustment_inc_input: CompositeChargeAdjustment_Inc_Input;
  CompositeChargeAdjustment_insert_input: CompositeChargeAdjustment_Insert_Input;
  CompositeChargeAdjustment_max_fields: CompositeChargeAdjustment_Max_Fields;
  CompositeChargeAdjustment_max_order_by: CompositeChargeAdjustment_Max_Order_By;
  CompositeChargeAdjustment_min_fields: CompositeChargeAdjustment_Min_Fields;
  CompositeChargeAdjustment_min_order_by: CompositeChargeAdjustment_Min_Order_By;
  CompositeChargeAdjustment_mutation_response: CompositeChargeAdjustment_Mutation_Response;
  CompositeChargeAdjustment_obj_rel_insert_input: CompositeChargeAdjustment_Obj_Rel_Insert_Input;
  CompositeChargeAdjustment_on_conflict: CompositeChargeAdjustment_On_Conflict;
  CompositeChargeAdjustment_order_by: CompositeChargeAdjustment_Order_By;
  CompositeChargeAdjustment_pk_columns_input: CompositeChargeAdjustment_Pk_Columns_Input;
  CompositeChargeAdjustment_set_input: CompositeChargeAdjustment_Set_Input;
  CompositeChargeAdjustment_stddev_fields: CompositeChargeAdjustment_Stddev_Fields;
  CompositeChargeAdjustment_stddev_order_by: CompositeChargeAdjustment_Stddev_Order_By;
  CompositeChargeAdjustment_stddev_pop_fields: CompositeChargeAdjustment_Stddev_Pop_Fields;
  CompositeChargeAdjustment_stddev_pop_order_by: CompositeChargeAdjustment_Stddev_Pop_Order_By;
  CompositeChargeAdjustment_stddev_samp_fields: CompositeChargeAdjustment_Stddev_Samp_Fields;
  CompositeChargeAdjustment_stddev_samp_order_by: CompositeChargeAdjustment_Stddev_Samp_Order_By;
  CompositeChargeAdjustment_stream_cursor_input: CompositeChargeAdjustment_Stream_Cursor_Input;
  CompositeChargeAdjustment_stream_cursor_value_input: CompositeChargeAdjustment_Stream_Cursor_Value_Input;
  CompositeChargeAdjustment_sum_fields: CompositeChargeAdjustment_Sum_Fields;
  CompositeChargeAdjustment_sum_order_by: CompositeChargeAdjustment_Sum_Order_By;
  CompositeChargeAdjustment_updates: CompositeChargeAdjustment_Updates;
  CompositeChargeAdjustment_var_pop_fields: CompositeChargeAdjustment_Var_Pop_Fields;
  CompositeChargeAdjustment_var_pop_order_by: CompositeChargeAdjustment_Var_Pop_Order_By;
  CompositeChargeAdjustment_var_samp_fields: CompositeChargeAdjustment_Var_Samp_Fields;
  CompositeChargeAdjustment_var_samp_order_by: CompositeChargeAdjustment_Var_Samp_Order_By;
  CompositeChargeAdjustment_variance_fields: CompositeChargeAdjustment_Variance_Fields;
  CompositeChargeAdjustment_variance_order_by: CompositeChargeAdjustment_Variance_Order_By;
  CompositeChargeLineItem: Omit<CompositeChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversParentTypes['TierSubLineItem']>> };
  CompositeChargePricingFactor: CompositeChargePricingFactor;
  CompositeChargePricingFactor_aggregate: CompositeChargePricingFactor_Aggregate;
  CompositeChargePricingFactor_aggregate_bool_exp: CompositeChargePricingFactor_Aggregate_Bool_Exp;
  CompositeChargePricingFactor_aggregate_bool_exp_count: CompositeChargePricingFactor_Aggregate_Bool_Exp_Count;
  CompositeChargePricingFactor_aggregate_fields: CompositeChargePricingFactor_Aggregate_Fields;
  CompositeChargePricingFactor_aggregate_order_by: CompositeChargePricingFactor_Aggregate_Order_By;
  CompositeChargePricingFactor_arr_rel_insert_input: CompositeChargePricingFactor_Arr_Rel_Insert_Input;
  CompositeChargePricingFactor_bool_exp: CompositeChargePricingFactor_Bool_Exp;
  CompositeChargePricingFactor_insert_input: CompositeChargePricingFactor_Insert_Input;
  CompositeChargePricingFactor_max_fields: CompositeChargePricingFactor_Max_Fields;
  CompositeChargePricingFactor_max_order_by: CompositeChargePricingFactor_Max_Order_By;
  CompositeChargePricingFactor_min_fields: CompositeChargePricingFactor_Min_Fields;
  CompositeChargePricingFactor_min_order_by: CompositeChargePricingFactor_Min_Order_By;
  CompositeChargePricingFactor_mutation_response: CompositeChargePricingFactor_Mutation_Response;
  CompositeChargePricingFactor_order_by: CompositeChargePricingFactor_Order_By;
  CompositeChargePricingFactor_set_input: CompositeChargePricingFactor_Set_Input;
  CompositeChargePricingFactor_stream_cursor_input: CompositeChargePricingFactor_Stream_Cursor_Input;
  CompositeChargePricingFactor_stream_cursor_value_input: CompositeChargePricingFactor_Stream_Cursor_Value_Input;
  CompositeChargePricingFactor_updates: CompositeChargePricingFactor_Updates;
  CompositeChargeTier: CompositeChargeTier;
  CompositeChargeTierAdjustment: CompositeChargeTierAdjustment;
  CompositeChargeTierAdjustment_aggregate: CompositeChargeTierAdjustment_Aggregate;
  CompositeChargeTierAdjustment_aggregate_bool_exp: CompositeChargeTierAdjustment_Aggregate_Bool_Exp;
  CompositeChargeTierAdjustment_aggregate_bool_exp_count: CompositeChargeTierAdjustment_Aggregate_Bool_Exp_Count;
  CompositeChargeTierAdjustment_aggregate_fields: CompositeChargeTierAdjustment_Aggregate_Fields;
  CompositeChargeTierAdjustment_aggregate_order_by: CompositeChargeTierAdjustment_Aggregate_Order_By;
  CompositeChargeTierAdjustment_arr_rel_insert_input: CompositeChargeTierAdjustment_Arr_Rel_Insert_Input;
  CompositeChargeTierAdjustment_avg_fields: CompositeChargeTierAdjustment_Avg_Fields;
  CompositeChargeTierAdjustment_avg_order_by: CompositeChargeTierAdjustment_Avg_Order_By;
  CompositeChargeTierAdjustment_bool_exp: CompositeChargeTierAdjustment_Bool_Exp;
  CompositeChargeTierAdjustment_inc_input: CompositeChargeTierAdjustment_Inc_Input;
  CompositeChargeTierAdjustment_insert_input: CompositeChargeTierAdjustment_Insert_Input;
  CompositeChargeTierAdjustment_max_fields: CompositeChargeTierAdjustment_Max_Fields;
  CompositeChargeTierAdjustment_max_order_by: CompositeChargeTierAdjustment_Max_Order_By;
  CompositeChargeTierAdjustment_min_fields: CompositeChargeTierAdjustment_Min_Fields;
  CompositeChargeTierAdjustment_min_order_by: CompositeChargeTierAdjustment_Min_Order_By;
  CompositeChargeTierAdjustment_mutation_response: CompositeChargeTierAdjustment_Mutation_Response;
  CompositeChargeTierAdjustment_on_conflict: CompositeChargeTierAdjustment_On_Conflict;
  CompositeChargeTierAdjustment_order_by: CompositeChargeTierAdjustment_Order_By;
  CompositeChargeTierAdjustment_pk_columns_input: CompositeChargeTierAdjustment_Pk_Columns_Input;
  CompositeChargeTierAdjustment_set_input: CompositeChargeTierAdjustment_Set_Input;
  CompositeChargeTierAdjustment_stddev_fields: CompositeChargeTierAdjustment_Stddev_Fields;
  CompositeChargeTierAdjustment_stddev_order_by: CompositeChargeTierAdjustment_Stddev_Order_By;
  CompositeChargeTierAdjustment_stddev_pop_fields: CompositeChargeTierAdjustment_Stddev_Pop_Fields;
  CompositeChargeTierAdjustment_stddev_pop_order_by: CompositeChargeTierAdjustment_Stddev_Pop_Order_By;
  CompositeChargeTierAdjustment_stddev_samp_fields: CompositeChargeTierAdjustment_Stddev_Samp_Fields;
  CompositeChargeTierAdjustment_stddev_samp_order_by: CompositeChargeTierAdjustment_Stddev_Samp_Order_By;
  CompositeChargeTierAdjustment_stream_cursor_input: CompositeChargeTierAdjustment_Stream_Cursor_Input;
  CompositeChargeTierAdjustment_stream_cursor_value_input: CompositeChargeTierAdjustment_Stream_Cursor_Value_Input;
  CompositeChargeTierAdjustment_sum_fields: CompositeChargeTierAdjustment_Sum_Fields;
  CompositeChargeTierAdjustment_sum_order_by: CompositeChargeTierAdjustment_Sum_Order_By;
  CompositeChargeTierAdjustment_updates: CompositeChargeTierAdjustment_Updates;
  CompositeChargeTierAdjustment_var_pop_fields: CompositeChargeTierAdjustment_Var_Pop_Fields;
  CompositeChargeTierAdjustment_var_pop_order_by: CompositeChargeTierAdjustment_Var_Pop_Order_By;
  CompositeChargeTierAdjustment_var_samp_fields: CompositeChargeTierAdjustment_Var_Samp_Fields;
  CompositeChargeTierAdjustment_var_samp_order_by: CompositeChargeTierAdjustment_Var_Samp_Order_By;
  CompositeChargeTierAdjustment_variance_fields: CompositeChargeTierAdjustment_Variance_Fields;
  CompositeChargeTierAdjustment_variance_order_by: CompositeChargeTierAdjustment_Variance_Order_By;
  CompositeChargeTier_aggregate: CompositeChargeTier_Aggregate;
  CompositeChargeTier_aggregate_bool_exp: CompositeChargeTier_Aggregate_Bool_Exp;
  CompositeChargeTier_aggregate_bool_exp_count: CompositeChargeTier_Aggregate_Bool_Exp_Count;
  CompositeChargeTier_aggregate_fields: CompositeChargeTier_Aggregate_Fields;
  CompositeChargeTier_aggregate_order_by: CompositeChargeTier_Aggregate_Order_By;
  CompositeChargeTier_arr_rel_insert_input: CompositeChargeTier_Arr_Rel_Insert_Input;
  CompositeChargeTier_avg_fields: CompositeChargeTier_Avg_Fields;
  CompositeChargeTier_avg_order_by: CompositeChargeTier_Avg_Order_By;
  CompositeChargeTier_bool_exp: CompositeChargeTier_Bool_Exp;
  CompositeChargeTier_inc_input: CompositeChargeTier_Inc_Input;
  CompositeChargeTier_insert_input: CompositeChargeTier_Insert_Input;
  CompositeChargeTier_max_fields: CompositeChargeTier_Max_Fields;
  CompositeChargeTier_max_order_by: CompositeChargeTier_Max_Order_By;
  CompositeChargeTier_min_fields: CompositeChargeTier_Min_Fields;
  CompositeChargeTier_min_order_by: CompositeChargeTier_Min_Order_By;
  CompositeChargeTier_mutation_response: CompositeChargeTier_Mutation_Response;
  CompositeChargeTier_on_conflict: CompositeChargeTier_On_Conflict;
  CompositeChargeTier_order_by: CompositeChargeTier_Order_By;
  CompositeChargeTier_pk_columns_input: CompositeChargeTier_Pk_Columns_Input;
  CompositeChargeTier_set_input: CompositeChargeTier_Set_Input;
  CompositeChargeTier_stddev_fields: CompositeChargeTier_Stddev_Fields;
  CompositeChargeTier_stddev_order_by: CompositeChargeTier_Stddev_Order_By;
  CompositeChargeTier_stddev_pop_fields: CompositeChargeTier_Stddev_Pop_Fields;
  CompositeChargeTier_stddev_pop_order_by: CompositeChargeTier_Stddev_Pop_Order_By;
  CompositeChargeTier_stddev_samp_fields: CompositeChargeTier_Stddev_Samp_Fields;
  CompositeChargeTier_stddev_samp_order_by: CompositeChargeTier_Stddev_Samp_Order_By;
  CompositeChargeTier_stream_cursor_input: CompositeChargeTier_Stream_Cursor_Input;
  CompositeChargeTier_stream_cursor_value_input: CompositeChargeTier_Stream_Cursor_Value_Input;
  CompositeChargeTier_sum_fields: CompositeChargeTier_Sum_Fields;
  CompositeChargeTier_sum_order_by: CompositeChargeTier_Sum_Order_By;
  CompositeChargeTier_updates: CompositeChargeTier_Updates;
  CompositeChargeTier_var_pop_fields: CompositeChargeTier_Var_Pop_Fields;
  CompositeChargeTier_var_pop_order_by: CompositeChargeTier_Var_Pop_Order_By;
  CompositeChargeTier_var_samp_fields: CompositeChargeTier_Var_Samp_Fields;
  CompositeChargeTier_var_samp_order_by: CompositeChargeTier_Var_Samp_Order_By;
  CompositeChargeTier_variance_fields: CompositeChargeTier_Variance_Fields;
  CompositeChargeTier_variance_order_by: CompositeChargeTier_Variance_Order_By;
  CompositeChargeTypeEnum: CompositeChargeTypeEnum;
  CompositeChargeTypeEnum_aggregate: CompositeChargeTypeEnum_Aggregate;
  CompositeChargeTypeEnum_aggregate_fields: CompositeChargeTypeEnum_Aggregate_Fields;
  CompositeChargeTypeEnum_bool_exp: CompositeChargeTypeEnum_Bool_Exp;
  CompositeChargeTypeEnum_enum_comparison_exp: CompositeChargeTypeEnum_Enum_Comparison_Exp;
  CompositeChargeTypeEnum_insert_input: CompositeChargeTypeEnum_Insert_Input;
  CompositeChargeTypeEnum_max_fields: CompositeChargeTypeEnum_Max_Fields;
  CompositeChargeTypeEnum_min_fields: CompositeChargeTypeEnum_Min_Fields;
  CompositeChargeTypeEnum_mutation_response: CompositeChargeTypeEnum_Mutation_Response;
  CompositeChargeTypeEnum_on_conflict: CompositeChargeTypeEnum_On_Conflict;
  CompositeChargeTypeEnum_order_by: CompositeChargeTypeEnum_Order_By;
  CompositeChargeTypeEnum_pk_columns_input: CompositeChargeTypeEnum_Pk_Columns_Input;
  CompositeChargeTypeEnum_set_input: CompositeChargeTypeEnum_Set_Input;
  CompositeChargeTypeEnum_stream_cursor_input: CompositeChargeTypeEnum_Stream_Cursor_Input;
  CompositeChargeTypeEnum_stream_cursor_value_input: CompositeChargeTypeEnum_Stream_Cursor_Value_Input;
  CompositeChargeTypeEnum_updates: CompositeChargeTypeEnum_Updates;
  CompositeCharge_aggregate: CompositeCharge_Aggregate;
  CompositeCharge_aggregate_bool_exp: CompositeCharge_Aggregate_Bool_Exp;
  CompositeCharge_aggregate_bool_exp_count: CompositeCharge_Aggregate_Bool_Exp_Count;
  CompositeCharge_aggregate_fields: CompositeCharge_Aggregate_Fields;
  CompositeCharge_aggregate_order_by: CompositeCharge_Aggregate_Order_By;
  CompositeCharge_arr_rel_insert_input: CompositeCharge_Arr_Rel_Insert_Input;
  CompositeCharge_avg_fields: CompositeCharge_Avg_Fields;
  CompositeCharge_avg_order_by: CompositeCharge_Avg_Order_By;
  CompositeCharge_bool_exp: CompositeCharge_Bool_Exp;
  CompositeCharge_inc_input: CompositeCharge_Inc_Input;
  CompositeCharge_insert_input: CompositeCharge_Insert_Input;
  CompositeCharge_max_fields: CompositeCharge_Max_Fields;
  CompositeCharge_max_order_by: CompositeCharge_Max_Order_By;
  CompositeCharge_min_fields: CompositeCharge_Min_Fields;
  CompositeCharge_min_order_by: CompositeCharge_Min_Order_By;
  CompositeCharge_mutation_response: CompositeCharge_Mutation_Response;
  CompositeCharge_obj_rel_insert_input: CompositeCharge_Obj_Rel_Insert_Input;
  CompositeCharge_on_conflict: CompositeCharge_On_Conflict;
  CompositeCharge_order_by: CompositeCharge_Order_By;
  CompositeCharge_pk_columns_input: CompositeCharge_Pk_Columns_Input;
  CompositeCharge_set_input: CompositeCharge_Set_Input;
  CompositeCharge_stddev_fields: CompositeCharge_Stddev_Fields;
  CompositeCharge_stddev_order_by: CompositeCharge_Stddev_Order_By;
  CompositeCharge_stddev_pop_fields: CompositeCharge_Stddev_Pop_Fields;
  CompositeCharge_stddev_pop_order_by: CompositeCharge_Stddev_Pop_Order_By;
  CompositeCharge_stddev_samp_fields: CompositeCharge_Stddev_Samp_Fields;
  CompositeCharge_stddev_samp_order_by: CompositeCharge_Stddev_Samp_Order_By;
  CompositeCharge_stream_cursor_input: CompositeCharge_Stream_Cursor_Input;
  CompositeCharge_stream_cursor_value_input: CompositeCharge_Stream_Cursor_Value_Input;
  CompositeCharge_sum_fields: CompositeCharge_Sum_Fields;
  CompositeCharge_sum_order_by: CompositeCharge_Sum_Order_By;
  CompositeCharge_updates: CompositeCharge_Updates;
  CompositeCharge_var_pop_fields: CompositeCharge_Var_Pop_Fields;
  CompositeCharge_var_pop_order_by: CompositeCharge_Var_Pop_Order_By;
  CompositeCharge_var_samp_fields: CompositeCharge_Var_Samp_Fields;
  CompositeCharge_var_samp_order_by: CompositeCharge_Var_Samp_Order_By;
  CompositeCharge_variance_fields: CompositeCharge_Variance_Fields;
  CompositeCharge_variance_order_by: CompositeCharge_Variance_Order_By;
  CompositeProductListItem: CompositeProductListItem;
  CompositeProductListItemState: Omit<CompositeProductListItemState, 'composite_products'> & { composite_products: Maybe<Array<ResolversParentTypes['NonCompositeProductListItem']>> };
  Contract: Omit<Contract, 'commits_union' | 'reseller_royalties'> & { commits_union: Array<ResolversParentTypes['CommitUnion']>, reseller_royalties: Array<ResolversParentTypes['ResellerRoyalty']> };
  ContractAWSProServiceRoyaltyLineItem: ContractAwsProServiceRoyaltyLineItem;
  ContractAWSRoyaltyLineItem: ContractAwsRoyaltyLineItem;
  ContractAmendment: Omit<ContractAmendment, 'commits_union' | 'reseller_royalties'> & { commits_union: Array<ResolversParentTypes['CommitUnion']>, reseller_royalties: Array<ResolversParentTypes['ResellerRoyaltyOrUpdate']> };
  ContractAppliedCommitLineItem: Omit<ContractAppliedCommitLineItem, 'product'> & { product: ResolversParentTypes['ContractUsageLineItemProductListItem'] };
  ContractChargeLineItem: Omit<ContractChargeLineItem, 'product'> & { product: ResolversParentTypes['FixedOrProServiceProductListItem'] };
  ContractCommitLineItem: Omit<ContractCommitLineItem, 'commit_union'> & { commit_union: ResolversParentTypes['CommitUnion'] };
  ContractCommitMappingInput: ContractCommitMappingInput;
  ContractConsistency: ContractConsistency;
  ContractConsistency_OutdatedBillingProviderProjection: ContractConsistency_OutdatedBillingProviderProjection;
  ContractConsistency_OutdatedRate: ContractConsistency_OutdatedRate;
  ContractConversionLineItem: Omit<ContractConversionLineItem, 'product'> & { product: ResolversParentTypes['ContractUsageLineItemProductListItem'] };
  ContractCreditManagedField: ContractCreditManagedField;
  ContractCreditManagedField_aggregate: ContractCreditManagedField_Aggregate;
  ContractCreditManagedField_aggregate_bool_exp: ContractCreditManagedField_Aggregate_Bool_Exp;
  ContractCreditManagedField_aggregate_bool_exp_bool_and: ContractCreditManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractCreditManagedField_aggregate_bool_exp_bool_or: ContractCreditManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractCreditManagedField_aggregate_bool_exp_count: ContractCreditManagedField_Aggregate_Bool_Exp_Count;
  ContractCreditManagedField_aggregate_fields: ContractCreditManagedField_Aggregate_Fields;
  ContractCreditManagedField_aggregate_order_by: ContractCreditManagedField_Aggregate_Order_By;
  ContractCreditManagedField_arr_rel_insert_input: ContractCreditManagedField_Arr_Rel_Insert_Input;
  ContractCreditManagedField_bool_exp: ContractCreditManagedField_Bool_Exp;
  ContractCreditManagedField_insert_input: ContractCreditManagedField_Insert_Input;
  ContractCreditManagedField_max_fields: ContractCreditManagedField_Max_Fields;
  ContractCreditManagedField_max_order_by: ContractCreditManagedField_Max_Order_By;
  ContractCreditManagedField_min_fields: ContractCreditManagedField_Min_Fields;
  ContractCreditManagedField_min_order_by: ContractCreditManagedField_Min_Order_By;
  ContractCreditManagedField_mutation_response: ContractCreditManagedField_Mutation_Response;
  ContractCreditManagedField_on_conflict: ContractCreditManagedField_On_Conflict;
  ContractCreditManagedField_order_by: ContractCreditManagedField_Order_By;
  ContractCreditManagedField_pk_columns_input: ContractCreditManagedField_Pk_Columns_Input;
  ContractCreditManagedField_set_input: ContractCreditManagedField_Set_Input;
  ContractCreditManagedField_stream_cursor_input: ContractCreditManagedField_Stream_Cursor_Input;
  ContractCreditManagedField_stream_cursor_value_input: ContractCreditManagedField_Stream_Cursor_Value_Input;
  ContractCreditManagedField_updates: ContractCreditManagedField_Updates;
  ContractDiscountLineItem: ContractDiscountLineItem;
  ContractGCPProServiceRoyaltyLineItem: ContractGcpProServiceRoyaltyLineItem;
  ContractGCPRoyaltyLineItem: ContractGcpRoyaltyLineItem;
  ContractManagedField: ContractManagedField;
  ContractManagedField_aggregate: ContractManagedField_Aggregate;
  ContractManagedField_aggregate_bool_exp: ContractManagedField_Aggregate_Bool_Exp;
  ContractManagedField_aggregate_bool_exp_bool_and: ContractManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractManagedField_aggregate_bool_exp_bool_or: ContractManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractManagedField_aggregate_bool_exp_count: ContractManagedField_Aggregate_Bool_Exp_Count;
  ContractManagedField_aggregate_fields: ContractManagedField_Aggregate_Fields;
  ContractManagedField_aggregate_order_by: ContractManagedField_Aggregate_Order_By;
  ContractManagedField_arr_rel_insert_input: ContractManagedField_Arr_Rel_Insert_Input;
  ContractManagedField_bool_exp: ContractManagedField_Bool_Exp;
  ContractManagedField_insert_input: ContractManagedField_Insert_Input;
  ContractManagedField_max_fields: ContractManagedField_Max_Fields;
  ContractManagedField_max_order_by: ContractManagedField_Max_Order_By;
  ContractManagedField_min_fields: ContractManagedField_Min_Fields;
  ContractManagedField_min_order_by: ContractManagedField_Min_Order_By;
  ContractManagedField_mutation_response: ContractManagedField_Mutation_Response;
  ContractManagedField_on_conflict: ContractManagedField_On_Conflict;
  ContractManagedField_order_by: ContractManagedField_Order_By;
  ContractManagedField_pk_columns_input: ContractManagedField_Pk_Columns_Input;
  ContractManagedField_set_input: ContractManagedField_Set_Input;
  ContractManagedField_stream_cursor_input: ContractManagedField_Stream_Cursor_Input;
  ContractManagedField_stream_cursor_value_input: ContractManagedField_Stream_Cursor_Value_Input;
  ContractManagedField_updates: ContractManagedField_Updates;
  ContractPage: ContractPage;
  ContractPostpaidTrueupInvoice: Omit<ContractPostpaidTrueupInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  ContractPostpaidTrueupLineItem: ContractPostpaidTrueupLineItem;
  ContractPricing: Omit<ContractPricing, 'product' | 'products'> & { product: ResolversParentTypes['ProductListItem'], products: Array<ResolversParentTypes['ProductListItem']> };
  ContractProServiceInvoice: Omit<ContractProServiceInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  ContractProServiceInvoicesInput: ContractProServiceInvoicesInput;
  ContractProServiceLineItem: ContractProServiceLineItem;
  ContractProServiceLineItemInput: ContractProServiceLineItemInput;
  ContractProductManagedField: ContractProductManagedField;
  ContractProductManagedField_aggregate: ContractProductManagedField_Aggregate;
  ContractProductManagedField_aggregate_bool_exp: ContractProductManagedField_Aggregate_Bool_Exp;
  ContractProductManagedField_aggregate_bool_exp_bool_and: ContractProductManagedField_Aggregate_Bool_Exp_Bool_And;
  ContractProductManagedField_aggregate_bool_exp_bool_or: ContractProductManagedField_Aggregate_Bool_Exp_Bool_Or;
  ContractProductManagedField_aggregate_bool_exp_count: ContractProductManagedField_Aggregate_Bool_Exp_Count;
  ContractProductManagedField_aggregate_fields: ContractProductManagedField_Aggregate_Fields;
  ContractProductManagedField_aggregate_order_by: ContractProductManagedField_Aggregate_Order_By;
  ContractProductManagedField_arr_rel_insert_input: ContractProductManagedField_Arr_Rel_Insert_Input;
  ContractProductManagedField_bool_exp: ContractProductManagedField_Bool_Exp;
  ContractProductManagedField_insert_input: ContractProductManagedField_Insert_Input;
  ContractProductManagedField_max_fields: ContractProductManagedField_Max_Fields;
  ContractProductManagedField_max_order_by: ContractProductManagedField_Max_Order_By;
  ContractProductManagedField_min_fields: ContractProductManagedField_Min_Fields;
  ContractProductManagedField_min_order_by: ContractProductManagedField_Min_Order_By;
  ContractProductManagedField_mutation_response: ContractProductManagedField_Mutation_Response;
  ContractProductManagedField_on_conflict: ContractProductManagedField_On_Conflict;
  ContractProductManagedField_order_by: ContractProductManagedField_Order_By;
  ContractProductManagedField_pk_columns_input: ContractProductManagedField_Pk_Columns_Input;
  ContractProductManagedField_set_input: ContractProductManagedField_Set_Input;
  ContractProductManagedField_stream_cursor_input: ContractProductManagedField_Stream_Cursor_Input;
  ContractProductManagedField_stream_cursor_value_input: ContractProductManagedField_Stream_Cursor_Value_Input;
  ContractProductManagedField_updates: ContractProductManagedField_Updates;
  ContractRateSchedule: Omit<ContractRateSchedule, 'products_on_segments'> & { products_on_segments: Array<ResolversParentTypes['ProductListItem']> };
  ContractRateScheduleSegment: Omit<ContractRateScheduleSegment, 'list_rate' | 'override_rate' | 'product'> & { list_rate: ResolversParentTypes['Rate'], override_rate: Maybe<ResolversParentTypes['Rate']>, product: ResolversParentTypes['ProductListItem'] };
  ContractRefundInvoice: Omit<ContractRefundInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  ContractRefundInvoiceCorrectionRecord: Omit<ContractRefundInvoiceCorrectionRecord, 'original_invoice'> & { original_invoice: ResolversParentTypes['ContractRefundInvoiceCorrectionRecordOriginalInvoice'] };
  ContractRefundInvoiceCorrectionRecordOriginalInvoice: ResolversParentTypes['ContractPostpaidTrueupInvoice'] | ResolversParentTypes['ContractScheduledInvoice'];
  ContractRolloverCommitLineItem: Omit<ContractRolloverCommitLineItem, 'product' | 'rollover_commit_union'> & { product: ResolversParentTypes['ContractUsageLineItemProductListItem'], rollover_commit_union: ResolversParentTypes['CommitUnion'] };
  ContractScheduledInvoice: Omit<ContractScheduledInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  ContractScheduledLineItem: ResolversParentTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractAWSRoyaltyLineItem'] | ResolversParentTypes['ContractChargeLineItem'] | ResolversParentTypes['ContractCommitLineItem'] | ResolversParentTypes['ContractDiscountLineItem'] | ResolversParentTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractGCPRoyaltyLineItem'];
  ContractStats: ContractStats;
  ContractSubscriptionLineItem: ContractSubscriptionLineItem;
  ContractUsageInvoice: Omit<ContractUsageInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  ContractUsageInvoiceSchedule: ContractUsageInvoiceSchedule;
  ContractUsageLineItem: Omit<ContractUsageLineItem, 'product'> & { product: ResolversParentTypes['ContractUsageLineItemProductListItem'] };
  ContractUsageLineItemProductListItem: ResolversParentTypes['CompositeProductListItem'] | ResolversParentTypes['SubscriptionProductListItem'] | ResolversParentTypes['UsageProductListItem'];
  CorrectableInvoice: ResolversParentTypes['ArrearsInvoice'] | ResolversParentTypes['ContractPostpaidTrueupInvoice'] | ResolversParentTypes['ContractScheduledInvoice'] | ResolversParentTypes['ContractUsageInvoice'];
  CorrectionInvoice: Omit<CorrectionInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  Cost: Cost;
  CostWindow: CostWindow;
  CreateBillingProviderSettingsInput: CreateBillingProviderSettingsInput;
  CreateContractUsageFilterInput: CreateContractUsageFilterInput;
  CreateContractUsageInvoiceScheduleInput: CreateContractUsageInvoiceScheduleInput;
  CreateStripeSettingsInput: CreateStripeSettingsInput;
  CreditAdjustment: CreditAdjustment;
  CreditAdjustment_aggregate: CreditAdjustment_Aggregate;
  CreditAdjustment_aggregate_fields: CreditAdjustment_Aggregate_Fields;
  CreditAdjustment_avg_fields: CreditAdjustment_Avg_Fields;
  CreditAdjustment_bool_exp: CreditAdjustment_Bool_Exp;
  CreditAdjustment_inc_input: CreditAdjustment_Inc_Input;
  CreditAdjustment_insert_input: CreditAdjustment_Insert_Input;
  CreditAdjustment_max_fields: CreditAdjustment_Max_Fields;
  CreditAdjustment_min_fields: CreditAdjustment_Min_Fields;
  CreditAdjustment_mutation_response: CreditAdjustment_Mutation_Response;
  CreditAdjustment_on_conflict: CreditAdjustment_On_Conflict;
  CreditAdjustment_order_by: CreditAdjustment_Order_By;
  CreditAdjustment_pk_columns_input: CreditAdjustment_Pk_Columns_Input;
  CreditAdjustment_set_input: CreditAdjustment_Set_Input;
  CreditAdjustment_stddev_fields: CreditAdjustment_Stddev_Fields;
  CreditAdjustment_stddev_pop_fields: CreditAdjustment_Stddev_Pop_Fields;
  CreditAdjustment_stddev_samp_fields: CreditAdjustment_Stddev_Samp_Fields;
  CreditAdjustment_stream_cursor_input: CreditAdjustment_Stream_Cursor_Input;
  CreditAdjustment_stream_cursor_value_input: CreditAdjustment_Stream_Cursor_Value_Input;
  CreditAdjustment_sum_fields: CreditAdjustment_Sum_Fields;
  CreditAdjustment_updates: CreditAdjustment_Updates;
  CreditAdjustment_var_pop_fields: CreditAdjustment_Var_Pop_Fields;
  CreditAdjustment_var_samp_fields: CreditAdjustment_Var_Samp_Fields;
  CreditAdjustment_variance_fields: CreditAdjustment_Variance_Fields;
  CreditAmount: Scalars['CreditAmount'];
  CreditAmountWithType: CreditAmountWithType;
  CreditGrant: CreditGrant;
  CreditGrantDeduction: CreditGrantDeduction;
  CreditGrantDeduction_aggregate: CreditGrantDeduction_Aggregate;
  CreditGrantDeduction_aggregate_bool_exp: CreditGrantDeduction_Aggregate_Bool_Exp;
  CreditGrantDeduction_aggregate_bool_exp_count: CreditGrantDeduction_Aggregate_Bool_Exp_Count;
  CreditGrantDeduction_aggregate_fields: CreditGrantDeduction_Aggregate_Fields;
  CreditGrantDeduction_aggregate_order_by: CreditGrantDeduction_Aggregate_Order_By;
  CreditGrantDeduction_arr_rel_insert_input: CreditGrantDeduction_Arr_Rel_Insert_Input;
  CreditGrantDeduction_avg_fields: CreditGrantDeduction_Avg_Fields;
  CreditGrantDeduction_avg_order_by: CreditGrantDeduction_Avg_Order_By;
  CreditGrantDeduction_bool_exp: CreditGrantDeduction_Bool_Exp;
  CreditGrantDeduction_inc_input: CreditGrantDeduction_Inc_Input;
  CreditGrantDeduction_insert_input: CreditGrantDeduction_Insert_Input;
  CreditGrantDeduction_max_fields: CreditGrantDeduction_Max_Fields;
  CreditGrantDeduction_max_order_by: CreditGrantDeduction_Max_Order_By;
  CreditGrantDeduction_min_fields: CreditGrantDeduction_Min_Fields;
  CreditGrantDeduction_min_order_by: CreditGrantDeduction_Min_Order_By;
  CreditGrantDeduction_mutation_response: CreditGrantDeduction_Mutation_Response;
  CreditGrantDeduction_on_conflict: CreditGrantDeduction_On_Conflict;
  CreditGrantDeduction_order_by: CreditGrantDeduction_Order_By;
  CreditGrantDeduction_pk_columns_input: CreditGrantDeduction_Pk_Columns_Input;
  CreditGrantDeduction_set_input: CreditGrantDeduction_Set_Input;
  CreditGrantDeduction_stddev_fields: CreditGrantDeduction_Stddev_Fields;
  CreditGrantDeduction_stddev_order_by: CreditGrantDeduction_Stddev_Order_By;
  CreditGrantDeduction_stddev_pop_fields: CreditGrantDeduction_Stddev_Pop_Fields;
  CreditGrantDeduction_stddev_pop_order_by: CreditGrantDeduction_Stddev_Pop_Order_By;
  CreditGrantDeduction_stddev_samp_fields: CreditGrantDeduction_Stddev_Samp_Fields;
  CreditGrantDeduction_stddev_samp_order_by: CreditGrantDeduction_Stddev_Samp_Order_By;
  CreditGrantDeduction_stream_cursor_input: CreditGrantDeduction_Stream_Cursor_Input;
  CreditGrantDeduction_stream_cursor_value_input: CreditGrantDeduction_Stream_Cursor_Value_Input;
  CreditGrantDeduction_sum_fields: CreditGrantDeduction_Sum_Fields;
  CreditGrantDeduction_sum_order_by: CreditGrantDeduction_Sum_Order_By;
  CreditGrantDeduction_updates: CreditGrantDeduction_Updates;
  CreditGrantDeduction_var_pop_fields: CreditGrantDeduction_Var_Pop_Fields;
  CreditGrantDeduction_var_pop_order_by: CreditGrantDeduction_Var_Pop_Order_By;
  CreditGrantDeduction_var_samp_fields: CreditGrantDeduction_Var_Samp_Fields;
  CreditGrantDeduction_var_samp_order_by: CreditGrantDeduction_Var_Samp_Order_By;
  CreditGrantDeduction_variance_fields: CreditGrantDeduction_Variance_Fields;
  CreditGrantDeduction_variance_order_by: CreditGrantDeduction_Variance_Order_By;
  CreditGrantHistory: CreditGrantHistory;
  CreditGrantHistory_aggregate: CreditGrantHistory_Aggregate;
  CreditGrantHistory_aggregate_fields: CreditGrantHistory_Aggregate_Fields;
  CreditGrantHistory_append_input: CreditGrantHistory_Append_Input;
  CreditGrantHistory_avg_fields: CreditGrantHistory_Avg_Fields;
  CreditGrantHistory_bool_exp: CreditGrantHistory_Bool_Exp;
  CreditGrantHistory_delete_at_path_input: CreditGrantHistory_Delete_At_Path_Input;
  CreditGrantHistory_delete_elem_input: CreditGrantHistory_Delete_Elem_Input;
  CreditGrantHistory_delete_key_input: CreditGrantHistory_Delete_Key_Input;
  CreditGrantHistory_inc_input: CreditGrantHistory_Inc_Input;
  CreditGrantHistory_insert_input: CreditGrantHistory_Insert_Input;
  CreditGrantHistory_max_fields: CreditGrantHistory_Max_Fields;
  CreditGrantHistory_min_fields: CreditGrantHistory_Min_Fields;
  CreditGrantHistory_mutation_response: CreditGrantHistory_Mutation_Response;
  CreditGrantHistory_on_conflict: CreditGrantHistory_On_Conflict;
  CreditGrantHistory_order_by: CreditGrantHistory_Order_By;
  CreditGrantHistory_pk_columns_input: CreditGrantHistory_Pk_Columns_Input;
  CreditGrantHistory_prepend_input: CreditGrantHistory_Prepend_Input;
  CreditGrantHistory_set_input: CreditGrantHistory_Set_Input;
  CreditGrantHistory_stddev_fields: CreditGrantHistory_Stddev_Fields;
  CreditGrantHistory_stddev_pop_fields: CreditGrantHistory_Stddev_Pop_Fields;
  CreditGrantHistory_stddev_samp_fields: CreditGrantHistory_Stddev_Samp_Fields;
  CreditGrantHistory_stream_cursor_input: CreditGrantHistory_Stream_Cursor_Input;
  CreditGrantHistory_stream_cursor_value_input: CreditGrantHistory_Stream_Cursor_Value_Input;
  CreditGrantHistory_sum_fields: CreditGrantHistory_Sum_Fields;
  CreditGrantHistory_updates: CreditGrantHistory_Updates;
  CreditGrantHistory_var_pop_fields: CreditGrantHistory_Var_Pop_Fields;
  CreditGrantHistory_var_samp_fields: CreditGrantHistory_Var_Samp_Fields;
  CreditGrantHistory_variance_fields: CreditGrantHistory_Variance_Fields;
  CreditGrantInput: CreditGrantInput;
  CreditGrantManagedField: CreditGrantManagedField;
  CreditGrantManagedField_aggregate: CreditGrantManagedField_Aggregate;
  CreditGrantManagedField_aggregate_bool_exp: CreditGrantManagedField_Aggregate_Bool_Exp;
  CreditGrantManagedField_aggregate_bool_exp_bool_and: CreditGrantManagedField_Aggregate_Bool_Exp_Bool_And;
  CreditGrantManagedField_aggregate_bool_exp_bool_or: CreditGrantManagedField_Aggregate_Bool_Exp_Bool_Or;
  CreditGrantManagedField_aggregate_bool_exp_count: CreditGrantManagedField_Aggregate_Bool_Exp_Count;
  CreditGrantManagedField_aggregate_fields: CreditGrantManagedField_Aggregate_Fields;
  CreditGrantManagedField_aggregate_order_by: CreditGrantManagedField_Aggregate_Order_By;
  CreditGrantManagedField_arr_rel_insert_input: CreditGrantManagedField_Arr_Rel_Insert_Input;
  CreditGrantManagedField_bool_exp: CreditGrantManagedField_Bool_Exp;
  CreditGrantManagedField_insert_input: CreditGrantManagedField_Insert_Input;
  CreditGrantManagedField_max_fields: CreditGrantManagedField_Max_Fields;
  CreditGrantManagedField_max_order_by: CreditGrantManagedField_Max_Order_By;
  CreditGrantManagedField_min_fields: CreditGrantManagedField_Min_Fields;
  CreditGrantManagedField_min_order_by: CreditGrantManagedField_Min_Order_By;
  CreditGrantManagedField_mutation_response: CreditGrantManagedField_Mutation_Response;
  CreditGrantManagedField_on_conflict: CreditGrantManagedField_On_Conflict;
  CreditGrantManagedField_order_by: CreditGrantManagedField_Order_By;
  CreditGrantManagedField_pk_columns_input: CreditGrantManagedField_Pk_Columns_Input;
  CreditGrantManagedField_set_input: CreditGrantManagedField_Set_Input;
  CreditGrantManagedField_stream_cursor_input: CreditGrantManagedField_Stream_Cursor_Input;
  CreditGrantManagedField_stream_cursor_value_input: CreditGrantManagedField_Stream_Cursor_Value_Input;
  CreditGrantManagedField_updates: CreditGrantManagedField_Updates;
  CreditGrantPage: CreditGrantPage;
  CreditGrantUnion: ResolversParentTypes['CreditGrant'] | ResolversParentTypes['MRI_CreditGrant'] | ResolversParentTypes['PrepaidCreditGrant'];
  CreditGrant_aggregate: CreditGrant_Aggregate;
  CreditGrant_aggregate_bool_exp: CreditGrant_Aggregate_Bool_Exp;
  CreditGrant_aggregate_bool_exp_count: CreditGrant_Aggregate_Bool_Exp_Count;
  CreditGrant_aggregate_fields: CreditGrant_Aggregate_Fields;
  CreditGrant_aggregate_order_by: CreditGrant_Aggregate_Order_By;
  CreditGrant_append_input: CreditGrant_Append_Input;
  CreditGrant_arr_rel_insert_input: CreditGrant_Arr_Rel_Insert_Input;
  CreditGrant_avg_fields: CreditGrant_Avg_Fields;
  CreditGrant_avg_order_by: CreditGrant_Avg_Order_By;
  CreditGrant_bool_exp: CreditGrant_Bool_Exp;
  CreditGrant_delete_at_path_input: CreditGrant_Delete_At_Path_Input;
  CreditGrant_delete_elem_input: CreditGrant_Delete_Elem_Input;
  CreditGrant_delete_key_input: CreditGrant_Delete_Key_Input;
  CreditGrant_inc_input: CreditGrant_Inc_Input;
  CreditGrant_insert_input: CreditGrant_Insert_Input;
  CreditGrant_max_fields: CreditGrant_Max_Fields;
  CreditGrant_max_order_by: CreditGrant_Max_Order_By;
  CreditGrant_min_fields: CreditGrant_Min_Fields;
  CreditGrant_min_order_by: CreditGrant_Min_Order_By;
  CreditGrant_mutation_response: CreditGrant_Mutation_Response;
  CreditGrant_obj_rel_insert_input: CreditGrant_Obj_Rel_Insert_Input;
  CreditGrant_on_conflict: CreditGrant_On_Conflict;
  CreditGrant_order_by: CreditGrant_Order_By;
  CreditGrant_pk_columns_input: CreditGrant_Pk_Columns_Input;
  CreditGrant_prepend_input: CreditGrant_Prepend_Input;
  CreditGrant_set_input: CreditGrant_Set_Input;
  CreditGrant_stddev_fields: CreditGrant_Stddev_Fields;
  CreditGrant_stddev_order_by: CreditGrant_Stddev_Order_By;
  CreditGrant_stddev_pop_fields: CreditGrant_Stddev_Pop_Fields;
  CreditGrant_stddev_pop_order_by: CreditGrant_Stddev_Pop_Order_By;
  CreditGrant_stddev_samp_fields: CreditGrant_Stddev_Samp_Fields;
  CreditGrant_stddev_samp_order_by: CreditGrant_Stddev_Samp_Order_By;
  CreditGrant_stream_cursor_input: CreditGrant_Stream_Cursor_Input;
  CreditGrant_stream_cursor_value_input: CreditGrant_Stream_Cursor_Value_Input;
  CreditGrant_sum_fields: CreditGrant_Sum_Fields;
  CreditGrant_sum_order_by: CreditGrant_Sum_Order_By;
  CreditGrant_updates: CreditGrant_Updates;
  CreditGrant_var_pop_fields: CreditGrant_Var_Pop_Fields;
  CreditGrant_var_pop_order_by: CreditGrant_Var_Pop_Order_By;
  CreditGrant_var_samp_fields: CreditGrant_Var_Samp_Fields;
  CreditGrant_var_samp_order_by: CreditGrant_Var_Samp_Order_By;
  CreditGrant_variance_fields: CreditGrant_Variance_Fields;
  CreditGrant_variance_order_by: CreditGrant_Variance_Order_By;
  CreditLineItem: CreditLineItem;
  CreditPurchaseChargeLineItem: CreditPurchaseChargeLineItem;
  CreditPurchaseInvoice: Omit<CreditPurchaseInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  CreditPurchaseLineItem: Omit<CreditPurchaseLineItem, 'credit_grant'> & { credit_grant: ResolversParentTypes['CreditGrantUnion'] };
  CreditType: CreditType;
  CreditTypeConversion: CreditTypeConversion;
  CreditTypeConversionAdjustment: CreditTypeConversionAdjustment;
  CreditTypeConversionAdjustment_aggregate: CreditTypeConversionAdjustment_Aggregate;
  CreditTypeConversionAdjustment_aggregate_bool_exp: CreditTypeConversionAdjustment_Aggregate_Bool_Exp;
  CreditTypeConversionAdjustment_aggregate_bool_exp_count: CreditTypeConversionAdjustment_Aggregate_Bool_Exp_Count;
  CreditTypeConversionAdjustment_aggregate_fields: CreditTypeConversionAdjustment_Aggregate_Fields;
  CreditTypeConversionAdjustment_aggregate_order_by: CreditTypeConversionAdjustment_Aggregate_Order_By;
  CreditTypeConversionAdjustment_arr_rel_insert_input: CreditTypeConversionAdjustment_Arr_Rel_Insert_Input;
  CreditTypeConversionAdjustment_avg_fields: CreditTypeConversionAdjustment_Avg_Fields;
  CreditTypeConversionAdjustment_avg_order_by: CreditTypeConversionAdjustment_Avg_Order_By;
  CreditTypeConversionAdjustment_bool_exp: CreditTypeConversionAdjustment_Bool_Exp;
  CreditTypeConversionAdjustment_inc_input: CreditTypeConversionAdjustment_Inc_Input;
  CreditTypeConversionAdjustment_insert_input: CreditTypeConversionAdjustment_Insert_Input;
  CreditTypeConversionAdjustment_max_fields: CreditTypeConversionAdjustment_Max_Fields;
  CreditTypeConversionAdjustment_max_order_by: CreditTypeConversionAdjustment_Max_Order_By;
  CreditTypeConversionAdjustment_min_fields: CreditTypeConversionAdjustment_Min_Fields;
  CreditTypeConversionAdjustment_min_order_by: CreditTypeConversionAdjustment_Min_Order_By;
  CreditTypeConversionAdjustment_mutation_response: CreditTypeConversionAdjustment_Mutation_Response;
  CreditTypeConversionAdjustment_on_conflict: CreditTypeConversionAdjustment_On_Conflict;
  CreditTypeConversionAdjustment_order_by: CreditTypeConversionAdjustment_Order_By;
  CreditTypeConversionAdjustment_pk_columns_input: CreditTypeConversionAdjustment_Pk_Columns_Input;
  CreditTypeConversionAdjustment_set_input: CreditTypeConversionAdjustment_Set_Input;
  CreditTypeConversionAdjustment_stddev_fields: CreditTypeConversionAdjustment_Stddev_Fields;
  CreditTypeConversionAdjustment_stddev_order_by: CreditTypeConversionAdjustment_Stddev_Order_By;
  CreditTypeConversionAdjustment_stddev_pop_fields: CreditTypeConversionAdjustment_Stddev_Pop_Fields;
  CreditTypeConversionAdjustment_stddev_pop_order_by: CreditTypeConversionAdjustment_Stddev_Pop_Order_By;
  CreditTypeConversionAdjustment_stddev_samp_fields: CreditTypeConversionAdjustment_Stddev_Samp_Fields;
  CreditTypeConversionAdjustment_stddev_samp_order_by: CreditTypeConversionAdjustment_Stddev_Samp_Order_By;
  CreditTypeConversionAdjustment_stream_cursor_input: CreditTypeConversionAdjustment_Stream_Cursor_Input;
  CreditTypeConversionAdjustment_stream_cursor_value_input: CreditTypeConversionAdjustment_Stream_Cursor_Value_Input;
  CreditTypeConversionAdjustment_sum_fields: CreditTypeConversionAdjustment_Sum_Fields;
  CreditTypeConversionAdjustment_sum_order_by: CreditTypeConversionAdjustment_Sum_Order_By;
  CreditTypeConversionAdjustment_updates: CreditTypeConversionAdjustment_Updates;
  CreditTypeConversionAdjustment_var_pop_fields: CreditTypeConversionAdjustment_Var_Pop_Fields;
  CreditTypeConversionAdjustment_var_pop_order_by: CreditTypeConversionAdjustment_Var_Pop_Order_By;
  CreditTypeConversionAdjustment_var_samp_fields: CreditTypeConversionAdjustment_Var_Samp_Fields;
  CreditTypeConversionAdjustment_var_samp_order_by: CreditTypeConversionAdjustment_Var_Samp_Order_By;
  CreditTypeConversionAdjustment_variance_fields: CreditTypeConversionAdjustment_Variance_Fields;
  CreditTypeConversionAdjustment_variance_order_by: CreditTypeConversionAdjustment_Variance_Order_By;
  CreditTypeConversionInput: CreditTypeConversionInput;
  CreditTypeConversion_aggregate: CreditTypeConversion_Aggregate;
  CreditTypeConversion_aggregate_bool_exp: CreditTypeConversion_Aggregate_Bool_Exp;
  CreditTypeConversion_aggregate_bool_exp_count: CreditTypeConversion_Aggregate_Bool_Exp_Count;
  CreditTypeConversion_aggregate_fields: CreditTypeConversion_Aggregate_Fields;
  CreditTypeConversion_aggregate_order_by: CreditTypeConversion_Aggregate_Order_By;
  CreditTypeConversion_arr_rel_insert_input: CreditTypeConversion_Arr_Rel_Insert_Input;
  CreditTypeConversion_avg_fields: CreditTypeConversion_Avg_Fields;
  CreditTypeConversion_avg_order_by: CreditTypeConversion_Avg_Order_By;
  CreditTypeConversion_bool_exp: CreditTypeConversion_Bool_Exp;
  CreditTypeConversion_inc_input: CreditTypeConversion_Inc_Input;
  CreditTypeConversion_insert_input: CreditTypeConversion_Insert_Input;
  CreditTypeConversion_max_fields: CreditTypeConversion_Max_Fields;
  CreditTypeConversion_max_order_by: CreditTypeConversion_Max_Order_By;
  CreditTypeConversion_min_fields: CreditTypeConversion_Min_Fields;
  CreditTypeConversion_min_order_by: CreditTypeConversion_Min_Order_By;
  CreditTypeConversion_mutation_response: CreditTypeConversion_Mutation_Response;
  CreditTypeConversion_on_conflict: CreditTypeConversion_On_Conflict;
  CreditTypeConversion_order_by: CreditTypeConversion_Order_By;
  CreditTypeConversion_pk_columns_input: CreditTypeConversion_Pk_Columns_Input;
  CreditTypeConversion_set_input: CreditTypeConversion_Set_Input;
  CreditTypeConversion_stddev_fields: CreditTypeConversion_Stddev_Fields;
  CreditTypeConversion_stddev_order_by: CreditTypeConversion_Stddev_Order_By;
  CreditTypeConversion_stddev_pop_fields: CreditTypeConversion_Stddev_Pop_Fields;
  CreditTypeConversion_stddev_pop_order_by: CreditTypeConversion_Stddev_Pop_Order_By;
  CreditTypeConversion_stddev_samp_fields: CreditTypeConversion_Stddev_Samp_Fields;
  CreditTypeConversion_stddev_samp_order_by: CreditTypeConversion_Stddev_Samp_Order_By;
  CreditTypeConversion_stream_cursor_input: CreditTypeConversion_Stream_Cursor_Input;
  CreditTypeConversion_stream_cursor_value_input: CreditTypeConversion_Stream_Cursor_Value_Input;
  CreditTypeConversion_sum_fields: CreditTypeConversion_Sum_Fields;
  CreditTypeConversion_sum_order_by: CreditTypeConversion_Sum_Order_By;
  CreditTypeConversion_updates: CreditTypeConversion_Updates;
  CreditTypeConversion_var_pop_fields: CreditTypeConversion_Var_Pop_Fields;
  CreditTypeConversion_var_pop_order_by: CreditTypeConversion_Var_Pop_Order_By;
  CreditTypeConversion_var_samp_fields: CreditTypeConversion_Var_Samp_Fields;
  CreditTypeConversion_var_samp_order_by: CreditTypeConversion_Var_Samp_Order_By;
  CreditTypeConversion_variance_fields: CreditTypeConversion_Variance_Fields;
  CreditTypeConversion_variance_order_by: CreditTypeConversion_Variance_Order_By;
  CreditType_aggregate: CreditType_Aggregate;
  CreditType_aggregate_fields: CreditType_Aggregate_Fields;
  CreditType_bool_exp: CreditType_Bool_Exp;
  CreditType_insert_input: CreditType_Insert_Input;
  CreditType_max_fields: CreditType_Max_Fields;
  CreditType_min_fields: CreditType_Min_Fields;
  CreditType_mutation_response: CreditType_Mutation_Response;
  CreditType_obj_rel_insert_input: CreditType_Obj_Rel_Insert_Input;
  CreditType_on_conflict: CreditType_On_Conflict;
  CreditType_order_by: CreditType_Order_By;
  CreditType_pk_columns_input: CreditType_Pk_Columns_Input;
  CreditType_set_input: CreditType_Set_Input;
  CreditType_stream_cursor_input: CreditType_Stream_Cursor_Input;
  CreditType_stream_cursor_value_input: CreditType_Stream_Cursor_Value_Input;
  CreditType_updates: CreditType_Updates;
  CurrentPlanUsage: CurrentPlanUsage;
  CustomPricing: CustomPricing;
  CustomPricingInput: CustomPricingInput;
  CustomPricing_aggregate: CustomPricing_Aggregate;
  CustomPricing_aggregate_bool_exp: CustomPricing_Aggregate_Bool_Exp;
  CustomPricing_aggregate_bool_exp_count: CustomPricing_Aggregate_Bool_Exp_Count;
  CustomPricing_aggregate_fields: CustomPricing_Aggregate_Fields;
  CustomPricing_aggregate_order_by: CustomPricing_Aggregate_Order_By;
  CustomPricing_arr_rel_insert_input: CustomPricing_Arr_Rel_Insert_Input;
  CustomPricing_bool_exp: CustomPricing_Bool_Exp;
  CustomPricing_insert_input: CustomPricing_Insert_Input;
  CustomPricing_max_fields: CustomPricing_Max_Fields;
  CustomPricing_max_order_by: CustomPricing_Max_Order_By;
  CustomPricing_min_fields: CustomPricing_Min_Fields;
  CustomPricing_min_order_by: CustomPricing_Min_Order_By;
  CustomPricing_mutation_response: CustomPricing_Mutation_Response;
  CustomPricing_obj_rel_insert_input: CustomPricing_Obj_Rel_Insert_Input;
  CustomPricing_on_conflict: CustomPricing_On_Conflict;
  CustomPricing_order_by: CustomPricing_Order_By;
  CustomPricing_pk_columns_input: CustomPricing_Pk_Columns_Input;
  CustomPricing_set_input: CustomPricing_Set_Input;
  CustomPricing_stream_cursor_input: CustomPricing_Stream_Cursor_Input;
  CustomPricing_stream_cursor_value_input: CustomPricing_Stream_Cursor_Value_Input;
  CustomPricing_updates: CustomPricing_Updates;
  CustomRate: CustomRate;
  CustomRateInput: CustomRateInput;
  Customer: Omit<Customer, 'commits'> & { commits: Array<ResolversParentTypes['CommitUnion']> };
  CustomerAggregate: CustomerAggregate;
  CustomerAlert: CustomerAlert;
  CustomerAlertComposite: CustomerAlertComposite;
  CustomerAlertHistory: CustomerAlertHistory;
  CustomerAlertHistory_aggregate: CustomerAlertHistory_Aggregate;
  CustomerAlertHistory_aggregate_bool_exp: CustomerAlertHistory_Aggregate_Bool_Exp;
  CustomerAlertHistory_aggregate_bool_exp_count: CustomerAlertHistory_Aggregate_Bool_Exp_Count;
  CustomerAlertHistory_aggregate_fields: CustomerAlertHistory_Aggregate_Fields;
  CustomerAlertHistory_aggregate_order_by: CustomerAlertHistory_Aggregate_Order_By;
  CustomerAlertHistory_append_input: CustomerAlertHistory_Append_Input;
  CustomerAlertHistory_arr_rel_insert_input: CustomerAlertHistory_Arr_Rel_Insert_Input;
  CustomerAlertHistory_bool_exp: CustomerAlertHistory_Bool_Exp;
  CustomerAlertHistory_delete_at_path_input: CustomerAlertHistory_Delete_At_Path_Input;
  CustomerAlertHistory_delete_elem_input: CustomerAlertHistory_Delete_Elem_Input;
  CustomerAlertHistory_delete_key_input: CustomerAlertHistory_Delete_Key_Input;
  CustomerAlertHistory_insert_input: CustomerAlertHistory_Insert_Input;
  CustomerAlertHistory_max_fields: CustomerAlertHistory_Max_Fields;
  CustomerAlertHistory_max_order_by: CustomerAlertHistory_Max_Order_By;
  CustomerAlertHistory_min_fields: CustomerAlertHistory_Min_Fields;
  CustomerAlertHistory_min_order_by: CustomerAlertHistory_Min_Order_By;
  CustomerAlertHistory_mutation_response: CustomerAlertHistory_Mutation_Response;
  CustomerAlertHistory_on_conflict: CustomerAlertHistory_On_Conflict;
  CustomerAlertHistory_order_by: CustomerAlertHistory_Order_By;
  CustomerAlertHistory_pk_columns_input: CustomerAlertHistory_Pk_Columns_Input;
  CustomerAlertHistory_prepend_input: CustomerAlertHistory_Prepend_Input;
  CustomerAlertHistory_set_input: CustomerAlertHistory_Set_Input;
  CustomerAlertHistory_stream_cursor_input: CustomerAlertHistory_Stream_Cursor_Input;
  CustomerAlertHistory_stream_cursor_value_input: CustomerAlertHistory_Stream_Cursor_Value_Input;
  CustomerAlertHistory_updates: CustomerAlertHistory_Updates;
  CustomerAlertStatusEnum: CustomerAlertStatusEnum;
  CustomerAlertStatusEnum_aggregate: CustomerAlertStatusEnum_Aggregate;
  CustomerAlertStatusEnum_aggregate_fields: CustomerAlertStatusEnum_Aggregate_Fields;
  CustomerAlertStatusEnum_bool_exp: CustomerAlertStatusEnum_Bool_Exp;
  CustomerAlertStatusEnum_enum_comparison_exp: CustomerAlertStatusEnum_Enum_Comparison_Exp;
  CustomerAlertStatusEnum_insert_input: CustomerAlertStatusEnum_Insert_Input;
  CustomerAlertStatusEnum_max_fields: CustomerAlertStatusEnum_Max_Fields;
  CustomerAlertStatusEnum_min_fields: CustomerAlertStatusEnum_Min_Fields;
  CustomerAlertStatusEnum_mutation_response: CustomerAlertStatusEnum_Mutation_Response;
  CustomerAlertStatusEnum_on_conflict: CustomerAlertStatusEnum_On_Conflict;
  CustomerAlertStatusEnum_order_by: CustomerAlertStatusEnum_Order_By;
  CustomerAlertStatusEnum_pk_columns_input: CustomerAlertStatusEnum_Pk_Columns_Input;
  CustomerAlertStatusEnum_set_input: CustomerAlertStatusEnum_Set_Input;
  CustomerAlertStatusEnum_stream_cursor_input: CustomerAlertStatusEnum_Stream_Cursor_Input;
  CustomerAlertStatusEnum_stream_cursor_value_input: CustomerAlertStatusEnum_Stream_Cursor_Value_Input;
  CustomerAlertStatusEnum_updates: CustomerAlertStatusEnum_Updates;
  CustomerAlert_aggregate: CustomerAlert_Aggregate;
  CustomerAlert_aggregate_bool_exp: CustomerAlert_Aggregate_Bool_Exp;
  CustomerAlert_aggregate_bool_exp_count: CustomerAlert_Aggregate_Bool_Exp_Count;
  CustomerAlert_aggregate_fields: CustomerAlert_Aggregate_Fields;
  CustomerAlert_aggregate_order_by: CustomerAlert_Aggregate_Order_By;
  CustomerAlert_append_input: CustomerAlert_Append_Input;
  CustomerAlert_arr_rel_insert_input: CustomerAlert_Arr_Rel_Insert_Input;
  CustomerAlert_bool_exp: CustomerAlert_Bool_Exp;
  CustomerAlert_delete_at_path_input: CustomerAlert_Delete_At_Path_Input;
  CustomerAlert_delete_elem_input: CustomerAlert_Delete_Elem_Input;
  CustomerAlert_delete_key_input: CustomerAlert_Delete_Key_Input;
  CustomerAlert_insert_input: CustomerAlert_Insert_Input;
  CustomerAlert_max_fields: CustomerAlert_Max_Fields;
  CustomerAlert_max_order_by: CustomerAlert_Max_Order_By;
  CustomerAlert_min_fields: CustomerAlert_Min_Fields;
  CustomerAlert_min_order_by: CustomerAlert_Min_Order_By;
  CustomerAlert_mutation_response: CustomerAlert_Mutation_Response;
  CustomerAlert_obj_rel_insert_input: CustomerAlert_Obj_Rel_Insert_Input;
  CustomerAlert_on_conflict: CustomerAlert_On_Conflict;
  CustomerAlert_order_by: CustomerAlert_Order_By;
  CustomerAlert_pk_columns_input: CustomerAlert_Pk_Columns_Input;
  CustomerAlert_prepend_input: CustomerAlert_Prepend_Input;
  CustomerAlert_set_input: CustomerAlert_Set_Input;
  CustomerAlert_stream_cursor_input: CustomerAlert_Stream_Cursor_Input;
  CustomerAlert_stream_cursor_value_input: CustomerAlert_Stream_Cursor_Value_Input;
  CustomerAlert_updates: CustomerAlert_Updates;
  CustomerAlertsSortingInput: CustomerAlertsSortingInput;
  CustomerBillingProviderConfiguration: CustomerBillingProviderConfiguration;
  CustomerBillingProviderConfigurationNotification: CustomerBillingProviderConfigurationNotification;
  CustomerBillingProviderConfigurationNotificationInput: CustomerBillingProviderConfigurationNotificationInput;
  CustomerCommitInput: CustomerCommitInput;
  CustomerConfig: CustomerConfig;
  CustomerConfigInput: CustomerConfigInput;
  CustomerConfig_aggregate: CustomerConfig_Aggregate;
  CustomerConfig_aggregate_bool_exp: CustomerConfig_Aggregate_Bool_Exp;
  CustomerConfig_aggregate_bool_exp_count: CustomerConfig_Aggregate_Bool_Exp_Count;
  CustomerConfig_aggregate_fields: CustomerConfig_Aggregate_Fields;
  CustomerConfig_aggregate_order_by: CustomerConfig_Aggregate_Order_By;
  CustomerConfig_arr_rel_insert_input: CustomerConfig_Arr_Rel_Insert_Input;
  CustomerConfig_bool_exp: CustomerConfig_Bool_Exp;
  CustomerConfig_insert_input: CustomerConfig_Insert_Input;
  CustomerConfig_max_fields: CustomerConfig_Max_Fields;
  CustomerConfig_max_order_by: CustomerConfig_Max_Order_By;
  CustomerConfig_min_fields: CustomerConfig_Min_Fields;
  CustomerConfig_min_order_by: CustomerConfig_Min_Order_By;
  CustomerConfig_mutation_response: CustomerConfig_Mutation_Response;
  CustomerConfig_on_conflict: CustomerConfig_On_Conflict;
  CustomerConfig_order_by: CustomerConfig_Order_By;
  CustomerConfig_pk_columns_input: CustomerConfig_Pk_Columns_Input;
  CustomerConfig_set_input: CustomerConfig_Set_Input;
  CustomerConfig_stream_cursor_input: CustomerConfig_Stream_Cursor_Input;
  CustomerConfig_stream_cursor_value_input: CustomerConfig_Stream_Cursor_Value_Input;
  CustomerConfig_updates: CustomerConfig_Updates;
  CustomerContractStatus: CustomerContractStatus;
  CustomerCount: CustomerCount;
  CustomerIngestAlias: CustomerIngestAlias;
  CustomerIngestAlias_aggregate: CustomerIngestAlias_Aggregate;
  CustomerIngestAlias_aggregate_bool_exp: CustomerIngestAlias_Aggregate_Bool_Exp;
  CustomerIngestAlias_aggregate_bool_exp_count: CustomerIngestAlias_Aggregate_Bool_Exp_Count;
  CustomerIngestAlias_aggregate_fields: CustomerIngestAlias_Aggregate_Fields;
  CustomerIngestAlias_aggregate_order_by: CustomerIngestAlias_Aggregate_Order_By;
  CustomerIngestAlias_arr_rel_insert_input: CustomerIngestAlias_Arr_Rel_Insert_Input;
  CustomerIngestAlias_bool_exp: CustomerIngestAlias_Bool_Exp;
  CustomerIngestAlias_insert_input: CustomerIngestAlias_Insert_Input;
  CustomerIngestAlias_max_fields: CustomerIngestAlias_Max_Fields;
  CustomerIngestAlias_max_order_by: CustomerIngestAlias_Max_Order_By;
  CustomerIngestAlias_min_fields: CustomerIngestAlias_Min_Fields;
  CustomerIngestAlias_min_order_by: CustomerIngestAlias_Min_Order_By;
  CustomerIngestAlias_mutation_response: CustomerIngestAlias_Mutation_Response;
  CustomerIngestAlias_on_conflict: CustomerIngestAlias_On_Conflict;
  CustomerIngestAlias_order_by: CustomerIngestAlias_Order_By;
  CustomerIngestAlias_pk_columns_input: CustomerIngestAlias_Pk_Columns_Input;
  CustomerIngestAlias_set_input: CustomerIngestAlias_Set_Input;
  CustomerIngestAlias_stream_cursor_input: CustomerIngestAlias_Stream_Cursor_Input;
  CustomerIngestAlias_stream_cursor_value_input: CustomerIngestAlias_Stream_Cursor_Value_Input;
  CustomerIngestAlias_updates: CustomerIngestAlias_Updates;
  CustomerList: CustomerList;
  CustomerManagedField: CustomerManagedField;
  CustomerManagedField_aggregate: CustomerManagedField_Aggregate;
  CustomerManagedField_aggregate_bool_exp: CustomerManagedField_Aggregate_Bool_Exp;
  CustomerManagedField_aggregate_bool_exp_bool_and: CustomerManagedField_Aggregate_Bool_Exp_Bool_And;
  CustomerManagedField_aggregate_bool_exp_bool_or: CustomerManagedField_Aggregate_Bool_Exp_Bool_Or;
  CustomerManagedField_aggregate_bool_exp_count: CustomerManagedField_Aggregate_Bool_Exp_Count;
  CustomerManagedField_aggregate_fields: CustomerManagedField_Aggregate_Fields;
  CustomerManagedField_aggregate_order_by: CustomerManagedField_Aggregate_Order_By;
  CustomerManagedField_arr_rel_insert_input: CustomerManagedField_Arr_Rel_Insert_Input;
  CustomerManagedField_bool_exp: CustomerManagedField_Bool_Exp;
  CustomerManagedField_insert_input: CustomerManagedField_Insert_Input;
  CustomerManagedField_max_fields: CustomerManagedField_Max_Fields;
  CustomerManagedField_max_order_by: CustomerManagedField_Max_Order_By;
  CustomerManagedField_min_fields: CustomerManagedField_Min_Fields;
  CustomerManagedField_min_order_by: CustomerManagedField_Min_Order_By;
  CustomerManagedField_mutation_response: CustomerManagedField_Mutation_Response;
  CustomerManagedField_on_conflict: CustomerManagedField_On_Conflict;
  CustomerManagedField_order_by: CustomerManagedField_Order_By;
  CustomerManagedField_pk_columns_input: CustomerManagedField_Pk_Columns_Input;
  CustomerManagedField_set_input: CustomerManagedField_Set_Input;
  CustomerManagedField_stream_cursor_input: CustomerManagedField_Stream_Cursor_Input;
  CustomerManagedField_stream_cursor_value_input: CustomerManagedField_Stream_Cursor_Value_Input;
  CustomerManagedField_updates: CustomerManagedField_Updates;
  CustomerPlan: CustomerPlan;
  CustomerPlanAdjustment: CustomerPlanAdjustment;
  CustomerPlanAdjustment_aggregate: CustomerPlanAdjustment_Aggregate;
  CustomerPlanAdjustment_aggregate_bool_exp: CustomerPlanAdjustment_Aggregate_Bool_Exp;
  CustomerPlanAdjustment_aggregate_bool_exp_count: CustomerPlanAdjustment_Aggregate_Bool_Exp_Count;
  CustomerPlanAdjustment_aggregate_fields: CustomerPlanAdjustment_Aggregate_Fields;
  CustomerPlanAdjustment_aggregate_order_by: CustomerPlanAdjustment_Aggregate_Order_By;
  CustomerPlanAdjustment_arr_rel_insert_input: CustomerPlanAdjustment_Arr_Rel_Insert_Input;
  CustomerPlanAdjustment_avg_fields: CustomerPlanAdjustment_Avg_Fields;
  CustomerPlanAdjustment_avg_order_by: CustomerPlanAdjustment_Avg_Order_By;
  CustomerPlanAdjustment_bool_exp: CustomerPlanAdjustment_Bool_Exp;
  CustomerPlanAdjustment_inc_input: CustomerPlanAdjustment_Inc_Input;
  CustomerPlanAdjustment_insert_input: CustomerPlanAdjustment_Insert_Input;
  CustomerPlanAdjustment_max_fields: CustomerPlanAdjustment_Max_Fields;
  CustomerPlanAdjustment_max_order_by: CustomerPlanAdjustment_Max_Order_By;
  CustomerPlanAdjustment_min_fields: CustomerPlanAdjustment_Min_Fields;
  CustomerPlanAdjustment_min_order_by: CustomerPlanAdjustment_Min_Order_By;
  CustomerPlanAdjustment_mutation_response: CustomerPlanAdjustment_Mutation_Response;
  CustomerPlanAdjustment_on_conflict: CustomerPlanAdjustment_On_Conflict;
  CustomerPlanAdjustment_order_by: CustomerPlanAdjustment_Order_By;
  CustomerPlanAdjustment_pk_columns_input: CustomerPlanAdjustment_Pk_Columns_Input;
  CustomerPlanAdjustment_set_input: CustomerPlanAdjustment_Set_Input;
  CustomerPlanAdjustment_stddev_fields: CustomerPlanAdjustment_Stddev_Fields;
  CustomerPlanAdjustment_stddev_order_by: CustomerPlanAdjustment_Stddev_Order_By;
  CustomerPlanAdjustment_stddev_pop_fields: CustomerPlanAdjustment_Stddev_Pop_Fields;
  CustomerPlanAdjustment_stddev_pop_order_by: CustomerPlanAdjustment_Stddev_Pop_Order_By;
  CustomerPlanAdjustment_stddev_samp_fields: CustomerPlanAdjustment_Stddev_Samp_Fields;
  CustomerPlanAdjustment_stddev_samp_order_by: CustomerPlanAdjustment_Stddev_Samp_Order_By;
  CustomerPlanAdjustment_stream_cursor_input: CustomerPlanAdjustment_Stream_Cursor_Input;
  CustomerPlanAdjustment_stream_cursor_value_input: CustomerPlanAdjustment_Stream_Cursor_Value_Input;
  CustomerPlanAdjustment_sum_fields: CustomerPlanAdjustment_Sum_Fields;
  CustomerPlanAdjustment_sum_order_by: CustomerPlanAdjustment_Sum_Order_By;
  CustomerPlanAdjustment_updates: CustomerPlanAdjustment_Updates;
  CustomerPlanAdjustment_var_pop_fields: CustomerPlanAdjustment_Var_Pop_Fields;
  CustomerPlanAdjustment_var_pop_order_by: CustomerPlanAdjustment_Var_Pop_Order_By;
  CustomerPlanAdjustment_var_samp_fields: CustomerPlanAdjustment_Var_Samp_Fields;
  CustomerPlanAdjustment_var_samp_order_by: CustomerPlanAdjustment_Var_Samp_Order_By;
  CustomerPlanAdjustment_variance_fields: CustomerPlanAdjustment_Variance_Fields;
  CustomerPlanAdjustment_variance_order_by: CustomerPlanAdjustment_Variance_Order_By;
  CustomerPlanManagedField: CustomerPlanManagedField;
  CustomerPlanManagedField_aggregate: CustomerPlanManagedField_Aggregate;
  CustomerPlanManagedField_aggregate_bool_exp: CustomerPlanManagedField_Aggregate_Bool_Exp;
  CustomerPlanManagedField_aggregate_bool_exp_bool_and: CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_And;
  CustomerPlanManagedField_aggregate_bool_exp_bool_or: CustomerPlanManagedField_Aggregate_Bool_Exp_Bool_Or;
  CustomerPlanManagedField_aggregate_bool_exp_count: CustomerPlanManagedField_Aggregate_Bool_Exp_Count;
  CustomerPlanManagedField_aggregate_fields: CustomerPlanManagedField_Aggregate_Fields;
  CustomerPlanManagedField_aggregate_order_by: CustomerPlanManagedField_Aggregate_Order_By;
  CustomerPlanManagedField_arr_rel_insert_input: CustomerPlanManagedField_Arr_Rel_Insert_Input;
  CustomerPlanManagedField_bool_exp: CustomerPlanManagedField_Bool_Exp;
  CustomerPlanManagedField_insert_input: CustomerPlanManagedField_Insert_Input;
  CustomerPlanManagedField_max_fields: CustomerPlanManagedField_Max_Fields;
  CustomerPlanManagedField_max_order_by: CustomerPlanManagedField_Max_Order_By;
  CustomerPlanManagedField_min_fields: CustomerPlanManagedField_Min_Fields;
  CustomerPlanManagedField_min_order_by: CustomerPlanManagedField_Min_Order_By;
  CustomerPlanManagedField_mutation_response: CustomerPlanManagedField_Mutation_Response;
  CustomerPlanManagedField_on_conflict: CustomerPlanManagedField_On_Conflict;
  CustomerPlanManagedField_order_by: CustomerPlanManagedField_Order_By;
  CustomerPlanManagedField_pk_columns_input: CustomerPlanManagedField_Pk_Columns_Input;
  CustomerPlanManagedField_set_input: CustomerPlanManagedField_Set_Input;
  CustomerPlanManagedField_stream_cursor_input: CustomerPlanManagedField_Stream_Cursor_Input;
  CustomerPlanManagedField_stream_cursor_value_input: CustomerPlanManagedField_Stream_Cursor_Value_Input;
  CustomerPlanManagedField_updates: CustomerPlanManagedField_Updates;
  CustomerPlanOneTimeCharge: CustomerPlanOneTimeCharge;
  CustomerPlanOneTimeCharge_aggregate: CustomerPlanOneTimeCharge_Aggregate;
  CustomerPlanOneTimeCharge_aggregate_fields: CustomerPlanOneTimeCharge_Aggregate_Fields;
  CustomerPlanOneTimeCharge_avg_fields: CustomerPlanOneTimeCharge_Avg_Fields;
  CustomerPlanOneTimeCharge_bool_exp: CustomerPlanOneTimeCharge_Bool_Exp;
  CustomerPlanOneTimeCharge_inc_input: CustomerPlanOneTimeCharge_Inc_Input;
  CustomerPlanOneTimeCharge_insert_input: CustomerPlanOneTimeCharge_Insert_Input;
  CustomerPlanOneTimeCharge_max_fields: CustomerPlanOneTimeCharge_Max_Fields;
  CustomerPlanOneTimeCharge_min_fields: CustomerPlanOneTimeCharge_Min_Fields;
  CustomerPlanOneTimeCharge_mutation_response: CustomerPlanOneTimeCharge_Mutation_Response;
  CustomerPlanOneTimeCharge_on_conflict: CustomerPlanOneTimeCharge_On_Conflict;
  CustomerPlanOneTimeCharge_order_by: CustomerPlanOneTimeCharge_Order_By;
  CustomerPlanOneTimeCharge_pk_columns_input: CustomerPlanOneTimeCharge_Pk_Columns_Input;
  CustomerPlanOneTimeCharge_set_input: CustomerPlanOneTimeCharge_Set_Input;
  CustomerPlanOneTimeCharge_stddev_fields: CustomerPlanOneTimeCharge_Stddev_Fields;
  CustomerPlanOneTimeCharge_stddev_pop_fields: CustomerPlanOneTimeCharge_Stddev_Pop_Fields;
  CustomerPlanOneTimeCharge_stddev_samp_fields: CustomerPlanOneTimeCharge_Stddev_Samp_Fields;
  CustomerPlanOneTimeCharge_stream_cursor_input: CustomerPlanOneTimeCharge_Stream_Cursor_Input;
  CustomerPlanOneTimeCharge_stream_cursor_value_input: CustomerPlanOneTimeCharge_Stream_Cursor_Value_Input;
  CustomerPlanOneTimeCharge_sum_fields: CustomerPlanOneTimeCharge_Sum_Fields;
  CustomerPlanOneTimeCharge_updates: CustomerPlanOneTimeCharge_Updates;
  CustomerPlanOneTimeCharge_var_pop_fields: CustomerPlanOneTimeCharge_Var_Pop_Fields;
  CustomerPlanOneTimeCharge_var_samp_fields: CustomerPlanOneTimeCharge_Var_Samp_Fields;
  CustomerPlanOneTimeCharge_variance_fields: CustomerPlanOneTimeCharge_Variance_Fields;
  CustomerPlanStatus: CustomerPlanStatus;
  CustomerPlan_aggregate: CustomerPlan_Aggregate;
  CustomerPlan_aggregate_bool_exp: CustomerPlan_Aggregate_Bool_Exp;
  CustomerPlan_aggregate_bool_exp_count: CustomerPlan_Aggregate_Bool_Exp_Count;
  CustomerPlan_aggregate_fields: CustomerPlan_Aggregate_Fields;
  CustomerPlan_aggregate_order_by: CustomerPlan_Aggregate_Order_By;
  CustomerPlan_arr_rel_insert_input: CustomerPlan_Arr_Rel_Insert_Input;
  CustomerPlan_avg_fields: CustomerPlan_Avg_Fields;
  CustomerPlan_avg_order_by: CustomerPlan_Avg_Order_By;
  CustomerPlan_bool_exp: CustomerPlan_Bool_Exp;
  CustomerPlan_inc_input: CustomerPlan_Inc_Input;
  CustomerPlan_insert_input: CustomerPlan_Insert_Input;
  CustomerPlan_max_fields: CustomerPlan_Max_Fields;
  CustomerPlan_max_order_by: CustomerPlan_Max_Order_By;
  CustomerPlan_min_fields: CustomerPlan_Min_Fields;
  CustomerPlan_min_order_by: CustomerPlan_Min_Order_By;
  CustomerPlan_mutation_response: CustomerPlan_Mutation_Response;
  CustomerPlan_obj_rel_insert_input: CustomerPlan_Obj_Rel_Insert_Input;
  CustomerPlan_on_conflict: CustomerPlan_On_Conflict;
  CustomerPlan_order_by: CustomerPlan_Order_By;
  CustomerPlan_pk_columns_input: CustomerPlan_Pk_Columns_Input;
  CustomerPlan_set_input: CustomerPlan_Set_Input;
  CustomerPlan_stddev_fields: CustomerPlan_Stddev_Fields;
  CustomerPlan_stddev_order_by: CustomerPlan_Stddev_Order_By;
  CustomerPlan_stddev_pop_fields: CustomerPlan_Stddev_Pop_Fields;
  CustomerPlan_stddev_pop_order_by: CustomerPlan_Stddev_Pop_Order_By;
  CustomerPlan_stddev_samp_fields: CustomerPlan_Stddev_Samp_Fields;
  CustomerPlan_stddev_samp_order_by: CustomerPlan_Stddev_Samp_Order_By;
  CustomerPlan_stream_cursor_input: CustomerPlan_Stream_Cursor_Input;
  CustomerPlan_stream_cursor_value_input: CustomerPlan_Stream_Cursor_Value_Input;
  CustomerPlan_sum_fields: CustomerPlan_Sum_Fields;
  CustomerPlan_sum_order_by: CustomerPlan_Sum_Order_By;
  CustomerPlan_updates: CustomerPlan_Updates;
  CustomerPlan_var_pop_fields: CustomerPlan_Var_Pop_Fields;
  CustomerPlan_var_pop_order_by: CustomerPlan_Var_Pop_Order_By;
  CustomerPlan_var_samp_fields: CustomerPlan_Var_Samp_Fields;
  CustomerPlan_var_samp_order_by: CustomerPlan_Var_Samp_Order_By;
  CustomerPlan_variance_fields: CustomerPlan_Variance_Fields;
  CustomerPlan_variance_order_by: CustomerPlan_Variance_Order_By;
  CustomerRelationship: CustomerRelationship;
  CustomerRelationship_aggregate: CustomerRelationship_Aggregate;
  CustomerRelationship_aggregate_fields: CustomerRelationship_Aggregate_Fields;
  CustomerRelationship_bool_exp: CustomerRelationship_Bool_Exp;
  CustomerRelationship_insert_input: CustomerRelationship_Insert_Input;
  CustomerRelationship_max_fields: CustomerRelationship_Max_Fields;
  CustomerRelationship_min_fields: CustomerRelationship_Min_Fields;
  CustomerRelationship_mutation_response: CustomerRelationship_Mutation_Response;
  CustomerRelationship_on_conflict: CustomerRelationship_On_Conflict;
  CustomerRelationship_order_by: CustomerRelationship_Order_By;
  CustomerRelationship_pk_columns_input: CustomerRelationship_Pk_Columns_Input;
  CustomerRelationship_set_input: CustomerRelationship_Set_Input;
  CustomerRelationship_stream_cursor_input: CustomerRelationship_Stream_Cursor_Input;
  CustomerRelationship_stream_cursor_value_input: CustomerRelationship_Stream_Cursor_Value_Input;
  CustomerRelationship_updates: CustomerRelationship_Updates;
  CustomerUsingContracts: CustomerUsingContracts;
  Customer_aggregate: Customer_Aggregate;
  Customer_aggregate_bool_exp: Customer_Aggregate_Bool_Exp;
  Customer_aggregate_bool_exp_bool_and: Customer_Aggregate_Bool_Exp_Bool_And;
  Customer_aggregate_bool_exp_bool_or: Customer_Aggregate_Bool_Exp_Bool_Or;
  Customer_aggregate_bool_exp_count: Customer_Aggregate_Bool_Exp_Count;
  Customer_aggregate_fields: Customer_Aggregate_Fields;
  Customer_aggregate_order_by: Customer_Aggregate_Order_By;
  Customer_arr_rel_insert_input: Customer_Arr_Rel_Insert_Input;
  Customer_bool_exp: Customer_Bool_Exp;
  Customer_insert_input: Customer_Insert_Input;
  Customer_max_fields: Customer_Max_Fields;
  Customer_max_order_by: Customer_Max_Order_By;
  Customer_min_fields: Customer_Min_Fields;
  Customer_min_order_by: Customer_Min_Order_By;
  Customer_mutation_response: Customer_Mutation_Response;
  Customer_obj_rel_insert_input: Customer_Obj_Rel_Insert_Input;
  Customer_on_conflict: Customer_On_Conflict;
  Customer_order_by: Customer_Order_By;
  Customer_pk_columns_input: Customer_Pk_Columns_Input;
  Customer_set_input: Customer_Set_Input;
  Customer_stream_cursor_input: Customer_Stream_Cursor_Input;
  Customer_stream_cursor_value_input: Customer_Stream_Cursor_Value_Input;
  Customer_updates: Customer_Updates;
  CustomersMetric: CustomersMetric;
  CustomersUsingContracts: CustomersUsingContracts;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange: CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse: CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse;
  Discount: Omit<Discount, 'product'> & { product: ResolversParentTypes['ProductListItem'] };
  DiscountInput: DiscountInput;
  DiscountSchedule: DiscountSchedule;
  DiscountScheduleInput: DiscountScheduleInput;
  DiscountScheduleItem: DiscountScheduleItem;
  DiscountScheduleItemInput: DiscountScheduleItemInput;
  DraftPlan: DraftPlan;
  DraftPlan_aggregate: DraftPlan_Aggregate;
  DraftPlan_aggregate_fields: DraftPlan_Aggregate_Fields;
  DraftPlan_append_input: DraftPlan_Append_Input;
  DraftPlan_avg_fields: DraftPlan_Avg_Fields;
  DraftPlan_bool_exp: DraftPlan_Bool_Exp;
  DraftPlan_delete_at_path_input: DraftPlan_Delete_At_Path_Input;
  DraftPlan_delete_elem_input: DraftPlan_Delete_Elem_Input;
  DraftPlan_delete_key_input: DraftPlan_Delete_Key_Input;
  DraftPlan_inc_input: DraftPlan_Inc_Input;
  DraftPlan_insert_input: DraftPlan_Insert_Input;
  DraftPlan_max_fields: DraftPlan_Max_Fields;
  DraftPlan_min_fields: DraftPlan_Min_Fields;
  DraftPlan_mutation_response: DraftPlan_Mutation_Response;
  DraftPlan_on_conflict: DraftPlan_On_Conflict;
  DraftPlan_order_by: DraftPlan_Order_By;
  DraftPlan_pk_columns_input: DraftPlan_Pk_Columns_Input;
  DraftPlan_prepend_input: DraftPlan_Prepend_Input;
  DraftPlan_set_input: DraftPlan_Set_Input;
  DraftPlan_stddev_fields: DraftPlan_Stddev_Fields;
  DraftPlan_stddev_pop_fields: DraftPlan_Stddev_Pop_Fields;
  DraftPlan_stddev_samp_fields: DraftPlan_Stddev_Samp_Fields;
  DraftPlan_stream_cursor_input: DraftPlan_Stream_Cursor_Input;
  DraftPlan_stream_cursor_value_input: DraftPlan_Stream_Cursor_Value_Input;
  DraftPlan_sum_fields: DraftPlan_Sum_Fields;
  DraftPlan_updates: DraftPlan_Updates;
  DraftPlan_var_pop_fields: DraftPlan_Var_Pop_Fields;
  DraftPlan_var_samp_fields: DraftPlan_Var_Samp_Fields;
  DraftPlan_variance_fields: DraftPlan_Variance_Fields;
  EditPlanResponse: EditPlanResponse;
  EffectiveDates: EffectiveDates;
  Environment: Environment;
  EnvironmentTypeEnum: EnvironmentTypeEnum;
  EnvironmentTypeEnum_aggregate: EnvironmentTypeEnum_Aggregate;
  EnvironmentTypeEnum_aggregate_fields: EnvironmentTypeEnum_Aggregate_Fields;
  EnvironmentTypeEnum_bool_exp: EnvironmentTypeEnum_Bool_Exp;
  EnvironmentTypeEnum_enum_comparison_exp: EnvironmentTypeEnum_Enum_Comparison_Exp;
  EnvironmentTypeEnum_insert_input: EnvironmentTypeEnum_Insert_Input;
  EnvironmentTypeEnum_max_fields: EnvironmentTypeEnum_Max_Fields;
  EnvironmentTypeEnum_min_fields: EnvironmentTypeEnum_Min_Fields;
  EnvironmentTypeEnum_mutation_response: EnvironmentTypeEnum_Mutation_Response;
  EnvironmentTypeEnum_obj_rel_insert_input: EnvironmentTypeEnum_Obj_Rel_Insert_Input;
  EnvironmentTypeEnum_on_conflict: EnvironmentTypeEnum_On_Conflict;
  EnvironmentTypeEnum_order_by: EnvironmentTypeEnum_Order_By;
  EnvironmentTypeEnum_pk_columns_input: EnvironmentTypeEnum_Pk_Columns_Input;
  EnvironmentTypeEnum_set_input: EnvironmentTypeEnum_Set_Input;
  EnvironmentTypeEnum_stream_cursor_input: EnvironmentTypeEnum_Stream_Cursor_Input;
  EnvironmentTypeEnum_stream_cursor_value_input: EnvironmentTypeEnum_Stream_Cursor_Value_Input;
  EnvironmentTypeEnum_updates: EnvironmentTypeEnum_Updates;
  Event: Event;
  EventCountWindow: EventCountWindow;
  FailedMarketplacesMeteringRecord: FailedMarketplacesMeteringRecord;
  FailedMarketplacesMeteringRecordList: FailedMarketplacesMeteringRecordList;
  FancyMetricInput: FancyMetricInput;
  FancyMetricValidationResponse: FancyMetricValidationResponse;
  FixedOrProServiceProductListItem: ResolversParentTypes['FixedProductListItem'] | ResolversParentTypes['ProServiceProductListItem'];
  FixedProductListItem: FixedProductListItem;
  FixedProductListItemState: FixedProductListItemState;
  FlatFee: FlatFee;
  FlatFeeAdjustment: FlatFeeAdjustment;
  FlatFeeAdjustment_aggregate: FlatFeeAdjustment_Aggregate;
  FlatFeeAdjustment_aggregate_bool_exp: FlatFeeAdjustment_Aggregate_Bool_Exp;
  FlatFeeAdjustment_aggregate_bool_exp_count: FlatFeeAdjustment_Aggregate_Bool_Exp_Count;
  FlatFeeAdjustment_aggregate_fields: FlatFeeAdjustment_Aggregate_Fields;
  FlatFeeAdjustment_aggregate_order_by: FlatFeeAdjustment_Aggregate_Order_By;
  FlatFeeAdjustment_arr_rel_insert_input: FlatFeeAdjustment_Arr_Rel_Insert_Input;
  FlatFeeAdjustment_avg_fields: FlatFeeAdjustment_Avg_Fields;
  FlatFeeAdjustment_avg_order_by: FlatFeeAdjustment_Avg_Order_By;
  FlatFeeAdjustment_bool_exp: FlatFeeAdjustment_Bool_Exp;
  FlatFeeAdjustment_inc_input: FlatFeeAdjustment_Inc_Input;
  FlatFeeAdjustment_insert_input: FlatFeeAdjustment_Insert_Input;
  FlatFeeAdjustment_max_fields: FlatFeeAdjustment_Max_Fields;
  FlatFeeAdjustment_max_order_by: FlatFeeAdjustment_Max_Order_By;
  FlatFeeAdjustment_min_fields: FlatFeeAdjustment_Min_Fields;
  FlatFeeAdjustment_min_order_by: FlatFeeAdjustment_Min_Order_By;
  FlatFeeAdjustment_mutation_response: FlatFeeAdjustment_Mutation_Response;
  FlatFeeAdjustment_on_conflict: FlatFeeAdjustment_On_Conflict;
  FlatFeeAdjustment_order_by: FlatFeeAdjustment_Order_By;
  FlatFeeAdjustment_pk_columns_input: FlatFeeAdjustment_Pk_Columns_Input;
  FlatFeeAdjustment_set_input: FlatFeeAdjustment_Set_Input;
  FlatFeeAdjustment_stddev_fields: FlatFeeAdjustment_Stddev_Fields;
  FlatFeeAdjustment_stddev_order_by: FlatFeeAdjustment_Stddev_Order_By;
  FlatFeeAdjustment_stddev_pop_fields: FlatFeeAdjustment_Stddev_Pop_Fields;
  FlatFeeAdjustment_stddev_pop_order_by: FlatFeeAdjustment_Stddev_Pop_Order_By;
  FlatFeeAdjustment_stddev_samp_fields: FlatFeeAdjustment_Stddev_Samp_Fields;
  FlatFeeAdjustment_stddev_samp_order_by: FlatFeeAdjustment_Stddev_Samp_Order_By;
  FlatFeeAdjustment_stream_cursor_input: FlatFeeAdjustment_Stream_Cursor_Input;
  FlatFeeAdjustment_stream_cursor_value_input: FlatFeeAdjustment_Stream_Cursor_Value_Input;
  FlatFeeAdjustment_sum_fields: FlatFeeAdjustment_Sum_Fields;
  FlatFeeAdjustment_sum_order_by: FlatFeeAdjustment_Sum_Order_By;
  FlatFeeAdjustment_updates: FlatFeeAdjustment_Updates;
  FlatFeeAdjustment_var_pop_fields: FlatFeeAdjustment_Var_Pop_Fields;
  FlatFeeAdjustment_var_pop_order_by: FlatFeeAdjustment_Var_Pop_Order_By;
  FlatFeeAdjustment_var_samp_fields: FlatFeeAdjustment_Var_Samp_Fields;
  FlatFeeAdjustment_var_samp_order_by: FlatFeeAdjustment_Var_Samp_Order_By;
  FlatFeeAdjustment_variance_fields: FlatFeeAdjustment_Variance_Fields;
  FlatFeeAdjustment_variance_order_by: FlatFeeAdjustment_Variance_Order_By;
  FlatFee_aggregate: FlatFee_Aggregate;
  FlatFee_aggregate_bool_exp: FlatFee_Aggregate_Bool_Exp;
  FlatFee_aggregate_bool_exp_bool_and: FlatFee_Aggregate_Bool_Exp_Bool_And;
  FlatFee_aggregate_bool_exp_bool_or: FlatFee_Aggregate_Bool_Exp_Bool_Or;
  FlatFee_aggregate_bool_exp_count: FlatFee_Aggregate_Bool_Exp_Count;
  FlatFee_aggregate_fields: FlatFee_Aggregate_Fields;
  FlatFee_aggregate_order_by: FlatFee_Aggregate_Order_By;
  FlatFee_arr_rel_insert_input: FlatFee_Arr_Rel_Insert_Input;
  FlatFee_avg_fields: FlatFee_Avg_Fields;
  FlatFee_avg_order_by: FlatFee_Avg_Order_By;
  FlatFee_bool_exp: FlatFee_Bool_Exp;
  FlatFee_inc_input: FlatFee_Inc_Input;
  FlatFee_insert_input: FlatFee_Insert_Input;
  FlatFee_max_fields: FlatFee_Max_Fields;
  FlatFee_max_order_by: FlatFee_Max_Order_By;
  FlatFee_min_fields: FlatFee_Min_Fields;
  FlatFee_min_order_by: FlatFee_Min_Order_By;
  FlatFee_mutation_response: FlatFee_Mutation_Response;
  FlatFee_obj_rel_insert_input: FlatFee_Obj_Rel_Insert_Input;
  FlatFee_on_conflict: FlatFee_On_Conflict;
  FlatFee_order_by: FlatFee_Order_By;
  FlatFee_pk_columns_input: FlatFee_Pk_Columns_Input;
  FlatFee_set_input: FlatFee_Set_Input;
  FlatFee_stddev_fields: FlatFee_Stddev_Fields;
  FlatFee_stddev_order_by: FlatFee_Stddev_Order_By;
  FlatFee_stddev_pop_fields: FlatFee_Stddev_Pop_Fields;
  FlatFee_stddev_pop_order_by: FlatFee_Stddev_Pop_Order_By;
  FlatFee_stddev_samp_fields: FlatFee_Stddev_Samp_Fields;
  FlatFee_stddev_samp_order_by: FlatFee_Stddev_Samp_Order_By;
  FlatFee_stream_cursor_input: FlatFee_Stream_Cursor_Input;
  FlatFee_stream_cursor_value_input: FlatFee_Stream_Cursor_Value_Input;
  FlatFee_sum_fields: FlatFee_Sum_Fields;
  FlatFee_sum_order_by: FlatFee_Sum_Order_By;
  FlatFee_updates: FlatFee_Updates;
  FlatFee_var_pop_fields: FlatFee_Var_Pop_Fields;
  FlatFee_var_pop_order_by: FlatFee_Var_Pop_Order_By;
  FlatFee_var_samp_fields: FlatFee_Var_Samp_Fields;
  FlatFee_var_samp_order_by: FlatFee_Var_Samp_Order_By;
  FlatFee_variance_fields: FlatFee_Variance_Fields;
  FlatFee_variance_order_by: FlatFee_Variance_Order_By;
  FlatKeyValueMap: Scalars['FlatKeyValueMap'];
  FlatRate: FlatRate;
  FlatRateInput: FlatRateInput;
  Float: Scalars['Float'];
  FutureInvoiceBehaviorInput: FutureInvoiceBehaviorInput;
  GCPProServiceRoyalty: GcpProServiceRoyalty;
  GCPProServiceRoyaltyUpdate: GcpProServiceRoyaltyUpdate;
  GCPRoyalty: GcpRoyalty;
  GCPRoyaltyUpdate: GcpRoyaltyUpdate;
  GenerateAzureAuthTokenMutationResponse: GenerateAzureAuthTokenMutationResponse;
  GroupedChargeLineItem: Omit<GroupedChargeLineItem, 'sub_line_items'> & { sub_line_items: Maybe<Array<ResolversParentTypes['TierSubLineItem']>> };
  GroupedProductChargeLineItem: Omit<GroupedProductChargeLineItem, 'sub_line_items'> & { sub_line_items: Array<ResolversParentTypes['ProductChargeSubLineItem']> };
  GroupedUsage: GroupedUsage;
  GroupedUsageTuple: GroupedUsageTuple;
  GroupedUsageWindow: GroupedUsageWindow;
  InitialUsageFilter: InitialUsageFilter;
  InsertBillingProviderDeliveryMethodResult: InsertBillingProviderDeliveryMethodResult;
  InsertCustomerBillingProviderConfigurationInput: InsertCustomerBillingProviderConfigurationInput;
  InsertCustomerBillingProviderConfigurationNewCustomerInput: InsertCustomerBillingProviderConfigurationNewCustomerInput;
  InsertCustomerBillingProviderConfigurationResult: InsertCustomerBillingProviderConfigurationResult;
  Int: Scalars['Int'];
  Int_comparison_exp: Int_Comparison_Exp;
  Invoice: Invoice;
  InvoiceAdjustment: InvoiceAdjustment;
  InvoiceCorrectionContractLineItemPointer: InvoiceCorrectionContractLineItemPointer;
  InvoiceCorrectionInput: InvoiceCorrectionInput;
  InvoiceCorrectionItem: InvoiceCorrectionItem;
  InvoiceCorrectionLineItemGroupPointer: InvoiceCorrectionLineItemGroupPointer;
  InvoiceCorrectionLineItemPointer: InvoiceCorrectionLineItemPointer;
  InvoiceCorrectionRecord: Omit<InvoiceCorrectionRecord, 'original_invoice'> & { original_invoice: ResolversParentTypes['CorrectableInvoice'] };
  InvoiceCountsByFinalizationStatusResponse: InvoiceCountsByFinalizationStatusResponse;
  InvoiceExternalStatusEnum: InvoiceExternalStatusEnum;
  InvoiceExternalStatusEnum_aggregate: InvoiceExternalStatusEnum_Aggregate;
  InvoiceExternalStatusEnum_aggregate_fields: InvoiceExternalStatusEnum_Aggregate_Fields;
  InvoiceExternalStatusEnum_bool_exp: InvoiceExternalStatusEnum_Bool_Exp;
  InvoiceExternalStatusEnum_enum_comparison_exp: InvoiceExternalStatusEnum_Enum_Comparison_Exp;
  InvoiceExternalStatusEnum_insert_input: InvoiceExternalStatusEnum_Insert_Input;
  InvoiceExternalStatusEnum_max_fields: InvoiceExternalStatusEnum_Max_Fields;
  InvoiceExternalStatusEnum_min_fields: InvoiceExternalStatusEnum_Min_Fields;
  InvoiceExternalStatusEnum_mutation_response: InvoiceExternalStatusEnum_Mutation_Response;
  InvoiceExternalStatusEnum_on_conflict: InvoiceExternalStatusEnum_On_Conflict;
  InvoiceExternalStatusEnum_order_by: InvoiceExternalStatusEnum_Order_By;
  InvoiceExternalStatusEnum_pk_columns_input: InvoiceExternalStatusEnum_Pk_Columns_Input;
  InvoiceExternalStatusEnum_set_input: InvoiceExternalStatusEnum_Set_Input;
  InvoiceExternalStatusEnum_stream_cursor_input: InvoiceExternalStatusEnum_Stream_Cursor_Input;
  InvoiceExternalStatusEnum_stream_cursor_value_input: InvoiceExternalStatusEnum_Stream_Cursor_Value_Input;
  InvoiceExternalStatusEnum_updates: InvoiceExternalStatusEnum_Updates;
  InvoiceFinalizationInput: InvoiceFinalizationInput;
  InvoiceFinalizationMetadata: InvoiceFinalizationMetadata;
  InvoiceFinalizationProgressCountResponse: InvoiceFinalizationProgressCountResponse;
  InvoiceGenerationOverrides: InvoiceGenerationOverrides;
  InvoiceIdentifier: InvoiceIdentifier;
  InvoiceManagedField: InvoiceManagedField;
  InvoiceManagedField_aggregate: InvoiceManagedField_Aggregate;
  InvoiceManagedField_aggregate_bool_exp: InvoiceManagedField_Aggregate_Bool_Exp;
  InvoiceManagedField_aggregate_bool_exp_bool_and: InvoiceManagedField_Aggregate_Bool_Exp_Bool_And;
  InvoiceManagedField_aggregate_bool_exp_bool_or: InvoiceManagedField_Aggregate_Bool_Exp_Bool_Or;
  InvoiceManagedField_aggregate_bool_exp_count: InvoiceManagedField_Aggregate_Bool_Exp_Count;
  InvoiceManagedField_aggregate_fields: InvoiceManagedField_Aggregate_Fields;
  InvoiceManagedField_aggregate_order_by: InvoiceManagedField_Aggregate_Order_By;
  InvoiceManagedField_arr_rel_insert_input: InvoiceManagedField_Arr_Rel_Insert_Input;
  InvoiceManagedField_bool_exp: InvoiceManagedField_Bool_Exp;
  InvoiceManagedField_insert_input: InvoiceManagedField_Insert_Input;
  InvoiceManagedField_max_fields: InvoiceManagedField_Max_Fields;
  InvoiceManagedField_max_order_by: InvoiceManagedField_Max_Order_By;
  InvoiceManagedField_min_fields: InvoiceManagedField_Min_Fields;
  InvoiceManagedField_min_order_by: InvoiceManagedField_Min_Order_By;
  InvoiceManagedField_mutation_response: InvoiceManagedField_Mutation_Response;
  InvoiceManagedField_on_conflict: InvoiceManagedField_On_Conflict;
  InvoiceManagedField_order_by: InvoiceManagedField_Order_By;
  InvoiceManagedField_pk_columns_input: InvoiceManagedField_Pk_Columns_Input;
  InvoiceManagedField_set_input: InvoiceManagedField_Set_Input;
  InvoiceManagedField_stream_cursor_input: InvoiceManagedField_Stream_Cursor_Input;
  InvoiceManagedField_stream_cursor_value_input: InvoiceManagedField_Stream_Cursor_Value_Input;
  InvoiceManagedField_updates: InvoiceManagedField_Updates;
  InvoicePage: InvoicePage;
  InvoiceSortOrder: InvoiceSortOrder;
  JSON: Scalars['JSON'];
  JSONObject: Scalars['JSONObject'];
  LaunchSoxReportGeneratorMutationResponse: LaunchSoxReportGeneratorMutationResponse;
  LegacyContractLineItem: LegacyContractLineItem;
  LegacyLineItem: LegacyLineItem;
  LineItem: LineItem;
  LineItemChunk: LineItemChunk;
  ListDeliveryMethodsResponse: ListDeliveryMethodsResponse;
  ListDeliveryMethodsWithoutConfigurationForAllClientsResponse: ListDeliveryMethodsWithoutConfigurationForAllClientsResponse;
  MRI_CreditBalance: Mri_CreditBalance;
  MRI_CreditDeductionCreditLedgerEntry: Mri_CreditDeductionCreditLedgerEntry;
  MRI_CreditExpirationCreditLedgerEntry: Mri_CreditExpirationCreditLedgerEntry;
  MRI_CreditGrant: Mri_CreditGrant;
  MRI_CreditGrantCreditLedgerEntry: Mri_CreditGrantCreditLedgerEntry;
  MRI_CreditLedger: Mri_CreditLedger;
  MRI_CreditLedgerEntry: ResolversParentTypes['MRI_CreditDeductionCreditLedgerEntry'] | ResolversParentTypes['MRI_CreditExpirationCreditLedgerEntry'] | ResolversParentTypes['MRI_CreditGrantCreditLedgerEntry'] | ResolversParentTypes['MRI_PendingChargeCreditLedgerEntry'] | ResolversParentTypes['MRI_PendingCreditExpirationCreditLedgerEntry'] | ResolversParentTypes['MRI_PendingRolloverCreditGrantLedgerEntry'] | ResolversParentTypes['MRI_PendingRolloverDeductionLedgerEntry'] | ResolversParentTypes['MRI_RolloverCreditGrantLedgerEntry'] | ResolversParentTypes['MRI_RolloverDeductionLedgerEntry'];
  MRI_GroupedUsage: Mri_GroupedUsage;
  MRI_GroupedUsageTuple: Mri_GroupedUsageTuple;
  MRI_GroupedUsageWindow: Mri_GroupedUsageWindow;
  MRI_Invoice: ResolversParentTypes['AdHocPlanInvoice'] | ResolversParentTypes['AdhocContractUsageInvoice'] | ResolversParentTypes['AdvanceInvoice'] | ResolversParentTypes['ArrearsInvoice'] | ResolversParentTypes['ContractPostpaidTrueupInvoice'] | ResolversParentTypes['ContractProServiceInvoice'] | ResolversParentTypes['ContractRefundInvoice'] | ResolversParentTypes['ContractScheduledInvoice'] | ResolversParentTypes['ContractUsageInvoice'] | ResolversParentTypes['CorrectionInvoice'] | ResolversParentTypes['CreditPurchaseInvoice'] | ResolversParentTypes['ParentInvoice'] | ResolversParentTypes['SeatPurchaseInvoice'];
  MRI_LineItem: ResolversParentTypes['AdvanceChargeLineItem'] | ResolversParentTypes['ChargeLineItem'] | ResolversParentTypes['CompositeChargeLineItem'] | ResolversParentTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractAWSRoyaltyLineItem'] | ResolversParentTypes['ContractAppliedCommitLineItem'] | ResolversParentTypes['ContractChargeLineItem'] | ResolversParentTypes['ContractCommitLineItem'] | ResolversParentTypes['ContractConversionLineItem'] | ResolversParentTypes['ContractDiscountLineItem'] | ResolversParentTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractGCPRoyaltyLineItem'] | ResolversParentTypes['ContractPostpaidTrueupLineItem'] | ResolversParentTypes['ContractProServiceLineItem'] | ResolversParentTypes['ContractRolloverCommitLineItem'] | ResolversParentTypes['ContractSubscriptionLineItem'] | ResolversParentTypes['ContractUsageLineItem'] | ResolversParentTypes['CreditLineItem'] | ResolversParentTypes['CreditPurchaseChargeLineItem'] | ResolversParentTypes['CreditPurchaseLineItem'] | ResolversParentTypes['GroupedChargeLineItem'] | ResolversParentTypes['GroupedProductChargeLineItem'] | ResolversParentTypes['LegacyContractLineItem'] | ResolversParentTypes['LegacyLineItem'] | ResolversParentTypes['MinimumLineItem'] | ResolversParentTypes['OverageLineItem'] | ResolversParentTypes['ProductChargeLineItem'] | ResolversParentTypes['SeatChargeLineItem'] | ResolversParentTypes['TierChargeLineItem'] | ResolversParentTypes['TrialDiscountLineItem'];
  MRI_PendingChargeCreditLedgerEntry: Mri_PendingChargeCreditLedgerEntry;
  MRI_PendingCreditExpirationCreditLedgerEntry: Mri_PendingCreditExpirationCreditLedgerEntry;
  MRI_PendingRolloverCreditGrantLedgerEntry: Mri_PendingRolloverCreditGrantLedgerEntry;
  MRI_PendingRolloverDeductionLedgerEntry: Mri_PendingRolloverDeductionLedgerEntry;
  MRI_RolloverCreditGrantLedgerEntry: Mri_RolloverCreditGrantLedgerEntry;
  MRI_RolloverDeductionLedgerEntry: Mri_RolloverDeductionLedgerEntry;
  MRI_UsageGroupByInput: Mri_UsageGroupByInput;
  ManagedEntityEnum: ManagedEntityEnum;
  ManagedEntityEnum_aggregate: ManagedEntityEnum_Aggregate;
  ManagedEntityEnum_aggregate_fields: ManagedEntityEnum_Aggregate_Fields;
  ManagedEntityEnum_bool_exp: ManagedEntityEnum_Bool_Exp;
  ManagedEntityEnum_enum_comparison_exp: ManagedEntityEnum_Enum_Comparison_Exp;
  ManagedEntityEnum_insert_input: ManagedEntityEnum_Insert_Input;
  ManagedEntityEnum_max_fields: ManagedEntityEnum_Max_Fields;
  ManagedEntityEnum_min_fields: ManagedEntityEnum_Min_Fields;
  ManagedEntityEnum_mutation_response: ManagedEntityEnum_Mutation_Response;
  ManagedEntityEnum_on_conflict: ManagedEntityEnum_On_Conflict;
  ManagedEntityEnum_order_by: ManagedEntityEnum_Order_By;
  ManagedEntityEnum_pk_columns_input: ManagedEntityEnum_Pk_Columns_Input;
  ManagedEntityEnum_set_input: ManagedEntityEnum_Set_Input;
  ManagedEntityEnum_stream_cursor_input: ManagedEntityEnum_Stream_Cursor_Input;
  ManagedEntityEnum_stream_cursor_value_input: ManagedEntityEnum_Stream_Cursor_Value_Input;
  ManagedEntityEnum_updates: ManagedEntityEnum_Updates;
  ManagedFieldInput: ManagedFieldInput;
  ManagedFieldKey: ManagedFieldKey;
  ManagedFieldKey_aggregate: ManagedFieldKey_Aggregate;
  ManagedFieldKey_aggregate_fields: ManagedFieldKey_Aggregate_Fields;
  ManagedFieldKey_bool_exp: ManagedFieldKey_Bool_Exp;
  ManagedFieldKey_insert_input: ManagedFieldKey_Insert_Input;
  ManagedFieldKey_max_fields: ManagedFieldKey_Max_Fields;
  ManagedFieldKey_min_fields: ManagedFieldKey_Min_Fields;
  ManagedFieldKey_mutation_response: ManagedFieldKey_Mutation_Response;
  ManagedFieldKey_obj_rel_insert_input: ManagedFieldKey_Obj_Rel_Insert_Input;
  ManagedFieldKey_on_conflict: ManagedFieldKey_On_Conflict;
  ManagedFieldKey_order_by: ManagedFieldKey_Order_By;
  ManagedFieldKey_pk_columns_input: ManagedFieldKey_Pk_Columns_Input;
  ManagedFieldKey_set_input: ManagedFieldKey_Set_Input;
  ManagedFieldKey_stream_cursor_input: ManagedFieldKey_Stream_Cursor_Input;
  ManagedFieldKey_stream_cursor_value_input: ManagedFieldKey_Stream_Cursor_Value_Input;
  ManagedFieldKey_updates: ManagedFieldKey_Updates;
  ManagedFieldVendorMapping: ManagedFieldVendorMapping;
  ManagedFieldVendorMapping_aggregate: ManagedFieldVendorMapping_Aggregate;
  ManagedFieldVendorMapping_aggregate_fields: ManagedFieldVendorMapping_Aggregate_Fields;
  ManagedFieldVendorMapping_bool_exp: ManagedFieldVendorMapping_Bool_Exp;
  ManagedFieldVendorMapping_insert_input: ManagedFieldVendorMapping_Insert_Input;
  ManagedFieldVendorMapping_max_fields: ManagedFieldVendorMapping_Max_Fields;
  ManagedFieldVendorMapping_min_fields: ManagedFieldVendorMapping_Min_Fields;
  ManagedFieldVendorMapping_mutation_response: ManagedFieldVendorMapping_Mutation_Response;
  ManagedFieldVendorMapping_on_conflict: ManagedFieldVendorMapping_On_Conflict;
  ManagedFieldVendorMapping_order_by: ManagedFieldVendorMapping_Order_By;
  ManagedFieldVendorMapping_pk_columns_input: ManagedFieldVendorMapping_Pk_Columns_Input;
  ManagedFieldVendorMapping_set_input: ManagedFieldVendorMapping_Set_Input;
  ManagedFieldVendorMapping_stream_cursor_input: ManagedFieldVendorMapping_Stream_Cursor_Input;
  ManagedFieldVendorMapping_stream_cursor_value_input: ManagedFieldVendorMapping_Stream_Cursor_Value_Input;
  ManagedFieldVendorMapping_updates: ManagedFieldVendorMapping_Updates;
  ManagedVendorEnum: ManagedVendorEnum;
  ManagedVendorEnum_aggregate: ManagedVendorEnum_Aggregate;
  ManagedVendorEnum_aggregate_fields: ManagedVendorEnum_Aggregate_Fields;
  ManagedVendorEnum_bool_exp: ManagedVendorEnum_Bool_Exp;
  ManagedVendorEnum_enum_comparison_exp: ManagedVendorEnum_Enum_Comparison_Exp;
  ManagedVendorEnum_insert_input: ManagedVendorEnum_Insert_Input;
  ManagedVendorEnum_max_fields: ManagedVendorEnum_Max_Fields;
  ManagedVendorEnum_min_fields: ManagedVendorEnum_Min_Fields;
  ManagedVendorEnum_mutation_response: ManagedVendorEnum_Mutation_Response;
  ManagedVendorEnum_on_conflict: ManagedVendorEnum_On_Conflict;
  ManagedVendorEnum_order_by: ManagedVendorEnum_Order_By;
  ManagedVendorEnum_pk_columns_input: ManagedVendorEnum_Pk_Columns_Input;
  ManagedVendorEnum_set_input: ManagedVendorEnum_Set_Input;
  ManagedVendorEnum_stream_cursor_input: ManagedVendorEnum_Stream_Cursor_Input;
  ManagedVendorEnum_stream_cursor_value_input: ManagedVendorEnum_Stream_Cursor_Value_Input;
  ManagedVendorEnum_updates: ManagedVendorEnum_Updates;
  MaxAmountRolloverAmountInput: MaxAmountRolloverAmountInput;
  MaxPercentageRolloverAmountInput: MaxPercentageRolloverAmountInput;
  MetricValidationResponse: MetricValidationResponse;
  Minimum: Minimum;
  MinimumInput: MinimumInput;
  MinimumLineItem: MinimumLineItem;
  Minimum_aggregate: Minimum_Aggregate;
  Minimum_aggregate_bool_exp: Minimum_Aggregate_Bool_Exp;
  Minimum_aggregate_bool_exp_count: Minimum_Aggregate_Bool_Exp_Count;
  Minimum_aggregate_fields: Minimum_Aggregate_Fields;
  Minimum_aggregate_order_by: Minimum_Aggregate_Order_By;
  Minimum_arr_rel_insert_input: Minimum_Arr_Rel_Insert_Input;
  Minimum_avg_fields: Minimum_Avg_Fields;
  Minimum_avg_order_by: Minimum_Avg_Order_By;
  Minimum_bool_exp: Minimum_Bool_Exp;
  Minimum_inc_input: Minimum_Inc_Input;
  Minimum_insert_input: Minimum_Insert_Input;
  Minimum_max_fields: Minimum_Max_Fields;
  Minimum_max_order_by: Minimum_Max_Order_By;
  Minimum_min_fields: Minimum_Min_Fields;
  Minimum_min_order_by: Minimum_Min_Order_By;
  Minimum_mutation_response: Minimum_Mutation_Response;
  Minimum_on_conflict: Minimum_On_Conflict;
  Minimum_order_by: Minimum_Order_By;
  Minimum_pk_columns_input: Minimum_Pk_Columns_Input;
  Minimum_set_input: Minimum_Set_Input;
  Minimum_stddev_fields: Minimum_Stddev_Fields;
  Minimum_stddev_order_by: Minimum_Stddev_Order_By;
  Minimum_stddev_pop_fields: Minimum_Stddev_Pop_Fields;
  Minimum_stddev_pop_order_by: Minimum_Stddev_Pop_Order_By;
  Minimum_stddev_samp_fields: Minimum_Stddev_Samp_Fields;
  Minimum_stddev_samp_order_by: Minimum_Stddev_Samp_Order_By;
  Minimum_stream_cursor_input: Minimum_Stream_Cursor_Input;
  Minimum_stream_cursor_value_input: Minimum_Stream_Cursor_Value_Input;
  Minimum_sum_fields: Minimum_Sum_Fields;
  Minimum_sum_order_by: Minimum_Sum_Order_By;
  Minimum_updates: Minimum_Updates;
  Minimum_var_pop_fields: Minimum_Var_Pop_Fields;
  Minimum_var_pop_order_by: Minimum_Var_Pop_Order_By;
  Minimum_var_samp_fields: Minimum_Var_Samp_Fields;
  Minimum_var_samp_order_by: Minimum_Var_Samp_Order_By;
  Minimum_variance_fields: Minimum_Variance_Fields;
  Minimum_variance_order_by: Minimum_Variance_Order_By;
  MoveRateCardProductsInput: MoveRateCardProductsInput;
  MultiplierOverride: MultiplierOverride;
  Mutation: {};
  MutationResponse: MutationResponse;
  NamedSchedule: NamedSchedule;
  NamedScheduleData: NamedScheduleData;
  NamedScheduleSegment: NamedScheduleSegment;
  NewUsageFilterInput: NewUsageFilterInput;
  NonCompositeProductListItem: ResolversParentTypes['FixedProductListItem'] | ResolversParentTypes['ProServiceProductListItem'] | ResolversParentTypes['SubscriptionProductListItem'] | ResolversParentTypes['UsageProductListItem'];
  NullableTimeRangeInput: NullableTimeRangeInput;
  OneTimeProductCharge: OneTimeProductCharge;
  OverageLineItem: OverageLineItem;
  OverrideRate: ResolversParentTypes['MultiplierOverride'] | ResolversParentTypes['OverwriteOverride'] | ResolversParentTypes['TieredOverride'];
  OverrideRateInput: OverrideRateInput;
  OverrideSpecifier: OverrideSpecifier;
  OverrideSpecifierInput: OverrideSpecifierInput;
  OverwriteOverride: Omit<OverwriteOverride, 'new_rate'> & { new_rate: ResolversParentTypes['Rate'] };
  PagedSeatMetricsResult: PagedSeatMetricsResult;
  PagedUsage: PagedUsage;
  PagedUsageWindow: PagedUsageWindow;
  ParentInvoice: Omit<ParentInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  PendingPrepaidGrant: PendingPrepaidGrant;
  PendingPrepaidGrantsResponse: PendingPrepaidGrantsResponse;
  PendingRechargesResponse: PendingRechargesResponse;
  PepaidCreditGrantResponse: PepaidCreditGrantResponse;
  PercentageRate: PercentageRate;
  PercentageRateInput: PercentageRateInput;
  Plan: Plan;
  PlanInput: PlanInput;
  PlanManagedField: PlanManagedField;
  PlanManagedField_aggregate: PlanManagedField_Aggregate;
  PlanManagedField_aggregate_bool_exp: PlanManagedField_Aggregate_Bool_Exp;
  PlanManagedField_aggregate_bool_exp_bool_and: PlanManagedField_Aggregate_Bool_Exp_Bool_And;
  PlanManagedField_aggregate_bool_exp_bool_or: PlanManagedField_Aggregate_Bool_Exp_Bool_Or;
  PlanManagedField_aggregate_bool_exp_count: PlanManagedField_Aggregate_Bool_Exp_Count;
  PlanManagedField_aggregate_fields: PlanManagedField_Aggregate_Fields;
  PlanManagedField_aggregate_order_by: PlanManagedField_Aggregate_Order_By;
  PlanManagedField_arr_rel_insert_input: PlanManagedField_Arr_Rel_Insert_Input;
  PlanManagedField_bool_exp: PlanManagedField_Bool_Exp;
  PlanManagedField_insert_input: PlanManagedField_Insert_Input;
  PlanManagedField_max_fields: PlanManagedField_Max_Fields;
  PlanManagedField_max_order_by: PlanManagedField_Max_Order_By;
  PlanManagedField_min_fields: PlanManagedField_Min_Fields;
  PlanManagedField_min_order_by: PlanManagedField_Min_Order_By;
  PlanManagedField_mutation_response: PlanManagedField_Mutation_Response;
  PlanManagedField_on_conflict: PlanManagedField_On_Conflict;
  PlanManagedField_order_by: PlanManagedField_Order_By;
  PlanManagedField_pk_columns_input: PlanManagedField_Pk_Columns_Input;
  PlanManagedField_set_input: PlanManagedField_Set_Input;
  PlanManagedField_stream_cursor_input: PlanManagedField_Stream_Cursor_Input;
  PlanManagedField_stream_cursor_value_input: PlanManagedField_Stream_Cursor_Value_Input;
  PlanManagedField_updates: PlanManagedField_Updates;
  Plan_aggregate: Plan_Aggregate;
  Plan_aggregate_bool_exp: Plan_Aggregate_Bool_Exp;
  Plan_aggregate_bool_exp_bool_and: Plan_Aggregate_Bool_Exp_Bool_And;
  Plan_aggregate_bool_exp_bool_or: Plan_Aggregate_Bool_Exp_Bool_Or;
  Plan_aggregate_bool_exp_count: Plan_Aggregate_Bool_Exp_Count;
  Plan_aggregate_fields: Plan_Aggregate_Fields;
  Plan_aggregate_order_by: Plan_Aggregate_Order_By;
  Plan_arr_rel_insert_input: Plan_Arr_Rel_Insert_Input;
  Plan_avg_fields: Plan_Avg_Fields;
  Plan_avg_order_by: Plan_Avg_Order_By;
  Plan_bool_exp: Plan_Bool_Exp;
  Plan_inc_input: Plan_Inc_Input;
  Plan_insert_input: Plan_Insert_Input;
  Plan_max_fields: Plan_Max_Fields;
  Plan_max_order_by: Plan_Max_Order_By;
  Plan_min_fields: Plan_Min_Fields;
  Plan_min_order_by: Plan_Min_Order_By;
  Plan_mutation_response: Plan_Mutation_Response;
  Plan_obj_rel_insert_input: Plan_Obj_Rel_Insert_Input;
  Plan_on_conflict: Plan_On_Conflict;
  Plan_order_by: Plan_Order_By;
  Plan_pk_columns_input: Plan_Pk_Columns_Input;
  Plan_set_input: Plan_Set_Input;
  Plan_stddev_fields: Plan_Stddev_Fields;
  Plan_stddev_order_by: Plan_Stddev_Order_By;
  Plan_stddev_pop_fields: Plan_Stddev_Pop_Fields;
  Plan_stddev_pop_order_by: Plan_Stddev_Pop_Order_By;
  Plan_stddev_samp_fields: Plan_Stddev_Samp_Fields;
  Plan_stddev_samp_order_by: Plan_Stddev_Samp_Order_By;
  Plan_stream_cursor_input: Plan_Stream_Cursor_Input;
  Plan_stream_cursor_value_input: Plan_Stream_Cursor_Value_Input;
  Plan_sum_fields: Plan_Sum_Fields;
  Plan_sum_order_by: Plan_Sum_Order_By;
  Plan_updates: Plan_Updates;
  Plan_var_pop_fields: Plan_Var_Pop_Fields;
  Plan_var_pop_order_by: Plan_Var_Pop_Order_By;
  Plan_var_samp_fields: Plan_Var_Samp_Fields;
  Plan_var_samp_order_by: Plan_Var_Samp_Order_By;
  Plan_variance_fields: Plan_Variance_Fields;
  Plan_variance_order_by: Plan_Variance_Order_By;
  PostpaidCommit: Omit<PostpaidCommit, 'applicable_products' | 'ledger' | 'product'> & { applicable_products: Maybe<Array<ResolversParentTypes['ProductListItem']>>, ledger: Array<ResolversParentTypes['PostpaidCommitLedgerEntry']>, product: ResolversParentTypes['ProductListItem'] };
  PostpaidCommitAutomatedInvoiceDeductionLedgerEntry: PostpaidCommitAutomatedInvoiceDeductionLedgerEntry;
  PostpaidCommitBaseLedgerEntry: ResolversParentTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversParentTypes['PostpaidCommitCanceledLedgerEntry'] | ResolversParentTypes['PostpaidCommitCreditedLedgerEntry'] | ResolversParentTypes['PostpaidCommitExpirationLedgerEntry'] | ResolversParentTypes['PostpaidCommitInitialBalanceLedgerEntry'] | ResolversParentTypes['PostpaidCommitManualLedgerEntry'] | ResolversParentTypes['PostpaidCommitRolloverLedgerEntry'] | ResolversParentTypes['PostpaidCommitTrueupLedgerEntry'];
  PostpaidCommitCanceledLedgerEntry: PostpaidCommitCanceledLedgerEntry;
  PostpaidCommitCreditedLedgerEntry: PostpaidCommitCreditedLedgerEntry;
  PostpaidCommitExpirationLedgerEntry: PostpaidCommitExpirationLedgerEntry;
  PostpaidCommitInitialBalanceLedgerEntry: PostpaidCommitInitialBalanceLedgerEntry;
  PostpaidCommitLedgerEntry: ResolversParentTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversParentTypes['PostpaidCommitCanceledLedgerEntry'] | ResolversParentTypes['PostpaidCommitCreditedLedgerEntry'] | ResolversParentTypes['PostpaidCommitExpirationLedgerEntry'] | ResolversParentTypes['PostpaidCommitInitialBalanceLedgerEntry'] | ResolversParentTypes['PostpaidCommitManualLedgerEntry'] | ResolversParentTypes['PostpaidCommitRolloverLedgerEntry'] | ResolversParentTypes['PostpaidCommitTrueupLedgerEntry'];
  PostpaidCommitManualLedgerEntry: PostpaidCommitManualLedgerEntry;
  PostpaidCommitRolloverLedgerEntry: PostpaidCommitRolloverLedgerEntry;
  PostpaidCommitTrueupLedgerEntry: PostpaidCommitTrueupLedgerEntry;
  PrepaidCommit: Omit<PrepaidCommit, 'applicable_products' | 'ledger' | 'product'> & { applicable_products: Maybe<Array<ResolversParentTypes['ProductListItem']>>, ledger: Array<ResolversParentTypes['PrepaidCommitLedgerEntry']>, product: ResolversParentTypes['ProductListItem'] };
  PrepaidCommitAutomatedInvoiceDeductionLedgerEntry: PrepaidCommitAutomatedInvoiceDeductionLedgerEntry;
  PrepaidCommitBaseLedgerEntry: ResolversParentTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversParentTypes['PrepaidCommitCanceledLedgerEntry'] | ResolversParentTypes['PrepaidCommitCreditedLedgerEntry'] | ResolversParentTypes['PrepaidCommitExpirationLedgerEntry'] | ResolversParentTypes['PrepaidCommitManualLedgerEntry'] | ResolversParentTypes['PrepaidCommitRolloverLedgerEntry'] | ResolversParentTypes['PrepaidCommitSegmentStartLedgerEntry'];
  PrepaidCommitCanceledLedgerEntry: PrepaidCommitCanceledLedgerEntry;
  PrepaidCommitCreditedLedgerEntry: PrepaidCommitCreditedLedgerEntry;
  PrepaidCommitExpirationLedgerEntry: PrepaidCommitExpirationLedgerEntry;
  PrepaidCommitLedgerEntry: ResolversParentTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry'] | ResolversParentTypes['PrepaidCommitCanceledLedgerEntry'] | ResolversParentTypes['PrepaidCommitCreditedLedgerEntry'] | ResolversParentTypes['PrepaidCommitExpirationLedgerEntry'] | ResolversParentTypes['PrepaidCommitManualLedgerEntry'] | ResolversParentTypes['PrepaidCommitRolloverLedgerEntry'] | ResolversParentTypes['PrepaidCommitSegmentStartLedgerEntry'];
  PrepaidCommitManualLedgerEntry: PrepaidCommitManualLedgerEntry;
  PrepaidCommitRolloverLedgerEntry: PrepaidCommitRolloverLedgerEntry;
  PrepaidCommitSegmentStartLedgerEntry: PrepaidCommitSegmentStartLedgerEntry;
  PrepaidCreditGrant: PrepaidCreditGrant;
  PrepaidCreditGrantInput: PrepaidCreditGrantInput;
  PrepaidCreditGrantRechargeResponse: PrepaidCreditGrantRechargeResponse;
  PrequelAuthTokenResponse: PrequelAuthTokenResponse;
  PresentationGroupValue: PresentationGroupValue;
  PresentationGroupValueInput: PresentationGroupValueInput;
  Price: Price;
  PriceAdjustment: PriceAdjustment;
  PriceAdjustmentInput: PriceAdjustmentInput;
  PriceAdjustmentTypeEnum: PriceAdjustmentTypeEnum;
  PriceAdjustmentTypeEnum_aggregate: PriceAdjustmentTypeEnum_Aggregate;
  PriceAdjustmentTypeEnum_aggregate_fields: PriceAdjustmentTypeEnum_Aggregate_Fields;
  PriceAdjustmentTypeEnum_bool_exp: PriceAdjustmentTypeEnum_Bool_Exp;
  PriceAdjustmentTypeEnum_enum_comparison_exp: PriceAdjustmentTypeEnum_Enum_Comparison_Exp;
  PriceAdjustmentTypeEnum_insert_input: PriceAdjustmentTypeEnum_Insert_Input;
  PriceAdjustmentTypeEnum_max_fields: PriceAdjustmentTypeEnum_Max_Fields;
  PriceAdjustmentTypeEnum_min_fields: PriceAdjustmentTypeEnum_Min_Fields;
  PriceAdjustmentTypeEnum_mutation_response: PriceAdjustmentTypeEnum_Mutation_Response;
  PriceAdjustmentTypeEnum_obj_rel_insert_input: PriceAdjustmentTypeEnum_Obj_Rel_Insert_Input;
  PriceAdjustmentTypeEnum_on_conflict: PriceAdjustmentTypeEnum_On_Conflict;
  PriceAdjustmentTypeEnum_order_by: PriceAdjustmentTypeEnum_Order_By;
  PriceAdjustmentTypeEnum_pk_columns_input: PriceAdjustmentTypeEnum_Pk_Columns_Input;
  PriceAdjustmentTypeEnum_set_input: PriceAdjustmentTypeEnum_Set_Input;
  PriceAdjustmentTypeEnum_stream_cursor_input: PriceAdjustmentTypeEnum_Stream_Cursor_Input;
  PriceAdjustmentTypeEnum_stream_cursor_value_input: PriceAdjustmentTypeEnum_Stream_Cursor_Value_Input;
  PriceAdjustmentTypeEnum_updates: PriceAdjustmentTypeEnum_Updates;
  PriceAdjustment_aggregate: PriceAdjustment_Aggregate;
  PriceAdjustment_aggregate_bool_exp: PriceAdjustment_Aggregate_Bool_Exp;
  PriceAdjustment_aggregate_bool_exp_count: PriceAdjustment_Aggregate_Bool_Exp_Count;
  PriceAdjustment_aggregate_fields: PriceAdjustment_Aggregate_Fields;
  PriceAdjustment_aggregate_order_by: PriceAdjustment_Aggregate_Order_By;
  PriceAdjustment_arr_rel_insert_input: PriceAdjustment_Arr_Rel_Insert_Input;
  PriceAdjustment_avg_fields: PriceAdjustment_Avg_Fields;
  PriceAdjustment_avg_order_by: PriceAdjustment_Avg_Order_By;
  PriceAdjustment_bool_exp: PriceAdjustment_Bool_Exp;
  PriceAdjustment_inc_input: PriceAdjustment_Inc_Input;
  PriceAdjustment_insert_input: PriceAdjustment_Insert_Input;
  PriceAdjustment_max_fields: PriceAdjustment_Max_Fields;
  PriceAdjustment_max_order_by: PriceAdjustment_Max_Order_By;
  PriceAdjustment_min_fields: PriceAdjustment_Min_Fields;
  PriceAdjustment_min_order_by: PriceAdjustment_Min_Order_By;
  PriceAdjustment_mutation_response: PriceAdjustment_Mutation_Response;
  PriceAdjustment_on_conflict: PriceAdjustment_On_Conflict;
  PriceAdjustment_order_by: PriceAdjustment_Order_By;
  PriceAdjustment_pk_columns_input: PriceAdjustment_Pk_Columns_Input;
  PriceAdjustment_set_input: PriceAdjustment_Set_Input;
  PriceAdjustment_stddev_fields: PriceAdjustment_Stddev_Fields;
  PriceAdjustment_stddev_order_by: PriceAdjustment_Stddev_Order_By;
  PriceAdjustment_stddev_pop_fields: PriceAdjustment_Stddev_Pop_Fields;
  PriceAdjustment_stddev_pop_order_by: PriceAdjustment_Stddev_Pop_Order_By;
  PriceAdjustment_stddev_samp_fields: PriceAdjustment_Stddev_Samp_Fields;
  PriceAdjustment_stddev_samp_order_by: PriceAdjustment_Stddev_Samp_Order_By;
  PriceAdjustment_stream_cursor_input: PriceAdjustment_Stream_Cursor_Input;
  PriceAdjustment_stream_cursor_value_input: PriceAdjustment_Stream_Cursor_Value_Input;
  PriceAdjustment_sum_fields: PriceAdjustment_Sum_Fields;
  PriceAdjustment_sum_order_by: PriceAdjustment_Sum_Order_By;
  PriceAdjustment_updates: PriceAdjustment_Updates;
  PriceAdjustment_var_pop_fields: PriceAdjustment_Var_Pop_Fields;
  PriceAdjustment_var_pop_order_by: PriceAdjustment_Var_Pop_Order_By;
  PriceAdjustment_var_samp_fields: PriceAdjustment_Var_Samp_Fields;
  PriceAdjustment_var_samp_order_by: PriceAdjustment_Var_Samp_Order_By;
  PriceAdjustment_variance_fields: PriceAdjustment_Variance_Fields;
  PriceAdjustment_variance_order_by: PriceAdjustment_Variance_Order_By;
  PriceInput: PriceInput;
  Price_aggregate: Price_Aggregate;
  Price_aggregate_bool_exp: Price_Aggregate_Bool_Exp;
  Price_aggregate_bool_exp_count: Price_Aggregate_Bool_Exp_Count;
  Price_aggregate_fields: Price_Aggregate_Fields;
  Price_aggregate_order_by: Price_Aggregate_Order_By;
  Price_arr_rel_insert_input: Price_Arr_Rel_Insert_Input;
  Price_avg_fields: Price_Avg_Fields;
  Price_avg_order_by: Price_Avg_Order_By;
  Price_bool_exp: Price_Bool_Exp;
  Price_inc_input: Price_Inc_Input;
  Price_insert_input: Price_Insert_Input;
  Price_max_fields: Price_Max_Fields;
  Price_max_order_by: Price_Max_Order_By;
  Price_min_fields: Price_Min_Fields;
  Price_min_order_by: Price_Min_Order_By;
  Price_mutation_response: Price_Mutation_Response;
  Price_on_conflict: Price_On_Conflict;
  Price_order_by: Price_Order_By;
  Price_pk_columns_input: Price_Pk_Columns_Input;
  Price_set_input: Price_Set_Input;
  Price_stddev_fields: Price_Stddev_Fields;
  Price_stddev_order_by: Price_Stddev_Order_By;
  Price_stddev_pop_fields: Price_Stddev_Pop_Fields;
  Price_stddev_pop_order_by: Price_Stddev_Pop_Order_By;
  Price_stddev_samp_fields: Price_Stddev_Samp_Fields;
  Price_stddev_samp_order_by: Price_Stddev_Samp_Order_By;
  Price_stream_cursor_input: Price_Stream_Cursor_Input;
  Price_stream_cursor_value_input: Price_Stream_Cursor_Value_Input;
  Price_sum_fields: Price_Sum_Fields;
  Price_sum_order_by: Price_Sum_Order_By;
  Price_updates: Price_Updates;
  Price_var_pop_fields: Price_Var_Pop_Fields;
  Price_var_pop_order_by: Price_Var_Pop_Order_By;
  Price_var_samp_fields: Price_Var_Samp_Fields;
  Price_var_samp_order_by: Price_Var_Samp_Order_By;
  Price_variance_fields: Price_Variance_Fields;
  Price_variance_order_by: Price_Variance_Order_By;
  PricedProduct: PricedProduct;
  PricedProductAdjustment: PricedProductAdjustment;
  PricedProductAdjustment_aggregate: PricedProductAdjustment_Aggregate;
  PricedProductAdjustment_aggregate_bool_exp: PricedProductAdjustment_Aggregate_Bool_Exp;
  PricedProductAdjustment_aggregate_bool_exp_count: PricedProductAdjustment_Aggregate_Bool_Exp_Count;
  PricedProductAdjustment_aggregate_fields: PricedProductAdjustment_Aggregate_Fields;
  PricedProductAdjustment_aggregate_order_by: PricedProductAdjustment_Aggregate_Order_By;
  PricedProductAdjustment_arr_rel_insert_input: PricedProductAdjustment_Arr_Rel_Insert_Input;
  PricedProductAdjustment_avg_fields: PricedProductAdjustment_Avg_Fields;
  PricedProductAdjustment_avg_order_by: PricedProductAdjustment_Avg_Order_By;
  PricedProductAdjustment_bool_exp: PricedProductAdjustment_Bool_Exp;
  PricedProductAdjustment_inc_input: PricedProductAdjustment_Inc_Input;
  PricedProductAdjustment_insert_input: PricedProductAdjustment_Insert_Input;
  PricedProductAdjustment_max_fields: PricedProductAdjustment_Max_Fields;
  PricedProductAdjustment_max_order_by: PricedProductAdjustment_Max_Order_By;
  PricedProductAdjustment_min_fields: PricedProductAdjustment_Min_Fields;
  PricedProductAdjustment_min_order_by: PricedProductAdjustment_Min_Order_By;
  PricedProductAdjustment_mutation_response: PricedProductAdjustment_Mutation_Response;
  PricedProductAdjustment_on_conflict: PricedProductAdjustment_On_Conflict;
  PricedProductAdjustment_order_by: PricedProductAdjustment_Order_By;
  PricedProductAdjustment_pk_columns_input: PricedProductAdjustment_Pk_Columns_Input;
  PricedProductAdjustment_set_input: PricedProductAdjustment_Set_Input;
  PricedProductAdjustment_stddev_fields: PricedProductAdjustment_Stddev_Fields;
  PricedProductAdjustment_stddev_order_by: PricedProductAdjustment_Stddev_Order_By;
  PricedProductAdjustment_stddev_pop_fields: PricedProductAdjustment_Stddev_Pop_Fields;
  PricedProductAdjustment_stddev_pop_order_by: PricedProductAdjustment_Stddev_Pop_Order_By;
  PricedProductAdjustment_stddev_samp_fields: PricedProductAdjustment_Stddev_Samp_Fields;
  PricedProductAdjustment_stddev_samp_order_by: PricedProductAdjustment_Stddev_Samp_Order_By;
  PricedProductAdjustment_stream_cursor_input: PricedProductAdjustment_Stream_Cursor_Input;
  PricedProductAdjustment_stream_cursor_value_input: PricedProductAdjustment_Stream_Cursor_Value_Input;
  PricedProductAdjustment_sum_fields: PricedProductAdjustment_Sum_Fields;
  PricedProductAdjustment_sum_order_by: PricedProductAdjustment_Sum_Order_By;
  PricedProductAdjustment_updates: PricedProductAdjustment_Updates;
  PricedProductAdjustment_var_pop_fields: PricedProductAdjustment_Var_Pop_Fields;
  PricedProductAdjustment_var_pop_order_by: PricedProductAdjustment_Var_Pop_Order_By;
  PricedProductAdjustment_var_samp_fields: PricedProductAdjustment_Var_Samp_Fields;
  PricedProductAdjustment_var_samp_order_by: PricedProductAdjustment_Var_Samp_Order_By;
  PricedProductAdjustment_variance_fields: PricedProductAdjustment_Variance_Fields;
  PricedProductAdjustment_variance_order_by: PricedProductAdjustment_Variance_Order_By;
  PricedProductInput: PricedProductInput;
  PricedProductPricingFactor: PricedProductPricingFactor;
  PricedProductPricingFactorAdjustment: PricedProductPricingFactorAdjustment;
  PricedProductPricingFactorAdjustmentInput: PricedProductPricingFactorAdjustmentInput;
  PricedProductPricingFactorAdjustment_aggregate: PricedProductPricingFactorAdjustment_Aggregate;
  PricedProductPricingFactorAdjustment_aggregate_bool_exp: PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp;
  PricedProductPricingFactorAdjustment_aggregate_bool_exp_count: PricedProductPricingFactorAdjustment_Aggregate_Bool_Exp_Count;
  PricedProductPricingFactorAdjustment_aggregate_fields: PricedProductPricingFactorAdjustment_Aggregate_Fields;
  PricedProductPricingFactorAdjustment_aggregate_order_by: PricedProductPricingFactorAdjustment_Aggregate_Order_By;
  PricedProductPricingFactorAdjustment_arr_rel_insert_input: PricedProductPricingFactorAdjustment_Arr_Rel_Insert_Input;
  PricedProductPricingFactorAdjustment_avg_fields: PricedProductPricingFactorAdjustment_Avg_Fields;
  PricedProductPricingFactorAdjustment_avg_order_by: PricedProductPricingFactorAdjustment_Avg_Order_By;
  PricedProductPricingFactorAdjustment_bool_exp: PricedProductPricingFactorAdjustment_Bool_Exp;
  PricedProductPricingFactorAdjustment_inc_input: PricedProductPricingFactorAdjustment_Inc_Input;
  PricedProductPricingFactorAdjustment_insert_input: PricedProductPricingFactorAdjustment_Insert_Input;
  PricedProductPricingFactorAdjustment_max_fields: PricedProductPricingFactorAdjustment_Max_Fields;
  PricedProductPricingFactorAdjustment_max_order_by: PricedProductPricingFactorAdjustment_Max_Order_By;
  PricedProductPricingFactorAdjustment_min_fields: PricedProductPricingFactorAdjustment_Min_Fields;
  PricedProductPricingFactorAdjustment_min_order_by: PricedProductPricingFactorAdjustment_Min_Order_By;
  PricedProductPricingFactorAdjustment_mutation_response: PricedProductPricingFactorAdjustment_Mutation_Response;
  PricedProductPricingFactorAdjustment_obj_rel_insert_input: PricedProductPricingFactorAdjustment_Obj_Rel_Insert_Input;
  PricedProductPricingFactorAdjustment_on_conflict: PricedProductPricingFactorAdjustment_On_Conflict;
  PricedProductPricingFactorAdjustment_order_by: PricedProductPricingFactorAdjustment_Order_By;
  PricedProductPricingFactorAdjustment_pk_columns_input: PricedProductPricingFactorAdjustment_Pk_Columns_Input;
  PricedProductPricingFactorAdjustment_set_input: PricedProductPricingFactorAdjustment_Set_Input;
  PricedProductPricingFactorAdjustment_stddev_fields: PricedProductPricingFactorAdjustment_Stddev_Fields;
  PricedProductPricingFactorAdjustment_stddev_order_by: PricedProductPricingFactorAdjustment_Stddev_Order_By;
  PricedProductPricingFactorAdjustment_stddev_pop_fields: PricedProductPricingFactorAdjustment_Stddev_Pop_Fields;
  PricedProductPricingFactorAdjustment_stddev_pop_order_by: PricedProductPricingFactorAdjustment_Stddev_Pop_Order_By;
  PricedProductPricingFactorAdjustment_stddev_samp_fields: PricedProductPricingFactorAdjustment_Stddev_Samp_Fields;
  PricedProductPricingFactorAdjustment_stddev_samp_order_by: PricedProductPricingFactorAdjustment_Stddev_Samp_Order_By;
  PricedProductPricingFactorAdjustment_stream_cursor_input: PricedProductPricingFactorAdjustment_Stream_Cursor_Input;
  PricedProductPricingFactorAdjustment_stream_cursor_value_input: PricedProductPricingFactorAdjustment_Stream_Cursor_Value_Input;
  PricedProductPricingFactorAdjustment_sum_fields: PricedProductPricingFactorAdjustment_Sum_Fields;
  PricedProductPricingFactorAdjustment_sum_order_by: PricedProductPricingFactorAdjustment_Sum_Order_By;
  PricedProductPricingFactorAdjustment_updates: PricedProductPricingFactorAdjustment_Updates;
  PricedProductPricingFactorAdjustment_var_pop_fields: PricedProductPricingFactorAdjustment_Var_Pop_Fields;
  PricedProductPricingFactorAdjustment_var_pop_order_by: PricedProductPricingFactorAdjustment_Var_Pop_Order_By;
  PricedProductPricingFactorAdjustment_var_samp_fields: PricedProductPricingFactorAdjustment_Var_Samp_Fields;
  PricedProductPricingFactorAdjustment_var_samp_order_by: PricedProductPricingFactorAdjustment_Var_Samp_Order_By;
  PricedProductPricingFactorAdjustment_variance_fields: PricedProductPricingFactorAdjustment_Variance_Fields;
  PricedProductPricingFactorAdjustment_variance_order_by: PricedProductPricingFactorAdjustment_Variance_Order_By;
  PricedProductPricingFactorInput: PricedProductPricingFactorInput;
  PricedProductPricingFactor_aggregate: PricedProductPricingFactor_Aggregate;
  PricedProductPricingFactor_aggregate_bool_exp: PricedProductPricingFactor_Aggregate_Bool_Exp;
  PricedProductPricingFactor_aggregate_bool_exp_bool_and: PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_And;
  PricedProductPricingFactor_aggregate_bool_exp_bool_or: PricedProductPricingFactor_Aggregate_Bool_Exp_Bool_Or;
  PricedProductPricingFactor_aggregate_bool_exp_count: PricedProductPricingFactor_Aggregate_Bool_Exp_Count;
  PricedProductPricingFactor_aggregate_fields: PricedProductPricingFactor_Aggregate_Fields;
  PricedProductPricingFactor_aggregate_order_by: PricedProductPricingFactor_Aggregate_Order_By;
  PricedProductPricingFactor_arr_rel_insert_input: PricedProductPricingFactor_Arr_Rel_Insert_Input;
  PricedProductPricingFactor_avg_fields: PricedProductPricingFactor_Avg_Fields;
  PricedProductPricingFactor_avg_order_by: PricedProductPricingFactor_Avg_Order_By;
  PricedProductPricingFactor_bool_exp: PricedProductPricingFactor_Bool_Exp;
  PricedProductPricingFactor_inc_input: PricedProductPricingFactor_Inc_Input;
  PricedProductPricingFactor_insert_input: PricedProductPricingFactor_Insert_Input;
  PricedProductPricingFactor_max_fields: PricedProductPricingFactor_Max_Fields;
  PricedProductPricingFactor_max_order_by: PricedProductPricingFactor_Max_Order_By;
  PricedProductPricingFactor_min_fields: PricedProductPricingFactor_Min_Fields;
  PricedProductPricingFactor_min_order_by: PricedProductPricingFactor_Min_Order_By;
  PricedProductPricingFactor_mutation_response: PricedProductPricingFactor_Mutation_Response;
  PricedProductPricingFactor_obj_rel_insert_input: PricedProductPricingFactor_Obj_Rel_Insert_Input;
  PricedProductPricingFactor_on_conflict: PricedProductPricingFactor_On_Conflict;
  PricedProductPricingFactor_order_by: PricedProductPricingFactor_Order_By;
  PricedProductPricingFactor_pk_columns_input: PricedProductPricingFactor_Pk_Columns_Input;
  PricedProductPricingFactor_set_input: PricedProductPricingFactor_Set_Input;
  PricedProductPricingFactor_stddev_fields: PricedProductPricingFactor_Stddev_Fields;
  PricedProductPricingFactor_stddev_order_by: PricedProductPricingFactor_Stddev_Order_By;
  PricedProductPricingFactor_stddev_pop_fields: PricedProductPricingFactor_Stddev_Pop_Fields;
  PricedProductPricingFactor_stddev_pop_order_by: PricedProductPricingFactor_Stddev_Pop_Order_By;
  PricedProductPricingFactor_stddev_samp_fields: PricedProductPricingFactor_Stddev_Samp_Fields;
  PricedProductPricingFactor_stddev_samp_order_by: PricedProductPricingFactor_Stddev_Samp_Order_By;
  PricedProductPricingFactor_stream_cursor_input: PricedProductPricingFactor_Stream_Cursor_Input;
  PricedProductPricingFactor_stream_cursor_value_input: PricedProductPricingFactor_Stream_Cursor_Value_Input;
  PricedProductPricingFactor_sum_fields: PricedProductPricingFactor_Sum_Fields;
  PricedProductPricingFactor_sum_order_by: PricedProductPricingFactor_Sum_Order_By;
  PricedProductPricingFactor_updates: PricedProductPricingFactor_Updates;
  PricedProductPricingFactor_var_pop_fields: PricedProductPricingFactor_Var_Pop_Fields;
  PricedProductPricingFactor_var_pop_order_by: PricedProductPricingFactor_Var_Pop_Order_By;
  PricedProductPricingFactor_var_samp_fields: PricedProductPricingFactor_Var_Samp_Fields;
  PricedProductPricingFactor_var_samp_order_by: PricedProductPricingFactor_Var_Samp_Order_By;
  PricedProductPricingFactor_variance_fields: PricedProductPricingFactor_Variance_Fields;
  PricedProductPricingFactor_variance_order_by: PricedProductPricingFactor_Variance_Order_By;
  PricedProduct_aggregate: PricedProduct_Aggregate;
  PricedProduct_aggregate_bool_exp: PricedProduct_Aggregate_Bool_Exp;
  PricedProduct_aggregate_bool_exp_count: PricedProduct_Aggregate_Bool_Exp_Count;
  PricedProduct_aggregate_fields: PricedProduct_Aggregate_Fields;
  PricedProduct_aggregate_order_by: PricedProduct_Aggregate_Order_By;
  PricedProduct_arr_rel_insert_input: PricedProduct_Arr_Rel_Insert_Input;
  PricedProduct_avg_fields: PricedProduct_Avg_Fields;
  PricedProduct_avg_order_by: PricedProduct_Avg_Order_By;
  PricedProduct_bool_exp: PricedProduct_Bool_Exp;
  PricedProduct_inc_input: PricedProduct_Inc_Input;
  PricedProduct_insert_input: PricedProduct_Insert_Input;
  PricedProduct_max_fields: PricedProduct_Max_Fields;
  PricedProduct_max_order_by: PricedProduct_Max_Order_By;
  PricedProduct_min_fields: PricedProduct_Min_Fields;
  PricedProduct_min_order_by: PricedProduct_Min_Order_By;
  PricedProduct_mutation_response: PricedProduct_Mutation_Response;
  PricedProduct_obj_rel_insert_input: PricedProduct_Obj_Rel_Insert_Input;
  PricedProduct_on_conflict: PricedProduct_On_Conflict;
  PricedProduct_order_by: PricedProduct_Order_By;
  PricedProduct_pk_columns_input: PricedProduct_Pk_Columns_Input;
  PricedProduct_set_input: PricedProduct_Set_Input;
  PricedProduct_stddev_fields: PricedProduct_Stddev_Fields;
  PricedProduct_stddev_order_by: PricedProduct_Stddev_Order_By;
  PricedProduct_stddev_pop_fields: PricedProduct_Stddev_Pop_Fields;
  PricedProduct_stddev_pop_order_by: PricedProduct_Stddev_Pop_Order_By;
  PricedProduct_stddev_samp_fields: PricedProduct_Stddev_Samp_Fields;
  PricedProduct_stddev_samp_order_by: PricedProduct_Stddev_Samp_Order_By;
  PricedProduct_stream_cursor_input: PricedProduct_Stream_Cursor_Input;
  PricedProduct_stream_cursor_value_input: PricedProduct_Stream_Cursor_Value_Input;
  PricedProduct_sum_fields: PricedProduct_Sum_Fields;
  PricedProduct_sum_order_by: PricedProduct_Sum_Order_By;
  PricedProduct_updates: PricedProduct_Updates;
  PricedProduct_var_pop_fields: PricedProduct_Var_Pop_Fields;
  PricedProduct_var_pop_order_by: PricedProduct_Var_Pop_Order_By;
  PricedProduct_var_samp_fields: PricedProduct_Var_Samp_Fields;
  PricedProduct_var_samp_order_by: PricedProduct_Var_Samp_Order_By;
  PricedProduct_variance_fields: PricedProduct_Variance_Fields;
  PricedProduct_variance_order_by: PricedProduct_Variance_Order_By;
  PricingFactorInput: PricingFactorInput;
  PricingGroupValue: PricingGroupValue;
  PricingGroupValueInput: PricingGroupValueInput;
  PricingSchemeIdentifier: PricingSchemeIdentifier;
  ProService: ProService;
  ProServiceInput: ProServiceInput;
  ProServiceManagedField: ProServiceManagedField;
  ProServiceManagedField_aggregate: ProServiceManagedField_Aggregate;
  ProServiceManagedField_aggregate_bool_exp: ProServiceManagedField_Aggregate_Bool_Exp;
  ProServiceManagedField_aggregate_bool_exp_bool_and: ProServiceManagedField_Aggregate_Bool_Exp_Bool_And;
  ProServiceManagedField_aggregate_bool_exp_bool_or: ProServiceManagedField_Aggregate_Bool_Exp_Bool_Or;
  ProServiceManagedField_aggregate_bool_exp_count: ProServiceManagedField_Aggregate_Bool_Exp_Count;
  ProServiceManagedField_aggregate_fields: ProServiceManagedField_Aggregate_Fields;
  ProServiceManagedField_aggregate_order_by: ProServiceManagedField_Aggregate_Order_By;
  ProServiceManagedField_arr_rel_insert_input: ProServiceManagedField_Arr_Rel_Insert_Input;
  ProServiceManagedField_bool_exp: ProServiceManagedField_Bool_Exp;
  ProServiceManagedField_insert_input: ProServiceManagedField_Insert_Input;
  ProServiceManagedField_max_fields: ProServiceManagedField_Max_Fields;
  ProServiceManagedField_max_order_by: ProServiceManagedField_Max_Order_By;
  ProServiceManagedField_min_fields: ProServiceManagedField_Min_Fields;
  ProServiceManagedField_min_order_by: ProServiceManagedField_Min_Order_By;
  ProServiceManagedField_mutation_response: ProServiceManagedField_Mutation_Response;
  ProServiceManagedField_on_conflict: ProServiceManagedField_On_Conflict;
  ProServiceManagedField_order_by: ProServiceManagedField_Order_By;
  ProServiceManagedField_pk_columns_input: ProServiceManagedField_Pk_Columns_Input;
  ProServiceManagedField_set_input: ProServiceManagedField_Set_Input;
  ProServiceManagedField_stream_cursor_input: ProServiceManagedField_Stream_Cursor_Input;
  ProServiceManagedField_stream_cursor_value_input: ProServiceManagedField_Stream_Cursor_Value_Input;
  ProServiceManagedField_updates: ProServiceManagedField_Updates;
  ProServiceProductListItem: ProServiceProductListItem;
  ProServiceProductListItemState: ProServiceProductListItemState;
  Product: Product;
  ProductChargeLineItem: Omit<ProductChargeLineItem, 'sub_line_items'> & { sub_line_items: Array<ResolversParentTypes['ProductChargeSubLineItem']> };
  ProductChargeSubLineItem: ResolversParentTypes['AdvanceChargeLineItem'] | ResolversParentTypes['ChargeLineItem'] | ResolversParentTypes['CompositeChargeLineItem'] | ResolversParentTypes['SeatChargeLineItem'];
  ProductListItem: ResolversParentTypes['CompositeProductListItem'] | ResolversParentTypes['FixedProductListItem'] | ResolversParentTypes['ProServiceProductListItem'] | ResolversParentTypes['SubscriptionProductListItem'] | ResolversParentTypes['UsageProductListItem'];
  ProductListItemUpdate: Omit<ProductListItemUpdate, 'composite_products'> & { composite_products: Maybe<Array<ResolversParentTypes['NonCompositeProductListItem']>> };
  ProductManagedField: ProductManagedField;
  ProductManagedField_aggregate: ProductManagedField_Aggregate;
  ProductManagedField_aggregate_bool_exp: ProductManagedField_Aggregate_Bool_Exp;
  ProductManagedField_aggregate_bool_exp_bool_and: ProductManagedField_Aggregate_Bool_Exp_Bool_And;
  ProductManagedField_aggregate_bool_exp_bool_or: ProductManagedField_Aggregate_Bool_Exp_Bool_Or;
  ProductManagedField_aggregate_bool_exp_count: ProductManagedField_Aggregate_Bool_Exp_Count;
  ProductManagedField_aggregate_fields: ProductManagedField_Aggregate_Fields;
  ProductManagedField_aggregate_order_by: ProductManagedField_Aggregate_Order_By;
  ProductManagedField_arr_rel_insert_input: ProductManagedField_Arr_Rel_Insert_Input;
  ProductManagedField_bool_exp: ProductManagedField_Bool_Exp;
  ProductManagedField_insert_input: ProductManagedField_Insert_Input;
  ProductManagedField_max_fields: ProductManagedField_Max_Fields;
  ProductManagedField_max_order_by: ProductManagedField_Max_Order_By;
  ProductManagedField_min_fields: ProductManagedField_Min_Fields;
  ProductManagedField_min_order_by: ProductManagedField_Min_Order_By;
  ProductManagedField_mutation_response: ProductManagedField_Mutation_Response;
  ProductManagedField_on_conflict: ProductManagedField_On_Conflict;
  ProductManagedField_order_by: ProductManagedField_Order_By;
  ProductManagedField_pk_columns_input: ProductManagedField_Pk_Columns_Input;
  ProductManagedField_set_input: ProductManagedField_Set_Input;
  ProductManagedField_stream_cursor_input: ProductManagedField_Stream_Cursor_Input;
  ProductManagedField_stream_cursor_value_input: ProductManagedField_Stream_Cursor_Value_Input;
  ProductManagedField_updates: ProductManagedField_Updates;
  ProductPricingFactor: ProductPricingFactor;
  ProductPricingFactor_aggregate: ProductPricingFactor_Aggregate;
  ProductPricingFactor_aggregate_bool_exp: ProductPricingFactor_Aggregate_Bool_Exp;
  ProductPricingFactor_aggregate_bool_exp_count: ProductPricingFactor_Aggregate_Bool_Exp_Count;
  ProductPricingFactor_aggregate_fields: ProductPricingFactor_Aggregate_Fields;
  ProductPricingFactor_aggregate_order_by: ProductPricingFactor_Aggregate_Order_By;
  ProductPricingFactor_arr_rel_insert_input: ProductPricingFactor_Arr_Rel_Insert_Input;
  ProductPricingFactor_avg_fields: ProductPricingFactor_Avg_Fields;
  ProductPricingFactor_avg_order_by: ProductPricingFactor_Avg_Order_By;
  ProductPricingFactor_bool_exp: ProductPricingFactor_Bool_Exp;
  ProductPricingFactor_inc_input: ProductPricingFactor_Inc_Input;
  ProductPricingFactor_insert_input: ProductPricingFactor_Insert_Input;
  ProductPricingFactor_max_fields: ProductPricingFactor_Max_Fields;
  ProductPricingFactor_max_order_by: ProductPricingFactor_Max_Order_By;
  ProductPricingFactor_min_fields: ProductPricingFactor_Min_Fields;
  ProductPricingFactor_min_order_by: ProductPricingFactor_Min_Order_By;
  ProductPricingFactor_mutation_response: ProductPricingFactor_Mutation_Response;
  ProductPricingFactor_obj_rel_insert_input: ProductPricingFactor_Obj_Rel_Insert_Input;
  ProductPricingFactor_on_conflict: ProductPricingFactor_On_Conflict;
  ProductPricingFactor_order_by: ProductPricingFactor_Order_By;
  ProductPricingFactor_pk_columns_input: ProductPricingFactor_Pk_Columns_Input;
  ProductPricingFactor_set_input: ProductPricingFactor_Set_Input;
  ProductPricingFactor_stddev_fields: ProductPricingFactor_Stddev_Fields;
  ProductPricingFactor_stddev_order_by: ProductPricingFactor_Stddev_Order_By;
  ProductPricingFactor_stddev_pop_fields: ProductPricingFactor_Stddev_Pop_Fields;
  ProductPricingFactor_stddev_pop_order_by: ProductPricingFactor_Stddev_Pop_Order_By;
  ProductPricingFactor_stddev_samp_fields: ProductPricingFactor_Stddev_Samp_Fields;
  ProductPricingFactor_stddev_samp_order_by: ProductPricingFactor_Stddev_Samp_Order_By;
  ProductPricingFactor_stream_cursor_input: ProductPricingFactor_Stream_Cursor_Input;
  ProductPricingFactor_stream_cursor_value_input: ProductPricingFactor_Stream_Cursor_Value_Input;
  ProductPricingFactor_sum_fields: ProductPricingFactor_Sum_Fields;
  ProductPricingFactor_sum_order_by: ProductPricingFactor_Sum_Order_By;
  ProductPricingFactor_updates: ProductPricingFactor_Updates;
  ProductPricingFactor_var_pop_fields: ProductPricingFactor_Var_Pop_Fields;
  ProductPricingFactor_var_pop_order_by: ProductPricingFactor_Var_Pop_Order_By;
  ProductPricingFactor_var_samp_fields: ProductPricingFactor_Var_Samp_Fields;
  ProductPricingFactor_var_samp_order_by: ProductPricingFactor_Var_Samp_Order_By;
  ProductPricingFactor_variance_fields: ProductPricingFactor_Variance_Fields;
  ProductPricingFactor_variance_order_by: ProductPricingFactor_Variance_Order_By;
  Product_aggregate: Product_Aggregate;
  Product_aggregate_fields: Product_Aggregate_Fields;
  Product_bool_exp: Product_Bool_Exp;
  Product_insert_input: Product_Insert_Input;
  Product_max_fields: Product_Max_Fields;
  Product_min_fields: Product_Min_Fields;
  Product_mutation_response: Product_Mutation_Response;
  Product_obj_rel_insert_input: Product_Obj_Rel_Insert_Input;
  Product_on_conflict: Product_On_Conflict;
  Product_order_by: Product_Order_By;
  Product_pk_columns_input: Product_Pk_Columns_Input;
  Product_set_input: Product_Set_Input;
  Product_stream_cursor_input: Product_Stream_Cursor_Input;
  Product_stream_cursor_value_input: Product_Stream_Cursor_Value_Input;
  Product_updates: Product_Updates;
  ProductsAndRateCards: Omit<ProductsAndRateCards, 'product' | 'products'> & { product: ResolversParentTypes['ProductListItem'], products: Array<ResolversParentTypes['ProductListItem']> };
  ProductsSortingInput: ProductsSortingInput;
  QuantityConversion: QuantityConversion;
  QuantityConversionInput: QuantityConversionInput;
  QuantityConversionUpdate: QuantityConversionUpdate;
  QuantityRounding: QuantityRounding;
  QuantityRoundingInput: QuantityRoundingInput;
  QuantityRoundingUpdate: QuantityRoundingUpdate;
  Query: {};
  Rate: ResolversParentTypes['CustomRate'] | ResolversParentTypes['FlatRate'] | ResolversParentTypes['PercentageRate'] | ResolversParentTypes['SubscriptionRate'] | ResolversParentTypes['TieredRate'];
  RateCard: Omit<RateCard, 'products'> & { products: Array<ResolversParentTypes['ProductListItem']> };
  RateCardAliasData: RateCardAliasData;
  RateCardAliasInput: RateCardAliasInput;
  RateCardAliasScheduleSegment: RateCardAliasScheduleSegment;
  RateCardCreditTypeConversion: RateCardCreditTypeConversion;
  RateCardCreditTypeConversionInput: RateCardCreditTypeConversionInput;
  RateCardEntry: Omit<RateCardEntry, 'product_list_item' | 'rate'> & { product_list_item: ResolversParentTypes['RateCardProductListItem'], rate: ResolversParentTypes['Rate'] };
  RateCardEntryInput: RateCardEntryInput;
  RateCardEntryOverride: Omit<RateCardEntryOverride, 'product' | 'rate'> & { product: Maybe<ResolversParentTypes['ProductListItem']>, rate: Maybe<ResolversParentTypes['OverrideRate']> };
  RateCardEntryOverrideInput: RateCardEntryOverrideInput;
  RateCardEntryPricingGroupValueEntry: RateCardEntryPricingGroupValueEntry;
  RateCardEntryPricingGroupValueInput: RateCardEntryPricingGroupValueInput;
  RateCardManagedField: RateCardManagedField;
  RateCardManagedField_aggregate: RateCardManagedField_Aggregate;
  RateCardManagedField_aggregate_bool_exp: RateCardManagedField_Aggregate_Bool_Exp;
  RateCardManagedField_aggregate_bool_exp_bool_and: RateCardManagedField_Aggregate_Bool_Exp_Bool_And;
  RateCardManagedField_aggregate_bool_exp_bool_or: RateCardManagedField_Aggregate_Bool_Exp_Bool_Or;
  RateCardManagedField_aggregate_bool_exp_count: RateCardManagedField_Aggregate_Bool_Exp_Count;
  RateCardManagedField_aggregate_fields: RateCardManagedField_Aggregate_Fields;
  RateCardManagedField_aggregate_order_by: RateCardManagedField_Aggregate_Order_By;
  RateCardManagedField_arr_rel_insert_input: RateCardManagedField_Arr_Rel_Insert_Input;
  RateCardManagedField_bool_exp: RateCardManagedField_Bool_Exp;
  RateCardManagedField_insert_input: RateCardManagedField_Insert_Input;
  RateCardManagedField_max_fields: RateCardManagedField_Max_Fields;
  RateCardManagedField_max_order_by: RateCardManagedField_Max_Order_By;
  RateCardManagedField_min_fields: RateCardManagedField_Min_Fields;
  RateCardManagedField_min_order_by: RateCardManagedField_Min_Order_By;
  RateCardManagedField_mutation_response: RateCardManagedField_Mutation_Response;
  RateCardManagedField_on_conflict: RateCardManagedField_On_Conflict;
  RateCardManagedField_order_by: RateCardManagedField_Order_By;
  RateCardManagedField_pk_columns_input: RateCardManagedField_Pk_Columns_Input;
  RateCardManagedField_set_input: RateCardManagedField_Set_Input;
  RateCardManagedField_stream_cursor_input: RateCardManagedField_Stream_Cursor_Input;
  RateCardManagedField_stream_cursor_value_input: RateCardManagedField_Stream_Cursor_Value_Input;
  RateCardManagedField_updates: RateCardManagedField_Updates;
  RateCardMetadata: Omit<RateCardMetadata, 'products'> & { products: Array<ResolversParentTypes['ProductListItem']> };
  RateCardProductListItem: ResolversParentTypes['SubscriptionProductListItem'] | ResolversParentTypes['UsageProductListItem'];
  RateCardProductPositionInput: RateCardProductPositionInput;
  RateInput: RateInput;
  RateSchedule: Omit<RateSchedule, 'products_on_segments'> & { products_on_segments: Array<ResolversParentTypes['ProductListItem']> };
  RateScheduleSegment: Omit<RateScheduleSegment, 'product' | 'rate'> & { product: ResolversParentTypes['ProductListItem'], rate: ResolversParentTypes['Rate'] };
  RateScheduleSelector: RateScheduleSelector;
  RatesPerProduct: Omit<RatesPerProduct, 'product'> & { product: ResolversParentTypes['ProductListItem'] };
  RebuildRateSegmentsSelector: RebuildRateSegmentsSelector;
  RechargeSettings: RechargeSettings;
  RechargeSettingsBillingProviderConfig: RechargeSettingsBillingProviderConfig;
  RechargeSettingsBillingProviderConfigInput: RechargeSettingsBillingProviderConfigInput;
  RechargeSettingsInput: RechargeSettingsInput;
  RechargeSettingsStripeConfig: RechargeSettingsStripeConfig;
  RechargeSettingsStripeConfigInput: RechargeSettingsStripeConfigInput;
  RechargeSettingsUpdateAndChargeResponse: RechargeSettingsUpdateAndChargeResponse;
  RecordOfNullableStringArrays: Scalars['RecordOfNullableStringArrays'];
  RecordOfNullableStrings: Scalars['RecordOfNullableStrings'];
  RecordOfStrings: Scalars['RecordOfStrings'];
  RecurringCreditGrant: RecurringCreditGrant;
  RecurringCreditGrantInput: RecurringCreditGrantInput;
  RecurringCreditGrant_aggregate: RecurringCreditGrant_Aggregate;
  RecurringCreditGrant_aggregate_bool_exp: RecurringCreditGrant_Aggregate_Bool_Exp;
  RecurringCreditGrant_aggregate_bool_exp_bool_and: RecurringCreditGrant_Aggregate_Bool_Exp_Bool_And;
  RecurringCreditGrant_aggregate_bool_exp_bool_or: RecurringCreditGrant_Aggregate_Bool_Exp_Bool_Or;
  RecurringCreditGrant_aggregate_bool_exp_count: RecurringCreditGrant_Aggregate_Bool_Exp_Count;
  RecurringCreditGrant_aggregate_fields: RecurringCreditGrant_Aggregate_Fields;
  RecurringCreditGrant_aggregate_order_by: RecurringCreditGrant_Aggregate_Order_By;
  RecurringCreditGrant_arr_rel_insert_input: RecurringCreditGrant_Arr_Rel_Insert_Input;
  RecurringCreditGrant_avg_fields: RecurringCreditGrant_Avg_Fields;
  RecurringCreditGrant_avg_order_by: RecurringCreditGrant_Avg_Order_By;
  RecurringCreditGrant_bool_exp: RecurringCreditGrant_Bool_Exp;
  RecurringCreditGrant_inc_input: RecurringCreditGrant_Inc_Input;
  RecurringCreditGrant_insert_input: RecurringCreditGrant_Insert_Input;
  RecurringCreditGrant_max_fields: RecurringCreditGrant_Max_Fields;
  RecurringCreditGrant_max_order_by: RecurringCreditGrant_Max_Order_By;
  RecurringCreditGrant_min_fields: RecurringCreditGrant_Min_Fields;
  RecurringCreditGrant_min_order_by: RecurringCreditGrant_Min_Order_By;
  RecurringCreditGrant_mutation_response: RecurringCreditGrant_Mutation_Response;
  RecurringCreditGrant_obj_rel_insert_input: RecurringCreditGrant_Obj_Rel_Insert_Input;
  RecurringCreditGrant_on_conflict: RecurringCreditGrant_On_Conflict;
  RecurringCreditGrant_order_by: RecurringCreditGrant_Order_By;
  RecurringCreditGrant_pk_columns_input: RecurringCreditGrant_Pk_Columns_Input;
  RecurringCreditGrant_set_input: RecurringCreditGrant_Set_Input;
  RecurringCreditGrant_stddev_fields: RecurringCreditGrant_Stddev_Fields;
  RecurringCreditGrant_stddev_order_by: RecurringCreditGrant_Stddev_Order_By;
  RecurringCreditGrant_stddev_pop_fields: RecurringCreditGrant_Stddev_Pop_Fields;
  RecurringCreditGrant_stddev_pop_order_by: RecurringCreditGrant_Stddev_Pop_Order_By;
  RecurringCreditGrant_stddev_samp_fields: RecurringCreditGrant_Stddev_Samp_Fields;
  RecurringCreditGrant_stddev_samp_order_by: RecurringCreditGrant_Stddev_Samp_Order_By;
  RecurringCreditGrant_stream_cursor_input: RecurringCreditGrant_Stream_Cursor_Input;
  RecurringCreditGrant_stream_cursor_value_input: RecurringCreditGrant_Stream_Cursor_Value_Input;
  RecurringCreditGrant_sum_fields: RecurringCreditGrant_Sum_Fields;
  RecurringCreditGrant_sum_order_by: RecurringCreditGrant_Sum_Order_By;
  RecurringCreditGrant_updates: RecurringCreditGrant_Updates;
  RecurringCreditGrant_var_pop_fields: RecurringCreditGrant_Var_Pop_Fields;
  RecurringCreditGrant_var_pop_order_by: RecurringCreditGrant_Var_Pop_Order_By;
  RecurringCreditGrant_var_samp_fields: RecurringCreditGrant_Var_Samp_Fields;
  RecurringCreditGrant_var_samp_order_by: RecurringCreditGrant_Var_Samp_Order_By;
  RecurringCreditGrant_variance_fields: RecurringCreditGrant_Variance_Fields;
  RecurringCreditGrant_variance_order_by: RecurringCreditGrant_Variance_Order_By;
  RecurringSchedule: RecurringSchedule;
  RecurringScheduleInput: RecurringScheduleInput;
  RepairPrepaidCreditGrantResponse: RepairPrepaidCreditGrantResponse;
  RequiredTimeRangeInput: RequiredTimeRangeInput;
  ResellerRoyalty: ResolversParentTypes['AWSProServiceRoyalty'] | ResolversParentTypes['AWSRoyalty'] | ResolversParentTypes['GCPProServiceRoyalty'] | ResolversParentTypes['GCPRoyalty'];
  ResellerRoyaltyInput: ResellerRoyaltyInput;
  ResellerRoyaltyOrUpdate: ResolversParentTypes['AWSProServiceRoyalty'] | ResolversParentTypes['AWSProServiceRoyaltyUpdate'] | ResolversParentTypes['AWSRoyalty'] | ResolversParentTypes['AWSRoyaltyUpdate'] | ResolversParentTypes['GCPProServiceRoyalty'] | ResolversParentTypes['GCPProServiceRoyaltyUpdate'] | ResolversParentTypes['GCPRoyalty'] | ResolversParentTypes['GCPRoyaltyUpdate'];
  ResellerRoyaltyOrUpdateInput: ResellerRoyaltyOrUpdateInput;
  ResellerRoyaltyUpdate: ResolversParentTypes['AWSProServiceRoyaltyUpdate'] | ResolversParentTypes['AWSRoyaltyUpdate'] | ResolversParentTypes['GCPProServiceRoyaltyUpdate'] | ResolversParentTypes['GCPRoyaltyUpdate'];
  RevenueBreakdown: RevenueBreakdown;
  RevenueMetric: RevenueMetric;
  Role: Role;
  Role_aggregate: Role_Aggregate;
  Role_aggregate_fields: Role_Aggregate_Fields;
  Role_append_input: Role_Append_Input;
  Role_bool_exp: Role_Bool_Exp;
  Role_delete_at_path_input: Role_Delete_At_Path_Input;
  Role_delete_elem_input: Role_Delete_Elem_Input;
  Role_delete_key_input: Role_Delete_Key_Input;
  Role_insert_input: Role_Insert_Input;
  Role_max_fields: Role_Max_Fields;
  Role_min_fields: Role_Min_Fields;
  Role_mutation_response: Role_Mutation_Response;
  Role_obj_rel_insert_input: Role_Obj_Rel_Insert_Input;
  Role_on_conflict: Role_On_Conflict;
  Role_order_by: Role_Order_By;
  Role_pk_columns_input: Role_Pk_Columns_Input;
  Role_prepend_input: Role_Prepend_Input;
  Role_set_input: Role_Set_Input;
  Role_stream_cursor_input: Role_Stream_Cursor_Input;
  Role_stream_cursor_value_input: Role_Stream_Cursor_Value_Input;
  Role_updates: Role_Updates;
  RolloverAmount: ResolversParentTypes['RolloverMaxAmount'] | ResolversParentTypes['RolloverMaxPercentage'];
  RolloverAmountInput: RolloverAmountInput;
  RolloverMaxAmount: RolloverMaxAmount;
  RolloverMaxPercentage: RolloverMaxPercentage;
  RolloverSettings: Omit<RolloverSettings, 'rollover_amount'> & { rollover_amount: ResolversParentTypes['RolloverAmount'] };
  RolloverSettingsInput: RolloverSettingsInput;
  RootLineItem: ResolversParentTypes['ContractAWSProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractAWSRoyaltyLineItem'] | ResolversParentTypes['ContractAppliedCommitLineItem'] | ResolversParentTypes['ContractChargeLineItem'] | ResolversParentTypes['ContractCommitLineItem'] | ResolversParentTypes['ContractConversionLineItem'] | ResolversParentTypes['ContractDiscountLineItem'] | ResolversParentTypes['ContractGCPProServiceRoyaltyLineItem'] | ResolversParentTypes['ContractGCPRoyaltyLineItem'] | ResolversParentTypes['ContractPostpaidTrueupLineItem'] | ResolversParentTypes['ContractProServiceLineItem'] | ResolversParentTypes['ContractRolloverCommitLineItem'] | ResolversParentTypes['ContractSubscriptionLineItem'] | ResolversParentTypes['ContractUsageLineItem'] | ResolversParentTypes['CreditLineItem'] | ResolversParentTypes['CreditPurchaseLineItem'] | ResolversParentTypes['GroupedProductChargeLineItem'] | ResolversParentTypes['LegacyContractLineItem'] | ResolversParentTypes['LegacyLineItem'] | ResolversParentTypes['MinimumLineItem'] | ResolversParentTypes['OverageLineItem'] | ResolversParentTypes['ProductChargeLineItem'] | ResolversParentTypes['TrialDiscountLineItem'];
  RoundingBehaviorEnum: RoundingBehaviorEnum;
  RoundingBehaviorEnum_aggregate: RoundingBehaviorEnum_Aggregate;
  RoundingBehaviorEnum_aggregate_fields: RoundingBehaviorEnum_Aggregate_Fields;
  RoundingBehaviorEnum_bool_exp: RoundingBehaviorEnum_Bool_Exp;
  RoundingBehaviorEnum_enum_comparison_exp: RoundingBehaviorEnum_Enum_Comparison_Exp;
  RoundingBehaviorEnum_insert_input: RoundingBehaviorEnum_Insert_Input;
  RoundingBehaviorEnum_max_fields: RoundingBehaviorEnum_Max_Fields;
  RoundingBehaviorEnum_min_fields: RoundingBehaviorEnum_Min_Fields;
  RoundingBehaviorEnum_mutation_response: RoundingBehaviorEnum_Mutation_Response;
  RoundingBehaviorEnum_on_conflict: RoundingBehaviorEnum_On_Conflict;
  RoundingBehaviorEnum_order_by: RoundingBehaviorEnum_Order_By;
  RoundingBehaviorEnum_pk_columns_input: RoundingBehaviorEnum_Pk_Columns_Input;
  RoundingBehaviorEnum_set_input: RoundingBehaviorEnum_Set_Input;
  RoundingBehaviorEnum_stream_cursor_input: RoundingBehaviorEnum_Stream_Cursor_Input;
  RoundingBehaviorEnum_stream_cursor_value_input: RoundingBehaviorEnum_Stream_Cursor_Value_Input;
  RoundingBehaviorEnum_updates: RoundingBehaviorEnum_Updates;
  SaveVendorEntityMappingInput: SaveVendorEntityMappingInput;
  SaveVendorEntityMappingsResponse: SaveVendorEntityMappingsResponse;
  ScalarContractRateScheduleSegment: Omit<ScalarContractRateScheduleSegment, 'list_rate' | 'override_rate'> & { list_rate: ResolversParentTypes['ScalarRate'], override_rate: Maybe<ResolversParentTypes['ScalarRate']> };
  ScalarFlatRate: ScalarFlatRate;
  ScalarRate: ResolversParentTypes['CustomRate'] | ResolversParentTypes['PercentageRate'] | ResolversParentTypes['ScalarFlatRate'] | ResolversParentTypes['SubscriptionRate'] | ResolversParentTypes['TieredRate'];
  ScalarRateScheduleSegment: Omit<ScalarRateScheduleSegment, 'rate'> & { rate: ResolversParentTypes['ScalarRate'] };
  Scalar__ContractRateScheduleSegment: Scalars['Scalar__ContractRateScheduleSegment'];
  Scalar__RateScheduleSegment: Scalars['Scalar__RateScheduleSegment'];
  ScheduledCharge: Omit<ScheduledCharge, 'product'> & { product: ResolversParentTypes['ProductListItem'] };
  ScheduledChargeInput: ScheduledChargeInput;
  ScheduledChargeManagedField: ScheduledChargeManagedField;
  ScheduledChargeManagedField_aggregate: ScheduledChargeManagedField_Aggregate;
  ScheduledChargeManagedField_aggregate_bool_exp: ScheduledChargeManagedField_Aggregate_Bool_Exp;
  ScheduledChargeManagedField_aggregate_bool_exp_bool_and: ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_And;
  ScheduledChargeManagedField_aggregate_bool_exp_bool_or: ScheduledChargeManagedField_Aggregate_Bool_Exp_Bool_Or;
  ScheduledChargeManagedField_aggregate_bool_exp_count: ScheduledChargeManagedField_Aggregate_Bool_Exp_Count;
  ScheduledChargeManagedField_aggregate_fields: ScheduledChargeManagedField_Aggregate_Fields;
  ScheduledChargeManagedField_aggregate_order_by: ScheduledChargeManagedField_Aggregate_Order_By;
  ScheduledChargeManagedField_arr_rel_insert_input: ScheduledChargeManagedField_Arr_Rel_Insert_Input;
  ScheduledChargeManagedField_bool_exp: ScheduledChargeManagedField_Bool_Exp;
  ScheduledChargeManagedField_insert_input: ScheduledChargeManagedField_Insert_Input;
  ScheduledChargeManagedField_max_fields: ScheduledChargeManagedField_Max_Fields;
  ScheduledChargeManagedField_max_order_by: ScheduledChargeManagedField_Max_Order_By;
  ScheduledChargeManagedField_min_fields: ScheduledChargeManagedField_Min_Fields;
  ScheduledChargeManagedField_min_order_by: ScheduledChargeManagedField_Min_Order_By;
  ScheduledChargeManagedField_mutation_response: ScheduledChargeManagedField_Mutation_Response;
  ScheduledChargeManagedField_on_conflict: ScheduledChargeManagedField_On_Conflict;
  ScheduledChargeManagedField_order_by: ScheduledChargeManagedField_Order_By;
  ScheduledChargeManagedField_pk_columns_input: ScheduledChargeManagedField_Pk_Columns_Input;
  ScheduledChargeManagedField_set_input: ScheduledChargeManagedField_Set_Input;
  ScheduledChargeManagedField_stream_cursor_input: ScheduledChargeManagedField_Stream_Cursor_Input;
  ScheduledChargeManagedField_stream_cursor_value_input: ScheduledChargeManagedField_Stream_Cursor_Value_Input;
  ScheduledChargeManagedField_updates: ScheduledChargeManagedField_Updates;
  ScheduledChargeSchedule: ScheduledChargeSchedule;
  ScheduledChargeScheduleInput: ScheduledChargeScheduleInput;
  ScheduledChargeScheduleItem: ScheduledChargeScheduleItem;
  ScheduledChargeScheduleItemInput: ScheduledChargeScheduleItemInput;
  ScheduledWebhookNotification: ScheduledWebhookNotification;
  ScheduledWebhookNotificationList: ScheduledWebhookNotificationList;
  SeatChargeInput: SeatChargeInput;
  SeatChargeLineItem: SeatChargeLineItem;
  SeatCount: SeatCount;
  SeatMetric: SeatMetric;
  SeatMetricInput: SeatMetricInput;
  SeatMetricSortingInput: SeatMetricSortingInput;
  SeatPrice: SeatPrice;
  SeatPurchaseInvoice: Omit<SeatPurchaseInvoice, 'line_items'> & { line_items: Array<ResolversParentTypes['RootLineItem']> };
  SequenceNumber: Omit<SequenceNumber, 'original_invoice'> & { original_invoice: ResolversParentTypes['CorrectableInvoice'] };
  SequenceNumberInput: SequenceNumberInput;
  ServicePeriod: ServicePeriod;
  ServicePeriodStartOnEnum: ServicePeriodStartOnEnum;
  ServicePeriodStartOnEnum_aggregate: ServicePeriodStartOnEnum_Aggregate;
  ServicePeriodStartOnEnum_aggregate_fields: ServicePeriodStartOnEnum_Aggregate_Fields;
  ServicePeriodStartOnEnum_bool_exp: ServicePeriodStartOnEnum_Bool_Exp;
  ServicePeriodStartOnEnum_enum_comparison_exp: ServicePeriodStartOnEnum_Enum_Comparison_Exp;
  ServicePeriodStartOnEnum_insert_input: ServicePeriodStartOnEnum_Insert_Input;
  ServicePeriodStartOnEnum_max_fields: ServicePeriodStartOnEnum_Max_Fields;
  ServicePeriodStartOnEnum_min_fields: ServicePeriodStartOnEnum_Min_Fields;
  ServicePeriodStartOnEnum_mutation_response: ServicePeriodStartOnEnum_Mutation_Response;
  ServicePeriodStartOnEnum_on_conflict: ServicePeriodStartOnEnum_On_Conflict;
  ServicePeriodStartOnEnum_order_by: ServicePeriodStartOnEnum_Order_By;
  ServicePeriodStartOnEnum_pk_columns_input: ServicePeriodStartOnEnum_Pk_Columns_Input;
  ServicePeriodStartOnEnum_set_input: ServicePeriodStartOnEnum_Set_Input;
  ServicePeriodStartOnEnum_stream_cursor_input: ServicePeriodStartOnEnum_Stream_Cursor_Input;
  ServicePeriodStartOnEnum_stream_cursor_value_input: ServicePeriodStartOnEnum_Stream_Cursor_Value_Input;
  ServicePeriodStartOnEnum_updates: ServicePeriodStartOnEnum_Updates;
  SimulateFancyMetricUsageInput: SimulateFancyMetricUsageInput;
  SimulatedFancyMetricUsageData: SimulatedFancyMetricUsageData;
  SimulatedFancyMetricUsageResult: SimulatedFancyMetricUsageResult;
  String: Scalars['String'];
  String_array_comparison_exp: String_Array_Comparison_Exp;
  String_comparison_exp: String_Comparison_Exp;
  StripeCreditGrantResponse: StripeCreditGrantResponse;
  StripeCustomer: StripeCustomer;
  StripePaymentData: StripePaymentData;
  StripePrepaidConfig_temporary: StripePrepaidConfig_Temporary;
  StripePrepaidConfig_temporary_aggregate: StripePrepaidConfig_Temporary_Aggregate;
  StripePrepaidConfig_temporary_aggregate_fields: StripePrepaidConfig_Temporary_Aggregate_Fields;
  StripePrepaidConfig_temporary_bool_exp: StripePrepaidConfig_Temporary_Bool_Exp;
  StripePrepaidConfig_temporary_insert_input: StripePrepaidConfig_Temporary_Insert_Input;
  StripePrepaidConfig_temporary_max_fields: StripePrepaidConfig_Temporary_Max_Fields;
  StripePrepaidConfig_temporary_min_fields: StripePrepaidConfig_Temporary_Min_Fields;
  StripePrepaidConfig_temporary_mutation_response: StripePrepaidConfig_Temporary_Mutation_Response;
  StripePrepaidConfig_temporary_on_conflict: StripePrepaidConfig_Temporary_On_Conflict;
  StripePrepaidConfig_temporary_order_by: StripePrepaidConfig_Temporary_Order_By;
  StripePrepaidConfig_temporary_pk_columns_input: StripePrepaidConfig_Temporary_Pk_Columns_Input;
  StripePrepaidConfig_temporary_set_input: StripePrepaidConfig_Temporary_Set_Input;
  StripePrepaidConfig_temporary_stream_cursor_input: StripePrepaidConfig_Temporary_Stream_Cursor_Input;
  StripePrepaidConfig_temporary_stream_cursor_value_input: StripePrepaidConfig_Temporary_Stream_Cursor_Value_Input;
  StripePrepaidConfig_temporary_updates: StripePrepaidConfig_Temporary_Updates;
  SubLineItem: SubLineItem;
  SubLineItemTier: SubLineItemTier;
  Subscription: {};
  SubscriptionProductListItem: SubscriptionProductListItem;
  SubscriptionProductListItemState: SubscriptionProductListItemState;
  SubscriptionRate: SubscriptionRate;
  SubscriptionRateInput: SubscriptionRateInput;
  SubtotalAndQuantityByTime: SubtotalAndQuantityByTime;
  SubtotalBreakdownInput: SubtotalBreakdownInput;
  SubtotalByTime: SubtotalByTime;
  SubtotalInput: SubtotalInput;
  Tier: Tier;
  TierChargeLineItem: TierChargeLineItem;
  TierInput: TierInput;
  TierMetadata: TierMetadata;
  TierPeriod: TierPeriod;
  TierPrice: ResolversParentTypes['FlatFee'] | ResolversParentTypes['Price'];
  TierSubLineItem: ResolversParentTypes['TierChargeLineItem'];
  TieredOverride: TieredOverride;
  TieredOverrideInput: TieredOverrideInput;
  TieredOverrideTiers: TieredOverrideTiers;
  TieredRate: TieredRate;
  TieredRateInput: TieredRateInput;
  TieringModeEnum: TieringModeEnum;
  TieringModeEnum_aggregate: TieringModeEnum_Aggregate;
  TieringModeEnum_aggregate_fields: TieringModeEnum_Aggregate_Fields;
  TieringModeEnum_bool_exp: TieringModeEnum_Bool_Exp;
  TieringModeEnum_enum_comparison_exp: TieringModeEnum_Enum_Comparison_Exp;
  TieringModeEnum_insert_input: TieringModeEnum_Insert_Input;
  TieringModeEnum_max_fields: TieringModeEnum_Max_Fields;
  TieringModeEnum_min_fields: TieringModeEnum_Min_Fields;
  TieringModeEnum_mutation_response: TieringModeEnum_Mutation_Response;
  TieringModeEnum_on_conflict: TieringModeEnum_On_Conflict;
  TieringModeEnum_order_by: TieringModeEnum_Order_By;
  TieringModeEnum_pk_columns_input: TieringModeEnum_Pk_Columns_Input;
  TieringModeEnum_set_input: TieringModeEnum_Set_Input;
  TieringModeEnum_stream_cursor_input: TieringModeEnum_Stream_Cursor_Input;
  TieringModeEnum_stream_cursor_value_input: TieringModeEnum_Stream_Cursor_Value_Input;
  TieringModeEnum_updates: TieringModeEnum_Updates;
  TimeRange: TimeRange;
  TimeRangeInput: TimeRangeInput;
  Tokens: Tokens;
  Tokens_aggregate: Tokens_Aggregate;
  Tokens_aggregate_bool_exp: Tokens_Aggregate_Bool_Exp;
  Tokens_aggregate_bool_exp_count: Tokens_Aggregate_Bool_Exp_Count;
  Tokens_aggregate_fields: Tokens_Aggregate_Fields;
  Tokens_aggregate_order_by: Tokens_Aggregate_Order_By;
  Tokens_arr_rel_insert_input: Tokens_Arr_Rel_Insert_Input;
  Tokens_bool_exp: Tokens_Bool_Exp;
  Tokens_insert_input: Tokens_Insert_Input;
  Tokens_max_fields: Tokens_Max_Fields;
  Tokens_max_order_by: Tokens_Max_Order_By;
  Tokens_min_fields: Tokens_Min_Fields;
  Tokens_min_order_by: Tokens_Min_Order_By;
  Tokens_mutation_response: Tokens_Mutation_Response;
  Tokens_on_conflict: Tokens_On_Conflict;
  Tokens_order_by: Tokens_Order_By;
  Tokens_pk_columns_input: Tokens_Pk_Columns_Input;
  Tokens_set_input: Tokens_Set_Input;
  Tokens_stream_cursor_input: Tokens_Stream_Cursor_Input;
  Tokens_stream_cursor_value_input: Tokens_Stream_Cursor_Value_Input;
  Tokens_updates: Tokens_Updates;
  TopCustomerSpenders: TopCustomerSpenders;
  Transition: Transition;
  TransitionInput: TransitionInput;
  TrialDiscountLineItem: TrialDiscountLineItem;
  TrialSpec: TrialSpec;
  TrialSpecInput: TrialSpecInput;
  TrialSpecSpendingCap: TrialSpecSpendingCap;
  TrialSpecSpendingCapInput: TrialSpecSpendingCapInput;
  TrialSpecSpendingCap_aggregate: TrialSpecSpendingCap_Aggregate;
  TrialSpecSpendingCap_aggregate_bool_exp: TrialSpecSpendingCap_Aggregate_Bool_Exp;
  TrialSpecSpendingCap_aggregate_bool_exp_count: TrialSpecSpendingCap_Aggregate_Bool_Exp_Count;
  TrialSpecSpendingCap_aggregate_fields: TrialSpecSpendingCap_Aggregate_Fields;
  TrialSpecSpendingCap_aggregate_order_by: TrialSpecSpendingCap_Aggregate_Order_By;
  TrialSpecSpendingCap_arr_rel_insert_input: TrialSpecSpendingCap_Arr_Rel_Insert_Input;
  TrialSpecSpendingCap_avg_fields: TrialSpecSpendingCap_Avg_Fields;
  TrialSpecSpendingCap_avg_order_by: TrialSpecSpendingCap_Avg_Order_By;
  TrialSpecSpendingCap_bool_exp: TrialSpecSpendingCap_Bool_Exp;
  TrialSpecSpendingCap_inc_input: TrialSpecSpendingCap_Inc_Input;
  TrialSpecSpendingCap_insert_input: TrialSpecSpendingCap_Insert_Input;
  TrialSpecSpendingCap_max_fields: TrialSpecSpendingCap_Max_Fields;
  TrialSpecSpendingCap_max_order_by: TrialSpecSpendingCap_Max_Order_By;
  TrialSpecSpendingCap_min_fields: TrialSpecSpendingCap_Min_Fields;
  TrialSpecSpendingCap_min_order_by: TrialSpecSpendingCap_Min_Order_By;
  TrialSpecSpendingCap_mutation_response: TrialSpecSpendingCap_Mutation_Response;
  TrialSpecSpendingCap_on_conflict: TrialSpecSpendingCap_On_Conflict;
  TrialSpecSpendingCap_order_by: TrialSpecSpendingCap_Order_By;
  TrialSpecSpendingCap_pk_columns_input: TrialSpecSpendingCap_Pk_Columns_Input;
  TrialSpecSpendingCap_set_input: TrialSpecSpendingCap_Set_Input;
  TrialSpecSpendingCap_stddev_fields: TrialSpecSpendingCap_Stddev_Fields;
  TrialSpecSpendingCap_stddev_order_by: TrialSpecSpendingCap_Stddev_Order_By;
  TrialSpecSpendingCap_stddev_pop_fields: TrialSpecSpendingCap_Stddev_Pop_Fields;
  TrialSpecSpendingCap_stddev_pop_order_by: TrialSpecSpendingCap_Stddev_Pop_Order_By;
  TrialSpecSpendingCap_stddev_samp_fields: TrialSpecSpendingCap_Stddev_Samp_Fields;
  TrialSpecSpendingCap_stddev_samp_order_by: TrialSpecSpendingCap_Stddev_Samp_Order_By;
  TrialSpecSpendingCap_stream_cursor_input: TrialSpecSpendingCap_Stream_Cursor_Input;
  TrialSpecSpendingCap_stream_cursor_value_input: TrialSpecSpendingCap_Stream_Cursor_Value_Input;
  TrialSpecSpendingCap_sum_fields: TrialSpecSpendingCap_Sum_Fields;
  TrialSpecSpendingCap_sum_order_by: TrialSpecSpendingCap_Sum_Order_By;
  TrialSpecSpendingCap_updates: TrialSpecSpendingCap_Updates;
  TrialSpecSpendingCap_var_pop_fields: TrialSpecSpendingCap_Var_Pop_Fields;
  TrialSpecSpendingCap_var_pop_order_by: TrialSpecSpendingCap_Var_Pop_Order_By;
  TrialSpecSpendingCap_var_samp_fields: TrialSpecSpendingCap_Var_Samp_Fields;
  TrialSpecSpendingCap_var_samp_order_by: TrialSpecSpendingCap_Var_Samp_Order_By;
  TrialSpecSpendingCap_variance_fields: TrialSpecSpendingCap_Variance_Fields;
  TrialSpecSpendingCap_variance_order_by: TrialSpecSpendingCap_Variance_Order_By;
  TrialSpec_aggregate: TrialSpec_Aggregate;
  TrialSpec_aggregate_fields: TrialSpec_Aggregate_Fields;
  TrialSpec_avg_fields: TrialSpec_Avg_Fields;
  TrialSpec_bool_exp: TrialSpec_Bool_Exp;
  TrialSpec_inc_input: TrialSpec_Inc_Input;
  TrialSpec_insert_input: TrialSpec_Insert_Input;
  TrialSpec_max_fields: TrialSpec_Max_Fields;
  TrialSpec_min_fields: TrialSpec_Min_Fields;
  TrialSpec_mutation_response: TrialSpec_Mutation_Response;
  TrialSpec_obj_rel_insert_input: TrialSpec_Obj_Rel_Insert_Input;
  TrialSpec_on_conflict: TrialSpec_On_Conflict;
  TrialSpec_order_by: TrialSpec_Order_By;
  TrialSpec_pk_columns_input: TrialSpec_Pk_Columns_Input;
  TrialSpec_set_input: TrialSpec_Set_Input;
  TrialSpec_stddev_fields: TrialSpec_Stddev_Fields;
  TrialSpec_stddev_pop_fields: TrialSpec_Stddev_Pop_Fields;
  TrialSpec_stddev_samp_fields: TrialSpec_Stddev_Samp_Fields;
  TrialSpec_stream_cursor_input: TrialSpec_Stream_Cursor_Input;
  TrialSpec_stream_cursor_value_input: TrialSpec_Stream_Cursor_Value_Input;
  TrialSpec_sum_fields: TrialSpec_Sum_Fields;
  TrialSpec_updates: TrialSpec_Updates;
  TrialSpec_var_pop_fields: TrialSpec_Var_Pop_Fields;
  TrialSpec_var_samp_fields: TrialSpec_Var_Samp_Fields;
  TrialSpec_variance_fields: TrialSpec_Variance_Fields;
  UnpaidInvoiceIdentifier: UnpaidInvoiceIdentifier;
  UpdateBillingProviderSettingsInput: UpdateBillingProviderSettingsInput;
  UpdateCommitAccessScheduleInput: UpdateCommitAccessScheduleInput;
  UpdateCommitInvoiceScheduleInput: UpdateCommitInvoiceScheduleInput;
  UpdateCommitNetsuiteSalesOrderIdInput: UpdateCommitNetsuiteSalesOrderIdInput;
  UpdateCommitRolloverFractionInput: UpdateCommitRolloverFractionInput;
  UpdateCommitScheduleImpactedInvoice: UpdateCommitScheduleImpactedInvoice;
  UpdateCommitScheduleInvoiceItemPreview: UpdateCommitScheduleInvoiceItemPreview;
  UpdateCommitScheduleMutationResponse: UpdateCommitScheduleMutationResponse;
  UpdateCommitScheduleSegmentItemPreview: UpdateCommitScheduleSegmentItemPreview;
  UpdateOrUndefined: ResolversParentTypes['QuantityConversionUpdate'] | ResolversParentTypes['QuantityRoundingUpdate'];
  UpdateOrUndefinedValue: ResolversParentTypes['QuantityConversion'] | ResolversParentTypes['QuantityRounding'];
  UpdateScheduledChargeNetsuiteSalesOrderIdInput: UpdateScheduledChargeNetsuiteSalesOrderIdInput;
  UpdateScheduledChargeScheduleInput: UpdateScheduledChargeScheduleInput;
  UpdateStripeSettingsInput: UpdateStripeSettingsInput;
  UpsertBillingProviderInvoiceInput: UpsertBillingProviderInvoiceInput;
  UsageBreakdownData: UsageBreakdownData;
  UsageBreakdownResponse: UsageBreakdownResponse;
  UsageEventMutationResponse: UsageEventMutationResponse;
  UsageFilter: UsageFilter;
  UsageFilterUpdate: UsageFilterUpdate;
  UsageGroup: UsageGroup;
  UsageGroupByInput: UsageGroupByInput;
  UsageProductListItem: UsageProductListItem;
  UsageProductListItemState: UsageProductListItemState;
  UsageWindow: UsageWindow;
  User: User;
  UserAuthTypeEnum: UserAuthTypeEnum;
  UserAuthTypeEnum_aggregate: UserAuthTypeEnum_Aggregate;
  UserAuthTypeEnum_aggregate_fields: UserAuthTypeEnum_Aggregate_Fields;
  UserAuthTypeEnum_bool_exp: UserAuthTypeEnum_Bool_Exp;
  UserAuthTypeEnum_enum_comparison_exp: UserAuthTypeEnum_Enum_Comparison_Exp;
  UserAuthTypeEnum_insert_input: UserAuthTypeEnum_Insert_Input;
  UserAuthTypeEnum_max_fields: UserAuthTypeEnum_Max_Fields;
  UserAuthTypeEnum_min_fields: UserAuthTypeEnum_Min_Fields;
  UserAuthTypeEnum_mutation_response: UserAuthTypeEnum_Mutation_Response;
  UserAuthTypeEnum_on_conflict: UserAuthTypeEnum_On_Conflict;
  UserAuthTypeEnum_order_by: UserAuthTypeEnum_Order_By;
  UserAuthTypeEnum_pk_columns_input: UserAuthTypeEnum_Pk_Columns_Input;
  UserAuthTypeEnum_set_input: UserAuthTypeEnum_Set_Input;
  UserAuthTypeEnum_stream_cursor_input: UserAuthTypeEnum_Stream_Cursor_Input;
  UserAuthTypeEnum_stream_cursor_value_input: UserAuthTypeEnum_Stream_Cursor_Value_Input;
  UserAuthTypeEnum_updates: UserAuthTypeEnum_Updates;
  UserSortingInput: UserSortingInput;
  User_aggregate: User_Aggregate;
  User_aggregate_bool_exp: User_Aggregate_Bool_Exp;
  User_aggregate_bool_exp_count: User_Aggregate_Bool_Exp_Count;
  User_aggregate_fields: User_Aggregate_Fields;
  User_aggregate_order_by: User_Aggregate_Order_By;
  User_arr_rel_insert_input: User_Arr_Rel_Insert_Input;
  User_bool_exp: User_Bool_Exp;
  User_insert_input: User_Insert_Input;
  User_max_fields: User_Max_Fields;
  User_max_order_by: User_Max_Order_By;
  User_min_fields: User_Min_Fields;
  User_min_order_by: User_Min_Order_By;
  User_mutation_response: User_Mutation_Response;
  User_on_conflict: User_On_Conflict;
  User_order_by: User_Order_By;
  User_pk_columns_input: User_Pk_Columns_Input;
  User_set_input: User_Set_Input;
  User_stream_cursor_input: User_Stream_Cursor_Input;
  User_stream_cursor_value_input: User_Stream_Cursor_Value_Input;
  User_updates: User_Updates;
  UuidFilter: UuidFilter;
  UuidRange: UuidRange;
  Webhook: Webhook;
  WebhookNotification: WebhookNotification;
  WebhookNotification_aggregate: WebhookNotification_Aggregate;
  WebhookNotification_aggregate_fields: WebhookNotification_Aggregate_Fields;
  WebhookNotification_append_input: WebhookNotification_Append_Input;
  WebhookNotification_avg_fields: WebhookNotification_Avg_Fields;
  WebhookNotification_bool_exp: WebhookNotification_Bool_Exp;
  WebhookNotification_delete_at_path_input: WebhookNotification_Delete_At_Path_Input;
  WebhookNotification_delete_elem_input: WebhookNotification_Delete_Elem_Input;
  WebhookNotification_delete_key_input: WebhookNotification_Delete_Key_Input;
  WebhookNotification_inc_input: WebhookNotification_Inc_Input;
  WebhookNotification_insert_input: WebhookNotification_Insert_Input;
  WebhookNotification_max_fields: WebhookNotification_Max_Fields;
  WebhookNotification_min_fields: WebhookNotification_Min_Fields;
  WebhookNotification_mutation_response: WebhookNotification_Mutation_Response;
  WebhookNotification_on_conflict: WebhookNotification_On_Conflict;
  WebhookNotification_order_by: WebhookNotification_Order_By;
  WebhookNotification_pk_columns_input: WebhookNotification_Pk_Columns_Input;
  WebhookNotification_prepend_input: WebhookNotification_Prepend_Input;
  WebhookNotification_set_input: WebhookNotification_Set_Input;
  WebhookNotification_stddev_fields: WebhookNotification_Stddev_Fields;
  WebhookNotification_stddev_pop_fields: WebhookNotification_Stddev_Pop_Fields;
  WebhookNotification_stddev_samp_fields: WebhookNotification_Stddev_Samp_Fields;
  WebhookNotification_stream_cursor_input: WebhookNotification_Stream_Cursor_Input;
  WebhookNotification_stream_cursor_value_input: WebhookNotification_Stream_Cursor_Value_Input;
  WebhookNotification_sum_fields: WebhookNotification_Sum_Fields;
  WebhookNotification_updates: WebhookNotification_Updates;
  WebhookNotification_var_pop_fields: WebhookNotification_Var_Pop_Fields;
  WebhookNotification_var_samp_fields: WebhookNotification_Var_Samp_Fields;
  WebhookNotification_variance_fields: WebhookNotification_Variance_Fields;
  Webhook_aggregate: Webhook_Aggregate;
  Webhook_aggregate_fields: Webhook_Aggregate_Fields;
  Webhook_bool_exp: Webhook_Bool_Exp;
  Webhook_insert_input: Webhook_Insert_Input;
  Webhook_max_fields: Webhook_Max_Fields;
  Webhook_min_fields: Webhook_Min_Fields;
  Webhook_mutation_response: Webhook_Mutation_Response;
  Webhook_on_conflict: Webhook_On_Conflict;
  Webhook_order_by: Webhook_Order_By;
  Webhook_pk_columns_input: Webhook_Pk_Columns_Input;
  Webhook_set_input: Webhook_Set_Input;
  Webhook_stream_cursor_input: Webhook_Stream_Cursor_Input;
  Webhook_stream_cursor_value_input: Webhook_Stream_Cursor_Value_Input;
  Webhook_updates: Webhook_Updates;
  WorkatoSSOUrlResponse: WorkatoSsoUrlResponse;
  json: Scalars['json'];
  json_comparison_exp: Json_Comparison_Exp;
  jsonb: Scalars['jsonb'];
  jsonb_cast_exp: Jsonb_Cast_Exp;
  jsonb_comparison_exp: Jsonb_Comparison_Exp;
  numeric: Scalars['numeric'];
  numeric_comparison_exp: Numeric_Comparison_Exp;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: Timestamptz_Comparison_Exp;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: Uuid_Comparison_Exp;
};

export type ApiTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['APITokenResponse'] = ResolversParentTypes['APITokenResponse']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  token_string?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsProServiceRoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['AWSProServiceRoyalty'] = ResolversParentTypes['AWSProServiceRoyalty']> = {
  applicable_product_ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  aws_account_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_payer_reference_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsProServiceRoyaltyUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AWSProServiceRoyaltyUpdate'] = ResolversParentTypes['AWSProServiceRoyaltyUpdate']> = {
  applicable_product_ids?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  aws_account_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_payer_reference_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsRoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['AWSRoyalty'] = ResolversParentTypes['AWSRoyalty']> = {
  applicable_product_ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  aws_account_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_payer_reference_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AwsRoyaltyUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AWSRoyaltyUpdate'] = ResolversParentTypes['AWSRoyaltyUpdate']> = {
  applicable_product_ids?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  aws_account_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aws_payer_reference_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum'] = ResolversParentTypes['AccountTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum_aggregate'] = ResolversParentTypes['AccountTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum_aggregate_fields'] = ResolversParentTypes['AccountTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AccountTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum_max_fields'] = ResolversParentTypes['AccountTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum_min_fields'] = ResolversParentTypes['AccountTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEnum_mutation_response'] = ResolversParentTypes['AccountTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActorOrUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActorOrUser'] = ResolversParentTypes['ActorOrUser']> = {
  __resolveType: TypeResolveFn<'Actor' | 'User', ParentType, ContextType>;
};

export type Actor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor_aggregate'] = ResolversParentTypes['Actor_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Actor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Actor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor_aggregate_fields'] = ResolversParentTypes['Actor_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Actor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Actor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Actor_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Actor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor_max_fields'] = ResolversParentTypes['Actor_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Actor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor_min_fields'] = ResolversParentTypes['Actor_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Actor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Actor_mutation_response'] = ResolversParentTypes['Actor_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Actor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdHocPlanInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdHocPlanInvoice'] = ResolversParentTypes['AdHocPlanInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<AdHocPlanInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddMarketplaceToCustomerMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddMarketplaceToCustomerMutationResponse'] = ResolversParentTypes['AddMarketplaceToCustomerMutationResponse']> = {
  billingProviderCustomer?: Resolver<ResolversTypes['BillingProviderCustomer'], ParentType, ContextType>;
  customerConfigs?: Resolver<Array<ResolversTypes['CustomerConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdhocContractUsageInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdhocContractUsageInvoice'] = ResolversParentTypes['AdhocContractUsageInvoice']> = {
  billable_status?: Resolver<ResolversTypes['BillableStatus'], ParentType, ContextType>;
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<AdhocContractUsageInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvanceChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvanceChargeLineItem'] = ResolversParentTypes['AdvanceChargeLineItem']> = {
  applicable_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  applicable_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  one_time_charge?: Resolver<Maybe<ResolversTypes['OneTimeProductCharge']>, ParentType, ContextType>;
  pricing_factor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sub_line_items?: Resolver<Maybe<Array<ResolversTypes['ChargeSubLineItem']>>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvanceInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvanceInvoice'] = ResolversParentTypes['AdvanceInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customer_plan?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<AdvanceInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert'] = ResolversParentTypes['Alert']> = {
  BillableMetric?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  CustomerAlerts?: Resolver<Array<ResolversTypes['CustomerAlert']>, ParentType, ContextType, Partial<AlertCustomerAlertsArgs>>;
  CustomerAlerts_aggregate?: Resolver<ResolversTypes['CustomerAlert_aggregate'], ParentType, ContextType, Partial<AlertCustomerAlerts_AggregateArgs>>;
  Plan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType>;
  alert_type?: Resolver<ResolversTypes['AlertTypeEnum_enum'], ParentType, ContextType>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_alerts?: Resolver<Array<ResolversTypes['CustomerAlertComposite']>, ParentType, ContextType, Partial<AlertCustomer_AlertsArgs>>;
  customer_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AlertCustomer_CountArgs>>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  evaluate_on_create?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<AlertFiltersArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  threshold?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  webhooks_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField'] = ResolversParentTypes['AlertManagedField']> = {
  Alert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  alert_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField_aggregate'] = ResolversParentTypes['AlertManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['AlertManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['AlertManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField_aggregate_fields'] = ResolversParentTypes['AlertManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AlertManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['AlertManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['AlertManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField_max_fields'] = ResolversParentTypes['AlertManagedField_max_fields']> = {
  alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField_min_fields'] = ResolversParentTypes['AlertManagedField_min_fields']> = {
  alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertManagedField_mutation_response'] = ResolversParentTypes['AlertManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['AlertManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum'] = ResolversParentTypes['AlertTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum_aggregate'] = ResolversParentTypes['AlertTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum_aggregate_fields'] = ResolversParentTypes['AlertTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<AlertTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum_max_fields'] = ResolversParentTypes['AlertTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum_min_fields'] = ResolversParentTypes['AlertTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertTypeEnum_mutation_response'] = ResolversParentTypes['AlertTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_aggregate'] = ResolversParentTypes['Alert_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Alert_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_aggregate_fields'] = ResolversParentTypes['Alert_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['Alert_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Alert_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Alert_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Alert_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Alert_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Alert_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Alert_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Alert_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Alert_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Alert_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Alert_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_avg_fields'] = ResolversParentTypes['Alert_avg_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_max_fields'] = ResolversParentTypes['Alert_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  threshold?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_min_fields'] = ResolversParentTypes['Alert_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  threshold?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_mutation_response'] = ResolversParentTypes['Alert_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_stddev_fields'] = ResolversParentTypes['Alert_stddev_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_stddev_pop_fields'] = ResolversParentTypes['Alert_stddev_pop_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_stddev_samp_fields'] = ResolversParentTypes['Alert_stddev_samp_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_sum_fields'] = ResolversParentTypes['Alert_sum_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_var_pop_fields'] = ResolversParentTypes['Alert_var_pop_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_var_samp_fields'] = ResolversParentTypes['Alert_var_samp_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Alert_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alert_variance_fields'] = ResolversParentTypes['Alert_variance_fields']> = {
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchiveContractMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArchiveContractMutationResponse'] = ResolversParentTypes['ArchiveContractMutationResponse']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  contract_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchiveProductListItemMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArchiveProductListItemMutationResponse'] = ResolversParentTypes['ArchiveProductListItemMutationResponse']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArrearsInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArrearsInvoice'] = ResolversParentTypes['ArrearsInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  corrections?: Resolver<Array<ResolversTypes['CorrectionInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customer_plan?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  is_trial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ArrearsInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuditLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuditLog'] = ResolversParentTypes['AuditLog']> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actor?: Resolver<ResolversTypes['ActorOrUser'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  parameters?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  request_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  request_timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  resource_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resource_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AuditLogStatusEnum'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuditLogResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuditLogResponse'] = ResolversParentTypes['AuditLogResponse']> = {
  data?: Resolver<Array<ResolversTypes['AuditLog']>, ParentType, ContextType>;
  next_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthCheckResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthCheckResponse'] = ResolversParentTypes['AuthCheckResponse']> = {
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCommitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCommit'] = ResolversParentTypes['BaseCommit']> = {
  __resolveType: TypeResolveFn<'PostpaidCommit' | 'PrepaidCommit', ParentType, ContextType>;
  applicable_contracts?: Resolver<Maybe<Array<ResolversTypes['Contract']>>, ParentType, ContextType>;
  applicable_products?: Resolver<Maybe<Array<ResolversTypes['ProductListItem']>>, ParentType, ContextType>;
  applicable_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CommitOrCreditManagedField']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  rollover_fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type BaseRateScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseRateScheduleSegment'] = ResolversParentTypes['BaseRateScheduleSegment']> = {
  __resolveType: TypeResolveFn<'RateScheduleSegment' | 'ScalarContractRateScheduleSegment' | 'ScalarRateScheduleSegment', ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
};

export type BaseUsageFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseUsageFilter'] = ResolversParentTypes['BaseUsageFilter']> = {
  __resolveType: TypeResolveFn<'InitialUsageFilter' | 'UsageFilterUpdate', ParentType, ContextType>;
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type BillableMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric'] = ResolversParentTypes['BillableMetric']> = {
  Alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType, Partial<BillableMetricAlertsArgs>>;
  Alerts_aggregate?: Resolver<ResolversTypes['Alert_aggregate'], ParentType, ContextType, Partial<BillableMetricAlerts_AggregateArgs>>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  active_plan_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active_product_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  aggregate?: Resolver<ResolversTypes['BillingMetricAggregateEnum_enum'], ParentType, ContextType>;
  aggregate_keys?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<BillableMetricAggregate_KeysArgs>>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  fancy_plan?: Resolver<Maybe<ResolversTypes['json']>, ParentType, ContextType, Partial<BillableMetricFancy_PlanArgs>>;
  filter?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<BillableMetricFilterArgs>>;
  group_keys?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<BillableMetricGroup_KeysArgs>>;
  grouped_usage?: Resolver<ResolversTypes['GroupedUsage'], ParentType, ContextType, RequireFields<BillableMetricGrouped_UsageArgs, 'customer_id' | 'ending_before' | 'starting_on' | 'window_size'>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paged_usage?: Resolver<ResolversTypes['PagedUsage'], ParentType, ContextType, RequireFields<BillableMetricPaged_UsageArgs, 'customer_id' | 'ending_before' | 'starting_on' | 'window_size'>>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<BillableMetricProductsArgs, 'types'>>;
  sql?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  usage?: Resolver<Array<ResolversTypes['UsageWindow']>, ParentType, ContextType, RequireFields<BillableMetricUsageArgs, 'customer_id' | 'ending_before' | 'starting_after' | 'window_size'>>;
  usage_breakdown?: Resolver<ResolversTypes['UsageBreakdownResponse'], ParentType, ContextType, RequireFields<BillableMetricUsage_BreakdownArgs, 'customer_id' | 'ending_before' | 'starting_on' | 'window_size'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField'] = ResolversParentTypes['BillableMetricManagedField']> = {
  BillableMetric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  billable_metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField_aggregate'] = ResolversParentTypes['BillableMetricManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField_aggregate_fields'] = ResolversParentTypes['BillableMetricManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillableMetricManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField_max_fields'] = ResolversParentTypes['BillableMetricManagedField_max_fields']> = {
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField_min_fields'] = ResolversParentTypes['BillableMetricManagedField_min_fields']> = {
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetricManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetricManagedField_mutation_response'] = ResolversParentTypes['BillableMetricManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetric_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric_aggregate'] = ResolversParentTypes['BillableMetric_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillableMetric_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetric_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric_aggregate_fields'] = ResolversParentTypes['BillableMetric_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillableMetric_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillableMetric_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillableMetric_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetric_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric_max_fields'] = ResolversParentTypes['BillableMetric_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sql?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetric_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric_min_fields'] = ResolversParentTypes['BillableMetric_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sql?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableMetric_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableMetric_mutation_response'] = ResolversParentTypes['BillableMetric_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableStatusWithEffectiveDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillableStatusWithEffectiveDate'] = ResolversParentTypes['BillableStatusWithEffectiveDate']> = {
  billable_status?: Resolver<ResolversTypes['BillableStatus'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BilledInvoiceTotalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BilledInvoiceTotals'] = ResolversParentTypes['BilledInvoiceTotals']> = {
  num_invoices?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total_usd?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum'] = ResolversParentTypes['BillingFrequencyEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum_aggregate'] = ResolversParentTypes['BillingFrequencyEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum_aggregate_fields'] = ResolversParentTypes['BillingFrequencyEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingFrequencyEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum_max_fields'] = ResolversParentTypes['BillingFrequencyEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum_min_fields'] = ResolversParentTypes['BillingFrequencyEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingFrequencyEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingFrequencyEnum_mutation_response'] = ResolversParentTypes['BillingFrequencyEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum'] = ResolversParentTypes['BillingMetricAggregateEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum_aggregate'] = ResolversParentTypes['BillingMetricAggregateEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum_aggregate_fields'] = ResolversParentTypes['BillingMetricAggregateEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingMetricAggregateEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum_max_fields'] = ResolversParentTypes['BillingMetricAggregateEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum_min_fields'] = ResolversParentTypes['BillingMetricAggregateEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingMetricAggregateEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingMetricAggregateEnum_mutation_response'] = ResolversParentTypes['BillingMetricAggregateEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer'] = ResolversParentTypes['BillingProviderCustomer']> = {
  BillingProviderEnum?: Resolver<ResolversTypes['BillingProviderEnum'], ParentType, ContextType>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  billing_provider_customer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomer_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer_aggregate'] = ResolversParentTypes['BillingProviderCustomer_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomer_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer_aggregate_fields'] = ResolversParentTypes['BillingProviderCustomer_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingProviderCustomer_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomer_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer_max_fields'] = ResolversParentTypes['BillingProviderCustomer_max_fields']> = {
  billing_provider_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomer_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer_min_fields'] = ResolversParentTypes['BillingProviderCustomer_min_fields']> = {
  billing_provider_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderCustomer_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderCustomer_mutation_response'] = ResolversParentTypes['BillingProviderCustomer_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderDeliveryMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderDeliveryMethod'] = ResolversParentTypes['BillingProviderDeliveryMethod']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  delivery_method?: Resolver<ResolversTypes['BillingProviderDeliveryMethod_enum'], ParentType, ContextType>;
  delivery_method_configuration?: Resolver<ResolversTypes['FlatKeyValueMap'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderDeliveryMethodWithoutConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderDeliveryMethodWithoutConfiguration'] = ResolversParentTypes['BillingProviderDeliveryMethodWithoutConfiguration']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  delivery_method?: Resolver<ResolversTypes['BillingProviderDeliveryMethod_enum'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum'] = ResolversParentTypes['BillingProviderEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum_aggregate'] = ResolversParentTypes['BillingProviderEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum_aggregate_fields'] = ResolversParentTypes['BillingProviderEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingProviderEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum_max_fields'] = ResolversParentTypes['BillingProviderEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum_min_fields'] = ResolversParentTypes['BillingProviderEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderEnum_mutation_response'] = ResolversParentTypes['BillingProviderEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice'] = ResolversParentTypes['BillingProviderInvoice']> = {
  BillingProviderEnum?: Resolver<ResolversTypes['BillingProviderEnum'], ParentType, ContextType>;
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  billing_provider_error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_invoice_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  due_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  external_status?: Resolver<ResolversTypes['InvoiceExternalStatusEnum_enum'], ParentType, ContextType>;
  finalized_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  tax_applied_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  workato_job_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoiceUpsertResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoiceUpsertResult'] = ResolversParentTypes['BillingProviderInvoiceUpsertResult']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  billing_provider_error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_invoice_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  external_status?: Resolver<ResolversTypes['InvoiceExternalStatusEnum_enum'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tax_applied_status?: Resolver<Maybe<ResolversTypes['TaxAppliedStatusEnum_enum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoice_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice_aggregate'] = ResolversParentTypes['BillingProviderInvoice_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoice_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice_aggregate_fields'] = ResolversParentTypes['BillingProviderInvoice_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingProviderInvoice_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoice_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice_max_fields'] = ResolversParentTypes['BillingProviderInvoice_max_fields']> = {
  billing_provider_error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_invoice_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  finalized_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  issued_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  tax_applied_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  workato_job_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoice_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice_min_fields'] = ResolversParentTypes['BillingProviderInvoice_min_fields']> = {
  billing_provider_error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_invoice_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billing_provider_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  finalized_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  issued_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  tax_applied_status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  workato_job_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderInvoice_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderInvoice_mutation_response'] = ResolversParentTypes['BillingProviderInvoice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken'] = ResolversParentTypes['BillingProviderToken']> = {
  BillingProviderEnum?: Resolver<ResolversTypes['BillingProviderEnum'], ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  encrypted_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderToken_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken_aggregate'] = ResolversParentTypes['BillingProviderToken_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['BillingProviderToken_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['BillingProviderToken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderToken_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken_aggregate_fields'] = ResolversParentTypes['BillingProviderToken_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<BillingProviderToken_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['BillingProviderToken_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['BillingProviderToken_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderToken_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken_max_fields'] = ResolversParentTypes['BillingProviderToken_max_fields']> = {
  account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  encrypted_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderToken_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken_min_fields'] = ResolversParentTypes['BillingProviderToken_min_fields']> = {
  account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  encrypted_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingProviderToken_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingProviderToken_mutation_response'] = ResolversParentTypes['BillingProviderToken_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['BillingProviderToken']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreakdownCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BreakdownCursor'] = ResolversParentTypes['BreakdownCursor']> = {
  customer_aggregate?: Resolver<Maybe<ResolversTypes['CustomerAggregate']>, ParentType, ContextType>;
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  event_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ingest_aliases?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  latest_billable_metric_updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreakdownItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['BreakdownItem'] = ResolversParentTypes['BreakdownItem']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotal?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  tiers?: Resolver<Array<ResolversTypes['BreakdownTier']>, ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BreakdownTierResolvers<ContextType = any, ParentType extends ResolversParentTypes['BreakdownTier'] = ResolversParentTypes['BreakdownTier']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotal?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CensusDestinationConnectLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['CensusDestinationConnectLink'] = ResolversParentTypes['CensusDestinationConnectLink']> = {
  destination_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination_type?: Resolver<ResolversTypes['CensusDestinationTypeEnum'], ParentType, ContextType>;
  expiration?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  redirect_uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revoked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CensusObjectSyncReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['CensusObjectSyncReport'] = ResolversParentTypes['CensusObjectSyncReport']> = {
  object_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sync_runs?: Resolver<Array<ResolversTypes['CensusSyncRun']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CensusRecordSyncErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CensusRecordSyncError'] = ResolversParentTypes['CensusRecordSyncError']> = {
  error_message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  record_attributes?: Resolver<Maybe<ResolversTypes['FlatKeyValueMap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CensusSyncRunResolvers<ContextType = any, ParentType extends ResolversParentTypes['CensusSyncRun'] = ResolversParentTypes['CensusSyncRun']> = {
  completed_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  records_failed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records_processed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  records_updated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sampled_failed_records?: Resolver<Maybe<Array<ResolversTypes['CensusRecordSyncError']>>, ParentType, ContextType>;
  scheduled_execution_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CensusSyncRunStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CensusWorkspaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CensusWorkspace'] = ResolversParentTypes['CensusWorkspace']> = {
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  destination_type?: Resolver<ResolversTypes['CensusDestinationTypeEnum'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeLineItem'] = ResolversParentTypes['ChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  one_time_charge?: Resolver<Maybe<ResolversTypes['OneTimeProductCharge']>, ParentType, ContextType>;
  pricing_factor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sub_line_items?: Resolver<Maybe<Array<ResolversTypes['ChargeSubLineItem']>>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  tier_period?: Resolver<Maybe<ResolversTypes['TierPeriod']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField'] = ResolversParentTypes['ChargeManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  ProductPricingFactor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField_aggregate'] = ResolversParentTypes['ChargeManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ChargeManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField_aggregate_fields'] = ResolversParentTypes['ChargeManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ChargeManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ChargeManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ChargeManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField_max_fields'] = ResolversParentTypes['ChargeManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField_min_fields'] = ResolversParentTypes['ChargeManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeManagedField_mutation_response'] = ResolversParentTypes['ChargeManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeSubLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeSubLineItem'] = ResolversParentTypes['ChargeSubLineItem']> = {
  __resolveType: TypeResolveFn<'GroupedChargeLineItem' | 'TierChargeLineItem', ParentType, ContextType>;
};

export type ChargeTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum'] = ResolversParentTypes['ChargeTypeEnum']> = {
  ProductPricingFactors?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, Partial<ChargeTypeEnumProductPricingFactorsArgs>>;
  ProductPricingFactors_aggregate?: Resolver<ResolversTypes['ProductPricingFactor_aggregate'], ParentType, ContextType, Partial<ChargeTypeEnumProductPricingFactors_AggregateArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum_aggregate'] = ResolversParentTypes['ChargeTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum_aggregate_fields'] = ResolversParentTypes['ChargeTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ChargeTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum_max_fields'] = ResolversParentTypes['ChargeTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum_min_fields'] = ResolversParentTypes['ChargeTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeTypeEnum_mutation_response'] = ResolversParentTypes['ChargeTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  BillableMetrics?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType, Partial<ClientBillableMetricsArgs>>;
  BillableMetrics_aggregate?: Resolver<ResolversTypes['BillableMetric_aggregate'], ParentType, ContextType, Partial<ClientBillableMetrics_AggregateArgs>>;
  BillingProviderTokens?: Resolver<Array<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, Partial<ClientBillingProviderTokensArgs>>;
  BillingProviderTokens_aggregate?: Resolver<ResolversTypes['BillingProviderToken_aggregate'], ParentType, ContextType, Partial<ClientBillingProviderTokens_AggregateArgs>>;
  ClientConfigs?: Resolver<Array<ResolversTypes['ClientConfig']>, ParentType, ContextType, Partial<ClientClientConfigsArgs>>;
  ClientConfigs_aggregate?: Resolver<ResolversTypes['ClientConfig_aggregate'], ParentType, ContextType, Partial<ClientClientConfigs_AggregateArgs>>;
  Customers?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, Partial<ClientCustomersArgs>>;
  Customers_aggregate?: Resolver<ResolversTypes['Customer_aggregate'], ParentType, ContextType, Partial<ClientCustomers_AggregateArgs>>;
  Users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<ClientUsersArgs>>;
  Users_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<ClientUsers_AggregateArgs>>;
  account_type?: Resolver<ResolversTypes['AccountTypeEnum_enum'], ParentType, ContextType>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_count?: Resolver<ResolversTypes['CustomerCount'], ParentType, ContextType, RequireFields<ClientCustomer_CountArgs, 'is_archived'>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig'] = ResolversParentTypes['ClientConfig']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['ClientConfigKeyEnum_enum'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum'] = ResolversParentTypes['ClientConfigKeyEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum_aggregate'] = ResolversParentTypes['ClientConfigKeyEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum_aggregate_fields'] = ResolversParentTypes['ClientConfigKeyEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ClientConfigKeyEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum_max_fields'] = ResolversParentTypes['ClientConfigKeyEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum_min_fields'] = ResolversParentTypes['ClientConfigKeyEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfigKeyEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfigKeyEnum_mutation_response'] = ResolversParentTypes['ClientConfigKeyEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfig_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig_aggregate'] = ResolversParentTypes['ClientConfig_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ClientConfig_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ClientConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfig_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig_aggregate_fields'] = ResolversParentTypes['ClientConfig_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ClientConfig_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ClientConfig_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ClientConfig_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfig_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig_max_fields'] = ResolversParentTypes['ClientConfig_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfig_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig_min_fields'] = ResolversParentTypes['ClientConfig_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientConfig_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientConfig_mutation_response'] = ResolversParentTypes['ClientConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ClientConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientDashboardMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientDashboardMetrics'] = ResolversParentTypes['ClientDashboardMetrics']> = {
  invoice_revenues?: Resolver<Array<ResolversTypes['RevenueMetric']>, ParentType, ContextType>;
  revenue_by_month?: Resolver<Array<ResolversTypes['RevenueBreakdown']>, ParentType, ContextType>;
  top_10_spenders?: Resolver<Array<ResolversTypes['TopCustomerSpenders']>, ParentType, ContextType>;
  total_customer?: Resolver<ResolversTypes['CustomersMetric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview'] = ResolversParentTypes['ClientOverview']> = {
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  top_spenders?: Resolver<ResolversTypes['ClientOverview_TopSpenders'], ParentType, ContextType>;
  total_active_customers?: Resolver<ResolversTypes['ClientOverview_Chart'], ParentType, ContextType>;
  total_revenue?: Resolver<ResolversTypes['ClientOverview_Chart'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverview_ChartResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview_Chart'] = ResolversParentTypes['ClientOverview_Chart']> = {
  credit_type?: Resolver<Maybe<ResolversTypes['CreditType']>, ParentType, ContextType>;
  data?: Resolver<Array<ResolversTypes['ClientOverview_ChartDatum']>, ParentType, ContextType>;
  snapshot_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverview_ChartDatumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview_ChartDatum'] = ResolversParentTypes['ClientOverview_ChartDatum']> = {
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverview_SpendResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview_Spend'] = ResolversParentTypes['ClientOverview_Spend']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverview_TopSpenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview_TopSpender'] = ResolversParentTypes['ClientOverview_TopSpender']> = {
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  delta_percentage?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total_revenue?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientOverview_TopSpendersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientOverview_TopSpenders'] = ResolversParentTypes['ClientOverview_TopSpenders']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['ClientOverview_TopSpender']>, ParentType, ContextType>;
  snapshot_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientUsingContractsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientUsingContracts'] = ResolversParentTypes['ClientUsingContracts']> = {
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  environment_types?: Resolver<Array<ResolversTypes['EnvironmentTypeEnum_enum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientUsingPrepaidCreditsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientUsingPrepaidCredits'] = ResolversParentTypes['ClientUsingPrepaidCredits']> = {
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  environment_types?: Resolver<Array<ResolversTypes['EnvironmentTypeEnum_enum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Client_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client_aggregate'] = ResolversParentTypes['Client_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Client_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Client']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Client_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client_aggregate_fields'] = ResolversParentTypes['Client_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Client_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Client_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Client_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Client_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client_max_fields'] = ResolversParentTypes['Client_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Client_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client_min_fields'] = ResolversParentTypes['Client_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Client_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client_mutation_response'] = ResolversParentTypes['Client_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Client']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientsUsingContractsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientsUsingContracts'] = ResolversParentTypes['ClientsUsingContracts']> = {
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<Array<ResolversTypes['ClientUsingContracts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientsUsingPrepaidCreditsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientsUsingPrepaidCredits'] = ResolversParentTypes['ClientsUsingPrepaidCredits']> = {
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<Array<ResolversTypes['ClientUsingPrepaidCredits']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum'] = ResolversParentTypes['CollectionScheduleEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum_aggregate'] = ResolversParentTypes['CollectionScheduleEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum_aggregate_fields'] = ResolversParentTypes['CollectionScheduleEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CollectionScheduleEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum_max_fields'] = ResolversParentTypes['CollectionScheduleEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum_min_fields'] = ResolversParentTypes['CollectionScheduleEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CollectionScheduleEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CollectionScheduleEnum_mutation_response'] = ResolversParentTypes['CollectionScheduleEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitResolvers<ContextType = any, ParentType extends ResolversParentTypes['Commit'] = ResolversParentTypes['Commit']> = {
  access_schedule?: Resolver<Maybe<ResolversTypes['CommitAccessSchedule']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  applicable_contracts?: Resolver<Maybe<Array<ResolversTypes['Contract']>>, ParentType, ContextType>;
  applicable_products?: Resolver<Maybe<Array<ResolversTypes['ProductListItem']>>, ParentType, ContextType>;
  applicable_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  external_type?: Resolver<Maybe<ResolversTypes['ExternalCommitType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  invoice_schedule?: Resolver<Maybe<ResolversTypes['CommitInvoiceSchedule']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  rollover_fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CommitType'], ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitAccessScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitAccessSchedule'] = ResolversParentTypes['CommitAccessSchedule']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  recurring_schedule?: Resolver<Maybe<ResolversTypes['RecurringSchedule']>, ParentType, ContextType>;
  schedule_items?: Resolver<Array<ResolversTypes['CommitSegmentScheduleItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitInvoiceScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitInvoiceSchedule'] = ResolversParentTypes['CommitInvoiceSchedule']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  recurring_schedule?: Resolver<Maybe<ResolversTypes['RecurringSchedule']>, ParentType, ContextType>;
  schedule_items?: Resolver<Array<ResolversTypes['CommitInvoiceScheduleItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitInvoiceScheduleItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitInvoiceScheduleItem'] = ResolversParentTypes['CommitInvoiceScheduleItem']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField'] = ResolversParentTypes['CommitManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  commit_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField_aggregate'] = ResolversParentTypes['CommitManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CommitManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CommitManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField_aggregate_fields'] = ResolversParentTypes['CommitManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CommitManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CommitManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CommitManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField_max_fields'] = ResolversParentTypes['CommitManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  commit_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField_min_fields'] = ResolversParentTypes['CommitManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  commit_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitManagedField_mutation_response'] = ResolversParentTypes['CommitManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CommitManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitOrCreditManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitOrCreditManagedField'] = ResolversParentTypes['CommitOrCreditManagedField']> = {
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitSegmentScheduleItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitSegmentScheduleItem'] = ResolversParentTypes['CommitSegmentScheduleItem']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  commit_with_segment?: Resolver<ResolversTypes['CommitWithSegment'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  remaining_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType, Partial<CommitSegmentScheduleItemRemaining_BalanceArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommitUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitUnion'] = ResolversParentTypes['CommitUnion']> = {
  __resolveType: TypeResolveFn<'PostpaidCommit' | 'PrepaidCommit', ParentType, ContextType>;
};

export type CommitWithSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommitWithSegment'] = ResolversParentTypes['CommitWithSegment']> = {
  commit?: Resolver<ResolversTypes['Commit'], ParentType, ContextType>;
  commit_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  commit_union?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge'] = ResolversParentTypes['CompositeCharge']> = {
  CompositeChargePricingFactors?: Resolver<Array<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType, Partial<CompositeChargeCompositeChargePricingFactorsArgs>>;
  CompositeChargePricingFactors_aggregate?: Resolver<ResolversTypes['CompositeChargePricingFactor_aggregate'], ParentType, ContextType, Partial<CompositeChargeCompositeChargePricingFactors_AggregateArgs>>;
  CompositeChargeTiers?: Resolver<Array<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, Partial<CompositeChargeCompositeChargeTiersArgs>>;
  CompositeChargeTiers_aggregate?: Resolver<ResolversTypes['CompositeChargeTier_aggregate'], ParentType, ContextType, Partial<CompositeChargeCompositeChargeTiers_AggregateArgs>>;
  PricedProductPricingFactor?: Resolver<ResolversTypes['PricedProductPricingFactor'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CompositeChargeTypeEnum_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment'] = ResolversParentTypes['CompositeChargeAdjustment']> = {
  CompositeChargeTierAdjustments?: Resolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, Partial<CompositeChargeAdjustmentCompositeChargeTierAdjustmentsArgs>>;
  CompositeChargeTierAdjustments_aggregate?: Resolver<ResolversTypes['CompositeChargeTierAdjustment_aggregate'], ParentType, ContextType, Partial<CompositeChargeAdjustmentCompositeChargeTierAdjustments_AggregateArgs>>;
  PricedProductPricingFactorAdjustment?: Resolver<ResolversTypes['PricedProductPricingFactorAdjustment'], ParentType, ContextType>;
  adjustment_type_enum?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_aggregate'] = ResolversParentTypes['CompositeChargeAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_aggregate_fields'] = ResolversParentTypes['CompositeChargeAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeChargeAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_avg_fields'] = ResolversParentTypes['CompositeChargeAdjustment_avg_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_max_fields'] = ResolversParentTypes['CompositeChargeAdjustment_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_min_fields'] = ResolversParentTypes['CompositeChargeAdjustment_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_mutation_response'] = ResolversParentTypes['CompositeChargeAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_stddev_fields'] = ResolversParentTypes['CompositeChargeAdjustment_stddev_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_stddev_pop_fields'] = ResolversParentTypes['CompositeChargeAdjustment_stddev_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_stddev_samp_fields'] = ResolversParentTypes['CompositeChargeAdjustment_stddev_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_sum_fields'] = ResolversParentTypes['CompositeChargeAdjustment_sum_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_var_pop_fields'] = ResolversParentTypes['CompositeChargeAdjustment_var_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_var_samp_fields'] = ResolversParentTypes['CompositeChargeAdjustment_var_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeAdjustment_variance_fields'] = ResolversParentTypes['CompositeChargeAdjustment_variance_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeLineItem'] = ResolversParentTypes['CompositeChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  pricing_factor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sub_line_items?: Resolver<Maybe<Array<ResolversTypes['TierSubLineItem']>>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  target_product_pricing_factors?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CompositeChargeTypeEnum_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor'] = ResolversParentTypes['CompositeChargePricingFactor']> = {
  CompositeCharge?: Resolver<ResolversTypes['CompositeCharge'], ParentType, ContextType>;
  ProductPricingFactor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  composite_charge_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor_aggregate'] = ResolversParentTypes['CompositeChargePricingFactor_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor_aggregate_fields'] = ResolversParentTypes['CompositeChargePricingFactor_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeChargePricingFactor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor_max_fields'] = ResolversParentTypes['CompositeChargePricingFactor_max_fields']> = {
  composite_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor_min_fields'] = ResolversParentTypes['CompositeChargePricingFactor_min_fields']> = {
  composite_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargePricingFactor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargePricingFactor_mutation_response'] = ResolversParentTypes['CompositeChargePricingFactor_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier'] = ResolversParentTypes['CompositeChargeTier']> = {
  CompositeCharge?: Resolver<ResolversTypes['CompositeCharge'], ParentType, ContextType>;
  composite_charge_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  composite_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment'] = ResolversParentTypes['CompositeChargeTierAdjustment']> = {
  CompositeChargeAdjustment?: Resolver<ResolversTypes['CompositeChargeAdjustment'], ParentType, ContextType>;
  composite_charge_adjustment_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  composite_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_aggregate'] = ResolversParentTypes['CompositeChargeTierAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_aggregate_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeChargeTierAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_avg_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_avg_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_max_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_max_fields']> = {
  composite_charge_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_min_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_min_fields']> = {
  composite_charge_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_mutation_response'] = ResolversParentTypes['CompositeChargeTierAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_stddev_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_stddev_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_stddev_pop_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_stddev_pop_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_stddev_samp_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_stddev_samp_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_sum_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_sum_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_var_pop_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_var_pop_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_var_samp_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_var_samp_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTierAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTierAdjustment_variance_fields'] = ResolversParentTypes['CompositeChargeTierAdjustment_variance_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_aggregate'] = ResolversParentTypes['CompositeChargeTier_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_aggregate_fields'] = ResolversParentTypes['CompositeChargeTier_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeChargeTier_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_avg_fields'] = ResolversParentTypes['CompositeChargeTier_avg_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_max_fields'] = ResolversParentTypes['CompositeChargeTier_max_fields']> = {
  composite_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_min_fields'] = ResolversParentTypes['CompositeChargeTier_min_fields']> = {
  composite_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_mutation_response'] = ResolversParentTypes['CompositeChargeTier_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_stddev_fields'] = ResolversParentTypes['CompositeChargeTier_stddev_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_stddev_pop_fields'] = ResolversParentTypes['CompositeChargeTier_stddev_pop_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_stddev_samp_fields'] = ResolversParentTypes['CompositeChargeTier_stddev_samp_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_sum_fields'] = ResolversParentTypes['CompositeChargeTier_sum_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_var_pop_fields'] = ResolversParentTypes['CompositeChargeTier_var_pop_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_var_samp_fields'] = ResolversParentTypes['CompositeChargeTier_var_samp_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTier_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTier_variance_fields'] = ResolversParentTypes['CompositeChargeTier_variance_fields']> = {
  composite_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum'] = ResolversParentTypes['CompositeChargeTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum_aggregate'] = ResolversParentTypes['CompositeChargeTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum_aggregate_fields'] = ResolversParentTypes['CompositeChargeTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeChargeTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum_max_fields'] = ResolversParentTypes['CompositeChargeTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum_min_fields'] = ResolversParentTypes['CompositeChargeTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeChargeTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeChargeTypeEnum_mutation_response'] = ResolversParentTypes['CompositeChargeTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_aggregate'] = ResolversParentTypes['CompositeCharge_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CompositeCharge_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CompositeCharge']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_aggregate_fields'] = ResolversParentTypes['CompositeCharge_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CompositeCharge_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompositeCharge_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CompositeCharge_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CompositeCharge_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CompositeCharge_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CompositeCharge_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CompositeCharge_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CompositeCharge_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CompositeCharge_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CompositeCharge_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CompositeCharge_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_avg_fields'] = ResolversParentTypes['CompositeCharge_avg_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_max_fields'] = ResolversParentTypes['CompositeCharge_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_min_fields'] = ResolversParentTypes['CompositeCharge_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_mutation_response'] = ResolversParentTypes['CompositeCharge_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CompositeCharge']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_stddev_fields'] = ResolversParentTypes['CompositeCharge_stddev_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_stddev_pop_fields'] = ResolversParentTypes['CompositeCharge_stddev_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_stddev_samp_fields'] = ResolversParentTypes['CompositeCharge_stddev_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_sum_fields'] = ResolversParentTypes['CompositeCharge_sum_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_var_pop_fields'] = ResolversParentTypes['CompositeCharge_var_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_var_samp_fields'] = ResolversParentTypes['CompositeCharge_var_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeCharge_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeCharge_variance_fields'] = ResolversParentTypes['CompositeCharge_variance_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeProductListItem'] = ResolversParentTypes['CompositeProductListItem']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  current?: Resolver<ResolversTypes['CompositeProductListItemState'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial?: Resolver<ResolversTypes['CompositeProductListItemState'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['ProductListItemUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompositeProductListItemStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompositeProductListItemState'] = ResolversParentTypes['CompositeProductListItemState']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  composite_products?: Resolver<Maybe<Array<ResolversTypes['NonCompositeProductListItem']>>, ParentType, ContextType>;
  composite_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  exclude_free_usage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_refundable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_overage_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  amendments?: Resolver<Array<ResolversTypes['ContractAmendment']>, ParentType, ContextType>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  commits?: Resolver<Array<ResolversTypes['Commit']>, ParentType, ContextType>;
  commits_union?: Resolver<Array<ResolversTypes['CommitUnion']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customer_billing_provider_configuration?: Resolver<Maybe<ResolversTypes['CustomerBillingProviderConfiguration']>, ParentType, ContextType>;
  discounts?: Resolver<Array<ResolversTypes['Discount']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractManagedField']>, ParentType, ContextType>;
  multiplier_override_prioritization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  named_schedule?: Resolver<Array<ResolversTypes['NamedScheduleSegment']>, ParentType, ContextType, RequireFields<ContractNamed_ScheduleArgs, 'name'>>;
  named_schedules?: Resolver<Array<ResolversTypes['NamedSchedule']>, ParentType, ContextType>;
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overrides?: Resolver<Array<ResolversTypes['RateCardEntryOverride']>, ParentType, ContextType>;
  pro_services?: Resolver<Array<ResolversTypes['ProService']>, ParentType, ContextType>;
  rate_card?: Resolver<Maybe<ResolversTypes['RateCard']>, ParentType, ContextType>;
  rate_schedule?: Resolver<ResolversTypes['ContractRateSchedule'], ParentType, ContextType, RequireFields<ContractRate_ScheduleArgs, 'limit'>>;
  reseller_royalties?: Resolver<Array<ResolversTypes['ResellerRoyalty']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduled_charges?: Resolver<Array<ResolversTypes['ScheduledCharge']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  total_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  transitions?: Resolver<Array<ResolversTypes['Transition']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  usage_filter?: Resolver<Maybe<ResolversTypes['UsageFilter']>, ParentType, ContextType>;
  usage_invoice_schedule?: Resolver<ResolversTypes['ContractUsageInvoiceSchedule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAwsProServiceRoyaltyLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractAWSProServiceRoyaltyLineItem'] = ResolversParentTypes['ContractAWSProServiceRoyaltyLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractAWSProServiceRoyaltyLineItem']>>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reseller_royalty?: Resolver<ResolversTypes['AWSProServiceRoyalty'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAwsRoyaltyLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractAWSRoyaltyLineItem'] = ResolversParentTypes['ContractAWSRoyaltyLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractAWSRoyaltyLineItem']>>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reseller_royalty?: Resolver<ResolversTypes['AWSRoyalty'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAmendmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractAmendment'] = ResolversParentTypes['ContractAmendment']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  commits?: Resolver<Array<ResolversTypes['Commit']>, ParentType, ContextType>;
  commits_union?: Resolver<Array<ResolversTypes['CommitUnion']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  discounts?: Resolver<Array<ResolversTypes['Discount']>, ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multiplier_override_prioritization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overrides?: Resolver<Array<ResolversTypes['RateCardEntryOverride']>, ParentType, ContextType>;
  pro_services?: Resolver<Array<ResolversTypes['ProService']>, ParentType, ContextType>;
  reseller_royalties?: Resolver<Array<ResolversTypes['ResellerRoyaltyOrUpdate']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduled_charges?: Resolver<Array<ResolversTypes['ScheduledCharge']>, ParentType, ContextType>;
  total_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAppliedCommitLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractAppliedCommitLineItem'] = ResolversParentTypes['ContractAppliedCommitLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractAppliedCommitLineItem']>>, ParentType, ContextType>;
  commit_with_segment?: Resolver<ResolversTypes['CommitWithSegment'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  presentation_group_values?: Resolver<Maybe<Array<ResolversTypes['PresentationGroupValue']>>, ParentType, ContextType>;
  presentation_groups?: Resolver<Maybe<ResolversTypes['RecordOfNullableStrings']>, ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['PricingGroupValue']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ContractUsageLineItemProductListItem'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractChargeLineItem'] = ResolversParentTypes['ContractChargeLineItem']> = {
  charge?: Resolver<ResolversTypes['ScheduledCharge'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['FixedOrProServiceProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCommitLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCommitLineItem'] = ResolversParentTypes['ContractCommitLineItem']> = {
  commit?: Resolver<ResolversTypes['Commit'], ParentType, ContextType>;
  commit_union?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['FixedProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractConsistencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractConsistency'] = ResolversParentTypes['ContractConsistency']> = {
  billing_provider_projection_inconsistencies?: Resolver<Array<ResolversTypes['ContractConsistency_OutdatedBillingProviderProjection']>, ParentType, ContextType, RequireFields<ContractConsistencyBilling_Provider_Projection_InconsistenciesArgs, 'limit'>>;
  rate_schedule_inconsistencies?: Resolver<Array<ResolversTypes['ContractConsistency_OutdatedRate']>, ParentType, ContextType, RequireFields<ContractConsistencyRate_Schedule_InconsistenciesArgs, 'limit'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractConsistency_OutdatedBillingProviderProjectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractConsistency_OutdatedBillingProviderProjection'] = ResolversParentTypes['ContractConsistency_OutdatedBillingProviderProjection']> = {
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  latest_event_version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  latest_projection_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractConsistency_OutdatedRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractConsistency_OutdatedRate'] = ResolversParentTypes['ContractConsistency_OutdatedRate']> = {
  earliest_rate_added_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  earliest_schedule_segment_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latest_rate_added_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  latest_schedule_segment_version?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['RateCardEntryPricingGroupValueEntry']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rate_card_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractConversionLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractConversionLineItem'] = ResolversParentTypes['ContractConversionLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractConversionLineItem']>>, ParentType, ContextType>;
  commit_with_segment?: Resolver<Maybe<ResolversTypes['CommitWithSegment']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  custom_credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['PricingGroupValue']>>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ContractUsageLineItemProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  tier_metadata?: Resolver<Maybe<ResolversTypes['TierMetadata']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField'] = ResolversParentTypes['ContractCreditManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  contract_credit_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField_aggregate'] = ResolversParentTypes['ContractCreditManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField_aggregate_fields'] = ResolversParentTypes['ContractCreditManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ContractCreditManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField_max_fields'] = ResolversParentTypes['ContractCreditManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_credit_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField_min_fields'] = ResolversParentTypes['ContractCreditManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_credit_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractCreditManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreditManagedField_mutation_response'] = ResolversParentTypes['ContractCreditManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractDiscountLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractDiscountLineItem'] = ResolversParentTypes['ContractDiscountLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Discount'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['FixedProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractGcpProServiceRoyaltyLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractGCPProServiceRoyaltyLineItem'] = ResolversParentTypes['ContractGCPProServiceRoyaltyLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractGCPProServiceRoyaltyLineItem']>>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reseller_royalty?: Resolver<ResolversTypes['GCPProServiceRoyalty'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractGcpRoyaltyLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractGCPRoyaltyLineItem'] = ResolversParentTypes['ContractGCPRoyaltyLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractGCPRoyaltyLineItem']>>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reseller_royalty?: Resolver<ResolversTypes['GCPRoyalty'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField'] = ResolversParentTypes['ContractManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField_aggregate'] = ResolversParentTypes['ContractManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ContractManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ContractManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField_aggregate_fields'] = ResolversParentTypes['ContractManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ContractManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ContractManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ContractManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField_max_fields'] = ResolversParentTypes['ContractManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField_min_fields'] = ResolversParentTypes['ContractManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractManagedField_mutation_response'] = ResolversParentTypes['ContractManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ContractManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractPage'] = ResolversParentTypes['ContractPage']> = {
  contracts?: Resolver<Array<ResolversTypes['Contract']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractPostpaidTrueupInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractPostpaidTrueupInvoice'] = ResolversParentTypes['ContractPostpaidTrueupInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  corrections?: Resolver<Array<ResolversTypes['CorrectionInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ContractPostpaidTrueupInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  regenerated_from?: Resolver<Maybe<ResolversTypes['ContractPostpaidTrueupInvoice']>, ParentType, ContextType>;
  regenerated_to?: Resolver<Maybe<ResolversTypes['ContractPostpaidTrueupInvoice']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractPostpaidTrueupLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractPostpaidTrueupLineItem'] = ResolversParentTypes['ContractPostpaidTrueupLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postpaid_commit?: Resolver<ResolversTypes['PostpaidCommit'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractPricingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractPricing'] = ResolversParentTypes['ContractPricing']> = {
  consistency?: Resolver<ResolversTypes['ContractConsistency'], ParentType, ContextType>;
  pricing_group_keys_in_use?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType, RequireFields<ContractPricingProductArgs, 'id'>>;
  products?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType, Partial<ContractPricingProductsArgs>>;
  rate_card?: Resolver<ResolversTypes['RateCardMetadata'], ParentType, ContextType, RequireFields<ContractPricingRate_CardArgs, 'id'>>;
  rate_cards?: Resolver<Array<ResolversTypes['RateCardMetadata']>, ParentType, ContextType, Partial<ContractPricingRate_CardsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProServiceInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProServiceInvoice'] = ResolversParentTypes['ContractProServiceInvoice']> = {
  amendment?: Resolver<Maybe<ResolversTypes['ContractAmendment']>, ParentType, ContextType>;
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ContractProServiceInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  netsuite_invoice_header_end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  netsuite_invoice_header_start?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  regenerated_from?: Resolver<Maybe<ResolversTypes['ContractProServiceInvoice']>, ParentType, ContextType>;
  regenerated_to?: Resolver<Maybe<ResolversTypes['ContractProServiceInvoice']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProServiceLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProServiceLineItem'] = ResolversParentTypes['ContractProServiceLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_invoice_billing_end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  netsuite_invoice_billing_start?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  pro_service?: Resolver<ResolversTypes['ProService'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProServiceProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField'] = ResolversParentTypes['ContractProductManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  contract_product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField_aggregate'] = ResolversParentTypes['ContractProductManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField_aggregate_fields'] = ResolversParentTypes['ContractProductManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ContractProductManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField_max_fields'] = ResolversParentTypes['ContractProductManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField_min_fields'] = ResolversParentTypes['ContractProductManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractProductManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractProductManagedField_mutation_response'] = ResolversParentTypes['ContractProductManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractRateScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRateSchedule'] = ResolversParentTypes['ContractRateSchedule']> = {
  credit_types_on_segments?: Resolver<Array<ResolversTypes['CreditType']>, ParentType, ContextType>;
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  products_on_segments?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType>;
  scalar_segments?: Resolver<Array<ResolversTypes['Scalar__ContractRateScheduleSegment']>, ParentType, ContextType>;
  segments?: Resolver<Array<ResolversTypes['ContractRateScheduleSegment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractRateScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRateScheduleSegment'] = ResolversParentTypes['ContractRateScheduleSegment']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  list_rate?: Resolver<ResolversTypes['Rate'], ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['Rate']>, ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['RateCardEntryPricingGroupValueEntry']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractRefundInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRefundInvoice'] = ResolversParentTypes['ContractRefundInvoice']> = {
  amendment?: Resolver<Maybe<ResolversTypes['ContractAmendment']>, ParentType, ContextType>;
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  correction_record?: Resolver<Maybe<ResolversTypes['ContractRefundInvoiceCorrectionRecord']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ContractRefundInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  regenerated_from?: Resolver<Maybe<ResolversTypes['ContractRefundInvoice']>, ParentType, ContextType>;
  regenerated_to?: Resolver<Maybe<ResolversTypes['ContractRefundInvoice']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractRefundInvoiceCorrectionRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRefundInvoiceCorrectionRecord'] = ResolversParentTypes['ContractRefundInvoiceCorrectionRecord']> = {
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  original_invoice?: Resolver<ResolversTypes['ContractRefundInvoiceCorrectionRecordOriginalInvoice'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractRefundInvoiceCorrectionRecordOriginalInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRefundInvoiceCorrectionRecordOriginalInvoice'] = ResolversParentTypes['ContractRefundInvoiceCorrectionRecordOriginalInvoice']> = {
  __resolveType: TypeResolveFn<'ContractPostpaidTrueupInvoice' | 'ContractScheduledInvoice', ParentType, ContextType>;
};

export type ContractRolloverCommitLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractRolloverCommitLineItem'] = ResolversParentTypes['ContractRolloverCommitLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ContractUsageLineItemProductListItem'], ParentType, ContextType>;
  rollover_commit?: Resolver<ResolversTypes['Commit'], ParentType, ContextType>;
  rollover_commit_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rollover_commit_union?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractScheduledInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractScheduledInvoice'] = ResolversParentTypes['ContractScheduledInvoice']> = {
  amendment?: Resolver<Maybe<ResolversTypes['ContractAmendment']>, ParentType, ContextType>;
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  corrections?: Resolver<Array<ResolversTypes['CorrectionInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ContractScheduledInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  regenerated_from?: Resolver<Maybe<ResolversTypes['ContractScheduledInvoice']>, ParentType, ContextType>;
  regenerated_to?: Resolver<Maybe<ResolversTypes['ContractScheduledInvoice']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractScheduledLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractScheduledLineItem'] = ResolversParentTypes['ContractScheduledLineItem']> = {
  __resolveType: TypeResolveFn<'ContractAWSProServiceRoyaltyLineItem' | 'ContractAWSRoyaltyLineItem' | 'ContractChargeLineItem' | 'ContractCommitLineItem' | 'ContractDiscountLineItem' | 'ContractGCPProServiceRoyaltyLineItem' | 'ContractGCPRoyaltyLineItem', ParentType, ContextType>;
};

export type ContractStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractStats'] = ResolversParentTypes['ContractStats']> = {
  customers_with_contracts_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractSubscriptionLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractSubscriptionLineItem'] = ResolversParentTypes['ContractSubscriptionLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractSubscriptionLineItem']>>, ParentType, ContextType>;
  commit_with_segment?: Resolver<Maybe<ResolversTypes['CommitWithSegment']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  is_prorated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['SubscriptionProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractUsageInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractUsageInvoice'] = ResolversParentTypes['ContractUsageInvoice']> = {
  billable_status?: Resolver<ResolversTypes['BillableStatus'], ParentType, ContextType>;
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  corrections?: Resolver<Array<ResolversTypes['CorrectionInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ContractUsageInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  regenerated_from?: Resolver<Maybe<ResolversTypes['ContractUsageInvoice']>, ParentType, ContextType>;
  regenerated_to?: Resolver<Maybe<ResolversTypes['ContractUsageInvoice']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractUsageInvoiceScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractUsageInvoiceSchedule'] = ResolversParentTypes['ContractUsageInvoiceSchedule']> = {
  billing_cycle_anchor_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['ContractUsageInvoiceScheduleFrequencyEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractUsageLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractUsageLineItem'] = ResolversParentTypes['ContractUsageLineItem']> = {
  breakdown?: Resolver<Maybe<Array<ResolversTypes['ContractUsageLineItem']>>, ParentType, ContextType>;
  commit_with_segment?: Resolver<Maybe<ResolversTypes['CommitWithSegment']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  presentation_group_values?: Resolver<Maybe<Array<ResolversTypes['PresentationGroupValue']>>, ParentType, ContextType>;
  presentation_groups?: Resolver<Maybe<ResolversTypes['RecordOfNullableStrings']>, ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['PricingGroupValue']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ContractUsageLineItemProductListItem'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  tier_metadata?: Resolver<Maybe<ResolversTypes['TierMetadata']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractUsageLineItemProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractUsageLineItemProductListItem'] = ResolversParentTypes['ContractUsageLineItemProductListItem']> = {
  __resolveType: TypeResolveFn<'CompositeProductListItem' | 'SubscriptionProductListItem' | 'UsageProductListItem', ParentType, ContextType>;
};

export type CorrectableInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CorrectableInvoice'] = ResolversParentTypes['CorrectableInvoice']> = {
  __resolveType: TypeResolveFn<'ArrearsInvoice' | 'ContractPostpaidTrueupInvoice' | 'ContractScheduledInvoice' | 'ContractUsageInvoice', ParentType, ContextType>;
};

export type CorrectionInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CorrectionInvoice'] = ResolversParentTypes['CorrectionInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  correction_record?: Resolver<ResolversTypes['InvoiceCorrectionRecord'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<CorrectionInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cost'] = ResolversParentTypes['Cost']> = {
  breakdown?: Resolver<Array<ResolversTypes['BreakdownItem']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['CostWindow'] = ResolversParentTypes['CostWindow']> = {
  costs?: Resolver<Array<ResolversTypes['Cost']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment'] = ResolversParentTypes['CreditAdjustment']> = {
  CreditGrant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grant_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_aggregate'] = ResolversParentTypes['CreditAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_aggregate_fields'] = ResolversParentTypes['CreditAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_avg_fields'] = ResolversParentTypes['CreditAdjustment_avg_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_max_fields'] = ResolversParentTypes['CreditAdjustment_max_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_min_fields'] = ResolversParentTypes['CreditAdjustment_min_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_mutation_response'] = ResolversParentTypes['CreditAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_stddev_fields'] = ResolversParentTypes['CreditAdjustment_stddev_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_stddev_pop_fields'] = ResolversParentTypes['CreditAdjustment_stddev_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_stddev_samp_fields'] = ResolversParentTypes['CreditAdjustment_stddev_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_sum_fields'] = ResolversParentTypes['CreditAdjustment_sum_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_var_pop_fields'] = ResolversParentTypes['CreditAdjustment_var_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_var_samp_fields'] = ResolversParentTypes['CreditAdjustment_var_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAdjustment_variance_fields'] = ResolversParentTypes['CreditAdjustment_variance_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CreditAmountScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CreditAmount'], any> {
  name: 'CreditAmount';
}

export type CreditAmountWithTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditAmountWithType'] = ResolversParentTypes['CreditAmountWithType']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant'] = ResolversParentTypes['CreditGrant']> = {
  AmountGrantedCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  AmountPaidCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CreditGrantDeductions?: Resolver<Array<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, Partial<CreditGrantCreditGrantDeductionsArgs>>;
  CreditGrantDeductions_aggregate?: Resolver<ResolversTypes['CreditGrantDeduction_aggregate'], ParentType, ContextType, Partial<CreditGrantCreditGrantDeductions_AggregateArgs>>;
  CreditGrantManagedFields?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, Partial<CreditGrantCreditGrantManagedFieldsArgs>>;
  CreditGrantManagedFields_aggregate?: Resolver<ResolversTypes['CreditGrantManagedField_aggregate'], ParentType, ContextType, Partial<CreditGrantCreditGrantManagedFields_AggregateArgs>>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  RecurringCreditGrant?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  Voider?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  amount_granted?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  amount_paid?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  billing_provider?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_enum']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cost_basis?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  expires_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['CreditPurchaseInvoice']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType>;
  mri_credit_ledger?: Resolver<ResolversTypes['MRI_CreditLedger'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  products?: Resolver<Maybe<Array<ResolversTypes['Product']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurring_credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_from_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_to_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rollover_settings?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<CreditGrantRollover_SettingsArgs>>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voided_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeductionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction'] = ResolversParentTypes['CreditGrantDeduction']> = {
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CreditGrant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit_grant_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  effective_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_aggregate'] = ResolversParentTypes['CreditGrantDeduction_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_aggregate_fields'] = ResolversParentTypes['CreditGrantDeduction_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditGrantDeduction_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_avg_fields'] = ResolversParentTypes['CreditGrantDeduction_avg_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_max_fields'] = ResolversParentTypes['CreditGrantDeduction_max_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  effective_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  memo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_min_fields'] = ResolversParentTypes['CreditGrantDeduction_min_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  effective_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  memo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_mutation_response'] = ResolversParentTypes['CreditGrantDeduction_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_stddev_fields'] = ResolversParentTypes['CreditGrantDeduction_stddev_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_stddev_pop_fields'] = ResolversParentTypes['CreditGrantDeduction_stddev_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_stddev_samp_fields'] = ResolversParentTypes['CreditGrantDeduction_stddev_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_sum_fields'] = ResolversParentTypes['CreditGrantDeduction_sum_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_var_pop_fields'] = ResolversParentTypes['CreditGrantDeduction_var_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_var_samp_fields'] = ResolversParentTypes['CreditGrantDeduction_var_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantDeduction_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantDeduction_variance_fields'] = ResolversParentTypes['CreditGrantDeduction_variance_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory'] = ResolversParentTypes['CreditGrantHistory']> = {
  BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType>;
  CreditGrantUpdater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grant_billing_provider?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_enum']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  credit_grant_expires_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grant_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  credit_grant_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit_grant_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_rollover_settings?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<CreditGrantHistoryCredit_Grant_Rollover_SettingsArgs>>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grant_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_aggregate'] = ResolversParentTypes['CreditGrantHistory_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_aggregate_fields'] = ResolversParentTypes['CreditGrantHistory_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditGrantHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_avg_fields'] = ResolversParentTypes['CreditGrantHistory_avg_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_max_fields'] = ResolversParentTypes['CreditGrantHistory_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  credit_grant_expires_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  credit_grant_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_min_fields'] = ResolversParentTypes['CreditGrantHistory_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  credit_grant_expires_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  credit_grant_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_grant_updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_mutation_response'] = ResolversParentTypes['CreditGrantHistory_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_stddev_fields'] = ResolversParentTypes['CreditGrantHistory_stddev_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_stddev_pop_fields'] = ResolversParentTypes['CreditGrantHistory_stddev_pop_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_stddev_samp_fields'] = ResolversParentTypes['CreditGrantHistory_stddev_samp_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_sum_fields'] = ResolversParentTypes['CreditGrantHistory_sum_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_var_pop_fields'] = ResolversParentTypes['CreditGrantHistory_var_pop_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_var_samp_fields'] = ResolversParentTypes['CreditGrantHistory_var_samp_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantHistory_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantHistory_variance_fields'] = ResolversParentTypes['CreditGrantHistory_variance_fields']> = {
  credit_grant_databricks_sbf_rollover_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  credit_grant_databricks_sbf_rollover_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField'] = ResolversParentTypes['CreditGrantManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CreditGrant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  credit_grant_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField_aggregate'] = ResolversParentTypes['CreditGrantManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField_aggregate_fields'] = ResolversParentTypes['CreditGrantManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditGrantManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField_max_fields'] = ResolversParentTypes['CreditGrantManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField_min_fields'] = ResolversParentTypes['CreditGrantManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantManagedField_mutation_response'] = ResolversParentTypes['CreditGrantManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantPage'] = ResolversParentTypes['CreditGrantPage']> = {
  credit_grants?: Resolver<Array<ResolversTypes['MRI_CreditGrant']>, ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrantUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrantUnion'] = ResolversParentTypes['CreditGrantUnion']> = {
  __resolveType: TypeResolveFn<'CreditGrant' | 'MRI_CreditGrant' | 'PrepaidCreditGrant', ParentType, ContextType>;
};

export type CreditGrant_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_aggregate'] = ResolversParentTypes['CreditGrant_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditGrant_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditGrant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_aggregate_fields'] = ResolversParentTypes['CreditGrant_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditGrant_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditGrant_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditGrant_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditGrant_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditGrant_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditGrant_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditGrant_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditGrant_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditGrant_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditGrant_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditGrant_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_avg_fields'] = ResolversParentTypes['CreditGrant_avg_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_max_fields'] = ResolversParentTypes['CreditGrant_max_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expires_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurring_credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_from_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_to_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voided_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_min_fields'] = ResolversParentTypes['CreditGrant_min_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  expires_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurring_credit_grant_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_from_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rolled_over_to_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voided_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_mutation_response'] = ResolversParentTypes['CreditGrant_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditGrant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_stddev_fields'] = ResolversParentTypes['CreditGrant_stddev_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_stddev_pop_fields'] = ResolversParentTypes['CreditGrant_stddev_pop_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_stddev_samp_fields'] = ResolversParentTypes['CreditGrant_stddev_samp_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_sum_fields'] = ResolversParentTypes['CreditGrant_sum_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_var_pop_fields'] = ResolversParentTypes['CreditGrant_var_pop_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_var_samp_fields'] = ResolversParentTypes['CreditGrant_var_samp_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditGrant_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditGrant_variance_fields'] = ResolversParentTypes['CreditGrant_variance_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditLineItem'] = ResolversParentTypes['CreditLineItem']> = {
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  time_range?: Resolver<Maybe<ResolversTypes['TimeRange']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditPurchaseChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditPurchaseChargeLineItem'] = ResolversParentTypes['CreditPurchaseChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditPurchaseInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditPurchaseInvoice'] = ResolversParentTypes['CreditPurchaseInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<CreditPurchaseInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditPurchaseLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditPurchaseLineItem'] = ResolversParentTypes['CreditPurchaseLineItem']> = {
  credit_grant?: Resolver<ResolversTypes['CreditGrantUnion'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sub_line_items?: Resolver<Array<ResolversTypes['CreditPurchaseChargeLineItem']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType'] = ResolversParentTypes['CreditType']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  environment_type?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_enum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion'] = ResolversParentTypes['CreditTypeConversion']> = {
  CustomCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  FiatCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  custom_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment'] = ResolversParentTypes['CreditTypeConversionAdjustment']> = {
  CustomCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  CustomerPlan?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType>;
  FiatCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  custom_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_aggregate'] = ResolversParentTypes['CreditTypeConversionAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_aggregate_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditTypeConversionAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_avg_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_avg_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_max_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_min_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_mutation_response'] = ResolversParentTypes['CreditTypeConversionAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_stddev_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_stddev_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_stddev_pop_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_stddev_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_stddev_samp_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_stddev_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_sum_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_sum_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_var_pop_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_var_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_var_samp_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_var_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversionAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversionAdjustment_variance_fields'] = ResolversParentTypes['CreditTypeConversionAdjustment_variance_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_aggregate'] = ResolversParentTypes['CreditTypeConversion_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_aggregate_fields'] = ResolversParentTypes['CreditTypeConversion_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditTypeConversion_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_avg_fields'] = ResolversParentTypes['CreditTypeConversion_avg_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_max_fields'] = ResolversParentTypes['CreditTypeConversion_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_min_fields'] = ResolversParentTypes['CreditTypeConversion_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  fiat_currency_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_mutation_response'] = ResolversParentTypes['CreditTypeConversion_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_stddev_fields'] = ResolversParentTypes['CreditTypeConversion_stddev_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_stddev_pop_fields'] = ResolversParentTypes['CreditTypeConversion_stddev_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_stddev_samp_fields'] = ResolversParentTypes['CreditTypeConversion_stddev_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_sum_fields'] = ResolversParentTypes['CreditTypeConversion_sum_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_var_pop_fields'] = ResolversParentTypes['CreditTypeConversion_var_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_var_samp_fields'] = ResolversParentTypes['CreditTypeConversion_var_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditTypeConversion_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditTypeConversion_variance_fields'] = ResolversParentTypes['CreditTypeConversion_variance_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  to_fiat_conversion_factor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditType_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType_aggregate'] = ResolversParentTypes['CreditType_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CreditType_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CreditType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditType_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType_aggregate_fields'] = ResolversParentTypes['CreditType_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CreditType_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CreditType_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CreditType_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditType_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType_max_fields'] = ResolversParentTypes['CreditType_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditType_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType_min_fields'] = ResolversParentTypes['CreditType_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreditType_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreditType_mutation_response'] = ResolversParentTypes['CreditType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CreditType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentPlanUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentPlanUsage'] = ResolversParentTypes['CurrentPlanUsage']> = {
  billable_metric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usage?: Resolver<Array<ResolversTypes['UsageWindow']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing'] = ResolversParentTypes['CustomPricing']> = {
  CustomerPlan?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType>;
  CustomerPlanAdjustments?: Resolver<Array<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, Partial<CustomPricingCustomerPlanAdjustmentsArgs>>;
  CustomerPlanAdjustments_aggregate?: Resolver<ResolversTypes['CustomerPlanAdjustment_aggregate'], ParentType, ContextType, Partial<CustomPricingCustomerPlanAdjustments_AggregateArgs>>;
  PricedProductAdjustments?: Resolver<Array<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, Partial<CustomPricingPricedProductAdjustmentsArgs>>;
  PricedProductAdjustments_aggregate?: Resolver<ResolversTypes['PricedProductAdjustment_aggregate'], ParentType, ContextType, Partial<CustomPricingPricedProductAdjustments_AggregateArgs>>;
  PricedProductPricingFactorAdjustments?: Resolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, Partial<CustomPricingPricedProductPricingFactorAdjustmentsArgs>>;
  PricedProductPricingFactorAdjustments_aggregate?: Resolver<ResolversTypes['PricedProductPricingFactorAdjustment_aggregate'], ParentType, ContextType, Partial<CustomPricingPricedProductPricingFactorAdjustments_AggregateArgs>>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricing_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing_aggregate'] = ResolversParentTypes['CustomPricing_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomPricing_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomPricing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricing_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing_aggregate_fields'] = ResolversParentTypes['CustomPricing_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomPricing_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomPricing_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomPricing_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricing_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing_max_fields'] = ResolversParentTypes['CustomPricing_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricing_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing_min_fields'] = ResolversParentTypes['CustomPricing_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomPricing_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomPricing_mutation_response'] = ResolversParentTypes['CustomPricing_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomPricing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomRate'] = ResolversParentTypes['CustomRate']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  BillingProviderCustomers?: Resolver<Array<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, Partial<CustomerBillingProviderCustomersArgs>>;
  BillingProviderCustomers_aggregate?: Resolver<ResolversTypes['BillingProviderCustomer_aggregate'], ParentType, ContextType, Partial<CustomerBillingProviderCustomers_AggregateArgs>>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  CreditGrants?: Resolver<Array<ResolversTypes['CreditGrant']>, ParentType, ContextType, Partial<CustomerCreditGrantsArgs>>;
  CreditGrants_aggregate?: Resolver<ResolversTypes['CreditGrant_aggregate'], ParentType, ContextType, Partial<CustomerCreditGrants_AggregateArgs>>;
  CustomerAlerts?: Resolver<Array<ResolversTypes['CustomerAlert']>, ParentType, ContextType, Partial<CustomerCustomerAlertsArgs>>;
  CustomerAlerts_aggregate?: Resolver<ResolversTypes['CustomerAlert_aggregate'], ParentType, ContextType, Partial<CustomerCustomerAlerts_AggregateArgs>>;
  CustomerConfigs?: Resolver<Array<ResolversTypes['CustomerConfig']>, ParentType, ContextType, Partial<CustomerCustomerConfigsArgs>>;
  CustomerConfigs_aggregate?: Resolver<ResolversTypes['CustomerConfig_aggregate'], ParentType, ContextType, Partial<CustomerCustomerConfigs_AggregateArgs>>;
  CustomerIngestAliases?: Resolver<Array<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, Partial<CustomerCustomerIngestAliasesArgs>>;
  CustomerIngestAliases_aggregate?: Resolver<ResolversTypes['CustomerIngestAlias_aggregate'], ParentType, ContextType, Partial<CustomerCustomerIngestAliases_AggregateArgs>>;
  CustomerManagedFields?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, Partial<CustomerCustomerManagedFieldsArgs>>;
  CustomerManagedFields_aggregate?: Resolver<ResolversTypes['CustomerManagedField_aggregate'], ParentType, ContextType, Partial<CustomerCustomerManagedFields_AggregateArgs>>;
  CustomerPlans?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType, Partial<CustomerCustomerPlansArgs>>;
  CustomerPlans_aggregate?: Resolver<ResolversTypes['CustomerPlan_aggregate'], ParentType, ContextType, Partial<CustomerCustomerPlans_AggregateArgs>>;
  alerts?: Resolver<Array<ResolversTypes['CustomerAlertComposite']>, ParentType, ContextType, RequireFields<CustomerAlertsArgs, 'types'>>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  billable_status?: Resolver<ResolversTypes['BillableStatusWithEffectiveDate'], ParentType, ContextType, Partial<CustomerBillable_StatusArgs>>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  commits?: Resolver<Array<ResolversTypes['CommitUnion']>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<CustomerContractArgs, 'id'>>;
  contract_status?: Resolver<Array<ResolversTypes['CustomerContractStatus']>, ParentType, ContextType>;
  contracts?: Resolver<Array<ResolversTypes['Contract']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grants?: Resolver<Array<ResolversTypes['MRI_CreditGrant']>, ParentType, ContextType>;
  current_plan_usage?: Resolver<Maybe<Array<ResolversTypes['CurrentPlanUsage']>>, ParentType, ContextType, RequireFields<CustomerCurrent_Plan_UsageArgs, 'end_date' | 'start_date'>>;
  current_seat_counts?: Resolver<Array<ResolversTypes['SeatCount']>, ParentType, ContextType>;
  end_date_of_last_finalized_invoice?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  has_contract?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, Partial<CustomerInvoicesArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType>;
  mri_invoices?: Resolver<ResolversTypes['InvoicePage'], ParentType, ContextType, RequireFields<CustomerMri_InvoicesArgs, 'limit'>>;
  mri_ledgers?: Resolver<Array<ResolversTypes['MRI_CreditLedger']>, ParentType, ContextType, Partial<CustomerMri_LedgersArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  named_schedule?: Resolver<Array<ResolversTypes['NamedScheduleSegment']>, ParentType, ContextType, RequireFields<CustomerNamed_ScheduleArgs, 'name'>>;
  named_schedules?: Resolver<Array<ResolversTypes['NamedSchedule']>, ParentType, ContextType>;
  plan_status?: Resolver<Array<ResolversTypes['CustomerPlanStatus']>, ParentType, ContextType>;
  recharge_settings?: Resolver<Array<ResolversTypes['RechargeSettings']>, ParentType, ContextType>;
  service_periods?: Resolver<Array<ResolversTypes['ServicePeriod']>, ParentType, ContextType, Partial<CustomerService_PeriodsArgs>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAggregate'] = ResolversParentTypes['CustomerAggregate']> = {
  rate_updates_hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert'] = ResolversParentTypes['CustomerAlert']> = {
  Alert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  CustomerAlertHistories?: Resolver<Array<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, Partial<CustomerAlertCustomerAlertHistoriesArgs>>;
  CustomerAlertHistories_aggregate?: Resolver<ResolversTypes['CustomerAlertHistory_aggregate'], ParentType, ContextType, Partial<CustomerAlertCustomerAlertHistories_AggregateArgs>>;
  additional_data?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<CustomerAlertAdditional_DataArgs>>;
  alert_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  current_status?: Resolver<ResolversTypes['CustomerAlertStatusEnum_enum'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  granular_idempotency_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  last_evaluation_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  triggered_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertCompositeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertComposite'] = ResolversParentTypes['CustomerAlertComposite']> = {
  alert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customer_status?: Resolver<Maybe<ResolversTypes['CustomerAlertCompositeStatusEnum']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  last_evaluation_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  triggered_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory'] = ResolversParentTypes['CustomerAlertHistory']> = {
  CustomerAlert?: Resolver<ResolversTypes['CustomerAlert'], ParentType, ContextType>;
  additional_data?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<CustomerAlertHistoryAdditional_DataArgs>>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_alert_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CustomerAlertStatusEnum_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistory_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory_aggregate'] = ResolversParentTypes['CustomerAlertHistory_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistory_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory_aggregate_fields'] = ResolversParentTypes['CustomerAlertHistory_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerAlertHistory_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistory_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory_max_fields'] = ResolversParentTypes['CustomerAlertHistory_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistory_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory_min_fields'] = ResolversParentTypes['CustomerAlertHistory_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertHistory_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertHistory_mutation_response'] = ResolversParentTypes['CustomerAlertHistory_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum'] = ResolversParentTypes['CustomerAlertStatusEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum_aggregate'] = ResolversParentTypes['CustomerAlertStatusEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum_aggregate_fields'] = ResolversParentTypes['CustomerAlertStatusEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerAlertStatusEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum_max_fields'] = ResolversParentTypes['CustomerAlertStatusEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum_min_fields'] = ResolversParentTypes['CustomerAlertStatusEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlertStatusEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlertStatusEnum_mutation_response'] = ResolversParentTypes['CustomerAlertStatusEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlert_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert_aggregate'] = ResolversParentTypes['CustomerAlert_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerAlert_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerAlert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlert_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert_aggregate_fields'] = ResolversParentTypes['CustomerAlert_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerAlert_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerAlert_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerAlert_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlert_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert_max_fields'] = ResolversParentTypes['CustomerAlert_max_fields']> = {
  alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  environment_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  granular_idempotency_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  last_evaluation_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  triggered_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlert_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert_min_fields'] = ResolversParentTypes['CustomerAlert_min_fields']> = {
  alert_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  environment_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  granular_idempotency_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  last_evaluation_time?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  triggered_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerAlert_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerAlert_mutation_response'] = ResolversParentTypes['CustomerAlert_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerAlert']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerBillingProviderConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerBillingProviderConfiguration'] = ResolversParentTypes['CustomerBillingProviderConfiguration']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  configuration?: Resolver<ResolversTypes['FlatKeyValueMap'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  delivery_method?: Resolver<ResolversTypes['BillingProviderDeliveryMethod_enum'], ParentType, ContextType>;
  delivery_method_configuration?: Resolver<ResolversTypes['FlatKeyValueMap'], ParentType, ContextType>;
  delivery_method_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerBillingProviderConfigurationNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerBillingProviderConfigurationNotification'] = ResolversParentTypes['CustomerBillingProviderConfigurationNotification']> = {
  contract_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_billing_provider_configuration?: Resolver<ResolversTypes['CustomerBillingProviderConfiguration'], ParentType, ContextType>;
  notify_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig'] = ResolversParentTypes['CustomerConfig']> = {
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfig_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig_aggregate'] = ResolversParentTypes['CustomerConfig_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerConfig_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfig_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig_aggregate_fields'] = ResolversParentTypes['CustomerConfig_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerConfig_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerConfig_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerConfig_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfig_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig_max_fields'] = ResolversParentTypes['CustomerConfig_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfig_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig_min_fields'] = ResolversParentTypes['CustomerConfig_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerConfig_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerConfig_mutation_response'] = ResolversParentTypes['CustomerConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerContractStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerContractStatus'] = ResolversParentTypes['CustomerContractStatus']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  transition_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerCount'] = ResolversParentTypes['CustomerCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAliasResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias'] = ResolversParentTypes['CustomerIngestAlias']> = {
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  ingest_alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAlias_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias_aggregate'] = ResolversParentTypes['CustomerIngestAlias_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAlias_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias_aggregate_fields'] = ResolversParentTypes['CustomerIngestAlias_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerIngestAlias_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAlias_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias_max_fields'] = ResolversParentTypes['CustomerIngestAlias_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ingest_alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAlias_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias_min_fields'] = ResolversParentTypes['CustomerIngestAlias_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ingest_alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerIngestAlias_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerIngestAlias_mutation_response'] = ResolversParentTypes['CustomerIngestAlias_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerListResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerList'] = ResolversParentTypes['CustomerList']> = {
  customers?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType>;
  next_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prev_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField'] = ResolversParentTypes['CustomerManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField_aggregate'] = ResolversParentTypes['CustomerManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField_aggregate_fields'] = ResolversParentTypes['CustomerManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField_max_fields'] = ResolversParentTypes['CustomerManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField_min_fields'] = ResolversParentTypes['CustomerManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerManagedField_mutation_response'] = ResolversParentTypes['CustomerManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan'] = ResolversParentTypes['CustomerPlan']> = {
  CreditTypeConversionAdjustments?: Resolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, Partial<CustomerPlanCreditTypeConversionAdjustmentsArgs>>;
  CreditTypeConversionAdjustments_aggregate?: Resolver<ResolversTypes['CreditTypeConversionAdjustment_aggregate'], ParentType, ContextType, Partial<CustomerPlanCreditTypeConversionAdjustments_AggregateArgs>>;
  CustomPricings?: Resolver<Array<ResolversTypes['CustomPricing']>, ParentType, ContextType, Partial<CustomerPlanCustomPricingsArgs>>;
  CustomPricings_aggregate?: Resolver<ResolversTypes['CustomPricing_aggregate'], ParentType, ContextType, Partial<CustomerPlanCustomPricings_AggregateArgs>>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  CustomerPlanManagedFields?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, Partial<CustomerPlanCustomerPlanManagedFieldsArgs>>;
  CustomerPlanManagedFields_aggregate?: Resolver<ResolversTypes['CustomerPlanManagedField_aggregate'], ParentType, ContextType, Partial<CustomerPlanCustomerPlanManagedFields_AggregateArgs>>;
  EnvironmentTypeEnum?: Resolver<ResolversTypes['EnvironmentTypeEnum'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  TrialSpec?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType>;
  already_invoiced?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cancellation_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType>;
  mri_remaining_trial_spending_caps?: Resolver<Array<ResolversTypes['CreditAmountWithType']>, ParentType, ContextType>;
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  trial_spec?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment'] = ResolversParentTypes['CustomerPlanAdjustment']> = {
  CustomPricing?: Resolver<ResolversTypes['CustomPricing'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  custom_pricing_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  percent_adjustment?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_aggregate'] = ResolversParentTypes['CustomerPlanAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_aggregate_fields'] = ResolversParentTypes['CustomerPlanAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerPlanAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_avg_fields'] = ResolversParentTypes['CustomerPlanAdjustment_avg_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_max_fields'] = ResolversParentTypes['CustomerPlanAdjustment_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_min_fields'] = ResolversParentTypes['CustomerPlanAdjustment_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_mutation_response'] = ResolversParentTypes['CustomerPlanAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_stddev_fields'] = ResolversParentTypes['CustomerPlanAdjustment_stddev_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_stddev_pop_fields'] = ResolversParentTypes['CustomerPlanAdjustment_stddev_pop_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_stddev_samp_fields'] = ResolversParentTypes['CustomerPlanAdjustment_stddev_samp_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_sum_fields'] = ResolversParentTypes['CustomerPlanAdjustment_sum_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_var_pop_fields'] = ResolversParentTypes['CustomerPlanAdjustment_var_pop_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_var_samp_fields'] = ResolversParentTypes['CustomerPlanAdjustment_var_samp_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanAdjustment_variance_fields'] = ResolversParentTypes['CustomerPlanAdjustment_variance_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField'] = ResolversParentTypes['CustomerPlanManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CustomerPlan?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customer_plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField_aggregate'] = ResolversParentTypes['CustomerPlanManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField_aggregate_fields'] = ResolversParentTypes['CustomerPlanManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerPlanManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField_max_fields'] = ResolversParentTypes['CustomerPlanManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField_min_fields'] = ResolversParentTypes['CustomerPlanManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanManagedField_mutation_response'] = ResolversParentTypes['CustomerPlanManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge'] = ResolversParentTypes['CustomerPlanOneTimeCharge']> = {
  CustomerPlan?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType>;
  ProductPricingFactor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  service_period_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  sub_line_item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_aggregate'] = ResolversParentTypes['CustomerPlanOneTimeCharge_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_aggregate_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerPlanOneTimeCharge_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_avg_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_avg_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_max_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  service_period_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sub_line_item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_min_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customer_plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  service_period_start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  sub_line_item_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_mutation_response'] = ResolversParentTypes['CustomerPlanOneTimeCharge_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_pop_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_pop_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_samp_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_stddev_samp_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_sum_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_sum_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_var_pop_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_var_pop_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_var_samp_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_var_samp_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanOneTimeCharge_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanOneTimeCharge_variance_fields'] = ResolversParentTypes['CustomerPlanOneTimeCharge_variance_fields']> = {
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlanStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlanStatus'] = ResolversParentTypes['CustomerPlanStatus']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_aggregate'] = ResolversParentTypes['CustomerPlan_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerPlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_aggregate_fields'] = ResolversParentTypes['CustomerPlan_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['CustomerPlan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerPlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerPlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerPlan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['CustomerPlan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['CustomerPlan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['CustomerPlan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['CustomerPlan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['CustomerPlan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['CustomerPlan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['CustomerPlan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_avg_fields'] = ResolversParentTypes['CustomerPlan_avg_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_max_fields'] = ResolversParentTypes['CustomerPlan_max_fields']> = {
  cancellation_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_min_fields'] = ResolversParentTypes['CustomerPlan_min_fields']> = {
  cancellation_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_mutation_response'] = ResolversParentTypes['CustomerPlan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_stddev_fields'] = ResolversParentTypes['CustomerPlan_stddev_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_stddev_pop_fields'] = ResolversParentTypes['CustomerPlan_stddev_pop_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_stddev_samp_fields'] = ResolversParentTypes['CustomerPlan_stddev_samp_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_sum_fields'] = ResolversParentTypes['CustomerPlan_sum_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_var_pop_fields'] = ResolversParentTypes['CustomerPlan_var_pop_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_var_samp_fields'] = ResolversParentTypes['CustomerPlan_var_samp_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerPlan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerPlan_variance_fields'] = ResolversParentTypes['CustomerPlan_variance_fields']> = {
  net_payment_terms_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationshipResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship'] = ResolversParentTypes['CustomerRelationship']> = {
  child_customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  child_customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  parent_customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  parent_customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  starting_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationship_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship_aggregate'] = ResolversParentTypes['CustomerRelationship_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['CustomerRelationship_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['CustomerRelationship']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationship_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship_aggregate_fields'] = ResolversParentTypes['CustomerRelationship_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CustomerRelationship_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['CustomerRelationship_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['CustomerRelationship_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationship_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship_max_fields'] = ResolversParentTypes['CustomerRelationship_max_fields']> = {
  child_customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parent_customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  starting_on?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationship_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship_min_fields'] = ResolversParentTypes['CustomerRelationship_min_fields']> = {
  child_customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  parent_customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  starting_on?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRelationship_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRelationship_mutation_response'] = ResolversParentTypes['CustomerRelationship_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['CustomerRelationship']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerUsingContractsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerUsingContracts'] = ResolversParentTypes['CustomerUsingContracts']> = {
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customer_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer_aggregate'] = ResolversParentTypes['Customer_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Customer_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customer_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer_aggregate_fields'] = ResolversParentTypes['Customer_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Customer_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Customer_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Customer_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customer_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer_max_fields'] = ResolversParentTypes['Customer_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customer_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer_min_fields'] = ResolversParentTypes['Customer_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Customer_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer_mutation_response'] = ResolversParentTypes['Customer_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomersMetric'] = ResolversParentTypes['CustomersMetric']> = {
  total_customers?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total_customers_on_plan?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersUsingContractsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomersUsingContracts'] = ResolversParentTypes['CustomersUsingContracts']> = {
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  page?: Resolver<Array<ResolversTypes['CustomerUsingContracts']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange'] = ResolversParentTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange']> = {
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse'] = ResolversParentTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse']> = {
  customers?: Resolver<Array<ResolversTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount']> = {
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  schedule?: Resolver<ResolversTypes['DiscountSchedule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountSchedule'] = ResolversParentTypes['DiscountSchedule']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  recurring_schedule?: Resolver<Maybe<ResolversTypes['RecurringSchedule']>, ParentType, ContextType>;
  schedule_items?: Resolver<Array<ResolversTypes['DiscountScheduleItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountScheduleItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DiscountScheduleItem'] = ResolversParentTypes['DiscountScheduleItem']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan'] = ResolversParentTypes['DraftPlan']> = {
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<DraftPlanDataArgs>>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_aggregate'] = ResolversParentTypes['DraftPlan_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['DraftPlan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['DraftPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_aggregate_fields'] = ResolversParentTypes['DraftPlan_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['DraftPlan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<DraftPlan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['DraftPlan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['DraftPlan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['DraftPlan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['DraftPlan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['DraftPlan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['DraftPlan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['DraftPlan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['DraftPlan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['DraftPlan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_avg_fields'] = ResolversParentTypes['DraftPlan_avg_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_max_fields'] = ResolversParentTypes['DraftPlan_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_min_fields'] = ResolversParentTypes['DraftPlan_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_mutation_response'] = ResolversParentTypes['DraftPlan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['DraftPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_stddev_fields'] = ResolversParentTypes['DraftPlan_stddev_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_stddev_pop_fields'] = ResolversParentTypes['DraftPlan_stddev_pop_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_stddev_samp_fields'] = ResolversParentTypes['DraftPlan_stddev_samp_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_sum_fields'] = ResolversParentTypes['DraftPlan_sum_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_var_pop_fields'] = ResolversParentTypes['DraftPlan_var_pop_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_var_samp_fields'] = ResolversParentTypes['DraftPlan_var_samp_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftPlan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftPlan_variance_fields'] = ResolversParentTypes['DraftPlan_variance_fields']> = {
  revision?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditPlanResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditPlanResponse'] = ResolversParentTypes['EditPlanResponse']> = {
  new_revision?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  old_revision?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EffectiveDatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EffectiveDates'] = ResolversParentTypes['EffectiveDates']> = {
  exclusive_end_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Environment'] = ResolversParentTypes['Environment']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_enum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum'] = ResolversParentTypes['EnvironmentTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum_aggregate'] = ResolversParentTypes['EnvironmentTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum_aggregate_fields'] = ResolversParentTypes['EnvironmentTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<EnvironmentTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum_max_fields'] = ResolversParentTypes['EnvironmentTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum_min_fields'] = ResolversParentTypes['EnvironmentTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnvironmentTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnvironmentTypeEnum_mutation_response'] = ResolversParentTypes['EnvironmentTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  billable_metrics?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<EventBillable_MetricsArgs, 'types'>>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  event_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_duplicate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType>;
  processedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  seat_metrics?: Resolver<Array<ResolversTypes['SeatMetric']>, ParentType, ContextType, RequireFields<EventSeat_MetricsArgs, 'types'>>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  transaction_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventCountWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventCountWindow'] = ResolversParentTypes['EventCountWindow']> = {
  count?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  starting_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FailedMarketplacesMeteringRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedMarketplacesMeteringRecord'] = ResolversParentTypes['FailedMarketplacesMeteringRecord']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  marketplaces_metered_usage_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meter_timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FailedMarketplacesMeteringRecordListResolvers<ContextType = any, ParentType extends ResolversParentTypes['FailedMarketplacesMeteringRecordList'] = ResolversParentTypes['FailedMarketplacesMeteringRecordList']> = {
  failed_marketplaces_metering_records?: Resolver<Array<ResolversTypes['FailedMarketplacesMeteringRecord']>, ParentType, ContextType>;
  next_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FancyMetricValidationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FancyMetricValidationResponse'] = ResolversParentTypes['FancyMetricValidationResponse']> = {
  _debug_plan?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FixedOrProServiceProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FixedOrProServiceProductListItem'] = ResolversParentTypes['FixedOrProServiceProductListItem']> = {
  __resolveType: TypeResolveFn<'FixedProductListItem' | 'ProServiceProductListItem', ParentType, ContextType>;
};

export type FixedProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FixedProductListItem'] = ResolversParentTypes['FixedProductListItem']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  current?: Resolver<ResolversTypes['FixedProductListItemState'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial?: Resolver<ResolversTypes['FixedProductListItemState'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['ProductListItemUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FixedProductListItemStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FixedProductListItemState'] = ResolversParentTypes['FixedProductListItemState']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  is_refundable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee'] = ResolversParentTypes['FlatFee']> = {
  CollectionScheduleEnum?: Resolver<ResolversTypes['CollectionScheduleEnum'], ParentType, ContextType>;
  PricedProductPricingFactor?: Resolver<ResolversTypes['PricedProductPricingFactor'], ParentType, ContextType>;
  collection_interval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  collection_schedule?: Resolver<ResolversTypes['CollectionScheduleEnum_enum'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_prorated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment'] = ResolversParentTypes['FlatFeeAdjustment']> = {
  PriceAdjustmentTypeEnum?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum'], ParentType, ContextType>;
  PricedProductPricingFactorAdjustment?: Resolver<ResolversTypes['PricedProductPricingFactorAdjustment'], ParentType, ContextType>;
  adjustment_type_enum?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_aggregate'] = ResolversParentTypes['FlatFeeAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_aggregate_fields'] = ResolversParentTypes['FlatFeeAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<FlatFeeAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_avg_fields'] = ResolversParentTypes['FlatFeeAdjustment_avg_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_max_fields'] = ResolversParentTypes['FlatFeeAdjustment_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_min_fields'] = ResolversParentTypes['FlatFeeAdjustment_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_mutation_response'] = ResolversParentTypes['FlatFeeAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_stddev_fields'] = ResolversParentTypes['FlatFeeAdjustment_stddev_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_stddev_pop_fields'] = ResolversParentTypes['FlatFeeAdjustment_stddev_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_stddev_samp_fields'] = ResolversParentTypes['FlatFeeAdjustment_stddev_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_sum_fields'] = ResolversParentTypes['FlatFeeAdjustment_sum_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_var_pop_fields'] = ResolversParentTypes['FlatFeeAdjustment_var_pop_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_var_samp_fields'] = ResolversParentTypes['FlatFeeAdjustment_var_samp_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFeeAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFeeAdjustment_variance_fields'] = ResolversParentTypes['FlatFeeAdjustment_variance_fields']> = {
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_aggregate'] = ResolversParentTypes['FlatFee_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['FlatFee_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['FlatFee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_aggregate_fields'] = ResolversParentTypes['FlatFee_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['FlatFee_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<FlatFee_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['FlatFee_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['FlatFee_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['FlatFee_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['FlatFee_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['FlatFee_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['FlatFee_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['FlatFee_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['FlatFee_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['FlatFee_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_avg_fields'] = ResolversParentTypes['FlatFee_avg_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_max_fields'] = ResolversParentTypes['FlatFee_max_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_min_fields'] = ResolversParentTypes['FlatFee_min_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_mutation_response'] = ResolversParentTypes['FlatFee_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['FlatFee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_stddev_fields'] = ResolversParentTypes['FlatFee_stddev_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_stddev_pop_fields'] = ResolversParentTypes['FlatFee_stddev_pop_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_stddev_samp_fields'] = ResolversParentTypes['FlatFee_stddev_samp_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_sum_fields'] = ResolversParentTypes['FlatFee_sum_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_var_pop_fields'] = ResolversParentTypes['FlatFee_var_pop_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_var_samp_fields'] = ResolversParentTypes['FlatFee_var_samp_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlatFee_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatFee_variance_fields'] = ResolversParentTypes['FlatFee_variance_fields']> = {
  collection_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FlatKeyValueMapScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FlatKeyValueMap'], any> {
  name: 'FlatKeyValueMap';
}

export type FlatRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlatRate'] = ResolversParentTypes['FlatRate']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GcpProServiceRoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['GCPProServiceRoyalty'] = ResolversParentTypes['GCPProServiceRoyalty']> = {
  applicable_product_ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  gcp_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gcp_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GcpProServiceRoyaltyUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['GCPProServiceRoyaltyUpdate'] = ResolversParentTypes['GCPProServiceRoyaltyUpdate']> = {
  applicable_product_ids?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gcp_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gcp_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GcpRoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['GCPRoyalty'] = ResolversParentTypes['GCPRoyalty']> = {
  applicable_product_ids?: Resolver<Array<ResolversTypes['uuid']>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  gcp_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gcp_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GcpRoyaltyUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['GCPRoyaltyUpdate'] = ResolversParentTypes['GCPRoyaltyUpdate']> = {
  applicable_product_ids?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  applicable_product_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  gcp_account_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gcp_offer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_reseller_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reseller_contract_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateAzureAuthTokenMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateAzureAuthTokenMutationResponse'] = ResolversParentTypes['GenerateAzureAuthTokenMutationResponse']> = {
  auth_token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expires_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupedChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupedChargeLineItem'] = ResolversParentTypes['GroupedChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sub_line_items?: Resolver<Maybe<Array<ResolversTypes['TierSubLineItem']>>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupedProductChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupedProductChargeLineItem'] = ResolversParentTypes['GroupedProductChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  sub_line_items?: Resolver<Array<ResolversTypes['ProductChargeSubLineItem']>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupedUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupedUsage'] = ResolversParentTypes['GroupedUsage']> = {
  data?: Resolver<Array<ResolversTypes['GroupedUsageWindow']>, ParentType, ContextType>;
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupedUsageTupleResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupedUsageTuple'] = ResolversParentTypes['GroupedUsageTuple']> = {
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupedUsageWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupedUsageWindow'] = ResolversParentTypes['GroupedUsageWindow']> = {
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  groupings?: Resolver<Array<ResolversTypes['GroupedUsageTuple']>, ParentType, ContextType>;
  starting_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InitialUsageFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['InitialUsageFilter'] = ResolversParentTypes['InitialUsageFilter']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertBillingProviderDeliveryMethodResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsertBillingProviderDeliveryMethodResult'] = ResolversParentTypes['InsertBillingProviderDeliveryMethodResult']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  delivery_method?: Resolver<ResolversTypes['BillingProviderDeliveryMethod_enum'], ParentType, ContextType>;
  delivery_method_configuration?: Resolver<ResolversTypes['FlatKeyValueMap'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsertCustomerBillingProviderConfigurationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsertCustomerBillingProviderConfigurationResult'] = ResolversParentTypes['InsertCustomerBillingProviderConfigurationResult']> = {
  billing_provider?: Resolver<ResolversTypes['BillingProviderEnum_enum'], ParentType, ContextType>;
  configuration?: Resolver<ResolversTypes['FlatKeyValueMap'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  delivery_method_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  customer_plan?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_adjustments?: Resolver<Array<ResolversTypes['InvoiceAdjustment']>, ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['LineItem']>, ParentType, ContextType>;
  one_time_charges?: Resolver<Maybe<Array<ResolversTypes['OneTimeProductCharge']>>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType>;
  schedule_type?: Resolver<ResolversTypes['InvoiceScheduleTypeEnum'], ParentType, ContextType>;
  service_period?: Resolver<ResolversTypes['ServicePeriod'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatus'], ParentType, ContextType>;
  subtotal?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceAdjustment'] = ResolversParentTypes['InvoiceAdjustment']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  mri_rollout_adjustment_draft_only_credit_grant_id_do_not_use?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceCorrectionRecordResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceCorrectionRecord'] = ResolversParentTypes['InvoiceCorrectionRecord']> = {
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  original_invoice?: Resolver<ResolversTypes['CorrectableInvoice'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sequence_number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceCountsByFinalizationStatusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceCountsByFinalizationStatusResponse'] = ResolversParentTypes['InvoiceCountsByFinalizationStatusResponse']> = {
  in_progress?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  pending?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum'] = ResolversParentTypes['InvoiceExternalStatusEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum_aggregate'] = ResolversParentTypes['InvoiceExternalStatusEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum_aggregate_fields'] = ResolversParentTypes['InvoiceExternalStatusEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<InvoiceExternalStatusEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum_max_fields'] = ResolversParentTypes['InvoiceExternalStatusEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum_min_fields'] = ResolversParentTypes['InvoiceExternalStatusEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceExternalStatusEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExternalStatusEnum_mutation_response'] = ResolversParentTypes['InvoiceExternalStatusEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceFinalizationMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceFinalizationMetadata'] = ResolversParentTypes['InvoiceFinalizationMetadata']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  books_closed_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  finalization_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_cohort?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  invoices_finalized?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceFinalizationMetadataStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceFinalizationProgressCountResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceFinalizationProgressCountResponse'] = ResolversParentTypes['InvoiceFinalizationProgressCountResponse']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceIdentifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceIdentifier'] = ResolversParentTypes['InvoiceIdentifier']> = {
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField'] = ResolversParentTypes['InvoiceManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField_aggregate'] = ResolversParentTypes['InvoiceManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField_aggregate_fields'] = ResolversParentTypes['InvoiceManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<InvoiceManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField_max_fields'] = ResolversParentTypes['InvoiceManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField_min_fields'] = ResolversParentTypes['InvoiceManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceManagedField_mutation_response'] = ResolversParentTypes['InvoiceManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoicePageResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoicePage'] = ResolversParentTypes['InvoicePage']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoices?: Resolver<Array<ResolversTypes['MRI_Invoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JSONScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type LaunchSoxReportGeneratorMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LaunchSoxReportGeneratorMutationResponse'] = ResolversParentTypes['LaunchSoxReportGeneratorMutationResponse']> = {
  message_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyContractLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegacyContractLineItem'] = ResolversParentTypes['LegacyContractLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegacyLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegacyLineItem'] = ResolversParentTypes['LegacyLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineItem'] = ResolversParentTypes['LineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sub_line_items?: Resolver<Array<ResolversTypes['SubLineItem']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineItemChunkResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineItemChunk'] = ResolversParentTypes['LineItemChunk']> = {
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  time_range?: Resolver<ResolversTypes['TimeRange'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListDeliveryMethodsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListDeliveryMethodsResponse'] = ResolversParentTypes['ListDeliveryMethodsResponse']> = {
  delivery_methods?: Resolver<Array<ResolversTypes['BillingProviderDeliveryMethod']>, ParentType, ContextType>;
  next_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListDeliveryMethodsWithoutConfigurationForAllClientsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListDeliveryMethodsWithoutConfigurationForAllClientsResponse'] = ResolversParentTypes['ListDeliveryMethodsWithoutConfigurationForAllClientsResponse']> = {
  delivery_methods?: Resolver<Array<ResolversTypes['BillingProviderDeliveryMethodWithoutConfiguration']>, ParentType, ContextType>;
  next_cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditBalance'] = ResolversParentTypes['MRI_CreditBalance']> = {
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  excluding_pending?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  including_pending?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditDeductionCreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditDeductionCreditLedgerEntry'] = ResolversParentTypes['MRI_CreditDeductionCreditLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_deduction?: Resolver<ResolversTypes['CreditGrantDeduction'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditExpirationCreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditExpirationCreditLedgerEntry'] = ResolversParentTypes['MRI_CreditExpirationCreditLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditGrantResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditGrant'] = ResolversParentTypes['MRI_CreditGrant']> = {
  AmountGrantedCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  AmountPaidCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  Customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  Voider?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  amount_granted?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_paid?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  billing_provider?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_enum']>, ParentType, ContextType>;
  cost_basis?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  expires_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice?: Resolver<Maybe<ResolversTypes['CreditPurchaseInvoice']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType>;
  mri_credit_ledger?: Resolver<ResolversTypes['MRI_CreditLedger'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  products?: Resolver<Maybe<Array<ResolversTypes['Product']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rolled_over_from?: Resolver<Maybe<ResolversTypes['MRI_CreditGrant']>, ParentType, ContextType>;
  rolled_over_to?: Resolver<Maybe<ResolversTypes['MRI_CreditGrant']>, ParentType, ContextType>;
  rollover_settings?: Resolver<Maybe<ResolversTypes['RolloverSettings']>, ParentType, ContextType>;
  uniqueness_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditGrantCreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditGrantCreditLedgerEntry'] = ResolversParentTypes['MRI_CreditGrantCreditLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditLedgerResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditLedger'] = ResolversParentTypes['MRI_CreditLedger']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  ending_balance?: Resolver<ResolversTypes['MRI_CreditBalance'], ParentType, ContextType, Partial<Mri_CreditLedgerEnding_BalanceArgs>>;
  ledger_entries?: Resolver<Array<ResolversTypes['MRI_CreditLedgerEntry']>, ParentType, ContextType, Partial<Mri_CreditLedgerLedger_EntriesArgs>>;
  starting_balance?: Resolver<ResolversTypes['MRI_CreditBalance'], ParentType, ContextType, Partial<Mri_CreditLedgerStarting_BalanceArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_CreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_CreditLedgerEntry'] = ResolversParentTypes['MRI_CreditLedgerEntry']> = {
  __resolveType: TypeResolveFn<'MRI_CreditDeductionCreditLedgerEntry' | 'MRI_CreditExpirationCreditLedgerEntry' | 'MRI_CreditGrantCreditLedgerEntry' | 'MRI_PendingChargeCreditLedgerEntry' | 'MRI_PendingCreditExpirationCreditLedgerEntry' | 'MRI_PendingRolloverCreditGrantLedgerEntry' | 'MRI_PendingRolloverDeductionLedgerEntry' | 'MRI_RolloverCreditGrantLedgerEntry' | 'MRI_RolloverDeductionLedgerEntry', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
};

export type Mri_GroupedUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_GroupedUsage'] = ResolversParentTypes['MRI_GroupedUsage']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Array<ResolversTypes['MRI_GroupedUsageWindow']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_GroupedUsageTupleResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_GroupedUsageTuple'] = ResolversParentTypes['MRI_GroupedUsageTuple']> = {
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_GroupedUsageWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_GroupedUsageWindow'] = ResolversParentTypes['MRI_GroupedUsageWindow']> = {
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  groupings?: Resolver<Array<ResolversTypes['MRI_GroupedUsageTuple']>, ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_Invoice'] = ResolversParentTypes['MRI_Invoice']> = {
  __resolveType: TypeResolveFn<'AdHocPlanInvoice' | 'AdhocContractUsageInvoice' | 'AdvanceInvoice' | 'ArrearsInvoice' | 'ContractPostpaidTrueupInvoice' | 'ContractProServiceInvoice' | 'ContractRefundInvoice' | 'ContractScheduledInvoice' | 'ContractUsageInvoice' | 'CorrectionInvoice' | 'CreditPurchaseInvoice' | 'ParentInvoice' | 'SeatPurchaseInvoice', ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<Mri_InvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
};

export type Mri_LineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_LineItem'] = ResolversParentTypes['MRI_LineItem']> = {
  __resolveType: TypeResolveFn<'AdvanceChargeLineItem' | 'ChargeLineItem' | 'CompositeChargeLineItem' | 'ContractAWSProServiceRoyaltyLineItem' | 'ContractAWSRoyaltyLineItem' | 'ContractAppliedCommitLineItem' | 'ContractChargeLineItem' | 'ContractCommitLineItem' | 'ContractConversionLineItem' | 'ContractDiscountLineItem' | 'ContractGCPProServiceRoyaltyLineItem' | 'ContractGCPRoyaltyLineItem' | 'ContractPostpaidTrueupLineItem' | 'ContractProServiceLineItem' | 'ContractRolloverCommitLineItem' | 'ContractSubscriptionLineItem' | 'ContractUsageLineItem' | 'CreditLineItem' | 'CreditPurchaseChargeLineItem' | 'CreditPurchaseLineItem' | 'GroupedChargeLineItem' | 'GroupedProductChargeLineItem' | 'LegacyContractLineItem' | 'LegacyLineItem' | 'MinimumLineItem' | 'OverageLineItem' | 'ProductChargeLineItem' | 'SeatChargeLineItem' | 'TierChargeLineItem' | 'TrialDiscountLineItem', ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
};

export type Mri_PendingChargeCreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_PendingChargeCreditLedgerEntry'] = ResolversParentTypes['MRI_PendingChargeCreditLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  draft_invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_PendingCreditExpirationCreditLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_PendingCreditExpirationCreditLedgerEntry'] = ResolversParentTypes['MRI_PendingCreditExpirationCreditLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_PendingRolloverCreditGrantLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_PendingRolloverCreditGrantLedgerEntry'] = ResolversParentTypes['MRI_PendingRolloverCreditGrantLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_PendingRolloverDeductionLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_PendingRolloverDeductionLedgerEntry'] = ResolversParentTypes['MRI_PendingRolloverDeductionLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_RolloverCreditGrantLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_RolloverCreditGrantLedgerEntry'] = ResolversParentTypes['MRI_RolloverCreditGrantLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Mri_RolloverDeductionLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MRI_RolloverDeductionLedgerEntry'] = ResolversParentTypes['MRI_RolloverDeductionLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  credit_grant?: Resolver<ResolversTypes['MRI_CreditGrant'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  memo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  running_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum'] = ResolversParentTypes['ManagedEntityEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum_aggregate'] = ResolversParentTypes['ManagedEntityEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum_aggregate_fields'] = ResolversParentTypes['ManagedEntityEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ManagedEntityEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum_max_fields'] = ResolversParentTypes['ManagedEntityEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum_min_fields'] = ResolversParentTypes['ManagedEntityEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedEntityEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedEntityEnum_mutation_response'] = ResolversParentTypes['ManagedEntityEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey'] = ResolversParentTypes['ManagedFieldKey']> = {
  AlertManagedFields?: Resolver<Array<ResolversTypes['AlertManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyAlertManagedFieldsArgs>>;
  AlertManagedFields_aggregate?: Resolver<ResolversTypes['AlertManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyAlertManagedFields_AggregateArgs>>;
  ChargeManagedFields?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyChargeManagedFieldsArgs>>;
  ChargeManagedFields_aggregate?: Resolver<ResolversTypes['ChargeManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyChargeManagedFields_AggregateArgs>>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  CommitManagedFields?: Resolver<Array<ResolversTypes['CommitManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyCommitManagedFieldsArgs>>;
  CommitManagedFields_aggregate?: Resolver<ResolversTypes['CommitManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyCommitManagedFields_AggregateArgs>>;
  ContractCreditManagedFields?: Resolver<Array<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyContractCreditManagedFieldsArgs>>;
  ContractCreditManagedFields_aggregate?: Resolver<ResolversTypes['ContractCreditManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyContractCreditManagedFields_AggregateArgs>>;
  ContractManagedFields?: Resolver<Array<ResolversTypes['ContractManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyContractManagedFieldsArgs>>;
  ContractManagedFields_aggregate?: Resolver<ResolversTypes['ContractManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyContractManagedFields_AggregateArgs>>;
  ContractProductManagedFields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyContractProductManagedFieldsArgs>>;
  ContractProductManagedFields_aggregate?: Resolver<ResolversTypes['ContractProductManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyContractProductManagedFields_AggregateArgs>>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  CreditGrantManagedFields?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyCreditGrantManagedFieldsArgs>>;
  CreditGrantManagedFields_aggregate?: Resolver<ResolversTypes['CreditGrantManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyCreditGrantManagedFields_AggregateArgs>>;
  CustomerManagedFields?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyCustomerManagedFieldsArgs>>;
  CustomerManagedFields_aggregate?: Resolver<ResolversTypes['CustomerManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyCustomerManagedFields_AggregateArgs>>;
  CustomerPlanManagedFields?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyCustomerPlanManagedFieldsArgs>>;
  CustomerPlanManagedFields_aggregate?: Resolver<ResolversTypes['CustomerPlanManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyCustomerPlanManagedFields_AggregateArgs>>;
  Disabler?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  InvoiceManagedFields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyInvoiceManagedFieldsArgs>>;
  InvoiceManagedFields_aggregate?: Resolver<ResolversTypes['InvoiceManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyInvoiceManagedFields_AggregateArgs>>;
  PlanManagedFields?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyPlanManagedFieldsArgs>>;
  PlanManagedFields_aggregate?: Resolver<ResolversTypes['PlanManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyPlanManagedFields_AggregateArgs>>;
  ProServiceManagedFields?: Resolver<Array<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyProServiceManagedFieldsArgs>>;
  ProServiceManagedFields_aggregate?: Resolver<ResolversTypes['ProServiceManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyProServiceManagedFields_AggregateArgs>>;
  ProductManagedFields?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyProductManagedFieldsArgs>>;
  ProductManagedFields_aggregate?: Resolver<ResolversTypes['ProductManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyProductManagedFields_AggregateArgs>>;
  RateCardManagedFields?: Resolver<Array<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyRateCardManagedFieldsArgs>>;
  RateCardManagedFields_aggregate?: Resolver<ResolversTypes['RateCardManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyRateCardManagedFields_AggregateArgs>>;
  ScheduledChargeManagedFields?: Resolver<Array<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, Partial<ManagedFieldKeyScheduledChargeManagedFieldsArgs>>;
  ScheduledChargeManagedFields_aggregate?: Resolver<ResolversTypes['ScheduledChargeManagedField_aggregate'], ParentType, ContextType, Partial<ManagedFieldKeyScheduledChargeManagedFields_AggregateArgs>>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enforce_uniqueness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['ManagedEntityEnum_enum'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKey_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey_aggregate'] = ResolversParentTypes['ManagedFieldKey_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKey_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey_aggregate_fields'] = ResolversParentTypes['ManagedFieldKey_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ManagedFieldKey_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKey_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey_max_fields'] = ResolversParentTypes['ManagedFieldKey_max_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKey_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey_min_fields'] = ResolversParentTypes['ManagedFieldKey_min_fields']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  archived_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  disabled_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldKey_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldKey_mutation_response'] = ResolversParentTypes['ManagedFieldKey_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping'] = ResolversParentTypes['ManagedFieldVendorMapping']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entity?: Resolver<ResolversTypes['ManagedEntityEnum_enum'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['ManagedVendorEnum_enum'], ParentType, ContextType>;
  vendor_entity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendor_field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMapping_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping_aggregate'] = ResolversParentTypes['ManagedFieldVendorMapping_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMapping_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping_aggregate_fields'] = ResolversParentTypes['ManagedFieldVendorMapping_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ManagedFieldVendorMapping_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMapping_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping_max_fields'] = ResolversParentTypes['ManagedFieldVendorMapping_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_entity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMapping_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping_min_fields'] = ResolversParentTypes['ManagedFieldVendorMapping_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_entity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor_field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedFieldVendorMapping_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedFieldVendorMapping_mutation_response'] = ResolversParentTypes['ManagedFieldVendorMapping_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum'] = ResolversParentTypes['ManagedVendorEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum_aggregate'] = ResolversParentTypes['ManagedVendorEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum_aggregate_fields'] = ResolversParentTypes['ManagedVendorEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ManagedVendorEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum_max_fields'] = ResolversParentTypes['ManagedVendorEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum_min_fields'] = ResolversParentTypes['ManagedVendorEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagedVendorEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagedVendorEnum_mutation_response'] = ResolversParentTypes['ManagedVendorEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetricValidationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetricValidationResponse'] = ResolversParentTypes['MetricValidationResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  validation_errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MinimumResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum'] = ResolversParentTypes['Minimum']> = {
  CreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MinimumLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MinimumLineItem'] = ResolversParentTypes['MinimumLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_aggregate'] = ResolversParentTypes['Minimum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Minimum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Minimum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_aggregate_fields'] = ResolversParentTypes['Minimum_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['Minimum_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Minimum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Minimum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Minimum_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Minimum_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Minimum_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Minimum_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Minimum_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Minimum_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Minimum_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Minimum_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_avg_fields'] = ResolversParentTypes['Minimum_avg_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_max_fields'] = ResolversParentTypes['Minimum_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_min_fields'] = ResolversParentTypes['Minimum_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_mutation_response'] = ResolversParentTypes['Minimum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Minimum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_stddev_fields'] = ResolversParentTypes['Minimum_stddev_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_stddev_pop_fields'] = ResolversParentTypes['Minimum_stddev_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_stddev_samp_fields'] = ResolversParentTypes['Minimum_stddev_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_sum_fields'] = ResolversParentTypes['Minimum_sum_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_var_pop_fields'] = ResolversParentTypes['Minimum_var_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_var_samp_fields'] = ResolversParentTypes['Minimum_var_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Minimum_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Minimum_variance_fields'] = ResolversParentTypes['Minimum_variance_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiplierOverrideResolvers<ContextType = any, ParentType extends ResolversParentTypes['MultiplierOverride'] = ResolversParentTypes['MultiplierOverride']> = {
  multiplier?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  add_aws_marketplace_to_customer?: Resolver<ResolversTypes['AddMarketplaceToCustomerMutationResponse'], ParentType, ContextType, RequireFields<MutationAdd_Aws_Marketplace_To_CustomerArgs, 'aws_customer_id' | 'aws_product_code' | 'aws_region' | 'customer_id'>>;
  add_azure_marketplace_to_customer?: Resolver<ResolversTypes['AddMarketplaceToCustomerMutationResponse'], ParentType, ContextType, RequireFields<MutationAdd_Azure_Marketplace_To_CustomerArgs, 'azure_subscription_id' | 'customer_id'>>;
  add_manual_commit_ledger_entry?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationAdd_Manual_Commit_Ledger_EntryArgs, 'amount' | 'commit_id' | 'customer_id' | 'reason' | 'segment_id'>>;
  add_plan_to_customer?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType, RequireFields<MutationAdd_Plan_To_CustomerArgs, 'customer_id' | 'plan_id' | 'start_date'>>;
  add_rate?: Resolver<ResolversTypes['RateCardEntry'], ParentType, ContextType, RequireFields<MutationAdd_RateArgs, 'effective_at' | 'entitled' | 'product_list_item_id' | 'rate' | 'rate_card_id'>>;
  amend_contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationAmend_ContractArgs, 'contract_id' | 'customer_id' | 'effective_at'>>;
  archive_alert?: Resolver<ResolversTypes['Alert'], ParentType, ContextType, RequireFields<MutationArchive_AlertArgs, 'alert_id'>>;
  archive_billable_metric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType, RequireFields<MutationArchive_Billable_MetricArgs, 'billable_metric_id'>>;
  archive_contract?: Resolver<ResolversTypes['ArchiveContractMutationResponse'], ParentType, ContextType, RequireFields<MutationArchive_ContractArgs, 'archived_at' | 'contract_id' | 'customer_id' | 'void_invoices'>>;
  archive_customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationArchive_CustomerArgs, 'customer_id'>>;
  archive_plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationArchive_PlanArgs, 'plan_id'>>;
  archive_product?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationArchive_ProductArgs, 'product_id'>>;
  archive_product_list_item?: Resolver<ResolversTypes['ArchiveProductListItemMutationResponse'], ParentType, ContextType, RequireFields<MutationArchive_Product_List_ItemArgs, 'archived_at' | 'product_id'>>;
  archive_scheduled_webhook_notifications?: Resolver<Array<ResolversTypes['ScheduledWebhookNotification']>, ParentType, ContextType, Partial<MutationArchive_Scheduled_Webhook_NotificationsArgs>>;
  archive_seat_metric?: Resolver<ResolversTypes['SeatMetric'], ParentType, ContextType, RequireFields<MutationArchive_Seat_MetricArgs, 'seat_metric_id'>>;
  archive_user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationArchive_UserArgs, 'id'>>;
  backfill_billable_metric?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationBackfill_Billable_MetricArgs, 'billable_metric_id'>>;
  cancel_end_customer_plan?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationCancel_End_Customer_PlanArgs, 'customer_id' | 'customer_plan_id'>>;
  change_commit_end_date?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType, RequireFields<MutationChange_Commit_End_DateArgs, 'commit_id' | 'customer_id'>>;
  change_password?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationChange_PasswordArgs, 'current_password' | 'new_password'>>;
  create_adhoc_contract_usage_invoices?: Resolver<Array<ResolversTypes['AdhocContractUsageInvoice']>, ParentType, ContextType, RequireFields<MutationCreate_Adhoc_Contract_Usage_InvoicesArgs, 'inputs' | 'preview'>>;
  create_adhoc_plan_invoices?: Resolver<Array<ResolversTypes['AdHocPlanInvoice']>, ParentType, ContextType, RequireFields<MutationCreate_Adhoc_Plan_InvoicesArgs, 'inputs' | 'preview'>>;
  create_api_token?: Resolver<ResolversTypes['APITokenResponse'], ParentType, ContextType, RequireFields<MutationCreate_Api_TokenArgs, 'name'>>;
  create_billable_metric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType, RequireFields<MutationCreate_Billable_MetricArgs, 'input'>>;
  create_billable_metrics?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<MutationCreate_Billable_MetricsArgs, 'inputs'>>;
  create_billing_provider_settings?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationCreate_Billing_Provider_SettingsArgs, 'billing_provider' | 'settings'>>;
  create_census_workspace?: Resolver<ResolversTypes['CensusWorkspace'], ParentType, ContextType, RequireFields<MutationCreate_Census_WorkspaceArgs, 'destination_type'>>;
  create_composite_product_list_item?: Resolver<ResolversTypes['CompositeProductListItem'], ParentType, ContextType, RequireFields<MutationCreate_Composite_Product_List_ItemArgs, 'name'>>;
  create_contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationCreate_ContractArgs, 'customer_id' | 'starting_at'>>;
  create_credit_grant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType, RequireFields<MutationCreate_Credit_GrantArgs, 'credit_grant'>>;
  create_customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationCreate_CustomerArgs, 'ingest_aliases' | 'name'>>;
  create_customer_commit?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType, RequireFields<MutationCreate_Customer_CommitArgs, 'customer_commit' | 'customer_id'>>;
  create_fancy_metric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType, RequireFields<MutationCreate_Fancy_MetricArgs, 'input'>>;
  create_fixed_product_list_item?: Resolver<ResolversTypes['FixedProductListItem'], ParentType, ContextType, RequireFields<MutationCreate_Fixed_Product_List_ItemArgs, 'name'>>;
  create_invoice_correction?: Resolver<ResolversTypes['CorrectionInvoice'], ParentType, ContextType, RequireFields<MutationCreate_Invoice_CorrectionArgs, 'invoice_correction' | 'preview'>>;
  create_one_time_charge?: Resolver<ResolversTypes['OneTimeProductCharge'], ParentType, ContextType, RequireFields<MutationCreate_One_Time_ChargeArgs, 'customer_plan_id' | 'description' | 'price' | 'product_pricing_factor_id' | 'quantity' | 'service_period_start_date'>>;
  create_plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationCreate_PlanArgs, 'plan'>>;
  create_prepaid_credit_grant?: Resolver<ResolversTypes['PepaidCreditGrantResponse'], ParentType, ContextType, RequireFields<MutationCreate_Prepaid_Credit_GrantArgs, 'credit_grant' | 'prepaid_input'>>;
  create_pro_service_invoices?: Resolver<Array<ResolversTypes['ContractProServiceInvoice']>, ParentType, ContextType, RequireFields<MutationCreate_Pro_Service_InvoicesArgs, 'pro_service_invoice_data'>>;
  create_pro_service_product_list_item?: Resolver<ResolversTypes['ProServiceProductListItem'], ParentType, ContextType, RequireFields<MutationCreate_Pro_Service_Product_List_ItemArgs, 'name'>>;
  create_rate_card?: Resolver<ResolversTypes['RateCard'], ParentType, ContextType, RequireFields<MutationCreate_Rate_CardArgs, 'name'>>;
  create_recharge_settings?: Resolver<ResolversTypes['RechargeSettings'], ParentType, ContextType, RequireFields<MutationCreate_Recharge_SettingsArgs, 'recharge_settings'>>;
  create_recharge_settings_and_recharge?: Resolver<ResolversTypes['RechargeSettingsUpdateAndChargeResponse'], ParentType, ContextType, RequireFields<MutationCreate_Recharge_Settings_And_RechargeArgs, 'current_credit_balance' | 'recharge_settings'>>;
  create_recurring_credit_grants?: Resolver<Array<ResolversTypes['CreditGrant']>, ParentType, ContextType, RequireFields<MutationCreate_Recurring_Credit_GrantsArgs, 'customer_id'>>;
  create_scheduled_webhook_notification?: Resolver<ResolversTypes['ScheduledWebhookNotification'], ParentType, ContextType, RequireFields<MutationCreate_Scheduled_Webhook_NotificationArgs, 'notification' | 'notification_type' | 'timestamp'>>;
  create_seat_metric?: Resolver<ResolversTypes['SeatMetric'], ParentType, ContextType, RequireFields<MutationCreate_Seat_MetricArgs, 'input'>>;
  create_seat_purchase_invoice?: Resolver<ResolversTypes['SeatPurchaseInvoice'], ParentType, ContextType, RequireFields<MutationCreate_Seat_Purchase_InvoiceArgs, 'customer_id' | 'invoice_date' | 'seat_charges'>>;
  create_subscription_product_list_item?: Resolver<ResolversTypes['SubscriptionProductListItem'], ParentType, ContextType, RequireFields<MutationCreate_Subscription_Product_List_ItemArgs, 'name'>>;
  create_usage_product_list_item?: Resolver<ResolversTypes['UsageProductListItem'], ParentType, ContextType, RequireFields<MutationCreate_Usage_Product_List_ItemArgs, 'billable_metric_id' | 'name'>>;
  create_user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreate_UserArgs, 'email' | 'name'>>;
  create_webhook?: Resolver<ResolversTypes['Webhook'], ParentType, ContextType, RequireFields<MutationCreate_WebhookArgs, 'name' | 'url'>>;
  delete_AccountTypeEnum?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_AccountTypeEnumArgs, 'where'>>;
  delete_AccountTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_AccountTypeEnum_By_PkArgs, 'value'>>;
  delete_Actor?: Resolver<Maybe<ResolversTypes['Actor_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ActorArgs, 'where'>>;
  delete_Actor_by_pk?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType, RequireFields<MutationDelete_Actor_By_PkArgs, 'id'>>;
  delete_Alert?: Resolver<Maybe<ResolversTypes['Alert_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_AlertArgs, 'where'>>;
  delete_AlertManagedField?: Resolver<Maybe<ResolversTypes['AlertManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_AlertManagedFieldArgs, 'where'>>;
  delete_AlertManagedField_by_pk?: Resolver<Maybe<ResolversTypes['AlertManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_AlertManagedField_By_PkArgs, 'id'>>;
  delete_AlertTypeEnum?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_AlertTypeEnumArgs, 'where'>>;
  delete_AlertTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_AlertTypeEnum_By_PkArgs, 'value'>>;
  delete_Alert_by_pk?: Resolver<Maybe<ResolversTypes['Alert']>, ParentType, ContextType, RequireFields<MutationDelete_Alert_By_PkArgs, 'id'>>;
  delete_BillableMetric?: Resolver<Maybe<ResolversTypes['BillableMetric_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillableMetricArgs, 'where'>>;
  delete_BillableMetricManagedField?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillableMetricManagedFieldArgs, 'where'>>;
  delete_BillableMetricManagedField_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_BillableMetricManagedField_By_PkArgs, 'id'>>;
  delete_BillableMetric_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<MutationDelete_BillableMetric_By_PkArgs, 'id'>>;
  delete_BillingFrequencyEnum?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingFrequencyEnumArgs, 'where'>>;
  delete_BillingFrequencyEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType, RequireFields<MutationDelete_BillingFrequencyEnum_By_PkArgs, 'value'>>;
  delete_BillingMetricAggregateEnum?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingMetricAggregateEnumArgs, 'where'>>;
  delete_BillingMetricAggregateEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType, RequireFields<MutationDelete_BillingMetricAggregateEnum_By_PkArgs, 'value'>>;
  delete_BillingProviderCustomer?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderCustomerArgs, 'where'>>;
  delete_BillingProviderCustomer_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderCustomer_By_PkArgs, 'billing_provider' | 'customer_id'>>;
  delete_BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderEnumArgs, 'where'>>;
  delete_BillingProviderEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderEnum_By_PkArgs, 'value'>>;
  delete_BillingProviderInvoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderInvoiceArgs, 'where'>>;
  delete_BillingProviderInvoice_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderInvoice_By_PkArgs, 'id'>>;
  delete_BillingProviderToken?: Resolver<Maybe<ResolversTypes['BillingProviderToken_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderTokenArgs, 'where'>>;
  delete_BillingProviderToken_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, RequireFields<MutationDelete_BillingProviderToken_By_PkArgs, 'billing_provider' | 'client_id' | 'environment_type'>>;
  delete_ChargeManagedField?: Resolver<Maybe<ResolversTypes['ChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ChargeManagedFieldArgs, 'where'>>;
  delete_ChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ChargeManagedField_By_PkArgs, 'id'>>;
  delete_ChargeTypeEnum?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ChargeTypeEnumArgs, 'where'>>;
  delete_ChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_ChargeTypeEnum_By_PkArgs, 'value'>>;
  delete_Client?: Resolver<Maybe<ResolversTypes['Client_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ClientArgs, 'where'>>;
  delete_ClientConfig?: Resolver<Maybe<ResolversTypes['ClientConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ClientConfigArgs, 'where'>>;
  delete_ClientConfigKeyEnum?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ClientConfigKeyEnumArgs, 'where'>>;
  delete_ClientConfigKeyEnum_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType, RequireFields<MutationDelete_ClientConfigKeyEnum_By_PkArgs, 'value'>>;
  delete_ClientConfig_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfig']>, ParentType, ContextType, RequireFields<MutationDelete_ClientConfig_By_PkArgs, 'id'>>;
  delete_Client_by_pk?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationDelete_Client_By_PkArgs, 'id'>>;
  delete_CollectionScheduleEnum?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CollectionScheduleEnumArgs, 'where'>>;
  delete_CollectionScheduleEnum_by_pk?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType, RequireFields<MutationDelete_CollectionScheduleEnum_By_PkArgs, 'value'>>;
  delete_CommitManagedField?: Resolver<Maybe<ResolversTypes['CommitManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CommitManagedFieldArgs, 'where'>>;
  delete_CommitManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CommitManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_CommitManagedField_By_PkArgs, 'id'>>;
  delete_CompositeCharge?: Resolver<Maybe<ResolversTypes['CompositeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeArgs, 'where'>>;
  delete_CompositeChargeAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeAdjustmentArgs, 'where'>>;
  delete_CompositeChargeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeAdjustment_By_PkArgs, 'id'>>;
  delete_CompositeChargePricingFactor?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargePricingFactorArgs, 'where'>>;
  delete_CompositeChargeTier?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTierArgs, 'where'>>;
  delete_CompositeChargeTierAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTierAdjustmentArgs, 'where'>>;
  delete_CompositeChargeTierAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTierAdjustment_By_PkArgs, 'id'>>;
  delete_CompositeChargeTier_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTier_By_PkArgs, 'id'>>;
  delete_CompositeChargeTypeEnum?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTypeEnumArgs, 'where'>>;
  delete_CompositeChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeChargeTypeEnum_By_PkArgs, 'value'>>;
  delete_CompositeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CompositeCharge']>, ParentType, ContextType, RequireFields<MutationDelete_CompositeCharge_By_PkArgs, 'id'>>;
  delete_ContractCreditManagedField?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ContractCreditManagedFieldArgs, 'where'>>;
  delete_ContractCreditManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ContractCreditManagedField_By_PkArgs, 'id'>>;
  delete_ContractManagedField?: Resolver<Maybe<ResolversTypes['ContractManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ContractManagedFieldArgs, 'where'>>;
  delete_ContractManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ContractManagedField_By_PkArgs, 'id'>>;
  delete_ContractProductManagedField?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ContractProductManagedFieldArgs, 'where'>>;
  delete_ContractProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ContractProductManagedField_By_PkArgs, 'id'>>;
  delete_CreditAdjustment?: Resolver<Maybe<ResolversTypes['CreditAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditAdjustmentArgs, 'where'>>;
  delete_CreditAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_CreditAdjustment_By_PkArgs, 'id'>>;
  delete_CreditGrant?: Resolver<Maybe<ResolversTypes['CreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantArgs, 'where'>>;
  delete_CreditGrantDeduction?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantDeductionArgs, 'where'>>;
  delete_CreditGrantDeduction_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantDeduction_By_PkArgs, 'id'>>;
  delete_CreditGrantHistory?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantHistoryArgs, 'where'>>;
  delete_CreditGrantHistory_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantHistory_By_PkArgs, 'id'>>;
  delete_CreditGrantManagedField?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantManagedFieldArgs, 'where'>>;
  delete_CreditGrantManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrantManagedField_By_PkArgs, 'id'>>;
  delete_CreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType, RequireFields<MutationDelete_CreditGrant_By_PkArgs, 'id'>>;
  delete_CreditType?: Resolver<Maybe<ResolversTypes['CreditType_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditTypeArgs, 'where'>>;
  delete_CreditTypeConversion?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditTypeConversionArgs, 'where'>>;
  delete_CreditTypeConversionAdjustment?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CreditTypeConversionAdjustmentArgs, 'where'>>;
  delete_CreditTypeConversionAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_CreditTypeConversionAdjustment_By_PkArgs, 'id'>>;
  delete_CreditTypeConversion_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, RequireFields<MutationDelete_CreditTypeConversion_By_PkArgs, 'id'>>;
  delete_CreditType_by_pk?: Resolver<Maybe<ResolversTypes['CreditType']>, ParentType, ContextType, RequireFields<MutationDelete_CreditType_By_PkArgs, 'id'>>;
  delete_CustomPricing?: Resolver<Maybe<ResolversTypes['CustomPricing_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomPricingArgs, 'where'>>;
  delete_CustomPricing_by_pk?: Resolver<Maybe<ResolversTypes['CustomPricing']>, ParentType, ContextType, RequireFields<MutationDelete_CustomPricing_By_PkArgs, 'id'>>;
  delete_Customer?: Resolver<Maybe<ResolversTypes['Customer_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerArgs, 'where'>>;
  delete_CustomerAlert?: Resolver<Maybe<ResolversTypes['CustomerAlert_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlertArgs, 'where'>>;
  delete_CustomerAlertHistory?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlertHistoryArgs, 'where'>>;
  delete_CustomerAlertHistory_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlertHistory_By_PkArgs, 'id'>>;
  delete_CustomerAlertStatusEnum?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlertStatusEnumArgs, 'where'>>;
  delete_CustomerAlertStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlertStatusEnum_By_PkArgs, 'value'>>;
  delete_CustomerAlert_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlert']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerAlert_By_PkArgs, 'id'>>;
  delete_CustomerConfig?: Resolver<Maybe<ResolversTypes['CustomerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerConfigArgs, 'where'>>;
  delete_CustomerConfig_by_pk?: Resolver<Maybe<ResolversTypes['CustomerConfig']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerConfig_By_PkArgs, 'id'>>;
  delete_CustomerIngestAlias?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerIngestAliasArgs, 'where'>>;
  delete_CustomerIngestAlias_by_pk?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerIngestAlias_By_PkArgs, 'client_id' | 'customer_id' | 'ingest_alias'>>;
  delete_CustomerManagedField?: Resolver<Maybe<ResolversTypes['CustomerManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerManagedFieldArgs, 'where'>>;
  delete_CustomerManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerManagedField_By_PkArgs, 'id'>>;
  delete_CustomerPlan?: Resolver<Maybe<ResolversTypes['CustomerPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanArgs, 'where'>>;
  delete_CustomerPlanAdjustment?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanAdjustmentArgs, 'where'>>;
  delete_CustomerPlanAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanAdjustment_By_PkArgs, 'id'>>;
  delete_CustomerPlanManagedField?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanManagedFieldArgs, 'where'>>;
  delete_CustomerPlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanManagedField_By_PkArgs, 'id'>>;
  delete_CustomerPlanOneTimeCharge?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanOneTimeChargeArgs, 'where'>>;
  delete_CustomerPlanOneTimeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlanOneTimeCharge_By_PkArgs, 'id'>>;
  delete_CustomerPlan_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerPlan_By_PkArgs, 'id'>>;
  delete_CustomerRelationship?: Resolver<Maybe<ResolversTypes['CustomerRelationship_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerRelationshipArgs, 'where'>>;
  delete_CustomerRelationship_by_pk?: Resolver<Maybe<ResolversTypes['CustomerRelationship']>, ParentType, ContextType, RequireFields<MutationDelete_CustomerRelationship_By_PkArgs, 'id'>>;
  delete_Customer_by_pk?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationDelete_Customer_By_PkArgs, 'id'>>;
  delete_DraftPlan?: Resolver<Maybe<ResolversTypes['DraftPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_DraftPlanArgs, 'where'>>;
  delete_DraftPlan_by_pk?: Resolver<Maybe<ResolversTypes['DraftPlan']>, ParentType, ContextType, RequireFields<MutationDelete_DraftPlan_By_PkArgs, 'id'>>;
  delete_EnvironmentTypeEnum?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_EnvironmentTypeEnumArgs, 'where'>>;
  delete_EnvironmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_EnvironmentTypeEnum_By_PkArgs, 'value'>>;
  delete_FlatFee?: Resolver<Maybe<ResolversTypes['FlatFee_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_FlatFeeArgs, 'where'>>;
  delete_FlatFeeAdjustment?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_FlatFeeAdjustmentArgs, 'where'>>;
  delete_FlatFeeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_FlatFeeAdjustment_By_PkArgs, 'id'>>;
  delete_FlatFee_by_pk?: Resolver<Maybe<ResolversTypes['FlatFee']>, ParentType, ContextType, RequireFields<MutationDelete_FlatFee_By_PkArgs, 'id'>>;
  delete_InvoiceExternalStatusEnum?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_InvoiceExternalStatusEnumArgs, 'where'>>;
  delete_InvoiceExternalStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType, RequireFields<MutationDelete_InvoiceExternalStatusEnum_By_PkArgs, 'value'>>;
  delete_InvoiceManagedField?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_InvoiceManagedFieldArgs, 'where'>>;
  delete_InvoiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_InvoiceManagedField_By_PkArgs, 'id'>>;
  delete_ManagedEntityEnum?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedEntityEnumArgs, 'where'>>;
  delete_ManagedEntityEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedEntityEnum_By_PkArgs, 'value'>>;
  delete_ManagedFieldKey?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedFieldKeyArgs, 'where'>>;
  delete_ManagedFieldKey_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedFieldKey_By_PkArgs, 'id'>>;
  delete_ManagedFieldVendorMapping?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedFieldVendorMappingArgs, 'where'>>;
  delete_ManagedFieldVendorMapping_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedFieldVendorMapping_By_PkArgs, 'id'>>;
  delete_ManagedVendorEnum?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedVendorEnumArgs, 'where'>>;
  delete_ManagedVendorEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType, RequireFields<MutationDelete_ManagedVendorEnum_By_PkArgs, 'value'>>;
  delete_Minimum?: Resolver<Maybe<ResolversTypes['Minimum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_MinimumArgs, 'where'>>;
  delete_Minimum_by_pk?: Resolver<Maybe<ResolversTypes['Minimum']>, ParentType, ContextType, RequireFields<MutationDelete_Minimum_By_PkArgs, 'id'>>;
  delete_Plan?: Resolver<Maybe<ResolversTypes['Plan_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PlanArgs, 'where'>>;
  delete_PlanManagedField?: Resolver<Maybe<ResolversTypes['PlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PlanManagedFieldArgs, 'where'>>;
  delete_PlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['PlanManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_PlanManagedField_By_PkArgs, 'id'>>;
  delete_Plan_by_pk?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<MutationDelete_Plan_By_PkArgs, 'id'>>;
  delete_Price?: Resolver<Maybe<ResolversTypes['Price_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PriceArgs, 'where'>>;
  delete_PriceAdjustment?: Resolver<Maybe<ResolversTypes['PriceAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PriceAdjustmentArgs, 'where'>>;
  delete_PriceAdjustmentTypeEnum?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PriceAdjustmentTypeEnumArgs, 'where'>>;
  delete_PriceAdjustmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_PriceAdjustmentTypeEnum_By_PkArgs, 'value'>>;
  delete_PriceAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_PriceAdjustment_By_PkArgs, 'id'>>;
  delete_Price_by_pk?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType, RequireFields<MutationDelete_Price_By_PkArgs, 'id'>>;
  delete_PricedProduct?: Resolver<Maybe<ResolversTypes['PricedProduct_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductArgs, 'where'>>;
  delete_PricedProductAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductAdjustmentArgs, 'where'>>;
  delete_PricedProductAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductAdjustment_By_PkArgs, 'id'>>;
  delete_PricedProductPricingFactor?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductPricingFactorArgs, 'where'>>;
  delete_PricedProductPricingFactorAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductPricingFactorAdjustmentArgs, 'where'>>;
  delete_PricedProductPricingFactorAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductPricingFactorAdjustment_By_PkArgs, 'id'>>;
  delete_PricedProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProductPricingFactor_By_PkArgs, 'id'>>;
  delete_PricedProduct_by_pk?: Resolver<Maybe<ResolversTypes['PricedProduct']>, ParentType, ContextType, RequireFields<MutationDelete_PricedProduct_By_PkArgs, 'id'>>;
  delete_ProServiceManagedField?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ProServiceManagedFieldArgs, 'where'>>;
  delete_ProServiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ProServiceManagedField_By_PkArgs, 'id'>>;
  delete_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ProductArgs, 'where'>>;
  delete_ProductManagedField?: Resolver<Maybe<ResolversTypes['ProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ProductManagedFieldArgs, 'where'>>;
  delete_ProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProductManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ProductManagedField_By_PkArgs, 'id'>>;
  delete_ProductPricingFactor?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ProductPricingFactorArgs, 'where'>>;
  delete_ProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationDelete_ProductPricingFactor_By_PkArgs, 'id'>>;
  delete_Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<MutationDelete_Product_By_PkArgs, 'id'>>;
  delete_RateCardManagedField?: Resolver<Maybe<ResolversTypes['RateCardManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_RateCardManagedFieldArgs, 'where'>>;
  delete_RateCardManagedField_by_pk?: Resolver<Maybe<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_RateCardManagedField_By_PkArgs, 'id'>>;
  delete_RecurringCreditGrant?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_RecurringCreditGrantArgs, 'where'>>;
  delete_RecurringCreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, RequireFields<MutationDelete_RecurringCreditGrant_By_PkArgs, 'id'>>;
  delete_Role?: Resolver<Maybe<ResolversTypes['Role_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_RoleArgs, 'where'>>;
  delete_Role_by_pk?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<MutationDelete_Role_By_PkArgs, 'id'>>;
  delete_RoundingBehaviorEnum?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_RoundingBehaviorEnumArgs, 'where'>>;
  delete_RoundingBehaviorEnum_by_pk?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType, RequireFields<MutationDelete_RoundingBehaviorEnum_By_PkArgs, 'value'>>;
  delete_ScheduledChargeManagedField?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ScheduledChargeManagedFieldArgs, 'where'>>;
  delete_ScheduledChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, RequireFields<MutationDelete_ScheduledChargeManagedField_By_PkArgs, 'id'>>;
  delete_ServicePeriodStartOnEnum?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_ServicePeriodStartOnEnumArgs, 'where'>>;
  delete_ServicePeriodStartOnEnum_by_pk?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType, RequireFields<MutationDelete_ServicePeriodStartOnEnum_By_PkArgs, 'value'>>;
  delete_StripePrepaidConfig_temporary?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_StripePrepaidConfig_TemporaryArgs, 'where'>>;
  delete_StripePrepaidConfig_temporary_by_pk?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType, RequireFields<MutationDelete_StripePrepaidConfig_Temporary_By_PkArgs, 'client_id' | 'environment_type'>>;
  delete_TieringModeEnum?: Resolver<Maybe<ResolversTypes['TieringModeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_TieringModeEnumArgs, 'where'>>;
  delete_TieringModeEnum_by_pk?: Resolver<Maybe<ResolversTypes['TieringModeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_TieringModeEnum_By_PkArgs, 'value'>>;
  delete_Tokens?: Resolver<Maybe<ResolversTypes['Tokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_TokensArgs, 'where'>>;
  delete_Tokens_by_pk?: Resolver<Maybe<ResolversTypes['Tokens']>, ParentType, ContextType, RequireFields<MutationDelete_Tokens_By_PkArgs, 'id'>>;
  delete_TrialSpec?: Resolver<Maybe<ResolversTypes['TrialSpec_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_TrialSpecArgs, 'where'>>;
  delete_TrialSpecSpendingCap?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_TrialSpecSpendingCapArgs, 'where'>>;
  delete_TrialSpecSpendingCap_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, RequireFields<MutationDelete_TrialSpecSpendingCap_By_PkArgs, 'id'>>;
  delete_TrialSpec_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType, RequireFields<MutationDelete_TrialSpec_By_PkArgs, 'id'>>;
  delete_UserAuthTypeEnum?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_UserAuthTypeEnumArgs, 'where'>>;
  delete_UserAuthTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType, RequireFields<MutationDelete_UserAuthTypeEnum_By_PkArgs, 'value'>>;
  delete_User_doNotUse?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_User_DoNotUseArgs, 'where'>>;
  delete_Webhook?: Resolver<Maybe<ResolversTypes['Webhook_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_WebhookArgs, 'where'>>;
  delete_WebhookNotification?: Resolver<Maybe<ResolversTypes['WebhookNotification_mutation_response']>, ParentType, ContextType, RequireFields<MutationDelete_WebhookNotificationArgs, 'where'>>;
  delete_WebhookNotification_by_pk?: Resolver<Maybe<ResolversTypes['WebhookNotification']>, ParentType, ContextType, RequireFields<MutationDelete_WebhookNotification_By_PkArgs, 'id'>>;
  delete_Webhook_by_pk?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType, RequireFields<MutationDelete_Webhook_By_PkArgs, 'id'>>;
  delete_billing_provider_settings?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationDelete_Billing_Provider_SettingsArgs, 'billing_provider'>>;
  delete_by_pk_User_doNotUse?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationDelete_By_Pk_User_DoNotUseArgs, 'id'>>;
  delete_one_time_charge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDelete_One_Time_ChargeArgs, 'id'>>;
  delete_recharge_settings?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationDelete_Recharge_SettingsArgs, 'id'>>;
  delete_user?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationDelete_UserArgs, 'id'>>;
  demo_setup_customer?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDemo_Setup_CustomerArgs, 'customer_id' | 'plan_id' | 'redirect_url_cancel' | 'redirect_url_success'>>;
  disable_managed_field_key?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType, RequireFields<MutationDisable_Managed_Field_KeyArgs, 'key_id'>>;
  edit_contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationEdit_ContractArgs, 'contract_id' | 'customer_id'>>;
  edit_plan?: Resolver<ResolversTypes['EditPlanResponse'], ParentType, ContextType, RequireFields<MutationEdit_PlanArgs, 'new_plan' | 'plan_id'>>;
  edit_product?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationEdit_ProductArgs, 'description' | 'name' | 'pricing_factors' | 'product_id'>>;
  end_customer_plan?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationEnd_Customer_PlanArgs, 'cancellation_date' | 'customer_id' | 'customer_plan_id'>>;
  generate_azure_auth_token?: Resolver<ResolversTypes['GenerateAzureAuthTokenMutationResponse'], ParentType, ContextType>;
  generate_census_destination_connect_link?: Resolver<ResolversTypes['CensusDestinationConnectLink'], ParentType, ContextType, RequireFields<MutationGenerate_Census_Destination_Connect_LinkArgs, 'destination_type'>>;
  generate_prequel_auth_token?: Resolver<ResolversTypes['PrequelAuthTokenResponse'], ParentType, ContextType, RequireFields<MutationGenerate_Prequel_Auth_TokenArgs, 'application_origin'>>;
  generate_workato_sso_url?: Resolver<ResolversTypes['WorkatoSSOUrlResponse'], ParentType, ContextType>;
  insert_AccountTypeEnum?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_AccountTypeEnumArgs, 'objects'>>;
  insert_AccountTypeEnum_one?: Resolver<Maybe<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_AccountTypeEnum_OneArgs, 'object'>>;
  insert_Actor?: Resolver<Maybe<ResolversTypes['Actor_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ActorArgs, 'objects'>>;
  insert_Actor_one?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType, RequireFields<MutationInsert_Actor_OneArgs, 'object'>>;
  insert_Alert?: Resolver<Maybe<ResolversTypes['Alert_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_AlertArgs, 'objects'>>;
  insert_AlertManagedField?: Resolver<Maybe<ResolversTypes['AlertManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_AlertManagedFieldArgs, 'objects'>>;
  insert_AlertManagedField_one?: Resolver<Maybe<ResolversTypes['AlertManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_AlertManagedField_OneArgs, 'object'>>;
  insert_AlertTypeEnum?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_AlertTypeEnumArgs, 'objects'>>;
  insert_AlertTypeEnum_one?: Resolver<Maybe<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_AlertTypeEnum_OneArgs, 'object'>>;
  insert_Alert_one?: Resolver<Maybe<ResolversTypes['Alert']>, ParentType, ContextType, RequireFields<MutationInsert_Alert_OneArgs, 'object'>>;
  insert_BillableMetric?: Resolver<Maybe<ResolversTypes['BillableMetric_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillableMetricArgs, 'objects'>>;
  insert_BillableMetricManagedField?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillableMetricManagedFieldArgs, 'objects'>>;
  insert_BillableMetricManagedField_one?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_BillableMetricManagedField_OneArgs, 'object'>>;
  insert_BillableMetric_one?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<MutationInsert_BillableMetric_OneArgs, 'object'>>;
  insert_BillingFrequencyEnum?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingFrequencyEnumArgs, 'objects'>>;
  insert_BillingFrequencyEnum_one?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType, RequireFields<MutationInsert_BillingFrequencyEnum_OneArgs, 'object'>>;
  insert_BillingMetricAggregateEnum?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingMetricAggregateEnumArgs, 'objects'>>;
  insert_BillingMetricAggregateEnum_one?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType, RequireFields<MutationInsert_BillingMetricAggregateEnum_OneArgs, 'object'>>;
  insert_BillingProviderCustomer?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderCustomerArgs, 'objects'>>;
  insert_BillingProviderCustomer_one?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderCustomer_OneArgs, 'object'>>;
  insert_BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderEnumArgs, 'objects'>>;
  insert_BillingProviderEnum_one?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderEnum_OneArgs, 'object'>>;
  insert_BillingProviderInvoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderInvoiceArgs, 'objects'>>;
  insert_BillingProviderInvoice_one?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderInvoice_OneArgs, 'object'>>;
  insert_BillingProviderToken?: Resolver<Maybe<ResolversTypes['BillingProviderToken_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderTokenArgs, 'objects'>>;
  insert_BillingProviderToken_one?: Resolver<Maybe<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, RequireFields<MutationInsert_BillingProviderToken_OneArgs, 'object'>>;
  insert_ChargeManagedField?: Resolver<Maybe<ResolversTypes['ChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ChargeManagedFieldArgs, 'objects'>>;
  insert_ChargeManagedField_one?: Resolver<Maybe<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ChargeManagedField_OneArgs, 'object'>>;
  insert_ChargeTypeEnum?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ChargeTypeEnumArgs, 'objects'>>;
  insert_ChargeTypeEnum_one?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_ChargeTypeEnum_OneArgs, 'object'>>;
  insert_Client?: Resolver<Maybe<ResolversTypes['Client_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ClientArgs, 'objects'>>;
  insert_ClientConfig?: Resolver<Maybe<ResolversTypes['ClientConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ClientConfigArgs, 'objects'>>;
  insert_ClientConfigKeyEnum?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ClientConfigKeyEnumArgs, 'objects'>>;
  insert_ClientConfigKeyEnum_one?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType, RequireFields<MutationInsert_ClientConfigKeyEnum_OneArgs, 'object'>>;
  insert_ClientConfig_one?: Resolver<Maybe<ResolversTypes['ClientConfig']>, ParentType, ContextType, RequireFields<MutationInsert_ClientConfig_OneArgs, 'object'>>;
  insert_Client_one?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationInsert_Client_OneArgs, 'object'>>;
  insert_CollectionScheduleEnum?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CollectionScheduleEnumArgs, 'objects'>>;
  insert_CollectionScheduleEnum_one?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType, RequireFields<MutationInsert_CollectionScheduleEnum_OneArgs, 'object'>>;
  insert_CommitManagedField?: Resolver<Maybe<ResolversTypes['CommitManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CommitManagedFieldArgs, 'objects'>>;
  insert_CommitManagedField_one?: Resolver<Maybe<ResolversTypes['CommitManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_CommitManagedField_OneArgs, 'object'>>;
  insert_CompositeCharge?: Resolver<Maybe<ResolversTypes['CompositeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeArgs, 'objects'>>;
  insert_CompositeChargeAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeAdjustmentArgs, 'objects'>>;
  insert_CompositeChargeAdjustment_one?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeAdjustment_OneArgs, 'object'>>;
  insert_CompositeChargePricingFactor?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargePricingFactorArgs, 'objects'>>;
  insert_CompositeChargePricingFactor_one?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargePricingFactor_OneArgs, 'object'>>;
  insert_CompositeChargeTier?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTierArgs, 'objects'>>;
  insert_CompositeChargeTierAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTierAdjustmentArgs, 'objects'>>;
  insert_CompositeChargeTierAdjustment_one?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTierAdjustment_OneArgs, 'object'>>;
  insert_CompositeChargeTier_one?: Resolver<Maybe<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTier_OneArgs, 'object'>>;
  insert_CompositeChargeTypeEnum?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTypeEnumArgs, 'objects'>>;
  insert_CompositeChargeTypeEnum_one?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeChargeTypeEnum_OneArgs, 'object'>>;
  insert_CompositeCharge_one?: Resolver<Maybe<ResolversTypes['CompositeCharge']>, ParentType, ContextType, RequireFields<MutationInsert_CompositeCharge_OneArgs, 'object'>>;
  insert_ContractCreditManagedField?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ContractCreditManagedFieldArgs, 'objects'>>;
  insert_ContractCreditManagedField_one?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ContractCreditManagedField_OneArgs, 'object'>>;
  insert_ContractManagedField?: Resolver<Maybe<ResolversTypes['ContractManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ContractManagedFieldArgs, 'objects'>>;
  insert_ContractManagedField_one?: Resolver<Maybe<ResolversTypes['ContractManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ContractManagedField_OneArgs, 'object'>>;
  insert_ContractProductManagedField?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ContractProductManagedFieldArgs, 'objects'>>;
  insert_ContractProductManagedField_one?: Resolver<Maybe<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ContractProductManagedField_OneArgs, 'object'>>;
  insert_CreditAdjustment?: Resolver<Maybe<ResolversTypes['CreditAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditAdjustmentArgs, 'objects'>>;
  insert_CreditAdjustment_one?: Resolver<Maybe<ResolversTypes['CreditAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_CreditAdjustment_OneArgs, 'object'>>;
  insert_CreditGrant?: Resolver<Maybe<ResolversTypes['CreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantArgs, 'objects'>>;
  insert_CreditGrantDeduction?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantDeductionArgs, 'objects'>>;
  insert_CreditGrantDeduction_one?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantDeduction_OneArgs, 'object'>>;
  insert_CreditGrantHistory?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantHistoryArgs, 'objects'>>;
  insert_CreditGrantHistory_one?: Resolver<Maybe<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantHistory_OneArgs, 'object'>>;
  insert_CreditGrantManagedField?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantManagedFieldArgs, 'objects'>>;
  insert_CreditGrantManagedField_one?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrantManagedField_OneArgs, 'object'>>;
  insert_CreditGrant_one?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType, RequireFields<MutationInsert_CreditGrant_OneArgs, 'object'>>;
  insert_CreditType?: Resolver<Maybe<ResolversTypes['CreditType_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditTypeArgs, 'objects'>>;
  insert_CreditTypeConversion?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditTypeConversionArgs, 'objects'>>;
  insert_CreditTypeConversionAdjustment?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CreditTypeConversionAdjustmentArgs, 'objects'>>;
  insert_CreditTypeConversionAdjustment_one?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_CreditTypeConversionAdjustment_OneArgs, 'object'>>;
  insert_CreditTypeConversion_one?: Resolver<Maybe<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, RequireFields<MutationInsert_CreditTypeConversion_OneArgs, 'object'>>;
  insert_CreditType_one?: Resolver<Maybe<ResolversTypes['CreditType']>, ParentType, ContextType, RequireFields<MutationInsert_CreditType_OneArgs, 'object'>>;
  insert_CustomPricing?: Resolver<Maybe<ResolversTypes['CustomPricing_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomPricingArgs, 'objects'>>;
  insert_CustomPricing_one?: Resolver<Maybe<ResolversTypes['CustomPricing']>, ParentType, ContextType, RequireFields<MutationInsert_CustomPricing_OneArgs, 'object'>>;
  insert_Customer?: Resolver<Maybe<ResolversTypes['Customer_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerArgs, 'objects'>>;
  insert_CustomerAlert?: Resolver<Maybe<ResolversTypes['CustomerAlert_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlertArgs, 'objects'>>;
  insert_CustomerAlertHistory?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlertHistoryArgs, 'objects'>>;
  insert_CustomerAlertHistory_one?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlertHistory_OneArgs, 'object'>>;
  insert_CustomerAlertStatusEnum?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlertStatusEnumArgs, 'objects'>>;
  insert_CustomerAlertStatusEnum_one?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlertStatusEnum_OneArgs, 'object'>>;
  insert_CustomerAlert_one?: Resolver<Maybe<ResolversTypes['CustomerAlert']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerAlert_OneArgs, 'object'>>;
  insert_CustomerConfig?: Resolver<Maybe<ResolversTypes['CustomerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerConfigArgs, 'objects'>>;
  insert_CustomerConfig_one?: Resolver<Maybe<ResolversTypes['CustomerConfig']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerConfig_OneArgs, 'object'>>;
  insert_CustomerIngestAlias?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerIngestAliasArgs, 'objects'>>;
  insert_CustomerIngestAlias_one?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerIngestAlias_OneArgs, 'object'>>;
  insert_CustomerManagedField?: Resolver<Maybe<ResolversTypes['CustomerManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerManagedFieldArgs, 'objects'>>;
  insert_CustomerManagedField_one?: Resolver<Maybe<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerManagedField_OneArgs, 'object'>>;
  insert_CustomerPlan?: Resolver<Maybe<ResolversTypes['CustomerPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanArgs, 'objects'>>;
  insert_CustomerPlanAdjustment?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanAdjustmentArgs, 'objects'>>;
  insert_CustomerPlanAdjustment_one?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanAdjustment_OneArgs, 'object'>>;
  insert_CustomerPlanManagedField?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanManagedFieldArgs, 'objects'>>;
  insert_CustomerPlanManagedField_one?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanManagedField_OneArgs, 'object'>>;
  insert_CustomerPlanOneTimeCharge?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanOneTimeChargeArgs, 'objects'>>;
  insert_CustomerPlanOneTimeCharge_one?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlanOneTimeCharge_OneArgs, 'object'>>;
  insert_CustomerPlan_one?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerPlan_OneArgs, 'object'>>;
  insert_CustomerRelationship?: Resolver<Maybe<ResolversTypes['CustomerRelationship_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerRelationshipArgs, 'objects'>>;
  insert_CustomerRelationship_one?: Resolver<Maybe<ResolversTypes['CustomerRelationship']>, ParentType, ContextType, RequireFields<MutationInsert_CustomerRelationship_OneArgs, 'object'>>;
  insert_Customer_one?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationInsert_Customer_OneArgs, 'object'>>;
  insert_DraftPlan?: Resolver<Maybe<ResolversTypes['DraftPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_DraftPlanArgs, 'objects'>>;
  insert_DraftPlan_one?: Resolver<Maybe<ResolversTypes['DraftPlan']>, ParentType, ContextType, RequireFields<MutationInsert_DraftPlan_OneArgs, 'object'>>;
  insert_EnvironmentTypeEnum?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_EnvironmentTypeEnumArgs, 'objects'>>;
  insert_EnvironmentTypeEnum_one?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_EnvironmentTypeEnum_OneArgs, 'object'>>;
  insert_FlatFee?: Resolver<Maybe<ResolversTypes['FlatFee_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_FlatFeeArgs, 'objects'>>;
  insert_FlatFeeAdjustment?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_FlatFeeAdjustmentArgs, 'objects'>>;
  insert_FlatFeeAdjustment_one?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_FlatFeeAdjustment_OneArgs, 'object'>>;
  insert_FlatFee_one?: Resolver<Maybe<ResolversTypes['FlatFee']>, ParentType, ContextType, RequireFields<MutationInsert_FlatFee_OneArgs, 'object'>>;
  insert_InvoiceExternalStatusEnum?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_InvoiceExternalStatusEnumArgs, 'objects'>>;
  insert_InvoiceExternalStatusEnum_one?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType, RequireFields<MutationInsert_InvoiceExternalStatusEnum_OneArgs, 'object'>>;
  insert_InvoiceManagedField?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_InvoiceManagedFieldArgs, 'objects'>>;
  insert_InvoiceManagedField_one?: Resolver<Maybe<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_InvoiceManagedField_OneArgs, 'object'>>;
  insert_ManagedEntityEnum?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedEntityEnumArgs, 'objects'>>;
  insert_ManagedEntityEnum_one?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedEntityEnum_OneArgs, 'object'>>;
  insert_ManagedFieldKey?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedFieldKeyArgs, 'objects'>>;
  insert_ManagedFieldKey_one?: Resolver<Maybe<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedFieldKey_OneArgs, 'object'>>;
  insert_ManagedFieldVendorMapping?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedFieldVendorMappingArgs, 'objects'>>;
  insert_ManagedFieldVendorMapping_one?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedFieldVendorMapping_OneArgs, 'object'>>;
  insert_ManagedVendorEnum?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedVendorEnumArgs, 'objects'>>;
  insert_ManagedVendorEnum_one?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType, RequireFields<MutationInsert_ManagedVendorEnum_OneArgs, 'object'>>;
  insert_Minimum?: Resolver<Maybe<ResolversTypes['Minimum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_MinimumArgs, 'objects'>>;
  insert_Minimum_one?: Resolver<Maybe<ResolversTypes['Minimum']>, ParentType, ContextType, RequireFields<MutationInsert_Minimum_OneArgs, 'object'>>;
  insert_Plan?: Resolver<Maybe<ResolversTypes['Plan_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PlanArgs, 'objects'>>;
  insert_PlanManagedField?: Resolver<Maybe<ResolversTypes['PlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PlanManagedFieldArgs, 'objects'>>;
  insert_PlanManagedField_one?: Resolver<Maybe<ResolversTypes['PlanManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_PlanManagedField_OneArgs, 'object'>>;
  insert_Plan_one?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<MutationInsert_Plan_OneArgs, 'object'>>;
  insert_Price?: Resolver<Maybe<ResolversTypes['Price_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PriceArgs, 'objects'>>;
  insert_PriceAdjustment?: Resolver<Maybe<ResolversTypes['PriceAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PriceAdjustmentArgs, 'objects'>>;
  insert_PriceAdjustmentTypeEnum?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PriceAdjustmentTypeEnumArgs, 'objects'>>;
  insert_PriceAdjustmentTypeEnum_one?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_PriceAdjustmentTypeEnum_OneArgs, 'object'>>;
  insert_PriceAdjustment_one?: Resolver<Maybe<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_PriceAdjustment_OneArgs, 'object'>>;
  insert_Price_one?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType, RequireFields<MutationInsert_Price_OneArgs, 'object'>>;
  insert_PricedProduct?: Resolver<Maybe<ResolversTypes['PricedProduct_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductArgs, 'objects'>>;
  insert_PricedProductAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductAdjustmentArgs, 'objects'>>;
  insert_PricedProductAdjustment_one?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductAdjustment_OneArgs, 'object'>>;
  insert_PricedProductPricingFactor?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductPricingFactorArgs, 'objects'>>;
  insert_PricedProductPricingFactorAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductPricingFactorAdjustmentArgs, 'objects'>>;
  insert_PricedProductPricingFactorAdjustment_one?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductPricingFactorAdjustment_OneArgs, 'object'>>;
  insert_PricedProductPricingFactor_one?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProductPricingFactor_OneArgs, 'object'>>;
  insert_PricedProduct_one?: Resolver<Maybe<ResolversTypes['PricedProduct']>, ParentType, ContextType, RequireFields<MutationInsert_PricedProduct_OneArgs, 'object'>>;
  insert_ProServiceManagedField?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ProServiceManagedFieldArgs, 'objects'>>;
  insert_ProServiceManagedField_one?: Resolver<Maybe<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ProServiceManagedField_OneArgs, 'object'>>;
  insert_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ProductArgs, 'objects'>>;
  insert_ProductManagedField?: Resolver<Maybe<ResolversTypes['ProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ProductManagedFieldArgs, 'objects'>>;
  insert_ProductManagedField_one?: Resolver<Maybe<ResolversTypes['ProductManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ProductManagedField_OneArgs, 'object'>>;
  insert_ProductPricingFactor?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ProductPricingFactorArgs, 'objects'>>;
  insert_ProductPricingFactor_one?: Resolver<Maybe<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationInsert_ProductPricingFactor_OneArgs, 'object'>>;
  insert_Product_one?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<MutationInsert_Product_OneArgs, 'object'>>;
  insert_RateCardManagedField?: Resolver<Maybe<ResolversTypes['RateCardManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_RateCardManagedFieldArgs, 'objects'>>;
  insert_RateCardManagedField_one?: Resolver<Maybe<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_RateCardManagedField_OneArgs, 'object'>>;
  insert_RecurringCreditGrant?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_RecurringCreditGrantArgs, 'objects'>>;
  insert_RecurringCreditGrant_one?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, RequireFields<MutationInsert_RecurringCreditGrant_OneArgs, 'object'>>;
  insert_Role?: Resolver<Maybe<ResolversTypes['Role_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_RoleArgs, 'objects'>>;
  insert_Role_one?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<MutationInsert_Role_OneArgs, 'object'>>;
  insert_RoundingBehaviorEnum?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_RoundingBehaviorEnumArgs, 'objects'>>;
  insert_RoundingBehaviorEnum_one?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType, RequireFields<MutationInsert_RoundingBehaviorEnum_OneArgs, 'object'>>;
  insert_ScheduledChargeManagedField?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ScheduledChargeManagedFieldArgs, 'objects'>>;
  insert_ScheduledChargeManagedField_one?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, RequireFields<MutationInsert_ScheduledChargeManagedField_OneArgs, 'object'>>;
  insert_ServicePeriodStartOnEnum?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_ServicePeriodStartOnEnumArgs, 'objects'>>;
  insert_ServicePeriodStartOnEnum_one?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType, RequireFields<MutationInsert_ServicePeriodStartOnEnum_OneArgs, 'object'>>;
  insert_StripePrepaidConfig_temporary?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_StripePrepaidConfig_TemporaryArgs, 'objects'>>;
  insert_StripePrepaidConfig_temporary_one?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType, RequireFields<MutationInsert_StripePrepaidConfig_Temporary_OneArgs, 'object'>>;
  insert_TieringModeEnum?: Resolver<Maybe<ResolversTypes['TieringModeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_TieringModeEnumArgs, 'objects'>>;
  insert_TieringModeEnum_one?: Resolver<Maybe<ResolversTypes['TieringModeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_TieringModeEnum_OneArgs, 'object'>>;
  insert_Tokens?: Resolver<Maybe<ResolversTypes['Tokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_TokensArgs, 'objects'>>;
  insert_Tokens_one?: Resolver<Maybe<ResolversTypes['Tokens']>, ParentType, ContextType, RequireFields<MutationInsert_Tokens_OneArgs, 'object'>>;
  insert_TrialSpec?: Resolver<Maybe<ResolversTypes['TrialSpec_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_TrialSpecArgs, 'objects'>>;
  insert_TrialSpecSpendingCap?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_TrialSpecSpendingCapArgs, 'objects'>>;
  insert_TrialSpecSpendingCap_one?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, RequireFields<MutationInsert_TrialSpecSpendingCap_OneArgs, 'object'>>;
  insert_TrialSpec_one?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType, RequireFields<MutationInsert_TrialSpec_OneArgs, 'object'>>;
  insert_UserAuthTypeEnum?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_UserAuthTypeEnumArgs, 'objects'>>;
  insert_UserAuthTypeEnum_one?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType, RequireFields<MutationInsert_UserAuthTypeEnum_OneArgs, 'object'>>;
  insert_User_doNotUse?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_User_DoNotUseArgs, 'objects'>>;
  insert_User_one_doNotUse?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationInsert_User_One_DoNotUseArgs, 'object'>>;
  insert_Webhook?: Resolver<Maybe<ResolversTypes['Webhook_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_WebhookArgs, 'objects'>>;
  insert_WebhookNotification?: Resolver<Maybe<ResolversTypes['WebhookNotification_mutation_response']>, ParentType, ContextType, RequireFields<MutationInsert_WebhookNotificationArgs, 'objects'>>;
  insert_WebhookNotification_one?: Resolver<Maybe<ResolversTypes['WebhookNotification']>, ParentType, ContextType, RequireFields<MutationInsert_WebhookNotification_OneArgs, 'object'>>;
  insert_Webhook_one?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType, RequireFields<MutationInsert_Webhook_OneArgs, 'object'>>;
  insert_billing_provider_delivery_method?: Resolver<ResolversTypes['InsertBillingProviderDeliveryMethodResult'], ParentType, ContextType, RequireFields<MutationInsert_Billing_Provider_Delivery_MethodArgs, 'billing_provider' | 'delivery_method' | 'delivery_method_configuration'>>;
  insert_customer_billing_provider_configuration?: Resolver<ResolversTypes['InsertCustomerBillingProviderConfigurationResult'], ParentType, ContextType, RequireFields<MutationInsert_Customer_Billing_Provider_ConfigurationArgs, 'customer_billing_provider_configuration_input'>>;
  insert_customer_billing_provider_configuration_notification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationInsert_Customer_Billing_Provider_Configuration_NotificationArgs, 'customer_billing_provider_configuration_notification'>>;
  insert_customer_billing_provider_configurations?: Resolver<Array<ResolversTypes['InsertCustomerBillingProviderConfigurationResult']>, ParentType, ContextType, RequireFields<MutationInsert_Customer_Billing_Provider_ConfigurationsArgs, 'customer_billing_provider_configuration_inputs'>>;
  insert_failed_marketplaces_metering_record?: Resolver<ResolversTypes['FailedMarketplacesMeteringRecord'], ParentType, ContextType, RequireFields<MutationInsert_Failed_Marketplaces_Metering_RecordArgs, 'customer_id' | 'marketplaces_metered_usage_id' | 'meter_timestamp'>>;
  launch_sox_report_generator?: Resolver<ResolversTypes['LaunchSoxReportGeneratorMutationResponse'], ParentType, ContextType, RequireFields<MutationLaunch_Sox_Report_GeneratorArgs, 'end_date' | 'report_email' | 'start_date'>>;
  migrate_plan_credits_to_contract_commits?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationMigrate_Plan_Credits_To_Contract_CommitsArgs, 'customer_id' | 'free_credits_product_id' | 'prepaid_credits_product_id'>>;
  move_rate_card_products?: Resolver<ResolversTypes['RateCard'], ParentType, ContextType, RequireFields<MutationMove_Rate_Card_ProductsArgs, 'input'>>;
  mri_end_customer_plan?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<MutationMri_End_Customer_PlanArgs, 'cancellation_date' | 'customer_id' | 'customer_plan_id' | 'void_stripe_invoices'>>;
  mri_finalize_invoices_for_customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationMri_Finalize_Invoices_For_CustomerArgs, 'customer_id' | 'invoices_to_finalize_in_order'>>;
  mri_void_invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType, RequireFields<MutationMri_Void_InvoiceArgs, 'id'>>;
  prepaid_credit_grant_recharge?: Resolver<ResolversTypes['PrepaidCreditGrantRechargeResponse'], ParentType, ContextType, RequireFields<MutationPrepaid_Credit_Grant_RechargeArgs, 'current_credit_balance' | 'customer_id' | 'recharge_id'>>;
  provision_salesforce_census_workspace?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationProvision_Salesforce_Census_WorkspaceArgs, 'only_linked_accounts'>>;
  rebuild_contract_billing_provider_projections?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationRebuild_Contract_Billing_Provider_ProjectionsArgs, 'customer_ids'>>;
  rebuild_rate_schedules?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationRebuild_Rate_SchedulesArgs, 'rate_card_id' | 'selectors'>>;
  regenerate_invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType, RequireFields<MutationRegenerate_InvoiceArgs, 'id'>>;
  release_invoices_for_finalization?: Resolver<ResolversTypes['InvoiceFinalizationMetadata'], ParentType, ContextType, RequireFields<MutationRelease_Invoices_For_FinalizationArgs, 'inputs' | 'preview'>>;
  release_prepaid_credit_grant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType, RequireFields<MutationRelease_Prepaid_Credit_GrantArgs, 'credit_grant_id'>>;
  remove_customer?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationRemove_CustomerArgs, 'id'>>;
  repair_credit_grant_recharge?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationRepair_Credit_Grant_RechargeArgs, 'customer_id' | 'recharge_id'>>;
  repair_prepaid_credit_grant?: Resolver<ResolversTypes['RepairPrepaidCreditGrantResponse'], ParentType, ContextType, RequireFields<MutationRepair_Prepaid_Credit_GrantArgs, 'credit_grant_id'>>;
  save_vendor_entity_mappings?: Resolver<ResolversTypes['SaveVendorEntityMappingsResponse'], ParentType, ContextType, RequireFields<MutationSave_Vendor_Entity_MappingsArgs, 'objects'>>;
  send_fake_synthetic_usage_event?: Resolver<ResolversTypes['UsageEventMutationResponse'], ParentType, ContextType, RequireFields<MutationSend_Fake_Synthetic_Usage_EventArgs, 'billable_metric_id' | 'customer_id' | 'json_payload'>>;
  send_invoice_to_stripe?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSend_Invoice_To_StripeArgs, 'invoice_id'>>;
  set_customer_ingest_aliases?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationSet_Customer_Ingest_AliasesArgs, 'customer_id' | 'ingest_aliases'>>;
  set_customer_plan_trial?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType, RequireFields<MutationSet_Customer_Plan_TrialArgs, 'customer_plan_id' | 'trial_spec'>>;
  store_azure_marketplace_credentials?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationStore_Azure_Marketplace_CredentialsArgs, 'azure_client_id' | 'azure_tenant_id' | 'raw_azure_client_secret'>>;
  store_client_webhook_secret?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationStore_Client_Webhook_SecretArgs, 'webhook_secret'>>;
  store_stripe_credentials?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationStore_Stripe_CredentialsArgs, 'code'>>;
  store_stripe_restricted_key?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationStore_Stripe_Restricted_KeyArgs, 'restricted_key'>>;
  test_webhooks?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationTest_WebhooksArgs, 'message'>>;
  update_AccountTypeEnum?: Resolver<Maybe<ResolversTypes['AccountTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_AccountTypeEnumArgs, 'where'>>;
  update_AccountTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_AccountTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_AccountTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccountTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_AccountTypeEnum_ManyArgs, 'updates'>>;
  update_Actor?: Resolver<Maybe<ResolversTypes['Actor_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ActorArgs, 'where'>>;
  update_Actor_by_pk?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType, RequireFields<MutationUpdate_Actor_By_PkArgs, 'pk_columns'>>;
  update_Actor_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Actor_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Actor_ManyArgs, 'updates'>>;
  update_Alert?: Resolver<Maybe<ResolversTypes['Alert_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_AlertArgs, 'where'>>;
  update_AlertManagedField?: Resolver<Maybe<ResolversTypes['AlertManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_AlertManagedFieldArgs, 'where'>>;
  update_AlertManagedField_by_pk?: Resolver<Maybe<ResolversTypes['AlertManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_AlertManagedField_By_PkArgs, 'pk_columns'>>;
  update_AlertManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlertManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_AlertManagedField_ManyArgs, 'updates'>>;
  update_AlertTypeEnum?: Resolver<Maybe<ResolversTypes['AlertTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_AlertTypeEnumArgs, 'where'>>;
  update_AlertTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_AlertTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_AlertTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlertTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_AlertTypeEnum_ManyArgs, 'updates'>>;
  update_Alert_by_pk?: Resolver<Maybe<ResolversTypes['Alert']>, ParentType, ContextType, RequireFields<MutationUpdate_Alert_By_PkArgs, 'pk_columns'>>;
  update_Alert_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Alert_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Alert_ManyArgs, 'updates'>>;
  update_BillableMetric?: Resolver<Maybe<ResolversTypes['BillableMetric_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetricArgs, 'where'>>;
  update_BillableMetricManagedField?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetricManagedFieldArgs, 'where'>>;
  update_BillableMetricManagedField_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetricManagedField_By_PkArgs, 'pk_columns'>>;
  update_BillableMetricManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillableMetricManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetricManagedField_ManyArgs, 'updates'>>;
  update_BillableMetric_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetric_By_PkArgs, 'pk_columns'>>;
  update_BillableMetric_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillableMetric_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillableMetric_ManyArgs, 'updates'>>;
  update_BillingFrequencyEnum?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingFrequencyEnumArgs, 'where'>>;
  update_BillingFrequencyEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingFrequencyEnum_By_PkArgs, 'pk_columns'>>;
  update_BillingFrequencyEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingFrequencyEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingFrequencyEnum_ManyArgs, 'updates'>>;
  update_BillingMetricAggregateEnum?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingMetricAggregateEnumArgs, 'where'>>;
  update_BillingMetricAggregateEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingMetricAggregateEnum_By_PkArgs, 'pk_columns'>>;
  update_BillingMetricAggregateEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingMetricAggregateEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingMetricAggregateEnum_ManyArgs, 'updates'>>;
  update_BillingProviderCustomer?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderCustomerArgs, 'where'>>;
  update_BillingProviderCustomer_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderCustomer_By_PkArgs, 'pk_columns'>>;
  update_BillingProviderCustomer_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingProviderCustomer_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderCustomer_ManyArgs, 'updates'>>;
  update_BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderEnumArgs, 'where'>>;
  update_BillingProviderEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderEnum_By_PkArgs, 'pk_columns'>>;
  update_BillingProviderEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingProviderEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderEnum_ManyArgs, 'updates'>>;
  update_BillingProviderInvoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderInvoiceArgs, 'where'>>;
  update_BillingProviderInvoice_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderInvoice_By_PkArgs, 'pk_columns'>>;
  update_BillingProviderInvoice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingProviderInvoice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderInvoice_ManyArgs, 'updates'>>;
  update_BillingProviderToken?: Resolver<Maybe<ResolversTypes['BillingProviderToken_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderTokenArgs, 'where'>>;
  update_BillingProviderToken_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderToken_By_PkArgs, 'pk_columns'>>;
  update_BillingProviderToken_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingProviderToken_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_BillingProviderToken_ManyArgs, 'updates'>>;
  update_ChargeManagedField?: Resolver<Maybe<ResolversTypes['ChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeManagedFieldArgs, 'where'>>;
  update_ChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeManagedField_By_PkArgs, 'pk_columns'>>;
  update_ChargeManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeManagedField_ManyArgs, 'updates'>>;
  update_ChargeTypeEnum?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeTypeEnumArgs, 'where'>>;
  update_ChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_ChargeTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ChargeTypeEnum_ManyArgs, 'updates'>>;
  update_Client?: Resolver<Maybe<ResolversTypes['Client_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ClientArgs, 'where'>>;
  update_ClientConfig?: Resolver<Maybe<ResolversTypes['ClientConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfigArgs, 'where'>>;
  update_ClientConfigKeyEnum?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfigKeyEnumArgs, 'where'>>;
  update_ClientConfigKeyEnum_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfigKeyEnum_By_PkArgs, 'pk_columns'>>;
  update_ClientConfigKeyEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ClientConfigKeyEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfigKeyEnum_ManyArgs, 'updates'>>;
  update_ClientConfig_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfig']>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfig_By_PkArgs, 'pk_columns'>>;
  update_ClientConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ClientConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ClientConfig_ManyArgs, 'updates'>>;
  update_Client_by_pk?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<MutationUpdate_Client_By_PkArgs, 'pk_columns'>>;
  update_Client_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Client_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Client_ManyArgs, 'updates'>>;
  update_CollectionScheduleEnum?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CollectionScheduleEnumArgs, 'where'>>;
  update_CollectionScheduleEnum_by_pk?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_CollectionScheduleEnum_By_PkArgs, 'pk_columns'>>;
  update_CollectionScheduleEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CollectionScheduleEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CollectionScheduleEnum_ManyArgs, 'updates'>>;
  update_CommitManagedField?: Resolver<Maybe<ResolversTypes['CommitManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CommitManagedFieldArgs, 'where'>>;
  update_CommitManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CommitManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_CommitManagedField_By_PkArgs, 'pk_columns'>>;
  update_CommitManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CommitManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CommitManagedField_ManyArgs, 'updates'>>;
  update_CompositeCharge?: Resolver<Maybe<ResolversTypes['CompositeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeArgs, 'where'>>;
  update_CompositeChargeAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeAdjustmentArgs, 'where'>>;
  update_CompositeChargeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeAdjustment_By_PkArgs, 'pk_columns'>>;
  update_CompositeChargeAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeChargeAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeAdjustment_ManyArgs, 'updates'>>;
  update_CompositeChargePricingFactor?: Resolver<Maybe<ResolversTypes['CompositeChargePricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargePricingFactorArgs, 'where'>>;
  update_CompositeChargePricingFactor_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeChargePricingFactor_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargePricingFactor_ManyArgs, 'updates'>>;
  update_CompositeChargeTier?: Resolver<Maybe<ResolversTypes['CompositeChargeTier_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTierArgs, 'where'>>;
  update_CompositeChargeTierAdjustment?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTierAdjustmentArgs, 'where'>>;
  update_CompositeChargeTierAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTierAdjustment_By_PkArgs, 'pk_columns'>>;
  update_CompositeChargeTierAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeChargeTierAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTierAdjustment_ManyArgs, 'updates'>>;
  update_CompositeChargeTier_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTier_By_PkArgs, 'pk_columns'>>;
  update_CompositeChargeTier_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeChargeTier_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTier_ManyArgs, 'updates'>>;
  update_CompositeChargeTypeEnum?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTypeEnumArgs, 'where'>>;
  update_CompositeChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_CompositeChargeTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeChargeTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeChargeTypeEnum_ManyArgs, 'updates'>>;
  update_CompositeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CompositeCharge']>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeCharge_By_PkArgs, 'pk_columns'>>;
  update_CompositeCharge_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompositeCharge_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CompositeCharge_ManyArgs, 'updates'>>;
  update_ContractCreditManagedField?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractCreditManagedFieldArgs, 'where'>>;
  update_ContractCreditManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractCreditManagedField_By_PkArgs, 'pk_columns'>>;
  update_ContractCreditManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContractCreditManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ContractCreditManagedField_ManyArgs, 'updates'>>;
  update_ContractManagedField?: Resolver<Maybe<ResolversTypes['ContractManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractManagedFieldArgs, 'where'>>;
  update_ContractManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractManagedField_By_PkArgs, 'pk_columns'>>;
  update_ContractManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContractManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ContractManagedField_ManyArgs, 'updates'>>;
  update_ContractProductManagedField?: Resolver<Maybe<ResolversTypes['ContractProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractProductManagedFieldArgs, 'where'>>;
  update_ContractProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ContractProductManagedField_By_PkArgs, 'pk_columns'>>;
  update_ContractProductManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContractProductManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ContractProductManagedField_ManyArgs, 'updates'>>;
  update_CreditAdjustment?: Resolver<Maybe<ResolversTypes['CreditAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditAdjustmentArgs, 'where'>>;
  update_CreditAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditAdjustment_By_PkArgs, 'pk_columns'>>;
  update_CreditAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditAdjustment_ManyArgs, 'updates'>>;
  update_CreditGrant?: Resolver<Maybe<ResolversTypes['CreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantArgs, 'where'>>;
  update_CreditGrantDeduction?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantDeductionArgs, 'where'>>;
  update_CreditGrantDeduction_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantDeduction_By_PkArgs, 'pk_columns'>>;
  update_CreditGrantDeduction_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditGrantDeduction_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantDeduction_ManyArgs, 'updates'>>;
  update_CreditGrantHistory?: Resolver<Maybe<ResolversTypes['CreditGrantHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantHistoryArgs, 'where'>>;
  update_CreditGrantHistory_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantHistory_By_PkArgs, 'pk_columns'>>;
  update_CreditGrantHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditGrantHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantHistory_ManyArgs, 'updates'>>;
  update_CreditGrantManagedField?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantManagedFieldArgs, 'where'>>;
  update_CreditGrantManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantManagedField_By_PkArgs, 'pk_columns'>>;
  update_CreditGrantManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditGrantManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrantManagedField_ManyArgs, 'updates'>>;
  update_CreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrant_By_PkArgs, 'pk_columns'>>;
  update_CreditGrant_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditGrant_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditGrant_ManyArgs, 'updates'>>;
  update_CreditType?: Resolver<Maybe<ResolversTypes['CreditType_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeArgs, 'where'>>;
  update_CreditTypeConversion?: Resolver<Maybe<ResolversTypes['CreditTypeConversion_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversionArgs, 'where'>>;
  update_CreditTypeConversionAdjustment?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversionAdjustmentArgs, 'where'>>;
  update_CreditTypeConversionAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversionAdjustment_By_PkArgs, 'pk_columns'>>;
  update_CreditTypeConversionAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditTypeConversionAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversionAdjustment_ManyArgs, 'updates'>>;
  update_CreditTypeConversion_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversion_By_PkArgs, 'pk_columns'>>;
  update_CreditTypeConversion_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditTypeConversion_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditTypeConversion_ManyArgs, 'updates'>>;
  update_CreditType_by_pk?: Resolver<Maybe<ResolversTypes['CreditType']>, ParentType, ContextType, RequireFields<MutationUpdate_CreditType_By_PkArgs, 'pk_columns'>>;
  update_CreditType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CreditType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CreditType_ManyArgs, 'updates'>>;
  update_CustomPricing?: Resolver<Maybe<ResolversTypes['CustomPricing_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomPricingArgs, 'where'>>;
  update_CustomPricing_by_pk?: Resolver<Maybe<ResolversTypes['CustomPricing']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomPricing_By_PkArgs, 'pk_columns'>>;
  update_CustomPricing_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomPricing_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomPricing_ManyArgs, 'updates'>>;
  update_Customer?: Resolver<Maybe<ResolversTypes['Customer_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerArgs, 'where'>>;
  update_CustomerAlert?: Resolver<Maybe<ResolversTypes['CustomerAlert_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertArgs, 'where'>>;
  update_CustomerAlertHistory?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertHistoryArgs, 'where'>>;
  update_CustomerAlertHistory_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertHistory_By_PkArgs, 'pk_columns'>>;
  update_CustomerAlertHistory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerAlertHistory_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertHistory_ManyArgs, 'updates'>>;
  update_CustomerAlertStatusEnum?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertStatusEnumArgs, 'where'>>;
  update_CustomerAlertStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertStatusEnum_By_PkArgs, 'pk_columns'>>;
  update_CustomerAlertStatusEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerAlertStatusEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlertStatusEnum_ManyArgs, 'updates'>>;
  update_CustomerAlert_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlert']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlert_By_PkArgs, 'pk_columns'>>;
  update_CustomerAlert_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerAlert_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerAlert_ManyArgs, 'updates'>>;
  update_CustomerConfig?: Resolver<Maybe<ResolversTypes['CustomerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerConfigArgs, 'where'>>;
  update_CustomerConfig_by_pk?: Resolver<Maybe<ResolversTypes['CustomerConfig']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerConfig_By_PkArgs, 'pk_columns'>>;
  update_CustomerConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerConfig_ManyArgs, 'updates'>>;
  update_CustomerIngestAlias?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerIngestAliasArgs, 'where'>>;
  update_CustomerIngestAlias_by_pk?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerIngestAlias_By_PkArgs, 'pk_columns'>>;
  update_CustomerIngestAlias_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerIngestAlias_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerIngestAlias_ManyArgs, 'updates'>>;
  update_CustomerManagedField?: Resolver<Maybe<ResolversTypes['CustomerManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerManagedFieldArgs, 'where'>>;
  update_CustomerManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerManagedField_By_PkArgs, 'pk_columns'>>;
  update_CustomerManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerManagedField_ManyArgs, 'updates'>>;
  update_CustomerPlan?: Resolver<Maybe<ResolversTypes['CustomerPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanArgs, 'where'>>;
  update_CustomerPlanAdjustment?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanAdjustmentArgs, 'where'>>;
  update_CustomerPlanAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanAdjustment_By_PkArgs, 'pk_columns'>>;
  update_CustomerPlanAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPlanAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanAdjustment_ManyArgs, 'updates'>>;
  update_CustomerPlanManagedField?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanManagedFieldArgs, 'where'>>;
  update_CustomerPlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanManagedField_By_PkArgs, 'pk_columns'>>;
  update_CustomerPlanManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPlanManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanManagedField_ManyArgs, 'updates'>>;
  update_CustomerPlanOneTimeCharge?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanOneTimeChargeArgs, 'where'>>;
  update_CustomerPlanOneTimeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanOneTimeCharge_By_PkArgs, 'pk_columns'>>;
  update_CustomerPlanOneTimeCharge_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPlanOneTimeCharge_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlanOneTimeCharge_ManyArgs, 'updates'>>;
  update_CustomerPlan_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlan_By_PkArgs, 'pk_columns'>>;
  update_CustomerPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerPlan_ManyArgs, 'updates'>>;
  update_CustomerRelationship?: Resolver<Maybe<ResolversTypes['CustomerRelationship_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerRelationshipArgs, 'where'>>;
  update_CustomerRelationship_by_pk?: Resolver<Maybe<ResolversTypes['CustomerRelationship']>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerRelationship_By_PkArgs, 'pk_columns'>>;
  update_CustomerRelationship_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerRelationship_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_CustomerRelationship_ManyArgs, 'updates'>>;
  update_Customer_by_pk?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<MutationUpdate_Customer_By_PkArgs, 'pk_columns'>>;
  update_Customer_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Customer_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Customer_ManyArgs, 'updates'>>;
  update_DraftPlan?: Resolver<Maybe<ResolversTypes['DraftPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_DraftPlanArgs, 'where'>>;
  update_DraftPlan_by_pk?: Resolver<Maybe<ResolversTypes['DraftPlan']>, ParentType, ContextType, RequireFields<MutationUpdate_DraftPlan_By_PkArgs, 'pk_columns'>>;
  update_DraftPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['DraftPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_DraftPlan_ManyArgs, 'updates'>>;
  update_EnvironmentTypeEnum?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_EnvironmentTypeEnumArgs, 'where'>>;
  update_EnvironmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_EnvironmentTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_EnvironmentTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['EnvironmentTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_EnvironmentTypeEnum_ManyArgs, 'updates'>>;
  update_FlatFee?: Resolver<Maybe<ResolversTypes['FlatFee_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFeeArgs, 'where'>>;
  update_FlatFeeAdjustment?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFeeAdjustmentArgs, 'where'>>;
  update_FlatFeeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFeeAdjustment_By_PkArgs, 'pk_columns'>>;
  update_FlatFeeAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlatFeeAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFeeAdjustment_ManyArgs, 'updates'>>;
  update_FlatFee_by_pk?: Resolver<Maybe<ResolversTypes['FlatFee']>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFee_By_PkArgs, 'pk_columns'>>;
  update_FlatFee_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['FlatFee_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_FlatFee_ManyArgs, 'updates'>>;
  update_InvoiceExternalStatusEnum?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceExternalStatusEnumArgs, 'where'>>;
  update_InvoiceExternalStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceExternalStatusEnum_By_PkArgs, 'pk_columns'>>;
  update_InvoiceExternalStatusEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceExternalStatusEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceExternalStatusEnum_ManyArgs, 'updates'>>;
  update_InvoiceManagedField?: Resolver<Maybe<ResolversTypes['InvoiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceManagedFieldArgs, 'where'>>;
  update_InvoiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceManagedField_By_PkArgs, 'pk_columns'>>;
  update_InvoiceManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['InvoiceManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_InvoiceManagedField_ManyArgs, 'updates'>>;
  update_ManagedEntityEnum?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedEntityEnumArgs, 'where'>>;
  update_ManagedEntityEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedEntityEnum_By_PkArgs, 'pk_columns'>>;
  update_ManagedEntityEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManagedEntityEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedEntityEnum_ManyArgs, 'updates'>>;
  update_ManagedFieldKey?: Resolver<Maybe<ResolversTypes['ManagedFieldKey_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldKeyArgs, 'where'>>;
  update_ManagedFieldKey_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldKey_By_PkArgs, 'pk_columns'>>;
  update_ManagedFieldKey_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManagedFieldKey_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldKey_ManyArgs, 'updates'>>;
  update_ManagedFieldVendorMapping?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldVendorMappingArgs, 'where'>>;
  update_ManagedFieldVendorMapping_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldVendorMapping_By_PkArgs, 'pk_columns'>>;
  update_ManagedFieldVendorMapping_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManagedFieldVendorMapping_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedFieldVendorMapping_ManyArgs, 'updates'>>;
  update_ManagedVendorEnum?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedVendorEnumArgs, 'where'>>;
  update_ManagedVendorEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedVendorEnum_By_PkArgs, 'pk_columns'>>;
  update_ManagedVendorEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManagedVendorEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ManagedVendorEnum_ManyArgs, 'updates'>>;
  update_Minimum?: Resolver<Maybe<ResolversTypes['Minimum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_MinimumArgs, 'where'>>;
  update_Minimum_by_pk?: Resolver<Maybe<ResolversTypes['Minimum']>, ParentType, ContextType, RequireFields<MutationUpdate_Minimum_By_PkArgs, 'pk_columns'>>;
  update_Minimum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Minimum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Minimum_ManyArgs, 'updates'>>;
  update_Plan?: Resolver<Maybe<ResolversTypes['Plan_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PlanArgs, 'where'>>;
  update_PlanManagedField?: Resolver<Maybe<ResolversTypes['PlanManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PlanManagedFieldArgs, 'where'>>;
  update_PlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['PlanManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_PlanManagedField_By_PkArgs, 'pk_columns'>>;
  update_PlanManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlanManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PlanManagedField_ManyArgs, 'updates'>>;
  update_Plan_by_pk?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<MutationUpdate_Plan_By_PkArgs, 'pk_columns'>>;
  update_Plan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Plan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Plan_ManyArgs, 'updates'>>;
  update_Price?: Resolver<Maybe<ResolversTypes['Price_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PriceArgs, 'where'>>;
  update_PriceAdjustment?: Resolver<Maybe<ResolversTypes['PriceAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustmentArgs, 'where'>>;
  update_PriceAdjustmentTypeEnum?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustmentTypeEnumArgs, 'where'>>;
  update_PriceAdjustmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustmentTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_PriceAdjustmentTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustmentTypeEnum_ManyArgs, 'updates'>>;
  update_PriceAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustment_By_PkArgs, 'pk_columns'>>;
  update_PriceAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PriceAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PriceAdjustment_ManyArgs, 'updates'>>;
  update_Price_by_pk?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType, RequireFields<MutationUpdate_Price_By_PkArgs, 'pk_columns'>>;
  update_Price_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Price_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Price_ManyArgs, 'updates'>>;
  update_PricedProduct?: Resolver<Maybe<ResolversTypes['PricedProduct_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductArgs, 'where'>>;
  update_PricedProductAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductAdjustmentArgs, 'where'>>;
  update_PricedProductAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductAdjustment_By_PkArgs, 'pk_columns'>>;
  update_PricedProductAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PricedProductAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductAdjustment_ManyArgs, 'updates'>>;
  update_PricedProductPricingFactor?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactorArgs, 'where'>>;
  update_PricedProductPricingFactorAdjustment?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactorAdjustmentArgs, 'where'>>;
  update_PricedProductPricingFactorAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactorAdjustment_By_PkArgs, 'pk_columns'>>;
  update_PricedProductPricingFactorAdjustment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactorAdjustment_ManyArgs, 'updates'>>;
  update_PricedProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactor_By_PkArgs, 'pk_columns'>>;
  update_PricedProductPricingFactor_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PricedProductPricingFactor_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProductPricingFactor_ManyArgs, 'updates'>>;
  update_PricedProduct_by_pk?: Resolver<Maybe<ResolversTypes['PricedProduct']>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProduct_By_PkArgs, 'pk_columns'>>;
  update_PricedProduct_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['PricedProduct_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_PricedProduct_ManyArgs, 'updates'>>;
  update_ProServiceManagedField?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ProServiceManagedFieldArgs, 'where'>>;
  update_ProServiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ProServiceManagedField_By_PkArgs, 'pk_columns'>>;
  update_ProServiceManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProServiceManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ProServiceManagedField_ManyArgs, 'updates'>>;
  update_Product?: Resolver<Maybe<ResolversTypes['Product_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ProductArgs, 'where'>>;
  update_ProductManagedField?: Resolver<Maybe<ResolversTypes['ProductManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ProductManagedFieldArgs, 'where'>>;
  update_ProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProductManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ProductManagedField_By_PkArgs, 'pk_columns'>>;
  update_ProductManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ProductManagedField_ManyArgs, 'updates'>>;
  update_ProductPricingFactor?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ProductPricingFactorArgs, 'where'>>;
  update_ProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, RequireFields<MutationUpdate_ProductPricingFactor_By_PkArgs, 'pk_columns'>>;
  update_ProductPricingFactor_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductPricingFactor_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ProductPricingFactor_ManyArgs, 'updates'>>;
  update_Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<MutationUpdate_Product_By_PkArgs, 'pk_columns'>>;
  update_Product_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Product_ManyArgs, 'updates'>>;
  update_RateCardManagedField?: Resolver<Maybe<ResolversTypes['RateCardManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_RateCardManagedFieldArgs, 'where'>>;
  update_RateCardManagedField_by_pk?: Resolver<Maybe<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_RateCardManagedField_By_PkArgs, 'pk_columns'>>;
  update_RateCardManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateCardManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_RateCardManagedField_ManyArgs, 'updates'>>;
  update_RecurringCreditGrant?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_RecurringCreditGrantArgs, 'where'>>;
  update_RecurringCreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, RequireFields<MutationUpdate_RecurringCreditGrant_By_PkArgs, 'pk_columns'>>;
  update_RecurringCreditGrant_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['RecurringCreditGrant_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_RecurringCreditGrant_ManyArgs, 'updates'>>;
  update_Role?: Resolver<Maybe<ResolversTypes['Role_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_RoleArgs, 'where'>>;
  update_Role_by_pk?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<MutationUpdate_Role_By_PkArgs, 'pk_columns'>>;
  update_Role_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Role_ManyArgs, 'updates'>>;
  update_RoundingBehaviorEnum?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_RoundingBehaviorEnumArgs, 'where'>>;
  update_RoundingBehaviorEnum_by_pk?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_RoundingBehaviorEnum_By_PkArgs, 'pk_columns'>>;
  update_RoundingBehaviorEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['RoundingBehaviorEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_RoundingBehaviorEnum_ManyArgs, 'updates'>>;
  update_ScheduledChargeManagedField?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ScheduledChargeManagedFieldArgs, 'where'>>;
  update_ScheduledChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, RequireFields<MutationUpdate_ScheduledChargeManagedField_By_PkArgs, 'pk_columns'>>;
  update_ScheduledChargeManagedField_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ScheduledChargeManagedField_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ScheduledChargeManagedField_ManyArgs, 'updates'>>;
  update_ServicePeriodStartOnEnum?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_ServicePeriodStartOnEnumArgs, 'where'>>;
  update_ServicePeriodStartOnEnum_by_pk?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_ServicePeriodStartOnEnum_By_PkArgs, 'pk_columns'>>;
  update_ServicePeriodStartOnEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ServicePeriodStartOnEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_ServicePeriodStartOnEnum_ManyArgs, 'updates'>>;
  update_StripePrepaidConfig_temporary?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_StripePrepaidConfig_TemporaryArgs, 'where'>>;
  update_StripePrepaidConfig_temporary_by_pk?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType, RequireFields<MutationUpdate_StripePrepaidConfig_Temporary_By_PkArgs, 'pk_columns'>>;
  update_StripePrepaidConfig_temporary_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['StripePrepaidConfig_temporary_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_StripePrepaidConfig_Temporary_ManyArgs, 'updates'>>;
  update_TieringModeEnum?: Resolver<Maybe<ResolversTypes['TieringModeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_TieringModeEnumArgs, 'where'>>;
  update_TieringModeEnum_by_pk?: Resolver<Maybe<ResolversTypes['TieringModeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_TieringModeEnum_By_PkArgs, 'pk_columns'>>;
  update_TieringModeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['TieringModeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_TieringModeEnum_ManyArgs, 'updates'>>;
  update_Tokens?: Resolver<Maybe<ResolversTypes['Tokens_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_TokensArgs, 'where'>>;
  update_Tokens_by_pk?: Resolver<Maybe<ResolversTypes['Tokens']>, ParentType, ContextType, RequireFields<MutationUpdate_Tokens_By_PkArgs, 'pk_columns'>>;
  update_Tokens_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tokens_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Tokens_ManyArgs, 'updates'>>;
  update_TrialSpec?: Resolver<Maybe<ResolversTypes['TrialSpec_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpecArgs, 'where'>>;
  update_TrialSpecSpendingCap?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpecSpendingCapArgs, 'where'>>;
  update_TrialSpecSpendingCap_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpecSpendingCap_By_PkArgs, 'pk_columns'>>;
  update_TrialSpecSpendingCap_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['TrialSpecSpendingCap_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpecSpendingCap_ManyArgs, 'updates'>>;
  update_TrialSpec_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpec_By_PkArgs, 'pk_columns'>>;
  update_TrialSpec_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['TrialSpec_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_TrialSpec_ManyArgs, 'updates'>>;
  update_UserAuthTypeEnum?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_UserAuthTypeEnumArgs, 'where'>>;
  update_UserAuthTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType, RequireFields<MutationUpdate_UserAuthTypeEnum_By_PkArgs, 'pk_columns'>>;
  update_UserAuthTypeEnum_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserAuthTypeEnum_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_UserAuthTypeEnum_ManyArgs, 'updates'>>;
  update_User_doNotUse?: Resolver<Maybe<ResolversTypes['User_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_User_DoNotUseArgs, 'where'>>;
  update_User_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['User_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_User_ManyArgs, 'updates'>>;
  update_Webhook?: Resolver<Maybe<ResolversTypes['Webhook_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_WebhookArgs, 'where'>>;
  update_WebhookNotification?: Resolver<Maybe<ResolversTypes['WebhookNotification_mutation_response']>, ParentType, ContextType, RequireFields<MutationUpdate_WebhookNotificationArgs, 'where'>>;
  update_WebhookNotification_by_pk?: Resolver<Maybe<ResolversTypes['WebhookNotification']>, ParentType, ContextType, RequireFields<MutationUpdate_WebhookNotification_By_PkArgs, 'pk_columns'>>;
  update_WebhookNotification_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['WebhookNotification_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_WebhookNotification_ManyArgs, 'updates'>>;
  update_Webhook_by_pk?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType, RequireFields<MutationUpdate_Webhook_By_PkArgs, 'pk_columns'>>;
  update_Webhook_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['Webhook_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationUpdate_Webhook_ManyArgs, 'updates'>>;
  update_billing_provider_settings?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Billing_Provider_SettingsArgs, 'billing_provider' | 'settings'>>;
  update_by_pk_User_doNotUse?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdate_By_Pk_User_DoNotUseArgs, 'pk_columns'>>;
  update_client_billing_provider_configurations?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Client_Billing_Provider_ConfigurationsArgs, 'billing_provider' | 'configuration' | 'delivery_method'>>;
  update_commit_schedule?: Resolver<ResolversTypes['UpdateCommitScheduleMutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Commit_ScheduleArgs, 'commit_id' | 'customer_id' | 'preview' | 'update'>>;
  update_contract_end_date?: Resolver<ResolversTypes['Contract'], ParentType, ContextType, RequireFields<MutationUpdate_Contract_End_DateArgs, 'contract_id' | 'customer_id'>>;
  update_contract_invoice_date?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType, RequireFields<MutationUpdate_Contract_Invoice_DateArgs, 'invoice_id' | 'new_invoice_date'>>;
  update_contract_named_schedule?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Contract_Named_ScheduleArgs, 'contract_id' | 'customer_id' | 'schedule_name' | 'starting_at' | 'value'>>;
  update_credit_grant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType, RequireFields<MutationUpdate_Credit_GrantArgs, 'expires_before' | 'id' | 'name'>>;
  update_customer_billable_status?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<MutationUpdate_Customer_Billable_StatusArgs, 'billable_status' | 'customer_id' | 'effective_at'>>;
  update_customer_named_schedule?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Customer_Named_ScheduleArgs, 'customer_id' | 'schedule_name' | 'starting_at' | 'value'>>;
  update_email?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdate_EmailArgs, 'email'>>;
  update_invoice_finalization_metadata_status?: Resolver<ResolversTypes['InvoiceFinalizationMetadata'], ParentType, ContextType, RequireFields<MutationUpdate_Invoice_Finalization_Metadata_StatusArgs, 'id' | 'status'>>;
  update_name?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdate_NameArgs, 'name'>>;
  update_product_list_item?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType, RequireFields<MutationUpdate_Product_List_ItemArgs, 'effective_at' | 'id'>>;
  update_rate_card?: Resolver<ResolversTypes['RateCard'], ParentType, ContextType, RequireFields<MutationUpdate_Rate_CardArgs, 'id'>>;
  update_rate_card_named_schedule?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Rate_Card_Named_ScheduleArgs, 'rate_card_id' | 'schedule_name' | 'starting_at' | 'value'>>;
  update_recharge_settings?: Resolver<ResolversTypes['RechargeSettings'], ParentType, ContextType, RequireFields<MutationUpdate_Recharge_SettingsArgs, 'id' | 'recharge_settings'>>;
  update_recharge_settings_and_recharge?: Resolver<ResolversTypes['RechargeSettingsUpdateAndChargeResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Recharge_Settings_And_RechargeArgs, 'current_credit_balance' | 'id' | 'recharge_settings'>>;
  update_usage_filter?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<MutationUpdate_Usage_FilterArgs, 'contract_id' | 'customer_id' | 'effective_at' | 'new_filter'>>;
  upsert_billing_provider_invoice?: Resolver<Array<Maybe<ResolversTypes['BillingProviderInvoiceUpsertResult']>>, ParentType, ContextType, RequireFields<MutationUpsert_Billing_Provider_InvoiceArgs, 'input'>>;
  void_credit_grant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType, RequireFields<MutationVoid_Credit_GrantArgs, 'credit_grant_id' | 'release_uniqueness_key'>>;
  void_customer_commit?: Resolver<ResolversTypes['CommitUnion'], ParentType, ContextType, RequireFields<MutationVoid_Customer_CommitArgs, 'commit_id' | 'customer_id'>>;
  void_prepaid_credit_grant?: Resolver<ResolversTypes['CreditGrant'], ParentType, ContextType, RequireFields<MutationVoid_Prepaid_Credit_GrantArgs, 'credit_grant_id'>>;
};

export type MutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationResponse'] = ResolversParentTypes['MutationResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NamedScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedSchedule'] = ResolversParentTypes['NamedSchedule']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segments?: Resolver<Array<ResolversTypes['NamedScheduleSegment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NamedScheduleDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedScheduleData'] = ResolversParentTypes['NamedScheduleData']> = {
  value?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NamedScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedScheduleSegment'] = ResolversParentTypes['NamedScheduleSegment']> = {
  data?: Resolver<ResolversTypes['NamedScheduleData'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NonCompositeProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['NonCompositeProductListItem'] = ResolversParentTypes['NonCompositeProductListItem']> = {
  __resolveType: TypeResolveFn<'FixedProductListItem' | 'ProServiceProductListItem' | 'SubscriptionProductListItem' | 'UsageProductListItem', ParentType, ContextType>;
};

export type OneTimeProductChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OneTimeProductCharge'] = ResolversParentTypes['OneTimeProductCharge']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer_plan?: Resolver<ResolversTypes['CustomerPlan'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  product_pricing_factor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  service_period_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OverageLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OverageLineItem'] = ResolversParentTypes['OverageLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  overage_credit_amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  overage_credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  overage_rate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OverrideRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OverrideRate'] = ResolversParentTypes['OverrideRate']> = {
  __resolveType: TypeResolveFn<'MultiplierOverride' | 'OverwriteOverride' | 'TieredOverride', ParentType, ContextType>;
};

export type OverrideSpecifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['OverrideSpecifier'] = ResolversParentTypes['OverrideSpecifier']> = {
  presentation_group_values?: Resolver<Maybe<Array<ResolversTypes['PresentationGroupValue']>>, ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['PricingGroupValue']>>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OverwriteOverrideResolvers<ContextType = any, ParentType extends ResolversParentTypes['OverwriteOverride'] = ResolversParentTypes['OverwriteOverride']> = {
  new_rate?: Resolver<ResolversTypes['Rate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedSeatMetricsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedSeatMetricsResult'] = ResolversParentTypes['PagedSeatMetricsResult']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['SeatMetric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedUsage'] = ResolversParentTypes['PagedUsage']> = {
  data?: Resolver<Array<ResolversTypes['PagedUsageWindow']>, ParentType, ContextType>;
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedUsageWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedUsageWindow'] = ResolversParentTypes['PagedUsageWindow']> = {
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starting_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParentInvoice'] = ResolversParentTypes['ParentInvoice']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<ParentInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingPrepaidGrantResolvers<ContextType = any, ParentType extends ResolversParentTypes['PendingPrepaidGrant'] = ResolversParentTypes['PendingPrepaidGrant']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingPrepaidGrantsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PendingPrepaidGrantsResponse'] = ResolversParentTypes['PendingPrepaidGrantsResponse']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pending_prepaid_grants?: Resolver<Array<ResolversTypes['PendingPrepaidGrant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingRechargesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PendingRechargesResponse'] = ResolversParentTypes['PendingRechargesResponse']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pending_recharges?: Resolver<Array<ResolversTypes['RechargeSettings']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PepaidCreditGrantResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PepaidCreditGrantResponse'] = ResolversParentTypes['PepaidCreditGrantResponse']> = {
  credit_grant?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PrepaidCreditGrantStatusEnum'], ParentType, ContextType>;
  stripe_response?: Resolver<Maybe<ResolversTypes['StripeCreditGrantResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PercentageRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PercentageRate'] = ResolversParentTypes['PercentageRate']> = {
  fraction?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  use_list_prices?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan'] = ResolversParentTypes['Plan']> = {
  Actor?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  Alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType, Partial<PlanAlertsArgs>>;
  Alerts_aggregate?: Resolver<ResolversTypes['Alert_aggregate'], ParentType, ContextType, Partial<PlanAlerts_AggregateArgs>>;
  BillingProviderEnum?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  CreditTypeConversions?: Resolver<Array<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, Partial<PlanCreditTypeConversionsArgs>>;
  CreditTypeConversions_aggregate?: Resolver<ResolversTypes['CreditTypeConversion_aggregate'], ParentType, ContextType, Partial<PlanCreditTypeConversions_AggregateArgs>>;
  CustomerPlans?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType, Partial<PlanCustomerPlansArgs>>;
  CustomerPlans_aggregate?: Resolver<ResolversTypes['CustomerPlan_aggregate'], ParentType, ContextType, Partial<PlanCustomerPlans_AggregateArgs>>;
  Minimums?: Resolver<Array<ResolversTypes['Minimum']>, ParentType, ContextType, Partial<PlanMinimumsArgs>>;
  Minimums_aggregate?: Resolver<ResolversTypes['Minimum_aggregate'], ParentType, ContextType, Partial<PlanMinimums_AggregateArgs>>;
  PlanManagedFields?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType, Partial<PlanPlanManagedFieldsArgs>>;
  PlanManagedFields_aggregate?: Resolver<ResolversTypes['PlanManagedField_aggregate'], ParentType, ContextType, Partial<PlanPlanManagedFields_AggregateArgs>>;
  PricedProducts?: Resolver<Array<ResolversTypes['PricedProduct']>, ParentType, ContextType, Partial<PlanPricedProductsArgs>>;
  PricedProducts_aggregate?: Resolver<ResolversTypes['PricedProduct_aggregate'], ParentType, ContextType, Partial<PlanPricedProducts_AggregateArgs>>;
  RecurringCreditGrants?: Resolver<Array<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, Partial<PlanRecurringCreditGrantsArgs>>;
  RecurringCreditGrants_aggregate?: Resolver<ResolversTypes['RecurringCreditGrant_aggregate'], ParentType, ContextType, Partial<PlanRecurringCreditGrants_AggregateArgs>>;
  TrialSpec?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType>;
  billing_frequency?: Resolver<ResolversTypes['BillingFrequencyEnum_enum'], ParentType, ContextType>;
  billing_provider?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_enum']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customer_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<PlanCustomer_CountArgs, 'status'>>;
  customer_plans?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<PlanCustomer_PlansArgs, 'status'>>;
  default_length_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType>;
  must_support_aws_marketplace?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newest_revision_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  seat_billing_frequency?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum_enum']>, ParentType, ContextType>;
  service_period_start_type?: Resolver<ResolversTypes['ServicePeriodStartOnEnum_enum'], ParentType, ContextType>;
  starting_on?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField'] = ResolversParentTypes['PlanManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField_aggregate'] = ResolversParentTypes['PlanManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PlanManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField_aggregate_fields'] = ResolversParentTypes['PlanManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PlanManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PlanManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PlanManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField_max_fields'] = ResolversParentTypes['PlanManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField_min_fields'] = ResolversParentTypes['PlanManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanManagedField_mutation_response'] = ResolversParentTypes['PlanManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_aggregate'] = ResolversParentTypes['Plan_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Plan_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_aggregate_fields'] = ResolversParentTypes['Plan_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['Plan_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Plan_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Plan_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Plan_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Plan_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Plan_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Plan_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Plan_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Plan_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Plan_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Plan_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_avg_fields'] = ResolversParentTypes['Plan_avg_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_max_fields'] = ResolversParentTypes['Plan_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default_length_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newest_revision_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  starting_on?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_min_fields'] = ResolversParentTypes['Plan_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  default_length_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newest_revision_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  starting_on?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_mutation_response'] = ResolversParentTypes['Plan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_stddev_fields'] = ResolversParentTypes['Plan_stddev_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_stddev_pop_fields'] = ResolversParentTypes['Plan_stddev_pop_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_stddev_samp_fields'] = ResolversParentTypes['Plan_stddev_samp_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_sum_fields'] = ResolversParentTypes['Plan_sum_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_var_pop_fields'] = ResolversParentTypes['Plan_var_pop_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_var_samp_fields'] = ResolversParentTypes['Plan_var_samp_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Plan_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan_variance_fields'] = ResolversParentTypes['Plan_variance_fields']> = {
  default_length_months?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommit'] = ResolversParentTypes['PostpaidCommit']> = {
  access_schedule?: Resolver<ResolversTypes['CommitAccessSchedule'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  applicable_contracts?: Resolver<Maybe<Array<ResolversTypes['Contract']>>, ParentType, ContextType>;
  applicable_products?: Resolver<Maybe<Array<ResolversTypes['ProductListItem']>>, ParentType, ContextType>;
  applicable_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  invoice_schedule?: Resolver<ResolversTypes['CommitInvoiceSchedule'], ParentType, ContextType>;
  ledger?: Resolver<Array<ResolversTypes['PostpaidCommitLedgerEntry']>, ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CommitOrCreditManagedField']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  remaining_balance?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  rolled_over_from?: Resolver<Maybe<ResolversTypes['PostpaidCommit']>, ParentType, ContextType>;
  rollover_fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitAutomatedInvoiceDeductionLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry'] = ResolversParentTypes['PostpaidCommitAutomatedInvoiceDeductionLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitBaseLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitBaseLedgerEntry'] = ResolversParentTypes['PostpaidCommitBaseLedgerEntry']> = {
  __resolveType: TypeResolveFn<'PostpaidCommitAutomatedInvoiceDeductionLedgerEntry' | 'PostpaidCommitCanceledLedgerEntry' | 'PostpaidCommitCreditedLedgerEntry' | 'PostpaidCommitExpirationLedgerEntry' | 'PostpaidCommitInitialBalanceLedgerEntry' | 'PostpaidCommitManualLedgerEntry' | 'PostpaidCommitRolloverLedgerEntry' | 'PostpaidCommitTrueupLedgerEntry', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
};

export type PostpaidCommitCanceledLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitCanceledLedgerEntry'] = ResolversParentTypes['PostpaidCommitCanceledLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitCreditedLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitCreditedLedgerEntry'] = ResolversParentTypes['PostpaidCommitCreditedLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitExpirationLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitExpirationLedgerEntry'] = ResolversParentTypes['PostpaidCommitExpirationLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitInitialBalanceLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitInitialBalanceLedgerEntry'] = ResolversParentTypes['PostpaidCommitInitialBalanceLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitLedgerEntry'] = ResolversParentTypes['PostpaidCommitLedgerEntry']> = {
  __resolveType: TypeResolveFn<'PostpaidCommitAutomatedInvoiceDeductionLedgerEntry' | 'PostpaidCommitCanceledLedgerEntry' | 'PostpaidCommitCreditedLedgerEntry' | 'PostpaidCommitExpirationLedgerEntry' | 'PostpaidCommitInitialBalanceLedgerEntry' | 'PostpaidCommitManualLedgerEntry' | 'PostpaidCommitRolloverLedgerEntry' | 'PostpaidCommitTrueupLedgerEntry', ParentType, ContextType>;
};

export type PostpaidCommitManualLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitManualLedgerEntry'] = ResolversParentTypes['PostpaidCommitManualLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitRolloverLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitRolloverLedgerEntry'] = ResolversParentTypes['PostpaidCommitRolloverLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  new_commit?: Resolver<ResolversTypes['PostpaidCommit'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostpaidCommitTrueupLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostpaidCommitTrueupLedgerEntry'] = ResolversParentTypes['PostpaidCommitTrueupLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommit'] = ResolversParentTypes['PrepaidCommit']> = {
  access_schedule?: Resolver<ResolversTypes['CommitAccessSchedule'], ParentType, ContextType>;
  applicable_contracts?: Resolver<Maybe<Array<ResolversTypes['Contract']>>, ParentType, ContextType>;
  applicable_products?: Resolver<Maybe<Array<ResolversTypes['ProductListItem']>>, ParentType, ContextType>;
  applicable_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['ExternalCommitType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  invoice_schedule?: Resolver<Maybe<ResolversTypes['CommitInvoiceSchedule']>, ParentType, ContextType>;
  ledger?: Resolver<Array<ResolversTypes['PrepaidCommitLedgerEntry']>, ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['CommitOrCreditManagedField']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  rolled_over_from?: Resolver<Maybe<ResolversTypes['PrepaidCommit']>, ParentType, ContextType>;
  rollover_fraction?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  salesforce_opportunity_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voided_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitAutomatedInvoiceDeductionLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry'] = ResolversParentTypes['PrepaidCommitAutomatedInvoiceDeductionLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitBaseLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitBaseLedgerEntry'] = ResolversParentTypes['PrepaidCommitBaseLedgerEntry']> = {
  __resolveType: TypeResolveFn<'PrepaidCommitAutomatedInvoiceDeductionLedgerEntry' | 'PrepaidCommitCanceledLedgerEntry' | 'PrepaidCommitCreditedLedgerEntry' | 'PrepaidCommitExpirationLedgerEntry' | 'PrepaidCommitManualLedgerEntry' | 'PrepaidCommitRolloverLedgerEntry' | 'PrepaidCommitSegmentStartLedgerEntry', ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
};

export type PrepaidCommitCanceledLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitCanceledLedgerEntry'] = ResolversParentTypes['PrepaidCommitCanceledLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitCreditedLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitCreditedLedgerEntry'] = ResolversParentTypes['PrepaidCommitCreditedLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice?: Resolver<ResolversTypes['MRI_Invoice'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitExpirationLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitExpirationLedgerEntry'] = ResolversParentTypes['PrepaidCommitExpirationLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitLedgerEntry'] = ResolversParentTypes['PrepaidCommitLedgerEntry']> = {
  __resolveType: TypeResolveFn<'PrepaidCommitAutomatedInvoiceDeductionLedgerEntry' | 'PrepaidCommitCanceledLedgerEntry' | 'PrepaidCommitCreditedLedgerEntry' | 'PrepaidCommitExpirationLedgerEntry' | 'PrepaidCommitManualLedgerEntry' | 'PrepaidCommitRolloverLedgerEntry' | 'PrepaidCommitSegmentStartLedgerEntry', ParentType, ContextType>;
};

export type PrepaidCommitManualLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitManualLedgerEntry'] = ResolversParentTypes['PrepaidCommitManualLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitRolloverLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitRolloverLedgerEntry'] = ResolversParentTypes['PrepaidCommitRolloverLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  new_commit?: Resolver<ResolversTypes['PrepaidCommit'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCommitSegmentStartLedgerEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCommitSegmentStartLedgerEntry'] = ResolversParentTypes['PrepaidCommitSegmentStartLedgerEntry']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  segment?: Resolver<ResolversTypes['CommitSegmentScheduleItem'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCreditGrantResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCreditGrant'] = ResolversParentTypes['PrepaidCreditGrant']> = {
  AmountGrantedCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  AmountPaidCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  amount_granted?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_paid?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrepaidCreditGrantRechargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrepaidCreditGrantRechargeResponse'] = ResolversParentTypes['PrepaidCreditGrantRechargeResponse']> = {
  credit_grant?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType>;
  stripe_error_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrequelAuthTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrequelAuthTokenResponse'] = ResolversParentTypes['PrequelAuthTokenResponse']> = {
  token_string?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresentationGroupValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['PresentationGroupValue'] = ResolversParentTypes['PresentationGroupValue']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = {
  PricedProductPricingFactor?: Resolver<ResolversTypes['PricedProductPricingFactor'], ParentType, ContextType>;
  block_rounding_behavior?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_enum']>, ParentType, ContextType>;
  block_size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment'] = ResolversParentTypes['PriceAdjustment']> = {
  PriceAdjustmentTypeEnum?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum'], ParentType, ContextType>;
  PricedProductPricingFactorAdjustment?: Resolver<ResolversTypes['PricedProductPricingFactorAdjustment'], ParentType, ContextType>;
  adjustment_type_enum?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum'] = ResolversParentTypes['PriceAdjustmentTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum_aggregate'] = ResolversParentTypes['PriceAdjustmentTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum_aggregate_fields'] = ResolversParentTypes['PriceAdjustmentTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PriceAdjustmentTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum_max_fields'] = ResolversParentTypes['PriceAdjustmentTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum_min_fields'] = ResolversParentTypes['PriceAdjustmentTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustmentTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustmentTypeEnum_mutation_response'] = ResolversParentTypes['PriceAdjustmentTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_aggregate'] = ResolversParentTypes['PriceAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PriceAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PriceAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_aggregate_fields'] = ResolversParentTypes['PriceAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['PriceAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PriceAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PriceAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PriceAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PriceAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PriceAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PriceAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PriceAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PriceAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PriceAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PriceAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_avg_fields'] = ResolversParentTypes['PriceAdjustment_avg_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_max_fields'] = ResolversParentTypes['PriceAdjustment_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_min_fields'] = ResolversParentTypes['PriceAdjustment_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_adjustment_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_mutation_response'] = ResolversParentTypes['PriceAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PriceAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_stddev_fields'] = ResolversParentTypes['PriceAdjustment_stddev_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_stddev_pop_fields'] = ResolversParentTypes['PriceAdjustment_stddev_pop_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_stddev_samp_fields'] = ResolversParentTypes['PriceAdjustment_stddev_samp_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_sum_fields'] = ResolversParentTypes['PriceAdjustment_sum_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_var_pop_fields'] = ResolversParentTypes['PriceAdjustment_var_pop_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_var_samp_fields'] = ResolversParentTypes['PriceAdjustment_var_samp_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceAdjustment_variance_fields'] = ResolversParentTypes['PriceAdjustment_variance_fields']> = {
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_aggregate'] = ResolversParentTypes['Price_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Price_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_aggregate_fields'] = ResolversParentTypes['Price_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['Price_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Price_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Price_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Price_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['Price_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['Price_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['Price_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['Price_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['Price_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['Price_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['Price_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_avg_fields'] = ResolversParentTypes['Price_avg_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_max_fields'] = ResolversParentTypes['Price_max_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_min_fields'] = ResolversParentTypes['Price_min_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_mutation_response'] = ResolversParentTypes['Price_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Price']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_stddev_fields'] = ResolversParentTypes['Price_stddev_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_stddev_pop_fields'] = ResolversParentTypes['Price_stddev_pop_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_stddev_samp_fields'] = ResolversParentTypes['Price_stddev_samp_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_sum_fields'] = ResolversParentTypes['Price_sum_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_var_pop_fields'] = ResolversParentTypes['Price_var_pop_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_var_samp_fields'] = ResolversParentTypes['Price_var_samp_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Price_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price_variance_fields'] = ResolversParentTypes['Price_variance_fields']> = {
  block_size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  metric_minimum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct'] = ResolversParentTypes['PricedProduct']> = {
  CreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  PricedProductPricingFactors?: Resolver<Array<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, Partial<PricedProductPricedProductPricingFactorsArgs>>;
  PricedProductPricingFactors_aggregate?: Resolver<ResolversTypes['PricedProductPricingFactor_aggregate'], ParentType, ContextType, Partial<PricedProductPricedProductPricingFactors_AggregateArgs>>;
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment'] = ResolversParentTypes['PricedProductAdjustment']> = {
  CustomPricing?: Resolver<ResolversTypes['CustomPricing'], ParentType, ContextType>;
  PricedProduct?: Resolver<ResolversTypes['PricedProduct'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  custom_pricing_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  percent_adjustment?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priced_product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_aggregate'] = ResolversParentTypes['PricedProductAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_aggregate_fields'] = ResolversParentTypes['PricedProductAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PricedProductAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_avg_fields'] = ResolversParentTypes['PricedProductAdjustment_avg_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_max_fields'] = ResolversParentTypes['PricedProductAdjustment_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_min_fields'] = ResolversParentTypes['PricedProductAdjustment_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_mutation_response'] = ResolversParentTypes['PricedProductAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_stddev_fields'] = ResolversParentTypes['PricedProductAdjustment_stddev_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_stddev_pop_fields'] = ResolversParentTypes['PricedProductAdjustment_stddev_pop_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_stddev_samp_fields'] = ResolversParentTypes['PricedProductAdjustment_stddev_samp_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_sum_fields'] = ResolversParentTypes['PricedProductAdjustment_sum_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_var_pop_fields'] = ResolversParentTypes['PricedProductAdjustment_var_pop_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_var_samp_fields'] = ResolversParentTypes['PricedProductAdjustment_var_samp_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductAdjustment_variance_fields'] = ResolversParentTypes['PricedProductAdjustment_variance_fields']> = {
  percent_adjustment?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor'] = ResolversParentTypes['PricedProductPricingFactor']> = {
  CompositeCharges?: Resolver<Array<ResolversTypes['CompositeCharge']>, ParentType, ContextType, Partial<PricedProductPricingFactorCompositeChargesArgs>>;
  CompositeCharges_aggregate?: Resolver<ResolversTypes['CompositeCharge_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorCompositeCharges_AggregateArgs>>;
  FlatFee?: Resolver<Maybe<ResolversTypes['FlatFee']>, ParentType, ContextType>;
  FlatFees?: Resolver<Array<ResolversTypes['FlatFee']>, ParentType, ContextType, Partial<PricedProductPricingFactorFlatFeesArgs>>;
  FlatFees_aggregate?: Resolver<ResolversTypes['FlatFee_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorFlatFees_AggregateArgs>>;
  PricedProduct?: Resolver<ResolversTypes['PricedProduct'], ParentType, ContextType>;
  Prices?: Resolver<Array<ResolversTypes['Price']>, ParentType, ContextType, Partial<PricedProductPricingFactorPricesArgs>>;
  Prices_aggregate?: Resolver<ResolversTypes['Price_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorPrices_AggregateArgs>>;
  ProductPricingFactor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  SeatPrices?: Resolver<Array<ResolversTypes['SeatPrice']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priced_product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  skip_ramp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tier_reset_frequency?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tiering_mode?: Resolver<ResolversTypes['TieringModeEnum_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment'] = ResolversParentTypes['PricedProductPricingFactorAdjustment']> = {
  CompositeChargeAdjustments?: Resolver<Array<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentCompositeChargeAdjustmentsArgs>>;
  CompositeChargeAdjustments_aggregate?: Resolver<ResolversTypes['CompositeChargeAdjustment_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentCompositeChargeAdjustments_AggregateArgs>>;
  CustomPricing?: Resolver<ResolversTypes['CustomPricing'], ParentType, ContextType>;
  FlatFeeAdjustments?: Resolver<Array<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentFlatFeeAdjustmentsArgs>>;
  FlatFeeAdjustments_aggregate?: Resolver<ResolversTypes['FlatFeeAdjustment_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentFlatFeeAdjustments_AggregateArgs>>;
  PriceAdjustments?: Resolver<Array<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentPriceAdjustmentsArgs>>;
  PriceAdjustments_aggregate?: Resolver<ResolversTypes['PriceAdjustment_aggregate'], ParentType, ContextType, Partial<PricedProductPricingFactorAdjustmentPriceAdjustments_AggregateArgs>>;
  PricedProduct?: Resolver<ResolversTypes['PricedProduct'], ParentType, ContextType>;
  ProductPricingFactor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  custom_pricing_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priced_product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start_period?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_aggregate'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_aggregate_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PricedProductPricingFactorAdjustment_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_avg_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_avg_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_max_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_max_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_min_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_min_fields']> = {
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  custom_pricing_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_mutation_response'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_pop_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_samp_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_stddev_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_sum_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_sum_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_var_pop_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_var_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_var_samp_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_var_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactorAdjustment_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactorAdjustment_variance_fields'] = ResolversParentTypes['PricedProductPricingFactorAdjustment_variance_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_aggregate'] = ResolversParentTypes['PricedProductPricingFactor_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_aggregate_fields'] = ResolversParentTypes['PricedProductPricingFactor_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PricedProductPricingFactor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_avg_fields'] = ResolversParentTypes['PricedProductPricingFactor_avg_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_max_fields'] = ResolversParentTypes['PricedProductPricingFactor_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_min_fields'] = ResolversParentTypes['PricedProductPricingFactor_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priced_product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_pricing_factor_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_mutation_response'] = ResolversParentTypes['PricedProductPricingFactor_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_stddev_fields'] = ResolversParentTypes['PricedProductPricingFactor_stddev_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_stddev_pop_fields'] = ResolversParentTypes['PricedProductPricingFactor_stddev_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_stddev_samp_fields'] = ResolversParentTypes['PricedProductPricingFactor_stddev_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_sum_fields'] = ResolversParentTypes['PricedProductPricingFactor_sum_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_var_pop_fields'] = ResolversParentTypes['PricedProductPricingFactor_var_pop_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_var_samp_fields'] = ResolversParentTypes['PricedProductPricingFactor_var_samp_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProductPricingFactor_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProductPricingFactor_variance_fields'] = ResolversParentTypes['PricedProductPricingFactor_variance_fields']> = {
  start_period?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tier_reset_frequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_aggregate'] = ResolversParentTypes['PricedProduct_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['PricedProduct_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['PricedProduct']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_aggregate_fields'] = ResolversParentTypes['PricedProduct_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['PricedProduct_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PricedProduct_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['PricedProduct_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['PricedProduct_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['PricedProduct_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['PricedProduct_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['PricedProduct_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['PricedProduct_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['PricedProduct_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['PricedProduct_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['PricedProduct_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_avg_fields'] = ResolversParentTypes['PricedProduct_avg_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_max_fields'] = ResolversParentTypes['PricedProduct_max_fields']> = {
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_min_fields'] = ResolversParentTypes['PricedProduct_min_fields']> = {
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_mutation_response'] = ResolversParentTypes['PricedProduct_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['PricedProduct']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_stddev_fields'] = ResolversParentTypes['PricedProduct_stddev_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_stddev_pop_fields'] = ResolversParentTypes['PricedProduct_stddev_pop_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_stddev_samp_fields'] = ResolversParentTypes['PricedProduct_stddev_samp_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_sum_fields'] = ResolversParentTypes['PricedProduct_sum_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_var_pop_fields'] = ResolversParentTypes['PricedProduct_var_pop_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_var_samp_fields'] = ResolversParentTypes['PricedProduct_var_samp_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricedProduct_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricedProduct_variance_fields'] = ResolversParentTypes['PricedProduct_variance_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricingGroupValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricingGroupValue'] = ResolversParentTypes['PricingGroupValue']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricingSchemeIdentifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['PricingSchemeIdentifier'] = ResolversParentTypes['PricingSchemeIdentifier']> = {
  billable_metric_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProService'] = ResolversParentTypes['ProService']> = {
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType>;
  maxAmount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  max_amount?: Resolver<Maybe<ResolversTypes['CreditAmount']>, ParentType, ContextType>;
  netsuite_overage_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['ProServiceProductListItem']>, ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField'] = ResolversParentTypes['ProServiceManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pro_service_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField_aggregate'] = ResolversParentTypes['ProServiceManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField_aggregate_fields'] = ResolversParentTypes['ProServiceManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ProServiceManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProServiceManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField_max_fields'] = ResolversParentTypes['ProServiceManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  pro_service_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField_min_fields'] = ResolversParentTypes['ProServiceManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  pro_service_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceManagedField_mutation_response'] = ResolversParentTypes['ProServiceManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceProductListItem'] = ResolversParentTypes['ProServiceProductListItem']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  current?: Resolver<ResolversTypes['ProServiceProductListItemState'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial?: Resolver<ResolversTypes['ProServiceProductListItemState'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['ProductListItemUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProServiceProductListItemStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProServiceProductListItemState'] = ResolversParentTypes['ProServiceProductListItemState']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  is_refundable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  Actor?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  PricedProducts?: Resolver<Array<ResolversTypes['PricedProduct']>, ParentType, ContextType, Partial<ProductPricedProductsArgs>>;
  PricedProducts_aggregate?: Resolver<ResolversTypes['PricedProduct_aggregate'], ParentType, ContextType, Partial<ProductPricedProducts_AggregateArgs>>;
  ProductManagedFields?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType, Partial<ProductProductManagedFieldsArgs>>;
  ProductManagedFields_aggregate?: Resolver<ResolversTypes['ProductManagedField_aggregate'], ParentType, ContextType, Partial<ProductProductManagedFields_AggregateArgs>>;
  ProductPricingFactors?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, Partial<ProductProductPricingFactorsArgs>>;
  ProductPricingFactors_aggregate?: Resolver<ResolversTypes['ProductPricingFactor_aggregate'], ParentType, ContextType, Partial<ProductProductPricingFactors_AggregateArgs>>;
  active_customers_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active_customers_plan_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active_plan_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<ProductPlansArgs, 'type'>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductChargeLineItem'] = ResolversParentTypes['ProductChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  sub_line_items?: Resolver<Array<ResolversTypes['ProductChargeSubLineItem']>, ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductChargeSubLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductChargeSubLineItem'] = ResolversParentTypes['ProductChargeSubLineItem']> = {
  __resolveType: TypeResolveFn<'AdvanceChargeLineItem' | 'ChargeLineItem' | 'CompositeChargeLineItem' | 'SeatChargeLineItem', ParentType, ContextType>;
};

export type ProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductListItem'] = ResolversParentTypes['ProductListItem']> = {
  __resolveType: TypeResolveFn<'CompositeProductListItem' | 'FixedProductListItem' | 'ProServiceProductListItem' | 'SubscriptionProductListItem' | 'UsageProductListItem', ParentType, ContextType>;
};

export type ProductListItemUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductListItemUpdate'] = ResolversParentTypes['ProductListItemUpdate']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  billable_metric?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType>;
  composite_products?: Resolver<Maybe<Array<ResolversTypes['NonCompositeProductListItem']>>, ParentType, ContextType>;
  composite_tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  exclude_free_usage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  is_refundable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_overage_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  presentation_group_key?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  pricing_group_key?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  quantity_conversion?: Resolver<Maybe<ResolversTypes['QuantityConversion']>, ParentType, ContextType>;
  quantity_conversion_update?: Resolver<Maybe<ResolversTypes['QuantityConversionUpdate']>, ParentType, ContextType>;
  quantity_rounding?: Resolver<Maybe<ResolversTypes['QuantityRounding']>, ParentType, ContextType>;
  quantity_rounding_update?: Resolver<Maybe<ResolversTypes['QuantityRoundingUpdate']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField'] = ResolversParentTypes['ProductManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField_aggregate'] = ResolversParentTypes['ProductManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ProductManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField_aggregate_fields'] = ResolversParentTypes['ProductManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ProductManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ProductManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProductManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField_max_fields'] = ResolversParentTypes['ProductManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField_min_fields'] = ResolversParentTypes['ProductManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductManagedField_mutation_response'] = ResolversParentTypes['ProductManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor'] = ResolversParentTypes['ProductPricingFactor']> = {
  BillableMetric?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType>;
  ChargeManagedFields?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, Partial<ProductPricingFactorChargeManagedFieldsArgs>>;
  ChargeManagedFields_aggregate?: Resolver<ResolversTypes['ChargeManagedField_aggregate'], ParentType, ContextType, Partial<ProductPricingFactorChargeManagedFields_AggregateArgs>>;
  ChargeTypeEnum?: Resolver<ResolversTypes['ChargeTypeEnum'], ParentType, ContextType>;
  CompositeChargePricingFactors?: Resolver<Array<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType, Partial<ProductPricingFactorCompositeChargePricingFactorsArgs>>;
  CompositeChargePricingFactors_aggregate?: Resolver<ResolversTypes['CompositeChargePricingFactor_aggregate'], ParentType, ContextType, Partial<ProductPricingFactorCompositeChargePricingFactors_AggregateArgs>>;
  PricedProductPricingFactors?: Resolver<Array<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, Partial<ProductPricingFactorPricedProductPricingFactorsArgs>>;
  PricedProductPricingFactors_aggregate?: Resolver<ResolversTypes['PricedProductPricingFactor_aggregate'], ParentType, ContextType, Partial<ProductPricingFactorPricedProductPricingFactors_AggregateArgs>>;
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  charge_type_enum?: Resolver<ResolversTypes['ChargeTypeEnum_enum'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  seat_metric?: Resolver<Maybe<ResolversTypes['SeatMetric']>, ParentType, ContextType>;
  seat_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_aggregate'] = ResolversParentTypes['ProductPricingFactor_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_aggregate_fields'] = ResolversParentTypes['ProductPricingFactor_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ProductPricingFactor_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['ProductPricingFactor_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_avg_fields'] = ResolversParentTypes['ProductPricingFactor_avg_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_max_fields'] = ResolversParentTypes['ProductPricingFactor_max_fields']> = {
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  seat_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_min_fields'] = ResolversParentTypes['ProductPricingFactor_min_fields']> = {
  billable_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  seat_metric_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_mutation_response'] = ResolversParentTypes['ProductPricingFactor_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_stddev_fields'] = ResolversParentTypes['ProductPricingFactor_stddev_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_stddev_pop_fields'] = ResolversParentTypes['ProductPricingFactor_stddev_pop_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_stddev_samp_fields'] = ResolversParentTypes['ProductPricingFactor_stddev_samp_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_sum_fields'] = ResolversParentTypes['ProductPricingFactor_sum_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_var_pop_fields'] = ResolversParentTypes['ProductPricingFactor_var_pop_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_var_samp_fields'] = ResolversParentTypes['ProductPricingFactor_var_samp_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPricingFactor_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPricingFactor_variance_fields'] = ResolversParentTypes['ProductPricingFactor_variance_fields']> = {
  ordering?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Product_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_aggregate'] = ResolversParentTypes['Product_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Product_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Product_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_aggregate_fields'] = ResolversParentTypes['Product_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Product_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Product_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Product_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Product_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_max_fields'] = ResolversParentTypes['Product_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Product_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_min_fields'] = ResolversParentTypes['Product_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group_key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Product_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product_mutation_response'] = ResolversParentTypes['Product_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductsAndRateCardsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductsAndRateCards'] = ResolversParentTypes['ProductsAndRateCards']> = {
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType, RequireFields<ProductsAndRateCardsProductArgs, 'id'>>;
  products?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType, Partial<ProductsAndRateCardsProductsArgs>>;
  rate_card?: Resolver<ResolversTypes['RateCard'], ParentType, ContextType, RequireFields<ProductsAndRateCardsRate_CardArgs, 'id'>>;
  rate_cards?: Resolver<Array<ResolversTypes['RateCard']>, ParentType, ContextType, Partial<ProductsAndRateCardsRate_CardsArgs>>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityConversionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityConversion'] = ResolversParentTypes['QuantityConversion']> = {
  conversion_factor?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<ResolversTypes['ConversionOperation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityConversionUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityConversionUpdate'] = ResolversParentTypes['QuantityConversionUpdate']> = {
  is_undefined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['QuantityConversion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityRoundingResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityRounding'] = ResolversParentTypes['QuantityRounding']> = {
  decimal_places?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rounding_method?: Resolver<ResolversTypes['RoundingMethod'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuantityRoundingUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuantityRoundingUpdate'] = ResolversParentTypes['QuantityRoundingUpdate']> = {
  is_undefined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['QuantityRounding']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  AccountTypeEnum?: Resolver<Array<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType, Partial<QueryAccountTypeEnumArgs>>;
  AccountTypeEnum_aggregate?: Resolver<ResolversTypes['AccountTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryAccountTypeEnum_AggregateArgs>>;
  AccountTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AccountTypeEnum']>, ParentType, ContextType, RequireFields<QueryAccountTypeEnum_By_PkArgs, 'value'>>;
  Actor?: Resolver<Array<ResolversTypes['Actor']>, ParentType, ContextType, Partial<QueryActorArgs>>;
  Actor_aggregate?: Resolver<ResolversTypes['Actor_aggregate'], ParentType, ContextType, Partial<QueryActor_AggregateArgs>>;
  Actor_by_pk?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType, RequireFields<QueryActor_By_PkArgs, 'id'>>;
  Alert?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType, Partial<QueryAlertArgs>>;
  AlertManagedField?: Resolver<Array<ResolversTypes['AlertManagedField']>, ParentType, ContextType, Partial<QueryAlertManagedFieldArgs>>;
  AlertManagedField_aggregate?: Resolver<ResolversTypes['AlertManagedField_aggregate'], ParentType, ContextType, Partial<QueryAlertManagedField_AggregateArgs>>;
  AlertManagedField_by_pk?: Resolver<Maybe<ResolversTypes['AlertManagedField']>, ParentType, ContextType, RequireFields<QueryAlertManagedField_By_PkArgs, 'id'>>;
  AlertTypeEnum?: Resolver<Array<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType, Partial<QueryAlertTypeEnumArgs>>;
  AlertTypeEnum_aggregate?: Resolver<ResolversTypes['AlertTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryAlertTypeEnum_AggregateArgs>>;
  AlertTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['AlertTypeEnum']>, ParentType, ContextType, RequireFields<QueryAlertTypeEnum_By_PkArgs, 'value'>>;
  Alert_aggregate?: Resolver<ResolversTypes['Alert_aggregate'], ParentType, ContextType, Partial<QueryAlert_AggregateArgs>>;
  Alert_by_pk?: Resolver<Maybe<ResolversTypes['Alert']>, ParentType, ContextType, RequireFields<QueryAlert_By_PkArgs, 'id'>>;
  BillableMetric?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType, Partial<QueryBillableMetricArgs>>;
  BillableMetricManagedField?: Resolver<Array<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType, Partial<QueryBillableMetricManagedFieldArgs>>;
  BillableMetricManagedField_aggregate?: Resolver<ResolversTypes['BillableMetricManagedField_aggregate'], ParentType, ContextType, Partial<QueryBillableMetricManagedField_AggregateArgs>>;
  BillableMetricManagedField_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetricManagedField']>, ParentType, ContextType, RequireFields<QueryBillableMetricManagedField_By_PkArgs, 'id'>>;
  BillableMetric_aggregate?: Resolver<ResolversTypes['BillableMetric_aggregate'], ParentType, ContextType, Partial<QueryBillableMetric_AggregateArgs>>;
  BillableMetric_by_pk?: Resolver<Maybe<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<QueryBillableMetric_By_PkArgs, 'id'>>;
  BillingFrequencyEnum?: Resolver<Array<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType, Partial<QueryBillingFrequencyEnumArgs>>;
  BillingFrequencyEnum_aggregate?: Resolver<ResolversTypes['BillingFrequencyEnum_aggregate'], ParentType, ContextType, Partial<QueryBillingFrequencyEnum_AggregateArgs>>;
  BillingFrequencyEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingFrequencyEnum']>, ParentType, ContextType, RequireFields<QueryBillingFrequencyEnum_By_PkArgs, 'value'>>;
  BillingMetricAggregateEnum?: Resolver<Array<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType, Partial<QueryBillingMetricAggregateEnumArgs>>;
  BillingMetricAggregateEnum_aggregate?: Resolver<ResolversTypes['BillingMetricAggregateEnum_aggregate'], ParentType, ContextType, Partial<QueryBillingMetricAggregateEnum_AggregateArgs>>;
  BillingMetricAggregateEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingMetricAggregateEnum']>, ParentType, ContextType, RequireFields<QueryBillingMetricAggregateEnum_By_PkArgs, 'value'>>;
  BillingProviderCustomer?: Resolver<Array<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, Partial<QueryBillingProviderCustomerArgs>>;
  BillingProviderCustomer_aggregate?: Resolver<ResolversTypes['BillingProviderCustomer_aggregate'], ParentType, ContextType, Partial<QueryBillingProviderCustomer_AggregateArgs>>;
  BillingProviderCustomer_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderCustomer']>, ParentType, ContextType, RequireFields<QueryBillingProviderCustomer_By_PkArgs, 'billing_provider' | 'customer_id'>>;
  BillingProviderEnum?: Resolver<Array<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType, Partial<QueryBillingProviderEnumArgs>>;
  BillingProviderEnum_aggregate?: Resolver<ResolversTypes['BillingProviderEnum_aggregate'], ParentType, ContextType, Partial<QueryBillingProviderEnum_AggregateArgs>>;
  BillingProviderEnum_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderEnum']>, ParentType, ContextType, RequireFields<QueryBillingProviderEnum_By_PkArgs, 'value'>>;
  BillingProviderInvoice?: Resolver<Array<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType, Partial<QueryBillingProviderInvoiceArgs>>;
  BillingProviderInvoice_aggregate?: Resolver<ResolversTypes['BillingProviderInvoice_aggregate'], ParentType, ContextType, Partial<QueryBillingProviderInvoice_AggregateArgs>>;
  BillingProviderInvoice_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType, RequireFields<QueryBillingProviderInvoice_By_PkArgs, 'id'>>;
  BillingProviderToken?: Resolver<Array<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, Partial<QueryBillingProviderTokenArgs>>;
  BillingProviderToken_aggregate?: Resolver<ResolversTypes['BillingProviderToken_aggregate'], ParentType, ContextType, Partial<QueryBillingProviderToken_AggregateArgs>>;
  BillingProviderToken_by_pk?: Resolver<Maybe<ResolversTypes['BillingProviderToken']>, ParentType, ContextType, RequireFields<QueryBillingProviderToken_By_PkArgs, 'billing_provider' | 'client_id' | 'environment_type'>>;
  ChargeManagedField?: Resolver<Array<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, Partial<QueryChargeManagedFieldArgs>>;
  ChargeManagedField_aggregate?: Resolver<ResolversTypes['ChargeManagedField_aggregate'], ParentType, ContextType, Partial<QueryChargeManagedField_AggregateArgs>>;
  ChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ChargeManagedField']>, ParentType, ContextType, RequireFields<QueryChargeManagedField_By_PkArgs, 'id'>>;
  ChargeTypeEnum?: Resolver<Array<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType, Partial<QueryChargeTypeEnumArgs>>;
  ChargeTypeEnum_aggregate?: Resolver<ResolversTypes['ChargeTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryChargeTypeEnum_AggregateArgs>>;
  ChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['ChargeTypeEnum']>, ParentType, ContextType, RequireFields<QueryChargeTypeEnum_By_PkArgs, 'value'>>;
  Client?: Resolver<Array<ResolversTypes['Client']>, ParentType, ContextType, Partial<QueryClientArgs>>;
  ClientConfig?: Resolver<Array<ResolversTypes['ClientConfig']>, ParentType, ContextType, Partial<QueryClientConfigArgs>>;
  ClientConfigKeyEnum?: Resolver<Array<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType, Partial<QueryClientConfigKeyEnumArgs>>;
  ClientConfigKeyEnum_aggregate?: Resolver<ResolversTypes['ClientConfigKeyEnum_aggregate'], ParentType, ContextType, Partial<QueryClientConfigKeyEnum_AggregateArgs>>;
  ClientConfigKeyEnum_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfigKeyEnum']>, ParentType, ContextType, RequireFields<QueryClientConfigKeyEnum_By_PkArgs, 'value'>>;
  ClientConfig_aggregate?: Resolver<ResolversTypes['ClientConfig_aggregate'], ParentType, ContextType, Partial<QueryClientConfig_AggregateArgs>>;
  ClientConfig_by_pk?: Resolver<Maybe<ResolversTypes['ClientConfig']>, ParentType, ContextType, RequireFields<QueryClientConfig_By_PkArgs, 'id'>>;
  Client_aggregate?: Resolver<ResolversTypes['Client_aggregate'], ParentType, ContextType, Partial<QueryClient_AggregateArgs>>;
  Client_by_pk?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, RequireFields<QueryClient_By_PkArgs, 'id'>>;
  CollectionScheduleEnum?: Resolver<Array<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType, Partial<QueryCollectionScheduleEnumArgs>>;
  CollectionScheduleEnum_aggregate?: Resolver<ResolversTypes['CollectionScheduleEnum_aggregate'], ParentType, ContextType, Partial<QueryCollectionScheduleEnum_AggregateArgs>>;
  CollectionScheduleEnum_by_pk?: Resolver<Maybe<ResolversTypes['CollectionScheduleEnum']>, ParentType, ContextType, RequireFields<QueryCollectionScheduleEnum_By_PkArgs, 'value'>>;
  CommitManagedField?: Resolver<Array<ResolversTypes['CommitManagedField']>, ParentType, ContextType, Partial<QueryCommitManagedFieldArgs>>;
  CommitManagedField_aggregate?: Resolver<ResolversTypes['CommitManagedField_aggregate'], ParentType, ContextType, Partial<QueryCommitManagedField_AggregateArgs>>;
  CommitManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CommitManagedField']>, ParentType, ContextType, RequireFields<QueryCommitManagedField_By_PkArgs, 'id'>>;
  CompositeCharge?: Resolver<Array<ResolversTypes['CompositeCharge']>, ParentType, ContextType, Partial<QueryCompositeChargeArgs>>;
  CompositeChargeAdjustment?: Resolver<Array<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, Partial<QueryCompositeChargeAdjustmentArgs>>;
  CompositeChargeAdjustment_aggregate?: Resolver<ResolversTypes['CompositeChargeAdjustment_aggregate'], ParentType, ContextType, Partial<QueryCompositeChargeAdjustment_AggregateArgs>>;
  CompositeChargeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeAdjustment']>, ParentType, ContextType, RequireFields<QueryCompositeChargeAdjustment_By_PkArgs, 'id'>>;
  CompositeChargePricingFactor?: Resolver<Array<ResolversTypes['CompositeChargePricingFactor']>, ParentType, ContextType, Partial<QueryCompositeChargePricingFactorArgs>>;
  CompositeChargePricingFactor_aggregate?: Resolver<ResolversTypes['CompositeChargePricingFactor_aggregate'], ParentType, ContextType, Partial<QueryCompositeChargePricingFactor_AggregateArgs>>;
  CompositeChargeTier?: Resolver<Array<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, Partial<QueryCompositeChargeTierArgs>>;
  CompositeChargeTierAdjustment?: Resolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, Partial<QueryCompositeChargeTierAdjustmentArgs>>;
  CompositeChargeTierAdjustment_aggregate?: Resolver<ResolversTypes['CompositeChargeTierAdjustment_aggregate'], ParentType, ContextType, Partial<QueryCompositeChargeTierAdjustment_AggregateArgs>>;
  CompositeChargeTierAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment']>, ParentType, ContextType, RequireFields<QueryCompositeChargeTierAdjustment_By_PkArgs, 'id'>>;
  CompositeChargeTier_aggregate?: Resolver<ResolversTypes['CompositeChargeTier_aggregate'], ParentType, ContextType, Partial<QueryCompositeChargeTier_AggregateArgs>>;
  CompositeChargeTier_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTier']>, ParentType, ContextType, RequireFields<QueryCompositeChargeTier_By_PkArgs, 'id'>>;
  CompositeChargeTypeEnum?: Resolver<Array<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType, Partial<QueryCompositeChargeTypeEnumArgs>>;
  CompositeChargeTypeEnum_aggregate?: Resolver<ResolversTypes['CompositeChargeTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryCompositeChargeTypeEnum_AggregateArgs>>;
  CompositeChargeTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['CompositeChargeTypeEnum']>, ParentType, ContextType, RequireFields<QueryCompositeChargeTypeEnum_By_PkArgs, 'value'>>;
  CompositeCharge_aggregate?: Resolver<ResolversTypes['CompositeCharge_aggregate'], ParentType, ContextType, Partial<QueryCompositeCharge_AggregateArgs>>;
  CompositeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CompositeCharge']>, ParentType, ContextType, RequireFields<QueryCompositeCharge_By_PkArgs, 'id'>>;
  ContractCreditManagedField?: Resolver<Array<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, Partial<QueryContractCreditManagedFieldArgs>>;
  ContractCreditManagedField_aggregate?: Resolver<ResolversTypes['ContractCreditManagedField_aggregate'], ParentType, ContextType, Partial<QueryContractCreditManagedField_AggregateArgs>>;
  ContractCreditManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractCreditManagedField']>, ParentType, ContextType, RequireFields<QueryContractCreditManagedField_By_PkArgs, 'id'>>;
  ContractManagedField?: Resolver<Array<ResolversTypes['ContractManagedField']>, ParentType, ContextType, Partial<QueryContractManagedFieldArgs>>;
  ContractManagedField_aggregate?: Resolver<ResolversTypes['ContractManagedField_aggregate'], ParentType, ContextType, Partial<QueryContractManagedField_AggregateArgs>>;
  ContractManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractManagedField']>, ParentType, ContextType, RequireFields<QueryContractManagedField_By_PkArgs, 'id'>>;
  ContractProductManagedField?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, Partial<QueryContractProductManagedFieldArgs>>;
  ContractProductManagedField_aggregate?: Resolver<ResolversTypes['ContractProductManagedField_aggregate'], ParentType, ContextType, Partial<QueryContractProductManagedField_AggregateArgs>>;
  ContractProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType, RequireFields<QueryContractProductManagedField_By_PkArgs, 'id'>>;
  CreditAdjustment?: Resolver<Array<ResolversTypes['CreditAdjustment']>, ParentType, ContextType, Partial<QueryCreditAdjustmentArgs>>;
  CreditAdjustment_aggregate?: Resolver<ResolversTypes['CreditAdjustment_aggregate'], ParentType, ContextType, Partial<QueryCreditAdjustment_AggregateArgs>>;
  CreditAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditAdjustment']>, ParentType, ContextType, RequireFields<QueryCreditAdjustment_By_PkArgs, 'id'>>;
  CreditGrant?: Resolver<Array<ResolversTypes['CreditGrant']>, ParentType, ContextType, Partial<QueryCreditGrantArgs>>;
  CreditGrantDeduction?: Resolver<Array<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, Partial<QueryCreditGrantDeductionArgs>>;
  CreditGrantDeduction_aggregate?: Resolver<ResolversTypes['CreditGrantDeduction_aggregate'], ParentType, ContextType, Partial<QueryCreditGrantDeduction_AggregateArgs>>;
  CreditGrantDeduction_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantDeduction']>, ParentType, ContextType, RequireFields<QueryCreditGrantDeduction_By_PkArgs, 'id'>>;
  CreditGrantHistory?: Resolver<Array<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType, Partial<QueryCreditGrantHistoryArgs>>;
  CreditGrantHistory_aggregate?: Resolver<ResolversTypes['CreditGrantHistory_aggregate'], ParentType, ContextType, Partial<QueryCreditGrantHistory_AggregateArgs>>;
  CreditGrantHistory_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantHistory']>, ParentType, ContextType, RequireFields<QueryCreditGrantHistory_By_PkArgs, 'id'>>;
  CreditGrantManagedField?: Resolver<Array<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, Partial<QueryCreditGrantManagedFieldArgs>>;
  CreditGrantManagedField_aggregate?: Resolver<ResolversTypes['CreditGrantManagedField_aggregate'], ParentType, ContextType, Partial<QueryCreditGrantManagedField_AggregateArgs>>;
  CreditGrantManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrantManagedField']>, ParentType, ContextType, RequireFields<QueryCreditGrantManagedField_By_PkArgs, 'id'>>;
  CreditGrant_aggregate?: Resolver<ResolversTypes['CreditGrant_aggregate'], ParentType, ContextType, Partial<QueryCreditGrant_AggregateArgs>>;
  CreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType, RequireFields<QueryCreditGrant_By_PkArgs, 'id'>>;
  CreditType?: Resolver<Array<ResolversTypes['CreditType']>, ParentType, ContextType, Partial<QueryCreditTypeArgs>>;
  CreditTypeConversion?: Resolver<Array<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, Partial<QueryCreditTypeConversionArgs>>;
  CreditTypeConversionAdjustment?: Resolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, Partial<QueryCreditTypeConversionAdjustmentArgs>>;
  CreditTypeConversionAdjustment_aggregate?: Resolver<ResolversTypes['CreditTypeConversionAdjustment_aggregate'], ParentType, ContextType, Partial<QueryCreditTypeConversionAdjustment_AggregateArgs>>;
  CreditTypeConversionAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment']>, ParentType, ContextType, RequireFields<QueryCreditTypeConversionAdjustment_By_PkArgs, 'id'>>;
  CreditTypeConversion_aggregate?: Resolver<ResolversTypes['CreditTypeConversion_aggregate'], ParentType, ContextType, Partial<QueryCreditTypeConversion_AggregateArgs>>;
  CreditTypeConversion_by_pk?: Resolver<Maybe<ResolversTypes['CreditTypeConversion']>, ParentType, ContextType, RequireFields<QueryCreditTypeConversion_By_PkArgs, 'id'>>;
  CreditType_aggregate?: Resolver<ResolversTypes['CreditType_aggregate'], ParentType, ContextType, Partial<QueryCreditType_AggregateArgs>>;
  CreditType_by_pk?: Resolver<Maybe<ResolversTypes['CreditType']>, ParentType, ContextType, RequireFields<QueryCreditType_By_PkArgs, 'id'>>;
  CustomPricing?: Resolver<Array<ResolversTypes['CustomPricing']>, ParentType, ContextType, Partial<QueryCustomPricingArgs>>;
  CustomPricing_aggregate?: Resolver<ResolversTypes['CustomPricing_aggregate'], ParentType, ContextType, Partial<QueryCustomPricing_AggregateArgs>>;
  CustomPricing_by_pk?: Resolver<Maybe<ResolversTypes['CustomPricing']>, ParentType, ContextType, RequireFields<QueryCustomPricing_By_PkArgs, 'id'>>;
  Customer?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, Partial<QueryCustomerArgs>>;
  CustomerAlert?: Resolver<Array<ResolversTypes['CustomerAlert']>, ParentType, ContextType, Partial<QueryCustomerAlertArgs>>;
  CustomerAlertHistory?: Resolver<Array<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, Partial<QueryCustomerAlertHistoryArgs>>;
  CustomerAlertHistory_aggregate?: Resolver<ResolversTypes['CustomerAlertHistory_aggregate'], ParentType, ContextType, Partial<QueryCustomerAlertHistory_AggregateArgs>>;
  CustomerAlertHistory_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertHistory']>, ParentType, ContextType, RequireFields<QueryCustomerAlertHistory_By_PkArgs, 'id'>>;
  CustomerAlertStatusEnum?: Resolver<Array<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType, Partial<QueryCustomerAlertStatusEnumArgs>>;
  CustomerAlertStatusEnum_aggregate?: Resolver<ResolversTypes['CustomerAlertStatusEnum_aggregate'], ParentType, ContextType, Partial<QueryCustomerAlertStatusEnum_AggregateArgs>>;
  CustomerAlertStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlertStatusEnum']>, ParentType, ContextType, RequireFields<QueryCustomerAlertStatusEnum_By_PkArgs, 'value'>>;
  CustomerAlert_aggregate?: Resolver<ResolversTypes['CustomerAlert_aggregate'], ParentType, ContextType, Partial<QueryCustomerAlert_AggregateArgs>>;
  CustomerAlert_by_pk?: Resolver<Maybe<ResolversTypes['CustomerAlert']>, ParentType, ContextType, RequireFields<QueryCustomerAlert_By_PkArgs, 'id'>>;
  CustomerConfig?: Resolver<Array<ResolversTypes['CustomerConfig']>, ParentType, ContextType, Partial<QueryCustomerConfigArgs>>;
  CustomerConfig_aggregate?: Resolver<ResolversTypes['CustomerConfig_aggregate'], ParentType, ContextType, Partial<QueryCustomerConfig_AggregateArgs>>;
  CustomerConfig_by_pk?: Resolver<Maybe<ResolversTypes['CustomerConfig']>, ParentType, ContextType, RequireFields<QueryCustomerConfig_By_PkArgs, 'id'>>;
  CustomerIngestAlias?: Resolver<Array<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, Partial<QueryCustomerIngestAliasArgs>>;
  CustomerIngestAlias_aggregate?: Resolver<ResolversTypes['CustomerIngestAlias_aggregate'], ParentType, ContextType, Partial<QueryCustomerIngestAlias_AggregateArgs>>;
  CustomerIngestAlias_by_pk?: Resolver<Maybe<ResolversTypes['CustomerIngestAlias']>, ParentType, ContextType, RequireFields<QueryCustomerIngestAlias_By_PkArgs, 'client_id' | 'customer_id' | 'ingest_alias'>>;
  CustomerManagedField?: Resolver<Array<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, Partial<QueryCustomerManagedFieldArgs>>;
  CustomerManagedField_aggregate?: Resolver<ResolversTypes['CustomerManagedField_aggregate'], ParentType, ContextType, Partial<QueryCustomerManagedField_AggregateArgs>>;
  CustomerManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerManagedField']>, ParentType, ContextType, RequireFields<QueryCustomerManagedField_By_PkArgs, 'id'>>;
  CustomerPlan?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType, Partial<QueryCustomerPlanArgs>>;
  CustomerPlanAdjustment?: Resolver<Array<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, Partial<QueryCustomerPlanAdjustmentArgs>>;
  CustomerPlanAdjustment_aggregate?: Resolver<ResolversTypes['CustomerPlanAdjustment_aggregate'], ParentType, ContextType, Partial<QueryCustomerPlanAdjustment_AggregateArgs>>;
  CustomerPlanAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanAdjustment']>, ParentType, ContextType, RequireFields<QueryCustomerPlanAdjustment_By_PkArgs, 'id'>>;
  CustomerPlanManagedField?: Resolver<Array<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, Partial<QueryCustomerPlanManagedFieldArgs>>;
  CustomerPlanManagedField_aggregate?: Resolver<ResolversTypes['CustomerPlanManagedField_aggregate'], ParentType, ContextType, Partial<QueryCustomerPlanManagedField_AggregateArgs>>;
  CustomerPlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanManagedField']>, ParentType, ContextType, RequireFields<QueryCustomerPlanManagedField_By_PkArgs, 'id'>>;
  CustomerPlanOneTimeCharge?: Resolver<Array<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType, Partial<QueryCustomerPlanOneTimeChargeArgs>>;
  CustomerPlanOneTimeCharge_aggregate?: Resolver<ResolversTypes['CustomerPlanOneTimeCharge_aggregate'], ParentType, ContextType, Partial<QueryCustomerPlanOneTimeCharge_AggregateArgs>>;
  CustomerPlanOneTimeCharge_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge']>, ParentType, ContextType, RequireFields<QueryCustomerPlanOneTimeCharge_By_PkArgs, 'id'>>;
  CustomerPlan_aggregate?: Resolver<ResolversTypes['CustomerPlan_aggregate'], ParentType, ContextType, Partial<QueryCustomerPlan_AggregateArgs>>;
  CustomerPlan_by_pk?: Resolver<Maybe<ResolversTypes['CustomerPlan']>, ParentType, ContextType, RequireFields<QueryCustomerPlan_By_PkArgs, 'id'>>;
  CustomerRelationship?: Resolver<Array<ResolversTypes['CustomerRelationship']>, ParentType, ContextType, Partial<QueryCustomerRelationshipArgs>>;
  CustomerRelationship_aggregate?: Resolver<ResolversTypes['CustomerRelationship_aggregate'], ParentType, ContextType, Partial<QueryCustomerRelationship_AggregateArgs>>;
  CustomerRelationship_by_pk?: Resolver<Maybe<ResolversTypes['CustomerRelationship']>, ParentType, ContextType, RequireFields<QueryCustomerRelationship_By_PkArgs, 'id'>>;
  Customer_aggregate?: Resolver<ResolversTypes['Customer_aggregate'], ParentType, ContextType, Partial<QueryCustomer_AggregateArgs>>;
  Customer_by_pk?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryCustomer_By_PkArgs, 'id'>>;
  DraftPlan?: Resolver<Array<ResolversTypes['DraftPlan']>, ParentType, ContextType, Partial<QueryDraftPlanArgs>>;
  DraftPlan_aggregate?: Resolver<ResolversTypes['DraftPlan_aggregate'], ParentType, ContextType, Partial<QueryDraftPlan_AggregateArgs>>;
  DraftPlan_by_pk?: Resolver<Maybe<ResolversTypes['DraftPlan']>, ParentType, ContextType, RequireFields<QueryDraftPlan_By_PkArgs, 'id'>>;
  EnvironmentTypeEnum?: Resolver<Array<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType, Partial<QueryEnvironmentTypeEnumArgs>>;
  EnvironmentTypeEnum_aggregate?: Resolver<ResolversTypes['EnvironmentTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryEnvironmentTypeEnum_AggregateArgs>>;
  EnvironmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['EnvironmentTypeEnum']>, ParentType, ContextType, RequireFields<QueryEnvironmentTypeEnum_By_PkArgs, 'value'>>;
  FlatFee?: Resolver<Array<ResolversTypes['FlatFee']>, ParentType, ContextType, Partial<QueryFlatFeeArgs>>;
  FlatFeeAdjustment?: Resolver<Array<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, Partial<QueryFlatFeeAdjustmentArgs>>;
  FlatFeeAdjustment_aggregate?: Resolver<ResolversTypes['FlatFeeAdjustment_aggregate'], ParentType, ContextType, Partial<QueryFlatFeeAdjustment_AggregateArgs>>;
  FlatFeeAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['FlatFeeAdjustment']>, ParentType, ContextType, RequireFields<QueryFlatFeeAdjustment_By_PkArgs, 'id'>>;
  FlatFee_aggregate?: Resolver<ResolversTypes['FlatFee_aggregate'], ParentType, ContextType, Partial<QueryFlatFee_AggregateArgs>>;
  FlatFee_by_pk?: Resolver<Maybe<ResolversTypes['FlatFee']>, ParentType, ContextType, RequireFields<QueryFlatFee_By_PkArgs, 'id'>>;
  InvoiceExternalStatusEnum?: Resolver<Array<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType, Partial<QueryInvoiceExternalStatusEnumArgs>>;
  InvoiceExternalStatusEnum_aggregate?: Resolver<ResolversTypes['InvoiceExternalStatusEnum_aggregate'], ParentType, ContextType, Partial<QueryInvoiceExternalStatusEnum_AggregateArgs>>;
  InvoiceExternalStatusEnum_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum']>, ParentType, ContextType, RequireFields<QueryInvoiceExternalStatusEnum_By_PkArgs, 'value'>>;
  InvoiceManagedField?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, Partial<QueryInvoiceManagedFieldArgs>>;
  InvoiceManagedField_aggregate?: Resolver<ResolversTypes['InvoiceManagedField_aggregate'], ParentType, ContextType, Partial<QueryInvoiceManagedField_AggregateArgs>>;
  InvoiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType, RequireFields<QueryInvoiceManagedField_By_PkArgs, 'id'>>;
  ManagedEntityEnum?: Resolver<Array<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType, Partial<QueryManagedEntityEnumArgs>>;
  ManagedEntityEnum_aggregate?: Resolver<ResolversTypes['ManagedEntityEnum_aggregate'], ParentType, ContextType, Partial<QueryManagedEntityEnum_AggregateArgs>>;
  ManagedEntityEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedEntityEnum']>, ParentType, ContextType, RequireFields<QueryManagedEntityEnum_By_PkArgs, 'value'>>;
  ManagedFieldKey?: Resolver<Array<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType, Partial<QueryManagedFieldKeyArgs>>;
  ManagedFieldKey_aggregate?: Resolver<ResolversTypes['ManagedFieldKey_aggregate'], ParentType, ContextType, Partial<QueryManagedFieldKey_AggregateArgs>>;
  ManagedFieldKey_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldKey']>, ParentType, ContextType, RequireFields<QueryManagedFieldKey_By_PkArgs, 'id'>>;
  ManagedFieldVendorMapping?: Resolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType, Partial<QueryManagedFieldVendorMappingArgs>>;
  ManagedFieldVendorMapping_aggregate?: Resolver<ResolversTypes['ManagedFieldVendorMapping_aggregate'], ParentType, ContextType, Partial<QueryManagedFieldVendorMapping_AggregateArgs>>;
  ManagedFieldVendorMapping_by_pk?: Resolver<Maybe<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType, RequireFields<QueryManagedFieldVendorMapping_By_PkArgs, 'id'>>;
  ManagedVendorEnum?: Resolver<Array<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType, Partial<QueryManagedVendorEnumArgs>>;
  ManagedVendorEnum_aggregate?: Resolver<ResolversTypes['ManagedVendorEnum_aggregate'], ParentType, ContextType, Partial<QueryManagedVendorEnum_AggregateArgs>>;
  ManagedVendorEnum_by_pk?: Resolver<Maybe<ResolversTypes['ManagedVendorEnum']>, ParentType, ContextType, RequireFields<QueryManagedVendorEnum_By_PkArgs, 'value'>>;
  Minimum?: Resolver<Array<ResolversTypes['Minimum']>, ParentType, ContextType, Partial<QueryMinimumArgs>>;
  Minimum_aggregate?: Resolver<ResolversTypes['Minimum_aggregate'], ParentType, ContextType, Partial<QueryMinimum_AggregateArgs>>;
  Minimum_by_pk?: Resolver<Maybe<ResolversTypes['Minimum']>, ParentType, ContextType, RequireFields<QueryMinimum_By_PkArgs, 'id'>>;
  Plan?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, Partial<QueryPlanArgs>>;
  PlanManagedField?: Resolver<Array<ResolversTypes['PlanManagedField']>, ParentType, ContextType, Partial<QueryPlanManagedFieldArgs>>;
  PlanManagedField_aggregate?: Resolver<ResolversTypes['PlanManagedField_aggregate'], ParentType, ContextType, Partial<QueryPlanManagedField_AggregateArgs>>;
  PlanManagedField_by_pk?: Resolver<Maybe<ResolversTypes['PlanManagedField']>, ParentType, ContextType, RequireFields<QueryPlanManagedField_By_PkArgs, 'id'>>;
  Plan_aggregate?: Resolver<ResolversTypes['Plan_aggregate'], ParentType, ContextType, Partial<QueryPlan_AggregateArgs>>;
  Plan_by_pk?: Resolver<Maybe<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<QueryPlan_By_PkArgs, 'id'>>;
  Price?: Resolver<Array<ResolversTypes['Price']>, ParentType, ContextType, Partial<QueryPriceArgs>>;
  PriceAdjustment?: Resolver<Array<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, Partial<QueryPriceAdjustmentArgs>>;
  PriceAdjustmentTypeEnum?: Resolver<Array<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType, Partial<QueryPriceAdjustmentTypeEnumArgs>>;
  PriceAdjustmentTypeEnum_aggregate?: Resolver<ResolversTypes['PriceAdjustmentTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryPriceAdjustmentTypeEnum_AggregateArgs>>;
  PriceAdjustmentTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum']>, ParentType, ContextType, RequireFields<QueryPriceAdjustmentTypeEnum_By_PkArgs, 'value'>>;
  PriceAdjustment_aggregate?: Resolver<ResolversTypes['PriceAdjustment_aggregate'], ParentType, ContextType, Partial<QueryPriceAdjustment_AggregateArgs>>;
  PriceAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PriceAdjustment']>, ParentType, ContextType, RequireFields<QueryPriceAdjustment_By_PkArgs, 'id'>>;
  Price_aggregate?: Resolver<ResolversTypes['Price_aggregate'], ParentType, ContextType, Partial<QueryPrice_AggregateArgs>>;
  Price_by_pk?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType, RequireFields<QueryPrice_By_PkArgs, 'id'>>;
  PricedProduct?: Resolver<Array<ResolversTypes['PricedProduct']>, ParentType, ContextType, Partial<QueryPricedProductArgs>>;
  PricedProductAdjustment?: Resolver<Array<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, Partial<QueryPricedProductAdjustmentArgs>>;
  PricedProductAdjustment_aggregate?: Resolver<ResolversTypes['PricedProductAdjustment_aggregate'], ParentType, ContextType, Partial<QueryPricedProductAdjustment_AggregateArgs>>;
  PricedProductAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductAdjustment']>, ParentType, ContextType, RequireFields<QueryPricedProductAdjustment_By_PkArgs, 'id'>>;
  PricedProductPricingFactor?: Resolver<Array<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, Partial<QueryPricedProductPricingFactorArgs>>;
  PricedProductPricingFactorAdjustment?: Resolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, Partial<QueryPricedProductPricingFactorAdjustmentArgs>>;
  PricedProductPricingFactorAdjustment_aggregate?: Resolver<ResolversTypes['PricedProductPricingFactorAdjustment_aggregate'], ParentType, ContextType, Partial<QueryPricedProductPricingFactorAdjustment_AggregateArgs>>;
  PricedProductPricingFactorAdjustment_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment']>, ParentType, ContextType, RequireFields<QueryPricedProductPricingFactorAdjustment_By_PkArgs, 'id'>>;
  PricedProductPricingFactor_aggregate?: Resolver<ResolversTypes['PricedProductPricingFactor_aggregate'], ParentType, ContextType, Partial<QueryPricedProductPricingFactor_AggregateArgs>>;
  PricedProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['PricedProductPricingFactor']>, ParentType, ContextType, RequireFields<QueryPricedProductPricingFactor_By_PkArgs, 'id'>>;
  PricedProduct_aggregate?: Resolver<ResolversTypes['PricedProduct_aggregate'], ParentType, ContextType, Partial<QueryPricedProduct_AggregateArgs>>;
  PricedProduct_by_pk?: Resolver<Maybe<ResolversTypes['PricedProduct']>, ParentType, ContextType, RequireFields<QueryPricedProduct_By_PkArgs, 'id'>>;
  ProServiceManagedField?: Resolver<Array<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, Partial<QueryProServiceManagedFieldArgs>>;
  ProServiceManagedField_aggregate?: Resolver<ResolversTypes['ProServiceManagedField_aggregate'], ParentType, ContextType, Partial<QueryProServiceManagedField_AggregateArgs>>;
  ProServiceManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProServiceManagedField']>, ParentType, ContextType, RequireFields<QueryProServiceManagedField_By_PkArgs, 'id'>>;
  Product?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, Partial<QueryProductArgs>>;
  ProductManagedField?: Resolver<Array<ResolversTypes['ProductManagedField']>, ParentType, ContextType, Partial<QueryProductManagedFieldArgs>>;
  ProductManagedField_aggregate?: Resolver<ResolversTypes['ProductManagedField_aggregate'], ParentType, ContextType, Partial<QueryProductManagedField_AggregateArgs>>;
  ProductManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ProductManagedField']>, ParentType, ContextType, RequireFields<QueryProductManagedField_By_PkArgs, 'id'>>;
  ProductPricingFactor?: Resolver<Array<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, Partial<QueryProductPricingFactorArgs>>;
  ProductPricingFactor_aggregate?: Resolver<ResolversTypes['ProductPricingFactor_aggregate'], ParentType, ContextType, Partial<QueryProductPricingFactor_AggregateArgs>>;
  ProductPricingFactor_by_pk?: Resolver<Maybe<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType, RequireFields<QueryProductPricingFactor_By_PkArgs, 'id'>>;
  Product_aggregate?: Resolver<ResolversTypes['Product_aggregate'], ParentType, ContextType, Partial<QueryProduct_AggregateArgs>>;
  Product_by_pk?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProduct_By_PkArgs, 'id'>>;
  RateCardManagedField?: Resolver<Array<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, Partial<QueryRateCardManagedFieldArgs>>;
  RateCardManagedField_aggregate?: Resolver<ResolversTypes['RateCardManagedField_aggregate'], ParentType, ContextType, Partial<QueryRateCardManagedField_AggregateArgs>>;
  RateCardManagedField_by_pk?: Resolver<Maybe<ResolversTypes['RateCardManagedField']>, ParentType, ContextType, RequireFields<QueryRateCardManagedField_By_PkArgs, 'id'>>;
  RecurringCreditGrant?: Resolver<Array<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, Partial<QueryRecurringCreditGrantArgs>>;
  RecurringCreditGrant_aggregate?: Resolver<ResolversTypes['RecurringCreditGrant_aggregate'], ParentType, ContextType, Partial<QueryRecurringCreditGrant_AggregateArgs>>;
  RecurringCreditGrant_by_pk?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType, RequireFields<QueryRecurringCreditGrant_By_PkArgs, 'id'>>;
  Role?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType, Partial<QueryRoleArgs>>;
  Role_aggregate?: Resolver<ResolversTypes['Role_aggregate'], ParentType, ContextType, Partial<QueryRole_AggregateArgs>>;
  Role_by_pk?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryRole_By_PkArgs, 'id'>>;
  RoundingBehaviorEnum?: Resolver<Array<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType, Partial<QueryRoundingBehaviorEnumArgs>>;
  RoundingBehaviorEnum_aggregate?: Resolver<ResolversTypes['RoundingBehaviorEnum_aggregate'], ParentType, ContextType, Partial<QueryRoundingBehaviorEnum_AggregateArgs>>;
  RoundingBehaviorEnum_by_pk?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType, RequireFields<QueryRoundingBehaviorEnum_By_PkArgs, 'value'>>;
  ScheduledChargeManagedField?: Resolver<Array<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, Partial<QueryScheduledChargeManagedFieldArgs>>;
  ScheduledChargeManagedField_aggregate?: Resolver<ResolversTypes['ScheduledChargeManagedField_aggregate'], ParentType, ContextType, Partial<QueryScheduledChargeManagedField_AggregateArgs>>;
  ScheduledChargeManagedField_by_pk?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType, RequireFields<QueryScheduledChargeManagedField_By_PkArgs, 'id'>>;
  ServicePeriodStartOnEnum?: Resolver<Array<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType, Partial<QueryServicePeriodStartOnEnumArgs>>;
  ServicePeriodStartOnEnum_aggregate?: Resolver<ResolversTypes['ServicePeriodStartOnEnum_aggregate'], ParentType, ContextType, Partial<QueryServicePeriodStartOnEnum_AggregateArgs>>;
  ServicePeriodStartOnEnum_by_pk?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType, RequireFields<QueryServicePeriodStartOnEnum_By_PkArgs, 'value'>>;
  StripePrepaidConfig_temporary?: Resolver<Array<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType, Partial<QueryStripePrepaidConfig_TemporaryArgs>>;
  StripePrepaidConfig_temporary_aggregate?: Resolver<ResolversTypes['StripePrepaidConfig_temporary_aggregate'], ParentType, ContextType, Partial<QueryStripePrepaidConfig_Temporary_AggregateArgs>>;
  StripePrepaidConfig_temporary_by_pk?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType, RequireFields<QueryStripePrepaidConfig_Temporary_By_PkArgs, 'client_id' | 'environment_type'>>;
  TieringModeEnum?: Resolver<Array<ResolversTypes['TieringModeEnum']>, ParentType, ContextType, Partial<QueryTieringModeEnumArgs>>;
  TieringModeEnum_aggregate?: Resolver<ResolversTypes['TieringModeEnum_aggregate'], ParentType, ContextType, Partial<QueryTieringModeEnum_AggregateArgs>>;
  TieringModeEnum_by_pk?: Resolver<Maybe<ResolversTypes['TieringModeEnum']>, ParentType, ContextType, RequireFields<QueryTieringModeEnum_By_PkArgs, 'value'>>;
  Tokens?: Resolver<Array<ResolversTypes['Tokens']>, ParentType, ContextType, Partial<QueryTokensArgs>>;
  Tokens_aggregate?: Resolver<ResolversTypes['Tokens_aggregate'], ParentType, ContextType, Partial<QueryTokens_AggregateArgs>>;
  Tokens_by_pk?: Resolver<Maybe<ResolversTypes['Tokens']>, ParentType, ContextType, RequireFields<QueryTokens_By_PkArgs, 'id'>>;
  TrialSpec?: Resolver<Array<ResolversTypes['TrialSpec']>, ParentType, ContextType, Partial<QueryTrialSpecArgs>>;
  TrialSpecSpendingCap?: Resolver<Array<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, Partial<QueryTrialSpecSpendingCapArgs>>;
  TrialSpecSpendingCap_aggregate?: Resolver<ResolversTypes['TrialSpecSpendingCap_aggregate'], ParentType, ContextType, Partial<QueryTrialSpecSpendingCap_AggregateArgs>>;
  TrialSpecSpendingCap_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, RequireFields<QueryTrialSpecSpendingCap_By_PkArgs, 'id'>>;
  TrialSpec_aggregate?: Resolver<ResolversTypes['TrialSpec_aggregate'], ParentType, ContextType, Partial<QueryTrialSpec_AggregateArgs>>;
  TrialSpec_by_pk?: Resolver<Maybe<ResolversTypes['TrialSpec']>, ParentType, ContextType, RequireFields<QueryTrialSpec_By_PkArgs, 'id'>>;
  User?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUserArgs>>;
  UserAuthTypeEnum?: Resolver<Array<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType, Partial<QueryUserAuthTypeEnumArgs>>;
  UserAuthTypeEnum_aggregate?: Resolver<ResolversTypes['UserAuthTypeEnum_aggregate'], ParentType, ContextType, Partial<QueryUserAuthTypeEnum_AggregateArgs>>;
  UserAuthTypeEnum_by_pk?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType, RequireFields<QueryUserAuthTypeEnum_By_PkArgs, 'value'>>;
  User_aggregate?: Resolver<ResolversTypes['User_aggregate'], ParentType, ContextType, Partial<QueryUser_AggregateArgs>>;
  User_by_pk?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUser_By_PkArgs, 'id'>>;
  Webhook?: Resolver<Array<ResolversTypes['Webhook']>, ParentType, ContextType, Partial<QueryWebhookArgs>>;
  WebhookNotification?: Resolver<Array<ResolversTypes['WebhookNotification']>, ParentType, ContextType, Partial<QueryWebhookNotificationArgs>>;
  WebhookNotification_aggregate?: Resolver<ResolversTypes['WebhookNotification_aggregate'], ParentType, ContextType, Partial<QueryWebhookNotification_AggregateArgs>>;
  WebhookNotification_by_pk?: Resolver<Maybe<ResolversTypes['WebhookNotification']>, ParentType, ContextType, RequireFields<QueryWebhookNotification_By_PkArgs, 'id'>>;
  Webhook_aggregate?: Resolver<ResolversTypes['Webhook_aggregate'], ParentType, ContextType, Partial<QueryWebhook_AggregateArgs>>;
  Webhook_by_pk?: Resolver<Maybe<ResolversTypes['Webhook']>, ParentType, ContextType, RequireFields<QueryWebhook_By_PkArgs, 'id'>>;
  add_rate_event_effective_dates?: Resolver<ResolversTypes['EffectiveDates'], ParentType, ContextType, RequireFields<QueryAdd_Rate_Event_Effective_DatesArgs, 'aggregate_id' | 'aggregate_version'>>;
  alerts?: Resolver<Array<ResolversTypes['Alert']>, ParentType, ContextType, RequireFields<QueryAlertsArgs, 'types'>>;
  all_users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryAll_UsersArgs>>;
  audit_log?: Resolver<Maybe<ResolversTypes['AuditLog']>, ParentType, ContextType, RequireFields<QueryAudit_LogArgs, 'logID' | 'requestTimestamp'>>;
  audit_logs?: Resolver<ResolversTypes['AuditLogResponse'], ParentType, ContextType, RequireFields<QueryAudit_LogsArgs, 'limit'>>;
  auth_check?: Resolver<ResolversTypes['AuthCheckResponse'], ParentType, ContextType, RequireFields<QueryAuth_CheckArgs, 'resolver' | 'type'>>;
  auth_check_mri?: Resolver<ResolversTypes['AuthCheckResponse'], ParentType, ContextType, RequireFields<QueryAuth_Check_MriArgs, 'resolver' | 'type'>>;
  billable_metrics?: Resolver<Array<ResolversTypes['BillableMetric']>, ParentType, ContextType, RequireFields<QueryBillable_MetricsArgs, 'types'>>;
  breakdown_cursor?: Resolver<ResolversTypes['BreakdownCursor'], ParentType, ContextType, RequireFields<QueryBreakdown_CursorArgs, 'customer_id' | 'ending_before' | 'invoice_id' | 'starting_at'>>;
  client_dashboard?: Resolver<ResolversTypes['ClientDashboardMetrics'], ParentType, ContextType>;
  client_overview?: Resolver<ResolversTypes['ClientOverview'], ParentType, ContextType>;
  clients_using_contracts?: Resolver<ResolversTypes['ClientsUsingContracts'], ParentType, ContextType, RequireFields<QueryClients_Using_ContractsArgs, 'filter_archived'>>;
  clients_using_prepaid_credits?: Resolver<ResolversTypes['ClientsUsingPrepaidCredits'], ParentType, ContextType, Partial<QueryClients_Using_Prepaid_CreditsArgs>>;
  contract_consistency?: Resolver<ResolversTypes['ContractConsistency'], ParentType, ContextType>;
  contract_pricing?: Resolver<ResolversTypes['ContractPricing'], ParentType, ContextType>;
  contract_stats?: Resolver<ResolversTypes['ContractStats'], ParentType, ContextType>;
  credit_grants?: Resolver<ResolversTypes['CreditGrantPage'], ParentType, ContextType, Partial<QueryCredit_GrantsArgs>>;
  customer_billing_provider_configurations?: Resolver<Array<ResolversTypes['CustomerBillingProviderConfiguration']>, ParentType, ContextType, RequireFields<QueryCustomer_Billing_Provider_ConfigurationsArgs, 'customer_id'>>;
  customers_using_contract_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customers_using_contracts?: Resolver<ResolversTypes['CustomersUsingContracts'], ParentType, ContextType, Partial<QueryCustomers_Using_ContractsArgs>>;
  customers_with_credit_grants_taking_effect_or_expiring_within_time_range?: Resolver<ResolversTypes['CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse'], ParentType, ContextType, RequireFields<QueryCustomers_With_Credit_Grants_Taking_Effect_Or_Expiring_Within_Time_RangeArgs, 'client_id' | 'credit_grant_time_range' | 'limit'>>;
  customers_with_recurring_credit_grants?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryCustomers_With_Recurring_Credit_GrantsArgs, 'ids' | 'limit'>>;
  environments?: Resolver<Array<ResolversTypes['Environment']>, ParentType, ContextType>;
  event_count?: Resolver<Array<ResolversTypes['EventCountWindow']>, ParentType, ContextType, RequireFields<QueryEvent_CountArgs, 'ending_before' | 'starting_after'>>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryEventsArgs, 'ending_before' | 'limit' | 'starting_after'>>;
  get_active_customer_contracts_for_billing_provider?: Resolver<ResolversTypes['ContractPage'], ParentType, ContextType, RequireFields<QueryGet_Active_Customer_Contracts_For_Billing_ProviderArgs, 'billing_providers' | 'grace_period_hours'>>;
  get_billing_provider_config_notifications?: Resolver<Array<Maybe<ResolversTypes['CustomerBillingProviderConfigurationNotification']>>, ParentType, ContextType, RequireFields<QueryGet_Billing_Provider_Config_NotificationsArgs, 'endDate' | 'startDate'>>;
  get_census_object_sync_runs?: Resolver<Array<ResolversTypes['CensusObjectSyncReport']>, ParentType, ContextType, RequireFields<QueryGet_Census_Object_Sync_RunsArgs, 'destination_type' | 'num_recent_syncs'>>;
  get_census_workspace?: Resolver<ResolversTypes['CensusWorkspace'], ParentType, ContextType, Partial<QueryGet_Census_WorkspaceArgs>>;
  invoice_counts_by_finalization_status?: Resolver<ResolversTypes['InvoiceCountsByFinalizationStatusResponse'], ParentType, ContextType, RequireFields<QueryInvoice_Counts_By_Finalization_StatusArgs, 'finalization_hold_date'>>;
  invoice_finalization_metadata?: Resolver<Array<Maybe<ResolversTypes['InvoiceFinalizationMetadata']>>, ParentType, ContextType>;
  invoice_finalization_progress_count?: Resolver<ResolversTypes['InvoiceFinalizationProgressCountResponse'], ParentType, ContextType, RequireFields<QueryInvoice_Finalization_Progress_CountArgs, 'metadata_id'>>;
  invoices?: Resolver<ResolversTypes['InvoicePage'], ParentType, ContextType, RequireFields<QueryInvoicesArgs, 'limit' | 'statuses'>>;
  is_customer_billing_provider_configuration_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_delta_stream_enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  list_customers?: Resolver<Maybe<ResolversTypes['CustomerList']>, ParentType, ContextType, RequireFields<QueryList_CustomersArgs, 'archived' | 'pageSize'>>;
  list_delivery_methods?: Resolver<ResolversTypes['ListDeliveryMethodsResponse'], ParentType, ContextType, RequireFields<QueryList_Delivery_MethodsArgs, 'page_size'>>;
  list_delivery_methods_without_configuration_for_all_clients?: Resolver<ResolversTypes['ListDeliveryMethodsWithoutConfigurationForAllClientsResponse'], ParentType, ContextType, RequireFields<QueryList_Delivery_Methods_Without_Configuration_For_All_ClientsArgs, 'billing_provider' | 'delivery_method' | 'limit'>>;
  list_failed_marketplace_metering_records?: Resolver<ResolversTypes['FailedMarketplacesMeteringRecordList'], ParentType, ContextType, RequireFields<QueryList_Failed_Marketplace_Metering_RecordsArgs, 'ascending' | 'page_size'>>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  mri_invoice?: Resolver<Maybe<ResolversTypes['MRI_Invoice']>, ParentType, ContextType, RequireFields<QueryMri_InvoiceArgs, 'id'>>;
  pending_prepaid_credit_grants?: Resolver<ResolversTypes['PendingPrepaidGrantsResponse'], ParentType, ContextType, Partial<QueryPending_Prepaid_Credit_GrantsArgs>>;
  pending_recharges?: Resolver<ResolversTypes['PendingRechargesResponse'], ParentType, ContextType, Partial<QueryPending_RechargesArgs>>;
  plans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, RequireFields<QueryPlansArgs, 'plan_type'>>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductsArgs, 'types'>>;
  products_and_rate_cards?: Resolver<ResolversTypes['ProductsAndRateCards'], ParentType, ContextType>;
  scheduled_webhook_notifications?: Resolver<Maybe<ResolversTypes['ScheduledWebhookNotificationList']>, ParentType, ContextType, RequireFields<QueryScheduled_Webhook_NotificationsArgs, 'limit' | 'type'>>;
  searchCustomers?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QuerySearchCustomersArgs, 'query'>>;
  seat_metric?: Resolver<ResolversTypes['SeatMetric'], ParentType, ContextType, RequireFields<QuerySeat_MetricArgs, 'seat_metric_id'>>;
  seat_metrics?: Resolver<ResolversTypes['PagedSeatMetricsResult'], ParentType, ContextType, RequireFields<QuerySeat_MetricsArgs, 'types'>>;
  simulate_fancy_metric_usage?: Resolver<ResolversTypes['SimulatedFancyMetricUsageResult'], ParentType, ContextType, RequireFields<QuerySimulate_Fancy_Metric_UsageArgs, 'input'>>;
  stripeCustomer?: Resolver<Maybe<ResolversTypes['StripeCustomer']>, ParentType, ContextType, RequireFields<QueryStripeCustomerArgs, 'id'>>;
  unpaid_invoices?: Resolver<Array<ResolversTypes['UnpaidInvoiceIdentifier']>, ParentType, ContextType, RequireFields<QueryUnpaid_InvoicesArgs, 'billing_provider' | 'credit_purchase_grace_period' | 'earliest_invoice_end_date' | 'exclude_invoices_for_archived_customers' | 'exclude_zero_dollar_invoices' | 'limit' | 'use_billing_provider_on_contract' | 'use_billing_provider_on_credit_grant' | 'use_billing_provider_on_plan'>>;
  validate_billable_metric?: Resolver<ResolversTypes['MetricValidationResponse'], ParentType, ContextType, RequireFields<QueryValidate_Billable_MetricArgs, 'metric'>>;
  validate_contract_input?: Resolver<ResolversTypes['MutationResponse'], ParentType, ContextType, RequireFields<QueryValidate_Contract_InputArgs, 'starting_at'>>;
  validate_fancy_metric?: Resolver<ResolversTypes['FancyMetricValidationResponse'], ParentType, ContextType, RequireFields<QueryValidate_Fancy_MetricArgs, 'sql'>>;
};

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = {
  __resolveType: TypeResolveFn<'CustomRate' | 'FlatRate' | 'PercentageRate' | 'SubscriptionRate' | 'TieredRate', ParentType, ContextType>;
};

export type RateCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCard'] = ResolversParentTypes['RateCard']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  Updater?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  aliases?: Resolver<Array<ResolversTypes['RateCardAliasScheduleSegment']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type_conversions?: Resolver<Maybe<Array<ResolversTypes['RateCardCreditTypeConversion']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fiat_credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['RateCardManagedField']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  named_schedule?: Resolver<Array<ResolversTypes['NamedScheduleSegment']>, ParentType, ContextType, RequireFields<RateCardNamed_ScheduleArgs, 'name'>>;
  named_schedules?: Resolver<Array<ResolversTypes['NamedSchedule']>, ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType>;
  rate_schedule?: Resolver<ResolversTypes['RateSchedule'], ParentType, ContextType, RequireFields<RateCardRate_ScheduleArgs, 'limit'>>;
  rates_per_product?: Resolver<Array<ResolversTypes['RatesPerProduct']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardAliasDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardAliasData'] = ResolversParentTypes['RateCardAliasData']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardAliasScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardAliasScheduleSegment'] = ResolversParentTypes['RateCardAliasScheduleSegment']> = {
  data?: Resolver<ResolversTypes['RateCardAliasData'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardCreditTypeConversionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardCreditTypeConversion'] = ResolversParentTypes['RateCardCreditTypeConversion']> = {
  custom_credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  fiat_per_custom_credit?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardEntry'] = ResolversParentTypes['RateCardEntry']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  entitled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['RateCardEntryPricingGroupValueEntry']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['UsageProductListItem'], ParentType, ContextType>;
  product_list_item?: Resolver<ResolversTypes['RateCardProductListItem'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Rate'], ParentType, ContextType>;
  rate_card?: Resolver<ResolversTypes['RateCard'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardEntryOverrideResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardEntryOverride'] = ResolversParentTypes['RateCardEntryOverride']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  entitled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  override_specifiers?: Resolver<Array<ResolversTypes['OverrideSpecifier']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['ProductListItem']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['OverrideRate']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardEntryPricingGroupValueEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardEntryPricingGroupValueEntry'] = ResolversParentTypes['RateCardEntryPricingGroupValueEntry']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField'] = ResolversParentTypes['RateCardManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rate_card_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField_aggregate'] = ResolversParentTypes['RateCardManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['RateCardManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['RateCardManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField_aggregate_fields'] = ResolversParentTypes['RateCardManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<RateCardManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['RateCardManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RateCardManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField_max_fields'] = ResolversParentTypes['RateCardManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rate_card_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField_min_fields'] = ResolversParentTypes['RateCardManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  rate_card_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardManagedField_mutation_response'] = ResolversParentTypes['RateCardManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['RateCardManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardMetadata'] = ResolversParentTypes['RateCardMetadata']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  Updater?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  aliases?: Resolver<Array<ResolversTypes['RateCardAliasScheduleSegment']>, ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type_conversions?: Resolver<Maybe<Array<ResolversTypes['RateCardCreditTypeConversion']>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fiat_credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  named_schedule?: Resolver<Array<ResolversTypes['NamedScheduleSegment']>, ParentType, ContextType, RequireFields<RateCardMetadataNamed_ScheduleArgs, 'name'>>;
  named_schedules?: Resolver<Array<ResolversTypes['NamedSchedule']>, ParentType, ContextType>;
  product_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType>;
  rate_schedule?: Resolver<ResolversTypes['RateSchedule'], ParentType, ContextType, RequireFields<RateCardMetadataRate_ScheduleArgs, 'limit'>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateCardProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateCardProductListItem'] = ResolversParentTypes['RateCardProductListItem']> = {
  __resolveType: TypeResolveFn<'SubscriptionProductListItem' | 'UsageProductListItem', ParentType, ContextType>;
};

export type RateScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateSchedule'] = ResolversParentTypes['RateSchedule']> = {
  credit_types_on_segments?: Resolver<Array<ResolversTypes['CreditType']>, ParentType, ContextType>;
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  products_on_segments?: Resolver<Array<ResolversTypes['ProductListItem']>, ParentType, ContextType>;
  scalar_segments?: Resolver<Array<ResolversTypes['Scalar__RateScheduleSegment']>, ParentType, ContextType>;
  segments?: Resolver<Array<ResolversTypes['RateScheduleSegment']>, ParentType, ContextType>;
  using_product_order?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateScheduleSegment'] = ResolversParentTypes['RateScheduleSegment']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  entitled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pricing_group_values?: Resolver<Maybe<Array<ResolversTypes['RateCardEntryPricingGroupValueEntry']>>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['Rate'], ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RatesPerProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['RatesPerProduct'] = ResolversParentTypes['RatesPerProduct']> = {
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  rates?: Resolver<Array<ResolversTypes['RateCardEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RechargeSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RechargeSettings'] = ResolversParentTypes['RechargeSettings']> = {
  auto_recharge_threshold?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  auto_recharge_to_amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  billing_provider?: Resolver<Maybe<ResolversTypes['BillingProviderEnum_enum']>, ParentType, ContextType>;
  billing_provider_config?: Resolver<Maybe<ResolversTypes['RechargeSettingsBillingProviderConfig']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  grant_duration?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['uuid']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status_metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RechargeSettingsBillingProviderConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['RechargeSettingsBillingProviderConfig'] = ResolversParentTypes['RechargeSettingsBillingProviderConfig']> = {
  stripe_config?: Resolver<Maybe<ResolversTypes['RechargeSettingsStripeConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RechargeSettingsStripeConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['RechargeSettingsStripeConfig'] = ResolversParentTypes['RechargeSettingsStripeConfig']> = {
  automatic_tax?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  default_tax_rates?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  invoice_custom_fields?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  invoice_metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  payment_method_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tax_product_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RechargeSettingsUpdateAndChargeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RechargeSettingsUpdateAndChargeResponse'] = ResolversParentTypes['RechargeSettingsUpdateAndChargeResponse']> = {
  recharge_response?: Resolver<Maybe<ResolversTypes['PrepaidCreditGrantRechargeResponse']>, ParentType, ContextType>;
  recharge_settings?: Resolver<ResolversTypes['RechargeSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RecordOfNullableStringArraysScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RecordOfNullableStringArrays'], any> {
  name: 'RecordOfNullableStringArrays';
}

export interface RecordOfNullableStringsScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RecordOfNullableStrings'], any> {
  name: 'RecordOfNullableStrings';
}

export interface RecordOfStringsScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['RecordOfStrings'], any> {
  name: 'RecordOfStrings';
}

export type RecurringCreditGrantResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant'] = ResolversParentTypes['RecurringCreditGrant']> = {
  AmountGrantedCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  AmountPaidCreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  Plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  amount_granted?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  amount_paid?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effective_duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  send_invoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_aggregate'] = ResolversParentTypes['RecurringCreditGrant_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_aggregate_fields'] = ResolversParentTypes['RecurringCreditGrant_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<RecurringCreditGrant_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['RecurringCreditGrant_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_avg_fields'] = ResolversParentTypes['RecurringCreditGrant_avg_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_max_fields'] = ResolversParentTypes['RecurringCreditGrant_max_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_min_fields'] = ResolversParentTypes['RecurringCreditGrant_min_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_granted_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid_credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  credit_grant_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  product_ids?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_mutation_response'] = ResolversParentTypes['RecurringCreditGrant_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['RecurringCreditGrant']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_stddev_fields'] = ResolversParentTypes['RecurringCreditGrant_stddev_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_stddev_pop_fields'] = ResolversParentTypes['RecurringCreditGrant_stddev_pop_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_stddev_samp_fields'] = ResolversParentTypes['RecurringCreditGrant_stddev_samp_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_sum_fields'] = ResolversParentTypes['RecurringCreditGrant_sum_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_var_pop_fields'] = ResolversParentTypes['RecurringCreditGrant_var_pop_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_var_samp_fields'] = ResolversParentTypes['RecurringCreditGrant_var_samp_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringCreditGrant_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringCreditGrant_variance_fields'] = ResolversParentTypes['RecurringCreditGrant_variance_fields']> = {
  amount_granted?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amount_paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  effective_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recurrence_interval?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecurringScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecurringSchedule'] = ResolversParentTypes['RecurringSchedule']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  amount_distribution?: Resolver<ResolversTypes['AmountDistributionType'], ParentType, ContextType>;
  end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['RecurringScheduleFrequency'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['CreditAmount']>, ParentType, ContextType>;
  start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  unit_price?: Resolver<Maybe<ResolversTypes['CreditAmount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepairPrepaidCreditGrantResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RepairPrepaidCreditGrantResponse'] = ResolversParentTypes['RepairPrepaidCreditGrantResponse']> = {
  action_taken?: Resolver<ResolversTypes['RepairPrepaidCreditGrantAction'], ParentType, ContextType>;
  credit_grant?: Resolver<Maybe<ResolversTypes['CreditGrant']>, ParentType, ContextType>;
  result?: Resolver<Maybe<ResolversTypes['PrepaidCreditGrantStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResellerRoyaltyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResellerRoyalty'] = ResolversParentTypes['ResellerRoyalty']> = {
  __resolveType: TypeResolveFn<'AWSProServiceRoyalty' | 'AWSRoyalty' | 'GCPProServiceRoyalty' | 'GCPRoyalty', ParentType, ContextType>;
};

export type ResellerRoyaltyOrUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResellerRoyaltyOrUpdate'] = ResolversParentTypes['ResellerRoyaltyOrUpdate']> = {
  __resolveType: TypeResolveFn<'AWSProServiceRoyalty' | 'AWSProServiceRoyaltyUpdate' | 'AWSRoyalty' | 'AWSRoyaltyUpdate' | 'GCPProServiceRoyalty' | 'GCPProServiceRoyaltyUpdate' | 'GCPRoyalty' | 'GCPRoyaltyUpdate', ParentType, ContextType>;
};

export type ResellerRoyaltyUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResellerRoyaltyUpdate'] = ResolversParentTypes['ResellerRoyaltyUpdate']> = {
  __resolveType: TypeResolveFn<'AWSProServiceRoyaltyUpdate' | 'AWSRoyaltyUpdate' | 'GCPProServiceRoyaltyUpdate' | 'GCPRoyaltyUpdate', ParentType, ContextType>;
};

export type RevenueBreakdownResolvers<ContextType = any, ParentType extends ResolversParentTypes['RevenueBreakdown'] = ResolversParentTypes['RevenueBreakdown']> = {
  average_revenue?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total_revenue?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevenueMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['RevenueMetric'] = ResolversParentTypes['RevenueMetric']> = {
  customer_count?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  customer_with_spend_count?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  invoice_count?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  range?: Resolver<ResolversTypes['RevenueRange'], ParentType, ContextType>;
  total_usd?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  Client?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType>;
  Tokens?: Resolver<Array<ResolversTypes['Tokens']>, ParentType, ContextType, Partial<RoleTokensArgs>>;
  Tokens_aggregate?: Resolver<ResolversTypes['Tokens_aggregate'], ParentType, ContextType, Partial<RoleTokens_AggregateArgs>>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<RolePermissionsArgs>>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role_aggregate'] = ResolversParentTypes['Role_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Role_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role_aggregate_fields'] = ResolversParentTypes['Role_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Role_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Role_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Role_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role_max_fields'] = ResolversParentTypes['Role_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role_min_fields'] = ResolversParentTypes['Role_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Role_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role_mutation_response'] = ResolversParentTypes['Role_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolloverAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['RolloverAmount'] = ResolversParentTypes['RolloverAmount']> = {
  __resolveType: TypeResolveFn<'RolloverMaxAmount' | 'RolloverMaxPercentage', ParentType, ContextType>;
};

export type RolloverMaxAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['RolloverMaxAmount'] = ResolversParentTypes['RolloverMaxAmount']> = {
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolloverMaxPercentageResolvers<ContextType = any, ParentType extends ResolversParentTypes['RolloverMaxPercentage'] = ResolversParentTypes['RolloverMaxPercentage']> = {
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolloverSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RolloverSettings'] = ResolversParentTypes['RolloverSettings']> = {
  expiry_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  rollover_amount?: Resolver<ResolversTypes['RolloverAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RootLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootLineItem'] = ResolversParentTypes['RootLineItem']> = {
  __resolveType: TypeResolveFn<'ContractAWSProServiceRoyaltyLineItem' | 'ContractAWSRoyaltyLineItem' | 'ContractAppliedCommitLineItem' | 'ContractChargeLineItem' | 'ContractCommitLineItem' | 'ContractConversionLineItem' | 'ContractDiscountLineItem' | 'ContractGCPProServiceRoyaltyLineItem' | 'ContractGCPRoyaltyLineItem' | 'ContractPostpaidTrueupLineItem' | 'ContractProServiceLineItem' | 'ContractRolloverCommitLineItem' | 'ContractSubscriptionLineItem' | 'ContractUsageLineItem' | 'CreditLineItem' | 'CreditPurchaseLineItem' | 'GroupedProductChargeLineItem' | 'LegacyContractLineItem' | 'LegacyLineItem' | 'MinimumLineItem' | 'OverageLineItem' | 'ProductChargeLineItem' | 'TrialDiscountLineItem', ParentType, ContextType>;
};

export type RoundingBehaviorEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum'] = ResolversParentTypes['RoundingBehaviorEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingBehaviorEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum_aggregate'] = ResolversParentTypes['RoundingBehaviorEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingBehaviorEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum_aggregate_fields'] = ResolversParentTypes['RoundingBehaviorEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<RoundingBehaviorEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['RoundingBehaviorEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingBehaviorEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum_max_fields'] = ResolversParentTypes['RoundingBehaviorEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingBehaviorEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum_min_fields'] = ResolversParentTypes['RoundingBehaviorEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoundingBehaviorEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoundingBehaviorEnum_mutation_response'] = ResolversParentTypes['RoundingBehaviorEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['RoundingBehaviorEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveVendorEntityMappingsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveVendorEntityMappingsResponse'] = ResolversParentTypes['SaveVendorEntityMappingsResponse']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScalarContractRateScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScalarContractRateScheduleSegment'] = ResolversParentTypes['ScalarContractRateScheduleSegment']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  list_rate?: Resolver<ResolversTypes['ScalarRate'], ParentType, ContextType>;
  override_rate?: Resolver<Maybe<ResolversTypes['ScalarRate']>, ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScalarFlatRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScalarFlatRate'] = ResolversParentTypes['ScalarFlatRate']> = {
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScalarRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScalarRate'] = ResolversParentTypes['ScalarRate']> = {
  __resolveType: TypeResolveFn<'CustomRate' | 'PercentageRate' | 'ScalarFlatRate' | 'SubscriptionRate' | 'TieredRate', ParentType, ContextType>;
};

export type ScalarRateScheduleSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScalarRateScheduleSegment'] = ResolversParentTypes['ScalarRateScheduleSegment']> = {
  ending_before?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  entitled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pricing_groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  product_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['ScalarRate'], ParentType, ContextType>;
  starting_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Scalar__ContractRateScheduleSegmentScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Scalar__ContractRateScheduleSegment'], any> {
  name: 'Scalar__ContractRateScheduleSegment';
}

export interface Scalar__RateScheduleSegmentScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Scalar__RateScheduleSegment'], any> {
  name: 'Scalar__RateScheduleSegment';
}

export type ScheduledChargeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledCharge'] = ResolversParentTypes['ScheduledCharge']> = {
  contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_sales_order_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['ProductListItem'], ParentType, ContextType>;
  schedule?: Resolver<ResolversTypes['ScheduledChargeSchedule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField'] = ResolversParentTypes['ScheduledChargeManagedField']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  ManagedFieldKey?: Resolver<ResolversTypes['ManagedFieldKey'], ParentType, ContextType>;
  Updater?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enforce_uniqueness?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  key_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  scheduled_charge_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedField_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField_aggregate'] = ResolversParentTypes['ScheduledChargeManagedField_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedField_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField_aggregate_fields'] = ResolversParentTypes['ScheduledChargeManagedField_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ScheduledChargeManagedField_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ScheduledChargeManagedField_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedField_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField_max_fields'] = ResolversParentTypes['ScheduledChargeManagedField_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  scheduled_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedField_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField_min_fields'] = ResolversParentTypes['ScheduledChargeManagedField_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  key_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  scheduled_charge_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeManagedField_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeManagedField_mutation_response'] = ResolversParentTypes['ScheduledChargeManagedField_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ScheduledChargeManagedField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeSchedule'] = ResolversParentTypes['ScheduledChargeSchedule']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  recurring_schedule?: Resolver<Maybe<ResolversTypes['RecurringSchedule']>, ParentType, ContextType>;
  schedule_items?: Resolver<Array<ResolversTypes['ScheduledChargeScheduleItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledChargeScheduleItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledChargeScheduleItem'] = ResolversParentTypes['ScheduledChargeScheduleItem']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledWebhookNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledWebhookNotification'] = ResolversParentTypes['ScheduledWebhookNotification']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  notification?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  notification_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledWebhookNotificationListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduledWebhookNotificationList'] = ResolversParentTypes['ScheduledWebhookNotificationList']> = {
  cursor?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  list?: Resolver<Array<ResolversTypes['ScheduledWebhookNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeatChargeLineItem'] = ResolversParentTypes['SeatChargeLineItem']> = {
  applicable_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  applicable_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  current_seat_count?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_prorated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  pricing_factor?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeatCount'] = ResolversParentTypes['SeatCount']> = {
  charge?: Resolver<ResolversTypes['ProductPricingFactor'], ParentType, ContextType>;
  seat_count?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatMetricResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeatMetric'] = ResolversParentTypes['SeatMetric']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  active_plan_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active_product_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  aggregate_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  filter?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  is_draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  usage?: Resolver<ResolversTypes['MRI_GroupedUsage'], ParentType, ContextType, RequireFields<SeatMetricUsageArgs, 'customer_id' | 'eventWindowSize' | 'exclusive_end_date' | 'groupings' | 'inclusive_start_date' | 'limit' | 'usage_type'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeatPrice'] = ResolversParentTypes['SeatPrice']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial_quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  is_prorated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeatPurchaseInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeatPurchaseInvoice'] = ResolversParentTypes['SeatPurchaseInvoice']> = {
  billing_provider_invoice?: Resolver<Maybe<ResolversTypes['BillingProviderInvoice']>, ParentType, ContextType>;
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType>;
  external_type?: Resolver<ResolversTypes['InvoiceExternalTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  issued_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  line_items?: Resolver<Array<ResolversTypes['RootLineItem']>, ParentType, ContextType, Partial<SeatPurchaseInvoiceLine_ItemsArgs>>;
  managed_fields?: Resolver<Array<ResolversTypes['InvoiceManagedField']>, ParentType, ContextType>;
  on_hold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SequenceNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['SequenceNumber'] = ResolversParentTypes['SequenceNumber']> = {
  original_invoice?: Resolver<ResolversTypes['CorrectableInvoice'], ParentType, ContextType>;
  sequence_number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriod'] = ResolversParentTypes['ServicePeriod']> = {
  accrued_costs?: Resolver<Array<ResolversTypes['CostWindow']>, ParentType, ContextType, Partial<ServicePeriodAccrued_CostsArgs>>;
  end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  lock_date?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum'] = ResolversParentTypes['ServicePeriodStartOnEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum_aggregate'] = ResolversParentTypes['ServicePeriodStartOnEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum_aggregate_fields'] = ResolversParentTypes['ServicePeriodStartOnEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ServicePeriodStartOnEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum_max_fields'] = ResolversParentTypes['ServicePeriodStartOnEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum_min_fields'] = ResolversParentTypes['ServicePeriodStartOnEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServicePeriodStartOnEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePeriodStartOnEnum_mutation_response'] = ResolversParentTypes['ServicePeriodStartOnEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ServicePeriodStartOnEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimulatedFancyMetricUsageDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimulatedFancyMetricUsageData'] = ResolversParentTypes['SimulatedFancyMetricUsageData']> = {
  groups?: Resolver<Maybe<ResolversTypes['RecordOfNullableStrings']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimulatedFancyMetricUsageResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimulatedFancyMetricUsageResult'] = ResolversParentTypes['SimulatedFancyMetricUsageResult']> = {
  usage?: Resolver<Array<ResolversTypes['SimulatedFancyMetricUsageData']>, ParentType, ContextType>;
  value_column_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeCreditGrantResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeCreditGrantResponse'] = ResolversParentTypes['StripeCreditGrantResponse']> = {
  error_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoice_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  next_action_stripe_sdk?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  next_action_type?: Resolver<Maybe<ResolversTypes['StripeNextActionTypeEnum']>, ParentType, ContextType>;
  payment_intent_client_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirect_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeCustomer'] = ResolversParentTypes['StripeCustomer']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_TemporaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary'] = ResolversParentTypes['StripePrepaidConfig_temporary']> = {
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  encrypted_anrok_api_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_token_string?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webhook_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_Temporary_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary_aggregate'] = ResolversParentTypes['StripePrepaidConfig_temporary_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_Temporary_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary_aggregate_fields'] = ResolversParentTypes['StripePrepaidConfig_temporary_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<StripePrepaidConfig_Temporary_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_Temporary_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary_max_fields'] = ResolversParentTypes['StripePrepaidConfig_temporary_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_anrok_api_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webhook_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_Temporary_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary_min_fields'] = ResolversParentTypes['StripePrepaidConfig_temporary_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_anrok_api_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_token_string?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webhook_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePrepaidConfig_Temporary_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrepaidConfig_temporary_mutation_response'] = ResolversParentTypes['StripePrepaidConfig_temporary_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['StripePrepaidConfig_temporary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubLineItem'] = ResolversParentTypes['SubLineItem']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  one_time_charge?: Resolver<Maybe<ResolversTypes['OneTimeProductCharge']>, ParentType, ContextType>;
  pricing_scheme?: Resolver<Maybe<ResolversTypes['PricingSchemeIdentifier']>, ParentType, ContextType>;
  product_pricing_factor?: Resolver<Maybe<ResolversTypes['ProductPricingFactor']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotal?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  tiers?: Resolver<Array<ResolversTypes['SubLineItemTier']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubLineItemTierResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubLineItemTier'] = ResolversParentTypes['SubLineItemTier']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotal?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  AccountTypeEnum?: SubscriptionResolver<Array<ResolversTypes['AccountTypeEnum']>, "AccountTypeEnum", ParentType, ContextType, Partial<SubscriptionAccountTypeEnumArgs>>;
  AccountTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['AccountTypeEnum_aggregate'], "AccountTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionAccountTypeEnum_AggregateArgs>>;
  AccountTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['AccountTypeEnum']>, "AccountTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionAccountTypeEnum_By_PkArgs, 'value'>>;
  AccountTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['AccountTypeEnum']>, "AccountTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionAccountTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  Actor?: SubscriptionResolver<Array<ResolversTypes['Actor']>, "Actor", ParentType, ContextType, Partial<SubscriptionActorArgs>>;
  Actor_aggregate?: SubscriptionResolver<ResolversTypes['Actor_aggregate'], "Actor_aggregate", ParentType, ContextType, Partial<SubscriptionActor_AggregateArgs>>;
  Actor_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Actor']>, "Actor_by_pk", ParentType, ContextType, RequireFields<SubscriptionActor_By_PkArgs, 'id'>>;
  Actor_stream?: SubscriptionResolver<Array<ResolversTypes['Actor']>, "Actor_stream", ParentType, ContextType, RequireFields<SubscriptionActor_StreamArgs, 'batch_size' | 'cursor'>>;
  Alert?: SubscriptionResolver<Array<ResolversTypes['Alert']>, "Alert", ParentType, ContextType, Partial<SubscriptionAlertArgs>>;
  AlertManagedField?: SubscriptionResolver<Array<ResolversTypes['AlertManagedField']>, "AlertManagedField", ParentType, ContextType, Partial<SubscriptionAlertManagedFieldArgs>>;
  AlertManagedField_aggregate?: SubscriptionResolver<ResolversTypes['AlertManagedField_aggregate'], "AlertManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionAlertManagedField_AggregateArgs>>;
  AlertManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['AlertManagedField']>, "AlertManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionAlertManagedField_By_PkArgs, 'id'>>;
  AlertManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['AlertManagedField']>, "AlertManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionAlertManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  AlertTypeEnum?: SubscriptionResolver<Array<ResolversTypes['AlertTypeEnum']>, "AlertTypeEnum", ParentType, ContextType, Partial<SubscriptionAlertTypeEnumArgs>>;
  AlertTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['AlertTypeEnum_aggregate'], "AlertTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionAlertTypeEnum_AggregateArgs>>;
  AlertTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['AlertTypeEnum']>, "AlertTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionAlertTypeEnum_By_PkArgs, 'value'>>;
  AlertTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['AlertTypeEnum']>, "AlertTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionAlertTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  Alert_aggregate?: SubscriptionResolver<ResolversTypes['Alert_aggregate'], "Alert_aggregate", ParentType, ContextType, Partial<SubscriptionAlert_AggregateArgs>>;
  Alert_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Alert']>, "Alert_by_pk", ParentType, ContextType, RequireFields<SubscriptionAlert_By_PkArgs, 'id'>>;
  Alert_stream?: SubscriptionResolver<Array<ResolversTypes['Alert']>, "Alert_stream", ParentType, ContextType, RequireFields<SubscriptionAlert_StreamArgs, 'batch_size' | 'cursor'>>;
  BillableMetric?: SubscriptionResolver<Array<ResolversTypes['BillableMetric']>, "BillableMetric", ParentType, ContextType, Partial<SubscriptionBillableMetricArgs>>;
  BillableMetricManagedField?: SubscriptionResolver<Array<ResolversTypes['BillableMetricManagedField']>, "BillableMetricManagedField", ParentType, ContextType, Partial<SubscriptionBillableMetricManagedFieldArgs>>;
  BillableMetricManagedField_aggregate?: SubscriptionResolver<ResolversTypes['BillableMetricManagedField_aggregate'], "BillableMetricManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionBillableMetricManagedField_AggregateArgs>>;
  BillableMetricManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillableMetricManagedField']>, "BillableMetricManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillableMetricManagedField_By_PkArgs, 'id'>>;
  BillableMetricManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['BillableMetricManagedField']>, "BillableMetricManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionBillableMetricManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  BillableMetric_aggregate?: SubscriptionResolver<ResolversTypes['BillableMetric_aggregate'], "BillableMetric_aggregate", ParentType, ContextType, Partial<SubscriptionBillableMetric_AggregateArgs>>;
  BillableMetric_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillableMetric']>, "BillableMetric_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillableMetric_By_PkArgs, 'id'>>;
  BillableMetric_stream?: SubscriptionResolver<Array<ResolversTypes['BillableMetric']>, "BillableMetric_stream", ParentType, ContextType, RequireFields<SubscriptionBillableMetric_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingFrequencyEnum?: SubscriptionResolver<Array<ResolversTypes['BillingFrequencyEnum']>, "BillingFrequencyEnum", ParentType, ContextType, Partial<SubscriptionBillingFrequencyEnumArgs>>;
  BillingFrequencyEnum_aggregate?: SubscriptionResolver<ResolversTypes['BillingFrequencyEnum_aggregate'], "BillingFrequencyEnum_aggregate", ParentType, ContextType, Partial<SubscriptionBillingFrequencyEnum_AggregateArgs>>;
  BillingFrequencyEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingFrequencyEnum']>, "BillingFrequencyEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingFrequencyEnum_By_PkArgs, 'value'>>;
  BillingFrequencyEnum_stream?: SubscriptionResolver<Array<ResolversTypes['BillingFrequencyEnum']>, "BillingFrequencyEnum_stream", ParentType, ContextType, RequireFields<SubscriptionBillingFrequencyEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingMetricAggregateEnum?: SubscriptionResolver<Array<ResolversTypes['BillingMetricAggregateEnum']>, "BillingMetricAggregateEnum", ParentType, ContextType, Partial<SubscriptionBillingMetricAggregateEnumArgs>>;
  BillingMetricAggregateEnum_aggregate?: SubscriptionResolver<ResolversTypes['BillingMetricAggregateEnum_aggregate'], "BillingMetricAggregateEnum_aggregate", ParentType, ContextType, Partial<SubscriptionBillingMetricAggregateEnum_AggregateArgs>>;
  BillingMetricAggregateEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingMetricAggregateEnum']>, "BillingMetricAggregateEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingMetricAggregateEnum_By_PkArgs, 'value'>>;
  BillingMetricAggregateEnum_stream?: SubscriptionResolver<Array<ResolversTypes['BillingMetricAggregateEnum']>, "BillingMetricAggregateEnum_stream", ParentType, ContextType, RequireFields<SubscriptionBillingMetricAggregateEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingProviderCustomer?: SubscriptionResolver<Array<ResolversTypes['BillingProviderCustomer']>, "BillingProviderCustomer", ParentType, ContextType, Partial<SubscriptionBillingProviderCustomerArgs>>;
  BillingProviderCustomer_aggregate?: SubscriptionResolver<ResolversTypes['BillingProviderCustomer_aggregate'], "BillingProviderCustomer_aggregate", ParentType, ContextType, Partial<SubscriptionBillingProviderCustomer_AggregateArgs>>;
  BillingProviderCustomer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingProviderCustomer']>, "BillingProviderCustomer_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingProviderCustomer_By_PkArgs, 'billing_provider' | 'customer_id'>>;
  BillingProviderCustomer_stream?: SubscriptionResolver<Array<ResolversTypes['BillingProviderCustomer']>, "BillingProviderCustomer_stream", ParentType, ContextType, RequireFields<SubscriptionBillingProviderCustomer_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingProviderEnum?: SubscriptionResolver<Array<ResolversTypes['BillingProviderEnum']>, "BillingProviderEnum", ParentType, ContextType, Partial<SubscriptionBillingProviderEnumArgs>>;
  BillingProviderEnum_aggregate?: SubscriptionResolver<ResolversTypes['BillingProviderEnum_aggregate'], "BillingProviderEnum_aggregate", ParentType, ContextType, Partial<SubscriptionBillingProviderEnum_AggregateArgs>>;
  BillingProviderEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingProviderEnum']>, "BillingProviderEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingProviderEnum_By_PkArgs, 'value'>>;
  BillingProviderEnum_stream?: SubscriptionResolver<Array<ResolversTypes['BillingProviderEnum']>, "BillingProviderEnum_stream", ParentType, ContextType, RequireFields<SubscriptionBillingProviderEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingProviderInvoice?: SubscriptionResolver<Array<ResolversTypes['BillingProviderInvoice']>, "BillingProviderInvoice", ParentType, ContextType, Partial<SubscriptionBillingProviderInvoiceArgs>>;
  BillingProviderInvoice_aggregate?: SubscriptionResolver<ResolversTypes['BillingProviderInvoice_aggregate'], "BillingProviderInvoice_aggregate", ParentType, ContextType, Partial<SubscriptionBillingProviderInvoice_AggregateArgs>>;
  BillingProviderInvoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingProviderInvoice']>, "BillingProviderInvoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingProviderInvoice_By_PkArgs, 'id'>>;
  BillingProviderInvoice_stream?: SubscriptionResolver<Array<ResolversTypes['BillingProviderInvoice']>, "BillingProviderInvoice_stream", ParentType, ContextType, RequireFields<SubscriptionBillingProviderInvoice_StreamArgs, 'batch_size' | 'cursor'>>;
  BillingProviderToken?: SubscriptionResolver<Array<ResolversTypes['BillingProviderToken']>, "BillingProviderToken", ParentType, ContextType, Partial<SubscriptionBillingProviderTokenArgs>>;
  BillingProviderToken_aggregate?: SubscriptionResolver<ResolversTypes['BillingProviderToken_aggregate'], "BillingProviderToken_aggregate", ParentType, ContextType, Partial<SubscriptionBillingProviderToken_AggregateArgs>>;
  BillingProviderToken_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['BillingProviderToken']>, "BillingProviderToken_by_pk", ParentType, ContextType, RequireFields<SubscriptionBillingProviderToken_By_PkArgs, 'billing_provider' | 'client_id' | 'environment_type'>>;
  BillingProviderToken_stream?: SubscriptionResolver<Array<ResolversTypes['BillingProviderToken']>, "BillingProviderToken_stream", ParentType, ContextType, RequireFields<SubscriptionBillingProviderToken_StreamArgs, 'batch_size' | 'cursor'>>;
  ChargeManagedField?: SubscriptionResolver<Array<ResolversTypes['ChargeManagedField']>, "ChargeManagedField", ParentType, ContextType, Partial<SubscriptionChargeManagedFieldArgs>>;
  ChargeManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ChargeManagedField_aggregate'], "ChargeManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionChargeManagedField_AggregateArgs>>;
  ChargeManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ChargeManagedField']>, "ChargeManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionChargeManagedField_By_PkArgs, 'id'>>;
  ChargeManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ChargeManagedField']>, "ChargeManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionChargeManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ChargeTypeEnum?: SubscriptionResolver<Array<ResolversTypes['ChargeTypeEnum']>, "ChargeTypeEnum", ParentType, ContextType, Partial<SubscriptionChargeTypeEnumArgs>>;
  ChargeTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['ChargeTypeEnum_aggregate'], "ChargeTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionChargeTypeEnum_AggregateArgs>>;
  ChargeTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ChargeTypeEnum']>, "ChargeTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionChargeTypeEnum_By_PkArgs, 'value'>>;
  ChargeTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['ChargeTypeEnum']>, "ChargeTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionChargeTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  Client?: SubscriptionResolver<Array<ResolversTypes['Client']>, "Client", ParentType, ContextType, Partial<SubscriptionClientArgs>>;
  ClientConfig?: SubscriptionResolver<Array<ResolversTypes['ClientConfig']>, "ClientConfig", ParentType, ContextType, Partial<SubscriptionClientConfigArgs>>;
  ClientConfigKeyEnum?: SubscriptionResolver<Array<ResolversTypes['ClientConfigKeyEnum']>, "ClientConfigKeyEnum", ParentType, ContextType, Partial<SubscriptionClientConfigKeyEnumArgs>>;
  ClientConfigKeyEnum_aggregate?: SubscriptionResolver<ResolversTypes['ClientConfigKeyEnum_aggregate'], "ClientConfigKeyEnum_aggregate", ParentType, ContextType, Partial<SubscriptionClientConfigKeyEnum_AggregateArgs>>;
  ClientConfigKeyEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ClientConfigKeyEnum']>, "ClientConfigKeyEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionClientConfigKeyEnum_By_PkArgs, 'value'>>;
  ClientConfigKeyEnum_stream?: SubscriptionResolver<Array<ResolversTypes['ClientConfigKeyEnum']>, "ClientConfigKeyEnum_stream", ParentType, ContextType, RequireFields<SubscriptionClientConfigKeyEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  ClientConfig_aggregate?: SubscriptionResolver<ResolversTypes['ClientConfig_aggregate'], "ClientConfig_aggregate", ParentType, ContextType, Partial<SubscriptionClientConfig_AggregateArgs>>;
  ClientConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ClientConfig']>, "ClientConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionClientConfig_By_PkArgs, 'id'>>;
  ClientConfig_stream?: SubscriptionResolver<Array<ResolversTypes['ClientConfig']>, "ClientConfig_stream", ParentType, ContextType, RequireFields<SubscriptionClientConfig_StreamArgs, 'batch_size' | 'cursor'>>;
  Client_aggregate?: SubscriptionResolver<ResolversTypes['Client_aggregate'], "Client_aggregate", ParentType, ContextType, Partial<SubscriptionClient_AggregateArgs>>;
  Client_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Client']>, "Client_by_pk", ParentType, ContextType, RequireFields<SubscriptionClient_By_PkArgs, 'id'>>;
  Client_stream?: SubscriptionResolver<Array<ResolversTypes['Client']>, "Client_stream", ParentType, ContextType, RequireFields<SubscriptionClient_StreamArgs, 'batch_size' | 'cursor'>>;
  CollectionScheduleEnum?: SubscriptionResolver<Array<ResolversTypes['CollectionScheduleEnum']>, "CollectionScheduleEnum", ParentType, ContextType, Partial<SubscriptionCollectionScheduleEnumArgs>>;
  CollectionScheduleEnum_aggregate?: SubscriptionResolver<ResolversTypes['CollectionScheduleEnum_aggregate'], "CollectionScheduleEnum_aggregate", ParentType, ContextType, Partial<SubscriptionCollectionScheduleEnum_AggregateArgs>>;
  CollectionScheduleEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CollectionScheduleEnum']>, "CollectionScheduleEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionCollectionScheduleEnum_By_PkArgs, 'value'>>;
  CollectionScheduleEnum_stream?: SubscriptionResolver<Array<ResolversTypes['CollectionScheduleEnum']>, "CollectionScheduleEnum_stream", ParentType, ContextType, RequireFields<SubscriptionCollectionScheduleEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  CommitManagedField?: SubscriptionResolver<Array<ResolversTypes['CommitManagedField']>, "CommitManagedField", ParentType, ContextType, Partial<SubscriptionCommitManagedFieldArgs>>;
  CommitManagedField_aggregate?: SubscriptionResolver<ResolversTypes['CommitManagedField_aggregate'], "CommitManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionCommitManagedField_AggregateArgs>>;
  CommitManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CommitManagedField']>, "CommitManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionCommitManagedField_By_PkArgs, 'id'>>;
  CommitManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['CommitManagedField']>, "CommitManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionCommitManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeCharge?: SubscriptionResolver<Array<ResolversTypes['CompositeCharge']>, "CompositeCharge", ParentType, ContextType, Partial<SubscriptionCompositeChargeArgs>>;
  CompositeChargeAdjustment?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeAdjustment']>, "CompositeChargeAdjustment", ParentType, ContextType, Partial<SubscriptionCompositeChargeAdjustmentArgs>>;
  CompositeChargeAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['CompositeChargeAdjustment_aggregate'], "CompositeChargeAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeChargeAdjustment_AggregateArgs>>;
  CompositeChargeAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CompositeChargeAdjustment']>, "CompositeChargeAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeAdjustment_By_PkArgs, 'id'>>;
  CompositeChargeAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeAdjustment']>, "CompositeChargeAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeChargePricingFactor?: SubscriptionResolver<Array<ResolversTypes['CompositeChargePricingFactor']>, "CompositeChargePricingFactor", ParentType, ContextType, Partial<SubscriptionCompositeChargePricingFactorArgs>>;
  CompositeChargePricingFactor_aggregate?: SubscriptionResolver<ResolversTypes['CompositeChargePricingFactor_aggregate'], "CompositeChargePricingFactor_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeChargePricingFactor_AggregateArgs>>;
  CompositeChargePricingFactor_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeChargePricingFactor']>, "CompositeChargePricingFactor_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeChargePricingFactor_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeChargeTier?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTier']>, "CompositeChargeTier", ParentType, ContextType, Partial<SubscriptionCompositeChargeTierArgs>>;
  CompositeChargeTierAdjustment?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, "CompositeChargeTierAdjustment", ParentType, ContextType, Partial<SubscriptionCompositeChargeTierAdjustmentArgs>>;
  CompositeChargeTierAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['CompositeChargeTierAdjustment_aggregate'], "CompositeChargeTierAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeChargeTierAdjustment_AggregateArgs>>;
  CompositeChargeTierAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CompositeChargeTierAdjustment']>, "CompositeChargeTierAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTierAdjustment_By_PkArgs, 'id'>>;
  CompositeChargeTierAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTierAdjustment']>, "CompositeChargeTierAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTierAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeChargeTier_aggregate?: SubscriptionResolver<ResolversTypes['CompositeChargeTier_aggregate'], "CompositeChargeTier_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeChargeTier_AggregateArgs>>;
  CompositeChargeTier_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CompositeChargeTier']>, "CompositeChargeTier_by_pk", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTier_By_PkArgs, 'id'>>;
  CompositeChargeTier_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTier']>, "CompositeChargeTier_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTier_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeChargeTypeEnum?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTypeEnum']>, "CompositeChargeTypeEnum", ParentType, ContextType, Partial<SubscriptionCompositeChargeTypeEnumArgs>>;
  CompositeChargeTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['CompositeChargeTypeEnum_aggregate'], "CompositeChargeTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeChargeTypeEnum_AggregateArgs>>;
  CompositeChargeTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CompositeChargeTypeEnum']>, "CompositeChargeTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTypeEnum_By_PkArgs, 'value'>>;
  CompositeChargeTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeChargeTypeEnum']>, "CompositeChargeTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeChargeTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  CompositeCharge_aggregate?: SubscriptionResolver<ResolversTypes['CompositeCharge_aggregate'], "CompositeCharge_aggregate", ParentType, ContextType, Partial<SubscriptionCompositeCharge_AggregateArgs>>;
  CompositeCharge_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CompositeCharge']>, "CompositeCharge_by_pk", ParentType, ContextType, RequireFields<SubscriptionCompositeCharge_By_PkArgs, 'id'>>;
  CompositeCharge_stream?: SubscriptionResolver<Array<ResolversTypes['CompositeCharge']>, "CompositeCharge_stream", ParentType, ContextType, RequireFields<SubscriptionCompositeCharge_StreamArgs, 'batch_size' | 'cursor'>>;
  ContractCreditManagedField?: SubscriptionResolver<Array<ResolversTypes['ContractCreditManagedField']>, "ContractCreditManagedField", ParentType, ContextType, Partial<SubscriptionContractCreditManagedFieldArgs>>;
  ContractCreditManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ContractCreditManagedField_aggregate'], "ContractCreditManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionContractCreditManagedField_AggregateArgs>>;
  ContractCreditManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ContractCreditManagedField']>, "ContractCreditManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionContractCreditManagedField_By_PkArgs, 'id'>>;
  ContractCreditManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ContractCreditManagedField']>, "ContractCreditManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionContractCreditManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ContractManagedField?: SubscriptionResolver<Array<ResolversTypes['ContractManagedField']>, "ContractManagedField", ParentType, ContextType, Partial<SubscriptionContractManagedFieldArgs>>;
  ContractManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ContractManagedField_aggregate'], "ContractManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionContractManagedField_AggregateArgs>>;
  ContractManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ContractManagedField']>, "ContractManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionContractManagedField_By_PkArgs, 'id'>>;
  ContractManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ContractManagedField']>, "ContractManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionContractManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ContractProductManagedField?: SubscriptionResolver<Array<ResolversTypes['ContractProductManagedField']>, "ContractProductManagedField", ParentType, ContextType, Partial<SubscriptionContractProductManagedFieldArgs>>;
  ContractProductManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ContractProductManagedField_aggregate'], "ContractProductManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionContractProductManagedField_AggregateArgs>>;
  ContractProductManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ContractProductManagedField']>, "ContractProductManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionContractProductManagedField_By_PkArgs, 'id'>>;
  ContractProductManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ContractProductManagedField']>, "ContractProductManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionContractProductManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditAdjustment?: SubscriptionResolver<Array<ResolversTypes['CreditAdjustment']>, "CreditAdjustment", ParentType, ContextType, Partial<SubscriptionCreditAdjustmentArgs>>;
  CreditAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['CreditAdjustment_aggregate'], "CreditAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionCreditAdjustment_AggregateArgs>>;
  CreditAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditAdjustment']>, "CreditAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditAdjustment_By_PkArgs, 'id'>>;
  CreditAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['CreditAdjustment']>, "CreditAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionCreditAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditGrant?: SubscriptionResolver<Array<ResolversTypes['CreditGrant']>, "CreditGrant", ParentType, ContextType, Partial<SubscriptionCreditGrantArgs>>;
  CreditGrantDeduction?: SubscriptionResolver<Array<ResolversTypes['CreditGrantDeduction']>, "CreditGrantDeduction", ParentType, ContextType, Partial<SubscriptionCreditGrantDeductionArgs>>;
  CreditGrantDeduction_aggregate?: SubscriptionResolver<ResolversTypes['CreditGrantDeduction_aggregate'], "CreditGrantDeduction_aggregate", ParentType, ContextType, Partial<SubscriptionCreditGrantDeduction_AggregateArgs>>;
  CreditGrantDeduction_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditGrantDeduction']>, "CreditGrantDeduction_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditGrantDeduction_By_PkArgs, 'id'>>;
  CreditGrantDeduction_stream?: SubscriptionResolver<Array<ResolversTypes['CreditGrantDeduction']>, "CreditGrantDeduction_stream", ParentType, ContextType, RequireFields<SubscriptionCreditGrantDeduction_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditGrantHistory?: SubscriptionResolver<Array<ResolversTypes['CreditGrantHistory']>, "CreditGrantHistory", ParentType, ContextType, Partial<SubscriptionCreditGrantHistoryArgs>>;
  CreditGrantHistory_aggregate?: SubscriptionResolver<ResolversTypes['CreditGrantHistory_aggregate'], "CreditGrantHistory_aggregate", ParentType, ContextType, Partial<SubscriptionCreditGrantHistory_AggregateArgs>>;
  CreditGrantHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditGrantHistory']>, "CreditGrantHistory_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditGrantHistory_By_PkArgs, 'id'>>;
  CreditGrantHistory_stream?: SubscriptionResolver<Array<ResolversTypes['CreditGrantHistory']>, "CreditGrantHistory_stream", ParentType, ContextType, RequireFields<SubscriptionCreditGrantHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditGrantManagedField?: SubscriptionResolver<Array<ResolversTypes['CreditGrantManagedField']>, "CreditGrantManagedField", ParentType, ContextType, Partial<SubscriptionCreditGrantManagedFieldArgs>>;
  CreditGrantManagedField_aggregate?: SubscriptionResolver<ResolversTypes['CreditGrantManagedField_aggregate'], "CreditGrantManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionCreditGrantManagedField_AggregateArgs>>;
  CreditGrantManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditGrantManagedField']>, "CreditGrantManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditGrantManagedField_By_PkArgs, 'id'>>;
  CreditGrantManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['CreditGrantManagedField']>, "CreditGrantManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionCreditGrantManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditGrant_aggregate?: SubscriptionResolver<ResolversTypes['CreditGrant_aggregate'], "CreditGrant_aggregate", ParentType, ContextType, Partial<SubscriptionCreditGrant_AggregateArgs>>;
  CreditGrant_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditGrant']>, "CreditGrant_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditGrant_By_PkArgs, 'id'>>;
  CreditGrant_stream?: SubscriptionResolver<Array<ResolversTypes['CreditGrant']>, "CreditGrant_stream", ParentType, ContextType, RequireFields<SubscriptionCreditGrant_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditType?: SubscriptionResolver<Array<ResolversTypes['CreditType']>, "CreditType", ParentType, ContextType, Partial<SubscriptionCreditTypeArgs>>;
  CreditTypeConversion?: SubscriptionResolver<Array<ResolversTypes['CreditTypeConversion']>, "CreditTypeConversion", ParentType, ContextType, Partial<SubscriptionCreditTypeConversionArgs>>;
  CreditTypeConversionAdjustment?: SubscriptionResolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, "CreditTypeConversionAdjustment", ParentType, ContextType, Partial<SubscriptionCreditTypeConversionAdjustmentArgs>>;
  CreditTypeConversionAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['CreditTypeConversionAdjustment_aggregate'], "CreditTypeConversionAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionCreditTypeConversionAdjustment_AggregateArgs>>;
  CreditTypeConversionAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditTypeConversionAdjustment']>, "CreditTypeConversionAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditTypeConversionAdjustment_By_PkArgs, 'id'>>;
  CreditTypeConversionAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['CreditTypeConversionAdjustment']>, "CreditTypeConversionAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionCreditTypeConversionAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditTypeConversion_aggregate?: SubscriptionResolver<ResolversTypes['CreditTypeConversion_aggregate'], "CreditTypeConversion_aggregate", ParentType, ContextType, Partial<SubscriptionCreditTypeConversion_AggregateArgs>>;
  CreditTypeConversion_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditTypeConversion']>, "CreditTypeConversion_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditTypeConversion_By_PkArgs, 'id'>>;
  CreditTypeConversion_stream?: SubscriptionResolver<Array<ResolversTypes['CreditTypeConversion']>, "CreditTypeConversion_stream", ParentType, ContextType, RequireFields<SubscriptionCreditTypeConversion_StreamArgs, 'batch_size' | 'cursor'>>;
  CreditType_aggregate?: SubscriptionResolver<ResolversTypes['CreditType_aggregate'], "CreditType_aggregate", ParentType, ContextType, Partial<SubscriptionCreditType_AggregateArgs>>;
  CreditType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CreditType']>, "CreditType_by_pk", ParentType, ContextType, RequireFields<SubscriptionCreditType_By_PkArgs, 'id'>>;
  CreditType_stream?: SubscriptionResolver<Array<ResolversTypes['CreditType']>, "CreditType_stream", ParentType, ContextType, RequireFields<SubscriptionCreditType_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomPricing?: SubscriptionResolver<Array<ResolversTypes['CustomPricing']>, "CustomPricing", ParentType, ContextType, Partial<SubscriptionCustomPricingArgs>>;
  CustomPricing_aggregate?: SubscriptionResolver<ResolversTypes['CustomPricing_aggregate'], "CustomPricing_aggregate", ParentType, ContextType, Partial<SubscriptionCustomPricing_AggregateArgs>>;
  CustomPricing_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomPricing']>, "CustomPricing_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomPricing_By_PkArgs, 'id'>>;
  CustomPricing_stream?: SubscriptionResolver<Array<ResolversTypes['CustomPricing']>, "CustomPricing_stream", ParentType, ContextType, RequireFields<SubscriptionCustomPricing_StreamArgs, 'batch_size' | 'cursor'>>;
  Customer?: SubscriptionResolver<Array<ResolversTypes['Customer']>, "Customer", ParentType, ContextType, Partial<SubscriptionCustomerArgs>>;
  CustomerAlert?: SubscriptionResolver<Array<ResolversTypes['CustomerAlert']>, "CustomerAlert", ParentType, ContextType, Partial<SubscriptionCustomerAlertArgs>>;
  CustomerAlertHistory?: SubscriptionResolver<Array<ResolversTypes['CustomerAlertHistory']>, "CustomerAlertHistory", ParentType, ContextType, Partial<SubscriptionCustomerAlertHistoryArgs>>;
  CustomerAlertHistory_aggregate?: SubscriptionResolver<ResolversTypes['CustomerAlertHistory_aggregate'], "CustomerAlertHistory_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerAlertHistory_AggregateArgs>>;
  CustomerAlertHistory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerAlertHistory']>, "CustomerAlertHistory_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerAlertHistory_By_PkArgs, 'id'>>;
  CustomerAlertHistory_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerAlertHistory']>, "CustomerAlertHistory_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerAlertHistory_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerAlertStatusEnum?: SubscriptionResolver<Array<ResolversTypes['CustomerAlertStatusEnum']>, "CustomerAlertStatusEnum", ParentType, ContextType, Partial<SubscriptionCustomerAlertStatusEnumArgs>>;
  CustomerAlertStatusEnum_aggregate?: SubscriptionResolver<ResolversTypes['CustomerAlertStatusEnum_aggregate'], "CustomerAlertStatusEnum_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerAlertStatusEnum_AggregateArgs>>;
  CustomerAlertStatusEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerAlertStatusEnum']>, "CustomerAlertStatusEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerAlertStatusEnum_By_PkArgs, 'value'>>;
  CustomerAlertStatusEnum_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerAlertStatusEnum']>, "CustomerAlertStatusEnum_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerAlertStatusEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerAlert_aggregate?: SubscriptionResolver<ResolversTypes['CustomerAlert_aggregate'], "CustomerAlert_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerAlert_AggregateArgs>>;
  CustomerAlert_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerAlert']>, "CustomerAlert_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerAlert_By_PkArgs, 'id'>>;
  CustomerAlert_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerAlert']>, "CustomerAlert_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerAlert_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerConfig?: SubscriptionResolver<Array<ResolversTypes['CustomerConfig']>, "CustomerConfig", ParentType, ContextType, Partial<SubscriptionCustomerConfigArgs>>;
  CustomerConfig_aggregate?: SubscriptionResolver<ResolversTypes['CustomerConfig_aggregate'], "CustomerConfig_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerConfig_AggregateArgs>>;
  CustomerConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerConfig']>, "CustomerConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerConfig_By_PkArgs, 'id'>>;
  CustomerConfig_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerConfig']>, "CustomerConfig_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerConfig_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerIngestAlias?: SubscriptionResolver<Array<ResolversTypes['CustomerIngestAlias']>, "CustomerIngestAlias", ParentType, ContextType, Partial<SubscriptionCustomerIngestAliasArgs>>;
  CustomerIngestAlias_aggregate?: SubscriptionResolver<ResolversTypes['CustomerIngestAlias_aggregate'], "CustomerIngestAlias_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerIngestAlias_AggregateArgs>>;
  CustomerIngestAlias_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerIngestAlias']>, "CustomerIngestAlias_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerIngestAlias_By_PkArgs, 'client_id' | 'customer_id' | 'ingest_alias'>>;
  CustomerIngestAlias_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerIngestAlias']>, "CustomerIngestAlias_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerIngestAlias_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerManagedField?: SubscriptionResolver<Array<ResolversTypes['CustomerManagedField']>, "CustomerManagedField", ParentType, ContextType, Partial<SubscriptionCustomerManagedFieldArgs>>;
  CustomerManagedField_aggregate?: SubscriptionResolver<ResolversTypes['CustomerManagedField_aggregate'], "CustomerManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerManagedField_AggregateArgs>>;
  CustomerManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerManagedField']>, "CustomerManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerManagedField_By_PkArgs, 'id'>>;
  CustomerManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerManagedField']>, "CustomerManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerPlan?: SubscriptionResolver<Array<ResolversTypes['CustomerPlan']>, "CustomerPlan", ParentType, ContextType, Partial<SubscriptionCustomerPlanArgs>>;
  CustomerPlanAdjustment?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanAdjustment']>, "CustomerPlanAdjustment", ParentType, ContextType, Partial<SubscriptionCustomerPlanAdjustmentArgs>>;
  CustomerPlanAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['CustomerPlanAdjustment_aggregate'], "CustomerPlanAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerPlanAdjustment_AggregateArgs>>;
  CustomerPlanAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPlanAdjustment']>, "CustomerPlanAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanAdjustment_By_PkArgs, 'id'>>;
  CustomerPlanAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanAdjustment']>, "CustomerPlanAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerPlanManagedField?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanManagedField']>, "CustomerPlanManagedField", ParentType, ContextType, Partial<SubscriptionCustomerPlanManagedFieldArgs>>;
  CustomerPlanManagedField_aggregate?: SubscriptionResolver<ResolversTypes['CustomerPlanManagedField_aggregate'], "CustomerPlanManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerPlanManagedField_AggregateArgs>>;
  CustomerPlanManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPlanManagedField']>, "CustomerPlanManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanManagedField_By_PkArgs, 'id'>>;
  CustomerPlanManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanManagedField']>, "CustomerPlanManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerPlanOneTimeCharge?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanOneTimeCharge']>, "CustomerPlanOneTimeCharge", ParentType, ContextType, Partial<SubscriptionCustomerPlanOneTimeChargeArgs>>;
  CustomerPlanOneTimeCharge_aggregate?: SubscriptionResolver<ResolversTypes['CustomerPlanOneTimeCharge_aggregate'], "CustomerPlanOneTimeCharge_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerPlanOneTimeCharge_AggregateArgs>>;
  CustomerPlanOneTimeCharge_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPlanOneTimeCharge']>, "CustomerPlanOneTimeCharge_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanOneTimeCharge_By_PkArgs, 'id'>>;
  CustomerPlanOneTimeCharge_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerPlanOneTimeCharge']>, "CustomerPlanOneTimeCharge_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerPlanOneTimeCharge_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerPlan_aggregate?: SubscriptionResolver<ResolversTypes['CustomerPlan_aggregate'], "CustomerPlan_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerPlan_AggregateArgs>>;
  CustomerPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerPlan']>, "CustomerPlan_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerPlan_By_PkArgs, 'id'>>;
  CustomerPlan_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerPlan']>, "CustomerPlan_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerPlan_StreamArgs, 'batch_size' | 'cursor'>>;
  CustomerRelationship?: SubscriptionResolver<Array<ResolversTypes['CustomerRelationship']>, "CustomerRelationship", ParentType, ContextType, Partial<SubscriptionCustomerRelationshipArgs>>;
  CustomerRelationship_aggregate?: SubscriptionResolver<ResolversTypes['CustomerRelationship_aggregate'], "CustomerRelationship_aggregate", ParentType, ContextType, Partial<SubscriptionCustomerRelationship_AggregateArgs>>;
  CustomerRelationship_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['CustomerRelationship']>, "CustomerRelationship_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomerRelationship_By_PkArgs, 'id'>>;
  CustomerRelationship_stream?: SubscriptionResolver<Array<ResolversTypes['CustomerRelationship']>, "CustomerRelationship_stream", ParentType, ContextType, RequireFields<SubscriptionCustomerRelationship_StreamArgs, 'batch_size' | 'cursor'>>;
  Customer_aggregate?: SubscriptionResolver<ResolversTypes['Customer_aggregate'], "Customer_aggregate", ParentType, ContextType, Partial<SubscriptionCustomer_AggregateArgs>>;
  Customer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Customer']>, "Customer_by_pk", ParentType, ContextType, RequireFields<SubscriptionCustomer_By_PkArgs, 'id'>>;
  Customer_stream?: SubscriptionResolver<Array<ResolversTypes['Customer']>, "Customer_stream", ParentType, ContextType, RequireFields<SubscriptionCustomer_StreamArgs, 'batch_size' | 'cursor'>>;
  DraftPlan?: SubscriptionResolver<Array<ResolversTypes['DraftPlan']>, "DraftPlan", ParentType, ContextType, Partial<SubscriptionDraftPlanArgs>>;
  DraftPlan_aggregate?: SubscriptionResolver<ResolversTypes['DraftPlan_aggregate'], "DraftPlan_aggregate", ParentType, ContextType, Partial<SubscriptionDraftPlan_AggregateArgs>>;
  DraftPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['DraftPlan']>, "DraftPlan_by_pk", ParentType, ContextType, RequireFields<SubscriptionDraftPlan_By_PkArgs, 'id'>>;
  DraftPlan_stream?: SubscriptionResolver<Array<ResolversTypes['DraftPlan']>, "DraftPlan_stream", ParentType, ContextType, RequireFields<SubscriptionDraftPlan_StreamArgs, 'batch_size' | 'cursor'>>;
  EnvironmentTypeEnum?: SubscriptionResolver<Array<ResolversTypes['EnvironmentTypeEnum']>, "EnvironmentTypeEnum", ParentType, ContextType, Partial<SubscriptionEnvironmentTypeEnumArgs>>;
  EnvironmentTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['EnvironmentTypeEnum_aggregate'], "EnvironmentTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionEnvironmentTypeEnum_AggregateArgs>>;
  EnvironmentTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['EnvironmentTypeEnum']>, "EnvironmentTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionEnvironmentTypeEnum_By_PkArgs, 'value'>>;
  EnvironmentTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['EnvironmentTypeEnum']>, "EnvironmentTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionEnvironmentTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  FlatFee?: SubscriptionResolver<Array<ResolversTypes['FlatFee']>, "FlatFee", ParentType, ContextType, Partial<SubscriptionFlatFeeArgs>>;
  FlatFeeAdjustment?: SubscriptionResolver<Array<ResolversTypes['FlatFeeAdjustment']>, "FlatFeeAdjustment", ParentType, ContextType, Partial<SubscriptionFlatFeeAdjustmentArgs>>;
  FlatFeeAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['FlatFeeAdjustment_aggregate'], "FlatFeeAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionFlatFeeAdjustment_AggregateArgs>>;
  FlatFeeAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['FlatFeeAdjustment']>, "FlatFeeAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionFlatFeeAdjustment_By_PkArgs, 'id'>>;
  FlatFeeAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['FlatFeeAdjustment']>, "FlatFeeAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionFlatFeeAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  FlatFee_aggregate?: SubscriptionResolver<ResolversTypes['FlatFee_aggregate'], "FlatFee_aggregate", ParentType, ContextType, Partial<SubscriptionFlatFee_AggregateArgs>>;
  FlatFee_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['FlatFee']>, "FlatFee_by_pk", ParentType, ContextType, RequireFields<SubscriptionFlatFee_By_PkArgs, 'id'>>;
  FlatFee_stream?: SubscriptionResolver<Array<ResolversTypes['FlatFee']>, "FlatFee_stream", ParentType, ContextType, RequireFields<SubscriptionFlatFee_StreamArgs, 'batch_size' | 'cursor'>>;
  InvoiceExternalStatusEnum?: SubscriptionResolver<Array<ResolversTypes['InvoiceExternalStatusEnum']>, "InvoiceExternalStatusEnum", ParentType, ContextType, Partial<SubscriptionInvoiceExternalStatusEnumArgs>>;
  InvoiceExternalStatusEnum_aggregate?: SubscriptionResolver<ResolversTypes['InvoiceExternalStatusEnum_aggregate'], "InvoiceExternalStatusEnum_aggregate", ParentType, ContextType, Partial<SubscriptionInvoiceExternalStatusEnum_AggregateArgs>>;
  InvoiceExternalStatusEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceExternalStatusEnum']>, "InvoiceExternalStatusEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionInvoiceExternalStatusEnum_By_PkArgs, 'value'>>;
  InvoiceExternalStatusEnum_stream?: SubscriptionResolver<Array<ResolversTypes['InvoiceExternalStatusEnum']>, "InvoiceExternalStatusEnum_stream", ParentType, ContextType, RequireFields<SubscriptionInvoiceExternalStatusEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  InvoiceManagedField?: SubscriptionResolver<Array<ResolversTypes['InvoiceManagedField']>, "InvoiceManagedField", ParentType, ContextType, Partial<SubscriptionInvoiceManagedFieldArgs>>;
  InvoiceManagedField_aggregate?: SubscriptionResolver<ResolversTypes['InvoiceManagedField_aggregate'], "InvoiceManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionInvoiceManagedField_AggregateArgs>>;
  InvoiceManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['InvoiceManagedField']>, "InvoiceManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionInvoiceManagedField_By_PkArgs, 'id'>>;
  InvoiceManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['InvoiceManagedField']>, "InvoiceManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionInvoiceManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ManagedEntityEnum?: SubscriptionResolver<Array<ResolversTypes['ManagedEntityEnum']>, "ManagedEntityEnum", ParentType, ContextType, Partial<SubscriptionManagedEntityEnumArgs>>;
  ManagedEntityEnum_aggregate?: SubscriptionResolver<ResolversTypes['ManagedEntityEnum_aggregate'], "ManagedEntityEnum_aggregate", ParentType, ContextType, Partial<SubscriptionManagedEntityEnum_AggregateArgs>>;
  ManagedEntityEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ManagedEntityEnum']>, "ManagedEntityEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionManagedEntityEnum_By_PkArgs, 'value'>>;
  ManagedEntityEnum_stream?: SubscriptionResolver<Array<ResolversTypes['ManagedEntityEnum']>, "ManagedEntityEnum_stream", ParentType, ContextType, RequireFields<SubscriptionManagedEntityEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  ManagedFieldKey?: SubscriptionResolver<Array<ResolversTypes['ManagedFieldKey']>, "ManagedFieldKey", ParentType, ContextType, Partial<SubscriptionManagedFieldKeyArgs>>;
  ManagedFieldKey_aggregate?: SubscriptionResolver<ResolversTypes['ManagedFieldKey_aggregate'], "ManagedFieldKey_aggregate", ParentType, ContextType, Partial<SubscriptionManagedFieldKey_AggregateArgs>>;
  ManagedFieldKey_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ManagedFieldKey']>, "ManagedFieldKey_by_pk", ParentType, ContextType, RequireFields<SubscriptionManagedFieldKey_By_PkArgs, 'id'>>;
  ManagedFieldKey_stream?: SubscriptionResolver<Array<ResolversTypes['ManagedFieldKey']>, "ManagedFieldKey_stream", ParentType, ContextType, RequireFields<SubscriptionManagedFieldKey_StreamArgs, 'batch_size' | 'cursor'>>;
  ManagedFieldVendorMapping?: SubscriptionResolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, "ManagedFieldVendorMapping", ParentType, ContextType, Partial<SubscriptionManagedFieldVendorMappingArgs>>;
  ManagedFieldVendorMapping_aggregate?: SubscriptionResolver<ResolversTypes['ManagedFieldVendorMapping_aggregate'], "ManagedFieldVendorMapping_aggregate", ParentType, ContextType, Partial<SubscriptionManagedFieldVendorMapping_AggregateArgs>>;
  ManagedFieldVendorMapping_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ManagedFieldVendorMapping']>, "ManagedFieldVendorMapping_by_pk", ParentType, ContextType, RequireFields<SubscriptionManagedFieldVendorMapping_By_PkArgs, 'id'>>;
  ManagedFieldVendorMapping_stream?: SubscriptionResolver<Array<ResolversTypes['ManagedFieldVendorMapping']>, "ManagedFieldVendorMapping_stream", ParentType, ContextType, RequireFields<SubscriptionManagedFieldVendorMapping_StreamArgs, 'batch_size' | 'cursor'>>;
  ManagedVendorEnum?: SubscriptionResolver<Array<ResolversTypes['ManagedVendorEnum']>, "ManagedVendorEnum", ParentType, ContextType, Partial<SubscriptionManagedVendorEnumArgs>>;
  ManagedVendorEnum_aggregate?: SubscriptionResolver<ResolversTypes['ManagedVendorEnum_aggregate'], "ManagedVendorEnum_aggregate", ParentType, ContextType, Partial<SubscriptionManagedVendorEnum_AggregateArgs>>;
  ManagedVendorEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ManagedVendorEnum']>, "ManagedVendorEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionManagedVendorEnum_By_PkArgs, 'value'>>;
  ManagedVendorEnum_stream?: SubscriptionResolver<Array<ResolversTypes['ManagedVendorEnum']>, "ManagedVendorEnum_stream", ParentType, ContextType, RequireFields<SubscriptionManagedVendorEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  Minimum?: SubscriptionResolver<Array<ResolversTypes['Minimum']>, "Minimum", ParentType, ContextType, Partial<SubscriptionMinimumArgs>>;
  Minimum_aggregate?: SubscriptionResolver<ResolversTypes['Minimum_aggregate'], "Minimum_aggregate", ParentType, ContextType, Partial<SubscriptionMinimum_AggregateArgs>>;
  Minimum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Minimum']>, "Minimum_by_pk", ParentType, ContextType, RequireFields<SubscriptionMinimum_By_PkArgs, 'id'>>;
  Minimum_stream?: SubscriptionResolver<Array<ResolversTypes['Minimum']>, "Minimum_stream", ParentType, ContextType, RequireFields<SubscriptionMinimum_StreamArgs, 'batch_size' | 'cursor'>>;
  Plan?: SubscriptionResolver<Array<ResolversTypes['Plan']>, "Plan", ParentType, ContextType, Partial<SubscriptionPlanArgs>>;
  PlanManagedField?: SubscriptionResolver<Array<ResolversTypes['PlanManagedField']>, "PlanManagedField", ParentType, ContextType, Partial<SubscriptionPlanManagedFieldArgs>>;
  PlanManagedField_aggregate?: SubscriptionResolver<ResolversTypes['PlanManagedField_aggregate'], "PlanManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionPlanManagedField_AggregateArgs>>;
  PlanManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PlanManagedField']>, "PlanManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionPlanManagedField_By_PkArgs, 'id'>>;
  PlanManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['PlanManagedField']>, "PlanManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionPlanManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  Plan_aggregate?: SubscriptionResolver<ResolversTypes['Plan_aggregate'], "Plan_aggregate", ParentType, ContextType, Partial<SubscriptionPlan_AggregateArgs>>;
  Plan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Plan']>, "Plan_by_pk", ParentType, ContextType, RequireFields<SubscriptionPlan_By_PkArgs, 'id'>>;
  Plan_stream?: SubscriptionResolver<Array<ResolversTypes['Plan']>, "Plan_stream", ParentType, ContextType, RequireFields<SubscriptionPlan_StreamArgs, 'batch_size' | 'cursor'>>;
  Price?: SubscriptionResolver<Array<ResolversTypes['Price']>, "Price", ParentType, ContextType, Partial<SubscriptionPriceArgs>>;
  PriceAdjustment?: SubscriptionResolver<Array<ResolversTypes['PriceAdjustment']>, "PriceAdjustment", ParentType, ContextType, Partial<SubscriptionPriceAdjustmentArgs>>;
  PriceAdjustmentTypeEnum?: SubscriptionResolver<Array<ResolversTypes['PriceAdjustmentTypeEnum']>, "PriceAdjustmentTypeEnum", ParentType, ContextType, Partial<SubscriptionPriceAdjustmentTypeEnumArgs>>;
  PriceAdjustmentTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['PriceAdjustmentTypeEnum_aggregate'], "PriceAdjustmentTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionPriceAdjustmentTypeEnum_AggregateArgs>>;
  PriceAdjustmentTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PriceAdjustmentTypeEnum']>, "PriceAdjustmentTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionPriceAdjustmentTypeEnum_By_PkArgs, 'value'>>;
  PriceAdjustmentTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['PriceAdjustmentTypeEnum']>, "PriceAdjustmentTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionPriceAdjustmentTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  PriceAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['PriceAdjustment_aggregate'], "PriceAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionPriceAdjustment_AggregateArgs>>;
  PriceAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PriceAdjustment']>, "PriceAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionPriceAdjustment_By_PkArgs, 'id'>>;
  PriceAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['PriceAdjustment']>, "PriceAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionPriceAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  Price_aggregate?: SubscriptionResolver<ResolversTypes['Price_aggregate'], "Price_aggregate", ParentType, ContextType, Partial<SubscriptionPrice_AggregateArgs>>;
  Price_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Price']>, "Price_by_pk", ParentType, ContextType, RequireFields<SubscriptionPrice_By_PkArgs, 'id'>>;
  Price_stream?: SubscriptionResolver<Array<ResolversTypes['Price']>, "Price_stream", ParentType, ContextType, RequireFields<SubscriptionPrice_StreamArgs, 'batch_size' | 'cursor'>>;
  PricedProduct?: SubscriptionResolver<Array<ResolversTypes['PricedProduct']>, "PricedProduct", ParentType, ContextType, Partial<SubscriptionPricedProductArgs>>;
  PricedProductAdjustment?: SubscriptionResolver<Array<ResolversTypes['PricedProductAdjustment']>, "PricedProductAdjustment", ParentType, ContextType, Partial<SubscriptionPricedProductAdjustmentArgs>>;
  PricedProductAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['PricedProductAdjustment_aggregate'], "PricedProductAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionPricedProductAdjustment_AggregateArgs>>;
  PricedProductAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PricedProductAdjustment']>, "PricedProductAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionPricedProductAdjustment_By_PkArgs, 'id'>>;
  PricedProductAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['PricedProductAdjustment']>, "PricedProductAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionPricedProductAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  PricedProductPricingFactor?: SubscriptionResolver<Array<ResolversTypes['PricedProductPricingFactor']>, "PricedProductPricingFactor", ParentType, ContextType, Partial<SubscriptionPricedProductPricingFactorArgs>>;
  PricedProductPricingFactorAdjustment?: SubscriptionResolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, "PricedProductPricingFactorAdjustment", ParentType, ContextType, Partial<SubscriptionPricedProductPricingFactorAdjustmentArgs>>;
  PricedProductPricingFactorAdjustment_aggregate?: SubscriptionResolver<ResolversTypes['PricedProductPricingFactorAdjustment_aggregate'], "PricedProductPricingFactorAdjustment_aggregate", ParentType, ContextType, Partial<SubscriptionPricedProductPricingFactorAdjustment_AggregateArgs>>;
  PricedProductPricingFactorAdjustment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PricedProductPricingFactorAdjustment']>, "PricedProductPricingFactorAdjustment_by_pk", ParentType, ContextType, RequireFields<SubscriptionPricedProductPricingFactorAdjustment_By_PkArgs, 'id'>>;
  PricedProductPricingFactorAdjustment_stream?: SubscriptionResolver<Array<ResolversTypes['PricedProductPricingFactorAdjustment']>, "PricedProductPricingFactorAdjustment_stream", ParentType, ContextType, RequireFields<SubscriptionPricedProductPricingFactorAdjustment_StreamArgs, 'batch_size' | 'cursor'>>;
  PricedProductPricingFactor_aggregate?: SubscriptionResolver<ResolversTypes['PricedProductPricingFactor_aggregate'], "PricedProductPricingFactor_aggregate", ParentType, ContextType, Partial<SubscriptionPricedProductPricingFactor_AggregateArgs>>;
  PricedProductPricingFactor_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PricedProductPricingFactor']>, "PricedProductPricingFactor_by_pk", ParentType, ContextType, RequireFields<SubscriptionPricedProductPricingFactor_By_PkArgs, 'id'>>;
  PricedProductPricingFactor_stream?: SubscriptionResolver<Array<ResolversTypes['PricedProductPricingFactor']>, "PricedProductPricingFactor_stream", ParentType, ContextType, RequireFields<SubscriptionPricedProductPricingFactor_StreamArgs, 'batch_size' | 'cursor'>>;
  PricedProduct_aggregate?: SubscriptionResolver<ResolversTypes['PricedProduct_aggregate'], "PricedProduct_aggregate", ParentType, ContextType, Partial<SubscriptionPricedProduct_AggregateArgs>>;
  PricedProduct_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['PricedProduct']>, "PricedProduct_by_pk", ParentType, ContextType, RequireFields<SubscriptionPricedProduct_By_PkArgs, 'id'>>;
  PricedProduct_stream?: SubscriptionResolver<Array<ResolversTypes['PricedProduct']>, "PricedProduct_stream", ParentType, ContextType, RequireFields<SubscriptionPricedProduct_StreamArgs, 'batch_size' | 'cursor'>>;
  ProServiceManagedField?: SubscriptionResolver<Array<ResolversTypes['ProServiceManagedField']>, "ProServiceManagedField", ParentType, ContextType, Partial<SubscriptionProServiceManagedFieldArgs>>;
  ProServiceManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ProServiceManagedField_aggregate'], "ProServiceManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionProServiceManagedField_AggregateArgs>>;
  ProServiceManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ProServiceManagedField']>, "ProServiceManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionProServiceManagedField_By_PkArgs, 'id'>>;
  ProServiceManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ProServiceManagedField']>, "ProServiceManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionProServiceManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  Product?: SubscriptionResolver<Array<ResolversTypes['Product']>, "Product", ParentType, ContextType, Partial<SubscriptionProductArgs>>;
  ProductManagedField?: SubscriptionResolver<Array<ResolversTypes['ProductManagedField']>, "ProductManagedField", ParentType, ContextType, Partial<SubscriptionProductManagedFieldArgs>>;
  ProductManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ProductManagedField_aggregate'], "ProductManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionProductManagedField_AggregateArgs>>;
  ProductManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ProductManagedField']>, "ProductManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionProductManagedField_By_PkArgs, 'id'>>;
  ProductManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ProductManagedField']>, "ProductManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionProductManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ProductPricingFactor?: SubscriptionResolver<Array<ResolversTypes['ProductPricingFactor']>, "ProductPricingFactor", ParentType, ContextType, Partial<SubscriptionProductPricingFactorArgs>>;
  ProductPricingFactor_aggregate?: SubscriptionResolver<ResolversTypes['ProductPricingFactor_aggregate'], "ProductPricingFactor_aggregate", ParentType, ContextType, Partial<SubscriptionProductPricingFactor_AggregateArgs>>;
  ProductPricingFactor_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ProductPricingFactor']>, "ProductPricingFactor_by_pk", ParentType, ContextType, RequireFields<SubscriptionProductPricingFactor_By_PkArgs, 'id'>>;
  ProductPricingFactor_stream?: SubscriptionResolver<Array<ResolversTypes['ProductPricingFactor']>, "ProductPricingFactor_stream", ParentType, ContextType, RequireFields<SubscriptionProductPricingFactor_StreamArgs, 'batch_size' | 'cursor'>>;
  Product_aggregate?: SubscriptionResolver<ResolversTypes['Product_aggregate'], "Product_aggregate", ParentType, ContextType, Partial<SubscriptionProduct_AggregateArgs>>;
  Product_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Product']>, "Product_by_pk", ParentType, ContextType, RequireFields<SubscriptionProduct_By_PkArgs, 'id'>>;
  Product_stream?: SubscriptionResolver<Array<ResolversTypes['Product']>, "Product_stream", ParentType, ContextType, RequireFields<SubscriptionProduct_StreamArgs, 'batch_size' | 'cursor'>>;
  RateCardManagedField?: SubscriptionResolver<Array<ResolversTypes['RateCardManagedField']>, "RateCardManagedField", ParentType, ContextType, Partial<SubscriptionRateCardManagedFieldArgs>>;
  RateCardManagedField_aggregate?: SubscriptionResolver<ResolversTypes['RateCardManagedField_aggregate'], "RateCardManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionRateCardManagedField_AggregateArgs>>;
  RateCardManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['RateCardManagedField']>, "RateCardManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionRateCardManagedField_By_PkArgs, 'id'>>;
  RateCardManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['RateCardManagedField']>, "RateCardManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionRateCardManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  RecurringCreditGrant?: SubscriptionResolver<Array<ResolversTypes['RecurringCreditGrant']>, "RecurringCreditGrant", ParentType, ContextType, Partial<SubscriptionRecurringCreditGrantArgs>>;
  RecurringCreditGrant_aggregate?: SubscriptionResolver<ResolversTypes['RecurringCreditGrant_aggregate'], "RecurringCreditGrant_aggregate", ParentType, ContextType, Partial<SubscriptionRecurringCreditGrant_AggregateArgs>>;
  RecurringCreditGrant_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['RecurringCreditGrant']>, "RecurringCreditGrant_by_pk", ParentType, ContextType, RequireFields<SubscriptionRecurringCreditGrant_By_PkArgs, 'id'>>;
  RecurringCreditGrant_stream?: SubscriptionResolver<Array<ResolversTypes['RecurringCreditGrant']>, "RecurringCreditGrant_stream", ParentType, ContextType, RequireFields<SubscriptionRecurringCreditGrant_StreamArgs, 'batch_size' | 'cursor'>>;
  Role?: SubscriptionResolver<Array<ResolversTypes['Role']>, "Role", ParentType, ContextType, Partial<SubscriptionRoleArgs>>;
  Role_aggregate?: SubscriptionResolver<ResolversTypes['Role_aggregate'], "Role_aggregate", ParentType, ContextType, Partial<SubscriptionRole_AggregateArgs>>;
  Role_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Role']>, "Role_by_pk", ParentType, ContextType, RequireFields<SubscriptionRole_By_PkArgs, 'id'>>;
  Role_stream?: SubscriptionResolver<Array<ResolversTypes['Role']>, "Role_stream", ParentType, ContextType, RequireFields<SubscriptionRole_StreamArgs, 'batch_size' | 'cursor'>>;
  RoundingBehaviorEnum?: SubscriptionResolver<Array<ResolversTypes['RoundingBehaviorEnum']>, "RoundingBehaviorEnum", ParentType, ContextType, Partial<SubscriptionRoundingBehaviorEnumArgs>>;
  RoundingBehaviorEnum_aggregate?: SubscriptionResolver<ResolversTypes['RoundingBehaviorEnum_aggregate'], "RoundingBehaviorEnum_aggregate", ParentType, ContextType, Partial<SubscriptionRoundingBehaviorEnum_AggregateArgs>>;
  RoundingBehaviorEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['RoundingBehaviorEnum']>, "RoundingBehaviorEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionRoundingBehaviorEnum_By_PkArgs, 'value'>>;
  RoundingBehaviorEnum_stream?: SubscriptionResolver<Array<ResolversTypes['RoundingBehaviorEnum']>, "RoundingBehaviorEnum_stream", ParentType, ContextType, RequireFields<SubscriptionRoundingBehaviorEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  ScheduledChargeManagedField?: SubscriptionResolver<Array<ResolversTypes['ScheduledChargeManagedField']>, "ScheduledChargeManagedField", ParentType, ContextType, Partial<SubscriptionScheduledChargeManagedFieldArgs>>;
  ScheduledChargeManagedField_aggregate?: SubscriptionResolver<ResolversTypes['ScheduledChargeManagedField_aggregate'], "ScheduledChargeManagedField_aggregate", ParentType, ContextType, Partial<SubscriptionScheduledChargeManagedField_AggregateArgs>>;
  ScheduledChargeManagedField_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ScheduledChargeManagedField']>, "ScheduledChargeManagedField_by_pk", ParentType, ContextType, RequireFields<SubscriptionScheduledChargeManagedField_By_PkArgs, 'id'>>;
  ScheduledChargeManagedField_stream?: SubscriptionResolver<Array<ResolversTypes['ScheduledChargeManagedField']>, "ScheduledChargeManagedField_stream", ParentType, ContextType, RequireFields<SubscriptionScheduledChargeManagedField_StreamArgs, 'batch_size' | 'cursor'>>;
  ServicePeriodStartOnEnum?: SubscriptionResolver<Array<ResolversTypes['ServicePeriodStartOnEnum']>, "ServicePeriodStartOnEnum", ParentType, ContextType, Partial<SubscriptionServicePeriodStartOnEnumArgs>>;
  ServicePeriodStartOnEnum_aggregate?: SubscriptionResolver<ResolversTypes['ServicePeriodStartOnEnum_aggregate'], "ServicePeriodStartOnEnum_aggregate", ParentType, ContextType, Partial<SubscriptionServicePeriodStartOnEnum_AggregateArgs>>;
  ServicePeriodStartOnEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ServicePeriodStartOnEnum']>, "ServicePeriodStartOnEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionServicePeriodStartOnEnum_By_PkArgs, 'value'>>;
  ServicePeriodStartOnEnum_stream?: SubscriptionResolver<Array<ResolversTypes['ServicePeriodStartOnEnum']>, "ServicePeriodStartOnEnum_stream", ParentType, ContextType, RequireFields<SubscriptionServicePeriodStartOnEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  StripePrepaidConfig_temporary?: SubscriptionResolver<Array<ResolversTypes['StripePrepaidConfig_temporary']>, "StripePrepaidConfig_temporary", ParentType, ContextType, Partial<SubscriptionStripePrepaidConfig_TemporaryArgs>>;
  StripePrepaidConfig_temporary_aggregate?: SubscriptionResolver<ResolversTypes['StripePrepaidConfig_temporary_aggregate'], "StripePrepaidConfig_temporary_aggregate", ParentType, ContextType, Partial<SubscriptionStripePrepaidConfig_Temporary_AggregateArgs>>;
  StripePrepaidConfig_temporary_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['StripePrepaidConfig_temporary']>, "StripePrepaidConfig_temporary_by_pk", ParentType, ContextType, RequireFields<SubscriptionStripePrepaidConfig_Temporary_By_PkArgs, 'client_id' | 'environment_type'>>;
  StripePrepaidConfig_temporary_stream?: SubscriptionResolver<Array<ResolversTypes['StripePrepaidConfig_temporary']>, "StripePrepaidConfig_temporary_stream", ParentType, ContextType, RequireFields<SubscriptionStripePrepaidConfig_Temporary_StreamArgs, 'batch_size' | 'cursor'>>;
  TieringModeEnum?: SubscriptionResolver<Array<ResolversTypes['TieringModeEnum']>, "TieringModeEnum", ParentType, ContextType, Partial<SubscriptionTieringModeEnumArgs>>;
  TieringModeEnum_aggregate?: SubscriptionResolver<ResolversTypes['TieringModeEnum_aggregate'], "TieringModeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionTieringModeEnum_AggregateArgs>>;
  TieringModeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['TieringModeEnum']>, "TieringModeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionTieringModeEnum_By_PkArgs, 'value'>>;
  TieringModeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['TieringModeEnum']>, "TieringModeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionTieringModeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  Tokens?: SubscriptionResolver<Array<ResolversTypes['Tokens']>, "Tokens", ParentType, ContextType, Partial<SubscriptionTokensArgs>>;
  Tokens_aggregate?: SubscriptionResolver<ResolversTypes['Tokens_aggregate'], "Tokens_aggregate", ParentType, ContextType, Partial<SubscriptionTokens_AggregateArgs>>;
  Tokens_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Tokens']>, "Tokens_by_pk", ParentType, ContextType, RequireFields<SubscriptionTokens_By_PkArgs, 'id'>>;
  Tokens_stream?: SubscriptionResolver<Array<ResolversTypes['Tokens']>, "Tokens_stream", ParentType, ContextType, RequireFields<SubscriptionTokens_StreamArgs, 'batch_size' | 'cursor'>>;
  TrialSpec?: SubscriptionResolver<Array<ResolversTypes['TrialSpec']>, "TrialSpec", ParentType, ContextType, Partial<SubscriptionTrialSpecArgs>>;
  TrialSpecSpendingCap?: SubscriptionResolver<Array<ResolversTypes['TrialSpecSpendingCap']>, "TrialSpecSpendingCap", ParentType, ContextType, Partial<SubscriptionTrialSpecSpendingCapArgs>>;
  TrialSpecSpendingCap_aggregate?: SubscriptionResolver<ResolversTypes['TrialSpecSpendingCap_aggregate'], "TrialSpecSpendingCap_aggregate", ParentType, ContextType, Partial<SubscriptionTrialSpecSpendingCap_AggregateArgs>>;
  TrialSpecSpendingCap_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['TrialSpecSpendingCap']>, "TrialSpecSpendingCap_by_pk", ParentType, ContextType, RequireFields<SubscriptionTrialSpecSpendingCap_By_PkArgs, 'id'>>;
  TrialSpecSpendingCap_stream?: SubscriptionResolver<Array<ResolversTypes['TrialSpecSpendingCap']>, "TrialSpecSpendingCap_stream", ParentType, ContextType, RequireFields<SubscriptionTrialSpecSpendingCap_StreamArgs, 'batch_size' | 'cursor'>>;
  TrialSpec_aggregate?: SubscriptionResolver<ResolversTypes['TrialSpec_aggregate'], "TrialSpec_aggregate", ParentType, ContextType, Partial<SubscriptionTrialSpec_AggregateArgs>>;
  TrialSpec_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['TrialSpec']>, "TrialSpec_by_pk", ParentType, ContextType, RequireFields<SubscriptionTrialSpec_By_PkArgs, 'id'>>;
  TrialSpec_stream?: SubscriptionResolver<Array<ResolversTypes['TrialSpec']>, "TrialSpec_stream", ParentType, ContextType, RequireFields<SubscriptionTrialSpec_StreamArgs, 'batch_size' | 'cursor'>>;
  User?: SubscriptionResolver<Array<ResolversTypes['User']>, "User", ParentType, ContextType, Partial<SubscriptionUserArgs>>;
  UserAuthTypeEnum?: SubscriptionResolver<Array<ResolversTypes['UserAuthTypeEnum']>, "UserAuthTypeEnum", ParentType, ContextType, Partial<SubscriptionUserAuthTypeEnumArgs>>;
  UserAuthTypeEnum_aggregate?: SubscriptionResolver<ResolversTypes['UserAuthTypeEnum_aggregate'], "UserAuthTypeEnum_aggregate", ParentType, ContextType, Partial<SubscriptionUserAuthTypeEnum_AggregateArgs>>;
  UserAuthTypeEnum_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['UserAuthTypeEnum']>, "UserAuthTypeEnum_by_pk", ParentType, ContextType, RequireFields<SubscriptionUserAuthTypeEnum_By_PkArgs, 'value'>>;
  UserAuthTypeEnum_stream?: SubscriptionResolver<Array<ResolversTypes['UserAuthTypeEnum']>, "UserAuthTypeEnum_stream", ParentType, ContextType, RequireFields<SubscriptionUserAuthTypeEnum_StreamArgs, 'batch_size' | 'cursor'>>;
  User_aggregate?: SubscriptionResolver<ResolversTypes['User_aggregate'], "User_aggregate", ParentType, ContextType, Partial<SubscriptionUser_AggregateArgs>>;
  User_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "User_by_pk", ParentType, ContextType, RequireFields<SubscriptionUser_By_PkArgs, 'id'>>;
  User_stream?: SubscriptionResolver<Array<ResolversTypes['User']>, "User_stream", ParentType, ContextType, RequireFields<SubscriptionUser_StreamArgs, 'batch_size' | 'cursor'>>;
  Webhook?: SubscriptionResolver<Array<ResolversTypes['Webhook']>, "Webhook", ParentType, ContextType, Partial<SubscriptionWebhookArgs>>;
  WebhookNotification?: SubscriptionResolver<Array<ResolversTypes['WebhookNotification']>, "WebhookNotification", ParentType, ContextType, Partial<SubscriptionWebhookNotificationArgs>>;
  WebhookNotification_aggregate?: SubscriptionResolver<ResolversTypes['WebhookNotification_aggregate'], "WebhookNotification_aggregate", ParentType, ContextType, Partial<SubscriptionWebhookNotification_AggregateArgs>>;
  WebhookNotification_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['WebhookNotification']>, "WebhookNotification_by_pk", ParentType, ContextType, RequireFields<SubscriptionWebhookNotification_By_PkArgs, 'id'>>;
  WebhookNotification_stream?: SubscriptionResolver<Array<ResolversTypes['WebhookNotification']>, "WebhookNotification_stream", ParentType, ContextType, RequireFields<SubscriptionWebhookNotification_StreamArgs, 'batch_size' | 'cursor'>>;
  Webhook_aggregate?: SubscriptionResolver<ResolversTypes['Webhook_aggregate'], "Webhook_aggregate", ParentType, ContextType, Partial<SubscriptionWebhook_AggregateArgs>>;
  Webhook_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['Webhook']>, "Webhook_by_pk", ParentType, ContextType, RequireFields<SubscriptionWebhook_By_PkArgs, 'id'>>;
  Webhook_stream?: SubscriptionResolver<Array<ResolversTypes['Webhook']>, "Webhook_stream", ParentType, ContextType, RequireFields<SubscriptionWebhook_StreamArgs, 'batch_size' | 'cursor'>>;
};

export type SubscriptionProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionProductListItem'] = ResolversParentTypes['SubscriptionProductListItem']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  current?: Resolver<ResolversTypes['SubscriptionProductListItemState'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial?: Resolver<ResolversTypes['SubscriptionProductListItemState'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['ProductListItemUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionProductListItemStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionProductListItemState'] = ResolversParentTypes['SubscriptionProductListItemState']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  is_refundable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_overage_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionRate'] = ResolversParentTypes['SubscriptionRate']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  is_prorated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubtotalAndQuantityByTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubtotalAndQuantityByTime'] = ResolversParentTypes['SubtotalAndQuantityByTime']> = {
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  time_range?: Resolver<ResolversTypes['TimeRange'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubtotalByTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubtotalByTime'] = ResolversParentTypes['SubtotalByTime']> = {
  time_range?: Resolver<ResolversTypes['TimeRange'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TierResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tier'] = ResolversParentTypes['Tier']> = {
  size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TierChargeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TierChargeLineItem'] = ResolversParentTypes['TierChargeLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  line_item_chunks?: Resolver<Array<ResolversTypes['LineItemChunk']>, ParentType, ContextType>;
  metric_minimum?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalAndQuantityByTime']>>, ParentType, ContextType>;
  tier_price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TierMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['TierMetadata'] = ResolversParentTypes['TierMetadata']> = {
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  starting_at?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TierPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['TierPeriod'] = ResolversParentTypes['TierPeriod']> = {
  end?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  start?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TierPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['TierPrice'] = ResolversParentTypes['TierPrice']> = {
  __resolveType: TypeResolveFn<'FlatFee' | 'Price', ParentType, ContextType>;
};

export type TierSubLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TierSubLineItem'] = ResolversParentTypes['TierSubLineItem']> = {
  __resolveType: TypeResolveFn<'TierChargeLineItem', ParentType, ContextType>;
};

export type TieredOverrideResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieredOverride'] = ResolversParentTypes['TieredOverride']> = {
  priority?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tiers?: Resolver<Array<ResolversTypes['TieredOverrideTiers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieredOverrideTiersResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieredOverrideTiers'] = ResolversParentTypes['TieredOverrideTiers']> = {
  multiplier?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieredRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieredRate'] = ResolversParentTypes['TieredRate']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  tiers?: Resolver<Array<ResolversTypes['Tier']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum'] = ResolversParentTypes['TieringModeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum_aggregate'] = ResolversParentTypes['TieringModeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TieringModeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TieringModeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum_aggregate_fields'] = ResolversParentTypes['TieringModeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TieringModeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['TieringModeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TieringModeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum_max_fields'] = ResolversParentTypes['TieringModeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum_min_fields'] = ResolversParentTypes['TieringModeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TieringModeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TieringModeEnum_mutation_response'] = ResolversParentTypes['TieringModeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TieringModeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeRange'] = ResolversParentTypes['TimeRange']> = {
  exclusive_end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  inclusive_start_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokensResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens'] = ResolversParentTypes['Tokens']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  Role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  token_display_suffix?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tokens_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens_aggregate'] = ResolversParentTypes['Tokens_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Tokens_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Tokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tokens_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens_aggregate_fields'] = ResolversParentTypes['Tokens_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Tokens_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Tokens_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Tokens_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tokens_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens_max_fields'] = ResolversParentTypes['Tokens_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  token_display_suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tokens_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens_min_fields'] = ResolversParentTypes['Tokens_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  token_display_suffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Tokens_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tokens_mutation_response'] = ResolversParentTypes['Tokens_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Tokens']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopCustomerSpendersResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopCustomerSpenders'] = ResolversParentTypes['TopCustomerSpenders']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  monthly_spend?: Resolver<Array<ResolversTypes['RevenueBreakdown']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transition'] = ResolversParentTypes['Transition']> = {
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  from_contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  to_contract?: Resolver<ResolversTypes['Contract'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TransitionTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialDiscountLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialDiscountLineItem'] = ResolversParentTypes['TrialDiscountLineItem']> = {
  credit_type?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  display_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtotals_by_time?: Resolver<Maybe<Array<ResolversTypes['SubtotalByTime']>>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec'] = ResolversParentTypes['TrialSpec']> = {
  CustomerPlans?: Resolver<Array<ResolversTypes['CustomerPlan']>, ParentType, ContextType, Partial<TrialSpecCustomerPlansArgs>>;
  CustomerPlans_aggregate?: Resolver<ResolversTypes['CustomerPlan_aggregate'], ParentType, ContextType, Partial<TrialSpecCustomerPlans_AggregateArgs>>;
  Plans?: Resolver<Array<ResolversTypes['Plan']>, ParentType, ContextType, Partial<TrialSpecPlansArgs>>;
  Plans_aggregate?: Resolver<ResolversTypes['Plan_aggregate'], ParentType, ContextType, Partial<TrialSpecPlans_AggregateArgs>>;
  TrialSpecSpendingCaps?: Resolver<Array<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType, Partial<TrialSpecTrialSpecSpendingCapsArgs>>;
  TrialSpecSpendingCaps_aggregate?: Resolver<ResolversTypes['TrialSpecSpendingCap_aggregate'], ParentType, ContextType, Partial<TrialSpecTrialSpecSpendingCaps_AggregateArgs>>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  length_in_days?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCapResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap'] = ResolversParentTypes['TrialSpecSpendingCap']> = {
  CreditType?: Resolver<ResolversTypes['CreditType'], ParentType, ContextType>;
  TrialSpec?: Resolver<ResolversTypes['TrialSpec'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  trial_spec_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_aggregate'] = ResolversParentTypes['TrialSpecSpendingCap_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_aggregate_fields'] = ResolversParentTypes['TrialSpecSpendingCap_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TrialSpecSpendingCap_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['TrialSpecSpendingCap_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_avg_fields'] = ResolversParentTypes['TrialSpecSpendingCap_avg_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_max_fields'] = ResolversParentTypes['TrialSpecSpendingCap_max_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_min_fields'] = ResolversParentTypes['TrialSpecSpendingCap_min_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  credit_type_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  trial_spec_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_mutation_response'] = ResolversParentTypes['TrialSpecSpendingCap_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TrialSpecSpendingCap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_stddev_fields'] = ResolversParentTypes['TrialSpecSpendingCap_stddev_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_stddev_pop_fields'] = ResolversParentTypes['TrialSpecSpendingCap_stddev_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_stddev_samp_fields'] = ResolversParentTypes['TrialSpecSpendingCap_stddev_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_sum_fields'] = ResolversParentTypes['TrialSpecSpendingCap_sum_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_var_pop_fields'] = ResolversParentTypes['TrialSpecSpendingCap_var_pop_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_var_samp_fields'] = ResolversParentTypes['TrialSpecSpendingCap_var_samp_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpecSpendingCap_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpecSpendingCap_variance_fields'] = ResolversParentTypes['TrialSpecSpendingCap_variance_fields']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_aggregate'] = ResolversParentTypes['TrialSpec_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['TrialSpec_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['TrialSpec']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_aggregate_fields'] = ResolversParentTypes['TrialSpec_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['TrialSpec_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TrialSpec_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['TrialSpec_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['TrialSpec_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['TrialSpec_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['TrialSpec_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['TrialSpec_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['TrialSpec_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['TrialSpec_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['TrialSpec_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['TrialSpec_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_avg_fields'] = ResolversParentTypes['TrialSpec_avg_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_max_fields'] = ResolversParentTypes['TrialSpec_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  length_in_days?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_min_fields'] = ResolversParentTypes['TrialSpec_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  length_in_days?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_mutation_response'] = ResolversParentTypes['TrialSpec_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['TrialSpec']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_stddev_fields'] = ResolversParentTypes['TrialSpec_stddev_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_stddev_pop_fields'] = ResolversParentTypes['TrialSpec_stddev_pop_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_stddev_samp_fields'] = ResolversParentTypes['TrialSpec_stddev_samp_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_sum_fields'] = ResolversParentTypes['TrialSpec_sum_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_var_pop_fields'] = ResolversParentTypes['TrialSpec_var_pop_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_var_samp_fields'] = ResolversParentTypes['TrialSpec_var_samp_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrialSpec_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrialSpec_variance_fields'] = ResolversParentTypes['TrialSpec_variance_fields']> = {
  length_in_days?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnpaidInvoiceIdentifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnpaidInvoiceIdentifier'] = ResolversParentTypes['UnpaidInvoiceIdentifier']> = {
  billing_provider_configuration?: Resolver<Maybe<ResolversTypes['FlatKeyValueMap']>, ParentType, ContextType>;
  billing_provider_customer_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  credit_type_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customer_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCommitScheduleImpactedInvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCommitScheduleImpactedInvoice'] = ResolversParentTypes['UpdateCommitScheduleImpactedInvoice']> = {
  contract_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  update?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  update_index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCommitScheduleInvoiceItemPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCommitScheduleInvoiceItemPreview'] = ResolversParentTypes['UpdateCommitScheduleInvoiceItemPreview']> = {
  amount?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  invoice_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  unit_price?: Resolver<ResolversTypes['CreditAmount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCommitScheduleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCommitScheduleMutationResponse'] = ResolversParentTypes['UpdateCommitScheduleMutationResponse']> = {
  access_schedule_items?: Resolver<Maybe<Array<ResolversTypes['UpdateCommitScheduleSegmentItemPreview']>>, ParentType, ContextType>;
  finalized_postpaid_trueup_invoices?: Resolver<Maybe<Array<ResolversTypes['UpdateCommitScheduleImpactedInvoice']>>, ParentType, ContextType>;
  finalized_scheduled_invoices?: Resolver<Maybe<Array<ResolversTypes['UpdateCommitScheduleImpactedInvoice']>>, ParentType, ContextType>;
  finalized_usage_invoices?: Resolver<Maybe<Array<ResolversTypes['UpdateCommitScheduleImpactedInvoice']>>, ParentType, ContextType>;
  invoice_schedule_items?: Resolver<Maybe<Array<ResolversTypes['UpdateCommitScheduleInvoiceItemPreview']>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCommitScheduleSegmentItemPreviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCommitScheduleSegmentItemPreview'] = ResolversParentTypes['UpdateCommitScheduleSegmentItemPreview']> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  end_date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateOrUndefinedResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateOrUndefined'] = ResolversParentTypes['UpdateOrUndefined']> = {
  __resolveType: TypeResolveFn<'QuantityConversionUpdate' | 'QuantityRoundingUpdate', ParentType, ContextType>;
  is_undefined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['UpdateOrUndefinedValue']>, ParentType, ContextType>;
};

export type UpdateOrUndefinedValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateOrUndefinedValue'] = ResolversParentTypes['UpdateOrUndefinedValue']> = {
  __resolveType: TypeResolveFn<'QuantityConversion' | 'QuantityRounding', ParentType, ContextType>;
};

export type UsageBreakdownDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageBreakdownData'] = ResolversParentTypes['UsageBreakdownData']> = {
  ending_before?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  groups?: Resolver<Maybe<ResolversTypes['RecordOfStrings']>, ParentType, ContextType>;
  starting_on?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageBreakdownResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageBreakdownResponse'] = ResolversParentTypes['UsageBreakdownResponse']> = {
  data?: Resolver<Array<ResolversTypes['UsageBreakdownData']>, ParentType, ContextType>;
  next_page?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageEventMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageEventMutationResponse'] = ResolversParentTypes['UsageEventMutationResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  transaction_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageFilter'] = ResolversParentTypes['UsageFilter']> = {
  current?: Resolver<Maybe<ResolversTypes['InitialUsageFilter']>, ParentType, ContextType>;
  initial?: Resolver<Maybe<ResolversTypes['InitialUsageFilter']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['UsageFilterUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageFilterUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageFilterUpdate'] = ResolversParentTypes['UsageFilterUpdate']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  group_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group_values?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageGroup'] = ResolversParentTypes['UsageGroup']> = {
  group_value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metric_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageProductListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageProductListItem'] = ResolversParentTypes['UsageProductListItem']> = {
  archived_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  current?: Resolver<ResolversTypes['UsageProductListItemState'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  initial?: Resolver<ResolversTypes['UsageProductListItemState'], ParentType, ContextType>;
  managed_fields?: Resolver<Array<ResolversTypes['ContractProductManagedField']>, ParentType, ContextType>;
  updates?: Resolver<Array<ResolversTypes['ProductListItemUpdate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageProductListItemStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageProductListItemState'] = ResolversParentTypes['UsageProductListItemState']> = {
  Creator?: Resolver<ResolversTypes['Actor'], ParentType, ContextType>;
  billable_metric?: Resolver<ResolversTypes['BillableMetric'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  effective_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  is_refundable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  netsuite_internal_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netsuite_overage_item_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  presentation_group_key?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  pricing_group_key?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  quantity_conversion?: Resolver<Maybe<ResolversTypes['QuantityConversion']>, ParentType, ContextType>;
  quantity_rounding?: Resolver<Maybe<ResolversTypes['QuantityRounding']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsageWindowResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsageWindow'] = ResolversParentTypes['UsageWindow']> = {
  end?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['UsageGroup']>>, ParentType, ContextType>;
  metric_value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  start?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  auth_type?: Resolver<ResolversTypes['UserAuthTypeEnum_enum'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnumResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum'] = ResolversParentTypes['UserAuthTypeEnum']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnum_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum_aggregate'] = ResolversParentTypes['UserAuthTypeEnum_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnum_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum_aggregate_fields'] = ResolversParentTypes['UserAuthTypeEnum_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<UserAuthTypeEnum_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['UserAuthTypeEnum_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnum_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum_max_fields'] = ResolversParentTypes['UserAuthTypeEnum_max_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnum_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum_min_fields'] = ResolversParentTypes['UserAuthTypeEnum_min_fields']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserAuthTypeEnum_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAuthTypeEnum_mutation_response'] = ResolversParentTypes['UserAuthTypeEnum_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['UserAuthTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_aggregate'] = ResolversParentTypes['User_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['User_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_aggregate_fields'] = ResolversParentTypes['User_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<User_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['User_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['User_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_max_fields'] = ResolversParentTypes['User_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_min_fields'] = ResolversParentTypes['User_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  deprecated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type User_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['User_mutation_response'] = ResolversParentTypes['User_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook'] = ResolversParentTypes['Webhook']> = {
  Client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  created_by?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  encrypted_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification'] = ResolversParentTypes['WebhookNotification']> = {
  attempts?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  client_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  created_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  environment_type?: Resolver<ResolversTypes['EnvironmentTypeEnum_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  notification_id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  notification_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['jsonb'], ParentType, ContextType, Partial<WebhookNotificationPropertiesArgs>>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  webhook_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_aggregate'] = ResolversParentTypes['WebhookNotification_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['WebhookNotification_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['WebhookNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_aggregate_fields'] = ResolversParentTypes['WebhookNotification_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['WebhookNotification_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<WebhookNotification_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['WebhookNotification_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['WebhookNotification_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['WebhookNotification_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['WebhookNotification_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['WebhookNotification_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['WebhookNotification_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['WebhookNotification_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['WebhookNotification_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['WebhookNotification_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Avg_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_avg_fields'] = ResolversParentTypes['WebhookNotification_avg_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_max_fields'] = ResolversParentTypes['WebhookNotification_max_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notification_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notification_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  webhook_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_min_fields'] = ResolversParentTypes['WebhookNotification_min_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  customer_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notification_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  notification_type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  webhook_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_mutation_response'] = ResolversParentTypes['WebhookNotification_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['WebhookNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Stddev_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_stddev_fields'] = ResolversParentTypes['WebhookNotification_stddev_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Stddev_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_stddev_pop_fields'] = ResolversParentTypes['WebhookNotification_stddev_pop_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Stddev_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_stddev_samp_fields'] = ResolversParentTypes['WebhookNotification_stddev_samp_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Sum_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_sum_fields'] = ResolversParentTypes['WebhookNotification_sum_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Var_Pop_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_var_pop_fields'] = ResolversParentTypes['WebhookNotification_var_pop_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Var_Samp_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_var_samp_fields'] = ResolversParentTypes['WebhookNotification_var_samp_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebhookNotification_Variance_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebhookNotification_variance_fields'] = ResolversParentTypes['WebhookNotification_variance_fields']> = {
  attempts?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Webhook_AggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook_aggregate'] = ResolversParentTypes['Webhook_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['Webhook_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Webhook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Webhook_Aggregate_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook_aggregate_fields'] = ResolversParentTypes['Webhook_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<Webhook_Aggregate_FieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['Webhook_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Webhook_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Webhook_Max_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook_max_fields'] = ResolversParentTypes['Webhook_max_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Webhook_Min_FieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook_min_fields'] = ResolversParentTypes['Webhook_min_fields']> = {
  client_id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  created_by?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  encrypted_secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Webhook_Mutation_ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Webhook_mutation_response'] = ResolversParentTypes['Webhook_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Webhook']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkatoSsoUrlResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WorkatoSSOUrlResponse'] = ResolversParentTypes['WorkatoSSOUrlResponse']> = {
  sso_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['json'], any> {
  name: 'json';
}

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export interface NumericScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  APITokenResponse?: ApiTokenResponseResolvers<ContextType>;
  AWSProServiceRoyalty?: AwsProServiceRoyaltyResolvers<ContextType>;
  AWSProServiceRoyaltyUpdate?: AwsProServiceRoyaltyUpdateResolvers<ContextType>;
  AWSRoyalty?: AwsRoyaltyResolvers<ContextType>;
  AWSRoyaltyUpdate?: AwsRoyaltyUpdateResolvers<ContextType>;
  AccountTypeEnum?: AccountTypeEnumResolvers<ContextType>;
  AccountTypeEnum_aggregate?: AccountTypeEnum_AggregateResolvers<ContextType>;
  AccountTypeEnum_aggregate_fields?: AccountTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  AccountTypeEnum_max_fields?: AccountTypeEnum_Max_FieldsResolvers<ContextType>;
  AccountTypeEnum_min_fields?: AccountTypeEnum_Min_FieldsResolvers<ContextType>;
  AccountTypeEnum_mutation_response?: AccountTypeEnum_Mutation_ResponseResolvers<ContextType>;
  Actor?: ActorResolvers<ContextType>;
  ActorOrUser?: ActorOrUserResolvers<ContextType>;
  Actor_aggregate?: Actor_AggregateResolvers<ContextType>;
  Actor_aggregate_fields?: Actor_Aggregate_FieldsResolvers<ContextType>;
  Actor_max_fields?: Actor_Max_FieldsResolvers<ContextType>;
  Actor_min_fields?: Actor_Min_FieldsResolvers<ContextType>;
  Actor_mutation_response?: Actor_Mutation_ResponseResolvers<ContextType>;
  AdHocPlanInvoice?: AdHocPlanInvoiceResolvers<ContextType>;
  AddMarketplaceToCustomerMutationResponse?: AddMarketplaceToCustomerMutationResponseResolvers<ContextType>;
  AdhocContractUsageInvoice?: AdhocContractUsageInvoiceResolvers<ContextType>;
  AdvanceChargeLineItem?: AdvanceChargeLineItemResolvers<ContextType>;
  AdvanceInvoice?: AdvanceInvoiceResolvers<ContextType>;
  Alert?: AlertResolvers<ContextType>;
  AlertManagedField?: AlertManagedFieldResolvers<ContextType>;
  AlertManagedField_aggregate?: AlertManagedField_AggregateResolvers<ContextType>;
  AlertManagedField_aggregate_fields?: AlertManagedField_Aggregate_FieldsResolvers<ContextType>;
  AlertManagedField_max_fields?: AlertManagedField_Max_FieldsResolvers<ContextType>;
  AlertManagedField_min_fields?: AlertManagedField_Min_FieldsResolvers<ContextType>;
  AlertManagedField_mutation_response?: AlertManagedField_Mutation_ResponseResolvers<ContextType>;
  AlertTypeEnum?: AlertTypeEnumResolvers<ContextType>;
  AlertTypeEnum_aggregate?: AlertTypeEnum_AggregateResolvers<ContextType>;
  AlertTypeEnum_aggregate_fields?: AlertTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  AlertTypeEnum_max_fields?: AlertTypeEnum_Max_FieldsResolvers<ContextType>;
  AlertTypeEnum_min_fields?: AlertTypeEnum_Min_FieldsResolvers<ContextType>;
  AlertTypeEnum_mutation_response?: AlertTypeEnum_Mutation_ResponseResolvers<ContextType>;
  Alert_aggregate?: Alert_AggregateResolvers<ContextType>;
  Alert_aggregate_fields?: Alert_Aggregate_FieldsResolvers<ContextType>;
  Alert_avg_fields?: Alert_Avg_FieldsResolvers<ContextType>;
  Alert_max_fields?: Alert_Max_FieldsResolvers<ContextType>;
  Alert_min_fields?: Alert_Min_FieldsResolvers<ContextType>;
  Alert_mutation_response?: Alert_Mutation_ResponseResolvers<ContextType>;
  Alert_stddev_fields?: Alert_Stddev_FieldsResolvers<ContextType>;
  Alert_stddev_pop_fields?: Alert_Stddev_Pop_FieldsResolvers<ContextType>;
  Alert_stddev_samp_fields?: Alert_Stddev_Samp_FieldsResolvers<ContextType>;
  Alert_sum_fields?: Alert_Sum_FieldsResolvers<ContextType>;
  Alert_var_pop_fields?: Alert_Var_Pop_FieldsResolvers<ContextType>;
  Alert_var_samp_fields?: Alert_Var_Samp_FieldsResolvers<ContextType>;
  Alert_variance_fields?: Alert_Variance_FieldsResolvers<ContextType>;
  ArchiveContractMutationResponse?: ArchiveContractMutationResponseResolvers<ContextType>;
  ArchiveProductListItemMutationResponse?: ArchiveProductListItemMutationResponseResolvers<ContextType>;
  ArrearsInvoice?: ArrearsInvoiceResolvers<ContextType>;
  AuditLog?: AuditLogResolvers<ContextType>;
  AuditLogResponse?: AuditLogResponseResolvers<ContextType>;
  AuthCheckResponse?: AuthCheckResponseResolvers<ContextType>;
  BaseCommit?: BaseCommitResolvers<ContextType>;
  BaseRateScheduleSegment?: BaseRateScheduleSegmentResolvers<ContextType>;
  BaseUsageFilter?: BaseUsageFilterResolvers<ContextType>;
  BillableMetric?: BillableMetricResolvers<ContextType>;
  BillableMetricManagedField?: BillableMetricManagedFieldResolvers<ContextType>;
  BillableMetricManagedField_aggregate?: BillableMetricManagedField_AggregateResolvers<ContextType>;
  BillableMetricManagedField_aggregate_fields?: BillableMetricManagedField_Aggregate_FieldsResolvers<ContextType>;
  BillableMetricManagedField_max_fields?: BillableMetricManagedField_Max_FieldsResolvers<ContextType>;
  BillableMetricManagedField_min_fields?: BillableMetricManagedField_Min_FieldsResolvers<ContextType>;
  BillableMetricManagedField_mutation_response?: BillableMetricManagedField_Mutation_ResponseResolvers<ContextType>;
  BillableMetric_aggregate?: BillableMetric_AggregateResolvers<ContextType>;
  BillableMetric_aggregate_fields?: BillableMetric_Aggregate_FieldsResolvers<ContextType>;
  BillableMetric_max_fields?: BillableMetric_Max_FieldsResolvers<ContextType>;
  BillableMetric_min_fields?: BillableMetric_Min_FieldsResolvers<ContextType>;
  BillableMetric_mutation_response?: BillableMetric_Mutation_ResponseResolvers<ContextType>;
  BillableStatusWithEffectiveDate?: BillableStatusWithEffectiveDateResolvers<ContextType>;
  BilledInvoiceTotals?: BilledInvoiceTotalsResolvers<ContextType>;
  BillingFrequencyEnum?: BillingFrequencyEnumResolvers<ContextType>;
  BillingFrequencyEnum_aggregate?: BillingFrequencyEnum_AggregateResolvers<ContextType>;
  BillingFrequencyEnum_aggregate_fields?: BillingFrequencyEnum_Aggregate_FieldsResolvers<ContextType>;
  BillingFrequencyEnum_max_fields?: BillingFrequencyEnum_Max_FieldsResolvers<ContextType>;
  BillingFrequencyEnum_min_fields?: BillingFrequencyEnum_Min_FieldsResolvers<ContextType>;
  BillingFrequencyEnum_mutation_response?: BillingFrequencyEnum_Mutation_ResponseResolvers<ContextType>;
  BillingMetricAggregateEnum?: BillingMetricAggregateEnumResolvers<ContextType>;
  BillingMetricAggregateEnum_aggregate?: BillingMetricAggregateEnum_AggregateResolvers<ContextType>;
  BillingMetricAggregateEnum_aggregate_fields?: BillingMetricAggregateEnum_Aggregate_FieldsResolvers<ContextType>;
  BillingMetricAggregateEnum_max_fields?: BillingMetricAggregateEnum_Max_FieldsResolvers<ContextType>;
  BillingMetricAggregateEnum_min_fields?: BillingMetricAggregateEnum_Min_FieldsResolvers<ContextType>;
  BillingMetricAggregateEnum_mutation_response?: BillingMetricAggregateEnum_Mutation_ResponseResolvers<ContextType>;
  BillingProviderCustomer?: BillingProviderCustomerResolvers<ContextType>;
  BillingProviderCustomer_aggregate?: BillingProviderCustomer_AggregateResolvers<ContextType>;
  BillingProviderCustomer_aggregate_fields?: BillingProviderCustomer_Aggregate_FieldsResolvers<ContextType>;
  BillingProviderCustomer_max_fields?: BillingProviderCustomer_Max_FieldsResolvers<ContextType>;
  BillingProviderCustomer_min_fields?: BillingProviderCustomer_Min_FieldsResolvers<ContextType>;
  BillingProviderCustomer_mutation_response?: BillingProviderCustomer_Mutation_ResponseResolvers<ContextType>;
  BillingProviderDeliveryMethod?: BillingProviderDeliveryMethodResolvers<ContextType>;
  BillingProviderDeliveryMethodWithoutConfiguration?: BillingProviderDeliveryMethodWithoutConfigurationResolvers<ContextType>;
  BillingProviderEnum?: BillingProviderEnumResolvers<ContextType>;
  BillingProviderEnum_aggregate?: BillingProviderEnum_AggregateResolvers<ContextType>;
  BillingProviderEnum_aggregate_fields?: BillingProviderEnum_Aggregate_FieldsResolvers<ContextType>;
  BillingProviderEnum_max_fields?: BillingProviderEnum_Max_FieldsResolvers<ContextType>;
  BillingProviderEnum_min_fields?: BillingProviderEnum_Min_FieldsResolvers<ContextType>;
  BillingProviderEnum_mutation_response?: BillingProviderEnum_Mutation_ResponseResolvers<ContextType>;
  BillingProviderInvoice?: BillingProviderInvoiceResolvers<ContextType>;
  BillingProviderInvoiceUpsertResult?: BillingProviderInvoiceUpsertResultResolvers<ContextType>;
  BillingProviderInvoice_aggregate?: BillingProviderInvoice_AggregateResolvers<ContextType>;
  BillingProviderInvoice_aggregate_fields?: BillingProviderInvoice_Aggregate_FieldsResolvers<ContextType>;
  BillingProviderInvoice_max_fields?: BillingProviderInvoice_Max_FieldsResolvers<ContextType>;
  BillingProviderInvoice_min_fields?: BillingProviderInvoice_Min_FieldsResolvers<ContextType>;
  BillingProviderInvoice_mutation_response?: BillingProviderInvoice_Mutation_ResponseResolvers<ContextType>;
  BillingProviderToken?: BillingProviderTokenResolvers<ContextType>;
  BillingProviderToken_aggregate?: BillingProviderToken_AggregateResolvers<ContextType>;
  BillingProviderToken_aggregate_fields?: BillingProviderToken_Aggregate_FieldsResolvers<ContextType>;
  BillingProviderToken_max_fields?: BillingProviderToken_Max_FieldsResolvers<ContextType>;
  BillingProviderToken_min_fields?: BillingProviderToken_Min_FieldsResolvers<ContextType>;
  BillingProviderToken_mutation_response?: BillingProviderToken_Mutation_ResponseResolvers<ContextType>;
  BreakdownCursor?: BreakdownCursorResolvers<ContextType>;
  BreakdownItem?: BreakdownItemResolvers<ContextType>;
  BreakdownTier?: BreakdownTierResolvers<ContextType>;
  CensusDestinationConnectLink?: CensusDestinationConnectLinkResolvers<ContextType>;
  CensusObjectSyncReport?: CensusObjectSyncReportResolvers<ContextType>;
  CensusRecordSyncError?: CensusRecordSyncErrorResolvers<ContextType>;
  CensusSyncRun?: CensusSyncRunResolvers<ContextType>;
  CensusWorkspace?: CensusWorkspaceResolvers<ContextType>;
  ChargeLineItem?: ChargeLineItemResolvers<ContextType>;
  ChargeManagedField?: ChargeManagedFieldResolvers<ContextType>;
  ChargeManagedField_aggregate?: ChargeManagedField_AggregateResolvers<ContextType>;
  ChargeManagedField_aggregate_fields?: ChargeManagedField_Aggregate_FieldsResolvers<ContextType>;
  ChargeManagedField_max_fields?: ChargeManagedField_Max_FieldsResolvers<ContextType>;
  ChargeManagedField_min_fields?: ChargeManagedField_Min_FieldsResolvers<ContextType>;
  ChargeManagedField_mutation_response?: ChargeManagedField_Mutation_ResponseResolvers<ContextType>;
  ChargeSubLineItem?: ChargeSubLineItemResolvers<ContextType>;
  ChargeTypeEnum?: ChargeTypeEnumResolvers<ContextType>;
  ChargeTypeEnum_aggregate?: ChargeTypeEnum_AggregateResolvers<ContextType>;
  ChargeTypeEnum_aggregate_fields?: ChargeTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  ChargeTypeEnum_max_fields?: ChargeTypeEnum_Max_FieldsResolvers<ContextType>;
  ChargeTypeEnum_min_fields?: ChargeTypeEnum_Min_FieldsResolvers<ContextType>;
  ChargeTypeEnum_mutation_response?: ChargeTypeEnum_Mutation_ResponseResolvers<ContextType>;
  Client?: ClientResolvers<ContextType>;
  ClientConfig?: ClientConfigResolvers<ContextType>;
  ClientConfigKeyEnum?: ClientConfigKeyEnumResolvers<ContextType>;
  ClientConfigKeyEnum_aggregate?: ClientConfigKeyEnum_AggregateResolvers<ContextType>;
  ClientConfigKeyEnum_aggregate_fields?: ClientConfigKeyEnum_Aggregate_FieldsResolvers<ContextType>;
  ClientConfigKeyEnum_max_fields?: ClientConfigKeyEnum_Max_FieldsResolvers<ContextType>;
  ClientConfigKeyEnum_min_fields?: ClientConfigKeyEnum_Min_FieldsResolvers<ContextType>;
  ClientConfigKeyEnum_mutation_response?: ClientConfigKeyEnum_Mutation_ResponseResolvers<ContextType>;
  ClientConfig_aggregate?: ClientConfig_AggregateResolvers<ContextType>;
  ClientConfig_aggregate_fields?: ClientConfig_Aggregate_FieldsResolvers<ContextType>;
  ClientConfig_max_fields?: ClientConfig_Max_FieldsResolvers<ContextType>;
  ClientConfig_min_fields?: ClientConfig_Min_FieldsResolvers<ContextType>;
  ClientConfig_mutation_response?: ClientConfig_Mutation_ResponseResolvers<ContextType>;
  ClientDashboardMetrics?: ClientDashboardMetricsResolvers<ContextType>;
  ClientOverview?: ClientOverviewResolvers<ContextType>;
  ClientOverview_Chart?: ClientOverview_ChartResolvers<ContextType>;
  ClientOverview_ChartDatum?: ClientOverview_ChartDatumResolvers<ContextType>;
  ClientOverview_Spend?: ClientOverview_SpendResolvers<ContextType>;
  ClientOverview_TopSpender?: ClientOverview_TopSpenderResolvers<ContextType>;
  ClientOverview_TopSpenders?: ClientOverview_TopSpendersResolvers<ContextType>;
  ClientUsingContracts?: ClientUsingContractsResolvers<ContextType>;
  ClientUsingPrepaidCredits?: ClientUsingPrepaidCreditsResolvers<ContextType>;
  Client_aggregate?: Client_AggregateResolvers<ContextType>;
  Client_aggregate_fields?: Client_Aggregate_FieldsResolvers<ContextType>;
  Client_max_fields?: Client_Max_FieldsResolvers<ContextType>;
  Client_min_fields?: Client_Min_FieldsResolvers<ContextType>;
  Client_mutation_response?: Client_Mutation_ResponseResolvers<ContextType>;
  ClientsUsingContracts?: ClientsUsingContractsResolvers<ContextType>;
  ClientsUsingPrepaidCredits?: ClientsUsingPrepaidCreditsResolvers<ContextType>;
  CollectionScheduleEnum?: CollectionScheduleEnumResolvers<ContextType>;
  CollectionScheduleEnum_aggregate?: CollectionScheduleEnum_AggregateResolvers<ContextType>;
  CollectionScheduleEnum_aggregate_fields?: CollectionScheduleEnum_Aggregate_FieldsResolvers<ContextType>;
  CollectionScheduleEnum_max_fields?: CollectionScheduleEnum_Max_FieldsResolvers<ContextType>;
  CollectionScheduleEnum_min_fields?: CollectionScheduleEnum_Min_FieldsResolvers<ContextType>;
  CollectionScheduleEnum_mutation_response?: CollectionScheduleEnum_Mutation_ResponseResolvers<ContextType>;
  Commit?: CommitResolvers<ContextType>;
  CommitAccessSchedule?: CommitAccessScheduleResolvers<ContextType>;
  CommitInvoiceSchedule?: CommitInvoiceScheduleResolvers<ContextType>;
  CommitInvoiceScheduleItem?: CommitInvoiceScheduleItemResolvers<ContextType>;
  CommitManagedField?: CommitManagedFieldResolvers<ContextType>;
  CommitManagedField_aggregate?: CommitManagedField_AggregateResolvers<ContextType>;
  CommitManagedField_aggregate_fields?: CommitManagedField_Aggregate_FieldsResolvers<ContextType>;
  CommitManagedField_max_fields?: CommitManagedField_Max_FieldsResolvers<ContextType>;
  CommitManagedField_min_fields?: CommitManagedField_Min_FieldsResolvers<ContextType>;
  CommitManagedField_mutation_response?: CommitManagedField_Mutation_ResponseResolvers<ContextType>;
  CommitOrCreditManagedField?: CommitOrCreditManagedFieldResolvers<ContextType>;
  CommitSegmentScheduleItem?: CommitSegmentScheduleItemResolvers<ContextType>;
  CommitUnion?: CommitUnionResolvers<ContextType>;
  CommitWithSegment?: CommitWithSegmentResolvers<ContextType>;
  CompositeCharge?: CompositeChargeResolvers<ContextType>;
  CompositeChargeAdjustment?: CompositeChargeAdjustmentResolvers<ContextType>;
  CompositeChargeAdjustment_aggregate?: CompositeChargeAdjustment_AggregateResolvers<ContextType>;
  CompositeChargeAdjustment_aggregate_fields?: CompositeChargeAdjustment_Aggregate_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_avg_fields?: CompositeChargeAdjustment_Avg_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_max_fields?: CompositeChargeAdjustment_Max_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_min_fields?: CompositeChargeAdjustment_Min_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_mutation_response?: CompositeChargeAdjustment_Mutation_ResponseResolvers<ContextType>;
  CompositeChargeAdjustment_stddev_fields?: CompositeChargeAdjustment_Stddev_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_stddev_pop_fields?: CompositeChargeAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_stddev_samp_fields?: CompositeChargeAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_sum_fields?: CompositeChargeAdjustment_Sum_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_var_pop_fields?: CompositeChargeAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_var_samp_fields?: CompositeChargeAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  CompositeChargeAdjustment_variance_fields?: CompositeChargeAdjustment_Variance_FieldsResolvers<ContextType>;
  CompositeChargeLineItem?: CompositeChargeLineItemResolvers<ContextType>;
  CompositeChargePricingFactor?: CompositeChargePricingFactorResolvers<ContextType>;
  CompositeChargePricingFactor_aggregate?: CompositeChargePricingFactor_AggregateResolvers<ContextType>;
  CompositeChargePricingFactor_aggregate_fields?: CompositeChargePricingFactor_Aggregate_FieldsResolvers<ContextType>;
  CompositeChargePricingFactor_max_fields?: CompositeChargePricingFactor_Max_FieldsResolvers<ContextType>;
  CompositeChargePricingFactor_min_fields?: CompositeChargePricingFactor_Min_FieldsResolvers<ContextType>;
  CompositeChargePricingFactor_mutation_response?: CompositeChargePricingFactor_Mutation_ResponseResolvers<ContextType>;
  CompositeChargeTier?: CompositeChargeTierResolvers<ContextType>;
  CompositeChargeTierAdjustment?: CompositeChargeTierAdjustmentResolvers<ContextType>;
  CompositeChargeTierAdjustment_aggregate?: CompositeChargeTierAdjustment_AggregateResolvers<ContextType>;
  CompositeChargeTierAdjustment_aggregate_fields?: CompositeChargeTierAdjustment_Aggregate_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_avg_fields?: CompositeChargeTierAdjustment_Avg_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_max_fields?: CompositeChargeTierAdjustment_Max_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_min_fields?: CompositeChargeTierAdjustment_Min_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_mutation_response?: CompositeChargeTierAdjustment_Mutation_ResponseResolvers<ContextType>;
  CompositeChargeTierAdjustment_stddev_fields?: CompositeChargeTierAdjustment_Stddev_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_stddev_pop_fields?: CompositeChargeTierAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_stddev_samp_fields?: CompositeChargeTierAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_sum_fields?: CompositeChargeTierAdjustment_Sum_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_var_pop_fields?: CompositeChargeTierAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_var_samp_fields?: CompositeChargeTierAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  CompositeChargeTierAdjustment_variance_fields?: CompositeChargeTierAdjustment_Variance_FieldsResolvers<ContextType>;
  CompositeChargeTier_aggregate?: CompositeChargeTier_AggregateResolvers<ContextType>;
  CompositeChargeTier_aggregate_fields?: CompositeChargeTier_Aggregate_FieldsResolvers<ContextType>;
  CompositeChargeTier_avg_fields?: CompositeChargeTier_Avg_FieldsResolvers<ContextType>;
  CompositeChargeTier_max_fields?: CompositeChargeTier_Max_FieldsResolvers<ContextType>;
  CompositeChargeTier_min_fields?: CompositeChargeTier_Min_FieldsResolvers<ContextType>;
  CompositeChargeTier_mutation_response?: CompositeChargeTier_Mutation_ResponseResolvers<ContextType>;
  CompositeChargeTier_stddev_fields?: CompositeChargeTier_Stddev_FieldsResolvers<ContextType>;
  CompositeChargeTier_stddev_pop_fields?: CompositeChargeTier_Stddev_Pop_FieldsResolvers<ContextType>;
  CompositeChargeTier_stddev_samp_fields?: CompositeChargeTier_Stddev_Samp_FieldsResolvers<ContextType>;
  CompositeChargeTier_sum_fields?: CompositeChargeTier_Sum_FieldsResolvers<ContextType>;
  CompositeChargeTier_var_pop_fields?: CompositeChargeTier_Var_Pop_FieldsResolvers<ContextType>;
  CompositeChargeTier_var_samp_fields?: CompositeChargeTier_Var_Samp_FieldsResolvers<ContextType>;
  CompositeChargeTier_variance_fields?: CompositeChargeTier_Variance_FieldsResolvers<ContextType>;
  CompositeChargeTypeEnum?: CompositeChargeTypeEnumResolvers<ContextType>;
  CompositeChargeTypeEnum_aggregate?: CompositeChargeTypeEnum_AggregateResolvers<ContextType>;
  CompositeChargeTypeEnum_aggregate_fields?: CompositeChargeTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  CompositeChargeTypeEnum_max_fields?: CompositeChargeTypeEnum_Max_FieldsResolvers<ContextType>;
  CompositeChargeTypeEnum_min_fields?: CompositeChargeTypeEnum_Min_FieldsResolvers<ContextType>;
  CompositeChargeTypeEnum_mutation_response?: CompositeChargeTypeEnum_Mutation_ResponseResolvers<ContextType>;
  CompositeCharge_aggregate?: CompositeCharge_AggregateResolvers<ContextType>;
  CompositeCharge_aggregate_fields?: CompositeCharge_Aggregate_FieldsResolvers<ContextType>;
  CompositeCharge_avg_fields?: CompositeCharge_Avg_FieldsResolvers<ContextType>;
  CompositeCharge_max_fields?: CompositeCharge_Max_FieldsResolvers<ContextType>;
  CompositeCharge_min_fields?: CompositeCharge_Min_FieldsResolvers<ContextType>;
  CompositeCharge_mutation_response?: CompositeCharge_Mutation_ResponseResolvers<ContextType>;
  CompositeCharge_stddev_fields?: CompositeCharge_Stddev_FieldsResolvers<ContextType>;
  CompositeCharge_stddev_pop_fields?: CompositeCharge_Stddev_Pop_FieldsResolvers<ContextType>;
  CompositeCharge_stddev_samp_fields?: CompositeCharge_Stddev_Samp_FieldsResolvers<ContextType>;
  CompositeCharge_sum_fields?: CompositeCharge_Sum_FieldsResolvers<ContextType>;
  CompositeCharge_var_pop_fields?: CompositeCharge_Var_Pop_FieldsResolvers<ContextType>;
  CompositeCharge_var_samp_fields?: CompositeCharge_Var_Samp_FieldsResolvers<ContextType>;
  CompositeCharge_variance_fields?: CompositeCharge_Variance_FieldsResolvers<ContextType>;
  CompositeProductListItem?: CompositeProductListItemResolvers<ContextType>;
  CompositeProductListItemState?: CompositeProductListItemStateResolvers<ContextType>;
  Contract?: ContractResolvers<ContextType>;
  ContractAWSProServiceRoyaltyLineItem?: ContractAwsProServiceRoyaltyLineItemResolvers<ContextType>;
  ContractAWSRoyaltyLineItem?: ContractAwsRoyaltyLineItemResolvers<ContextType>;
  ContractAmendment?: ContractAmendmentResolvers<ContextType>;
  ContractAppliedCommitLineItem?: ContractAppliedCommitLineItemResolvers<ContextType>;
  ContractChargeLineItem?: ContractChargeLineItemResolvers<ContextType>;
  ContractCommitLineItem?: ContractCommitLineItemResolvers<ContextType>;
  ContractConsistency?: ContractConsistencyResolvers<ContextType>;
  ContractConsistency_OutdatedBillingProviderProjection?: ContractConsistency_OutdatedBillingProviderProjectionResolvers<ContextType>;
  ContractConsistency_OutdatedRate?: ContractConsistency_OutdatedRateResolvers<ContextType>;
  ContractConversionLineItem?: ContractConversionLineItemResolvers<ContextType>;
  ContractCreditManagedField?: ContractCreditManagedFieldResolvers<ContextType>;
  ContractCreditManagedField_aggregate?: ContractCreditManagedField_AggregateResolvers<ContextType>;
  ContractCreditManagedField_aggregate_fields?: ContractCreditManagedField_Aggregate_FieldsResolvers<ContextType>;
  ContractCreditManagedField_max_fields?: ContractCreditManagedField_Max_FieldsResolvers<ContextType>;
  ContractCreditManagedField_min_fields?: ContractCreditManagedField_Min_FieldsResolvers<ContextType>;
  ContractCreditManagedField_mutation_response?: ContractCreditManagedField_Mutation_ResponseResolvers<ContextType>;
  ContractDiscountLineItem?: ContractDiscountLineItemResolvers<ContextType>;
  ContractGCPProServiceRoyaltyLineItem?: ContractGcpProServiceRoyaltyLineItemResolvers<ContextType>;
  ContractGCPRoyaltyLineItem?: ContractGcpRoyaltyLineItemResolvers<ContextType>;
  ContractManagedField?: ContractManagedFieldResolvers<ContextType>;
  ContractManagedField_aggregate?: ContractManagedField_AggregateResolvers<ContextType>;
  ContractManagedField_aggregate_fields?: ContractManagedField_Aggregate_FieldsResolvers<ContextType>;
  ContractManagedField_max_fields?: ContractManagedField_Max_FieldsResolvers<ContextType>;
  ContractManagedField_min_fields?: ContractManagedField_Min_FieldsResolvers<ContextType>;
  ContractManagedField_mutation_response?: ContractManagedField_Mutation_ResponseResolvers<ContextType>;
  ContractPage?: ContractPageResolvers<ContextType>;
  ContractPostpaidTrueupInvoice?: ContractPostpaidTrueupInvoiceResolvers<ContextType>;
  ContractPostpaidTrueupLineItem?: ContractPostpaidTrueupLineItemResolvers<ContextType>;
  ContractPricing?: ContractPricingResolvers<ContextType>;
  ContractProServiceInvoice?: ContractProServiceInvoiceResolvers<ContextType>;
  ContractProServiceLineItem?: ContractProServiceLineItemResolvers<ContextType>;
  ContractProductManagedField?: ContractProductManagedFieldResolvers<ContextType>;
  ContractProductManagedField_aggregate?: ContractProductManagedField_AggregateResolvers<ContextType>;
  ContractProductManagedField_aggregate_fields?: ContractProductManagedField_Aggregate_FieldsResolvers<ContextType>;
  ContractProductManagedField_max_fields?: ContractProductManagedField_Max_FieldsResolvers<ContextType>;
  ContractProductManagedField_min_fields?: ContractProductManagedField_Min_FieldsResolvers<ContextType>;
  ContractProductManagedField_mutation_response?: ContractProductManagedField_Mutation_ResponseResolvers<ContextType>;
  ContractRateSchedule?: ContractRateScheduleResolvers<ContextType>;
  ContractRateScheduleSegment?: ContractRateScheduleSegmentResolvers<ContextType>;
  ContractRefundInvoice?: ContractRefundInvoiceResolvers<ContextType>;
  ContractRefundInvoiceCorrectionRecord?: ContractRefundInvoiceCorrectionRecordResolvers<ContextType>;
  ContractRefundInvoiceCorrectionRecordOriginalInvoice?: ContractRefundInvoiceCorrectionRecordOriginalInvoiceResolvers<ContextType>;
  ContractRolloverCommitLineItem?: ContractRolloverCommitLineItemResolvers<ContextType>;
  ContractScheduledInvoice?: ContractScheduledInvoiceResolvers<ContextType>;
  ContractScheduledLineItem?: ContractScheduledLineItemResolvers<ContextType>;
  ContractStats?: ContractStatsResolvers<ContextType>;
  ContractSubscriptionLineItem?: ContractSubscriptionLineItemResolvers<ContextType>;
  ContractUsageInvoice?: ContractUsageInvoiceResolvers<ContextType>;
  ContractUsageInvoiceSchedule?: ContractUsageInvoiceScheduleResolvers<ContextType>;
  ContractUsageLineItem?: ContractUsageLineItemResolvers<ContextType>;
  ContractUsageLineItemProductListItem?: ContractUsageLineItemProductListItemResolvers<ContextType>;
  CorrectableInvoice?: CorrectableInvoiceResolvers<ContextType>;
  CorrectionInvoice?: CorrectionInvoiceResolvers<ContextType>;
  Cost?: CostResolvers<ContextType>;
  CostWindow?: CostWindowResolvers<ContextType>;
  CreditAdjustment?: CreditAdjustmentResolvers<ContextType>;
  CreditAdjustment_aggregate?: CreditAdjustment_AggregateResolvers<ContextType>;
  CreditAdjustment_aggregate_fields?: CreditAdjustment_Aggregate_FieldsResolvers<ContextType>;
  CreditAdjustment_avg_fields?: CreditAdjustment_Avg_FieldsResolvers<ContextType>;
  CreditAdjustment_max_fields?: CreditAdjustment_Max_FieldsResolvers<ContextType>;
  CreditAdjustment_min_fields?: CreditAdjustment_Min_FieldsResolvers<ContextType>;
  CreditAdjustment_mutation_response?: CreditAdjustment_Mutation_ResponseResolvers<ContextType>;
  CreditAdjustment_stddev_fields?: CreditAdjustment_Stddev_FieldsResolvers<ContextType>;
  CreditAdjustment_stddev_pop_fields?: CreditAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditAdjustment_stddev_samp_fields?: CreditAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditAdjustment_sum_fields?: CreditAdjustment_Sum_FieldsResolvers<ContextType>;
  CreditAdjustment_var_pop_fields?: CreditAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  CreditAdjustment_var_samp_fields?: CreditAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  CreditAdjustment_variance_fields?: CreditAdjustment_Variance_FieldsResolvers<ContextType>;
  CreditAmount?: GraphQLScalarType;
  CreditAmountWithType?: CreditAmountWithTypeResolvers<ContextType>;
  CreditGrant?: CreditGrantResolvers<ContextType>;
  CreditGrantDeduction?: CreditGrantDeductionResolvers<ContextType>;
  CreditGrantDeduction_aggregate?: CreditGrantDeduction_AggregateResolvers<ContextType>;
  CreditGrantDeduction_aggregate_fields?: CreditGrantDeduction_Aggregate_FieldsResolvers<ContextType>;
  CreditGrantDeduction_avg_fields?: CreditGrantDeduction_Avg_FieldsResolvers<ContextType>;
  CreditGrantDeduction_max_fields?: CreditGrantDeduction_Max_FieldsResolvers<ContextType>;
  CreditGrantDeduction_min_fields?: CreditGrantDeduction_Min_FieldsResolvers<ContextType>;
  CreditGrantDeduction_mutation_response?: CreditGrantDeduction_Mutation_ResponseResolvers<ContextType>;
  CreditGrantDeduction_stddev_fields?: CreditGrantDeduction_Stddev_FieldsResolvers<ContextType>;
  CreditGrantDeduction_stddev_pop_fields?: CreditGrantDeduction_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditGrantDeduction_stddev_samp_fields?: CreditGrantDeduction_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditGrantDeduction_sum_fields?: CreditGrantDeduction_Sum_FieldsResolvers<ContextType>;
  CreditGrantDeduction_var_pop_fields?: CreditGrantDeduction_Var_Pop_FieldsResolvers<ContextType>;
  CreditGrantDeduction_var_samp_fields?: CreditGrantDeduction_Var_Samp_FieldsResolvers<ContextType>;
  CreditGrantDeduction_variance_fields?: CreditGrantDeduction_Variance_FieldsResolvers<ContextType>;
  CreditGrantHistory?: CreditGrantHistoryResolvers<ContextType>;
  CreditGrantHistory_aggregate?: CreditGrantHistory_AggregateResolvers<ContextType>;
  CreditGrantHistory_aggregate_fields?: CreditGrantHistory_Aggregate_FieldsResolvers<ContextType>;
  CreditGrantHistory_avg_fields?: CreditGrantHistory_Avg_FieldsResolvers<ContextType>;
  CreditGrantHistory_max_fields?: CreditGrantHistory_Max_FieldsResolvers<ContextType>;
  CreditGrantHistory_min_fields?: CreditGrantHistory_Min_FieldsResolvers<ContextType>;
  CreditGrantHistory_mutation_response?: CreditGrantHistory_Mutation_ResponseResolvers<ContextType>;
  CreditGrantHistory_stddev_fields?: CreditGrantHistory_Stddev_FieldsResolvers<ContextType>;
  CreditGrantHistory_stddev_pop_fields?: CreditGrantHistory_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditGrantHistory_stddev_samp_fields?: CreditGrantHistory_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditGrantHistory_sum_fields?: CreditGrantHistory_Sum_FieldsResolvers<ContextType>;
  CreditGrantHistory_var_pop_fields?: CreditGrantHistory_Var_Pop_FieldsResolvers<ContextType>;
  CreditGrantHistory_var_samp_fields?: CreditGrantHistory_Var_Samp_FieldsResolvers<ContextType>;
  CreditGrantHistory_variance_fields?: CreditGrantHistory_Variance_FieldsResolvers<ContextType>;
  CreditGrantManagedField?: CreditGrantManagedFieldResolvers<ContextType>;
  CreditGrantManagedField_aggregate?: CreditGrantManagedField_AggregateResolvers<ContextType>;
  CreditGrantManagedField_aggregate_fields?: CreditGrantManagedField_Aggregate_FieldsResolvers<ContextType>;
  CreditGrantManagedField_max_fields?: CreditGrantManagedField_Max_FieldsResolvers<ContextType>;
  CreditGrantManagedField_min_fields?: CreditGrantManagedField_Min_FieldsResolvers<ContextType>;
  CreditGrantManagedField_mutation_response?: CreditGrantManagedField_Mutation_ResponseResolvers<ContextType>;
  CreditGrantPage?: CreditGrantPageResolvers<ContextType>;
  CreditGrantUnion?: CreditGrantUnionResolvers<ContextType>;
  CreditGrant_aggregate?: CreditGrant_AggregateResolvers<ContextType>;
  CreditGrant_aggregate_fields?: CreditGrant_Aggregate_FieldsResolvers<ContextType>;
  CreditGrant_avg_fields?: CreditGrant_Avg_FieldsResolvers<ContextType>;
  CreditGrant_max_fields?: CreditGrant_Max_FieldsResolvers<ContextType>;
  CreditGrant_min_fields?: CreditGrant_Min_FieldsResolvers<ContextType>;
  CreditGrant_mutation_response?: CreditGrant_Mutation_ResponseResolvers<ContextType>;
  CreditGrant_stddev_fields?: CreditGrant_Stddev_FieldsResolvers<ContextType>;
  CreditGrant_stddev_pop_fields?: CreditGrant_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditGrant_stddev_samp_fields?: CreditGrant_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditGrant_sum_fields?: CreditGrant_Sum_FieldsResolvers<ContextType>;
  CreditGrant_var_pop_fields?: CreditGrant_Var_Pop_FieldsResolvers<ContextType>;
  CreditGrant_var_samp_fields?: CreditGrant_Var_Samp_FieldsResolvers<ContextType>;
  CreditGrant_variance_fields?: CreditGrant_Variance_FieldsResolvers<ContextType>;
  CreditLineItem?: CreditLineItemResolvers<ContextType>;
  CreditPurchaseChargeLineItem?: CreditPurchaseChargeLineItemResolvers<ContextType>;
  CreditPurchaseInvoice?: CreditPurchaseInvoiceResolvers<ContextType>;
  CreditPurchaseLineItem?: CreditPurchaseLineItemResolvers<ContextType>;
  CreditType?: CreditTypeResolvers<ContextType>;
  CreditTypeConversion?: CreditTypeConversionResolvers<ContextType>;
  CreditTypeConversionAdjustment?: CreditTypeConversionAdjustmentResolvers<ContextType>;
  CreditTypeConversionAdjustment_aggregate?: CreditTypeConversionAdjustment_AggregateResolvers<ContextType>;
  CreditTypeConversionAdjustment_aggregate_fields?: CreditTypeConversionAdjustment_Aggregate_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_avg_fields?: CreditTypeConversionAdjustment_Avg_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_max_fields?: CreditTypeConversionAdjustment_Max_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_min_fields?: CreditTypeConversionAdjustment_Min_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_mutation_response?: CreditTypeConversionAdjustment_Mutation_ResponseResolvers<ContextType>;
  CreditTypeConversionAdjustment_stddev_fields?: CreditTypeConversionAdjustment_Stddev_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_stddev_pop_fields?: CreditTypeConversionAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_stddev_samp_fields?: CreditTypeConversionAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_sum_fields?: CreditTypeConversionAdjustment_Sum_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_var_pop_fields?: CreditTypeConversionAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_var_samp_fields?: CreditTypeConversionAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  CreditTypeConversionAdjustment_variance_fields?: CreditTypeConversionAdjustment_Variance_FieldsResolvers<ContextType>;
  CreditTypeConversion_aggregate?: CreditTypeConversion_AggregateResolvers<ContextType>;
  CreditTypeConversion_aggregate_fields?: CreditTypeConversion_Aggregate_FieldsResolvers<ContextType>;
  CreditTypeConversion_avg_fields?: CreditTypeConversion_Avg_FieldsResolvers<ContextType>;
  CreditTypeConversion_max_fields?: CreditTypeConversion_Max_FieldsResolvers<ContextType>;
  CreditTypeConversion_min_fields?: CreditTypeConversion_Min_FieldsResolvers<ContextType>;
  CreditTypeConversion_mutation_response?: CreditTypeConversion_Mutation_ResponseResolvers<ContextType>;
  CreditTypeConversion_stddev_fields?: CreditTypeConversion_Stddev_FieldsResolvers<ContextType>;
  CreditTypeConversion_stddev_pop_fields?: CreditTypeConversion_Stddev_Pop_FieldsResolvers<ContextType>;
  CreditTypeConversion_stddev_samp_fields?: CreditTypeConversion_Stddev_Samp_FieldsResolvers<ContextType>;
  CreditTypeConversion_sum_fields?: CreditTypeConversion_Sum_FieldsResolvers<ContextType>;
  CreditTypeConversion_var_pop_fields?: CreditTypeConversion_Var_Pop_FieldsResolvers<ContextType>;
  CreditTypeConversion_var_samp_fields?: CreditTypeConversion_Var_Samp_FieldsResolvers<ContextType>;
  CreditTypeConversion_variance_fields?: CreditTypeConversion_Variance_FieldsResolvers<ContextType>;
  CreditType_aggregate?: CreditType_AggregateResolvers<ContextType>;
  CreditType_aggregate_fields?: CreditType_Aggregate_FieldsResolvers<ContextType>;
  CreditType_max_fields?: CreditType_Max_FieldsResolvers<ContextType>;
  CreditType_min_fields?: CreditType_Min_FieldsResolvers<ContextType>;
  CreditType_mutation_response?: CreditType_Mutation_ResponseResolvers<ContextType>;
  CurrentPlanUsage?: CurrentPlanUsageResolvers<ContextType>;
  CustomPricing?: CustomPricingResolvers<ContextType>;
  CustomPricing_aggregate?: CustomPricing_AggregateResolvers<ContextType>;
  CustomPricing_aggregate_fields?: CustomPricing_Aggregate_FieldsResolvers<ContextType>;
  CustomPricing_max_fields?: CustomPricing_Max_FieldsResolvers<ContextType>;
  CustomPricing_min_fields?: CustomPricing_Min_FieldsResolvers<ContextType>;
  CustomPricing_mutation_response?: CustomPricing_Mutation_ResponseResolvers<ContextType>;
  CustomRate?: CustomRateResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerAggregate?: CustomerAggregateResolvers<ContextType>;
  CustomerAlert?: CustomerAlertResolvers<ContextType>;
  CustomerAlertComposite?: CustomerAlertCompositeResolvers<ContextType>;
  CustomerAlertHistory?: CustomerAlertHistoryResolvers<ContextType>;
  CustomerAlertHistory_aggregate?: CustomerAlertHistory_AggregateResolvers<ContextType>;
  CustomerAlertHistory_aggregate_fields?: CustomerAlertHistory_Aggregate_FieldsResolvers<ContextType>;
  CustomerAlertHistory_max_fields?: CustomerAlertHistory_Max_FieldsResolvers<ContextType>;
  CustomerAlertHistory_min_fields?: CustomerAlertHistory_Min_FieldsResolvers<ContextType>;
  CustomerAlertHistory_mutation_response?: CustomerAlertHistory_Mutation_ResponseResolvers<ContextType>;
  CustomerAlertStatusEnum?: CustomerAlertStatusEnumResolvers<ContextType>;
  CustomerAlertStatusEnum_aggregate?: CustomerAlertStatusEnum_AggregateResolvers<ContextType>;
  CustomerAlertStatusEnum_aggregate_fields?: CustomerAlertStatusEnum_Aggregate_FieldsResolvers<ContextType>;
  CustomerAlertStatusEnum_max_fields?: CustomerAlertStatusEnum_Max_FieldsResolvers<ContextType>;
  CustomerAlertStatusEnum_min_fields?: CustomerAlertStatusEnum_Min_FieldsResolvers<ContextType>;
  CustomerAlertStatusEnum_mutation_response?: CustomerAlertStatusEnum_Mutation_ResponseResolvers<ContextType>;
  CustomerAlert_aggregate?: CustomerAlert_AggregateResolvers<ContextType>;
  CustomerAlert_aggregate_fields?: CustomerAlert_Aggregate_FieldsResolvers<ContextType>;
  CustomerAlert_max_fields?: CustomerAlert_Max_FieldsResolvers<ContextType>;
  CustomerAlert_min_fields?: CustomerAlert_Min_FieldsResolvers<ContextType>;
  CustomerAlert_mutation_response?: CustomerAlert_Mutation_ResponseResolvers<ContextType>;
  CustomerBillingProviderConfiguration?: CustomerBillingProviderConfigurationResolvers<ContextType>;
  CustomerBillingProviderConfigurationNotification?: CustomerBillingProviderConfigurationNotificationResolvers<ContextType>;
  CustomerConfig?: CustomerConfigResolvers<ContextType>;
  CustomerConfig_aggregate?: CustomerConfig_AggregateResolvers<ContextType>;
  CustomerConfig_aggregate_fields?: CustomerConfig_Aggregate_FieldsResolvers<ContextType>;
  CustomerConfig_max_fields?: CustomerConfig_Max_FieldsResolvers<ContextType>;
  CustomerConfig_min_fields?: CustomerConfig_Min_FieldsResolvers<ContextType>;
  CustomerConfig_mutation_response?: CustomerConfig_Mutation_ResponseResolvers<ContextType>;
  CustomerContractStatus?: CustomerContractStatusResolvers<ContextType>;
  CustomerCount?: CustomerCountResolvers<ContextType>;
  CustomerIngestAlias?: CustomerIngestAliasResolvers<ContextType>;
  CustomerIngestAlias_aggregate?: CustomerIngestAlias_AggregateResolvers<ContextType>;
  CustomerIngestAlias_aggregate_fields?: CustomerIngestAlias_Aggregate_FieldsResolvers<ContextType>;
  CustomerIngestAlias_max_fields?: CustomerIngestAlias_Max_FieldsResolvers<ContextType>;
  CustomerIngestAlias_min_fields?: CustomerIngestAlias_Min_FieldsResolvers<ContextType>;
  CustomerIngestAlias_mutation_response?: CustomerIngestAlias_Mutation_ResponseResolvers<ContextType>;
  CustomerList?: CustomerListResolvers<ContextType>;
  CustomerManagedField?: CustomerManagedFieldResolvers<ContextType>;
  CustomerManagedField_aggregate?: CustomerManagedField_AggregateResolvers<ContextType>;
  CustomerManagedField_aggregate_fields?: CustomerManagedField_Aggregate_FieldsResolvers<ContextType>;
  CustomerManagedField_max_fields?: CustomerManagedField_Max_FieldsResolvers<ContextType>;
  CustomerManagedField_min_fields?: CustomerManagedField_Min_FieldsResolvers<ContextType>;
  CustomerManagedField_mutation_response?: CustomerManagedField_Mutation_ResponseResolvers<ContextType>;
  CustomerPlan?: CustomerPlanResolvers<ContextType>;
  CustomerPlanAdjustment?: CustomerPlanAdjustmentResolvers<ContextType>;
  CustomerPlanAdjustment_aggregate?: CustomerPlanAdjustment_AggregateResolvers<ContextType>;
  CustomerPlanAdjustment_aggregate_fields?: CustomerPlanAdjustment_Aggregate_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_avg_fields?: CustomerPlanAdjustment_Avg_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_max_fields?: CustomerPlanAdjustment_Max_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_min_fields?: CustomerPlanAdjustment_Min_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_mutation_response?: CustomerPlanAdjustment_Mutation_ResponseResolvers<ContextType>;
  CustomerPlanAdjustment_stddev_fields?: CustomerPlanAdjustment_Stddev_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_stddev_pop_fields?: CustomerPlanAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_stddev_samp_fields?: CustomerPlanAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_sum_fields?: CustomerPlanAdjustment_Sum_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_var_pop_fields?: CustomerPlanAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_var_samp_fields?: CustomerPlanAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  CustomerPlanAdjustment_variance_fields?: CustomerPlanAdjustment_Variance_FieldsResolvers<ContextType>;
  CustomerPlanManagedField?: CustomerPlanManagedFieldResolvers<ContextType>;
  CustomerPlanManagedField_aggregate?: CustomerPlanManagedField_AggregateResolvers<ContextType>;
  CustomerPlanManagedField_aggregate_fields?: CustomerPlanManagedField_Aggregate_FieldsResolvers<ContextType>;
  CustomerPlanManagedField_max_fields?: CustomerPlanManagedField_Max_FieldsResolvers<ContextType>;
  CustomerPlanManagedField_min_fields?: CustomerPlanManagedField_Min_FieldsResolvers<ContextType>;
  CustomerPlanManagedField_mutation_response?: CustomerPlanManagedField_Mutation_ResponseResolvers<ContextType>;
  CustomerPlanOneTimeCharge?: CustomerPlanOneTimeChargeResolvers<ContextType>;
  CustomerPlanOneTimeCharge_aggregate?: CustomerPlanOneTimeCharge_AggregateResolvers<ContextType>;
  CustomerPlanOneTimeCharge_aggregate_fields?: CustomerPlanOneTimeCharge_Aggregate_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_avg_fields?: CustomerPlanOneTimeCharge_Avg_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_max_fields?: CustomerPlanOneTimeCharge_Max_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_min_fields?: CustomerPlanOneTimeCharge_Min_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_mutation_response?: CustomerPlanOneTimeCharge_Mutation_ResponseResolvers<ContextType>;
  CustomerPlanOneTimeCharge_stddev_fields?: CustomerPlanOneTimeCharge_Stddev_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_stddev_pop_fields?: CustomerPlanOneTimeCharge_Stddev_Pop_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_stddev_samp_fields?: CustomerPlanOneTimeCharge_Stddev_Samp_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_sum_fields?: CustomerPlanOneTimeCharge_Sum_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_var_pop_fields?: CustomerPlanOneTimeCharge_Var_Pop_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_var_samp_fields?: CustomerPlanOneTimeCharge_Var_Samp_FieldsResolvers<ContextType>;
  CustomerPlanOneTimeCharge_variance_fields?: CustomerPlanOneTimeCharge_Variance_FieldsResolvers<ContextType>;
  CustomerPlanStatus?: CustomerPlanStatusResolvers<ContextType>;
  CustomerPlan_aggregate?: CustomerPlan_AggregateResolvers<ContextType>;
  CustomerPlan_aggregate_fields?: CustomerPlan_Aggregate_FieldsResolvers<ContextType>;
  CustomerPlan_avg_fields?: CustomerPlan_Avg_FieldsResolvers<ContextType>;
  CustomerPlan_max_fields?: CustomerPlan_Max_FieldsResolvers<ContextType>;
  CustomerPlan_min_fields?: CustomerPlan_Min_FieldsResolvers<ContextType>;
  CustomerPlan_mutation_response?: CustomerPlan_Mutation_ResponseResolvers<ContextType>;
  CustomerPlan_stddev_fields?: CustomerPlan_Stddev_FieldsResolvers<ContextType>;
  CustomerPlan_stddev_pop_fields?: CustomerPlan_Stddev_Pop_FieldsResolvers<ContextType>;
  CustomerPlan_stddev_samp_fields?: CustomerPlan_Stddev_Samp_FieldsResolvers<ContextType>;
  CustomerPlan_sum_fields?: CustomerPlan_Sum_FieldsResolvers<ContextType>;
  CustomerPlan_var_pop_fields?: CustomerPlan_Var_Pop_FieldsResolvers<ContextType>;
  CustomerPlan_var_samp_fields?: CustomerPlan_Var_Samp_FieldsResolvers<ContextType>;
  CustomerPlan_variance_fields?: CustomerPlan_Variance_FieldsResolvers<ContextType>;
  CustomerRelationship?: CustomerRelationshipResolvers<ContextType>;
  CustomerRelationship_aggregate?: CustomerRelationship_AggregateResolvers<ContextType>;
  CustomerRelationship_aggregate_fields?: CustomerRelationship_Aggregate_FieldsResolvers<ContextType>;
  CustomerRelationship_max_fields?: CustomerRelationship_Max_FieldsResolvers<ContextType>;
  CustomerRelationship_min_fields?: CustomerRelationship_Min_FieldsResolvers<ContextType>;
  CustomerRelationship_mutation_response?: CustomerRelationship_Mutation_ResponseResolvers<ContextType>;
  CustomerUsingContracts?: CustomerUsingContractsResolvers<ContextType>;
  Customer_aggregate?: Customer_AggregateResolvers<ContextType>;
  Customer_aggregate_fields?: Customer_Aggregate_FieldsResolvers<ContextType>;
  Customer_max_fields?: Customer_Max_FieldsResolvers<ContextType>;
  Customer_min_fields?: Customer_Min_FieldsResolvers<ContextType>;
  Customer_mutation_response?: Customer_Mutation_ResponseResolvers<ContextType>;
  CustomersMetric?: CustomersMetricResolvers<ContextType>;
  CustomersUsingContracts?: CustomersUsingContractsResolvers<ContextType>;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRange?: CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResolvers<ContextType>;
  CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponse?: CustomersWithCreditGrantsTakingEffectOrExpiringWithinTimeRangeResponseResolvers<ContextType>;
  Discount?: DiscountResolvers<ContextType>;
  DiscountSchedule?: DiscountScheduleResolvers<ContextType>;
  DiscountScheduleItem?: DiscountScheduleItemResolvers<ContextType>;
  DraftPlan?: DraftPlanResolvers<ContextType>;
  DraftPlan_aggregate?: DraftPlan_AggregateResolvers<ContextType>;
  DraftPlan_aggregate_fields?: DraftPlan_Aggregate_FieldsResolvers<ContextType>;
  DraftPlan_avg_fields?: DraftPlan_Avg_FieldsResolvers<ContextType>;
  DraftPlan_max_fields?: DraftPlan_Max_FieldsResolvers<ContextType>;
  DraftPlan_min_fields?: DraftPlan_Min_FieldsResolvers<ContextType>;
  DraftPlan_mutation_response?: DraftPlan_Mutation_ResponseResolvers<ContextType>;
  DraftPlan_stddev_fields?: DraftPlan_Stddev_FieldsResolvers<ContextType>;
  DraftPlan_stddev_pop_fields?: DraftPlan_Stddev_Pop_FieldsResolvers<ContextType>;
  DraftPlan_stddev_samp_fields?: DraftPlan_Stddev_Samp_FieldsResolvers<ContextType>;
  DraftPlan_sum_fields?: DraftPlan_Sum_FieldsResolvers<ContextType>;
  DraftPlan_var_pop_fields?: DraftPlan_Var_Pop_FieldsResolvers<ContextType>;
  DraftPlan_var_samp_fields?: DraftPlan_Var_Samp_FieldsResolvers<ContextType>;
  DraftPlan_variance_fields?: DraftPlan_Variance_FieldsResolvers<ContextType>;
  EditPlanResponse?: EditPlanResponseResolvers<ContextType>;
  EffectiveDates?: EffectiveDatesResolvers<ContextType>;
  Environment?: EnvironmentResolvers<ContextType>;
  EnvironmentTypeEnum?: EnvironmentTypeEnumResolvers<ContextType>;
  EnvironmentTypeEnum_aggregate?: EnvironmentTypeEnum_AggregateResolvers<ContextType>;
  EnvironmentTypeEnum_aggregate_fields?: EnvironmentTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  EnvironmentTypeEnum_max_fields?: EnvironmentTypeEnum_Max_FieldsResolvers<ContextType>;
  EnvironmentTypeEnum_min_fields?: EnvironmentTypeEnum_Min_FieldsResolvers<ContextType>;
  EnvironmentTypeEnum_mutation_response?: EnvironmentTypeEnum_Mutation_ResponseResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventCountWindow?: EventCountWindowResolvers<ContextType>;
  FailedMarketplacesMeteringRecord?: FailedMarketplacesMeteringRecordResolvers<ContextType>;
  FailedMarketplacesMeteringRecordList?: FailedMarketplacesMeteringRecordListResolvers<ContextType>;
  FancyMetricValidationResponse?: FancyMetricValidationResponseResolvers<ContextType>;
  FixedOrProServiceProductListItem?: FixedOrProServiceProductListItemResolvers<ContextType>;
  FixedProductListItem?: FixedProductListItemResolvers<ContextType>;
  FixedProductListItemState?: FixedProductListItemStateResolvers<ContextType>;
  FlatFee?: FlatFeeResolvers<ContextType>;
  FlatFeeAdjustment?: FlatFeeAdjustmentResolvers<ContextType>;
  FlatFeeAdjustment_aggregate?: FlatFeeAdjustment_AggregateResolvers<ContextType>;
  FlatFeeAdjustment_aggregate_fields?: FlatFeeAdjustment_Aggregate_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_avg_fields?: FlatFeeAdjustment_Avg_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_max_fields?: FlatFeeAdjustment_Max_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_min_fields?: FlatFeeAdjustment_Min_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_mutation_response?: FlatFeeAdjustment_Mutation_ResponseResolvers<ContextType>;
  FlatFeeAdjustment_stddev_fields?: FlatFeeAdjustment_Stddev_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_stddev_pop_fields?: FlatFeeAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_stddev_samp_fields?: FlatFeeAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_sum_fields?: FlatFeeAdjustment_Sum_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_var_pop_fields?: FlatFeeAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_var_samp_fields?: FlatFeeAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  FlatFeeAdjustment_variance_fields?: FlatFeeAdjustment_Variance_FieldsResolvers<ContextType>;
  FlatFee_aggregate?: FlatFee_AggregateResolvers<ContextType>;
  FlatFee_aggregate_fields?: FlatFee_Aggregate_FieldsResolvers<ContextType>;
  FlatFee_avg_fields?: FlatFee_Avg_FieldsResolvers<ContextType>;
  FlatFee_max_fields?: FlatFee_Max_FieldsResolvers<ContextType>;
  FlatFee_min_fields?: FlatFee_Min_FieldsResolvers<ContextType>;
  FlatFee_mutation_response?: FlatFee_Mutation_ResponseResolvers<ContextType>;
  FlatFee_stddev_fields?: FlatFee_Stddev_FieldsResolvers<ContextType>;
  FlatFee_stddev_pop_fields?: FlatFee_Stddev_Pop_FieldsResolvers<ContextType>;
  FlatFee_stddev_samp_fields?: FlatFee_Stddev_Samp_FieldsResolvers<ContextType>;
  FlatFee_sum_fields?: FlatFee_Sum_FieldsResolvers<ContextType>;
  FlatFee_var_pop_fields?: FlatFee_Var_Pop_FieldsResolvers<ContextType>;
  FlatFee_var_samp_fields?: FlatFee_Var_Samp_FieldsResolvers<ContextType>;
  FlatFee_variance_fields?: FlatFee_Variance_FieldsResolvers<ContextType>;
  FlatKeyValueMap?: GraphQLScalarType;
  FlatRate?: FlatRateResolvers<ContextType>;
  GCPProServiceRoyalty?: GcpProServiceRoyaltyResolvers<ContextType>;
  GCPProServiceRoyaltyUpdate?: GcpProServiceRoyaltyUpdateResolvers<ContextType>;
  GCPRoyalty?: GcpRoyaltyResolvers<ContextType>;
  GCPRoyaltyUpdate?: GcpRoyaltyUpdateResolvers<ContextType>;
  GenerateAzureAuthTokenMutationResponse?: GenerateAzureAuthTokenMutationResponseResolvers<ContextType>;
  GroupedChargeLineItem?: GroupedChargeLineItemResolvers<ContextType>;
  GroupedProductChargeLineItem?: GroupedProductChargeLineItemResolvers<ContextType>;
  GroupedUsage?: GroupedUsageResolvers<ContextType>;
  GroupedUsageTuple?: GroupedUsageTupleResolvers<ContextType>;
  GroupedUsageWindow?: GroupedUsageWindowResolvers<ContextType>;
  InitialUsageFilter?: InitialUsageFilterResolvers<ContextType>;
  InsertBillingProviderDeliveryMethodResult?: InsertBillingProviderDeliveryMethodResultResolvers<ContextType>;
  InsertCustomerBillingProviderConfigurationResult?: InsertCustomerBillingProviderConfigurationResultResolvers<ContextType>;
  Invoice?: InvoiceResolvers<ContextType>;
  InvoiceAdjustment?: InvoiceAdjustmentResolvers<ContextType>;
  InvoiceCorrectionRecord?: InvoiceCorrectionRecordResolvers<ContextType>;
  InvoiceCountsByFinalizationStatusResponse?: InvoiceCountsByFinalizationStatusResponseResolvers<ContextType>;
  InvoiceExternalStatusEnum?: InvoiceExternalStatusEnumResolvers<ContextType>;
  InvoiceExternalStatusEnum_aggregate?: InvoiceExternalStatusEnum_AggregateResolvers<ContextType>;
  InvoiceExternalStatusEnum_aggregate_fields?: InvoiceExternalStatusEnum_Aggregate_FieldsResolvers<ContextType>;
  InvoiceExternalStatusEnum_max_fields?: InvoiceExternalStatusEnum_Max_FieldsResolvers<ContextType>;
  InvoiceExternalStatusEnum_min_fields?: InvoiceExternalStatusEnum_Min_FieldsResolvers<ContextType>;
  InvoiceExternalStatusEnum_mutation_response?: InvoiceExternalStatusEnum_Mutation_ResponseResolvers<ContextType>;
  InvoiceFinalizationMetadata?: InvoiceFinalizationMetadataResolvers<ContextType>;
  InvoiceFinalizationProgressCountResponse?: InvoiceFinalizationProgressCountResponseResolvers<ContextType>;
  InvoiceIdentifier?: InvoiceIdentifierResolvers<ContextType>;
  InvoiceManagedField?: InvoiceManagedFieldResolvers<ContextType>;
  InvoiceManagedField_aggregate?: InvoiceManagedField_AggregateResolvers<ContextType>;
  InvoiceManagedField_aggregate_fields?: InvoiceManagedField_Aggregate_FieldsResolvers<ContextType>;
  InvoiceManagedField_max_fields?: InvoiceManagedField_Max_FieldsResolvers<ContextType>;
  InvoiceManagedField_min_fields?: InvoiceManagedField_Min_FieldsResolvers<ContextType>;
  InvoiceManagedField_mutation_response?: InvoiceManagedField_Mutation_ResponseResolvers<ContextType>;
  InvoicePage?: InvoicePageResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  LaunchSoxReportGeneratorMutationResponse?: LaunchSoxReportGeneratorMutationResponseResolvers<ContextType>;
  LegacyContractLineItem?: LegacyContractLineItemResolvers<ContextType>;
  LegacyLineItem?: LegacyLineItemResolvers<ContextType>;
  LineItem?: LineItemResolvers<ContextType>;
  LineItemChunk?: LineItemChunkResolvers<ContextType>;
  ListDeliveryMethodsResponse?: ListDeliveryMethodsResponseResolvers<ContextType>;
  ListDeliveryMethodsWithoutConfigurationForAllClientsResponse?: ListDeliveryMethodsWithoutConfigurationForAllClientsResponseResolvers<ContextType>;
  MRI_CreditBalance?: Mri_CreditBalanceResolvers<ContextType>;
  MRI_CreditDeductionCreditLedgerEntry?: Mri_CreditDeductionCreditLedgerEntryResolvers<ContextType>;
  MRI_CreditExpirationCreditLedgerEntry?: Mri_CreditExpirationCreditLedgerEntryResolvers<ContextType>;
  MRI_CreditGrant?: Mri_CreditGrantResolvers<ContextType>;
  MRI_CreditGrantCreditLedgerEntry?: Mri_CreditGrantCreditLedgerEntryResolvers<ContextType>;
  MRI_CreditLedger?: Mri_CreditLedgerResolvers<ContextType>;
  MRI_CreditLedgerEntry?: Mri_CreditLedgerEntryResolvers<ContextType>;
  MRI_GroupedUsage?: Mri_GroupedUsageResolvers<ContextType>;
  MRI_GroupedUsageTuple?: Mri_GroupedUsageTupleResolvers<ContextType>;
  MRI_GroupedUsageWindow?: Mri_GroupedUsageWindowResolvers<ContextType>;
  MRI_Invoice?: Mri_InvoiceResolvers<ContextType>;
  MRI_LineItem?: Mri_LineItemResolvers<ContextType>;
  MRI_PendingChargeCreditLedgerEntry?: Mri_PendingChargeCreditLedgerEntryResolvers<ContextType>;
  MRI_PendingCreditExpirationCreditLedgerEntry?: Mri_PendingCreditExpirationCreditLedgerEntryResolvers<ContextType>;
  MRI_PendingRolloverCreditGrantLedgerEntry?: Mri_PendingRolloverCreditGrantLedgerEntryResolvers<ContextType>;
  MRI_PendingRolloverDeductionLedgerEntry?: Mri_PendingRolloverDeductionLedgerEntryResolvers<ContextType>;
  MRI_RolloverCreditGrantLedgerEntry?: Mri_RolloverCreditGrantLedgerEntryResolvers<ContextType>;
  MRI_RolloverDeductionLedgerEntry?: Mri_RolloverDeductionLedgerEntryResolvers<ContextType>;
  ManagedEntityEnum?: ManagedEntityEnumResolvers<ContextType>;
  ManagedEntityEnum_aggregate?: ManagedEntityEnum_AggregateResolvers<ContextType>;
  ManagedEntityEnum_aggregate_fields?: ManagedEntityEnum_Aggregate_FieldsResolvers<ContextType>;
  ManagedEntityEnum_max_fields?: ManagedEntityEnum_Max_FieldsResolvers<ContextType>;
  ManagedEntityEnum_min_fields?: ManagedEntityEnum_Min_FieldsResolvers<ContextType>;
  ManagedEntityEnum_mutation_response?: ManagedEntityEnum_Mutation_ResponseResolvers<ContextType>;
  ManagedFieldKey?: ManagedFieldKeyResolvers<ContextType>;
  ManagedFieldKey_aggregate?: ManagedFieldKey_AggregateResolvers<ContextType>;
  ManagedFieldKey_aggregate_fields?: ManagedFieldKey_Aggregate_FieldsResolvers<ContextType>;
  ManagedFieldKey_max_fields?: ManagedFieldKey_Max_FieldsResolvers<ContextType>;
  ManagedFieldKey_min_fields?: ManagedFieldKey_Min_FieldsResolvers<ContextType>;
  ManagedFieldKey_mutation_response?: ManagedFieldKey_Mutation_ResponseResolvers<ContextType>;
  ManagedFieldVendorMapping?: ManagedFieldVendorMappingResolvers<ContextType>;
  ManagedFieldVendorMapping_aggregate?: ManagedFieldVendorMapping_AggregateResolvers<ContextType>;
  ManagedFieldVendorMapping_aggregate_fields?: ManagedFieldVendorMapping_Aggregate_FieldsResolvers<ContextType>;
  ManagedFieldVendorMapping_max_fields?: ManagedFieldVendorMapping_Max_FieldsResolvers<ContextType>;
  ManagedFieldVendorMapping_min_fields?: ManagedFieldVendorMapping_Min_FieldsResolvers<ContextType>;
  ManagedFieldVendorMapping_mutation_response?: ManagedFieldVendorMapping_Mutation_ResponseResolvers<ContextType>;
  ManagedVendorEnum?: ManagedVendorEnumResolvers<ContextType>;
  ManagedVendorEnum_aggregate?: ManagedVendorEnum_AggregateResolvers<ContextType>;
  ManagedVendorEnum_aggregate_fields?: ManagedVendorEnum_Aggregate_FieldsResolvers<ContextType>;
  ManagedVendorEnum_max_fields?: ManagedVendorEnum_Max_FieldsResolvers<ContextType>;
  ManagedVendorEnum_min_fields?: ManagedVendorEnum_Min_FieldsResolvers<ContextType>;
  ManagedVendorEnum_mutation_response?: ManagedVendorEnum_Mutation_ResponseResolvers<ContextType>;
  MetricValidationResponse?: MetricValidationResponseResolvers<ContextType>;
  Minimum?: MinimumResolvers<ContextType>;
  MinimumLineItem?: MinimumLineItemResolvers<ContextType>;
  Minimum_aggregate?: Minimum_AggregateResolvers<ContextType>;
  Minimum_aggregate_fields?: Minimum_Aggregate_FieldsResolvers<ContextType>;
  Minimum_avg_fields?: Minimum_Avg_FieldsResolvers<ContextType>;
  Minimum_max_fields?: Minimum_Max_FieldsResolvers<ContextType>;
  Minimum_min_fields?: Minimum_Min_FieldsResolvers<ContextType>;
  Minimum_mutation_response?: Minimum_Mutation_ResponseResolvers<ContextType>;
  Minimum_stddev_fields?: Minimum_Stddev_FieldsResolvers<ContextType>;
  Minimum_stddev_pop_fields?: Minimum_Stddev_Pop_FieldsResolvers<ContextType>;
  Minimum_stddev_samp_fields?: Minimum_Stddev_Samp_FieldsResolvers<ContextType>;
  Minimum_sum_fields?: Minimum_Sum_FieldsResolvers<ContextType>;
  Minimum_var_pop_fields?: Minimum_Var_Pop_FieldsResolvers<ContextType>;
  Minimum_var_samp_fields?: Minimum_Var_Samp_FieldsResolvers<ContextType>;
  Minimum_variance_fields?: Minimum_Variance_FieldsResolvers<ContextType>;
  MultiplierOverride?: MultiplierOverrideResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationResponse?: MutationResponseResolvers<ContextType>;
  NamedSchedule?: NamedScheduleResolvers<ContextType>;
  NamedScheduleData?: NamedScheduleDataResolvers<ContextType>;
  NamedScheduleSegment?: NamedScheduleSegmentResolvers<ContextType>;
  NonCompositeProductListItem?: NonCompositeProductListItemResolvers<ContextType>;
  OneTimeProductCharge?: OneTimeProductChargeResolvers<ContextType>;
  OverageLineItem?: OverageLineItemResolvers<ContextType>;
  OverrideRate?: OverrideRateResolvers<ContextType>;
  OverrideSpecifier?: OverrideSpecifierResolvers<ContextType>;
  OverwriteOverride?: OverwriteOverrideResolvers<ContextType>;
  PagedSeatMetricsResult?: PagedSeatMetricsResultResolvers<ContextType>;
  PagedUsage?: PagedUsageResolvers<ContextType>;
  PagedUsageWindow?: PagedUsageWindowResolvers<ContextType>;
  ParentInvoice?: ParentInvoiceResolvers<ContextType>;
  PendingPrepaidGrant?: PendingPrepaidGrantResolvers<ContextType>;
  PendingPrepaidGrantsResponse?: PendingPrepaidGrantsResponseResolvers<ContextType>;
  PendingRechargesResponse?: PendingRechargesResponseResolvers<ContextType>;
  PepaidCreditGrantResponse?: PepaidCreditGrantResponseResolvers<ContextType>;
  PercentageRate?: PercentageRateResolvers<ContextType>;
  Plan?: PlanResolvers<ContextType>;
  PlanManagedField?: PlanManagedFieldResolvers<ContextType>;
  PlanManagedField_aggregate?: PlanManagedField_AggregateResolvers<ContextType>;
  PlanManagedField_aggregate_fields?: PlanManagedField_Aggregate_FieldsResolvers<ContextType>;
  PlanManagedField_max_fields?: PlanManagedField_Max_FieldsResolvers<ContextType>;
  PlanManagedField_min_fields?: PlanManagedField_Min_FieldsResolvers<ContextType>;
  PlanManagedField_mutation_response?: PlanManagedField_Mutation_ResponseResolvers<ContextType>;
  Plan_aggregate?: Plan_AggregateResolvers<ContextType>;
  Plan_aggregate_fields?: Plan_Aggregate_FieldsResolvers<ContextType>;
  Plan_avg_fields?: Plan_Avg_FieldsResolvers<ContextType>;
  Plan_max_fields?: Plan_Max_FieldsResolvers<ContextType>;
  Plan_min_fields?: Plan_Min_FieldsResolvers<ContextType>;
  Plan_mutation_response?: Plan_Mutation_ResponseResolvers<ContextType>;
  Plan_stddev_fields?: Plan_Stddev_FieldsResolvers<ContextType>;
  Plan_stddev_pop_fields?: Plan_Stddev_Pop_FieldsResolvers<ContextType>;
  Plan_stddev_samp_fields?: Plan_Stddev_Samp_FieldsResolvers<ContextType>;
  Plan_sum_fields?: Plan_Sum_FieldsResolvers<ContextType>;
  Plan_var_pop_fields?: Plan_Var_Pop_FieldsResolvers<ContextType>;
  Plan_var_samp_fields?: Plan_Var_Samp_FieldsResolvers<ContextType>;
  Plan_variance_fields?: Plan_Variance_FieldsResolvers<ContextType>;
  PostpaidCommit?: PostpaidCommitResolvers<ContextType>;
  PostpaidCommitAutomatedInvoiceDeductionLedgerEntry?: PostpaidCommitAutomatedInvoiceDeductionLedgerEntryResolvers<ContextType>;
  PostpaidCommitBaseLedgerEntry?: PostpaidCommitBaseLedgerEntryResolvers<ContextType>;
  PostpaidCommitCanceledLedgerEntry?: PostpaidCommitCanceledLedgerEntryResolvers<ContextType>;
  PostpaidCommitCreditedLedgerEntry?: PostpaidCommitCreditedLedgerEntryResolvers<ContextType>;
  PostpaidCommitExpirationLedgerEntry?: PostpaidCommitExpirationLedgerEntryResolvers<ContextType>;
  PostpaidCommitInitialBalanceLedgerEntry?: PostpaidCommitInitialBalanceLedgerEntryResolvers<ContextType>;
  PostpaidCommitLedgerEntry?: PostpaidCommitLedgerEntryResolvers<ContextType>;
  PostpaidCommitManualLedgerEntry?: PostpaidCommitManualLedgerEntryResolvers<ContextType>;
  PostpaidCommitRolloverLedgerEntry?: PostpaidCommitRolloverLedgerEntryResolvers<ContextType>;
  PostpaidCommitTrueupLedgerEntry?: PostpaidCommitTrueupLedgerEntryResolvers<ContextType>;
  PrepaidCommit?: PrepaidCommitResolvers<ContextType>;
  PrepaidCommitAutomatedInvoiceDeductionLedgerEntry?: PrepaidCommitAutomatedInvoiceDeductionLedgerEntryResolvers<ContextType>;
  PrepaidCommitBaseLedgerEntry?: PrepaidCommitBaseLedgerEntryResolvers<ContextType>;
  PrepaidCommitCanceledLedgerEntry?: PrepaidCommitCanceledLedgerEntryResolvers<ContextType>;
  PrepaidCommitCreditedLedgerEntry?: PrepaidCommitCreditedLedgerEntryResolvers<ContextType>;
  PrepaidCommitExpirationLedgerEntry?: PrepaidCommitExpirationLedgerEntryResolvers<ContextType>;
  PrepaidCommitLedgerEntry?: PrepaidCommitLedgerEntryResolvers<ContextType>;
  PrepaidCommitManualLedgerEntry?: PrepaidCommitManualLedgerEntryResolvers<ContextType>;
  PrepaidCommitRolloverLedgerEntry?: PrepaidCommitRolloverLedgerEntryResolvers<ContextType>;
  PrepaidCommitSegmentStartLedgerEntry?: PrepaidCommitSegmentStartLedgerEntryResolvers<ContextType>;
  PrepaidCreditGrant?: PrepaidCreditGrantResolvers<ContextType>;
  PrepaidCreditGrantRechargeResponse?: PrepaidCreditGrantRechargeResponseResolvers<ContextType>;
  PrequelAuthTokenResponse?: PrequelAuthTokenResponseResolvers<ContextType>;
  PresentationGroupValue?: PresentationGroupValueResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  PriceAdjustment?: PriceAdjustmentResolvers<ContextType>;
  PriceAdjustmentTypeEnum?: PriceAdjustmentTypeEnumResolvers<ContextType>;
  PriceAdjustmentTypeEnum_aggregate?: PriceAdjustmentTypeEnum_AggregateResolvers<ContextType>;
  PriceAdjustmentTypeEnum_aggregate_fields?: PriceAdjustmentTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  PriceAdjustmentTypeEnum_max_fields?: PriceAdjustmentTypeEnum_Max_FieldsResolvers<ContextType>;
  PriceAdjustmentTypeEnum_min_fields?: PriceAdjustmentTypeEnum_Min_FieldsResolvers<ContextType>;
  PriceAdjustmentTypeEnum_mutation_response?: PriceAdjustmentTypeEnum_Mutation_ResponseResolvers<ContextType>;
  PriceAdjustment_aggregate?: PriceAdjustment_AggregateResolvers<ContextType>;
  PriceAdjustment_aggregate_fields?: PriceAdjustment_Aggregate_FieldsResolvers<ContextType>;
  PriceAdjustment_avg_fields?: PriceAdjustment_Avg_FieldsResolvers<ContextType>;
  PriceAdjustment_max_fields?: PriceAdjustment_Max_FieldsResolvers<ContextType>;
  PriceAdjustment_min_fields?: PriceAdjustment_Min_FieldsResolvers<ContextType>;
  PriceAdjustment_mutation_response?: PriceAdjustment_Mutation_ResponseResolvers<ContextType>;
  PriceAdjustment_stddev_fields?: PriceAdjustment_Stddev_FieldsResolvers<ContextType>;
  PriceAdjustment_stddev_pop_fields?: PriceAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  PriceAdjustment_stddev_samp_fields?: PriceAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  PriceAdjustment_sum_fields?: PriceAdjustment_Sum_FieldsResolvers<ContextType>;
  PriceAdjustment_var_pop_fields?: PriceAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  PriceAdjustment_var_samp_fields?: PriceAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  PriceAdjustment_variance_fields?: PriceAdjustment_Variance_FieldsResolvers<ContextType>;
  Price_aggregate?: Price_AggregateResolvers<ContextType>;
  Price_aggregate_fields?: Price_Aggregate_FieldsResolvers<ContextType>;
  Price_avg_fields?: Price_Avg_FieldsResolvers<ContextType>;
  Price_max_fields?: Price_Max_FieldsResolvers<ContextType>;
  Price_min_fields?: Price_Min_FieldsResolvers<ContextType>;
  Price_mutation_response?: Price_Mutation_ResponseResolvers<ContextType>;
  Price_stddev_fields?: Price_Stddev_FieldsResolvers<ContextType>;
  Price_stddev_pop_fields?: Price_Stddev_Pop_FieldsResolvers<ContextType>;
  Price_stddev_samp_fields?: Price_Stddev_Samp_FieldsResolvers<ContextType>;
  Price_sum_fields?: Price_Sum_FieldsResolvers<ContextType>;
  Price_var_pop_fields?: Price_Var_Pop_FieldsResolvers<ContextType>;
  Price_var_samp_fields?: Price_Var_Samp_FieldsResolvers<ContextType>;
  Price_variance_fields?: Price_Variance_FieldsResolvers<ContextType>;
  PricedProduct?: PricedProductResolvers<ContextType>;
  PricedProductAdjustment?: PricedProductAdjustmentResolvers<ContextType>;
  PricedProductAdjustment_aggregate?: PricedProductAdjustment_AggregateResolvers<ContextType>;
  PricedProductAdjustment_aggregate_fields?: PricedProductAdjustment_Aggregate_FieldsResolvers<ContextType>;
  PricedProductAdjustment_avg_fields?: PricedProductAdjustment_Avg_FieldsResolvers<ContextType>;
  PricedProductAdjustment_max_fields?: PricedProductAdjustment_Max_FieldsResolvers<ContextType>;
  PricedProductAdjustment_min_fields?: PricedProductAdjustment_Min_FieldsResolvers<ContextType>;
  PricedProductAdjustment_mutation_response?: PricedProductAdjustment_Mutation_ResponseResolvers<ContextType>;
  PricedProductAdjustment_stddev_fields?: PricedProductAdjustment_Stddev_FieldsResolvers<ContextType>;
  PricedProductAdjustment_stddev_pop_fields?: PricedProductAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  PricedProductAdjustment_stddev_samp_fields?: PricedProductAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  PricedProductAdjustment_sum_fields?: PricedProductAdjustment_Sum_FieldsResolvers<ContextType>;
  PricedProductAdjustment_var_pop_fields?: PricedProductAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  PricedProductAdjustment_var_samp_fields?: PricedProductAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  PricedProductAdjustment_variance_fields?: PricedProductAdjustment_Variance_FieldsResolvers<ContextType>;
  PricedProductPricingFactor?: PricedProductPricingFactorResolvers<ContextType>;
  PricedProductPricingFactorAdjustment?: PricedProductPricingFactorAdjustmentResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_aggregate?: PricedProductPricingFactorAdjustment_AggregateResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_aggregate_fields?: PricedProductPricingFactorAdjustment_Aggregate_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_avg_fields?: PricedProductPricingFactorAdjustment_Avg_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_max_fields?: PricedProductPricingFactorAdjustment_Max_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_min_fields?: PricedProductPricingFactorAdjustment_Min_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_mutation_response?: PricedProductPricingFactorAdjustment_Mutation_ResponseResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_stddev_fields?: PricedProductPricingFactorAdjustment_Stddev_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_stddev_pop_fields?: PricedProductPricingFactorAdjustment_Stddev_Pop_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_stddev_samp_fields?: PricedProductPricingFactorAdjustment_Stddev_Samp_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_sum_fields?: PricedProductPricingFactorAdjustment_Sum_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_var_pop_fields?: PricedProductPricingFactorAdjustment_Var_Pop_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_var_samp_fields?: PricedProductPricingFactorAdjustment_Var_Samp_FieldsResolvers<ContextType>;
  PricedProductPricingFactorAdjustment_variance_fields?: PricedProductPricingFactorAdjustment_Variance_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_aggregate?: PricedProductPricingFactor_AggregateResolvers<ContextType>;
  PricedProductPricingFactor_aggregate_fields?: PricedProductPricingFactor_Aggregate_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_avg_fields?: PricedProductPricingFactor_Avg_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_max_fields?: PricedProductPricingFactor_Max_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_min_fields?: PricedProductPricingFactor_Min_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_mutation_response?: PricedProductPricingFactor_Mutation_ResponseResolvers<ContextType>;
  PricedProductPricingFactor_stddev_fields?: PricedProductPricingFactor_Stddev_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_stddev_pop_fields?: PricedProductPricingFactor_Stddev_Pop_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_stddev_samp_fields?: PricedProductPricingFactor_Stddev_Samp_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_sum_fields?: PricedProductPricingFactor_Sum_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_var_pop_fields?: PricedProductPricingFactor_Var_Pop_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_var_samp_fields?: PricedProductPricingFactor_Var_Samp_FieldsResolvers<ContextType>;
  PricedProductPricingFactor_variance_fields?: PricedProductPricingFactor_Variance_FieldsResolvers<ContextType>;
  PricedProduct_aggregate?: PricedProduct_AggregateResolvers<ContextType>;
  PricedProduct_aggregate_fields?: PricedProduct_Aggregate_FieldsResolvers<ContextType>;
  PricedProduct_avg_fields?: PricedProduct_Avg_FieldsResolvers<ContextType>;
  PricedProduct_max_fields?: PricedProduct_Max_FieldsResolvers<ContextType>;
  PricedProduct_min_fields?: PricedProduct_Min_FieldsResolvers<ContextType>;
  PricedProduct_mutation_response?: PricedProduct_Mutation_ResponseResolvers<ContextType>;
  PricedProduct_stddev_fields?: PricedProduct_Stddev_FieldsResolvers<ContextType>;
  PricedProduct_stddev_pop_fields?: PricedProduct_Stddev_Pop_FieldsResolvers<ContextType>;
  PricedProduct_stddev_samp_fields?: PricedProduct_Stddev_Samp_FieldsResolvers<ContextType>;
  PricedProduct_sum_fields?: PricedProduct_Sum_FieldsResolvers<ContextType>;
  PricedProduct_var_pop_fields?: PricedProduct_Var_Pop_FieldsResolvers<ContextType>;
  PricedProduct_var_samp_fields?: PricedProduct_Var_Samp_FieldsResolvers<ContextType>;
  PricedProduct_variance_fields?: PricedProduct_Variance_FieldsResolvers<ContextType>;
  PricingGroupValue?: PricingGroupValueResolvers<ContextType>;
  PricingSchemeIdentifier?: PricingSchemeIdentifierResolvers<ContextType>;
  ProService?: ProServiceResolvers<ContextType>;
  ProServiceManagedField?: ProServiceManagedFieldResolvers<ContextType>;
  ProServiceManagedField_aggregate?: ProServiceManagedField_AggregateResolvers<ContextType>;
  ProServiceManagedField_aggregate_fields?: ProServiceManagedField_Aggregate_FieldsResolvers<ContextType>;
  ProServiceManagedField_max_fields?: ProServiceManagedField_Max_FieldsResolvers<ContextType>;
  ProServiceManagedField_min_fields?: ProServiceManagedField_Min_FieldsResolvers<ContextType>;
  ProServiceManagedField_mutation_response?: ProServiceManagedField_Mutation_ResponseResolvers<ContextType>;
  ProServiceProductListItem?: ProServiceProductListItemResolvers<ContextType>;
  ProServiceProductListItemState?: ProServiceProductListItemStateResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductChargeLineItem?: ProductChargeLineItemResolvers<ContextType>;
  ProductChargeSubLineItem?: ProductChargeSubLineItemResolvers<ContextType>;
  ProductListItem?: ProductListItemResolvers<ContextType>;
  ProductListItemUpdate?: ProductListItemUpdateResolvers<ContextType>;
  ProductManagedField?: ProductManagedFieldResolvers<ContextType>;
  ProductManagedField_aggregate?: ProductManagedField_AggregateResolvers<ContextType>;
  ProductManagedField_aggregate_fields?: ProductManagedField_Aggregate_FieldsResolvers<ContextType>;
  ProductManagedField_max_fields?: ProductManagedField_Max_FieldsResolvers<ContextType>;
  ProductManagedField_min_fields?: ProductManagedField_Min_FieldsResolvers<ContextType>;
  ProductManagedField_mutation_response?: ProductManagedField_Mutation_ResponseResolvers<ContextType>;
  ProductPricingFactor?: ProductPricingFactorResolvers<ContextType>;
  ProductPricingFactor_aggregate?: ProductPricingFactor_AggregateResolvers<ContextType>;
  ProductPricingFactor_aggregate_fields?: ProductPricingFactor_Aggregate_FieldsResolvers<ContextType>;
  ProductPricingFactor_avg_fields?: ProductPricingFactor_Avg_FieldsResolvers<ContextType>;
  ProductPricingFactor_max_fields?: ProductPricingFactor_Max_FieldsResolvers<ContextType>;
  ProductPricingFactor_min_fields?: ProductPricingFactor_Min_FieldsResolvers<ContextType>;
  ProductPricingFactor_mutation_response?: ProductPricingFactor_Mutation_ResponseResolvers<ContextType>;
  ProductPricingFactor_stddev_fields?: ProductPricingFactor_Stddev_FieldsResolvers<ContextType>;
  ProductPricingFactor_stddev_pop_fields?: ProductPricingFactor_Stddev_Pop_FieldsResolvers<ContextType>;
  ProductPricingFactor_stddev_samp_fields?: ProductPricingFactor_Stddev_Samp_FieldsResolvers<ContextType>;
  ProductPricingFactor_sum_fields?: ProductPricingFactor_Sum_FieldsResolvers<ContextType>;
  ProductPricingFactor_var_pop_fields?: ProductPricingFactor_Var_Pop_FieldsResolvers<ContextType>;
  ProductPricingFactor_var_samp_fields?: ProductPricingFactor_Var_Samp_FieldsResolvers<ContextType>;
  ProductPricingFactor_variance_fields?: ProductPricingFactor_Variance_FieldsResolvers<ContextType>;
  Product_aggregate?: Product_AggregateResolvers<ContextType>;
  Product_aggregate_fields?: Product_Aggregate_FieldsResolvers<ContextType>;
  Product_max_fields?: Product_Max_FieldsResolvers<ContextType>;
  Product_min_fields?: Product_Min_FieldsResolvers<ContextType>;
  Product_mutation_response?: Product_Mutation_ResponseResolvers<ContextType>;
  ProductsAndRateCards?: ProductsAndRateCardsResolvers<ContextType>;
  QuantityConversion?: QuantityConversionResolvers<ContextType>;
  QuantityConversionUpdate?: QuantityConversionUpdateResolvers<ContextType>;
  QuantityRounding?: QuantityRoundingResolvers<ContextType>;
  QuantityRoundingUpdate?: QuantityRoundingUpdateResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RateCard?: RateCardResolvers<ContextType>;
  RateCardAliasData?: RateCardAliasDataResolvers<ContextType>;
  RateCardAliasScheduleSegment?: RateCardAliasScheduleSegmentResolvers<ContextType>;
  RateCardCreditTypeConversion?: RateCardCreditTypeConversionResolvers<ContextType>;
  RateCardEntry?: RateCardEntryResolvers<ContextType>;
  RateCardEntryOverride?: RateCardEntryOverrideResolvers<ContextType>;
  RateCardEntryPricingGroupValueEntry?: RateCardEntryPricingGroupValueEntryResolvers<ContextType>;
  RateCardManagedField?: RateCardManagedFieldResolvers<ContextType>;
  RateCardManagedField_aggregate?: RateCardManagedField_AggregateResolvers<ContextType>;
  RateCardManagedField_aggregate_fields?: RateCardManagedField_Aggregate_FieldsResolvers<ContextType>;
  RateCardManagedField_max_fields?: RateCardManagedField_Max_FieldsResolvers<ContextType>;
  RateCardManagedField_min_fields?: RateCardManagedField_Min_FieldsResolvers<ContextType>;
  RateCardManagedField_mutation_response?: RateCardManagedField_Mutation_ResponseResolvers<ContextType>;
  RateCardMetadata?: RateCardMetadataResolvers<ContextType>;
  RateCardProductListItem?: RateCardProductListItemResolvers<ContextType>;
  RateSchedule?: RateScheduleResolvers<ContextType>;
  RateScheduleSegment?: RateScheduleSegmentResolvers<ContextType>;
  RatesPerProduct?: RatesPerProductResolvers<ContextType>;
  RechargeSettings?: RechargeSettingsResolvers<ContextType>;
  RechargeSettingsBillingProviderConfig?: RechargeSettingsBillingProviderConfigResolvers<ContextType>;
  RechargeSettingsStripeConfig?: RechargeSettingsStripeConfigResolvers<ContextType>;
  RechargeSettingsUpdateAndChargeResponse?: RechargeSettingsUpdateAndChargeResponseResolvers<ContextType>;
  RecordOfNullableStringArrays?: GraphQLScalarType;
  RecordOfNullableStrings?: GraphQLScalarType;
  RecordOfStrings?: GraphQLScalarType;
  RecurringCreditGrant?: RecurringCreditGrantResolvers<ContextType>;
  RecurringCreditGrant_aggregate?: RecurringCreditGrant_AggregateResolvers<ContextType>;
  RecurringCreditGrant_aggregate_fields?: RecurringCreditGrant_Aggregate_FieldsResolvers<ContextType>;
  RecurringCreditGrant_avg_fields?: RecurringCreditGrant_Avg_FieldsResolvers<ContextType>;
  RecurringCreditGrant_max_fields?: RecurringCreditGrant_Max_FieldsResolvers<ContextType>;
  RecurringCreditGrant_min_fields?: RecurringCreditGrant_Min_FieldsResolvers<ContextType>;
  RecurringCreditGrant_mutation_response?: RecurringCreditGrant_Mutation_ResponseResolvers<ContextType>;
  RecurringCreditGrant_stddev_fields?: RecurringCreditGrant_Stddev_FieldsResolvers<ContextType>;
  RecurringCreditGrant_stddev_pop_fields?: RecurringCreditGrant_Stddev_Pop_FieldsResolvers<ContextType>;
  RecurringCreditGrant_stddev_samp_fields?: RecurringCreditGrant_Stddev_Samp_FieldsResolvers<ContextType>;
  RecurringCreditGrant_sum_fields?: RecurringCreditGrant_Sum_FieldsResolvers<ContextType>;
  RecurringCreditGrant_var_pop_fields?: RecurringCreditGrant_Var_Pop_FieldsResolvers<ContextType>;
  RecurringCreditGrant_var_samp_fields?: RecurringCreditGrant_Var_Samp_FieldsResolvers<ContextType>;
  RecurringCreditGrant_variance_fields?: RecurringCreditGrant_Variance_FieldsResolvers<ContextType>;
  RecurringSchedule?: RecurringScheduleResolvers<ContextType>;
  RepairPrepaidCreditGrantResponse?: RepairPrepaidCreditGrantResponseResolvers<ContextType>;
  ResellerRoyalty?: ResellerRoyaltyResolvers<ContextType>;
  ResellerRoyaltyOrUpdate?: ResellerRoyaltyOrUpdateResolvers<ContextType>;
  ResellerRoyaltyUpdate?: ResellerRoyaltyUpdateResolvers<ContextType>;
  RevenueBreakdown?: RevenueBreakdownResolvers<ContextType>;
  RevenueMetric?: RevenueMetricResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  Role_aggregate?: Role_AggregateResolvers<ContextType>;
  Role_aggregate_fields?: Role_Aggregate_FieldsResolvers<ContextType>;
  Role_max_fields?: Role_Max_FieldsResolvers<ContextType>;
  Role_min_fields?: Role_Min_FieldsResolvers<ContextType>;
  Role_mutation_response?: Role_Mutation_ResponseResolvers<ContextType>;
  RolloverAmount?: RolloverAmountResolvers<ContextType>;
  RolloverMaxAmount?: RolloverMaxAmountResolvers<ContextType>;
  RolloverMaxPercentage?: RolloverMaxPercentageResolvers<ContextType>;
  RolloverSettings?: RolloverSettingsResolvers<ContextType>;
  RootLineItem?: RootLineItemResolvers<ContextType>;
  RoundingBehaviorEnum?: RoundingBehaviorEnumResolvers<ContextType>;
  RoundingBehaviorEnum_aggregate?: RoundingBehaviorEnum_AggregateResolvers<ContextType>;
  RoundingBehaviorEnum_aggregate_fields?: RoundingBehaviorEnum_Aggregate_FieldsResolvers<ContextType>;
  RoundingBehaviorEnum_max_fields?: RoundingBehaviorEnum_Max_FieldsResolvers<ContextType>;
  RoundingBehaviorEnum_min_fields?: RoundingBehaviorEnum_Min_FieldsResolvers<ContextType>;
  RoundingBehaviorEnum_mutation_response?: RoundingBehaviorEnum_Mutation_ResponseResolvers<ContextType>;
  SaveVendorEntityMappingsResponse?: SaveVendorEntityMappingsResponseResolvers<ContextType>;
  ScalarContractRateScheduleSegment?: ScalarContractRateScheduleSegmentResolvers<ContextType>;
  ScalarFlatRate?: ScalarFlatRateResolvers<ContextType>;
  ScalarRate?: ScalarRateResolvers<ContextType>;
  ScalarRateScheduleSegment?: ScalarRateScheduleSegmentResolvers<ContextType>;
  Scalar__ContractRateScheduleSegment?: GraphQLScalarType;
  Scalar__RateScheduleSegment?: GraphQLScalarType;
  ScheduledCharge?: ScheduledChargeResolvers<ContextType>;
  ScheduledChargeManagedField?: ScheduledChargeManagedFieldResolvers<ContextType>;
  ScheduledChargeManagedField_aggregate?: ScheduledChargeManagedField_AggregateResolvers<ContextType>;
  ScheduledChargeManagedField_aggregate_fields?: ScheduledChargeManagedField_Aggregate_FieldsResolvers<ContextType>;
  ScheduledChargeManagedField_max_fields?: ScheduledChargeManagedField_Max_FieldsResolvers<ContextType>;
  ScheduledChargeManagedField_min_fields?: ScheduledChargeManagedField_Min_FieldsResolvers<ContextType>;
  ScheduledChargeManagedField_mutation_response?: ScheduledChargeManagedField_Mutation_ResponseResolvers<ContextType>;
  ScheduledChargeSchedule?: ScheduledChargeScheduleResolvers<ContextType>;
  ScheduledChargeScheduleItem?: ScheduledChargeScheduleItemResolvers<ContextType>;
  ScheduledWebhookNotification?: ScheduledWebhookNotificationResolvers<ContextType>;
  ScheduledWebhookNotificationList?: ScheduledWebhookNotificationListResolvers<ContextType>;
  SeatChargeLineItem?: SeatChargeLineItemResolvers<ContextType>;
  SeatCount?: SeatCountResolvers<ContextType>;
  SeatMetric?: SeatMetricResolvers<ContextType>;
  SeatPrice?: SeatPriceResolvers<ContextType>;
  SeatPurchaseInvoice?: SeatPurchaseInvoiceResolvers<ContextType>;
  SequenceNumber?: SequenceNumberResolvers<ContextType>;
  ServicePeriod?: ServicePeriodResolvers<ContextType>;
  ServicePeriodStartOnEnum?: ServicePeriodStartOnEnumResolvers<ContextType>;
  ServicePeriodStartOnEnum_aggregate?: ServicePeriodStartOnEnum_AggregateResolvers<ContextType>;
  ServicePeriodStartOnEnum_aggregate_fields?: ServicePeriodStartOnEnum_Aggregate_FieldsResolvers<ContextType>;
  ServicePeriodStartOnEnum_max_fields?: ServicePeriodStartOnEnum_Max_FieldsResolvers<ContextType>;
  ServicePeriodStartOnEnum_min_fields?: ServicePeriodStartOnEnum_Min_FieldsResolvers<ContextType>;
  ServicePeriodStartOnEnum_mutation_response?: ServicePeriodStartOnEnum_Mutation_ResponseResolvers<ContextType>;
  SimulatedFancyMetricUsageData?: SimulatedFancyMetricUsageDataResolvers<ContextType>;
  SimulatedFancyMetricUsageResult?: SimulatedFancyMetricUsageResultResolvers<ContextType>;
  StripeCreditGrantResponse?: StripeCreditGrantResponseResolvers<ContextType>;
  StripeCustomer?: StripeCustomerResolvers<ContextType>;
  StripePrepaidConfig_temporary?: StripePrepaidConfig_TemporaryResolvers<ContextType>;
  StripePrepaidConfig_temporary_aggregate?: StripePrepaidConfig_Temporary_AggregateResolvers<ContextType>;
  StripePrepaidConfig_temporary_aggregate_fields?: StripePrepaidConfig_Temporary_Aggregate_FieldsResolvers<ContextType>;
  StripePrepaidConfig_temporary_max_fields?: StripePrepaidConfig_Temporary_Max_FieldsResolvers<ContextType>;
  StripePrepaidConfig_temporary_min_fields?: StripePrepaidConfig_Temporary_Min_FieldsResolvers<ContextType>;
  StripePrepaidConfig_temporary_mutation_response?: StripePrepaidConfig_Temporary_Mutation_ResponseResolvers<ContextType>;
  SubLineItem?: SubLineItemResolvers<ContextType>;
  SubLineItemTier?: SubLineItemTierResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionProductListItem?: SubscriptionProductListItemResolvers<ContextType>;
  SubscriptionProductListItemState?: SubscriptionProductListItemStateResolvers<ContextType>;
  SubscriptionRate?: SubscriptionRateResolvers<ContextType>;
  SubtotalAndQuantityByTime?: SubtotalAndQuantityByTimeResolvers<ContextType>;
  SubtotalByTime?: SubtotalByTimeResolvers<ContextType>;
  Tier?: TierResolvers<ContextType>;
  TierChargeLineItem?: TierChargeLineItemResolvers<ContextType>;
  TierMetadata?: TierMetadataResolvers<ContextType>;
  TierPeriod?: TierPeriodResolvers<ContextType>;
  TierPrice?: TierPriceResolvers<ContextType>;
  TierSubLineItem?: TierSubLineItemResolvers<ContextType>;
  TieredOverride?: TieredOverrideResolvers<ContextType>;
  TieredOverrideTiers?: TieredOverrideTiersResolvers<ContextType>;
  TieredRate?: TieredRateResolvers<ContextType>;
  TieringModeEnum?: TieringModeEnumResolvers<ContextType>;
  TieringModeEnum_aggregate?: TieringModeEnum_AggregateResolvers<ContextType>;
  TieringModeEnum_aggregate_fields?: TieringModeEnum_Aggregate_FieldsResolvers<ContextType>;
  TieringModeEnum_max_fields?: TieringModeEnum_Max_FieldsResolvers<ContextType>;
  TieringModeEnum_min_fields?: TieringModeEnum_Min_FieldsResolvers<ContextType>;
  TieringModeEnum_mutation_response?: TieringModeEnum_Mutation_ResponseResolvers<ContextType>;
  TimeRange?: TimeRangeResolvers<ContextType>;
  Tokens?: TokensResolvers<ContextType>;
  Tokens_aggregate?: Tokens_AggregateResolvers<ContextType>;
  Tokens_aggregate_fields?: Tokens_Aggregate_FieldsResolvers<ContextType>;
  Tokens_max_fields?: Tokens_Max_FieldsResolvers<ContextType>;
  Tokens_min_fields?: Tokens_Min_FieldsResolvers<ContextType>;
  Tokens_mutation_response?: Tokens_Mutation_ResponseResolvers<ContextType>;
  TopCustomerSpenders?: TopCustomerSpendersResolvers<ContextType>;
  Transition?: TransitionResolvers<ContextType>;
  TrialDiscountLineItem?: TrialDiscountLineItemResolvers<ContextType>;
  TrialSpec?: TrialSpecResolvers<ContextType>;
  TrialSpecSpendingCap?: TrialSpecSpendingCapResolvers<ContextType>;
  TrialSpecSpendingCap_aggregate?: TrialSpecSpendingCap_AggregateResolvers<ContextType>;
  TrialSpecSpendingCap_aggregate_fields?: TrialSpecSpendingCap_Aggregate_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_avg_fields?: TrialSpecSpendingCap_Avg_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_max_fields?: TrialSpecSpendingCap_Max_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_min_fields?: TrialSpecSpendingCap_Min_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_mutation_response?: TrialSpecSpendingCap_Mutation_ResponseResolvers<ContextType>;
  TrialSpecSpendingCap_stddev_fields?: TrialSpecSpendingCap_Stddev_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_stddev_pop_fields?: TrialSpecSpendingCap_Stddev_Pop_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_stddev_samp_fields?: TrialSpecSpendingCap_Stddev_Samp_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_sum_fields?: TrialSpecSpendingCap_Sum_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_var_pop_fields?: TrialSpecSpendingCap_Var_Pop_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_var_samp_fields?: TrialSpecSpendingCap_Var_Samp_FieldsResolvers<ContextType>;
  TrialSpecSpendingCap_variance_fields?: TrialSpecSpendingCap_Variance_FieldsResolvers<ContextType>;
  TrialSpec_aggregate?: TrialSpec_AggregateResolvers<ContextType>;
  TrialSpec_aggregate_fields?: TrialSpec_Aggregate_FieldsResolvers<ContextType>;
  TrialSpec_avg_fields?: TrialSpec_Avg_FieldsResolvers<ContextType>;
  TrialSpec_max_fields?: TrialSpec_Max_FieldsResolvers<ContextType>;
  TrialSpec_min_fields?: TrialSpec_Min_FieldsResolvers<ContextType>;
  TrialSpec_mutation_response?: TrialSpec_Mutation_ResponseResolvers<ContextType>;
  TrialSpec_stddev_fields?: TrialSpec_Stddev_FieldsResolvers<ContextType>;
  TrialSpec_stddev_pop_fields?: TrialSpec_Stddev_Pop_FieldsResolvers<ContextType>;
  TrialSpec_stddev_samp_fields?: TrialSpec_Stddev_Samp_FieldsResolvers<ContextType>;
  TrialSpec_sum_fields?: TrialSpec_Sum_FieldsResolvers<ContextType>;
  TrialSpec_var_pop_fields?: TrialSpec_Var_Pop_FieldsResolvers<ContextType>;
  TrialSpec_var_samp_fields?: TrialSpec_Var_Samp_FieldsResolvers<ContextType>;
  TrialSpec_variance_fields?: TrialSpec_Variance_FieldsResolvers<ContextType>;
  UnpaidInvoiceIdentifier?: UnpaidInvoiceIdentifierResolvers<ContextType>;
  UpdateCommitScheduleImpactedInvoice?: UpdateCommitScheduleImpactedInvoiceResolvers<ContextType>;
  UpdateCommitScheduleInvoiceItemPreview?: UpdateCommitScheduleInvoiceItemPreviewResolvers<ContextType>;
  UpdateCommitScheduleMutationResponse?: UpdateCommitScheduleMutationResponseResolvers<ContextType>;
  UpdateCommitScheduleSegmentItemPreview?: UpdateCommitScheduleSegmentItemPreviewResolvers<ContextType>;
  UpdateOrUndefined?: UpdateOrUndefinedResolvers<ContextType>;
  UpdateOrUndefinedValue?: UpdateOrUndefinedValueResolvers<ContextType>;
  UsageBreakdownData?: UsageBreakdownDataResolvers<ContextType>;
  UsageBreakdownResponse?: UsageBreakdownResponseResolvers<ContextType>;
  UsageEventMutationResponse?: UsageEventMutationResponseResolvers<ContextType>;
  UsageFilter?: UsageFilterResolvers<ContextType>;
  UsageFilterUpdate?: UsageFilterUpdateResolvers<ContextType>;
  UsageGroup?: UsageGroupResolvers<ContextType>;
  UsageProductListItem?: UsageProductListItemResolvers<ContextType>;
  UsageProductListItemState?: UsageProductListItemStateResolvers<ContextType>;
  UsageWindow?: UsageWindowResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAuthTypeEnum?: UserAuthTypeEnumResolvers<ContextType>;
  UserAuthTypeEnum_aggregate?: UserAuthTypeEnum_AggregateResolvers<ContextType>;
  UserAuthTypeEnum_aggregate_fields?: UserAuthTypeEnum_Aggregate_FieldsResolvers<ContextType>;
  UserAuthTypeEnum_max_fields?: UserAuthTypeEnum_Max_FieldsResolvers<ContextType>;
  UserAuthTypeEnum_min_fields?: UserAuthTypeEnum_Min_FieldsResolvers<ContextType>;
  UserAuthTypeEnum_mutation_response?: UserAuthTypeEnum_Mutation_ResponseResolvers<ContextType>;
  User_aggregate?: User_AggregateResolvers<ContextType>;
  User_aggregate_fields?: User_Aggregate_FieldsResolvers<ContextType>;
  User_max_fields?: User_Max_FieldsResolvers<ContextType>;
  User_min_fields?: User_Min_FieldsResolvers<ContextType>;
  User_mutation_response?: User_Mutation_ResponseResolvers<ContextType>;
  Webhook?: WebhookResolvers<ContextType>;
  WebhookNotification?: WebhookNotificationResolvers<ContextType>;
  WebhookNotification_aggregate?: WebhookNotification_AggregateResolvers<ContextType>;
  WebhookNotification_aggregate_fields?: WebhookNotification_Aggregate_FieldsResolvers<ContextType>;
  WebhookNotification_avg_fields?: WebhookNotification_Avg_FieldsResolvers<ContextType>;
  WebhookNotification_max_fields?: WebhookNotification_Max_FieldsResolvers<ContextType>;
  WebhookNotification_min_fields?: WebhookNotification_Min_FieldsResolvers<ContextType>;
  WebhookNotification_mutation_response?: WebhookNotification_Mutation_ResponseResolvers<ContextType>;
  WebhookNotification_stddev_fields?: WebhookNotification_Stddev_FieldsResolvers<ContextType>;
  WebhookNotification_stddev_pop_fields?: WebhookNotification_Stddev_Pop_FieldsResolvers<ContextType>;
  WebhookNotification_stddev_samp_fields?: WebhookNotification_Stddev_Samp_FieldsResolvers<ContextType>;
  WebhookNotification_sum_fields?: WebhookNotification_Sum_FieldsResolvers<ContextType>;
  WebhookNotification_var_pop_fields?: WebhookNotification_Var_Pop_FieldsResolvers<ContextType>;
  WebhookNotification_var_samp_fields?: WebhookNotification_Var_Samp_FieldsResolvers<ContextType>;
  WebhookNotification_variance_fields?: WebhookNotification_Variance_FieldsResolvers<ContextType>;
  Webhook_aggregate?: Webhook_AggregateResolvers<ContextType>;
  Webhook_aggregate_fields?: Webhook_Aggregate_FieldsResolvers<ContextType>;
  Webhook_max_fields?: Webhook_Max_FieldsResolvers<ContextType>;
  Webhook_min_fields?: Webhook_Min_FieldsResolvers<ContextType>;
  Webhook_mutation_response?: Webhook_Mutation_ResponseResolvers<ContextType>;
  WorkatoSSOUrlResponse?: WorkatoSsoUrlResponseResolvers<ContextType>;
  json?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  numeric?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  uuid?: GraphQLScalarType;
};

