import React from "react";

import { useRecentInvoicesQuery } from "types/generated-graphql/src/pages/customer/invoices/queries.graphql";
import { InvoiceFragmentReader } from "lib/Invoice/InvoiceFragmentReader";

export type InvoicesReq =
  | {
      state: "loading";
    }
  | {
      state: "success";
      cursor: string | null;
      customerId: string;
      invoices: InvoiceFragmentReader[];
    }
  | {
      state: "error";
      error: Error;
    }
  | {
      state: "not found";
    };

export function useRecentInvoices(
  options: Parameters<typeof useRecentInvoicesQuery>[0]
): InvoicesReq {
  const req = useRecentInvoicesQuery(options);

  return React.useMemo((): InvoicesReq => {
    if (req.loading) {
      return { state: "loading" };
    }

    if (req.error) {
      return { state: "error", error: req.error };
    }

    if (!req.data || !req.data.Customer) {
      return { state: "not found" };
    }

    const customer = req.data.Customer[0];

    return {
      state: "success",
      cursor: customer.recentInvoices.cursor,
      customerId: customer.id,
      invoices: customer.recentInvoices.invoices.map(
        (invoice) => new InvoiceFragmentReader(customer.id, invoice)
      ),
    };
  }, [req]);
}
