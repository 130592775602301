import React from "react";
import classNames from "classnames";
import { Headline, Subtitle, Icon } from "@metronome-industries/design-system";

import { RoundedCurrency } from "lib/credits";
import { dayjs, printDateRange } from "lib/date";
import { renderDate } from "lib/time";
import { RoundRectList } from "components/RoundRectList";
import { InvoiceFragmentReader } from "lib/Invoice";

interface Props {
  loading: boolean;
  invoices: InvoiceFragmentReader[];
  activeInvoiceId?: string;
  setActiveInvoiceId: (invoiceId?: string) => void;
}

export const InvoiceList: React.FC<Props> = (props) => {
  const now = dayjs();

  return (
    <RoundRectList
      loading={props.loading}
      items={props.invoices.map((invoice, i) => {
        const id = invoice.id ?? `index:${i}`;
        const active = id === props.activeInvoiceId;

        return {
          id,
          active,
          onClick: () => props.setActiveInvoiceId(id),
          content: (
            <>
              <div className="flex justify-between">
                <Headline
                  level={6}
                  className={classNames(
                    {
                      "text-primary-600": active,
                    },
                    "text-[14px]"
                  )}
                >
                  {invoice.inclusive_start_date && invoice.exclusive_end_date
                    ? printDateRange(
                        now,
                        invoice.inclusive_start_date,
                        invoice.exclusive_end_date
                      )
                    : renderDate(invoice.issued_at.toDate(), { isUtc: true })}
                </Headline>
                <div>
                  {invoice.renderStatusPills({
                    light: !active,
                  })}
                </div>
              </div>
              <Subtitle level={3} className="mt-8 text-gray-800">
                <Icon icon="cash" className="mr-4 text-sm align-middle" />
                <RoundedCurrency
                  amount={invoice.total}
                  creditType={invoice.creditType}
                />
              </Subtitle>
            </>
          ),
        };
      })}
    />
  );
};
