import React from "react";
import { Label, Subtitle } from "@metronome-industries/design-system";
import { renderDate } from "lib/time";
import { CorrectionsFragment } from "./fragments.graphql";

export const CorrectedBy: React.FC<{
  customer_id: string;
  correction: CorrectionsFragment;
}> = ({ customer_id, correction }) => {
  return (
    <div className="pt-12 px-12">
      <div className="flex items-center gap-4">
        <Subtitle level={1}>
          This invoice was corrected on{" "}
          {renderDate(new Date(correction.issued_at), {
            isUtc: true,
            excludeUtcLabel: true,
          })}
          . A credit memo was issued.
        </Subtitle>
      </div>
      <div>
        <Label className="text-xs">
          {correction.correction_record.reason} /{" "}
          {correction.correction_record.memo}
        </Label>
      </div>
    </div>
  );
};
