import React from "react";
import { Headline } from "@metronome-industries/design-system";

function LandingPage() {
  return (
    <Headline level={2} className="text-center m-12">
      Metronome embeddable dashboards
    </Headline>
  );
}

export default LandingPage;
