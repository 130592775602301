import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";

function useAuth() {
  const [authToken, setAuthToken] = useState("");
  useEffect(() => {
    if (
      window.location.search.includes("token=") ||
      window.location.search.includes("impersonationToken=")
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const token =
        urlParams.get("token") || urlParams.get("impersonationToken") || "";
      setAuthToken(token);
    }
  }, []);

  return {
    authToken,
  };
}

export const AuthContext = createContainer(useAuth);
