import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./index.module.less";
import { Button, Icon } from "@metronome-industries/design-system";

interface SortingIndicatorProps {
  direction: "asc" | "desc" | "none";
}

const SortingIndicator: React.FC<SortingIndicatorProps> = ({ direction }) => {
  const upClassname = classnames({
    [styles.active]: direction === "asc",
  });
  const downClassname = classnames({
    [styles.active]: direction === "desc",
  });
  return (
    <div className={styles.sortingIndicator}>
      <Icon icon="caretUp" className={upClassname} />
      <Icon icon="caretDown" className={downClassname} />
    </div>
  );
};

export type Column<T> = {
  render: (row: T, isSubRow?: boolean) => ReactNode;
  header: string | ReactNode;
  width?: number;
  alignment?: "left" | "top-left" | "right" | "top-right";
  sort?: "none" | "asc" | "desc";
  headerClassName?: string;
};

type Page = number | "prev" | "next";

export type Props<T extends object> = {
  data: (T & { subRows?: T[] | null; cellKey?: string })[];
  columns: Column<T>[];
  paginationButtons?: {
    page: Page;
    disabled?: boolean;
    selected?: boolean;
    onClick: () => void;
  }[];
  onSortClick?: (index: number) => void;
  noBottomBorder?: boolean;
};

/*
  SimpleTable uses basic markup (instead of a library) to create a table component.
  When should you use this component vs. react-table?
    When you don't need advanced functionality like: Sorting, Pagination, Row collapse/expand, etc
  This component also allows for stable updates to the table data without a full re-render.
    This is useful for cells that have inputs (does not force a full re-render when an input is changed)
*/
export function SimpleTable<T extends Object>(props: Props<T>) {
  const getRows = (rowData: Props<T>["data"]) => {
    const rows = [];
    const createRow = (
      row: Props<T>["data"][0],
      rowIndex: string,
      subRow?: boolean
    ) => {
      return (
        /* 
          Currently the table only needs for cells to be updated vs. creating new rows/columns, therefor it's OK to use indices for keys
        */
        <tr
          key={rowIndex}
          className={classnames({
            [styles.expanded]: subRow,
          })}
        >
          <td />
          {props.columns.map((column, colIndex) => {
            const widthStyle =
              colIndex === 0
                ? { minWidth: column.width }
                : { width: column.width };
            return (
              <td
                style={widthStyle}
                key={
                  /* Optionally pass a key */
                  row.cellKey
                    ? `${row.cellKey}:${String(colIndex)}`
                    : `${rowIndex}:${colIndex}`
                }
                className={classnames(
                  column.alignment?.startsWith("top") ? "align-top" : "",
                  column.headerClassName
                )}
              >
                <div
                  className={classnames(
                    styles.cell,
                    styles[column.alignment ?? "left"]
                  )}
                >
                  {column.render(row, subRow)}
                </div>
              </td>
            );
          })}
          <td />
        </tr>
      );
    };
    for (let i = 0; i < rowData.length; i++) {
      const row = rowData[i];
      rows.push(createRow(row, String(i)));
      if (row.subRows) {
        row.subRows.map((subRow, subRowIndex) =>
          rows.push(createRow(subRow, `${subRowIndex}:${i}`, true))
        );
      }
    }
    return rows;
  };

  return (
    <div className={styles.tableContainer}>
      <table className={classnames(styles.table)}>
        <thead>
          <tr>
            {/* The first and last column are styled differently and they should always exist on the table */}
            <th />
            {props.columns.map((c, index) => {
              return (
                <th
                  key={index}
                  className={c.alignment?.startsWith("top") ? "align-top" : ""}
                >
                  <div
                    className={classnames(
                      styles.header,
                      styles[c.alignment ?? "left"],
                      { [styles.clickable]: !!c.sort }
                    )}
                    onClick={() => !!c.sort && props.onSortClick?.(index)}
                  >
                    {c.sort && <SortingIndicator direction={c.sort} />}
                    <span>{c.header}</span>
                  </div>
                </th>
              );
            })}
            <th />
          </tr>
        </thead>
        <tbody
          className={classnames({
            [styles.noBottomBorder]: props.noBottomBorder,
          })}
        >
          {getRows(props.data)}
        </tbody>
      </table>
      {props.paginationButtons ? (
        <div className={styles.pagination}>
          <div className={styles.pages}>
            {props.paginationButtons.map((button, i) => {
              if (button.page === "prev" || button.page === "next") {
                return (
                  <Button
                    key={i}
                    icon={button.page === "prev" ? "caretBack" : "caretForward"}
                    onClick={button.onClick}
                    theme="gray"
                    type="outline"
                    size="small"
                    className={styles.button}
                    disabled={button.disabled}
                  />
                );
              }

              return (
                <Button
                  key={i}
                  theme={button.selected ? "primary" : "gray"}
                  type="outline"
                  size="small"
                  onClick={button.onClick}
                  className={styles.button}
                >
                  {button.page}
                </Button>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
