import React from "react";

import { dayjs } from "lib/date";
import { ProductListItemFragmentReader } from "lib/ProductList";
import { CommitReferenceableFragment } from "./fragments.graphql";

export function CommitReference(commit: CommitReferenceableFragment) {
  const now = dayjs();
  const product = React.useMemo(
    () => new ProductListItemFragmentReader(commit.product),
    [commit.product]
  );

  return <>{commit.name ?? product.getName(now)}</>;
}
