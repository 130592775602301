import React, { useEffect } from "react";

import { useGetInvoiceQuery } from "types/generated-graphql/src/components/Invoice/queries.graphql";
import { Headline } from "@metronome-industries/design-system";
import { dayjs } from "lib/dayjs";
import { renderDate } from "lib/time";
import { Invoice as InvoiceType } from "./types";
import styles from "./index.module.less";
import { StatusPills } from "components/StatusPills";
import { EmptyState } from "components/EmptyState";
import { TextSkeleton } from "components/Skeleton";
import { InvoiceTimeline } from "./Timeline";
import {
  LineItem,
  ContractLineItems,
  extractContractLineItems,
} from "./LineItem";
import { Totals } from "./Totals";
import { CorrectedBy } from "./CorrectedBy";
import { CorrectionOf } from "./CorrectionOf";
import {
  BMGroupKeyOverridePayload,
  BMGroupKeyOverrides,
  convertPayloadToOverridesMap,
} from "types/override-types";

export function getInvoiceEndDate(i: InvoiceType) {
  switch (i.__typename) {
    case "ArrearsInvoice":
    case "ParentInvoice":
    case "AdhocContractUsageInvoice":
    case "AdHocPlanInvoice":
      return dayjs.utc(i.exclusive_end_date).subtract(1, "s").toDate();
    case "CreditPurchaseInvoice":
    case "AdvanceInvoice":
    case "CorrectionInvoice":
    case "ContractScheduledInvoice":
    case "ContractUsageInvoice":
    case "ContractRefundInvoice":
    case "ContractPostpaidTrueupInvoice":
    case "SeatPurchaseInvoice":
    case "ContractProServiceInvoice":
      return new Date(i.issued_at);
  }
  // If this is failing to compile, you probably need to add a new case to the switch statement above
  i satisfies never;
  throw new Error("Unreachable");
}

export const InvoiceSkeleton: React.FC = () => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.header}>
        <TextSkeleton />
      </div>
      <div className={styles.header}>
        <TextSkeleton />
      </div>
      <div className={styles.body}>
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
        <TextSkeleton />
      </div>
    </div>
  );
};

export const Invoice: React.FC<{
  invoiceID: string;
}> = ({ invoiceID }) => {
  const { data, loading } = useGetInvoiceQuery({
    variables: {
      id: invoiceID,
    },
  });
  const [showChargesWithZeroUsage, setShowChargesWithZeroUsage] =
    React.useState(false);

  const [bmGroupKeyOverrides, setBMGroupKeyOverrides] =
    React.useState<BMGroupKeyOverrides>({});

  useEffect(() => {
    if (window.location.search.includes("show_zero_usage_line_items=")) {
      const urlParams = new URLSearchParams(window.location.search);
      try {
        const showZeros =
          (urlParams.get("show_zero_usage_line_items") || "false") === "true";
        setShowChargesWithZeroUsage(showZeros);
      } catch {
        // do nothing
      }
    }

    if (window.location.search.includes("bmGroupKeyOverrides=")) {
      const overrides = new URLSearchParams(window.location.search).get(
        "bmGroupKeyOverrides"
      );
      if (overrides) {
        setBMGroupKeyOverrides(
          convertPayloadToOverridesMap(
            JSON.parse(overrides) as BMGroupKeyOverridePayload
          )
        );
      }
    }
  }, []);

  const invoice = data?.invoice;

  if (loading) {
    return <InvoiceSkeleton />;
  }
  if (!invoice) {
    return (
      <EmptyState
        title="Error loading invoice"
        subtitle="Please try again"
        icon="documentOutline"
      />
    );
  }

  // Sort newest to oldest
  const corrections =
    "corrections" in invoice
      ? [...invoice.corrections].sort((a, b) =>
          new Date(a.issued_at) < new Date(b.issued_at) ? 1 : -1
        )
      : [];

  const { contractLineItems, nonContractLineItems } = extractContractLineItems(
    invoice.line_items
  );

  return (
    <div>
      <div className={styles.header}>
        <Headline level={5}>
          {renderDate(getInvoiceEndDate(invoice), {
            isUtc: true,
          })}
        </Headline>
        <StatusPills invoice={invoice} light={false} className={styles.pills} />
      </div>
      <div className={styles.timeline}>
        <InvoiceTimeline invoice={invoice} />
      </div>
      <div>
        {corrections.map((correction, i) => (
          <CorrectedBy
            customer_id={invoice.customer.id}
            correction={correction}
            key={i}
          />
        ))}
      </div>
      {invoice.__typename === "CorrectionInvoice" ? (
        <CorrectionOf correction_of={invoice} customer={invoice.customer} />
      ) : null}
      <div>
        {contractLineItems.length ? (
          <ContractLineItems
            invoiceType={invoice.__typename}
            lineItems={contractLineItems}
            issuedAt={new Date(invoice.issued_at)}
          />
        ) : null}
        {nonContractLineItems.map((lineItem, i) => (
          <LineItem
            invoiceId={invoice.id}
            key={i}
            lineItem={lineItem}
            showChargesWithZeroUsage={showChargesWithZeroUsage}
            bmGroupKeyOverrides={bmGroupKeyOverrides}
          />
        ))}
      </div>
      <Totals invoice={invoice} />
    </div>
  );
};
