import React from "react";
import Decimal from "decimal.js";

import styles from "./index.module.less";
import { CreditType } from "types/credit-types";
import { Headline, Subtitle } from "@metronome-industries/design-system";
import { displayCreditTypeName, RoundedCurrency } from "lib/credits";
import classNames from "classnames";
import { TotalsFieldsFragment } from "./fragments.graphql";
import { CommitReference } from "../CommitReference";

type LineItem = TotalsFieldsFragment["line_items"][number];

type OverageLineItem = LineItem & {
  __typename: "OverageLineItem";
};
type ContractConversionLineItem = LineItem & {
  __typename: "ContractConversionLineItem";
};
type CreditLineItem = LineItem & { __typename: "CreditLineItem" };
type MinimumLineItem = LineItem & {
  __typename: "MinimumLineItem";
};
type TrialDiscountLineItem = LineItem & {
  __typename: "TrialDiscountLineItem";
};
type ContractAppliedCommitLineItem = LineItem & {
  __typename: "ContractAppliedCommitLineItem";
};
type ContractRolloverCommitLineItem = LineItem & {
  __typename: "ContractRolloverCommitLineItem";
};

export const Totals: React.FC<{
  invoice: TotalsFieldsFragment;
}> = ({ invoice }) => {
  // Accumulate the list of credit types that are different from the invoice credit type. This order is used to display
  // the subtotals below.
  const customCreditTypesInOrder: CreditType[] = [];

  // Accumulate the totals for each credit type. This is used to display the subtotals below.
  const totalsPerCreditType: Record<
    string,
    {
      subTotal: Decimal;
      total: Decimal;
    }
  > = {
    [invoice.credit_type.id]: {
      subTotal: new Decimal(0),
      total: new Decimal(0),
    },
  };

  const minimumLineItems: MinimumLineItem[] = [];
  const trialDiscountLineItems: TrialDiscountLineItem[] = [];
  const creditLineItems: CreditLineItem[] = [];
  const overageLineItems: OverageLineItem[] = [];
  const conversionLineItems: ContractConversionLineItem[] = [];
  const appliedAndRolloverCommitsById = new Map<
    string,
    {
      commit:
        | ContractAppliedCommitLineItem["commit_with_segment"]["commit_union"]
        | ContractRolloverCommitLineItem["rollover_commit_union"];
      total: Decimal;
    }
  >();

  for (const lineItem of invoice.line_items) {
    const creditTypeID = lineItem.credit_type.id;

    // Add the credit type to the list of custom credit types if it's not already there
    if (
      !customCreditTypesInOrder
        .map((ct) => ct.id)
        .includes(lineItem.credit_type.id) &&
      lineItem.credit_type.id !== invoice.credit_type.id
    ) {
      customCreditTypesInOrder.push(lineItem.credit_type);
    }

    // Initialize the totals for this credit type if they haven't been initialized yet
    if (!totalsPerCreditType[creditTypeID]) {
      totalsPerCreditType[creditTypeID] = {
        subTotal: new Decimal(0),
        total: new Decimal(0),
      };
    }
    switch (lineItem.__typename) {
      // These line item types are included in the subtotal and total
      case "ProductChargeLineItem":
      case "GroupedProductChargeLineItem":
      case "LegacyContractLineItem":
      case "LegacyLineItem":
      case "CreditPurchaseLineItem":
      case "ContractChargeLineItem":
      case "ContractCommitLineItem":
      // TODO: Determine if discount line items should be in the subtotal or not
      // https://linear.app/getmetronome/issue/PRA-269/should-discountlineitems-be-included-in-the-invoice-subtotal
      case "ContractDiscountLineItem":
      case "ContractAWSRoyaltyLineItem":
      case "ContractGCPRoyaltyLineItem":
      case "ContractPostpaidTrueupLineItem":
      case "ContractSubscriptionLineItem":
      case "ContractUsageLineItem":
      case "ContractProServiceLineItem":
      case "ContractAWSProServiceRoyaltyLineItem":
      case "ContractGCPProServiceRoyaltyLineItem": {
        totalsPerCreditType[creditTypeID].subTotal = totalsPerCreditType[
          creditTypeID
        ].subTotal.plus(lineItem.total);
        totalsPerCreditType[creditTypeID].total = totalsPerCreditType[
          creditTypeID
        ].total.plus(lineItem.total);
        continue;
      }

      // These line item types are not included in the subtotal
      case "CreditLineItem":
      case "MinimumLineItem":
      case "OverageLineItem":
      case "ContractAppliedCommitLineItem":
      case "ContractRolloverCommitLineItem":
      case "ContractConversionLineItem":
      case "TrialDiscountLineItem": {
        if (lineItem.__typename === "CreditLineItem") {
          creditLineItems.push(lineItem);
        } else if (lineItem.__typename === "MinimumLineItem") {
          minimumLineItems.push(lineItem);
        } else if (lineItem.__typename === "TrialDiscountLineItem") {
          trialDiscountLineItems.push(lineItem);
        } else if (lineItem.__typename === "OverageLineItem") {
          overageLineItems.push(lineItem);
        } else if (lineItem.__typename === "ContractConversionLineItem") {
          conversionLineItems.push(lineItem);
        } else if (lineItem.__typename === "ContractAppliedCommitLineItem") {
          if (lineItem.credit_type.id !== invoice.credit_type.id) {
            continue;
          }

          const applied = appliedAndRolloverCommitsById.get(
            lineItem.commit_with_segment.commit_union.id
          );

          if (!applied) {
            appliedAndRolloverCommitsById.set(
              lineItem.commit_with_segment.commit_union.id,
              {
                commit: lineItem.commit_with_segment.commit_union,
                total: new Decimal(lineItem.total),
              }
            );
          } else {
            applied.total = applied.total.plus(lineItem.total);
          }
        } else if (lineItem.__typename === "ContractRolloverCommitLineItem") {
          if (lineItem.credit_type.id !== invoice.credit_type.id) {
            continue;
          }
          const rollover = appliedAndRolloverCommitsById.get(
            lineItem.rollover_commit_id
          );

          if (!rollover) {
            appliedAndRolloverCommitsById.set(lineItem.rollover_commit_id, {
              commit: lineItem.rollover_commit_union,
              total: new Decimal(lineItem.total),
            });
          } else {
            rollover.total = rollover.total.plus(lineItem.total);
          }
        } else {
          lineItem satisfies never;
        }

        totalsPerCreditType[creditTypeID].total = totalsPerCreditType[
          creditTypeID
        ].total.plus(lineItem.total);
        continue;
      }
    }
    // If this is failing to compile, you probably need to add a new case to the switch statement above
    lineItem satisfies never;
  }

  if (
    totalsPerCreditType[invoice.credit_type.id].total
      .sub(new Decimal(invoice.total))
      .absoluteValue()
      .greaterThan(0.000000001)
  ) {
    throw new Error(
      `Invoice total does not match the sum of its line items, or the difference is greater than 0.000000001. ` +
        `Expected ${totalsPerCreditType[
          invoice.credit_type.id
        ].total.toString()}, got ${invoice.total}`
    );
  }

  const isContractInvoice = (invoice: TotalsFieldsFragment) =>
    ["ContractUsageInvoice", "ContractScheduledInvoice"].includes(
      invoice.__typename
    );

  return (
    <div className={styles.Totals}>
      <div className={styles.contents}>
        {/* credit type subtotals */}
        {customCreditTypesInOrder.map((creditType) => {
          const creditTypeMinimumLineItems = minimumLineItems.filter(
            (li) => li.credit_type.id === creditType.id
          );
          const creditTypeTrialDiscountLineItems =
            trialDiscountLineItems.filter(
              (li) => li.credit_type.id === creditType.id
            );
          const creditTypeCreditLineItems = creditLineItems.filter(
            (li) => li.credit_type.id === creditType.id
          );
          const overageLineItem =
            overageLineItems.find(
              (li) => li.overage_credit_type.id === creditType.id
            ) ??
            conversionLineItems.find(
              (li) => li.overage_credit_type.id === creditType.id
            );

          return (
            <React.Fragment key={creditType.id}>
              <Headline level={5}>{displayCreditTypeName(creditType)}</Headline>

              <div className={styles.line}>
                <Subtitle level={3}>Subtotal</Subtitle>
                <Subtitle level={3} className={styles.amount}>
                  <RoundedCurrency
                    amount={totalsPerCreditType[creditType.id].subTotal}
                    creditType={creditType}
                  />
                </Subtitle>
              </div>

              {creditTypeMinimumLineItems.map((li, i) => (
                <div key={`creditTypeMinimum-${i}`} className={styles.line}>
                  <Subtitle level={3}>
                    Invoice minimum (
                    <RoundedCurrency
                      amount={new Decimal(li.minimum)}
                      creditType={li.credit_type}
                    />
                    )
                  </Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              {creditTypeTrialDiscountLineItems.map((li, i) => (
                <div
                  key={`creditTypeTrialDiscount${i}`}
                  className={styles.line}
                >
                  <Subtitle level={3}>Trial discount</Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              {creditTypeCreditLineItems.map((li, i) => (
                <div key={`creditTypeCredit${i}`} className={styles.line}>
                  <Subtitle level={3}>
                    Credits applied: {li.credit_grant.name}
                  </Subtitle>
                  <Subtitle level={3} className={styles.amount}>
                    <RoundedCurrency
                      amount={new Decimal(li.total)}
                      creditType={li.credit_type}
                    />
                  </Subtitle>
                </div>
              ))}

              <div className={styles.line}>
                <Subtitle level={3}>Total</Subtitle>
                <Subtitle level={3} className={styles.amount}>
                  <RoundedCurrency
                    amount={totalsPerCreditType[creditType.id].total}
                    creditType={creditType}
                  />
                </Subtitle>
              </div>

              {overageLineItem ? (
                <div className={styles.line}>
                  <div>
                    <Subtitle level={3}>
                      Subtotal (
                      {displayCreditTypeName(overageLineItem.credit_type)})
                    </Subtitle>
                    <Subtitle level={3}>
                      <RoundedCurrency
                        amount={new Decimal(overageLineItem.overage_rate)}
                        creditType={overageLineItem.credit_type}
                      />{" "}
                      per{" "}
                      {displayCreditTypeName(
                        overageLineItem.overage_credit_type
                      )}
                    </Subtitle>
                  </div>
                  <Subtitle
                    level={3}
                    className={classNames(styles.total, styles.amount)}
                  >
                    <RoundedCurrency
                      amount={new Decimal(overageLineItem.total)}
                      creditType={overageLineItem.credit_type}
                    />
                  </Subtitle>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}

        {/* invoice total */}
        {/* We only show the name of the invoice credit type if there were _other_ credit types on the invoice too */}
        {customCreditTypesInOrder.length ? (
          <Headline level={5}>
            {displayCreditTypeName(invoice.credit_type)}
          </Headline>
        ) : null}

        {!isContractInvoice(invoice) && (
          <div className={styles.line}>
            <Subtitle level={3}>Subtotal</Subtitle>
            <Subtitle level={3} className={styles.amount}>
              <RoundedCurrency
                amount={totalsPerCreditType[invoice.credit_type.id].subTotal}
                creditType={invoice.credit_type}
              />
            </Subtitle>
          </div>
        )}

        {minimumLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div key={`minLine-${i}`} className={styles.line}>
              <Subtitle level={3}>
                Invoice minimum (
                <RoundedCurrency
                  amount={new Decimal(li.minimum)}
                  creditType={li.credit_type}
                />
                )
              </Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {trialDiscountLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div key={`discountLine-${i}`} className={styles.line}>
              <Subtitle level={3}>Trial discount</Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {creditLineItems
          .filter((li) => li.credit_type.id == invoice.credit_type.id)
          .map((li, i) => (
            <div
              key={`creditGrant-${li.credit_grant.id}-${i}`}
              className={styles.line}
            >
              <Subtitle level={3}>
                Credits applied: {li.credit_grant.name}
              </Subtitle>
              <Subtitle level={3} className={styles.amount}>
                <RoundedCurrency
                  amount={new Decimal(li.total)}
                  creditType={li.credit_type}
                />
              </Subtitle>
            </div>
          ))}

        {Array.from(appliedAndRolloverCommitsById.values()).map((li, i) => (
          <div key={`commit-${li.commit.id}-${i}`} className={styles.line}>
            <Subtitle level={3}>
              <CommitReference {...li.commit} />
              {li.total.isPositive() ? " returned" : " consumed"}
            </Subtitle>
            <Subtitle level={3} className={styles.amount}>
              <RoundedCurrency
                amount={new Decimal(li.total)}
                creditType={invoice.credit_type}
              />
            </Subtitle>
          </div>
        ))}

        <div className={classNames(styles.line, styles.invoiceTotal)}>
          <Subtitle>Total due</Subtitle>
          <Subtitle className={styles.total}>
            <RoundedCurrency
              amount={new Decimal(invoice.total)}
              creditType={invoice.credit_type}
            />
          </Subtitle>
        </div>
      </div>
    </div>
  );
};
