export type BMGroupKeyOverridePayload = Array<{
  group_key_name: string;
  display_name?: string;
  value_display_names?: {
    [group_key_value: string]: string;
  };
}>;

export type BMGroupKeyOverrides = {
  [key: string]: {
    display_name?: string;
    value_display_names?: {
      [key: string]: string;
    };
  };
};

export function convertPayloadToOverridesMap(
  payload: BMGroupKeyOverridePayload
): BMGroupKeyOverrides {
  return payload.reduce((map, item) => {
    map[item.group_key_name] = {
      display_name: item.display_name,
      value_display_names: item.value_display_names,
    };
    return map;
  }, {} as BMGroupKeyOverrides);
}

export function getDisplayName(
  overrides: BMGroupKeyOverrides,
  key: string
): string | null {
  const override = overrides[key];
  return override && override.display_name ? override.display_name : null;
}

export function getValueDisplayName(
  overrides: BMGroupKeyOverrides,
  key: string,
  value: string
): string | null {
  const override = overrides[key];
  return override &&
    override.value_display_names &&
    override.value_display_names[value]
    ? override.value_display_names[value]
    : null;
}
