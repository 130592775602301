import classNames from "classnames";
import { compareAsc, isEqual } from "date-fns";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

import {
  Icon,
  Caption,
  Subtitle,
  Headline,
  Badge,
} from "@metronome-industries/design-system";
import { BlockSkeleton } from "components/Skeleton";
import { RoundedCurrency } from "lib/credits";
import { renderDateRange } from "lib/time";
import { CreditType } from "types/credit-types";
import { IssuedCreditGrant } from "../..";
import CreditLedger from "../CreditLedger";
import styles from "./index.module.less";

const grantHasExpired = (
  creditGrant: Pick<IssuedCreditGrant, "expiresBefore">
) => {
  return creditGrant.expiresBefore < new Date();
};

const grantIsPending = (
  creditGrant: Pick<IssuedCreditGrant, "effectiveAt">
) => {
  return creditGrant.effectiveAt > new Date();
};
const grantIsVoid = (creditGrant: Pick<IssuedCreditGrant, "voidedAt">) => {
  return creditGrant.voidedAt !== null;
};

type CreditGrantToSort = Pick<
  IssuedCreditGrant,
  | "expiresBefore"
  | "effectiveAt"
  | "createdAt"
  | "priority"
  | "voidedAt"
  | "products"
>;
export const sortCreditGrants = (
  a: CreditGrantToSort,
  b: CreditGrantToSort
) => {
  if (grantIsVoid(a) && !grantIsVoid(b)) {
    return 1;
  } else if (!grantIsVoid(a) && grantIsVoid(b)) {
    return -1;
  } else {
    if (grantHasExpired(a) === grantHasExpired(b)) {
      if (a.priority === b.priority) {
        if (isEqual(a.expiresBefore, b.expiresBefore)) {
          if (!!a.products === !!b.products) {
            if (isEqual(a.effectiveAt, b.effectiveAt)) {
              return compareAsc(a.createdAt, b.createdAt);
            } else {
              return compareAsc(a.effectiveAt, b.effectiveAt);
            }
          } else {
            return a.products ? -1 : 1;
          }
        } else {
          return compareAsc(a.expiresBefore, b.expiresBefore);
        }
      } else {
        return new Decimal(a.priority || 0).comparedTo(b.priority || 0);
      }
    }
    return compareAsc(b.expiresBefore, a.expiresBefore);
  }
};

type CreditGrantBalanceProps = {
  creditGrant: IssuedCreditGrant;
};

export const CreditGrantBalance: React.FC<CreditGrantBalanceProps> = ({
  creditGrant,
}) => {
  if (grantIsVoid(creditGrant)) {
    return (
      <div className={classNames("flex", styles.ledgerBalance)}>
        <Caption
          level={2}
          className={classNames("mr-4 normal-case", styles.ledgerCaption)}
        >
          Total Voided
        </Caption>
        <Subtitle level={4} className={styles.voided}>
          <RoundedCurrency
            amount={new Decimal(creditGrant.amountGranted)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  if (grantHasExpired(creditGrant)) {
    return (
      <div className={classNames("flex", styles.ledgerBalance)}>
        <Caption
          level={2}
          className={classNames("mr-1 normal-case", styles.ledgerCaption)}
        >
          Total Expired
        </Caption>

        <Subtitle level={4} className={styles.expired}>
          <RoundedCurrency
            amount={new Decimal(creditGrant.ledger.expired)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  if (grantIsPending(creditGrant)) {
    return (
      <div className={classNames("flex", styles.ledgerBalance)}>
        <Caption
          level={2}
          className={classNames("mr-1 normal-case", styles.ledgerCaption)}
        >
          Pending Balance
        </Caption>
        <Subtitle level={4} className={styles.pending}>
          <RoundedCurrency
            amount={new Decimal(creditGrant.amountGranted)}
            creditType={creditGrant.amountGrantedCreditType}
          />
        </Subtitle>
      </div>
    );
  }
  return (
    <div className={classNames("flex", styles.ledgerBalance)}>
      <Caption
        level={2}
        className={classNames("mr-1 normal-case", styles.ledgerCaption)}
      >
        Available Balance
      </Caption>
      <Subtitle level={4} className={styles.active}>
        <RoundedCurrency
          amount={new Decimal(creditGrant.ledger.availableBalance)}
          creditType={creditGrant.amountGrantedCreditType}
        />
      </Subtitle>
    </div>
  );
};

type CreditGrantProps = {
  creditGrant: IssuedCreditGrant;
};

const CreditGrant: React.FC<CreditGrantProps> = ({ creditGrant }) => {
  const [creditLedgerOpen, setCreditLedgerOpen] = useState<boolean>(false);
  useState<IssuedCreditGrant | null>(null);

  const [hideGrantName, setHideGrantName] = React.useState(false);

  useEffect(() => {
    if (window.location.search.includes("hide_grant_name=")) {
      const urlParams = new URLSearchParams(window.location.search);
      try {
        const hideName =
          (urlParams.get("hide_grant_name") || "false") === "true";
        setHideGrantName(hideName);
      } catch {
        // do nothing
      }
    }
  }, []);

  return (
    <>
      <div
        className={classNames(
          "box-border mt-12 rounded-medium",
          styles.creditGrantContainer
        )}
      >
        <div className={classNames("p-8", styles.creditGrantInfo)}>
          {!hideGrantName && (
            <div className="flex justify-between">
              <Headline level={6} className="mb-4">
                {creditGrant.name}
              </Headline>
            </div>
          )}

          <div className={classNames("flex", styles.grantData)}>
            <div className={classNames("mr-32", styles.grantDataItem)}>
              <Caption
                level={1}
                className={classNames("normal-case", styles.itemLabel)}
              >
                Available credits
              </Caption>
              <Subtitle
                level={1}
                className={classNames(
                  styles.itemValue,
                  !creditGrant.ledger.availableBalance.isZero() && styles.green
                )}
              >
                <RoundedCurrency
                  amount={new Decimal(creditGrant.ledger.availableBalance)}
                  creditType={creditGrant.amountGrantedCreditType}
                />
              </Subtitle>
            </div>
            <div className={classNames("mr-32", styles.grantDataItem)}>
              <Caption
                level={1}
                className={classNames("normal-case", styles.itemLabel)}
              >
                Total consumed
              </Caption>
              <Subtitle level={1} className={styles.itemValue}>
                <RoundedCurrency
                  amount={new Decimal(creditGrant.ledger.consumed)}
                  creditType={creditGrant.amountGrantedCreditType}
                />
              </Subtitle>
            </div>
            {grantHasExpired(creditGrant) && (
              <div className={classNames("mr-32", styles.grantDataItem)}>
                <Caption
                  level={1}
                  className={classNames("normal-case", styles.itemLabel)}
                >
                  Total Expired
                </Caption>

                <Subtitle level={1} className={styles.expired}>
                  <RoundedCurrency
                    amount={new Decimal(creditGrant.ledger.expired)}
                    creditType={creditGrant.amountGrantedCreditType}
                  />
                </Subtitle>
              </div>
            )}
            <div className={classNames("mr-32", styles.grantDataItem)}>
              <Caption
                level={1}
                className={classNames("normal-case", styles.itemLabel)}
              >
                Amount issued
              </Caption>
              <Subtitle level={1} className={styles.itemValue}>
                <RoundedCurrency
                  amount={new Decimal(creditGrant.amountGranted)}
                  creditType={creditGrant.amountGrantedCreditType}
                />
              </Subtitle>
            </div>

            <div className="absolute right-0">
              {grantHasExpired(creditGrant) && (
                <div
                  className={classNames(
                    "mr-32 inline-block",
                    styles.grantDataItem
                  )}
                >
                  <Badge theme="error" type="dark">
                    EXPIRED
                  </Badge>
                </div>
              )}
              {creditGrant.products ? (
                <div
                  className={classNames(
                    "mr-32 inline-block",
                    styles.grantDataItem
                  )}
                >
                  <Caption
                    level={1}
                    className={classNames(
                      styles.itemLabel,
                      "text-right normal-case"
                    )}
                  >
                    Credits apply to
                  </Caption>
                  <Subtitle level={1} className={styles.itemValue}>
                    {creditGrant.products.map((p) => p.name).join(", ")}
                  </Subtitle>
                </div>
              ) : null}
              <div
                className={classNames(
                  "mr-32 inline-block",
                  styles.grantDataItem
                )}
              >
                <Caption
                  level={1}
                  className={classNames(
                    styles.itemLabel,
                    "text-right normal-case"
                  )}
                >
                  Effective Date
                </Caption>
                <Subtitle level={1} className={styles.itemValue}>
                  {renderDateRange(
                    creditGrant.effectiveAt,
                    creditGrant.expiresBefore,
                    {
                      isUtc: true,
                      excludeUtcLabel: true,
                    },
                    false
                  )}
                </Subtitle>
              </div>{" "}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "p-12 flex items-center justify-between p-3 w-full cursor-pointer rounded-b",
            styles.creditLedgerHeader,
            creditLedgerOpen && "rounded-b-none"
          )}
          onClick={() => setCreditLedgerOpen(!creditLedgerOpen)}
        >
          <div className={classNames("flex items-center", styles.toggleLedger)}>
            {creditLedgerOpen ? (
              <Icon
                icon="chevronDown"
                className={classNames("mr-4", styles.chevron)}
              />
            ) : (
              <Icon
                icon="chevronForward"
                className={classNames("mr-4", styles.chevron)}
              />
            )}
            <Caption level={2} className={styles.toggleLedgerLabel}>
              Ledger
            </Caption>
          </div>
        </div>

        {creditLedgerOpen && (
          <div
            className={classNames(
              "mt-0 mr-12 -mb-12 ml-12",
              styles.creditGrantLedger
            )}
          >
            <CreditLedger
              loading={false}
              ledger={creditGrant.ledger}
              columnsToHide={new Set(["cost_basis", "grant_name", "reason"])}
              noBottomBorder={true}
            />
          </div>
        )}
      </div>
    </>
  );
};
export const CreditGrantListSkeleton: React.FC = () => {
  return (
    <div className={styles.creditGrantList}>
      <div className={classNames("mt-12", styles.creditGrantLoading)}>
        <BlockSkeleton />
      </div>
      <div className={classNames("mt-12", styles.creditGrantLoading)}>
        <BlockSkeleton />
      </div>
    </div>
  );
};

type CreditGrantListProps = {
  issuedCreditGrants: IssuedCreditGrant[];
  selectedCreditType: CreditType;
};
export const CreditGrantList: React.FC<CreditGrantListProps> = ({
  issuedCreditGrants,
  selectedCreditType,
}) => {
  const sortedCreditGrants = issuedCreditGrants
    .filter((cg) => cg.amountGrantedCreditType.id === selectedCreditType.id)
    .sort(sortCreditGrants);

  return (
    <div className={styles.creditGrantList}>
      {Object.values(sortedCreditGrants).map((cg, idx) => (
        <div className="mb-[20px]">
          <CreditGrant key={idx} creditGrant={cg} />
        </div>
      ))}
    </div>
  );
};
