import React from "react";

import {
  LineItemFieldsFragment,
  SubLineItemFieldsFragment,
} from "../../fragments.graphql";

import { Headline, Tooltip } from "@metronome-industries/design-system";
import styles from "./index.module.less";
import Decimal from "decimal.js";
import { RoundedCurrency, USD_CREDIT_ID } from "lib/credits";
import { ProductContainer } from "components/ProductContainer";
import { SimpleTable } from "components/SimpleTable";
import { TierChargeLineItem } from "types/generated-graphql/__types__";
import { NoSubLineItems } from "../../../NoSubLineItems";
import { dayjs } from "lib/dayjs";
import {
  BMGroupKeyOverrides,
  getDisplayName,
  getValueDisplayName,
} from "../../../../../types/override-types";

function tierChargeLineItemTypeGuard(
  t: SubLineItemFieldsFragment
): t is TierChargeLineItem {
  return t.__typename === "TierChargeLineItem";
}

export const ProductChargeLineItem: React.FC<{
  invoiceId: string;
  lineItem: LineItemFieldsFragment & {
    __typename:
      | "GroupedProductChargeLineItem"
      | "ProductChargeLineItem"
      | "LegacyContractLineItem"
      | "LegacyLineItem";
  };
  showChargesWithZeroUsage: boolean;
  bmGroupKeyOverrides: BMGroupKeyOverrides;
}> = ({
  lineItem,
  showChargesWithZeroUsage,
  invoiceId,
  bmGroupKeyOverrides,
}) => {
  function formatDate(startDate: string, endDate: string) {
    const actualStart = dayjs.utc(startDate);
    // display end date inclusive
    const actualEnd = dayjs.utc(endDate).subtract(1, "day");
    const template =
      actualStart.year() !== actualEnd.year() ? "MMMM D, YYYY" : "MMMM D";

    return `${actualStart.format(template)} - ${actualEnd.format(template)}`;
  }

  const lineItemName =
    lineItem.__typename === "GroupedProductChargeLineItem"
      ? lineItem.product.name
      : lineItem.display_name;

  const subLineItems =
    lineItem.__typename === "LegacyContractLineItem" ||
    lineItem.__typename === "LegacyLineItem"
      ? [lineItem]
      : lineItem.sub_line_items
          .map((subLineItem) => {
            if (
              (subLineItem.__typename === "ChargeLineItem" ||
                subLineItem.__typename === "AdvanceChargeLineItem") &&
              subLineItem.sub_line_items?.length
            ) {
              return {
                ...subLineItem,
                subRows:
                  subLineItem.sub_line_items as SubLineItemFieldsFragment[],
              };
            }

            if (subLineItem.__typename === "SeatChargeLineItem") {
              return {
                ...subLineItem,
                unit_price: subLineItem.seat_price,
                display_name: `${subLineItem.display_name} (${formatDate(
                  subLineItem.applicable_start_date,
                  subLineItem.applicable_end_date
                )}) ${subLineItem.is_prorated ? " (Prorated)" : ""}`,
              };
            }
            return subLineItem;
          })
          .filter((subLineItem) => {
            if (showChargesWithZeroUsage) {
              return true;
            }
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem" ||
              subLineItem.__typename === "SeatChargeLineItem"
            ) {
              return !new Decimal(subLineItem.quantity).eq(0);
            }
          });

  const subLineItemTable = (
    <SimpleTable
      noBottomBorder
      data={subLineItems}
      columns={[
        {
          header: "Description",
          render: (subLineItem) => subLineItem.display_name,
        },
        {
          header: "Quantity",
          alignment: "right",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "SeatChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem"
            ) {
              return (
                <Tooltip content={subLineItem.quantity}>
                  {Number(subLineItem.quantity).toLocaleString()}
                </Tooltip>
              );
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              return (
                <Tooltip content={subLineItem.quantity}>
                  {Number(subLineItem.quantity).toLocaleString()}
                </Tooltip>
              );
            }
            return "-";
          },
        },
        {
          header: "Unit Price",
          alignment: "right",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem"
            ) {
              if (!subLineItem.unit_price) {
                return "Tiered";
              }
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.unit_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (subLineItem.__typename === "SeatChargeLineItem") {
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.seat_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (subLineItem.__typename === "CompositeChargeLineItem") {
              const unitPrice =
                subLineItem.quantity === "0"
                  ? new Decimal(0)
                  : new Decimal(subLineItem.total).div(subLineItem.quantity);
              return (
                <RoundedCurrency
                  amount={unitPrice}
                  creditType={subLineItem.credit_type}
                />
              );
            } else if (tierChargeLineItemTypeGuard(subLineItem)) {
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.tier_price)}
                  creditType={subLineItem.credit_type}
                />
              );
            }
            return "-";
          },
        },
        {
          header: "Total",
          alignment: "right",
          render: (subLineItem) => {
            return (
              <RoundedCurrency
                amount={new Decimal(subLineItem.total)}
                creditType={subLineItem.credit_type}
                hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
              />
            );
          },
        },
      ]}
    />
  );
  return (
    <div className={styles.lineItem}>
      <ProductContainer
        title={<Headline level={5}>{lineItemName}</Headline>}
        groupKey={
          lineItem.__typename === "GroupedProductChargeLineItem"
            ? getDisplayName(bmGroupKeyOverrides, lineItem.group_key) ||
              lineItem.group_key
            : null
        }
      >
        {lineItem.__typename === "GroupedProductChargeLineItem" ? (
          <div className={styles.groupInfo}>
            {getDisplayName(bmGroupKeyOverrides, lineItem.group_key) ||
              lineItem.group_key}
            :{" "}
            <b>
              "
              {lineItem.group_value
                ? getValueDisplayName(
                    bmGroupKeyOverrides,
                    lineItem.group_key,
                    lineItem.group_value
                  ) || lineItem.group_value
                : "<empty>"}
              "
            </b>
          </div>
        ) : null}
        <div className={styles.content}>
          {subLineItems.length > 0 ? (
            subLineItemTable
          ) : (
            <NoSubLineItems
              showChargesWithZeroUsage={showChargesWithZeroUsage}
            />
          )}
        </div>
      </ProductContainer>
    </div>
  );
};
