import React from "react";
import {
  Headline,
  Body,
  Button,
  Icon,
  IconName,
} from "@metronome-industries/design-system";
import classnames from "classnames";

type Props = {
  title: string;
  subtitle: React.ReactNode;
  icon?: IconName;
  image?: React.ReactNode;
  buttonText?: string;
  buttonIcon?: IconName;
  onClick?: () => void;
};

export const EmptyState: React.FC<Props> = (props) => {
  return (
    <div
      className={classnames([
        "flex",
        "flex-grow",
        "flex-col",
        "items-center",
        "justify-center",
        "py-0",
        "px-[40px]",
        "my-0",
        "mx-[auto]",
        "mb-[100px]",
        "max-w-[680px]",
        "text-center",
      ])}
    >
      <div
        className={classnames([
          "w-[80px]",
          "h-[80px]",
          "text-2xl",
          "bg-primary-50",
          "text-primary-500",
          "flex",
          "items-center",
          "justify-center",
          "rounded-[40px]",
          "mb-24",
          "p-4",
        ])}
      >
        {props.icon && <Icon icon={props.icon} />}
        {props.image && props.image}
      </div>
      <Headline level={4}>{props.title}</Headline>
      <Body level={1} className="mt-8 mb-24 text-grey-700">
        {props.subtitle}
      </Body>
      {props.buttonText || props.buttonIcon ? (
        <Button
          size="regular"
          type="fill"
          theme="primary"
          icon={props.buttonIcon}
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      ) : null}
    </div>
  );
};
