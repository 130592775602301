import React from "react";
import { Subtitle } from "@metronome-industries/design-system";

interface ProductContainerProps extends React.PropsWithChildren {
  title: React.ReactNode;
  groupKey?: string | null;
  groupValue?: string | null;
}

export const ProductContainer: React.FC<ProductContainerProps> = ({
  title,
  groupKey,
  groupValue,
  children,
}) => {
  return (
    <div className="border border-grey-100 rounded-medium mb-24">
      <div className="bg-primary-50 flex items-center justify-between p-12">
        <div className="flex items-center">
          <Subtitle level={1}>{title}</Subtitle>
          {groupKey && groupValue && (
            <Subtitle level={1} className="font-mono">
              &nbsp;-&nbsp;{groupKey}: {groupValue}
            </Subtitle>
          )}
        </div>
        {groupKey && (
          <div className="flex gap-8 items-center">
            <Subtitle level={4}>Group by:</Subtitle>
            <Subtitle
              level={4}
              className="bg-grey-100 text-grey-800 p-4 font-mono rounded-medium font-normal"
            >
              {groupKey}
            </Subtitle>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
