import React from "react";
import classNames from "classnames";

import { Panel, PanelProps } from "../Panel";
import { InPanelTable, InPanelTableProps } from "./InPanelTable";

export type { Column as TableColumn } from "../Table";

export type TablePanelProps<T extends object> = Omit<
  InPanelTableProps<T>,
  "containerClassName"
> & { tableContainerClassName?: string } & PanelProps;

/**
 * A table with a header, wrapped in a rounded border
 */
export function TablePanel<T extends object>({
  title,
  className,
  contentClassName,
  controls,
  tableContainerClassName,
  emptyState,
  data,
  ...tableProps
}: TablePanelProps<T>) {
  return (
    <Panel
      title={title}
      className={className}
      contentClassName={classNames(contentClassName)}
      controls={controls}
    >
      {data.length ? (
        <InPanelTable<T>
          panelHeader={!title}
          containerClassName={tableContainerClassName}
          data={data}
          {...tableProps}
        />
      ) : (
        emptyState
      )}
    </Panel>
  );
}
