import Decimal from "decimal.js";
import React from "react";

import { RoundedCurrency } from "lib/credits";
import { dayjs, printDateRange, toDayjs } from "lib/date";
import { CommitReference } from "../../../CommitReference";
import { ContractLineItem } from "../../types";
import { TablePanel } from "components/TablePanel";
import { Tooltip } from "@metronome-industries/design-system";
import { renderDate } from "lib/time";

interface Props {
  issuedAt: Date;
  lineItems: ContractLineItem[];
  invoiceType:
    | "AdvanceInvoice"
    | "ArrearsInvoice"
    | "ContractScheduledInvoice"
    | "ContractUsageInvoice"
    | "CorrectionInvoice"
    | "SeatPurchaseInvoice"
    | "CreditPurchaseInvoice"
    | "ContractRefundInvoice"
    | "ContractPostpaidTrueupInvoice"
    | "ParentInvoice"
    | "AdhocContractUsageInvoice"
    | "AdHocPlanInvoice"
    | "ContractProServiceInvoice";
}

export const ContractLineItems: React.FC<Props> = (props) => {
  const now = dayjs();
  return (
    <TablePanel
      data={props.lineItems.filter(
        // these line items are only shown in the totals
        (li) =>
          li.__typename !== "ContractAppliedCommitLineItem" &&
          li.__typename !== "ContractRolloverCommitLineItem"
      )}
      className="m-12"
      columns={[
        {
          id: "charge",
          header: "Charge",
          render: (row: ContractLineItem) => row.display_name,
        },
        {
          id: "commit",
          header: "Commitment",
          disabled: props.invoiceType !== "ContractUsageInvoice",
          render: (row: ContractLineItem) =>
            row.__typename === "ContractUsageLineItem" &&
            row.optionalCommitWithSegment ? (
              <CommitReference
                {...row.optionalCommitWithSegment.commit_union}
              />
            ) : (
              "-"
            ),
        },
        {
          id: "start",
          header: "Effective date",
          render: (row: ContractLineItem) =>
            "inclusive_start_date" in row
              ? printDateRange(
                  now,
                  toDayjs(row.inclusive_start_date),
                  toDayjs(row.exclusive_end_date)
                )
              : renderDate(props.issuedAt, { isUtc: true }),
        },
        {
          id: "quantity",
          header: "Quantity",
          align: "right",
          render: (row: ContractLineItem) => {
            if (
              (row.__typename === "ContractUsageLineItem" &&
                row.product.__typename === "CompositeProductListItem") ||
              row.__typename === "ContractAWSRoyaltyLineItem" ||
              row.__typename === "ContractGCPRoyaltyLineItem"
            ) {
              return (
                <RoundedCurrency
                  amount={new Decimal(row.quantity)}
                  creditType={row.credit_type}
                />
              );
            } else if ("quantity" in row) {
              const quantity = new Decimal(row.quantity);

              // This mirrors the logic in the RoundedCurrency component.
              const rounded = (
                quantity.abs().lessThan(1)
                  ? quantity.toSignificantDigits(2)
                  : quantity.toDecimalPlaces(2)
              ).toString();
              if (rounded !== row.quantity) {
                return (
                  <Tooltip
                    inline
                    content={
                      row.quantity.length < 20
                        ? row.quantity
                        : row.quantity.slice(0, 20) + "..."
                    }
                  >
                    {rounded}
                  </Tooltip>
                );
              } else {
                return row.quantity;
              }
            } else {
              return "-";
            }
          },
        },
        {
          id: "unitPrice",
          header: "Unit price",
          align: "right",
          render: (row: ContractLineItem) => {
            if (
              (row.__typename === "ContractUsageLineItem" &&
                row.product.__typename === "CompositeProductListItem") ||
              row.__typename === "ContractAWSRoyaltyLineItem" ||
              row.__typename === "ContractGCPRoyaltyLineItem"
            ) {
              return `${new Decimal(row.unit_price).mul(100)}%`;
            } else if ("unit_price" in row) {
              return (
                <RoundedCurrency
                  creditType={row.credit_type}
                  amount={new Decimal(row.unit_price)}
                />
              );
            }

            return "-";
          },
        },
        {
          id: "total",
          header: "Total",
          align: "right",
          render: (row: ContractLineItem) => (
            <RoundedCurrency
              creditType={row.credit_type}
              amount={new Decimal(row.total)}
            />
          ),
        },
      ]}
    />
  );
};
