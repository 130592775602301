import React from "react";

import { renderDate, renderDateTime } from "lib/time";

import {
  ComposedChart,
  YAxis,
  XAxis,
  Text,
  CartesianGrid,
  Area,
  Line,
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  ReferenceLine,
  Legend as RechartsLegend,
  TooltipProps,
} from "recharts";

import styles from "./index.module.css";
import { TooltipContent } from "@metronome-industries/design-system";
import { GraphSkeleton } from "components/Skeleton";

// if you want to adjust the size of dots on line charts, do it here
// to make sure that the tooltip cursor matches
const DOT_RADIUS = 5;
const DOT_STROKE = 3;

type AxisProps = {
  payload: {
    value: number;
  };
};

type ReferenceMarker = {
  date: Date;
  label: string;
};

export type TimeSeriesDatum = {
  date: Date;
  value: number;
};

export type TimeSeries = {
  color: string;
  data: TimeSeriesDatum[];
  name?: string;
};

type Props = {
  areas?: TimeSeries[];
  lines?: TimeSeries[];
  yValueFormatter?: (n: number) => string;
  isUTC?: boolean;
  stroke?: "solid" | "dashed";
  referenceMarkers?: ReferenceMarker[];
  showLegend?: boolean;
  // when showing the legend, should the sum of values for each line/area
  // appear next to the color?
  showTotalsInLegend?: boolean;
  showHours?: boolean;
  loading?: boolean;
  legendPosition?: "top" | "bottom";
};

export const Graph: React.FC<Props> = (props) => {
  const {
    areas = [],
    lines = [],
    yValueFormatter,
    isUTC = false,
    stroke = "solid",
    referenceMarkers,
    showLegend = false,
    showTotalsInLegend: showLegendTotals = false,
    showHours = false,
    legendPosition = "top",
  } = props;
  // empty charts and loading charts get the skeleton treatment
  if ((areas.length === 0 && lines.length === 0) || props.loading) {
    return (
      <GraphSkeleton
        showLegend={showLegend}
        showTotalsInLegend={showLegendTotals}
      />
    );
  }

  // This component currently assumes that every graph layer's data array has the same exact dates in the same order
  // TODO: Make this not the case with additional logic
  const combinedLayers = areas.concat(lines);
  if (
    !combinedLayers.every(
      (arr) => arr.data.length === combinedLayers[0].data.length
    )
  ) {
    throw new Error(
      "Got data arrays with mismatched lengths, meaning the data doesn't all represent the same time period"
    );
  }

  // convert the time series into a recharts-friendly format
  const dataWithUnixTime = (areas.length > 0 ? areas[0] : lines[0]).data.map(
    (datum, datumIndex) => ({
      date: datum.date.valueOf(),
      ...Object.fromEntries(
        areas.map((area, areaIndex) => [
          areaIndex.toString(),
          area.data[datumIndex].value,
        ])
      ),
      ...Object.fromEntries(
        lines.map((line, lineIndex) => [
          (areas.length + lineIndex).toString(),
          line.data[datumIndex].value,
        ])
      ),
    })
  );

  // if the data in the chart is all 0s, set the y axis to be 0-4
  const maxDatumValue =
    Math.max(
      ...combinedLayers.flatMap((timeSeries) =>
        timeSeries.data.map((datum) => datum.value)
      )
    ) || 4;

  const YTick: React.FC<AxisProps> = (props) => {
    let tickValue =
      yValueFormatter !== undefined
        ? yValueFormatter(props.payload.value)
        : props.payload.value.toLocaleString();
    return (
      <Text {...props} x={0} textAnchor="start" className={styles.yAxisTick}>
        {tickValue}
      </Text>
    );
  };

  const referenceMarkerLabels = Object.fromEntries(
    (referenceMarkers || []).map((m) => [m.date.valueOf(), m.label])
  );

  const XAxisTick: React.FC<AxisProps> = (props) => {
    const date = new Date(props.payload.value);
    const dateLabel = renderDate(date, {
      excludeYear: true,
      isUtc: isUTC,
      excludeUtcLabel: true,
    });
    return (
      <Text {...props} className={styles.xAxisTick}>
        {dateLabel}
      </Text>
    );
  };

  const Tooltip: React.FC<TooltipProps<any, any>> = (props) => {
    if (!props.payload || !props.label) {
      return null;
    }
    const payload: {
      [key: string]: number;
      date: number;
    } = (props.payload || [])[0]?.payload;
    if (!payload) {
      return null;
    }
    const countDisplays: string[] = props.payload
      .map((_, i) => payload[i.toString()])
      .map((value) =>
        yValueFormatter !== undefined
          ? yValueFormatter(value)
          : value.toLocaleString()
      );

    const dateLabel = showHours
      ? renderDateTime(new Date(props.label), false)
      : renderDate(new Date(props.label), {
          isUtc: isUTC,
          excludeUtcLabel: !isUTC,
        });

    return (
      <TooltipContent>
        <div className="max-h-[200px] overflow-y-auto">
          {dateLabel}
          <br />
          {referenceMarkerLabels[props.label] && (
            <>
              {referenceMarkerLabels[props.label]}
              <br />
            </>
          )}
          {countDisplays.map((countDisplay, i) => (
            <p key={i}>
              {props.payload ? props.payload[i].name : ""}:{" "}
              <b>{countDisplay}</b>
            </p>
          ))}
        </div>
      </TooltipContent>
    );
  };

  const Legend: React.FC<{
    areas: TimeSeries[];
    lines: TimeSeries[];
    showTotals: boolean;
  }> = (props) => {
    const timeSeries: TimeSeries[] = props.areas.concat(props.lines);
    return (
      <ul className={styles.legend}>
        {timeSeries.map((layer, i) => (
          <div className={styles.legendLayer} key={i}>
            <div className={styles.legendLabel}>
              <div
                className={styles.dot}
                style={{ backgroundColor: layer.color }}
              />
              <li>{layer.name}</li>
            </div>
            {props.showTotals && (
              <li className={styles.legendTotal}>
                {layer.data
                  .reduce((sum, { value }) => sum + value, 0)
                  .toLocaleString()}
              </li>
            )}
          </div>
        ))}
      </ul>
    );
  };
  return (
    <div className={styles.container}>
      <ResponsiveContainer>
        <ComposedChart data={dataWithUnixTime} className={styles.chart}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tick={XAxisTick as any}
            interval="preserveStartEnd"
            padding={{ left: 32, right: 31 }}
            height={16}
            type="number"
            domain={["dataMin", "dataMax"]}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={YTick as any}
            width={32}
            padding={{ top: 8, bottom: 0 }}
            domain={[0, maxDatumValue]}
          />
          {showLegend && (
            <RechartsLegend
              content={
                <Legend
                  areas={areas}
                  lines={lines}
                  showTotals={showLegendTotals}
                />
              }
              verticalAlign={legendPosition}
              align="left"
            />
          )}
          {areas.map((area, i) => (
            <Area
              name={area.name}
              dataKey={i}
              stroke={area.color}
              fillOpacity="80%"
              fill={area.color}
              isAnimationActive={false}
              key={i}
            />
          ))}
          {lines.map((line, i) => (
            <Line
              name={line.name}
              type="linear"
              dataKey={areas.length + i}
              stroke={line.color}
              strokeWidth={2}
              dot={{
                stroke: "white",
                strokeWidth: DOT_STROKE,
                r: DOT_RADIUS,
                fill: line.color,
                strokeDasharray: "",
              }}
              strokeDasharray={stroke === "dashed" ? "3 3" : ""}
              isAnimationActive={false}
              key={i}
            />
          ))}
          {(referenceMarkers || []).map((m) => (
            <ReferenceLine
              x={m.date.valueOf()}
              className={styles.referenceMarker}
              key={m.date.valueOf()}
            />
          ))}
          <RechartsToolTip
            content={<Tooltip />}
            cursor={{
              strokeWidth: 1,
              stroke: "var(--color-grey-800)",
              strokeDasharray: 4,
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
