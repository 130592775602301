import React from "react";
import { Subtitle } from "@metronome-industries/design-system";
import styles from "./index.module.less";

export const NoSubLineItems: React.FC<{
  showChargesWithZeroUsage: boolean;
}> = ({ showChargesWithZeroUsage }) => {
  return (
    <div className={styles.NoSubLineItems}>
      <Subtitle level={3}>
        {showChargesWithZeroUsage
          ? "No charges for this product"
          : "No charges with usage for this product"}
      </Subtitle>
    </div>
  );
};
