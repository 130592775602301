import React from "react";
import classNames from "classnames";

export type PanelProps = React.PropsWithChildren<{
  title?: string;
  subtitle?: string;
  badge?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  controls?: React.ReactNode;
}>;

/**
 * A simple container with a header, wrapped in a rounded border
 */
export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (
    { title, subtitle, badge, className, contentClassName, controls, children },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          className,
          "rounded-large border border-gray-light"
        )}
      >
        {title || subtitle || badge || controls ? (
          <div className="flex flex-row items-center p-8 pl-12 bg-grey-lightest dark:bg-grey-darkest rounded-t-large">
            {!subtitle ? (
              <p className="text-sm leading-4">{title}</p>
            ) : (
              <div>
                <p className="text-sm leading-3">{title}</p>
                <p className="text-xs leading-2">{subtitle}</p>
              </div>
            )}
            {badge}
            <span className="grow" />
            {controls}
          </div>
        ) : null}
        <div
          className={classNames(
            "overflow-clip rounded-b-large",
            contentClassName
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);
