import React from "react";
import Decimal from "decimal.js";

import { RoundedCurrency } from "lib/credits";
import { StatusPills } from "components/StatusPills";

import { InvoiceFragment } from "./fragments.graphql";
import { printDateRange, printDate, Dayjs, toDayjs } from "../date";
import { CreditType } from "types/credit-types";

export class InvoiceFragmentReader {
  static compareByDate(a: InvoiceFragmentReader, b: InvoiceFragmentReader) {
    return a.issued_at.diff(b.issued_at, "milliseconds");
  }

  public readonly total: Decimal;
  public readonly creditType: CreditType;
  public readonly issued_at: Dayjs;

  // fields that are only available on specific invoice types
  public readonly id: string;
  public readonly inclusive_start_date?: Dayjs;
  public readonly exclusive_end_date?: Dayjs;

  constructor(customerId: string, private readonly fragment: InvoiceFragment) {
    this.id = fragment.id;
    this.total = new Decimal(fragment.total);
    this.creditType = fragment.credit_type;
    this.issued_at = toDayjs(fragment.issued_at);

    if (
      fragment.__typename === "ArrearsInvoice" ||
      fragment.__typename === "ContractUsageInvoice"
    ) {
      this.inclusive_start_date = toDayjs(fragment.inclusive_start_date);
      this.exclusive_end_date = toDayjs(fragment.exclusive_end_date);
    }
  }

  printType(): string {
    const typename = this.fragment.__typename;
    switch (typename) {
      case "AdvanceInvoice":
        return "Advance";
      case "ArrearsInvoice":
      case "AdHocPlanInvoice":
        return "Arrears";
      case "CorrectionInvoice":
        return "Correction";
      case "CreditPurchaseInvoice":
        return "Credit purchase";
      case "ContractScheduledInvoice":
        return "Scheduled";
      case "ContractUsageInvoice":
      case "AdhocContractUsageInvoice":
        return "Usage";
      case "SeatPurchaseInvoice":
        return "Seat purchase";
      case "ContractRefundInvoice":
        return "Refund";
      case "ContractPostpaidTrueupInvoice":
        return "True-up";
      case "ParentInvoice":
        return "Parent";
      case "ContractProServiceInvoice":
        return "Professional services";
      default:
        typename satisfies never;
        throw new Error("Unknown invoice type: " + typename);
    }
  }

  /**
   * If the invoice is an arrears invoice, then the invoice date is the date range
   * of the invoice. Otherwise, it's the date the invoice was issued.
   */
  printInvoiceDate(now: Dayjs) {
    if (this.inclusive_start_date && this.exclusive_end_date) {
      return printDateRange(
        now,
        this.inclusive_start_date,
        this.exclusive_end_date
      );
    }

    return printDate(this.issued_at);
  }

  renderTotal() {
    return (
      <RoundedCurrency
        amount={this.total}
        creditType={this.fragment.credit_type}
      />
    );
  }

  /**
   * Get the underlying graphql data for this invoice.
   */
  renderStatusPills({ light = true } = {}) {
    return <StatusPills invoice={this.fragment} light={light} />;
  }
}
