import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { AuthContext } from "./lib/AuthContext";
import { ApolloProvider } from "./lib/apollo";
import CustomerDashboard from "./pages/customer/CustomerDashboard";
import Invoices from "./pages/customer/invoices";
import UsageDashboard from "./pages/customer/usage";
import CreditsDashboard from "./pages/customer/credits";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://9a89952c39d840cbaa6841024456d8ed@o431438.ingest.sentry.io/4504483987652608",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  enabled: !!(
    process.env.REACT_APP_SENTRY_ENVIRONMENT &&
    process.env.REACT_APP_SENTRY_ENVIRONMENT !== "dev"
  ),
  release: process.env.REACT_APP_BUILD_SHA1,
  // We have so few users for now so YOLO - Will need to drop this if we start hitting limits
  tracesSampleRate: 1,
});

// TODO(ekaragiannis) - setup the color scheme
// import { colorScheme } from "@metronome-industries/design-system";
import "@metronome-industries/design-system/style.css";
import "./style/index.css";

// setup the color scheme
// colorScheme();
const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <React.StrictMode>
    <AuthContext.Provider>
      <ApolloProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/dashboards/customer" element={<CustomerDashboard />}>
              <Route path="invoices/v1/" element={<Invoices />} />
              <Route path="usage/v1/" element={<UsageDashboard />} />
              <Route path="credits/v1/" element={<CreditsDashboard />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </AuthContext.Provider>
  </React.StrictMode>
);
