import React from "react";

import { LineItemFieldsFragment } from "../../fragments.graphql";

import { Headline, Tooltip } from "@metronome-industries/design-system";
import styles from "./index.module.less";
import { ProductContainer } from "components/ProductContainer";
import { SimpleTable } from "components/SimpleTable";
import { RoundedCurrency } from "lib/credits";
import Decimal from "decimal.js";

export const CreditPurchaseLineItem: React.FC<{
  lineItem: LineItemFieldsFragment & { __typename: "CreditPurchaseLineItem" };
}> = ({ lineItem }) => {
  return (
    <div className={styles.lineItem}>
      <ProductContainer
        title={<Headline level={5}>{lineItem.display_name}</Headline>}
        groupKey={null}
      >
        <div className={styles.content}>
          <SimpleTable
            noBottomBorder
            data={lineItem.sub_line_items}
            columns={[
              {
                header: "Description",
                render: (subLineItem) => {
                  return subLineItem.display_name;
                },
              },
              {
                header: "Quantity",
                alignment: "right",
                render: (subLineItem) => (
                  <Tooltip content={subLineItem.quantity}>
                    {Number(subLineItem.quantity).toLocaleString()}
                  </Tooltip>
                ),
              },
              {
                header: "Unit Price",
                alignment: "right",
                render: (subLineItem) => (
                  <RoundedCurrency
                    amount={new Decimal(subLineItem.total).div(
                      subLineItem.quantity
                    )}
                    creditType={subLineItem.credit_type}
                  />
                ),
              },
            ]}
          />
        </div>
      </ProductContainer>
    </div>
  );
};
